import React, { useState, useContext } from 'react';
import { Grid } from '@material-ui/core';
import {
    AppContext,
    SttDialog,
    SttFormCheck,
    SttFormPeriod,
    SttFormText,
    SttFormTextarea,
    SttSelectFixedInterval,
    SttFormPrice,
    SttSelectGroup,
    SttFormSelect,
} from '../../../sporttia/all';
import { parsePrice } from '../../../lib/utils';
import ActivityRateDiscountList from './ActivityDiscountList';
import translations from '../../../translations';
import constants from '../../../config/constants';
import { useTranslations } from '../../../lib/hooks';

/**
 * ActivityRateDialog - dialog usado para añadir y editar tarifas de actividades
 * bool open - si está abierto o no
 * bool item - la tarifa que estamos viendo (si no contiene id ha de ser una tarifa que estamos creando)
 * int activityId - id de la actividad padre
 * Function onClose - callback al cerrar el dialog
 * Function onSave - callback al crear, guardar o borrar la tarifa actual
 */
export default function ActivityRateDialog({
    open,
    item,
    activityId,
    onClose,
    onSave,
    deleteRateEnabled = true,
}) {
    const [rate, setRate] = useState(item);

    const cxt = useContext(AppContext);
    const { translate } = useTranslations();
    function handleChange({ name, value }) {
        setRate({ ...rate, [name]: value });
    }

    function saveAndBye() {
        cxt.showMessage('S', cxt.t('OperationSuccessful'));

        onSave();
        onClose();
    }

    function saveRate() {
        const params = {
            name: rate.name,
            description: rate.description,
            ini: rate.ini,
            end: rate.end,
            maxStudents: rate.maxStudents,
            onlyCertified: rate.onlyCertified,
            signupOnline: rate.signupOnline,
            enrollment: rate.enrollment && parsePrice(rate.enrollment),
            fee: rate.fee && parsePrice(rate.fee),
            period: rate.period,
            tpvEnabled: rate.tpvEnabled,
            feeTaxConceptCode: rate.feeTaxConceptCode ?? null,
            signupTaxConceptCode: rate.signupTaxConceptCode ?? null,
            maxAge: rate?.maxAge === 0 ? null : rate?.maxAge,
            minAge: rate?.minAge === 0 ? null : rate?.minAge,
        };

        if (rate?.mandatory?.id !== undefined) {
            params.mandatory = {
                id: rate.mandatory.id === 0 ? '' : rate.mandatory.id,
            };
        }

        if (rate.id) {
            cxt.api('PUT', `/activities/rates/${rate.id}`, {
                params,
                success: () => {
                    saveAndBye();
                },
            });
        } else {
            if (!activityId) {
                return;
            }

            cxt.api('POST', `/activities/${activityId}/rates`, {
                params,
                success: () => {
                    saveAndBye();
                },
            });
        }
    }

    function deleteRate() {
        cxt.api('DELETE', `/activities/rates/${rate.id}`, {
            confirmation: true,
            success: () => {
                saveAndBye();
            },
        });
    }

    if (!rate) {
        return null;
    }

    const buttons = [
        {
            type: 'save',
            onClick: saveRate,
        },
        {
            type: 'close',
            onClick: onClose,
        },
    ];

    // si el rate es uno existente, incrustamos el botón borrar entre los otros 2
    if (rate.id && deleteRateEnabled) {
        buttons.splice(1, 0, {
            type: 'delete',
            onClick: deleteRate,
        });
    }

    return (
        <SttDialog
            title={cxt.t('Rate')}
            open={open}
            maxWidth="md"
            buttons={buttons}
            onClose={onClose}
            content={
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <SttFormText
                                    name="name"
                                    caption={cxt.t('Name')}
                                    defVal={rate.name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SttFormTextarea
                                    name="description"
                                    caption={cxt.t('Description')}
                                    defVal={rate.description}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <SttFormPeriod
                                    captionIni={cxt.t('generateFeesFrom')}
                                    nameIni="ini"
                                    defValIni={rate.ini}
                                    nameEnd="end"
                                    defValEnd={rate.end}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SttSelectGroup
                                    caption={cxt.t('MandatoryGroup')}
                                    name="mandatory"
                                    defVal={rate.mandatory}
                                    onChange={handleChange}
                                    onlyActive
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SttFormText
                                    caption={cxt.t('MaxUsers')}
                                    name="maxStudents"
                                    defVal={rate.maxStudents}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <SttFormPrice
                                    name="enrollment"
                                    caption={cxt.t('Enrollment')}
                                    defVal={rate.enrollment}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <SttFormPrice
                                    name="fee"
                                    caption={cxt.t('Fee')}
                                    defVal={rate.fee}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <SttSelectFixedInterval
                                    name="period"
                                    caption={cxt.t('generateFeesEvery')}
                                    options={[
                                        'FORTNIGHT',
                                        '1 MONTH',
                                        '2 MONTH',
                                        '3 MONTH',
                                        '4 MONTH',
                                        '6 MONTH',
                                        '1 YEAR',
                                    ]}
                                    defVal={rate.period}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6} md={6} xl={4}>
                                <SttFormSelect
                                    caption={translate(
                                        translations.activity.minAge,
                                    )}
                                    name="minAge"
                                    defVal={
                                        rate.minAge ??
                                        translate(translations.generic.empty)
                                    }
                                    onChange={({ value, name }) => {
                                        handleChange({ name, value });
                                    }}
                                    options={[
                                        {
                                            label: translate(
                                                translations.generic.empty,
                                            ),
                                            value: 0,
                                        },
                                        ...[...Array(90).keys()]
                                            .slice(1)
                                            .map((value) => ({
                                                label: value,
                                                value,
                                            })),
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={6} md={6} xl={4}>
                                <SttFormSelect
                                    caption={translate(
                                        translations.activity.maxAge,
                                    )}
                                    name="maxAge"
                                    defVal={
                                        rate.maxAge ??
                                        translate(translations.generic.empty)
                                    }
                                    onChange={({ value, name }) => {
                                        handleChange({ name, value });
                                    }}
                                    options={[
                                        {
                                            label: translate(
                                                translations.generic.empty,
                                            ),
                                            value: 0,
                                        },
                                        ...[...Array(90).keys()]
                                            .slice(1)
                                            .map((value) => ({
                                                label: value,
                                                value,
                                            })),
                                    ]}
                                />
                            </Grid>
                            {Object.values(constants.orgt.scIds).includes(
                                cxt?.sc?.id,
                            ) && (
                                <Grid item md={6} sm={6} xs={6}>
                                    <SttFormSelect
                                        name="feeTaxConceptCode"
                                        caption={cxt.t(
                                            translations.orgt.feeTaxConcept,
                                        )}
                                        defVal={
                                            rate?.feeTaxConceptCode ??
                                            constants.orgt.taxCodes.noOption
                                        }
                                        options={[
                                            {
                                                caption: translate(
                                                    translations.orgt.noOption,
                                                ),
                                                value: null,
                                            },
                                            {
                                                caption: translate(
                                                    translations.orgt.feePass,
                                                ),
                                                value: constants.orgt.taxCodes
                                                    .feePassCode,
                                            },
                                            {
                                                caption: translate(
                                                    translations.orgt
                                                        .feeRoomActivity,
                                                ),
                                                value: constants.orgt.taxCodes
                                                    .feeRoomActivity,
                                            },
                                            {
                                                caption: translate(
                                                    translations.orgt
                                                        .feeAquaticActivity,
                                                ),
                                                value: constants.orgt.taxCodes
                                                    .feeAquaticActivity,
                                            },
                                            {
                                                caption: translate(
                                                    translations.orgt
                                                        .feeSchoolActivity,
                                                ),
                                                value: constants.orgt.taxCodes
                                                    .feeSchoolActivity,
                                            },
                                            {
                                                caption: translate(
                                                    translations.orgt.assurance,
                                                ),
                                                value: constants.orgt.taxCodes
                                                    .assurance,
                                            },
                                        ]}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            )}
                            {Object.values(constants.orgt.scIds).includes(
                                cxt?.sc?.id,
                            ) && (
                                <Grid item md={6} sm={6} xs={6}>
                                    <SttFormSelect
                                        name="signupTaxConceptCode"
                                        caption={cxt.t(
                                            translations.orgt.signupTaxConcept,
                                        )}
                                        defVal={
                                            rate?.signupTaxConceptCode ??
                                            constants.orgt.taxCodes.noOption
                                        }
                                        options={[
                                            {
                                                caption: translate(
                                                    translations.orgt.noOption,
                                                ),
                                                value: null,
                                            },
                                            {
                                                caption: translate(
                                                    translations.orgt
                                                        .enrollment,
                                                ),
                                                value: constants.orgt.taxCodes
                                                    .enrollment,
                                            },
                                        ]}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <div>
                                    <SttFormCheck
                                        name="onlyCertified"
                                        caption={cxt.t('OnlyCertified')}
                                        checked={!!rate.onlyCertified}
                                        onChange={({ name, value }) =>
                                            handleChange({ name, value })
                                        }
                                    />
                                </div>
                                <div>
                                    <SttFormCheck
                                        name="signupOnline"
                                        caption={cxt.t('page.homeA.at.1')}
                                        checked={!!rate.signupOnline}
                                        onChange={({ name, value }) =>
                                            handleChange({ name, value })
                                        }
                                    />
                                </div>
                                <div>
                                    <SttFormCheck
                                        name="tpvEnabled"
                                        caption={cxt.t('page.homeA.bk.1')}
                                        checked={!!rate.tpvEnabled}
                                        disabled={!rate.signupOnline}
                                        onChange={({ name, value }) =>
                                            handleChange({ name, value })
                                        }
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Grid container>
                            <Grid item xs={12}>
                                <ActivityRateDiscountList rateId={item.id} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        />
    );
}
