import React, { useContext } from 'react';
import { Chip } from '@material-ui/core';
import { AppContext } from '../AppContext';

/**
 * Chip purse movs
 */
export function SttChipPurseMov({ type }) {
    const cxt = useContext(AppContext);

    const color = {
        DEPOSIT: '#3186a7',
        REFUND: '#a341bb',
        RENT: '#f35b77',
        PAYMENT: '#d8af54',
        BUY: '#75bb41',
    };

    return (
        <>
            {type && (
                <Chip
                    size="small"
                    label={cxt.t(`PMT.${type}`)}
                    style={{ backgroundColor: color[type], color: 'white' }}
                />
            )}
        </>
    );
}
