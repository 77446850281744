import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import { AppContext, SttDialog, SttList } from '../../../sporttia/all';

export default function ErrorsDialog({ errors, onClose }) {
    const cxt = useContext(AppContext);

    if (!errors) {
        return null;
    }

    return (
        <SttDialog
            onClose={onClose}
            open
            buttons={[
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
            title={cxt.t('Errors')}
            content={
                <Box p={2}>
                    <Box mb={2}>
                        <Typography variant="body1">
                            {cxt.t('busySchedules.errorsTitle')}
                        </Typography>
                    </Box>
                    <Box>
                        <SttList
                            data={errors.map((error, i) => ({
                                caption: `${i + 1}. ${moment(error.ini).format(
                                    'dddd, DD MMMM HH:mm',
                                )} - ${moment(error.end).format('HH:mm')}, ${
                                    error.mark == 'FREE'
                                        ? cxt.t(
                                              `controller.support.capacityField`,
                                          )
                                        : error.title
                                }`,
                                text: `${cxt.t(`const.TT.${error.mark}`)} - ${
                                    error.duration
                                } ${cxt.t('minutes')}`,
                            }))}
                        />
                    </Box>
                </Box>
            }
        />
    );
}
