import React, { ReactNode } from 'react';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { SttButtonAccept } from '../../sporttia/buttons/SttButtonAccept';
import { SttButtonCancel } from '../../sporttia/buttons/SttButtonCancel';
import { SttButtonClose } from '../../sporttia/buttons/SttButtonClose';
import { SttButtonDelete } from '../../sporttia/buttons/SttButtonDelete';
import { SttButtonRecover } from '../../sporttia/buttons/SttButtonRecover';
import { SttButtonSave } from '../../sporttia/buttons/SttButtonSave';
import { SttButtonUnsubscribe } from '../../sporttia/buttons/SttButtonUnsubscribe';
import { SttButtonDownload } from '../../sporttia/buttons/SttButtonDownload';

type ButtonType =
    | 'accept'
    | 'cancel'
    | 'close'
    | 'delete'
    | 'recover'
    | 'save'
    | 'unsubscribe'
    | 'download'
    | 'component';

type ButtonConfig = {
    show?: boolean;
    component?: ReactNode;
    type?: ButtonType;
    disabled?: boolean;
    caption?: string;
    onClick: () => void;
    confirmation?: boolean;
    loading?: boolean;
    icon?: ReactNode;
};

type AlertDialogProps = {
    title?: string;
    subtitle?: string;
    open: boolean;
    onClose?: () => void;
    buttons: ButtonConfig[];
};

/**
 * Alert dialog. Used to warn the user before taking an action.
 */
export function AlertDialog({
    title,
    subtitle,
    open,
    onClose,
    buttons,
}: AlertDialogProps) {
    /**
     * Render buttons.
     */
    const renderButton = (button: ButtonConfig, idx: number) => {
        if (button) {
            let content: ReactNode;

            if (button.show !== false) {
                if (button.component) {
                    content = button.component;
                } else {
                    switch (button.type) {
                        case 'accept':
                            content = (
                                <SttButtonAccept
                                    fullWidth
                                    disabled={button.disabled}
                                    caption={button.caption}
                                    onClick={button.onClick}
                                />
                            );
                            break;
                        case 'cancel':
                            content = (
                                <SttButtonCancel
                                    fullWidth
                                    onClick={button.onClick}
                                />
                            );
                            break;
                        case 'close':
                            content = (
                                <SttButtonClose
                                    fullWidth
                                    onClick={button.onClick}
                                />
                            );
                            break;
                        case 'delete':
                            content = (
                                <SttButtonDelete
                                    fullWidth
                                    caption={button.caption}
                                    onClick={button.onClick}
                                    confirmation={!!button.confirmation}
                                />
                            );
                            break;
                        case 'recover':
                            content = (
                                <SttButtonRecover
                                    fullWidth
                                    onClick={button.onClick}
                                />
                            );
                            break;
                        case 'save':
                            content = (
                                <SttButtonSave
                                    fullWidth
                                    onClick={button.onClick}
                                    loading={button.loading}
                                    disabled={button.disabled}
                                />
                            );
                            break;
                        case 'unsubscribe':
                            content = (
                                <SttButtonUnsubscribe
                                    fullWidth
                                    onClick={button.onClick}
                                />
                            );
                            break;
                        case 'download':
                            content = (
                                <SttButtonDownload
                                    fullWidth
                                    onClick={button.onClick}
                                />
                            );
                            break;
                        default:
                            content = (
                                <Button
                                    onClick={button.onClick}
                                    startIcon={button.icon}
                                    color="primary"
                                >
                                    {button.caption}
                                </Button>
                            );
                            break;
                    }
                }

                return <Box key={idx}>{content}</Box>;
            }
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {title && (
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            )}

            {subtitle && (
                <DialogContent style={{ backgroundColor: 'white' }}>
                    <DialogContentText id="alert-dialog-description">
                        {subtitle}
                    </DialogContentText>
                </DialogContent>
            )}

            <DialogActions>
                {buttons.map((button, idx) => renderButton(button, idx))}
            </DialogActions>
        </Dialog>
    );
}
