import React, { useState, useEffect, useContext } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, TextField } from '@material-ui/core';
import { AppContext } from '../all';

/*
 * SttSelectAutocomplete: render a generic Autocomplete Select
 * bool grid: wrap the item in a grid element, take xs, sm, md etc. from ...rest props
 * string name: field name,
 * string caption: textarea label
 * array options: list of arbitrary option objects
 * string defVal: value of the currently selected option
 * string labelField: which option field to use for the option labels, for example {"name"}
 * string valueField: which option field to use for the values, for example {"id"}
 * function onChange: callback for the select
 * */
export function SttSelectAutocomplete({
    grid,
    name,
    caption,
    options,
    defVal,
    size = 'small',
    variant = 'outlined',
    labelField = 'name',
    valueField = 'id',
    onChange,
    ...rest
}) {
    const selected = options.find((option) => option[valueField] == defVal) || {
        [labelField]: '',
        [valueField]: null,
    };

    function change(item) {
        if (onChange && onChange.constructor == Function) {
            onChange({ name, value: item ? item[valueField] : undefined });
        }
    }

    // Render item
    const content = (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => option[labelField]}
            onChange={(ev, item) => change(item)}
            // getOptionSelected={option => (option[valueField] == defVal) || {[labelField]: ""}}
            value={selected}
            renderInput={(params) => (
                <TextField
                    fullWidth
                    {...params}
                    size={size}
                    label={caption}
                    variant={variant}
                />
            )}
        />
    );

    return grid ? (
        <Grid item {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
