import React, { useState, useContext } from 'react';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import { AppContext, SttDialog, SttForm } from '../../../sporttia/all';

export default function ({ open = false, device, onClose }) {
    const cxt = useContext(AppContext);
    const [simulateResult, setSimulateResult] = useState();
    const [simulateDeviceResult, setSimulateDeviceResult] = useState();
    const [form, setForm] = useState({
        code: '1111',
        direction: 'IN',
        date: moment().format('YYYY-MM-DD'),
        time: moment().format('HH:mm'),
    });

    /**
     * Simulate pass
     */
    const simulatePass = () => {
        setSimulateResult(null);
        cxt.api('GET', `/devices/${device.id}/auth`, {
            params: {
                code: form.code,
                direction: form.direction,
                moment: `${moment(form.date).format('YYYY-MM-DD')}T${
                    form.time
                }:00`,
            },
            success: (r) => {
                setSimulateResult(r.access);
                setSimulateDeviceResult(r.device);
            },
        });
    };

    /**
     * Handle form
     */
    const handleForm = ({ name, value }) => {
        setForm({ ...form, [name]: value });
    };

    // -----| Render |-----
    return (
        <SttDialog
            title={cxt.t('SimulatePass')}
            open={open}
            onClose={onClose}
            content={
                <>
                    <SttForm
                        elements={[
                            {
                                md: 12,
                                type: 'textarea',
                                caption: cxt.t('Code'),
                                name: 'code',
                                value: form.code,
                            },
                            {
                                md: 4,
                                caption: cxt.t('Direction'),
                                name: 'direction',
                                type: 'select',
                                value: form.direction,
                                options: [
                                    {
                                        caption: cxt.t('DirectionIn'),
                                        value: 'IN',
                                    },
                                    {
                                        caption: cxt.t('DirectionOut'),
                                        value: 'OUT',
                                    },
                                ],
                            },
                            {
                                md: 5,
                                caption: cxt.t('Date'),
                                type: 'date',
                                name: 'date',
                                value: form.date,
                            },
                            {
                                md: 3,
                                caption: cxt.t('Time'),
                                type: 'time',
                                name: 'time',
                                value: form.time,
                            },
                        ]}
                        onChangeForm={handleForm}
                    />

                    {/* --- Result --- */}
                    {simulateResult && (
                        <Box mt={2}>
                            <Alert
                                severity={
                                    simulateResult.error ? 'error' : 'success'
                                }
                            >
                                {simulateResult.error
                                    ? simulateResult.error
                                    : 'OK'}
                            </Alert>
                        </Box>
                    )}

                    {simulateDeviceResult &&
                        simulateDeviceResult?.situation && (
                            <Box mt={2}>
                                <Alert
                                    severity={
                                        simulateDeviceResult?.situation
                                            ?.mainLightsOn
                                            ? 'success'
                                            : 'error'
                                    }
                                >
                                    {cxt.t('mainLights')}:{' '}
                                    {simulateDeviceResult?.situation
                                        ?.mainLightsOn
                                        ? 'ON'
                                        : 'OFF'}
                                </Alert>
                            </Box>
                        )}

                    {simulateDeviceResult &&
                        simulateDeviceResult?.situation && (
                            <Box mt={2}>
                                <Alert
                                    severity={
                                        simulateDeviceResult?.situation
                                            ?.cortesyLightsOn
                                            ? 'success'
                                            : 'error'
                                    }
                                >
                                    {cxt.t('cortesyLights')}:{' '}
                                    {simulateDeviceResult?.situation
                                        ?.cortesyLightsOn
                                        ? 'ON'
                                        : 'OFF'}
                                </Alert>
                            </Box>
                        )}
                </>
            }
            buttons={[
                {
                    type: 'accept',
                    onClick: simulatePass,
                },
            ]}
        />
    );
}
