import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import {
    Hidden,
    SwipeableDrawer,
    makeStyles,
    Drawer,
    Box,
    AppBar,
    Toolbar,
    List,
    Typography,
    ListItemIcon,
    ListItemText,
    ListItem,
    Divider,
    IconButton,
    Collapse,
    Avatar,
    Tooltip,
} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import {
    Menu as MenuIcon,
    ChevronLeft as ChevronLeftIcon,
    ExpandMore as ExpandMoreIcon,
    ExpandLess as ExpandLessIcon,
    MailOutline as MailOutlineIcon,
    Euro as EuroIcon,
    Apps as AppsIcon,
    PeopleAlt as PeopleAltIcon,
    Visibility,
} from '@material-ui/icons';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PeopleIcon from '@material-ui/icons/People';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import SchoolIcon from '@material-ui/icons/School';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SportsIcon from '@material-ui/icons/Sports';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PublicIcon from '@material-ui/icons/Public';
import GolfIcon from '@material-ui/icons/GolfCourse';
import { getPath } from '../../pages/Pages';
import SCHeaderAvatar from './SCHeaderAvatar';
import sporttiaSVG from '../../images/sporttia.svg';
import SalePoint from '../../pages/sc/salePoint/SalePoint';
import LinkWrapper from '../LinkWrapper';
import { AppContext } from '../../sporttia/all';
import CapacityMonitorDialog from '../../components/dialogs/CapacityMonitorDialog';
import translations from '../../translations';
import { useTranslations } from '../../lib/hooks';

// Style
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        backgroundColor: '#9eb319',
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    fullAppBar: {
        zIndex: theme.zIndex.drawer + 1,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    },
    menuButton: {
        marginRight: 24,
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
    },
    fixedDrawer: {
        height: '100%',
    },
    noFixedDrawer: {
        display: 'none',
    },
    submenu: {
        paddingLeft: theme.spacing(4),
    },
    sporttiaTitle: {
        fontSize: 25,
        width: '100%',
        marginLeft: 10,
        textAlign: 'left',
    },
}));

export default function SCHeader() {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openFixed, setOpenFixed] = React.useState(true);
    const [activeMenu, setActiveMenu] = useState(null);
    const [openedSalePoint, setOpenedSalePoint] = useState(false);
    const [openedCapacityMonitor, setOpenedCapacityMonitor] = useState(false);
    const { translate } = useTranslations();
    const location = useLocation();

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    // Find the parent menu of the submenu corresponding to the current url, and expand it (used when directly opening a url like /scs/facilities)
    useEffect(() => {
        if (location && location.pathname) {
            // Nothing to do at home page
            if (location.pathname === getPath('home')) {
                return;
            }

            // Find a parent menu which contains the submenu corresponding to this path
            const newActiveMenu = cxt.menus.sc
                .filter(
                    (menu) =>
                        (!menu.privilege ||
                            cxt.privileges?.includes(menu.privilege)) &&
                        menu.submenus,
                )
                .find(
                    (menu) =>
                        menu.submenus.find(
                            (submenu) =>
                                getPath(submenu.path) === location.pathname,
                        ) !== undefined,
                );

            // couldn't find it for some reason, abort
            if (!newActiveMenu) {
                return;
            }

            if (!activeMenu || newActiveMenu.id !== activeMenu.id) {
                setActiveMenu(newActiveMenu);
            }
        }
    }, [location]);

    /**
     * Go to the old page
     */
    const go = (menu) => {
        // 'url' means its an old url (should be deprecated now)
        if (menu.url) {
            history.push(menu.url);
        }

        // react-navigation path
        if (menu.path) {
            history.push(getPath(menu.path, menu.params));
            handleDrawerClose();
        }
    };

    /**
     * Select menu
     */
    const selectMenu = (menu) => {
        setActiveMenu(menu);
        if (menu.path) {
            go(menu);
        }
    };

    /**
     * Render menu
     */
    const renderMenu = (menu, idx) => {
        if (
            !menu.module ||
            (menu.module && cxt.sc.modules.includes(menu.module))
        ) {
            return (
                <div key={idx}>
                    <LinkWrapper
                        href={
                            menu.url ||
                            (menu.path && getPath(menu.path, menu.params))
                        }
                        callback={() => selectMenu(menu)}
                    >
                        <ListItem button>
                            <ListItemIcon>{getMenuIcon(menu)}</ListItemIcon>
                            <ListItemText primary={cxt.t(menu.caption)} />

                            {menu.submenus && (
                                <>
                                    {activeMenu && activeMenu === menu ? (
                                        <ExpandLessIcon />
                                    ) : (
                                        <ExpandMoreIcon />
                                    )}
                                </>
                            )}
                        </ListItem>
                    </LinkWrapper>
                    {activeMenu && menu.submenus && (
                        <Collapse
                            in={activeMenu === menu}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List component="div" disablePadding>
                                {activeMenu &&
                                    menu.submenus.map((sm, idx2) =>
                                        renderSubmenu(sm, idx2),
                                    )}
                            </List>
                        </Collapse>
                    )}
                </div>
            );
        }
    };

    /**
     * Render sub-menu
     */
    const renderSubmenu = (sm, idx) => {
        if (!sm.module || (sm.module && cxt.sc.modules.includes(sm.module))) {
            return (
                <LinkWrapper
                    key={idx}
                    href={sm.url || (sm.path && getPath(sm.path, sm.params))}
                    callback={() => go(sm)}
                >
                    <ListItem button className={classes.submenu}>
                        <ListItemText primary={cxt.t(sm.caption)} />
                    </ListItem>
                </LinkWrapper>
            );
        }
    };

    /**
     * Get menu icon
     */
    const getMenuIcon = (menu) => {
        switch (menu.id) {
            case 'home':
                return <AccountBalanceIcon />;
            case 'users':
                return <PeopleIcon />;
            case 'bookings':
                return <SportsIcon />;
            case 'events':
                return <EmojiEventsIcon />;
            case 'activities':
                return <SchoolIcon />;
            case 'accountability':
                return <AttachMoneyIcon />;
            case 'help':
                return <HelpOutlineIcon />;
            case 'scReports':
                return <ListAltIcon />;
            case 'golf':
                return <GolfIcon />;
            default:
                return null;
        }
    };

    let menus = [];
    if (cxt.menus) {
        menus = cxt.menus.sc.filter(
            (menu) =>
                !menu.privilege || cxt.privileges?.includes(menu.privilege),
        );
    }

    let submenus = null;
    if (activeMenu && activeMenu.submenus) {
        submenus = activeMenu.submenus.filter(
            (submenu) =>
                !submenu.privilege ||
                cxt.privileges?.includes(submenu.privilege),
        );
        activeMenu.submenus = submenus;
    }

    // Drawer
    const drawer = (
        <>
            <Box
                pl={2}
                className={classes.toolbarIcon}
                style={{
                    backgroundColor:
                        localStorage.getItem('environment') === 'PRE'
                            ? '#fbc02d'
                            : 'default',
                }}
            >
                <Box mr={1}>
                    <Avatar
                        style={{
                            backgroundColor: 'white',
                        }}
                    >
                        <img
                            style={{
                                width: 30,
                                height: 30,
                            }}
                            src={sporttiaSVG}
                            alt=""
                        />
                    </Avatar>
                </Box>
                <Typography noWrap component="h1" variant="h6" color="inherit">
                    <Link
                        onClick={() => history.push('/')}
                        style={{
                            color:
                                localStorage.getItem('environment') === 'PRE'
                                    ? 'white'
                                    : 'default',
                            textDecoration: 'none',
                        }}
                    >
                        SPORTTIA
                    </Link>
                </Typography>
                {openFixed && (
                    <Hidden smDown>
                        <Box pl={2}>
                            <IconButton
                                onClick={() => {
                                    setOpenFixed(false);
                                }}
                            >
                                <ChevronLeftIcon />
                            </IconButton>
                        </Box>
                    </Hidden>
                )}
                {open && (
                    <Hidden mdUp>
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Hidden>
                )}
            </Box>

            <Divider />

            <List>
                {menus.map(
                    (menu, idx) => cxt.sc.modules && renderMenu(menu, idx),
                )}
            </List>
        </>
    );

    const renderHeader = () => (
        <Box display="flex" alignItems="center">
            <Typography
                noWrap
                component="h1"
                variant="h6"
                color="inherit"
                className={classes.title}
            >
                {cxt.header && cxt.header.title}
            </Typography>
        </Box>
    );

    // -----| Render |-----
    return (
        <>
            <AppBar
                position="absolute"
                className={openFixed ? classes.appBar : classes.fullAppBar}
            >
                <Toolbar
                    className={classes.toolbar}
                    style={{
                        backgroundColor:
                            localStorage.getItem('environment') === 'PRE'
                                ? '#fbc02d'
                                : 'default',
                    }}
                >
                    <Box flex={1}>
                        <Box display="flex">
                            <Hidden smDown>
                                {!openFixed && (
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={() => {
                                            setOpenFixed(true);
                                        }}
                                        className={classes.menuButton}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                )}
                            </Hidden>
                            <Hidden mdUp>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleDrawerOpen}
                                    className={classes.menuButton}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Hidden>
                            <Hidden xsDown>{renderHeader()}</Hidden>
                            <Box
                                flex={1}
                                display="flex"
                                justifyContent="flex-end"
                            >
                                {cxt.sc &&
                                    cxt.sc.modules &&
                                    cxt.sc.modules.includes('salePoint') && (
                                        <Tooltip
                                            title={cxt.t('PointOfSale')}
                                            arrow
                                        >
                                            <IconButton
                                                color="inherit"
                                                className="float-right"
                                                onClick={() =>
                                                    setOpenedSalePoint(true)
                                                }
                                            >
                                                {cxt.sc?.city?.country
                                                    ?.currency &&
                                                cxt.sc?.city?.country
                                                    ?.currency !== 'EUR' ? (
                                                    cxt.sc?.city?.country
                                                        ?.currency
                                                ) : (
                                                    <EuroIcon />
                                                )}
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                {cxt.sc &&
                                    cxt.sc.modules &&
                                    cxt.sc.modules.includes('device') && (
                                        <Tooltip
                                            title={translate(
                                                translations.deviceSpace
                                                    .devicesCapacityMonitor,
                                            )}
                                            arrow
                                        >
                                            <IconButton
                                                color="inherit"
                                                className="float-right"
                                                onClick={() =>
                                                    setOpenedCapacityMonitor(
                                                        true,
                                                    )
                                                }
                                            >
                                                {cxt.sc?.city?.country
                                                    ?.currency &&
                                                cxt.sc?.city?.country
                                                    ?.currency !== 'EUR' ? (
                                                    cxt.sc?.city?.country
                                                        ?.currency
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                <Tooltip title={cxt.t('Timetable')} arrow>
                                    <IconButton
                                        color="inherit"
                                        className="float-right"
                                        onClick={() =>
                                            go({
                                                url: getPath('timetable', {
                                                    id: cxt.sc.id,
                                                }),
                                            })
                                        }
                                    >
                                        <AppsIcon />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title={cxt.t('Users')} arrow>
                                    <IconButton
                                        color="inherit"
                                        className="float-right"
                                        onClick={() =>
                                            history.push(getPath('mships'))
                                        }
                                    >
                                        <PeopleAltIcon />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title={cxt.t('Messages')} arrow>
                                    <IconButton
                                        color="inherit"
                                        className="float-right"
                                        onClick={() =>
                                            go({ path: 'scMessages' })
                                        }
                                    >
                                        <MailOutlineIcon />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title={cxt?.sc?.name || ''} arrow>
                                    <IconButton
                                        color="inherit"
                                        className="float-right"
                                        onClick={() =>
                                            go({
                                                url: getPath('scProfile', {
                                                    id: cxt.sc.id,
                                                }),
                                            })
                                        }
                                    >
                                        <PublicIcon />
                                    </IconButton>
                                </Tooltip>

                                <SCHeaderAvatar />
                            </Box>
                        </Box>
                        <Hidden smUp>{renderHeader()}</Hidden>
                    </Box>
                </Toolbar>
            </AppBar>
            <Hidden mdUp>
                <SwipeableDrawer
                    variant="temporary"
                    anchor="left"
                    open={open}
                    onOpen={handleDrawerOpen}
                    onClose={handleDrawerClose}
                    classes={{
                        paper: classes.drawer,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </SwipeableDrawer>
            </Hidden>
            <Hidden smDown>
                <Drawer
                    variant="permanent"
                    className={
                        openFixed ? classes.fixedDrawer : classes.noFixedDrawer
                    }
                    classes={{
                        paper: clsx(classes.drawerPaper),
                    }}
                >
                    {drawer}
                </Drawer>
            </Hidden>

            {openedSalePoint && (
                <SalePoint
                    open={openedSalePoint}
                    idTicket="create"
                    onClose={() => setOpenedSalePoint()}
                />
            )}
            {openedCapacityMonitor && (
                <CapacityMonitorDialog
                    onClose={() => setOpenedCapacityMonitor(false)}
                />
            )}
        </>
    );
}
