import React, { useState, useContext } from 'react';
import {
    Box,
    Grid,
    Card,
    CardHeader,
    CardContent,
    Chip,
    Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AppContext, SttButton, SttQR } from '../../../sporttia/all';
import { formatPriceByLocale, fullName } from '../../../lib/utils';
import constants from '../../../config/constants';
import translations from '../../../translations';

export default function Occupants({ occupants, cancelPrivilege, idOwner }) {
    const cxt = useContext(AppContext);
    const [occupantsValues, setOccupantsValues] = useState(occupants);

    const history = useHistory();

    function deleteOccupant(occupantId) {
        cxt.api('DELETE', `/bookings/occupants/${occupantId}`, {
            params: {
                cancelBookingOnLastOccupant: true,
            },
            success: () => {
                cxt.showMessage('S', cxt.t(translations.generic.deleted));
                const newOccupantsValue = occupantsValues.filter(
                    (occupant) => occupant.id !== occupantId,
                );
                setOccupantsValues(newOccupantsValue);
                if (newOccupantsValue.length === 0) {
                    history.goBack();
                }
            },
            confirmation: true,
        });
    }

    return (
        <Box mt={2}>
            <Grid container spacing={3}>
                {occupantsValues?.map((occupant) => (
                    <Grid key={occupant.id} item md={12} sm={12} xs={12}>
                        <Card>
                            <CardHeader title={fullName(occupant.user)} />
                            <CardContent>
                                {/* --- Rate & price --- */}
                                <Box>
                                    {occupant.rate?.map((price) => (
                                        <Chip
                                            key={price.id}
                                            label={`${
                                                price.name
                                            } [ ${formatPriceByLocale(
                                                price.price,
                                                price.priceShape?.currency,
                                                price.priceShape?.locale,
                                            )} ]`}
                                        />
                                    ))}
                                </Box>
                                {occupant.bonoMov?.id && (
                                    <Box>
                                        <Chip
                                            key={occupant?.bonoMov?.id}
                                            label={`${occupant?.bonoMov?.boleto?.bono?.name}`}
                                        />
                                    </Box>
                                )}
                                {occupant.unlockers?.[0]?.type ===
                                    constants.unlocker.types.QR && (
                                    <Box mt={1} align="center">
                                        <SttQR
                                            unlocker={occupant.unlockers[0]}
                                        />
                                    </Box>
                                )}

                                {occupant.unlockers?.[0]?.type ===
                                    constants.unlocker.types.PIN && (
                                    <Box mt={1}>
                                        <Typography variant="body1">
                                            PIN: {occupant.unlockers[0].code}
                                        </Typography>
                                        <Box mt={1}>
                                            <Typography variant="body2">
                                                * {cxt.t('RememberToPressHash')}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}

                                {occupant.unlockers?.[1]?.type ===
                                    constants.unlocker.types.QR && (
                                    <Box mt={1} align="center">
                                        <SttQR
                                            unlocker={occupant.unlockers[1]}
                                        />
                                    </Box>
                                )}

                                {occupant.unlockers?.[1]?.type ===
                                    constants.unlocker.types.PIN && (
                                    <Box mt={1}>
                                        <Typography variant="body1">
                                            PIN: {occupant.unlockers[1].code}
                                        </Typography>
                                        <Box mt={1}>
                                            <Typography variant="body2">
                                                * {cxt.t('RememberToPressHash')}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                                {cxt.logged &&
                                    (cxt.user.role ===
                                        constants.roles.sportcenter ||
                                        (idOwner === cxt.user.id &&
                                            cancelPrivilege)) && (
                                        <Box align="right">
                                            <SttButton
                                                caption={cxt.t('CancelTicket')}
                                                onClick={() =>
                                                    deleteOccupant(occupant.id)
                                                }
                                            />
                                        </Box>
                                    )}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}
