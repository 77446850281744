import React, { useState, useEffect, useContext } from 'react';
import { Box, Paper } from '@material-ui/core';
import { AppContext } from '../../../sporttia/all';
import { useCrud } from '../../../lib/hooks';
import ConsentDialog from '../../../components/dialogs/ConsentDialog';
import { SttConsentList } from '../../../sporttia/lists/SttConsentList';

export default function ConfigurationConsents() {
    const cxt = useContext(AppContext);
    const [consents, setConsents, , Get, Post, Put, Delete] = useCrud();
    const [viewConsent, setViewConsent] = useState(null);

    /**
     * Init
     */
    useEffect(() => {
        loadConsents();
    }, []);

    /**
     * Load constents
     */
    function loadConsents(params = { rows: 20 }) {
        Get(`/scs/${cxt.sc.id}/consents/templates`, {
            ...params,
        }).then((response) => {
            setConsents(response.rows);
        });
    }

    /**
     * Save constent
     */
    function saveConsent(consent) {
        const { name, text, textShort, forBooking } = consent;
        if (consent.id) {
            Put(`/consents/templates/${consent.id}`, {
                name,
                text,
                textShort,
                forBooking,
            })
                .then(({ consentTemplate }) => {
                    setConsents(
                        consents.map((csnt) =>
                            csnt.id === consent.id ? consent : csnt,
                        ),
                    );
                })
                .finally(() => {
                    setViewConsent(null);
                });
        } else {
            Post(`/scs/${cxt.sc.id}/consents/templates`, {
                name,
                text,
                textShort,
                forBooking,
            })
                .then(({ consentTemplate }) => {
                    setConsents((consents || []).concat([consentTemplate]));
                })
                .finally(() => {
                    setViewConsent(null);
                });
        }
    }

    /**
     * Delete consent
     */
    function deleteConsent(consent) {
        if (consent.id) {
            Delete(`/consents/templates/${consent.id}`)
                .then(({ consentTemplate }) => {
                    setConsents(
                        consents.filter(
                            (csnt) => csnt.id !== consentTemplate.id,
                        ),
                    );
                })
                .finally(() => {
                    setViewConsent(null);
                });
        }
    }

    // Render
    return (
        <Box display="flex" justifyContent="center">
            <Box flex={1}>
                <SttConsentList
                    consents={consents}
                    onAdd={() =>
                        setViewConsent({
                            name: '',
                            text: '',
                            textShort: '',
                            forBooking: false,
                        })
                    }
                    onSelect={(consent) => setViewConsent(consent)}
                    onDelete={(consent) => deleteConsent(consent)}
                />
            </Box>
            <ConsentDialog
                item={viewConsent}
                onClose={() => setViewConsent(null)}
                onSave={saveConsent}
            />
        </Box>
    );
}
