import React, { useContext } from 'react';
import { Chip } from '@material-ui/core';
import { AppContext } from '../AppContext';

/**
 * Modules
 */
export function SttChipModule({ module }) {
    const cxt = useContext(AppContext);

    const color = {
        RENTING: '#ff5353',
        TOURNAMENT: '#19cc3a',
        EVENT: '#19cc3a',
        GAME: '#4768fd',
        CLASS: '#fd66bc',
        SALE_POINT: '#8a6d3b',
        PURSE: '#3186a7',
        ABONADOS: '#8a3930',
        BONO: '#269c97',
    };

    return (
        <>
            {module && (
                <Chip
                    size="small"
                    label={cxt.t(`M.${module}`)}
                    style={{ backgroundColor: color[module], color: 'white' }}
                />
            )}
        </>
    );
}
