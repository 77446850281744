import React, { useState, useEffect, useContext } from 'react';
import { Paper } from '@material-ui/core';
import moment from 'moment';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import GroupIcon from '@material-ui/icons/Group';
import { useHistory } from 'react-router-dom';
import {
    AppContext,
    SttChipFacilityRow,
    SttChipUnlocker,
    SttTable,
    SttTopControls,
} from '../../../sporttia/all';
import { useToggle, useTuples } from '../../../lib/hooks';
import { DevicesKeysDialog } from './DevicesKeysDialog';
import DeviceDetailsDialog from './DeviceDetailsDialog';
import { getPath } from '../../Pages';

export default function Devices() {
    const cxt = useContext(AppContext);

    const [devices, setDevices] = useState(null);
    const [filters, setFilter] = useTuples({
        name: '',
    });
    const history = useHistory();

    // Trash hook
    const [trash, toggleTrash] = useToggle(() => {
        loadDevices();
    });
    // Keys dialog hook
    const [openKeysDialog, setOpenKeysDialog] = useState(false);
    const [deviceIdSelected, setDeviceIdSelected] = useState(null);

    useEffect(() => {
        cxt.setHeader({ title: cxt.t('Devices') });
    }, [deviceIdSelected]);

    /**
     * Load devices
     */
    const loadDevices = (p = { page: 1, rows: 20 }) => {
        cxt.api('GET', `/scs/${cxt.sc.id}/devices`, {
            params: {
                page: p.page,
                rows: p.rows,
                ...filters,
                trash,
            },
            success: (r) => {
                setDevices(r);
            },
        });
    };

    // Render
    return (
        <Paper>
            <SttTopControls
                p={2}
                fields={[
                    {
                        caption: cxt.t('Name'),
                        type: 'text',
                        name: 'name',
                        value: filters.name,
                        onEnterKey: loadDevices,
                    },
                ]}
                onToggleTrash={toggleTrash}
                onChange={({ name, value }) => setFilter({ name, value })}
                iconButtons={[
                    {
                        icon: <VpnKeyIcon />,
                        onClick: () => setOpenKeysDialog(true),
                    },
                    {
                        icon: <GroupIcon />,
                        onClick: () => history.push(getPath('devicesSpaces')),
                    },
                ]}
                onFilter={loadDevices}
            />
            <SttTable
                onFetch={loadDevices}
                data={devices}
                columns={[
                    {
                        title: cxt.t('Code'),
                        type: 'id',
                        field: 'id',
                    },
                    {
                        align: 'left',
                        title: cxt.t('Name'),
                        type: 'link',
                        field: 'name',
                        onClick: (row) => setDeviceIdSelected(row.id),
                    },
                    {
                        title: cxt.t('Type'),
                        // This 'row' is not an Unlocker, it's a Device, but they 'type' is the same so yeah
                        value: (row) => (
                            <SttChipUnlocker unlocker={{ type: row.type }} />
                        ),
                    },
                    {
                        title: cxt.t('Lights'),
                        type: 'active',
                        field: 'lightOn',
                    },
                    {
                        title: 'APB',
                        type: 'active',
                        field: 'antiPassback',
                    },
                    {
                        title: cxt.t('Open'),
                        type: 'active',
                        field: 'foorOpen',
                    },
                    {
                        title: 'RO',
                        type: 'active',
                        field: 'remoteOpening',
                    },
                    {
                        title: cxt.t('Counter'),
                        type: 'text',
                        field: 'counter',
                    },
                    {
                        title: cxt.t('Date'),
                        value: (row) =>
                            row.localDate
                                ? moment(row.localDate).format('D MMM YYYY')
                                : '',
                    },
                    {
                        title: cxt.t('Facilities'),
                        value: (row) => (
                            <SttChipFacilityRow facilities={row.facilities} />
                        ),
                    },
                ]}
            />

            <DevicesKeysDialog
                openStatus={openKeysDialog}
                onClose={() => setOpenKeysDialog(false)}
            />

            <DeviceDetailsDialog
                scId={cxt.sc.id}
                deviceId={deviceIdSelected}
                onClose={() => {
                    setDeviceIdSelected(null);
                    loadDevices();
                }}
            />
        </Paper>
    );
}
