import { DateTime } from 'luxon';
import constants from '../config/constants';

const defaultTimezone = 'Europe/Madrid';
const defaultLocale = 'es';
/**
 * Clase para gestionar fechas. Internamente, hace uso de "luxon".
 */
export default class DateBuilder {
    private date!: DateTime;

    private timestamp!: number;

    private timezone!: string;

    private locale!: string;

    /**
     * Constructor para generar un objeto de fecha.
     */
    constructor(
        date: string | DateBuilder | Date | null = null,
        timezone: string | null = null,
        locale: string | null = null,
    ) {
        this.timezone = timezone ?? defaultTimezone;
        this.locale = locale ?? defaultLocale;
        this.set(date);
    }

    /**
     * Setea la fecha y actualiza el timestamp. Si se pasa como parámetro null la fecha se seteara
     * a la actual.
     */
    private set = (date: string | DateBuilder | Date | null) => {
        if (
            date !== null &&
            typeof date !== 'string' &&
            !(date instanceof DateBuilder) &&
            !(date instanceof DateTime) &&
            !(date instanceof Date)
        ) {
            throw new Error(
                `El parámetro "date" debe ser una cadena, un objeto Date, DateLib o DateTime (Luxon). Se recibió ${typeof date} en su lugar`,
            );
        }

        if (
            typeof date === 'string' &&
            !constants.regexp.datetime.test(date) &&
            !DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss').isValid &&
            !constants.regexp.date.test(date) &&
            !DateTime.fromFormat(date, 'yyyy-MM-dd').isValid &&
            !constants.regexp.datetimeISO8601.test(date) &&
            !DateTime.fromISO(date).isValid
        ) {
            throw new Error(
                `El parámetro "date" no tiene un formato (yyyy-MM-ddTHH:mm:ss, yyyy-MM-dd HH:mm:ss o yyyy-MM-dd) valido: ${date}`,
            );
        }

        if (date instanceof DateBuilder) {
            this.date = date.date;
            this.timestamp = date.timestamp;
        } else if (date instanceof DateTime) {
            this.date = date;
            this.timestamp = this.date.toMillis();
        } else if (date instanceof Date) {
            this.date = DateTime.fromJSDate(date);
            this.timestamp = this.date.toMillis();
        } else {
            if (
                typeof date === 'string' &&
                constants.regexp.datetime.test(date)
            ) {
                this.date = DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss');
            } else if (
                typeof date === 'string' &&
                constants.regexp.date.test(date)
            ) {
                this.date = DateTime.fromFormat(date, 'yyyy-MM-dd');
            } else if (
                typeof date === 'string' &&
                constants.regexp.datetimeISO8601.test(date)
            ) {
                this.date = DateTime.fromISO(date);
            } else {
                this.date = DateTime.now();
            }

            this.timestamp = this.date.toMillis();
        }
        this.date = this.date.setLocale(this.locale);
        this.date = this.date.setZone(this.timezone);
        return this;
    };

    /**
     * Setea la hora de la fecha.
     */
    setTime = (time: string) => {
        let newDate = null;

        if (time.match(/^[0-9]{1,2}:[0-9]{2}:[0-9]{2}$/)) {
            newDate = new DateBuilder(`${this.ymd()} ${time}`);
        } else if (time.match(/^[0-9]{1,2}:[0-9]{2}$/)) {
            newDate = new DateBuilder(`${this.ymd()} ${time}:${this.s()}`);
        } else {
            throw new Error(
                'Formato de hora inválido en setTime. Se acepta [H]H:mm:ss y [H]H:mm',
            );
        }

        this.set(newDate);

        return this;
    };

    /**
     * Setea la hora de la fecha a 00:00:00.
     */
    setZeroTime = () => {
        const newDate = new DateBuilder(`${this.ymd()} 00:00:00`);
        this.set(newDate);
        return this;
    };

    /**
     * Setea la hora de la fecha a 23:59:59.
     */
    setLastTimeOfDay = () => {
        const newDate = new DateBuilder(`${this.ymd()} 23:59:59`);
        this.set(newDate);
        return this;
    };

    /**
     * Método para formatear una fecha y devolver un string con ella. Por defecto el formato es el
     * dado por luxon.
     */
    private format = (format = '') => {
        switch (format.toUpperCase()) {
            case 'YMDHMS':
                return this.date.toFormat('yyyy-MM-dd HH:mm:ss');
            case 'YMDHM':
                return this.date.toFormat('yyyy-MM-dd HH:mm');
            case 'YMD':
                return this.date.toFormat('yyyy-MM-dd');
            case 'YM':
                return this.date.toFormat('yyyy-MM');
            case 'HMS':
                return this.date.toFormat('HH:mm:ss');
            case 'HM':
                return this.date.toFormat('HH:mm');
            case 'H':
                return this.date.toFormat('H');
            case 'S':
                return this.date.toFormat('ss');
            case 'm':
                return this.date.toFormat('mm');
            case 'D':
                return this.date.toFormat('dd');
            case 'Y':
                return this.date.toFormat('yyyy');
            case 'M':
                return this.date.toFormat('M');
            case 'E':
                return this.date.toFormat('E');
            case 'ISO':
                return this.date.toISO()!;
            case 'DMY':
                return this.date.toFormat('dd-MM-yyyy');
            case 'DMYHM':
                return this.date.toFormat('dd-MM-yyyy HH:mm');
            case 'CDLHM':
                return this.date.toFormat('cccc, dd LLLL HH:mm');
            case 'DEFAULT':
                return `${this.date.toFormat(
                    'yyyy-MM-dd',
                )}T${this.date.toFormat('HH:mm:ss')}`;
            default:
                return this.date.toISO()!;
        }
    };

    /**
     * Devuelve la fecha con el formato "YMDHMS".
     */
    toISO = () => this.format('ISO');

    /**
     * Devuelve la fecha con el formato "YMDHMSTHH:mm:ss".
     */
    toDefaultFormat = () => this.format('DEFAULT');

    /**
     * Devuelve la fecha con el formato "YMDHMS".
     */
    ymdhms = () => this.format('YMDHMS');

    /**
     * Devuelve la fecha con el formato "YMDHM".
     */
    ymdhm = () => this.format('YMDHM');

    /**
     * Devuelve la fecha con el formato "HMS".
     */
    hms = () => this.format('HMS');

    /**
     * Devuelve la fecha con el formato "HM".
     */
    hm = () => this.format('HM');

    /**
     * Devuelve la fecha con el formato "S".
     */
    s = () => this.format('S');

    /**
     * Devuelve la fecha con el formato "YMD".
     */
    ymd = () => this.format('YMD');

    /**
     * Devuelve la fecha con el formato "YM".
     */
    ym = () => this.format('YM');

    /**
     * Devuelve la fecha con el formato "DMY".
     */
    dmy = () => this.format('DMY');

    /**
     * Devuelve la fecha con el formato "DMYHM"
     */
    dmyhm = () => this.format('DMYHM');

    /**
     * Devuelve la fecha con el formato "CDLHM"
     */
    cdlhm = () => this.format('CDLHM');

    /**
     * Devuelve la hora de una fecha.
     */
    hour = () => parseInt(this.format('H'), 10);

    /**
     * Devuelve los minutos de una fecha
     */
    minute = () => parseInt(this.format('m'), 10);

    /**
     * Devuelve el día de una fecha.
     */
    day = () => parseInt(this.format('D'), 10);

    /**
     * Devuelve el mes de una fecha (1=Enero y 12=Diciembre).
     */
    month = () => parseInt(this.format('M'), 10);

    /**
     * Devuelve el año de una fecha.
     */
    year = () => parseInt(this.format('Y'), 10);

    /**
     * Devuelve el día de la semana de una fecha (1=Lunes y 7=Domingo).
     */
    weekday = () => parseInt(this.format('E'), 10);

    /**
     * Devuelve el ultimo día del mes dado por la fecha.
     */
    getLastDayMonth = () =>
        parseInt(this.date.endOf('month').toFormat('d'), 10);

    /**
     * Compara dos fechas usando el timestamp.
     */
    isEqual = (date: DateBuilder) => this.timestamp === date.timestamp;

    /**
     * Compara dos fechas usando el timestamp.
     */
    isLessThan = (date: DateBuilder) => this.timestamp < date.timestamp;

    /**
     * Compara dos fechas usando el timestamp.
     */
    isLessOrEqualThan = (date: DateBuilder) => this.timestamp <= date.timestamp;

    /**
     * Compara dos fechas usando el timestamp.
     */
    isGreaterThan = (date: DateBuilder) => this.timestamp > date.timestamp;

    /**
     * Compara dos fechas usando el timestamp.
     */
    isGreaterOrEqualThan = (date: DateBuilder) =>
        this.timestamp >= date.timestamp;

    /**
     * Compara dos fechas usando solo la hora.
     */
    timeIsLessThan = (date: DateBuilder) => {
        const firstDate = new DateBuilder(`2022-01-01 ${this.hms()}`);
        const secondDate = new DateBuilder(`2022-01-01 ${date.hms()}`);

        return firstDate.isLessThan(secondDate);
    };

    /**
     * Compara dos fechas usando solo la hora.
     */
    timeIsLessOrEqualThan = (date: DateBuilder) => {
        const firstDate = new DateBuilder(`2022-01-01 ${this.hms()}`);
        const secondDate = new DateBuilder(`2022-01-01 ${date.hms()}`);

        return firstDate.isLessOrEqualThan(secondDate);
    };

    /**
     * Compara dos fechas usando solo la hora.
     */
    timeIsGreaterThan = (date: DateBuilder) => {
        const firstDate = new DateBuilder(`2022-01-01 ${this.hms()}`);
        const secondDate = new DateBuilder(`2022-01-01 ${date.hms()}`);

        return firstDate.isGreaterThan(secondDate);
    };

    /**
     * Compara dos fechas usando solo la hora.
     */
    timeIsGreaterOrEqualThan = (date: DateBuilder) => {
        const firstDate = new DateBuilder(`2022-01-01 ${this.hms()}`);
        const secondDate = new DateBuilder(`2022-01-01 ${date.hms()}`);

        return firstDate.isGreaterOrEqualThan(secondDate);
    };

    /**
     * Obtener entre dos fechas la fecha mas pequeña.
     */
    min = (date: DateBuilder) => {
        if (this.isLessThan(date)) {
            return this;
        }

        return date;
    };

    /**
     * Obtener entre dos fechas la fecha mas grande.
     */
    max = (date: DateBuilder) => {
        if (this.isGreaterThan(date)) {
            return this;
        }

        return date;
    };

    /**
     * Setea la zona horaria de la fecha.
     */
    setTimeZone = (timezone: string) => {
        this.date = this.date.setZone(timezone);
        return this;
    };

    toUTC = () => this.date.toUTC();

    /**
     * Obtener una fecha formateada como string. Devuelve el formato:
     * "YYYY-MM-DD HH:mm:ss"
     */
    toString = () => this.ymdhms();
}
