import React, { useContext, useState } from 'react';
import {
    TextField,
    Grid,
    GridProps,
    InputAdornment,
    IconButton,
} from '@material-ui/core';
import { Clear as ClearIcon, Search as SearchIcon } from '@material-ui/icons';
import { AppContext } from '../all';
import translations from '../../translations';
import Country from '../../types/models/Country';
import { SttSelectCountryDialog } from '../dialogs/SttSelectCountryDialog';

type SttSelectCountryProps = {
    grid?: boolean;
    caption: string;
    name: string;
    defVal:
        | {
              id: number;
              name: string;
          }
        | null
        | undefined;
    value: {
        id: number;
        name: string;
    };
    onChange: ({
        name,
        value,
    }: {
        name: string;
        value: Country | null;
    }) => void;
    onlyActive?: boolean;
} & Omit<GridProps, 'onChange'>;

export function SttSelectCountry({
    grid = false,
    caption,
    name,
    defVal,
    value,
    onChange,
    md = 3,
    xs = 12,
    ...rest
}: SttSelectCountryProps) {
    const cxt = useContext(AppContext)!;

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const anyCountry = {
        id: 0,
        name: cxt.t(translations.country.anyCountry),
    };

    const defaultValue = defVal ?? anyCountry;

    const dialog = (
        <SttSelectCountryDialog
            open
            onClose={() => {
                setIsDialogOpen(false);
            }}
            onSelect={(country: Country) => {
                setIsDialogOpen(false);
                onChange({ name, value: country });
            }}
        />
    );

    const content = (
        <TextField
            fullWidth
            size="small"
            label={caption}
            variant="outlined"
            value={value?.name || defaultValue.name}
            InputProps={{
                readOnly: true,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => {
                                onChange({
                                    name,
                                    value: anyCountry,
                                });
                            }}
                        >
                            <ClearIcon />
                        </IconButton>

                        <IconButton
                            onClick={() => {
                                setIsDialogOpen(true);
                            }}
                        >
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );

    return grid ? (
        <Grid item {...rest} md={md} xs={xs}>
            {isDialogOpen && dialog}
            {content}
        </Grid>
    ) : (
        <div>
            {isDialogOpen && dialog}
            {content}
        </div>
    );
}
