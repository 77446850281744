import React, { useState, Fragment, ReactNode } from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    tab: {
        maxWidth: (props: { maxWidth: number | string }) => props.maxWidth,
        minWidth: 120,
        '&:focus': {
            outline: 'none',
        },
    },
    fullTab: {
        flex: 1,
        minWidth: 120,
        maxWidth: 'none',
        '&:focus': {
            outline: 'none',
        },
    },
    tabContent: {
        marginBottom: theme.spacing(3),
        padding: theme.spacing(3),
    },
}));

type TabList = {
    caption: string;
    component: ReactNode;
    show?: boolean;
    icon?: JSX.Element;
};

type SttTabsProps = {
    tabs: TabList[];
    fullWidth?: boolean;
    grow?: boolean;
};

/**
 *	El contenido de las pestañas se cargan cuando se muestra la primera vez, una vez cargada ya no se vuelve a cargar.
 */
export function SttTabs({ tabs, fullWidth, grow }: SttTabsProps) {
    const classes = useStyles({
        maxWidth: grow ? 'auto' : 180,
    });
    const [currTabIndex, setCurrTabIndex] = useState(0);
    const [loadedTabIndexes, setLoadedTabIndexes] = useState<number[]>([0]);

    const handleSelectTab = (tabIndex: number) => {
        setCurrTabIndex(tabIndex);

        if (!loadedTabIndexes.includes(tabIndex)) {
            setLoadedTabIndexes([...loadedTabIndexes, tabIndex]);
        }
    };

    return (
        <>
            <Tabs
                value={currTabIndex}
                textColor="primary"
                onChange={(ev, val: string) => handleSelectTab(Number(val))}
                variant="scrollable"
                scrollButtons="auto"
            >
                {tabs
                    ?.filter((tab) => tab.show ?? true)
                    .map((tab, i) => (
                        <Tab
                            key={tab.caption}
                            id={`tab${i}`}
                            icon={tab.icon}
                            label={tab.caption}
                            className={
                                fullWidth ? classes.fullTab : classes.tab
                            }
                        />
                    ))}
            </Tabs>

            {tabs
                ?.filter((tab) => tab.show ?? true)
                .map((tab, idx) =>
                    loadedTabIndexes.includes(idx) ? (
                        <Paper
                            key={tab.caption}
                            className={classes.tabContent}
                            style={{
                                display:
                                    currTabIndex !== idx ? 'none' : 'block',
                            }}
                        >
                            {tab.component}
                        </Paper>
                    ) : null,
                )}
        </>
    );
}
