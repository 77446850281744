import React, { useRef } from 'react';
import { Box, CircularProgress, Divider } from '@material-ui/core';
import { useInfiniteQuery } from 'react-query';
import { useOnViewport, useTranslations } from '../../../lib/hooks';
import useSearchService from '../../../services/SearchService';
import { USER_SEARCH_EVENTS } from '../../../lib/queryKeys';
import { SttEventCard } from './SttEventCard';

/**
 * List of activities cards. Use the infinite scroll.
 * @param params Parameters that are sent to the API.
 * @returns {JSX.Element}
 */
export function SttEventCardList({ params = {} }) {
    const ROWS_PER_PAGE = 15;
    const { translate } = useTranslations();
    const searchService = useSearchService();
    const ref = useRef();
    const { data, status, isFetchingNextPage, fetchNextPage, hasNextPage } =
        useInfiniteQuery(
            [
                USER_SEARCH_EVENTS,
                {
                    ...params,
                },
            ],
            ({ pageParam = 1 }) =>
                searchService
                    .getList({
                        page: pageParam,
                        rows: ROWS_PER_PAGE,
                        kind: 2,
                        ...params,
                    })
                    .then((response) => {
                        response.events.page = pageParam;
                        return response;
                    }),
            {
                getNextPageParam: (lastPage) =>
                    lastPage?.events?.page < lastPage?.events?.pages
                        ? lastPage?.events?.page + 1
                        : undefined,
            },
        );
    useOnViewport(ref, '-100px', fetchNextPage);

    return (
        <>
            {status === 'loading' ? (
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            ) : status === 'error' ? (
                <>Error</>
            ) : (
                <>
                    <Box my={3} align="right">
                        <b>{data?.pages[0]?.events?.count}</b>{' '}
                        {translate('events')}
                    </Box>
                    {data.pages.map((page, i) => (
                        <React.Fragment key={i}>
                            {page.events.rows.map((event, eventKey) => (
                                <Box key={eventKey}>
                                    {eventKey > 0 && (
                                        <Box mt={1} mb={1}>
                                            <Divider />
                                        </Box>
                                    )}
                                    <SttEventCard event={event} />
                                </Box>
                            ))}
                        </React.Fragment>
                    ))}
                    {isFetchingNextPage && (
                        <Box display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    )}
                    {!hasNextPage && (
                        <Box display="flex" justifyContent="center">
                            No hay más resultados.
                        </Box>
                    )}
                </>
            )}
            {/* Este div es para detectar el final de la página, cuando sea visible se van a pedir datos a la API para realizar el scroll infinito. */}
            <div ref={ref} />
        </>
    );
}
