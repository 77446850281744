import React, { useState, useEffect, useContext } from 'react';
import { Box, TextField } from '@material-ui/core';
import { Search, Add } from '@material-ui/icons';
import {
    useEnvironment,
    usePOSConfig,
    useTranslations,
} from '../../../lib/hooks';
import { AppContext, SttButton, SttDialog } from '../../../sporttia/all';
import { requestAdHoc } from '../../../lib/utils';

export default function ConfigurationPosDialog({ open, onClose, onAdd }) {
    const environment = useEnvironment();
    const port = environment === 'DEV' ? 3001 : 3011;
    const { translate } = useTranslations();
    const cxt = useContext(AppContext);

    const [ip, setIp] = useState('');
    // const [ipsFound, setIpsFound] = useState([]);
    // const [searching, setSearching] = useState(false);
    const [forceInputFocus, setForceInputFocus] = useState(false);
    const [testing, setTesting] = useState(false);
    const [tested, setTested] = useState(false);

    // Config local del datáfono
    const [POSConfig, setPOSConfig] = useState(null);

    // Esto es una función que hay que llamar con la IP del dispositivo
    const setupPOSConfig = usePOSConfig('PAYCOMET');

    useEffect(() => {
        setForceInputFocus(false);
    }, [open]);

    useEffect(() => {
        if (ip !== '') {
            setForceInputFocus(true);
        }
        setTested(false);
        setPOSConfig(setupPOSConfig(ip));
    }, [ip]);

    useEffect(() => {
        setIp('');
    }, [open]);

    function addDevice() {
        if (!tested) {
            testDevice()
                .then((result) => {
                    if (result) {
                        onAdd && onAdd(ip);
                    } else {
                        cxt.showMessage(
                            'E',
                            cxt.t('config.pos.deviceTestNotFound'),
                        );
                    }
                })
                .catch((error) => {
                    cxt.showMessage('E', error);
                });
        } else {
            onAdd && onAdd(ip);
        }
    }

    function testDevice() {
        return new Promise((resolve, reject) => {
            if (ip) {
                if (
                    /* ip.match(/\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/) && */ POSConfig
                ) {
                    setTesting(true);

                    requestAdHoc(
                        POSConfig.init.method,
                        POSConfig.init.uri,
                        POSConfig.init.params(),
                    )
                        .then((response) => {
                            if (parseInt(response.resultCode) === 1000) {
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        })
                        .catch((error) => {
                            reject(cxt.t('config.pos.deviceTestError'));
                        })
                        .finally(() => {
                            setTesting(false);
                        });
                } else {
                    reject(cxt.t('config.pos.deviceTestInvalidIP'));
                }
            } else {
                reject(cxt.t('config.pos.deviceTestEmptyIP'));
            }
        });
    }

    return (
        <SttDialog
            title={cxt.t('config.pos.addDeviceTitle')}
            open={open}
            onClose={onClose}
            content={
                <div>
                    <Box display="flex" alignItems="flex-start">
                        <TextField
                            fullWidth
                            type="text"
                            variant="outlined"
                            label={cxt.t('config.pos.IPPlaceholderLabel')}
                            focused={forceInputFocus}
                            size="small"
                            value={ip}
                            onChange={(e) => {
                                setIp(e.target.value);
                            }}
                            // onKeyPress={keyPressed}
                            helperText={cxt.t('config.pos.IPHelpText')}
                            // error={error}
                        />
                        <Box ml={2}>
                            <SttButton
                                startIcon={<Add />}
                                caption={translate('Add')}
                                color="primary"
                                variant="contained"
                                disabled={testing}
                                onClick={addDevice}
                            />
                        </Box>
                    </Box>

                    {/* BUSCAR DISPOSITIVOS es en teoría un sistema "a prueba de funcionarios", pero en la práctica su funcionamiento no es del todo fiable
					    por temas de conexión y tarda mucho en cualquier caso. Es más fácil que pongan la IP a mano (La tienen en Sporttia Pay) */}
                    {/* <Box mt={3} display={"flex"}>
						<SttButton
							startIcon={<Search/>}
							caption={"Buscar dispositivos"}
							color={"default"}
							variant={"contained"}
							disabled={false}
							onClick={findPOS}
						/>
						{searching &&
						<Box display={"flex"} alignItems={"center"} ml={2}>
							<CircularProgress size={14} color={"primary"}/>
							<Box ml={1}>
								<Typography variant={"body2"}>Buscando dispositivos...</Typography>
							</Box>
						</Box>
						}
					</Box> */}

                    {/* LISTA DE DISPOSITIVOS ENCONTRADOS */}
                    {/* <Box display={"flex"} mt={2}>
						{ipsFound.length > 0 && ipsFound.map((ip, index) => (
							<Box key={index} display={"flex"} alignItems={"center"}>
								<div key={index}>{`Dispositivo encontrado en ${ip}`}</div>
								<IconButton
									style={{
										position:'absolute',
										right: 5,
									}}
									onClick={() => {
										setIp(ip);
									}}>
									<Add />
								</IconButton>
							</Box>
						))}
					</Box> */}
                    <Box display="flex" mt={3}>
                        <SttButton
                            startIcon={<Search />}
                            loading={testing}
                            caption={cxt.t('config.pos.deviceTestLabel')}
                            color="default"
                            variant="contained"
                            disabled={testing}
                            onClick={() => {
                                testDevice()
                                    .then((result) => {
                                        if (result) {
                                            cxt.showMessage(
                                                'S',
                                                cxt.t(
                                                    'config.pos.deviceTestOK',
                                                ),
                                            );
                                        } else {
                                            cxt.showMessage(
                                                'E',
                                                cxt.t(
                                                    'config.pos.deviceTestNotFound',
                                                ),
                                            );
                                        }
                                    })
                                    .catch((msg) => {
                                        cxt.showMessage('E', msg);
                                    });
                            }}
                        />
                    </Box>
                </div>
            }
            buttons={[]}
        />
    );
}

/**
 * Buscamos el POS en toda la red local por el método cuenta de la vieja.
 * En general funciona, aunque no es del 100% fiable (a veces no lo encuentra o tarda el doble), por temas de red imagino.
 * Actualmente no se usa, ya que de momento se mete la IP a mano (la app del datáfono te la chiva).
 * Conservado aquí por ahora (por si volvemos a la aproximación app custom)
 */
/* function findPOS() {
	const baseIP = '192.168.1.';
	const start = 2;
	const end = 255;
	const lapse = 1000;

	setSearching(true);
	setIpsFound([]);

	let ips = [];

	let j=0;
	for(let i=start; i<=end; i++) {
		let req = ((lastDigits, index) => {
			const uri = `${baseIP+lastDigits}:${port}`;

			return new Promise((resolve, reject) => {

				console.log(`buscando en ${uri}...`);

				//do this i * lapse time in the future
				setTimeout(() => {
					requestAdHoc(
						"POST", uri,
						handShakeValues.request,
						true,
						3000
					)
					.then(response => {
						if(jsonCompare(response, handShakeValues.response)) {
							resolve(uri);
						} else {
							reject();
						}
					}).catch(error => {
						reject();
					}).finally(() => {
						if(lastDigits === end) {
							setSearching(false);
						}
					});
				}, lapse * index)
			});
		})(i, j).then(ip => {

			console.log("Hemos encontrado un cacharro en "+i);
			ips = [
				...ips,
				ip,
			];
			setIpsFound(ips);

		}).catch(() => {
			//console.log("No hay cacharro en "+i);
		});
		j++;
	}
} */
