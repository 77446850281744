import { useContext } from 'react';
import useRestService from './RestService';
import { AppContext } from '../sporttia/AppContext';
import { useAPI } from '../lib/hooks';

const resource = '/migration-endpoints';

/**
 * Hook to access all 'migration-endpoints' resources.
 */
export default function useMigrationEndpointsService() {
    const { migrationApis } = useContext(AppContext);
    const [request] = useAPI(migrationApis);
    const services = useRestService(resource);

    /**
     * POST /migration-endpoints/swagger request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function createMigrationEndpointsWithSwagger() {
        return request('POST', `${resource}/swagger`);
    }

    /**
     * PUT /migration-endpoints/:id request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function updateMigrationEndpoint(id, requestBody) {
        return request('PUT', `${resource}/${id}`, requestBody);
    }

    /**
     * DELETE /migration-endpoints/:id request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function deleteMigrationEndpoint(id) {
        return request('DELETE', `${resource}/${id}`);
    }
    return {
        ...services,
        createMigrationEndpointsWithSwagger,
        updateMigrationEndpoint,
        deleteMigrationEndpoint,
    };
}
