import React, { useContext, useState } from 'react';
import { Grid, Box } from '@material-ui/core';
import moment from 'moment';
import {
    AppContext,
    SttFormTextarea,
    SttFormPeriod,
    SttLabelForm,
    SttFormPrice,
    SttButtonSave,
    SttList,
} from '../../sporttia/all';
import { fullName, parsePrice } from '../../lib/utils';
import DiscountList from '../discounts/DiscountsList';
import constants from '../../config/constants';
import translations from '../../translations';
import usePaymentsService from '../../services/PaymentsService';
import Payment from '../../types/models/Payment';
import DateBuilder from '../../lib/DateBuilder';

type PaymentModalDetailProps = {
    payment: Payment;
    onSave?: (payment: Payment) => void;
};

export default function PaymentModalDetail({
    payment,
    onSave,
}: PaymentModalDetailProps) {
    const cxt = useContext(AppContext)!;

    const [paymentForm, setPaymentForm] = useState(payment);

    const handleForm = ({ name, value }: { name: string; value: string }) => {
        setPaymentForm((prev) => ({ ...prev, [name]: value }));
    };

    const paymentsService = usePaymentsService();
    const updatePaymentMutation = paymentsService.useUpdatePayment({
        onSuccess: (response) => {
            if (response.payment) {
                onSave?.(response.payment);
                cxt.showMessage('S', cxt.t(translations.generic.saved));
            }
        },
    });

    return (
        <Box mt={2}>
            <Grid container spacing={3}>
                <SttLabelForm
                    grid
                    title={cxt.t(translations.generic.created)}
                    md={3}
                    text={new DateBuilder(
                        paymentForm.created,
                        cxt?.sc?.timezone?.name,
                        cxt?.lang?.key,
                    ).dmyhm()}
                />

                <SttLabelForm
                    grid
                    title={cxt.t(translations.generic.user)}
                    md={4}
                    text={
                        paymentForm.user
                            ? `@${paymentForm.user.login} - ${fullName(
                                  paymentForm.user,
                              )}`
                            : paymentForm.name
                    }
                />

                {paymentForm.paymentForm && (
                    <SttLabelForm
                        grid
                        title={cxt.t(translations.payment.paymentForm)}
                        md={2}
                        text={cxt.t(`PF.${paymentForm.paymentForm}`)}
                    />
                )}

                {paymentForm.collector && (
                    <SttLabelForm
                        grid
                        title={cxt.t(translations.generic.collector)}
                        md={2}
                        text={`@${paymentForm.collector.login} - ${paymentForm.collector.name}`}
                    />
                )}

                <SttFormTextarea
                    grid
                    xs={12}
                    md={12}
                    disabled={
                        paymentForm.status ===
                        constants.payment.status.paid.name
                    }
                    name="concept"
                    caption={cxt.t(translations.generic.concept)}
                    defVal={paymentForm.concept || ''}
                    onChange={handleForm}
                />

                <SttFormPrice
                    grid
                    xs={12}
                    sm={6}
                    md={3}
                    disabled={
                        paymentForm.status ===
                        constants.payment.status.paid.name
                    }
                    name="price"
                    caption={cxt.t(translations.generic.price)}
                    defVal={String(paymentForm.price)}
                    onChange={handleForm}
                />

                <SttFormPeriod
                    grid
                    xs={12}
                    sm={12}
                    md={9}
                    disabled
                    caption={cxt.t(translations.generic.period)}
                    nameIni="ini"
                    nameEnd="end"
                    defValIni={paymentForm.ini}
                    defValEnd={paymentForm.end}
                    onChange={handleForm}
                />
            </Grid>

            {paymentForm.receipts && (
                <Box my={3}>
                    {/* @ts-expect-error: Migrate SttList */}
                    <SttList
                        title={cxt.t(translations.generic.receipts)}
                        data={paymentForm.receipts.map((rpt) => ({
                            caption: rpt.remittence.name,
                        }))}
                    />
                </Box>
            )}

            {!!paymentForm.discounts?.length && (
                <Box my={3}>
                    <DiscountList
                        discounts={paymentForm.discounts}
                        showEmptyMessage={false}
                    />
                </Box>
            )}

            <Box mt={2}>
                <Grid container spacing={3}>
                    {paymentForm.privileges.includes(
                        constants.payment.privileges.modify,
                    ) && (
                        <Grid item md={3} xs={12}>
                            <SttButtonSave
                                onClick={() => {
                                    updatePaymentMutation.mutate({
                                        id: payment.id,
                                        params: {
                                            concept: paymentForm.concept,
                                            // @ts-expect-error: Migrate utils
                                            price: parsePrice(
                                                paymentForm.price,
                                            ),
                                        },
                                    });
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Box>
    );
}
