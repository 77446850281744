import React, { useState, useContext } from 'react';
import { Box, Grid } from '@material-ui/core';
import {
    AppContext,
    SttList,
    SttChipPForm,
    SttChipPaymentStatus,
    SttLabelDate,
} from '../all';
import PaymentModal from '../../components/paymentModal/PaymentModal';
import { formatPriceByLocale, fullName } from '../../lib/utils';
import translations from '../../translations';
import constants from '../../config/constants';

/**
 *	Payment list
 *	============
 *
 *	Props
 *
 *		* array payments
 *		* boolean clickable [false]: cuando se hace click se abre el pago
 */
export function SttPaymentList({
    paper = true,
    payments,
    clickable = false,
    onClose,
    ...rest
}) {
    const cxt = useContext(AppContext);
    const [idPayModal, setIdPayModal] = useState(null);

    const handleOnSelect = clickable
        ? (pay) => {
              setIdPayModal(pay.id);
          }
        : null;

    return (
        <>
            <SttList
                paper={paper}
                title={cxt.t(translations.payment.payments)}
                onSelect={handleOnSelect}
                data={payments.map((pay) => ({
                    caption: (
                        <Grid
                            container
                            spacing={3}
                            style={{
                                color: pay.trash ? '#ff5131' : 'none',
                            }}
                        >
                            <Grid item md={6}>
                                {fullName(pay.user)}
                                <Box
                                    style={{
                                        fontSize: 13,
                                        color: '#aaa',
                                    }}
                                >
                                    <b>
                                        <SttLabelDate
                                            date={pay.created}
                                            timezoneName={
                                                cxt?.sc?.timezone?.name
                                            }
                                            locale={cxt?.lang?.key}
                                        />
                                    </b>
                                    , {pay.concept}
                                </Box>
                            </Grid>
                            <Grid item md={3} align="center">
                                {pay.status ===
                                constants.payment.status.paid.name ? (
                                    <SttChipPForm pf={pay.paymentForm} />
                                ) : (
                                    <SttChipPaymentStatus status={pay.status} />
                                )}
                            </Grid>
                            <Grid item md={3} align="right">
                                <b>
                                    {formatPriceByLocale(
                                        pay.price,
                                        pay?.priceShape?.currency,
                                        pay?.priceShape?.locale,
                                    )}
                                </b>
                            </Grid>
                        </Grid>
                    ),
                    objToSelect: pay,
                }))}
                {...rest}
            />

            {idPayModal && (
                <PaymentModal
                    idPayment={idPayModal}
                    onClose={() => {
                        setIdPayModal(null);
                        onClose?.();
                    }}
                />
            )}
        </>
    );
}
