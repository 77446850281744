import React, { useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Box, Paper } from '@material-ui/core';
import { SttFullDialog } from '../../../../sporttia/dialogs/SttFullDialog';
import { SUPERADMIN_API } from '../../../../lib/queryKeys';
import useApisService from '../../../../services/ApisService';
import { AppContext } from '../../../../sporttia/AppContext';
import { SttTabs } from '../../../../sporttia/SttTabs';
import AdminApiDetails from './AdminApiDetails';
import AdminApiParameters from './AdminApiParameters';
import AdminApiResponses from './AdminApiResponses';
import AdminApiTests from './AdminApiTests';

/**
 * Api view. Contains tabs to access api data.
 * @param id API id.
 * @param onClose Function executed when closing the full dialog.
 * @returns {JSX.Element}
 */
export default function AdminApi({ id, onClose }) {
    const cxt = useContext(AppContext);
    const [apiId, setApiId] = useState(id);
    const queryClient = useQueryClient();
    const apisService = useApisService();
    const apiQuery = useQuery(
        [SUPERADMIN_API, { id: apiId }],
        () => apisService.get(apiId),
        { enabled: !!apiId && apiId !== 'create' },
    );

    /**
     * Update api id if id prop was changed.
     */
    useEffect(() => {
        setApiId(id);
    }, [id]);

    return (
        <SttFullDialog
            open={apiId !== null}
            onClose={onClose}
            title={
                apiQuery?.isLoading
                    ? ''
                    : apiQuery?.data?.api?.id
                    ? apiQuery?.data?.api?.endpoint
                    : cxt.t('createApi')
            }
        >
            <Paper>
                <Box p={3}>
                    <SttTabs
                        tabs={[
                            {
                                caption: cxt.t('Detail'),
                                component: (
                                    <AdminApiDetails
                                        api={apiQuery?.data?.api}
                                        status={apiQuery?.status}
                                        onCreate={setApiId}
                                        onUpdate={(data) =>
                                            queryClient.setQueryData(
                                                [
                                                    SUPERADMIN_API,
                                                    { id: data?.api?.id },
                                                ],
                                                data,
                                            )
                                        }
                                        onDelete={onClose}
                                    />
                                ),
                            },
                            {
                                show: apiId !== 'create',
                                caption: cxt.t('Parameters'),
                                component: (
                                    <AdminApiParameters
                                        apiId={apiQuery?.data?.api?.id}
                                    />
                                ),
                            },
                            {
                                show: apiId !== 'create',
                                caption: cxt.t('Responses'),
                                component: (
                                    <AdminApiResponses
                                        apiId={apiQuery?.data?.api?.id}
                                    />
                                ),
                            },
                            {
                                show: apiId !== 'create',
                                caption: cxt.t('Tests'),
                                component: (
                                    <AdminApiTests
                                        apiId={apiQuery?.data?.api?.id}
                                    />
                                ),
                            },
                        ]}
                    />
                </Box>
            </Paper>
        </SttFullDialog>
    );
}
