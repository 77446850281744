import React, { useContext, useState } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import PaymentIcon from '@material-ui/icons/Payment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { AppContext, SttDialog } from '../../sporttia/all';
import { useLoader, useTranslations } from '../../lib/hooks';
import {
    useCollectStripePayment,
    useGetStripeReaders,
    useGetStripeTerminal,
} from './hooks';
import { getErrorMessage } from '../../lib/utils';

type Status = 'success' | 'loading' | 'error' | 'idle';

type PaymentData = {
    amount: number;
    concept: string;
    idTpv: number;
};

type POSStripePaymentDialogProps = {
    paymentData: PaymentData;
    onClose?: () => void;
    onSuccess?: () => void;
};

/**
 * Component for collecting Stripe payments using the Stripe Terminal SDK.
 */
export default function POSStripePaymentDialog({
    paymentData,
    onClose,
    onSuccess,
}: POSStripePaymentDialogProps) {
    const cxt = useContext(AppContext)!;
    const { translate } = useTranslations();
    const [connectStripeReaderStatus, setConnectStripeReaderStatus] =
        useState<Status>('idle');

    const getStripeTerminal = useGetStripeTerminal(cxt.sc?.id, {
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
            onClose?.();
        },
        onDisconnect: () => {
            cxt.showMessage(
                'W',
                translate('stripe.terminal.terminalDisconnect'),
            );
            onClose?.();
        },
    });

    const getStripeReaders = useGetStripeReaders(
        getStripeTerminal.terminal,
        cxt.sc?.sporttiaStripeLocationId,
        {
            onSuccess: async (readers) => {
                if (!getStripeTerminal.terminal) {
                    return;
                }

                const readerToConnect = readers[0];

                if (!readerToConnect) {
                    cxt.showMessage(
                        'W',
                        translate('stripe.terminal.noStripeReaders'),
                    );

                    onClose?.();
                    return;
                }

                setConnectStripeReaderStatus('loading');

                const connectResult =
                    await getStripeTerminal.terminal.connectReader(
                        readerToConnect,
                    );

                if ('error' in connectResult) {
                    setConnectStripeReaderStatus('error');
                    cxt.showMessage('E', getErrorMessage(connectResult.error));
                    onClose?.();
                } else {
                    setConnectStripeReaderStatus('success');
                }
            },
            onError: (error) => {
                cxt.showMessage('E', getErrorMessage(error));
                onClose?.();
            },
        },
    );

    const collectStripePayment = useCollectStripePayment({
        terminal: getStripeTerminal.terminal,
        paymentAmount: paymentData.amount,
        stripeConnectAccountId: cxt.sc?.sporttiaStripeConnectedAccount,
        tpvId: paymentData.idTpv,
        options: {
            enabled: connectStripeReaderStatus === 'success',
            onSuccess,
            onCancel: () => {
                cxt.showMessage(
                    'W',
                    translate('stripe.terminal.paymentCancelled'),
                );
                onClose?.();
            },
            onError: (error) => {
                cxt.showMessage('E', getErrorMessage(error));
                onClose?.();
            },
        },
    });

    const [loading] = useLoader([
        getStripeTerminal.status,
        getStripeReaders.status,
        collectStripePayment.status,
        connectStripeReaderStatus,
    ]);

    return (
        <SttDialog
            open
            title={translate('posPaymentDialog.title')}
            maxWidth="sm"
            onClose={onClose}
            content={
                <Box display="flex" flexDirection="column" alignItems="center">
                    {loading && (
                        <>
                            <Typography>{translate('loading')}</Typography>
                            <Box mt={2}>
                                <CircularProgress />
                            </Box>
                        </>
                    )}

                    {collectStripePayment.status === 'waiting' && (
                        <>
                            <Typography>
                                {translate('stripe.terminal.waitingForPayment')}
                            </Typography>
                            <Box mt={2}>
                                <PaymentIcon color="primary" fontSize="large" />
                            </Box>
                        </>
                    )}

                    {collectStripePayment.status === 'success' && (
                        <>
                            <Typography>
                                {translate('stripe.terminal.paymentCompleted')}
                            </Typography>
                            <Box mt={2}>
                                <CheckCircleIcon
                                    style={{ color: '#75bb41' }}
                                    fontSize="large"
                                />
                            </Box>
                        </>
                    )}
                </Box>
            }
            buttons={[
                {
                    type:
                        collectStripePayment.status === 'waiting'
                            ? 'cancel'
                            : 'close',
                    onClick: onClose,
                },
            ]}
        />
    );
}
