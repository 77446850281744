import React, { useState, useContext, useEffect } from 'react';
import {
    Link,
    Typography,
    makeStyles,
    Grid,
    Box,
    Divider,
    Hidden,
} from '@material-ui/core';
import { Loop, Add } from '@material-ui/icons';
import {
    generateFormField,
    fullName,
    formatPriceByLocale,
} from '../../../lib/utils';
import {
    AppContext,
    SttButton,
    SttButtonAccept,
    SttDialog,
    SttFormText,
    SttSelectPForm,
} from '../../../sporttia/all';
import { SttInscriptionList } from '../../../sporttia/lists/SttInscriptionList';
import { SttSelectOrAddUser } from '../../../sporttia/selectors/SttSelectOrAddUser';

const useStyles = makeStyles((theme) => ({
    nonFree: {
        textAlign: 'right',
        color: '#000',
    },
    free: {
        textAlign: 'right',
        color: '#3c763d',
    },
    mandatory: {
        display: 'inline-block',
        width: '100%',
        textAlign: 'right',
        color: 'red',
        marginTop: 8,
    },
    link: {
        display: 'flex',
        alignItems: 'center',
    },
    pendingTitle: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    full: {
        color: 'red',
    },
}));

export default function ModalSignup({
    event,
    team,
    category,
    onClose,
    onSaveInscription,
    onSaveTeam,
    idMship,
}) {
    const cxt = useContext(AppContext);
    const classes = useStyles();

    const [user, setUser] = useState({
        id: cxt.user.role === 'SPORTCENTER' ? null : cxt.user.id,
        name: cxt.user.role === 'SPORTCENTER' ? '' : fullName(cxt.user),
    });

    const [pending, setPending] = useState([]);
    const [paymentForm, setPaymentForm] = useState('PURSE');
    const [formValues, setFormValues] = useState(event.form);
    const [someoneElse, setSomeoneElse] = useState(
        cxt.user.role === 'SPORTCENTER',
    );
    const [teamName, setTeamName] = useState(team ? team.name : '');
    const [creatingTeam, setCreatingTeam] = useState(!team);
    const [idTeam, setIdTeam] = useState(team ? team.id : null);

    // const [capacity, setCapacity] = useState(!!event.teamable ? category.maxTeams !== 0 : (category.maxParticipants !== 0 || event.maxParticipants !== 0));
    const [availableSlots, setAvailableSlots] = useState(
        calculateAvailableSlots(),
    );
    const inscribeOtherLink = (
        <Link
            onClick={() => {
                setSomeoneElse(true);
                setUser({ id: 0, name: '' });
            }}
        >
            <Typography variant="body2" className={classes.link}>
                <Loop style={{ fontSize: 16 }} />
                <span>&nbsp;</span>
                <span>{cxt.t('page.activity.signUpAnotherPerson')}</span>
            </Typography>
        </Link>
    );

    const inscribeMeLink = (
        <Link
            onClick={() => {
                setSomeoneElse(false);
                setUser({
                    id: cxt.user.id,
                    name: fullName(cxt.user),
                });
            }}
        >
            <Typography variant="body2" className={classes.link}>
                <Loop style={{ fontSize: 16 }} />
                <span>&nbsp;</span>
                <span>{cxt.t('SignMeUp')}</span>
            </Typography>
        </Link>
    );

    const newTeamLink = (
        <Link
            onClick={() => {
                setCreatingTeam(true);
                setTeamName('');
                setIdTeam(null);
            }}
        >
            <Typography variant="body2" className={classes.link}>
                <Loop style={{ fontSize: 16 }} />
                <span>&nbsp;</span>
                <span>{cxt.t('CreateNewTeam')}</span>
            </Typography>
        </Link>
    );

    const existingTeamLink = (
        <Link
            onClick={() => {
                setCreatingTeam(false);
                setTeamName(team.name);
                setIdTeam(team.id);
            }}
        >
            <Typography variant="body2" className={classes.link}>
                <Loop style={{ fontSize: 16 }} />
                <span>&nbsp;</span>
                <span>{cxt.t('ExistingTeam')}</span>
            </Typography>
        </Link>
    );

    function calculateAvailableSlots() {
        // Determine which 'available slots' config, in whole event or this specific category, is more restrictive.
        let result;
        if (event.teamable) {
            result =
                typeof event.maxPlayers === 'number' && event.maxPlayers !== 0
                    ? event.maxPlayers -
                      (team?.participants?.length
                          ? team?.participants?.length
                          : 0)
                    : null;
        } else if (
            category.maxParticipants === 0 &&
            event.maxParticipants === 0
        ) {
            result = null;
        } else {
            const catSlots =
                category.maxParticipants !== 0
                    ? category.maxParticipants - category.numParticipants
                    : null;
            const eventSlots =
                event.maxParticipants !== 0
                    ? event.maxParticipants - event.numParticipants
                    : null;

            if ((catSlots === eventSlots) === null) {
                result = null;
            } else {
                if (catSlots === null) {
                    return eventSlots;
                }
                if (eventSlots === null) {
                    return catSlots;
                }
                result = catSlots < eventSlots ? catSlots : eventSlots;
            }
        }

        return result;
    }

    function handleFormFieldChange({ name, value }) {
        setFormValues(
            formValues.map((question) =>
                question.id === parseInt(name)
                    ? { ...question, answer: { value } }
                    : question,
            ),
        );
    }

    function clearForm() {
        setFormValues(
            formValues.map((question) => ({
                ...question,
                answer: { value: '' },
            })),
        );
    }

    function parseForm(formFields) {
        const result = {};
        formFields.forEach((field) => {
            result[field.id] = field.answer ? field.answer.value : '';
        });

        return result;
    }

    function addCurrentToPending() {
        if (user.id === 0 && user.name === '') {
            cxt.showMessage('E', cxt.t('page.event.noPlayerName'));
            return;
        }

        if (
            pending.find(
                (insc) =>
                    (user.id && insc.id === user.id) ||
                    fullName(insc) === fullName(user),
            )
        ) {
            cxt.showMessage('E', cxt.t('UserAlreadyOnList'));
            return;
        }

        if (form && form.length > 0) {
            let valid = true;
            let missing;
            for (let i = 0; i < formValues.length; i++) {
                if (
                    formValues[i].mandatory &&
                    (!formValues[i].answer || !formValues[i].answer.value)
                ) {
                    valid = false;
                    missing = formValues[i];
                    break;
                }
            }

            if (!valid) {
                cxt.showMessage('E', `${cxt.t('Mandatory')}: ${missing.name}`);
                return;
            }
        }

        const params = {
            idUser: user.id,
            name: user.name,
            surname1: user.surname1,
            surname2: user.surname2,
            idCat: category.id,
            form: form && form.length > 0 ? parseForm(formValues) : null,
            price: category.enrollment,
        };

        setPending(pending.concat(params));

        if (someoneElse) {
            setUser({ id: 0, name: '' });
        }

        if (formValues) {
            clearForm();
        }

        // Add to local count
        if (availableSlots !== null) {
            setAvailableSlots(availableSlots - 1);
        }
    }

    function deletePendingInsc(inscription) {
        setPending(
            pending.filter((insc) => {
                if (insc.idUser === 0) {
                    return insc.name !== inscription.name;
                }
                return insc.idUser !== inscription.idUser;
            }),
        );

        // Delete from local count
        if (availableSlots !== null) {
            setAvailableSlots(availableSlots + 1);
        }
    }

    function save() {
        if (!event.teamable) {
            if (!pending || pending.length === 0) {
                cxt.showMessage('E', cxt.t('EmptyList'));
                return;
            }

            onSaveInscription &&
                onSaveInscription({
                    paymentForm,
                    participants: pending.map((participant) => ({
                        idUser: participant.idUser,
                        name: participant.name,
                        surname1: participant.surname1,
                        surname2: participant.surname2,
                        idCat: participant.idCat,
                        form: participant.form,
                    })),
                });
        } else {
            if (!idTeam && teamName === '') {
                cxt.showMessage('E', cxt.t('app.event.teamNameEmpty'));
                return;
            }

            onSaveTeam &&
                onSaveTeam({
                    teamName,
                    idTeam,
                    paymentForm,
                    participants: pending.map((participant) => ({
                        idUser: participant.idUser,
                        name: participant.name,
                        surname1: participant.surname1,
                        surname2: participant.surname2,
                        form: participant.form,
                        admin: cxt.user && participant.idUser === cxt.user.id,
                    })),
                });
        }
    }

    if (!event) {
        return true;
    }

    const { form } = event;

    return (
        <SttDialog
            open
            onClose={onClose}
            title={cxt.t('Inscription')}
            maxWidth="md"
            content={
                <Grid container spacing={3}>
                    {/* LEFT SIDE: Category details and inscription form */}

                    <Grid item xs={12} md={6}>
                        <Typography variant="body2">
                            {cxt.t('page.event.signupDescription')}
                        </Typography>
                        <Box
                            mt={3}
                            display="flex"
                            justifyContent="space-between"
                        >
                            <div>
                                <Typography variant="caption">
                                    {cxt.t('Category')}
                                </Typography>
                                <Typography variant="body1">
                                    {category.name}
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="caption">
                                    {cxt.t('Inscription')}
                                </Typography>
                                {category.enrollment ? (
                                    <Box className={classes.nonFree}>
                                        <Typography variant="body1">
                                            {formatPriceByLocale(
                                                category?.enrollment,
                                                category?.enrollmentShape
                                                    ?.currency,
                                                category?.enrollmentShape
                                                    ?.locale,
                                            )}
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Box className={classes.free}>
                                        <Typography variant="body1">
                                            {' '}
                                            {cxt.t('Free')}
                                        </Typography>
                                    </Box>
                                )}
                            </div>
                        </Box>
                        <Box my={3}>
                            <Divider />
                        </Box>
                        {!!event.teamable && (
                            <Box mb={3}>
                                <Box
                                    mb={1}
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="body2">
                                        <b>
                                            {creatingTeam
                                                ? cxt.t('NewTeam')
                                                : cxt.t('Team')}
                                            :
                                        </b>
                                    </Typography>
                                </Box>
                                {creatingTeam ? (
                                    <Box>
                                        <SttFormText
                                            defVal={teamName}
                                            placeholder={cxt.t(
                                                'app.event.teamName',
                                            )}
                                            onChange={({ name, value }) =>
                                                setTeamName(value)
                                            }
                                        />
                                    </Box>
                                ) : (
                                    <Box>
                                        <SttFormText
                                            disabled
                                            defVal={teamName}
                                        />
                                    </Box>
                                )}
                            </Box>
                        )}
                        <Box mb={3}>
                            <Box
                                mb={1}
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Typography variant="body2">
                                    <b>
                                        {cxt.t('page.activity.personToSignUp')}:
                                    </b>
                                </Typography>
                                {cxt.user.role != 'SPORTCENTER' && (
                                    <Hidden xsDown>
                                        {someoneElse
                                            ? inscribeMeLink
                                            : inscribeOtherLink}
                                    </Hidden>
                                )}
                            </Box>
                            {cxt.user.role === 'SPORTCENTER' || someoneElse ? (
                                <SttSelectOrAddUser
                                    defVal={user}
                                    caption={cxt.t('User')}
                                    onChange={(user) => setUser(user)}
                                    onEnterKey={addCurrentToPending}
                                    idSC={event.sc.id}
                                    idMship={idMship}
                                />
                            ) : (
                                <SttFormText
                                    disabled={user.id !== null}
                                    name="person"
                                    defVal={user.name} // Nota: este .name es solo cosmético, su valor será el fullName del usuario
                                    placeholder={
                                        !user.id ? cxt.t('NameAndSurname') : ''
                                    }
                                    // onChange={({inputName, value}) => setUser({name: value, id: null})}
                                />
                            )}
                            {cxt.user.role != 'SPORTCENTER' && (
                                <Hidden smUp>
                                    <Box mt={1}>
                                        {someoneElse
                                            ? inscribeMeLink
                                            : inscribeOtherLink}
                                    </Box>
                                </Hidden>
                            )}
                        </Box>
                        {form && form.length > 0 && (
                            <>
                                <Box
                                    mb={1}
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="body2">
                                        <b>{cxt.t('page.homeA.ev.2')}:</b>
                                    </Typography>
                                </Box>
                                {formValues.map((item, i) => (
                                    <Box mb={3} key={i}>
                                        <>
                                            {generateFormField(
                                                item,
                                                handleFormFieldChange,
                                            )}
                                            {!!item.mandatory && (
                                                <span
                                                    className={
                                                        classes.mandatory
                                                    }
                                                >{`(${cxt.t(
                                                    'Mandatory',
                                                )})`}</span>
                                            )}
                                        </>
                                    </Box>
                                ))}
                            </>
                        )}
                        <Box display="flex" justifyContent="flex-end">
                            <SttButton
                                startIcon={<Add />}
                                caption={cxt.t('Add')}
                                color={
                                    availableSlots !== null &&
                                    availableSlots <= 0
                                        ? 'primary'
                                        : 'default' /* if button is disabled, change the color to 'primary' (it looks better when disabled) */
                                }
                                variant="contained"
                                disabled={
                                    availableSlots !== null &&
                                    availableSlots <= 0
                                }
                                onClick={addCurrentToPending}
                            />
                        </Box>
                        {availableSlots !== null && availableSlots <= 0 && (
                            <Box display="flex" justifyContent="flex-end">
                                <span className={classes.mandatory}>
                                    {cxt.t('page.event.maxUsersSignup')}
                                </span>
                            </Box>
                        )}
                    </Grid>

                    {/* RIGHT SIDE: List of pendings inscriptions */}

                    <Grid item xs={12} md={6}>
                        <Typography className={classes.pendingTitle}>
                            {cxt.t('page.event.userForSignup')}:
                        </Typography>

                        {/* List of pending incriptions */}
                        <SttInscriptionList
                            hidePrice={!creatingTeam}
                            inscriptions={pending}
                            onDelete={deletePendingInsc}
                        />

                        {/* CAPACITY */}
                        {availableSlots !== null && (
                            <Typography
                                variant="body2"
                                className={
                                    availableSlots === 0
                                        ? classes.full
                                        : undefined
                                }
                            >
                                {`${cxt.t(
                                    'pages.events.availableSlots',
                                )}: ${availableSlots}`}
                            </Typography>
                        )}

                        {/* PAYMENT */}
                        {category.enrollment > 0 &&
                            pending.length > 0 &&
                            creatingTeam && (
                                <>
                                    <Box mt={3}>
                                        <Divider />
                                    </Box>
                                    <Box mt={3}>
                                        {/* <Box mb={1} display={"flex"} justifyContent={"space-between"}>
									<Typography variant={"body2"}>
										<b>{cxt.t('PaymentForm')}</b>
										<span>({`${cxt.t('page.activity.signUpPayment')}, ${formatPrice(category.enrollment, cxt.t('coin'))}`})</span></Typography>
								</Box> */}
                                        <Box
                                            mb={3}
                                            display="flex"
                                            justifyContent="space-between"
                                        >
                                            <Typography
                                                variant="body2"
                                                style={{ fontSize: 16 }}
                                            >
                                                <b>{`${cxt.t('Total')} (${cxt.t(
                                                    'Enrollment',
                                                )})`}</b>
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                style={{ fontSize: 18 }}
                                            >
                                                <b>
                                                    {event.teamable
                                                        ? `${formatPriceByLocale(
                                                              category
                                                                  ?.enrollmentShape
                                                                  ?.price,
                                                              category
                                                                  ?.enrollmentShape
                                                                  ?.currency,
                                                              category
                                                                  ?.enrollmentShape
                                                                  ?.locale,
                                                          )}`
                                                        : `${formatPriceByLocale(
                                                              pending.reduce(
                                                                  (res, insc) =>
                                                                      res +
                                                                      insc.price,
                                                                  0,
                                                              ),
                                                              category
                                                                  ?.enrollmentShape
                                                                  ?.currency,
                                                              category
                                                                  ?.enrollmentShape
                                                                  ?.locale,
                                                          )}`}
                                                </b>
                                                {/* <b>{`${formatPrice(pending[0].price, cxt.t('coin'))}`}</b> */}
                                            </Typography>
                                        </Box>
                                        <SttSelectPForm
                                            caption=""
                                            defVal={paymentForm}
                                            activePFs={event.paymentForms}
                                            onChange={({ name, value }) =>
                                                setPaymentForm(value)
                                            }
                                        />
                                    </Box>
                                </>
                            )}
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <SttButtonAccept
                                caption={cxt.t('SignUp')}
                                onClick={save}
                            />
                        </Box>
                    </Grid>
                </Grid>
            }
        />
    );
}
