import React, { useState, useContext, useRef } from 'react';
import {
    Box,
    Grid,
    Button,
    MenuItem,
    IconButton,
    Menu,
    Divider,
    ListItemIcon,
    ButtonGroup,
    FormControlLabel,
    Switch,
} from '@material-ui/core';
import useKey from '@rooks/use-key';
import {
    Search as SearchIcon,
    MoreVert as MoreIcon,
    FilterList as FilterListIcon,
} from '@material-ui/icons';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Typography from '@material-ui/core/Typography';
import FilterDialog from '../components/filter/FilterDialog';
import FilterFields from '../components/filter/FilterFields';
import {
    AppContext,
    SttButton,
    SttButtonDelete,
    SttButtonFilter,
    SttButtonNew,
    SttFormSelect,
} from './all';
import SttLoadingBar from './SttLoadingBar';

/**
 * SttTopControls
 * ==============
 *
 * 	Componente para el control genérico en la cabecera de las páginas. Acepta campos para filtrado, opciones de filtro avanzado, etc.
 *
 * Props
 *
 * 	* Function fields: campos para pintar (y filtrar). Ver tipos aceptados en /components/filter/FilterFields.js
 * 	* Function extraFields: si se proporciona, se usarán para rellenar un modal con campos de filtro. El modal se muestra al pulsar el botón "avanzado" (setDialogOpen)
 * 	* Function onChange({name, value}): callback general para cambios en cualquier campo. Cada campo puede proporcionar su propio onChange que tiene preferencia sobre este
 * 	* Function onFilter: callback para el botón de búsqueda / aplicar cambios
 *
 * 	* Object filterButton: show filter icon button
 * 		- onClick: action
 *
 * 	* Object downloadButton: show download button
 * 		- onClick: action
 *
 * 	* bool skipSearchButton
 *
 * 	* Object mainAction: algunas pantallas pueden un botón por defecto que en general es el de añadir depósito, etc.
 *
 * 		* onClick
 * 		* component
 * 		* caption
 * 		* options
 * 		* type: diferentes posibilidades dependiendo de mainAction.type
 *
 *  		* create
 *      		- onClick: callback para el botón
 *  		* select
 *      		- options: opciones para el select
 *      		- selected: opción seleccionada por defecto en el select
 *      		- onChange: callback para el select
 *      	* delete
 *      	* component: render component in mainAction.component
 *
 * 	* array menu: muestra los "3 puntos" para abrir un menú con opciones. Cada compnente del menú tiene:
 * 		- boolean show
 * 		- string caption:
 * 		- function onClick
 * 		- Component icon
 * 		- boolean divider: si está establecido pondrá un <Divider /> justo arriba del item.
 *
 * 	* Component menuIcon: icono opcional para el menú desplegable ("3 puntos" por defecto).
 * 	* Function onToggleTrash: callback for the trash switch. It will be called with the switch value.
 * 	    - This is best used alongside the useToggle hook defined in /src/lib/hooks.js
 * 	* array iconButtons: muestra un botón en formato icono que realiza una función:
 * 		- icon
 * 		- onClick
 * 		- caption
 *  * boolean loadingData: valor booleano para bloquear el filtrado hasta que se carguen los datos.
 */

export function SttTopControls({
    fields,
    extraFields,
    onChange,
    onFilter,
    skipSearchButton,
    mainAction,
    menu,
    menuIcon,
    filterButton,
    downloadButton,
    onToggleTrash,
    iconButtons,
    loadingData = false,
    ...rest
}) {
    const cxt = useContext(AppContext);
    const [buttonMenuDOMiObject, setButtonMenuDOMObject] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    // const [shouldOnFilterBeCalled, setShouldOnFilterBeCalled] = useState(false);

    // Setup relative sizes of the controls
    // TODO: remove filterButton and downloadButton when they're removed from Mship and Report
    const extra =
        extraFields ||
        filterButton ||
        downloadButton ||
        menu ||
        onToggleTrash ||
        iconButtons;
    let sizes;
    if (fields && extra) {
        // Everything's up
        sizes = {
            fields: {
                xs: 12,
                md: 8,
                lg: 10,
            },
            extra: {
                xs: 12,
                md: 4,
                lg: 2,
            },
        };
    } else {
        sizes = {
            fields: {
                xs: 12,
            },
        };
    }

    // If the field contains a specific onChange callback use it, otherwise use the generic one provided in the props
    /* function handleChange({name, value}, fieldCallback) {
		let callback;
		if(fieldCallback && fieldCallback.constructor == Function) {
			callback = fieldCallback;
		} else if(onChange && onChange.constructor == Function) {
			callback = onChange;
		}

		if(callback) {
			callback({name, value});
			setShouldOnFilterBeCalled(true);
		} else {
			console.log(`SttTopControls: no callback defined for field ${name}`);
		}
	} */

    /**
     * Render fields
     */
    function renderDefaultFields(sizes) {
        if (fields) {
            let content;

            // If we only specify a single text field to filter by default, we turn it into a search form text field
            if (fields.length == 1 && fields[0].type == 'text') {
                content = (
                    <FilterFields
                        spacing={3}
                        onChange={onChange}
                        fields={[
                            {
                                ...fields[0],
                                type: 'search',
                            },
                        ]}
                        action={renderMainAction()}
                    />
                );
            } else {
                content = (
                    <FilterFields
                        spacing={3}
                        onChange={onChange}
                        fields={fields}
                        action={renderMainAction()}
                    />
                );
            }

            return (
                <Grid item {...sizes}>
                    {content}
                </Grid>
            );
        }
    }

    /**
     * Render main action button
     */
    function renderMainAction() {
        let content;

        if (mainAction) {
            switch (mainAction.type) {
                case 'create':
                    content = (
                        <SttButtonNew
                            caption={mainAction.caption}
                            onClick={mainAction.onClick}
                        />
                    );
                    break;
                case 'delete':
                    content = <SttButtonDelete onClick={mainAction.onClick} />;
                    break;
                case 'select':
                    content = (
                        <SttFormSelect
                            options={mainAction.options}
                            onChange={({ name, value }) =>
                                mainAction.onChange(value)
                            }
                            defVal={mainAction.selected}
                        />
                    );
                    break;
                case 'component':
                    content = mainAction.component;
                    break;
            }
        }
        return content;
    }

    function renderExtra(sizes) {
        // Extra components container should grow to take all available space (but should it really?)
        const mustGrow = true; // onToggleTrash && !(extraFields || filterButton || downloadButton || menu)

        return (
            <Grid item {...sizes} style={mustGrow ? { flexGrow: 1 } : {}}>
                <Box display="flex" justifyContent="flex-end">
                    {/* --- Trash --- */}
                    {onToggleTrash && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={
                                        undefined /* We don't care about this value because the switch has its own state and this will always be false first */
                                    }
                                    onChange={(ev) =>
                                        onToggleTrash(ev.target.checked)
                                    }
                                />
                            }
                            label={cxt.t('Trash')}
                        />
                    )}

                    {/* --- Popup filter --- */}
                    {extraFields && (
                        <IconButton onClick={() => setDialogOpen(true)}>
                            <FilterListIcon />
                        </IconButton>
                    )}

                    {filterButton /* Solo se usa en Report y Mship; TODO: Unificar esos 2 componentes y eliminar esto */ && (
                        <IconButton onClick={filterButton.onClick}>
                            <FilterListIcon />
                        </IconButton>
                    )}

                    {downloadButton /* Solo se usa en Report y Mship; TODO: Unificar esos 2 componentes y eliminar esto */ && (
                        <IconButton onClick={downloadButton.onClick}>
                            <CloudDownloadIcon />
                        </IconButton>
                    )}

                    <Box display="flex" alignItems="center">
                        {iconButtons &&
                            iconButtons.map((item, idx) => (
                                <React.Fragment key={idx}>
                                    <IconButton onClick={item.onClick}>
                                        {item.icon}
                                    </IconButton>
                                    {item.caption && (
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                        >
                                            {item.caption}
                                        </Typography>
                                    )}
                                </React.Fragment>
                            ))}
                    </Box>

                    {menu && (
                        <>
                            <IconButton
                                onClick={(ev) =>
                                    setButtonMenuDOMObject(ev.currentTarget)
                                }
                            >
                                {menuIcon || <MoreIcon />}
                            </IconButton>
                            <Menu
                                anchorEl={buttonMenuDOMiObject}
                                open={buttonMenuDOMiObject !== null}
                                onClose={() => setButtonMenuDOMObject(null)}
                            >
                                {menu &&
                                    menu.map((item, idx) => (
                                        <div key={idx}>
                                            {item.divider && <Divider />}
                                            <MenuItem
                                                onClick={() => {
                                                    if (
                                                        item.onClick &&
                                                        item.onClick
                                                            .constructor ==
                                                            Function
                                                    ) {
                                                        item.onClick();
                                                    }
                                                    setButtonMenuDOMObject(
                                                        null,
                                                    );
                                                }}
                                            >
                                                {item.icon && (
                                                    <ListItemIcon>
                                                        {item.icon}
                                                    </ListItemIcon>
                                                )}
                                                {item.caption}
                                            </MenuItem>
                                        </div>
                                    ))}
                            </Menu>
                        </>
                    )}
                </Box>
            </Grid>
        );
    }

    // Apply filters, but only if there has been at least one change
    function filterClicked() {
        if (
            onFilter &&
            onFilter.constructor === Function /* && shouldOnFilterBeCalled */
        ) {
            onFilter();
            // setShouldOnFilterBeCalled(false);
        }
    }

    /**
     * Render menu
     */
    // -----| Render |-----
    return (
        <>
            <Box {...rest} style={{ pointerEvents: loadingData ? 'none' : '' }}>
                <Grid container spacing={3}>
                    {renderDefaultFields(sizes.fields)}

                    {mainAction && !fields && (
                        <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
                            {renderMainAction()}
                        </Grid>
                    )}

                    {extra && renderExtra(sizes.extra)}
                </Grid>
            </Box>

            {extraFields && (
                <FilterDialog
                    title={cxt.t('Filter')}
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    fields={extraFields}
                    onChange={onChange}
                    onApply={() => {
                        filterClicked();
                        setDialogOpen(false);
                    }}
                />
            )}
            {loadingData && <SttLoadingBar visible />}
        </>
    );
}
