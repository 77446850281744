import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Chip,
    FormControlLabel,
    Grid,
    Hidden,
    Link,
    Switch,
} from '@material-ui/core';
import {
    AppContext,
    SttLabelFee,
    SttFeeRow,
    SttList,
    SttLabelPeriod,
    SttSelectGroupDialog,
    SttButtonNew,
} from '../../../sporttia/all';
import PaymentModal from '../../../components/paymentModal/PaymentModal';
import { useToggle } from '../../../lib/hooks';
import SttGroupMemberDialog from '../../../sporttia/dialogs/groupMemberDialog/SttGroupMemberDialog';

export default function MshipGroups({ mship }) {
    const cxt = useContext(AppContext);
    const [groupMships, setGroupMships] = useState({});
    const [selectGroupOpen, setSelectGroupOpen] = useState(false);
    const [idPayModal, setIdPayModal] = useState(null);
    const [active, toggleActive] = useToggle(() => {
        // eslint-disable-next-line no-use-before-define
        loadGroups();
    });
    const [selectedGroup, setSelectedGroup] = useState({});

    const loadGroups = () => {
        cxt.api('GET', `/mships/${mship.id}/groups/mships`, {
            params: {
                page: 1,
                rows: 100,
                fetchFees: 1,
                onlyActive: active,
            },
            success: (r) => {
                setGroupMships(r);
            },
        });
    };

    const addUserToGroup = (group) => {
        cxt.api('POST', `/groups/${group.id}/users/${mship.user.id}`, {
            success: () => {
                loadGroups();
                cxt.showMessage('S', cxt.t('Saved'));
                setSelectGroupOpen(false);
            },
        });
    };

    const renderCell = (ug) => (
        <>
            <Grid container spacing={3}>
                <Grid item md={4} sm={12} xs={12}>
                    <Link onClick={() => setSelectedGroup(ug)}>
                        {ug.group.name}
                    </Link>

                    {ug.benefactor && (
                        <p>
                            <Chip
                                size="small"
                                label={`Titular: ${ug.benefactor?.name}`}
                            />
                        </p>
                    )}
                </Grid>
                <Hidden mdDown>
                    <Grid item md={1} align="center">
                        {ug.group.numActiveUsers}
                    </Grid>
                </Hidden>
                <Hidden mdDown>
                    <Grid item md={2} sm={12} xs={12} align="center">
                        {cxt.t(`GT.${ug.group.type}`)}
                    </Grid>
                </Hidden>
                <Hidden mdDown>
                    <Grid item md={1} sm={12} xs={12}>
                        {ug.enrollment && ug.enrollment.price}
                    </Grid>
                </Hidden>
                <Hidden mdDown>
                    <Grid item md={2} sm={12} xs={12} align="center">
                        <SttLabelPeriod ini={ug.ini} end={ug.end} />
                    </Grid>
                </Hidden>
                <Hidden mdDown>
                    <Grid item md={2} sm={12} xs={12}>
                        <SttLabelFee
                            interval={ug.group.period}
                            price={ug.group.fee}
                        />
                    </Grid>
                </Hidden>
            </Grid>

            <Box mt={2}>
                {ug.fees && (
                    <SttFeeRow
                        fees={ug.fees}
                        onFeeClicked={(id) => setIdPayModal(id)}
                    />
                )}
            </Box>
        </>
    );

    /**
     * Init
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadGroups, []);

    return (
        <>
            <Box mb={2}>
                <Grid container alignItems="stretch" spacing={2}>
                    <Grid item>
                        <SttButtonNew
                            onClick={() => setSelectGroupOpen(true)}
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={undefined}
                                    onChange={(ev) =>
                                        toggleActive(ev.target.checked)
                                    }
                                />
                            }
                            label={cxt.t('OnlyActiveOnes')}
                        />
                    </Grid>
                </Grid>
            </Box>

            <SttList
                data={
                    groupMships.rows &&
                    groupMships.rows.map((ug) => ({
                        caption: renderCell(ug),
                    }))
                }
            />

            {selectGroupOpen && (
                <SttSelectGroupDialog
                    open
                    types="NORMAL,ABONADOS"
                    onClose={() => setSelectGroupOpen(false)}
                    onSelect={(group) => addUserToGroup(group)}
                />
            )}

            {idPayModal && (
                <PaymentModal
                    idPayment={idPayModal}
                    onClose={() => setIdPayModal(null)}
                />
            )}

            {selectedGroup.id && (
                <SttGroupMemberDialog
                    idGroupMember={selectedGroup.id}
                    showHistory
                    group={selectedGroup}
                    onClose={() => setSelectedGroup({})}
                    onUpdateMember={() => {
                        setSelectedGroup({});
                        loadGroups();
                    }}
                />
            )}
        </>
    );
}
