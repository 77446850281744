import React, { useContext, useEffect, useState } from 'react';
import { Chip } from '@material-ui/core';
import { AppContext, SttTable } from '../../../sporttia/all';
import DeviceDetailsDialog from '../../../pages/sc/devices/DeviceDetailsDialog';
import DateBuilder from '../../../lib/DateBuilder';

/**
 * Booking dialog with booking device authentications.
 * @param booking Booking object.
 * @returns {JSX.Element}
 */
export default function BookingDialogDeviceAuths({ booking }) {
    const cxt = useContext(AppContext);
    const [deviceAuths, setDeviceAuths] = useState();
    const [deviceId, setDeviceId] = useState(null);
    /**
     * Load booking device authentications.
     * @param p Params.
     */
    const loadBookingDeviceAuths = (p = { page: 1, rows: 20 }) => {
        cxt.api('GET', `/bookings/${booking.id}/devices/auths`, {
            params: { ...p },
            success: (r) => {
                setDeviceAuths(r);
            },
        });
    };

    useEffect(() => {
        if (booking && booking.id) loadBookingDeviceAuths();
    }, [booking]);

    // -----| Render |-----
    return (
        <>
            <SttTable
                onFetch={loadBookingDeviceAuths}
                data={deviceAuths}
                columns={[
                    {
                        title: cxt.t('Created'),
                        field: 'created',
                        value: (row) =>
                            new DateBuilder(
                                row?.created,
                                cxt?.sc?.timezone?.name,
                                cxt?.lang?.key,
                            ).dmy(),
                    },
                    {
                        type: 'dmyRange',
                        title: cxt.t('Date'),
                        value: (row) => ({
                            ini: row.dateini,
                            end: row.dateend,
                        }),
                    },
                    {
                        type: 'hmRange',
                        title: cxt.t('Time'),
                        value: (row) => ({
                            ini: `${row.dateini} ${row.timeini}`,
                            end: `${row.dateend} ${row.timeend}`,
                        }),
                    },
                    {
                        title: cxt.t('Week'),
                        width: 150,
                        type: 'weekdays',
                        field: 'weekdays',
                    },
                    {
                        title: cxt.t('Devices'),
                        name: 'devices',
                        value: (row) => (
                            <div>
                                {row.devices &&
                                    row.devices.map((device) => (
                                        <Chip
                                            key={device.id}
                                            label={device.name}
                                            size="small"
                                            style={{
                                                marginLeft: 4,
                                                marginRight: 4,
                                            }}
                                            onClick={() =>
                                                setDeviceId(device?.id)
                                            }
                                        />
                                    ))}
                            </div>
                        ),
                    },
                ]}
            />
            {deviceId !== null && (
                <DeviceDetailsDialog
                    scId={cxt.sc.id}
                    deviceId={deviceId}
                    onClose={() => {
                        setDeviceId(null);
                    }}
                />
            )}
        </>
    );
}
