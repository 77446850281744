import React, { useState, useContext, useEffect } from 'react';
import { AppContext, SttTable } from '../all';
import { fullName } from '../../lib/utils';
import useEventsService from '../../services/EventsService';
import { SttTopFilteringControls } from '../SttTopFilteringControls';
import EventTeam from '../../types/models/EventTeam';
import translations from '../../translations';
import { useLoader } from '../../lib/hooks';

type Category = {
    id: number;
    name: string;
};
type TeamParticipantRow = {
    fullName?: string;
    teamName?: string;
};
type TeamsParticipantsData = {
    count: number;
    rows: Array<TeamParticipantRow>;
};
type TeamParticipant = {
    name: string;
    surname1: string;
    surname2: string;
};

export default function SignedTeamsByCategoryList({
    items,
}: {
    items: Array<Category>;
}) {
    const cxt = useContext(AppContext)!;
    const [categories] = useState<Array<Category>>(items);
    const [currentCategoryId, setCurrentCategoryId] = useState(
        categories ? categories[0]?.id : null,
    );
    const [selectedCategoryId, setSelectedCategoryId] = useState<
        number | undefined
    >(items[0]?.id);
    const [teamParticipantsRows, setTeamParticipantsRows] =
        useState<TeamsParticipantsData>();
    const getEventTeamsByCategoryId =
        useEventsService().useGetEventTeamsByCategoryId(
            Number(currentCategoryId),
            { trash: false },
        );
    const [loader] = useLoader([getEventTeamsByCategoryId?.status]);

    useEffect(() => {
        if (
            getEventTeamsByCategoryId?.isSuccess &&
            getEventTeamsByCategoryId?.data?.rows?.length > 0
        ) {
            const teamsTableRows: Array<TeamParticipantRow> = [];

            getEventTeamsByCategoryId?.data?.rows?.forEach(
                (team: EventTeam) => {
                    const teamParticipants =
                        team.participants as Array<TeamParticipant>;

                    teamParticipants.forEach((participant: TeamParticipant) => {
                        teamsTableRows.push({
                            fullName: fullName({
                                name: participant.name,
                                surname1: participant.surname1,
                                surname2: participant.surname2,
                            }) as string,
                            teamName: team?.name,
                        });
                    });
                },
            );
            setTeamParticipantsRows({
                count: teamsTableRows.length,
                rows: teamsTableRows,
            });
        }
    }, [getEventTeamsByCategoryId?.data, getEventTeamsByCategoryId?.isSuccess]);

    return (
        <>
            {loader}
            <SttTopFilteringControls
                fields={[
                    {
                        caption: cxt.t('Category'),
                        name: 'category',
                        type: 'select',
                        value: selectedCategoryId,
                        options:
                            items &&
                            items.map((cat: Category) => ({
                                label: cat.name,
                                value: cat.id,
                            })),
                    },
                ]}
                onFilter={({ value }: { value: number }) => {
                    setSelectedCategoryId(value);
                }}
                onChange={undefined}
                menu={undefined}
                menuIcon={undefined}
                filterButton={undefined}
                downloadButton={undefined}
                iconButtons={undefined}
                trashAction={undefined}
                mainAction={undefined}
            />

            <SttTable
                autoload={false}
                data={teamParticipantsRows}
                localPagination
                perPage={1}
                columns={[
                    {
                        title: cxt.t(translations.generic.name),
                        type: 'text',
                        field: 'fullName',
                        align: 'left',
                    },
                    {
                        title: cxt.t(translations.teams.teamName),
                        type: 'text',
                        field: 'teamName',
                        align: 'left',
                    },
                ]}
                onFetch={undefined}
                loadingValue={undefined}
                onClickRow={undefined}
                totals={undefined}
                selection={undefined}
                selectRows={undefined}
                setSelectRows={undefined}
                forceUpdateSwitch={undefined}
            />
        </>
    );
}
