import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { AppContext } from '../../../../sporttia/AppContext';
import useReportsService from '../../../../services/ReportsService';
import { SttDialog } from '../../../../sporttia/dialogs/SttDialog';
import SttValidatedForm from '../../../../sporttia/SttValidatedForm';
import { useLoader } from '../../../../lib/hooks';

/**
 * Dialog to modify, create and delete report filters.
 * @param reportId Report ID.
 * @param filterValue Filter object.
 * @param onClose Function executed when closing the dialog.
 * @param onCreate Action taken when creating the tpv configuration.
 * @param onUpdate Action taken when updating the tpv configuration.
 * @param onDelete Method executed when removing tpv configuration.
 * @returns {JSX.Element}
 */
export default function AdminReportFilterDialog({
    reportId,
    filterValue,
    onClose,
    onCreate,
    onUpdate,
    onDelete,
}) {
    const cxt = useContext(AppContext);
    const reportsService = useReportsService();
    const form = useForm();
    const [filter, setFilter] = useState(filterValue);
    const deleteMutation = useMutation(
        (params) => reportsService.removeReportFilter(params?.id),
        {
            onSuccess: onDelete,
        },
    );
    const updateMutation = useMutation(
        (params) =>
            reportsService.updateReportFilter(params?.id, params?.params),
        {
            onSuccess: (data) => {
                onUpdate(data);
                setFilter(data?.reportFilter);
            },
        },
    );
    const createMutation = useMutation(
        (params) =>
            reportsService.createReportFilter(params?.id, params?.params),
        {
            onSuccess: (data) => {
                onCreate(data);
                setFilter(data?.reportFilter);
            },
        },
    );
    const [, loader] = useLoader([
        deleteMutation.status,
        updateMutation.status,
        createMutation.status,
    ]);

    /**
     * Method executed when the user clicks on save or create filter.
     * @param formData Form data.
     */
    const mutateFilter = (formData) => {
        if (filter?.id) {
            updateMutation.mutate({ id: filter?.id, params: formData });
        } else {
            createMutation.mutate({ id: reportId, params: formData });
        }
    };

    /**
     * When the "column" data is returned by the API, it is set in the form.
     */
    useEffect(() => {
        if (filter) {
            form.reset({
                name: filter?.name,
                field: filter?.field,
                sqlPiece: filter?.sqlPiece,
                value: filter?.value,
                type: filter?.type,
            });
        } else {
            form.reset({
                name: '',
                field: '',
                sqlPiece: '',
                value: '',
                type: '',
            });
        }
    }, [filter, form]);

    /**
     * When the value of the filter passed to the component is modified, the selected internal
     * filter saved in the "filter" state will be modified.
     */
    useEffect(() => {
        setFilter(filterValue);
    }, [filterValue]);

    return (
        <SttDialog
            title={cxt.t('Filter')}
            maxWidth="sm"
            open={filter !== null}
            onClose={onClose}
            content={
                <>
                    {loader}
                    <SttValidatedForm
                        form={form}
                        fields={[
                            {
                                type: 'textinput',
                                name: 'name',
                                caption: cxt.t('Name'),
                            },
                            {
                                type: 'textinput',
                                name: 'field',
                                caption: cxt.t('Field'),
                            },
                            {
                                type: 'textinput',
                                name: 'sqlPiece',
                                caption: 'SQL',
                            },
                            {
                                type: 'textinput',
                                name: 'value',
                                caption: cxt.t('Value'),
                            },
                            {
                                type: 'textinput',
                                name: 'type',
                                caption: cxt.t('Type'),
                            },
                        ]}
                    />
                </>
            }
            buttons={[
                {
                    type: 'save',
                    onClick: form.handleSubmit((formData) =>
                        mutateFilter(formData),
                    ),
                },
                {
                    type: 'delete',
                    onClick: () => deleteMutation.mutate({ id: filter?.id }),
                },
            ]}
        />
    );
}
