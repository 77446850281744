import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { AppContext, SttList, SttLabelPeriod, SttChipPForm } from '../all';
import { formatPriceByLocale } from '../../lib/utils';

/**
 * Fee list
 * ========
 *
 * Props
 *
 * 		* Array fees
 *
 */
export function SttFeeList({ fees }) {
    const cxt = useContext(AppContext);
    const [feesValues, setFeesValues] = useState(fees);

    /**
     * Generate fee
     */
    const generateFee = (fee, feeIndex) => {
        cxt.api('POST', `/feeslot/${fee.feeslot.id}/fees`, {
            // confirmation: true,
            params: { dates: [fee.ini] },
            success: (r) => {
                const newFees = [...feesValues];
                newFees[feeIndex] = Object.assign(fee, r.fees[0]);
                setFeesValues(newFees);
            },
        });
    };

    useEffect(() => {
        setFeesValues(fees);
    }, [fees]);

    // -----| Render |-----
    return (
        <SttList
            numRows={5}
            data={
                feesValues &&
                feesValues.map((fee, feeIndex) => ({
                    caption: (
                        <Grid container spacing={3}>
                            <Grid item xs={5} align="left">
                                <SttLabelPeriod ini={fee.ini} end={fee.end} />
                            </Grid>
                            <Grid item xs={4} align="center">
                                {fee.id ? (
                                    <SttChipPForm pf={fee.paymentForm} />
                                ) : (
                                    <a
                                        onClick={() =>
                                            generateFee(fee, feeIndex)
                                        }
                                    >
                                        {cxt.t('Generate')}
                                    </a>
                                )}
                            </Grid>
                            <Grid item xs={3} align="right">
                                <b>
                                    {formatPriceByLocale(
                                        fee.price,
                                        fee?.priceShape?.currency,
                                        fee?.priceShape?.locale,
                                    )}
                                </b>
                            </Grid>
                        </Grid>
                    ),
                }))
            }
        />
    );
}
