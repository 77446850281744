import React, { useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Box, Paper } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../../../sporttia/AppContext';
import { SttTabs } from '../../../../sporttia/SttTabs';
import AdminReportDetail from './AdminReportDetail';
import { SttFullDialog } from '../../../../sporttia/dialogs/SttFullDialog';
import { SUPERADMIN_REPORT } from '../../../../lib/queryKeys';
import useReportsService from '../../../../services/ReportsService';
import AdminReportColumns from './AdminReportColumns';
import AdminReportFilters from './AdminReportFilters';
import { useLoader, useTranslations } from '../../../../lib/hooks';
import translations from '../../../../translations';

/**
 * Report view. Contains tabs to access sport center data.
 * @param id Report id.
 * @param onClose Function executed when closing the full dialog.
 * @returns {JSX.Element}
 */
export default function AdminReport({ id = null, onClose }) {
    const cxt = useContext(AppContext);
    const reportsService = useReportsService();
    const queryClient = useQueryClient();
    const [reportId, setReportId] = useState(id);
    const history = useHistory();
    const { id: reportIdFromPath } = useParams();

    const reportQuery = useQuery(
        [SUPERADMIN_REPORT, { id: reportId }],
        () => reportsService.get(reportId, { trash: true }),
        {
            enabled: !!reportId && reportId !== 'create',
        },
    );

    const { translate } = useTranslations();
    /**
     * Update report id if id prop was changed.
     */
    useEffect(() => {
        setReportId(id || reportIdFromPath);
    }, [id, reportIdFromPath]);

    const [, loader] = useLoader([reportQuery?.status]);

    return (
        <>
            {loader}
            <SttFullDialog
                open={reportId !== null}
                onClose={() =>
                    onClose ? onClose() : history.push('/admin/reports')
                }
                title={
                    reportQuery?.data?.report?.id
                        ? reportQuery?.data?.report?.name
                        : cxt.t('CreateReport')
                }
            >
                <Paper>
                    <Box p={3}>
                        <SttTabs
                            tabs={[
                                {
                                    caption: cxt.t('Detail'),
                                    component: (
                                        <AdminReportDetail
                                            report={{
                                                ...reportQuery?.data?.report,
                                            }}
                                            status={reportQuery?.status}
                                            onCreate={setReportId}
                                            onUpdate={(data) => {
                                                queryClient.setQueryData(
                                                    [
                                                        SUPERADMIN_REPORT,
                                                        {
                                                            id: data?.report
                                                                ?.id,
                                                        },
                                                    ],
                                                    data,
                                                );
                                                cxt.showMessage(
                                                    'S',
                                                    translate(
                                                        translations.report
                                                            .updated,
                                                    ),
                                                );
                                            }}
                                            onDelete={onClose}
                                            onRefresh={() => {
                                                queryClient.invalidateQueries(
                                                    SUPERADMIN_REPORT,
                                                );
                                                cxt.showMessage(
                                                    'I',
                                                    `${translations.generic.loading}...`,
                                                    1000,
                                                );
                                            }}
                                        />
                                    ),
                                },
                                {
                                    caption: cxt.t('Columns'),
                                    show: !!reportQuery?.data?.report?.id,
                                    component: (
                                        <AdminReportColumns
                                            report={reportQuery?.data?.report}
                                        />
                                    ),
                                },
                                {
                                    caption: cxt.t('Filters'),
                                    show: !!reportQuery?.data?.report?.id,
                                    component: (
                                        <AdminReportFilters
                                            report={reportQuery?.data?.report}
                                        />
                                    ),
                                },
                            ]}
                        />
                    </Box>
                </Paper>
            </SttFullDialog>
        </>
    );
}
