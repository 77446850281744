import React from 'react';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core';
import { useTranslations } from '../../lib/hooks';

const useStyles = makeStyles(() => ({
    root: (props: { textColor: string }) => ({
        color: props.textColor,
        '& .MuiButton-label': {
            color: props.textColor,
        },
    }),
}));

type SttButtonCloseProps = {
    fullWidth?: boolean;
    onClick: () => void;
    textColor?: string;
};

/**
 * Close button
 */
export function SttButtonClose({
    fullWidth,
    onClick,
    textColor = 'inherit',
}: SttButtonCloseProps) {
    const { translate } = useTranslations();
    const classes = useStyles({ textColor });

    return (
        <Button
            fullWidth={fullWidth}
            variant="outlined"
            color="default"
            startIcon={<CloseIcon />}
            onClick={onClick}
            className={classes.root}
        >
            {translate('Close')}
        </Button>
    );
}
