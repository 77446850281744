import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { SttDialog } from '../../../sporttia/dialogs/SttDialog';
import { AppContext } from '../../../sporttia/AppContext';
import { SttFormTime, SttList, SttSelectWeekdays } from '../../../sporttia/all';

/**
 * SlotDialog - dialog para la visualización de Tramos (slots)
 * <Slot>Object item - el objeto de tipo Slot
 * Function onSave - callback para el botón de guardado
 * Function onDelete - callback para el botón de borrado
 * Function onClose - callback para el botón cerrar
 * */
export function BusySlotDialog({ item, onSave, onDelete, onClose, loading }) {
    const cxt = useContext(AppContext);

    const [slot, setSlot] = useState(item);
    const [periods, setPeriods] = useState();

    // item cambia. Si el contiene id, cargar los periodos asociados.
    useEffect(() => {
        setSlot(item);
        if (item && item.id) {
            loadPeriods(item.id);
        } else {
            setPeriods(null);
        }
    }, [item]);

    // cambios en los campos
    function handleChange({ name, value }) {
        setSlot({ ...slot, [name]: value });
    }

    // Cargar periodos para este slot
    // TODO: Gestionar mejor la paginación (el problema es que SttList solo pagina el pintado, pero no la obtención de filas)
    function loadPeriods(id) {
        cxt.api('GET', `/busies/schedules/slots/${id}/periods`, {
            params: {
                page: 1,
                rows: 1000000,
            },
            success: (response) => {
                if (response.rows) {
                    setPeriods(response.rows);
                }
            },
        });
    }

    if (!slot) {
        return null;
    }

    return (
        <SttDialog
            open={slot != null}
            onClose={onClose}
            title={slot.id ? cxt.t('Slot') : cxt.t('CreateTimeSlot')}
            content={
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SttSelectWeekdays
                            selected={slot.weekdays}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {/* El output de esto es tipo HH:mm:ss así que lo convertimos a HH:mm para el Slot */}
                        <SttFormTime
                            name="ini"
                            caption={cxt.t('From')}
                            defVal={slot.ini}
                            min="00:00:00"
                            max="23:45:00"
                            interval={5}
                            onChange={({ name, value }) =>
                                handleChange({
                                    name,
                                    value: value
                                        .split(':')
                                        .splice(0, 2)
                                        .join(':'),
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {/* El output de esto es tipo HH:mm:ss así que lo convertimos a HH:mm para el Slot */}
                        <SttFormTime
                            name="end"
                            caption={cxt.t('Until')}
                            defVal={slot.end}
                            min="00:00:00"
                            max="23:45:00"
                            interval={5}
                            onChange={({ name, value }) =>
                                handleChange({
                                    name,
                                    value: value
                                        .split(':')
                                        .splice(0, 2)
                                        .join(':'),
                                })
                            }
                        />
                    </Grid>
                    {periods && (
                        <Grid item xs={12}>
                            <SttList
                                paper
                                title={cxt.t('periods')}
                                numRows={4}
                                data={periods.map((period) => ({
                                    caption: moment(period.ini).format(
                                        'dddd, DD MMM YYYY',
                                    ),
                                    text: `${moment(period.ini).format(
                                        'HH:mm',
                                    )} - ${moment(period.end).format('HH:mm')}`,
                                }))}
                            />
                        </Grid>
                    )}
                </Grid>
            }
            buttons={[
                {
                    type: 'save',
                    onClick: () => onSave(slot),
                    disabled: loading,
                },
                slot.id
                    ? {
                          type: 'delete',
                          onClick: () => onDelete(slot),
                      }
                    : null,
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
        />
    );
}
