import React, { useContext, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { useForm } from 'react-hook-form';
import { QueryKey, useMutation, useQueryClient } from 'react-query';
import { AppContext } from '../AppContext';
import { SttPage } from '../all';
import Tpv from '../../types/models/Tpv';
import SttValidatedForm from '../SttValidatedForm';
import useTpvsService from '../../services/TpvsService';
import { SUPERADMIN_PAYMENTS, SUPERADMIN_TPV } from '../../lib/queryKeys';
import { DefaultListResponse200 } from '../../types/api/utils';
import translations from '../../translations';
import { useTranslations } from '../../lib/hooks';

type SttAdminPaymentFormTpvProps = {
    tpv: Tpv;
    getTpvsQueryKey: QueryKey;
};

type CreateTpvConfirmationMutationParams = {
    id: number;
    params: {
        test: string;
        force: boolean;
    };
};

export function SttAdminPaymentFormTpv({
    tpv,
    getTpvsQueryKey,
}: SttAdminPaymentFormTpvProps) {
    const cxt = useContext(AppContext)!;
    const formTpv = useForm<Tpv>();
    const { translate } = useTranslations();
    const tpvService = useTpvsService();
    const queryClient = useQueryClient();
    const createTpvConfirmationMutation = useMutation(
        SUPERADMIN_TPV,
        (params: CreateTpvConfirmationMutationParams) =>
            tpvService.createTpvConfirmations(params?.id, params?.params),
        {
            onSuccess: (data: Tpv) => {
                if (data?.payment) {
                    queryClient.setQueryData(
                        getTpvsQueryKey,
                        (prevData: DefaultListResponse200<Tpv> | undefined) => {
                            if (prevData) {
                                return {
                                    ...prevData,
                                    rows: prevData?.rows?.map(
                                        (currentTpv: Tpv) => {
                                            let updatedTpv = null;
                                            if (currentTpv?.id === data?.id) {
                                                updatedTpv = data;
                                            }
                                            return updatedTpv || currentTpv;
                                        },
                                    ),
                                };
                            }
                            return { pages: 0, count: 0, rows: [] }; // Esto no deberia ejecutarse nunca pero no encuentro
                            // otro modo de quitar el undefined que se requiere para actualizar prevData de una query.
                        },
                    );
                }

                queryClient.invalidateQueries(SUPERADMIN_PAYMENTS);
            },
        },
    );
    useEffect(() => {
        if (tpv) {
            formTpv.reset({
                id: tpv?.id,
                created: tpv?.created,
                status: tpv?.status,
                result: tpv?.result,
                concept: tpv?.concept,
                price: tpv?.price,
                trash: tpv?.trash
                    ? translate(translations.generic.active)
                    : translate(translations.generic.inactive),
                error: tpv?.error,
            });
        }
    }, [tpv, formTpv, translate]);

    return (
        <Box mt={2}>
            <SttPage innerTitle={tpv?.concept}>
                <SttValidatedForm
                    form={formTpv}
                    fields={[
                        {
                            type: 'label',
                            caption: 'Id',
                            name: 'id',
                            md: 1,
                        },
                        {
                            type: 'label',
                            caption: cxt.t('Created'),
                            name: 'created',
                            md: 2,
                        },
                        {
                            type: 'label',
                            caption: cxt.t('Result'),
                            name: 'result',
                            md: 1,
                        },
                        {
                            type: 'label',
                            caption: cxt.t('Price'),
                            name: 'price',
                            md: 1,
                        },
                        {
                            type: 'label',
                            caption: cxt.t('Trash'),
                            name: 'trash',
                            md: 1,
                        },
                        {
                            type: 'label',
                            caption: cxt.t('Error'),
                            name: 'error',
                            md: 2,
                        },
                    ]}
                    buttons={[
                        {
                            type: 'accept',
                            show: true,
                            caption: cxt.t('onlineConfirmation'),
                            confirmation: true,
                            onClick: () =>
                                createTpvConfirmationMutation.mutate({
                                    id: tpv?.id,
                                    params: {
                                        test: '__2n37ndj3y476d83udjn97ue@@j',
                                        force: true,
                                    },
                                }),
                        },
                    ]}
                />
            </SttPage>
        </Box>
    );
}
