import { MutationFunction, UseMutationOptions, useMutation } from 'react-query';
import { useContext } from 'react';
import { AppContext } from '../sporttia/AppContext';
import { useAPI } from '../lib/hooks';
import {
    DeleteMship200,
    DeleteMshipParams,
    UpdateMship200,
    UpdateMshipBody,
} from '../types/api/paths/Mship';

const resource = '/mships';

/**
 * Hook to access all 'mships' resources.
 */
export default function useMshipsService() {
    const { migrationApis } = useContext(AppContext)!;
    const [request] = useAPI(migrationApis);

    /**
     * PUT /mships/{mshipId} request.
     */
    function useUpdateMship(
        mutationOptions?: UseMutationOptions<
            UpdateMship200,
            unknown,
            { id: number; params: UpdateMshipBody }
        >,
    ) {
        const mutationFn: MutationFunction<
            UpdateMship200,
            { id: number; params: UpdateMshipBody }
        > = ({ id, params }) => request('PUT', `${resource}/${id}`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * DELETE /mships/{mshipId} request.
     */
    function useDeleteMship(
        mutationOptions?: UseMutationOptions<
            DeleteMship200,
            unknown,
            { id: number; params: DeleteMshipParams }
        >,
    ) {
        const mutationFn: MutationFunction<
            DeleteMship200,
            { id: number; params: DeleteMshipParams }
        > = ({ id, params }) => request('DELETE', `${resource}/${id}`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    return {
        useUpdateMship,
        useDeleteMship,
    };
}
