import React, { useState, useRef } from 'react';
import {
    Divider,
    Box,
    Grid,
    FormControlLabel,
    Switch,
    CircularProgress,
} from '@material-ui/core';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { SttButtonRefresh } from '../../../sporttia/all';
import { useOnViewport, useTranslations } from '../../../lib/hooks';
import { USER_MESSAGES } from '../../../lib/queryKeys';
import useMessagesService from '../../../services/MessagesService';
import MessageItem from './MessageItem';

/**
 * Infinite scroll with the messages of a user.
 * @param fold Fold type (INBOX|...).
 * @returns {JSX.Element}
 */
export default function MessageList({ fold }) {
    const ROWS_PER_PAGE = 15;
    const { translate } = useTranslations();
    const queryClient = useQueryClient();
    const ref = useRef();
    const messagesService = useMessagesService();
    const [onlyUnread, setOnlyUnread] = useState(true);
    const { data, status, isFetchingNextPage, fetchNextPage, hasNextPage } =
        useInfiniteQuery(
            [
                USER_MESSAGES,
                {
                    fold,
                    isUnread: fold === 'INBOX' ? onlyUnread : false,
                },
            ],
            ({ pageParam = 1 }) =>
                messagesService
                    .getList({
                        page: pageParam,
                        rows: ROWS_PER_PAGE,
                        fold,
                        isUnread: fold === 'INBOX' ? onlyUnread : false,
                    })
                    .then((response) => {
                        response.page = pageParam;
                        return response;
                    }),
            {
                getNextPageParam: (lastPage) =>
                    lastPage?.page < lastPage?.pages
                        ? lastPage?.page + 1
                        : undefined,
            },
        );
    useOnViewport(ref, '-100px', fetchNextPage);

    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item md={4} sm={5} xs={6}>
                    <Box pl={1} pt={1}>
                        <b>{data?.pages[0]?.count}</b> {translate('Messages')}
                    </Box>
                </Grid>
                <Grid item md={8} sm={7} xs={6}>
                    <Box align="right">
                        {fold === 'INBOX' && (
                            <FormControlLabel
                                label={translate('ShowOnlyNoRead')}
                                control={
                                    <Switch
                                        checked={onlyUnread}
                                        onChange={(ev) =>
                                            setOnlyUnread(ev.target.checked)
                                        }
                                    />
                                }
                            />
                        )}

                        <Box ml={2} display="inline">
                            <SttButtonRefresh
                                onClick={() =>
                                    queryClient.invalidateQueries(USER_MESSAGES)
                                }
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            {status === 'loading' ? (
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            ) : status === 'error' ? (
                <>Error</>
            ) : (
                <>
                    {data.pages.map((page, i) => (
                        <React.Fragment key={i}>
                            {page.rows.map((msg, msgKey) => (
                                <Box key={msgKey}>
                                    {msgKey > 0 && (
                                        <Box mt={1} mb={1}>
                                            <Divider />
                                        </Box>
                                    )}
                                    <MessageItem
                                        message={msg}
                                        fold={fold}
                                        onUpdate={() =>
                                            queryClient.invalidateQueries(
                                                USER_MESSAGES,
                                            )
                                        }
                                    />
                                </Box>
                            ))}
                        </React.Fragment>
                    ))}
                    {isFetchingNextPage && (
                        <Box display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    )}
                    {!hasNextPage && (
                        <Box display="flex" justifyContent="center">
                            No hay más mensajes.
                        </Box>
                    )}
                </>
            )}

            {/* Este div es para detectar el final de la página, cuando sea visible se van a pedir datos a la API para realizar el scroll infinito. */}
            <div ref={ref} />
        </Box>
    );
}
