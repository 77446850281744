import React, { useState, useContext } from 'react';
import { Paper } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import moment from 'moment';
import { AppContext } from '../../../../sporttia/all';
import { usePageHeader, useQueryFilters } from '../../../../lib/hooks';
import useApisService from '../../../../services/ApisService';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';
import { SUPERADMIN_API_LOGS } from '../../../../lib/queryKeys';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import { SttChipHttpVerb } from '../../../../sporttia/chips/SttChipHttpVerb';
import AdminApiLogDialog from './AdminApiLogDialog';

/**
 * View where the sporttia superadmin can see the list of apis logs.
 * @returns {JSX.Element}
 */
export default function AdminApiLogs({}) {
    const cxt = useContext(AppContext);
    usePageHeader('apiLogs');
    const apisService = useApisService();
    const [filters, setFilters] = useQueryFilters({}, SUPERADMIN_API_LOGS);
    const [apiLog, setApiLog] = useState(null);
    // Esto habría que borrarlo en el futuro en el caso de que el endpoint sea más eficiente
    // y se pueda imprimir todas las filas a la primera.
    const [loads, setLoads] = useState(0);

    /**
     * In this case format the date of timeini and timeend to the format 'YYYY-MM-DD'.
     * @param updatedFilters Object with the parameters returned by the SttTopFilteringControls component.
     */
    const doFiltering = (updatedFilters) => {
        const formatedFilters = { ...updatedFilters };

        // Esto es una chapuza para que no cargue la tabla al abrir la vista, es en la unica vista
        // de Sporttia donde se hace esto porque el endpoint es poco eficiente dada la cantidad de filas que
        // existen de logs.
        if (loads <= 1) setLoads(loads + 1);

        if (updatedFilters.timeini) {
            formatedFilters.timeini = moment(updatedFilters.timeini).format(
                'YYYY-MM-DD',
            );
        }

        if (updatedFilters.timeend) {
            formatedFilters.timeend = moment(updatedFilters.timeend).format(
                'YYYY-MM-DD',
            );
        }

        setFilters(formatedFilters);
    };

    return (
        <Paper>
            <SttTopFilteringControls
                p={2}
                fields={[
                    {
                        type: 'search',
                        caption: 'ID',
                        name: 'id',
                        value: '',
                    },
                ]}
                extraFields={[
                    {
                        caption: cxt.t('Method'),
                        name: 'method',
                        value: '',
                    },
                    {
                        caption: cxt.t('Resource'),
                        name: 'endpoint',
                        value: '',
                    },
                    {
                        caption: cxt.t('Body'),
                        name: 'body',
                        value: '',
                    },
                    {
                        caption: cxt.t('UserId'),
                        name: 'idUser',
                        value: '',
                    },
                    {
                        caption: cxt.t('Begin'),
                        name: 'timeini',
                        type: 'date',
                        value: moment().format('YYYY-MM-DD'),
                    },
                    {
                        caption: cxt.t('End'),
                        name: 'timeend',
                        type: 'date',
                        value: moment().format('YYYY-MM-DD'),
                    },
                    {
                        caption: 'API ID',
                        name: 'idAPI',
                        value: '',
                    },
                    {
                        caption: cxt.t('OnlyWithErrors'),
                        name: 'onlyErrors',
                        type: 'check',
                        checked: '',
                    },
                    {
                        caption: cxt.t('OnlyFatalErrors'),
                        name: 'isFatalError',
                        type: 'check',
                        checked: '',
                    },
                ]}
                onFilter={doFiltering}
            />

            <SttCachedTable
                queryKey={SUPERADMIN_API_LOGS}
                queryFn={(params) => apisService.getApisExecutions(params)}
                queryParams={filters}
                queryOptions={{ cacheTime: Infinity, enabled: loads >= 2 }}
                onClickRow={(row) => setApiLog(row)}
                columns={[
                    {
                        title: 'ID',
                        type: 'id',
                        field: 'id',
                    },
                    {
                        title: cxt.t('Error'),
                        value: (row) =>
                            row.error !== null &&
                            row.error !== '' && (
                                <HighlightOff style={{ color: '#df3525' }} />
                            ),
                    },
                    {
                        title: cxt.t('Fatal'),
                        value: (row) =>
                            row.isFatalError && (
                                <HighlightOff style={{ color: '#df3525' }} />
                            ),
                    },
                    {
                        title: cxt.t('Created'),
                        field: 'created',
                        type: 'dmyhm',
                    },
                    {
                        title: cxt.t('Method'),
                        value: (row) =>
                            row.api && (
                                <SttChipHttpVerb httpVerb={row.api.method} />
                            ),
                        width: 40,
                    },
                    {
                        title: cxt.t('Resource'),
                        align: 'left',
                        value: (row) => <b>{row.endpoint}</b>,
                    },
                    {
                        title: cxt.t('Executor'),
                        value: (row) =>
                            row.executor &&
                            `${row.executor.id} - ${row.executor.name}`,
                    },
                    {
                        title: cxt.t('Version'),
                        field: 'version',
                        width: 50,
                    },
                    {
                        title: cxt.t('IP'),
                        field: 'ip',
                        width: 80,
                    },
                    {
                        title: cxt.t('Time'),
                        field: 'duration',
                        width: 40,
                    },
                    {
                        title: cxt.t('withLogs'),
                        type: 'binaryStatus',
                        value: (row) => row?.logs,
                    },
                ]}
            />

            {/* En el futuro si tenemos un GET /apis/logs/:id podríamos quitar apiLog y pasar el id del log. */}
            <AdminApiLogDialog
                apiLog={apiLog}
                onClose={() => setApiLog(null)}
            />
        </Paper>
    );
}
