import React, { useState, useContext } from 'react';
import {
    Container,
    Box,
    Typography,
    Grid,
    CircularProgress,
} from '@material-ui/core';
import {
    CreditCard,
    PostAdd,
    AccountBalanceWallet,
    Contactless,
} from '@material-ui/icons';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import moment from 'moment';
import {
    AppContext,
    SttButton,
    SttLabelTitle,
    SttLabelWeekdays,
    SttPage,
} from '../../../sporttia/all';
import { getPath } from '../../Pages';
import {
    normalizeDuration,
    formatPriceByLocale,
    getErrorMessage,
} from '../../../lib/utils';
import constants from '../../../config/constants';
import useBonosService from '../../../services/BonosService';
import SttError from '../../../components/error/SttError';
import useScsService from '../../../services/ScsService';
import ModalConfirmation from '../../../layout/ModalConfirmation';
import { PaymentForm } from '../../../types/payment';
import translations from '../../../translations';

type BuyBonoProps = RouteComponentProps<{
    id: string;
    idSC: string;
}>;

export default function BuyBono({ match }: BuyBonoProps) {
    const cxt = useContext(AppContext)!;
    const history = useHistory();

    const [selectedPaymentForm, setSelectedPaymentForm] =
        useState<PaymentForm>();

    const bonosService = useBonosService();
    const scsService = useScsService();

    const getBonoQuery = bonosService.useGetBono(parseInt(match.params.id));
    const getScMeQuery = scsService.useGetScMe(parseInt(match.params.idSC));
    const getScTpvConfs = scsService.useGetScTpvConfs(
        parseInt(match.params.idSC),
    );
    const createBonoUserMutation = bonosService.useCreateBonoUser({
        onSuccess: (response) => {
            if (response.tpv) {
                history.push(getPath('tpvSwitcher', { id: response.tpv.id }));
            } else if (response.boleto) {
                history.push(getPath('userBonos'));
            }
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });

    if (
        getBonoQuery.isLoading ||
        getScMeQuery.isLoading ||
        getScTpvConfs.isLoading
    ) {
        return (
            <Container maxWidth="md">
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    if (getBonoQuery.isError || getScMeQuery.isError || getScTpvConfs.isError) {
        return <SttError />;
    }

    if (!getBonoQuery.data || !getScMeQuery.data || !getScTpvConfs.data) {
        return null;
    }

    const { bono } = getBonoQuery.data;
    const { mship } = getScMeQuery.data;

    const hasZitycard = getScTpvConfs.data.rows.some(
        (tpv) => tpv.bank === constants.tpvConf.banks.zitycard.name,
    );

    return (
        <Container maxWidth="md">
            <SttPage innerTitle={cxt.t('BuyBono')}>
                {mship && <SttLabelTitle>{mship.sc!.name}</SttLabelTitle>}
                <Box mt={2}>
                    <Box>
                        <span>
                            {cxt.t('Name')}: <b>{bono.name}</b>
                        </span>
                        <br />
                        <span>
                            {cxt.t('scProfile.buyBono.numConsumed')}:{' '}
                            <b>
                                {bono.amount} x{' '}
                                {normalizeDuration(bono.duration)}
                            </b>
                        </span>
                        <br />
                        <span>
                            {cxt.t('scProfile.buyBono.priceBono')}:{' '}
                            <b>
                                {formatPriceByLocale(
                                    bono?.price,
                                    bono?.priceShape?.currency ?? undefined,
                                )}
                            </b>
                        </span>
                        <br />
                        {bono.expiration && (
                            <>
                                <span>
                                    {cxt.t('Expiration')}:{' '}
                                    <b>{bono.expirationLang}</b>
                                </span>
                                <br />
                            </>
                        )}
                        <span>
                            {cxt.t('ValidUntil')}:{' '}
                            <b>{moment(bono.dateend).format('DD MM YYYY')}</b>
                        </span>
                        <Box mt={1}>
                            <b>{cxt.t('scProfile.buyBono.horary')}:</b>
                            <SttLabelWeekdays weekdays={bono.weekdays} />
                            <span>{`${bono.timeini} - ${bono.timeend}`}</span>
                        </Box>
                        <Box mt={1}>
                            <div>
                                {cxt.t('Type')}: <b>{bono.typeLang}</b>
                            </div>
                            <Typography variant="caption">
                                * {cxt.t('scProfile.buyBono.typeBono')}
                            </Typography>
                        </Box>
                    </Box>

                    <Box mt={2}>{bono.description}</Box>

                    <Box mt={2}>
                        {bono.facilities.length > 0 && (
                            <>
                                <b>{cxt.t('scProfile.buyBono.fields')}:</b>
                                <Box pl={1} pt={1}>
                                    {bono.facilities.map((facility) => (
                                        <div key={facility.id}>
                                            {facility.name}
                                        </div>
                                    ))}
                                </Box>
                            </>
                        )}
                    </Box>

                    <Box mt={3}>
                        <Grid container spacing={3}>
                            {/* --- Paying with TPV --- */}
                            {bono.price > 0 && (
                                <>
                                    {mship &&
                                        bono.purchasePFs?.includes('PURSE') && (
                                            <Grid item md={6} sm={12}>
                                                <SttButton
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    caption={`${cxt.t(
                                                        'payWithPurse',
                                                    )} (${formatPriceByLocale(
                                                        mship?.amount,
                                                        mship?.amountShape
                                                            ?.currency ??
                                                            undefined,
                                                    )})`}
                                                    startIcon={
                                                        <AccountBalanceWallet />
                                                    }
                                                    onClick={() => {
                                                        setSelectedPaymentForm(
                                                            constants.payment
                                                                .paymentForms
                                                                .purse.name,
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        )}

                                    {bono.purchasePFs?.includes('TPV') && (
                                        <Grid item md={6} sm={12}>
                                            <SttButton
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                caption={cxt.t('PayWithTPV')}
                                                startIcon={<CreditCard />}
                                                onClick={() => {
                                                    setSelectedPaymentForm(
                                                        constants.payment
                                                            .paymentForms.tpv
                                                            .name,
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    )}
                                </>
                            )}

                            {!bono.price && (
                                <Grid item md={6} sm={12}>
                                    <SttButton
                                        variant="contained"
                                        color="primary"
                                        caption={cxt.t('GetAbono')}
                                        startIcon={<PostAdd />}
                                        onClick={() => {
                                            setSelectedPaymentForm(
                                                constants.payment.paymentForms
                                                    .free.name,
                                            );
                                        }}
                                    />
                                </Grid>
                            )}

                            {hasZitycard && (
                                <Grid item md={6} sm={12}>
                                    <SttButton
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        caption={cxt.t(
                                            translations.payment
                                                .payWithZitycard,
                                        )}
                                        startIcon={<Contactless />}
                                        onClick={() => {
                                            setSelectedPaymentForm(
                                                constants.payment.paymentForms
                                                    .zitycard.name,
                                            );
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Box>
            </SttPage>

            {selectedPaymentForm && (
                <ModalConfirmation
                    show
                    onAccept={() => {
                        createBonoUserMutation.mutate({
                            userId: cxt.user!.id!,
                            bonoId: bono.id,
                            params: {
                                paymentForm: selectedPaymentForm,
                            },
                        });

                        setSelectedPaymentForm(undefined);
                    }}
                    onClose={() => {
                        setSelectedPaymentForm(undefined);
                    }}
                />
            )}
        </Container>
    );
}
