import React, { SyntheticEvent, useContext } from 'react';
import {
    FormControl,
    FormControlProps,
    Grid,
    GridProps,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
} from '@material-ui/core';
import { Clear as ClearIcon, Search as SearchIcon } from '@material-ui/icons';
import { AppContext } from '../AppContext';
import translations from '../../translations';

type SttFormSearchProps = {
    grid?: boolean;
    size?: FormControlProps['size'];
    caption?: string;
    onChange: ({ name, value }: { name: string; value: string }) => void;
    defVal: string;
    name?: string;
    disabled?: boolean;
    readOnly?: boolean;
    onClickSearch?: (event: SyntheticEvent) => void;
    onEnterKey?: (event: SyntheticEvent) => void;
    onClean?: () => void;
} & Omit<GridProps, 'onChange'>;

/**
 * Form search
 */
export function SttFormSearch({
    grid = false,
    size = 'small',
    caption,
    onChange,
    defVal,
    name = '',
    disabled,
    readOnly = false,
    onClickSearch,
    onEnterKey,
    onClean,
    ...rest
}: SttFormSearchProps) {
    const cxt = useContext(AppContext)!;

    const content = (
        <FormControl variant="outlined" size={size} fullWidth>
            <InputLabel>
                {caption || cxt.t(translations.generic.search)}
            </InputLabel>
            <OutlinedInput
                disabled={disabled}
                type="text"
                value={defVal}
                onChange={(ev) => onChange({ name, value: ev.target.value })}
                endAdornment={
                    <InputAdornment position="end">
                        {onClean && defVal && !readOnly && (
                            <IconButton onClick={() => onClean()}>
                                <ClearIcon />
                            </IconButton>
                        )}

                        {onClickSearch && !readOnly && (
                            <IconButton onClick={onClickSearch}>
                                <SearchIcon />
                            </IconButton>
                        )}
                    </InputAdornment>
                }
                labelWidth={70}
                onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                        if (onEnterKey) {
                            onEnterKey(event);
                        } else if (onClickSearch) {
                            onClickSearch(event);
                        }
                    }
                }}
            />
        </FormControl>
    );
    return grid ? (
        <Grid item {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
