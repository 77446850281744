import React, { useContext, useState } from 'react';
import { Chip, Avatar } from '@material-ui/core';
import { AppContext, SttUnlockerDialog } from '../all';

/**
 * Chip unlocker
 */
export function SttChipUnlocker({
    unlocker = null,
    showModal = false,
    onClick = null,
}) {
    const cxt = useContext(AppContext);
    const [unlockerDialogOpened, setUnlockerDialogOpened] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    /**
     * Get type letter
     */
    const getTypeLetter = (unlocker) => {
        switch (unlocker.type) {
            case 'PIN':
                return 'P';
                break;
            case 'QR':
                return 'Q';
                break;
            case 'TICKET_CARD':
                return 'T';
                break;
            case 'CARD':
                return 'C';
                break;
            case 'FINGERPRINT':
                return 'F';
                break;
            case 'TICKET_ONE_USE':
                return '1';
                break;
            default:
                return 'X';
        }
    };

    // Render
    return (
        <>
            {unlocker && unlocker.type && !isDeleted && (
                <Chip
                    size="small"
                    style={{ marginRight: 3, marginBottom: 3 }}
                    avatar={
                        <Avatar
                            style={{ backgroundColor: 'white' }}
                            alt={cxt.t(`UT.${unlocker.type}`)}
                        >
                            {getTypeLetter(unlocker)}
                        </Avatar>
                    }
                    label={cxt.t(`UT.${unlocker.type}`)}
                    onClick={
                        showModal
                            ? (ev) => setUnlockerDialogOpened(true)
                            : onClick
                    }
                />
            )}

            {showModal && unlocker && unlockerDialogOpened && (
                <SttUnlockerDialog
                    idUnlocker={unlocker.id}
                    open={unlockerDialogOpened}
                    onClose={(ev) => setUnlockerDialogOpened(false)}
                    onDelete={(ev) => setIsDeleted(true)}
                />
            )}
        </>
    );
}
