import React, { useState, useEffect, useContext, useRef } from 'react';
import {
    Grid,
    FormControl,
    InputLabel,
    InputAdornment,
    OutlinedInput,
    IconButton,
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    Divider,
    Box,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import {
    AppContext,
    SttFormSearch,
    SttFormSelect,
    SttFormText,
    SttPagination,
} from '../all';

/**
 *	Select city
 *
 */
export function SttSelectCity({
    grid = true,
    search = true,
    caption,
    name,
    idProvince,
    defVal,
    onChange,
    md = 12,
    xs = 12,
    onSelect,
    disabled,
    ...rest
}) {
    const cxt = useContext(AppContext);
    const [dialogOpened, setDialogOpened] = useState(false);
    const [cities, setCities] = useState({ count: 0, rows: [] });
    const [keywordSearch, setKewordSearch] = useState('');
    const [page, setPage] = useState(1);

    const searchNumRows = 8;

    useEffect(() => {
        if (!search) {
            loadCities();
        } else {
            setKewordSearch('');
            loadCities({ page: 1, rows: searchNumRows, name: '' });
        }
    }, [idProvince]);

    /**
     * Load cities
     */
    const loadCities = (p = { page: 1 }) => {
        const currPage = p.page !== undefined ? p.page : page;
        cxt.api('GET', `/cities`, {
            params: {
                page: currPage,
                rows: p.rows || 10000,
                name: p.name === undefined ? keywordSearch : p.name,
                idProvince: idProvince || '',
            },
            success: (r) => {
                setCities(r);
                setPage(currPage);
            },
        });
    };

    /**
     * Click search
     */
    const handleClickSearch = () => {
        if (disabled) {
            return;
        }

        if (!cities) {
            loadCities({ page: 1, rows: searchNumRows });
        }
        setDialogOpened(true);
    };

    /**
     * Close dialog
     */
    const onClose = () => {
        setDialogOpened(false);
    };

    /**
     * Select
     */
    const _onSelect = (p) => {
        setDialogOpened(false);
        onSelect(p);
    };

    let content;

    content = (
        <>
            <SttFormSearch
                disabled
                caption={caption}
                defVal={defVal}
                showClearButton
                onClean={() => onSelect({ name, value: null })}
                onChange={onChange}
                onClickSearch={handleClickSearch}
            />

            <Dialog fullWidth onClose={onClose} open={dialogOpened}>
                <DialogTitle>
                    <SttFormSearch
                        defVal={keywordSearch}
                        onClickSearch={() =>
                            loadCities({ page: 1, rows: searchNumRows })
                        }
                        onChange={({ name, value }) => setKewordSearch(value)}
                    />
                </DialogTitle>

                <List>
                    {cities &&
                        cities.rows.map((city, idx) => (
                            <div key={idx}>
                                <Divider />
                                <ListItem
                                    button
                                    onClick={() =>
                                        _onSelect({ name, value: city })
                                    }
                                >
                                    <ListItemText primary={city.name} />
                                </ListItem>
                            </div>
                        ))}

                    {cities && cities.count == 0 && (
                        <ListItem>
                            <ListItemText primary={cxt.t('NoResults')} />
                        </ListItem>
                    )}

                    <ListItem>
                        {cities && (
                            <Box m="auto">
                                <SttPagination
                                    page={page}
                                    pages={cities.pages}
                                    count={cities.count}
                                    onChangePage={(page) =>
                                        loadCities({
                                            page,
                                            rows: searchNumRows,
                                        })
                                    }
                                />
                            </Box>
                        )}
                    </ListItem>
                </List>
            </Dialog>
        </>
    );

    // -----| Render |-----

    return grid ? (
        <Grid item xs={xs} md={md} {...rest}>
            {content}
        </Grid>
    ) : (
        <>{content}</>
    );
}
