import React, { useState, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    main: {
        padding: theme.spacing(3),
        color: '#666',
    },
    wrap: {
        userSelect: 'none',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

export default function Privacy(props) {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.main}>
                <Box p={1}>
                    <h1>CONDICIONES GENERALES</h1>
                </Box>
                <p className={classes.wrap}>
                    <h3>1. Objeto</h3>
                    <span>
                        Este Aviso legal regula el uso por los usuarios de las
                        páginas web en el sitio web{' '}
                        <a href="http://www.sporttia.com"> www.sporttia.com </a>{' '}
                        (en adelante, el sitio web), propiedad de Social Cloud
                        SL, en calle Seúl, 2B CP 41740 Sevilla (España) y con
                        CIF B-90061284. <br /> <br /> La navegación por el sitio
                        web atribuye la condición de usuario e implica la
                        aceptación total y sin reservas de este Aviso Legal.
                        Social Cloud S.L., a través de su sitio web, brinda a
                        los usuarios acceso a ciertos contenidos puestos a
                        disposición por Social Cloud S.L. o por proveedores de
                        servicios y contenido de terceros. El usuario acepta
                        voluntariamente que el uso de estos tiene lugar, en
                        cualquier caso, bajo su única y exclusiva
                        responsabilidad. <br /> <br /> Social Cloud S.L. se
                        reserva el derecho de modificar unilateralmente en
                        cualquier momento y sin previo aviso, presentación,
                        configuración o contenido del sitio web y de este Aviso
                        Legal. <br /> <br /> Estas modificaciones se
                        considerarán válidamente notificadas desde su
                        publicación en la web. El usuario acepta leer este Aviso
                        legal cada vez que tenga la intención de acceder al
                        Aviso del sitio web. <br /> <br />
                    </span>

                    <h3>2. Obligaciones del usuario</h3>
                    <span>
                        El usuario se obliga a usar los contenidos de forma
                        diligente, correcta, lícita y de conformidad a la Ley y
                        al presente Aviso Legal. En particular, se compromete a
                        no utilizar los contenidos con una finalidad contraria a
                        la Ley, moral u orden público; a no reproducir o copiar,
                        no distribuir, no permitir el acceso público, no
                        transformar y no modificar ningún tipo de contenido del
                        website, a excepción de que cuente con autorización
                        expresa y en cualquier tipo de soporte, ya sea físico o
                        lógico, de Social Cloud S.L. o con autorización expresa,
                        en su caso, del legítimo titular de los derechos sobre
                        los contenidos antes mencionados.
                        <br />
                        <br />
                        Asimismo el usuario se obliga a no utilizar el diseño y
                        el código fuente de las páginas web del website con una
                        finalidad contraria a la Ley, moral u orden público.
                        <br />
                        <br />
                    </span>

                    <h3>3. Acceso y Seguridad</h3>
                    <span>
                        El acceso a diversos servicios, áreas o recursos
                        requiere el registro previo de los usuarios, únicamente
                        será posible mediante el registro del usuario. El
                        usuario deberá facilitar ciertos datos de carácter
                        personal que serán tratados de acuerdo con la finalidad
                        y condiciones establecidas en la política de privacidad
                        de datos de Social Cloud S.L. La identificación del
                        usuario estará compuesta por su dirección de correo
                        electrónico y contraseña. Para el acceso a la cuenta
                        propia del usuario, será necesaria la identificación del
                        usuario y una contraseña elegida por el usuario. El uso
                        de la contraseña es personal e intransferible, no
                        estando permitida la cesión, ni siquiera temporal, a
                        terceros. En tal sentido, el usuario se compromete a
                        hacer un uso diligente y a mantener en secreto la misma,
                        asumiendo toda responsabilidad por las consecuencias de
                        su divulgación a terceros.
                        <br />
                        <br />
                        En el supuesto de que le usuario conozca o sospeche del
                        uso de su contraseña por terceros, deberá modificar la
                        misma de forma inmediata y se compromete a notificar a
                        sporttia a la mayor brevedad el uso no autorizado de su
                        nombre de usuario y contraseña o cualquier otro fallo en
                        la seguridad. www.sporttia.com no será responsable por
                        los daños o pérdidas que se pudieran originar debido al
                        no cumplimiento de esta obligación.
                        <br />
                        <br />
                    </span>

                    <h3>4. Propiedad intelectual e industrial</h3>
                    <span>
                        Todos los contenidos de la web, salvo que se indique lo
                        contrario, son titularidad exclusiva del titular de la
                        web o de terceros y, con carácter enunciativo, que no
                        limitativo, el diseño gráfico, código fuente, logos,
                        textos, imágenes, software, gráficos, ilustraciones,
                        fotografías y demás elementos que aparecen en la web.
                        Igualmente todos los nombres comerciales, marcas o
                        signos distintivos de cualquier clase contenidos en la
                        web están protegidos por la ley.
                        <br />
                        <br />
                        En particular, los usuarios y demás personas que quieran
                        explotar, reproducir, distribuir, comunicar públicamente
                        y transformar los contenidos incluidos en la website
                        deberán realizarlo con la previa autorización de Social
                        Cloud S.L., o, en su caso, de su legítimo titular y
                        siempre realizando estas actuaciones de buena fe y
                        siguiendo las directrices del presente Aviso Legal, así
                        como de la Ley, moral y orden público.
                        <br />
                        <br />
                        En cualquier caso Social Cloud S.L. se reserva a
                        emprender las oportunas acciones legales en caso de
                        actos de confusión, engaño, denigración, comparación,
                        imitación, explotación de la reputación ajena,
                        incorporación de referencias ocultas en sitios de
                        Internet perjudiciales para Social Cloud S.L., o
                        cualquier otraconducta análoga o similar que perjudique
                        sus legítimos derechos de propiedad.
                        <br />
                        <br />
                    </span>

                    <h3>5. Exclusión de responsabilidad</h3>
                    <span>
                        Social Cloud S.L. no garantiza la disponibilidad y
                        continuidad del funcionamiento del website. Asimismo,
                        Social Cloud S.L. tampoco garantiza la utilidad del
                        website o de sus contenidos para la realización de
                        ninguna actividad en particular. Social Cloud S.L.
                        excluye cualquier tipo de responsabilidad por los daños
                        y perjuicios de toda naturaleza que puedan deberse a la
                        falta de disponibilidad o de continuidad del
                        funcionamiento del website o a la defraudación de la
                        utilidad que los usuarios hubieren podido atribuir al
                        website. En particular, Social Cloud S.L. no asumirá
                        ningún tipo de responsabilidad por los posibles fallos
                        en el acceso a las distintas páginas del website de su
                        propiedad.
                        <br />
                        <br />
                        Social Cloud S.L. no controla ni garantiza la ausencia
                        de elementos de carácter informático en los contenidos
                        del website que puedan producir alteraciones en los
                        equipos para el proceso de información de los usuarios o
                        en cualquier tipo de archivo almacenado en el mismo.
                        Social Cloud S.L. excluye cualquier responsabilidad por
                        los daños y perjuicios de toda naturaleza que puedan
                        deberse a la presencia de elementos en los contenidos
                        del website que puedan provocar alteraciones en los
                        equipos para el proceso de información o en los archivos
                        informáticos de los usuarios guardados en los mismos.
                        <br />
                        <br />
                        Social Cloud S.L. excluye cualquier responsabilidad por
                        los daños y perjuicios de toda naturaleza que puedan
                        deberse a la transmisión, difusión, almacenamiento,
                        puesta disposición, recepción, obtención, acceso o
                        cualquier otra conducta análoga o similar a los
                        contenidos.
                        <br />
                        <br />
                        Social Cloud S.L. no garantiza la veracidad, exactitud,
                        exhaustividad y actualidad de los contenidos. Social
                        Cloud S.L. excluye cualquier tipo de responsabilidad por
                        los daños y perjuicios de toda naturaleza que puedan
                        deberse a la falta de veracidad, exactitud,
                        exhaustividad y/o actualidad de los contenidos. Social
                        Cloud S.L. no concede ninguna licencia o autorización de
                        uso de ninguna clase sobre sus derechos de propiedad
                        industrial e intelectual o sobre cualquier otra
                        propiedad o derecho relacionado con el website, los
                        servicios o contenidos.
                        <br />
                        <br />
                    </span>

                    <h3>
                        6. Obtención del documento sustitutivo de la factura
                    </h3>
                    <span>
                        El usuario de la website podrá acceder a un documento
                        justificativo del servicio prestado por Social Cloud
                        S.L., o ticket, exclusivamente en la fase de ticket de
                        la website, entendiendo con ello cumplidos todos los
                        requisitos de emisión y puesta a disposición al usuario
                        del ticket.
                        <br />
                        <br />
                        Cualquier otro documento al que el usuario tenga acceso,
                        y no se encuentre localizado en la fase de ticket de la
                        website no supone, a estos efectos, la puesta a
                        disposición de un documento sustitutivo de la factura,
                        conforme al Real Decreto 1496/2003, de 28 de noviembre,
                        por el que se aprueba el reglamento por el que se
                        regulan las obligaciones de facturación.
                        <br />
                        <br />
                    </span>

                    <h3>7. Duración y terminación</h3>
                    <span>
                        La duración del presente Aviso Legal es indefinida. No
                        obstante, Social Cloud S.L. podrá dar por terminada o
                        suspender la accesibilidad de cualquiera de los
                        contenidos ofrecidos en el website.
                        <br />
                        <br />
                        Asimismo, Social Cloud S.L., podrá modificar el
                        contenido del presente Aviso Legal en cualquier momento,
                        con la finalidad de adecuarlo a futuros cambios
                        legislativos o tecnológicos. Estas modificaciones se
                        considerará que han sido eficazmente notificadas desde
                        su publicación en la web siendo válidas desde ese
                        momento.
                        <br />
                        <br />
                    </span>

                    <h3>8. Ley aplicable, conciliación y jurisdicción</h3>
                    <span>
                        En caso de conflicto, dificultad o disputa con respecto
                        a cualquier material de este Aviso Legal, se someterá a
                        la jurisdicción de los Tribunales de Sevilla (España)
                        siempre que esto no infrinja la legislación vigente. El
                        presente Aviso Legal se regirá e interpretará de
                        conformidad con la legislación española. <br /> <br />
                    </span>

                    <h3>9. Comercio Electrónico</h3>
                    <span>
                        En cumplimiento del deber de información estipulado en
                        el Artículo 10 de la Ley 34/2002, de 11 de julio,
                        Servicios de la Sociedad de la Información y de Comercio
                        Electrónico (LSSI-CE), Social Cloud S.L. el responsable
                        de los contenidos y servicios ofrecidos a través del
                        sitio web{' '}
                        <a href="http://www.sporttia.com"> www.sporttia.com </a>{' '}
                        procede a informar los datos de identificación
                        requeridos por esa norma: Nombre de la empresa: Social
                        Cloud SL Dirección: Calle Seúl, 2B CP 41740 Lebrija -
                        Sevilla (España) CIF: B-90061284 Correo electrónico:{' '}
                        <a href="mailto:info@sporttia.com">
                            {' '}
                            info@sporttia.com{' '}
                        </a>{' '}
                        <br /> <br />
                    </span>

                    <h3>10. Protección de datos</h3>

                    <h4>Confidencialidad</h4>
                    <span>
                        De conformidad con lo dispuesto en la Ley 34/2002, de 11
                        de julio, de Servicios de la Sociedad de la Información
                        y de Comercio Electrónico (LSSI-CE), y en la Ley
                        Orgánica 15/1999, de 13 de diciembre, de Protección de
                        Datos.
                    </span>

                    <h4>Agencia Española de Protección de Datos</h4>
                    <span>
                        Le informamos que los datos personales que nos
                        proporcione a través de varios formularios y/o correos
                        electrónicos enviados a este sitio se incorporarán en el
                        archivo "USUARIOS". Este archivo está registrado en el
                        Registro General de la Agencia de Protección de Datos,
                        que es responsable, para administrar los servicios que
                        los usuarios solicitan a través del sitio web{' '}
                        <a href="http <= _Web?>: //www.sporttia.com ">
                            {' '}
                            www.sporttia.com{' '}
                        </a>
                        , así como el posible envío de información comercial o
                        actividades promocionales que puedan ser de su interés.
                        Al enviar sus datos personales a través del formulario,
                        usted da su consentimiento expreso para el procesamiento
                        y la documentación de los datos en los términos de la
                        Ley 15/1999, de 13 de diciembre (LOPD) y el Real Decreto
                        1720/2007, de 21 de diciembre. <br />
                    </span>

                    <h4>Datos de terceras personas</h4>
                    <span>
                        En el supuesto de que el usuario facilite datos de
                        carácter personal de terceros, en cumplimiento de lo
                        dispuesto en el artículo 5.4 de la Ley Orgánica 15/1999,
                        de 13 de diciembre, de Protección de Datos de Carácter
                        Personal, declara contar con el consentimiento previo y
                        expreso de los mismos e informándoles a dichos terceros,
                        del contenido de los datos facilitados, de la
                        procedencia de los mismos, de la existencia y finalidad
                        del fichero donde se contienen sus datos, de la
                        posibilidad de ejercitar los derechos de acceso,
                        rectificación, cancelación y oposición y así como los
                        términos establecidos en la presente política de
                        protección de datos.
                    </span>

                    <h4>Veracidad de los datos</h4>
                    <span>
                        El hecho de enviar datos personales implica que el
                        usuario reconoce que la información y los datos
                        personales que nos proporciona son verídicos, suyos,
                        exactos, ciertos y comprometiéndose a no introducir
                        datos falsos y a proceder a la modificación de los
                        mismos si fuera necesario.
                    </span>

                    <h4>
                        Envío de datos personales mediante formularios y correos
                    </h4>
                    <span>
                        El envío de correos electrónicos o la cumplimentación de
                        formularios incluidos en el presente sitio web para
                        formalizar los servicios que se ofrecen a través de
                        sporttia.com y solicitan los usuarios como alquiler y
                        reservas de instalaciones deportivas, inscripciones en
                        escuelas deportivas, torneos, participación en la red
                        social, etc., implica el consentimiento libre,
                        inequívoco, específico, informado y expreso del usuario
                        a la inclusión de sus datos de carácter personal en un
                        fichero denominado "usuarios"y da su consentimiento a
                        que sus datos sean utilizados para el envío de
                        información comercial o promociones de actividades
                        relacionadas con la prestación, mejora y actualizaciones
                        de los servicios prestados que puedan ser de su interés.
                        La falta de cumplimentación de los campos determinados
                        como obligatorios o el suministro de datos incorrectos
                        imposibilitará que www.sporttia.com pueda prestarle los
                        servicios que seleccione, gestionar su solicitud o
                        remitirle la información solicitada.
                    </span>

                    <h4>Consentimiento del usuario</h4>
                    <span>
                        Al facilitar sus datos personales a www.sporttia.com,
                        los usuarios estarán dando su consentimiento libre,
                        inequívoco, específico, informado y expreso para el
                        tratamiento de sus datos personales con la finalidad
                        establecida en el punto anterior, datos que serán
                        incorporados a un fichero denominado "usuarios". El
                        usuario, en cualquier momento podrá ejercitar los
                        derechos de acceso, rectificación, cancelación y
                        oposición, comunicándolo a www.sporttia.com, a las
                        direcciones anteriormente indicadas.
                        <br />
                        <br />
                        Social Cloud S.L. informa que en esta política de
                        privacidad se contienen todos los aspectos relacionados
                        con el tratamiento de datos personales que
                        www.sporttia.com lleva a cabo como responsable del
                        mismo. Así, se informa que cuando el usuario no mantenga
                        relaciones comerciales con www.sporttia.com y realice el
                        envío de un correo electrónico, dicho usuario está dando
                        su consentimiento libre, inequívoco, específico,
                        informado y expreso para el tratamiento de sus datos
                        personales a www.sporttia.com con las finalidades
                        establecidas anteriormente, así como atender su
                        comunicación o enviar documentación.
                    </span>

                    <h4>Seguridad</h4>
                    <span>
                        www.sporttia.com ha adaptado los niveles de seguridad de
                        protección de datos personales exigidos por el
                        Reglamento de desarrollo de la Ley Orgánica 15/1999, de
                        protección de datos personales (Real Decreto 1720/2007).
                    </span>

                    <h4>Uso de cookies</h4>
                    <span>
                        www.sporttia.com puede usar cookies cuando un usuario
                        navega por sus sitios web y páginas web.
                    </span>
                    <br />
                    <br />
                    <br />
                    <br />
                    <span>
                        Equipo de <b>Sporttia.com</b>
                    </span>
                </p>
            </div>
        </div>
    );
}
