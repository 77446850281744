import {
    Grid,
    TextField,
    InputAdornment,
    ClickAwayListener,
    GridProps,
} from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../all';

function convert(value: string) {
    if (value === undefined || value === null || value === '') {
        return '';
    }

    let result;
    if (Number.isNaN(Number(value))) {
        const number = parseFloat(value.replace(',', '.'));
        if (Number.isNaN(Number(number))) {
            result = '';
        } else {
            result = number.toFixed(2).toString().replace('.', ',');
        }
    } else {
        result = parseFloat(value).toFixed(2).toString().replace('.', ',');
    }

    return result;
}

type SttFormPriceProps = {
    grid?: boolean;
    caption: string;
    onChange: ({ name, value }: { name: string; value: string }) => void;
    name: string;
    disabled?: boolean;
    defVal: string;
} & Omit<GridProps, 'onChange'>;

export function SttFormPrice({
    grid = false,
    xs = 6,
    sm = 6,
    md = 3,
    caption,
    onChange,
    defVal,
    name,
    disabled,
    ...rest
}: SttFormPriceProps) {
    const cxt = useContext(AppContext)!;
    const [val, setVal] = useState(defVal);
    const [displayVal, setDisplayVal] = useState(convert(defVal));
    const [valueChanged, setValueChanged] = useState(false);
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        if (!Number.isNaN(Number(defVal))) {
            setVal(defVal);
        }
    }, [defVal]);

    useEffect(() => {
        if (focused) {
            setDisplayVal(val);
            setValueChanged(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [val]);

    function applyChange() {
        if (valueChanged) {
            setDisplayVal(convert(val));
            onChange({ name, value: val });
            setValueChanged(false);
        }
    }

    const content = (
        <ClickAwayListener onClickAway={() => focused && applyChange()}>
            <TextField
                fullWidth
                variant="outlined"
                label={caption}
                size="small"
                value={displayVal || ''}
                onChange={(e) => setVal(e.target.value)}
                onFocus={() => setFocused(true)}
                onBlur={() => {
                    setFocused(false);
                    applyChange();
                }}
                onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                        applyChange();
                    }
                }}
                disabled={disabled}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {cxt.sc!.city!.country!.currencySymbol}
                        </InputAdornment>
                    ),
                }}
            />
        </ClickAwayListener>
    );

    return grid ? (
        <Grid item xs={xs} sm={sm} md={md} {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
