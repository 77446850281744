import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, CircularProgress, Container } from '@material-ui/core';
import { AppContext, SttTable, SttNoResults } from '../../../sporttia/all';
import { getPath } from '../../Pages';
import { formatPriceByLocale } from '../../../lib/utils';
import useScsService from '../../../services/ScsService';
import { useTranslations } from '../../../lib/hooks';
import Group from '../../../types/models/Group';
import SttError from '../../../components/error/SttError';

const flatGroupsAndChildren = (scGroups: Group[]) =>
    scGroups.reduce((groups: Group[], group) => {
        const { children, ...withoutChildren } = group;
        children?.rows.forEach((child) => {
            groups.push(child);
        });
        groups.push(withoutChildren);
        return groups;
    }, []);

type Params = {
    id: string;
};

export default function AbonoList() {
    const cxt = useContext(AppContext)!;
    const history = useHistory();
    const { translate } = useTranslations();

    const { id: idSc } = useParams<Params>();

    const scsService = useScsService();

    const getScsGroupsQuery = scsService.useGetScsGroups<Group[]>(
        Number(idSc),
        {
            types: 'ABONADOS',
            purchaseOnline: true,
            rows: 100,
            includeChildren: true,
            inTime: true,
            inFuture: true,
            onlyRoots: false,
        },
        {
            select: (data) => flatGroupsAndChildren(data.rows),
        },
    );

    if (getScsGroupsQuery.isLoading) {
        return (
            <Container maxWidth="md">
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    if (getScsGroupsQuery.isError) {
        return <SttError />;
    }

    if (!getScsGroupsQuery.data) {
        return null;
    }

    return (
        <Container maxWidth="md">
            {!getScsGroupsQuery.data.length ? (
                <SttNoResults />
            ) : (
                // @ts-expect-error: Migrar SttTable
                <SttTable
                    data={{
                        count: getScsGroupsQuery.data.length,
                        rows: getScsGroupsQuery.data,
                    }}
                    columns={[
                        {
                            title: cxt.t('Name'),
                            type: 'link',
                            align: 'left',
                            value: (row: Group) => row.name,
                            onClick: (row: Group) => {
                                history.push(
                                    getPath('buyAbono', {
                                        idSc,
                                        idGroup: row.id,
                                    }),
                                );
                            },
                        },
                        {
                            title: cxt.t('Enrollment'),
                            type: 'text',
                            value: (row: Group) =>
                                formatPriceByLocale(
                                    row?.enrollmentPrice,
                                    // @ts-expect-error: Migrate utils
                                    row?.enrollmentShape?.currency,
                                ),
                        },
                        {
                            title: cxt.t('Fee'),
                            type: 'text',
                            value: (row: Group) =>
                                row.feeInterval &&
                                `${formatPriceByLocale(
                                    row?.feePrice,
                                    // @ts-expect-error: Migrate utils
                                    row?.feeShape?.currency,
                                )}/${translate(row.feeInterval)}`,
                        },
                    ]}
                />
            )}
        </Container>
    );
}
