import React, { useContext } from 'react';
import { Box, Chip, Grid, Typography } from '@material-ui/core';
import { AppContext } from '../AppContext';
import { formatPriceByLocale } from '../../lib/utils';

export function SttChipRate({
    rates,
    title = '',
    color = 'primary',
    maxWidth = 'auto',
    grid = false,
    xs = 12,
    justifyContent = 'left',
    ...rest
}) {
    const cxt = useContext(AppContext);

    function unifyRate(rate) {
        const name = rate.name ? rate.name : '';
        const duration = rate.duration ? `${rate.duration} min` : '';
        const price = rate.price
            ? formatPriceByLocale(
                  rate.price,
                  rate?.priceShape?.currency,
                  rate?.priceShape?.locale,
              )
            : '';
        return `${name} ${duration} ${price}`;
    }

    const content = (
        <>
            {title !== '' && <Typography variant="caption">{title}</Typography>}
            <Box
                display="flex"
                maxWidth={maxWidth}
                flexWrap="wrap"
                justifyContent={justifyContent}
                alignItems="center"
            >
                {rates &&
                    rates.map((rate, idx) => (
                        <Box key={idx} mr={1}>
                            <Chip
                                color={color}
                                size="small"
                                label={unifyRate(rate)}
                            />
                        </Box>
                    ))}
            </Box>
        </>
    );

    // -----| Render |-----
    return grid ? (
        <Grid item xs={xs} {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
