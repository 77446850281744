import React, { ReactNode } from 'react';
import { Chip, Grid, GridSpacing } from '@material-ui/core';
import {
    SttFormCheck,
    SttFormDate,
    SttFormPeriod,
    SttFormSearch,
    SttFormSelect,
    SttFormSwitch,
    SttFormText,
    SttSelectSport,
    SttSelectYear,
    SttSelectAutocomplete,
    SttSelectAsync,
    SttSelectMultiple,
    SttLabelForm,
    SttFormTime,
    SttFormPrice,
    SttSelectGroup,
    SttSelectCity,
} from '../../sporttia/all';
import { SttSelectableChips } from '../../sporttia/SttSelectableChips';
import { SttSelectMonth } from '../../sporttia/selectors/SttSelectMonth';
import {
    ItemsSizes,
    FilterField,
    FilterOnChangeCallback,
    FilterOnChangeParam,
} from './filterTypes';
import { toLowerCase } from '../../types/common';
import { SttFormSelectPeriod } from '../../sporttia/forms/SttFormSelectPeriod';

type FilterFieldsProps = {
    fields: FilterField[];
    onChange: FilterOnChangeCallback;
    spacing: GridSpacing;
    action?: ReactNode;
};

/**
 * Component to render a collection of form fields in a grid
 */
export default function FilterFields({
    fields,
    onChange,
    spacing,
    action,
}: FilterFieldsProps) {
    function handleChange(
        { name, value }: FilterOnChangeParam,
        fieldCallback?: FilterOnChangeCallback,
    ) {
        const callback =
            typeof fieldCallback === 'function' ? fieldCallback : onChange;

        if (typeof callback === 'function') {
            callback({ name, value });
        }
    }

    function renderField(field: FilterField) {
        const formatedField = { ...field };

        // Directly mutate field by converting its' name to lowercase allows the compiler to infer the correct type
        // Also adding default case
        formatedField.type = formatedField.type
            ? toLowerCase(formatedField.type)
            : 'text';

        switch (formatedField.type) {
            case 'text':
                return (
                    <SttFormText
                        name={formatedField.name}
                        caption={formatedField.caption}
                        defVal={formatedField.value}
                        onChange={({ name, value }) => {
                            handleChange(
                                { name, value },
                                formatedField.onChange,
                            );
                        }}
                        onEnterKey={formatedField.onEnterKey}
                    />
                );
            case 'search':
                return (
                    <SttFormSearch
                        name={formatedField.name}
                        caption={formatedField.caption}
                        onChange={({ name, value }) => {
                            handleChange(
                                { name, value },
                                formatedField.onChange,
                            );
                        }}
                        defVal={formatedField.value}
                        onClickSearch={() => formatedField.onEnterKey?.()}
                    />
                );
            case 'check':
                return (
                    <SttFormCheck
                        color="primary"
                        name={formatedField.name}
                        caption={formatedField.caption}
                        checked={formatedField.checked}
                        onChange={({ name, value }) => {
                            handleChange(
                                { name, value },
                                formatedField.onChange,
                            );
                        }}
                    />
                );
            case 'checkmulti':
                return (
                    <>
                        <SttLabelForm text={formatedField.caption} />
                        <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            {formatedField.children.map((subField, index) => (
                                <SttFormCheck
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    color="primary"
                                    name={
                                        subField.name !== undefined
                                            ? subField.name
                                            : formatedField.name
                                    }
                                    caption={subField.caption}
                                    checked={subField.checked}
                                    onChange={({ name, value }) =>
                                        handleChange(
                                            { name, value },
                                            subField.onChange ||
                                                formatedField.onChange,
                                        )
                                    }
                                />
                            ))}
                        </div>
                    </>
                );
            case 'selectablechips':
                return (
                    <>
                        <SttLabelForm text={formatedField.caption} />
                        <SttSelectableChips
                            name={formatedField.name}
                            items={formatedField.items}
                            selected={formatedField.selected}
                            onChange={({ name, value }) => {
                                handleChange(
                                    { name, value },
                                    formatedField.onChange,
                                );
                            }}
                            ChipComponent={formatedField.chip || Chip}
                        />
                    </>
                );
            case 'switch':
                return (
                    <SttFormSwitch
                        color="primary"
                        name={formatedField.name}
                        caption={formatedField.caption}
                        checked={formatedField.checked}
                        onChange={({ name, value }) => {
                            handleChange(
                                { name, value },
                                formatedField.onChange,
                            );
                        }}
                    />
                );
            case 'select':
                return (
                    <SttFormSelect
                        name={formatedField.name}
                        options={formatedField.options}
                        defVal={formatedField.value}
                        caption={formatedField.caption}
                        mandatory={formatedField.mandatory}
                        onChange={({ name, value }: FilterOnChangeParam) => {
                            handleChange(
                                { name, value },
                                formatedField.onChange,
                            );
                        }}
                        cleanable={formatedField.cleanable}
                    />
                );
            case 'selectmulti':
                return (
                    // @ts-expect-error TS(2739): Type '{ name: string; caption: string; defVal: num... Remove this comment to see the full error message
                    <SttSelectMultiple
                        name={formatedField.name}
                        caption={formatedField.caption}
                        defVal={formatedField.value}
                        options={formatedField.options}
                        onChange={({ name, value }: FilterOnChangeParam) => {
                            handleChange(
                                { name, value },
                                formatedField.onChange,
                            );
                        }}
                    />
                );
            case 'selectasync':
                return (
                    // @ts-expect-error TS(2741): Property 'grid' is missing in type '{ name: string... Remove this comment to see the full error message
                    <SttSelectAsync
                        name={formatedField.name}
                        caption={formatedField.caption}
                        loader={formatedField.loader}
                        defVal={formatedField.value}
                        labelField={formatedField.labelField}
                        valueField={formatedField.valueField}
                        onChange={({ name, value }: FilterOnChangeParam) => {
                            handleChange(
                                { name, value },
                                formatedField.onChange,
                            );
                        }}
                    />
                );
            case 'selectautocomplete':
                return (
                    // @ts-expect-error TS(2741): Property 'grid' is missing in type '{ name: string... Remove this comment to see the full error message
                    <SttSelectAutocomplete
                        name={formatedField.name}
                        caption={formatedField.caption}
                        options={formatedField.options}
                        defVal={formatedField.value}
                        labelField={formatedField.labelField}
                        valueField={formatedField.valueField}
                        onChange={({ name, value }: FilterOnChangeParam) => {
                            handleChange(
                                { name, value },
                                formatedField.onChange,
                            );
                        }}
                    />
                );
            case 'date':
                return (
                    // @ts-expect-error TS(2741): Property 'disabled' is missing in type '{ caption:... Remove this comment to see the full error message
                    <SttFormDate
                        caption={formatedField.caption}
                        name={formatedField.name}
                        defVal={formatedField.value}
                        onChange={({ name, value }: FilterOnChangeParam) => {
                            handleChange(
                                { name, value },
                                formatedField.onChange,
                            );
                        }}
                    />
                );
            case 'period':
                return (
                    <SttFormPeriod
                        checked={formatedField.enabled}
                        caption={formatedField.caption}
                        nameIni={formatedField.nameIni}
                        nameEnd={formatedField.nameEnd}
                        defValIni={formatedField.valIni}
                        defValEnd={formatedField.valEnd}
                        onChange={({ name, value }: FilterOnChangeParam) => {
                            handleChange(
                                { name, value },
                                formatedField.onChange,
                            );
                        }}
                        onToggle={formatedField.onToggle}
                    />
                );
            case 'year':
                return (
                    <SttSelectYear
                        name={formatedField.name}
                        caption={formatedField.caption}
                        defVal={formatedField.value}
                        startYear={
                            formatedField.years
                                ? formatedField.years.start
                                : undefined
                        }
                        pastYears={
                            formatedField.years
                                ? formatedField.years.past
                                : undefined
                        }
                        futureYears={
                            formatedField.years
                                ? formatedField.years.future
                                : undefined
                        }
                        onChange={({ name, value }: FilterOnChangeParam) => {
                            handleChange(
                                { name, value },
                                formatedField.onChange,
                            );
                        }}
                    />
                );
            case 'sport':
                return (
                    <SttSelectSport
                        name={formatedField.name}
                        caption={formatedField.caption}
                        defVal={formatedField.value}
                        onChange={({ name, value }: FilterOnChangeParam) => {
                            handleChange(
                                { name, value },
                                formatedField.onChange,
                            );
                        }}
                    />
                );
            case 'month':
                return (
                    <SttSelectMonth
                        name={formatedField.name}
                        caption={formatedField.caption}
                        // @ts-expect-error: Migrate SttSelectMonth
                        defVal={formatedField.value}
                        firstMonth={
                            formatedField.months
                                ? formatedField.months.first
                                : undefined
                        }
                        lastMonth={
                            formatedField.months
                                ? formatedField.months.last
                                : undefined
                        }
                        onChange={({ name, value }: FilterOnChangeParam) => {
                            handleChange(
                                { name, value },
                                formatedField.onChange,
                            );
                        }}
                    />
                );
            case 'time':
                return (
                    // @ts-expect-error TS(2322): Type '{ name: string; caption: string; defVal: num... Remove this comment to see the full error message
                    <SttFormTime
                        name={formatedField.name}
                        caption={formatedField.caption}
                        defVal={formatedField.value}
                        min="00:00:00"
                        max="23:50:00"
                        interval={formatedField.interval || 5}
                        onChange={({ name, value }: FilterOnChangeParam) => {
                            handleChange(
                                { name, value },
                                formatedField.onChange,
                            );
                        }}
                    />
                );
            case 'price':
                return (
                    <SttFormPrice
                        name={formatedField.name}
                        caption={formatedField.caption}
                        defVal={formatedField.value}
                        onChange={({ name, value }: FilterOnChangeParam) => {
                            handleChange(
                                { name, value },
                                formatedField.onChange,
                            );
                        }}
                    />
                );
            case 'group':
                return (
                    <SttSelectGroup
                        name={formatedField.name}
                        caption={formatedField.caption}
                        defVal={formatedField.value}
                        onChange={({ name, value }) => {
                            handleChange(
                                { name, value },
                                formatedField.onChange,
                            );
                        }}
                    />
                );
            case 'city':
                return (
                    // @ts-expect-error TS(2739): Type '{ name: string; caption: string; defVal: num... Remove this comment to see the full error message
                    <SttSelectCity
                        name={formatedField.name}
                        caption={formatedField.caption}
                        defVal={formatedField.value}
                        onSelect={({ name, value }: FilterOnChangeParam) => {
                            handleChange(
                                { name, value },
                                formatedField.onChange,
                            );
                        }}
                    />
                );
            case 'selectperiod':
                return (
                    <SttFormSelectPeriod
                        selectCaption={formatedField.selectCaption}
                        selectName={formatedField.selectName}
                        selectValue={formatedField.selectValue}
                        selectOptions={formatedField.selectOptions}
                        nameIni={formatedField.nameIni}
                        nameEnd={formatedField.nameEnd}
                        valIni={formatedField.valIni}
                        valEnd={formatedField.valEnd}
                        disableOnValue={formatedField.disableOnValue}
                        onChange={({ name, value }) => {
                            handleChange(
                                { name, value },
                                formatedField.onChange,
                            );
                        }}
                    />
                );
            default:
        }
    }

    if (!fields) {
        return null;
    }

    const itemSizes: ItemsSizes = {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
    };

    return (
        <Grid container spacing={spacing}>
            {fields.map((field, index) => (
                <Grid
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    item
                    // This looks retarded, but it's a cheap fallback mechanism for undefined layout props.
                    // For example if the item's "lg" is not defined, it'll try using the item's "md", then "sm, then "xs" THEN the default itemSizes.lg if all else fails
                    xs={field.xs || itemSizes.xs}
                    sm={field.sm || field.xs || itemSizes.sm}
                    md={field.md || field.sm || field.xs || itemSizes.md}
                    lg={
                        field.lg ||
                        field.md ||
                        field.sm ||
                        field.xs ||
                        itemSizes.lg
                    }
                >
                    {renderField(field)}
                </Grid>
            ))}

            {action && (
                <Grid item {...itemSizes}>
                    {action}
                </Grid>
            )}
        </Grid>
    );
}
