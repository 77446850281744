import React, { useEffect, useContext } from 'react';
import moment from 'moment';
import { AppContext, SttFullDialog, SttTabs } from '../../../sporttia/all';
import BonoDetails from './BonoDetails';
import BonoBoletos from './BonoBoletos';
import BonoDevices from './BonoDevices';
import { useCrud } from '../../../lib/hooks';

export default function Bono({ id, trash, onChange, onClose }) {
    const cxt = useContext(AppContext);
    const [bono, setBono, , Get] = useCrud();

    useEffect(() => {
        // load
        if (!isNaN(parseInt(id))) {
            loadBono();
        }
        // creating
        else if (id === 'create') {
            setBono({
                id: 'create',
                name: '',
                dateini: moment().format('YYYY-MM-DD'),
                dateend: moment().format('YYYY-12-31'),
                timeini: '06:00',
                timeend: '23:30',
                weekdays: [0, 1, 2, 3, 4, 5, 6],
                type: 'COMPLETE',
                expiration: '0000-00-00 00:00:00',
                amount: 10,
                purchaseOnline: true,
                purchasePFs: [],
                transferable: false,
                mandatoryGroup: null,
                price: 0,
            });
        }
        // offload
        else {
            setBono(null);
        }
    }, [id]);

    function loadBono() {
        Get(`/bonos/${id}`, { trash }).then((response) => {
            const bonoResponse = response.bono;
            // Fix to force bonos to have default value if null
            if (bonoResponse.expiration == null) {
                bonoResponse.expiration = '0000-00-00 00:00:00';
            }
            setBono(bonoResponse);
        });
    }

    const tabs = [
        {
            caption: cxt.t('Detail'),
            component: (
                <BonoDetails
                    item={bono}
                    onSave={(bonoToSave) => {
                        setBono(bonoToSave);
                        onChange(bonoToSave);
                    }}
                    onDelete={(bonoToDelete) => {
                        onChange(bonoToDelete);
                        onClose?.();
                    }}
                />
            ),
        },
        {
            caption: cxt.t('Users'),
            component: <BonoBoletos item={bono} />,
            show: bono?.id !== 'create',
        },
        {
            caption: cxt.t('Devices'),
            component: bono && <BonoDevices item={bono} />,
            show: bono?.id !== 'create',
        },
    ];

    return (
        <SttFullDialog
            title={id === 'create' ? cxt.t('CreateBono') : bono && bono.name}
            open={!!bono}
            onClose={onClose}
        >
            <SttTabs tabs={tabs} />
        </SttFullDialog>
    );
}
