import React, { useState, useEffect, useContext } from 'react';
import { Select, Grid, MenuItem } from '@material-ui/core';
import { AppContext, SttFormSelect } from '../all';

/**
 * SttSelectFixedInterval: present a list of pre-defined time intervals (as they are defined in the back end). Used for fees and such.
 * bool grid: wrap in a Grid item
 * string name: field name, for forms and such
 * string caption
 * string defVal: currently selected value
 * <string>array options: enabled options. Must be a subset of the OPTIONS defined below, for example: ['1 DAY', '2 DAY', '1 WEEK'...]
 * ...rest of the props, typically xs, md, etc.
 */
export function SttSelectFixedInterval({
    grid,
    name,
    caption,
    defVal,
    options,
    onChange,
    cleanable = false,
    size = 'small',
    variant = 'outlined',
    ...rest
}) {
    const cxt = useContext(AppContext);

    // Available options to pick from.
    const OPTIONS = [
        {
            name: 'NO PERIOD',
            label: cxt.t('0000-00-00 00:00:00'),
            value: '0000-00-00 00:00:00',
        },
        {
            name: '1 DAY',
            label: cxt.t('0000-00-01 00:00:00'),
            value: '0000-00-01 00:00:00',
        },
        {
            name: '2 DAY',
            label: cxt.t('0000-00-02 00:00:00'),
            value: '0000-00-02 00:00:00',
        },
        {
            name: '3 DAY',
            label: cxt.t('0000-00-03 00:00:00'),
            value: '0000-00-03 00:00:00',
        },
        {
            name: '4 DAY',
            label: cxt.t('0000-00-04 00:00:00'),
            value: '0000-00-04 00:00:00',
        },
        {
            name: '5 DAY',
            label: cxt.t('0000-00-05 00:00:00'),
            value: '0000-00-05 00:00:00',
        },
        {
            name: '6 DAY',
            label: cxt.t('0000-00-06 00:00:00'),
            value: '0000-00-06 00:00:00',
        },
        {
            name: '1 WEEK',
            label: cxt.t('0000-00-07 00:00:00'),
            value: '0000-00-07 00:00:00',
        },
        {
            name: '2 WEEK',
            label: cxt.t('0000-00-14 00:00:00'),
            value: '0000-00-14 00:00:00',
        },
        {
            name: 'FORTNIGHT',
            label: cxt.t('0000-00-15 00:00:00'),
            value: '0000-00-15 00:00:00',
        },
        {
            name: '1 MONTH',
            label: cxt.t('0000-01-00 00:00:00'),
            value: '0000-01-00 00:00:00',
        },
        {
            name: '2 MONTH',
            label: cxt.t('0000-02-00 00:00:00'),
            value: '0000-02-00 00:00:00',
        },
        {
            name: '3 MONTH',
            label: cxt.t('0000-03-00 00:00:00'),
            value: '0000-03-00 00:00:00',
        },
        {
            name: '4 MONTH',
            label: cxt.t('0000-04-00 00:00:00'),
            value: '0000-04-00 00:00:00',
        },
        {
            name: '6 MONTH',
            label: cxt.t('0000-06-00 00:00:00'),
            value: '0000-06-00 00:00:00',
        },
        {
            name: '1 YEAR',
            label: cxt.t('0001-00-00 00:00:00'),
            value: '0001-00-00 00:00:00',
        },
    ];

    let usedOptions;
    if (options && options.length > 0) {
        // Add options explicitly included
        usedOptions = OPTIONS.filter((opt) => options.includes(opt.name));

        // Check for explicitly excluded options instead
        if (usedOptions.length == 0) {
            usedOptions = OPTIONS.filter(
                (opt) => !options.includes(`!${opt.name}`),
            );
        }
    } else {
        usedOptions = OPTIONS;
    }

    const content = (
        <SttFormSelect
            name={name}
            caption={caption}
            defVal={defVal}
            options={usedOptions}
            onChange={onChange}
            cleanable={cleanable}
        />
    );

    return grid ? (
        <Grid item {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
