import { useContext } from 'react';
import {
    MutationFunction,
    UseMutationOptions,
    UseQueryOptions,
    useMutation,
    useQuery,
} from 'react-query';
import useRestService from './RestService';
import { useAPI } from '../lib/hooks';
import { AppContext } from '../sporttia/AppContext';
import {
    CreateGroupMember200,
    CreateGroupMemberBody,
    GetGroupDiscounts200,
    GetGroupDiscountsParams,
    UpdateGroupMember200,
    UpdateGroupMemberBody,
    CreateGroupDiscount200,
    DeleteGroupDiscount200,
    GetGroupMembersParams,
    GetGroupMembers200,
    GetGroups200,
    GetGroupsParams,
    GetGroupParams,
    GetGroup200,
} from '../types/api/paths/Group';

const resource = '/groups';

export const getGetGroupsQueryKey = (params?: GetGroupsParams) => [
    resource,
    ...(params ? [params] : []),
];

export const getGetGroupQueryKey = (id: number, params?: GetGroupParams) => [
    `${resource}/${id}`,
    ...(params ? [params] : []),
];

export const getGetGroupMembersQueryKey = (
    id: number,
    params?: GetGroupMembersParams,
) => [`${resource}/${id}/members`, ...(params ? [params] : [])];

export const getGetGroupDiscountsQueryKey = (
    id: number,
    params?: GetGroupDiscountsParams,
) => [`${resource}/${id}/discounts`, ...(params ? [params] : [])];

/**
 * Hook to access all 'groups' resources.
 */
export default function useGroupsService() {
    const { migrationApis } = useContext(AppContext)!;
    const [request] = useAPI(migrationApis);
    const services = useRestService(resource);

    /**
     * GET /groups request.
     */
    function useGetGroups<TData = GetGroups200>(
        params?: GetGroupsParams,
        queryOptions?: UseQueryOptions<GetGroups200, unknown, TData>,
    ) {
        const queryKey = queryOptions?.queryKey ?? getGetGroupsQueryKey(params);

        const queryFn = () => request<GetGroups200>('GET', resource, params);

        const query = useQuery(queryKey, queryFn, queryOptions);

        return {
            ...query,
            queryKey,
        };
    }

    /**
     * GET /groups/:id request.
     */
    function useGetGroup<TData = GetGroup200>(
        id: number,
        params?: GetGroupParams,
        queryOptions?: UseQueryOptions<GetGroup200, unknown, TData>,
    ) {
        const queryKey =
            queryOptions?.queryKey ?? getGetGroupQueryKey(id, params);

        const queryFn = () =>
            request<GetGroup200>('GET', `${resource}/${id}`, params);

        const query = useQuery(queryKey, queryFn, queryOptions);

        return {
            ...query,
            queryKey,
        };
    }

    /**
     * GET /groups/members/{id}/beneficiaries request.
     */
    function getBeneficiaries(id: number) {
        return request('GET', `${resource}/members/${id}/beneficiaries`);
    }

    /**
     * POST /groups/members/{id}/beneficiaries request.
     */
    function addBeneficiary(id: number, params: Record<string, unknown>) {
        return request(
            'POST',
            `${resource}/members/${id}/beneficiaries`,
            params,
        );
    }

    /**
     * DELETE /groups/members/beneficiaries/{id} request.
     */
    function deleteBeneficiary(id: number) {
        return request('DELETE', `${resource}/members/beneficiaries/${id}`);
    }

    /**
     * GET /groups/:id/members request.
     */
    function getGroupMembers(id: number, params: Record<string, unknown>) {
        return request('GET', `${resource}/${id}/members`, params);
    }

    /**
     * GET /groups/:id/members request.
     */
    function useGetGroupMembers<TData = GetGroupMembers200>(
        id: number,
        params?: GetGroupMembersParams,
        queryOptions?: UseQueryOptions<GetGroupMembers200, unknown, TData>,
    ) {
        const queryKey =
            queryOptions?.queryKey ?? getGetGroupMembersQueryKey(id, params);

        const queryFn = () =>
            request<GetGroupMembers200>(
                'GET',
                `${resource}/${id}/members`,
                params,
            );

        const query = useQuery(queryKey, queryFn, queryOptions);

        return {
            ...query,
            queryKey,
        };
    }

    /**
     * POST /groups/:id/members request.
     */
    function useCreateGroupMember(
        mutationOptions?: UseMutationOptions<
            CreateGroupMember200,
            unknown,
            {
                id: number;
                params?: CreateGroupMemberBody;
            }
        >,
    ) {
        const mutationFn: MutationFunction<
            CreateGroupMember200,
            {
                id: number;
                params?: CreateGroupMemberBody;
            }
        > = ({ id, params }) =>
            request('POST', `${resource}/${id}/members`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * PUT /groups/members/:id request.
     */
    function useUpdateGroupMember(
        mutationOptions?: UseMutationOptions<
            UpdateGroupMember200,
            unknown,
            {
                id: number;
                params: UpdateGroupMemberBody;
            }
        >,
    ) {
        const mutationFn: MutationFunction<
            UpdateGroupMember200,
            {
                id: number;
                params: UpdateGroupMemberBody;
            }
        > = ({ id, params }) => request('PUT', `/groups/members/${id}`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * GET /groups/:id/discounts request.
     */
    function useGetGroupDiscounts<TData = GetGroupDiscounts200>(
        id: number,
        params?: GetGroupDiscountsParams,
        queryOptions?: UseQueryOptions<GetGroupDiscounts200, unknown, TData>,
    ) {
        const queryKey =
            queryOptions?.queryKey ?? getGetGroupDiscountsQueryKey(id, params);

        const queryFn = () =>
            request<GetGroupDiscounts200>(
                'GET',
                `${resource}/${id}/discounts`,
                params,
            );

        const query = useQuery(queryKey, queryFn, queryOptions);

        return {
            ...query,
            queryKey,
        };
    }

    /**
     * POST /groups/:id/discounts/:id request.
     */
    function useCreateGroupDiscount(
        mutationOptions?: UseMutationOptions<
            CreateGroupDiscount200,
            unknown,
            {
                groupId: number;
                discountId: number;
            }
        >,
    ) {
        const mutationFn: MutationFunction<
            CreateGroupDiscount200,
            {
                groupId: number;
                discountId: number;
            }
        > = ({ discountId, groupId }) =>
            request('POST', `${resource}/${groupId}/discounts/${discountId}`);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * DELETE /groups/:id/discounts/:id request.
     */
    function useDeleteGroupDiscount(
        mutationOptions?: UseMutationOptions<
            DeleteGroupDiscount200,
            unknown,
            {
                groupId: number;
                discountId: number;
            }
        >,
    ) {
        const mutationFn: MutationFunction<
            DeleteGroupDiscount200,
            {
                groupId: number;
                discountId: number;
            }
        > = ({ discountId, groupId }) =>
            request('DELETE', `${resource}/${groupId}/discounts/${discountId}`);

        return useMutation(mutationFn, mutationOptions);
    }

    return {
        ...services,
        useGetGroup,
        useGetGroups,
        getBeneficiaries,
        addBeneficiary,
        deleteBeneficiary,
        getGroupMembers,
        useGetGroupMembers,
        useGetGroupDiscounts,
        useCreateGroupDiscount,
        useDeleteGroupDiscount,
        useCreateGroupMember,
        useUpdateGroupMember,
    };
}
