import React from 'react';
import { Avatar, Box, Chip, Tooltip } from '@material-ui/core';
import Discount from '../../types/models/Discount';
import { useTranslations } from '../../lib/hooks';
import translations from '../../translations';

type DiscountTooltipProps = {
    discounts: Discount[];
};

export default function DiscountTooltip({ discounts }: DiscountTooltipProps) {
    const { translate } = useTranslations();

    return discounts.length ? (
        <Tooltip
            title={
                <div
                    // Establecer varias líneas para el Tooltip de MUI
                    style={{
                        whiteSpace: 'pre-line',
                    }}
                >
                    {discounts
                        .map(
                            (discount) =>
                                `${discount.name} - ${discount.discountPercentage}%`,
                        )
                        .join('\n')}
                </div>
            }
        >
            <Box mt={1}>
                <Chip
                    size="small"
                    avatar={<Avatar>{discounts.length}</Avatar>}
                    label={translate(translations.generic.discounts)}
                />
            </Box>
        </Tooltip>
    ) : null;
}
