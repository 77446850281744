import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useTranslations } from '../../lib/hooks';

type SttButtonCancelProps = {
    onClick: () => void;
} & ButtonProps;

/**
 * Delete button
 */
export function SttButtonCancel({ onClick, ...rest }: SttButtonCancelProps) {
    const { translate } = useTranslations();

    return (
        <Button
            variant="outlined"
            onClick={onClick}
            startIcon={<CloseIcon />}
            {...rest}
        >
            {translate('Cancel')}
        </Button>
    );
}
