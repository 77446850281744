import React, { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { SttFullDialog } from '../../../../sporttia/dialogs/SttFullDialog';
import { useLoader, usePageHeader } from '../../../../lib/hooks';
import { AppContext } from '../../../../sporttia/AppContext';
import SttValidatedForm from '../../../../sporttia/SttValidatedForm';
import { SUPERADMIN_USER } from '../../../../lib/queryKeys';
import useUsersService from '../../../../services/UsersService';

type AdminUserProps = {
    id: number;
    onClose: () => void;
    onDelete: () => void;
    onUpdate: () => void;
    onCreate: () => void;
};

type DeleteMutationParams = {
    id: number;
};

type UpdateMutationParams = {
    id: number;
    params: Record<string, unknown>;
};

type CreateMutationParams = {
    params: Record<string, unknown>;
};

// ¿Esto quizá debería ir en un tipo común? Asumiendo que las respuestas de 'cosas de user' siempre sean compatibles, claro.
type UserData = {
    id: number;
    name: string;
    surname1: string;
    surname2: string;
    fullName: string;
    login: string;
    email: string;
    mobile: string;
    password: string | undefined;
    birthday: string;
    x: number;
    y: number;
    status: string;
    dni: string;
    iban: string;
    postalCode: string;
    address: string;
    privilege: string;
    debug: number;
    comActive: boolean;
    trash: boolean;
};

// Esto quizá debería ser un UserService.Response o algo así
type UserResponse = {
    user: UserData;
};

// Y esto a lo mejor podría ser un SttError
type Error = {
    error: {
        msg: string;
    };
};

/**
 * User view, contains user form.
 * @param id User ID.
 * @param onClose Function executed when closing the full dialog.
 * @param onDelete Action taken when creating the tpv configuration.
 * @param onUpdate Action taken when updating the tpv configuration.
 * @param onCreate Method executed when removing tpv configuration.
 * @returns {JSX.Element}
 */
export default function AdminUser({
    id,
    onClose,
    onDelete,
    onUpdate,
    onCreate,
}: AdminUserProps) {
    const cxt = useContext(AppContext)!;
    usePageHeader('Users');
    const form = useForm();
    const queryClient = useQueryClient();
    const usersService = useUsersService();
    const [userId, setUserId] = useState(id);

    const userQuery = useQuery<UserResponse>(
        [SUPERADMIN_USER, { id: userId }],
        () => usersService.get<UserResponse>(userId, { trash: true }),
        { enabled: userId !== null },
    );

    const deleteMutation = useMutation(
        (params: DeleteMutationParams) => usersService.remove(params?.id),
        {
            onSuccess: onDelete,
        },
    );

    const updateMutation = useMutation(
        (params: UpdateMutationParams) =>
            usersService.update<UserResponse>(params?.id, params?.params),
        {
            onSuccess: (data: UserResponse) => {
                queryClient.setQueryData(
                    [SUPERADMIN_USER, { id: data?.user?.id }],
                    data,
                );
                onUpdate();
            },
            onError: (error: Error) => cxt.showMessage('E', error?.error?.msg),
        },
    );
    const createMutation = useMutation(
        (params: CreateMutationParams) =>
            usersService.create<UserResponse>(params?.params),
        {
            onSuccess: (data: UserResponse) => {
                queryClient.setQueryData(
                    [SUPERADMIN_USER, { id: data?.user?.id }],
                    data,
                );
                onCreate();
            },
        },
    );
    const [, loader] = useLoader([
        deleteMutation.status,
        updateMutation.status,
        createMutation.status,
    ]);

    /**
     * Method executed when the user clicks on save or create user.
     * @param data Form data.
     */
    const mutateUser = (data: FieldValues) => {
        let password: string | undefined;
        if (data.password) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            password = data.password;
        }
        updateMutation.mutate({ id: userId, params: { ...data, password } });
    };

    /**
     * When the "user" data is returned by the API, it is set in the form.
     */
    useEffect(() => {
        if (userQuery?.status === 'success') {
            form.reset({
                name: userQuery?.data?.user?.name,
                surname1: userQuery?.data?.user?.surname1,
                surname2: userQuery?.data?.user?.surname2,
                login: userQuery?.data?.user?.login,
                password: '',
                email: userQuery?.data?.user?.email,
                mobile: userQuery?.data?.user?.mobile,
                birthday: userQuery?.data?.user?.birthday,
                x: userQuery?.data?.user?.x,
                y: userQuery?.data?.user?.y,
                status: userQuery?.data?.user?.status,
                dni: userQuery?.data?.user?.dni,
                iban: userQuery?.data?.user?.iban,
                postalCode: userQuery?.data?.user?.postalCode,
                address: userQuery?.data?.user?.address,
                privilege: userQuery?.data?.user?.privilege,
                debug: userQuery?.data?.user?.debug,
                comActive: userQuery?.data?.user?.comActive,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userQuery?.data?.user]);

    /**
     * Update user id if id prop was changed and clear form data.
     */
    useEffect(() => {
        setUserId(id);
        form.reset();
    }, [form, id]);

    let title = '';
    if (!userQuery.isLoading) {
        title = userQuery?.data?.user?.id
            ? userQuery?.data?.user?.fullName
            : cxt.t('admin.customer.create');
    }

    return (
        <SttFullDialog open={id !== null} onClose={onClose} title={title}>
            {loader}
            <Paper>
                <Box p={3}>
                    <SttValidatedForm
                        form={form}
                        loadingData={userQuery?.status === 'loading'}
                        fields={[
                            {
                                caption: cxt.t('Name'),
                                name: 'name',
                                md: 2,
                            },
                            {
                                caption: cxt.t('Surname1'),
                                name: 'surname1',
                                md: 2,
                            },
                            {
                                caption: cxt.t('Surname2'),
                                name: 'surname2',
                                md: 2,
                            },
                            {
                                caption: cxt.t('Login'),
                                name: 'login',
                                md: 2,
                            },
                            {
                                caption: cxt.t('Email'),
                                name: 'email',
                                md: 2,
                            },
                            {
                                caption: cxt.t('Mobile'),
                                name: 'mobile',
                                md: 2,
                            },
                            {
                                caption: cxt.t('Password'),
                                name: 'password',
                                md: 2,
                            },
                            {
                                type: 'date',
                                caption: cxt.t('Birthday'),
                                name: 'birthday',
                                md: 2,
                            },
                            {
                                caption: cxt.t('X'),
                                name: 'x',
                                md: 2,
                            },
                            {
                                caption: cxt.t('Y'),
                                name: 'y',
                                md: 2,
                            },
                            {
                                type: 'select',
                                name: 'status',
                                caption: cxt.t('Status'),
                                options: [
                                    {
                                        caption: cxt.t('ACTIVE'),
                                        value: 'ACTIVE',
                                    },
                                    {
                                        caption: cxt.t('INACTIVE'),
                                        value: 'INACTIVE',
                                    },
                                    {
                                        caption: cxt.t('NOT_CONFIRM'),
                                        value: 'NOT_CONFIRM',
                                    },
                                    {
                                        caption: cxt.t('WAIT_EMAIL'),
                                        value: 'WAIT_EMAIL',
                                    },
                                ],
                                disableClearable: true,
                                md: 2,
                            },
                            {
                                caption: cxt.t('DNI'),
                                name: 'dni',
                                md: 2,
                            },
                            {
                                caption: cxt.t('IBAN'),
                                name: 'iban',
                                md: 2,
                            },
                            {
                                caption: cxt.t('postalCode'),
                                name: 'postalCode',
                                md: 2,
                            },
                            {
                                caption: cxt.t('Address'),
                                name: 'address',
                                md: 2,
                            },
                            {
                                caption: cxt.t('Privilege'),
                                name: 'privilege',
                                md: 2,
                            },
                            {
                                type: 'select',
                                name: 'debug',
                                caption: cxt.t('Debug'),
                                options: [
                                    {
                                        caption: cxt.t('disabledDebug'),
                                        value: 0,
                                    },
                                    {
                                        caption: cxt.t('tracesDebug'),
                                        value: 1,
                                    },
                                    {
                                        caption: cxt.t('tracesAndSqlDebug'),
                                        value: 2,
                                    },
                                ],
                                disableClearable: true,
                                md: 2,
                            },
                            {
                                type: 'check',
                                caption: cxt.t('ComActive'),
                                name: 'comActive',
                                md: 2,
                            },
                        ]}
                        buttons={[
                            {
                                show: !userQuery?.data?.user?.trash,
                                type: 'save',
                                onClick: form.handleSubmit((UserData) =>
                                    mutateUser(UserData),
                                ),
                            },
                            {
                                show:
                                    userQuery?.data?.user?.id &&
                                    !userQuery?.data?.user?.trash,
                                type: 'delete',
                                onClick: () =>
                                    deleteMutation.mutate({ id: userId }),
                            },
                        ]}
                    />
                </Box>
            </Paper>
        </SttFullDialog>
    );
}
