import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { AppContext } from '../../../../sporttia/AppContext';
import { SttFullDialog } from '../../../../sporttia/dialogs/SttFullDialog';
import SttValidatedForm from '../../../../sporttia/SttValidatedForm';
import { SttButtonAccept } from '../../../../sporttia/all';
import { useLoader, useTranslations } from '../../../../lib/hooks';
import useCoresService from '../../../../services/CoresService';
import { SUPERADMIN_API_TRIGGERS_EXECUTIONS } from '../../../../lib/queryKeys';

/**
 * Dialog to view API Trigger execution.
 * @param triggerExecution API Trigger execution object.
 * @param onClose Function executed when closing the dialog.
 * @returns {JSX.Element}
 */
export default function AdminApiTriggerExecutionDialog({
    triggerExecution,
    onClose,
}) {
    const { translate } = useTranslations();
    const cxt = useContext(AppContext);

    const queryClient = useQueryClient();
    const coresService = useCoresService();

    const form = useForm({
        values: {
            id: triggerExecution?.id,
            created: triggerExecution?.created,
            status: triggerExecution?.status,
            scName: triggerExecution?.coreTriggerSC?.sc?.name,
            script: triggerExecution?.coreTriggerSC?.coreTrigger?.script,
            code: triggerExecution?.coreTriggerSC?.coreTrigger?.code,
            answer: triggerExecution?.answer,
            request: triggerExecution?.request,
            error: triggerExecution?.error,
            execution: triggerExecution?.execution,
        },
    });

    const createMutation = useMutation(
        (params) => coresService.createCoresTriggersExecutionsRun(params?.id),
        {
            onSuccess: (response) => {
                queryClient.invalidateQueries(
                    SUPERADMIN_API_TRIGGERS_EXECUTIONS,
                );

                form.reset({ ...response.coreTriggerExecution });
            },
        },
    );

    const [, loader] = useLoader([createMutation.status]);

    return (
        <SttFullDialog
            open
            onClose={onClose}
            title={triggerExecution?.coreTriggerSC?.coreTrigger?.code}
        >
            {loader}

            <Paper>
                <Box p={3}>
                    <Box paddingBottom={2}>
                        <SttButtonAccept
                            caption={translate('CoreTriggerExecute')}
                            md={3}
                            onClick={() => {
                                createMutation.mutate({
                                    id: triggerExecution.id,
                                });
                            }}
                        />
                    </Box>

                    <SttValidatedForm
                        form={form}
                        fields={[
                            {
                                type: 'label',
                                caption: 'Id',
                                name: 'id',
                                md: 3,
                            },
                            {
                                type: 'label',
                                caption: cxt.t('Created'),
                                name: 'created',
                                md: 3,
                            },
                            {
                                type: 'label',
                                caption: cxt.t('CoreTriggerExecution'),
                                name: 'execution',
                                md: 3,
                            },
                            {
                                type: 'label',
                                caption: cxt.t('Status'),
                                name: 'status',
                                md: 3,
                            },
                            {
                                type: 'label',
                                caption: cxt.t('SportcenterName'),
                                name: 'scName',
                                md: 3,
                            },
                            {
                                type: 'label',
                                caption: 'Script',
                                name: 'script',
                                md: 3,
                            },
                            {
                                type: 'label',
                                caption: cxt.t('Code'),
                                name: 'code',
                                md: 3,
                            },
                            {
                                type: 'textarea',
                                caption: cxt.t('Request'),
                                name: 'request',
                                rows: triggerExecution?.request ? 10 : 3,
                                md: 12,
                                style: { width: '50%' },
                            },
                            {
                                type: 'textarea',
                                caption: cxt.t('Responses'),
                                name: 'answer',
                                rows: triggerExecution?.answer ? 10 : 3,
                                md: 12,
                                style: { width: '50%' },
                            },
                            {
                                type: 'textarea',
                                caption: cxt.t('Error'),
                                name: 'error',
                                rows: triggerExecution?.error ? 10 : 3,
                                md: 12,
                                style: { width: '50%' },
                            },
                        ]}
                    />
                </Box>
            </Paper>
        </SttFullDialog>
    );
}
