import React, { useState, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { SttButton } from '../../../sporttia/buttons/SttButton';
import { useEnvironment, usePOSConfig } from '../../../lib/hooks';
import { AppContext, SttList } from '../../../sporttia/all';
import ConfigurationPosDialog from './ConfigurationPOSDialog';
import { requestAdHoc } from '../../../lib/utils';

// Frases para el handshake (app Sporttia Pay)
/* export const handShakeValues = {
	request: {
		hand: 'shake'
	},
	response:  {
		hand: 'shaken',
	}
} */

/**
 * Pantalla de configuración de POS (datáfono físico, para distinguir de TPV o 'datáfono virtual')
 * @param props
 * @returns {JSX.Element}
 */
export default function ConfigurationPos(props) {
    const environment = useEnvironment();
    const cxt = useContext(AppContext);

    const [dialogOpen, setDialogOpen] = useState(false);

    // devices es un array por si acaso, aunque ahora mismo solo guardamos un valor
    const [devices, setDevices] = useState(null);
    const [testing, setTesting] = useState(false);

    // load config builder object
    const setupPOSConfig = usePOSConfig('PAYCOMET');

    // Load existing POS ip
    useEffect(() => {
        const ip = localStorage.getItem('CM5DeviceAddress');
        if (ip) {
            setDevices([{ ip }]);
        }
    }, []);

    // Al modificar devices, se guarda el valor en localStorage para más adelante
    useEffect(() => {
        if (devices) {
            if (devices.length > 0) {
                localStorage.setItem('CM5DeviceAddress', devices[0].ip);
            } else {
                localStorage.setItem('CM5DeviceAddress', '');
            }
        }
    }, [devices]);

    // Probar que el datáfono en la ip especificada responde
    function testDevice(ip) {
        setTesting(true);

        const config = setupPOSConfig(ip);

        // Hacer la petición, 2 segundos de timeout
        requestAdHoc(config.init.method, config.init.uri, config.init.params())
            .then((r) => {
                const response = config.init.response(r);
                if (response.status === 'OK') {
                    cxt.showMessage('S', cxt.t('config.pos.deviceTestOK'));
                } else {
                    cxt.showMessage(
                        'E',
                        cxt.t('config.pos.deviceTestNotFound'),
                    );
                }
            })
            .catch(() => {
                cxt.showMessage('E', cxt.t('config.pos.deviceTestError'));
            })
            .finally(() => {
                setTesting(false);
            });
    }

    return (
        <Box>
            <SttButton
                startIcon={<Add />}
                caption={cxt.t('config.pos.addDevice')}
                color="primary"
                variant="contained"
                disabled={false}
                onClick={() => {
                    setDialogOpen(true);
                }}
            />
            {devices && (
                <Box mt={2}>
                    <SttList
                        paper={false}
                        title={cxt.t('config.pos.devicesConnected')}
                        onSelect={() => {}}
                        onDelete={(ip) =>
                            setDevices(
                                devices.filter(
                                    (cacharro) => cacharro.ip !== ip,
                                ),
                            )
                        }
                        data={devices.map((item) => ({
                            caption: (
                                <Box display="flex" alignItems="center">
                                    <Box mr={3}>{item.ip}</Box>
                                    <SttButton
                                        disabled={testing}
                                        caption={cxt.t('Check')}
                                        color="primary"
                                        variant="contained"
                                        onClick={() => testDevice(item.ip)}
                                    />
                                </Box>
                            ),
                            objToSelect: item.ip,
                        }))}
                    />
                </Box>
            )}
            <ConfigurationPosDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onAdd={(ip) => {
                    // Se machaca el array con un array de un único valor;
                    setDevices([{ ip }]);
                    setDialogOpen(false);
                }}
            />
        </Box>
    );
}
