import React from 'react';
import { Chip } from '@material-ui/core';

/**
 * Compact element to represent the different HTTP verbs (GET, POST, PUT, DELETE ...)
 * @param httpVerb Http verb, can be: [GET, POST, PUT, DELETE]
 * @returns {JSX.Element}
 */
export function SttChipHttpVerb({ httpVerb }) {
    const color = {
        GET: '#00b6ff',
        POST: '#0fbf05',
        PUT: 'orange',
        DELETE: 'red',
    };

    return (
        <Chip
            size="small"
            label={httpVerb}
            style={{
                color: 'white',
                backgroundColor: color[httpVerb],
            }}
        />
    );
}
