import React, { useState, useEffect, useContext } from 'react';
import { TextField, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AppContext, SttSelectAutocomplete } from '../all';

/**
 * SttSelectSport - Presents an autocomplete select with Sports obtained from the API
 * bool grid - wrap or not the underlying component in a <Grid item>
 * string caption - form element's caption
 * string name - form element's internal name
 * value defVal - selected value
 * Function onChange - selection callback
 * Object rest - any other props
 */

// Module level cache
let cachedSports = [];

// TODO: fix this component because the chain of grid={true} and {...rest} parameters is just broken.
export function SttSelectSport({
    grid = false,
    caption,
    name,
    defVal,
    onChange,
    ...rest
}) {
    const cxt = useContext(AppContext);
    const [sports, setSports] = useState(cachedSports);

    // Init
    useEffect(() => {
        if (cachedSports.length === 0) {
            loadSports();
        }
    }, []);

    // Load sports from API
    const loadSports = () => {
        cxt.api('GET', `/sports`, {
            params: { page: 1, rows: 500 },
            success: (r) => {
                cachedSports = r.rows;
                setSports(r.rows);
            },
        });
    };

    // SttSelectAutocomplete handles all the rest of the stuff on its own
    return (
        <SttSelectAutocomplete
            {...rest}
            grid={grid}
            name={name}
            caption={caption}
            options={sports}
            defVal={defVal}
            onChange={onChange}
        />
    );
}
