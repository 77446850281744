import { MutationFunction, UseMutationOptions, useMutation } from 'react-query';
import { useContext } from 'react';
import {
    UpdateDiscount200,
    UpdateDiscountBody,
    DeleteDiscount200,
} from '../types/api/paths/Discount';
import { AppContext } from '../sporttia/AppContext';
import { useAPI } from '../lib/hooks';

const resource = '/discounts';

/**
 * Hook to access all 'discounts' resources.
 */
export default function useDiscountService() {
    const { migrationApis } = useContext(AppContext)!;
    const [request] = useAPI(migrationApis);

    /**
     * PUT /discounts/{discountId} request.
     */
    function useUpdateDiscount(
        mutationOptions?: UseMutationOptions<
            UpdateDiscount200,
            unknown,
            { id: number; params: UpdateDiscountBody }
        >,
    ) {
        const mutationFn: MutationFunction<
            UpdateDiscount200,
            { id: number; params: UpdateDiscountBody }
        > = ({ id, params }) => request('PUT', `${resource}/${id}`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * DELETE /discounts/{discountId} request.
     */
    function useDeleteDiscount(
        mutationOptions?: UseMutationOptions<
            DeleteDiscount200,
            unknown,
            number
        >,
    ) {
        const mutationFn: MutationFunction<DeleteDiscount200, number> = (id) =>
            request('DELETE', `${resource}/${id}`);

        return useMutation(mutationFn, mutationOptions);
    }

    return {
        useUpdateDiscount,
        useDeleteDiscount,
    };
}
