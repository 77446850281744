import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Drawer,
    Box,
    AppBar,
    Toolbar,
    List,
    Typography,
    ListItemText,
    ListItem,
    IconButton,
    Collapse,
} from '@material-ui/core';
import {
    Menu as MenuIcon,
    ExpandMore as ExpandMoreIcon,
    ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { AppContext } from '../../sporttia/all';
import { getPath } from '../../pages/Pages';
import LinkWrapper from '../LinkWrapper';

export default function AdminHeader() {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [activeMenu, setActiveMenu] = useState(null);

    /**
     * Go to the old page
     */
    const go = (sm) => {
        if (sm.url) history.push(sm.url);
        if (sm.path) {
            history.push(getPath(sm.path, sm.params));
            setOpenDrawer(false);
        }
    };

    /**
     * Render sub-menu
     */
    const renderSubmenu = (sm, idx) => {
        if (
            !sm.privilege ||
            (sm.privilege && cxt.privileges.includes(sm.privilege))
        ) {
            return (
                <LinkWrapper
                    key={idx}
                    href={sm.url || (sm.path && getPath(sm.path, sm.params))}
                    callback={() => go(sm)}
                >
                    <ListItem button>
                        <ListItemText
                            primary={sm.caption}
                            style={{
                                marginLeft: 20,
                            }}
                        />
                    </ListItem>
                </LinkWrapper>
            );
        }
    };

    /**
     * Logout
     */
    const logout = () => {
        cxt.api('POST', '/logout', {
            confirmation: true,
            success: (r) => {
                cxt.setLoggedUser(false);
                cxt.setHeader(null);
            },
        });
    };

    /**
     * If "cxt.logged" change and is false, we will redirect to login page.
     */
    useEffect(() => {
        if (!cxt.logged) history.push(getPath('loadingSporttia'));
    }, [cxt.logged]);

    /**
     * Select menu
     */
    const selectMenu = (menu) => {
        setActiveMenu(menu);
        if (menu.path) {
            go(menu);
        }
    };

    /**
     * Drawer
     */
    const drawer = (
        <List>
            {cxt.menus &&
                cxt.menus.admin.map((menu, idx) => (
                    <div key={idx}>
                        <LinkWrapper
                            href={
                                menu.url ||
                                (menu.path && getPath(menu.path, menu.params))
                            }
                            callback={() => selectMenu(menu)}
                        >
                            <ListItem button>
                                <ListItemText primary={menu.caption} />

                                {menu.submenus && (
                                    <>
                                        {activeMenu && activeMenu === menu ? (
                                            <ExpandLessIcon />
                                        ) : (
                                            <ExpandMoreIcon />
                                        )}
                                    </>
                                )}
                            </ListItem>
                        </LinkWrapper>

                        <Collapse
                            in={activeMenu === menu}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List component="div" disablePadding>
                                {activeMenu &&
                                    activeMenu.submenus &&
                                    activeMenu.submenus.map((sm, idx2) =>
                                        renderSubmenu(sm, idx2),
                                    )}
                            </List>
                        </Collapse>
                    </div>
                ))}
        </List>
    );

    /**
     * Render header title
     */
    const renderHeaderTitle = () => (
        <Box display="flex" alignItems="center">
            <Typography noWrap component="h1" variant="h6" color="inherit">
                {cxt.header && cxt.header.title}
            </Typography>
        </Box>
    );

    // -----| Render |-----
    return (
        <>
            <AppBar
                position="absolute"
                style={{
                    backgroundColor:
                        localStorage.getItem('environment') === 'PRE'
                            ? '#fbc02d'
                            : 'default',
                }}
            >
                <Toolbar>
                    <Box flex={1}>
                        <Box display="flex">
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => setOpenDrawer(true)}
                            >
                                <MenuIcon />
                            </IconButton>

                            {renderHeaderTitle()}

                            <Box
                                flex={1}
                                display="flex"
                                justifyContent="flex-end"
                            >
                                <IconButton
                                    color="inherit"
                                    className="float-right"
                                    onClick={() => logout()}
                                >
                                    <ExitToAppIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>

            <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
                {drawer}
            </Drawer>
        </>
    );
}
