import React, { useState, useEffect, useContext } from 'react';
import { Link, Paper } from '@material-ui/core';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import SttCachedTable from '../../../sporttia/SttCachedTable';
import { AppContext, SttButtonFab, SttChipUser } from '../../../sporttia/all';
import { SPORTCENTER_ACTIVITIES } from '../../../lib/queryKeys';
import useScsService from '../../../services/ScsService';
import { useCrud, usePageHeader, useQueryFilters } from '../../../lib/hooks';
import Activity from './Activity';
import { copyToClipboard, fullName } from '../../../lib/utils';
import { SttTopFilteringControls } from '../../../sporttia/SttTopFilteringControls';
import { SttActivitiesDuplicationDialog } from '../../../sporttia/dialogs/SttActivitiesDuplicationDialog';

export default function Activities() {
    usePageHeader('Activities');
    const queryClient = useQueryClient();

    const [scTags, setScTags] = useState();
    const [activity, setActivity] = useState({
        id: null,
        trash: false,
    });
    const scsService = useScsService();
    const [filters, setFilters] = useQueryFilters(
        {
            name: '',
            year: moment().format('YYYY'),
            type: '',
            idSport: '',
            status: '',
            tags: '',
            trash: false,
        },
        SPORTCENTER_ACTIVITIES,
    );
    const [selectRows, setSelectRows] = useState([]);
    const [activitiesToDuplicate, setActivitiesToDuplicate] = useState([]);
    const [, , , Get, , ,] = useCrud();

    const cxt = useContext(AppContext);

    const getSportCenterTags = () => {
        if (cxt.sc?.id) {
            Get(`/scs/${cxt.sc.id}/tags`).then((response) =>
                setScTags(response?.rows),
            );
        }
    };

    // Init
    useEffect(() => {
        getSportCenterTags();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Cargando...
    if (!scTags) {
        return null;
    }

    return (
        <Paper>
            <SttTopFilteringControls
                p={2}
                fields={[
                    {
                        caption: cxt.t('Name'),
                        name: 'name',
                        type: 'text',
                        value: filters.name,
                    },
                ]}
                extraFields={[
                    {
                        caption: cxt.t('Code'),
                        name: 'idActivity',
                        type: 'text',
                        value: filters.idActivity,
                    },
                    {
                        caption: cxt.t('Year'),
                        type: 'year',
                        name: 'year',
                        years: {
                            future: 1,
                        },
                        value: filters.year
                            ? moment(filters.year).format('YYYY')
                            : '',
                    },
                    {
                        caption: cxt.t('Type'),
                        type: 'select',
                        name: 'type',
                        value: filters.type,
                        options: [
                            {
                                caption: cxt.t(`CT.COURSE`),
                                value: 'COURSE',
                            },
                            {
                                caption: cxt.t(`CT.PUNCTUAL`),
                                value: 'PUNCTUAL',
                            },
                        ],
                    },
                    {
                        caption: cxt.t('Sport'),
                        type: 'sport',
                        name: 'idSport',
                        value: filters.idSport,
                    },
                    {
                        caption: cxt.t('Status'),
                        type: 'select',
                        name: 'status',
                        value: filters.status,
                        options: [
                            {
                                caption: cxt.t(`CS.OPEN`),
                                value: 'OPEN',
                            },
                            {
                                caption: cxt.t(`CS.CLOSE`),
                                value: 'CLOSE',
                            },
                        ],
                    },
                    {
                        caption: cxt.t('Tags'),
                        name: 'tags',
                        type: 'selectMulti',
                        value: filters.tags,
                        xs: 12,
                        sm: 6,
                        md: 4,
                        lg: 9,
                        options: scTags.map((tag) => ({
                            label: tag.name,
                            value: tag.id,
                        })),
                    },
                ]}
                onFilter={(value) => {
                    let year = null;
                    if (value?.year) {
                        year = value.year.toString();
                    }
                    setFilters({ ...value, year });
                }}
                trashAction
            />

            <SttCachedTable
                queryKey={SPORTCENTER_ACTIVITIES}
                queryFn={(params) =>
                    scsService.getActivities(cxt?.sc?.id, params)
                }
                queryParams={filters}
                prefetching={false}
                setSelectRows={setSelectRows}
                onClickRow={(row) => {
                    setActivity({ id: row.id, trash: row.trash });
                }}
                columns={[
                    {
                        title: '#',
                        type: 'tooltip',
                        value: (row) => ({ label: '#', value: row.id }),
                        onClick: (row) =>
                            copyToClipboard(row.id, () =>
                                cxt.showMessage(
                                    'S',
                                    cxt.t('copiedToClipboard'),
                                ),
                            ),
                    },
                    {
                        title: cxt.t('Name'),
                        align: 'left',
                        width: 250,
                        value: (row) => (
                            <div>
                                {row.status === 'CLOSE' ? (
                                    <Link style={{ color: 'red' }}>
                                        {row.user
                                            ? fullName(row.user)
                                            : row.name}
                                    </Link>
                                ) : (
                                    <Link>
                                        {row.user
                                            ? fullName(row.user)
                                            : row.name}
                                    </Link>
                                )}
                                <div>
                                    <span>{row.typeLang}</span>
                                    {row.numClasses > 0 && (
                                        <span>{` - ${row.numClasses} ${cxt.t(
                                            'Classes',
                                        )}`}</span>
                                    )}
                                </div>
                            </div>
                        ),
                    },
                    {
                        type: 'sport',
                        title: cxt.t('Sport'),
                        value: (row) => row.sport?.name,
                    },
                    {
                        title: cxt.t('Teacher'),
                        value: (row) => (
                            <SttChipUser
                                user={
                                    row.teacher
                                        ? { login: row.teacher.name }
                                        : undefined
                                }
                            />
                        ),
                    },
                    {
                        type: 'period',
                        title: cxt.t('Inscription'),
                        value: (row) => ({
                            ini: row.inscIni,
                            end: row.inscEnd,
                        }),
                    },
                    {
                        type: 'period',
                        title: cxt.t('Period'),
                        value: (row) => ({ ini: row.ini, end: row.end }),
                    },
                    {
                        type: 'counter',
                        title: cxt.t('Students'),
                        value: (row) =>
                            `${row.numStudents} / ${row.maxStudents || '∞'}`,
                    },
                    {
                        type: 'price',
                        title: cxt.t('Total'),
                        field: 'total',
                    },
                    {
                        type: 'debt',
                        title: cxt.t('Debt'),
                        value: (row) => ({ price: row?.debt }),
                    },
                ]}
                selection={{
                    actions: [
                        {
                            caption: 'Duplicar cursos',
                            onClick: () => {
                                setActivitiesToDuplicate(
                                    selectRows.map(
                                        (activitySelected) =>
                                            activitySelected?.id,
                                    ),
                                );
                            },
                        },
                    ],
                }}
            />
            <Activity
                id={activity.id}
                trash={activity.trash}
                // apply changes locally, remove items with 'trash'
                onChange={(newActivity) => {
                    if (newActivity) {
                        setActivity(newActivity);
                    }
                    queryClient.invalidateQueries(SPORTCENTER_ACTIVITIES);
                }}
                onClose={() => {
                    setActivity({ id: null, trash: false });
                }}
            />
            <SttButtonFab
                onClick={() => setActivity({ id: 'create', trash: false })}
            />

            {activitiesToDuplicate?.length > 0 && (
                <SttActivitiesDuplicationDialog
                    open
                    activities={activitiesToDuplicate}
                    onClose={() => setActivitiesToDuplicate([])}
                    onDuplicate={() => {
                        setActivitiesToDuplicate([]);
                    }}
                />
            )}
        </Paper>
    );
}
