import React, { useContext } from 'react';
import moment from 'moment';
import { AppContext } from '../AppContext';

/**
 * Label for period. Green if the current date fall within the period, red otherwise.
 */
export function SttLabelPeriod({ ini, end, dateWithTime = false }) {
    const cxt = useContext(AppContext);
    const mIni = moment(ini);
    const mEnd = moment(end);
    // Añadida granularidad para ver si se soluciona el problema de que pintar periodos en rojos cuando está dentro por fecha.
    // El problema venía que al comparar toda la fecha con el tiempo puede no entrar en el rango por minutos o segundos, de ahí que
    // haya añadido la granularidad "day" al segundo parámetro de isSameOrAfter().
    const inTime =
        moment().isSameOrAfter(mIni, 'day') &&
        mEnd.isSameOrAfter(moment(), 'day');
    const year = moment().format('YYYY');

    if (!ini && !end) {
        return null;
    }

    return (
        <span style={{ color: inTime ? 'green' : 'red' }}>
            {mIni.format('YYYY') === year
                ? mIni.format('D MMM')
                : mIni.format(dateWithTime ? 'D MMM YYYY hh:mm' : 'D MMM YYYY')}
            {ini !== end &&
                ` - ${
                    mEnd.format('YYYY') === year
                        ? mEnd.format('D MMM')
                        : mEnd.format(
                              dateWithTime ? 'D MMM YYYY hh:mm' : 'D MMM YYYY',
                          )
                }`}
        </span>
    );
}
