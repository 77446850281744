import React, { useContext, useState } from 'react';
import { Paper } from '@material-ui/core';
import { AppContext } from '../../../sporttia/AppContext';
import {
    SttButtonFab,
    SttChipEventKind,
    SttChipEventType,
    SttChipFileFormat,
    SttChipFileType,
    SttFileDialog,
    SttTable,
    SttUploadDialog,
} from '../../../sporttia/all';
import { updateElementInArray } from '../../../lib/utils';
import DateBuilder from '../../../lib/DateBuilder';

export default function MshipDocuments({ mship }) {
    const cxt = useContext(AppContext);
    const [userFiles, setUserFiles] = useState({});
    const [openFileId, setOpenFileId] = useState();
    const [uploadFileOpen, setUploadFileOpen] = useState(false);

    /**
     * Get user files.
     */
    const loadUserFiles = (p = {}) => {
        cxt.api('GET', `/mships/${mship.id}/files`, {
            params: {
                ...p,
            },
            success: (r) => {
                setUserFiles(r);
            },
        });
    };

    /**
     * Update file
     */
    const updateFile = (file) => {
        setUserFiles({
            ...userFiles,
            rows: updateElementInArray(userFiles.rows, file),
        });
        setOpenFileId(null);
    };

    // -----| Render |-----
    return (
        <>
            <SttTable
                data={userFiles}
                columns={[
                    {
                        title: cxt.t('Created'),
                        field: 'created',
                        value: (row) =>
                            new DateBuilder(
                                row?.created,
                                cxt?.sc?.timezone?.name,
                                cxt?.lang?.key,
                            ).dmy(),
                    },
                    {
                        type: 'link',
                        align: 'left',
                        title: cxt.t('Name'),
                        value: (row) => row.name || cxt.t('Noname'),
                        onClick: (row) => setOpenFileId(row.id),
                    },
                    {
                        title: cxt.t('Type'),
                        field: 'type',
                        value: (row) => <SttChipFileType type={row.type} />,
                    },
                    {
                        title: cxt.t('Format'),
                        value: (row) => (
                            <SttChipFileFormat format={row.format} />
                        ),
                    },
                ]}
                onFetch={loadUserFiles}
            />

            <SttButtonFab onClick={() => setUploadFileOpen(true)} />

            <SttUploadDialog
                open={uploadFileOpen}
                types={['USER_DOC']}
                uploadParams={{ idMship: mship.id }}
                onClose={() => setUploadFileOpen(false)}
                onUploaded={(r) => {
                    setUploadFileOpen(false);
                    loadUserFiles();
                }}
            />

            <SttFileDialog
                idFile={openFileId}
                onClose={() => setOpenFileId(null)}
                onUpdate={updateFile}
            />
        </>
    );
}
