import React, { useState } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { Done as DoneIcon } from '@material-ui/icons';
import ModalConfirmation from '../../layout/ModalConfirmation';
import { useTranslations } from '../../lib/hooks';

type SttButtonRecoverProps = {
    caption?: string;
    block?: boolean;
    onClick: () => void;
    confirmation?: boolean;
} & ButtonProps;

/**
 * Button recover
 */
export function SttButtonRecover({
    caption,
    onClick,
    confirmation = false,
    ...rest
}: SttButtonRecoverProps) {
    const { translate } = useTranslations();
    const [confirmationDialog, setConfirmationDialog] = useState({
        show: false,
    });

    return (
        <>
            <Button
                {...rest}
                variant="contained"
                color="primary"
                startIcon={<DoneIcon />}
                onClick={
                    confirmation
                        ? () => setConfirmationDialog({ show: true })
                        : onClick
                }
                style={{
                    backgroundColor: 'orange',
                }}
            >
                {caption || translate('Recover')}
            </Button>

            <ModalConfirmation
                show={confirmationDialog.show}
                onClose={() => setConfirmationDialog({ show: false })}
                onAccept={() => {
                    onClick();
                    setConfirmationDialog({ show: false });
                }}
            />
        </>
    );
}
