import React, { useState, useEffect, useContext } from 'react';
import {} from '@material-ui/core';
import { AppContext, SttDialog, SttForm } from '../../../sporttia/all';
import {
    getObjectDiff,
    subsetObject,
    usePrevAndUpdatedStates,
} from '../../../lib/utils';

export default function ProductDialog({
    product,
    autoClose = true,
    onClose,
    onUpdateProduct,
    onDeleteProduct,
}) {
    const cxt = useContext(AppContext);
    const [internalProduct, setInternalProduct] = useState(product);
    const { setPrevState, setUpdatedState, updatedState, prevState } =
        usePrevAndUpdatedStates(product);

    /**
     * Set product
     */
    useEffect(() => {
        setInternalProduct(product);
        setPrevState(product);
    }, [product, setPrevState]);

    /**
     * Save product
     */
    const saveProduct = () => {
        const params = subsetObject(
            getObjectDiff(updatedState, prevState),
            'name,description,category,finalPrice,stockageActive,vat,stock',
        );

        // Updating
        if (internalProduct.id) {
            cxt.api('PUT', `/products/${internalProduct.id}`, {
                params,
                success: (r) => {
                    cxt.showMessage('S', cxt.t('Saved'));
                    setPrevState(updatedState);
                    if (onUpdateProduct) onUpdateProduct(r.product);
                    if (autoClose) onClose();
                },
            });

            // Creating
        } else {
            cxt.api('POST', `/scs/${cxt.sc.id}/products`, {
                params,
                success: (r) => {
                    cxt.showMessage('S', cxt.t('Created'));
                    if (onUpdateProduct) onUpdateProduct(r.product);
                    if (autoClose) onClose();
                },
            });
        }
    };

    /**
     * Delete product
     */
    const deleteProduct = () => {
        cxt.api('DELETE', `/products/${internalProduct.id}`, {
            confirmation: true,
            success: (r) => {
                cxt.showMessage('S', cxt.t('Deleted'));
                if (onDeleteProduct) onDeleteProduct(r.product);
                if (autoClose) onClose();
            },
        });
    };

    /**
     * Handle form
     */
    const handleForm = ({ name, value }) => {
        setInternalProduct({ ...internalProduct, [name]: value });
        setUpdatedState({ ...updatedState, [name]: value });
    };

    // -----| Render |-----
    return (
        <SttDialog
            title={cxt.t('Product')}
            open={product != null}
            onClose={onClose}
            content={
                internalProduct && (
                    <SttForm
                        onChangeForm={handleForm}
                        elements={[
                            {
                                md: 12,
                                sm: 12,
                                xs: 12,
                                caption: cxt.t('Name'),
                                name: 'name',
                                value: internalProduct.name,
                            },
                            {
                                md: 12,
                                sm: 12,
                                xs: 12,
                                caption: cxt.t('Description'),
                                type: 'textarea',
                                name: 'description',
                                value: internalProduct.description,
                            },
                            {
                                md: 9,
                                sm: 6,
                                xs: 12,
                                caption: cxt.t('Category'),
                                name: 'category',
                                value: internalProduct.category,
                            },
                            {
                                md: 3,
                                sm: 6,
                                xs: 12,
                                caption: cxt.t('Stock'),
                                name: 'stock',
                                value: internalProduct.stock,
                            },
                            {
                                disabled: true,
                                md: 4,
                                sm: 4,
                                xs: 12,
                                caption: cxt.t('NetPrice'),
                                name: 'netPrice',
                                value: internalProduct.netPrice,
                            },
                            {
                                md: 4,
                                sm: 4,
                                xs: 12,
                                caption: cxt.t('VAT'),
                                name: 'vat',
                                value: internalProduct.vat,
                            },
                            {
                                md: 4,
                                sm: 4,
                                xs: 12,
                                caption: cxt.t('Price'),
                                name: 'finalPrice',
                                value: internalProduct.finalPrice,
                            },
                            {
                                md: 6,
                                sm: 6,
                                xs: 12,
                                type: 'check',
                                caption: cxt.t('Stockage'),
                                name: 'stockageActive',
                                value: internalProduct.stockageActive,
                            },
                        ]}
                    />
                )
            }
            buttons={[
                {
                    type: 'delete',
                    show: internalProduct && internalProduct.id > 0,
                    onClick: () => deleteProduct(),
                },
                {
                    type: 'save',
                    onClick: () => saveProduct(),
                },
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
        />
    );
}
