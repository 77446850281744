import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { SttDialog } from '../../../sporttia/dialogs/SttDialog';
import { useLoader, useTranslations } from '../../../lib/hooks';
import useGolfService from '../../../services/GolfService';
import SttValidatedForm from '../../../sporttia/SttValidatedForm';

/**
 * Dialog to modify, edit and delete rates of a golf course schedule.
 * @param golfScheduleId Golf schedule ID.
 * @param rate Golf rate.
 * @param onClose Action taken when closing the modal.
 * @param onCreate Action taken when creating the tpv configuration.
 * @param onUpdate Action taken when updating a license.
 * @param onDelete Action taken when removing a license.
 * @returns {JSX.Element}
 */
function GolfCourseScheduleRateDialog({
    golfScheduleId,
    rate,
    onClose,
    onCreate,
    onUpdate,
    onDelete,
}) {
    const { translate } = useTranslations();
    const form = useForm();
    const golfService = useGolfService();
    const createMutation = useMutation(
        (params) =>
            golfService.createGolfCoursesFreesRates(params?.id, params?.params),
        {
            onSuccess: onCreate,
        },
    );
    const updateMutation = useMutation(
        (params) =>
            golfService.updateGolfCoursesRates(params?.id, params?.params),
        {
            onSuccess: onUpdate,
        },
    );
    const deleteMutation = useMutation(
        (params) => golfService.removeGolfCoursesRates(params?.id),
        {
            onSuccess: onDelete,
        },
    );
    const [, loader] = useLoader([
        deleteMutation.status,
        updateMutation.status,
        createMutation.status,
    ]);

    /**
     * Method executed when the user clicks on save or create api model.
     * @param formData Form data.
     */
    const mutateGolfCourseRate = (formData) => {
        const formatedFormData = { ...formData };

        delete formatedFormData.group;
        formatedFormData.idGroup = formData?.group?.id;

        if (rate?.id) {
            updateMutation.mutate({ id: rate?.id, params: formatedFormData });
        } else {
            createMutation.mutate({
                id: golfScheduleId,
                params: formatedFormData,
            });
        }
    };

    /**
     * When the data is returned by the API, it is set in the form.
     */
    useEffect(() => {
        if (rate?.id) {
            form.reset({
                name: rate?.name,
                price: rate?.price,
                group: rate?.group,
            });
        } else {
            form.reset({
                name: '',
                price: '',
                group: '',
            });
        }
    }, [rate]);

    return (
        <SttDialog
            open={rate !== null}
            title={translate('Rate')}
            onClose={onClose}
            content={
                <>
                    {loader}
                    <SttValidatedForm
                        form={form}
                        fields={[
                            {
                                type: 'textinput',
                                name: 'name',
                                caption: translate('Name'),
                            },
                            {
                                type: 'price',
                                name: 'price',
                                caption: translate('Price'),
                            },
                            {
                                type: 'group',
                                name: 'group',
                                caption: translate('Group'),
                            },
                        ]}
                    />
                </>
            }
            buttons={[
                {
                    type: 'save',
                    onClick: form.handleSubmit((formData) =>
                        mutateGolfCourseRate(formData),
                    ),
                },
                {
                    type: 'delete',
                    show: !!rate?.id,
                    confirmation: true,
                    onClick: () => deleteMutation.mutate({ id: rate?.id }),
                },
            ]}
        />
    );
}

export default GolfCourseScheduleRateDialog;
