import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import { formatPriceByLocale } from '../../lib/utils';
/**
 * Label for fees
 *
 * Muestra algo como "10€ cada mes"
 *
 * Props
 *
 * 	* string interval: es del tipo 0000-01-05 (un mes y 5 días)
 * 	* double price:
 */
export function SttLabelFee({ interval, price }) {
    const cxt = useContext(AppContext);

    return price ? (
        <div>
            <b>
                {formatPriceByLocale(
                    price,
                    cxt.sc?.city?.country?.currency,
                    cxt.sc?.city?.country?.locale,
                )}
            </b>
            &nbsp;
            {cxt.t('every')}
            &nbsp;
            <b>{interval ? cxt.t(interval) : '...'}</b>
        </div>
    ) : null;
}
