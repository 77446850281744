import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { AppContext } from '../../../../sporttia/AppContext';
import { SttDialog } from '../../../../sporttia/all';
import { useLoader } from '../../../../lib/hooks';
import SttValidatedForm from '../../../../sporttia/SttValidatedForm';
import useTestsService from '../../../../services/TestsService';

/**
 * Dialog to modify, create and delete API tests.
 * @param apiId API Id.
 * @param testValue API Test object.
 * @param onClose Function executed when closing the dialog.
 * @param onCreate Action taken when creating the api parameter.
 * @param onUpdate Action taken when updating the api parameter.
 * @param onDelete Method executed when removing api parameter.
 * @returns {JSX.Element}
 */
function AdminApiTestDialog({
    apiId,
    testValue,
    onClose,
    onCreate,
    onUpdate,
    onDelete,
}) {
    const cxt = useContext(AppContext);
    const testsService = useTestsService();
    const form = useForm();
    const [test, setTest] = useState(testValue);
    const deleteMutation = useMutation(
        (params) => testsService.remove(params?.id),
        {
            onSuccess: onDelete,
        },
    );
    const updateMutation = useMutation(
        (params) => testsService.update(params?.id, params?.params),
        {
            onSuccess: (data) => {
                onUpdate(data);
                setTest(data?.test);
            },
        },
    );
    const createMutation = useMutation(
        (params) => testsService.create(params?.params),
        {
            onSuccess: (data) => {
                onCreate(data);
                setTest(data?.test);
            },
        },
    );
    const [, loader] = useLoader([
        deleteMutation.status,
        updateMutation.status,
        createMutation.status,
    ]);

    /**
     * Method executed when the user clicks on save or create test.
     * @param formData Form data.
     */
    const mutateTest = (formData) => {
        if (test?.id) {
            updateMutation.mutate({ id: test?.id, params: formData });
        } else {
            createMutation.mutate({ params: formData });
        }
    };

    /**
     * When the "test" data is returned by the API, it is set in the form.
     */
    useEffect(() => {
        if (test) {
            form.reset({
                type: test?.type,
                hart: test?.hart,
                function: test?.function,
                status: test?.status,
                idAPI: apiId,
                description: test?.description,
            });
        } else {
            form.reset({
                type: '',
                hart: '',
                function: '',
                status: '',
                idAPI: apiId,
                description: '',
            });
        }
    }, [test]);

    /**
     * When the value of the test passed to the component is modified, the selected internal
     * test saved in the "test" state will be modified.
     */
    useEffect(() => {
        setTest(testValue);
    }, [testValue]);

    return (
        <SttDialog
            title="Test"
            open={test != null}
            onClose={onClose}
            content={
                <>
                    {loader}
                    <SttValidatedForm
                        form={form}
                        fields={[
                            {
                                type: 'select',
                                name: 'type',
                                caption: cxt.t('Type'),
                                options: [
                                    { caption: cxt.t('Hart'), value: 'UNIT' },
                                    { caption: cxt.t('APIs'), value: 'REST' },
                                    {
                                        caption: cxt.t('Manual'),
                                        value: 'MANUAL',
                                    },
                                ],
                                disableClearable: true,
                            },
                            {
                                type: 'textinput',
                                name: 'hart',
                                caption: cxt.t('Hart'),
                            },
                            {
                                type: 'textinput',
                                name: 'function',
                                caption: cxt.t('Function'),
                            },
                            {
                                type: 'select',
                                name: 'status',
                                caption: cxt.t('Status'),
                                options: [
                                    {
                                        caption: cxt.t('Passed'),
                                        value: 'PASSED',
                                    },
                                    { caption: cxt.t('Error'), value: 'ERROR' },
                                    {
                                        caption: cxt.t('Skipped'),
                                        value: 'SKIP',
                                    },
                                    {
                                        caption: cxt.t('No passed'),
                                        value: 'NO_PASSED',
                                    },
                                ],
                                disableClearable: true,
                            },
                            {
                                type: 'textinput',
                                name: 'idAPI',
                                caption: cxt.t('APIId'),
                                disabled: true,
                            },
                            {
                                type: 'textArea',
                                name: 'description',
                                caption: cxt.t('Description'),
                                rows: 5,
                            },
                        ]}
                    />
                </>
            }
            buttons={[
                {
                    type: 'delete',
                    confirmation: true,
                    onClick: () => deleteMutation.mutate({ id: test?.id }),
                },
                {
                    type: 'save',
                    onClick: form.handleSubmit((formData) =>
                        mutateTest(formData),
                    ),
                },
            ]}
        />
    );
}

export default AdminApiTestDialog;
