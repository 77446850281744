import React, { useContext } from 'react';
import { Chip, Tooltip } from '@material-ui/core';
import { AppContext } from '../all';

export function SttChipAccessType({ access, onClick }) {
    const cxt = useContext(AppContext);

    if (!access) {
        return null;
    }

    let item = {
        type: access.type,
    };

    if (access.booking) {
        item = {
            ...item,
            id: access.booking?.id,
            name: cxt.t('Booking'),
        };
    } else if (access.bonoUser) {
        /* Creo que esto no sirve para nada ya que siempre habrá un booking si hay un bonoUser */
        item = {
            ...item,
            id: access.bonoUser?.id,
            name: cxt.t('Bono'),
        };
    } else if (access.groupMember) {
        item = {
            ...item,
            id: access.groupMember?.group?.id,
            name: cxt.t('abono'),
        };
    } else if (access.student) {
        item = {
            ...item,
            id: access.student?.id,
            name: cxt.t('Class'),
        };
    } else if (access.boleto) {
        item = {
            ...item,
            id: access.boleto?.id,
            name: cxt.t('Bono'),
        };
    } else {
        return null;
    }

    return (
        <Tooltip arrow placement="top" title={item.id}>
            <Chip
                clickable
                size="small"
                label={item?.name}
                onClick={() => {
                    if (onClick) {
                        onClick(item);
                    }
                }}
            />
        </Tooltip>
    );
}
