import React, { useState, useEffect, useContext } from 'react';
import { AppContext, SttTable, SttTopControls } from '../../../sporttia/all';
import { useCrud, useToggle } from '../../../lib/hooks';
import ModalCategory from './ModalCategory';
import { copyToClipboard, parsePrice } from '../../../lib/utils';

export default function EventCategories({ items, onChange, event }) {
    const [categories, setCategories, , Get, Post, Put, Delete] = useCrud();
    const [selectedCat, setSelectedCat] = useState();
    const [trash, toggleTrash] = useToggle(() => {
        // eslint-disable-next-line no-use-before-define
        loadCategories();
    });

    const cxt = useContext(AppContext);

    useEffect(() => {
        if (items) {
            setCategories({
                count: items.length,
                rows: items,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    function loadCategories(params = { page: 1, rows: 25 }) {
        Get(`/events/${event.id}/categories`, { ...params, trash }).then(
            (result) => {
                if (result.rows) {
                    setCategories(result);
                }
            },
        );
    }

    function saveCategory(category) {
        const params = {
            name: category.name,
            description: category.description,
            enrollment: category.enrollment
                ? parsePrice(category.enrollment)
                : 0,
            maxParticipants: category.maxParticipants
                ? parseInt(category.maxParticipants, 10)
                : null,
            maxTeams: category.maxTeams
                ? parseInt(category.maxTeams, 10)
                : null,
            idMandatory: category?.mandatory ? category.mandatory.id : null,
        };

        // Put or Post depending on existing id
        if (category.id) {
            Put(`/events/categories/${category.id}`, params)
                .then((response) => {
                    const cat = response.category;
                    const newCategories = {
                        ...categories,
                        rows: categories.rows.map((c) =>
                            c.id === cat.id ? cat : c,
                        ),
                    };
                    setCategories(newCategories);
                    setSelectedCat(null);
                    if (onChange) {
                        onChange(newCategories.rows);
                    }
                })
                .catch(() => {});
        } else {
            Post(`/events/${event.id}/categories`, params)
                .then((response) => {
                    const cat = response.category;
                    const newCategories = {
                        ...categories,
                        rows: [...categories.rows, cat],
                    };
                    setCategories(newCategories);
                    setSelectedCat(null);
                    if (onChange) {
                        onChange(newCategories.rows);
                    }
                })
                .catch(() => {});
        }
    }

    function deleteCategory(category) {
        Delete(`/events/categories/${category.id}`)
            .then(() => {
                setSelectedCat(null);
                loadCategories();
                if (onChange) {
                    onChange(
                        categories.rows.filter((row) => row.id !== category.id),
                    );
                }
            })
            .catch(() => {});
    }

    function recoverCategory(cat) {
        Put(`/events/categories/${cat.id}/recover`)
            .then(() => {
                const newCategories = {
                    ...categories,
                    rows: categories.rows.map((c) =>
                        c.id === cat.id ? { ...c, trash: false } : c,
                    ),
                };
                setCategories(newCategories);
                setSelectedCat(null);
                if (onChange) {
                    onChange(newCategories.rows);
                }
            })
            .catch(() => {});
    }

    if (!categories) {
        return null;
    }

    return (
        <>
            <SttTopControls
                mainAction={{
                    type: 'create',
                    caption: cxt.t('Create'),
                    onClick: () =>
                        setSelectedCat({
                            category: {
                                id: 0,
                            },
                        }),
                }}
                onToggleTrash={toggleTrash}
                pl={0}
            />
            <SttTable
                autoload={false}
                data={categories}
                onFetch={loadCategories}
                columns={[
                    {
                        title: '#',
                        type: 'tooltip',
                        value: (row) => ({ label: '#', value: row.id }),
                        onClick: (row) => {
                            copyToClipboard(row.id, () =>
                                cxt.showMessage(
                                    'S',
                                    cxt.t('copiedToClipboard'),
                                ),
                            );
                        },
                    },
                    {
                        title: cxt.t('Name'),
                        type: 'link',
                        field: 'name',
                        align: 'left',
                        onClick: (row) => setSelectedCat(row),
                    },
                    {
                        title: cxt.t('Enrollment'),
                        type: 'price',
                        field: 'enrollment',
                        align: 'center',
                    },
                    {
                        title: event.teamable
                            ? cxt.t('Teams')
                            : cxt.t('Participants'),
                        value: (row) =>
                            event.teamable
                                ? `${row.numTeams} / ${row.maxTeams || '∞'}`
                                : `${row.numParticipants} / ${
                                      row.maxParticipants || '∞'
                                  }`,
                    },
                    {
                        title: cxt.t('MandatoryGroup'),
                        value: (row) => row.mandatory && row.mandatory.name,
                        align: 'right',
                    },
                ]}
            />
            <ModalCategory
                item={selectedCat}
                eventName={event.name}
                teamable={event.teamable}
                onClose={() => setSelectedCat(null)}
                onSave={saveCategory}
                onDelete={deleteCategory}
                onRecover={recoverCategory}
            />
        </>
    );
}
