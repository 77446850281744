import React, { useState, useEffect, useContext } from 'react';
import { Paper } from '@material-ui/core';
import moment from 'moment';
import ActivityDetails from '../activities/Activity';
import { getPath } from '../../Pages';
import { SttTabs } from '../../../sporttia/SttTabs';
import { AppContext, SttFullDialog } from '../../../sporttia/all';
import { useCrud } from '../../../lib/hooks';
import EventDetails from './EventDetails';
import EventCategories from './EventCategories';
import EventParticipants from './EventParticipants';
import EventTeams from './EventTeams';
import EventPeriods from './EventPeriods';
import EventForm from './EventForm';
import EventDocuments from './EventDocuments';

export default function Event({ id, trash, onChange, onClose }) {
    const cxt = useContext(AppContext);
    const [event, setEvent, , Get] = useCrud();

    useEffect(() => {
        if (!isNaN(parseInt(id))) {
            loadEvent();
        } else if (id === 'create') {
            setEvent({
                name: '',
                kind: '',
                teamable: false,
                inscIni: moment().format('YYYY-MM-DD 08:00:00'),
                inscEnd: moment().format('YYYY-MM-DD 23:30:00'),
                ini: moment().format('YYYY-MM-DD 08:00:00'),
                end: moment().format('YYYY-MM-DD 23:30:00'),
                paymentForms: [],
                sport: {
                    id: 0,
                },
            });
        } else {
            setEvent(null);
        }
    }, [id]);

    function loadEvent() {
        Get(`/events/${id}`, { trash })
            .then((response) => {
                if (response.event) {
                    setEvent(response.event);
                    cxt.setHeader({
                        title: response.event?.name,
                        urlBack: getPath('events'),
                    });
                }
            })
            .catch(console.log);
    }

    if (!event) {
        return null;
    }

    // Tabs
    const tabs = [
        {
            caption: cxt.t('Detail'),
            component: (
                <EventDetails
                    item={event}
                    onSave={(newEvent) => {
                        const _event = {
                            ...newEvent,
                            categories: newEvent.categories || event.categories,
                        };
                        setEvent(_event);
                        onChange(_event);
                    }} /* BUG with PUT /events: el event devuelto no contiene 'categories'  */
                    onDelete={(newEvent) => {
                        const _event = {
                            ...newEvent,
                            trash: 1,
                            categories: newEvent.categories || event.categories,
                        };
                        onChange(_event);
                        onClose && onClose();
                    }}
                />
            ),
        },
        {
            caption: cxt.t('Categories'),
            component: (
                <EventCategories
                    items={event && event.categories}
                    onChange={(categories) => {
                        setEvent({ ...event, categories });
                    }}
                    event={event}
                />
            ),
            show: (event && event.id) || false,
        },
        {
            caption: cxt.t('Participants'),
            component: <EventParticipants event={event} />,
            show: (event && event.id && !event.teamable) || false,
        },
        {
            caption: cxt.t('Teams'),
            component: <EventTeams event={event} />,
            show: (event && event.id && event.teamable) || false,
        },
        {
            caption: cxt.t('Timetables'),
            component: <EventPeriods event={event} />,
            show: (event && event.id) || false,
        },
        {
            caption: cxt.t('Form'),
            component: (
                <EventForm
                    event={event}
                    onChange={(form) => {
                        setEvent({ ...event, form });
                        onChange({ ...event, form });
                    }}
                />
            ),
            show: (event && event.id) || false,
        },
        {
            caption: cxt.t('Documents'),
            component: <EventDocuments event={event} />,
            show: (event && event.id) || false,
        },
        /* {caption: cxt.t('Rates'), component: <ActivityRates item={activity} />},
		{caption: cxt.t('Classes'), component: <ActivityClasses teachers={teachers} item={activity}/>},
		{caption: cxt.t('Students'), component: <ActivityStudents item={activity} />},
		{caption: cxt.t('Form'), component: <ActivityForm onChange={loadActivity} item={activity} />},
		{caption: cxt.t('Devices'), component: <ActivityDevices item={activity} />}, */
    ];

    return (
        <SttFullDialog
            open
            onClose={onClose}
            title={id === 'create' ? cxt.t('CreateEvent') : event.name}
        >
            <SttTabs tabs={tabs} />
        </SttFullDialog>
    );
}
