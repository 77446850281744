import React, { useContext } from 'react';
import moment from 'moment';
import { AppContext } from '../all';

/**
 *	LABEL DURATION
 *	==============
 *
 *	Turn "0000-02-00" into "2 months"
 *
 * Props
 *
 * 		* string duration: 0000-01-00 00:00:00
 */
export function SttLabelDuration({ duration }) {
    const cxt = useContext(AppContext);

    // Render
    return duration ? (
        <>{cxt.t(duration.length < 11 ? `${duration} 00:00:00` : duration)}</>
    ) : null;
}
