import React, { useState, useEffect, useContext } from 'react';
import { TextField, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AppContext } from '../all';

// Componente para seleccionar instalaciones mediante autocomplete
export function SttSelectFacility({
    grid,
    caption,
    defVal,
    size = 'small',
    variant = 'outlined',
    disabled,
    onChange,
    ...rest
}) {
    const cxt = useContext(AppContext);
    const [facilities, setFacilities] = useState([]);

    // Load data
    function loadFacilities(params = { page: 1, rows: 1000 }) {
        cxt.api('GET', `/scs/${cxt.sc.id}/facilities`, {
            params,
            success: (response) => {
                setFacilities(response.rows);
            },
        });
    }

    // Init
    useEffect(loadFacilities, []);

    // -----| Render |-----
    const content = (
        <Autocomplete
            disabled={disabled}
            value={facilities.find((facility) => facility.id === defVal) || ''}
            options={facilities}
            getOptionLabel={(option) => option.name || ''}
            onChange={(ev, facility) => onChange(facility)}
            renderInput={(params) => (
                <TextField
                    fullWidth
                    {...params}
                    size={size}
                    label={caption}
                    variant={variant}
                />
            )}
        />
    );

    return grid ? (
        <Grid item {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
