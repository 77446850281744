import makeStyles from '@material-ui/styles/makeStyles';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

type ConsumptionProps = {
    caption: string;
    amount: number;
    consumed: number;
};

export default function Consumption({
    caption,
    amount,
    consumed,
}: ConsumptionProps) {
    const useStyles = makeStyles(() => ({
        container: {
            width: '100%',
            height: 24,
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'row-reverse',
        },
        item: {
            marginLeft: 2,
            flex: 1,
        },
        first: {
            margin: 0,
        },
        consumed: {
            backgroundColor: '#c2c2d6',
        },
        available: {
            backgroundColor: '#7AB97A',
        },
    }));

    const classes = useStyles();

    const items = [];
    // Primero establecemos el tamaños de los grupos de slots que vamos a renderizar. Usamos 10 como maximo por temas de
    // visualización.
    const slotsSizeDivisor = Math.min(10, amount);
    // Calculamos que grupos de slots se han consumido ya para visualizar los sets de slots que aun no se han consumido
    const currentSetOfSlots = Math.floor(Math.abs(consumed) / slotsSizeDivisor);
    // Mostraremos los consumos en sets de slotSizeDivisor para evitar casos como el centro 3208 donde tienen 100000 consumos por bono
    for (
        let currentSlot = currentSetOfSlots * slotsSizeDivisor;
        currentSlot < currentSetOfSlots * slotsSizeDivisor + slotsSizeDivisor;
        currentSlot++
    ) {
        items.push(
            <Box
                key={currentSlot}
                className={clsx({
                    [classes.item]: true,
                    [classes.first]: currentSlot === amount - 1, // 'first' en realidad es el último elemento de la iteración, ya que los estamos pintando en 'row-reverse'
                    [classes.consumed]:
                        currentSlot < consumed || consumed === amount,
                    [classes.available]:
                        currentSlot >= consumed && consumed < amount,
                })}
            />,
        );
    }

    return (
        <div style={{ width: '100%' }}>
            {caption && (
                <Box mb={1}>
                    <Typography variant="body2">{caption}</Typography>
                </Box>
            )}
            <Box className={classes.container}>{items}</Box>
        </div>
    );
}
