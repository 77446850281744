import React, { useState, useEffect, useContext } from 'react';
import { Avatar, Box, Chip, Grid } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import { AppContext, SttTable } from '../../sporttia/all';
import { fullName } from '../../lib/utils';
import DateBuilder from '../../lib/DateBuilder';

export default function PaymentModalChildren({ payment }) {
    const cxt = useContext(AppContext);

    return (
        <Box>
            {payment.children && (
                <SttTable
                    data={payment.children}
                    columns={[
                        {
                            field: 'created',
                            title: cxt.t('Created'),
                            value: (row) =>
                                new DateBuilder(
                                    row.created,
                                    cxt?.sc?.timezone?.name,
                                    cxt?.lang?.key,
                                ).dmy(),
                        },
                        // {field:'creator', title:cxt.t('Creator'), type:'user.login'},
                        // {field:'module', title:cxt.t('Module'), type:'module'},
                        {
                            field: 'concept',
                            title: cxt.t('Concept'),
                            type: 'link',
                            align: 'left',
                            value: (r) => (
                                <>
                                    {r.concept}
                                    {r.user && (
                                        <Box mt={1}>
                                            <Chip
                                                size="small"
                                                icon={<FaceIcon />}
                                                label={
                                                    r.user?.mship
                                                        ? fullName(
                                                              r.user?.mship,
                                                          )
                                                        : r.name
                                                }
                                            />
                                        </Box>
                                    )}
                                </>
                            ),
                        },
                        // {field:'period', title:cxt.t('Period'), value:r => ({ini:r.ini, end:r.end}), type:'period'},
                        // {field:'collector', title:cxt.t('Collector'), type:'user.login'},
                        {
                            field: 'paymentForm',
                            title: cxt.t('PForm'),
                            type: 'pf',
                        },
                        {
                            field: 'paymentDate',
                            title: cxt.t('Paid'),
                            type: 'dmy',
                        },
                        {
                            field: 'price',
                            title: cxt.t('Price'),
                            type: 'price',
                        },
                    ]}
                    // onFetch={loadPayments}
                />
            )}
        </Box>
    );
}
