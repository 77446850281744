import {
    Box,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    TextField,
} from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';
import React from 'react';
import { isDefined } from '../../lib/utils';

/**
 *	Form select
 *	===========
 *
 * Props
 *
 * 	* bool grid - wrap the element into a <Grid>
 * 	* Array options - options as an array of {caption, value}
 * 	* <any> defVal - the currently selected value
 * 	* boolean cleanable: if it's true, a clean button (X) is added to reset the selection.
 * 	* string name - the field name
 * 	* string caption - the field caption (top label & placeholder text)
 * 	* Function onChange(value) - callback when the selected value changes
 * 	* bool disabled
 * 	* bool mandatory - if mandatory, the value cannot be de-selected
 */
export function SttFormSelect({
    grid = false,
    options = [{ label: 'Empty', value: 0 }],
    defVal,
    name,
    cleanable = false,
    caption,
    onChange,
    disabled = false,
    mandatory = false,
    translate = true,
    ...rest
}) {
    const handleChange = (ev) => {
        if (onChange && onChange.constructor === Function) {
            onChange({ name, value: ev.target.value });
        }
    };

    // Los Select de material-ui tienen un bug por el cual si el 'value' de una opción no evalúa a true (por ej, 0, null, undefined, etc) el caption no se pinta al tener la opción seleccionada.
    const content = (
        <TextField
            fullWidth
            select
            name={name}
            variant="outlined"
            label={caption}
            size="small"
            value={isDefined(defVal) ? defVal : ''}
            onChange={handleChange}
            disabled={disabled}
            // Si el campo es select es 'mandatory' significa que no se puede de-seleccionar el valor
            // TODO: arreglar la 'x' para que funcione bien el hover
            InputProps={
                !mandatory && cleanable
                    ? {
                          endAdornment: (
                              <InputAdornment
                                  position="end"
                                  onClick={() =>
                                      onChange({ name, value: null })
                                  }
                              >
                                  {defVal && !disabled ? (
                                      <Box mr={1}>
                                          <IconButton>
                                              <ClearIcon fontSize="small" />
                                          </IconButton>
                                      </Box>
                                  ) : (
                                      <></>
                                  )}
                              </InputAdornment>
                          ),
                      }
                    : {}
            }
        >
            {/* JPB, 17 Abr 20. Se aceptan duplas value/cpation y id/label */}
            {options &&
                options.map((op, i) => (
                    <MenuItem
                        className={translate ? '' : 'notranslate'}
                        key={i}
                        value={
                            op.hasOwnProperty('value')
                                ? op.value
                                : op.hasOwnProperty('id')
                                ? op.id
                                : op.key
                        }
                    >
                        {op.caption || op.label}
                    </MenuItem>
                ))}
        </TextField>
    );

    return grid ? (
        <Grid item {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
