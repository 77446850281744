import React from 'react';
import { Button, ButtonGroup } from '@material-ui/core';

/**
 * Generate years array
 */
function generateYears(currentYear: number, currentRadius: number): number[] {
    const yearsArray: number[] = [];
    for (
        let i = currentYear - currentRadius;
        i <= currentYear + currentRadius;
        i += 1
    ) {
        yearsArray.push(i);
    }

    return yearsArray;
}

type SttButtonYearsProps = {
    year: number;
    radius?: number;
    onSelectYear: (year: number) => void;
};

/**
 * Button years
 */
export function SttButtonYears({
    year,
    radius = 2,
    onSelectYear,
}: SttButtonYearsProps) {
    const years = generateYears(year, radius);

    return (
        years.length > 0 && (
            <ButtonGroup size="small">
                {years.map((ye) => (
                    <Button
                        key={ye}
                        variant={ye === year ? 'contained' : 'outlined'}
                        color={ye === year ? 'primary' : 'default'}
                        onClick={() => onSelectYear(ye)}
                    >
                        {ye}
                    </Button>
                ))}
            </ButtonGroup>
        )
    );
}
