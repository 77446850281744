import React, { useContext } from 'react';
import { Box, Link, Grid, Typography } from '@material-ui/core';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { AppContext, SttButtonFollow } from '../../all';
import { getPath } from '../../../pages/Pages';

import useScsService from '../../../services/ScsService';
import { formatPriceByLocale } from '../../../lib/utils';

/**
 * Card representing a sports center.
 * @param sc Sport center object.
 * @returns {JSX.Element}
 */
export function SttSCCard({ sc }) {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const classes = useStyles();
    const scsService = useScsService();
    const updateFollowMutation = useMutation((params) =>
        scsService.updateScsFollow(params?.id),
    );
    const updateUnfollowMutation = useMutation((params) =>
        scsService.updateScsUnfollow(params?.id),
    );

    /**
     * Toggle following
     */
    const toggleFollowing = () => {
        if (sc.following) {
            sc.following = false;
            updateUnfollowMutation.mutate({ id: sc.id });
        } else {
            sc.following = true;
            updateFollowMutation.mutate({ id: sc.id });
        }
    };

    let logoUrl;
    if (sc?.logo?.id) {
        logoUrl = sc?.logo?.url
            ? `${sc.logo.url}?outfit=70`
            : cxt.getResource(
                  `/images/${sc.logo ? sc.logo.id : 0}.jpg?outfit=70`,
              );
    } else {
        logoUrl = cxt.getResource(`/images/1.jpg?outfit=70`); // logo de Sporttia (img por defecto)
    }

    return (
        <>
            {sc && (
                <Box
                    p={3}
                    style={{
                        position: 'relative',
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item md={2}>
                            <Box align="center">
                                <img src={logoUrl} />
                            </Box>
                        </Grid>

                        <Grid item md={9}>
                            <Link
                                onClick={() =>
                                    history.push(
                                        getPath('scProfile', { id: sc.id }),
                                    )
                                }
                            >
                                <Typography variant="h5">{sc.name}</Typography>
                            </Link>

                            <Box>
                                <Typography variant="body1">
                                    {sc.city && (
                                        <>
                                            <b>{sc.city.name}</b>
                                            {sc.city.province &&
                                                ` ( ${sc.city.province.name} )`}
                                        </>
                                    )}
                                </Typography>
                            </Box>

                            {sc.distance ? (
                                <Box>
                                    <Typography variant="body2">
                                        <b>{sc.distance}</b> kms
                                    </Typography>
                                </Box>
                            ) : (
                                ''
                            )}

                            <Box mt={4}>
                                <b>{sc.numFollowers}</b> {cxt.t('followers')}
                            </Box>
                        </Grid>
                    </Grid>

                    {/* --- Buttons --- */}
                    <Box
                        style={{
                            position: 'absolute',
                            top: 20,
                            right: 20,
                        }}
                    >
                        <Box display="flex" justifyContent="flex-end">
                            <Box className={classes.purse} mr={1}>
                                {sc && sc.mship && sc.mship.amount !== 0 && (
                                    <>
                                        <Typography variant="overline">
                                            {cxt.t('Purse')}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            align="right"
                                            className={classes.purseMoney}
                                        >
                                            <b>
                                                {formatPriceByLocale(
                                                    sc?.mship?.amount,
                                                    sc?.mship?.amountShape
                                                        ?.currency,
                                                    sc?.mship?.amountShape
                                                        ?.locale,
                                                )}
                                            </b>
                                        </Typography>
                                    </>
                                )}
                            </Box>
                            <Box>
                                {cxt?.logged && (
                                    <SttButtonFollow
                                        isFollowing={sc.following}
                                        onToggleFollowing={() =>
                                            toggleFollowing()
                                        }
                                    />
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
}

const useStyles = makeStyles(() => ({
    purse: {
        height: 59,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    purseMoney: {
        marginTop: '-10px',
    },
}));
