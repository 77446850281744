import React from 'react';
import { usePageHeader } from '../../../../lib/hooks';
import Report from '../../../sc/Report';

/**
 * View where the sporttia superadmin can see the list of rentals.
 * @returns {JSX.Element}
 */
function AdminRentals({}) {
    usePageHeader('rentals');

    return <Report id={28} navigateBack="/home" />;
}

export default AdminRentals;
