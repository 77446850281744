import React, { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { IconButton, Paper } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import Link from '@material-ui/core/Link';
import useApisService from '../../../../services/ApisService';
import { usePageHeader, useQueryFilters } from '../../../../lib/hooks';
import { AppContext } from '../../../../sporttia/AppContext';
import { SUPERADMIN_APIS } from '../../../../lib/queryKeys';
import { SttChipHttpVerb } from '../../../../sporttia/chips/SttChipHttpVerb';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';
import AdminApi from './AdminApi';
import { SttButtonFab } from '../../../../sporttia/buttons/SttButtonFab';
import { copyToClipboard } from '../../../../lib/utils';

/**
 * View where the sporttia superadmin can see the list of hart end points/apis.
 * @returns {JSX.Element}
 */
export default function AdminApis({}) {
    usePageHeader('apis');
    const cxt = useContext(AppContext);
    const queryClient = useQueryClient();
    const apisService = useApisService();
    const [filters, setFilters] = useQueryFilters({}, SUPERADMIN_APIS);
    const [apiId, setApiId] = useState(null);

    /**
     * In this case, before making the request (due to Material UI problems), if the value of "method"
     * takes the value of "ALL", it must be removed from the parameters sent.
     * @param updatedFilters Object with the parameters returned by the SttTopFilteringControls component.
     */
    const doFiltering = (updatedFilters) => {
        if (updatedFilters.method === 'ALL') delete updatedFilters.method;

        setFilters(updatedFilters);
    };

    return (
        <Paper>
            <SttTopFilteringControls
                p={2}
                fields={[
                    {
                        type: 'select',
                        name: 'idVersion',
                        caption: cxt.t('Version'),
                        value: 3,
                        options: [
                            { id: 3, label: 'V6' },
                            { id: 1, label: 'V5' },
                        ],
                        md: 2,
                    },
                    {
                        type: 'text',
                        name: 'category',
                        caption: cxt.t('Category'),
                        value: '',
                        md: 3,
                    },
                    {
                        type: 'text',
                        name: 'endpoint',
                        caption: cxt.t('Endpoint'),
                        value: '',
                        md: 3,
                    },
                    {
                        type: 'select',
                        name: 'method',
                        caption: cxt.t('Method'),
                        value: 'ALL',
                        options: [
                            { id: 'ALL', label: 'Todos' },
                            { id: 'GET', label: 'GET' },
                            { id: 'POST', label: 'POST' },
                            { id: 'PUT', label: 'PUT' },
                            { id: 'DELETE', label: 'DELETE' },
                        ],
                        md: 2,
                    },
                ]}
                onFilter={doFiltering}
            />

            <SttCachedTable
                queryKey={SUPERADMIN_APIS}
                queryFn={(params) => apisService.getList(params)}
                queryParams={filters}
                prefetching={false}
                columns={[
                    {
                        title: cxt.t('Actions'),
                        value: (row) => (
                            <IconButton
                                size="small"
                                aria-label="delete"
                                onClick={() =>
                                    copyToClipboard(row.endpoint, () =>
                                        cxt.showMessage(
                                            'S',
                                            cxt.t('copiedToClipboard'),
                                        ),
                                    )
                                }
                            >
                                <FileCopy fontSize="inherit" />
                            </IconButton>
                        ),
                    },
                    {
                        title: cxt.t('Method'),
                        field: 'method',
                        value: (row) => (
                            <SttChipHttpVerb httpVerb={row.method} />
                        ),
                    },
                    {
                        title: cxt.t('Resource'),
                        field: 'endpoint',
                        align: 'left',
                        value: (row) => (
                            <Link onClick={() => setApiId(row.id)}>
                                <b>{row.endpoint}</b>
                            </Link>
                        ),
                    },
                    {
                        title: cxt.t('Category'),
                        field: 'category',
                    },
                    {
                        title: cxt.t('Name'),
                        field: 'name',
                        align: 'left',
                    },
                    {
                        title: cxt.t('Object'),
                        field: 'objectfunc',
                        align: 'left',
                        value: (row) => `${row.object}.${row.function}`,
                    },
                    {
                        title: cxt.t('Public'),
                        field: 'public',
                        type: 'active',
                    },
                    {
                        title: cxt.t('Tests'),
                        field: 'numTests',
                        type: 'counter',
                    },
                    {
                        title: cxt.t('Execs'),
                        field: 'numExecutions',
                        type: 'counter',
                    },
                    {
                        title: cxt.t('Errors'),
                        field: 'numErrors',
                        type: 'counter',
                    },
                ]}
            />

            <AdminApi
                id={apiId}
                onClose={() => {
                    setApiId(null);
                    queryClient.invalidateQueries(SUPERADMIN_APIS);
                }}
            />

            <SttButtonFab onClick={() => setApiId('create')} />
        </Paper>
    );
}
