import React, { useState, useContext } from 'react';
import { Paper, Box, Grid, Switch, FormControlLabel } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Add as AddIcon } from '@material-ui/icons';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import {
    AppContext,
    SttButton,
    SttList,
    SttSelectMshipDialog,
    SttSelectGroupDialog,
} from '../../../sporttia/all';
import translations from '../../../translations';

export default function Receptors({
    receptors,
    sendAllSC,
    setSendAllSC,
    onAddReceptor,
    onDelReceptor,
}) {
    const cxt = useContext(AppContext);
    const [openedSelectingMship, setOpenedSelectingMship] = useState(false);
    const [openedSelectingGroup, setOpenedSelectingGroup] = useState(false);

    const addReceptor = ({ mship, group }) => {
        // Add mship to receptors
        if (mship) {
            // Check if it exists
            if (receptors.find((e) => e.mship && e.mship.id === mship.id)) {
                cxt.showMessage('E', cxt.t('UserAlreadyOnList'));
            } else {
                onAddReceptor({
                    id: receptors.length,
                    caption: mship.name,
                    mship,
                    objToSelect: mship,
                    avatar: <PersonOutlineIcon />,
                });
                cxt.showMessage('S', cxt.t(translations.generic.added));
            }
        }

        // Add group to receptors
        if (group) {
            // Check if it exists
            if (receptors.find((e) => e.group && e.group.id === group.id)) {
                cxt.showMessage('E', cxt.t('GroupAlreadyExists'));
            } else {
                onAddReceptor({
                    id: receptors.length,
                    caption: group.name,
                    group,
                    objToSelect: group,
                    avatar: <PeopleOutlineIcon />,
                });
                cxt.showMessage('S', cxt.t(translations.generic.added));
            }
        }
    };

    return (
        <Paper>
            <Box p={2}>
                <FormControlLabel
                    label={cxt.t('SendAllSC')}
                    control={
                        <Switch
                            checked={sendAllSC}
                            onChange={(ev) => setSendAllSC(ev.target.checked)}
                            color="primary"
                        />
                    }
                />

                {sendAllSC ? (
                    <Box mt={2}>
                        <Alert severity="info">{cxt.t('SendAllSCAlert')}</Alert>
                    </Box>
                ) : (
                    <>
                        <SttList
                            title={cxt.t('Receptors')}
                            numRows={8}
                            data={receptors}
                            onDelete={onDelReceptor}
                        />

                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <SttButton
                                    fullWidth
                                    startIcon={<AddIcon />}
                                    caption={cxt.t(translations.generic.user)}
                                    onClick={() =>
                                        setOpenedSelectingMship(true)
                                    }
                                />
                            </Grid>
                            <Grid item md={6}>
                                <SttButton
                                    fullWidth
                                    startIcon={<AddIcon />}
                                    caption={cxt.t(translations.generic.group)}
                                    onClick={() =>
                                        setOpenedSelectingGroup(true)
                                    }
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Box>

            <SttSelectMshipDialog
                open={openedSelectingMship}
                onClose={() => setOpenedSelectingMship(false)}
                onSelectMship={(mship) => addReceptor({ mship })}
            />

            {openedSelectingGroup && (
                <SttSelectGroupDialog
                    open
                    onClose={() => setOpenedSelectingGroup(false)}
                    onSelect={(group) => addReceptor({ group })}
                />
            )}
        </Paper>
    );
}
