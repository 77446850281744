import React, { useState, useEffect, useContext } from 'react';
import { Chip, Box } from '@material-ui/core';
import { AppContext } from '../all';

export function SttChipFacilityRow({ facilities, maxWidth = 'auto' }) {
    const cxt = useContext(AppContext);

    /**
     * Get initials
     */
    const getInitials = (name) => {
        if (!name) {
            return '';
        }

        const words = name.split(' ');
        const result = [];
        words.forEach((word) => {
            if (!['-', '(', ')'].includes(word))
                return result.push(word.substring(0, 1));
        });

        return result;
    };

    // -----| Render |-----
    return (
        <Box
            display="flex"
            justifyContent="center"
            maxWidth={maxWidth}
            flexWrap="wrap"
        >
            {facilities &&
                facilities.map((facility, idx) => (
                    <Box key={idx} mr={1} mt={1}>
                        <Chip size="small" label={getInitials(facility.name)} />
                    </Box>
                ))}
        </Box>
    );
}
