import React, { useState, useEffect, useContext } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    DialogContent,
} from '@material-ui/core';
import { AppContext } from '../../sporttia/all';

/**
 * ConfirmDialog: A simple 'title', 'text', 'yes', 'no'. For use with useConfirmDialog hook (see /src/lib/hooks.js)
 * @param bool open
 * @param string title
 * @param ReactNode content
 * @param function onConfirm
 * @param function onCancel
 * @returns ReactNode
 */
export default function ConfirmDialog({
    open,
    title,
    content,
    onConfirm,
    onCancel,
}) {
    const cxt = useContext(AppContext);

    return (
        <Dialog open={open} onClose={onCancel}>
            {title && <DialogTitle>{title}</DialogTitle>}
            {content && <DialogContent>{content}</DialogContent>}
            <DialogActions>
                <Button onClick={onCancel} color="default">
                    {cxt.t('Reject')}
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus>
                    {cxt.t('Accept')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
