import React, { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link, Paper } from '@material-ui/core';
import { SttTopFilteringControls } from '../../../sporttia/SttTopFilteringControls';
import { usePageHeader, useQueryFilters } from '../../../lib/hooks';
import { SPORTCENTER_DISCOUNTS } from '../../../lib/queryKeys';
import { AppContext } from '../../../sporttia/AppContext';
import SttCachedTable from '../../../sporttia/SttCachedTable';
import useScsService from '../../../services/ScsService';
import Discount from '../../../types/models/Discount';
import { GetScsDiscountsParams } from '../../../types/api/paths/Scs';
import { copyToClipboard } from '../../../lib/utils';
import { SttButtonFab } from '../../../sporttia/all';
import DiscountEditDialog from './DiscountEditDialog';
import translations from '../../../translations';
import { DefaultListParams } from '../../../types/api/utils';

export default function Discounts() {
    usePageHeader(translations.generic.discounts);

    const cxt = useContext(AppContext)!;

    const [selectedDiscount, setSelectedDiscount] = useState<Discount>();
    const [isDiscountEditDialogOpen, setIsDiscountEditDialogOpen] =
        useState(false);

    const scsService = useScsService();

    const [filters, setFilters] = useQueryFilters<GetScsDiscountsParams>(
        {},
        SPORTCENTER_DISCOUNTS,
    );

    const queryClient = useQueryClient();
    const queryParams = { ...filters, id: cxt.sc!.id };
    const queryKey = [SPORTCENTER_DISCOUNTS, queryParams];

    return (
        <Paper>
            {/* @ts-expect-error TS(2739): Migrar SttTopFilteringControls */}
            <SttTopFilteringControls
                p={2}
                fields={[
                    {
                        type: 'text',
                        caption: cxt.t('Name'),
                        name: 'name',
                    },
                ]}
                onFilter={(value: GetScsDiscountsParams) => {
                    setFilters(value);
                }}
            />

            <SttCachedTable
                queryKey={SPORTCENTER_DISCOUNTS}
                queryFn={({
                    id,
                    ...params
                }: typeof queryParams & DefaultListParams) =>
                    scsService.getScsDiscounts(id, params)
                }
                /* @ts-expect-error: Migrar SttCachedTable */
                queryParams={queryParams}
                prefetching
                columns={[
                    {
                        title: '#',
                        type: 'tooltip',
                        value: (row: Discount) => ({
                            label: '#',
                            value: row.id,
                        }),
                        onClick: (row: Discount) =>
                            copyToClipboard(row.id, () => {
                                cxt.showMessage(
                                    'S',
                                    cxt.t('copiedToClipboard'),
                                );
                            }),
                    },
                    {
                        title: cxt.t('Name'),
                        type: 'link',
                        field: 'name',
                        align: 'left',
                        value: (row: Discount) => (
                            <Link
                                onClick={() => {
                                    setSelectedDiscount(row);
                                    setIsDiscountEditDialogOpen(true);
                                }}
                                variant="body2"
                            >
                                {row.name}
                            </Link>
                        ),
                    },
                    {
                        title: cxt.t('discountPercentage'),
                        value: (row: Discount) => `${row.discountPercentage}%`,
                    },
                ]}
            />

            {isDiscountEditDialogOpen && (
                <DiscountEditDialog
                    discount={selectedDiscount}
                    onClose={() => {
                        setIsDiscountEditDialogOpen(false);
                        setSelectedDiscount(undefined);
                    }}
                    onUpdate={() => {
                        queryClient.invalidateQueries({ queryKey });
                        setSelectedDiscount(undefined);
                        setIsDiscountEditDialogOpen(false);
                    }}
                    onSave={() => {
                        queryClient.invalidateQueries({ queryKey });
                        setSelectedDiscount(undefined);
                        setIsDiscountEditDialogOpen(false);
                    }}
                    onDelete={() => {
                        queryClient.invalidateQueries({ queryKey });
                        setSelectedDiscount(undefined);
                        setIsDiscountEditDialogOpen(false);
                    }}
                />
            )}

            <SttButtonFab
                onClick={() => {
                    setIsDiscountEditDialogOpen(true);
                }}
            />
        </Paper>
    );
}
