import React, { useState, useEffect, useContext } from 'react';
import {} from '@material-ui/core';
import { AppContext, SttTabs } from '../../../sporttia/all';
import AdminsTab from './AdminsTab';
import RolesTab from './RolesTab';

export default function Roles({}) {
    const cxt = useContext(AppContext);

    /**
     * Init
     */
    useEffect(() => {
        // Set page title
        cxt.setHeader({ title: cxt.t('Administrators') });
    }, []);

    // -----| Render |-----
    return (
        <SttTabs
            tabs={[
                {
                    caption: cxt.t('Administrators'),
                    component: <AdminsTab />,
                },
                {
                    caption: cxt.t('Roles'),
                    component: <RolesTab />,
                },
            ]}
        />
    );
}
