import React, { useContext, useState } from 'react';
import {
    TextField,
    Grid,
    GridProps,
    InputAdornment,
    IconButton,
} from '@material-ui/core';
import { Clear as ClearIcon, Search as SearchIcon } from '@material-ui/icons';
import { AppContext, SttSelectGroupDialog } from '../all';
import { Group } from '../../types/api/models';
import translations from '../../translations';

type SttSelectGroupProps = {
    grid?: boolean;
    caption: string;
    name: string;
    defVal:
        | {
              id: number;
              name: string;
          }
        | null
        | undefined;
    onChange: ({ name, value }: { name: string; value: Group | null }) => void;
    onlyActive?: boolean;
} & Omit<GridProps, 'onChange'>;

export function SttSelectGroup({
    grid = false,
    caption,
    name,
    defVal,
    onChange,
    md = 3,
    xs = 12,
    onlyActive = false,
    ...rest
}: SttSelectGroupProps) {
    const cxt = useContext(AppContext)!;

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const anyGroup = {
        id: 0,
        name: cxt.t(translations.generic.anyGroup),
    };

    const defaultValue = defVal ?? anyGroup;

    const dialog = (
        <SttSelectGroupDialog
            open
            onClose={() => {
                setIsDialogOpen(false);
            }}
            onSelect={(group) => {
                setIsDialogOpen(false);
                onChange({ name, value: group });
            }}
            onlyActive={onlyActive}
        />
    );

    const content = (
        <TextField
            fullWidth
            size="small"
            label={caption}
            variant="outlined"
            value={defaultValue.name}
            InputProps={{
                readOnly: true,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => {
                                onChange({
                                    name,
                                    value: anyGroup,
                                });
                            }}
                        >
                            <ClearIcon />
                        </IconButton>

                        <IconButton
                            onClick={() => {
                                setIsDialogOpen(true);
                            }}
                        >
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );

    return grid ? (
        <Grid item {...rest} md={md} xs={xs}>
            {isDialogOpen && dialog}
            {content}
        </Grid>
    ) : (
        <div>
            {isDialogOpen && dialog}
            {content}
        </div>
    );
}
