import { useContext } from 'react';
import { useAPI } from '../lib/hooks';
import useRestService from './RestService';
import { AppContext } from '../sporttia/AppContext';

const resource = '/subscriptions';

/**
 * Hook to access all 'subscriptions' resources.
 */
export default function useSubscriptionsService() {
    const { migrationApis } = useContext(AppContext);
    const [request] = useAPI(migrationApis);
    const services = useRestService(resource);

    /**
     * GET /customers/:id/subscriptions request.
     * @param id ID parameter of the object to request.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function getSubscriptionLicenses(id, params) {
        return request('GET', `${resource}/${id}/licences`, params);
    }

    /**
     * DELETE /subscriptions/licences/:id request.
     * @param id ID parameter of the object to request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function removeSubscriptionLicenses(id) {
        return request('DELETE', `${resource}/licences/${id}`);
    }

    /**
     * PUT /subscriptions/licences/:id request.
     * @param id ID parameter of the object to request.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function updateSubscriptionLicenses(id, params) {
        return request('PUT', `${resource}/licences/${id}`, params);
    }

    return {
        ...services,
        getSubscriptionLicenses,
        removeSubscriptionLicenses,
        updateSubscriptionLicenses,
    };
}
