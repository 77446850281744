import React from 'react';
import { SttList } from '../../sporttia/all';
import Discount from '../../types/models/Discount';
import { useTranslations } from '../../lib/hooks';
import translations from '../../translations';

type DiscountListProps = {
    discounts: Discount[];
    showEmptyMessage?: boolean;
    showTitle?: boolean;
    onAdd?: () => void;
    onDelete?: (discount: Discount) => void;
    onSelect?: (discount: Discount) => void;
};

export default function DiscountList({
    discounts,
    showEmptyMessage = true,
    showTitle = true,
    onAdd,
    onDelete,
    onSelect,
}: DiscountListProps) {
    const { translate } = useTranslations();

    return discounts.length || showEmptyMessage ? (
        // @ts-expect-error Migrar SttList
        <SttList
            title={
                showTitle
                    ? translate(translations.generic.discounts)
                    : undefined
            }
            data={discounts.map((discount) => ({
                caption: `${discount.name} - ${discount.discountPercentage}%`,
                objToSelect: discount,
            }))}
            onAdd={onAdd}
            onDelete={onDelete}
            onSelect={onSelect}
        />
    ) : null;
}
