import { useContext } from 'react';
import useRestService from './RestService';
import { useAPI } from '../lib/hooks';
import { AppContext } from '../sporttia/AppContext';

const resource = '/golf';

/**
 * Hook to access all 'golf' resources.
 */
export default function useGolfService() {
    const { migrationApis } = useContext(AppContext);
    const [request] = useAPI(migrationApis);
    const services = useRestService(resource);

    /**
     * GET /golf/courses/:id request.
     * @param id Golf course ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function getGolfCourse(id, params) {
        return request('GET', `${resource}/courses/${id}`, params);
    }

    /**
     * PUT /golf/courses/:id request.
     * @param id Sport center ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function updateGolfCourse(id, params) {
        return request('PUT', `${resource}/courses/${id}`, params);
    }

    /**
     * DELETE /golf/courses/:id request.
     * @param id Golf course ID.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function removeGolfCourse(id) {
        return request('DELETE', `${resource}/courses/${id}`);
    }

    /**
     * PUT /golf/courses/holes/:id request.
     * @param id Golf course hole ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function updateGolfCourseHole(id, params) {
        return request('PUT', `${resource}/courses/holes/${id}`, params);
    }

    /**
     * GET /golf/courses/:id/frees request.
     * @param id Golf course ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function getGolfCourseFrees(id, params) {
        return request('GET', `${resource}/courses/${id}/frees`, params);
    }

    /**
     * POST /golf/courses/:id/frees request.
     * @param id Golf course ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function createGolfCoursesFrees(id, params) {
        return request('POST', `${resource}/courses/${id}/frees`, params);
    }

    /**
     * PUT /golf/courses/frees/:id request.
     * @param id Golf course schedule ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function updateGolfCoursesFrees(id, params) {
        return request('PUT', `${resource}/courses/frees/${id}`, params);
    }

    /**
     * GET /golf/courses/frees/:id request.
     * @param id Golf course schedule ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function getGolfCourseFree(id, params) {
        return request('GET', `${resource}/courses/frees/${id}`, params);
    }

    /**
     * DELETE /golf/courses/frees/:id request.
     * @param id Golf course schedule ID.
     * @returns {Promise<never>|Promise<unknown>}
     */
    function removeGolfCoursesFrees(id) {
        return request('DELETE', `${resource}/courses/frees/${id}`);
    }

    /**
     * POST /golf/courses/frees/:id/rates request.
     * @param id Golf course schedule ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function createGolfCoursesFreesRates(id, params) {
        return request('POST', `${resource}/courses/frees/${id}/rates`, params);
    }

    /**
     * PUT /golf/courses/rates/:id request.
     * @param id Golf course rate ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function updateGolfCoursesRates(id, params) {
        return request('PUT', `${resource}/courses/rates/${id}`, params);
    }

    /**
     * DELETE /golf/courses/frees/rates/:id request.
     * @param id Golf course rate ID.
     * @returns {Promise<never>|Promise<unknown>}
     */
    function removeGolfCoursesRates(id) {
        return request('DELETE', `${resource}/courses/rates/${id}`);
    }

    return {
        ...services,
        getGolfCourse,
        updateGolfCourse,
        removeGolfCourse,
        updateGolfCourseHole,
        getGolfCourseFrees,
        createGolfCoursesFrees,
        updateGolfCoursesFrees,
        getGolfCourseFree,
        removeGolfCoursesFrees,
        createGolfCoursesFreesRates,
        updateGolfCoursesRates,
        removeGolfCoursesRates,
    };
}
