import React, { useState, useContext } from 'react';
import { Box, Chip, Tooltip, Grid, Link } from '@material-ui/core';
import moment from 'moment';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { PaymentOutlined, Replay } from '@material-ui/icons';
import { useQueryClient } from 'react-query';
import {
    AppContext,
    SttSelectableItems,
    SttFeeRow,
    SttChipPForm,
} from '../../../sporttia/all';
import {
    fullName,
    updateElementInArray,
    formatPriceByLocale,
    isDefined,
} from '../../../lib/utils';
import PaymentModal from '../../../components/paymentModal/PaymentModal';
import StudentDialog from '../../../components/dialogs/StudentDialog';
import { useInteractionsFiles, useQueryFilters } from '../../../lib/hooks';
import SttMshipDialog from '../../../sporttia/dialogs/SttMshipDialog';
import { SPORTCENTER_ACTIVITIES_STUDENTS } from '../../../lib/queryKeys';
import useActivityRatesService from '../../../services/ActivityRatesService';
import SttCachedTable from '../../../sporttia/SttCachedTable';
import { SttTopFilteringControls } from '../../../sporttia/SttTopFilteringControls';
import DiscountTooltip from '../../../components/discounts/DiscountTooltip';
import DateBuilder from '../../../lib/DateBuilder';

const useStyles = makeStyles({
    infoButton: {
        '&:hover': {
            cursor: 'pointer',
            color: 'rgba(33, 150, 243, 0.5)',
        },
    },
});

export default function ActivityStudents({
    item,
    onSave,
    onPayment,
    onGenerateFee,
}) {
    const cxt = useContext(AppContext);
    const { downloadFile } = useInteractionsFiles();
    const classes = useStyles();
    const activityRateService = useActivityRatesService();
    const [idPayModal, setIdPayModal] = useState(null);
    const [viewStudentId, setViewStudentId] = useState(null);
    const [mode, setMode] = useState('STUDENTS');
    const [selectedMshipId, setSelectedMshipId] = useState(null);
    const queryClient = useQueryClient();
    const [filters, setFilters] = useQueryFilters(
        {},
        SPORTCENTER_ACTIVITIES_STUDENTS,
    );

    const activeFilter = 'ACTIVE_AND_FUTURE';
    const idRate = item?.rates?.[0]?.id ?? null;

    const queryKey = [SPORTCENTER_ACTIVITIES_STUDENTS, filters];

    function generateEnrollment(studentId) {
        cxt.api('POST', `/students/${studentId}/enrollment/generate`, {
            success: () => {
                cxt.showMessage('S', cxt.t('GeneratedEnrollment'));
                queryClient.invalidateQueries(SPORTCENTER_ACTIVITIES_STUDENTS);
            },
        });
    }

    function updatePayment(payment) {
        queryClient.setQueriesData({ queryKey }, (old) => {
            if (!old) {
                return undefined;
            }

            return {
                ...old,
                rows: old.rows.map((row) => {
                    // It is an inscription payment
                    if (row.enrollment?.id === payment.id) {
                        return {
                            ...row,
                            enrollment: {
                                ...row.enrollment,
                                ...payment,
                            },
                        };
                    }

                    // It is a fee payment
                    // Since we are iterating through all existing fees, we need to find the user fee owner
                    if (row.fees && payment.user.id === row.user.id) {
                        return {
                            ...row,
                            fees: updateElementInArray(row.fees, payment),
                        };
                    }

                    // When a fee is paid for a child, the payment is made by the parent.
                    // Therefore, we search among the children of the payer and add the payment to the child's fees.
                    const foundChildrenPayment = payment.children?.rows?.find(
                        (children) => children.user.id === row.user.id,
                    );
                    if (row.fees && foundChildrenPayment) {
                        return {
                            ...row,
                            fees: row.fees.map((fee) =>
                                fee.id === foundChildrenPayment.id
                                    ? payment
                                    : fee,
                            ),
                        };
                    }

                    return row;
                }),
            };
        });
    }

    function updateMship(mship) {
        queryClient.setQueriesData({ queryKey }, (old) => {
            if (!old) {
                return undefined;
            }

            return {
                ...old,
                rows: old.rows.map((row) => {
                    if (row.user?.mship?.id === mship.id) {
                        return {
                            ...row,
                            user: {
                                ...row.user,
                                mship: {
                                    ...row.user.mship,
                                    ...mship,
                                },
                            },
                        };
                    }

                    return row;
                }),
            };
        });
    }

    function onFeeGenerated(fee, index, student) {
        queryClient.setQueriesData({ queryKey }, (old) => {
            if (!old) {
                return undefined;
            }

            return {
                ...old,
                rows: old.rows.map((oldRow) => {
                    if (oldRow.id === student.id) {
                        return {
                            ...oldRow,
                            // Update by index, since non-generated fees don't have an id
                            fees: oldRow.fees.map(
                                (oldRowFee, oldRowFeeIndex) =>
                                    oldRowFeeIndex === index ? fee : oldRowFee,
                            ),
                        };
                    }

                    return oldRow;
                }),
            };
        });
    }

    if (!item) {
        return null;
    }

    let tableColumns;

    if (mode === 'STUDENTS') {
        tableColumns = [
            {
                title: cxt.t('Name'),
                align: 'left',
                field: 'name',
                value: (row) => (
                    <Box
                        style={{
                            flexGrow: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Link onClick={() => setViewStudentId(row.id)}>
                            {fullName(row.user)}
                        </Link>
                        {row?.status === 'PAYING' && (
                            <Tooltip title={cxt.t('PayingWithCreditCard')}>
                                <PaymentOutlined
                                    style={{ marginLeft: 10, fontSize: 13 }}
                                />
                            </Tooltip>
                        )}
                    </Box>
                ),
            },
            {
                title: cxt.t('Created'),
                field: 'created',
                value: (row) =>
                    new DateBuilder(
                        row?.created,
                        cxt?.sc?.timezone?.name,
                        cxt?.lang?.key,
                    ).dmy(),
            },
            {
                title: cxt.t('Form'),
                value: (row) =>
                    row.form?.map(
                        (rowFormItem) =>
                            rowFormItem.answer?.value && (
                                <Tooltip
                                    arrow
                                    key={rowFormItem.id}
                                    placement="top"
                                    title={rowFormItem.name || ''}
                                    style={{
                                        marginLeft: 4,
                                        marginRight: 4,
                                        marginTop: 2,
                                        marginBottom: 2,
                                    }}
                                >
                                    <Chip
                                        size="small"
                                        label={
                                            rowFormItem.type === 'DATE'
                                                ? moment(
                                                      rowFormItem.answer.value,
                                                  ).format('DD-MM-YYYY')
                                                : rowFormItem.answer.value
                                        }
                                    />
                                </Tooltip>
                            ),
                    ),
            },
            {
                width: 165,
                title: cxt.t('Enrollment'),
                value: (row) => {
                    let value;
                    if (row.enrollment) {
                        if (row.enrollment.refund) {
                            value = (
                                <Box
                                    px={1}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Link
                                        onClick={() =>
                                            setIdPayModal(row.enrollment.id)
                                        }
                                    >
                                        {formatPriceByLocale(
                                            row.enrollment.price,
                                            cxt.sc?.city?.country?.currency,
                                        )}
                                    </Link>
                                    <Box mx={1}>
                                        <SttChipPForm
                                            pf={row.enrollment.paymentForm}
                                        />
                                    </Box>
                                    <Replay fontSize="small" />
                                </Box>
                            );
                        } else {
                            value = (
                                <Box
                                    px={1}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Link
                                        onClick={() =>
                                            setIdPayModal(row.enrollment.id)
                                        }
                                    >
                                        {formatPriceByLocale(
                                            row.enrollment.price,
                                            cxt.sc?.city?.country?.currency,
                                        )}
                                    </Link>
                                    <Box mx={1}>
                                        <SttChipPForm
                                            pf={row.enrollment.paymentForm}
                                        />
                                    </Box>
                                    <Box width={20} height={20} />
                                </Box>
                            );
                        }
                    } else if (row.rate.enrollment > 0) {
                        value = (
                            <Box
                                px={1}
                                display="flex"
                                justifyContent="space-around"
                            >
                                <Link
                                    onClick={() => generateEnrollment(row.id)}
                                >
                                    {cxt.t('Generate')}
                                </Link>
                            </Box>
                        );
                    }

                    return value;
                },
            },
            {
                title: '',
                value: (row) =>
                    row.discounts && (
                        <DiscountTooltip discounts={row.discounts} />
                    ),
            },
            {
                title: cxt.t('Period'),
                type: 'period',
                value: (row) => ({ ini: row.ini, end: row.end }),
            },
            {
                title: cxt.t('Fees'),
                value: (row) => {
                    if (row.fees) {
                        return <SttChipPForm pf={row.feePaymentForm || ''} />;
                    }
                    return null;
                },
            },
            {
                title: '',
                value: (row) => (
                    <PersonOutlineOutlinedIcon
                        fontSize="small"
                        className={`${classes.infoButton}`}
                        onClick={() => {
                            setSelectedMshipId(row?.user?.mship?.id);
                        }}
                    />
                ),
            },
        ];
    } else if (mode === 'FEES') {
        tableColumns = [
            {
                value: (row) => (
                    <div>
                        <Box textAlign="left" mb={1}>
                            <Link onClick={() => setViewStudentId(row.id)}>
                                {row.user ? fullName(row.user) : row.name}
                            </Link>
                        </Box>
                        <SttFeeRow
                            fees={row.fees}
                            showUntil={moment().format('YYYY-12-31:23:59:59')}
                            onGenerated={(fee, index) => {
                                onGenerateFee();
                                onFeeGenerated(fee, index, row);
                            }}
                            onFeeClicked={(id) => setIdPayModal(id)}
                        />
                    </div>
                ),
            },
        ];
    }

    function download(format) {
        const formatedFilters = { ...filters };
        const type = format === 'pdf' ? 'application/pdf' : 'text/csv';

        if (!isDefined(formatedFilters.name)) {
            formatedFilters.name = '';
        }
        const { rate: rateId } = formatedFilters;
        const inTime = !!(
            formatedFilters?.onlyActive || formatedFilters?.onTime
        );

        delete formatedFilters.rate;
        // Migración a formato inTime,inPast,inFuture https://wiki.sporttia.com/index.php/Gesti%C3%B3n_de_fechas,_tiempos_y_zonas_horarias
        delete formatedFilters.onlyActive;
        delete formatedFilters.onTime;

        downloadFile(
            `/activities/rates/${rateId}/students.${format}`,
            type,
            { inTime, ...formatedFilters },
            `${item.name} - ${cxt.t('Students')}.${format}`,
        );
    }

    return (
        <>
            <SttTopFilteringControls
                mb={2}
                fields={[
                    {
                        name: 'rate',
                        type: 'select',
                        mandatory: true,
                        caption: cxt.t('Rate'),
                        value: idRate,
                        options: item.rates?.map((rate) => ({
                            caption: rate.name,
                            value: rate.id,
                        })),
                    },
                    {
                        name: 'activeFilter',
                        type: 'select',
                        mandatory: true,
                        caption: cxt.t('Active'),
                        value: activeFilter,
                        options: [
                            {
                                caption: cxt.t(
                                    'page.sc.classes.onlyActiveStudents',
                                ),
                                value: 'ACTIVE_AND_FUTURE',
                            },
                            {
                                caption: cxt.t(
                                    'page.sc.classes.activeAndPastStudents',
                                ),
                                value: 'PAST',
                            },
                        ],
                    },
                    {
                        name: 'name',
                        type: 'search',
                        caption: cxt.t('Name'),
                        value: filters.name,
                    },
                ]}
                menu={[
                    {
                        caption: `${cxt.t('Download')} PDF`,
                        onClick: () => download('pdf'),
                    },
                    {
                        caption: `${cxt.t('Download')} CSV`,
                        onClick: () => download('csv'),
                    },
                ]}
                mainAction={
                    mode === 'STUDENTS'
                        ? {
                              type: 'create',
                              onClick: () => setViewStudentId(0),
                          }
                        : null
                }
                onFilter={({ activeFilter: currentFilter, ...restFilters }) => {
                    let inTime = null;

                    if (idRate) {
                        if (currentFilter === 'ACTIVE_AND_FUTURE') {
                            inTime = true;
                        } else if (currentFilter === 'PAST') {
                            inTime = false;
                        }
                    }

                    setFilters({ ...restFilters, inTime });
                }}
                trashAction
            />
            {/* --- Students or fees--- */}
            <Box pl={3}>
                <Grid container spacing={3}>
                    <SttSelectableItems
                        grid
                        xs={12}
                        md={4}
                        lg={3}
                        xl={2}
                        name="mode"
                        items={[
                            {
                                label: cxt.t('Students'),
                                value: 'STUDENTS',
                            },
                            {
                                label: cxt.t('Fees'),
                                value: 'FEES',
                            },
                        ]}
                        exclusive
                        selected={mode}
                        onChange={({ value }) => setMode(value)}
                    />
                </Grid>
            </Box>
            <SttCachedTable
                queryKey={SPORTCENTER_ACTIVITIES_STUDENTS}
                queryFn={({ rate: id, ...params }) =>
                    activityRateService.getActivityRateStudents(id, params)
                }
                queryParams={{ ...filters }}
                prefetching={false}
                columns={tableColumns}
            />
            {idPayModal && (
                <PaymentModal
                    idPayment={idPayModal}
                    onClose={() => setIdPayModal(null)}
                    onPay={(payment) => {
                        updatePayment(payment);
                        onPayment();
                    }}
                    onDeleted={() => {
                        queryClient.invalidateQueries(
                            SPORTCENTER_ACTIVITIES_STUDENTS,
                        );
                        onPayment();
                    }}
                    onRecovered={() => {
                        queryClient.invalidateQueries(
                            SPORTCENTER_ACTIVITIES_STUDENTS,
                        );
                        onPayment();
                    }}
                    onRefund={() => {
                        queryClient.invalidateQueries(
                            SPORTCENTER_ACTIVITIES_STUDENTS,
                        );
                        onPayment();
                    }}
                    onSave={() => {
                        queryClient.invalidateQueries(
                            SPORTCENTER_ACTIVITIES_STUDENTS,
                        );
                        onPayment();
                    }}
                />
            )}
            {viewStudentId !== null && (
                <StudentDialog
                    open
                    idStudent={viewStudentId}
                    defaultIdRate={filters.rate ?? idRate}
                    activity={item}
                    onClose={() => {
                        setViewStudentId(null);
                    }}
                    onSave={() => {
                        setViewStudentId(null);
                        queryClient.invalidateQueries(
                            SPORTCENTER_ACTIVITIES_STUDENTS,
                        );
                        onSave();
                    }}
                    trash={filters.trash}
                />
            )}
            <SttMshipDialog
                open={!!selectedMshipId}
                mshipId={selectedMshipId}
                onClose={() => {
                    setSelectedMshipId(null);
                }}
                onSave={updateMship}
            />
        </>
    );
}
