import React, { useState, useContext, useEffect } from 'react';
import { Paper, Box, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import useReportsService from '../../services/ReportsService';
import { AppContext, SttFullDialog } from '../../sporttia/all';
import { PAGE_REPORT, PAGE_REPORT_ROWS } from '../../lib/queryKeys';
import SttCachedTable from '../../sporttia/SttCachedTable';
import { SttTopFilteringControls } from '../../sporttia/SttTopFilteringControls';
import { useInteractionsFiles, useTranslations } from '../../lib/hooks';
import { getPath } from '../Pages';
import constants from '../../config/constants';
import { ColumnPopup } from '../../sporttia/dialogs/ColumnPopup';

/**
 * Converts the columns returned by the API to columns that the SttTopFilteringControls component can understand
 * @param columns Columns returned by the api.
 */
const getColumns = (columns, align = 'C') => {
    if (!Array.isArray(columns)) {
        return []; // Devuelvo un array vacio para evitar errores
    }
    let columnsPositions = [];
    columns.forEach((column) => {
        if (column?.field) {
            columnsPositions[column?.field] = column?.position;
        }
    });
    // Extraemos las entradas del array con el campo de la columna y su posicion,
    // ordenamos en base al valor numerico (posición, elemento 1 del vector)
    // por último reducimos el array resultante ordenado de componentes {field, position}
    // y vamos creando un objeto con todos los fields ordenados
    columnsPositions = Object.entries(columnsPositions)
        .sort((columnLeft, columnRight) => columnLeft[1] - columnRight[1])
        .reduce((previousValue, currentValue) => {
            // eslint-disable-next-line no-param-reassign
            previousValue[currentValue[0]] = null;
            return previousValue;
        }, {});
    const formattedColumns = [];
    columns.forEach((col) => {
        // Class
        let className;
        switch (align) {
            case 'L':
                className = 'text-left';
                break;
            case 'R':
                className = 'text-right';
                break;
            default:
                className = 'text-center';
        }

        // Type
        const type = '';

        formattedColumns[col?.field || '*'] = {
            field: col.field,
            title: col.name,
            width: col.width,
            className,
            type,
        };
    });
    // Asignamos las columnas formateadas siguiendo el orden definido en columnsPositions
    // para las columnas
    return Object.values(Object.assign(columnsPositions, formattedColumns));
};

/**
 * Converts the filters returned by the API to filters that the SttTopFilteringControls component can understand
 * @param filters Filters returned by the api.
 * @returns {*} Returns an array with the filters used by SttTopFilteringControls
 */
const getFilters = (filters) =>
    filters.map((ft) => {
        const { field, type, value, name } = ft;

        // If it's "date" type, comes with dd-mm-yyyy,
        // we've got to conver to ISO
        if (value && type && type.toLowerCase() === 'date') {
            moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD');
        }

        return {
            name: field,
            caption: name,
            type,
            value: '',
        };
    });

/**
 * Report view.
 * @param id Report ID.
 * @returns {JSX.Element}
 */
export default function Report({ id = null, navigateBack = null }) {
    const cxt = useContext(AppContext);
    const { downloadFile } = useInteractionsFiles();
    const params = useParams();
    const { translate } = useTranslations();
    const reportsService = useReportsService();
    const [reportId, setReportId] = useState(id);
    const [columns, setColumns] = useState();
    const [filtersTopControls, setFiltersTopControls] = useState();
    const [filters, setFilters] = useState();
    const [summatory, setSummatory] = useState();
    const [pageAndRows, setPageAndRows] = useState();
    const [isColumnPopupOpen, setColumnPopupOpen] = useState(false);
    const [selectedAlign, setSelectedAlign] = useState('C');
    const history = useHistory();
    const location = useLocation();
    const reportQuery = useQuery(
        [PAGE_REPORT, { id: reportId }],
        () => reportsService.get(reportId),
        {
            enabled: !!reportId && reportId !== 'create',
            onSuccess: (data) => {
                setColumns(getColumns(data?.report?.columns, selectedAlign));
                if (data?.report?.filters) {
                    setFiltersTopControls(getFilters(data?.report?.filters));
                }
            },
        },
    );

    let reportGroups = location.state?.reportGroups ?? null;
    if (!reportGroups)
        reportGroups = [
            {
                caption: 'OccupationOfFacilities',
                path: 'report',
                params: { id: 8 },
            },
            {
                caption: 'Seating',
                path: 'report',
                params: { id: 53 },
            },
            {
                caption: 'tpvRecordsBySc',
                path: 'report',
                params: { id: 27 },
            },
            {
                caption: 'AdminSports',
                path: 'report',
                params: { id: 43 },
            },
            {
                caption: 'increasesInEnrollmentInActivitiesPerYear',
                path: 'report',
                params: { id: 33 },
            },
            {
                caption: 'increasesInEnrollmentInActivitiesPerYear',
                path: 'report',
                params: { id: 32 },
            },
            {
                caption: 'playtomicCenters',
                path: 'report',
                params: { id: 44 },
            },
            {
                caption: 'playtomicCenters',
                path: 'report',
                params: { id: 39 },
            },
            {
                caption: 'events',
                path: 'report',
                params: { id: 14 },
            },
            {
                caption: 'rentals',
                path: 'report',
                params: { id: 28 },
            },
            {
                caption: 'rentalsPerDay',
                path: 'report',
                params: { id: 37 },
            },
            {
                caption: 'rentalsRatioByCenter',
                path: 'report',
                params: { id: 30 },
            },
            {
                caption: 'rentalsRatioPerYear',
                path: 'report',
                params: { id: 9 },
            },
            {
                caption: 'emails',
                path: 'report',
                params: { id: 4 },
            },
            {
                caption: 'notificationsTypes',
                path: 'report',
                params: { id: 17 },
            },
            {
                caption: 'pushNotifications',
                path: 'report',
                params: { id: 31 },
            },
            {
                caption: 'smss',
                path: 'report',
                params: { id: 16 },
            },
        ];

    if (
        cxt.privileges?.includes(15) ||
        cxt.user?.role === constants.roles.admin
    ) {
        reportGroups = reportGroups.concat([
            {
                caption: 'sc.reportClass.studentList',
                path: 'report',
                params: { id: 11 },
            },
            {
                caption: 'activitiesStudentsTotals',
                path: 'report',
                params: { id: 1 },
            },
            {
                caption: 'ReportActivitiesByMonth',
                path: 'report',
                params: { id: 19 },
            },
            {
                caption: 'ReportMissedClasses',
                path: 'report',
                params: { id: 22 },
            },
            {
                caption: 'ReportStudentFees',
                path: 'report',
                params: { id: 20 },
            },
        ]);
    }
    /**
     * Método para descargar los ficheros de los informes.
     * TODO migrar a react-query
     * @param format [pdf|csv] string format.
     */
    function download(format) {
        const type = format === 'csv' ? 'text/csv' : 'application/pdf';

        // PDF
        if (format === 'pdf') {
            downloadFile(
                `/reports/${reportId}.pdf?page=${pageAndRows.page}&rows=${pageAndRows.rows}&filter=${filters.filter}`,
                type,
                {},
                `informe.${format}`,
            );
        }

        // CSV
        if (format === 'csv') {
            downloadFile(
                `/reports/${reportId}.csv?page=${pageAndRows.page}&rows=${pageAndRows.rows}&filter=${filters.filter}`,
                type,
                {},
                `informe.${format}`,
            );
        }
    }

    /**
     * Method to format the filter parameter with the format: property1::value1,property2::value2
     * @param toFormat Filter object like this: {name: '', city: 12}
     * @returns {[]} Return string like this: property1::value1,property2::value2
     */
    const formatFilterParam = (toFormat) => {
        const finalFilter = [];
        Object.keys(toFormat).forEach((key) => {
            finalFilter.push(`${key}::${toFormat[key]}`);
        });

        finalFilter.join(',');

        return finalFilter;
    };

    /**
     * When "id" or "params" change we set the "reportId" variable.
     */
    useEffect(() => {
        if (id !== null) setReportId(id);
        else if (params?.id) setReportId(Number(params?.id));
    }, [id, params]);

    const handleOpenColumnPopup = () => {
        setColumnPopupOpen(true);
    };

    const handleCloseColumnPopup = () => {
        setColumnPopupOpen(false);
    };

    const handleSaveColumnAlign = (align) => {
        setSelectedAlign(align);
        setColumns((prevColumns) => getColumns(prevColumns, align));
        handleCloseColumnPopup();
    };

    return reportQuery?.data ? (
        <>
            <SttFullDialog
                open={id || reportId}
                onClose={() => {
                    if (navigateBack) {
                        history.push(getPath(navigateBack));
                    } else if (cxt?.user?.role === constants.roles.admin) {
                        history.push(getPath('adminReports'));
                    } else {
                        history.push(getPath('scReports'));
                    }
                }}
                title={
                    id || reportId
                        ? translate(
                              reportGroups.find(
                                  (reportGroup) =>
                                      reportGroup.params.id === id ||
                                      reportGroup.params.id === reportId,
                              )?.caption,
                          )
                        : 'Report'
                }
            >
                <Paper>
                    <Box>
                        <SttTopFilteringControls
                            p={2}
                            extraFields={filtersTopControls}
                            menu={[
                                {
                                    caption: `${cxt.t('Download')} PDF`,
                                    onClick: () => download('pdf'),
                                },
                                {
                                    caption: `${cxt.t('Download')} CSV`,
                                    onClick: () => download('csv'),
                                },
                                {
                                    caption:
                                        'Configurar Alineación de Columnas',
                                    onClick: handleOpenColumnPopup,
                                },
                            ]}
                            onFilter={(filter) =>
                                setFilters({
                                    filter: formatFilterParam(filter),
                                })
                            }
                        />
                        <SttCachedTable
                            queryKey={PAGE_REPORT_ROWS}
                            queryFn={async (queryParams) => {
                                const reportRows =
                                    await reportsService.getReportsRows(
                                        reportId,
                                        queryParams,
                                    );
                                if (reportRows?.rows && reportRows?.count)
                                    setPageAndRows({
                                        page: 1,
                                        rows:
                                            reportRows.count *
                                            reportRows.rows.length,
                                    });
                                return reportRows;
                            }}
                            queryParams={{ ...filters }}
                            queryOptions={{
                                enabled:
                                    !!reportId &&
                                    reportId !== 'create' &&
                                    reportQuery.status !== 'loading',
                                onSuccess: (data) =>
                                    setSummatory(data?.summatory),
                            }}
                            prefetching={false}
                            columns={columns}
                        />
                    </Box>
                </Paper>
                <Box mt={2}>
                    {summatory && (
                        <Grid container spacing={2} justifyContent="flex-end">
                            <Grid item>
                                <Paper>
                                    <Box p={2}>
                                        <Typography
                                            align="center"
                                            variant="overline"
                                            display="block"
                                        >
                                            {cxt.t('Price')}
                                        </Typography>
                                        <Typography align="center" variant="h5">
                                            {summatory.price
                                                ? summatory.price
                                                : 0.0}{' '}
                                            {cxt.t('Coin')}
                                        </Typography>
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </SttFullDialog>
            <ColumnPopup
                open={isColumnPopupOpen}
                onClose={handleCloseColumnPopup}
                onSave={handleSaveColumnAlign}
            />
        </>
    ) : null;
}
