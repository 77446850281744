import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useState } from 'react';

/**
 * Form text
 */
export function SttFormPassword({
    grid = false,
    xs = 12,
    sm = 6,
    md = 3,
    caption,
    onChange,
    defVal,
    name,
    disabled,
    onEnterKey,
    ...rest
}) {
    const [showPassword, setShowPassword] = useState(false);
    function keyPressed(event) {
        if (
            onEnterKey &&
            onEnterKey.constructor === Function &&
            event.which === 13
        ) {
            onEnterKey();
        }
    }

    const content = (
        <TextField
            fullWidth
            variant="outlined"
            label={caption}
            size="small"
            type={showPassword ? 'text' : 'password'}
            value={defVal || ''}
            onChange={(e) => onChange({ name, value: e.target.value })}
            onKeyPress={keyPressed}
            disabled={disabled}
            InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => {
                                setShowPassword(!showPassword);
                            }}
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );

    return grid ? (
        <Grid item xs={xs} sm={sm} md={md} {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
