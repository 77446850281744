import React, { useState, useEffect, useContext } from 'react';
import { Chip, Avatar } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { AppContext, SttChipUnlocker, SttUnlockerDialog } from './all';

export function SttUnlockerChips({
    unlockers = [],
    idUser,
    idSC,
    showCreatingButton,
}) {
    const cxt = useContext(AppContext);
    const [unlockerDialogOpened, setUnlockerDialogOpened] = useState(false);
    const [showingUnlockerId, setShowingUnlockerId] = useState();

    /**
     * Created unlocker
     */
    const onCreated = (uk) => {
        unlockers.push(uk);
    };

    /**
     * Open Unlocker
     */
    const openUnlocker = (unlocker) => {
        setUnlockerDialogOpened(true);
        setShowingUnlockerId(unlocker.id);
    };

    /**
     * On Deleted
     */
    const onDeleted = (unlocker) => {
        // Find the index to delete
        const idxToDelete = unlockers.findIndex((e) => e.id === unlocker.id);

        // Delete
        if (idxToDelete >= 0) {
            unlockers.splice(idxToDelete, 1);
        }
    };

    /**
     * Open to create
     */
    const openToCreate = () => {
        setShowingUnlockerId(null);
        setUnlockerDialogOpened(true);
    };

    // -----| Render |-----
    return (
        <>
            {unlockers &&
                unlockers.map((unlocker, idx) => (
                    <SttChipUnlocker
                        key={idx}
                        unlocker={unlocker}
                        onClick={(ev) => openUnlocker(unlocker)}
                    />
                ))}

            {showCreatingButton && (
                <Chip
                    size="small"
                    style={{ marginRight: 3, marginBottom: 3 }}
                    label={cxt.t('Add')}
                    avatar={
                        <Avatar style={{ backgroundColor: 'white' }}>
                            <AddIcon />
                        </Avatar>
                    }
                    variant="outlined"
                    onClick={(e) => openToCreate()}
                />
            )}

            <SttUnlockerDialog
                open={unlockerDialogOpened}
                idUnlocker={showingUnlockerId}
                idUser={idUser}
                idSC={idSC}
                onClose={(ev) => setUnlockerDialogOpened(false)}
                onSave={(uk) => onCreated(uk)}
                onDelete={onDeleted}
            />
        </>
    );
}
