import {
    MutationFunction,
    useMutation,
    UseMutationOptions,
    useQuery,
    UseQueryOptions,
} from 'react-query';
import { useContext } from 'react';
import { useAPI } from '../lib/hooks';
import { AppContext } from '../sporttia/AppContext';
import {
    CreateActivityRateStudent200,
    CreateActivityRateStudentBody,
    CreateDuplicationsActivities200,
    GetActivityRateInscriptions200,
    GetActivityRateInscriptionsParams,
    GetActivityStudentAssistences200,
    GetActivityStudentAssistencesParams,
    CreateDuplicationActivitiesBody,
    GetActivityClasses200,
} from '../types/api/paths/Student';
import Error from '../types/models/Error';
import { DefaultListParams } from '../types/api/utils';

const resource = '/activities';

export const getActivityRateInscriptionsQueryKey = (
    rateId: number,
    params?: GetActivityRateInscriptionsParams,
) => [`${resource}/${rateId}/inscriptions`, ...(params ? [params] : [])];

export const getActivityStudentsAssistencesQueryKey = (
    activityId: number,
    params?: GetActivityStudentAssistencesParams,
) => [
    `${resource}/${activityId}/students/assistences`,
    ...(params ? [params] : []),
];

export const getActivityClassesQueryKey = (
    activityId: number,
    params?: DefaultListParams,
) => [
    `${resource}/${activityId}/students/assistences`,
    ...(params ? [params] : []),
];
/**
 * Hook to access 'activities' resources.
 */
export default function useActivitiesService() {
    const { migrationApis } = useContext(AppContext)!;
    const [request] = useAPI(migrationApis);

    function useGetActivityRateInscriptions<
        TData = GetActivityRateInscriptions200,
    >(
        rateId: number,
        params?: GetActivityRateInscriptionsParams,
        queryOptions?: UseQueryOptions<
            GetActivityRateInscriptions200,
            unknown,
            TData
        >,
    ) {
        const queryKey =
            queryOptions?.queryKey ??
            getActivityRateInscriptionsQueryKey(rateId, params);

        const queryFn = () =>
            request<GetActivityRateInscriptions200>(
                'GET',
                `${resource}/rates/${rateId}/inscriptions`,
                params,
            );

        const query = useQuery(queryKey, queryFn, queryOptions);

        return {
            ...query,
            queryKey,
        };
    }

    /**
     * POST /activities/rates/:idRate/students request.
     */
    function useCreateActivityRateStudent(
        mutationOptions?: UseMutationOptions<
            CreateActivityRateStudent200,
            unknown,
            { idRate: number; params: CreateActivityRateStudentBody }
        >,
    ) {
        const mutationFn: MutationFunction<
            CreateActivityRateStudent200,
            { idRate: number; params: CreateActivityRateStudentBody }
        > = ({ idRate, params }) =>
            request('POST', `/activities/rates/${idRate}/students`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    function useGetActivityStudentAssistences<
        TData = GetActivityStudentAssistences200,
    >(
        activityId: number,
        params?: GetActivityStudentAssistencesParams,
        queryOptions?: UseQueryOptions<
            GetActivityStudentAssistences200,
            unknown,
            TData
        >,
    ) {
        const queryKey =
            queryOptions?.queryKey ??
            getActivityStudentsAssistencesQueryKey(activityId, params);

        const queryFn = () =>
            request<GetActivityStudentAssistences200>(
                'GET',
                `${resource}/${activityId}/students/assistences`,
                params,
            );

        const query = useQuery(queryKey, queryFn, queryOptions);

        return {
            ...query,
            queryKey,
        };
    }

    function useGetActivityClasses<TData = GetActivityClasses200>(
        activityId: number,
        params?: DefaultListParams,
        queryOptions?: UseQueryOptions<GetActivityClasses200, unknown, TData>,
    ) {
        const queryKey =
            queryOptions?.queryKey ??
            getActivityClassesQueryKey(activityId, params);

        const queryFn = () =>
            request<GetActivityClasses200>(
                'GET',
                `${resource}/${activityId}/classes`,
                params,
            );

        const query = useQuery(queryKey, queryFn, queryOptions);

        return {
            ...query,
            queryKey,
        };
    }

    /**
     * POST /activities/duplications request.
     */
    function useCreateDuplicationsActivities(
        mutationOptions?: UseMutationOptions<
            CreateDuplicationsActivities200,
            Error,
            { params: CreateDuplicationActivitiesBody }
        >,
    ) {
        const mutationFn: MutationFunction<
            CreateDuplicationsActivities200,
            { params: CreateDuplicationActivitiesBody }
        > = ({ params }) => request('POST', `/activities/duplications`, params);

        return useMutation(mutationFn, mutationOptions);
    }
    return {
        useGetActivityRateInscriptions,
        useCreateActivityRateStudent,
        useGetActivityStudentAssistences,
        useCreateDuplicationsActivities,
        useGetActivityClasses,
    };
}
