import React from 'react';
import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
    SttButton,
    SttButtonAccept,
    SttButtonClose,
    SttButtonSave,
    SttButtonCancel,
    SttMenu,
    SttButtonUnsubscribe,
    SttButtonDelete,
    SttButtonRecover,
    SttButtonDownload,
} from '../all';

/**
 *	Dialog
 *	======
 *
 *	Props
 *
 * 	* string title
 * 	* string subtitle
 * 	* boolean open
 *	* function onClose
 *	* Component content
 *	* array buttons: botones con los componentes:
 *
 * 		- string caption
 * 		- Icon icon
 * 		- boolean show
 *		- string type: accept, close, save
 *		- function onClick
 *		- Componente component: si se quiere añadir un componente cualquiera
 *
 * 	* array menu: arriba a la izquierda saldrían tres puntos con un menú. Más info <SttMenu />
 */
export function SttDialog({
    title,
    subtitle = undefined,
    open = false,
    content,
    onClose,
    buttons,
    menu = undefined,
    minHeight = undefined,
    maxHeight = undefined,
    ...rest
}) {
    /**
     * Render button
     */
    const renderButton = (button, idx) => {
        if (button) {
            let buttonContent;

            if (button.show !== false) {
                if (button.component) {
                    buttonContent = button.component;
                } else {
                    switch (button.type) {
                        case 'accept':
                            buttonContent = (
                                <SttButtonAccept
                                    fullWidth
                                    disabled={button.disabled}
                                    caption={button.caption}
                                    onClick={button.onClick}
                                />
                            );
                            break;
                        case 'cancel':
                            buttonContent = (
                                <SttButtonCancel
                                    fullWidth
                                    onClick={button.onClick}
                                />
                            );
                            break;
                        case 'close':
                            buttonContent = (
                                <SttButtonClose
                                    fullWidth
                                    onClick={button.onClick}
                                />
                            );
                            break;
                        case 'delete':
                            buttonContent = (
                                <SttButtonDelete
                                    fullWidth
                                    caption={button.caption}
                                    onClick={button.onClick}
                                    confirmation={!!button.confirmation}
                                />
                            );
                            break;
                        case 'recover':
                            buttonContent = (
                                <SttButtonRecover
                                    fullWidth
                                    onClick={button.onClick}
                                />
                            );
                            break;
                        case 'save':
                            buttonContent = (
                                <SttButtonSave
                                    fullWidth
                                    onClick={button.onClick}
                                    loading={button.loading}
                                    disabled={button.disabled}
                                />
                            );
                            break;
                        case 'unsubscribe':
                            buttonContent = (
                                <SttButtonUnsubscribe
                                    fullWidth
                                    onClick={button.onClick}
                                />
                            );
                            break;
                        case 'download':
                            buttonContent = (
                                <SttButtonDownload
                                    fullWidth
                                    onClick={button.onClick}
                                />
                            );
                            break;
                        case 'component':
                            buttonContent = button.component;
                            break;
                        default:
                            buttonContent = (
                                <SttButton
                                    fullWidth
                                    startIcon={button.icon}
                                    caption={button.caption}
                                    onClick={button.onClick}
                                />
                            );
                            break;
                    }
                }

                return (
                    <Grid item key={idx}>
                        {buttonContent}
                    </Grid>
                );
            }
        }
    };

    return (
        <Dialog
            fullWidth
            onClose={onClose}
            open={open}
            PaperProps={{
                style: {
                    minHeight,
                    maxHeight,
                },
            }}
            {...rest}
        >
            {title && (
                <DialogTitle style={{ position: 'relative' }}>
                    <div>{title}</div>

                    {subtitle && (
                        <Box fontSize={15} style={{ color: '#999' }}>
                            {subtitle}
                        </Box>
                    )}

                    {/* TODO: mejorar esta implementación para que sea flexible */}
                    {menu && (
                        <SttMenu
                            items={menu}
                            style={{
                                position: 'absolute',
                                top: 10,
                                right: 50,
                            }}
                        />
                    )}

                    <IconButton
                        style={{
                            position: 'absolute',
                            right: 10,
                            top: 10,
                        }}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
            )}

            <DialogContent dividers>
                <Box p={1}>{content}</Box>
            </DialogContent>

            {buttons && (
                <DialogActions>
                    <Box p={1}>
                        <Grid container spacing={3}>
                            {buttons.map((button, idx) =>
                                renderButton(button, idx),
                            )}
                        </Grid>
                    </Box>
                </DialogActions>
            )}
        </Dialog>
    );
}
