import React from 'react';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Spinner, { SpinnerProps } from 'react-spinkit';

type SttFoldingCubeSpinnerProps = {
    active: boolean;
    color: SpinnerProps['color'];
    message: string;
};

export default function SttFoldingCubeSpinner({
    active = false,
    color = '#DBEA7B',
    message = '',
}: SttFoldingCubeSpinnerProps) {
    return active ? (
        <>
            <Box mb={3}>
                <Spinner name="folding-cube" color={color} />
            </Box>

            {message !== '' && (
                <Box>
                    <Typography variant="overline">{message}</Typography>
                </Box>
            )}
        </>
    ) : null;
}
