import React, { useRef, useState, useContext } from 'react';
import { Box } from '@material-ui/core';
import ReactCrop from 'react-image-crop';
import { SttButton } from '../../sporttia/buttons/SttButton';
import 'react-image-crop/dist/ReactCrop.css';
import { AppContext } from '../../sporttia/all';

const DataReturnTypes = {
    blob: 'blob',
    base64: 'base64',
};

export default function ImageCrop({
    src,
    cropWidth,
    cropHeight,
    targetWidth,
    targetHeight,
    onCrop,
    dataReturnType,
}) {
    const cxt = useContext(AppContext);

    const imageRef = useRef(null);
    const [crop, setCrop] = useState();

    function getCroppedImg(image, crop, returnType, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            targetWidth,
            targetHeight,
        );

        if (returnType == DataReturnTypes.base64) {
            return canvas.toDataURL('image/jpeg', 1.0);
        }
        if (returnType == DataReturnTypes.blob) {
            return new Promise((resolve, reject) => {
                canvas.toBlob(
                    (blob) => {
                        blob.name = fileName;
                        resolve(blob);
                    },
                    'image/jpeg',
                    1,
                );
            });
        }
        throw 'ImageCrop component requires a DataReturnType parameter';

        // base64:
        // const base64Image = canvas.toDataURL('image/jpeg');

        // blob:
        /* ; */
    }

    async function applyCrop() {
        if (onCrop && onCrop.constructor === Function) {
            const cropped = await getCroppedImg(
                imageRef.current,
                crop,
                dataReturnType,
                'cropped.jpg',
            );
            onCrop(cropped);
        }
    }

    function setup() {
        if (imageRef.current) {
            const { width, height } = imageRef.current;

            if (cropWidth > width) {
                cropWidth = width;
            }

            if (cropHeight > height) {
                cropHeight = height;
            }

            const rect = {
                aspect: cropWidth / cropHeight,
                x: (width - cropWidth) / 2,
                y: (height - cropHeight) / 2,
                width: cropWidth,
                height: cropHeight,
            };

            setCrop(rect);
        }
    }

    return (
        <Box>
            <img
                ref={imageRef}
                src={src}
                style={{
                    visibility: 'hidden',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                }}
                onLoad={setup}
            />
            {crop && (
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Box mb={3}>
                        <ReactCrop
                            src={src}
                            crop={crop}
                            onChange={(newCrop) => setCrop(newCrop)}
                        />
                    </Box>
                    <SttButton
                        caption={cxt.t('page.user.webcam.crop')}
                        variant="contained"
                        color="primary"
                        onClick={applyCrop}
                    />
                </Box>
            )}
        </Box>
    );
}
