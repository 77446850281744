import React, { useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { SttFullDialog } from '../../../../sporttia/dialogs/SttFullDialog';
import { AppContext } from '../../../../sporttia/AppContext';
import { SUPERADMIN_API_MODEL } from '../../../../lib/queryKeys';
import useApisService from '../../../../services/ApisService';
import { SttTabs } from '../../../../sporttia/SttTabs';
import AdminApiModelDetails from './AdminApiModelDetails';
import AdminApiModelProperties from './AdminApiModelProperties';

/**
 * Api model view. Contains tabs to access api model data.
 * @param id API Model ID.
 * @param apiVersion API Version.
 * @param onClose Function executed when closing the full dialog.
 * @returns {JSX.Element}
 */
export default function AdminApiModel({ id, apiVersion, onClose }) {
    const cxt = useContext(AppContext);
    const queryClient = useQueryClient();
    const apisService = useApisService();
    const [apiModelId, setApiModelId] = useState(id);
    const apiModelQuery = useQuery(
        [SUPERADMIN_API_MODEL, { id: apiModelId }],
        () => apisService.getApiModel(apiModelId),
        { enabled: !!apiModelId && apiModelId !== 'create' },
    );

    /**
     * Update api model id if id prop was changed.
     */
    useEffect(() => {
        setApiModelId(id);
    }, [id]);

    return (
        <SttFullDialog
            open={apiModelId !== null}
            onClose={onClose}
            title={
                apiModelQuery?.isLoading
                    ? ''
                    : apiModelQuery?.data?.apiModel?.id
                    ? apiModelQuery?.data?.apiModel?.name
                    : cxt.t('admin.api.model.create')
            }
        >
            <Paper>
                <Box p={3}>
                    <SttTabs
                        tabs={[
                            {
                                caption: cxt.t('Detail'),
                                component: (
                                    <AdminApiModelDetails
                                        apiModel={apiModelQuery?.data?.apiModel}
                                        apiVersion={apiVersion}
                                        status={apiModelQuery?.status}
                                        onCreate={setApiModelId}
                                        onUpdate={(data) =>
                                            queryClient.setQueryData(
                                                [
                                                    SUPERADMIN_API_MODEL,
                                                    { id: data?.apiModel?.id },
                                                ],
                                                data,
                                            )
                                        }
                                        onDelete={onClose}
                                    />
                                ),
                            },
                            {
                                caption: cxt.t('Properties'),
                                show: apiModelId !== 'create',
                                component: (
                                    <AdminApiModelProperties
                                        apiModel={apiModelQuery?.data?.apiModel}
                                    />
                                ),
                            },
                        ]}
                    />
                </Box>
            </Paper>
        </SttFullDialog>
    );
}
