import React, { useContext, useState } from 'react';
import { makeStyles, Box, Paper, Typography, Divider } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from 'react-query';
import { usePageHeader, useTranslations } from '../../../lib/hooks';
import GolfCourse from './GolfCourse';
import GolfTimetableControls from '../golfTimetable/GolfTimetableControls';
import GolfTimetableTee from '../golfTimetable/GolfTimetableTee';
import GolfTimetableCart from '../golfTimetable/GolfTimetableCart';
import { SPORTCENTER_GOLF_COURSES } from '../../../lib/queryKeys';
import { AppContext } from '../../../sporttia/AppContext';
import useScsService from '../../../services/ScsService';

/**
 * View where the sports center can see the list of golf courses and their availability.
 * @returns {JSX.Element}
 */
export default function Golf() {
    const cxt = useContext(AppContext);
    const classes = useStyles();
    usePageHeader('Golf');

    const { translate } = useTranslations();
    const queryClient = useQueryClient();
    const form = useForm();
    const [selectedCourse, setSelectedCourse] = useState({
        status: null,
        id: null,
    });
    const scsService = useScsService();
    const golfCoursesQuery = useQuery(
        [SPORTCENTER_GOLF_COURSES, { id: cxt?.sc?.id }],
        () => scsService.getScsGolfCourses(cxt?.sc?.id, null),
    );

    return (
        <>
            <Box className={classes.golfCoursesList}>
                {golfCoursesQuery?.data?.rows?.map((course) => (
                    <Paper
                        className={`${classes.golfCourse} ${
                            classes.hoverGolfCardCourse
                        } ${
                            course?.id === selectedCourse?.id
                                ? classes.activeGolfCourse
                                : classes.disableGolfCourse
                        }`}
                        onClick={() =>
                            setSelectedCourse({
                                status: `${
                                    selectedCourse &&
                                    selectedCourse.id === course.id
                                        ? 'EDIT'
                                        : 'SELECTED'
                                }`,
                                id: course?.id,
                            })
                        }
                    >
                        {course?.id !== selectedCourse?.id ? (
                            <>
                                <Typography variant="subtitle1" noWrap>
                                    {course?.name}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    noWrap
                                >{`${course?.holes?.count} Hoyos`}</Typography>
                                <Typography
                                    variant="caption"
                                    noWrap
                                >{`${translate('Duration')}: ${
                                    course?.duration
                                } min`}</Typography>
                                {/* <Typography variant="caption" noWrap>{`Salidas cada: ${course?.interval} min`}</Typography> */}
                            </>
                        ) : (
                            <Box
                                display="flex"
                                flex={1}
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                                style={{ height: '100%' }}
                            >
                                <Typography variant="subtitle1" align="center">
                                    Seleccionado {course.name}
                                </Typography>
                                <Typography variant="caption" align="center">
                                    Pulsa de nuevo para editar
                                </Typography>
                            </Box>
                        )}
                    </Paper>
                ))}
                <Paper
                    className={`${classes.golfCourse} ${classes.hoverGolfCardCourse}`}
                    style={{ width: 250, height: 120 }}
                    onClick={() =>
                        setSelectedCourse({ status: 'CREATE', id: 'create' })
                    }
                >
                    <Box
                        display="flex"
                        flex={1}
                        justifyContent="center"
                        alignItems="center"
                        style={{ height: '100%' }}
                    >
                        <Typography>Añadir nuevo campo</Typography>
                    </Box>
                </Paper>
            </Box>

            <Paper>
                <GolfTimetableControls form={form} />
                <Box mb={2}>
                    <Divider />
                </Box>
                <Box p={2}>
                    <div className={classes.timetable}>
                        <Box className={classes.timetableBox}>
                            <Box display="flex">
                                <GolfTimetableTee
                                    teeNumber={1}
                                    disableTime={false}
                                />
                                <GolfTimetableTee teeNumber={5} />
                                <GolfTimetableTee teeNumber={10} />
                                <GolfTimetableCart />
                            </Box>
                        </Box>
                    </div>
                </Box>
            </Paper>

            <GolfCourse
                open={
                    selectedCourse?.status === 'EDIT' ||
                    selectedCourse?.status === 'CREATE'
                }
                id={selectedCourse?.id}
                onClose={() => {
                    setSelectedCourse({ status: 'SELECTED', id: null });
                    queryClient.invalidateQueries(SPORTCENTER_GOLF_COURSES);
                }}
            />
        </>
    );
}

const useStyles = makeStyles(() => ({
    golfCoursesList: {
        display: 'flex',
        width: '100%',
        overflowX: 'auto',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 1,
        paddingRight: 1,
    },
    golfCourse: {
        display: 'flex',
        flexDirection: 'column',
        flex: '0 0 250px',
        // overflow: 'hidden',
        width: 250,
        height: 120,
        marginRight: 10,
        padding: 15,
        borderRadius: '5px',
    },
    activeGolfCourse: {
        color: 'white',
        backgroundColor: '#5FB924',
    },
    disableGolfCourse: {},
    hoverGolfCardCourse: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgba(33, 150, 243, 0.5)',
        },
    },
    timetable: {
        // height: '75vh',
        margin: 0,
        padding: 0,
    },
    timetableBox: {
        maxHeight: '100%',
        overflowX: 'auto',
        overflowY: 'auto',
    },
}));
