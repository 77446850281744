import React from 'react';
import { usePageHeader } from '../../../../lib/hooks';
import Report from '../../../sc/Report';

/**
 * View where the sporttia superadmin can see the list of sports.
 * @returns {JSX.Element}
 */
function AdminSports({}) {
    usePageHeader('sports');

    return <Report id={43} navigateBack="/home" />;
}

export default AdminSports;
