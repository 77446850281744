import React, { useState, useContext } from 'react';
import { Link, Chip, Box, Paper } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import { AppContext, SttButtonFab } from '../../../sporttia/all';
import { fullName, checkOffTag, copyToClipboard } from '../../../lib/utils';
import {
    useInteractionsFiles,
    usePageHeader,
    useQueryFilters,
} from '../../../lib/hooks';
import { SPORTCENTER_USERS } from '../../../lib/queryKeys';
import SttCachedTable from '../../../sporttia/SttCachedTable';
import useScsService from '../../../services/ScsService';
import { SttTopFilteringControls } from '../../../sporttia/SttTopFilteringControls';
import UserCreationDialog from '../../../components/dialogs/UserCreationDialog';
import Mship from './Mship';
import translations from '../../../translations';

/**
 * View where the sporttia sportcenter can see the list of mships (users).
 * @returns {JSX.Element}
 */
export default function Mships() {
    usePageHeader('users');
    const cxt = useContext(AppContext);
    const scsService = useScsService();
    const queryClient = useQueryClient();
    const { downloadFile } = useInteractionsFiles();
    const [mshipId, setMshipId] = useState(null);
    const [filters, setFilters] = useQueryFilters({}, SPORTCENTER_USERS);
    const [creatingUserDialogOpen, setCreatingUserDialogOpen] = useState(false);

    /**
     * Método para descargar los ficheros de los informes.
     * TODO migrar a react-query
     * @param format [pdf|csv] string format.
     */
    const download = (format) => {
        const urlQuery = Object.keys(filters)
            .filter((key) => filters[key] !== null)
            .map((key) => `${key}=${filters[key]}`)
            .join('&');

        downloadFile(
            `/scs/${cxt.sc.id}/mships.${format}?${urlQuery}`,
            format === 'pdf' ? 'application/pdf' : 'text/csv',
            null,
            `users.${format}`,
        );
    };

    /**
     * In this case, the value of group and city is changed to idGroup and idCity.
     * @param updatedFilters Object with the parameters returned by the SttTopFilteringControls component.
     */
    const doFiltering = (updatedFilters) => {
        const formatedFilters = { ...updatedFilters };

        delete formatedFilters.group;
        delete formatedFilters.city;

        if (updatedFilters?.group) {
            formatedFilters.idGroup = updatedFilters?.group?.id;
        }

        if (updatedFilters?.city) {
            formatedFilters.idCity = updatedFilters?.city?.id;
        }

        setFilters(formatedFilters);
    };

    return (
        <Paper>
            <SttTopFilteringControls
                p={2}
                fields={[
                    {
                        caption: cxt.t('Search'),
                        name: 'keyword',
                        type: 'text',
                        value: '',
                    },
                ]}
                extraFields={[
                    {
                        caption: cxt.t('Name'),
                        name: 'name',
                        type: 'text',
                        value: '',
                    },
                    {
                        caption: cxt.t('Login'),
                        name: 'login',
                        type: 'text',
                        value: '',
                    },
                    {
                        caption: cxt.t('Email'),
                        name: 'email',
                        type: 'text',
                        value: '',
                    },
                    {
                        caption: cxt.t('Gender'),
                        name: 'gender',
                        type: 'selectAutocomplete',
                        value: null,
                        labelField: 'caption',
                        valueField: 'value',
                        options: [
                            {
                                caption: cxt.t('Male'),
                                value: 'MALE',
                            },
                            {
                                caption: cxt.t('Female'),
                                value: 'FEMALE',
                            },
                        ],
                    },
                    {
                        caption: cxt.t('Purse'),
                        name: 'minPurseAmount',
                        type: 'price',
                        value: null,
                    },
                    {
                        caption: cxt.t('Debt'),
                        name: 'minDebt',
                        type: 'price',
                        value: null,
                    },
                    {
                        caption: cxt.t('Certified'),
                        name: 'certified',
                        type: 'select',
                        value: null,
                        options: [
                            {
                                caption: cxt.t('Yes'),
                                value: 'S',
                            },
                            {
                                caption: cxt.t('No'),
                                value: 'N',
                            },
                        ],
                    },
                    {
                        caption: cxt.t('Sport'),
                        name: 'idSport',
                        type: 'sport',
                        value: null,
                    },
                    {
                        caption: cxt.t('Group'),
                        name: 'group',
                        type: 'group',
                        value: null,
                    },
                    {
                        caption: cxt.t('DNI'),
                        name: 'dni',
                        type: 'text',
                        value: '',
                    },
                    {
                        caption: cxt.t('City'),
                        name: 'city',
                        type: 'city',
                        value: '',
                    },
                    {
                        caption: cxt.t('PostalCode'),
                        name: 'postalCode',
                        type: 'text',
                        value: null,
                    },
                    {
                        caption: cxt.t('Mobile'),
                        name: 'mobile',
                        type: 'text',
                        value: '',
                    },
                    {
                        caption: cxt.t('minAge'),
                        name: 'minAge',
                        type: 'text',
                        value: null,
                    },
                    {
                        caption: cxt.t('maxAge'),
                        name: 'maxAge',
                        type: 'text',
                        value: null,
                    },
                ]}
                trashAction
                onFilter={doFiltering}
                menu={[
                    {
                        caption: `${cxt.t(translations.generic.download)} PDF`,
                        onClick: () => download('pdf'),
                    },
                    {
                        caption: `${cxt.t(translations.generic.download)} CSV`,
                        onClick: () => download('csv'),
                    },
                ]}
            />

            <SttCachedTable
                queryKey={SPORTCENTER_USERS}
                queryFn={(params) =>
                    scsService.getScsMships(cxt?.sc?.id, params)
                }
                queryParams={filters}
                columns={[
                    {
                        title: '#',
                        type: 'tooltip',
                        value: (row) => ({ label: '#', value: row.id }),
                        onClick: (row) =>
                            copyToClipboard(row.id, () =>
                                cxt.showMessage(
                                    'S',
                                    cxt.t('copiedToClipboard'),
                                ),
                            ),
                    },
                    {
                        type: 'avatar',
                        value: (row) => (row.img ? row.img.url : null),
                    },
                    {
                        field: 'name',
                        title: cxt.t('Name'),
                        align: 'left',
                        value: (r) => (
                            <>
                                <Link
                                    style={{
                                        color: checkOffTag(r.user.login)
                                            ? '#4C4C4E'
                                            : 'primary',
                                    }}
                                    to={`#${r.id}`}
                                    onClick={() => setMshipId(r.id)}
                                >
                                    <b>
                                        {r.fullName
                                            ? r.fullName
                                            : cxt.t('UserWithoutName')}
                                    </b>
                                </Link>

                                <br />
                                {r.user &&
                                    (checkOffTag(r.user.login) ? (
                                        <i style={{ color: '#5b5b5b' }}>
                                            {cxt.t('UserCancelled')}
                                        </i>
                                    ) : (
                                        `@${r.user.login}`
                                    ))}
                                {r.age && (
                                    <span>
                                        ,{' '}
                                        {r.age
                                            ? `${r.age} ${cxt.t(
                                                  translations.generic.years,
                                              )}`
                                            : ''}
                                    </span>
                                )}
                                {r.tutor && (
                                    <Box mt={1}>
                                        <Chip
                                            size="small"
                                            label={`${cxt.t(
                                                'Tutor',
                                            )}: ${fullName(r.tutor)}`}
                                        />
                                    </Box>
                                )}

                                {r.notes && (
                                    <Box mt={1} style={{ color: 'red' }}>
                                        {r.notes}
                                    </Box>
                                )}
                            </>
                        ),
                    },
                    {
                        field: 'certified',
                        title: 'C',
                        type: 'active',
                        value: (r) =>
                            r.certified !== undefined && r.certified !== null,
                    },
                    {
                        field: 'withEmail',
                        title: cxt.t(translations.generic.email),
                        type: 'active',
                        value: (r) => r.email !== null,
                    },
                    {
                        field: 'numBookings',
                        title: cxt.t('bookings'),
                        type: 'counter',
                    },
                    {
                        field: 'amount',
                        title: cxt.t(translations.generic.purse),
                        type: 'price',
                    },
                    {
                        field: 'debt',
                        title: cxt.t('Debt'),
                        type: 'price',
                        value: (row) => ({ paid: false, price: row.debt }),
                    },
                    {
                        field: 'total',
                        title: cxt.t('Total'),
                        type: 'price',
                    },
                ]}
            />

            <Mship
                id={mshipId}
                open={!!mshipId}
                onClose={() => {
                    setMshipId(null);
                    queryClient.invalidateQueries(SPORTCENTER_USERS);
                }}
            />

            <UserCreationDialog
                open={creatingUserDialogOpen}
                onClose={() => {
                    setCreatingUserDialogOpen(false);
                }}
                onCreate={() => {
                    queryClient.invalidateQueries(SPORTCENTER_USERS);
                }}
            />

            <SttButtonFab onClick={() => setCreatingUserDialogOpen(true)} />
        </Paper>
    );
}
