import React, { useContext } from 'react';
import { Chip } from '@material-ui/core';
import { AppContext } from '../AppContext';

/**
 * Chip event type
 */
export function SttChipEventType({ type }) {
    const cxt = useContext(AppContext);

    const color = {
        NORMAL: '#3186a7',
        TOURNAMENT: '#a341bb',
    };

    return (
        <>
            {type && (
                <Chip
                    size="small"
                    label={cxt.t(`ET.${type}`)}
                    style={{ backgroundColor: color[type], color: 'white' }}
                />
            )}
        </>
    );
}
