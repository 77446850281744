import React, { useState, useEffect, useContext } from 'react';
import { Box, Paper, Divider } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import {
    AppContext,
    SttTimetable,
    SttTimetableWeek,
    SttTabs,
    SttButton,
    SttDialog,
} from '../../../sporttia/all';
import BookingDialog from '../../../components/dialogs/bookingDialog/BookingDialog';
import TimetableControls from './TimetableControls';
import TimetableOperate from './TimetableOperate';
import { getPath } from '../../Pages';
import BusyScheduleDialog from '../../../components/dialogs/BusyScheduleDialog';
import WidgetLogin from '../widget/components/WidgetLogin';
import Activity from '../../sc/activities/Activity';
import { getQueryStrings, redirectLogin } from '../../../lib/utils';
import BookingsConsentsPopUp from '../../../components/popups/BookingsConsentsPopUp';

export default function Timetable({ match }) {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const location = useLocation();

    const [date, setDate] = useState(moment().format('YYYY-MM-DD')); // YYYY-MM-DD
    const [timetable, setTimetable] = useState();
    const [selectedPiece, setSelectedPiece] = useState(null);
    const [selectedColumn, setSelectedColumn] = useState(null);
    const [isWeekly, setIsWeekly] = useState(false);
    const [openBookingId, setOpenBookingId] = useState(null);
    const [loginDialog, setLoginDialog] = useState(null);
    const [openActivityId, setOpenActivityId] = useState(null);
    const [affidavitAccepted, setAffidavitAccepted] = useState(null);

    // Copy / paste booking id
    const [bookingCut, setBookingCut] = useState(null);

    // ver busy
    const [busySchedule, setBusySchedule] = useState(null);

    // Top control configuration
    const [columnWidth, setColumnWidth] = useState(100);
    const [facilityGroupId, setFacilityGroupId] = useState();

    // Privilegios para ver los alquileres
    const [ImUserPrivileged, setImUserPrivileged] = useState(false);

    // Column width coeffs
    const coeffs = [
        {
            id: 'NORMAL',
            width: 100,
            name: cxt.t('controller.timetable.widthNormal'),
        },
        {
            id: 'CLOSE',
            width: 50,
            name: cxt.t('controller.timetable.widthClose'),
        },
        {
            id: 'WIDE',
            width: 150,
            name: cxt.t('controller.timetable.widthWide'),
        },
        {
            id: 'VERY_WIDE',
            width: 200,
            name: cxt.t('controller.timetable.widthveryWide'),
        },
    ];

    // Query strings
    let queryStrings = {};

    /**
     * Load timetable
     */
    const loadTimetable = () => {
        cxt.api('GET', '/timetable', {
            params: {
                idSC: match.params.id,
                date,
                idFieldGroup: facilityGroupId > 0 ? facilityGroupId : null,
                weekly: isWeekly,
                // idSport: $scope.sport ? $scope.sport.id : null,
            },
            success: (r) => {
                setTimetable(r);
            },
        });
    };

    /**
     * On change any control
     *
     * 	* facilityGroupId
     * 	* coeff
     * 	* date
     */
    const onChange = (p = {}) => {
        // Date changes
        if (p.date) {
            setDate(p.date);
        }

        // Column width coeff changes
        if (p.coeff) setColumnWidth(p.coeff.width);

        // Facility group changes
        if (p.facilityGroupId !== undefined)
            setFacilityGroupId(p.facilityGroupId);

        // Coeff changes
        if (p.coeff) {
            const coeff = coeffs.find((e) => e.id === p.coeff);
            setColumnWidth(coeff.width);
        }
    };

    /**
     * On change timetable
     */
    const onChangeTimetable = () => {
        // Close all
        setSelectedPiece(null);

        // Refresh
        loadTimetable();
    };

    /**
     * Top controls change
     */
    useEffect(() => {
        if (facilityGroupId) loadTimetable();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facilityGroupId, date, isWeekly]);

    /**
     * Init
     */
    useEffect(() => {
        cxt.setHeader({ title: cxt.t('Timetable') });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Open piece
     */
    const openPiece = (column, piece) => {
        setSelectedColumn(column);

        // If I'm use I only can use a FREE piece or see my own booking
        if (
            cxt.user.role === cxt.constants.roleUser ||
            cxt.user.role === cxt.constants.roleTeacher
        ) {
            switch (piece.mark) {
                case 'FREE':
                    setSelectedPiece(piece);
                    break;

                // Si el aforo está lleno, puedo abrirla si cuento con privilegios de ver alquileres
                case 'FULL':
                    if (ImUserPrivileged) {
                        setSelectedPiece(piece);
                    }
                    break;

                case 'RENT':
                    // I'm user owner of the booking
                    if (
                        piece.booking.user &&
                        piece.booking.user.id === cxt.user.id
                    ) {
                        history.push(
                            getPath('booked', { id: piece.booking.id }),
                        );
                    }
                    break;

                case 'EVENT':
                    history.push(
                        getPath('eventPublic', {
                            id: piece.eventPeriod.event.id,
                        }),
                    );
                    break;
                default:
                    break;
            }

            // I'm SC owner
        } else if (cxt.user.role === cxt.constants.roleSC) {
            switch (piece.mark) {
                case 'BUSY':
                    setBusySchedule({
                        id: piece.busy.id,
                        idFacility: column.facility ? column.facility.id : null,
                        name: piece.title,
                        notes: piece.notes,
                        ini: piece.ini,
                        end: piece.end,
                        timeini: moment(piece.ini).format('HH:mm'),
                        timeend: moment(piece.end).format('HH:mm'),
                    });

                    break;

                case 'RENT':
                    setOpenBookingId(piece.booking.id);
                    break;

                case 'CLASS':
                    setOpenActivityId(piece.class.activity.id);
                    break;

                default:
                    setSelectedPiece(piece);
                    setSelectedColumn(column);
                    break;
            }

            // I'm no one (not logged in, likely used from /scs/:id/widget)
        } else if (piece.mark === 'FREE') {
            // setSelectedPiece(piece);
            // setLoginDialog(true);
            // If user not logged in we will redirect to login page and
            // after that we will redirect to previous page.
            redirectLogin(history);
        }
    };

    /**
     * Cut booking
     */
    const cutBooking = (booking) => {
        setBookingCut(booking);
        setSelectedPiece(null);
        setOpenBookingId(null);

        cxt.showMessage('S', cxt.t('copyPasteBookingPrepared'));
    };

    /**
     * Toggle weekly
     */
    const toggleWeekly = () => {
        setIsWeekly(!isWeekly);
    };

    useEffect(() => {
        setAffidavitAccepted(affidavitAccepted);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [affidavitAccepted]);

    // date: 2021-04-20
    // group: 1
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        queryStrings = getQueryStrings(location.search);
        if (queryStrings && queryStrings.date) setDate(queryStrings.date);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    /**
     * Check if user have "19" privilege (can see bookings people names).
     * Esto lo necesitamos aquí para saber si podemos siquiera pinchar; pasamos la info a TimetableOperate
     */
    const getUserPrivileges = () => {
        cxt.api('GET', `/scs/${match.params.id}/me`, {
            success: (result) => {
                if (result.mship && result.mship.privileges.includes(19)) {
                    setImUserPrivileged(true);
                }
            },
        });
    };

    useEffect(() => {
        if (cxt.user && cxt.user.role === 'USER') getUserPrivileges();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cxt.user.id]);

    // -----| Render |-----
    return (
        <Paper>
            <Box p={2}>
                <TimetableControls
                    sc={{ id: match.params.id }}
                    date={date}
                    loadTimetable={loadTimetable}
                    onChange={onChange}
                    facilityGroupId={facilityGroupId}
                    coeffs={coeffs}
                    isWeekly={isWeekly}
                    toggleWeekly={toggleWeekly}
                />

                <Box mb={2}>
                    <Divider />
                </Box>
                {/* --- Copy / paste booking message --- */}
                {bookingCut && (
                    <Box mb={2}>
                        <Alert severity="info">
                            {cxt.t('copyPasteBookingPrepared')}
                            <Box mt={1}>
                                {cxt.t('page.timetable.move.info')}
                            </Box>

                            <Box mt={2}>
                                <SttButton
                                    size="small"
                                    onClick={() =>
                                        setOpenBookingId(bookingCut.id)
                                    }
                                >
                                    {cxt.t('See')}
                                </SttButton>
                            </Box>
                        </Alert>
                    </Box>
                )}

                {timetable && timetable.one && (
                    <SttTimetable
                        timetable={timetable.one}
                        columnWidth={columnWidth}
                        onSelectPiece={openPiece}
                    />
                )}

                {timetable && timetable.weekly && (
                    <Box>
                        <SttTabs
                            tabs={timetable.weekly.map((tt) => ({
                                caption: tt.facility.name,
                                component: (
                                    <SttTimetableWeek
                                        weekTimetable={tt.week.map((col) => ({
                                            ...col,
                                            facility: tt.facility,
                                        }))}
                                        columnWidth={columnWidth}
                                        onSelectPiece={openPiece}
                                    />
                                ),
                            }))}
                        />
                    </Box>
                )}
            </Box>

            {/* --- Booking dialog --- */}
            <BookingDialog
                idBooking={openBookingId}
                onClose={() => setOpenBookingId(null)}
                onUpdate={() => loadTimetable()}
                cutBooking={cutBooking}
            />

            {/* --- Operate on FREE --- */}
            {affidavitAccepted &&
                selectedPiece &&
                ['FREE', 'FULL'].includes(selectedPiece.mark) && (
                    <TimetableOperate
                        sc={{ id: match.params.id }}
                        column={selectedColumn}
                        piece={selectedPiece}
                        onClose={() => {
                            setSelectedPiece(null);
                            setAffidavitAccepted(null);
                        }}
                        onChangeTimetable={() => onChangeTimetable()}
                        bookingCut={bookingCut}
                        setBookingCut={setBookingCut}
                        cutBooking={cutBooking}
                        privileged={ImUserPrivileged}
                    />
                )}

            {/* --- Busy schedule --- */}
            {busySchedule && (
                <BusyScheduleDialog
                    item={busySchedule}
                    facility={selectedColumn && selectedColumn.facility}
                    onClose={() => setBusySchedule(null)}
                    onSave={onChangeTimetable}
                />
            )}

            {/* --- Activity dialog --- */}
            {openActivityId && (
                <Activity
                    id={openActivityId}
                    onClose={() => setOpenActivityId(null)}
                />
            )}

            {loginDialog && (
                <SttDialog
                    maxWidth="xs"
                    open
                    title={cxt.t('Login')}
                    content={
                        <WidgetLogin
                            mode="dialog"
                            onLogin={() => {
                                cxt.showMessage(
                                    'S',
                                    cxt.t('OperationSuccessful'),
                                );
                                setLoginDialog(false);
                            }}
                            onClose={() => setLoginDialog(false)}
                        />
                    }
                    onClose={() => setLoginDialog(false)}
                />
            )}
            {selectedPiece && ['FREE', 'FULL'].includes(selectedPiece.mark) && (
                <BookingsConsentsPopUp
                    scId={
                        cxt.logged && cxt.user.role === cxt.roleSC
                            ? cxt.sc.id
                            : match.params.id
                    }
                    onResponse={(value) => {
                        setAffidavitAccepted(value);
                        if (!value) {
                            setSelectedPiece(null);
                        }
                    }}
                />
            )}
        </Paper>
    );
}
