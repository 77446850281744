import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import {
    AppContext,
    SttFormSelect,
    SttButton,
    SttButtonNew,
    SttPrivilegePureList,
    SttSCAdminList,
} from '../../../sporttia/all';
import RoleDialog from './RoleDialog';
import {
    updateElementInArray,
    deleteElementFromArray,
} from '../../../lib/utils';

export default function RolesTab() {
    const cxt = useContext(AppContext);
    const [roles, setRoles] = useState();
    const [selectedRole, setSelectedRole] = useState(null);
    const [roleDialog, setRoleDialog] = useState();
    /**
     * Load admin roles
     */
    const loadAdminRoles = () => {
        cxt.api('GET', `/scs/${cxt.sc.id}/roles`, {
            success: (r) => {
                setRoles(r);
                if (r.count > 0) setSelectedRole(r.rows[0]);
            },
        });
    };

    /**
     * SC changes
     */
    useEffect(() => {
        if (cxt.sc.id) loadAdminRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cxt.sc.id]);

    /**
     * Add admin to role
     */
    const addAdminToRole = (admin) => {
        cxt.api('POST', `/scs/roles/${selectedRole.id}/admins`, {
            params: {
                idAdmin: admin.id,
            },
            success: () => {
                setSelectedRole({
                    ...selectedRole,
                    admins: updateElementInArray(selectedRole.admins, admin),
                });
                setRoles({
                    ...roles,
                    rows: updateElementInArray(roles.rows, selectedRole),
                });
            },
        });
    };

    /**
     * Delete admin from role
     */
    const delAdminFromRole = (admin) => {
        cxt.api('DELETE', `/scs/roles/${selectedRole.id}/admins/${admin.id}`, {
            success: () => {
                setSelectedRole({
                    ...selectedRole,
                    admins: deleteElementFromArray(selectedRole.admins, admin),
                });

                setRoles({
                    ...roles,
                    rows: updateElementInArray(roles.rows, selectedRole),
                });
            },
        });
    };

    /**
     * Add privilege to role
     */
    const addPrivilegeToRole = (priv) => {
        cxt.api('POST', `/scs/roles/${selectedRole.id}/privileges/${priv.id}`, {
            success: () => {
                setSelectedRole({
                    ...selectedRole,
                    privileges: updateElementInArray(
                        selectedRole.privileges,
                        priv,
                    ),
                });
                setRoles({
                    ...roles,
                    rows: updateElementInArray(roles.rows, selectedRole),
                });
            },
        });
    };

    /**
     * Delete privilege from role
     */
    const delPrivilegeFromRole = (priv) => {
        cxt.api(
            'DELETE',
            `/scs/roles/${selectedRole.id}/privileges/${priv.id}`,
            {
                success: () => {
                    setSelectedRole({
                        ...selectedRole,
                        privileges: deleteElementFromArray(
                            selectedRole.privileges,
                            priv,
                        ),
                    });

                    setRoles({
                        ...roles,
                        rows: updateElementInArray(roles.rows, selectedRole),
                    });
                },
            },
        );
    };

    // -----| Render |-----
    return (
        <>
            <Box>
                <Grid container spacing={3}>
                    {roles && (
                        <SttFormSelect
                            grid
                            md={6}
                            sm={6}
                            xs={12}
                            caption={cxt.t('Roles')}
                            defVal={selectedRole && selectedRole.id}
                            onChange={({ value }) =>
                                setSelectedRole(
                                    roles.rows.find((e) => e.id === value),
                                )
                            }
                            options={roles.rows.map((role) => ({
                                caption: role.name,
                                value: role.id,
                            }))}
                        />
                    )}

                    <Grid item md={3}>
                        <SttButton
                            fullWidth
                            startIcon=<CreateIcon />
                            caption={cxt.t('Modify')}
                            onClick={() => setRoleDialog(selectedRole)}
                        />
                    </Grid>

                    <Grid item md={3}>
                        <SttButtonNew
                            fullWidth
                            onClick={() => setRoleDialog({})}
                        />
                    </Grid>
                </Grid>
            </Box>

            {selectedRole && (
                <Box mt={3}>
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <SttSCAdminList
                                scAdmins={selectedRole.admins}
                                onDelete={delAdminFromRole}
                                onAdd={addAdminToRole}
                            />
                        </Grid>

                        <Grid item md={6}>
                            <SttPrivilegePureList
                                privileges={selectedRole.privileges}
                                scope="SC"
                                onAdd={addPrivilegeToRole}
                                onDelete={delPrivilegeFromRole}
                            />
                        </Grid>
                    </Grid>
                </Box>
            )}

            <RoleDialog
                role={roleDialog}
                onClose={(newRole) => {
                    // Close popup
                    setRoleDialog(null);

                    // Update roles
                    if (newRole) {
                        // Role deleted
                        if (newRole.trash) {
                            if (roles.rows.length > 1)
                                setSelectedRole(roles.rows[0]);
                            setRoles({
                                ...roles,
                                rows: deleteElementFromArray(
                                    roles.rows,
                                    newRole,
                                ),
                            });

                            // Role created or updated
                        } else {
                            setSelectedRole(newRole);
                            setRoles({
                                ...roles,
                                rows: updateElementInArray(roles.rows, newRole),
                            });
                        }
                    }
                }}
            />
        </>
    );
}
