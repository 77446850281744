import React, { useState, useEffect, useContext } from 'react';
import {
    Grid,
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Divider,
    Box,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import useKey from '@rooks/use-key';
import {
    AppContext,
    SttButton,
    SttDialog,
    SttForm,
    SttFormSearch,
    SttFormText,
    SttMshipList,
    SttPagination,
} from '../all';
import { useCrud } from '../../lib/hooks';
import { fullName } from '../../lib/utils';

const fetchRows = 8;

/**
 * SttSelectOrAddUser: select and existing user obtained from the back-end or manually write the user's name
 *
 * <String> caption: the control's caption
 * <String> name: component name (id)
 * <Object> defVal: currently selected user as {id, name}. id==0 means it's not an existing user
 * <Function> onChange: callback onChange(user => {})
 * <Function> onEnterKey: callback when the 'ENTER' key is pressed
 */
export function SttSelectOrAddUser({
    caption,
    name,
    defVal,
    onChange,
    onEnterKey,
    idSC,
    idMship,
}) {
    const cxt = useContext(AppContext);
    const [users, setUsers, , Get] = useCrud();
    const [user, setUser, setProperty] = useCrud(
        typeof defVal === 'object' ? defVal : { id: null, name: defVal || '' },
    );
    const [dialogOpened, setDialogOpened] = useState(false);

    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);

    const [newUser, setNewUser] = useState({
        name: '',
        surname1: '',
        surname2: '',
    });
    const [newUserDialogOpened, setNewUserDialogOpened] = useState(false);

    // defVal changes from the outside
    useEffect(() => {
        setUser({
            id: defVal.id,
            name: defVal.name || '',
            surname1: defVal.surname1 || '',
            surname2: defVal.surname2 || '',
        });
    }, [defVal]);

    // Either name or id changed, trigger onChange
    useEffect(() => {
        onChange(user);
    }, [user.name, user.id]);

    // Load 'real' users from the back-end
    function loadUsers(params = { page: 1 }) {
        Get(`/${idMship ? 'mships' : 'scs'}/${idMship || idSC}/pupils`, {
            ...params,
            name: search,
            rows: fetchRows,
        })
            .then((response) => {
                if (response.rows) {
                    setUsers(response);
                }
                if (params.page) {
                    setPage(params.page);
                }
            })
            .catch(console.log);
    }

    // Clicking on the search icon
    function onClickSearch() {
        if (!users) {
            loadUsers();
        }
        setDialogOpened(true);
    }

    // Existing user selected
    function selectUser(user) {
        setDialogOpened(false);
        if (onChange && user) {
            setUser(user);
            onChange(user);
        }
    }

    function clear() {
        setUser({
            id: null,
            name: '',
            surname1: '',
            surname2: '',
        });
    }

    return (
        <>
            {/* Search component that also allows the user to write whatever they want */}
            <SttFormSearch
                caption={caption}
                name="name"
                disabled
                defVal={
                    `${fullName(user)}` || ''
                } /* la func. fullName(user) obtiene el fullName o el combo name + surname1 + surname2 tanto de user como de su mship si lo hay */
                onClean={clear}
                onChange={setProperty}
                onClickSearch={onClickSearch}
                onEnterKey={onEnterKey}
            />

            {/* Dialog containing a search form and a list of users */}
            <SttDialog
                title={cxt.t('app.usersModal.title')}
                maxWidth="sm"
                onClose={() => setDialogOpened(false)}
                open={dialogOpened}
                content={
                    <div>
                        <Grid
                            container
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={8}>
                                <SttFormSearch
                                    defVal={search}
                                    onClickSearch={() =>
                                        loadUsers({ page: 1, name: search })
                                    }
                                    onChange={({ name, value }) =>
                                        setSearch(value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <SttButton
                                    caption="Añadir usuario"
                                    onClick={() => setNewUserDialogOpened(true)}
                                />
                            </Grid>
                        </Grid>

                        <SttMshipList
                            mships={{
                                ...users,
                                rows:
                                    users &&
                                    users.rows &&
                                    users.rows.map((row) => ({
                                        ...row,
                                        fullName: fullName(row),
                                        img: row.img
                                            ? {
                                                  url: cxt.getResource(
                                                      `/images/${row.img.id}.jpg`,
                                                  ),
                                              }
                                            : null,
                                    })),
                            }}
                            page={page}
                            onSelect={selectUser}
                            onLoadMships={loadUsers}
                        />

                        <SttDialog
                            title="Crear nuevo usuario"
                            open={newUserDialogOpened}
                            onClose={() => setNewUserDialogOpened(false)}
                            content={
                                <SttForm
                                    onChangeForm={({ name, value }) =>
                                        setNewUser({
                                            ...newUser,
                                            [name]: value,
                                        })
                                    }
                                    elements={[
                                        {
                                            xs: 12,
                                            caption: cxt.t('Name'),
                                            name: 'name',
                                            value: newUser.name,
                                        },
                                        {
                                            xs: 12,
                                            caption: cxt.t('Surname1'),
                                            name: 'surname1',
                                            value: newUser.surname1,
                                        },
                                        {
                                            xs: 12,
                                            caption: cxt.t('Surname2'),
                                            name: 'surname2',
                                            value: newUser.surname2,
                                        },
                                    ]}
                                />
                            }
                            buttons={[
                                {
                                    type: 'accept',
                                    disabled:
                                        newUser.name === '' ||
                                        newUser.surname1 === '',
                                    caption: 'Guardar',
                                    onClick: () => {
                                        setUser(newUser);
                                        setNewUserDialogOpened(false);
                                        setDialogOpened(false);
                                        setNewUser({
                                            name: '',
                                            surname1: '',
                                            surname2: '',
                                        });
                                    },
                                },
                            ]}
                        />
                    </div>
                }
            />
        </>
    );
}
