import React, { useState, useEffect, useContext } from 'react';
import {
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';
import { AppContext } from '../all';
import {
    updateElementInArray,
    deleteElementFromArray,
    normalizeDuration,
} from '../../lib/utils';

// Componente para seleccionar intervalos (en minutos). Empezando por min, hasta max, cada interval
/**
 *
 * string caption - field caption
 * int labelWidth - optional with for the caption can be provided
 * int defVal - selected value
 * int min - minimum minutes available for selection
 * int max - maximum minutos available for selection
 * int interval - length of intervals (in minutes, obviously)
 * Function onChange(minutes) - callback for select
 */
export function SttSelectInterval({
    grid = false,
    caption,
    labelWidth = 60,
    defVal = 0,
    min = 10,
    max = 23 * 60, // From 00:00 to 23:00
    interval,
    onChange,
    ...rest
}) {
    const cxt = useContext(AppContext);
    // const minutesWord = cxt.t('minutes');

    // Un primer elemento falso para seleccionar '0'. TODO: pensarse esto porque no me convence
    const menuItems = [
        <MenuItem key={0} value={0}>
            {cxt.t('Select')}
        </MenuItem>,
    ];

    // Creamos los elementos
    for (let i = min; i <= max; i += interval) {
        menuItems.push(
            <MenuItem key={i} value={i}>
                {/* `${i} ${minutesWord}` */}
                {normalizeDuration(i)}
            </MenuItem>,
        );
    }

    const content = (
        <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
            <InputLabel>{caption}</InputLabel>
            <Select
                value={defVal}
                onChange={(e) => onChange(e.target.value)}
                labelWidth={labelWidth}
            >
                {menuItems}
            </Select>
        </FormControl>
    );

    return grid ? (
        <Grid item {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
