import React, { useState, useEffect, useContext } from 'react';
import {
    Paper,
    Box,
    Container,
    Grid,
    Link,
    Typography,
} from '@material-ui/core';
import { AppContext } from '../../sporttia/all';
import LogoHeading from '../../layout/LogoHeading';

//
export default function ConfirmEmail(props) {
    const cxt = useContext(AppContext);
    const [user, setUser] = useState();

    // On component mount, we load the user from the state passed in the location
    useEffect(() => {
        if (props.location && props.location.state) {
            setUser(props.location.state.user);
        }
    }, []);

    function send() {
        if (!user) {
            cxt.showMessage('E', cxt.t('confirmEmail.invalid'));
            return;
        }

        cxt.api('POST', `/users/emails/confirmations`, {
            params: {
                idUser: user.id,
            },
            success: (result) => {
                if (result.email) {
                    cxt.showMessage(
                        'S',
                        cxt.t('view.recoverPassword.sendEmail'),
                    );
                }
            },
        });
    }

    return (
        <Container maxWidth="sm">
            <Box mt={6}>
                <Paper>
                    <Box p={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <LogoHeading
                                    caption={cxt.t('emailConfirmation.title')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    {cxt.t('confirmEmail.emailSent1')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    {cxt.t('confirmEmail.emailSent2')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <Box textAlign="center">
                                            <Link
                                                style={{ cursor: 'pointer' }}
                                                href="/login"
                                            >
                                                <Typography variant="body1">
                                                    {cxt.t('SignIn')}
                                                </Typography>
                                            </Link>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box textAlign="center">
                                            <Link
                                                style={{ cursor: 'pointer' }}
                                                onClick={send}
                                            >
                                                <Typography variant="body1">
                                                    {cxt.t(
                                                        'confirmEmail.emailSent3',
                                                    )}
                                                </Typography>
                                            </Link>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        </Container>
    );
}
