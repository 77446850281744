import React, { useState, useEffect, useContext } from 'react';
import { Paper } from '@material-ui/core';
import moment from 'moment';
import { AppContext, SttTopControls, SttTable } from '../../../sporttia/all';
import ActivityClassDialog from '../activities/ActivityClassDialog';

export default function TeacherClasses({ idTeacher }) {
    const cxt = useContext(AppContext);
    const [classes, setClasses] = useState();
    const [filters, setFilters] = useState({ name: '' });
    const [classOpened, setClassOpened] = useState(null);

    /**
     * Load classes
     */
    const loadClasses = (p = { rows: 20, page: 1 }) => {
        cxt.api('GET', `/teachers/${idTeacher}/classes`, {
            params: {
                ...filters,
                ...p,
                page: p.page,
                rows: p.rows,
            },
            success: (r) => {
                setClasses(r);
            },
        });
    };

    /**
     * Teacher id changes
     */
    useEffect(() => {
        if (idTeacher) loadClasses();
    }, [idTeacher]);

    // -----| Render |-----
    return (
        <>
            <SttTopControls
                p={2}
                fields={[
                    {
                        caption: cxt.t('Name'),
                        name: 'name',
                        type: 'text',
                        value: filters.name,
                        onEnterKey: loadClasses,
                    },
                ]}
                onChange={({ name, value }) =>
                    setFilters({ ...filters, [name]: value })
                }
                onFilter={loadClasses}
            />

            <SttTable
                data={classes}
                loading={false}
                autoload={false}
                onFetch={loadClasses}
                columns={[
                    {
                        title: cxt.t('Name'),
                        name: 'name',
                        field: 'name',
                        type: 'link',
                        onClick: (row) => setClassOpened(row),
                    },
                    {
                        title: cxt.t('Facility'),
                        name: 'facility',
                        type: 'facility',
                        field: 'facility',
                    },
                    {
                        title: cxt.t('Day'),
                        name: 'day',
                        value: (row) => moment(row.ini).format('ddd'),
                    },
                    {
                        title: cxt.t('Date'),
                        name: 'date',
                        type: 'dmy',
                        field: 'ini',
                    },
                    {
                        title: cxt.t('Time'),
                        name: 'time',
                        type: 'periodTime',
                        value: (row) => ({ ini: row.ini, end: row.end }),
                    },
                ]}
            />

            <ActivityClassDialog
                open={classOpened !== null}
                item={classOpened}
                onSave={loadClasses}
                onClose={() => setClassOpened(null)}
            />
        </>
    );
}
