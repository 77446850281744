import React, { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { Link } from '@material-ui/core';
import AdminApiTriggerExecutionDialog from '../../admin/hart/triggersExecutions/AdminApiTriggerExecutionDialog';
import { SttTable, SttTopControls } from '../../../sporttia/all';
import { AppContext } from '../../../sporttia/AppContext';
import { SttFullDialog } from '../../../sporttia/dialogs/SttFullDialog';
import ConfigurationTriggerDialog from './ConfigurationTriggerDialog';

const useStyles = makeStyles({
    playButton: {
        '&:hover': {
            cursor: 'pointer',
            color: 'rgba(33, 150, 243, 0.5)',
        },
    },
});

/**
 * Sport Center triggers dialog.
 * @param open
 * @param onClose
 * @returns {JSX.Element}
 */
export default function ConfigurationTriggersDialog({ open, onClose }) {
    const cxt = useContext(AppContext);
    const classes = useStyles();
    const [triggers, setTriggers] = useState();
    const [params, setParams] = useState({ page: 1, rows: 20 });
    const [selectedAPITrigger, setSelectedAPITrigger] = useState();
    const [filter, setFilter] = useState({ status: 'ALL' });

    /**
     * Get all api triggers.
     * @param p Params with page value.
     */
    const loadApiTriggers = (p = { page: 1, rows: 20 }) => {
        let finalFilter = {};
        if (filter.status !== 'ALL') finalFilter = filter;

        cxt.api('GET', `/scs/${cxt.sc.id}/cores/triggers/executions`, {
            params: {
                ...finalFilter,
                ...p,
            },
            success: (r) => {
                setTriggers(r);
            },
        });
    };

    /**
     * Execute trigger.
     * @param triggerId Integer with trigger ID.
     */
    const executeTrigger = (triggerId) => {
        cxt.api('POST', `/cores/triggers/executions/${triggerId}/runs`, {
            confirmation: true,
            success: () => {
                loadApiTriggers();
                cxt.showMessage('S', `Trigger con id ${triggerId} ejecutado.`);
            },
        });
    };

    useEffect(() => {
        loadApiTriggers();
    }, []);

    useEffect(() => {
        loadApiTriggers();
    }, [filter]);

    return (
        <SttFullDialog
            open={open}
            onClose={onClose}
            title={cxt.t('IntegrationOperationsTitle')}
        >
            <Paper>
                <SttTopControls
                    p={2}
                    fields={[
                        {
                            caption: cxt.t('Type'),
                            type: 'select',
                            name: 'status',
                            value: filter.status,
                            options: [
                                {
                                    caption: cxt.t('All'),
                                    value: 'ALL',
                                },
                                {
                                    caption: cxt.t('ERROR'),
                                    value: 'ERROR',
                                },
                                {
                                    caption: cxt.t('OK'),
                                    value: 'OK',
                                },
                            ],
                            onChange: ({ name, value }) =>
                                setFilter({ ...filter, [name]: value }),
                        },
                    ]}
                    iconButtons={[
                        {
                            icon: <AutorenewIcon />,
                            onClick: () => loadApiTriggers(params),
                        },
                    ]}
                    onFilter={loadApiTriggers}
                />

                <SttTable
                    data={triggers}
                    onFetch={(p) => {
                        loadApiTriggers(p);
                        setParams(p);
                    }}
                    columns={[
                        {
                            title: 'Id',
                            field: 'id',
                            value: (row) => (
                                <Link
                                    onClick={() => setSelectedAPITrigger(row)}
                                    variant="body2"
                                >
                                    {row.id}
                                </Link>
                            ),
                        },
                        {
                            title: 'Created',
                            field: 'created',
                            type: 'dmyhm',
                        },
                        {
                            title: 'Function',
                            value: (row) => row.coreTriggerSC.coreTrigger.code,
                        },
                        {
                            title: 'Status',
                            field: 'status',
                            value: (row) => (
                                <b
                                    style={{
                                        color:
                                            row.status === 'OK'
                                                ? 'green'
                                                : 'red',
                                    }}
                                >
                                    {row.status}
                                </b>
                            ),
                        },
                        {
                            title: '',
                            value: (row) => (
                                <PlayArrowIcon
                                    className={`${classes.playButton}`}
                                    onClick={() => executeTrigger(row.id)}
                                />
                            ),
                        },
                    ]}
                />

                <ConfigurationTriggerDialog
                    trigger={selectedAPITrigger}
                    onClose={() => setSelectedAPITrigger(null)}
                />
            </Paper>
        </SttFullDialog>
    );
}
