import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import { AppContext } from '../../../sporttia/AppContext';
import {
    SttSelectInterval,
    SttFormPrice,
    SttFormText,
    SttButtonCancel,
    SttButtonDelete,
    SttButtonSave,
    SttFormSelect,
    SttButtonRecover,
    SttFullDialog,
} from '../../../sporttia/all';
import { SttGroupList } from '../../../sporttia/lists/SttGroupList';
import { getPath } from '../../Pages';
import { getErrorMessage, parsePrice, subsetObject } from '../../../lib/utils';
import translations from '../../../translations';
import useScsService from '../../../services/ScsService';
import { useLoader } from '../../../lib/hooks';

/*
 * Componente para visualizar los datos de una tarifa
 */
export default function Rate({ item, onClose }) {
    const cxt = useContext(AppContext);
    const [rate, setRate] = useState(item || null);

    const [dialogTitle, setDialogTitle] = useState(
        item.id === 'create'
            ? cxt.t(translations.generic.createRate)
            : rate.name,
    );

    // Component mounting
    useEffect(() => {
        if (item.id === 'create') {
            setRate({
                name: '',
                individual: 0,
                prices: [
                    { duration: 0, price: 0 },
                    { duration: 0, price: 0 },
                    { duration: 0, price: 0 },
                ],
                groups: [],
                position: 0,
            });

            cxt.setHeader({
                title: cxt.t(translations.generic.newRate),
                urlBack: getPath('rates'),
            });
        } else {
            // eslint-disable-next-line no-use-before-define
            loadRate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    function loadRate(params = { trash: 1 }) {
        cxt.api('GET', `/rates/${item.id}`, {
            params,
            success: (response) => {
                if (response.rate) {
                    setDialogTitle(response.rate.name);
                    setRate(response.rate);
                }
            },
        });
    }

    function onChange({ name, value }) {
        setRate((prev) => ({ ...prev, [name]: value }));
    }

    function onChangeRateType({ name, value }) {
        setRate((prev) => ({ ...prev, [name]: parseInt(value) }));
    }

    // Changing prices requires special logic since they're arbitrary objects in an array
    function changePrice(price, value) {
        const { prices } = rate;

        const foundPrice = prices.find((p) => p === price);
        foundPrice.duration = value.duration;
        foundPrice.price = value.price;

        prices.splice(
            prices.findIndex((p) => p === price),
            1,
            foundPrice,
        );

        setRate({ ...rate, prices });
    }

    const scsService = useScsService();
    const updateScRateMutation = scsService.useUpdateScRate({
        onSuccess: (response) => {
            setDialogTitle(response.rate.name);
            cxt.showMessage('S', cxt.t(translations.generic.saved));
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });
    const createScRateMutation = scsService.useCreateScRate({
        onSuccess: (response) => {
            setRate(response.rate);
            setDialogTitle(response.rate.name);
            cxt.showMessage('S', cxt.t(translations.generic.created));
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });

    function upsertRate() {
        const params = subsetObject(rate, [
            'name',
            'individual',
            'prices',
            'position',
        ]);

        if (rate.id) {
            updateScRateMutation.mutate({
                rateId: rate.id,
                params: {
                    ...params,
                    prices: params.prices.map((p) => ({
                        ...p,
                        price: parsePrice(p.price),
                    })),
                    groups: rate.groups,
                },
            });
        } else {
            createScRateMutation.mutate({
                scId: cxt.sc.id,
                params: {
                    ...params,
                    prices: params.prices.map((p) => ({
                        ...p,
                        price: parsePrice(p.price),
                    })),
                },
            });
        }
    }

    function deleteRate() {
        cxt.api('DELETE', `/rates/${rate.id}`, {
            confirmation: true,
            success: () => {
                onClose();
            },
        });
    }

    function recoverRate() {
        cxt.api('PUT', `/rates/${rate.id}/recover`, {
            confirmation: true,
            success: () => {
                setRate({
                    ...rate,
                    trash: 0,
                });
            },
        });
    }

    function addGroup(group) {
        cxt.api('POST', `/rates/${rate.id}/groups/${group.id}`, {
            params: {
                idFacility: group.id,
            },
            success: () => {
                setRate({
                    ...rate,
                    groups: rate.groups ? rate.groups.concat([group]) : [group],
                });
            },
        });
    }

    function removeGroup(group) {
        cxt.api('DELETE', `/rates/${rate.id}/groups/${group.id}`, {
            success: () => {
                setRate({
                    ...rate,
                    groups: rate.groups.filter((g) => g.id !== group.id),
                });
            },
        });
    }

    const [, mutationsLoader] = useLoader([
        createScRateMutation.status,
        updateScRateMutation.status,
    ]);

    return (
        <SttFullDialog
            open
            onClose={() => {
                setRate(null);
                onClose();
            }}
            title={dialogTitle}
        >
            {mutationsLoader}

            <Paper padding={3}>
                <Box padding={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={8}>
                                    <SttFormText
                                        name="name"
                                        caption={cxt.t(
                                            translations.generic
                                                .nameAndDescription,
                                        )}
                                        defVal={rate.name}
                                        onChange={onChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <SttFormSelect
                                        name="individual"
                                        defVal={rate.individual ? 1 : 0}
                                        caption={cxt.t(
                                            translations.generic.type,
                                        )}
                                        options={[
                                            {
                                                caption: cxt.t(
                                                    translations.generic
                                                        .completed,
                                                ),
                                                value: '0',
                                            },
                                            {
                                                caption: cxt.t(
                                                    translations.generic
                                                        .individual,
                                                ),
                                                value: '1',
                                            },
                                        ]}
                                        onChange={onChangeRateType}
                                    />
                                </Grid>

                                {/* --- For each duration / price --- */}
                                {rate.prices?.map((p, index) => (
                                    <React.Fragment key={p.id}>
                                        <Grid item xs={12} md={6}>
                                            <SttSelectInterval
                                                caption={`${cxt.t(
                                                    translations.generic
                                                        .duration,
                                                )} ${index + 1}`}
                                                labelWidth={90}
                                                defVal={p.duration}
                                                min={5}
                                                max={1435}
                                                interval={5}
                                                onChange={(value) =>
                                                    changePrice(p, {
                                                        duration: value,
                                                        price: p.price,
                                                    })
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <SttFormPrice
                                                name="name"
                                                caption={`${cxt.t(
                                                    translations.generic.price,
                                                )} ${index + 1}`}
                                                defVal={p.price}
                                                onChange={({ value }) =>
                                                    changePrice(p, {
                                                        duration: p.duration,
                                                        price: value,
                                                    })
                                                }
                                            />
                                        </Grid>
                                    </React.Fragment>
                                ))}

                                <Grid item xs={12}>
                                    <SttFormText
                                        name="position"
                                        caption={cxt.t(
                                            translations.generic.position,
                                        )}
                                        defVal={rate.position}
                                        onChange={onChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* --- Group list --- */}
                        <Grid item xs={12} md={6}>
                            {rate.id && (
                                <SttGroupList
                                    includeChildren
                                    onTime
                                    groups={rate.groups || []}
                                    onAdd={(group) => addGroup(group)}
                                    onDelete={(group) => removeGroup(group)}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Paper>

            {/* --- Buttons --- */}
            <Box mt={3}>
                <Grid container spacing={3}>
                    {!rate.trash && (
                        <Grid item xs={12} sm={6} md={3}>
                            <SttButtonSave
                                fullWidth
                                onClick={() => {
                                    upsertRate();
                                }}
                            />
                        </Grid>
                    )}

                    {!rate.id && (
                        <Grid item xs={12} sm={6} md={3}>
                            <SttButtonCancel fullWidth onClick={onClose} />
                        </Grid>
                    )}

                    {rate.id && !rate.trash && (
                        <Grid item xs={12} sm={6} md={3}>
                            <SttButtonDelete
                                fullWidth
                                onClick={() => {
                                    deleteRate();
                                }}
                            />
                        </Grid>
                    )}

                    {rate.trash && (
                        <Grid item xs={12} sm={6} md={3}>
                            <SttButtonRecover
                                fullWidth
                                onClick={() => {
                                    recoverRate();
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
        </SttFullDialog>
    );
}
