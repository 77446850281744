import React, { useContext } from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { SttFullDialog } from '../../../sporttia/dialogs/SttFullDialog';
import { SttForm } from '../../../sporttia/SttForm';
import { SttLabelForm } from '../../../sporttia/labels/SttLabelForm';
import { AppContext } from '../../../sporttia/AppContext';

export default function ConfigurationTriggerDialog({
    trigger = null,
    onClose,
}) {
    const cxt = useContext(AppContext);

    return (
        <SttFullDialog
            open={trigger !== null}
            onClose={onClose}
            title={cxt.t('IntegrationOperationsTitle')}
        >
            <Container>
                <Paper>
                    <Box mt={2} p={2}>
                        {trigger && (
                            <Grid container spacing={3}>
                                <SttLabelForm
                                    grid
                                    md={8}
                                    title="Request"
                                    text={trigger.request}
                                />

                                <SttLabelForm
                                    grid
                                    md={8}
                                    title="Answer"
                                    text={trigger.answer}
                                />
                            </Grid>
                        )}
                    </Box>
                </Paper>
            </Container>
        </SttFullDialog>
    );
}
