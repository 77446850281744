/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react';
import {
    MutationFunction,
    UseMutationOptions,
    UseQueryOptions,
    useMutation,
    useQuery,
} from 'react-query';
import { useAPI } from '../lib/hooks';
import useRestService from './RestService';
import { AppContext } from '../sporttia/AppContext';
import { GetDeviceSpace200 } from '../types/api/paths/Devices';
import DeviceSpace from '../types/models/DeviceSpace';

const resource = '/devices';

/**
 * Hook to access all 'devices' resources.
 */
export default function useDevicesService() {
    const { migrationApis } = useContext(AppContext)!;
    const [request] = useAPI(migrationApis);
    const services = useRestService(resource);

    /**
     * PUT /devices/:id/recover request.
     * @param id Device ID.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function updateDevicesRecover(id: any) {
        return request('PUT', `${resource}/${id}/recover`);
    }

    /**
     * POST /devices/:deviceId/facilities/:faciltyId request.
     * @param deviceId Device ID.
     * @param facilityId Facility ID.
     * @returns {Promise<*>|*}
     */
    function createDevicesFacilities(deviceId: any, facilityId: any) {
        return request(
            'POST',
            `${resource}/${deviceId}/facilities/${facilityId}`,
        );
    }

    /**
     * DELETE /devices/:deviceId/facilities/:faciltyId request.
     * @param deviceId Device ID.
     * @param facilityId Facility ID.
     * @returns {Promise<*>|*}
     */
    function removeDevicesFacilities(deviceId: any, facilityId: any) {
        return request(
            'DELETE',
            `${resource}/${deviceId}/facilities/${facilityId}`,
        );
    }

    /**
     * POST /events/categories/:categoryId/participants request.
     */
    function useCreateDeviceSpace(
        mutationOptions?: UseMutationOptions<
            { deviceSpace: DeviceSpace },
            unknown,
            {
                sportcenterId: number;
                params: Record<string, unknown>;
            }
        >,
    ) {
        const mutationFn: MutationFunction<
            { deviceSpace: DeviceSpace },
            {
                sportcenterId: number;
                params: Record<string, unknown>;
            }
        > = ({ sportcenterId, params }) =>
            request('POST', `/scs/${sportcenterId}${resource}/spaces`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * POST /events/categories/:categoryId/participants request.
     */
    function useUpdateDeviceSpace(
        mutationOptions?: UseMutationOptions<
            { deviceSpace: DeviceSpace },
            unknown,
            {
                deviceSpaceId: number;
                params: Record<string, unknown>;
            }
        >,
    ) {
        const mutationFn: MutationFunction<
            { deviceSpace: DeviceSpace },
            {
                deviceSpaceId: number;
                params: Record<string, unknown>;
            }
        > = ({ deviceSpaceId, params }) =>
            request('PUT', `${resource}/spaces/${deviceSpaceId}`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * DELETE /devices/spaces/:id request.
     */
    function useDeleteDeviceSpace(
        mutationOptions?: UseMutationOptions<
            unknown,
            unknown,
            {
                deviceSpaceId: number;
            }
        >,
    ) {
        const mutationFn: MutationFunction<
            unknown,
            {
                deviceSpaceId: number;
            }
        > = ({ deviceSpaceId }) =>
            request('DELETE', `${resource}/spaces/${deviceSpaceId}`);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * GET /devices/spaces/:id request.
     */
    function useGetDeviceSpace<TData = GetDeviceSpace200>(
        id: number,
        queryOptions?: UseQueryOptions<GetDeviceSpace200, unknown, TData>,
    ) {
        const queryKey = queryOptions?.queryKey ?? [`${resource}/spaces/${id}`];

        const queryFn = () =>
            request<GetDeviceSpace200>('GET', `${resource}/${id}/groups`);

        const query = useQuery(queryKey, queryFn, queryOptions);

        return {
            ...query,
            queryKey,
        };
    }

    /**
     * POST /devices/spaces/:spaceDeviceid/devices/:deviceId request.
     */
    function useCreateDeviceSpaceDevice(
        mutationOptions?: UseMutationOptions<
            { deviceSpace: DeviceSpace },
            unknown,
            {
                deviceSpaceId: number;
                deviceId: number;
            }
        >,
    ) {
        const mutationFn: MutationFunction<
            { deviceSpace: DeviceSpace },
            {
                deviceSpaceId: number;
                deviceId: number;
            }
        > = ({ deviceSpaceId, deviceId }) =>
            request(
                'POST',
                `${resource}/spaces/${deviceSpaceId}/devices/${deviceId}`,
            );

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * DELETE /devices/spaces/:spaceDeviceid/devices/:deviceId request.
     */
    function useDeleteDeviceSpaceDevice(
        mutationOptions?: UseMutationOptions<
            { deviceSpace: DeviceSpace },
            unknown,
            {
                deviceSpaceId: number;
                deviceId: number;
            }
        >,
    ) {
        const mutationFn: MutationFunction<
            { deviceSpace: DeviceSpace },
            {
                deviceSpaceId: number;
                deviceId: number;
            }
        > = ({ deviceSpaceId, deviceId }) =>
            request(
                'DELETE',
                `${resource}/spaces/${deviceSpaceId}/devices/${deviceId}`,
            );

        return useMutation(mutationFn, mutationOptions);
    }

    return {
        ...services,
        updateDevicesRecover,
        createDevicesFacilities,
        removeDevicesFacilities,
        useCreateDeviceSpace,
        useUpdateDeviceSpace,
        useDeleteDeviceSpace,
        useGetDeviceSpace,
        useCreateDeviceSpaceDevice,
        useDeleteDeviceSpaceDevice,
    };
}
