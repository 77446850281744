import React, { useContext, useState } from 'react';
import { Box } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { SttForm } from '../../sporttia/SttForm';
import { SttDialog } from '../../sporttia/dialogs/SttDialog';
import { AppContext } from '../../sporttia/AppContext';
import { useInteractionsFiles } from '../../lib/hooks';

export default function Q60DownloadDialog({
    open = false,
    onClose,
    filters = {},
}) {
    const cxt = useContext(AppContext);
    const { downloadFile } = useInteractionsFiles();
    const [q60Params, setQ60Params] = useState({
        codeTramitter: 30820,
        presenterEntity: '0081',
        presenterOffice: 1045,
        paidIni: new Date(),
        paymentDate: new Date(),
        ccc: null,
        collectorEntity: 81,
        collectorOffice: 1045,
        paidEnd: new Date(),
        validityDate: new Date(),
        taxCode: 35,
    });

    const downloadQ60 = () => {
        cxt.api('POST', `/c60s`, {
            params: {
                ...filters,
                ...q60Params,
            },
            success: (response) => {
                downloadFile(`/c60s/specials/${response.c60.id}.txt`, null);
                downloadFile(`/c60s/${response.c60.id}.txt`, null);
            },
        });
    };

    /**
     * Handle form.
     * @param name Key value.
     * @param value Value to be set.
     */
    const handleForm = ({ name, value }) => {
        setQ60Params({ ...q60Params, [name]: value });
    };

    return (
        <SttDialog
            maxWidth="md"
            open={open}
            onClose={onClose}
            title="Cuaderno 60"
            content={
                <Paper>
                    <Box p={2}>
                        <SttForm
                            elements={[
                                {
                                    xs: 4,
                                    caption: cxt.t('hart.c60.codetramitter'),
                                    name: 'codeTramitter',
                                    value: q60Params.codeTramitter,
                                },
                                {
                                    xs: 4,
                                    caption: cxt.t('hart.c60.presenterEntity'),
                                    name: 'presenterEntity',
                                    value: q60Params.presenterEntity,
                                },
                                {
                                    xs: 4,
                                    caption: cxt.t('hart.c60.presenterOffice'),
                                    name: 'presenterOffice',
                                    value: q60Params.presenterOffice,
                                },
                                {
                                    xs: 4,
                                    caption: cxt.t('DateIni'),
                                    name: 'paidIni',
                                    type: 'date',
                                    value: q60Params.paidIni,
                                },
                                {
                                    xs: 4,
                                    caption: cxt.t('DateEnd'),
                                    name: 'paidEnd',
                                    type: 'date',
                                    value: q60Params.paidEnd,
                                },
                                {
                                    xs: 4,
                                    caption: 'CCC',
                                    name: 'ccc',
                                    value: q60Params.ccc,
                                },
                                {
                                    xs: 4,
                                    caption: cxt.t('hart.c60.collectorEntity'),
                                    name: 'collectorEntity',
                                    value: q60Params.collectorEntity,
                                },
                                {
                                    xs: 4,
                                    caption: cxt.t('hart.c60.collectorOffice'),
                                    name: 'collectorOffice',
                                    value: q60Params.collectorOffice,
                                },
                                {
                                    xs: 4,
                                    caption: cxt.t('hart.c60.taxCode'),
                                    name: 'taxCode',
                                    value: q60Params.taxCode,
                                },
                            ]}
                            onChangeForm={handleForm}
                        />
                    </Box>
                </Paper>
            }
            buttons={[
                {
                    caption: cxt.t('Generate'),
                    type: 'accept',
                    onClick: () => downloadQ60(),
                },
                {
                    caption: cxt.t('Close'),
                    onClick: () => onClose(),
                },
            ]}
        />
    );
}
