import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { Paper, Box, Typography } from '@material-ui/core';
import { AppContext } from './all';

type SttPageProps = PropsWithChildren<{
    title?: string;
    innerTitle?: string;
}>;

export function SttPage({ title, innerTitle, children }: SttPageProps) {
    const cxt = useContext(AppContext)!;

    /**
     * Init
     */
    useEffect(() => {
        if (title)
            cxt.setHeader({
                title,
            });
    }, []);

    // Render
    return (
        <Paper>
            <Box p={3}>
                {innerTitle && (
                    <Box mb={2}>
                        <Typography component="h1">{innerTitle}</Typography>
                    </Box>
                )}

                {children}
            </Box>
        </Paper>
    );
}
