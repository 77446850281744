import React, { useState, useEffect, useContext } from 'react';
import { Grid, Box, Divider } from '@material-ui/core';
import moment from 'moment';

import { useQueryClient } from 'react-query';
import {
    AppContext,
    SttLabelForm,
    SttFormText,
    SttButtonSave,
    SttButtonDelete,
    SttButtonRecover,
    SttButtonCancel,
    SttSelectGender,
} from '../../../sporttia/all';
import { SPORTCENTER_ACTIVITY_TEACHERS } from '../../../lib/queryKeys';
import { GENDER } from '../../../sporttia/selectors/SttSelectGender';
import DateBuilder from '../../../lib/DateBuilder';

export default function Teacher({ idTeacher, onClose, onCreate }) {
    const queryClient = useQueryClient();
    const cxt = useContext(AppContext);
    const [creating, setCreating] = useState(false);
    const [teacher, setTeacher] = useState();

    /**
     * Load free schedule
     */
    const loadTeacher = () => {
        cxt.api('GET', `/teachers/${idTeacher}`, {
            params: {
                trash: true,
            },
            success: (r) => {
                setTeacher(r.teacher);

                // Set creating
                setCreating(false);
            },
        });
    };

    /**
     * Save free schedule
     */
    const saveTeacher = () => {
        // Create
        if (creating) {
            cxt.api('POST', `/scs/${cxt.sc.id}/teachers`, {
                params: {
                    ...teacher,
                    gender: teacher.gender || GENDER.UNKNOWN,
                },
                success: (r) => {
                    cxt.showMessage('S', cxt.t('Created'));
                    setTeacher(r.teacher);
                    setCreating(false);
                    onCreate(r.teacher.name, r.teacher.id);
                    queryClient.invalidateQueries(
                        SPORTCENTER_ACTIVITY_TEACHERS,
                    );
                },
            });

            // Update
        } else {
            const putParameters = {
                name: teacher.name,
                login: teacher.login,
                surname1: teacher.surname1,
                surname2: teacher.surname2,
                email: teacher.email,
                phone: teacher.phone,
                gender: teacher.gender || GENDER.UNKNOWN,
                dni: teacher.dni,
            };
            cxt.api('PUT', `/teachers/${teacher.id}`, {
                params: putParameters,
                success: () => {
                    cxt.showMessage('S', cxt.t('Saved'));
                },
            });
        }
    };

    /**
     * Delete free schedule
     */
    const delTeacher = () => {
        cxt.api('DELETE', `/teachers/${teacher.id}`, {
            confirmation: true,
            success: () => {
                cxt.showMessage('S', cxt.t('Deleted'));
                queryClient.invalidateQueries(SPORTCENTER_ACTIVITY_TEACHERS);
                onClose();
            },
        });
    };

    /**
     * Recover free schedule
     */
    const recoverTeacher = () => {
        cxt.api('PUT', `/teachers/${teacher.id}/recover`, {
            confirmation: true,
            success: () => {
                cxt.showMessage('S', cxt.t('Recovered'));
                loadTeacher();
                queryClient.invalidateQueries(SPORTCENTER_ACTIVITY_TEACHERS);
            },
        });
    };

    /**
     * Handle form
     */
    const handleForm = ({ name, value }) => {
        setTeacher({ ...teacher, [name]: value });
    };

    /**
     * Init
     */
    useEffect(() => {
        if (idTeacher) {
            if (idTeacher === 'create') {
                // Init data to create
                setTeacher({
                    login: null,
                    name: null,
                    surname1: null,
                    surname2: null,
                    dni: null,
                    email: null,
                    phone: null,
                    gender: null,
                });

                // Set status
                setCreating(true);
            } else {
                loadTeacher();
            }
        }
    }, [idTeacher]);

    // -----| Render |-----
    return (
        <Box>
            {teacher && (
                <>
                    {!creating && (
                        <>
                            <Grid container spacing={3}>
                                <SttLabelForm
                                    grid
                                    md={2}
                                    title={cxt.t('Created')}
                                    text={new DateBuilder(
                                        teacher.created,
                                        cxt?.sc?.timezone?.name,
                                        cxt?.lang?.key,
                                    ).dmyhm()}
                                />
                            </Grid>

                            <Box my={4}>
                                <Divider />
                            </Box>
                        </>
                    )}

                    <Grid container spacing={3}>
                        <SttFormText
                            grid
                            md={3}
                            sm={12}
                            xs={12}
                            name="login"
                            caption={cxt.t('Login')}
                            defVal={teacher.login}
                            onChange={handleForm}
                        />

                        <SttFormText
                            grid
                            md={9}
                            sm={12}
                            xs={12}
                            name="name"
                            caption={cxt.t('Name')}
                            defVal={teacher.name}
                            onChange={handleForm}
                        />

                        <SttFormText
                            grid
                            md={6}
                            name="surname1"
                            caption={cxt.t('Surname1')}
                            defVal={teacher.surname1}
                            onChange={handleForm}
                        />

                        <SttFormText
                            grid
                            md={6}
                            name="surname2"
                            caption={cxt.t('Surname2')}
                            defVal={teacher.surname2}
                            onChange={handleForm}
                        />

                        <SttFormText
                            grid
                            md={4}
                            sm={12}
                            xs={12}
                            name="dni"
                            caption={cxt.t('DNI')}
                            defVal={teacher.dni}
                            onChange={handleForm}
                        />

                        <SttSelectGender
                            grid
                            md={4}
                            sm={12}
                            xs={12}
                            name="gender"
                            defVal={teacher.gender || GENDER.UNKNOWN}
                            onChange={handleForm}
                        />

                        <SttFormText
                            grid
                            md={4}
                            sm={12}
                            xs={12}
                            name="phone"
                            caption={cxt.t('Phone')}
                            defVal={teacher.phone}
                            onChange={handleForm}
                        />

                        <SttFormText
                            grid
                            md={8}
                            sm={12}
                            xs={12}
                            name="email"
                            caption={cxt.t('Email')}
                            defVal={teacher.email}
                            onChange={handleForm}
                        />
                    </Grid>
                </>
            )}

            {teacher && (
                <Box mt={3}>
                    <Grid container spacing={3}>
                        {teacher.trash && (
                            <Grid item md={3} sm={12} xs={12}>
                                <SttButtonRecover
                                    fullWidth
                                    onClick={() => recoverTeacher()}
                                />
                            </Grid>
                        )}

                        {!teacher.trash && (
                            <Grid item md={3} sm={12} xs={12}>
                                <SttButtonSave
                                    fullWidth
                                    onClick={() => saveTeacher()}
                                />
                            </Grid>
                        )}

                        {!creating && !teacher.trash && (
                            <Grid item md={3} sm={12} xs={12}>
                                <SttButtonDelete
                                    fullWidth
                                    onClick={() => delTeacher()}
                                />
                            </Grid>
                        )}

                        {creating && (
                            <Grid item md={3} sm={12} xs={12}>
                                <SttButtonCancel
                                    fullWidth
                                    onClick={() => onClose()}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Box>
            )}
        </Box>
    );
}
