import React from 'react';
import { usePageHeader } from '../../../../lib/hooks';
import Report from '../../../sc/Report';

/**
 * View where the sporttia superadmin can see the list of sms's.
 * @returns {JSX.Element}
 */
function AdminSmss({}) {
    usePageHeader('smss');

    return <Report id={16} navigateBack="/home" />;
}

export default AdminSmss;
