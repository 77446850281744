import { requestAdHoc } from './utils';
import constants from '../config/constants';

const localMessagingServiceUrl = `https://agent.sporttia.com:9001`;

export const OP_ERROR = {
    UNKNOWN: 0,
    UNAVAILABLE: 'Estado de la operación no disponible.',
    NO_CHANGE: 'Se ha producido un error: el dispositivo no dispone de cambio.',
    CANCELLED: 'Se ha cancelado la operación.',
    HARDWARE:
        'Error en el dispositivo; verifique si hay billetes o monedas atascados.',
};

const defaultQueryTimer = 3000;

export default class Cashdro {
    constructor(ip = '', user = '', pass = '') {
        this.deviceIp = ip || localStorage.getItem('CashdroIp');
        this.user = user || localStorage.getItem('CashdroUser');
        this.pass = pass || localStorage.getItem('CashdroPass');

        if (!this.deviceIp || !this.user || !this.pass) {
            throw new Error(
                'Error de configuración: consulte la sección "Configuración", perstaña "Cashdro".',
            );
        }

        this.deviceUrl = `https://${this.deviceIp}/Cashdro3WS/index.php`;

        this.availablePieces = {
            monedas: [],
            billetes: [],
        };

        this.noRepeatkillSwitch = false;
    }

    async startOperation(type, params) {
        const result = await requestAdHoc('POST', localMessagingServiceUrl, {
            method: 'GET',
            url: this.deviceUrl,
            params: {
                operation: 'startOperation',
                type,
                name: this.user,
                password: this.pass,
                parameters: params ? JSON.stringify(params) : '',
            },
        });

        /*
        {
          "code": <code>, si su valor es 1, no se ha producido error, en caso contrario se indica el código del error (valor negativo).
          -1 Usuario/password incorrectos.
          -2 CashDro ocupado con otra transacción.
          -3 Importe de la transacción incorrecto.
          -4 El usuario no tiene permiso para realizar la transacción.
          -99 Parámetros incorrectos.
          -1900 CashDro está cargando o actualizando.
          -998 CashDro está fuera de servicio. Revisar estado en la pantalla de diagnostico.
          -999 El servicio de CashDro está parado.
          "response": {
            "errorMessage": "none",
            "operation": {
              "operationId": "<operationId>"
            }
          }
        }

        LO DE ARRIBA ES MENTIRA LOL

        La respuesta real es tipo:
        {
            "code": 1,
            "data: "35" <- el operationId de verdad
        }

        PARECE QUE TODAS LAS RESPUESTAS DE LA DOC SON MENTIRA. SIEMPRE ES "data"

        */

        return result;
    }

    async confirmOperation(operationId) {
        const result = await requestAdHoc('POST', localMessagingServiceUrl, {
            method: 'GET',
            url: this.deviceUrl,
            params: {
                operation: 'acknowledgeOperationId',
                operationId,
                name: this.user,
                password: this.pass,
            },
        });

        /*
        {
            "code": <code>,
            1 ok
            -1 Usuario/password incorrectos
            -2 Identificador de la transacción incorrecto
            -3 Parámetros incorrectos, transacción no iniciada.
            "response": {
                "errorMessage": "none"
            }
        }
        */

        return result;
    }

    async whileQueryingOperation(params, onFinished, onError, onUpdate) {
        const { operationId, queryTimer, updateOnlyOnce } = params;

        this.noRepeatkillSwitch = false;

        if (!operationId) {
            console.log('Se requiere operationId');
        }

        const interval = setInterval(async () => {
            const result = await this.queryOperation(operationId).catch(
                (err) => {
                    onError(err?.error?.msg || err);
                },
            );

            const { code } = result;

            // result.data viene como un string que representa un json
            const data = JSON.parse(result.data);

            // operation son los datos de la operación
            // devices es el estado de los mecanismos internos (qué monedas tienen etc)
            const { operation, devices } = data;

            if (code === 1) {
                switch (operation?.state) {
                    case 'F': // fin, pagado
                        clearInterval(interval);
                        if (onFinished) {
                            onFinished({ operation, devices });
                        }
                        break;
                    // hay que esperar
                    case 'Q':
                        break;
                    case 'E':
                        if (onUpdate) {
                            if (
                                !updateOnlyOnce ||
                                (updateOnlyOnce && !this.noRepeatkillSwitch)
                            ) {
                                onUpdate();
                                this.noRepeatkillSwitch = true;
                            }
                        }
                        break;
                    case 'I': // Operación no confirmada con acknowledgeOperation
                    default: // no puede pasar en teoría
                        clearInterval(interval);
                        if (onError) {
                            onError(
                                'Operación no confirmada / error desconocido',
                            );
                        }
                        break;
                }
            }
        }, queryTimer || defaultQueryTimer);
    }

    async queryOperation(operationId) {
        const result = await requestAdHoc('POST', localMessagingServiceUrl, {
            method: 'GET',
            url: this.deviceUrl,
            params: {
                operation: 'askOperation',
                operationId,
                name: this.user,
                password: this.pass,
            },
        });

        return result;

        /*
        {
          "code": <code>,
          "response": {
            "errorMessage": "none",
            "operation": {
              "operation": {
                "operationid": "<operationId>",
                "state": "<state>",
                    I = operación pendiente de ejecutar (acknowledge).
                    Q = la operación está en cola.
                    E = la operación está en ejecución.
                    F = la operación está finalizada.
                "payInProgress": "<payInProgress>",
                "payOutProgress": "<payOutProgress>",
                "total": "<total>",
                "totalin": "<totalin>",
                    Importe total introducido en CashDro. Siempre se indica multiplicado por 100. Por ejemplo, para un importe de 1,05 se indica 105.
                "totalout": "<-totalout>",
                    Importe total dispensado por CashDro. Siempre se indica multiplicado por 100 y en negativo.
                "amountchangenotavailable": "<amountchangenotavailable>"
                    Importe no devuelto al generarse un cambio no disponible.
                …
              },
              "devices": [
                ...
                {
                  "type": "<type>",
                  ...
                  "pieces": [
                    {
                      ...
                      "value": "<value>",
                      ...
                      "finishlevelrecycler": <finishlevelrecycler>,
                      "finishlevelcassette": <finishlevelcassette>,
                      ...
                    },
                    ...
                  ]
                },
                ...
              ],
              "messages": [],
              ...
              "withError": "<withError>",
                True/false. Indica si CashDro está operativo o en estado de error (ej . billete atascado,..).
              ...
            }
          }
        }
        */
    }

    async cancelOperation(operationId) {
        const result = await requestAdHoc('POST', localMessagingServiceUrl, {
            method: 'GET',
            url: this.deviceUrl,
            params: {
                operation: 'finishOperation',
                operationId,
                type: 2,
                name: this.user,
                password: this.pass,
            },
        });

        /*
        {
            "code":<code>,
            "response":{
                "errorMessage":"none"
            }
        }
        */

        return result;
    }

    async finishOperation(operationId, params = null, type = 1) {
        const result = await requestAdHoc('POST', localMessagingServiceUrl, {
            method: 'GET',
            url: this.deviceUrl,
            params: {
                operation: 'finishOperation',
                operationId,
                name: this.user,
                password: this.pass,
                type,
                params: params ? JSON.stringify(params) : '',
            },
        });

        /*
        {
            "code":<code>,
            "response":{
                "errorMessage":"none"
            }
        }
        */

        return result;
    }

    async importOperation(operationId) {
        const result = await requestAdHoc('POST', localMessagingServiceUrl, {
            method: 'GET',
            url: this.deviceUrl,
            params: {
                operation: 'setOperationImported',
                operationId,
                name: this.user,
                password: this.pass,
            },
        });

        /*
        {
            "code":<code>,
            "response":{
                "errorMessage":"none"
            }
        }
        */

        return result;
    }

    async checkCurrencyLevels() {
        const result = await requestAdHoc('POST', localMessagingServiceUrl, {
            method: 'GET',
            url: this.deviceUrl,
            params: {
                operation: 'getPiecesCurrency',
                currencyId: 'EUR',
                includeImages: 0,
                levels: 1,
                name: this.user,
                password: this.pass,
            },
        });

        /*
        {
            "code": 1,
            "response": {
            "errorMessage": "none",
            "operation": {
            "pieces": [
            {
                "CurrencyId": "EUR",
                "Value": "1",
                "Type": "1",
                "Destination": "1",
                "MinLevel": "1",
                "MaxLevel": "0",
                "DepositLevel": "27",
                "MaxPiecesExchange": "0",
                "State": "-1",
                "Image": "",
                "LevelRecycler": "28",
                "LevelCasete": "0",...
            },...
         ]
         }
         }
        }
        */

        return result;
    }

    /**
     * Guardar las piezas (monedas y billetes) actualmente disponibles en los recicladores (los dispositivos que pueden escupir dinero).
     * @returns void
     */
    setAvailablePieces({ monedas = [], billetes = [] }) {
        if (monedas.length > 0) {
            this.availablePieces.monedas = monedas.map((pieza) => {
                const startLevel = parseInt(pieza.startlevelrecycler);
                const finishLevel = parseInt(pieza.finishlevelrecycler);

                let level = finishLevel;

                if (finishLevel > startLevel) {
                    level = finishLevel - startLevel;
                }

                return {
                    level,
                    value: pieza.value,
                };
            });
        }

        if (billetes.length > 0) {
            this.availablePieces.billetes = billetes.map((pieza) => ({
                level: pieza.finishlevelrecycler,
                value: pieza.value,
            }));
        }

        console.log('piezas disponibles:');
        console.log(this.availablePieces);
    }

    /**
     * Devuelve una lista de las monedas/billetes necesarias para emitir un reintegro (sí, hay que especificar).
     * Se devuelven de entre las disponibles, de mayor a menor denominación.
     * @param amount
     * @returns {{monedas: *[], billetes: *[]}}
     */
    calculateNecessaryPieces(amount) {
        const available = {
            monedas: this.availablePieces.monedas.sort(
                (a, b) => parseInt(b.value) - parseInt(a.value),
            ),
            billetes: this.availablePieces.billetes.sort(
                (a, b) => parseInt(b.value) - parseInt(a.value),
            ),
        };

        const result = {
            monedas: [],
            billetes: [],
        };

        let amtRemaining = parseInt(amount);
        let done = false;
        const safety = 100;
        let tries = 0;
        while (amtRemaining > 0 && !done) {
            if (tries >= safety) {
                break;
            }

            // eslint-disable-next-line no-loop-func
            available.billetes.forEach((billete) => {
                if (done) {
                    return;
                }

                const { multiplier, value, remaining } =
                    Cashdro.checkPiecesToSubstract(billete, amtRemaining);

                if (multiplier > 0) {
                    result.billetes.push({
                        level: multiplier,
                        value,
                    });
                }

                amtRemaining = remaining;

                if (amtRemaining === 0) {
                    done = true;
                }
            });

            if (done) {
                break;
            }

            // eslint-disable-next-line no-loop-func
            available.monedas.forEach((moneda) => {
                if (done) {
                    return;
                }

                const { multiplier, value, remaining } =
                    Cashdro.checkPiecesToSubstract(moneda, amtRemaining);

                if (multiplier > 0) {
                    result.monedas.push({
                        level: multiplier,
                        value,
                    });
                }

                amtRemaining = remaining;

                if (remaining === 0) {
                    done = true;
                }
            });
            tries++;
        }

        return result;
    }

    static checkPiecesToSubstract(piece, remainingAmt) {
        // let remaining = remainingAmt;

        let multiplier = 0;
        let value = 0;
        let remaining = parseInt(remainingAmt);

        const pieceValue = parseInt(piece.value);

        if (remaining % pieceValue === 0) {
            // si es múltiplo exacto
            multiplier = remaining / pieceValue;
            remaining -= pieceValue * multiplier;
            value = pieceValue;
        } else if (pieceValue < remaining) {
            // si solo es mayor, pues la restamos y palante
            remaining -= pieceValue;
            multiplier = 1;
            value = pieceValue;
        }

        return { multiplier, value, remaining };
    }

    static validRefundPieces(pieces, amtToCheck) {
        let amount = parseInt(amtToCheck);

        pieces.billetes.forEach((piece) => {
            amount -= piece.value * piece.level;
        });

        pieces.monedas.forEach((piece) => {
            amount -= piece.value * piece.level;
        });

        if (amount === 0) {
            console.log('refund pieces ok');
        }

        return amount === 0;
    }

    static saleOperationSuccessful(operation) {
        let success = false;
        let error = null;

        if (!operation || operation.state !== 'F') {
            return { success, error: OP_ERROR.UNAVAILABLE };
        }

        const total = parseInt(operation.total);
        const totalin = parseInt(operation.totalin);
        const totalout = parseInt(operation.totalout);
        const amountchangenotavailable = parseInt(
            operation.amountchangenotavailable,
        );

        if (
            operation.withError === 'true' ||
            (operation.withError && operation.withError !== 'false')
        ) {
            // error de la maquinaria interna
            error = OP_ERROR.HARDWARE;
        } else if (amountchangenotavailable) {
            // no había cambio
            error = OP_ERROR.NO_CHANGE;
        } else if (totalin === 0 && totalout === 0) {
            // operación cancelada / finalizada a manisqui
            error = OP_ERROR.CANCELLED;
        } else if (total === totalin + totalout) {
            // operación normal con devolución de cambio correcta
            success = true;
        }

        return { success, error };
    }

    async confirmRefund(opId, { monedas = [], billetes = [] }) {
        return this.finishOperation(
            opId,
            {
                /* La estructura de este parámetro de verdad tiene que ser así de cutre */
                Piezas: {
                    pieza: monedas
                        .map((piece) => ({
                            valor: piece.value.toString(),
                            nivel: piece.level.toString(),
                            isBillete: 'false',
                        }))
                        .concat(
                            billetes.map((piece) => ({
                                valor: piece.value.toString(),
                                nivel: piece.level.toString(),
                                isBillete: 'true',
                            })),
                        ),
                },
            },
            constants.cashdro.op.EXCUTE_WITHDRAWAL,
        );
    }
}
