import React from 'react';
import FilterFields from './FilterFields';
import { SttDialog } from '../../sporttia/all';
import { FilterField, FilterOnChangeCallback } from './filterTypes';

type FilterDialogProps = {
    title: string;
    open: boolean;
    onClose: () => void;
    onChange: FilterOnChangeCallback;
    onApply: () => void;
    fields: FilterField[];
};

/**
 * Simple dialog to display a list of filter fields. Uses <FilterFields> for the actual form fields
 */
export default function FilterDialog({
    title,
    open,
    onClose,
    onChange,
    onApply,
    fields,
}: FilterDialogProps) {
    return (
        <SttDialog
            title={title}
            open={open}
            onClose={onClose}
            maxWidth="md"
            content={
                <FilterFields spacing={3} fields={fields} onChange={onChange} />
            }
            buttons={[
                { type: 'accept', onClick: () => onApply() },
                { type: 'close', onClick: () => onClose() },
            ]}
        />
    );
}
