import React, { useContext, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import { AppContext } from '../../../../sporttia/AppContext';
import { SttButtonFab } from '../../../../sporttia/buttons/SttButtonFab';
import AdminCustomer from './AdminCustomer';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';
import { SUPERADMIN_CUSTOMERS } from '../../../../lib/queryKeys';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import useCustomersService from '../../../../services/CustomersService';
import { usePageHeader, useQueryFilters } from '../../../../lib/hooks';

/**
 * View where the sporttia superadmin can see the list of customers.
 * @returns {JSX.Element}
 */
export default function AdminCustomers() {
    usePageHeader('customers');
    const cxt = useContext(AppContext);
    const queryClient = useQueryClient();
    const customersService = useCustomersService();
    const [filters, setFilters] = useQueryFilters({}, SUPERADMIN_CUSTOMERS);
    const [customerId, setCustomerId] = useState(null);

    /**
     * In this case, before making the request (due to Material UI problems),
     * if the value of "subscriptionStatus" or "type" takes the value of "ALL",
     * it must be removed from the parameters sent.
     * @param updatedFilters Object with the parameters returned by the SttTopFilteringControls component.
     */
    const doFiltering = (updatedFilters) => {
        const filteredFilters = { ...updatedFilters };

        if (filteredFilters.subscriptionStatus === 'ALL') {
            delete filteredFilters.subscriptionStatus;
        }
        if (filteredFilters.type === 'ALL') {
            delete filteredFilters.type;
        }

        setFilters(filteredFilters);
    };

    return (
        <Paper>
            <SttTopFilteringControls
                p={2}
                fields={[
                    {
                        name: 'name',
                        type: 'text',
                        value: '',
                        caption: cxt.t('Search'),
                    },
                ]}
                extraFields={[
                    {
                        caption: cxt.t('associatedScName'),
                        name: 'scName',
                        type: 'text',
                        value: '',
                    },
                    {
                        caption: cxt.t('Type'),
                        name: 'type',
                        type: 'select',
                        value: 'ALL',
                        options: [
                            { id: 'ALL', label: cxt.t('All') },
                            { id: 'TOWNHALL', label: cxt.t('CT.TOWNHALL') },
                            { id: 'CLUB', label: cxt.t('CT.CLUB') },
                        ],
                    },
                    {
                        caption: cxt.t('subscriptionType'),
                        name: 'subscriptionStatus',
                        type: 'select',
                        value: 'ALL',
                        options: [
                            { id: 'ALL', label: cxt.t('All') },
                            { id: 'ACTIVE', label: cxt.t('ACTIVE') },
                            { id: 'INACTIVE', label: cxt.t('INACTIVE') },
                        ],
                    },
                ]}
                onFilter={doFiltering}
            />

            <SttCachedTable
                queryKey={SUPERADMIN_CUSTOMERS}
                queryFn={(params) => customersService.getList(params)}
                queryParams={filters}
                prefetching={false}
                onClickRow={(row) => setCustomerId(row.id)}
                columns={[
                    {
                        title: cxt.t('type'),
                        align: 'left',
                        field: 'type',
                        value: (row) => cxt.t(`CT.${row.type}`),
                    },
                    {
                        title: cxt.t('name'),
                        align: 'left',
                        field: 'name',
                    },
                    {
                        title: 'CIF',
                        align: 'left',
                        field: 'cif',
                    },
                    {
                        title: cxt.t('currentLicence'),
                        align: 'left',
                        value: (row) =>
                            row.currentLicence ? (
                                `${moment(row.currentLicence.ini).format(
                                    'D MMM YYYY',
                                )} - ${moment(row.currentLicence.end).format(
                                    'D MMM YYYY',
                                )}`
                            ) : (
                                <b style={{ color: 'red' }}>
                                    {cxt.t('WithoutLicence')}
                                </b>
                            ),
                    },
                    {
                        title: cxt.t('Subscription'),
                        align: 'left',
                        value: (row) =>
                            row.subscription ? (
                                `${moment(row.subscription.ini).format(
                                    'D MMM YYYY',
                                )} - ${moment(row.subscription.end).format(
                                    'D MMM YYYY',
                                )}`
                            ) : (
                                <b style={{ color: 'red' }}>
                                    {cxt.t('WithoutSubscription')}
                                </b>
                            ),
                    },
                    {
                        title: `${cxt.t('price')} ${cxt.t('Subscription')}`,
                        align: 'left',
                        value: (row) =>
                            row.currentLicence ? (
                                <b>{`${row.currentLicence.price ?? 0} ${cxt.t(
                                    'coin',
                                )}`}</b>
                            ) : (
                                ''
                            ),
                    },
                ]}
            />

            <AdminCustomer
                id={customerId}
                onClose={() => {
                    setCustomerId(null);
                    queryClient.invalidateQueries(SUPERADMIN_CUSTOMERS);
                }}
            />

            <SttButtonFab onClick={() => setCustomerId('create')} />
        </Paper>
    );
}
