import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { useTranslations } from '../../lib/hooks';

type SttButtonNewProps = {
    caption?: string;
    onClick: () => void;
} & ButtonProps;

/**
 * Add button
 */
export function SttButtonNew({ caption, onClick, ...rest }: SttButtonNewProps) {
    const { translate } = useTranslations();

    return (
        <Button
            variant="contained"
            onClick={onClick}
            startIcon={<AddIcon />}
            color="primary"
            {...rest}
        >
            {caption || translate('Add')}
        </Button>
    );
}
