import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ReplyIcon from '@material-ui/icons/Reply';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import { SttFormTextarea } from '../../../sporttia/forms/SttFormTextarea';
import SCWallCommentsList from './SCWallCommentsList';
import { SttNoResults } from '../../../sporttia/SttNoResults';
import { SttLabelDate } from '../../../sporttia/labels/SttLabelDate';
import { AppContext } from '../../../sporttia/AppContext';

/**
 * Sport Center Wall component. Visualize sport center posts.
 * @param scId integer Sport Center ID.
 * @returns {JSX.Element} JSX with component.
 */
export default function SCWall({ scId }) {
    const cxt = useContext(AppContext);
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(10);
    const [posts, setPosts] = useState({});
    const [showingCommentsMsg, setShowingCommentsMsg] = useState();
    const [text, setText] = useState('');

    /**
     * Post send button.
     * @returns {JSX.Element} JSX Element with SendButton component.
     * @constructor
     */
    function SendButton() {
        return (
            <IconButton
                onClick={() => addPost(text)}
                style={{ color: '#495e0b' }}
            >
                <Typography variant="button">{cxt.t('Send')}&nbsp;</Typography>

                <SendIcon style={{ fontSize: 20 }} />
            </IconButton>
        );
    }

    /**
     * Load posts.
     */
    const loadPosts = () => {
        cxt.api('GET', `/scs/${scId}/posts`, {
            params: {
                page,
                rows,
            },
            success: (r) => {
                if (page === 1) {
                    setPosts(r);
                } else {
                    setPosts({
                        ...posts,
                        rows: [...posts.rows, ...r.rows],
                    });
                }
            },
        });
    };

    /**
     * Add new post to sport center wall.
     * @param text String with text that will be send.
     */
    const addPost = (text) => {
        cxt.api('POST', `/scs/${scId}/posts`, {
            params: {
                text,
            },
            success: () => {
                loadPosts();
                setText('');
                cxt.showMessage('S', cxt.t('PostSent'));
            },
        });
    };

    /**
     * Delete Post
     */
    const deletePost = (postId) => {
        cxt.api('DELETE', `/posts/${postId}`, {
            confirmation: true,
            success: (r) => {
                cxt.showMessage('S', 'Deleted');
                loadPosts();
            },
        });
    };

    /**
     * Get post title.
     * @param post Object Post.
     * @returns {string|ts.JSDocNamespaceDeclaration|ts.Identifier|string|*} String with Post title.
     */
    const buildPostTitle = (post) =>
        post.sender && post.sender.fullName
            ? post.sender.fullName
            : post.sender.name;

    /**
     * If page value is greater than 1 we load posts.
     */
    useEffect(() => {
        if (page > 1) loadPosts();
    }, [page]);

    /**
     * If 'scId' was changed we load posts.
     */
    useEffect(() => {
        if (scId) loadPosts();
    }, [scId]);

    // -----| Render |-----
    return (
        <>
            {cxt.logged && cxt.sc && cxt.sc.id === parseInt(scId) && (
                <Box mb={3}>
                    <SttFormTextarea
                        grid
                        autoFocus
                        rows={2}
                        defVal={text}
                        caption={cxt.t('WriteOnWall')}
                        onChange={({ value }) => setText(value)}
                        InputProps={{ endAdornment: <SendButton /> }}
                    />
                </Box>
            )}

            <List>
                {posts &&
                    posts.rows &&
                    posts.rows.map((post, idxpost) => (
                        <Fragment key={idxpost}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    {post.img && post.img.id ? (
                                        <Avatar
                                            alt={post.sender.fullName}
                                            src={cxt.getResource(
                                                `/images/${post.img.id}.jpg`,
                                            )}
                                        />
                                    ) : (
                                        <Avatar
                                            alt={post.sender.fullName}
                                            src="/static/images/avatar/1.jpg"
                                        />
                                    )}
                                </ListItemAvatar>

                                <ListItemText
                                    primary={buildPostTitle(post)}
                                    secondary={
                                        <Typography
                                            variant="body2"
                                            color="textPrimary"
                                        >
                                            <SttLabelDate date={post.created} />
                                        </Typography>
                                    }
                                />
                            </ListItem>

                            <Box ml={9}>
                                <Box
                                    my={2}
                                    pl={2}
                                    style={{
                                        borderLeft: 2,
                                        borderLeftStyle: 'solid',
                                        borderColor: '#3e3939',
                                    }}
                                >
                                    <Typography variant="body1">
                                        {post.text}
                                    </Typography>
                                </Box>

                                <Box mb={2}>
                                    <Grid container spacing={3}>
                                        <Grid item md={2}>
                                            {post.comments &&
                                                post.comments.count > 0 && (
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Box
                                                            style={{
                                                                flex: 'left',
                                                            }}
                                                        >
                                                            <Button
                                                                size="small"
                                                                startIcon={
                                                                    <ChatBubbleOutlineIcon />
                                                                }
                                                                onClick={() =>
                                                                    setShowingCommentsMsg(
                                                                        showingCommentsMsg
                                                                            ? null
                                                                            : post,
                                                                    )
                                                                }
                                                            >
                                                                {
                                                                    post
                                                                        .comments
                                                                        .count
                                                                }{' '}
                                                                {cxt.t(
                                                                    'comments',
                                                                )}
                                                            </Button>
                                                        </Box>
                                                        {cxt.logged &&
                                                            cxt.user.id ===
                                                                post.sender
                                                                    .id && (
                                                                <Box
                                                                    style={{
                                                                        flex: 'left',
                                                                    }}
                                                                >
                                                                    <Button
                                                                        size="small"
                                                                        startIcon={
                                                                            <DeleteIcon />
                                                                        }
                                                                        onClick={() =>
                                                                            deletePost(
                                                                                post.id,
                                                                            )
                                                                        }
                                                                    >
                                                                        {cxt.t(
                                                                            'Delete',
                                                                        )}
                                                                    </Button>
                                                                </Box>
                                                            )}
                                                    </Box>
                                                )}

                                            {cxt.logged &&
                                                post.comments.count === 0 && (
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Box
                                                            style={{
                                                                flex: 'left',
                                                            }}
                                                        >
                                                            <Button
                                                                size="small"
                                                                startIcon={
                                                                    <ReplyIcon />
                                                                }
                                                                onClick={() =>
                                                                    setShowingCommentsMsg(
                                                                        post,
                                                                    )
                                                                }
                                                            >
                                                                {cxt.t(
                                                                    'Comment',
                                                                )}
                                                            </Button>
                                                        </Box>
                                                        {(cxt.user.id ===
                                                            post.sender.id ||
                                                            (cxt.user.role ===
                                                                'SPORTCENTER' &&
                                                                scId.toString() ===
                                                                    cxt.sc.id.toString())) && (
                                                            <Box
                                                                style={{
                                                                    flex: 'left',
                                                                }}
                                                            >
                                                                <Button
                                                                    size="small"
                                                                    startIcon={
                                                                        <DeleteIcon />
                                                                    }
                                                                    onClick={() =>
                                                                        deletePost(
                                                                            post.id,
                                                                        )
                                                                    }
                                                                >
                                                                    {cxt.t(
                                                                        'Delete',
                                                                    )}
                                                                </Button>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                )}
                                        </Grid>
                                    </Grid>
                                </Box>

                                {showingCommentsMsg &&
                                    showingCommentsMsg.id === post.id && (
                                        <SCWallCommentsList
                                            post={post}
                                            scId={scId}
                                        />
                                    )}
                            </Box>

                            {idxpost !== posts.rows.length - 1 && (
                                <Divider variant="inset" component="li" />
                            )}
                        </Fragment>
                    ))}

                {posts && posts.count === 0 && <SttNoResults />}
            </List>

            {posts && posts.rows && posts.count > posts.rows.length && (
                <Button fullWidth onClick={() => setPage(page + 1)}>
                    {cxt.t('ShowMoreResults')}
                </Button>
            )}
        </>
    );
}
