import React from 'react';
import { usePageHeader } from '../../../../lib/hooks';
import Report from '../../../sc/Report';

/**
 * View where the sporttia superadmin can see the list of activities enrrollment increases per year.
 * @returns {JSX.Element}
 */
function AdminActivitiesEnrrollmentIncreasesPerYear({}) {
    usePageHeader('increasesInEnrollmentInActivitiesPerYear');

    return <Report id={33} navigateBack="/home" />;
}

export default AdminActivitiesEnrrollmentIncreasesPerYear;
