import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import moment from 'moment';
import { formatPriceByLocale } from '../../lib/utils';

/**
 * Timetable piece
 * ===============
 */
export default function SttTimetablePiece({
    column,
    piece,
    onOpenPiece, // onOpenPiece(column, piece)
}) {
    const [capacityClass, setCapacityClass] = useState('');

    /**
     * Set capacity class
     */
    useEffect(() => {
        if (piece.capacity) {
            if (piece.capacity.free === 0) {
                setCapacityClass('full');
            } else if (piece.capacity.free < piece.capacity.total) {
                setCapacityClass('taken');
            } else {
                setCapacityClass('');
            }
        }
    }, [piece]);

    // Render
    return (
        <Box
            className={`piece ${piece.mark}`}
            style={{
                height: Math.max(piece.duration * 2, 30),
            }} // Incidencia piezas de disinto tamaño
            onClick={() => onOpenPiece(column, piece)}
        >
            <Box className="time">{moment(piece.ini).format('HH:mm')}</Box>

            {piece?.capacity?.total === 1 && piece?.capacity.free === 0 && (
                <Box className="time">{piece?.bookings[0]?.name}</Box>
            )}
            {/* --- Porcentage payment --- */}
            {piece.mark === 'RENT' && !piece.capacity && (
                <div className="paymentBar">
                    <div style={{ width: `${piece.percentagePaid}%` }} />
                </div>
            )}

            {/* ImUser */}
            {piece === 'RENT' && !piece.capacity && (
                <Box className="paymentBar">
                    <Box style={{ width: piece.percentagePaid }} />
                </Box>
            )}

            <Box className="concept">{piece?.title}</Box>

            {/* --- Price --- */}
            {piece.mark === 'FREE' && piece?.price && piece?.price !== 0 && (
                <Box className="price">
                    {formatPriceByLocale(
                        piece?.price,
                        piece?.priceShape?.currency,
                        piece?.priceShape?.locale,
                    )}
                </Box>
            )}

            {/* --- Capacity --- */}
            {piece.capacity && (
                <Box className={`capacity ${capacityClass}`}>
                    {piece.capacity.free} / {piece.capacity.total}
                </Box>
            )}

            {/* --- Flags --- */}
            <Box className="flags">
                {piece.fixed && piece.mark === 'RENT' && (
                    <Box className="fixed">F</Box>
                )}
                {piece.notes && <Box className="notes">N</Box>}
            </Box>
        </Box>
    );
}
