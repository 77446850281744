import React, { useState, useContext } from 'react';
import {} from '@material-ui/core';
import { AppContext, SttDialog, SttList } from '../../../sporttia/all';
import BookingDialog from '../../../components/dialogs/bookingDialog/BookingDialog';

export default function TimetableBookingsDialog({
    open = false,
    bookings,
    onClose,
    cutBooking = null,
}) {
    const cxt = useContext(AppContext);
    const [openBookingId, setOpenBookingId] = useState(null);

    // -----| Render |-----
    return (
        <>
            <SttDialog
                title={cxt.t('Bookings')}
                open={open}
                onClose={onClose}
                content={
                    <SttList
                        paper
                        numRows={10}
                        onSelect={(booking) => setOpenBookingId(booking.id)}
                        data={bookings.map((booking) => ({
                            caption: `${booking.name} [ ${booking.occupants.count} ]`,
                            objToSelect: booking,
                        }))}
                    />
                }
                buttons={[
                    {
                        type: 'close',
                        onClick: onClose,
                    },
                ]}
            />

            {/* --- Booking dialog --- */}
            <BookingDialog
                idBooking={openBookingId}
                onClose={() => setOpenBookingId(null)}
                onUpdate={() => {
                    onClose();
                }}
                cutBooking={cutBooking}
            />
        </>
    );
}
