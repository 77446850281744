import React from 'react';
import { Avatar, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../images/logo512.png';

const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: theme.spacing(1),
        width: 48,
        height: 48,
    },
    image: {
        width: 48,
        height: 48,
    },
}));

type LogoHeadingProps = {
    caption: string;
};

export default function LogoHeading({ caption }: LogoHeadingProps) {
    const classes = useStyles();

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar className={classes.avatar}>
                <img className={classes.image} src={logo} alt="Sporttia logo" />
            </Avatar>
            <Typography variant="h5">{caption}</Typography>
        </Box>
    );
}
