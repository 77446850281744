import React, { useEffect, useContext, useState } from 'react';
import { Grid, Box, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import {
    AppContext,
    SttButton,
    SttDialog,
    SttFormCheck,
    SttFormSelect,
    SttFormText,
} from '../../sporttia/all';
import { useCrud } from '../../lib/hooks';

const types = ['STRING', 'NUMERIC', 'DATE', 'SELECT', 'CHECK'];

/**
 * FormQuestionDialog: Diálogo para añadir o editar preguntas de formulario
 * string parentCategory: 'activities', 'events', etc.
 * int idParent: id de la actividad, el evento, etc.
 * Object item: la pregunta (objeto existente o vacío para nuevas preguntas)
 * Function onSave
 * Function onClose
 */
export default function FormQuestionDialog({
    parentCategory,
    idParent,
    item,
    onSave,
    onDelete,
    onClose,
}) {
    const [question, setQuestion, setProperty, , Post, Put, Delete] = useCrud();
    const [existingOptions, setExistingOptions] = useState();

    useEffect(() => {
        setQuestion(item);
        setExistingOptions(
            item?.options?.map((option) => ({
                name: option.name,
                value: option.value,
            })),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    const cxt = useContext(AppContext);

    function addOption() {
        const options = question.options || [];
        setQuestion({
            ...question,
            options: options.concat([{ name: '', value: '' }]),
        });
    }

    function removeOption(option) {
        const { options } = question;

        options.splice(options.indexOf(option), 1);
        setQuestion({ ...question, options });
    }

    function setOption(option, name, value) {
        const { options } = question;
        // eslint-disable-next-line no-param-reassign
        option[name] = value;

        options.splice(options.indexOf(option), 1, option);

        setQuestion({ ...question, options });
    }

    // Create/save question depending on parent (activity, event, etc) and parent id
    function saveQuestion() {
        const params = {
            name: question.name,
            type: question.type,
            mandatory: !!question.mandatory,
            options:
                question.type === 'SELECT'
                    ? question.options.map((option) => ({
                          name: option.name,
                          value: option.value,
                      }))
                    : null,
        };

        // Save existing question
        if (question.id) {
            Put(`/questions/${question.id}`, params)
                .then(({ question }) => {
                    question && onSave && onSave(question);
                    onClose();
                })
                .catch(console.log);
        } else {
            // Create new question, calling apis like /events/{id}/questions
            Post(`/${parentCategory}/${idParent}/questions`, params)
                .then(({ question }) => {
                    question && onSave && onSave(question);
                    onClose();
                })
                .catch(console.log);
        }
    }

    function deleteQuestion() {
        Delete(`/questions/${question.id}`)
            .then(() => {
                if (onDelete) onDelete(question);
                onClose();
            })
            .catch();
    }

    if (!question) {
        return null;
    }

    return (
        <SttDialog
            maxWidth="sm"
            open={item !== null}
            title={cxt.t('Form')}
            onClose={onClose}
            buttons={[
                {
                    type: 'save',
                    onClick: saveQuestion,
                },
                question.id && {
                    type: 'delete',
                    onClick: deleteQuestion,
                },
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
            content={
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SttFormText
                            caption={cxt.t('Question')}
                            name="name"
                            defVal={question.name}
                            onChange={setProperty}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SttFormSelect
                            caption={cxt.t('Type')}
                            name="type"
                            options={types.map((option) => ({
                                caption: cxt.t(`PT.${option}`),
                                value: option,
                            }))}
                            defVal={question.type}
                            onChange={setProperty}
                        />
                    </Grid>
                    {question.type === 'SELECT' && (
                        <Grid item xs={12}>
                            <Box pl={1} pr={1}>
                                {question.options &&
                                    // eslint-disable-next-line no-shadow
                                    question.options.map((item, i) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <Box key={i} mb={2}>
                                            <Grid container spacing={0}>
                                                <Grid item xs={11}>
                                                    <Grid container spacing={2}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}
                                                        >
                                                            <SttFormText
                                                                name="name"
                                                                caption={cxt.t(
                                                                    'Option',
                                                                )}
                                                                defVal={
                                                                    item.name
                                                                }
                                                                onChange={({
                                                                    name,
                                                                    value,
                                                                }) =>
                                                                    setOption(
                                                                        item,
                                                                        name,
                                                                        value,
                                                                    )
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}
                                                        >
                                                            <SttFormText
                                                                name="value"
                                                                caption={cxt.t(
                                                                    'Value',
                                                                )}
                                                                defVal={
                                                                    item.value
                                                                }
                                                                onChange={({
                                                                    name,
                                                                    value,
                                                                }) =>
                                                                    setOption(
                                                                        item,
                                                                        name,
                                                                        value,
                                                                    )
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <IconButton
                                                        style={{
                                                            width: 40,
                                                            height: 40,
                                                        }}
                                                        onClick={() =>
                                                            removeOption(item)
                                                        }
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    ))}
                                <SttButton
                                    variant="contained"
                                    caption={cxt.t('AddOption')}
                                    onClick={addOption}
                                />
                            </Box>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <SttFormCheck
                            name="mandatory"
                            caption={cxt.t('Mandatory')}
                            checked={!!question.mandatory}
                            onChange={({ name, value }) =>
                                setProperty({ name, value: +value })
                            }
                        />
                    </Grid>
                </Grid>
            }
        />
    );
}
