import React, { useState } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Box } from '@material-ui/core';
import { useMutation } from 'react-query';
import { useTranslations } from '../../../lib/hooks';
import { SttButton } from '../../../sporttia/buttons/SttButton';
import useMeService from '../../../services/MeService';

export default function UserConnections({ userData }) {
    const { translate } = useTranslations();
    const [user, setUser] = useState(userData);
    const meService = useMeService();
    const updateMutation = useMutation(
        (params) => meService.updateMe(params?.params),
        {
            onSuccess: (data) => {
                setUser(data?.user);
            },
        },
    );

    return (
        <>
            {user?.paycometIdUser ? (
                <Alert severity="info">
                    <AlertTitle>{translate('connectdWithPaycomet')}</AlertTitle>
                    {translate('paycometConnectionAlert')}
                    <Box marginTop={2}>
                        <SttButton
                            caption={translate('disableConnection')}
                            variant="outlined"
                            color="primary"
                            confirmation
                            onClick={() =>
                                updateMutation.mutate({
                                    params: { paycometIdUser: '' },
                                })
                            }
                        />
                    </Box>
                </Alert>
            ) : (
                <Alert severity="warning">
                    <AlertTitle>{translate('noConnections')}</AlertTitle>
                    {translate('noConnectionsAlert')}
                </Alert>
            )}
        </>
    );
}
