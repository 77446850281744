import React, { useState, useEffect, useContext } from 'react';
import { Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
    AppContext,
    SttButtonFab,
    SttTable,
    SttTopControls,
    SttChipBono,
} from '../../../sporttia/all';
import { useToggle, useTuples, useCrud } from '../../../lib/hooks';
import Bono from './Bono';

export default function Bonos() {
    const cxt = useContext(AppContext);
    const history = useHistory();

    // Use Crud hook
    const [bonos, setBonos, , Get] = useCrud();
    const [bono, setBono] = useState({
        id: null,
        trash: false,
    });

    // Use tuples hook
    const [filters, setFilter] = useTuples({
        name: '',
    });

    // Use trash hook
    const [trash, toggleTrash] = useToggle(() => {
        loadBonos();
    });

    const [onTime, toggleOnTime] = useToggle(() => {
        loadBonos();
    }, true);

    useEffect(() => {
        cxt.setHeader({ title: cxt.t('Bonos') });
    }, []);

    function loadBonos(params = { page: 1, rows: 20 }) {
        Get(`/scs/${cxt.sc.id}/bonos`, {
            ...params,
            ...filters,
            trash,
            onTime,
        }).then(setBonos);
    }

    function goCreateNew() {
        setBono({ id: 'create', trash: false });
    }

    return (
        <Paper>
            <SttTopControls
                p={2}
                fields={[
                    {
                        caption: cxt.t('Name'),
                        type: 'search',
                        name: 'name',
                        value: filters.name,
                        onEnterKey: loadBonos,
                    },
                    {
                        caption: cxt.t('Type'),
                        type: 'select',
                        name: 'onTime',
                        value: (+onTime).toString(),
                        options: [
                            {
                                caption: cxt.t('All'),
                                value: '0',
                            },
                            {
                                caption: cxt.t('InForce'),
                                value: '1',
                            },
                        ],
                        onChange: ({ name, value }) =>
                            toggleOnTime(!!parseInt(value)),
                    },
                ]}
                onToggleTrash={toggleTrash}
                onChange={setFilter}
                onFilter={loadBonos}
            />
            <SttTable
                onFetch={loadBonos}
                data={bonos}
                columns={[
                    {
                        title: cxt.t('Name'),
                        align: 'left',
                        type: 'link',
                        field: 'name',
                        onClick: (row) =>
                            setBono({ id: row.id, trash: row.trash }),
                        params: (row) => ({ trash: +row.trash }),
                    },
                    {
                        title: cxt.t('Type'),
                        type: 'text',
                        value: (row) => <SttChipBono type={row.type} />,
                    },
                    {
                        title: cxt.t('Period'),
                        type: 'period',
                        value: (row) => ({
                            ini: row.dateini,
                            end: row.dateend,
                        }),
                    },
                    {
                        title: cxt.t('Week'),
                        width: 150,
                        type: 'weekdays',
                        field: 'weekdays',
                    },
                    {
                        title: cxt.t('hours'),
                        type: 'text',
                        value: (row) => `${row.timeini} - ${row.timeend}`,
                    },
                    {
                        title: cxt.t('Uses'),
                        type: 'text',
                        value: (row) => `${row.amount} x ${row.duration}m`,
                    },
                    {
                        title: cxt.t('Expiration'),
                        type: 'text',
                        value: (row) =>
                            row.expirationLang
                                ? row.expirationLang
                                : cxt.t('0000-00-00 00:00:00'),
                    },
                    {
                        title: `${cxt.t('Sold')} / ${cxt.t('Active')}`,
                        type: 'text',
                        value: (row) => `${row.numSold} / ${row.numActive}`,
                    },
                    {
                        title: cxt.t('Consumed'),
                        type: 'text',
                        value: (row) =>
                            row.numConsumptions < 0
                                ? -row.numConsumptions
                                : row.numConsumptions,
                    },
                    {
                        align: 'right',
                        title: cxt.t('Price'),
                        type: 'price',
                        field: 'price',
                    },
                    {
                        align: 'right',
                        title: cxt.t('Income'),
                        type: 'price',
                        field: 'totalIncome',
                    },
                    {
                        title: cxt.t('Transferable'),
                        type: 'active',
                        field: 'transferable',
                    },
                ]}
            />
            <SttButtonFab onClick={goCreateNew} />

            <Bono
                id={bono.id}
                trash={bono.trash}
                // Apply changes locally to avoid subsequent petitions (once change is applied, remove items marked with trash)
                onChange={(newBono) => {
                    setBonos({
                        ...bonos,
                        rows: bonos.rows
                            .map((bono) =>
                                bono.id === newBono.id ? newBono : bono,
                            )
                            .filter(
                                (bono) => !!trash || (!trash && !bono.trash),
                            ),
                    });
                }}
                onClose={() => {
                    setBono({ id: null, trash: false });
                    loadBonos();
                }}
            />
        </Paper>
    );
}
