import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography, Chip } from '@material-ui/core';
import {
    AppContext,
    SttDialog,
    SttFormPeriod,
    SttFormTime,
    SttSelectWeekdays,
} from '../../sporttia/all';
import { SttSelectableChips } from '../../sporttia/SttSelectableChips';
import { showErrors } from '../../lib/utils';

/**
 * DeviceAuthDialog : Dialog to add device authorizations to groups, ativities, whatever
 * Depending on the <item> spec, the API will respond differently, for ex: idGroup to authorize a group, idEvent for an event, etc.
 * bool open - visibility of the dialog
 * Object item - auth spec to display in the form {[optional]id, dateini, dateend, timeini, timeend, weekdays, [optional]devices}
 * Function onSave - save callback (also called after deletion)
 * Function onClose - close callback
 */
export default function DeviceAuthDialog({ open, item, onSave, onClose }) {
    const [auth, setAuth] = useState(item); // the authorization object we're dealing with (if it doesn't contain an id, we're creating one)
    const [devices, setDevices] = useState([]); // available devices for the sports center
    const [deviceIds, setDeviceIds] = useState([]); // device ids associated with the auth object. These are separate since we need additional API calls

    // Only for already existing device auths: we use this to keep track of changes, since those must be sent individually to the API
    const [existingDeviceIds, setExistingDeviceIds] = useState([]);
    const [deviceIdsHaveChanged, setDeviceIdsHaveChanged] = useState(false);

    const cxt = useContext(AppContext);

    // Load devices from API
    function loadDevices() {
        cxt.api('GET', `/scs/${cxt.sc.id}/devices`, {
            params: {},
            success: (result) => {
                if (result.rows) {
                    setDevices(result.rows);

                    if (auth.id) {
                        const ids = auth.devices?.map((device) => device.id);
                        if (ids) {
                            setDeviceIds(ids);
                            setExistingDeviceIds(ids);
                        }
                    }
                }
            },
        });
    }

    // Form fields changed
    function handleChange({ name, value }) {
        setAuth({ ...auth, [name]: value });
    }

    // Add device, returns a promise for async functions' await mechanics
    function addDevice(authId, deviceId) {
        return new Promise((resolve, reject) => {
            cxt.api('POST', `/devices/auths/${authId}/devices/${deviceId}`, {
                success: (response) => {
                    if (response.deviceAuth) {
                        resolve();
                    } else {
                        reject(response);
                    }
                },
                error: reject,
            });
        });
    }

    // Remove device, returns a promise for async functions' await mechanics
    function removeDevice(authId, deviceId) {
        return new Promise((resolve, reject) => {
            cxt.api('DELETE', `/devices/auths/${authId}/devices/${deviceId}`, {
                success: (response) => {
                    if (response.deviceAuth) {
                        resolve();
                    } else {
                        reject(response);
                    }
                },
                error: reject,
            });
        });
    }

    // Synchronously (weird, I know) add the associated devices with this auth
    async function saveDevices(authId, ids) {
        if (ids.length === 0) {
            return;
        }

        let index = 0;
        while (ids[index]) {
            // We wait for each response because the API can fail if we send them too fast
            // eslint-disable-next-line no-await-in-loop
            await addDevice(authId, ids[index]);
            index++;
        }
    }

    // Synchronously remove devices from this auth
    async function deleteDevices(ids) {
        if (ids.length === 0) {
            return;
        }

        let index = 0;
        while (ids[index]) {
            // We wait for each response because the API can fail if we send them too fast
            // eslint-disable-next-line no-await-in-loop
            await removeDevice(auth.id, ids[index]);
            index++;
        }
    }

    // Convenience function to call the save and close callbacks at once
    function saveAndClose() {
        if (typeof onSave === 'function') {
            onSave();
        }

        if (typeof onClose === 'function') {
            onClose();
        }
    }

    // Create new authorization. Execute additional API calls afterwards.
    function createAuth() {
        cxt.api('POST', `/devices/auths`, {
            params: {
                ...auth,
            },
            success: (response) => {
                if (response.deviceAuth) {
                    saveDevices(response.deviceAuth.id, deviceIds).then(() => {
                        saveAndClose();
                    });
                }
            },
        });
    }

    // Update existing authorization. Afterwards, check device ids that must be added and/or deleted and execute the corresponding API calls.
    function updateAuth() {
        cxt.api('PUT', `/devices/auths/${auth.id}`, {
            params: {
                dateini: auth.dateini,
                dateend: auth.dateend,
                timeini: auth.timeini,
                timeend: auth.timeend,
                weekdays: auth.weekdays,
            },
            success: (response) => {
                if (response.deviceAuth) {
                    // Handled in state
                    if (deviceIdsHaveChanged) {
                        // Devices we had but not anymore must be removed
                        const devicesToRemove = existingDeviceIds.filter(
                            (id) => !deviceIds.includes(id),
                        );

                        // Devices we didn't have but now do, must be added
                        const devicesToAdd = deviceIds.filter(
                            (id) => !existingDeviceIds.includes(id),
                        );

                        // All this is synchronous
                        saveDevices(auth.id, devicesToAdd).then(() => {
                            deleteDevices(devicesToRemove).then(() => {
                                saveAndClose();
                            });
                        });
                    } else {
                        saveAndClose();
                    }
                }
            },
            error: (errorWrapper) => {
                showErrors(errorWrapper.error, cxt.showMessage);
            },
        });
    }

    // create or update depending on whether we have an id or not
    function save() {
        if (auth.id) {
            updateAuth();
        } else {
            createAuth();
        }
    }

    // Delete this auth (we don't care about associated devices because backend)
    function deleteAuth() {
        if (!auth.id) {
            return false;
        }

        cxt.api('DELETE', `/devices/auths/${auth.id}`, {
            confirmation: true,
            success: (result) => {
                if (result.deviceAuth) {
                    saveAndClose();
                }
            },
        });
    }

    const buttons = [
        {
            type: 'save',
            onClick: save,
        },
    ];

    if (auth.id) {
        buttons.push({
            type: 'delete',
            onClick: deleteAuth,
        });
    }

    // Load available devices
    useEffect(() => {
        loadDevices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // If devices have changed, check if the current list is different than the old one (existingdeviceIds)
    useEffect(() => {
        const haveChanged =
            existingDeviceIds.sort().join(',') !== deviceIds.sort().join(',');
        setDeviceIdsHaveChanged(haveChanged);
    }, [deviceIds, existingDeviceIds]);

    return (
        <SttDialog
            title={cxt.t('Horary')}
            open={open}
            onClose={onClose}
            content={
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SttFormPeriod
                            nameIni="dateini"
                            nameEnd="dateend"
                            defValIni={auth.dateini}
                            defValEnd={auth.dateend}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SttFormTime
                            min="00:00:00"
                            name="timeini"
                            caption={cxt.t('sc.class.schedules.hourInit')}
                            defVal={auth.timeini}
                            interval={15}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SttFormTime
                            min="00:00:00"
                            name="timeend"
                            caption={cxt.t('sc.class.schedules.hourEnd')}
                            defVal={auth.timeend}
                            interval={15}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box mb={1}>
                            <Typography variant="body1">
                                {cxt.t('Weekdays')}:
                            </Typography>
                        </Box>
                        <SttSelectWeekdays
                            name="weekdays"
                            selected={auth.weekdays}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            {cxt.t('DevicesToAuth')}:
                        </Typography>
                        <SttSelectableChips
                            size="medium"
                            ChipComponent={Chip}
                            name="deviceIds"
                            items={devices.map((device) => ({
                                label: device.name,
                                value: device.id,
                            }))}
                            selected={[...deviceIds]}
                            onChange={({ value }) => setDeviceIds(value)}
                        />
                    </Grid>
                </Grid>
            }
            buttons={buttons}
        />
    );
}
