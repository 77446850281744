import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Grid } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { AppContext } from '../all';

/**
 * Form Date
 *
 * Props
 *
 * 	* string defVal: cadena en formato ISO (2010-01-01T10:20:20).
 * 	* function onClick({name, value}): el valor es en formato ISO.
 */
export function SttFormDate({
    grid = false,
    caption,
    onChange,
    defVal,
    name,
    disabled,
    ...rest
}) {
    const cxt = useContext(AppContext);
    const [open, setOpen] = useState(false);

    const _onChange = (date) => {
        if (date)
            onChange({
                name,
                value: moment(date).format('YYYY-MM-DDTHH:mm:SS'),
            });
        else onChange({ name, value: null });
    };

    const content = (
        <KeyboardDatePicker
            clearable
            autoOk
            style={{ width: '100%' }}
            inputVariant="outlined"
            size="small"
            label={caption}
            value={defVal ? moment(defVal).toDate() : null}
            // placeholder="10/10/2018"
            onChange={_onChange}
            /* minDate={new Date()} */
            format="DD/MM/YYYY"
            disabled={disabled}
            cancelLabel={cxt.t('Cancel')}
            clearLabel={cxt.t('Clear')}
            onClick={() => !disabled && setOpen(true)}
            onClose={() => !disabled && setOpen(false)}
            open={open}
        />
    );

    // Wrapped
    return grid ? (
        <Grid item {...rest}>
            {content}
        </Grid>
    ) : (
        <>{content}</>
    );
}
