import React, { useState, useContext } from 'react';
import { Paper } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import { AppContext } from '../../../../sporttia/AppContext';
import { usePageHeader, useQueryFilters } from '../../../../lib/hooks';
import useTpvsService from '../../../../services/TpvsService';
import { SUPERADMIN_TPV_CONFIGURATIONS } from '../../../../lib/queryKeys';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';
import { SttButtonFab } from '../../../../sporttia/buttons/SttButtonFab';
import AdminTPVConf from './AdminTPVConf';
import translations from '../../../../translations';

/**
 * View where the sporttia superadmin can see the list of tpv configurations.
 * @returns {JSX.Element}
 */
export default function AdminTPVConfs() {
    usePageHeader('tpvConfigurations');

    const cxt = useContext(AppContext);

    const queryClient = useQueryClient();
    const tpvsService = useTpvsService();
    const [filters, setFilters] = useQueryFilters(
        {},
        SUPERADMIN_TPV_CONFIGURATIONS,
    );

    const [tpvConfId, setTpvConfId] = useState(null);

    /**
     * In this case, before making the request (due to Material UI problems), if the value of "bank"
     * takes the value of "ALL", it must be removed from the parameters sent.
     * @param updatedFilters Object with the parameters returned by the SttTopFilteringControls component.
     */
    const doFiltering = (updatedFilters) => {
        const newFilters = { ...updatedFilters }; // Create a new object using the spread syntax

        if (newFilters.bank === 'ALL') {
            delete newFilters.bank;
        }

        setFilters(newFilters);
    };

    return (
        <Paper>
            <SttTopFilteringControls
                p={2}
                fields={[
                    {
                        type: 'search',
                        caption: cxt.t(translations.generic.name),
                        name: 'name',
                        value: '',
                    },
                    {
                        caption: cxt.t(translations.generic.bank),
                        type: 'select',
                        name: 'bank',
                        value: 'ALL',
                        options: [
                            {
                                caption: cxt.t(translations.generic.all),
                                value: 'ALL',
                            },
                            {
                                caption: 'REDSYS_HMAC256',
                                value: 'REDSYS_HMAC256',
                            },
                            {
                                caption: 'CCM',
                                value: 'CCM',
                            },
                            {
                                caption: 'STRIPE',
                                value: 'STRIPE',
                            },
                            {
                                caption: 'PAYCOMET',
                                value: 'PAYCOMET',
                            },
                            {
                                caption: 'ZITYCARD',
                                value: 'ZITYCARD',
                            },
                        ],
                    },
                ]}
                onFilter={doFiltering}
            />

            <SttCachedTable
                queryKey={SUPERADMIN_TPV_CONFIGURATIONS}
                queryFn={(params) => tpvsService.getTpvsConfigurations(params)}
                queryParams={filters}
                onClickRow={(row) => setTpvConfId(row?.id)}
                columns={[
                    {
                        title: cxt.t(translations.generic.bank),
                        field: 'bank',
                        width: 300,
                    },
                    {
                        title: cxt.t(translations.generic.name),
                        type: 'link',
                        field: 'name',
                        align: 'left',
                        width: 300,
                    },
                    {
                        title: cxt.t(translations.generic.mode),
                        field: 'environment',
                        width: 300,
                    },
                ]}
            />

            <SttButtonFab onClick={() => setTpvConfId('create')} />

            {tpvConfId && (
                <AdminTPVConf
                    id={tpvConfId}
                    onClose={() => setTpvConfId(null)}
                    onCreate={() =>
                        queryClient.invalidateQueries(
                            SUPERADMIN_TPV_CONFIGURATIONS,
                        )
                    }
                    onUpdate={() =>
                        queryClient.invalidateQueries(
                            SUPERADMIN_TPV_CONFIGURATIONS,
                        )
                    }
                    onDelete={() => {
                        setTpvConfId(null);
                        queryClient.invalidateQueries(
                            SUPERADMIN_TPV_CONFIGURATIONS,
                        );
                    }}
                />
            )}
        </Paper>
    );
}
