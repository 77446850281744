import { useContext } from 'react';
import { AppContext } from '../sporttia/AppContext';
import { useAPI } from '../lib/hooks';
import useRestService from './RestService';
import Terrain from '../types/models/Terrain';
import { FacilityParams, FacilityResponse } from '../types/api/paths/Facility';
import TerrainResponse from '../types/api/paths/Terrain';

const resource = '/facilities';

export default function useFacilitiesService() {
    const { migrationApis } = useContext(AppContext)!;
    const [request] = useAPI(migrationApis);
    const services = useRestService(resource);

    const updateTerrain = (id: number, x: number, y: number) =>
        request<Terrain>('PUT', `/terrains/${id}`, { x, y });

    // Override pq es url rara
    function create(idSC: number | undefined, params: FacilityParams) {
        if (!idSC) {
            return Promise.reject();
        }
        return request<FacilityResponse>(
            'POST',
            `/scs/${idSC}/facilities`,
            params,
        );
    }

    function createTerrain(id: number | undefined) {
        if (!id) {
            return Promise.reject();
        }
        return request<TerrainResponse>('POST', `/facilities/${id}/terrains`);
    }

    function addExistingTerrain(
        idFacility: number | undefined,
        idTerrain: number | undefined,
    ) {
        if (!idFacility || !idTerrain) {
            return Promise.reject();
        }
        return request<TerrainResponse>(
            'POST',
            `/facilities/${idFacility}/terrains/${idTerrain}`,
        );
    }

    function removeTerrain(
        idFacility: number | undefined,
        idTerrain: number | undefined,
    ) {
        if (!idFacility || !idTerrain) {
            return Promise.reject();
        }
        return request<TerrainResponse>(
            'DELETE',
            `/facilities/${idFacility}/terrains/${idTerrain}`,
        );
    }

    return {
        ...services,
        create,
        updateTerrain,
        createTerrain,
        addExistingTerrain,
        removeTerrain,
    };
}
