import React, { useRef, useState, useEffect, useContext } from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Webcam from 'react-webcam';
import { SttButton } from '../../sporttia/buttons/SttButton';
import { AppContext } from '../../sporttia/all';

const useStyles = makeStyles((theme) => ({
    webcamContainer: {
        position: 'relative',
        backgroundColor: 'white',
        width: 'inherit',
        maxWidth: 'inherit',
        height: 'inherit',
        maxHeight: 'inherit',
    },
    border: {
        border: '1px dashed #cbcbcb',
    },
    borderLess: {
        borderWidth: 0,
    },
    webcam: {
        width: 'inherit',
        maxWidth: 'inherit',
        height: 'inherit',
        maxHeight: 'inherit',
    },
    loadingContainer: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
    },
    error: {
        padding: theme.spacing(3),
        fontSize: 16,
    },
}));

export default function WebcamCapture({
    width,
    height,
    format = 'image/jpeg',
    onCapture,
}) {
    const cxt = useContext(AppContext);

    const webcamRef = useRef(null);
    const [devices, setDevices] = useState([]);
    const [ready, setReady] = useState(false);
    const [error, setError] = useState(false);
    const [cameraReady, setCameraReady] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
            setReady(true);
            setDevices(
                mediaDevices.filter(({ kind }) => kind === 'videoinput'),
            );
        });
    }, []);

    function capture() {
        const imageSrc = webcamRef.current.getScreenshot();
        onCapture && onCapture(imageSrc);
    }

    const devicesDetected = devices && devices.length > 0;

    if (!ready) {
        return null;
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            {!devicesDetected && (
                <Box p={3} textAlign="center">
                    <p>{cxt.t('page.user.webcam.noWebcam')}</p>
                </Box>
            )}
            {error && (
                <Box p={3} textAlign="center">
                    <p>{cxt.t('page.user.webcam.webcamError')}</p>
                </Box>
            )}
            {devicesDetected && !error && (
                <>
                    <Box
                        mb={3}
                        textAlign="center"
                        width={width || 'auto'}
                        height={height || 'auto'}
                    >
                        <Box
                            className={clsx({
                                [classes.webcamContainer]: true,
                                [classes.border]: !cameraReady,
                            })}
                        >
                            <Webcam
                                className={classes.webcam}
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat={format}
                                onUserMedia={(mediaStream) => {
                                    setCameraReady(true);
                                }}
                                onUserMediaError={(MediaStreamError) => {
                                    setError(MediaStreamError);
                                }}
                            />
                            {!cameraReady && (
                                <div className={classes.loadingContainer}>
                                    <CircularProgress color="primary" />
                                </div>
                            )}
                        </Box>
                    </Box>
                    <Box textAlign="center">
                        <SttButton
                            caption={cxt.t('page.user.webcam.capture')}
                            variant="contained"
                            color="primary"
                            onClick={capture}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
}
