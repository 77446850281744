import React, { useState, useEffect, useContext } from 'react';
import {
    Grid,
    Paper,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Box,
    ListItemSecondaryAction,
    IconButton,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { AppContext, SttDialog, SttFormText } from '../../../sporttia/all';
import { updateElementInArray, subsetObject } from '../../../lib/utils';

export default function BookingSelectPupilsDialog({
    open = false,
    occupants,
    sc,
    onClose,
    onAddOccupant,
}) {
    const cxt = useContext(AppContext);
    const [allPupils, setAllPupils] = useState();
    const [availableUsers, setAvailableUsers] = useState();
    const [newUser, setNewUser] = useState({});

    /**
     * Load pupils
     */
    const loadPupils = () => {
        cxt.api('GET', `/scs/${sc.id}/pupils`, {
            params: {
                page: 1,
                rows: 100,
            },
            success: (r) => {
                setAllPupils(r.rows);
            },
        });
    };

    /**
     * Generate available users
     */
    useEffect(() => {
        if (allPupils) {
            const allPeople = [
                {
                    ...subsetObject(cxt.user, 'id,name,fullName'),
                    checked: false,
                },
                ...allPupils.map((pupil) => ({ ...pupil, checked: false })),
            ];

            // Only show who is not already in the booking
            setAvailableUsers(
                allPeople.filter(
                    (user) =>
                        user &&
                        user.id &&
                        !occupants.find(
                            (occupant) =>
                                occupant.user && occupant.user.id == user.id,
                        ),
                ),
            );
        }
    }, [occupants, allPupils]);

    /**
     * Init
     */
    useEffect(() => {
        if (sc) loadPupils();
    }, [sc]);

    /**
     * Handle new user
     */
    const handleNewUser = ({ name, value }) => {
        setNewUser({ ...newUser, [name]: value });
    };

    /**
     * Create pupil
     */
    const createPupil = () => {
        cxt.api('POST', `/my/scs/${sc.id}/pupils`, {
            params: newUser,
            success: (r) => {
                cxt.showMessage('S', cxt.t('Created'));

                // Add user to the booking
                onAddOccupant({
                    name: r.user.fullName,
                    user: r.user,
                });

                // Clear new user
                setNewUser({});
            },
        });
    };

    // -----| Render |-----
    return (
        <SttDialog
            title={cxt.t('Pupils')}
            open={open}
            onClose={onClose}
            content={
                <>
                    {availableUsers && availableUsers.length > 0 && (
                        <Paper>
                            <List>
                                {availableUsers.map((user, idxUser) => (
                                    <ListItem key={idxUser}>
                                        <ListItemText primary={user.fullName} />

                                        <ListItemSecondaryAction>
                                            <IconButton
                                                edge="end"
                                                onClick={() => {
                                                    onAddOccupant({
                                                        name: user.fullName,
                                                        user,
                                                    });
                                                    cxt.showMessage(
                                                        'S',
                                                        cxt.t('Added'),
                                                    );
                                                }}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    )}

                    <Box mt={4}>{cxt.t('booking.youCanAddNoRegistered')}</Box>
                    <Box mt={1}>
                        <Grid container spacing={3}>
                            <SttFormText
                                grid
                                md={12}
                                sm={12}
                                xs={12}
                                caption={cxt.t('Name')}
                                name="name"
                                defVal={newUser.name}
                                onChange={handleNewUser}
                            />

                            <SttFormText
                                grid
                                md={6}
                                sm={6}
                                xs={12}
                                caption={`${cxt.t('Surname')} 1`}
                                name="surname1"
                                defVal={newUser.surname1}
                                onChange={handleNewUser}
                            />

                            <SttFormText
                                grid
                                md={6}
                                sm={6}
                                xs={12}
                                caption={`${cxt.t('Surname')} 2`}
                                name="surname2"
                                defVal={newUser.surname2}
                                onChange={handleNewUser}
                            />
                        </Grid>
                    </Box>
                </>
            }
            buttons={[
                {
                    caption: cxt.t('AddUser'),
                    icon: <PersonAddIcon />,
                    onClick: () => createPupil(),
                },
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
        />
    );
}
