import React, { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { Box, Container } from '@material-ui/core';
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import { AppContext, SttDialog, SttTabs } from '../../../sporttia/all';
import DeviceDetails from './DeviceDetails';
import DeviceAuths from './DeviceAuths';
import DeviceAccesses from './DeviceAccesses';
import { SttFullDialog } from '../../../sporttia/dialogs/SttFullDialog';

export default function DeviceDetailsDialog({ scId, deviceId, onClose }) {
    const cxt = useContext(AppContext);
    const [device, setDevice] = useState();
    const [simulateAccessOpen, setSimulateAccessOpen] = useState(false);

    const loadDevice = () => {
        cxt.api('GET', `/devices/${deviceId}`, {
            params: {
                trash: true,
            },
            success: (r) => {
                setDevice(r.device);
            },
        });
    };

    useEffect(() => {
        if (deviceId) loadDevice();
    }, [deviceId]);

    // Tabs
    const tabs = [
        {
            caption: cxt.t('Detail'),
            component: (
                <DeviceDetails
                    device={device}
                    scId={scId}
                    onClose={onClose}
                    setSimulateAccessOpen={setSimulateAccessOpen}
                    simulateAccessOpen={simulateAccessOpen}
                />
            ),
        },
        {
            caption: cxt.t('Authorizations'),
            component: <DeviceAuths id={deviceId} />,
        },
        {
            caption: cxt.t('Accesses'),
            component: <DeviceAccesses id={deviceId} />,
            show: deviceId !== 'create',
        },
    ];

    // -----| Render |-----
    return (
        <SttFullDialog
            open={deviceId !== null}
            title={
                deviceId === 'create'
                    ? cxt.t('CreateDevice')
                    : cxt.t('Devices').slice(0, -1)
            }
            onClose={onClose}
            bottomButtons={[
                {
                    caption: cxt.t('SimulatePass'),
                    onClick: () => setSimulateAccessOpen(true),
                    icon: <CenterFocusWeakIcon />,
                },
            ]}
        >
            <Container>
                <Box mt={2}>
                    <Paper>
                        <SttTabs tabs={tabs} />
                    </Paper>
                </Box>
            </Container>
        </SttFullDialog>
    );
}
