import React, { useState, useEffect, useContext } from 'react';
import { Paper } from '@material-ui/core';
import moment from 'moment';
import { AppContext, SttTable, SttTopControls } from '../../../sporttia/all';
import SalePoint from '../salePoint/SalePoint';
import DateBuilder from '../../../lib/DateBuilder';

export default function SCTickets() {
    const cxt = useContext(AppContext);
    const [tickets, setTickets] = useState();
    const [openedTicket, setOpenedTicket] = useState(null);

    const [filters, setFilters] = useState({
        ini: '',
        end: moment().format('YYYY-MM-DD'),
    });

    /**
     * Load tickets with date filters
     */
    const loadTickets = (p = { page: 1 }) => {
        const params = {
            page: p.page,
            rows: 20,
            ini: filters.ini,
            end: filters.end,
        };

        cxt.api('GET', `/scs/${cxt.sc.id}/products/tickets`, {
            params,
            success: (r) => {
                setTickets(r);
                cxt.setHeader({
                    title: cxt.t('Tickets'),
                });
            },
        });
    };

    /**
     * SC changes
     */
    useEffect(() => {
        if (cxt.sc.id) loadTickets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cxt.sc.id, filters]);

    // Render
    return (
        <Paper>
            <SttTopControls
                p={2}
                extraFields={[
                    {
                        name: 'ini',
                        type: 'date',
                        value: filters.ini,
                        caption: cxt.t('Dateini'),
                        onChange: ({ name, value }) =>
                            setFilters({
                                ...filters,
                                [name]: value
                                    ? moment(value).format('YYYY-MM-DD')
                                    : '',
                            }),
                    },
                    {
                        name: 'end',
                        type: 'date',
                        value: filters.end,
                        caption: cxt.t('Dateend'),
                        onChange: ({ name, value }) =>
                            setFilters({
                                ...filters,
                                [name]: value
                                    ? moment(value).format('YYYY-MM-DD')
                                    : '',
                            }),
                    },
                ]}
                onFilter={loadTickets}
            />

            <SttTable
                data={tickets}
                autoload={false}
                onClickRow={setOpenedTicket}
                columns={[
                    {
                        title: cxt.t('Created'),
                        field: 'created',
                        value: (row) =>
                            new DateBuilder(
                                row?.created,
                                cxt?.sc?.timezone?.name,
                                cxt?.lang?.key,
                            ).dmy(),
                    },
                    {
                        title: cxt.t('Status'),
                        field: 'status',
                        type: 'binaryStatus',
                        value: (row) => row.status === 'OPEN',
                    },
                    {
                        title: cxt.t('fullname'),
                        field: 'fullName',
                        align: 'left',
                        value: (row) => row.user && row.user.fullName,
                    },
                    {
                        align: 'right',
                        title: cxt.t('Total'),
                        field: 'totalPaid',
                        type: 'price',
                    },
                ]}
                onFetch={loadTickets}
            />

            <SalePoint
                open={openedTicket != null}
                idTicket={openedTicket && openedTicket.id}
                onClose={() => {
                    setOpenedTicket();
                    loadTickets();
                }}
            />
        </Paper>
    );
}
