import React, { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { SttTopFilteringControls } from '../../../sporttia/SttTopFilteringControls';
import GolfCourseScheduleDialog from './GolfCourseScheduleDialog';
import { SPORTCENTER_GOLF_COURSE_SCHEDULES } from '../../../lib/queryKeys';
import SttCachedTable from '../../../sporttia/SttCachedTable';
import useGolfService from '../../../services/GolfService';
import { useTranslations } from '../../../lib/hooks';
import { AppContext } from '../../../sporttia/AppContext';
import { copyToClipboard } from '../../../lib/utils';
import { SttChipRate } from '../../../sporttia/chips/SttChipRate';

/**
 * Golf course tab to list rates and schedules.
 * @param golfCourseId Golf course ID.
 * @returns {JSX.Element}
 */
function GolfCourseSchedulesAndRates({ golfCourseId }) {
    const cxt = useContext(AppContext);
    const { translate } = useTranslations();
    const golfService = useGolfService();
    const queryClient = useQueryClient();
    const [selectedGolfCourseScheduleId, setSelectedGolfCourseScheduleId] =
        useState(null);

    return (
        <>
            <SttTopFilteringControls
                mainAction={{
                    type: 'create',
                    caption: translate('CreateFreeSchedule'),
                    onClick: () => setSelectedGolfCourseScheduleId('create'),
                }}
            />

            <SttCachedTable
                queryKey={SPORTCENTER_GOLF_COURSE_SCHEDULES}
                queryFn={(params) =>
                    golfService.getGolfCourseFrees(golfCourseId, params)
                }
                onClickRow={(row) => setSelectedGolfCourseScheduleId(row?.id)}
                columns={[
                    {
                        title: '#',
                        type: 'tooltip',
                        value: (row) => ({ label: '#', value: row.id }),
                        onClick: (row) =>
                            copyToClipboard(row.id, () =>
                                cxt.showMessage(
                                    'S',
                                    cxt.t('copiedToClipboard'),
                                ),
                            ),
                    },
                    {
                        title: translate('status'),
                        field: 'status',
                        value: (row) => translate(row?.status),
                    },
                    {
                        type: 'dmy',
                        title: translate('DateIni'),
                        field: 'dateini',
                    },
                    {
                        type: 'dmy',
                        title: translate('DateEnd'),
                        field: 'dateend',
                    },
                    {
                        title: translate('TimeIni'),
                        field: 'timeini',
                    },
                    {
                        title: translate('TimeEnd'),
                        field: 'timeend',
                    },
                    {
                        type: 'weekdays',
                        title: translate('WeekDays'),
                        field: 'weekdays',
                    },
                    {
                        title: translate('Rates'),
                        value: (row) => (
                            <SttChipRate
                                rates={row?.rates?.rows}
                                justifyContent="center"
                            />
                        ),
                    },
                ]}
            />

            <GolfCourseScheduleDialog
                id={selectedGolfCourseScheduleId}
                golfCourseId={golfCourseId}
                onClose={() => setSelectedGolfCourseScheduleId(null)}
                onDelete={() => {
                    setSelectedGolfCourseScheduleId(null);
                    queryClient.invalidateQueries(
                        SPORTCENTER_GOLF_COURSE_SCHEDULES,
                    );
                }}
                onUpdate={() =>
                    queryClient.invalidateQueries(
                        SPORTCENTER_GOLF_COURSE_SCHEDULES,
                    )
                }
                onCreate={(response) => {
                    queryClient.invalidateQueries(
                        SPORTCENTER_GOLF_COURSE_SCHEDULES,
                    );
                    if (response?.error) {
                        cxt.showMessage('E', response?.error?.msg);
                    } else {
                        setSelectedGolfCourseScheduleId(
                            response?.golfScheduleFree?.id,
                        );
                    }
                }}
            />
        </>
    );
}

export default GolfCourseSchedulesAndRates;
