import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Container, InputAdornment, Typography } from '@material-ui/core';
import { Euro } from '@material-ui/icons';
import { AppContext } from '../../../sporttia/AppContext';
import { useCrud } from '../../../lib/hooks';
import { SttPage } from '../../../sporttia/SttPage';
import { SttButton, SttFormSelect, SttFormText } from '../../../sporttia/all';
import { getPath } from '../../Pages';
import { formatPriceByLocale, parsePrice } from '../../../lib/utils';

// RechargePurse screen for users: pick a recharge option or input amount manually
export default function RechargePurse({ match }) {
    const cxt = useContext(AppContext);
    const history = useHistory();

    const [ready, setReady] = useState(false);
    const [mship, setMship, , Get, Post] = useCrud(null, false);
    const [sc, setSc] = useState();
    const [options, setOptions] = useState(false);
    const [selected, setSelected] = useState(null);
    const [price, setPrice] = useState('');

    // Load all the stuff
    function loadProfile() {
        const { id } = match.params;

        // Launch 3 different requests, then setReady when all of them are fulfilled
        Promise.all([
            // My membership in the SC, if any
            Get(`/scs/${id}/me`)
                .then((response) => {
                    if (response.mship) {
                        setMship(response.mship);
                    }
                })
                .catch(() => {}),

            // The SC info itself. TODO: recycle this from the previous screen. We are requesting it twice ffs.
            Get(`/scs/${id}`)
                .then((response) => {
                    if (response.sc) {
                        setSc(response.sc);
                    }
                })
                .catch(() => {}),

            // Payment options from the sports center
            Get(`/scs/${id}/chargings/options`)
                .then((response) => {
                    if (response.count > 0) {
                        setOptions(response.rows);
                    }
                })
                .catch(() => {}),
        ])
            .then(() => {
                // All promises resolved
                setReady(true);
            })
            .catch(() => {});
    }

    // Using the option's id, grab the price and setSelected(id) for the state
    function setOption(id) {
        const option = options.find((opt) => opt.id === id);
        setPrice(option.amount);
        setSelected(id);
    }

    // Component mounted
    useEffect(() => {
        if (match) {
            loadProfile();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Options obtained (only once). Set the first option as selected.
    useEffect(() => {
        if (options) {
            setOption(options[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    // Execute the actual payment request
    function purchase() {
        const { id } = match.params;
        const parsedPrice = parsePrice(price);

        if (Number.isNaN(Number(parsedPrice))) {
            cxt.showMessage('E', cxt.t('InvalidAmount'));
            return;
        }

        Post(`/scs/${id}/chargings`, { price: parsedPrice })
            .then((response) => {
                if (response.tpv) {
                    // Redirect to the tpv container
                    history.push(
                        getPath('tpvSwitcher', { id: response.tpv.id }),
                    );
                }
            })
            .catch((error) => {
                cxt.showMessage('E', error.error.msg);
            });
    }

    if (!ready) {
        return null;
    }

    return (
        <Container maxWidth="sm">
            <SttPage innerTitle={`${cxt.t('ChargePurse')}.`}>
                <Box p={1} mb={3}>
                    <Typography
                        variant="body1"
                        dangerouslySetInnerHTML={{
                            __html: cxt
                                .t('page.scProfile.purseCharge.descriptionFull')
                                .replace('{{sc.name}}', `<b>${sc.name}</b>`),
                        }}
                    />
                </Box>
                <Box p={1} mb={3}>
                    {mship.id ? (
                        <Typography variant="body1">{`${cxt.t(
                            'page.scProfile.purseCharge.purseAmount1',
                        )}: ${formatPriceByLocale(
                            mship?.amount,
                            mship?.amountShape?.currency,
                            mship?.amountShape?.locale,
                        )}`}</Typography>
                    ) : (
                        <Typography variant="body1">
                            {cxt.t(
                                'page.scProfile.purseCharge.noAmountInPurse',
                            )}
                        </Typography>
                    )}
                </Box>
                <Box p={1} mb={3}>
                    {options /* If the SC has defined options (5, 20, 40, etc) we show them here, otherwise a text form */ ? (
                        <SttFormSelect
                            name="option"
                            defVal={selected}
                            onChange={({ value }) => setOption(value)}
                            options={options.map((opt) => ({
                                caption: opt.name,
                                value: opt.id,
                            }))}
                        />
                    ) : (
                        <SttFormText
                            placeholder={cxt.t('PriceToCharge')}
                            name="amount"
                            defVal={price}
                            onChange={({ value }) => setPrice(value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {mship?.amountShape?.currency
                                            ? mship?.amountShape?.currency
                                            : '€ '}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                </Box>
                {/* SC info and sales policy */}
                <Box p={1} mb={3}>
                    <Typography variant="body1">
                        <b>{cxt.t('sellingPolicy.title')}</b>
                    </Typography>
                    <Typography variant="body1">{`${sc.name}${
                        sc.address && `, ${sc.address}`
                    }${sc.city && `, ${sc.city.name}`}${
                        sc.city &&
                        sc.city.province &&
                        `, ${sc.city.province.name}`
                    }`}</Typography>
                    <Typography variant="body1">
                        {sc.salesPolicy || cxt.t('sellingPolicy.desc')}
                    </Typography>
                </Box>
                <Box>
                    {/* Confirm button. Disabled if price is invalid */}
                    <SttButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={!price || price <= 0}
                        caption={cxt.t('ChargePurse')}
                        startIcon={
                            mship?.amountShape?.currency !== 'EUR' &&
                            mship?.amountShape?.currency ? (
                                mship?.amountShape?.currency
                            ) : (
                                <Euro />
                            )
                        }
                        onClick={purchase}
                    />
                </Box>
            </SttPage>
        </Container>
    );
}
