import React, { useState, useContext } from 'react';
import { AppContext, SttTable } from '../all';
import { fullName } from '../../lib/utils';

type ActivityParticipants = {
    id: number;
    user: {
        fullName: string;
        mship: {
            fullName: string;
        };
    };
};
export default function SignedUsersList({
    items,
}: {
    items: Array<ActivityParticipants>;
}) {
    const cxt = useContext(AppContext)!;
    const [signedUsers] = useState(items);

    return (
        <SttTable
            data={{
                rows: signedUsers,
                count: signedUsers.length,
            }}
            columns={[
                {
                    title: cxt.t('fullname'),
                    align: 'left',
                    value: (value: ActivityParticipants) =>
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                        fullName(value?.user),
                },
            ]}
            onFetch={undefined}
            loadingValue={undefined}
            onClickRow={undefined}
            totals={undefined}
            selection={undefined}
            selectRows={undefined}
            setSelectRows={undefined}
            forceUpdateSwitch={undefined}
        />
    );
}
