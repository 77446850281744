import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Container, Box, Paper, Tabs, Tab, Grid } from '@material-ui/core';
import {
    AppContext,
    SttFormText,
    SttTabs,
    SttFormDate,
    SttTable,
    SttChipEventType,
    SttChipEventKind,
} from '../../../sporttia/all';
import DateBuilder from '../../../lib/DateBuilder';

export default function MshipEventParticipants({ mship }) {
    const cxt = useContext(AppContext);
    const [participants, setEventParticipants] = useState({});

    /**
     * Get participants
     */
    const loadEventParticipants = (p = {}) => {
        cxt.api('GET', `/mships/${mship.id}/events/participants`, {
            params: {
                page: p.page,
                rows: p.numRows,
            },
            success: (r) => {
                setEventParticipants(r);
            },
        });
    };
    // -----| Render |-----
    return (
        <SttTable
            data={participants}
            columns={[
                {
                    field: 'created',
                    title: cxt.t('Created'),
                    value: (row) =>
                        new DateBuilder(
                            row?.created,
                            cxt?.sc?.timezone?.name,
                            cxt?.lang?.key,
                        ).cdlhm(),
                },
                {
                    field: 'eventCategory',
                    title: `${cxt.t('Event')} / ${cxt.t('Category')}`,
                    align: 'left',
                    value: (r) => (
                        <>
                            <b>{r.category.event.name}</b>
                            <div>
                                {cxt.t('Category')}: <b>{r.category.name}</b>
                            </div>
                        </>
                    ),
                },
                {
                    field: 'kind',
                    title: cxt.t('Kind'),
                    value: (r) => (
                        <SttChipEventKind kind={r.category.event.kind} />
                    ),
                },
                {
                    field: 'type',
                    title: cxt.t('Inscription'),
                    value: (r) => (
                        <SttChipEventType type={r.category.event.type} />
                    ),
                },
                {
                    field: 'period',
                    title: cxt.t('Period'),
                    value: (r) => ({
                        ini: r.category.event.ini,
                        end: r.category.event.end,
                    }),
                    type: 'period',
                },
                {
                    field: 'price',
                    title: cxt.t('Price'),
                    type: 'price',
                    value: (r) => (r.enrollment ? r.enrollment.price : null),
                },
            ]}
            onFetch={loadEventParticipants}
        />
    );
}
