import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { AppContext } from '../../../../sporttia/AppContext';
import SttValidatedForm from '../../../../sporttia/SttValidatedForm';
import useReportsService from '../../../../services/ReportsService';
import translations from '../../../../translations';
import { useTranslations } from '../../../../lib/hooks';
import constants from '../../../../config/constants';

/**
 * View to create and modify the details of a report.
 * @param report Report object.
 * @param status API Request status.
 * @param onCreate Launched when a new customer is created.
 * @param onDelete Launched when a new customer is removed.
 * @param onUpdate Launched when a new customer is updated.
 * @returns {JSX.Element}
 */
export default function AdminReportDetail({
    report,
    status,
    onCreate,
    onDelete,
    onUpdate,
    onRefresh,
}) {
    const cxt = useContext(AppContext);
    const { translate } = useTranslations();
    const reportsService = useReportsService();
    const form = useForm();
    const deleteMutation = useMutation(
        (params) => reportsService.remove(params?.id),
        {
            onSuccess: onDelete,
        },
    );
    const updateMutation = useMutation(
        (params) => reportsService.update(params?.id, params?.params),
        {
            onSuccess: onUpdate,
        },
    );
    const createMutation = useMutation(
        (params) => reportsService.create(params?.params),
        {
            onSuccess: (data) => onCreate(data?.report?.id),
        },
    );

    /**
     * Method executed when the user clicks on save or create report.
     */
    const mutateReport = (formData) => {
        if (report?.id) {
            updateMutation.mutate({ id: report?.id, params: formData });
        } else {
            createMutation.mutate({ params: formData });
        }
    };

    /**
     * When the data is returned by the API, it is set in the form.
     */
    useEffect(() => {
        if (report) {
            form.reset({
                created: report?.created,
                name: report?.name,
                description: report?.description,
                sql: report?.sql,
                group: report?.group,
                scope: report?.scope,
                status:
                    report?.status === constants.report.status.ACTIVE
                        ? constants.report.status.ACTIVE
                        : constants.report.status.INACTIVE,
            });
        } else {
            form.reset({
                created: null,
                name: '',
                description: '',
                sql: '',
                group: '',
                scope: '',
                status: constants.report.status.INACTIVE,
            });
        }
    }, [report, form]);

    return (
        <SttValidatedForm
            form={form}
            loadingData={status === 'loading'}
            fields={[
                {
                    type: 'date',
                    name: 'created',
                    caption: cxt.t('Created'),
                    disabled: true,
                    md: 3,
                },
                {
                    type: 'textInput',
                    name: 'name',
                    caption: cxt.t('Name'),
                    md: 9,
                },
                {
                    type: 'textArea',
                    name: 'description',
                    caption: cxt.t('Description'),
                    rows: 3,
                },
                {
                    type: 'textArea',
                    name: 'sql',
                    caption: 'SQL',
                    rows: 25,
                },
                {
                    type: 'select',
                    name: 'group',
                    caption: cxt.t('Group'),
                    md: 6,
                    options: [
                        { caption: cxt.t('activity'), value: 'ACTIVITY' },
                        { caption: cxt.t('user'), value: 'USER' },
                        {
                            caption: cxt.t('rg.accounting'),
                            value: 'ACCOUNTING',
                        },
                        { caption: cxt.t('booking'), value: 'BOOKING' },
                        { caption: cxt.t('event'), value: 'EVENT' },
                        { caption: cxt.t('facility'), value: 'FACILITY' },
                        { caption: cxt.t('device'), value: 'DEVICE' },
                        { caption: 'Api', value: 'API' },
                    ],
                },
                {
                    type: 'select',
                    name: 'scope',
                    caption: cxt.t('Scope'),
                    md: 6,
                    options: [
                        { caption: 'Centro', value: 'SC' },
                        { caption: 'Admin Sporttia', value: 'ADMIN' },
                    ],
                },
                {
                    type: 'select',
                    name: 'status',
                    caption: cxt.t('Active'),
                    options: [
                        {
                            caption: translate('Active'),
                            value: constants.report.status.ACTIVE,
                        },
                        {
                            caption: translate('Inactive'),
                            value: constants.report.status.INACTIVE,
                        },
                    ],
                    md: 6,
                },
            ]}
            buttons={[
                {
                    type: 'save',
                    onClick: form.handleSubmit((formData) =>
                        mutateReport(formData),
                    ),
                },
                {
                    type: 'recover',
                    show: report?.id,
                    caption: translate(translations.generic.refresh),
                    onClick: () => onRefresh(),
                },
                {
                    show: !report?.trash,
                    type: 'delete',
                    onClick: () => deleteMutation.mutate({ id: report?.id }),
                },
            ]}
        />
    );
}
