import { useContext } from 'react';
import { MutationFunction, UseMutationOptions, useMutation } from 'react-query';
import { AppContext } from '../sporttia/AppContext';
import { useAPI } from '../lib/hooks';

const resource = '/bookings';

/**
 * Hook para acceder a los recursos de 'bookings'.
 */
export default function useBookingsService() {
    const { migrationApis } = useContext(AppContext)!;
    const [request] = useAPI(migrationApis);

    /**
     * POST /bookings request.
     */
    function useCreateBooking(
        mutationOptions?: UseMutationOptions<
            unknown,
            unknown,
            {
                params: Record<string, unknown>;
            }
        >,
    ) {
        const mutationFn: MutationFunction<
            unknown,
            {
                params: Record<string, unknown>;
            }
        > = ({ params }) => request('POST', resource, params);

        return useMutation(mutationFn, mutationOptions);
    }

    return {
        useCreateBooking,
    };
}
