import { Grid, GridProps, Typography } from '@material-ui/core';
import React from 'react';

type SttLabelFormProps = {
    grid?: boolean;
    title?: string;
    text: string;
} & GridProps;

/**
 * Label for forms
 */
export function SttLabelForm({
    grid = false,
    xs = 12,
    title,
    text,
    ...rest
}: SttLabelFormProps) {
    const content = (
        <div>
            <div>
                <Typography variant="caption">{title}</Typography>
            </div>
            <b>{text}</b>
        </div>
    );

    return grid ? (
        <Grid item xs={xs} {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
