import React, { useState, useEffect, useContext } from 'react';
import { Box, List, ListItem, Chip } from '@material-ui/core';
import moment from 'moment';
import { AppContext, SttDialog, SttList } from '../../../sporttia/all';
import { formatPriceByLocale } from '../../../lib/utils';

/**
 *
 *	Props
 *
 *	* onSelect({rate,boleto})
 */
export default function BookingSelectRateDialog({
    open = false,
    fares,
    rateType,
    onClose,
    onSelect,
    occupants,
}) {
    const cxt = useContext(AppContext);
    const [rates, setRates] = useState();
    const [boletos, setBoletos] = useState();

    /**
     * Set rates
     */
    useEffect(() => {
        if (fares) {
            if (rateType === cxt.constants.RT_Individual) {
                setRates(fares.individualRates);
                setBoletos(fares.individualBonos);
            } else {
                setRates(fares.fullRates);
                setBoletos(fares.fullBonos);
            }
        } else {
            setRates(null);
            setBoletos(null);
        }
    }, [fares, rateType]);

    /**
     * Select rate / boleto
     */
    const selectRateBoleto = (p) => {
        onSelect(p);
        onClose();
    };

    // Render
    return (
        <SttDialog
            title={`${cxt.t('Rates')} & ${cxt.t('Bonos')}`}
            open={open}
            onClose={onClose}
            content={
                <>
                    {boletos && (
                        <Box mb={2}>
                            <SttList
                                paper
                                title={cxt.t('Bonos')}
                                onSelect={(boleto) =>
                                    selectRateBoleto({ boleto })
                                }
                                numRows={3}
                                data={boletos.map((boleto) => ({
                                    caption: `${boleto.bono.name} [ ${boleto.consumed} / ${boleto.amount} ]`,
                                    objToSelect: boleto,
                                }))}
                            />
                        </Box>
                    )}

                    {rates && (
                        <SttList
                            paper
                            title={cxt.t('Rates')}
                            onSelect={(rate) => selectRateBoleto({ rate })}
                            numRows={3}
                            data={rates.map((rate) => ({
                                caption: (
                                    <>
                                        {rate &&
                                            rate.prices &&
                                            rate.prices.map(
                                                (price, idxPrice) => (
                                                    <Chip
                                                        key={idxPrice}
                                                        style={{
                                                            marginRight: 3,
                                                            marginTop: 3,
                                                        }}
                                                        size="small"
                                                        label={`${
                                                            price.name
                                                        } [ ${
                                                            price.duration
                                                        }m / ${formatPriceByLocale(
                                                            price?.price,
                                                            price?.priceShape
                                                                ?.currency,
                                                            price?.priceShape
                                                                ?.locale,
                                                        )} ]`}
                                                    />
                                                ),
                                            )}
                                    </>
                                ),
                                objToSelect: rate,
                            }))}
                        />
                    )}
                </>
            }
            buttons={[
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
        />
    );
}
