import React, { useState, useEffect, useContext } from 'react';
import { Box, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { AppContext, SttPage, SttForm } from '../../sporttia/all';
import { useTranslations } from '../../lib/hooks';
import translations from '../../translations';

export default function Contact() {
    const cxt = useContext(AppContext);
    const { translate } = useTranslations();
    const [form, setForm] = useState({});
    const [sent, setSent] = useState(false);
    const [privacyPolicy, setPrivacyPolicy] = useState(false);

    /**
     * Handle form
     */
    const handleForm = ({ name, value }) => {
        setForm({ ...form, [name]: value });
    };

    /**
     * Send
     */
    const send = () => {
        if (form.email === null) {
            form.email = '';
        }
        cxt.api('POST', '/consults', {
            params: form,
            success: () => {
                cxt.showMessage('S', cxt.t('Sent'));
                setSent(true);
            },
        });
    };

    /**
     * Init
     */
    useEffect(() => {
        if (cxt.user) {
            setForm({
                name: cxt.user.name,
                email: cxt.user.email,
                phone: cxt.user.mobile,
            });
        }
    }, [cxt.user]);

    // -----| Render |-----
    return (
        <Container maxWidth="md">
            <SttPage
                title={cxt.t('Contact')}
                innerTitle={cxt.t('view.contact.typesOfContact')}
            >
                <ul>
                    <li>
                        {cxt.t('view.contact.byPhone')}:{' '}
                        <b>{cxt.constants.contactPhone}</b>
                    </li>
                    <li>
                        {cxt.t('view.contact.byWhatsApp')}:{' '}
                        <b>{cxt.constants.contactWhatsApp}</b>
                    </li>
                    <li>
                        {cxt.t('view.contact.byEmail')}:{' '}
                        <b>{cxt.constants.contactEmail}</b>
                    </li>
                </ul>

                <Box mt={3} mb={2}>
                    <b>{cxt.t('view.contact.byForm')}</b>
                </Box>

                <SttForm
                    onChangeForm={handleForm}
                    elements={[
                        {
                            md: 12,
                            sm: 12,
                            xs: 12,
                            caption: cxt.t('Name'),
                            name: 'name',
                            value: form.name,
                        },
                        {
                            md: 12,
                            sm: 12,
                            xs: 12,
                            caption: cxt.t('Email'),
                            name: 'email',
                            value: form.email,
                        },
                        {
                            md: 12,
                            sm: 12,
                            xs: 12,
                            caption: cxt.t('Phone'),
                            name: 'phone',
                            value: form.phone,
                        },
                        {
                            md: 12,
                            sm: 12,
                            xs: 12,
                            type: 'textarea',
                            name: 'text',
                            caption: cxt.t('ReportIncidenceOrConsults'),
                            value: form.text,
                        },
                        {
                            md: 12,
                            sm: 12,
                            xs: 12,
                            type: 'component',
                            component: (
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={privacyPolicy}
                                                onChange={() =>
                                                    setPrivacyPolicy(
                                                        !privacyPolicy,
                                                    )
                                                }
                                            />
                                        }
                                        label={
                                            <Typography>
                                                {translate(
                                                    translations.generic
                                                        .iHaveReadAndAccept,
                                                )}{' '}
                                                <a href="https://www.sporttia.com/es/privacy/">
                                                    {translate(
                                                        translations.generic
                                                            .privacyPolicy,
                                                    )}
                                                </a>
                                            </Typography>
                                        }
                                    />
                                </Grid>
                            ),
                        },
                    ]}
                    buttons={[
                        {
                            type: 'accept',
                            show: !sent,
                            caption: cxt.t('Send'),
                            onClick: () => {
                                if (privacyPolicy) send();
                                else
                                    cxt.showMessage(
                                        'E',
                                        translate(
                                            translations.generic
                                                .youMustAcceptPrivacyPolicy,
                                        ),
                                    );
                            },
                        },
                    ]}
                />

                {sent && (
                    <Box mt={3}>
                        <Alert severity="info" variant="filled">
                            {cxt.t('ContactSent')}
                        </Alert>
                    </Box>
                )}
            </SttPage>
        </Container>
    );
}
