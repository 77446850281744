import React, { useState, useEffect, useContext } from 'react';
import { Grid, Box } from '@material-ui/core';
import { AppContext, SttForm, SttDialog } from '../../../sporttia/all';

export default function RoleDialog({ role = null, onSave, onDelete, onClose }) {
    const cxt = useContext(AppContext);
    const [internalRole, setInternalRole] = useState({});

    /**
     * Role changes
     */
    useEffect(() => {
        if (role) setInternalRole(role);
    }, [role]);

    /**
     * Handle form
     */
    const handleForm = ({ name, value }) => {
        setInternalRole({ ...internalRole, [name]: value });
    };

    /**
     * Save role
     */
    const saveRole = () => {
        // Updating
        if (internalRole.id) {
            cxt.api('PUT', `/scs/roles/${internalRole.id}`, {
                params: {
                    name: internalRole.name,
                    description: internalRole.description,
                },
                success: (r) => {
                    cxt.showMessage('S', cxt.t('Saved'));
                    onClose({ privileges: role.privileges, ...r.role });
                },
            });

            // Creating
        } else {
            cxt.api('POST', `/scs/${cxt.sc.id}/roles`, {
                params: {
                    name: internalRole.name,
                    description: internalRole.description,
                },
                success: (r) => {
                    cxt.showMessage('S', cxt.t('Created'));
                    onClose({ privileges: role.privileges, ...r.role });
                },
            });
        }
    };

    /**
     * Delete role
     */
    const delRole = () => {
        cxt.api('DELETE', `/scs/roles/${internalRole.id}`, {
            confirmation: true,
            success: (r) => {
                cxt.showMessage('S', cxt.t('Deleted'));
                onClose(r.role);
            },
        });
    };

    // -----| Render |-----
    return (
        <SttDialog
            title={cxt.t('Role')}
            open={role != null}
            onClose={() => onClose(null)}
            content={
                <Box mt={3}>
                    <SttForm
                        elements={[
                            {
                                md: 12,
                                sm: 12,
                                xs: 12,
                                name: 'name',
                                value: internalRole.name,
                            },
                            {
                                md: 12,
                                sm: 12,
                                xs: 12,
                                name: 'description',
                                value: internalRole.description,
                                type: 'textarea',
                            },
                        ]}
                        onChangeForm={handleForm}
                    />
                </Box>
            }
            buttons={[
                {
                    show: internalRole.id > 0,
                    type: 'delete',
                    onClick: delRole,
                },
                {
                    type: 'save',
                    onClick: saveRole,
                },
            ]}
        />
    );
}
