import React, { useState, useEffect, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { useQuery, useQueryClient } from 'react-query';
import AdminSCDetail from './AdminSCDetail';
import { AppContext, SttFullDialog, SttTabs } from '../../../../sporttia/all';
import { SUPERADMIN_SC } from '../../../../lib/queryKeys';
import useScsService from '../../../../services/ScsService';
import AdminSCOptions from './AdminSCOptions';
import AdminSCMap from './AdminSCMap';
import AdminSCDevices from './AdminSCDevices';
import POSStripeConfiguration from '../../../../components/stripe/POSStripeConfiguration';

/**
 * Sport center view. Contains tabs to access sport center data.
 * @param id Sport center ID.
 * @param onClose Function executed when closing the full dialog.
 * @returns {JSX.Element}
 */
export default function AdminSC({ id, onClose }) {
    const cxt = useContext(AppContext);
    const scsService = useScsService();
    const queryClient = useQueryClient();
    const [scId, setScId] = useState(id);
    const scQuery = useQuery(
        [SUPERADMIN_SC, { id: scId }],
        () => scsService.get(scId, { trash: true }),
        { enabled: !!scId && scId !== 'create' },
    );

    /**
     * Update sport center id if id prop was changed.
     */
    useEffect(() => {
        setScId(id);
    }, [id]);

    return (
        <SttFullDialog
            open={scId !== null}
            onClose={onClose}
            title={(() => {
                if (scQuery?.isLoading) {
                    return '';
                }

                if (scQuery?.data?.sc?.id) {
                    return scQuery?.data?.sc?.name;
                }

                return cxt.t('admin.sportcenter.create');
            })()}
        >
            <Paper>
                <Box p={3}>
                    <SttTabs
                        tabs={[
                            {
                                caption: cxt.t('Detail'),
                                component: (
                                    <AdminSCDetail
                                        sc={scQuery?.data?.sc}
                                        status={scQuery?.status}
                                        onCreate={setScId}
                                        onUpdate={(data) =>
                                            queryClient.setQueryData(
                                                [
                                                    SUPERADMIN_SC,
                                                    { id: data?.sc?.id },
                                                ],
                                                data,
                                            )
                                        }
                                        onDelete={onClose}
                                    />
                                ),
                            },
                            {
                                caption: cxt.t('Options'),
                                show: !!scQuery?.data?.sc?.id,
                                component: (
                                    <AdminSCOptions
                                        sc={scQuery?.data?.sc}
                                        onUpdate={(data) =>
                                            queryClient.setQueryData(
                                                [
                                                    SUPERADMIN_SC,
                                                    { id: data?.sc?.id },
                                                ],
                                                data,
                                            )
                                        }
                                    />
                                ),
                            },
                            {
                                caption: cxt.t('Map'),
                                show: !!scQuery?.data?.sc?.id,
                                component: (
                                    <AdminSCMap
                                        sc={scQuery?.data?.sc}
                                        onUpdate={(data) =>
                                            queryClient.setQueryData(
                                                [
                                                    SUPERADMIN_SC,
                                                    { id: data?.sc?.id },
                                                ],
                                                data,
                                            )
                                        }
                                    />
                                ),
                            },
                            {
                                caption: cxt.t('Devices'),
                                show: !!scQuery?.data?.sc?.id,
                                component: (
                                    <AdminSCDevices sc={scQuery?.data?.sc} />
                                ),
                            },
                            {
                                caption: cxt.t('PF.DATAFONO'),
                                show: !!scQuery?.data?.sc
                                    ?.sporttiaStripeLocationId,
                                component: (
                                    <POSStripeConfiguration
                                        sc={scQuery?.data?.sc}
                                    />
                                ),
                            },
                        ]}
                    />
                </Box>
            </Paper>
        </SttFullDialog>
    );
}
