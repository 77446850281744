import React, { useContext } from 'react';
import { AppContext, SttDialog } from '../../../sporttia/all';
import { SttFormAttendance } from '../../../sporttia/forms/SttFormAttendance';
import Activity from '../../../types/models/Activity';
import translations from '../../../translations';

type AttendancesDialogProps = {
    activity: Activity;
    open: boolean;
    onClose: () => void;
};

export default function AttendancesDialog({
    activity,
    open,
    onClose,
}: AttendancesDialogProps) {
    const cxt = useContext(AppContext)!;

    return (
        <SttDialog
            title={cxt.t(translations.generic.assistences)}
            open={open}
            onClose={onClose}
            maxWidth="lg"
            buttons={[
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
            content={<SttFormAttendance activity={activity} />}
        />
    );
}
