// Rates & boletos
import { useContext } from 'react';
import { Box, Chip } from '@material-ui/core';
import React from 'react';
import { AppContext } from '../../../sporttia/AppContext';
import { formatPriceByLocale } from '../../../lib/utils';

export default function RateAndBoletos({ occupant }) {
    const cxt = useContext(AppContext);

    return (
        <>
            <Box>
                {cxt.t('Rate')} / {cxt.t('Bono')}
            </Box>

            {occupant.fares ? (
                <Box mt={1}>
                    {/* --- Rate --- */}
                    {occupant.selectedRate &&
                        occupant.selectedRate.prices.map((price, idxPrice) => (
                            <Chip
                                key={idxPrice}
                                color="primary"
                                style={{
                                    marginRight: 3,
                                    marginTop: 3,
                                    color: 'white',
                                    backgroundColor: '#5b7fff',
                                }}
                                size="small"
                                label={`${price.name} [ ${
                                    price.duration
                                }m / ${formatPriceByLocale(
                                    price?.price,
                                    price?.priceShape?.currency,
                                    price?.priceShape?.locale,
                                )} ]`}
                            />
                        ))}

                    {/* --- Boleto --- */}
                    {occupant.selectedBoleto && (
                        <Chip
                            style={{
                                marginRight: 3,
                                marginTop: 3,
                                color: 'white',
                                backgroundColor: '#f576f7',
                            }}
                            color="primary"
                            size="small"
                            label={`${occupant.selectedBoleto.bono.name}
							[ ${occupant.selectedBoleto.consumed} / ${occupant.selectedBoleto.amount} ]`}
                        />
                    )}
                </Box>
            ) : (
                <Box style={{ color: 'red' }}>{cxt.t('RateNotSelected')}</Box>
            )}
        </>
    );
}
