import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { SttDialog } from './SttDialog';
import { AppContext } from '../AppContext';
import { generateFormField } from '../../lib/utils';

export function SttActivityFillForms({
    open = false,
    onClose,
    onSave,
    rateId,
    activityForms,
}) {
    const cxt = useContext(AppContext);
    const [forms, setForms] = useState([]);

    const handleFormFieldChange = ({ name, value }) => {
        const formsValues = forms.map((l) => ({ ...l }));
        const question = formsValues.find((question) => question.id == name);

        formsValues.splice(formsValues.indexOf(question), 1, {
            ...question,
            answer: { value },
        });

        setForms(formsValues);
    };

    useEffect(() => {
        setForms(activityForms);
    }, [activityForms]);

    const save = () => {
        const formsParam = {};

        forms.forEach((form) => (formsParam[form.id] = form.answer.value));

        onSave(rateId, { form: formsParam });
    };

    return (
        <SttDialog
            title={cxt.t('FillForms')}
            open={open}
            onClose={() => (onClose ? onClose() : null)}
            content={
                <Grid container spacing={3}>
                    {forms &&
                        forms.map((item, i) => (
                            <Grid item key={i} xs={12}>
                                {generateFormField(item, handleFormFieldChange)}
                            </Grid>
                        ))}
                </Grid>
            }
            buttons={[
                {
                    type: 'save',
                    onClick: () => save(),
                },
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
        />
    );
}
