import { withStyles } from '@material-ui/core/styles';
import colors from './Colors';

/**
 * Global CSS
 *
 * 	@global is handled by jss-plugin-global.
 */
export default withStyles({
    '@global': {
        // Table
        '.MuiTableCell-sizeSmall': {
            paddingLeft: 10,
            paddingRight: 10,
        },

        // AppBar
        '.MuiAppBar-colorPrimary': {
            backgroundColor: '#9eb319',
        },

        // Links
        '.MuiTypography-colorPrimary': {
            color: '#788a08',
        },
        '.MuiTypography-colorPrimary:hover': {
            color: '#687a02',
        },
        'a:hover': {
            cursor: 'pointer',
        },

        // Buttons
        '.MuiButton-label': {
            color: '#495e0b',
        },
        '.MuiButton-outlinedPrimary': {
            borderColor: colors.A8,
        },
        '.MuiButton-outlinedPrimary:hover': {
            borderColor: colors.A7,
        },
        '.MuiButton-containedPrimary': {
            backgroundColor: colors.A8,
        },
        '.MuiButton-containedPrimary .MuiButton-label': {
            color: '#fff',
        },
        '.MuiButton-contained.button-delete': {
            backgroundColor: '#f33',
        },
        '.MuiButton-contained.button-delete:hover': {
            backgroundColor: '#ca0e22',
        },
        '.MuiButton-contained.button-delete:disabled': {
            backgroundColor: '#e0e0e0',
        },
        '.MuiButton-contained.button-delete .MuiButton-label': {
            color: '#fff',
        },
        '.MuiButton-containedPrimary:hover': {
            backgroundColor: colors.A9,
        },

        // Form input
        '.MuiInputBase-root': {
            backgroundColor: 'white',
        },

        // Dialogs
        '.MuiDialogContent-root': {
            backgroundColor: '#f7f7f7',
        },

        // Tooltips
        '.MuiTooltip-tooltip': {
            fontSize: 14,
        },

        '.MuiFab-primary': {
            backgroundColor: colors.A7,
        },
        '.MuiFab-primary:hover': {
            backgroundColor: colors.A8,
        },
    },
})(() => null);
