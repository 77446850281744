import { useContext } from 'react';
import { useAPI } from '../lib/hooks';
import { AppContext } from '../sporttia/AppContext';
import useRestService from './RestService';

const resource = '/cores';

/**
 * Hook to access all 'cores' resources.
 */
export default function useCoresService() {
    const { migrationApis } = useContext(AppContext);
    const [request] = useAPI(migrationApis);
    const services = useRestService(resource);

    /**
     * GET /cores/triggers/scs request.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function getCoresTriggersBySc(params) {
        return request('GET', `${resource}/triggers/scs`, params);
    }

    /**
     * GET /cores/triggers/executions request.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function getCoresTriggersExecutions(params) {
        const requestParams = Object.fromEntries(
            Object.entries(params).filter(([, value]) => value !== ''),
        );

        return request('GET', `${resource}/triggers/executions`, requestParams);
    }

    /**
     * POST /cores/triggers/executions/:id/runs request.
     * @param id Core trigger execution ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function createCoresTriggersExecutionsRun(id, params) {
        return request(
            'POST',
            `${resource}/triggers/executions/${id}/runs`,
            params,
        );
    }

    /**
     * POST /cores/triggers/executions/runs request.
     * @param id Core trigger execution ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function coresTriggersMaxExecutionRun({
        scId = null,
        maxExecutions = 100,
    }) {
        return request(
            'POST',
            `${resource}/triggers/executions/runs?scId=${scId}&maxExecutions=${maxExecutions}`,
        );
    }

    return {
        ...services,
        getCoresTriggersBySc,
        getCoresTriggersExecutions,
        createCoresTriggersExecutionsRun,
        coresTriggersMaxExecutionRun,
    };
}
