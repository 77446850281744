import React, { useState, useContext } from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import {
    AppContext,
    SttLabelForm,
    SttFormText,
    SttFormTextarea,
    SttFormPrice,
    SttLabelDate,
    SttLabelLink,
} from '../../../sporttia/all';
import { SttChipRate } from '../../../sporttia/chips/SttChipRate';
import Mship from '../../../pages/sc/mships/Mship';
import { fullName, formatPriceByLocale } from '../../../lib/utils';
import DateBuilder from '../../../lib/DateBuilder';

export default function BookingDialogDetail({ booking, setBooking }) {
    const cxt = useContext(AppContext);

    const [viewUserId, setViewUserId] = useState(null);

    /**
     * Handle form
     */
    const handleForm = ({ name, value }) => {
        setBooking({ ...booking, [name]: value });
    };

    // Algo raro pasa
    if (!booking.user) {
        return null;
    }

    // -----| Render |-----
    return (
        <Grid container spacing={3}>
            <SttLabelForm
                grid
                title={cxt.t('Created')}
                md={4}
                text={new DateBuilder(
                    booking.created,
                    cxt?.sc?.timezone?.name,
                    cxt?.lang?.key,
                ).dmyhm()}
            />

            <SttLabelForm
                grid
                title={cxt.t('Operator')}
                md={4}
                text={fullName(booking.creator)}
            />

            <SttLabelForm
                grid
                title={cxt.t('Facility')}
                md={4}
                text={booking.facility.name}
            />

            <SttLabelForm
                grid
                title={cxt.t('Date')}
                md={4}
                text={<SttLabelDate date={booking.ini} />}
            />

            <SttLabelForm
                grid
                title={cxt.t('Time')}
                md={4}
                text={`${moment(booking.ini).format('H:mm')} - ${moment(
                    booking.end,
                ).format('H:mm')}`}
            />
            <SttLabelForm
                grid
                title={cxt.t('PaymentStatus')}
                md={4}
                text={`${cxt.t('PS.NOT_PAID')} ${formatPriceByLocale(
                    booking && booking.price - booking.totalPaid,
                    booking?.priceShape?.currency,
                    booking?.priceShape?.locale,
                )} / ${cxt.t('PS.PAID')}: ${formatPriceByLocale(
                    booking?.totalPaid,
                    booking?.totalPaidShape?.currency,
                    booking?.totalPaidShape?.locale,
                )}`}
            />

            {booking.cancelator && (
                <SttLabelForm
                    grid
                    title={cxt.t('Cancelator')}
                    md={4}
                    text={booking.cancelator ? booking.cancelator.fullName : ''}
                />
            )}

            {/**/}

            {booking.user && booking.user.mship ? (
                <SttLabelLink
                    grid
                    xs={12}
                    md={booking.cancelator ? 4 : 8}
                    title={cxt.t('User')}
                    text={booking.user.mship.name}
                    onClick={() => setViewUserId(booking.user.mship.id)}
                />
            ) : (
                <SttFormText
                    disabled
                    grid
                    md={booking.cancelator ? 4 : 8}
                    name="user.name"
                    caption={cxt.t('User')}
                    defVal={booking.user.name}
                    onChange={handleForm}
                />
            )}
            <SttFormPrice
                grid
                xs={12}
                sm={12}
                md={4}
                name="price"
                caption={cxt.t('Price')}
                defVal={booking.price}
                onChange={handleForm}
            />

            <SttFormTextarea
                grid
                name="notes"
                caption={cxt.t('Notes')}
                defVal={booking.notes}
                onChange={handleForm}
            />

            {booking && booking.rate && (
                <SttChipRate
                    grid
                    md={7}
                    rates={booking && booking.rate}
                    title={cxt.t('Rates')}
                />
            )}

            {viewUserId && (
                <Mship
                    id={viewUserId}
                    open
                    onClose={() => setViewUserId(null)}
                />
            )}
        </Grid>
    );
}
