import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Box, Paper, Divider, TextField } from '@material-ui/core';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import {
    AppContext,
    SttLabelForm,
    SttFormPeriod,
    SttFormText,
    SttFormSelect,
    SttSelectInterval,
    SttFormTime,
    SttSelectGroup,
    SttButtonMonths,
    SttFacilityList,
    SttButtonSave,
    SttButtonDelete,
    SttList,
    SttLabelWeekdays,
    SttButtonRecover,
    SttButtonCancel,
    SttFullDialog,
} from '../../../sporttia/all';
import { subsetObject, updateElementInArray } from '../../../lib/utils';
import FreeScheduleSlotDialog from './FreeScheduleSlotDialog';
import { SPORTCENTER_FREE_SCHEDULES } from '../../../lib/queryKeys';
import { useTranslations, useFormatDate } from '../../../lib/hooks';
import DateBuilder from '../../../lib/DateBuilder';

export default function FreeSchedule({ idFreeSchedule, onClose }) {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const queryClient = useQueryClient();
    const [creating, setCreating] = useState(false);
    const [title, setTitle] = useState(null);
    const { translate } = useTranslations();
    const [freeSchedule, setFreeSchedule] = useState();
    const [facilities, setFacilities] = useState();
    const [slots, setSlots] = useState();
    const [removeSeconds] = useFormatDate();
    const [openedSlotId, setOpenedSlotId] = useState(null);

    /**
     * Load free schedule
     */
    const loadFreeSchedule = () => {
        cxt.api('GET', `/frees/schedules/${idFreeSchedule}`, {
            params: {
                trash: true,
            },
            success: (r) => {
                if (!r.freeSchedule.hasOwnProperty('months'))
                    r.freeSchedule.months = [];
                setFreeSchedule(r.freeSchedule);
                setFacilities(r.freeSchedule.facilities);
                setSlots(r.freeSchedule.slots);
                setTitle(r.freeSchedule.name);
                // Set creating
                setCreating(false);
            },
        });
    };

    /**
     * Save free schedule
     */
    const saveFreeSchedule = () => {
        const params = subsetObject(freeSchedule, [
            'name',
            'ini',
            'end',
            'status',
            'layer',
            'capacity',
            'interval',
            'preBookingIni',
            'preBookingEnd',
            'preBookingIniTime',
            'months',
        ]);

        // Set mandatory group
        if (freeSchedule.mandatory)
            params.idMandatory = freeSchedule.mandatory.id;

        // Create
        if (creating) {
            cxt.api('POST', `/scs/${cxt.sc.id}/frees/schedules`, {
                params,
                success: (r) => {
                    cxt.showMessage('S', cxt.t('Created'));
                    if (!r.freeSchedule.hasOwnProperty('months'))
                        r.freeSchedule.months = [];
                    setFreeSchedule(r.freeSchedule);
                    setFacilities(r.freeSchedule.facilities);
                    setSlots(r.freeSchedule.slots);
                    setTitle(freeSchedule.name);
                    setCreating(false);
                    queryClient.invalidateQueries(SPORTCENTER_FREE_SCHEDULES);
                },
            });

            // Update
        } else {
            cxt.api('PUT', `/frees/schedules/${freeSchedule.id}`, {
                params,
                success: (r) => {
                    cxt.showMessage('S', cxt.t('Saved'));
                    if (title != freeSchedule.name) setTitle(freeSchedule.name);
                },
            });
        }
    };

    /**
     * Delete free schedule
     */
    const delFreeSchedule = () => {
        cxt.api('DELETE', `/frees/schedules/${freeSchedule.id}`, {
            confirmation: true,
            success: (r) => {
                cxt.showMessage('S', cxt.t('Deleted'));
                queryClient.invalidateQueries(SPORTCENTER_FREE_SCHEDULES);
                onClose();
            },
        });
    };

    /**
     * Recover free schedule
     */
    const recoverFreeSchedule = () => {
        cxt.api('PUT', `/frees/schedules/${freeSchedule.id}/recover`, {
            confirmation: true,
            success: (r) => {
                cxt.showMessage('S', cxt.t('Recovered'));
                loadFreeSchedule();
            },
        });
    };

    /**
     * Handle form
     */
    const handleForm = ({ name, value }) => {
        if (name === 'capacity' && value === '') value = 0;
        setFreeSchedule({ ...freeSchedule, [name]: value });
    };

    /**
     * On month updated
     */
    const onMonthUpdated = (month) => {
        // Set it off
        if (freeSchedule.months.includes(month)) {
            const newMonths = [...freeSchedule.months];
            newMonths.splice(
                newMonths.findIndex((e) => e === month),
                1,
            );
            setFreeSchedule({ ...freeSchedule, months: newMonths });

            // Set it on
        } else {
            setFreeSchedule({
                ...freeSchedule,
                months: freeSchedule.months.concat([month]),
            });
        }
    };

    /**
     * Add facility
     */
    const addFacility = (facility) => {
        cxt.api(
            'POST',
            `/frees/schedules/${freeSchedule.id}/facilities/${facility.id}`,
            {
                success: (r) => {
                    const newFacilities = [...(facilities || [])];
                    newFacilities.push(facility);
                    setFacilities(newFacilities);
                },
            },
        );
    };

    /**
     * Delete facility
     */
    const delFacility = (facility) => {
        cxt.api(
            'DELETE',
            `/frees/schedules/${freeSchedule.id}/facilities/${facility.id}`,
            {
                confirmation: true,
                success: (r) => {
                    const newFacilities = [...facilities];
                    newFacilities.splice(
                        newFacilities.findIndex((e) => e.id === facility.id),
                        1,
                    );
                    setFacilities(newFacilities);
                },
            },
        );
    };

    /**
     * Open adding slot
     */
    const openAddingSlot = () => {
        setOpenedSlotId('create');
    };

    /**
     * Delete slot
     */
    const delSlot = (slot) => {
        cxt.api('DELETE', `/frees/schedules/slots/${slot.id}`, {
            confirmation: true,
            success: (r) => {
                const newSlots = [...slots];
                newSlots.splice(
                    newSlots.findIndex((e) => e.id === slot.id),
                    1,
                );
                setSlots(newSlots);
            },
        });
    };

    /**
     * Init
     */
    useEffect(() => {
        if (idFreeSchedule) {
            if (idFreeSchedule === 'create') {
                // Init data to create
                setFreeSchedule({
                    status: 'ACTIVE',
                    layer: 0,
                    months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                    ini: `${moment().format('YYYY')}-01-01`,
                    end: `${moment().format('YYYY')}-12-31`,
                    capacity: 0,
                    interval: 60,
                    preBookingIni: '0000-00-00',
                    preBookingIniTime: '00:00',
                    preBookingEnd: '0000-00-00T00:00:00',
                    mandatory: null,
                });

                setTitle(translate('New'));
                // Set status and header
                setCreating(true);
            } else {
                loadFreeSchedule();
            }
        }
    }, []);

    // -----| Render |-----
    return (
        <SttFullDialog open title={title} onClose={onClose}>
            <Box>
                <Paper>
                    <Box p={3}>
                        {freeSchedule && (
                            <>
                                {!creating && (
                                    <>
                                        <Grid container spacing={3}>
                                            <SttLabelForm
                                                grid
                                                md={2}
                                                title={cxt.t('Created')}
                                                text={new DateBuilder(
                                                    freeSchedule.created,
                                                    cxt?.sc?.timezone?.name,
                                                    cxt?.lang?.key,
                                                ).dmyhm()}
                                            />
                                        </Grid>

                                        <Box my={4}>
                                            <Divider />
                                        </Box>
                                    </>
                                )}

                                <Grid container spacing={3}>
                                    <SttFormText
                                        grid
                                        md={9}
                                        sm={12}
                                        xs={12}
                                        name="name"
                                        caption={cxt.t('Name')}
                                        defVal={freeSchedule.name}
                                        onChange={handleForm}
                                    />

                                    <SttFormSelect
                                        grid
                                        md={3}
                                        sm={12}
                                        xs={12}
                                        name="status"
                                        caption={cxt.t('Status')}
                                        defVal={freeSchedule.status}
                                        onChange={handleForm}
                                        options={cxt.constants.scheduleStatuses}
                                    />

                                    <SttFormPeriod
                                        grid
                                        md={6}
                                        sm={12}
                                        xs={12}
                                        caption={cxt.t('Period')}
                                        nameIni="ini"
                                        nameEnd="end"
                                        defValIni={freeSchedule.ini}
                                        defValEnd={freeSchedule.end}
                                        onChange={handleForm}
                                    />

                                    <SttFormSelect
                                        grid
                                        md={2}
                                        sm={6}
                                        xs={6}
                                        name="layer"
                                        caption={cxt.t('Layer')}
                                        defVal={freeSchedule.layer}
                                        onChange={handleForm}
                                        options={cxt.constants.freeLayers}
                                    />

                                    <SttFormText
                                        grid
                                        md={2}
                                        sm={4}
                                        xs={12}
                                        name="capacity"
                                        caption={cxt.t('Capacity')}
                                        defVal={freeSchedule.capacity}
                                        onChange={handleForm}
                                    />

                                    <SttSelectInterval
                                        grid
                                        md={2}
                                        sm={4}
                                        xs={4}
                                        caption={cxt.t('Interval')}
                                        defVal={freeSchedule.interval}
                                        onChange={(minutes) =>
                                            handleForm({
                                                name: 'interval',
                                                value: minutes,
                                            })
                                        }
                                        min={5}
                                        max={1435} /* 23:55 horas */
                                        interval={5}
                                    />

                                    <SttFormSelect
                                        grid
                                        md={4}
                                        sm={12}
                                        xs={12}
                                        name="preBookingIni"
                                        caption={cxt.t('PeriodRenting')}
                                        defVal={freeSchedule.preBookingIni}
                                        onChange={handleForm}
                                        options={
                                            cxt.constants
                                                .bookingInAdvanceIniIntervals
                                        }
                                    />

                                    <SttFormTime
                                        grid
                                        md={3}
                                        sm={6}
                                        xs={6}
                                        name="preBookingIniTime"
                                        caption={cxt.t('Time')}
                                        defVal={freeSchedule.preBookingIniTime}
                                        interval={30}
                                        disableClearable={false}
                                        onChange={handleForm}
                                    />

                                    <SttFormSelect
                                        grid
                                        md={5}
                                        sm={6}
                                        xs={6}
                                        name="preBookingEnd"
                                        caption={cxt.t('Even')}
                                        defVal={freeSchedule.preBookingEnd}
                                        onChange={handleForm}
                                        options={
                                            cxt.constants
                                                .bookingInAdvanceEndIntervals
                                        }
                                    />

                                    {/* --- Mandatory group --- */}
                                    <SttSelectGroup
                                        grid
                                        md={6}
                                        sm={12}
                                        xs={12}
                                        name="mandatory"
                                        caption={cxt.t('MandatoryGroup')}
                                        defVal={freeSchedule.mandatory}
                                        onChange={handleForm}
                                    />

                                    <Grid item md={6} sm={12} xs={12}>
                                        <SttButtonMonths
                                            tinyLabels
                                            multipleSelection
                                            selectedMonths={freeSchedule.months}
                                            onChangeMonth={onMonthUpdated}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Box>
                </Paper>

                {freeSchedule && !freeSchedule.trash && !creating && (
                    <Box mt={3}>
                        <Grid container spacing={3}>
                            <Grid item md={6} sm={12} xs={12}>
                                <Paper>
                                    <SttFacilityList
                                        facilities={facilities}
                                        onAdd={(facility) =>
                                            addFacility(facility)
                                        }
                                        onDelete={(facility) =>
                                            delFacility(facility)
                                        }
                                    />
                                </Paper>
                            </Grid>

                            <Grid item md={6} sm={12} xs={12}>
                                <Paper>
                                    <SttList
                                        title={cxt.t('Slots')}
                                        onAdd={() => openAddingSlot()}
                                        onDelete={delSlot}
                                        onSelect={(slot) =>
                                            setOpenedSlotId(slot.id)
                                        }
                                        data={
                                            slots &&
                                            slots.map((slot) => ({
                                                caption: (
                                                    <Box display="flex">
                                                        <SttLabelWeekdays
                                                            weekdays={
                                                                slot.weekdays
                                                            }
                                                        />

                                                        <Box ml={2}>
                                                            {removeSeconds(
                                                                slot.ini,
                                                            )}{' '}
                                                            -{' '}
                                                            {removeSeconds(
                                                                slot.end,
                                                            )}
                                                        </Box>
                                                    </Box>
                                                ),
                                                objToSelect: slot,
                                                chips:
                                                    slot.prices &&
                                                    slot.prices.map(
                                                        (price) => ({
                                                            caption: price.name,
                                                        }),
                                                    ),
                                            }))
                                        }
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                )}

                {freeSchedule && (
                    <Box mt={3}>
                        <Grid container spacing={3}>
                            {freeSchedule.trash && (
                                <Grid item md={3} sm={12} xs={12}>
                                    <SttButtonRecover
                                        fullWidth
                                        onClick={() => recoverFreeSchedule()}
                                    />
                                </Grid>
                            )}

                            {!freeSchedule.trash && (
                                <Grid item md={3} sm={12} xs={12}>
                                    <SttButtonSave
                                        fullWidth
                                        onClick={() => saveFreeSchedule()}
                                    />
                                </Grid>
                            )}

                            {!creating && !freeSchedule.trash && (
                                <Grid item md={3} sm={12} xs={12}>
                                    <SttButtonDelete
                                        fullWidth
                                        onClick={() => delFreeSchedule()}
                                    />
                                </Grid>
                            )}

                            {creating && (
                                <Grid item md={3} sm={12} xs={12}>
                                    <SttButtonCancel
                                        fullWidth
                                        onClick={() => {
                                            onClose();
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                )}

                <FreeScheduleSlotDialog
                    open={openedSlotId != null}
                    idSlot={openedSlotId}
                    freeSchedule={freeSchedule}
                    onUpdate={(slot) =>
                        setSlots(updateElementInArray(slots, slot))
                    }
                    onClose={() => setOpenedSlotId(null)}
                />
            </Box>
        </SttFullDialog>
    );
}
