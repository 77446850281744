import React, { PropsWithChildren } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    label: {
        fontSize: 18,
    },
});

type SttLabelTitleProps = PropsWithChildren<{
    style?: React.CSSProperties;
}>;

export function SttLabelTitle({ style, children }: SttLabelTitleProps) {
    const classes = useStyles();

    return (
        <Typography className={classes.label} style={style}>
            {children}
        </Typography>
    );
}
