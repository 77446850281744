import React, { useState, useContext, Fragment } from 'react';
import {
    Paper,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Divider,
    ListSubheader,
    ListItemAvatar,
    ListItemIcon,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { AppContext, SttPagination } from '../all';
import ModalConfirmation from '../../layout/ModalConfirmation';

/**
 * List component
 * -------------
 *
 * Props
 *
 * 	* string title
 * 	* array data: components of each data element:
 *
 *		- string|Component caption: title
 *		- string text: secondary text
 *		- <Avatar> avatar
 *		- Chip[] chips: listado de duplas del tipo {caption}
 *		- Object objToSelect: en caso de que se use "onSelect", esta función
 *			devuelve este objeto.
 *		- boolean selected
 *
 *	* integer numRows: number of result to show. If row count is greater than
 *		this number then a pagination coomponent shows up
 * 	* function onSelect(obj): si se establece se podrá seleccionar un elemento de la lista.
 *	* function onAdd: if it exists add adding button at the top-right corner
 *	* function onDelete(obj)
 *	* boolean paper: wrap it on paper
 */
export function SttList({
    title,
    paper = false,
    data = [],
    onSelect,
    onDelete,
    confirmationDelete = false,
    numRows,
    onAdd,
}) {
    const cxt = useContext(AppContext);
    const [page, setPage] = useState(1);
    const [confirmationDialog, setConfirmationDialog] = useState({
        show: false,
    });

    const renderChips = (listItem) =>
        listItem.chips &&
        listItem.chips.map((chip, idx) => (
            <span
                key={idx}
                style={{
                    padding: 4,
                    fontSize: 10,
                    backgroundColor: '#aaa',
                    color: 'white',
                    marginRight: 4,
                    marginTop: 4,
                }}
            >
                {chip.caption}
            </span>
        ));

    const handleOnSelect = (item) => {
        if (onSelect && item.objToSelect) {
            onSelect(item.objToSelect);
        }
    };

    const handleOnDelete = (item) => {
        if (onDelete && item.objToSelect) {
            onDelete(item.objToSelect);
        }
    };

    const pagination = (
        <SttPagination
            page={page}
            pages={Math.ceil(data.length / numRows)}
            count={data.length}
            onChangePage={setPage}
        />
    );

    const getPaginatedData = () => {
        if (numRows) {
            const idxIni = (page - 1) * numRows;
            const idxEnd = page * numRows;

            return data.filter((e, i) => i >= idxIni && i < idxEnd);
        }

        return data;
    };

    const content = (
        <List
            subheader={
                <ListSubheader component="div">
                    {title}

                    {onAdd && (
                        <IconButton
                            style={{
                                position: 'absolute',
                                right: 5,
                            }}
                            onClick={onAdd}
                        >
                            <AddIcon />
                        </IconButton>
                    )}
                </ListSubheader>
            }
        >
            {numRows && data.length > numRows ? pagination : null}

            {data &&
                getPaginatedData().map((item, i) => (
                    <Fragment key={i}>
                        <Divider />
                        <ListItem
                            style={{
                                alignItems: 'flex-start',
                                backgroundColor: item.selected
                                    ? '#faffe9'
                                    : null,
                            }}
                            button={onSelect !== null && onSelect !== undefined}
                            onClick={() => handleOnSelect(item)}
                        >
                            {item.avatar && (
                                <ListItemAvatar>{item.avatar}</ListItemAvatar>
                            )}

                            <ListItemText
                                primary={item.caption}
                                secondary={
                                    <>
                                        {item.text}
                                        {renderChips(item)}
                                    </>
                                }
                            />

                            {onDelete && onDelete.constructor === Function && (
                                <ListItemSecondaryAction>
                                    <IconButton
                                        edge="end"
                                        onClick={
                                            confirmationDelete
                                                ? () =>
                                                      setConfirmationDialog({
                                                          show: true,
                                                          item,
                                                      })
                                                : () => handleOnDelete(item)
                                        }
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            )}
                        </ListItem>
                    </Fragment>
                ))}

            {((data && data.length === 0) || !data) && (
                <ListItem>
                    <ListItemIcon>
                        <ErrorOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary={cxt.t('NoResults')} />
                </ListItem>
            )}
        </List>
    );

    const confirmationModal = (
        <ModalConfirmation
            show={confirmationDialog.show}
            onClose={() => setConfirmationDialog({ show: false })}
            onAccept={() => {
                handleOnDelete(confirmationDialog.item);
                setConfirmationDialog({ show: false });
            }}
        />
    );

    // Render
    if (paper) {
        return (
            <Paper>
                {content}
                {confirmationModal}
            </Paper>
        );
    }

    return (
        <>
            {content}
            {confirmationModal}
        </>
    );
}
