import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Paper, Box, Link, Grid } from '@material-ui/core';
import useKey from '@rooks/use-key';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import {
    AppContext,
    SttButton,
    SttFormPassword,
    SttFormText,
} from '../../sporttia/all';
import LogoHeading from '../../layout/LogoHeading';
import { getPath } from '../Pages';
import { useQuery, useTranslations } from '../../lib/hooks';
import useUsersService from '../../services/UsersService';
import Input2FA from '../../components/Input2FA';
import translations from '../../translations';

/**
 * Login page
 * ==========
 *
 * Query:
 *
 *	* token: if it's set, login automatically
 * 	* redirect: after loggin in, redirect
 */
export default function Login(props) {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const { token, redirect } = useQuery();
    const [loginLoading, setLoginLoading] = useState(false);
    const [inProgress2FA, setInProgress2FA] = useState(false);
    const [text2FA, setText2FA] = useState(null);
    const { translate } = useTranslations();

    const twoFactorAuthMutation = useUsersService().usePost2FACode();
    /**
     * Press enter
     */
    useKey(['Enter'], () => {
        doLogin();
    });

    const processLoginSuccess = (response) => {
        setLoginLoading(false);
        if (response.user) {
            if (response.user.role === cxt.constants.roleAdmin) {
                cxt.setLoggedUser(response.user); /* .then(() => {
							redirectUser();
						}); */
                // redirectUser();
            } else {
                switch (response.user.status) {
                    case 'WAIT_EMAIL':
                        // Redirect to the "please verify your email" page with user obj as state param
                        history.push('/email/activation', {
                            user: response.user,
                        });
                        break;
                    case 'NOT_CONFIRM':
                        // Redirect to TOS confirmation page
                        history.push(
                            `/privacy/confirmation/${response.user.id}/${response.user.signature}`,
                        );
                        break;
                    case 'ACTIVE':
                        cxt.setLoggedUser(response.user); /* .then(() => {
									redirectUser();
								}); */
                        // redirectUser();
                        break;
                }
            }
        } else if (response.twoFactorCodeSent) {
            setInProgress2FA(true);
            setText2FA(response.text2FA);
        }
    };

    /**
     * Attempt to login
     */
    const doLogin = () => {
        setLoginLoading(true);
        cxt.api('POST', '/login', {
            params: {
                login,
                password,
            },
            error: (r) => {
                cxt.showMessage('E', r.error.msg);
                setLoginLoading(false);
            },
            success: (r) => {
                processLoginSuccess(r);
            },
        });
    };

    // const redirectUser = () => {
    // 	if (redirect) {
    // 		history.push(decodeURIComponent(redirect))
    // 	} else if (props.location.state && props.location.state.from && props.location.state.from.pathname) {
    // 		history.push(props.location.state.from.pathname);
    // 	} else {
    // 		history.push('/');
    // 	}
    // }

    /**
     * Cuando el usuario se loguea se redirige a la pagina inicial dado su rol o a la página que anteriormente
     * consultó pero que necesitaba estar logueado para entrar.
     */
    useEffect(() => {
        if (cxt.user && cxt.user.id) {
            if (redirect) {
                history.push(decodeURIComponent(redirect));
            } else if (
                props.location.state &&
                props.location.state.from &&
                props.location.state.from.pathname
            ) {
                history.push(props.location.state.from.pathname);
            } else {
                history.push('/');
            }
        }
    }, [cxt.user]);

    // /**
    //  * Trying to automatically log in and redirect
    //  */
    // useEffect(() => {
    //
    // 	if (token && redirect) {
    //
    // 		// login with token and redirect
    // 		cxt.api('POST', '/login', {
    // 			params: {
    // 				token: decodeURIComponent(token)
    // 			},
    // 			success: (r) => {
    //
    // 				cxt.setLoggedUser(r.user);
    //
    // 				// JPB, 30 jun 2020. This is a patch, there must be a better way
    // 				// to do this but I'm so rush
    // 				setTimeout(() => {
    // 					history.push(decodeURIComponent(redirect))
    // 				}, 3000)
    // 			}
    // 		});
    // 	}
    //
    // }, [token, redirect])

    // Render
    return (
        <Container maxWidth="xs">
            <Box mt={9}>
                <Paper>
                    <Box p={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <LogoHeading
                                    caption={cxt.t('SignInOnSporttia')}
                                />
                            </Grid>

                            {/* --- Login & password --- */}
                            <Grid item xs={12}>
                                <SttFormText
                                    autoFocus
                                    name="login"
                                    caption={cxt.t('page.login.loginOrEmail')}
                                    defVal={login}
                                    onChange={({ value }) => setLogin(value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SttFormPassword
                                    name="password"
                                    caption={cxt.t('Password')}
                                    defVal={password}
                                    onChange={({ value }) => setPassword(value)}
                                />
                            </Grid>

                            {/* --- Button login --- */}
                            <Grid item xs={12}>
                                <SttButton
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    startIcon={<SentimentSatisfiedAltIcon />}
                                    disabled={loginLoading}
                                    loading={loginLoading}
                                    onClick={() => doLogin()}
                                >
                                    {cxt.t('page.login.Enter')}
                                </SttButton>
                            </Grid>

                            {/* --- Recover password --- */}
                            <Grid item xs={12}>
                                <Box>
                                    <Link href="/recover" variant="body2">
                                        {cxt.t('RecoverPassword')}
                                    </Link>
                                </Box>
                            </Grid>

                            {/* --- Sign up --- */}
                            <Grid item xs={12}>
                                <Box>
                                    <Link
                                        href={`${getPath(
                                            'signup',
                                        )}?redirect=${redirect}`}
                                        variant="body2"
                                    >
                                        {`${cxt.t(
                                            'app.login.noAccount',
                                        )} ${cxt.t('RegisterNow')}`}
                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
            {inProgress2FA && (
                <Input2FA
                    text2FA={text2FA}
                    onSave={(code) => {
                        twoFactorAuthMutation.mutate(
                            {
                                params: {
                                    twoFactorCode: Number.parseInt(code),
                                    login,
                                },
                            },
                            {
                                onError: (error) => {
                                    cxt.showMessage('E', error.msg);
                                },
                                onSuccess: (response) => {
                                    processLoginSuccess(response);
                                },
                            },
                        );
                    }}
                    onResentCode={() => {
                        twoFactorAuthMutation.mutate(
                            {
                                params: {
                                    resent2FACode: true,
                                    login,
                                },
                            },
                            {
                                onError: (error) => {
                                    cxt.showMessage('E', error.msg);
                                },
                                onSuccess: () => {
                                    cxt.showMessage(
                                        'S',
                                        translate(
                                            translations.user.resent2FASuccess,
                                        ),
                                    );
                                },
                            },
                        );
                    }}
                    onClose={() => setInProgress2FA(false)}
                />
            )}
        </Container>
    );
}
