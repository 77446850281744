import React, { useEffect, useContext } from 'react';
import { Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
    AppContext,
    SttButtonFab,
    SttChipFacilityRow,
    SttChipRestrictionType,
    SttTable,
    SttTopControls,
} from '../../../sporttia/all';
import { useToggle, useTuples, useCrud } from '../../../lib/hooks';
import { getPath } from '../../Pages';

export default function Restrictions() {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const [restrictions, setRestrictions, , Get] = useCrud();
    const [filters, setFilter] = useTuples({
        name: '',
    });
    const [trash, toggleTrash] = useToggle(() => {
        // eslint-disable-next-line no-use-before-define
        loadRestrictions();
    });
    /**
     * Load restrictions
     */
    function loadRestrictions(params = { rows: 20 }) {
        Get(`/scs/${cxt.sc.id}/bookings/restrictions`, {
            ...params,
            ...filters,
            trash,
        }).then(setRestrictions);
    }

    /**
     * Init
     */
    useEffect(() => {
        cxt.setHeader({
            title: cxt.t('Restrictions'),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Go to create a new one
     */
    function goCreateNew() {
        history.push(getPath('restriction', { id: 'create' }));
    }

    /**
     * Build num bookings column
     */
    const buildNumBookingsColumn = (restriction) => {
        let result = '';

        if (
            ['NUM_BOOKINGS', 'NUM_ACTIVE_BOOKINGS'].includes(restriction.type)
        ) {
            result = restriction.numBookings;
        }
        if (restriction.type === 'NUM_BOOKINGS') {
            result += ` / ${cxt.t(restriction.interval)}`;
        }

        return result;
    };

    // Render
    return (
        <Paper>
            <SttTopControls
                p={2}
                fields={[
                    {
                        caption: cxt.t('Name'),
                        type: 'text',
                        name: 'name',
                        value: filters.name,
                        onEnterKey: loadRestrictions,
                    },
                ]}
                onToggleTrash={toggleTrash}
                onChange={setFilter}
                onFilter={loadRestrictions}
            />
            <SttTable
                onFetch={loadRestrictions}
                data={restrictions}
                columns={[
                    {
                        title: cxt.t('Name'),
                        align: 'left',
                        type: 'link',
                        field: 'name',
                        to: (row) => getPath('restriction', { id: row.id }),
                        params: (row) => ({ trash: +row.trash }),
                    },
                    {
                        title: cxt.t('Type'),
                        type: 'text',
                        value: (row) => (
                            <SttChipRestrictionType type={row.type} />
                        ),
                    },
                    {
                        title: cxt.t('Validity'),
                        type: 'dmy',
                        field: 'dateend',
                    },
                    {
                        title: cxt.t('Bookings'),
                        type: 'text',
                        field: 'numBookingsPerInterval',
                        value: (row) => buildNumBookingsColumn(row),
                        width: 150,
                    },
                    {
                        title: cxt.t('Facilities'),
                        value: (row) => (
                            <SttChipFacilityRow
                                maxWidth={220}
                                facilities={row.facilities}
                            />
                        ),
                    },
                ]}
            />
            <SttButtonFab onClick={goCreateNew} />
        </Paper>
    );
}
