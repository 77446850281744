import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import {
    AppContext,
    SttDialog,
    SttFormDate,
    SttLabelForm,
    SttSelectMship,
    SttSelectUser,
} from '../../../sporttia/all';
import DateBuilder from '../../../lib/DateBuilder';

export default function RestrictionUserDialog({
    item,
    onSave,
    onDelete,
    onClose,
}) {
    const [userRestriction, setUserRestriction] = useState(item);

    const cxt = useContext(AppContext);

    return (
        <SttDialog
            maxWidth="sm"
            open={userRestriction !== undefined}
            title={cxt.t('RestrictionUser')}
            onClose={onClose}
            content={
                <Grid container spacing={3}>
                    {userRestriction.created && (
                        <Grid item xs={12}>
                            <SttLabelForm
                                title={cxt.t('Created')}
                                text={new DateBuilder(
                                    userRestriction.created,
                                    cxt?.sc?.timezone?.name,
                                    cxt?.lang?.key,
                                ).dmy()}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <SttSelectUser
                            controlDisabled={userRestriction.id !== undefined}
                            caption={cxt.t('User')}
                            name="user"
                            user={userRestriction.user}
                            onSelect={(user) =>
                                setUserRestriction({ ...userRestriction, user })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SttFormDate
                            caption={cxt.t('ValidFrom')}
                            name="ini"
                            defVal={userRestriction.ini}
                            onChange={({ name, value }) =>
                                setUserRestriction({
                                    ...userRestriction,
                                    ini: value,
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SttFormDate
                            caption={cxt.t('until')}
                            name="end"
                            defVal={userRestriction.end}
                            onChange={({ name, value }) =>
                                setUserRestriction({
                                    ...userRestriction,
                                    end: value,
                                })
                            }
                        />
                    </Grid>
                </Grid>
            }
            buttons={[
                userRestriction.id && {
                    type: 'delete',
                    onClick: () => onDelete(userRestriction),
                },
                {
                    type: 'save',
                    onClick: () => onSave(userRestriction),
                },
            ]}
        />
    );
}
