import React, { useContext, useState } from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import { AppContext } from '../AppContext';
import AdminCustomerSubscriptionDialog from '../../pages/admin/customers/customers/AdminCustomerSubscriptionDialog';
import { formatPriceByLocale } from '../../lib/utils';

const useStyles = makeStyles({
    genericBorder: {
        borderColor: '#C4C4C4',
    },
    borderRounded: {
        borderRadius: '5px',
    },
    cellTitle: {
        fontWeight: 'bold',
    },
    cellHoverData: {
        '&:hover': {
            backgroundColor: 'rgba(33, 150, 243, 0.5)',
            cursor: 'pointer',
        },
    },
    cellData: {
        fontSize: '13px',
        paddingLeft: '11px',
    },
    activeCell: {
        borderLeft: '3px solid rgba(33, 150, 243, 0.5)',
        backgroundColor: '#eceff1',
        paddingLeft: '8px',
    },
    editButton: {
        color: '#3F51B5',
        fontSize: 15,
        '&:hover': {
            color: 'black',
            cursor: 'pointer',
        },
    },
});

/**
 * Box with the customer's subscription list.
 * @param customerId Customer ID.
 * @param subscriptions Subscriptions list.
 * @param subscriptionSelected Selected subscription (appears in blue when selected).
 * @param onSelect Action taken when selecting subscription.
 * @param onChange Action taken when creating, deleting or updating a subscription.
 * @returns {JSX.Element}
 */
export default function SttSCSubscriptionsList({
    customerId,
    subscriptions,
    subscriptionSelected,
    onSelect,
    onChange,
}) {
    const cxt = useContext(AppContext);
    const classes = useStyles();
    const [openSubscriptionDialog, setOpenSubscriptionDialog] = useState(false);

    return (
        <>
            <Box
                className={`${classes.genericBorder} ${classes.borderRounded}`}
                border={1}
            >
                {/* Title row */}
                <Box
                    className={`${classes.genericBorder} ${classes.cellTitle}`}
                    borderBottom={1}
                    p={1}
                >
                    {cxt.t('admin.customer.subscriptions')}
                </Box>

                {/* Subscriptions rows */}
                {subscriptions &&
                    subscriptions.map((subscription) => (
                        <Box
                            key={subscription.id}
                            onClick={() => onSelect(subscription)}
                            className={`${
                                subscription &&
                                subscriptionSelected &&
                                subscription.id === subscriptionSelected.id
                                    ? classes.activeCell
                                    : ''
                            }
						 ${classes.cellData}
						 ${
                             subscription &&
                             subscriptionSelected &&
                             subscription.id !== subscriptionSelected.id
                                 ? classes.cellHoverData
                                 : ''
                         }`}
                            p={1}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={2} md={2} sm={2}>
                                    {moment(subscription.created).format(
                                        'DD MMM YYYY',
                                    )}
                                </Grid>
                                <Grid item xs={2} md={2} sm={2}>
                                    {subscription.plan}
                                </Grid>
                                <Grid item xs={3} md={3} sm={3}>
                                    {`${moment(subscription.dateini).format(
                                        'DD MMM YYYY',
                                    )} - ${moment(subscription.dateend).format(
                                        'DD MMM YYYY',
                                    )}`}
                                </Grid>
                                <Grid item xs={3} md={3} sm={3}>
                                    {`${formatPriceByLocale(
                                        subscription.fee ?? 0,
                                        subscription?.feeShape?.currency,
                                        subscription?.feeShape?.locale,
                                    )} / ${cxt.t(subscription.feeInterval)}`}
                                </Grid>
                                {subscription &&
                                    subscriptionSelected &&
                                    subscription.id ===
                                        subscriptionSelected.id && (
                                        <Grid
                                            item
                                            xs={2}
                                            md={2}
                                            sm={2}
                                            style={{ textAlign: 'center' }}
                                        >
                                            <EditIcon
                                                className={classes.editButton}
                                                onClick={() =>
                                                    setOpenSubscriptionDialog(
                                                        true,
                                                    )
                                                }
                                            />
                                        </Grid>
                                    )}
                            </Grid>
                        </Box>
                    ))}

                {/* Last row use to add new subscription */}
                <Box
                    className={`${classes.cellData} ${classes.cellHoverData}`}
                    p={1}
                    onClick={() => {
                        onSelect({ id: 'create' });
                        setOpenSubscriptionDialog(true);
                    }}
                >
                    <Grid container direction="row" alignItems="center">
                        <AddIcon /> {cxt.t('CreateSubscription')}
                    </Grid>
                </Box>
            </Box>

            <AdminCustomerSubscriptionDialog
                customerId={customerId}
                subscription={subscriptionSelected}
                open={openSubscriptionDialog}
                onClose={() => {
                    setOpenSubscriptionDialog(false);
                }}
                onDelete={() => {
                    setOpenSubscriptionDialog(false);
                    onChange();
                }}
                onUpdate={onChange}
                onCreate={onChange}
            />
        </>
    );
}
