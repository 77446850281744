import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { SttForm } from '../../../sporttia/SttForm';
import { AppContext } from '../../../sporttia/AppContext';
import { subsetObject } from '../../../lib/utils';

export default function ConfigurationWeb({}) {
    const cxt = useContext(AppContext);
    const [web, setWeb] = useState({});

    /**
     * Load sport center website.
     */
    const loadSportCenterWebSite = () => {
        cxt.api('GET', `/scs/${cxt.sc.id}/webs`, {
            success: (r) => {
                if (r.count > 0) {
                    setWeb(r.rows[0]);
                }
            },
        });
    };

    /**
     * Save sport center website.
     */
    const saveSportCenterWebSite = () => {
        const params = subsetObject(web, [
            'name',
            'description',
            'slogan',
            'address',
            'city',
            'email',
            'phone',
            'facebook',
            'twitter',
            'youtube',
            'rates',
            'availability',
        ]);

        if (web && web.id) {
            cxt.api('PUT', `/webs/${web.id}`, {
                params,
                success: () => {
                    cxt.showMessage('S', cxt.t('Saved'));
                },
            });
        }
    };

    /**
     * Handle form.
     * @param name Object key.
     * @param value Object value.
     */
    const handleForm = ({ name, value }) => {
        setWeb({ ...web, [name]: value });
    };

    /**
     * When we load "web" tab for first time we will get sport center website data from database.
     */
    useEffect(() => {
        loadSportCenterWebSite();
    }, []);

    // -----| Render |-----
    return (
        <Box>
            <SttForm
                onChangeForm={handleForm}
                elements={[
                    {
                        md: 12,
                        sm: 12,
                        xs: 12,
                        caption: cxt.t('Name'),
                        name: 'name',
                        value: web.name,
                    },
                    {
                        md: 12,
                        sm: 12,
                        xs: 12,
                        caption: cxt.t('Slogan'),
                        name: 'slogan',
                        value: web.slogan,
                    },
                    {
                        md: 12,
                        sm: 12,
                        xs: 12,
                        type: 'textarea',
                        caption: cxt.t('Description'),
                        name: 'description',
                        value: web.description,
                    },
                    {
                        md: 6,
                        sm: 6,
                        xs: 12,
                        caption: cxt.t('Address'),
                        name: 'address',
                        value: web.address,
                    },
                    {
                        md: 6,
                        sm: 6,
                        xs: 12,
                        caption: cxt.t('City'),
                        name: 'city',
                        value: web.city,
                    },
                    {
                        md: 6,
                        sm: 6,
                        xs: 12,
                        caption: cxt.t('Email'),
                        name: 'email',
                        value: web.email,
                    },
                    {
                        md: 6,
                        sm: 6,
                        xs: 12,
                        caption: cxt.t('Phone'),
                        name: 'phone',
                        value: web.phone,
                    },
                    {
                        md: 6,
                        sm: 6,
                        xs: 12,
                        caption: 'Facebook',
                        name: 'facebook',
                        value: web.facebook,
                    },
                    {
                        md: 6,
                        sm: 6,
                        xs: 12,
                        caption: 'Twitter',
                        name: 'twitter',
                        value: web.twitter,
                    },
                    {
                        md: 12,
                        sm: 12,
                        xs: 12,
                        caption: 'Youtube',
                        name: 'youtube',
                        value: web.youtube,
                    },
                    {
                        md: 12,
                        sm: 12,
                        xs: 12,
                        type: 'textarea',
                        caption: cxt.t('Rates'),
                        name: 'rates',
                        value: web.rates,
                    },
                    {
                        md: 12,
                        sm: 12,
                        xs: 12,
                        type: 'textarea',
                        caption: cxt.t('Availability'),
                        name: 'availability',
                        value: web.availability,
                    },
                ]}
                buttons={[
                    {
                        type: 'save',
                        onClick: () => saveSportCenterWebSite(),
                    },
                ]}
            />
        </Box>
    );
}
