import React from 'react';
import { usePageHeader } from '../../../../lib/hooks';
import Report from '../../../sc/Report';

/**
 * View where the sporttia superadmin can see the list of emails.
 * @returns {JSX.Element}
 */
function AdminEmails({}) {
    usePageHeader('emails');

    return <Report id={4} navigateBack="/home" />;
}

export default AdminEmails;
