import useRestService from './RestService';

const resource = '/cities';

/**
 * Hook to access all 'cities' resources.
 */
export default function useCitiesService() {
    const services = useRestService(resource);

    return {
        ...services,
    };
}
