import React, { useState, useRef, useContext } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { AppContext, SttDialog } from '../sporttia/all';
import { useTranslations } from '../lib/hooks';
import translations from '../translations';

interface TwoFAInputProps {
    text2FA: string;
    onSave: (twoFactorCode: string) => void;
    onClose: () => void;
    onResentCode: () => void;
}

export default function Input2FA({
    text2FA,
    onSave,
    onClose,
    onResentCode,
}: TwoFAInputProps) {
    const cxt = useContext(AppContext)!;
    const [code, setCode] = useState<string[]>(Array(6).fill(''));
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const { translate } = useTranslations();
    const handleCodeChange = (index: number, value: string) => {
        if (value !== '') {
            const numberParsed = Number.parseInt(value);
            if (numberParsed !== 0 && !numberParsed) {
                cxt.showMessage('E', 'Bad type of number');
                return;
            }
        }

        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);

        if (value && index < 5) {
            inputRefs.current[index + 1]?.focus();
        }
    };

    const handleKeyDown = (
        index: number,
        e: React.KeyboardEvent<HTMLInputElement>,
    ) => {
        if (e.key === 'Backspace' && !code[index] && index > 0) {
            inputRefs.current[index - 1]?.focus();
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const pastedData = e.clipboardData
            .getData('text')
            .substring(0, 6)
            .split('');
        const new2FACode = new Array(6).fill('');

        pastedData.forEach((digit: string, index: number) => {
            if (digit) {
                new2FACode[index] = Number.parseInt(digit);
            }
        });
        setCode(new2FACode);
        inputRefs.current[
            pastedData.length >= 6 ? 5 : pastedData.length
        ]?.focus();
    };

    const inputSlots2FAIds = [1, 2, 3, 4, 5, 6];
    const boxSlots2FAIds = [7, 8, 9, 10, 11, 12];
    return (
        <SttDialog
            title={translate(translations.user.label2FA)}
            open
            buttons={[
                {
                    type: 'save',
                    onClick: () => onSave(code.join('')),
                },
                {
                    component: (
                        <Button
                            variant="outlined"
                            onClick={() => onResentCode()}
                        >
                            {' '}
                            Reenviar código{' '}
                        </Button>
                    ),
                },
                {
                    type: 'close',
                    onClick: () => onClose(),
                },
            ]}
            content={
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <Box sx={{ alignContent: 'center', textAlign: 'center' }}>
                        <Typography>{text2FA}</Typography>
                    </Box>

                    <Box
                        sx={{ display: 'flex', justifyContent: 'center' }}
                        mt={5}
                    >
                        {code.map((digit, index) => (
                            <Box
                                key={boxSlots2FAIds[index]}
                                sx={{ display: 'flex' }}
                                padding={1}
                            >
                                <input
                                    key={inputSlots2FAIds[index]}
                                    ref={(el) => {
                                        inputRefs.current[index] = el;
                                    }}
                                    type="text"
                                    maxLength={1}
                                    value={digit}
                                    onChange={(e) => {
                                        handleCodeChange(index, e.target.value);
                                    }}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                    onPaste={handlePaste}
                                    style={{
                                        width: '50px',
                                        height: '70px',
                                        fontSize: '25px',
                                        textAlign: 'center',
                                    }}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>
            }
            onClose={onClose}
        />
    );
}
