import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Paper, Link } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import { AppContext, SttButtonFab } from '../../../../sporttia/all';
import { getPath } from '../../../Pages';
import AdminSC from './AdminSC';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import useScsService from '../../../../services/ScsService';
import { SUPERADMIN_SCS } from '../../../../lib/queryKeys';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';
import { usePageHeader, useQueryFilters } from '../../../../lib/hooks';

/**
 * View where the sporttia superadmin can see the list of centers.
 * @returns {JSX.Element}
 */
export default function AdminSCs({}) {
    usePageHeader('Centers');

    const cxt = useContext(AppContext);
    const history = useHistory();
    const [filters, setFilters] = useQueryFilters({}, SUPERADMIN_SCS);
    const [scId, setScId] = useState(null);
    const scsService = useScsService();
    const queryClient = useQueryClient();

    return (
        <>
            <Paper>
                <SttTopFilteringControls
                    p={2}
                    fields={[
                        {
                            caption: cxt.t('Name'),
                            name: 'name',
                            type: 'text',
                            value: '',
                        },
                    ]}
                    extraFields={[
                        {
                            caption: `ID ${cxt.t('SportCenter')}`,
                            name: 'id',
                            type: 'text',
                            value: '',
                        },
                        {
                            caption: cxt.t('Status'),
                            name: 'status',
                            type: 'select',
                            value: 'ACTIVE',
                            options: [
                                { id: 'ACTIVE', label: cxt.t('SCS.ACTIVE') },
                                {
                                    id: 'INACTIVE',
                                    label: cxt.t('SCS.INACTIVE'),
                                },
                                {
                                    id: 'UNSUSCRIBED',
                                    label: cxt.t('UnsubscribeShort'),
                                },
                            ],
                        },
                        {
                            caption: `${cxt.t('SportCenter')} visible`,
                            name: 'visible',
                            type: 'check',
                            checked: false,
                        },
                        {
                            caption: cxt.t('latheActivated'),
                            name: 'modDevice',
                            type: 'check',
                            checked: false,
                        },
                    ]}
                    onFilter={setFilters}
                    trashAction
                />

                <SttCachedTable
                    queryKey={SUPERADMIN_SCS}
                    queryFn={(params) => scsService.getList(params)}
                    queryParams={filters}
                    prefetching={false}
                    onClickRow={(row) => setScId(row?.id)}
                    columns={[
                        {
                            title: 'Id',
                            type: 'text',
                            value: (row) => row.id,
                        },
                        {
                            title: cxt.t('Status'),
                            type: 'text',
                            width: 215,
                            value: (row) => cxt.t(`FS.${row.status}`),
                        },
                        {
                            title: cxt.t('Name'),
                            align: 'left',
                            field: 'name',
                            value: (row) => row.name || row.short,
                        },
                        {
                            title: cxt.t('Followers'),
                            field: 'numFollowers',
                            type: 'counter',
                            value: (row) => row.numFollowers,
                        },
                        {
                            title: cxt.t('Visible'),
                            type: 'binaryStatus',
                            value: (row) => row.visible,
                        },
                        {
                            title: cxt.t('City'),
                            type: 'text',
                            width: 215,
                            value: (row) => row.city && row.city.name,
                        },
                        {
                            title: cxt.t('Booking'),
                            field: 'booking',
                            type: 'active',
                            width: 85,
                            value: (row) =>
                                row.modules && row.modules.includes('booking'),
                        },
                        {
                            title: cxt.t('Games'),
                            field: 'game',
                            type: 'active',
                            width: 85,
                            value: (row) =>
                                row.modules && row.modules.includes('game'),
                        },
                        {
                            title: cxt.t('Activity'),
                            field: 'activity',
                            type: 'active',
                            width: 85,
                            value: (row) =>
                                row.modules && row.modules.includes('activity'),
                        },
                        {
                            title: cxt.t('Purse'),
                            field: 'purse',
                            type: 'active',
                            width: 85,
                            value: (row) =>
                                row.modules && row.modules.includes('purse'),
                        },
                        {
                            title: cxt.t('Receipts'),
                            field: 'receipt',
                            type: 'active',
                            width: 85,
                            value: (row) =>
                                row.modules && row.modules.includes('receipt'),
                        },
                        {
                            title: cxt.t('Fees'),
                            field: 'fees',
                            type: 'active',
                            width: 85,
                            value: (row) => row.generateFees,
                        },
                        {
                            title: 'Tpv',
                            align: 'left',
                            field: 'name',
                            value: (row) =>
                                row.tpvConf && (
                                    <Link
                                        onClick={() =>
                                            history.push(
                                                getPath('adminTPVConf', {
                                                    id: row.tpvConf.id,
                                                }),
                                            )
                                        }
                                    >
                                        {row.tpvConf.name}
                                    </Link>
                                ),
                        },
                        {
                            title: 'Web',
                            field: 'web',
                            type: 'active',
                            width: 85,
                            value: (row) => row.web,
                        },
                    ]}
                />

                <SttButtonFab onClick={() => setScId('create')} />
            </Paper>

            <AdminSC
                id={scId}
                onClose={() => {
                    setScId(null);
                    queryClient.invalidateQueries(SUPERADMIN_SCS);
                }}
            />
        </>
    );
}
