import React, { useContext, useState } from 'react';
import { copyToClipboard } from '../../../lib/utils';
import { AppContext, SttTable, SttTopControls } from '../../../sporttia/all';
import ActivityRateDialog from './ActivityRateDialog';

export default function ActivityRates({ item, onSave }) {
    const cxt = useContext(AppContext);

    const [rate, setRate] = useState(null);

    const rates = item.rates
        ? { count: item.rates.length, rows: item.rates }
        : { rows: [], count: 0 };

    return (
        <>
            <SttTopControls
                mb={6}
                mainAction={{
                    type: 'create',
                    onClick: () =>
                        setRate({
                            ini: item.ini,
                            end: item.end,
                        }),
                }}
            />
            <SttTable
                autoload={false}
                hidePagination
                data={rates}
                columns={[
                    {
                        title: '#',
                        type: 'tooltip',
                        value: (row) => ({ label: '#', value: row.id }),
                        onClick: (row) => {
                            copyToClipboard(row.id, () =>
                                cxt.showMessage(
                                    'S',
                                    cxt.t('copiedToClipboard'),
                                ),
                            );
                        },
                    },
                    {
                        type: 'link',
                        title: cxt.t('Name'),
                        field: 'name',
                        align: 'left',
                        onClick: (row) => setRate(row),
                    },
                    {
                        title: cxt.t('Period'),
                        type: 'period',
                        value: (row) => ({ ini: row.ini, end: row.end }),
                    },
                    {
                        title: cxt.t('Enrollment'),
                        align: 'right',
                        type: 'price',
                        field: 'enrollment',
                    },
                    {
                        title: cxt.t('Fees'),
                        type: 'enrollment',
                        value: (row) => ({
                            fee: row.fee,
                            interval: row.period,
                        }),
                    },
                    {
                        title: cxt.t('Students'),
                        type: 'text',
                        value: (row) =>
                            `${row.numStudents} / ${row.maxStudents || '∞'}`,
                    },
                    {
                        title: cxt.t('SignupOnline'),
                        type: 'active',
                        field: 'signupOnline',
                    },
                    {
                        title: cxt.t('Mandatory'),
                        type: 'text',
                        value: (row) =>
                            row.mandatory ? row.mandatory.name : null,
                    },
                ]}
            />

            {rate !== null && (
                <ActivityRateDialog
                    open
                    activityId={item.id}
                    item={rate}
                    onClose={() => {
                        setRate(null);
                    }}
                    onSave={onSave}
                    deleteRateEnabled={
                        !(rates.count <= 1 || rate?.numStudents >= 1)
                    }
                />
            )}
        </>
    );
}
