import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext, SttTabs, SttFullDialog } from '../../../sporttia/all';
import BookingScheduleDetails from './BookingScheduleDetails';
import BookingScheduleBookings from './BookingScheduleBookings';
import { useCrud, useTranslations } from '../../../lib/hooks';
import { getPath } from '../../Pages';
import constants from '../../../config/constants';

export default function BookingSchedule({ match }) {
    const cxt = useContext(AppContext);
    const [bookings, setBookings, , Get] = useCrud();
    const [bookingSchedule, setBookingSchedule] = useState();
    const history = useHistory();
    const { translate } = useTranslations();

    function loadBookings(params = { page: 1, rows: 20 }) {
        let { id } = match.params;
        if (!id) id = bookingSchedule?.id;
        if (id !== constants.gui.objectState.create) {
            Get(`/bookings/schedules/${id}/bookings`, { ...params }).then(
                setBookings,
            );
        }
    }

    // Load bookingSchedule from API
    function loadBookingSchedule() {
        Get(`/bookings/schedules/${match.params.id}`, {
            trash: true,
        }).then(({ bookingSchedule: bookingScheduleRes }) => {
            setBookingSchedule(bookingScheduleRes);
        });
    }

    useEffect(() => {
        if (match.params.id !== 'create') {
            loadBookings();
            loadBookingSchedule();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.id]);

    const tabs = [
        {
            caption: cxt.t('Detail'),
            component: (
                <BookingScheduleDetails
                    id={match.params.id}
                    trash={match.params.trash}
                    onSaveSlot={loadBookings}
                    bookingScheduleInit={bookingSchedule}
                    onSaveBookingSchedule={(pickedBookingSchedule) =>
                        setBookingSchedule(pickedBookingSchedule)
                    }
                />
            ),
        },
        {
            caption: cxt.t('Bookings'),
            component: (
                <BookingScheduleBookings
                    onFetch={loadBookings}
                    id={match.params.id}
                    bookings={bookings}
                />
            ),
            show: match.params.id !== 'create',
        },
    ];

    return (
        <SttFullDialog
            open={!!(bookingSchedule?.id || match.params.id === 'create')}
            onClose={() => history.push(getPath('bookingSchedules'))}
            title={
                bookingSchedule
                    ? bookingSchedule.name
                    : translate('newBookingSchedule')
            }
        >
            <SttTabs tabs={tabs} />
        </SttFullDialog>
    );
}
