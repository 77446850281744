import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import {
    AppContext,
    SttDialog,
    SttFormSelect,
    SttLabelTitle,
} from '../../../sporttia/all';

/**
 * HandlePaymentsModal: dialog to present options available when deleting existing groups.
 * bool open - modal visibility
 * function onAccept(option) - Callback when 'accept' button is closed. Will be called with currently selected 'mode'
 * function onClose - Callback for closing
 *
 * Note: The option selected in the <select> will be passed as parameter to the onAccept function.
 *       Dialog doesn't close automatically when onAccept is called.
 */
export default function HandlePaymentsModal({
    warningText,
    open = false,
    onAccept,
    onClose,
    options = null,
}) {
    const cxt = useContext(AppContext);
    const [mode, setMode] = useState(null);

    // Render
    return (
        <SttDialog
            open={open}
            onClose={onClose}
            title={cxt.t('Collections')}
            content={
                <div>
                    <Box textAlign="center" mb={1}>
                        <SttLabelTitle>{`! ${cxt.t(
                            'Attention',
                        )} !`}</SttLabelTitle>
                    </Box>
                    <Box mb={1}>
                        <Typography variant="body1">{warningText}</Typography>
                    </Box>
                    <SttFormSelect
                        caption={cxt.t('SelectOneOption')}
                        defVal={mode}
                        onChange={({ undefined, value }) => setMode(value)}
                        options={
                            options === null
                                ? [
                                      {
                                          caption: cxt.t(
                                              'associatedPayments.doNothing',
                                          ),
                                          value: '0',
                                      },
                                      {
                                          caption: cxt.t(
                                              'associatedPayments.refundToPurse',
                                          ),
                                          value: '1',
                                      },
                                      {
                                          caption: cxt.t(
                                              'associatedPayments.refundInCash',
                                          ),
                                          value: '2',
                                      },
                                      {
                                          caption: cxt.t(
                                              'associatedPayments.delete',
                                          ),
                                          value: '3',
                                      },
                                      {
                                          caption: cxt.t(
                                              'associatedPayments.pending',
                                          ),
                                          value: '4',
                                      },
                                  ]
                                : options
                        }
                    />
                </div>
            }
            buttons={[
                {
                    type: 'accept',
                    caption: cxt.t('Accept'),
                    onClick: () => onAccept(mode),
                    disabled: mode === null,
                },
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
        />
    );
}

/*
 associatedPayments.doNothing 	Dejarlos como están 	Leave them as they are 	activar 		activar
6679 	associatedPayments.refundToPurse 	Devolverlos al monedero 	Refund into purse 	activar 		activar
6680 	associatedPayments.refundInCash 	Devolverlos todos al contado 	Refund them in cash 	activar 		activar
6681 	associatedPayments.delete
*/
