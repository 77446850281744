import React, { useContext } from 'react';
import { Chip } from '@material-ui/core';
import { AppContext } from '../AppContext';

/**
 * Chip bono type
 */
export function SttChipBono({ type }) {
    const cxt = useContext(AppContext);

    const color = {
        COMPLETE: '#337788',
        SINGLE: '#A94AAF',
    };

    return (
        type && (
            <Chip
                size="small"
                label={cxt.t(`BT.${type}`)}
                style={{ backgroundColor: color[type], color: 'white' }}
            />
        )
    );
}
