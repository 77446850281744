import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';

type FooterMenuItemProps = {
    caption: string;
    onClick: () => void;
};

export default function FooterMenuItem({
    caption,
    onClick,
}: FooterMenuItemProps) {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" mx={1}>
            <Link onClick={onClick} style={{ color: 'black' }}>
                <Typography variant="caption">{caption}</Typography>
            </Link>
        </Box>
    );
}
