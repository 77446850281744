import React, { useContext, useState, useEffect } from 'react';
import {
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Grid,
} from '@material-ui/core';
import { AppContext, SttFormSelect } from '../all';

/**
 * Seleccionar una forma de pago
 *
 * Las formas de pagos actuales son: CASH, PURSE, BANK, DATAFONO, TPV, FREE, RECEIPT y ''
 *
 * Props:
 *  string name: control name
 * 	string defVal: currently selected value
 * 	array activePFs: listado de formas de pago a mostrar. Si no se establece aparecerán todas.
 * 	function onChange => ({name, value}): value is CASH, PURSE, etc.
 */
export function SttSelectPForm({
    caption,
    defVal,
    activePFs = [''],
    onChange,
    ...rest
}) {
    const cxt = useContext(AppContext);
    const [activePaymentForms, setActivePaymentForms] = useState();

    /**
     * Active PF's change
     */
    useEffect(() => {
        if (activePFs && cxt.constants.paymentForms) {
            // Changing "" by "LATER"
            const auxPFs = cxt.constants.paymentForms.map((pf) =>
                pf.id == '' ? { ...pf, id: 'LATER' } : pf,
            );

            // Only take the input PF's
            setActivePaymentForms(
                auxPFs.filter((pf) =>
                    activePFs
                        .map((pf) => (pf == '' ? 'LATER' : pf))
                        .includes(pf.id),
                ),
            );
        }
    }, [activePFs, cxt.constants]);

    // Render
    return (
        <SttFormSelect
            caption={caption !== undefined ? caption : cxt.t('PaymentForm')}
            defVal={defVal == '' || defVal == undefined ? 'LATER' : defVal}
            onChange={({ name, value }) =>
                onChange({ name, value: value == 'LATER' ? '' : value })
            }
            options={activePaymentForms}
            {...rest}
        />
    );
}
