import React, { useState, ReactNode } from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    DialogActions,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    ListItemIcon,
    Divider,
} from '@material-ui/core';
import {
    ArrowBack as ArrowBackIcon,
    MoreVert as MoreIcon,
} from '@material-ui/icons';
import { SttButtonDelete, SttButtonSave, SttButton } from '../all';

type ButtonProps = {
    show?: boolean;
    type?: 'delete' | 'save' | 'custom';
    onClick: () => void;
    caption?: string;
    icon?: ReactNode;
};

type MenuItemProps = {
    show?: boolean;
    caption: string;
    onClick?: () => void;
    icon?: ReactNode;
    divider?: boolean;
};

type SttFullDialogProps = {
    open?: boolean;
    title?: string | JSX.Element;
    onClose?: () => void;
    buttons?: ButtonProps[];
    children?: ReactNode;
    menu?: MenuItemProps[];
    bottomButtons?: ButtonProps[];
    bottomContent?: ReactNode;
};

export function SttFullDialog({
    open = false,
    title,
    onClose,
    buttons,
    children,
    menu,
    bottomButtons,
    bottomContent,
}: SttFullDialogProps) {
    const [buttonMenuDOMObject, setButtonMenuDOMObject] =
        useState<HTMLElement | null>(null);

    const renderButton = (button: ButtonProps, idx: number) => {
        const { show = true, type, onClick, caption, icon } = button;

        if (!show) {
            return null;
        }

        let content: ReactNode;
        switch (type) {
            case 'delete':
                content = (
                    <SttButtonDelete onClick={onClick} caption={caption} />
                );
                break;

            case 'save':
                content = <SttButtonSave onClick={onClick} />;
                break;

            default:
                content = (
                    <SttButton
                        caption={caption}
                        onClick={onClick}
                        startIcon={icon}
                    />
                );
                break;
        }

        return (
            <Box key={idx} mx={1}>
                {content}
            </Box>
        );
    };

    const renderMenu = (
        <Box mx={1}>
            <IconButton
                color="inherit"
                onClick={(ev) => setButtonMenuDOMObject(ev.currentTarget)}
            >
                <MoreIcon />
            </IconButton>
            <Menu
                anchorEl={buttonMenuDOMObject}
                open={buttonMenuDOMObject !== null}
                onClose={() => setButtonMenuDOMObject(null)}
            >
                {menu
                    ?.filter(({ show = true }) => show)
                    .map((item, idx) => (
                        <div key={idx}>
                            {item.divider && <Divider />}
                            <MenuItem
                                onClick={() => {
                                    item.onClick?.();
                                    setButtonMenuDOMObject(null);
                                }}
                            >
                                {item.icon && (
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                )}
                                {item.caption}
                            </MenuItem>
                        </div>
                    ))}
            </Menu>
        </Box>
    );

    return (
        <Dialog fullScreen open={open} onClose={onClose}>
            <AppBar style={{ position: 'relative' }} color="default">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <ArrowBackIcon />
                    </IconButton>

                    {title && (
                        <Typography variant="h6" style={{ flex: 1 }}>
                            {title}
                        </Typography>
                    )}

                    {buttons?.map((bt, i) => renderButton(bt, i))}

                    {menu && renderMenu}
                </Toolbar>
            </AppBar>

            <DialogContent>{children}</DialogContent>

            {(bottomButtons || bottomContent) && (
                <DialogActions>
                    {bottomContent}
                    {bottomButtons?.map((button, i) => renderButton(button, i))}
                </DialogActions>
            )}
        </Dialog>
    );
}
