import React, { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { AppContext } from '../../../../sporttia/AppContext';
import useApisService from '../../../../services/ApisService';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import { SUPERADMIN_API_RESPONSES } from '../../../../lib/queryKeys';
import AdminApiResponseDialog from './AdminApiResponseDialog';

/**
 * API responses view. View with a table that lists the responses of an api.
 * @param apiId API ID.
 * @returns {JSX.Element}
 */
export default function AdminApiResponses({ apiId }) {
    const cxt = useContext(AppContext);
    const [response, setResponse] = useState(null);
    const queryClient = useQueryClient();
    const apisService = useApisService();

    return (
        <>
            <SttTopFilteringControls
                p={2}
                mainAction={{
                    type: 'create',
                    caption: cxt.t('Create'),
                    onClick: () => setResponse({}),
                }}
            />

            <SttCachedTable
                queryKey={SUPERADMIN_API_RESPONSES}
                queryFn={(params) => apisService.getApiResponses(apiId, params)}
                onClickRow={(row) => setResponse(row)}
                columns={[
                    {
                        title: 'Name',
                        field: 'name',
                        value: (row) => <b>{row.name}</b>,
                    },
                    {
                        title: 'Description',
                        field: 'description',
                        align: 'left',
                    },
                    {
                        title: 'Type',
                        field: 'type',
                    },
                ]}
            />

            {/* En el futuro si tenemos un GET /apis/responses/:id podríamos quitar responseValue y pasar el id del response. */}
            <AdminApiResponseDialog
                apiId={apiId}
                responseValue={response}
                onClose={() => setResponse(null)}
                onCreate={() =>
                    queryClient.invalidateQueries(SUPERADMIN_API_RESPONSES)
                }
                onUpdate={() =>
                    queryClient.invalidateQueries(SUPERADMIN_API_RESPONSES)
                }
                onDelete={() => {
                    setResponse(null);
                    queryClient.invalidateQueries(SUPERADMIN_API_RESPONSES);
                }}
            />
        </>
    );
}
