import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import {
    AppContext,
    SttSelectMship,
    SttFormDate,
    SttFormTextarea,
    SttSelectPForm,
    SttFormText,
    SttFormCheck,
} from '../../all';
import { formatPriceByLocale } from '../../../lib/utils';
import DiscountList from '../../../components/discounts/DiscountsList';
import constants from '../../../config/constants';
import translations from '../../../translations';

export default function SttGroupMemberDialogDetail({
    member,
    creating,
    group,
    handleChange,
    discounts,
    handleChangeDiscounts,
}) {
    const cxt = useContext(AppContext);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
                {creating ? (
                    <SttSelectMship
                        caption={cxt.t(translations.generic.name)}
                        mship={member.user.mship}
                        onSelect={(mship) =>
                            handleChange({ name: 'mship', value: mship })
                        }
                    />
                ) : (
                    <SttFormText
                        disabled
                        caption={cxt.t(translations.generic.name)}
                        defVal={member.name}
                    />
                )}
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <SttFormDate
                    caption={cxt.t('SignupFrom')}
                    name="ini"
                    defVal={member.ini}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <SttFormDate
                    caption={cxt.t('Until')}
                    name="end"
                    defVal={member.end}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={12}>
                <SttFormTextarea
                    caption={cxt.t('Notes')}
                    name="notes"
                    defVal={member.notes}
                    onChange={handleChange}
                />
            </Grid>

            {group.type !== 'SPORT' && (
                <>
                    {group.enrollmentPrice > 0 && (
                        <Grid item xs={12} md={6}>
                            <SttSelectPForm
                                name="paymentFormSignup"
                                disabled={!creating}
                                caption={`${cxt.t(
                                    translations.generic.inscription,
                                )} ${
                                    member.enrollment
                                        ? `[ ${formatPriceByLocale(
                                              member.enrollment.price,
                                              member?.enrollment?.priceShape
                                                  ?.currency,
                                              member?.enrollment?.priceShape
                                                  ?.locale,
                                          )} ]`
                                        : ''
                                }`}
                                defVal={member.enrollment?.paymentForm}
                                activePFs={[
                                    '',
                                    constants.payment.paymentForms.purse.name,
                                    constants.payment.paymentForms.cash.name,
                                    constants.payment.paymentForms.bank.name,
                                    cxt?.sc?.dataphoneConnected
                                        ? constants.payment.paymentForms
                                              .dataphoneConnected.name
                                        : constants.payment.paymentForms
                                              .dataphone.name,
                                    constants.payment.paymentForms.receipt.name,
                                    constants.payment.paymentForms.free.name,
                                ]}
                                onChange={handleChange}
                            />
                        </Grid>
                    )}
                    {group.feePrice > 0 && (
                        <Grid item xs={12} md={6}>
                            <SttSelectPForm
                                name="paymentFormFee"
                                caption={`${cxt.t(
                                    translations.generic.paymentForm,
                                )}, (${cxt.t(
                                    translations.generic.fee,
                                )}: ${formatPriceByLocale(
                                    member.group.fee,
                                    member?.group?.feeShape?.currency,
                                    member?.group?.feeShape?.locale,
                                )})`}
                                defVal={member.paymentFormFee}
                                activePFs={[
                                    '',
                                    cxt.sc.modules?.includes('receipt') &&
                                        constants.payment.paymentForms.receipt
                                            .name,
                                    cxt.sc.TPVFeePayment &&
                                        constants.payment.paymentForms.tpv.name,
                                ]}
                                onChange={handleChange}
                            />
                        </Grid>
                    )}

                    {creating &&
                        discounts &&
                        discounts.length > 0 &&
                        (group.feePrice || group.enrollmentPrice) && (
                            <Grid item xs={12}>
                                <Grid container>
                                    {discounts.map((discount) => (
                                        <Grid item key={discount.id} xs={12}>
                                            <SttFormCheck
                                                name={discount.id}
                                                caption={`${discount.name} - ${discount.discountPercentage}%`}
                                                checked={discount.checked}
                                                onChange={handleChangeDiscounts}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        )}

                    {!creating && discounts && discounts.length > 0 && (
                        <Grid item xs={12}>
                            <DiscountList
                                discounts={discounts}
                                showEmptyMessage={false}
                            />
                        </Grid>
                    )}

                    {creating &&
                        group.expiration &&
                        group.expiration !== '0000-00-00 00:00:00' && (
                            <Grid item xs={12} md={6}>
                                <SttFormCheck
                                    name="expirationCheck"
                                    caption={cxt.t(
                                        translations.generic.expiration,
                                    )}
                                    checked={member?.expirationCheck}
                                    onChange={handleChange}
                                />
                            </Grid>
                        )}
                </>
            )}
        </Grid>
    );
}
