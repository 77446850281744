import React from 'react';
import {
    Checkbox,
    CheckboxProps,
    FormControlLabel,
    Grid,
    GridProps,
    Typography,
} from '@material-ui/core';

type SttFormCheckProps = {
    grid?: boolean;
    name: string;
    caption?: string;
    color?: CheckboxProps['color'];
    checked?: boolean;
    onChange: (event: { name: string; value: boolean }) => void;
    disabled?: boolean;
} & Omit<GridProps, 'onChange'>;

export function SttFormCheck({
    grid = false,
    name,
    caption,
    color = 'default',
    checked = false,
    onChange,
    disabled,
    ...rest
}: SttFormCheckProps) {
    const content = (
        <FormControlLabel
            control={
                <Checkbox
                    color={color}
                    onChange={(e) =>
                        onChange({ name, value: e.target.checked })
                    }
                    checked={checked}
                    disabled={disabled}
                />
            }
            label={
                <Typography
                    variant="body2"
                    style={disabled ? { color: '#9f9f9f' } : {}}
                >
                    {caption}
                </Typography>
            }
        />
    );

    return grid ? (
        <Grid item {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
