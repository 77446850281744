import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Button,
    ButtonGroup,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { AppContext } from '../all';
import { updateElementInArray, deleteElementFromArray } from '../../lib/utils';

/**
 * Button months
 *
 * Props
 *
 * 	* integer month: 1 = january, etc
 * 	* integer[] selectedMonths: selected months, 1=january, etc.
 * 	* boolean multipleSelection
 * 	* boolean tinyLabels: show only the first letter of every month.
 * 	* string size: size of buttons (small)
 * 	* function onChangeMonth(month): when THE month (1=january) is changed (not multiple choices). January = 1, etc.
 * 	* string contract: breakpoint at which the component will render as 2 rows one on top of the other
 */
export function SttButtonMonths({
    selectedMonths = [],
    tinyLabels = false,
    size,
    onChangeMonth,
    contract = null,
}) {
    const cxt = useContext(AppContext);
    const theme = useTheme();
    const matchQuery = useMediaQuery(theme.breakpoints.down(contract || 'xl'));

    // Render
    return (
        <>
            {cxt.constants.months &&
                (contract &&
                matchQuery /* Si la pantalla es muy estrecha, sencillamente lo pintamos en dos filas, con la mitad de meses en cada una */ ? (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'stretch',
                        }}
                    >
                        <ButtonGroup size={size}>
                            {cxt.constants.months.slice(0, 6).map((month) => (
                                <Button
                                    key={month.id}
                                    style={{ flex: 1 }}
                                    variant={
                                        selectedMonths.find(
                                            (id) => id == month.id,
                                        )
                                            ? 'contained'
                                            : 'outlined'
                                    }
                                    color={
                                        selectedMonths.find(
                                            (id) => id == month.id,
                                        )
                                            ? 'primary'
                                            : 'default'
                                    }
                                    onClick={() => onChangeMonth(month.id)}
                                >
                                    {tinyLabels ? month.tiny : month.short}
                                </Button>
                            ))}
                        </ButtonGroup>
                        <ButtonGroup size={size} style={{ marginTop: 8 }}>
                            {cxt.constants.months.slice(6, 12).map((month) => (
                                <Button
                                    key={month.id}
                                    style={{ flex: 1 }}
                                    variant={
                                        selectedMonths.find(
                                            (id) => id == month.id,
                                        )
                                            ? 'contained'
                                            : 'outlined'
                                    }
                                    color={
                                        selectedMonths.find(
                                            (id) => id == month.id,
                                        )
                                            ? 'primary'
                                            : 'default'
                                    }
                                    onClick={() => onChangeMonth(month.id)}
                                >
                                    {tinyLabels ? month.tiny : month.short}
                                </Button>
                            ))}
                        </ButtonGroup>
                    </div>
                ) : (
                    <ButtonGroup size={size}>
                        {cxt.constants.months.map((month) => (
                            <Button
                                key={month.id}
                                variant={
                                    selectedMonths.find((id) => id == month.id)
                                        ? 'contained'
                                        : 'outlined'
                                }
                                color={
                                    selectedMonths.find((id) => id == month.id)
                                        ? 'primary'
                                        : 'default'
                                }
                                onClick={() => onChangeMonth(month.id)}
                            >
                                {tinyLabels ? month.tiny : month.short}
                            </Button>
                        ))}
                    </ButtonGroup>
                ))}
        </>
    );
}
