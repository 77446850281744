import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, ButtonGroup, Typography, Grid } from '@material-ui/core';
import React from 'react';
import { ContactsOutlined } from '@material-ui/icons';

const useStyles = makeStyles({
    labelWrapper: {
        position: 'absolute',
        top: -12,
        left: 8,
        backgroundColor: 'white',
        padding: '2px 6px',
        zIndex: 10,
        borderRadius: 2,
    },
    label: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
    },
    /* btnGroup: {
		height: 40,
	} */
});

/**
 * SttSelectableItems: toggle one or many of a list of simple items. They are rendered as a button group (for the free styling and feedback)
 * bool grid - wrap the component inside a <Grid item>
 * string name - field name
 * string caption - caption for the label
 * Object[] items - array of {label, value} pairs representing the available items
 * array selected - array of selected VALUES, for ex. [0,1,4,5], or ['yes', 'no', 'perhaps']. The values must correlate with the ones in items
 * Function onChange - callback for when an item is selected / deselected. It will be called with the array of selected items AFTER the change.
 * bool exclusive - if true only one item can be selected at a time
 */
export function SttSelectableItems({
    grid = false,
    name,
    caption,
    items,
    selected,
    onChange,
    exclusive = false,
    size = 'medium',
    variant = 'contained',
    disabled,
    ...rest
}) {
    const classes = useStyles();

    // sanitize input prop
    const _selected = Array.isArray(selected) ? selected : [selected];
    function select(value, isSelected) {
        if (isSelected) {
            // In exclusive mode, we can only select one item
            if (exclusive) {
                onChange({ name, value });
            } else {
                onChange({ name, value: [..._selected, value] });
            }
        } else if (selected) {
            _selected.splice(_selected.indexOf(value), 1);
            onChange({ name, value: _selected });
        }
    }

    const content = (
        <Box position="relative">
            {caption && (
                <div className={classes.labelWrapper}>
                    <Typography className={classes.label}>{caption}</Typography>
                </div>
            )}
            <ButtonGroup
                fullWidth
                className={classes.btnGroup}
                size={size}
                variant={variant}
            >
                {items.map((item, i) => {
                    const isSelected = _selected.indexOf(item.value) !== -1;
                    return (
                        <Button
                            disabled={disabled}
                            key={i}
                            color={isSelected ? 'primary' : 'default'}
                            // The 'disabled style' is hardcoded here using clearer versions of the 'primary' and 'default'. TODO: Handle this kind of thing at the theme level.
                            style={
                                disabled
                                    ? isSelected
                                        ? {
                                              backgroundColor:
                                                  'rgba(156, 182, 21, 0.6)',
                                          }
                                        : {
                                              backgroundColor:
                                                  'rgba(230,230,230,0.6)',
                                          }
                                    : {}
                            }
                            onClick={() => select(item.value, !isSelected)}
                        >
                            {item.label}
                        </Button>
                    );
                })}
            </ButtonGroup>
        </Box>
    );

    return grid ? (
        <Grid item {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
