import React, { useState, useContext } from 'react';
import { useQueryClient } from 'react-query';
import { AppContext } from '../../../../sporttia/all';
import useApisService from '../../../../services/ApisService';
import { SUPERADMIN_API_TESTS } from '../../../../lib/queryKeys';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import AdminApiTestDialog from './AdminApiTestDialog';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';

/**
 * API tests view. View with a table that lists the tests of an api.
 * @param apiId API ID.
 * @returns {JSX.Element}
 */
export default function AdminApiTests({ apiId }) {
    const cxt = useContext(AppContext);
    const [test, setTest] = useState(null);
    const queryClient = useQueryClient();
    const apisService = useApisService();

    return (
        <>
            <SttTopFilteringControls
                p={2}
                mainAction={{
                    type: 'create',
                    caption: cxt.t('Create'),
                    onClick: () => setTest({}),
                }}
            />

            <SttCachedTable
                queryKey={SUPERADMIN_API_TESTS}
                queryFn={(params) => apisService.getApiTests(apiId, params)}
                onClickRow={(row) => setTest(row)}
                columns={[
                    {
                        title: 'Id',
                        field: 'id',
                    },
                    {
                        title: 'Description',
                        field: 'description',
                        align: 'left',
                    },
                ]}
            />

            {/* En el futuro si tenemos un GET /apis/tests/:id podríamos quitar testValue y pasar el id del test. */}
            <AdminApiTestDialog
                apiId={apiId}
                testValue={test}
                onClose={() => setTest(null)}
                onCreate={() =>
                    queryClient.invalidateQueries(SUPERADMIN_API_TESTS)
                }
                onUpdate={() =>
                    queryClient.invalidateQueries(SUPERADMIN_API_TESTS)
                }
                onDelete={() => {
                    setTest(null);
                    queryClient.invalidateQueries(SUPERADMIN_API_TESTS);
                }}
            />
        </>
    );
}
