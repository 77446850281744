import React, { useState, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AppContext } from '../../../sporttia/all';

export default function BookingSectionMessages({ bookingPrivilege, sc }) {
    const cxt = useContext(AppContext);

    return (
        <Box mt={2}>
            {cxt.logged && !bookingPrivilege && (
                <Alert severity="warning">
                    {cxt.t('page.user.booking.IDontHaveRentPrivilege')}
                </Alert>
            )}

            {!sc.modules.includes('booking') && (
                <Alert severity="warning">
                    {cxt.t('page.user.booking.SCNoBookingMod')}
                </Alert>
            )}

            {!cxt.logged && (
                <Alert severity="warning">
                    {cxt.t('page.booking.mustLogin')}
                </Alert>
            )}
        </Box>
    );
}
