import React, { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { AppContext } from '../../../../sporttia/AppContext';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';
import { SUPERADMIN_REPORT_FILTERS } from '../../../../lib/queryKeys';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import useReportsService from '../../../../services/ReportsService';
import AdminReportFilterDialog from './AdminReportFilterDialog';

/**
 * View to create and modify the details of a report filters.
 * @param report Report object.
 * @returns {JSX.Element}
 */
export default function AdminReportFilters({ report }) {
    const cxt = useContext(AppContext);
    const reportsService = useReportsService();
    const [filter, setFilter] = useState(null);
    const queryClient = useQueryClient();

    return (
        <>
            <SttTopFilteringControls
                p={2}
                mainAction={{
                    type: 'create',
                    caption: cxt.t('Create'),
                    onClick: () => setFilter({}),
                }}
            />

            {/* Aqui no podemos paginar directamente los filtros ya que no tenemos un end point que lo haga, tenemos que usar
            GET /reports/:id para de dentro coger filters y ya listarlo, de ahí que se pidan 200 filas. */}
            <SttCachedTable
                queryKey={SUPERADMIN_REPORT_FILTERS}
                queryFn={(params) =>
                    reportsService
                        .get(params?.id, { trash: true })
                        .then((data) => ({
                            count: data.report.filters
                                ? data.report.filters.length
                                : 0,
                            rows: data.report.filters
                                ? data.report.filters
                                : [],
                        }))
                }
                queryOptions={{
                    enabled: !!report?.id && report?.id !== 'create',
                }}
                queryParams={{
                    id: report?.id,
                }}
                onClickRow={(row) => setFilter(row)}
                rowsPerPage={200}
                columns={[
                    {
                        title: cxt.t('name'),
                        align: 'left',
                        type: 'text',
                        field: 'name',
                    },
                    {
                        title: cxt.t('field'),
                        align: 'left',
                        type: 'text',
                        field: 'field',
                    },
                    {
                        title: 'SQL',
                        align: 'left',
                        type: 'text',
                        field: 'sqlPiece',
                    },
                    {
                        title: cxt.t('value'),
                        align: 'left',
                        type: 'text',
                        field: 'value',
                    },
                    {
                        title: cxt.t('type'),
                        align: 'left',
                        type: 'text',
                        field: 'type',
                    },
                ]}
            />

            {/* En el futuro si tenemos un GET /reports/columns/:id podríamos quitar columnValue y pasar el id de la columna. */}
            <AdminReportFilterDialog
                reportId={report?.id}
                filterValue={filter}
                onClose={() => setFilter(null)}
                onCreate={() =>
                    queryClient.invalidateQueries(SUPERADMIN_REPORT_FILTERS)
                }
                onUpdate={() =>
                    queryClient.invalidateQueries(SUPERADMIN_REPORT_FILTERS)
                }
                onDelete={() => {
                    setFilter(null);
                    queryClient.invalidateQueries(SUPERADMIN_REPORT_FILTERS);
                }}
            />
        </>
    );
}
