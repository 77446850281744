import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useCrud } from '../../../lib/hooks';
import {
    AppContext,
    SttButtonCancel,
    SttButtonDelete,
    SttButtonRecover,
    SttButtonSave,
    SttFacilityList,
    SttFormCheck,
    SttFormDate,
    SttFormPrice,
    SttFormSelect,
    SttFormText,
    SttFormTextarea,
    SttFormTime,
    SttSelectFixedInterval,
    SttSelectGroup,
    SttSelectWeekdays,
    SttSelectInterval,
} from '../../../sporttia/all';
import { parsePrice, subsetObject } from '../../../lib/utils';

export default function BonoDetails({ item, onSave, onDelete }) {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const [bono, setBono, setProperty, Get, Post, Put, Delete] = useCrud(item);
    const [tpvEnabled, setTpvEnabled] = useState();

    useEffect(() => {
        setTpvEnabled(item && item.purchasePFs.includes('TPV'));
    }, [item]);

    /**
     * Create / update bono
     */
    function saveBono() {
        const p = subsetObject(
            bono,
            'name,description,price,amount,type,duration,dateini,dateend,timeini,timeend,weekdays,expiration,transferable',
        );
        p.price = parsePrice(p.price);
        p.paymentForms = tpvEnabled ? ['PURSE', 'TPV'] : ['PURSE'];
        p.onlinePurchase = bono.purchaseOnline;

        if (bono.mandatoryGroup && bono.mandatoryGroup?.name != 'Ningún grupo')
            p.idMandatoryGroup = bono.mandatoryGroup.id;
        else p.idMandatoryGroup = null;

        // Fix with default expiration value for bonos, force the field to be null
        if (p.expiration == '0000-00-00 00:00:00') {
            p.expiration = null;
        }
        // Updating
        if (bono.id !== 'create') {
            Put(`/bonos/${bono.id}`, p).then(({ bono }) => {
                setBono(bono);
                onSave && onSave(bono);
            });
            // Creating
        } else {
            Post(`/scs/${cxt.sc.id}/bonos`, p).then(({ bono }) => {
                setBono(bono);
                onSave && onSave(bono);
            });
        }
    }

    /**
     * Delete bono
     */
    function deleteBono() {
        Delete(`/bonos/${bono.id}`).then(({ bono }) => {
            onDelete && onDelete(bono);
        });
    }

    /**
     * Recover bono
     */
    function recoverBono() {
        Put(`/bonos/${bono.id}/recover`, {}, true).then(() => {
            const _bono = {
                ...bono,
                trash: false,
            };
            setBono(_bono);
            onSave && onSave(_bono);
        });
    }

    /**
     * Add facility to a bono
     */
    function addFacility(facility) {
        Post(`/bonos/${bono.id}/facilities/${facility.id}`).then(() => {
            setBono({
                ...bono,
                facilities: bono.facilities.concat([facility]),
            });
        });
    }

    /**
     * Delete facility from a bono
     */
    function deleteFacility(facility) {
        Delete(`/bonos/${bono.id}/facilities/${facility.id}`).then(() => {
            setBono({
                ...bono,
                facilities: bono.facilities.filter(
                    (fct) => fct.id !== facility.id,
                ),
            });
        });
    }

    // Turn 90 into 01:30 etc.
    function minutesToString(minutes) {
        const duration = moment.duration(minutes, 'minutes');
        const durationString = `${duration
            .hours()
            .toString()
            .padStart(2, '0')}:${duration
            .minutes()
            .toString()
            .padStart(2, '0')}`;
        return durationString;
    }

    if (!bono) {
        return false;
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <SttFormText
                                caption={cxt.t('Name')}
                                name="name"
                                defVal={bono.name}
                                onChange={setProperty}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SttFormTextarea
                                caption={cxt.t('Description')}
                                name="description"
                                defVal={bono.description}
                                onChange={setProperty}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} xl={3}>
                            <SttFormPrice
                                caption={cxt.t('Price')}
                                name="price"
                                defVal={bono.price}
                                onChange={setProperty}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} xl={3}>
                            <SttFormText
                                caption={cxt.t('Amount')}
                                name="amount"
                                defVal={bono.amount}
                                onChange={setProperty}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} xl={3}>
                            <SttFormSelect
                                caption={cxt.t('Type')}
                                name="type"
                                defVal={bono.type}
                                options={cxt.constants.bonoTypes.map(
                                    (item) => ({
                                        caption: item.label,
                                        value: item.id,
                                    }),
                                )}
                                onChange={setProperty}
                            />
                        </Grid>

                        {/* --- Duration --- */}
                        <Grid item md={6} sm={6} xs={12}>
                            <SttSelectInterval
                                caption={cxt.t('Duration')}
                                name="duration"
                                labelWidth={90}
                                defVal={bono.duration}
                                min={5}
                                max={1435}
                                interval={5}
                                onChange={(value) =>
                                    setProperty({ name: 'duration', value })
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} xl={3}>
                            <SttFormDate
                                caption={cxt.t('ValidFrom')}
                                name="dateini"
                                defVal={bono.dateini}
                                onChange={setProperty}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} xl={3}>
                            <SttFormDate
                                caption={cxt.t('Until')}
                                name="dateend"
                                defVal={bono.dateend}
                                onChange={setProperty}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} xl={3}>
                            <SttFormTime
                                interval={30}
                                caption={cxt.t('TimeIni')}
                                name="timeini"
                                defVal={bono.timeini}
                                onChange={setProperty}
                                min="00:00:00"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} xl={3}>
                            <SttFormTime
                                interval={30}
                                caption={cxt.t('TimeEnd')}
                                name="timeend"
                                defVal={bono.timeend}
                                onChange={setProperty}
                                min="00:00:00"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SttSelectWeekdays
                                name="weekdays"
                                selected={bono.weekdays || []}
                                onChange={setProperty}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SttSelectFixedInterval
                                caption={cxt.t('Expiration')}
                                name="expiration"
                                defVal={bono.expiration}
                                options={['!FORTNIGHT']}
                                onChange={setProperty}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SttSelectGroup
                                caption={cxt.t('MandatoryGroup')}
                                name="mandatoryGroup"
                                defVal={bono.mandatoryGroup}
                                onChange={setProperty}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                <SttFormCheck
                                    caption={cxt.t(
                                        'page.sc.bono.onlinePurchase',
                                    )}
                                    name="purchaseOnline"
                                    checked={bono.purchaseOnline || false}
                                    onChange={setProperty}
                                />
                            </div>
                            <div>
                                <SttFormCheck
                                    caption={cxt.t('page.sc.bono.tpvPurchase')}
                                    checked={tpvEnabled}
                                    onChange={({ undefined, value }) =>
                                        setTpvEnabled(value)
                                    }
                                />
                            </div>
                            <div>
                                <SttFormCheck
                                    caption={cxt.t('page.sc.bono.transferable')}
                                    name="transferable"
                                    checked={bono.transferable || false}
                                    onChange={setProperty}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                    {bono.facilities && (
                        <SttFacilityList
                            status="ACTIVE"
                            facilities={bono.facilities}
                            onAdd={addFacility}
                            onDelete={deleteFacility}
                        />
                    )}
                </Grid>
            </Grid>

            <Box mt={3}>
                <Grid mt={3} container spacing={3}>
                    {!bono.trash && (
                        <Grid item xs={12} sm={6} md={3}>
                            <SttButtonSave fullWidth onClick={saveBono} />
                        </Grid>
                    )}

                    {!bono.id && (
                        <Grid item xs={12} sm={6} md={3}>
                            <SttButtonCancel
                                fullWidth
                                onClick={history.goBack}
                            />
                        </Grid>
                    )}

                    {bono.id && !bono.trash && (
                        <Grid item xs={12} sm={6} md={3}>
                            <SttButtonDelete fullWidth onClick={deleteBono} />
                        </Grid>
                    )}

                    {bono.trash && (
                        <Grid item xs={12} sm={6} md={3}>
                            <SttButtonRecover fullWidth onClick={recoverBono} />
                        </Grid>
                    )}
                </Grid>
            </Box>
        </>
    );
}
