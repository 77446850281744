import React, { useContext, useState } from 'react';
import { Button, Chip, Divider, Grid, Typography } from '@material-ui/core';
import { Publish } from '@material-ui/icons';

import {
    SttDialog,
    SttFormText,
    SttFormDate,
    SttUploadDialog,
    AppContext,
} from '../all';
import { useLoader, useTranslations } from '../../lib/hooks';
import useActivitiesService from '../../services/ActivitiesService';
import { CreateDuplicationActivitiesBody } from '../../types/api/paths/Student';
import translations from '../../translations';
import { isErrorResponse } from '../../types/common';

type SttActivitiesDuplicationDialogProps = {
    open?: boolean;
    onClose: () => void;
    onDuplicate: () => void;
    activities: number[];
};

type ActivityDocument = {
    id: number;
    url: string;
    name: string;
};
/**
 * Select group dialog
 */
export function SttActivitiesDuplicationDialog({
    open = false,
    onClose,
    onDuplicate,
    activities,
}: SttActivitiesDuplicationDialogProps) {
    // Handler to ensure page is reset to the first page when the filter is changed
    const cxt = useContext(AppContext)!;

    const [form, setForm] = useState<CreateDuplicationActivitiesBody>({
        ids: activities,
        originalNameFragment: undefined,
        newNameFragment: undefined,
        inscIni: undefined,
        inscEnd: undefined,
        timeini: undefined,
        timeend: undefined,
    });

    const { translate } = useTranslations();
    const [documents, setDocuments] = useState<ActivityDocument[]>([]);
    const [openUploadPoster, setOpenUploadPoster] = useState(false);
    const { useCreateDuplicationsActivities } = useActivitiesService();
    const duplicateActivitiesMutation = useCreateDuplicationsActivities();
    const [, loader] = useLoader([duplicateActivitiesMutation.status]);

    if (!open) {
        return null;
    }

    if (isErrorResponse(duplicateActivitiesMutation.error)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        cxt.showMessage('E', duplicateActivitiesMutation.error.error.msg);
        duplicateActivitiesMutation.reset();
    }

    if (duplicateActivitiesMutation.isSuccess) {
        onDuplicate();
    }

    if (duplicateActivitiesMutation.status)
        return (
            <SttDialog
                open
                title={translate('duplicateActivities')}
                // @ts-expect-error Migrate SttDialog
                minHeight="60%"
                // @ts-expect-error Migrate SttDialog
                maxHeight="60%"
                onClose={() => onClose()}
                maxWidth="md"
                content={
                    <>
                        {loader}
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            spacing={4}
                            xs={12}
                            sm={12}
                            md={12}
                        >
                            <Grid
                                item
                                container
                                xs={12}
                                md={12}
                                lg={12}
                                spacing={4}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item container xs={12} spacing={2}>
                                    <SttFormText
                                        grid
                                        md={6}
                                        sm={6}
                                        xs={6}
                                        name="originalNameFragment"
                                        caption={translate(
                                            translations.activity
                                                .currentNameToChange,
                                        )}
                                        defVal={form.originalNameFragment || ''}
                                        onChange={(data) => {
                                            setForm({
                                                ...form,
                                                originalNameFragment:
                                                    data.value,
                                            });
                                        }}
                                    />
                                    <SttFormText
                                        grid
                                        md={6}
                                        sm={6}
                                        xs={6}
                                        name="newNameFragment"
                                        caption={translate(
                                            translations.activity
                                                .newActivityName,
                                        )}
                                        defVal={form.newNameFragment || ''}
                                        onChange={(data) => {
                                            setForm({
                                                ...form,
                                                newNameFragment: data.value,
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    lg={12}
                                    md={12}
                                    spacing={2}
                                >
                                    <SttFormDate
                                        grid
                                        disabled={false}
                                        md={6}
                                        sm={6}
                                        xs={6}
                                        name="inscIni"
                                        caption={translate(
                                            translations.activity
                                                .inscriptionFrom,
                                        )}
                                        defVal={form?.inscIni || ''}
                                        onChange={(data: {
                                            name: string;
                                            value: string;
                                        }) => {
                                            setForm({
                                                ...form,
                                                inscIni: data.value,
                                            });
                                        }}
                                    />
                                    <SttFormDate
                                        grid
                                        disabled={false}
                                        md={6}
                                        sm={6}
                                        xs={6}
                                        name="inscEnd"
                                        caption={translate(
                                            translations.activity
                                                .inscriptionUntil,
                                        )}
                                        defVal={form?.inscEnd || ''}
                                        onChange={(data: {
                                            name: string;
                                            value: string;
                                        }) => {
                                            setForm({
                                                ...form,
                                                inscEnd: data.value,
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    lg={12}
                                    md={12}
                                    spacing={2}
                                >
                                    <SttFormDate
                                        grid
                                        disabled={false}
                                        md={6}
                                        sm={6}
                                        xs={6}
                                        name="timeini"
                                        caption={translate(
                                            translations.activity.durationFrom,
                                        )}
                                        defVal={form?.timeini || ''}
                                        onChange={(data: {
                                            name: string;
                                            value: string;
                                        }) => {
                                            setForm({
                                                ...form,
                                                timeini: data.value,
                                            });
                                        }}
                                    />
                                    <SttFormDate
                                        grid
                                        disabled={false}
                                        md={6}
                                        sm={6}
                                        xs={6}
                                        name="timeend"
                                        caption={translate(
                                            translations.activity.durationUntil,
                                        )}
                                        defVal={form?.timeend || ''}
                                        onChange={(data: {
                                            name: string;
                                            value: string;
                                        }) => {
                                            setForm({
                                                ...form,
                                                timeend: data.value,
                                            });
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                container
                                xs={12}
                                md={12}
                                lg={12}
                                justifyContent="flex-start"
                                alignItems="center"
                                style={{ paddingLeft: 30 }}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                    direction="row"
                                    alignItems="flex-start"
                                >
                                    <Grid item style={{ marginTop: '20px' }}>
                                        <Typography
                                            style={{ marginLeft: '20px' }}
                                        >
                                            {translate(
                                                translations.generic.documents,
                                            )}
                                        </Typography>
                                    </Grid>

                                    {documents.length > 0 &&
                                        documents.map(
                                            (document: ActivityDocument) => (
                                                <Grid
                                                    item
                                                    style={{
                                                        marginTop: '20px',
                                                    }}
                                                >
                                                    <Chip
                                                        label={document.name}
                                                        variant="default"
                                                        clickable
                                                        color="primary"
                                                        size="medium"
                                                    />
                                                </Grid>
                                            ),
                                        )}
                                </Grid>
                            </Grid>
                            <Divider style={{ width: '90%' }} />
                        </Grid>
                        <SttUploadDialog
                            open={openUploadPoster}
                            types={['ACTIVITY_DOC']}
                            onClose={() => setOpenUploadPoster(false)}
                            onUploaded={(r: {
                                file: { id: number; url: string; name: string };
                            }) => {
                                const { file } = r;
                                setOpenUploadPoster(false);
                                setDocuments([
                                    ...documents,
                                    {
                                        id: file?.id,
                                        url: file?.url,
                                        name: file?.name,
                                    },
                                ]);
                            }}
                        />
                    </>
                }
                buttons={[
                    {
                        type: 'component',
                        show: true,
                        component: (
                            <Button
                                size="medium"
                                color="default"
                                variant="contained"
                                startIcon={<Publish />}
                                onClick={() => setOpenUploadPoster(true)}
                            >
                                {translate(
                                    translations.activity.uploadDocuments,
                                )}
                            </Button>
                        ),
                        onClick: () => () => setOpenUploadPoster(true),
                    },
                    {
                        type: 'save',
                        label: 'Duplicar actividades',
                        onClick: () => {
                            duplicateActivitiesMutation.mutate(
                                {
                                    params: {
                                        ...form,
                                        idFiles: documents?.map(
                                            (doc) => doc?.id,
                                        ),
                                    },
                                },
                                {
                                    onSuccess: () =>
                                        cxt.showMessage(
                                            'S',
                                            translate(
                                                translations.generic
                                                    .OperationSuccessful,
                                            ),
                                        ),
                                },
                            );
                        },
                    },
                ]}
            />
        );
}
