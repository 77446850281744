import React, { useContext, useState } from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import { AppContext } from '../all';
import PaymentModal from '../../components/paymentModal/PaymentModal';
import { SttLabelDeleted } from './SttLabelDeleted';
import { formatPriceByLocale } from '../../lib/utils';

/**
 *	LABEL PAYMENT
 *	==============
 */
export function SttLabelPayment({
    payment,
    paymentDialogActive = false,
    onClose,
}) {
    const cxt = useContext(AppContext);
    const [openPaymentId, setOpenPaymentId] = useState(null);

    return (
        <>
            <Box align="right">
                {payment ? (
                    paymentDialogActive ? (
                        <Link onClick={() => setOpenPaymentId(payment.id)}>
                            {payment &&
                                formatPriceByLocale(
                                    payment.price,
                                    payment?.priceShape?.currency,
                                    payment?.priceShape?.locale,
                                )}
                        </Link>
                    ) : (
                        formatPriceByLocale(
                            payment.price,
                            payment?.priceShape?.currency,
                            payment?.priceShape?.locale,
                        )
                    )
                ) : (
                    <SttLabelDeleted />
                )}

                {payment && payment.refund && payment.refund.id && (
                    <Box>
                        <Typography variant="caption">
                            {cxt.t('Returned')}
                        </Typography>
                    </Box>
                )}
            </Box>

            {paymentDialogActive && openPaymentId && (
                <PaymentModal
                    idPayment={openPaymentId}
                    onClose={() => {
                        setOpenPaymentId(null);
                        onClose();
                    }}
                    onPay={onClose}
                />
            )}
        </>
    );
}
