import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    Grid,
    Typography,
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import Link from '@material-ui/core/Link';
import moment from 'moment';
import {
    AppContext,
    SttForm,
    SttSelectMship,
    SttUploadDialog,
} from '../../../sporttia/all';
import { subsetObject } from '../../../lib/utils';
import sporttiaImage from '../../../images/sporttia.svg';
import ConfigurationTriggersDialog from './ConfigurationTriggersDialog';

export default function ConfigurationMain() {
    const cxt = useContext(AppContext);
    const [sc, setSc] = useState({});
    const [mship, setMship] = useState({ fullName: '' });
    const [openUploadPhoto, setOpenUploadPhoto] = useState(false);
    const [
        openConfigurationTriggersDialog,
        setOpenConfigurationTriggersDialog,
    ] = useState(false);

    /**
     * Handle form
     */
    const handleForm = ({ name, value }) => {
        if (name === 'numInvoiceFormat') {
            setSc({
                ...sc,
                customer: {
                    ...sc.customer,
                    numInvoiceFormat: value,
                },
            });
        } else if (name === 'timezoneId') {
            const finalSc = sc;
            finalSc.timezone.id = value;
            setSc(finalSc);
        } else {
            setSc({ ...sc, [name]: value });
        }
    };

    /**
     * Save sportcenter
     */
    const saveSportcenter = () => {
        const params = subsetObject(sc, [
            'name',
            'bulletin',
            'addrees',
            'web',
            'emailPrivated',
            'emailPublic',
            'phonePrivated',
            'phonePublic',
            'mobileSMS',
            'facebook',
            'twitter',
            'updatePursesOnCascadeAllowed',
            'termsAndConditions',
            'iban',
        ]);

        // Set guest user
        if (mship && mship.id && mship.user && mship.user.id) {
            params.idGuestUser = mship.user.id;
        }

        // Set guestUser to null if fullname = ''
        if (mship?.fullName === '') {
            params.idGuestUser = null;
        }

        // Set timezone
        if (sc.timezone) {
            params.idTimezone = sc.timezone.id;
        }

        // Call API
        cxt.api('PUT', `/scs/${cxt.sc.id}`, {
            params,
            success: (r) => {
                cxt.showMessage('S', cxt.t('Saved'));
            },
        });
    };

    /**
     * Load sports center
     */
    const loadSportcenter = () => {
        cxt.api('GET', `/scs/${cxt.sc.id}`, {
            success: (r) => {
                setSc(r.sc);

                // Set guest user
                if (r.sc.guestUser && r.sc.guestUser.mship) {
                    setMship(r.sc.guestUser.mship);
                }
            },
        });
    };

    /**
     * Init
     */
    useEffect(() => {
        loadSportcenter();
    }, []);

    // Las visit (with this account)
    let lastVisit = null;
    if (cxt?.user?.lastVisit) {
        lastVisit = moment(cxt?.user?.lastVisit).format('D MMM YYYY - HH:mm');
    }

    // -----| Render |-----
    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <Card>
                        {sc.logo ? (
                            <img
                                style={{ width: '100%' }}
                                src={cxt.getResource(
                                    `/images/${sc.logo.id}.jpg`,
                                )}
                                alt={`${sc.name} logo`}
                            />
                        ) : (
                            <Box p={2}>
                                <img
                                    style={{ width: '100%' }}
                                    src={sporttiaImage}
                                    alt="Sporttia logo"
                                />
                            </Box>
                        )}

                        <CardActions>
                            <Button
                                fullWidth
                                size="small"
                                color="primary"
                                startIcon={<PublishIcon />}
                                onClick={() => setOpenUploadPhoto(true)}
                            >
                                {cxt.t('Upload')}
                            </Button>
                        </CardActions>

                        {lastVisit && (
                            <Box p={1} mt={2}>
                                <Typography variant="body1">
                                    Última visita:
                                </Typography>
                                <Typography variant="body2">
                                    {lastVisit}
                                </Typography>
                            </Box>
                        )}
                    </Card>

                    <Box mt={3}>
                        <Link
                            onClick={() =>
                                setOpenConfigurationTriggersDialog(true)
                            }
                        >
                            {cxt.t('SeeIntegrationOperations')}
                        </Link>
                    </Box>
                </Grid>
                <Grid item xs={12} md={9}>
                    <SttForm
                        onChangeForm={handleForm}
                        elements={[
                            {
                                md: 12,
                                sm: 12,
                                xs: 12,
                                caption: cxt.t('Name'),
                                name: 'name',
                                value: sc.name,
                            },
                            {
                                md: 12,
                                sm: 12,
                                xs: 12,
                                type: 'textarea',
                                caption: cxt.t('Bulletin'),
                                name: 'bulletin',
                                value: sc.bulletin,
                            },
                            {
                                md: 6,
                                sm: 12,
                                xs: 12,
                                caption: cxt.t('Address'),
                                name: 'address',
                                value: sc.address,
                            },
                            {
                                md: 6,
                                sm: 12,
                                xs: 12,
                                caption: cxt.t('WebPage'),
                                name: 'web',
                                value: sc.web,
                            },
                            {
                                md: 6,
                                sm: 12,
                                xs: 12,
                                caption: cxt.t('page.sc.conf.emailPrivated'),
                                name: 'emailPrivated',
                                value: sc.emailPrivated,
                            },
                            {
                                md: 6,
                                sm: 12,
                                xs: 12,
                                caption: cxt.t('page.sc.conf.emailPublic'),
                                name: 'emailPublic',
                                value: sc.emailPublic,
                            },
                            {
                                md: 6,
                                sm: 12,
                                xs: 12,
                                caption: cxt.t('page.sc.conf.phonePublic'),
                                name: 'phonePublic',
                                value: sc.phonePublic,
                            },
                            {
                                md: 6,
                                sm: 12,
                                xs: 12,
                                caption: cxt.t('page.sc.conf.phonePrivated'),
                                name: 'phonePrivated',
                                disabled: true,
                                value: sc.phonePrivated,
                            },
                            {
                                md: 6,
                                sm: 12,
                                xs: 12,
                                caption: cxt.t('page.sc.conf.SMSPhone'),
                                name: 'mobileSMS',
                                value: sc.mobileSMS,
                            },
                            {
                                md: 6,
                                sm: 12,
                                xs: 12,
                                caption: 'Facebook',
                                name: 'facebook',
                                value: sc.facebook,
                            },
                            {
                                md: 6,
                                sm: 12,
                                xs: 12,
                                caption: 'Twitter',
                                name: 'twitter',
                                value: sc.twitter,
                            },
                            {
                                md: 6,
                                sm: 12,
                                xs: 12,
                                type: 'component',
                                component: (
                                    <SttSelectMship
                                        caption={cxt.t('GuestUser')}
                                        mship={mship}
                                        onSelect={setMship}
                                    />
                                ),
                            },
                            {
                                type: 'textInput',
                                name: 'iban',
                                caption: cxt.t('IBAN'),
                                md: 6,
                                sm: 12,
                                xs: 12,
                                value: sc.iban,
                            },
                            {
                                xs: 12,
                                type: 'textarea',
                                rows: 10,
                                caption: cxt.t('TermsAndConditions'),
                                name: 'termsAndConditions',
                                value: sc.termsAndConditions,
                            },
                        ]}
                        buttons={[
                            {
                                type: 'save',
                                onClick: () => saveSportcenter(),
                            },
                        ]}
                    />
                </Grid>
            </Grid>

            <SttUploadDialog
                open={openUploadPhoto}
                types={['SC_LOGO']}
                onClose={() => setOpenUploadPhoto(false)}
                onUploaded={(r) => {
                    const { file } = r;
                    setOpenUploadPhoto(false);
                    setSc({
                        ...sc,
                        logo: {
                            id: file.id,
                        },
                    });
                }}
            />

            <ConfigurationTriggersDialog
                open={openConfigurationTriggersDialog}
                onClose={() => setOpenConfigurationTriggersDialog(false)}
            />
        </Box>
    );
}
