import { useContext } from 'react';
import { useAPI } from '../lib/hooks';
import useRestService from './RestService';
import { AppContext } from '../sporttia/AppContext';

const resource = '/customers';

/**
 * Hook to access all 'customers' resources.
 */
export default function useCustomersService() {
    const { migrationApis } = useContext(AppContext);
    const [request] = useAPI(migrationApis);
    const services = useRestService(resource);

    /**
     * GET /customers/:id/subscriptions request.
     * @param id Customer ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function getCustomerSubscriptions(id, params) {
        return request('GET', `${resource}/${id}/subscriptions`, params);
    }

    /**
     * PUT /customers/subscriptions/:id request.
     * @param id Subscription ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function updateCustomerSubscriptions(id, params) {
        return request('PUT', `${resource}/subscriptions/${id}`, params);
    }

    /**
     * POST /customers/:id/subscriptions request.
     * @param id Customer ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function createCustomerSubscriptions(id, params) {
        return request('POST', `${resource}/${id}/subscriptions`, params);
    }

    /**
     * DELETE /customers/subscriptions/:id request.
     * @param id Subscription ID.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function removeCustomerSubscriptions(id) {
        return request('DELETE', `${resource}/subscriptions/${id}`);
    }

    /**
     * POST /customers/:id/scs request.
     * @param id Customer ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function createCustomerScs(id, params) {
        return request('POST', `${resource}/${id}/scs`, params);
    }

    return {
        ...services,
        getCustomerSubscriptions,
        updateCustomerSubscriptions,
        createCustomerSubscriptions,
        removeCustomerSubscriptions,
        createCustomerScs,
    };
}
