import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { CheckOutlined } from '@material-ui/icons';
import {
    AppContext,
    SttDialog,
    SttFormDate,
    SttFormSelect,
    SttFormText,
    SttFormTextarea,
    SttFormTime,
    SttSelectAutocomplete,
    SttSelectWeekdays,
} from '../../../sporttia/all';
import AttendancesDialog from './AttendancesDialog';

/**
 * ActivityClassDialog - Dialog to add / modify classes to an Activity
 * bool open - dialog is open/closed
 * Object item - The class itself. If it contains an id, it's an existing class
 * Object activity - The parent Activity, if any
 * Function onSave - callback when changes are applied or one or more classes are created
 * Function onClose - closing callback
 * Array teachers - array of teaches from this sports center
 */
export default function ActivityClassDialog({
    open,
    item,
    activity,
    onSave,
    onClose,
    availableTeachers = [],
}) {
    const cxt = useContext(AppContext);

    // 'class' is a reserved keyword in Javascript, so we use theClass instead
    const [theClass, setTheClass] = useState(item);
    const [teachers, setTeachers] = useState(availableTeachers);
    // Facilities from this sc
    const [facilities, setFacilities] = useState([]);
    const [dialog, setDialog] = useState(false);

    // load facilities from API
    function loadFacilities() {
        cxt.api('GET', `/scs/${cxt.sc.id}/facilities`, {
            params: {
                rows: 1000,
                page: 1,
            },
            success: (response) => {
                if (response.rows) {
                    setFacilities(response.rows);
                }
            },
        });
    }

    function loadTeachers() {
        cxt.api('GET', `/scs/${cxt.sc.id}/teachers`, {
            params: { page: 1, rows: 1000 },
            success: (response) => {
                if (response.rows) {
                    setTeachers(response.rows);
                }
            },
        });
    }

    // startup
    useEffect(() => {
        loadFacilities();
        if (teachers.length === 0) {
            loadTeachers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // if the 'item' prop changes, set is as theClass
    useEffect(() => {
        setTheClass(item);
    }, [item]);

    if (!theClass) {
        return null;
    }

    // apply 'value' to field 'name'
    function handleChange({ name, value }) {
        setTheClass({ ...theClass, [name]: value });
    }

    // Select a facility from the list
    function setFacility(id) {
        const facility = facilities.find((f) => f.id === id);

        if (facility) {
            setTheClass({
                ...theClass,
                facility: { id: facility.id, name: facility.name },
            });
        } else {
            setTheClass({ ...theClass, facility: null });
        }
    }

    // Select a teacher from the list
    function setTeacher(id) {
        const teacher = teachers.find((t) => t.id === id);

        if (teacher) {
            setTheClass({
                ...theClass,
                teacher: { id: teacher.id, name: teacher.name },
            });
        } else {
            setTheClass({ ...theClass, teacher: null });
        }
    }

    /*
    Set the date(s).

        If the class already exists, both ini and end refer to the same day, and the TIME part of the date refers to the class duration.
            For example: 2020-04-07 10:00:00 - 2020-04-07 12:00:00 Means a class on 2020-04-07 that runs from 10:00 to 12:00

        If the class doesn't exist (has no id), then ini and end function both as start and end of the date range, as well as start time and end time
            For example: 2020-04-07 10:00:00 - 2020-05-07 12:00:00 means that classes will be created from 2020-04-07 to 2020-05-07 and they
            will each run from 10:00 to 12:00

    Weird, but that's how it is.
    */
    function setDate({ name, value }) {
        const current = moment(theClass[name]);

        if (theClass.id) {
            setTheClass({
                ...theClass,
                ini: `${moment(value).format('YYYY-MM-DD')} ${current.format(
                    'HH:mm:ss',
                )}`,
            });
            setTheClass({
                ...theClass,
                end: `${moment(value).format('YYYY-MM-DD')} ${current.format(
                    'HH:mm:ss',
                )}`,
            });
        } else {
            setTheClass({
                ...theClass,
                [name]: `${moment(value).format('YYYY-MM-DD')} ${moment(
                    current,
                ).format('HH:mm:ss')}`,
            });
        }
    }

    // Set the time part of the 'name' date
    function setTime({ name, value }) {
        const current = moment(theClass[name]);
        setTheClass({
            ...theClass,
            [name]: `${current.format('YYYY-MM-DD')} ${value}`,
        });
    }

    // Show success message, then call both onSave and onClose
    function saveAndClose() {
        cxt.showMessage('S', cxt.t('OperationSuccessful'));
        onSave?.();
        onClose?.();
    }

    // Save via API. Uses PUT or POST depending on whether the class has an id
    function saveClass() {
        const ini = moment(theClass.ini).format('YYYY-MM-DDTHH:mm:ss');
        const end = moment(theClass.end).format('YYYY-MM-DDTHH:mm:ss');

        if (theClass.id) {
            cxt.api('PUT', `/classes/${theClass.id}`, {
                params: {
                    name: theClass.name,
                    notes: theClass.notes,
                    status: theClass.status,
                    idFacility: theClass.facility
                        ? theClass.facility.id
                        : undefined,
                    idTeacher: theClass.teacher
                        ? theClass.teacher.id
                        : undefined,
                    ini,
                    end,
                },
                success: () => {
                    saveAndClose();
                },
            });
        } else {
            cxt.api('POST', `/classes`, {
                params: {
                    name: theClass.name,
                    notes: theClass.notes,
                    status: theClass.status,
                    idFacility: theClass.facility
                        ? theClass.facility.id
                        : undefined,
                    idTeacher: theClass.teacher
                        ? theClass.teacher.id
                        : undefined,
                    idActivity: activity ? activity.id : undefined,
                    ini,
                    end,
                    weekdays: theClass.weekdays,
                },
                success: () => {
                    saveAndClose();
                },
            });
        }
    }

    // Delete via API
    function deleteClass() {
        cxt.api('DELETE', `/classes/${theClass.id}`, {
            confirmation: true,
            success: () => {
                saveAndClose();
            },
        });
    }

    if (!theClass) {
        return null;
    }

    const buttons = [
        {
            type: 'save',
            onClick: saveClass,
        },
    ];

    // If the class has an id, we plug the 'delete' button after the 'save' button
    if (theClass.id) {
        buttons.splice(
            1,
            0,
            {
                type: 'delete',
                onClick: deleteClass,
            },
            {
                icon: <CheckOutlined />,
                caption: cxt.t('Assistences'),
                onClick: () => setDialog(true),
            },
        );
    }

    return (
        <>
            <SttDialog
                title={cxt.t('Class')}
                subtitle={activity && activity.name}
                maxWidth="sm"
                open={open}
                buttons={buttons}
                onClose={onClose}
                content={
                    <div>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <SttFormText
                                    name="name"
                                    caption={cxt.t('Name')}
                                    defVal={theClass.name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SttFormTextarea
                                    name="notes"
                                    caption={cxt.t('Notes')}
                                    defVal={theClass.notes}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SttFormSelect
                                    caption={cxt.t('Status')}
                                    name="status"
                                    defVal={theClass.status}
                                    options={[
                                        {
                                            caption: cxt.t('Active'),
                                            value: 'ACTIVE',
                                        },
                                        {
                                            caption: cxt.t('Inactive'),
                                            value: 'INACTIVE',
                                        },
                                    ]}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SttSelectAutocomplete
                                    name="facility"
                                    caption={cxt.t('Facility')}
                                    options={facilities}
                                    defVal={
                                        theClass.facility
                                            ? theClass.facility.id
                                            : null
                                    }
                                    onChange={({ value }) => setFacility(value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={theClass.id ? 6 : 12}>
                                {teachers && (
                                    <SttSelectAutocomplete
                                        name="teacher"
                                        caption={cxt.t('Teacher')}
                                        options={teachers}
                                        defVal={
                                            theClass.teacher
                                                ? theClass.teacher.id
                                                : null
                                        }
                                        onChange={({ value }) =>
                                            setTeacher(value)
                                        }
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SttFormDate
                                    name="ini"
                                    caption={cxt.t('Date')}
                                    defVal={theClass.ini}
                                    onChange={setDate}
                                />
                            </Grid>
                            {!theClass.id && (
                                <Grid item xs={12} sm={6}>
                                    <SttFormDate
                                        name="end"
                                        caption={cxt.t('End')}
                                        defVal={theClass.end}
                                        onChange={setDate}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} sm={6}>
                                <SttFormTime
                                    name="ini"
                                    caption={cxt.t('Begin')}
                                    defVal={moment(theClass.ini).format(
                                        'HH:mm',
                                    )}
                                    min="06:00:00"
                                    max="23:45:00"
                                    interval={5}
                                    onChange={setTime}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SttFormTime
                                    name="end"
                                    caption={cxt.t('Until')}
                                    defVal={moment(theClass.end).format(
                                        'HH:mm',
                                    )}
                                    min="06:00:00"
                                    max="23:45:00"
                                    interval={5}
                                    onChange={setTime}
                                />
                            </Grid>
                            {!theClass.id && (
                                <Grid item xs={12}>
                                    <SttSelectWeekdays
                                        name="weekdays"
                                        selected={theClass.weekdays}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </div>
                }
            />
            <AttendancesDialog
                activity={activity}
                open={dialog}
                onClose={() => setDialog(false)}
            />
        </>
    );
}
