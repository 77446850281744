import React, { useContext, useEffect, useState } from 'react';
import { Box, Paper } from '@material-ui/core';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import { SttFullDialog } from '../../../../sporttia/dialogs/SttFullDialog';
import { AppContext } from '../../../../sporttia/AppContext';
import { useLoader } from '../../../../lib/hooks';
import useScsService from '../../../../services/ScsService';
import SttValidatedForm from '../../../../sporttia/SttValidatedForm';

/**
 * Dialog to create API Triggers.
 * @param apiTriggerValue API Trigger object.
 * @param onClose Function executed when closing the dialog.
 * @param onCreate Action taken when creating the api parameter.
 * @returns {JSX.Element}
 */
export default function AdminApiTriggerByScDialog({
    apiTriggerValue,
    onClose,
    onCreate,
}) {
    const cxt = useContext(AppContext);
    const scsService = useScsService();
    const form = useForm();
    const [apiTrigger, setApiTrigger] = useState(apiTriggerValue);
    const createMutation = useMutation(
        (params) =>
            scsService.createScsCoreTrigger(params?.scId, params?.triggerId),
        {
            onSuccess: (data) => {
                onCreate(data);
                setApiTrigger(data?.coreTriggerSC);
            },
        },
    );
    const [, loader] = useLoader([createMutation.status]);

    /**
     * When the "apiTrigger" data is returned by the API, it is set in the form.
     */
    useEffect(() => {
        if (apiTrigger) {
            form.reset({
                scId: apiTrigger?.sc?.id,
                triggerId: apiTrigger?.coreTrigger?.id,
            });
        } else {
            form.reset({
                scId: '',
                triggerId: '',
            });
        }
    }, [apiTrigger]);

    /**
     * When the value of the api trigger passed to the component is modified, the selected internal
     * parameter saved in the "apiTrigger" state will be modified.
     */
    useEffect(() => {
        setApiTrigger(apiTriggerValue);
    }, [apiTriggerValue]);

    return (
        <SttFullDialog
            open={apiTrigger !== null}
            onClose={onClose}
            title={cxt.t('createApiTriggerBySc')}
        >
            {loader}
            <Paper>
                <Box p={3}>
                    <SttValidatedForm
                        form={form}
                        fields={[
                            {
                                type: 'textinput',
                                name: 'scId',
                                caption: cxt.t('scId'),
                            },
                            {
                                type: 'textinput',
                                name: 'triggerId',
                                caption: cxt.t('triggerId'),
                            },
                        ]}
                        buttons={[
                            {
                                type: 'save',
                                onClick: form.handleSubmit((formData) =>
                                    createMutation.mutate({ ...formData }),
                                ),
                            },
                        ]}
                    />
                </Box>
            </Paper>
        </SttFullDialog>
    );
}
