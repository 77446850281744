import React, { useState, useEffect, useContext } from 'react';
import { Grid, Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
    AppContext,
    SttDialog,
    SttFormSelect,
    SttFormTextarea,
    SttSelectUnlockerType,
} from '../all';

export function SttUnlockerDialog({
    idUnlocker, // When is modifying
    idUser, // Only when is creating
    idSC, // Only when is creating
    open = false,
    onClose,
    onDelete,
    onSave,
}) {
    const cxt = useContext(AppContext);
    const [unlocker, setUnlocker] = useState();

    /**
     * Init
     */
    useEffect(() => {
        if (idUnlocker) loadUnlocker();
        else
            setUnlocker({
                type: cxt.constants.unlockerTypes[0].id,
            });
    }, [idUnlocker]);

    /**
     * Load unlocker
     */
    const loadUnlocker = () => {
        cxt.api('GET', `/unlockers/${idUnlocker}`, {
            params: { trash: true },
            success: (r) => {
                setUnlocker(r.unlocker);
            },
        });
    };

    /**
     * Save
     */
    const save = () => {
        // Update
        if (unlocker.id) {
            // Update unlocker
            cxt.api('PUT', `/unlockers/${idUnlocker}`, {
                params: {
                    type: unlocker.type,
                    code: unlocker.code,
                },
                success: (r) => {
                    setUnlocker(r.unlocker);
                    cxt.showMessage('S', cxt.t('Saved'));
                },
            });
        } else {
            // Create unlocker
            cxt.api('POST', `/scs/${idSC}/users/${idUser}/unlockers`, {
                params: {
                    type: unlocker.type,
                    code: unlocker.code,
                },
                success: (r) => {
                    cxt.showMessage('S', cxt.t('Saved'));
                    setUnlocker(null); // clear state
                    onClose();
                    if (onSave) onSave(r.unlocker);
                },
            });
        }
    };

    /**
     * Remove
     */
    const remove = () => {
        cxt.api('DELETE', `/unlockers/${idUnlocker}`, {
            confirmation: true,
            success: (r) => {
                cxt.showMessage('S', cxt.t('Deleted'));
                onClose();
                if (onDelete) onDelete(r.unlocker);
            },
        });
    };

    /**
     * Handle form
     */
    const handleForm = ({ name, value }) => {
        setUnlocker({ ...unlocker, [name]: value });
    };

    // -----| Render |-----
    return (
        <SttDialog
            title={cxt.t('Unlocker')}
            open={open}
            onClose={onClose}
            content={
                <>
                    {unlocker && (
                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <SttSelectUnlockerType
                                    name="type"
                                    defVal={unlocker.type}
                                    onChange={handleForm}
                                />
                            </Grid>

                            <SttFormTextarea
                                grid
                                name="code"
                                caption={cxt.t('Code')}
                                defVal={unlocker.code}
                                onChange={handleForm}
                            />
                        </Grid>
                    )}

                    {unlocker && unlocker.type === 'PIN' && !unlocker.id && (
                        <Box mt={2}>
                            <Alert security="info">
                                {cxt.t('popup.unlocker.generatePIN')}
                            </Alert>
                        </Box>
                    )}

                    {unlocker && unlocker.type === 'QR' && unlocker.id && (
                        <Box align="center" mx="auto">
                            <img
                                src={cxt.getResource(
                                    `/unlockers/${unlocker.id}.svg`,
                                )}
                                width="250px"
                            />
                        </Box>
                    )}
                </>
            }
            buttons={[
                {
                    type: 'delete',
                    onClick: () => remove(),
                    show: unlocker && unlocker.id != undefined,
                },
                {
                    type: 'save',
                    onClick: () => save(),
                },
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
        />
    );
}
