import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import { SttDialog } from '../../../sporttia/dialogs/SttDialog';
import useGolfService from '../../../services/GolfService';
import SttValidatedForm from '../../../sporttia/SttValidatedForm';
import { useLoader, useTranslations } from '../../../lib/hooks';

/**
 * Dialog to modify a golf hole.
 * @param golfHole Golf hole object.
 * @param onClose Launched when the dialog is closed.
 * @param onUpdate Launched when a golf course hole is updated.
 * @returns {JSX.Element}
 */
export function GolfCourseConfigurationHoleDialog({
    golfHole,
    onClose,
    onUpdate,
}) {
    const { translate } = useTranslations();
    const form = useForm();
    const golfService = useGolfService();
    const updateHoleMutation = useMutation(
        (params) =>
            golfService.updateGolfCourseHole(params?.id, params?.params),
        {
            onSuccess: onUpdate,
        },
    );
    const [, loader] = useLoader([updateHoleMutation.status]);

    /**
     * Method executed when the user clicks on save hole.
     * @param formData Form data.
     */
    const mutateHole = (formData) => {
        const formatedFormData = { ...formData };

        formatedFormData.status = formData?.status ? 'ACTIVE' : 'INACTIVE';

        updateHoleMutation.mutate({
            id: golfHole?.id,
            params: formatedFormData,
        });
    };

    /**
     * When the data is returned by the API, it is set in the form.
     */
    useEffect(() => {
        if (golfHole) {
            form.reset({
                par: golfHole?.par,
                notes: golfHole?.notes,
                tee: golfHole?.tee,
                status: golfHole?.status === 'ACTIVE',
            });
        } else {
            form.reset({
                par: '',
                notes: '',
                tee: false,
                status: true,
            });
        }
    }, [golfHole]);

    return (
        <SttDialog
            title={
                golfHole ? `${translate('editHole')} ${golfHole?.position}` : ''
            }
            open={golfHole !== null}
            onClose={onClose}
            content={
                <>
                    {loader}
                    <SttValidatedForm
                        form={form}
                        fields={[
                            {
                                type: 'textInput',
                                name: 'par',
                                caption: 'Par',
                            },
                            {
                                type: 'textArea',
                                name: 'notes',
                                caption: translate('Notes'),
                                rows: 8,
                            },
                            {
                                type: 'check',
                                name: 'tee',
                                caption: 'Tee',
                                md: 6,
                            },
                            {
                                type: 'check',
                                name: 'status',
                                caption: translate('Active'),
                                md: 6,
                            },
                        ]}
                    />
                </>
            }
            buttons={[
                {
                    type: 'save',
                    onClick: form.handleSubmit((formData) =>
                        mutateHole(formData),
                    ),
                },
            ]}
        />
    );
}
