import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import SttValidatedForm from '../../../../sporttia/SttValidatedForm';
import { AppContext } from '../../../../sporttia/AppContext';
import useApisService from '../../../../services/ApisService';
import { useLoader } from '../../../../lib/hooks';

/**
 * API details view. View with form to modify api data.
 * @param api API Object.
 * @param status React-query status.
 * @param onCreate Launched when a new customer is created.
 * @param onUpdate Launched when a new customer is updated.
 * @param onDelete Launched when a new customer is removed.
 * @returns {JSX.Element}
 */
export default function AdminApiDetails({
    api,
    status,
    onCreate,
    onUpdate,
    onDelete,
}) {
    const cxt = useContext(AppContext);
    const form = useForm();
    const apisService = useApisService();
    const deleteMutation = useMutation(
        (params) => apisService.remove(params?.id),
        {
            onSuccess: onDelete,
        },
    );
    const updateMutation = useMutation(
        (params) => apisService.update(params?.id, params?.params),
        {
            onSuccess: onUpdate,
        },
    );
    const createMutation = useMutation(
        (params) => apisService.create(params?.params),
        {
            onSuccess: (data) => onCreate(data?.api?.id),
        },
    );
    const [, loader] = useLoader([
        deleteMutation.status,
        updateMutation.status,
        createMutation.status,
    ]);

    /**
     * Method executed when the user clicks on save or create api.
     */
    const mutateApi = (formData) => {
        if (api?.id) {
            updateMutation.mutate({ id: api?.id, params: formData });
        } else {
            createMutation.mutate({ params: formData });
        }
    };

    /**
     * When the data is returned by the API, it is set in the form.
     */
    useEffect(() => {
        if (status === 'success' && api) {
            form.reset({
                id: api?.id,
                created: api?.created,
                type: api?.type,
                method: api?.method,
                endpoint: api?.endpoint,
                name: api?.name,
                description: api?.description,
                object: api?.object,
                function: api?.function,
                category: api?.category,
                public: api?.public,
            });
        } else {
            form.reset({
                id: '',
                created: new Date(),
                type: '',
                method: 'GET',
                endpoint: '',
                name: '',
                description: '',
                object: '',
                function: '',
                category: '',
                public: false,
            });
        }
    }, [api]);

    return (
        <>
            {loader}
            <SttValidatedForm
                form={form}
                loadingData={status === 'loading'}
                fields={[
                    {
                        type: 'textInput',
                        name: 'id',
                        caption: 'ID',
                        disabled: true,
                        md: 2,
                    },
                    {
                        type: 'date',
                        name: 'created',
                        caption: cxt.t('Created'),
                        disabled: true,
                        md: 3,
                    },
                    {
                        type: 'select',
                        name: 'method',
                        caption: cxt.t('Method'),
                        options: [
                            { caption: 'GET', value: 'GET' },
                            { caption: 'POST', value: 'POST' },
                            { caption: 'PUT', value: 'PUT' },
                            { caption: 'DELETE', value: 'DELETE' },
                        ],
                        disableClearable: true,
                        md: 3,
                    },
                    {
                        type: 'textInput',
                        name: 'endpoint',
                        caption: cxt.t('Endpoint'),
                        md: 4,
                    },
                    {
                        type: 'textInput',
                        name: 'name',
                        caption: cxt.t('Name'),
                    },
                    {
                        type: 'textArea',
                        name: 'description',
                        caption: cxt.t('Description'),
                        rows: 10,
                    },
                    {
                        type: 'textInput',
                        name: 'object',
                        caption: cxt.t('Object'),
                        md: 3,
                    },
                    {
                        type: 'textInput',
                        name: 'function',
                        caption: cxt.t('Function'),
                        md: 3,
                    },
                    {
                        type: 'textInput',
                        name: 'category',
                        caption: cxt.t('Category'),
                        md: 3,
                    },
                    {
                        type: 'check',
                        name: 'public',
                        caption: cxt.t('Public'),
                        md: 3,
                    },
                ]}
                buttons={[
                    {
                        show: !api?.trash,
                        type: 'save',
                        onClick: form.handleSubmit((formData) =>
                            mutateApi(formData),
                        ),
                    },
                    {
                        show: api?.id && !api?.trash,
                        type: 'delete',
                        onClick: () => deleteMutation.mutate({ id: api?.id }),
                    },
                ]}
            />
        </>
    );
}
