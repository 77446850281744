import React, { useContext, useEffect } from 'react';
import { Box, Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { AppContext, SttFullDialog } from '../../../../sporttia/all';
import SttValidatedForm from '../../../../sporttia/SttValidatedForm';

/**
 * Dialog to get api log data.
 * @param apiLog API Log object.
 * @param onClose Function executed when closing the full dialog.
 * @returns {JSX.Element}
 */
export default function AdminApiLogDialog({ apiLog = null, onClose }) {
    const cxt = useContext(AppContext);
    const form = useForm();

    /**
     * When the data is returned by the API, it is set in the form.
     */
    useEffect(() => {
        if (apiLog) {
            form.reset({
                id: apiLog?.id,
                created: apiLog?.created,
                method: apiLog?.api?.method,
                ip: apiLog?.ip,
                endpoint: apiLog?.endpoint,
                executor: apiLog?.executor?.name,
                query: apiLog?.query,
                body: apiLog?.body,
                error: apiLog?.error,
                agent: apiLog?.agent,
                curl: apiLog?.curl,
            });
        } else {
            form.reset({
                id: '',
                created: '',
                method: '',
                ip: '',
                endpoint: '',
                executor: '',
                query: '',
                body: '',
                error: '',
                agent: '',
                curl: '',
            });
        }
    }, [apiLog]);

    return (
        <SttFullDialog open={apiLog !== null} onClose={onClose} title="API Log">
            <Box mb={1}>
                <Paper>
                    <Box p={2}>
                        <SttValidatedForm
                            form={form}
                            fields={[
                                {
                                    type: 'label',
                                    caption: 'Id',
                                    name: 'id',
                                    md: 3,
                                },
                                {
                                    type: 'label',
                                    caption: cxt.t('Created'),
                                    name: 'created',
                                    md: 3,
                                },
                                {
                                    type: 'label',
                                    caption: cxt.t('Method'),
                                    name: 'method',
                                    md: 3,
                                },
                                {
                                    type: 'label',
                                    caption: 'IP',
                                    name: 'ip',
                                    md: 3,
                                },
                                {
                                    type: 'label',
                                    caption: cxt.t('Endpoint'),
                                    name: 'endpoint',
                                    md: 6,
                                },
                                {
                                    type: 'label',
                                    caption: cxt.t('Executor'),
                                    name: 'executor',
                                    md: 6,
                                },
                                {
                                    type: 'label',
                                    caption: cxt.t('Query'),
                                    name: 'query',
                                },
                                {
                                    type: 'label',
                                    caption: cxt.t('Body'),
                                    name: 'body',
                                },
                                {
                                    type: 'label',
                                    caption: cxt.t('Error'),
                                    name: 'error',
                                },
                                {
                                    type: 'label',
                                    caption: cxt.t('Agent'),
                                    name: 'agent',
                                },
                                {
                                    type: 'label',
                                    caption: 'Curl',
                                    name: 'curl',
                                },
                            ]}
                        />
                    </Box>
                </Paper>
            </Box>

            {apiLog?.logs && (
                <Paper>
                    <Box p={2} style={{ overflow: 'auto' }}>
                        <pre>{apiLog.logs}</pre>
                    </Box>
                </Paper>
            )}
        </SttFullDialog>
    );
}
