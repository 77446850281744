import React, { useEffect, useContext, useState } from 'react';
import {
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    Button,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Close, LockOpen } from '@material-ui/icons';
import { useCrud, useInteractionsFiles } from '../../../lib/hooks';
import {
    AppContext,
    SttLabelTitle,
    SttLabelForm,
    SttPage,
    SttButtonTicket,
    SttButton,
} from '../../../sporttia/all';
import { getPath } from '../../Pages';
import { formatPriceByLocale, fullName } from '../../../lib/utils';
import Occupants from './Occupants';
import HandlePaymentsModal from '../../sc/groups/HandlePaymentsModal';

export default function Booked({ match }) {
    const cxt = useContext(AppContext);
    const { openFile } = useInteractionsFiles();
    const history = useHistory();
    const [booking, setBooking, , Get, , , Delete] = useCrud();
    const [payments, setPayments] = useState();
    const [openingDevice, setOpeningDevice] = useState(false);
    const [ready, setReady] = useState(false);
    const [openAskingPaymentProcedure, setOpenAskingPaymentProcedure] =
        useState(false);
    const [cancelPrivilege, setCancelPrivilege] = useState(false);

    function loadBooking() {
        const { id } = match.params;

        Get(`/bookings/${id}`)
            .then((response) => {
                setBooking(response.booking);
                cxt.setHeader({
                    title: `${cxt.t('Booking')}`,
                });
            })
            .catch(() => {});
    }

    function loadPayments() {
        const { id } = match.params;

        Get(`/bookings/${id}/payments`).then(({ rows }) => {
            setPayments(rows);
            cxt.setHeader({
                title: `${cxt.t('Booking')}`,
            });
        });
    }

    /**
     * Booking id changes
     */
    useEffect(() => {
        loadBooking();
        loadPayments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.id]);

    useEffect(() => {
        if (booking && booking.facility) {
            if (
                cxt.user.role === cxt.constants.roleUser ||
                cxt.user.role === cxt.constants.roleTeacher
            ) {
                Get(`/scs/${booking.facility.sc.id}/me`)
                    .then(({ mship }) => {
                        setCancelPrivilege(
                            cxt.user.role === cxt.constants.roleSC ||
                                mship.privileges.find((priv) => priv === 9) !==
                                    undefined,
                        );
                    })
                    .catch(() => {})
                    .finally(() => {
                        setReady(true);
                    });
            } else {
                setReady(true);
                setCancelPrivilege(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [booking]);

    function downloadTicket(format) {
        openFile(
            `/bookings/${booking.id}.pdf?paper=${format}`,
            'application/pdf',
        );
    }

    const openDevice = (device) => {
        cxt.api('POST', `/devices/${device.id}/openings`, {
            success: (r) => {
                setOpeningDevice(false);

                if (r.resultFromDevice.result === 'SUCCESS') {
                    cxt.showMessage('S', cxt.t('device.opening.opened'));
                } else {
                    cxt.showMessage('S', cxt.t('device.opening.error.general'));
                }
            },
            loading: setOpeningDevice,
        });
    };

    const cancelBooking = (deletingPaymentProcedure) => {
        let paymentAction;
        if (deletingPaymentProcedure !== undefined) {
            paymentAction = deletingPaymentProcedure;
        }

        Delete(
            `/bookings/${booking.id}`,
            { paymentAction },
            cxt.user && cxt.user.role === 'USER',
        )
            .then(() => {
                history.replace('/');
            })
            .catch(() => {});
    };

    if (!ready) {
        return null;
    }

    return (
        <Container maxWidth="md">
            {booking && (
                <>
                    <SttPage innerTitle={cxt.t('BookingConfirmed')}>
                        <Grid container spacing={3}>
                            <SttLabelForm
                                grid
                                xs={12}
                                sm={6}
                                title={cxt.t('Facility')}
                                text={booking.facility.name}
                            />

                            <SttLabelForm
                                grid
                                xs={12}
                                sm={6}
                                title={cxt.t('Center')}
                                text={`${booking.facility.sc.name}`}
                            />

                            <SttLabelForm
                                grid
                                xs={12}
                                sm={6}
                                title={cxt.t('Date')}
                                text={`${moment(booking.ini).format(
                                    'dddd, DD MMMM YYYY',
                                )}`}
                            />

                            <SttLabelForm
                                grid
                                xs={12}
                                sm={6}
                                title={cxt.t('Time')}
                                text={`${moment(booking.ini).format(
                                    'HH:mm',
                                )} - ${moment(booking.end).format('HH:mm')}`}
                            />

                            <SttLabelForm
                                grid
                                sm={6}
                                xs={12}
                                title={cxt.t('Total')}
                                text={`${formatPriceByLocale(
                                    booking?.price,
                                    booking?.priceShape?.currency,
                                    booking?.priceShape?.locale,
                                )}`}
                            />
                        </Grid>
                    </SttPage>

                    {/* --- Occupants (QR's) --- */}
                    {booking.occupants && (
                        <Occupants
                            occupants={booking.occupants}
                            idOwner={booking.user && booking.user.id}
                            scId={booking.facility.sc.id}
                            isFacilityWithCapacity={
                                booking.facility.maxOccupants > 0
                            }
                            cancelPrivilege={cancelPrivilege}
                        />
                    )}
                </>
            )}

            {/* --- Payments --- */}
            {payments && payments.length > 0 && (
                <Box mt={3}>
                    <Grid item xs={12}>
                        <div>
                            <SttLabelTitle>{cxt.t('payments')}</SttLabelTitle>
                        </div>
                        <Box pt={1}>
                            {payments.map((payment) => (
                                <Card key={payment.id}>
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6}>
                                                <SttLabelForm
                                                    title={fullName(
                                                        payment.user,
                                                    )}
                                                    text={payment.concept}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <SttLabelForm
                                                    title={`${formatPriceByLocale(
                                                        payment?.priceShape
                                                            ?.price,
                                                        payment?.priceShape
                                                            ?.currency,
                                                        payment?.priceShape
                                                            ?.locale,
                                                    )}`}
                                                    text={payment.statusLang}
                                                    textStyle={
                                                        payment.status !==
                                                        'PAID'
                                                            ? {
                                                                  color: '#ec3e2d',
                                                              }
                                                            : undefined
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            ))}
                        </Box>
                    </Grid>
                </Box>
            )}

            {/* --- Devices --- */}
            {booking &&
                booking.facility &&
                booking.facility.devices &&
                booking.facility.devices.count > 0 && (
                    <Box mt={3}>
                        <Grid item xs={12}>
                            <div>
                                <SttLabelTitle>
                                    {cxt.t('Devices')}
                                </SttLabelTitle>
                            </div>
                            <Box pt={1}>
                                {booking.facility.devices.rows
                                    .filter((device) => device.remoteOpening)
                                    .map((device) => (
                                        <Card key={device.id}>
                                            <CardContent>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <SttLabelForm
                                                            title={device.name}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <SttButton
                                                            caption={cxt.t(
                                                                'ToOpen',
                                                            )}
                                                            startIcon={
                                                                <LockOpen />
                                                            }
                                                            onClick={() =>
                                                                openDevice(
                                                                    device,
                                                                )
                                                            }
                                                            loading={
                                                                openingDevice
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    ))}
                            </Box>
                        </Grid>
                    </Box>
                )}

            {/* --- Buttons --- */}
            <Box mt={3}>
                <Grid container spacing={3}>
                    <Grid item md={cancelPrivilege ? 6 : 4} sm={12} xs={12}>
                        <SttButtonTicket
                            fullWidth
                            caption={`${cxt.t('Ticket')} A4`}
                            onClick={() => downloadTicket('A4')}
                        />
                    </Grid>

                    <Grid item md={cancelPrivilege ? 6 : 4} sm={12} xs={12}>
                        <SttButtonTicket
                            fullWidth
                            caption={cxt.t('Ticket')}
                            onClick={() => downloadTicket('TICKET')}
                        />
                    </Grid>

                    <Grid item md={cancelPrivilege ? 6 : 4} sm={12} xs={12}>
                        <SttButton
                            fullWidth
                            caption={cxt.t('GoToFields')}
                            onClick={() =>
                                history.push(
                                    `${getPath('timetable', {
                                        id: booking.facility.sc.id,
                                    })}?&date=${moment(booking.ini).format(
                                        'YYYY-MM-DD',
                                    )}`,
                                )
                            }
                        />
                    </Grid>

                    {cancelPrivilege && (
                        <Grid item md={6} xs={12}>
                            <Button
                                fullWidth
                                variant="contained"
                                className="button-delete"
                                onClick={() =>
                                    cxt.user && cxt.user.role === 'USER'
                                        ? cancelBooking(4)
                                        : setOpenAskingPaymentProcedure(true)
                                }
                                startIcon={<Close />}
                            >
                                {cxt.t('CancelBooking')}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Box>

            <HandlePaymentsModal
                warningText={cxt.t('sc.bookings.delete.howToHandlePayments')}
                open={openAskingPaymentProcedure}
                onClose={() => setOpenAskingPaymentProcedure(false)}
                onAccept={(option) => {
                    // JPB 9 Jul 2020. Deleting action codes in groups is different than in other in booking. */

                    switch (option) {
                        case '0':
                            cancelBooking();
                            break; // PBM: 'do nothing' case was missing
                        case '1':
                            cancelBooking(4);
                            break;
                        case '2':
                            cancelBooking(5);
                            break;
                        case '3':
                            cancelBooking(2);
                            break;
                        case '4':
                            cancelBooking(3);
                            break;
                        default:
                    }
                }}
            />
        </Container>
    );
}
