import React from 'react';
import { Container } from '@material-ui/core';
import Timetable from '../timetable/Timetable';

export default function Widget({ match }) {
    return (
        <Container maxWidth="md">
            <Timetable match={match} />
        </Container>
    );
}
