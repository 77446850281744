import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Subscription from '../subscription/Subscription';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(4),
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: 'auto',
    },
}));

// NoSubscription: container page to redirect the customer to, when they lack a valid license.
export default function NoSubscription() {
    const classes = useStyles();

    return (
        <Container maxWidth="xl" className={classes.container}>
            <Box flex={1}>
                <Subscription />
            </Box>
        </Container>
    );
}
