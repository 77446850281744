import React, { useContext, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import {
    Avatar,
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    ListItem,
    ListItemAvatar,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import { KeyboardDatePicker, TimePicker } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import { useQuery } from 'react-query';
import {
    SELECT_CITIES,
    SELECT_CUSTOMERS,
    SELECT_MSHIPS,
    SELECT_PROVINCES,
    SELECT_TPV_CONFIGURATION,
} from '../lib/queryKeys';
import useCitiesService from '../services/CitiesService';
import useCustomersService from '../services/CustomersService';
import useTpvsService from '../services/TpvsService';
import { SttButtonSave } from './buttons/SttButtonSave';
import { SttButtonDelete } from './buttons/SttButtonDelete';
import { SttButtonAccept } from './buttons/SttButtonAccept';
import { SttButtonRecover } from './buttons/SttButtonRecover';
import { SttButton } from './buttons/SttButton';
import { AppContext } from './AppContext';
import useScsService from '../services/ScsService';
import { checkOffTag } from '../lib/utils';
import { useTranslations } from '../lib/hooks';
import useProvincesService from '../services/ProvincesService';
import { SttGoogleMap, SttSelectGroup } from './all';
import { SttSelectCountry } from './selectors/SttSelectCountry';

const textHeight = 56;
const textAreaHeightPerRow = 24;

// TODO - Viendo que el componente está creciendo habría que sacar todas las funciones de los subcomponentes en ficheros separados.

function SttAutocompleteCity({
    name,
    caption,
    value,
    errors,
    onChange,
    idProvince,
    ...rest
}) {
    const citiesService = useCitiesService();
    const [queryParams, setQueryParams] = useState({
        rows: 100,
        name: '',
        idProvince,
    });

    const citiesQuery = useQuery([SELECT_CITIES, queryParams], () =>
        citiesService.getList(queryParams),
    );

    useEffect(() => {
        setQueryParams((prev) => ({ ...prev, idProvince }));
    }, [idProvince]);

    return (
        <Autocomplete
            options={
                citiesQuery?.data?.rows
                    ? citiesQuery?.data?.rows
                    : [
                          {
                              id: value?.id,
                              name: value?.name,
                          },
                      ]
            }
            loading={citiesQuery?.isLoading}
            getOptionLabel={(option) => (option?.name ? option?.name : '')}
            getOptionSelected={(option) => option?.id === value?.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={caption}
                    size="small"
                    variant="outlined"
                    error={!!errors[name]}
                    helperText={errors[name] ? errors[name].message : null}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {citiesQuery?.isLoading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            onChange={(e, data) => onChange(data)}
            onInputChange={(event, newInputValue) =>
                setQueryParams({
                    ...queryParams,
                    name: newInputValue,
                })
            }
            value={value || null}
            style={{ paddingTop: 16 }}
            {...rest}
        />
    );
}

function SttAutocompleteCustomer({
    name,
    caption,
    value,
    errors,
    onChange,
    ...rest
}) {
    const customersService = useCustomersService();
    const [queryParams, setQueryParams] = useState({
        rows: 100,
        name: '',
    });

    const customersQuery = useQuery([SELECT_CUSTOMERS, queryParams], () =>
        customersService.getList(queryParams),
    );

    return (
        <Autocomplete
            options={
                customersQuery?.data?.rows
                    ? customersQuery?.data?.rows
                    : [
                          {
                              id: value?.id,
                              name: value?.name,
                          },
                      ]
            }
            loading={customersQuery?.isLoading}
            getOptionLabel={(option) => (option?.name ? option?.name : '')}
            // Estas comprobaciones se hacen para que no aparezcan warnings, cuando dejamos sin
            // cliente el back-end devuelve customer con id === 0 y no corresponde con ninguno de
            // la lista, pero debemos dar la posibilidad de dejarlo en blanco.
            getOptionSelected={(option) =>
                value?.id === 0 || option?.id === value?.id
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={caption}
                    size="small"
                    variant="outlined"
                    error={!!errors[name]}
                    helperText={errors[name] ? errors[name].message : null}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {customersQuery?.isLoading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            onChange={(e, data) => onChange(data)}
            onInputChange={(event, newInputValue) =>
                setQueryParams({
                    ...queryParams,
                    name: newInputValue,
                })
            }
            value={value || null}
            style={{ paddingTop: 16 }}
            {...rest}
        />
    );
}

function SttAutocompleteTpvConfiguration({
    name,
    caption,
    value,
    errors,
    onChange,
    ...rest
}) {
    const tpvsService = useTpvsService();
    const [queryParams, setQueryParams] = useState({
        rows: 100,
        name: '',
    });

    const tpvsConfigurationsQuery = useQuery(
        [SELECT_TPV_CONFIGURATION, queryParams],
        () => tpvsService.getTpvsConfigurations(queryParams),
    );

    return (
        <Autocomplete
            options={
                tpvsConfigurationsQuery?.data?.rows
                    ? tpvsConfigurationsQuery?.data?.rows
                    : [
                          {
                              id: value?.id,
                              name: value?.name,
                          },
                      ]
            }
            loading={tpvsConfigurationsQuery?.isLoading}
            getOptionLabel={(option) => (option?.name ? option?.name : '')}
            getOptionSelected={(option) => option?.id === value?.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={caption}
                    size="small"
                    variant="outlined"
                    error={!!errors[name]}
                    helperText={errors[name] ? errors[name].message : null}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {tpvsConfigurationsQuery?.isLoading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            onChange={(e, data) => onChange(data)}
            onInputChange={(event, newInputValue) =>
                setQueryParams({
                    ...queryParams,
                    name: newInputValue,
                })
            }
            value={value || null}
            style={{ paddingTop: 16 }}
            {...rest}
        />
    );
}

function SttAutocompleteGroup({ name, caption, value, onChange, ...rest }) {
    return (
        <SttSelectGroup
            caption={caption}
            name={name}
            defVal={value}
            onChange={(prop) => {
                onChange(prop.value);
            }}
            {...rest}
        />
    );
}

function SttAutocompleteCountry({
    name,
    caption,
    defValue,
    value,
    onChange,
    ...rest
}) {
    return (
        <SttSelectCountry
            caption={caption}
            name={name}
            defVal={defValue}
            value={value}
            onChange={(prop) => {
                onChange(prop.value);
            }}
            {...rest}
        />
    );
}

function SttAutocompleteMap({ value, defValue, onChange }) {
    return (
        <SttGoogleMap
            latLng={{
                lat: value?.mapX || defValue?.mapX,
                lng: value?.mapY || defValue?.mapY,
            }}
            searchEnabled
            onChange={(prop) => {
                onChange({ mapX: prop.lat, mapY: prop.lng });
            }}
        />
    );
}

function SttAutocompleteScsMships({
    name,
    caption,
    value,
    errors,
    onChange,
    ...rest
}) {
    const { translate } = useTranslations();
    const { sc } = useContext(AppContext);
    const scsService = useScsService();
    const [queryParams, setQueryParams] = useState({
        rows: 100,
        name: '',
    });

    const scsMshipsQuery = useQuery([SELECT_MSHIPS, queryParams], () =>
        scsService.getScsMships(sc?.id, queryParams),
    );

    return (
        <Autocomplete
            options={
                scsMshipsQuery?.data?.rows
                    ? scsMshipsQuery?.data?.rows
                    : [
                          {
                              id: value?.id,
                              name: value?.name,
                          },
                      ]
            }
            loading={scsMshipsQuery?.isLoading}
            getOptionLabel={(option) => (option?.name ? option?.name : '')}
            getOptionSelected={(option) => option?.id === value?.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={caption}
                    size="small"
                    variant="outlined"
                    error={!!errors[name]}
                    helperText={errors[name] ? errors[name].message : null}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {scsMshipsQuery?.isLoading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            renderOption={(option) => (
                <ListItem button>
                    <ListItemAvatar>
                        <Avatar
                            alt={option.name}
                            src={option.img ? option.img.url : null}
                        />
                    </ListItemAvatar>

                    <ListItemText
                        primary={option.fullName}
                        secondary={(() => {
                            if (option.user && checkOffTag(option.user.login)) {
                                return (
                                    <i style={{ color: '#5b5b5b' }}>
                                        {translate('UserCancelled')}
                                    </i>
                                );
                            }

                            if (option.amount) {
                                return (
                                    <>
                                        <span>{translate('Purse')}:&nbsp;</span>
                                        <b>{option.amount}€</b>
                                    </>
                                );
                            }

                            return null;
                        })()}
                    />
                </ListItem>
            )}
            onChange={(e, data) => onChange(data)}
            onInputChange={(event, newInputValue) =>
                setQueryParams({
                    ...queryParams,
                    name: newInputValue,
                })
            }
            value={value || null}
            style={{ paddingTop: 16 }}
            {...rest}
        />
    );
}

function SttAutocompleteProvince({
    name,
    caption,
    value,
    errors,
    onChange,
    ...rest
}) {
    const provincesService = useProvincesService();
    const [queryParams, setQueryParams] = useState({
        idCountry: 724,
        rows: 100,
        name: '',
    });

    const provincesQuery = useQuery([SELECT_PROVINCES, queryParams], () =>
        provincesService.getList(queryParams),
    );

    return (
        <Autocomplete
            options={
                provincesQuery?.data?.rows
                    ? provincesQuery?.data?.rows
                    : [
                          {
                              id: value?.id,
                              name: value?.name,
                          },
                      ]
            }
            loading={provincesQuery?.isLoading}
            getOptionLabel={(option) => (option?.name ? option?.name : '')}
            getOptionSelected={(option) => option?.id === value?.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={caption}
                    size="small"
                    variant="outlined"
                    error={!!errors[name]}
                    helperText={errors[name] ? errors[name].message : null}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {provincesQuery?.isLoading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            onChange={(e, data) => onChange(data)}
            onInputChange={(event, newInputValue) =>
                setQueryParams({
                    ...queryParams,
                    name: newInputValue,
                })
            }
            value={value || null}
            style={{ paddingTop: 16 }}
            {...rest}
        />
    );
}

function SttValidatedForm({
    form,
    loadingData = false,
    fields = [],
    buttons = [],
    containerProps = {},
}) {
    const cxt = useContext(AppContext);
    const {
        control,
        formState: { errors },
    } = form;

    const renderTextInput = (field, fieldIndex) => {
        const { name, caption, rules, type, show, onEnterKey, ...rest } = field;

        return loadingData ? (
            <Skeleton variant="rect" height={textHeight} />
        ) : (
            <Controller
                key={fieldIndex}
                name={name}
                control={control}
                rules={{
                    ...rules,
                    ...(rules?.required === true
                        ? { required: cxt.t('mandatory') }
                        : undefined),
                }}
                render={({ field: { onChange, value } }) => (
                    <TextField
                        variant="outlined"
                        size="small"
                        margin="normal"
                        fullWidth
                        id={name}
                        label={caption}
                        name={name}
                        onChange={onChange}
                        error={!!errors[name]}
                        helperText={(() => {
                            if (errors[name]) {
                                return errors[name].message;
                            }

                            if (rules) {
                                return ' ';
                            }

                            return null;
                        })()}
                        value={value || ''}
                        onKeyPress={(ev) => {
                            if (ev.key === 'Enter') {
                                onEnterKey?.(value);
                            }
                        }}
                        {...rest}
                    />
                )}
            />
        );
    };

    const renderNumberInput = (field, fieldIndex) => {
        const { name, caption, rules, type, show, onEnterKey, ...rest } = field;

        return loadingData ? (
            <Skeleton variant="rect" height={textHeight} />
        ) : (
            <Controller
                key={fieldIndex}
                name={name}
                control={control}
                rules={{
                    pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        message: cxt.t('mustBeNumber'),
                    },
                    ...rules,
                    ...(rules?.required === true
                        ? { required: cxt.t('mandatory') }
                        : undefined),
                }}
                render={({ field: { onChange, value } }) => (
                    <TextField
                        type="number"
                        variant="outlined"
                        size="small"
                        margin="normal"
                        fullWidth
                        id={name}
                        label={caption}
                        name={name}
                        onChange={onChange}
                        error={!!errors[name]}
                        helperText={(() => {
                            if (errors[name]) {
                                return errors[name].message;
                            }

                            if (rules) {
                                return ' ';
                            }

                            return null;
                        })()}
                        value={value || ''}
                        onKeyPress={(ev) => {
                            if (ev.key === 'Enter') {
                                onEnterKey?.(value);
                            }
                        }}
                        {...rest}
                    />
                )}
            />
        );
    };

    const renderSelector = (field, fieldIndex) => {
        const { name, caption, rules, options, type, show, ...rest } = field;

        // He añadido un padding de 16px al componente Autocomplete para que quede centrado, no he doy con una solución
        // bonita.
        return loadingData ? (
            <Skeleton variant="rect" height={textHeight} />
        ) : (
            <Controller
                key={fieldIndex}
                name={name}
                control={control}
                rules={rules}
                render={({ field: { onChange, value } }) => (
                    <Autocomplete
                        options={options}
                        getOptionLabel={(option) =>
                            option ? option.caption : options[0]
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={caption}
                                size="small"
                                variant="outlined"
                                error={!!errors[name]}
                                helperText={
                                    errors[name] ? errors[name].message : null
                                }
                            />
                        )}
                        onChange={(e, data) => {
                            onChange(data?.value);
                        }}
                        value={
                            options && value !== undefined
                                ? options.find(
                                      (option) => option.value === value,
                                  )
                                : options[0]
                        }
                        style={{ paddingTop: 16 }}
                        {...rest}
                    />
                )}
            />
        );
    };

    const renderTextArea = (field, fieldIndex) => {
        const { name, caption, rules, rows = 1, type, show, ...rest } = field;

        return loadingData ? (
            <Skeleton variant="rect" height={rows * textAreaHeightPerRow} />
        ) : (
            <Controller
                key={fieldIndex}
                name={name}
                control={control}
                rules={rules}
                render={({ field: { onChange, value } }) => (
                    <TextField
                        fullWidth
                        multiline
                        variant="outlined"
                        margin="normal"
                        size="small"
                        id={name}
                        label={caption}
                        name={name}
                        minRows={rows || 3}
                        maxRows={rows || 3}
                        onChange={onChange}
                        error={!!errors[name]}
                        helperText={errors[name] ? errors[name].message : null}
                        value={value || ''}
                        {...rest}
                    />
                )}
            />
        );
    };

    const renderDate = (field, fieldIndex) => {
        const { name, caption, rules, type, show, ...rest } = field;

        return loadingData ? (
            <Skeleton variant="rect" height={textHeight} />
        ) : (
            <Controller
                key={fieldIndex}
                name={name}
                control={control}
                rules={rules}
                render={({ field: { onChange, value } }) => (
                    <KeyboardDatePicker
                        style={{ width: '100%' }}
                        size="small"
                        clearable
                        autoOk
                        inputVariant="outlined"
                        format="DD/MM/YYYY"
                        margin="normal"
                        id={name}
                        label={caption}
                        value={value ? moment(value) : null}
                        onChange={(date) =>
                            onChange(moment(date).format('YYYY-MM-DD'))
                        }
                        error={!!errors[name]}
                        helperText={errors[name] ? errors[name].message : null}
                        {...rest}
                    />
                )}
            />
        );
    };

    const renderCheckBox = (field, fieldIndex) => {
        const { name, caption, rules, type, show, ...rest } = field;

        return loadingData ? (
            <Skeleton variant="rect" height={textHeight} />
        ) : (
            <FormControl component="fieldset">
                <FormControlLabel
                    control={
                        <Controller
                            key={fieldIndex}
                            name={name}
                            control={control}
                            rules={rules}
                            render={(props) => (
                                <Checkbox
                                    checked={props.field.value}
                                    onChange={props.field.onChange}
                                    name={name}
                                    disabled={
                                        rest.disabled ? rest.disabled : false
                                    }
                                />
                            )}
                        />
                    }
                    label={caption}
                    {...rest}
                />
                <FormHelperText error={!!errors[name]}>
                    {errors[name] ? errors[name].message : null}
                </FormHelperText>
            </FormControl>
        );
    };

    const renderCity = (field, fieldIndex) => {
        const { name, caption, rules, type, show, idProvince, ...rest } = field;

        // He añadido un padding de 16px al componente Autocomplete para que quede centrado, no he doy con una solución
        // bonita.
        return loadingData ? (
            <Skeleton variant="rect" height={textHeight} />
        ) : (
            <Controller
                key={fieldIndex}
                name={name}
                control={control}
                rules={rules}
                render={({ field: { onChange, value } }) => (
                    <SttAutocompleteCity
                        name={name}
                        caption={caption}
                        value={value}
                        errors={errors}
                        onChange={onChange}
                        idProvince={idProvince}
                        rest={rest}
                    />
                )}
            />
        );
    };

    const renderPrice = (field, fieldIndex) => {
        const { name, caption, rules, type, show, ...rest } = field;

        return loadingData ? (
            <Skeleton variant="rect" height={textHeight} />
        ) : (
            <Controller
                key={fieldIndex}
                name={name}
                control={control}
                rules={rules}
                render={({ field: { onChange, value } }) => (
                    <TextField
                        variant="outlined"
                        margin="normal"
                        size="small"
                        fullWidth
                        id={name}
                        label={caption}
                        name={name}
                        onChange={onChange}
                        error={!!errors[name]}
                        helperText={errors[name] ? errors[name].message : null}
                        value={value || ''}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    €
                                </InputAdornment>
                            ),
                        }}
                        {...rest}
                    />
                )}
            />
        );
    };

    const renderCustomer = (field, fieldIndex) => {
        const { name, caption, rules, type, show, ...rest } = field;

        // He añadido un padding de 16px al componente Autocomplete para que quede centrado, no he doy con una solución
        // bonita.
        return loadingData ? (
            <Skeleton variant="rect" height={textHeight} />
        ) : (
            <Controller
                key={fieldIndex}
                name={name}
                control={control}
                rules={rules}
                render={({ field: { onChange, value } }) => (
                    <SttAutocompleteCustomer
                        name={name}
                        caption={caption}
                        value={value}
                        errors={errors}
                        onChange={onChange}
                        rest={rest}
                    />
                )}
            />
        );
    };

    const renderTpvConfiguration = (field, fieldIndex) => {
        const { name, caption, rules, type, show, ...rest } = field;

        // He añadido un padding de 16px al componente Autocomplete para que quede centrado, no he doy con una solución
        // bonita.
        return loadingData ? (
            <Skeleton variant="rect" height={textHeight} />
        ) : (
            <Controller
                key={fieldIndex}
                name={name}
                control={control}
                rules={rules}
                render={({ field: { onChange, value } }) => (
                    <SttAutocompleteTpvConfiguration
                        name={name}
                        caption={caption}
                        value={value}
                        errors={errors}
                        onChange={onChange}
                        rest={rest}
                    />
                )}
            />
        );
    };

    const renderLabel = (field, fieldIndex) => {
        const { caption, name, rules, formatedText = false } = field;

        return loadingData ? (
            <Skeleton variant="rect" height={textHeight} />
        ) : (
            <Controller
                key={fieldIndex}
                name={name}
                control={control}
                rules={rules}
                render={({ field: { value } }) => (
                    <div key={fieldIndex}>
                        <div>
                            <Typography variant="caption">{caption}</Typography>
                        </div>
                        {formatedText ? (
                            <Box style={{ overflow: 'auto' }}>
                                <pre>
                                    <b>{value}</b>
                                </pre>
                            </Box>
                        ) : (
                            <b>{value}</b>
                        )}
                    </div>
                )}
            />
        );
    };

    const renderTime = (field, fieldIndex) => {
        const { name, caption, rules, type, show, ...rest } = field;

        return loadingData ? (
            <Skeleton variant="rect" height={textHeight} />
        ) : (
            <Controller
                key={fieldIndex}
                name={name}
                control={control}
                rules={rules}
                render={({ field: { onChange, value } }) => (
                    <TimePicker
                        style={{ width: '100%' }}
                        size="small"
                        ampm={false}
                        clearable
                        autoOk
                        inputVariant="outlined"
                        margin="normal"
                        id={name}
                        label={caption}
                        value={
                            value
                                ? new Date(
                                      1970,
                                      1,
                                      1,
                                      parseInt(String(value).split(':')[0]),
                                      parseInt(String(value).split(':')[1]),
                                      0,
                                  )
                                : value
                        }
                        onChange={(date) =>
                            onChange(moment(date).format('H:mm'))
                        }
                        error={!!errors[name]}
                        helperText={errors[name] ? errors[name].message : null}
                        {...rest}
                    />
                )}
            />
        );
    };

    const renderMultipleSelectorCheck = (field, fieldIndex) => {
        const { name, caption, rules, options, type, show, ...rest } = field;

        return loadingData ? (
            <Skeleton variant="rect" height={textHeight} />
        ) : (
            <Controller
                key={fieldIndex}
                name={name}
                control={control}
                rules={rules}
                render={({ field: { onChange, value } }) => (
                    <FormControl
                        variant="outlined"
                        size="small"
                        margin="normal"
                        fullWidth
                    >
                        <InputLabel>{caption}</InputLabel>
                        <Select
                            multiple
                            variant="outlined"
                            label={caption}
                            value={options && value !== undefined ? value : []}
                            onChange={(data) => onChange(data?.target?.value)}
                            renderValue={(selected) =>
                                selected
                                    .map(
                                        (a) =>
                                            options.find((o) => o?.value === a)
                                                ?.caption,
                                    )
                                    .join(', ')
                            }
                            {...rest}
                        >
                            {options.map((option) => (
                                <MenuItem
                                    key={option?.value}
                                    value={option?.value}
                                >
                                    <Checkbox
                                        checked={
                                            value !== undefined &&
                                            value
                                                .map((a) => a)
                                                .indexOf(option?.value) > -1
                                        }
                                    />
                                    <ListItemText primary={option?.caption} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            />
        );
    };

    const renderMonths = (field, fieldIndex) => {
        const { name, caption, rules, tinyLabels = true, ...rest } = field;

        return loadingData ? (
            <Skeleton variant="rect" height={textHeight} />
        ) : (
            <Controller
                key={fieldIndex}
                name={name}
                control={control}
                rules={rules}
                render={({ field: { onChange, value } }) => (
                    <ButtonGroup>
                        {cxt.constants.months.map((month) => (
                            <Button
                                key={month.id}
                                variant={
                                    Array.isArray(value) &&
                                    value.map((a) => a).indexOf(month?.id) > -1
                                        ? 'contained'
                                        : 'outlined'
                                }
                                color={
                                    Array.isArray(value) &&
                                    value.map((a) => a).indexOf(month?.id) > -1
                                        ? 'primary'
                                        : 'default'
                                }
                                onClick={() => {
                                    const updatedMonths = [...value];
                                    const indexValue = value.findIndex(
                                        (m) => m === month?.id,
                                    );

                                    if (indexValue > -1) {
                                        updatedMonths.splice(indexValue, 1);
                                        onChange(updatedMonths);
                                    } else {
                                        updatedMonths.push(month?.id);
                                        onChange(updatedMonths);
                                    }
                                }}
                                {...rest}
                            >
                                {tinyLabels ? month.tiny : month.short}
                            </Button>
                        ))}
                    </ButtonGroup>
                )}
            />
        );
    };

    const renderWeekdays = (field, fieldIndex) => {
        const { name, caption, rules, tinyLabels = true, ...rest } = field;

        return loadingData ? (
            <Skeleton variant="rect" height={textHeight} />
        ) : (
            <Controller
                key={fieldIndex}
                name={name}
                control={control}
                rules={rules}
                render={({ field: { onChange, value } }) => (
                    <ButtonGroup>
                        {cxt.constants.weekdays.map((weekday) => (
                            <Button
                                key={weekday.id}
                                variant={
                                    Array.isArray(value) &&
                                    value.map((a) => a).indexOf(weekday?.id) >
                                        -1
                                        ? 'contained'
                                        : 'outlined'
                                }
                                color={
                                    Array.isArray(value) &&
                                    value.map((a) => a).indexOf(weekday?.id) >
                                        -1
                                        ? 'primary'
                                        : 'default'
                                }
                                onClick={() => {
                                    const updatedMonths = [...value];
                                    const indexValue = value.findIndex(
                                        (m) => m === weekday?.id,
                                    );

                                    if (indexValue > -1) {
                                        updatedMonths.splice(indexValue, 1);
                                        onChange(updatedMonths);
                                    } else {
                                        updatedMonths.push(weekday?.id);
                                        onChange(updatedMonths);
                                    }
                                }}
                                {...rest}
                            >
                                {tinyLabels ? weekday.tiny : weekday.short}
                            </Button>
                        ))}
                    </ButtonGroup>
                )}
            />
        );
    };

    const renderGroup = (field, fieldIndex) => {
        const { name, caption, rules, type, show, ...rest } = field;

        // He añadido un padding de 16px al componente Autocomplete para que quede centrado, no he doy con una solución
        // bonita.
        return loadingData ? (
            <Skeleton variant="rect" height={textHeight} />
        ) : (
            <Controller
                key={fieldIndex}
                name={name}
                control={control}
                rules={rules}
                render={({ field: { onChange, value } }) => (
                    <SttAutocompleteGroup
                        name={name}
                        caption={caption}
                        value={value}
                        errors={errors}
                        onChange={onChange}
                        rest={rest}
                    />
                )}
            />
        );
    };

    const renderCountry = (field, fieldIndex) => {
        const {
            name,
            caption,
            rules,
            type,
            show,
            value: defValue,
            ...rest
        } = field;
        // He añadido un padding de 16px al componente Autocomplete para que quede centrado, no he doy con una solución
        // bonita.
        return loadingData ? (
            <Skeleton variant="rect" height={textHeight} />
        ) : (
            <Controller
                key={fieldIndex}
                name={name}
                control={control}
                rules={rules}
                render={({ field: { onChange, value } }) => (
                    <SttAutocompleteCountry
                        name={name}
                        caption={caption}
                        value={value}
                        defValue={defValue}
                        errors={errors}
                        onChange={onChange}
                        rest={rest}
                    />
                )}
            />
        );
    };

    const renderMap = (field, fieldIndex) => {
        const { name, rules, value: defValue } = field;

        // He añadido un padding de 16px al componente Autocomplete para que quede centrado, no he doy con una solución
        // bonita.
        return loadingData ? (
            <Skeleton variant="rect" height={textHeight} />
        ) : (
            <Controller
                key={fieldIndex}
                name={name}
                control={control}
                rules={rules}
                render={({ field: { onChange, value } }) => (
                    <SttAutocompleteMap
                        value={value}
                        defValue={defValue}
                        onChange={onChange}
                    />
                )}
            />
        );
    };
    const renderMship = (field, fieldIndex) => {
        const { name, caption, rules, type, show, ...rest } = field;

        // He añadido un padding de 16px al componente Autocomplete para que quede centrado, no he doy con una solución
        // bonita.
        return loadingData ? (
            <Skeleton variant="rect" height={textHeight} />
        ) : (
            <Controller
                key={fieldIndex}
                name={name}
                control={control}
                rules={rules}
                render={({ field: { onChange, value } }) => (
                    <SttAutocompleteScsMships
                        name={name}
                        caption={caption}
                        value={value}
                        errors={errors}
                        onChange={onChange}
                        rest={rest}
                    />
                )}
            />
        );
    };

    const renderProvince = (field, fieldIndex) => {
        const { name, caption, rules, type, show, ...rest } = field;

        // He añadido un padding de 16px al componente Autocomplete para que quede centrado, no he doy con una solución
        // bonita.
        return loadingData ? (
            <Skeleton variant="rect" height={textHeight} />
        ) : (
            <Controller
                key={fieldIndex}
                name={name}
                control={control}
                rules={rules}
                render={({ field: { onChange, value } }) => (
                    <SttAutocompleteProvince
                        name={name}
                        caption={caption}
                        value={value}
                        errors={errors}
                        onChange={onChange}
                        rest={rest}
                    />
                )}
            />
        );
    };

    /**
     * Render form buttons.
     * @param button Button object.
     * @param buttonIndex Button index.
     * @returns {JSX.Element}
     */
    const renderButton = (button, buttonIndex) => {
        let content;
        const {
            caption,
            onClick,
            show = true,
            type,
            confirmation = false,
        } = button;

        switch (type) {
            case 'save':
                content = (
                    <SttButtonSave
                        fullWidth
                        caption={caption}
                        onClick={onClick}
                        confirmation={confirmation}
                    />
                );
                break;
            case 'delete':
                content = (
                    <SttButtonDelete
                        fullWidth
                        caption={caption}
                        onClick={onClick}
                        confirmation
                    />
                );
                break;
            case 'accept':
                content = (
                    <SttButtonAccept
                        fullWidth
                        caption={caption}
                        onClick={onClick}
                        confirmation={confirmation}
                    />
                );
                break;
            case 'recover':
                content = (
                    <SttButtonRecover
                        fullWidth
                        caption={caption}
                        onClick={onClick}
                        confirmation={confirmation}
                    />
                );
                break;
            case 'component':
                content = button.component;
                break;
            default:
                content = (
                    <SttButton
                        fullWidth
                        caption={caption}
                        onClick={onClick}
                        confirmation={confirmation}
                    />
                );
        }

        if (show) {
            return (
                <Grid key={buttonIndex} item md={3} sm={12} xs={12}>
                    {content}
                </Grid>
            );
        }
    };

    const renderFormField = (field, fieldIndex) => {
        switch (field?.type ? field?.type.toLowerCase() : 'textinput') {
            case 'textinput':
                return renderTextInput(field, fieldIndex);
            case 'numberinput':
                return renderNumberInput(field, fieldIndex);
            case 'select':
                return renderSelector(field, fieldIndex);
            case 'textarea':
                return renderTextArea(field, fieldIndex);
            case 'date':
                return renderDate(field, fieldIndex);
            case 'check':
                return renderCheckBox(field, fieldIndex);
            case 'city':
                return renderCity(field, fieldIndex);
            case 'price':
                return renderPrice(field, fieldIndex);
            case 'customer':
                return renderCustomer(field, fieldIndex);
            case 'tpvconf':
                return renderTpvConfiguration(field, fieldIndex);
            case 'label':
                return renderLabel(field, fieldIndex);
            case 'time':
                return renderTime(field, fieldIndex);
            case 'multipleselectcheck':
                return renderMultipleSelectorCheck(field, fieldIndex);
            case 'months':
                return renderMonths(field, fieldIndex);
            case 'weekdays':
                return renderWeekdays(field, fieldIndex);
            case 'group':
                return renderGroup(field, fieldIndex);
            case 'mship':
                return renderMship(field, fieldIndex);
            case 'province':
                return renderProvince(field, fieldIndex);
            case 'country':
                return renderCountry(field, fieldIndex);
            case 'map':
                return renderMap(field, fieldIndex);
            default:
                return null;
        }
    };

    const renderFormFieldGridItem = (field, fieldIndex) => {
        const { show = true, xs = 12, sm, md, lg, xl } = field;

        if (show) {
            return (
                <Grid
                    item
                    key={fieldIndex}
                    xs={xs}
                    sm={sm}
                    md={md}
                    lg={lg}
                    xl={xl}
                >
                    {renderFormField(field, fieldIndex)}
                </Grid>
            );
        }
        return null;
    };

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <Grid container spacing={2} {...containerProps}>
                {fields.map((field, fieldIndex) =>
                    renderFormFieldGridItem(field, fieldIndex),
                )}
            </Grid>

            {buttons && (
                <Box mt={4}>
                    <Grid container spacing={3}>
                        {buttons.map(renderButton)}
                    </Grid>
                </Box>
            )}
        </form>
    );
}

export default SttValidatedForm;
