import React, { useState, useEffect, useContext } from 'react';
import { Paper, Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { AppContext, SttTable, SttTopControls } from '../../../sporttia/all';
import { updateElementInArray } from '../../../lib/utils';
import { useToggle } from '../../../lib/hooks';
import FileCSVLineDialog from './FileCSVLineDialog';
import ImportUsersDialog from './ImportUsersDialog';

export default function FileCSV(props) {
    const cxt = useContext(AppContext);
    const [lines, setLines] = useState();
    const [filters, setFilters] = useState({
        searchingString: '',
        status: null,
    });
    const [selectedLine, setSelectedLine] = useState(null);
    const [file, setFile] = useState();
    const [openImportUsers, setOpenImportUsers] = useState(false);

    // Trash hook
    const [trash, toggleTrash] = useToggle(() => {
        // eslint-disable-next-line no-use-before-define
        loadLines();
    });

    const loadLines = (p = { page: 1, rows: 40 }) => {
        cxt.api('GET', `/files/csvs/${props.match.params.id}/lines`, {
            params: {
                ...filters,

                trash,
                page: p.page,
                rows: p.rows,
            },
            success: (r) => {
                setLines(r);
            },
        });
    };

    const loadFile = () => {
        cxt.api('GET', `/files/${props.match.params.id}`, {
            success: (r) => {
                setFile(r.file);
                cxt.setHeader({
                    title: r.file.name,
                });
                loadLines();
            },
        });
    };

    // File id changes
    useEffect(() => {
        if (props.match.params.id) loadFile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.id]);

    // Get field columns
    const getFieldColumns = () =>
        [...Array(cxt.constants.numFieldsToUserImport).keys()].map((num) => ({
            title: `#${num}`,
            field: `field_${num}`,
        }));

    // Get icon status
    const getIconStatus = (line) => {
        if (line.isImported) {
            return <CheckCircleOutlineIcon style={{ color: '#18b432' }} />;
        }
        if (line.error) {
            return <HighlightOffIcon style={{ color: '#df3525' }} />;
        }
    };

    // -----| Render |-----
    return (
        <Paper>
            <SttTopControls
                p={2}
                fields={[
                    {
                        caption: cxt.t('Name'),
                        name: 'searchingString',
                        type: 'text',
                        value: filters.searchingString,
                        onEnterKey: loadLines,
                    },
                ]}
                extraFields={[
                    {
                        md: 5,
                        sm: 12,
                        xs: 12,
                        caption: cxt.t('Status'),
                        name: 'status',
                        value: filters.status,
                        type: 'select',
                        cleanable: true,
                        options: [
                            {
                                value: 'ERROR',
                                caption: cxt.t('OnlyErrors'),
                            },
                            {
                                value: 'NOT_EXECUTED',
                                caption: cxt.t('OnlyNotExecuted'),
                            },
                        ],
                    },
                ]}
                mainAction={{
                    type: 'create',
                    caption: cxt.t('ImportUsers'),
                    onClick: () => setOpenImportUsers(true),
                }}
                onChange={({ name, value }) =>
                    setFilters({ ...filters, [name]: value })
                }
                onFilter={loadLines}
                onToggleTrash={toggleTrash}
            />

            {file && (file.numErrors > 0 || file.numImported > 0) && (
                <Box p={2}>
                    <Alert severity="info" variant="filled">
                        {file.numImported > 0 && (
                            <Box>
                                {cxt.t('Imported')}: {file.numImported}
                            </Box>
                        )}

                        {file.numErrors > 0 && (
                            <Box>
                                {cxt.t('Errors')}: {file.numErrors}
                            </Box>
                        )}
                    </Alert>
                </Box>
            )}

            <SttTable
                data={lines}
                autoload={false}
                columns={[
                    {
                        title: cxt.t('Status'),
                        // value: row => row.isImported ? <CheckIcon /> : (row.error ? <CloseIcon/> : null)
                        value: (row) => getIconStatus(row),
                    },
                    ...getFieldColumns(),
                ]}
                onFetch={loadLines}
                onClickRow={(row) => setSelectedLine(row)}
            />

            <FileCSVLineDialog
                line={selectedLine}
                onClose={() => setSelectedLine(null)}
                onUpdate={(newLine) => {
                    setLines({
                        ...lines,
                        rows: updateElementInArray(lines.rows, newLine),
                    });
                    setSelectedLine(null);
                }}
            />

            <ImportUsersDialog
                open={openImportUsers}
                file={file}
                lines={lines}
                onClose={() => setOpenImportUsers(false)}
                onUpdate={(newFileCSV) => {
                    setFile(newFileCSV);
                    loadLines();
                }}
            />
        </Paper>
    );
}
