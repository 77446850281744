import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useTranslations } from '../../lib/hooks';
import SttError from './SttError';
import { SttButton } from '../../sporttia/all';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        height: '100vh',
    },
}));

export default function SttFullPageError() {
    const { translate } = useTranslations();
    const classes = useStyles();

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={classes.root}
        >
            <Box pb={25} px={2}>
                <SttError />
                <Box display="flex" justifyContent="center" pt={2}>
                    <SttButton
                        caption={translate('refresh')}
                        onClick={() => {
                            window.location.assign(window.location.href);
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
}
