import React from 'react';
import { Box, Divider, makeStyles, Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import GolfTimetableTee from './GolfTimetableTee';
import GolfTimetableCart from './GolfTimetableCart';
import GolfTimetableControls from './GolfTimetableControls';

/**
 * Draw golf timetable.
 * @returns {JSX.Element}
 */
export default function GolfTimetable({}) {
    const classes = useStyles();
    const form = useForm();

    {
        /* Render golf timetable with tee columns and golf cart availability column. */
    }
    return (
        <Paper>
            <GolfTimetableControls form={form} />
            <Box mb={2}>
                <Divider />
            </Box>
            <Box p={2}>
                <div className={classes.timetable}>
                    <Box className={classes.timetableBox}>
                        <Box display="flex">
                            <GolfTimetableTee
                                teeNumber={1}
                                disableTime={false}
                            />
                            <GolfTimetableTee teeNumber={5} />
                            <GolfTimetableTee teeNumber={10} />
                            <GolfTimetableCart />
                        </Box>
                    </Box>
                </div>
            </Box>
        </Paper>
    );
}

const useStyles = makeStyles(() => ({
    timetable: {
        height: '57vh',
        margin: 0,
        padding: 0,
    },
    timetableBox: {
        maxHeight: '100%',
        overflowX: 'auto',
        overflowY: 'auto',
    },
}));
