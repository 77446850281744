import React, { useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Box } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { SttTopControls } from '../SttTopControls';
import { AppContext } from '../AppContext';
import { SttPagination } from '../SttPagination';
import { SttDialog } from './SttDialog';
import { SttActivityFillForms } from './SttActivityFillForms';
import { SttList } from '../lists/SttList';
import { SPORTCENTER_ACTIVITIES_STUDENTS } from '../../lib/queryKeys';

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(6),
    },
}));

/**
 * Rate selector ordered by activities.
 * @param open boolean Open or close the dialog.
 * @param studentId integer Student id.
 * @param onClose function Method threw when user close the dialog.
 * @returns {JSX.Element} JSX with component.
 */
export function SttSelectClassAndRateDialog({
    open = false,
    studentId,
    onClose,
}) {
    const cxt = useContext(AppContext);
    const classes = useStyles();
    const [activities, setActivities] = useState({});
    const [filter, setFilter] = useState({
        name: '',
        year: new Date().getFullYear(),
    });
    const [openListItem, setOpenListItem] = React.useState([]);
    const [page, setPage] = useState(1);
    const rowsPerPage = 7;
    const [activityForms, setActivityForms] = useState([]);
    const [openActivityFillFormsDialog, setOpenActivityFillFormsDialog] =
        useState(false);
    const [selectedRateId, setSelectedRateId] = useState();
    const queryClient = useQueryClient();

    /**
     * Will be load activity forms data and next open fill forms dialog or call changeStudentRate.
     * @param activityId Activity ID.
     * @param rateId Rate ID.
     */
    const changeStudentRateProccess = (activityId, rateId) => {
        cxt.api('GET', `/activities/${activityId}`, {
            params: {
                trash: true,
            },
            success: (response) => {
                if (response && response.activity && response.activity.form) {
                    setActivityForms(response.activity.form);
                    setOpenActivityFillFormsDialog(true);
                    setSelectedRateId(rateId);
                } else {
                    changeStudentRate(rateId);
                }
            },
        });
    };

    /**
     * Load all activities using sport center id.
     * @param p Search parameters ({page: X, rows: X})
     */
    const loadActivities = (p = { page: 1, rows: 7 }) => {
        cxt.api('GET', `/scs/${cxt.sc.id}/activities`, {
            params: {
                ...filter,
                ...p,
            },
            success: (response) => {
                setActivities(response);
                setOpenListItem(new Array(response.rows.length).fill(false));
            },
        });
    };

    /**
     * Load all fees using activity id.
     * @param activityId Activity id.
     */
    const loadFeesByActivity = (activityId) => {
        cxt.api('GET', `/activities/${activityId}`, {
            success: (response) => {
                const newActivities = { ...activities };
                const activityIndex = newActivities.rows.findIndex(
                    (x) => x.id === activityId,
                );
                newActivities.rows[activityIndex] = response.activity;
                handleClick(activityIndex);
                setActivities(newActivities);
            },
        });
    };

    /**
     * Change student rate.
     * @param idRate integer Rate ID.
     * @param p Params.
     */
    const changeStudentRate = (idRate, p = {}) => {
        cxt.api('PUT', `/students/${studentId}/changeRate`, {
            params: {
                idRate,
                ...p,
            },
            success: (r) => {
                if (onClose) onClose();
                queryClient.invalidateQueries(SPORTCENTER_ACTIVITIES_STUDENTS);
                setOpenActivityFillFormsDialog(false);
                cxt.showMessage('S', cxt.t('Changed'));
            },
        });
    };

    /**
     * Will be execute when user press cell list item.
     * Save the status of the list to check if the activity was loaded or not.
     * @param index integer Activity index.
     */
    const handleClick = (index) => {
        const newOpenListItem = [...openListItem];
        newOpenListItem[index] = !newOpenListItem[index];
        setOpenListItem(newOpenListItem);
    };

    /**
     * Render activity cell inside SttList.
     * @param activity Activity object.
     * @param index Activity index.
     * @returns {JSX.Element} JSX with activity cell.
     */
    const renderCell = (activity, index) => (
        <>
            <ListItem
                button
                onClick={() => {
                    activity.hasOwnProperty('rates')
                        ? handleClick(index)
                        : loadFeesByActivity(activity.id);
                }}
            >
                <ListItemText primary={activity.name} />
                {openListItem[index] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openListItem[index]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {activity.rates &&
                        activity.rates.map((rate) => (
                            <ListItem
                                button
                                className={classes.nested}
                                onClick={() =>
                                    changeStudentRateProccess(
                                        activity.id,
                                        rate.id,
                                    )
                                }
                            >
                                <ListItemText primary={rate.name} />
                            </ListItem>
                        ))}
                </List>
            </Collapse>
        </>
    );

    /**
     * Will be execute when change page.
     * @param page integer Page number.
     */
    const onChangePage = (page) => {
        setPage(page);
        loadActivities({ page, rows: rowsPerPage });
    };

    /**
     * When the component is opened we load activities.
     */
    useEffect(() => {
        if (open) loadActivities();
    }, [open]);

    /**
     * When "filter.year" change we load activities.
     */
    useEffect(() => {
        if (open) loadActivities();
    }, [filter.year]);

    // -----| Render |-----
    return (
        <>
            <SttDialog
                title={cxt.t('ChangeClassAndRate')}
                open={open}
                onClose={() => (onClose ? onClose() : null)}
                maxWidth="md"
                content={
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={9}>
                                <SttTopControls
                                    pl={0}
                                    fields={[
                                        {
                                            caption: cxt.t('Search'),
                                            name: 'name',
                                            type: 'text',
                                            value: filter.name,
                                            onEnterKey: () => loadActivities(),
                                        },
                                        {
                                            caption: cxt.t('Year'),
                                            type: 'year',
                                            name: 'year',
                                            years: {
                                                future: 1,
                                            },
                                            value: filter.year
                                                ? filter.year
                                                : '',
                                            onChange: ({ name, value }) =>
                                                setFilter({
                                                    ...filter,
                                                    [name]: value || '',
                                                }),
                                        },
                                    ]}
                                    onChange={({ name, value }) =>
                                        setFilter({ ...filter, [name]: value })
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <SttPagination
                                    page={page}
                                    pages={Math.ceil(
                                        activities.count / rowsPerPage,
                                    )}
                                    count={activities.count}
                                    onChangePage={(page) => onChangePage(page)}
                                />
                            </Grid>
                        </Grid>

                        <Box mt={2}>
                            <Paper>
                                <SttList
                                    data={
                                        activities.rows &&
                                        activities.rows.map(
                                            (activity, index) => ({
                                                caption: renderCell(
                                                    activity,
                                                    index,
                                                ),
                                            }),
                                        )
                                    }
                                />
                            </Paper>
                        </Box>
                    </>
                }
            />
            <SttActivityFillForms
                open={openActivityFillFormsDialog}
                onClose={() => setOpenActivityFillFormsDialog(false)}
                onSave={changeStudentRate}
                rateId={selectedRateId}
                activityForms={activityForms}
            />
        </>
    );
}
