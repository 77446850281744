import React, { useState, useEffect, useContext } from 'react';
import { Grid, Box, InputAdornment, Link } from '@material-ui/core';
import {
    AppContext,
    SttDialog,
    SttFormCheck,
    SttFormSelect,
    SttFormText,
    SttFormTextarea,
    SttSelectPForm,
    SttSelectUser,
    SttTabs,
} from '../../../sporttia/all';
import { useCrud } from '../../../lib/hooks';
import { fullName, generateFormField } from '../../../lib/utils';
import SttMshipDialog from '../../../sporttia/dialogs/SttMshipDialog';
import translations from '../../../translations';

export default function ModalParticipant({
    item,
    event,
    category,
    teams,
    onClose,
    onSave,
    onDelete,
    disableFieldsForParticipantWithUser = false,
}) {
    const [participant, setParticipant, setProperty] = useCrud(item);
    const [participantCreator, setParticipantCreator] = useState({});
    const [openCreator, setOpenCreator] = useState(false);

    const cxt = useContext(AppContext);

    function handleFormFieldChange({ name, value }) {
        const { form } = participant;
        const question = form.find((q) => q.id === Number(name));

        form.splice(form.indexOf(question), 1, {
            ...question,
            answer: { value },
        });
        setParticipant({ ...participant, form });
    }

    const getCreatorData = () => {
        cxt.api('GET', `/users/${participant.creator.id}`, {
            params: {},
            success: (result) => {
                if (result.user) {
                    cxt.api('GET', `/mships/${result.user.mship.id}`, {
                        params: {},
                        success: (response) => {
                            if (response.mship) {
                                setParticipantCreator(response.mship);
                            }
                        },
                    });
                }
            },
        });
    };

    const participantData = (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {participant && participant.user ? (
                    <SttSelectUser
                        user={participant.user}
                        caption={cxt.t('User')}
                        onSelect={(mship) => {
                            if (mship)
                                setProperty({
                                    name: 'user',
                                    value: {
                                        fullName: mship.fullName,
                                        id: mship.user.id,
                                    },
                                });
                            else
                                setProperty({
                                    name: 'user',
                                    value: {
                                        fullName: ' ',
                                    },
                                });
                        }}
                        returnMship
                    />
                ) : (
                    <SttFormText
                        caption={cxt.t('User')}
                        name="user"
                        defVal={participant.displayName}
                        disabled
                    />
                )}
                {participant && participant.id && participantCreator && (
                    <Box pt={1}>
                        <b>{cxt.t('SignedBy')}: </b>
                        <Link onClick={() => setOpenCreator(true)}>
                            {fullName(participantCreator)}
                        </Link>
                    </Box>
                )}
            </Grid>
            <Grid item xs={12} sm={8}>
                <SttFormText
                    caption={cxt.t('Email')}
                    name="email"
                    defVal={participant.email}
                    onChange={setProperty}
                    disabled={
                        !!participant?.user &&
                        disableFieldsForParticipantWithUser
                    }
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SttFormText
                    caption={cxt.t('Mobile')}
                    name="mobile"
                    defVal={participant.mobile}
                    onChange={setProperty}
                    disabled={
                        !!participant?.user &&
                        disableFieldsForParticipantWithUser
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <SttFormTextarea
                    caption={cxt.t('Notes')}
                    name="notes"
                    defVal={participant.notes}
                    onChange={setProperty}
                />
            </Grid>
            {event.teamable && teams ? (
                <>
                    <Grid item xs={12} sm={6}>
                        <SttFormSelect
                            caption={cxt.t('Team')}
                            name="team"
                            defVal={
                                participant.team ? participant.team.id : '0'
                            }
                            options={[
                                {
                                    caption: cxt.t('CreateNewTeam'),
                                    value: '0',
                                },
                            ].concat(
                                teams.map((team) => ({
                                    caption: team.name,
                                    value: team.id,
                                })),
                            )}
                            onChange={({ value }) =>
                                setProperty({
                                    name: 'team',
                                    value: {
                                        id: value,
                                        name:
                                            value !== '0'
                                                ? teams.find(
                                                      (team) =>
                                                          team.id === value,
                                                  ).name
                                                : cxt.t('CreateNewTeam'),
                                    },
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {(!participant.team || participant.team.id === '0') && (
                            <SttFormText
                                caption={cxt.t('NewTeam')}
                                defVal={participant.team.name}
                                onChange={({ value }) => {
                                    setProperty({
                                        name: 'team',
                                        value: { id: '0', name: value },
                                    });
                                }}
                            />
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                        <SttFormCheck
                            caption={cxt.t('Administrator')}
                            checked={participant.admin}
                            name="admin"
                            onChange={setProperty}
                        />
                    </Grid>
                </>
            ) : null}

            {category.enrollment &&
            (!event.teamable ||
                (event.teamable &&
                    participant.team &&
                    parseInt(participant.team.id) === 0)) ? (
                <>
                    <Grid item xs={6} sm={3}>
                        <SttFormText
                            caption={cxt.t(translations.generic.price)}
                            disabled
                            defVal={category.enrollment}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {category.enrollmentShape?.currency}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={9}>
                        <SttSelectPForm
                            disabled={!!participant.id}
                            name="paymentForm"
                            defVal={
                                participant.paymentForm ||
                                (participant.enrollment
                                    ? participant.enrollment.paymentForm
                                    : '')
                            }
                            activePFs={[
                                '',
                                'PURSE',
                                'CASH',
                                'BANK',
                                'DATAFONO',
                                'RECEIPT',
                                'FREE',
                            ]}
                            onChange={setProperty}
                        />
                    </Grid>
                </>
            ) : null}
        </Grid>
    );

    const form = (
        <Grid container spacing={3}>
            {participant.form?.map((f) => (
                <Grid item key={f.id} xs={12}>
                    {generateFormField(
                        {
                            ...event.form.find((itm) => itm.id === f.id),
                            answer: f.answer,
                        },
                        handleFormFieldChange,
                    )}
                </Grid>
            ))}
        </Grid>
    );

    useEffect(() => {
        if (participant.creator) {
            getCreatorData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SttDialog
                open
                title={cxt.t(translations.generic.participant)}
                subtitle={`${event.name} - ${category.name}`}
                onClose={onClose}
                maxWidth="sm"
                content={
                    event.form ? (
                        <SttTabs
                            tabs={[
                                {
                                    caption: cxt.t(
                                        translations.generic.participant,
                                    ),
                                    component: participantData,
                                },
                                {
                                    caption: cxt.t(translations.generic.form),
                                    show: event.form.length > 0,
                                    component: form,
                                },
                            ]}
                        />
                    ) : (
                        participantData
                    )
                }
                buttons={[
                    {
                        type: 'save',
                        onClick: () => onSave(participant),
                    },
                    participant.id &&
                        !participant.trash && {
                            type: 'delete',
                            onClick: () => onDelete(participant),
                        },
                    {
                        type: 'cancel',
                        onClick: onClose,
                    },
                ]}
            />

            <SttMshipDialog
                open={openCreator}
                mshipId={participantCreator.id}
                onClose={() => {
                    setOpenCreator(false);
                }}
            />
        </>
    );
}
