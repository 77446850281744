import React, { useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import {
    SttDialog,
    SttList,
    SttFormSearch,
    SttPagination,
    SttNoResults,
} from '../all';
import SttError from '../../components/error/SttError';
import useCountriesService from '../../services/CountriesService';
import Country from '../../types/models/Country';

type SttSelectCountryDialogProps = {
    open?: boolean;
    onClose: () => void;
    onSelect: (country: Country) => void;
};

/**
 * Select group dialog
 */
export function SttSelectCountryDialog({
    open = false,
    onClose,
    onSelect,
}: SttSelectCountryDialogProps) {
    const [searchForm, setSearchForm] = useState('');

    const [getCountryPage, setGetCountryPage] = useState(1);
    const [getCountriesFilters, setGetCountriesFilters] = useState({
        name: null,
    });

    const countryService = useCountriesService();
    const getCountriesQuery = countryService.useGetCountries({
        page: getCountryPage,
        rows: 7,
        ...getCountriesFilters,
    });

    // Handler to ensure page is reset to the first page when the filter is changed
    const handleOnFilter = ({
        name,
        value,
    }: {
        name: string;
        value: string;
    }) => {
        setGetCountryPage(1);
        setGetCountriesFilters((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    if (!open) {
        return null;
    }

    if (getCountriesQuery.isError) {
        return (
            <SttDialog
                open
                // @ts-expect-error Migrate SttDialog
                minHeight="60%"
                // @ts-expect-error Migrate SttDialog
                maxHeight="60%"
                onClose={onClose}
                maxWidth="md"
                content={
                    <Box paddingY={12}>
                        <SttError />
                    </Box>
                }
            />
        );
    }

    return (
        <SttDialog
            open
            // @ts-expect-error Migrate SttDialog
            minHeight="60%"
            // @ts-expect-error Migrate SttDialog
            maxHeight="60%"
            onClose={onClose}
            maxWidth="md"
            content={
                <>
                    <SttFormSearch
                        defVal={searchForm}
                        onClean={() => {
                            setSearchForm('');
                            handleOnFilter({ name: 'name', value: '' });
                        }}
                        onChange={({ value }) => {
                            setSearchForm(value);
                        }}
                        onClickSearch={() => {
                            handleOnFilter({
                                name: 'name',
                                value: searchForm,
                            });
                        }}
                    />

                    {getCountriesQuery.isLoading && (
                        <Box
                            display="flex"
                            justifyContent="center"
                            paddingY={25}
                        >
                            <CircularProgress />
                        </Box>
                    )}

                    {getCountriesQuery.isSuccess &&
                        getCountriesQuery.data.count > 0 && (
                            // @ts-expect-error SttList
                            <SttList
                                onSelect={(country: Country) =>
                                    onSelect(country)
                                }
                                data={getCountriesQuery.data.rows.map(
                                    (country) => ({
                                        caption: country.name,
                                        objToSelect: country,
                                    }),
                                )}
                            />
                        )}

                    {getCountriesQuery.data?.count === 0 && (
                        <Box paddingY={12}>
                            <SttNoResults />
                        </Box>
                    )}
                </>
            }
            buttons={
                getCountriesQuery.isSuccess &&
                getCountriesQuery.data.count > 0 && [
                    {
                        type: 'component',
                        show: true,
                        component: (
                            <Box m="auto">
                                <SttPagination
                                    page={getCountryPage}
                                    pages={getCountriesQuery.data.pages}
                                    count={getCountriesQuery.data.count}
                                    onChangePage={(page) => {
                                        setGetCountryPage(page);
                                    }}
                                />
                            </Box>
                        ),
                    },
                ]
            }
        />
    );
}
