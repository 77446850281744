import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Box, Paper } from '@material-ui/core';
import { AppContext, SttFullDialog } from '../../../../sporttia/all';
import useTestsService from '../../../../services/TestsService';
import { useLoader } from '../../../../lib/hooks';
import SttValidatedForm from '../../../../sporttia/SttValidatedForm';
import { SUPERADMIN_TEST } from '../../../../lib/queryKeys';

/**
 * Test view. Contains form to update, delete or create tests.
 * @param id Test ID.
 * @param onClose Function executed when closing the full dialog.
 * @param onCreate Launched when a new test is created.
 * @param onUpdate Launched when a new test is updated.
 * @param onDelete Launched when a new test is removed.
 * @returns {JSX.Element}
 */
export default function AdminTest({
    id,
    onClose,
    onCreate,
    onUpdate,
    onDelete,
}) {
    const cxt = useContext(AppContext);
    const testsService = useTestsService();
    const queryClient = useQueryClient();
    const form = useForm();
    const [testId, setTestId] = useState(id);
    const testQuery = useQuery(
        [SUPERADMIN_TEST, { id: testId }],
        () => testsService.get(testId),
        { enabled: !!testId && testId !== 'create' },
    );
    const deleteMutation = useMutation(
        (params) => testsService.remove(params?.id),
        {
            onSuccess: onDelete,
        },
    );
    const updateMutation = useMutation(
        (params) => testsService.update(params?.id, params?.params),
        {
            onSuccess: (data) => {
                queryClient.setQueryData(
                    [SUPERADMIN_TEST, { id: data?.test?.id }],
                    data,
                );
                onUpdate();
            },
        },
    );
    const createMutation = useMutation(
        (params) => testsService.create(params?.params),
        {
            onSuccess: (data) => onCreate(data?.test?.id),
        },
    );
    const [, loader] = useLoader([
        deleteMutation.status,
        updateMutation.status,
        createMutation.status,
    ]);

    /**
     * Method executed when the user clicks on save or create test.
     * @param formData Form data.
     */
    const mutateTest = (formData) => {
        if (testQuery?.data?.test?.id) {
            updateMutation.mutate({
                id: testQuery?.data?.test?.id,
                params: formData,
            });
        } else {
            createMutation.mutate({ params: formData });
        }
    };

    /**
     * When the "test" data is returned by the API, it is set in the form.
     */
    useEffect(() => {
        if (testQuery?.status === 'success' && testQuery?.data?.test) {
            form.reset({
                type: testQuery?.data?.test?.type,
                hart: testQuery?.data?.test?.hart,
                function: testQuery?.data?.test?.function,
                status: testQuery?.data?.test?.status,
                idAPI: '',
                description: testQuery?.data?.test?.description,
            });
        } else {
            form.reset({
                type: 'UNIT',
                hart: '',
                function: '',
                status: 'PASSED',
                idAPI: '',
                description: '',
            });
        }
    }, [testQuery?.data?.test]);

    /**
     * Update test id if id prop was changed and reset form values.
     */
    useEffect(() => {
        setTestId(id);
        form.reset();
    }, [id]);

    return (
        <SttFullDialog
            open={testId !== null}
            onClose={onClose}
            title={
                testId?.isLoading
                    ? ''
                    : testId?.data?.test?.id
                    ? testId?.data?.test?.id
                    : cxt.t('admin.test.create')
            }
        >
            <Paper>
                <Box p={3}>
                    {loader}
                    <SttValidatedForm
                        form={form}
                        loadingData={testQuery?.status === 'loading'}
                        fields={[
                            {
                                type: 'select',
                                name: 'type',
                                caption: cxt.t('Type'),
                                options: [
                                    { caption: cxt.t('Hart'), value: 'UNIT' },
                                    { caption: cxt.t('APIs'), value: 'REST' },
                                    {
                                        caption: cxt.t('Manual'),
                                        value: 'MANUAL',
                                    },
                                ],
                                disableClearable: true,
                            },
                            {
                                type: 'textinput',
                                name: 'hart',
                                caption: cxt.t('Hart'),
                            },
                            {
                                type: 'textinput',
                                name: 'function',
                                caption: cxt.t('Function'),
                            },
                            {
                                type: 'select',
                                name: 'status',
                                caption: cxt.t('Status'),
                                options: [
                                    {
                                        caption: cxt.t('Passed'),
                                        value: 'PASSED',
                                    },
                                    { caption: cxt.t('Error'), value: 'ERROR' },
                                    {
                                        caption: cxt.t('Skipped'),
                                        value: 'SKIP',
                                    },
                                    {
                                        caption: cxt.t('No passed'),
                                        value: 'NO_PASSED',
                                    },
                                ],
                                disableClearable: true,
                            },
                            {
                                type: 'textinput',
                                name: 'idAPI',
                                caption: cxt.t('APIId'),
                                show: testId === 'create',
                            },
                            {
                                type: 'textArea',
                                name: 'description',
                                caption: cxt.t('Description'),
                                rows: 5,
                            },
                        ]}
                        buttons={[
                            {
                                type: 'save',
                                onClick: form.handleSubmit((formData) =>
                                    mutateTest(formData),
                                ),
                            },
                            {
                                type: 'delete',
                                onClick: () =>
                                    deleteMutation.mutate({
                                        id: testQuery?.data?.test?.id,
                                    }),
                            },
                        ]}
                    />
                </Box>
            </Paper>
        </SttFullDialog>
    );
}
