import React, { useState, useEffect, useContext } from 'react';
import {} from '@material-ui/core';
import { AppContext, SttPrivilegeList } from '../../../sporttia/all';
import { updateElementInArray } from '../../../lib/utils';

export default function ConfigurationPrivileges({}) {
    const cxt = useContext(AppContext);
    const [privileges, setPrivileges] = useState();

    // Only show this privileges
    const privilegesToShow = [6, 9, 13, 19, 23, 24, 25, 26, 27];

    /**
     * Load privileges
     */
    const loadPrivileges = () => {
        cxt.api('GET', `/scs/${cxt.sc.id}/privileges`, {
            success: (r) => {
                setPrivileges(r);
            },
        });
    };

    /**
     * Toggle privilege
     */
    const togglePrivilege = ({ id, value }) => {
        const idPrivilege = id;
        const privilege = privileges.rows.find(
            (privilege) => privilege.id == idPrivilege,
        );
        if (privilege) {
            privilege.active = value;

            if (privilege.active) {
                cxt.api('POST', `/scs/${cxt.sc.id}/privileges/${idPrivilege}`, {
                    success: (r) => {
                        setPrivileges({
                            rows: updateElementInArray(
                                privileges.rows,
                                privilege,
                            ),
                        });
                    },
                });
            } else {
                cxt.api(
                    'DELETE',
                    `/scs/${cxt.sc.id}/privileges/${idPrivilege}`,
                    {
                        success: (r) => {
                            setPrivileges({
                                rows: updateElementInArray(
                                    privileges.rows,
                                    privilege,
                                ),
                            });
                        },
                    },
                );
            }
        }
    };

    /**
     * SC changes
     */
    useEffect(() => {
        if (cxt.sc.id) loadPrivileges();
    }, [cxt.sc.id]);

    // -----| Render |-----
    return (
        <>
            {privileges && (
                <SttPrivilegeList
                    privileges={privileges.rows.filter((priv) =>
                        privilegesToShow.includes(priv.id),
                    )}
                    onToggle={togglePrivilege}
                    title={cxt.t('Privilege')}
                />
            )}
        </>
    );
}
