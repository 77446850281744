import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, Avatar, Tooltip } from '@material-ui/core';
import {
    ExitToApp,
    Settings,
    PowerSettingsNew,
    Speed,
    HelpOutline,
    PersonOutline,
    PostAdd,
    BallotOutlined,
    FamilyRestroom,
} from '@material-ui/icons';
import PaymentIcon from '@material-ui/icons/Payment';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { paths, getPath } from '../../pages/Pages';
import { AppContext, SttMenu } from '../../sporttia/all';

export default function UserHeaderAvatar() {
    const cxt = useContext(AppContext);
    const history = useHistory();

    /**
     * Logout
     */
    const logout = () => {
        cxt.api('POST', '/logout', {
            confirmation: true,
            success: () => {
                cxt.setLoggedUser(false);
                cxt.setHeader(null);
            },
        });
    };

    /**
     * If "cxt.logged" change and is false, we will redirect to login page.
     */
    useEffect(() => {
        if (!cxt.logged) history.push(getPath('loadingSporttia'));
    }, [cxt.logged]);

    return (
        <SttMenu
            icon={
                <Tooltip title={cxt.t('MyAccount')} arrow>
                    <Avatar
                        style={{
                            backgroundColor: 'white',
                            color: '#666',
                            width: 25,
                            height: 25,
                        }}
                    >
                        {cxt &&
                            cxt.user &&
                            cxt.user.name &&
                            cxt.user.name.substring(0, 1)}
                    </Avatar>
                </Tooltip>
            }
            items={[
                {
                    caption: cxt.user.name,
                    onClick: () => history.push(getPath('userConfiguration')),
                },
                {
                    divider: true,
                    caption: cxt.t('Configuration'),
                    icon: <Settings />,
                    onClick: () => history.push(getPath('userConfiguration')),
                },
                {
                    caption: cxt.t('Bonos'),
                    icon: <BallotOutlined />,
                    onClick: () => history.push(getPath('userBonos')),
                },
                {
                    caption: cxt.t('Abonos'),
                    icon: <PostAdd />,
                    onClick: () => history.push(getPath('userAbonos')),
                },
                {
                    caption: cxt.t('Payments'),
                    icon: <PaymentIcon />,
                    onClick: () => history.push(getPath('userPayments')),
                },
                {
                    caption: cxt.t('Pupils'),
                    icon: <SupervisorAccountIcon />,
                    onClick: () => history.push(getPath('userPupils')),
                },
                {
                    caption: cxt.t('Help'),
                    icon: <HelpOutlineIcon />,
                    onClick: () => history.push(getPath('contact')),
                },
                {
                    divider: true,
                    caption: cxt.t('Exit'),
                    icon: <PowerSettingsNew />,
                    onClick: () => logout(),
                },
            ]}
        />
    );
}
