import { useContext } from 'react';
import { useAPI } from '../lib/hooks';
import { AppContext } from '../sporttia/AppContext';
import useRestService from './RestService';

const resource = '/me';

/**
 * Hook to access all 'me' resources.
 */
export default function useMeService() {
    const { migrationApis } = useContext(AppContext);
    const [request] = useAPI(migrationApis);
    const services = useRestService(resource);

    /**
     * PUT /me request.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function updateMe(params) {
        return request('PUT', resource, params);
    }

    return {
        ...services,
        updateMe,
    };
}
