import useRestService from './RestService';

const resource = '/provinces';

/**
 * Hook to access all 'provinces' resources.
 */
export default function useProvincesService() {
    const services = useRestService(resource);

    return {
        ...services,
    };
}
