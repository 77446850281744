import React from 'react';
import { Box, BoxProps, LinearProgress } from '@material-ui/core';

type SttLoadingBarProps = {
    visible: boolean;
    style?: BoxProps['style'];
};

/**
 * SttLoadingBar: A very simple LinearProgress wrapped in a flex-growing Box. For use with the useLoadingBar hook (see /src/lib/hooks)
 */
export default function SttLoadingBar({
    visible,
    style = undefined,
}: SttLoadingBarProps) {
    if (!visible) {
        return null;
    }

    return (
        <Box flex={1} style={style}>
            <LinearProgress />
        </Box>
    );
}
