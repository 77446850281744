import React, { useState, useEffect, useContext } from 'react';
import { Box, CircularProgress, Link, Typography } from '@material-ui/core';
import moment from 'moment';
import {
    AppContext,
    SttTabs,
    SttFeeList,
    SttDialog,
    SttLabelPeriod,
    SttList,
} from '../../all';
import {
    createObjectWithUpdatedFieldsOnly,
    getErrorMessage,
    subsetObject,
} from '../../../lib/utils';
import SttGroupMemberDialogDetail from './SttGroupMemberDialogDetail';
import SttBeneficiariesList from '../../lists/SttBeneficiariesList';
import POSPaymentDialog from '../../../components/dialogs/POSPaymentDialog';
import POSStripePaymentDialog from '../../../components/stripe/POSStripePaymentDialog';
import SttError from '../../../components/error/SttError';
import useGroupsService from '../../../services/GroupsService';
import constants from '../../../config/constants';
import { useLoader } from '../../../lib/hooks';
import translations from '../../../translations';

/**
 *	Group member of a group
 *	=======================
 *
 * Props
 *
 *	* integer idGroupMember
 * 	* SttGroup group - the parent group (as we need some data for conditional operations)
 * 	* function onClose : callback when 'close' button or ESC key is pressed
 * 	* function onUpdateMember: after adding, updating and deleting.
 */
export default function SttGroupMemberDialog({
    idGroupMember = null,
    group,
    onClose,
    onUpdateMember,
    trash = false,
}) {
    const cxt = useContext(AppContext);
    const [member, setMember] = useState();
    const [discountsForm, setDiscountsForm] = useState([]);
    const [creating, setCreating] = useState(false);
    const [history, setHistory] = useState();
    const [posPaymentParams, setPosPaymentParams] = useState(null);
    const [currentMemberState, setCurrentMemberState] = useState();
    const groupsService = useGroupsService();
    const getGroupDiscountsQuery = groupsService.useGetGroupDiscounts(
        group.id,
        {},
        {
            onSuccess: (response) => {
                setDiscountsForm(
                    response.rows.map((discount) => ({
                        ...discount,
                        checked: false,
                    })),
                );
            },
            refetchOnMount: false,
            enabled: creating,
        },
    );

    const createGroupMemberMutation = groupsService.useCreateGroupMember({
        onSuccess: (response) => {
            const { tpv, groupMember } = response;

            if (
                tpv?.payment?.paymentForm ===
                constants.payment.paymentForms.dataphoneConnected.name
            ) {
                setPosPaymentParams({
                    amount: tpv.price,
                    concept: tpv.concept,
                    idTpv: tpv.id,
                    sc: {
                        id: tpv?.sc?.id,
                        scName: tpv?.sc?.short,
                        entityName: tpv?.sc?.customer?.name,
                        address: tpv?.sc?.address,
                        cif: tpv?.sc?.customer?.cif,
                        phone: tpv?.sc?.phonePublic,
                    },
                    returnParams: {
                        groupMember,
                    },
                });
            } else {
                onUpdateMember(response.groupMember);
                setCurrentMemberState(response?.groupMember);
            }
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });
    const updateGroupMemberMutation = groupsService.useUpdateGroupMember({
        onSuccess: (response) => {
            onUpdateMember(response.groupMember);
            setCurrentMemberState(response?.groupMember);
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
            setMember(currentMemberState);
        },
    });

    const loadHistory = () => {
        cxt.api(
            'GET',
            `/groups/${member.group.id}/users/${member.user.id}/members`,
            {
                success: (r) => {
                    setHistory(r);
                },
            },
        );
    };

    const loadGroupMember = () => {
        cxt.api('GET', `/groups/members/${idGroupMember}`, {
            params: {
                trash,
            },
            success: (r) => {
                setMember(r.groupMember);
                setCurrentMemberState(r.groupMember);
            },
        });
    };

    useEffect(() => {
        if (member?.id) {
            loadHistory();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [member?.id]);

    /**
     * Group member id changes
     */
    useEffect(() => {
        // Load group member
        if (idGroupMember === 'create') {
            // Group member data by default
            setMember({
                name: '',
                notes: '',
                ini: moment(group.ini).isBefore(moment())
                    ? moment().format('YYYY-MM-DD')
                    : moment(group.ini).format('YYYY-MM-DD'),
                end: moment(group.end).format('YYYY-MM-DD'),
                paymentFormFee: '',
                user: {
                    mship: {
                        fullName: '',
                    },
                },
                enrollment: {
                    price: group.enrollmentPrice,
                    paymentForm: '',
                },
                group: {
                    fee: group.feePrice,
                },
                expirationCheck: !(
                    group.expiration === constants.bono.bonoExpirationDefault ||
                    group.expiration === null
                ), // Check para comprobar si se envia parametro end o no
            });
            setCreating(true);
        } else {
            setCreating(false);
            loadGroupMember();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idGroupMember]);

    function handleChangeDiscountsForm({ name: id, value }) {
        setDiscountsForm((prev) =>
            prev.map((discount) =>
                discount.id === id
                    ? {
                          ...discount,
                          checked: value,
                      }
                    : discount,
            ),
        );
    }

    function handleChange({ name, value }) {
        let updatedName = name;
        let updatedValue = value;

        if (name === 'paymentFormSignup') {
            updatedName = 'enrollment';
            updatedValue = {
                ...member.enrollment,
                paymentForm: value,
            };
        }

        if (name === 'mship') {
            updatedName = 'user';
            updatedValue = {
                mship: value,
            };
        }

        setMember((prev) => ({ ...prev, [updatedName]: updatedValue }));
    }

    function upsertMember() {
        let paramsFilter = ['ini', 'end', 'notes', 'paymentFormFee']; // No se debe incluir expirationCheck

        if (member.expirationCheck) {
            paramsFilter = paramsFilter.filter(
                (parameter) => parameter !== 'end',
            );
        }
        const params = createObjectWithUpdatedFieldsOnly(
            subsetObject(member, paramsFilter.join(',')),
            currentMemberState,
        );

        const discountsIdsToSend = discountsForm
            .filter((discount) => discount.checked)
            .map((discount) => discount.id);

        if (creating) {
            createGroupMemberMutation.mutate({
                id: group.id,
                params: {
                    ...params,
                    idUser: member.user.mship.user && member.user.mship.user.id,
                    paymentFormSignup:
                        member.enrollment && member.enrollment.paymentForm,
                    discountsIds: discountsIdsToSend,
                },
            });
        } else {
            updateGroupMemberMutation.mutate({
                id: member.id,
                params,
            });
        }
    }

    function deleteMember() {
        cxt.api('PUT', `/groups/members/${member.id}/unsubscribe`, {
            confirmation: true,
            success: (r) => {
                onUpdateMember(r.groupMember);
            },
        });
    }

    function deleteFutureMemberPeriod(id) {
        cxt.api('DELETE', `/groups/members/${id}`, {
            confirmation: true,
            success: (r) => {
                onUpdateMember(r.groupMember);
            },
        });
    }

    const [isMutationsLoading, mutationsLoader] = useLoader([
        createGroupMemberMutation.status,
        updateGroupMemberMutation.status,
    ]);

    if (isMutationsLoading) {
        return mutationsLoader;
    }

    let dialogContent;
    if (getGroupDiscountsQuery.isLoading) {
        dialogContent = (
            <Box display="flex" justifyContent="center" paddingY={12}>
                <CircularProgress />
            </Box>
        );
    } else if (getGroupDiscountsQuery.isError) {
        dialogContent = <SttError />;
    } else if (!member) {
        return null;
    } else {
        dialogContent = (
            <SttTabs
                tabs={[
                    {
                        caption: cxt.t('sc.groups.members.details'),
                        component: (
                            <SttGroupMemberDialogDetail
                                member={member}
                                creating={creating}
                                handleChange={handleChange}
                                group={group}
                                discounts={
                                    creating ? discountsForm : member.discounts
                                }
                                handleChangeDiscounts={
                                    handleChangeDiscountsForm
                                }
                            />
                        ),
                    },
                    {
                        show: !creating,
                        caption: cxt.t(translations.generic.history),
                        component: (
                            <SttList
                                numRows={5}
                                data={history?.rows.map((ug) => ({
                                    caption: (
                                        <>
                                            {ug.ini && ug.end ? (
                                                <SttLabelPeriod
                                                    ini={ug.ini}
                                                    end={ug.end}
                                                    dateWithTime
                                                />
                                            ) : (
                                                <Typography
                                                    variant="subtitle1"
                                                    display="inline"
                                                >
                                                    {cxt.t('PeriodWithoutDate')}
                                                </Typography>
                                            )}
                                            <Link
                                                onClick={() => {
                                                    deleteFutureMemberPeriod(
                                                        ug.id,
                                                    );
                                                }}
                                            >
                                                &nbsp;&nbsp;
                                                {cxt.t('Delete')}
                                            </Link>
                                        </>
                                    ),
                                }))}
                            />
                        ),
                    },
                    {
                        show: group.feePrice !== undefined && !creating,
                        caption: cxt.t('Fees'),
                        component: <SttFeeList fees={member.fees} />,
                    },
                    {
                        show: group.type === 'FAMILY' && !creating,
                        caption: cxt.t('Beneficiaries'),
                        component: <SttBeneficiariesList member={member} />,
                    },
                ]}
            />
        );
    }

    return (
        <>
            <SttDialog
                open={idGroupMember !== null}
                title={cxt.t('sc.groups.members.details')}
                onClose={() => (onClose ? onClose() : null)}
                maxWidth="md"
                content={dialogContent}
                buttons={[
                    {
                        show:
                            !getGroupDiscountsQuery.isLoading &&
                            !getGroupDiscountsQuery.isError,
                        type: 'save',
                        onClick: upsertMember,
                    },
                    {
                        show:
                            !creating &&
                            !getGroupDiscountsQuery.isLoading &&
                            !getGroupDiscountsQuery.isError,
                        type: 'unsubscribe',
                        onClick: deleteMember,
                    },
                ]}
            />

            {cxt.sc?.sporttiaStripeLocationId && posPaymentParams !== null ? (
                <POSStripePaymentDialog
                    paymentData={posPaymentParams}
                    onClose={() => setPosPaymentParams(null)}
                    onSuccess={() => {
                        onUpdateMember(
                            posPaymentParams.returnParams.groupMember,
                        );
                    }}
                />
            ) : (
                <POSPaymentDialog
                    open={posPaymentParams !== null}
                    paymentData={posPaymentParams}
                    onClose={() => setPosPaymentParams(null)}
                    onSuccess={(ticket, returnParams) => {
                        onUpdateMember(returnParams.groupMember);
                    }}
                />
            )}
        </>
    );
}
