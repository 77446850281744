import React, { useState, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AppContext, SttDialog, SttForm } from '../../../sporttia/all';

export default function FileCSVLineDialog({ line, onClose, onUpdate }) {
    const cxt = useContext(AppContext);
    const [internalLine, setInternalLine] = useState();

    /**
     * Intpu line changes
     */
    useEffect(() => {
        setInternalLine(line);
    }, [line]);

    /**
     * Save line
     */
    const saveLine = () => {
        const newValues = [];
        for (let i = 0; i < cxt.constants.numFieldsToUserImport; i++) {
            newValues.push(internalLine[`field_${i}`]);
        }

        cxt.api('PUT', `/files/csvs/lines/${internalLine.id}`, {
            params: {
                values: newValues,
            },
            success: (r) => {
                if (onUpdate) onUpdate(r.fileCSVLine);
            },
        });
    };

    /**
     * Delete line
     */
    const delLine = () => {
        cxt.api('DELETE', `/files/csvs/lines/${internalLine.id}`, {
            confirmation: true,
            success: (r) => {
                if (onUpdate) onUpdate(r.fileCSVLine);
            },
        });
    };

    /**
     * Recover line
     */
    const recoverLine = () => {
        cxt.api('PUT', `/files/csvs/lines/${internalLine.id}/recover`, {
            success: (r) => {
                if (onUpdate) onUpdate(r.fileCSVLine);
            },
        });
    };

    /**
     * Handle form
     */
    const handleForm = ({ name, value }) => {
        setInternalLine({ ...internalLine, [name]: value });
    };

    // -----| Render |-----
    return (
        <>
            {internalLine && (
                <SttDialog
                    title={cxt.t('Line')}
                    open={internalLine != null}
                    onClose={onClose}
                    content={
                        <Box>
                            {line && line.error && (
                                <Box mb={3}>
                                    <Alert severity="error" variant="filled">
                                        {line.error}
                                    </Alert>
                                </Box>
                            )}

                            <SttForm
                                elements={[
                                    ...Array(
                                        cxt.constants.numFieldsToUserImport,
                                    ).keys(),
                                ].map((num) => ({
                                    md: 6,
                                    sm: 12,
                                    xs: 12,
                                    type: 'text',
                                    name: `field_${num}`,
                                    caption: `#${num}`,
                                    value: internalLine[`field_${num}`],
                                }))}
                                onChangeForm={handleForm}
                            />
                        </Box>
                    }
                    buttons={[
                        {
                            show: internalLine && internalLine.trash,
                            type: 'recover',
                            onClick: () => recoverLine(),
                        },
                        {
                            show: internalLine && !internalLine.trash,
                            type: 'delete',
                            onClick: () => delLine(),
                        },
                        {
                            show: internalLine && !internalLine.trash,
                            type: 'save',
                            onClick: () => saveLine(),
                        },
                        {
                            type: 'close',
                            onClick: onClose,
                        },
                    ]}
                />
            )}
        </>
    );
}
