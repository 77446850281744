import React, { useState, useEffect, useContext } from 'react';
import {} from '@material-ui/core';
import { AppContext, SttSCAdminList } from '../../../sporttia/all';
import {
    updateElementInArray,
    deleteElementFromArray,
} from '../../../lib/utils';
import SCAdminDialog from './SCAdminDialog';

export default function AdminsTab() {
    const cxt = useContext(AppContext);
    const [scAdmins, setScAdmins] = useState();
    const [selectedAdmin, setSelectedAdmin] = useState();

    /**
     * Load admins
     */
    const loadAdmins = () => {
        cxt.api('GET', `/scs/${cxt.sc.id}/admins`, {
            success: (r) => {
                setScAdmins(r.rows);
            },
        });
    };

    /**
     * SC id changes
     */
    useEffect(() => {
        if (cxt.sc.id) loadAdmins();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cxt.sc.id]);

    /**
     * Delete sc admin
     */
    const delSCAdmin = (scAdmin) => {
        cxt.api('DELETE', `/scs/admins/${scAdmin.id}`, {
            confirmation: true,
            success: () => {
                cxt.showMessage('S', cxt.t('Deleted'));
                setScAdmins(deleteElementFromArray(scAdmins, scAdmin));
            },
        });
    };

    // -----| Render |-----
    return (
        <>
            <SttSCAdminList
                scAdmins={scAdmins}
                onCreate={() => setSelectedAdmin({})}
                onDelete={delSCAdmin}
                onSelect={(scAdmin) => setSelectedAdmin(scAdmin)}
            />

            <SCAdminDialog
                scAdmin={selectedAdmin}
                onClose={(adminUpdated) => {
                    // Close popup
                    setSelectedAdmin(null);

                    if (adminUpdated.id) {
                        setScAdmins(
                            updateElementInArray(scAdmins, adminUpdated),
                        );
                    }
                }}
            />
        </>
    );
}
