import React, { useState, useContext } from 'react';
import { Paper } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import { AppContext, SttButtonFab } from '../../../../sporttia/all';
import { usePageHeader, useQueryFilters } from '../../../../lib/hooks';
import AdminTest from './AdminTest';
import useTestsService from '../../../../services/TestsService';
import { SUPERADMIN_TESTS } from '../../../../lib/queryKeys';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';
import SttCachedTable from '../../../../sporttia/SttCachedTable';

/**
 * View where the sporttia superadmin can see the list of tests.
 * @returns {JSX.Element}
 */
export default function APITests({}) {
    const cxt = useContext(AppContext);
    usePageHeader('tests');
    const queryClient = useQueryClient();
    const testsService = useTestsService();
    const [filters, setFilters] = useQueryFilters({}, SUPERADMIN_TESTS);
    const [testId, setTestId] = useState(null);

    return (
        <Paper>
            <SttTopFilteringControls
                p={2}
                fields={[
                    {
                        name: 'id',
                        type: 'text',
                        value: '',
                        caption: 'ID',
                    },
                ]}
                extraFields={[
                    {
                        caption: 'Type',
                        name: 'type',
                        value: 'UNIT',
                        type: 'select',
                        options: cxt.constants.testTypes,
                    },
                    {
                        caption: 'Status',
                        name: 'status',
                        value: '',
                        type: 'select',
                        options: cxt.constants.testStatuses,
                    },
                    {
                        caption: 'Description',
                        name: 'description',
                        value: '',
                    },
                    {
                        caption: 'Tags',
                        name: 'tags',
                        value: '',
                    },
                    {
                        caption: 'Hart',
                        name: 'hart',
                        value: '',
                    },
                    {
                        caption: 'Function',
                        name: 'function',
                        value: '',
                    },
                ]}
                onFilter={setFilters}
            />

            <SttCachedTable
                queryKey={SUPERADMIN_TESTS}
                queryFn={(params) => testsService.getList(params)}
                queryParams={filters}
                onClickRow={(row) => setTestId(row?.id)}
                columns={[
                    {
                        title: 'ID',
                        field: 'id',
                        type: 'id',
                    },
                    {
                        title: 'Hart',
                        field: 'hart',
                    },
                    {
                        title: cxt.t('Function'),
                        field: 'function',
                    },
                    {
                        title: cxt.t('Description'),
                        field: 'description',
                        align: 'left',
                        value: (row) => (
                            <span style={{ whiteSpace: 'pre-line' }}>
                                <b>
                                    {row.description &&
                                        row.description
                                            .split('<br/>')
                                            .join('\n')}
                                </b>
                            </span>
                        ),
                    },
                    {
                        title: cxt.t('Tags'),
                        value: (row) => row.tags && row.tags.join(', '),
                        width: 130,
                    },
                    {
                        title: cxt.t('Status'),
                        field: 'status',
                    },
                ]}
            />

            <AdminTest
                id={testId}
                onClose={() => setTestId(null)}
                onCreate={setTestId}
                onUpdate={() => queryClient.invalidateQueries(SUPERADMIN_TESTS)}
                onDelete={() => {
                    queryClient.invalidateQueries(SUPERADMIN_TESTS);
                    setTestId(null);
                }}
            />

            <SttButtonFab onClick={() => setTestId('create')} />
        </Paper>
    );
}
