import { useCrud } from '../../../lib/hooks';

export function useParticipantFuncs() {
    const [, , , , Post, Put, Delete] = useCrud();

    function composeFormData(form) {
        return form.reduce((result, field) => {
            // eslint-disable-next-line no-param-reassign
            result[field.id] = field.answer ? field.answer.value : '';
            return result;
        }, {});
    }

    function saveParticipant(participant, categoryId) {
        return new Promise((resolve, reject) => {
            const {
                name,
                mobile,
                email,
                notes,
                user,
                team,
                admin,
                paymentForm,
                form,
            } = participant;
            const idTeamExists = team && team.id && team.id !== '0';

            const params = {
                name,
                mobile,
                email,
                notes,
                idUser: user.id,
                paymentForm: paymentForm || '',
                idTeam: idTeamExists ? team.id : null,
                teamName: team && !idTeamExists ? team.name : null,
                admin: idTeamExists ? +admin : null,
                form: form ? composeFormData(form) : null,
            };

            delete params.team;
            delete params.user;
            delete params.category;

            if (participant.id) {
                Put(`/events/participants/${participant.id}`, params)
                    .then(resolve)
                    .catch(reject)
                    .finally(() => {});
            } else {
                Post(`/events/categories/${categoryId}/participants`, params)
                    .then(resolve)
                    .catch(reject)
                    .finally(() => {});
            }
        });
    }

    function deleteParticipant(participant) {
        return new Promise((resolve, reject) => {
            Delete(`/events/participants/${participant.id}`)
                .then(resolve)
                .catch(reject);
        });
    }

    return [saveParticipant, deleteParticipant];
}
