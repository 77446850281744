import React, { useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { AppContext } from '../../../../sporttia/AppContext';
import SttValidatedForm from '../../../../sporttia/SttValidatedForm';
import { SttDialog } from '../../../../sporttia/dialogs/SttDialog';
import { useLoader } from '../../../../lib/hooks';
import useCustomersService from '../../../../services/CustomersService';

/**
 * Dialog to create, modify and delete subscriptions associated with a client.
 * @param open If the value is true, the dialog will open and close otherwise.
 * @param customerId Customer ID.
 * @param subscription Subscription object.
 * @param onClose Action taken when closing the dialog.
 * @param onDelete Action taken when removing subscription.
 * @param onUpdate Action taken when updating the subscription.
 * @param onCreate Action taken when creating the subscription.
 * @returns {JSX.Element}
 */
export default function AdminCustomerSubscriptionDialog({
    open,
    customerId,
    subscription,
    onClose,
    onDelete,
    onUpdate,
    onCreate,
}) {
    const cxt = useContext(AppContext);
    const form = useForm();
    const customersService = useCustomersService();
    const deleteMutation = useMutation(
        (params) => customersService.removeCustomerSubscriptions(params?.id),
        {
            onSuccess: onDelete,
        },
    );
    const updateMutation = useMutation(
        (params) =>
            customersService.updateCustomerSubscriptions(
                params?.id,
                params?.params,
            ),
        {
            onSuccess: onUpdate,
        },
    );
    const createMutation = useMutation(
        (params) =>
            customersService.createCustomerSubscriptions(
                params?.id,
                params?.params,
            ),
        {
            onSuccess: (data) => onCreate(data?.customer?.id),
        },
    );
    const [, loader] = useLoader([
        deleteMutation.status,
        updateMutation.status,
        createMutation.status,
    ]);

    /**
     * Method executed when the user clicks on save or create subscription.
     */
    const mutateSubscription = (formData) => {
        if (subscription?.id !== 'create') {
            updateMutation.mutate({ id: subscription?.id, params: formData });
        } else {
            createMutation.mutate({ id: customerId, params: formData });
        }
    };

    /**
     * When the dialog opens, the data in the form is reset.
     */
    useEffect(() => {
        if (open && subscription?.id !== 'create') {
            form.reset({
                status: subscription?.status,
                dateini: subscription?.dateini,
                dateend: subscription?.dateend,
                plan: subscription?.plan,
                fee: subscription?.fee,
                feeInterval: subscription?.feeInterval,
            });
        } else {
            form.reset({
                status: 'ACTIVE',
                dateini: new Date(),
                dateend: new Date(),
                plan: 'FREE',
                fee: 0,
                feeInterval: '0000-01-00 00:00:00',
            });
        }
    }, [open]);

    return (
        <SttDialog
            title={cxt.t('Subscription')}
            maxWidth="md"
            open={open}
            onClose={onClose}
            content={
                <>
                    {loader}
                    <Grid container spacing={3}>
                        <SttValidatedForm
                            form={form}
                            fields={[
                                {
                                    type: 'select',
                                    name: 'status',
                                    caption: cxt.t('Status'),
                                    options: [
                                        {
                                            caption: cxt.t('ACTIVE'),
                                            value: 'ACTIVE',
                                        },
                                        {
                                            caption: cxt.t('INACTIVE'),
                                            value: 'INACTIVE',
                                        },
                                    ],
                                    disableClearable: true,
                                    md: 4,
                                    xs: 6,
                                },
                                {
                                    type: 'date',
                                    name: 'dateini',
                                    caption: cxt.t('DateIni'),
                                    md: 4,
                                    xs: 6,
                                },
                                {
                                    type: 'date',
                                    name: 'dateend',
                                    caption: cxt.t('DateEnd'),
                                    md: 4,
                                    xs: 6,
                                },
                                {
                                    type: 'select',
                                    name: 'plan',
                                    caption: cxt.t('Plan'),
                                    options: [
                                        {
                                            caption: cxt.t('Free'),
                                            value: 'FREE',
                                        },
                                        {
                                            caption: cxt.t('Basic'),
                                            value: 'BASIC',
                                        },
                                        { caption: 'Plus', value: 'PLUS' },
                                        {
                                            caption: 'Personal',
                                            value: 'PERSONAL',
                                        },
                                        { caption: 'ZERO', value: 'ZERO' },
                                    ],
                                    disableClearable: true,
                                    md: 4,
                                    xs: 6,
                                },
                                {
                                    type: 'price',
                                    name: 'fee',
                                    caption: cxt.t('price'),
                                    md: 4,
                                    xs: 6,
                                },
                                {
                                    type: 'select',
                                    name: 'feeInterval',
                                    caption: cxt.t('Interval'),
                                    options: [
                                        {
                                            caption: cxt.t(
                                                '0000-01-00 00:00:00',
                                            ),
                                            value: '0000-01-00 00:00:00',
                                        },
                                        {
                                            caption: cxt.t(
                                                '0000-02-00 00:00:00',
                                            ),
                                            value: '0000-02-00 00:00:00',
                                        },
                                        {
                                            caption: cxt.t(
                                                '0000-03-00 00:00:00',
                                            ),
                                            value: '0000-03-00 00:00:00',
                                        },
                                        {
                                            caption: cxt.t(
                                                '0000-06-00 00:00:00',
                                            ),
                                            value: '0000-06-00 00:00:00',
                                        },
                                        {
                                            caption: cxt.t(
                                                '0001-00-00 00:00:00',
                                            ),
                                            value: '0001-00-00 00:00:00',
                                        },
                                        {
                                            caption: cxt.t(
                                                '0002-00-00 00:00:00',
                                            ),
                                            value: '0002-00-00 00:00:00',
                                        },
                                        {
                                            caption: cxt.t(
                                                '0003-00-00 00:00:00',
                                            ),
                                            value: '0003-00-00 00:00:00',
                                        },
                                        {
                                            caption: cxt.t(
                                                '0004-00-00 00:00:00',
                                            ),
                                            value: '0004-00-00 00:00:00',
                                        },
                                        {
                                            caption: cxt.t(
                                                '0005-00-00 00:00:00',
                                            ),
                                            value: '0005-00-00 00:00:00',
                                        },
                                    ],
                                    disableClearable: true,
                                    md: 4,
                                    xs: 6,
                                },
                            ]}
                        />
                    </Grid>
                </>
            }
            buttons={[
                {
                    type: 'save',
                    onClick: form.handleSubmit((formData) =>
                        mutateSubscription(formData),
                    ),
                },
                {
                    type: 'delete',
                    confirmation: true,
                    onClick: () =>
                        deleteMutation.mutate({ id: subscription?.id }),
                },
            ]}
        />
    );
}
