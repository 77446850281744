import React, { useContext, useState } from 'react';
import { Box, Container, FormControlLabel, Switch } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { useHistory, Link } from 'react-router-dom';
import { AppContext, SttTable, SttTopControls } from '../../../sporttia/all';
import { getPath } from '../../Pages';
import { useInteractionsFiles, useToggle } from '../../../lib/hooks';
import PaymentFormDialog from '../../../components/dialogs/PaymentFormDialog';
import { formatPriceByLocale } from '../../../lib/utils';
import constants from '../../../config/constants';

const getPaymentFormsBySportcenter = (sportcenter) => {
    const result = [constants.payment.paymentForms.purse.name];

    if (sportcenter.tpvConf) {
        result.push(constants.payment.paymentForms.tpv.name);
    }

    return result;
};

export default function UserPayments() {
    const cxt = useContext(AppContext);
    const { openFile } = useInteractionsFiles();
    const [filters, setFilters] = useState({
        scName: '',
    });
    const [userPayments, setUserPayments] = useState();
    const [debts, toggleDebts] = useToggle(() => {
        // eslint-disable-next-line no-use-before-define
        loadUserPayments();
    });
    const [paymentChosen, setPaymentChosen] = useState(null);
    const history = useHistory();

    /**
     * Download payment Ticket.
     * @param idPayment Payment ID.
     * @param type Ticket type.
     */
    const ticket = (idPayment, type = 0) => {
        openFile(
            `/payments/${idPayment}.pdf?format=${type}`,
            'application/pdf',
            null,
            `${cxt.t('Ticket')}_${idPayment}`,
        );
    };

    /**
     * Load user payments.
     * @param p params with rows and page requested.
     */
    const loadUserPayments = (p = { rows: 45, page: 1 }) => {
        cxt.api('GET', `/my/payments`, {
            params: {
                ...filters,
                ...p,
                page: p.page,
                rows: p.rows,
                status: debts ? 'NOT_PAID' : 'PAID',
            },
            success: (r) => {
                setUserPayments(r);
            },
        });
    };

    /**
     * Make the payment.
     * @param idPayment Payment ID.
     * @param pf Payment form (number).
     */
    const pay = (idPayment, pf) => {
        cxt.api('PUT', `/payments/${idPayment}/collect`, {
            params: {
                paymentForm: pf,
            },
            confirmation: pf !== 'TPV',
            success: (r) => {
                if (pf === 'TPV') {
                    history.push(getPath('tpvSwitcher', { id: r.tpv.id }));
                } else {
                    loadUserPayments();
                    setPaymentChosen(null);
                    cxt.showMessage('S', cxt.t('PaymentCollected'));
                }
            },
        });
    };

    return (
        <>
            <Container maxWidth="md">
                <Paper>
                    {/* Top controls with sport center search field and toggle button to change between PAID or NOT PAID payments. */}
                    <SttTopControls
                        p={2}
                        onChange={({ name, value }) => {
                            setFilters({ ...filters, [name]: value });
                        }}
                        onFilter={loadUserPayments}
                        mainAction={{
                            type: 'component',
                            component: (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={undefined}
                                            onChange={(ev) =>
                                                toggleDebts(ev.target.checked)
                                            }
                                        />
                                    }
                                    label={cxt.t('Debt')}
                                />
                            ),
                        }}
                        fields={[
                            {
                                caption: cxt.t('Sportcenter'),
                                name: 'scName',
                                type: 'text',
                                value: filters.scName,
                                onEnterKey: () => loadUserPayments(),
                            },
                        ]}
                    />

                    {/* Table with payments data. */}
                    <SttTable
                        data={userPayments}
                        loading={false}
                        autoload
                        onFetch={loadUserPayments}
                        columns={[
                            {
                                title: cxt.t('Created'),
                                name: 'created',
                                width: 110,
                                value: (row) =>
                                    moment(row.created).format('D MMM YYYY'),
                            },
                            {
                                title: cxt.t('Concept'),
                                name: 'concept',
                                value: (row) => (
                                    <Box
                                        fontWeight="fontWeightMedium"
                                        display="inline"
                                    >
                                        {row.concept}
                                    </Box>
                                ),
                            },
                            {
                                title: cxt.t('Center'),
                                name: 'sc',
                                value: (row) => (
                                    <Link
                                        to={getPath('scProfile', {
                                            id: row.sc.id,
                                        })}
                                    >
                                        {row.sc.short}
                                    </Link>
                                ),
                            },
                            {
                                title: cxt.t('paymentForm'),
                                name: 'paymentForm',
                                value: (row) =>
                                    row.paymentForm
                                        ? cxt.t(`PF.${row.paymentForm}`)
                                        : cxt.t('Pending'),
                            },
                            {
                                title: cxt.t('paymentDate'),
                                name: 'paymentDate',
                                width: 110,
                                value: (row) =>
                                    row.paymentDate
                                        ? moment(row.paymentDate).format(
                                              'D MMM YYYY',
                                          )
                                        : '',
                            },
                            {
                                title: cxt.t('Price'),
                                name: 'price',
                                value: (row) => (
                                    <Box
                                        style={{
                                            color:
                                                row.status === 'PAID'
                                                    ? 'green'
                                                    : 'red',
                                        }}
                                    >
                                        {formatPriceByLocale(
                                            row.price,
                                            row.priceShape?.currency,
                                            row.priceShape?.locale,
                                        )}
                                    </Box>
                                ),
                            },
                            {
                                title: '',
                                name: 'pay',
                                value: (row) =>
                                    row.status === 'PAID' ? (
                                        <Link onClick={() => ticket(row.id)}>
                                            {cxt.t('Ticket')}
                                        </Link>
                                    ) : (
                                        <Link
                                            onClick={() =>
                                                setPaymentChosen(row)
                                            }
                                        >
                                            {cxt.t('Pay')}
                                        </Link>
                                    ),
                            },
                        ]}
                    />
                </Paper>
            </Container>

            {/* Payment form dialog to collect payment. */}
            {paymentChosen && (
                <PaymentFormDialog
                    open
                    onClose={() => setPaymentChosen(null)}
                    pfs={getPaymentFormsBySportcenter(paymentChosen.sc)}
                    onSelect={(pf) => pay(paymentChosen.id, pf)}
                />
            )}
        </>
    );
}
