import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Box, Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Check } from '@material-ui/icons';
import {
    AppContext,
    SttFormSearch,
    SttList,
    SttNoResults,
} from '../../../sporttia/all';
import { useCrud } from '../../../lib/hooks';
import { getPath } from '../../Pages';
import { formatPriceByLocale } from '../../../lib/utils';

export default function BonoList({ match }) {
    const cxt = useContext(AppContext);
    const history = useHistory();

    const [bonos, setBonos, , Get] = useCrud();
    const [idSC, setIdSC] = useState(null);

    const [search, setSearch] = useState(undefined);

    useEffect(() => {
        if (match.params && match.params.id) {
            setIdSC(match.params.id);
            loadBonos();
        }

        cxt.setHeader({ title: cxt.t('Bonos') });
    }, []);

    const filteredBonos = useMemo(() => {
        if (!search) return bonos?.rows;

        return bonos?.rows?.filter((bono) =>
            bono.name.toLowerCase().includes(search.toLowerCase()),
        );
    }, [bonos, search]);

    const handleSearchChange = ({ value }) => {
        setSearch(value);
    };

    const handleSearchClean = () => {
        setSearch(undefined);
    };

    function loadBonos(params = { rows: 1000 }) {
        Get(`/scs/${match.params.id}/bonos`, { ...params }).then(setBonos);
    }

    if (!bonos || !idSC) {
        return null;
    }

    return (
        <Container maxWidth="md">
            <Box p={1}>
                <SttFormSearch
                    caption={cxt.t('search')}
                    onChange={handleSearchChange}
                    defVal={search ?? ''}
                    onClean={handleSearchClean}
                />
            </Box>
            <Box p={1}>
                {filteredBonos.length > 0 ? (
                    <SttList
                        paper
                        title={cxt.t('Bonos')}
                        onSelect={(bono) => {
                            if (bono.purchaseOnline) {
                                history.push(
                                    getPath('buyBono', { idSC, id: bono.id }),
                                );
                            }
                        }}
                        data={filteredBonos.map((bono) => ({
                            caption: (
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <div>
                                        {bono.name} -{' '}
                                        {`${formatPriceByLocale(
                                            bono?.price,
                                            bono?.priceShape?.currency,
                                            bono?.priceShape?.locale,
                                        )}`}
                                    </div>
                                    {!bono.purchaseOnline ? (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            style={{ color: '#717171' }}
                                        >
                                            {cxt.t('hart.bonoUser.noOnline')}
                                        </Box>
                                    ) : (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            style={{ color: '#00A000' }}
                                        >
                                            <span>
                                                {cxt.t('OnlinePurchase')}
                                            </span>
                                            &nbsp;
                                            <Check />
                                        </Box>
                                    )}
                                </Box>
                            ),
                            objToSelect: bono,
                        }))}
                    />
                ) : (
                    <SttNoResults />
                )}
            </Box>
        </Container>
    );
}
