import React, { useContext, useEffect, useState } from 'react';
import { Container, Box, InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import {
    AppContext,
    SttActivityCardList,
    SttSCCardList,
    SttTabs,
} from '../../../sporttia/all';
import { usePosition, useTranslations } from '../../../lib/hooks';
import SttValidatedForm from '../../../sporttia/SttValidatedForm';
import { SttEventCardList } from '../../../sporttia/public/cards/SttEventCardList';
import { getQueryStrings } from '../../../lib/utils';
import useMeService from '../../../services/MeService';
import { getPath } from '../../Pages';

/**
 * Search view of centers, activities and events. There are tabs to choose these options next to the list of them.
 * @returns {JSX.Element}
 */
export default function Search() {
    const cxt = useContext(AppContext);
    const { translate } = useTranslations();
    const location = useLocation();
    const history = useHistory();

    const form = useForm();
    const watchKeyword = form.watch('keyword');
    const [keyword, setKeyword] = useState('');
    const { latitude, longitude } = usePosition(true);

    const meService = useMeService();
    const updateMeMutation = useMutation((params) =>
        meService.updateMe(params?.params),
    );

    const { mutate: updateMeMutate } = updateMeMutation;
    const ctxUserId = cxt.user.id;

    useEffect(() => {
        const queryStrings = getQueryStrings(location.search);

        if (queryStrings && queryStrings.q) {
            setKeyword(queryStrings.q);
            form.reset({ keyword: queryStrings.q });
        }
    }, [form, location.search]);

    useEffect(() => {
        if (latitude && longitude && cxt.logged && ctxUserId) {
            updateMeMutate({ params: { x: latitude, y: longitude } });
        }
    }, [cxt.logged, latitude, longitude, updateMeMutate, ctxUserId]);

    return (
        <Container maxWidth="md">
            <Box display="flex" justifyContent="center">
                <SttValidatedForm
                    form={form}
                    fields={[
                        {
                            type: 'textInput',
                            name: 'keyword',
                            caption: translate('search'),
                            size: 'medium',
                            InputProps: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => {
                                                if (
                                                    watchKeyword !== undefined
                                                ) {
                                                    setKeyword(watchKeyword);
                                                    history.push(
                                                        `${getPath(
                                                            'search',
                                                        )}?q=${watchKeyword}`,
                                                    );
                                                }
                                            }}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            },
                            onEnterKey: (value) => {
                                if (value !== undefined) {
                                    setKeyword(value);
                                    history.push(
                                        `${getPath('search')}?q=${value}`,
                                    );
                                }
                            },
                        },
                    ]}
                />
            </Box>

            <SttTabs
                fullWidth
                tabs={[
                    {
                        caption: translate('Centers'),
                        component: <SttSCCardList params={{ keyword }} />,
                    },
                    {
                        caption: translate('Activities'),
                        component: <SttActivityCardList params={{ keyword }} />,
                    },
                    {
                        caption: translate('Events'),
                        component: <SttEventCardList params={{ keyword }} />,
                    },
                ]}
            />
        </Container>
    );
}
