import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { useTranslations } from '../../lib/hooks';

type SttButtonDownloadProps = {
    caption?: string;
    onClick: () => void;
} & ButtonProps;

/**
 * Download button
 */
export function SttButtonDownload({
    caption,
    onClick,
    ...rest
}: SttButtonDownloadProps) {
    const { translate } = useTranslations();

    return (
        <Button
            {...rest}
            variant="contained"
            color="default"
            startIcon={<CloudDownload />}
            onClick={onClick}
        >
            {caption || translate('Download')}
        </Button>
    );
}
