import React, { useContext, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Button, Paper, makeStyles } from '@material-ui/core';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { CheckCircleOutline, HighlightOff } from '@material-ui/icons';
import { AppContext } from '../../../../sporttia/all';
import {
    useLoader,
    usePageHeader,
    useQueryFilters,
} from '../../../../lib/hooks';
import { SUPERADMIN_MIGRATION_ENDPOINTS } from '../../../../lib/queryKeys';
import useMigrationEndpointsService from '../../../../services/MigrationEndpointsService';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';
import MigrationEndpoint from './MigrationEndpoint';
import DateBuilder from '../../../../lib/DateBuilder';

const useStyles = makeStyles({
    notificationButton: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    notificationButtonActive: {
        color: '#18b432',
        '&:hover': {
            color: 'rgba(33, 150, 243, 0.5)',
        },
    },
    notificationButtonDisabled: {
        color: '#df3525',
        '&:hover': {
            color: 'rgba(33, 150, 243, 0.5)',
        },
    },
});

export default function AdminMigrationEndpoints() {
    usePageHeader('migrationEndpoints');
    const cxt = useContext(AppContext);
    const classes = useStyles();
    const queryClient = useQueryClient();
    const migrationEndpointsService = useMigrationEndpointsService();
    const [currentEndpoint, setCurrentEndpoint] = useState(null);
    const [filters, setFilters] = useQueryFilters(
        {},
        SUPERADMIN_MIGRATION_ENDPOINTS,
    );
    const createMigrationEndpointsWithSwagger = useMutation(
        () => migrationEndpointsService.createMigrationEndpointsWithSwagger(),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(SUPERADMIN_MIGRATION_ENDPOINTS);
            },
        },
    );
    const updateMutation = useMutation(
        (params) =>
            migrationEndpointsService.update(params?.id, params?.params),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(SUPERADMIN_MIGRATION_ENDPOINTS);
            },
            onError: (error) => {
                cxt.showMessage('E', error?.errors?.msg);
            },
        },
    );
    const [, loader] = useLoader([
        createMigrationEndpointsWithSwagger.status,
        updateMutation.status,
    ]);

    /**
     * In this case, before making the request (due to Material UI problems), if the value of "method"
     * takes the value of "ALL", it must be removed from the parameters sent.
     * @param updatedFilters Object with the parameters returned by the SttTopFilteringControls component.
     */
    const doFiltering = (updatedFilters) => {
        const processedFilters = updatedFilters;
        if (updatedFilters?.method === 'ALL') delete processedFilters.method;
        if (updatedFilters?.active === false) delete processedFilters.active;
        if (updatedFilters?.swagger === false) delete processedFilters.swagger;
        setFilters(updatedFilters);
    };

    return (
        <>
            {loader}
            <Paper>
                <SttTopFilteringControls
                    p={2}
                    fields={[
                        {
                            caption: cxt.t('Method'),
                            type: 'select',
                            name: 'method',
                            value: 'ALL',
                            options: [
                                {
                                    caption: cxt.t('All'),
                                    value: 'ALL',
                                },
                                {
                                    caption: 'GET',
                                    value: 'GET',
                                },
                                {
                                    caption: 'POST',
                                    value: 'POST',
                                },
                                {
                                    caption: 'PUT',
                                    value: 'PUT',
                                },
                                {
                                    caption: 'DELETE',
                                    value: 'DELETE',
                                },
                            ],
                        },
                        {
                            type: 'text',
                            caption: cxt.t('Endpoint'),
                            name: 'endpoint',
                            value: '',
                        },
                        {
                            type: 'switch',
                            caption: cxt.t('Active'),
                            name: 'active',
                            checked: false,
                        },
                        {
                            type: 'switch',
                            caption: 'Swagger',
                            name: 'swagger',
                            checked: false,
                        },
                    ]}
                    iconButtons={[
                        {
                            icon: <SystemUpdateAltIcon />,
                            onClick: () =>
                                createMigrationEndpointsWithSwagger.mutate(),
                        },
                    ]}
                    trashAction={false}
                    onFilter={doFiltering}
                />

                <SttCachedTable
                    queryKey={SUPERADMIN_MIGRATION_ENDPOINTS}
                    queryFn={(params) =>
                        migrationEndpointsService.getList(params)
                    }
                    queryParams={filters}
                    rowsPerPage={100}
                    columns={[
                        {
                            title: 'Id',
                            field: 'id',
                            align: 'left',
                        },
                        {
                            title: 'Metodo',
                            align: 'left',
                            field: 'method',
                        },
                        {
                            title: 'Endpoint',
                            align: 'left',
                            field: 'endpoint',
                            value: (row) => (
                                // eslint-disable-next-line react/button-has-type
                                <Button onClick={() => setCurrentEndpoint(row)}>
                                    {row?.endpoint}
                                </Button>
                            ),
                        },
                        {
                            title: 'Updated',
                            field: 'updatedAt',
                            align: 'left',
                            value: (row) =>
                                row?.updatedAt
                                    ? new DateBuilder(row?.updatedAt).dmy()
                                    : '',
                        },
                        {
                            title: 'Notes',
                            field: 'notes',
                            align: 'left',
                        },
                        {
                            title: 'PRE',
                            field: 'activePreproduction',
                            align: 'left',
                            value: (row) =>
                                row?.activePreproduction
                                    ? new DateBuilder(
                                          row?.activePreproduction,
                                      ).dmy()
                                    : '',
                        },
                        {
                            title: 'PRO',
                            field: 'activeProduction',
                            align: 'left',
                            value: (row) =>
                                row?.activeProduction
                                    ? new DateBuilder(
                                          row?.activeProduction,
                                      ).dmy()
                                    : '',
                        },
                        {
                            title: '¿Activo?',
                            value: (row) =>
                                row.active ? (
                                    <CheckCircleOutline
                                        className={`${classes.notificationButton} ${classes.notificationButtonActive}`}
                                        onClick={() =>
                                            updateMutation.mutate({
                                                id: row?.id,
                                                params: {
                                                    active: !row.active,
                                                },
                                            })
                                        }
                                    />
                                ) : (
                                    <HighlightOff
                                        className={`${classes.notificationButton} ${classes.notificationButtonDisabled}`}
                                        onClick={() =>
                                            updateMutation.mutate({
                                                id: row?.id,
                                                params: {
                                                    active: !row.active,
                                                },
                                            })
                                        }
                                    />
                                ),
                        },
                        {
                            title: '¿Swagger?',
                            value: (row) =>
                                row?.swagger ? (
                                    <CheckCircleOutline
                                        className={`${classes.notificationButtonActive}`}
                                    />
                                ) : (
                                    <HighlightOff
                                        className={`${classes.notificationButtonDisabled}`}
                                    />
                                ),
                        },
                    ]}
                />

                {currentEndpoint && (
                    <MigrationEndpoint
                        migrationEndpoint={currentEndpoint}
                        onClose={() => {
                            setCurrentEndpoint(null);
                        }}
                        onUpdate={() => {
                            queryClient.invalidateQueries(
                                SUPERADMIN_MIGRATION_ENDPOINTS,
                            );
                        }}
                    />
                )}
            </Paper>
        </>
    );
}
