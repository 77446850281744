import React, { useState } from 'react';
import { Box, makeStyles, Paper, Typography } from '@material-ui/core';
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined';
import { GolfCourseConfigurationHoleDialog } from './GolfCourseConfigurationHoleDialog';

/**
 * Card that identifies a hole on a golf course.
 * @param hole Hole object.
 * @param onUpdate Launched when golf hole is updated.
 * @returns {JSX.Element}
 */
function GolfHoleCard({ hole, onUpdate }) {
    const classes = useStyles();
    const [selectedGolfHole, setSelectedGolfHole] = useState(null);

    return (
        <>
            <Paper
                className={classes.golfHoleCard}
                onClick={() => setSelectedGolfHole(hole)}
            >
                <Box className={classes.golfHoleBox}>
                    <Typography
                        variant="subtitle1"
                        noWrap
                    >{`Hoyo ${hole?.position}`}</Typography>
                    <Typography
                        variant="caption"
                        noWrap
                    >{`Par ${hole?.par}`}</Typography>
                    <Box className={classes.noteIcon}>
                        {hole?.notes && <NoteOutlinedIcon fontSize="small" />}
                    </Box>
                    <Box
                        className={`${classes.golfCircle} ${
                            hole?.tee
                                ? classes.golfCircleActive
                                : classes.golfCircleInactive
                        }`}
                    >
                        <Typography
                            noWrap
                            style={{
                                fontSize: '10px',
                                color: hole?.tee ? 'white' : 'black',
                            }}
                        >
                            T
                        </Typography>
                    </Box>
                    <Box
                        className={`${classes.golfCircle} ${
                            hole?.status === 'ACTIVE'
                                ? classes.golfCircleActive
                                : classes.golfCircleInactive
                        }`}
                    >
                        <Typography
                            noWrap
                            style={{
                                fontSize: '10px',
                                color:
                                    hole?.status === 'ACTIVE'
                                        ? 'white'
                                        : 'black',
                            }}
                        >
                            H
                        </Typography>
                    </Box>
                </Box>
            </Paper>

            <GolfCourseConfigurationHoleDialog
                golfHole={selectedGolfHole}
                onClose={() => setSelectedGolfHole(null)}
                onUpdate={(data) => {
                    onUpdate(data);
                    setSelectedGolfHole(null);
                }}
            />
        </>
    );
}

const useStyles = makeStyles({
    golfHoleCard: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgba(33, 150, 243, 0.5)',
        },
    },
    golfHoleBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    golfCircle: {
        borderRadius: '50%',
        width: '15px',
        height: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    golfCircleActive: {
        backgroundColor: 'green',
    },
    golfCircleInactive: {
        backgroundColor: '#E0E0E0',
    },
    noteIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default GolfHoleCard;
