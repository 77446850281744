import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Divider, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AppContext, SttButtonAccept } from '../../../sporttia/all';
import { formatPriceByLocale, redirectLogin } from '../../../lib/utils';
import ModalSignUp from './ModalSignup';
import { useCrud } from '../../../lib/hooks';
import { getPath } from '../../Pages';

const useStyles = makeStyles(() => ({
    eventName: {
        fontSize: 18,
        color: '#000',
    },
    catName: {
        fontSize: 16,
        color: '#000',
    },
    catDesc: {
        fontSize: 14,
        color: '#333',
    },
    catPrice: {},
    full: {
        color: 'red',
    },
}));

export default function EventDetails({
    event,
    team,
    onInscription,
    inscriptionDisabled,
}) {
    const history = useHistory();
    const classes = useStyles();
    const cxt = useContext(AppContext);
    const [signupCat, setSignupCat] = useState(null);
    const [, , , Get, Post, ,] = useCrud(undefined, false);
    const [idMship, setIdMship] = useState();

    function saveInscription(inscription) {
        cxt.api('POST', `/events/${event.id}/inscriptions`, {
            params: inscription,
            success: (response) => {
                if (
                    response.tpv ||
                    (response.inscription &&
                        response.inscription.payment &&
                        response.inscription.payment.tpv)
                ) {
                    const tpv =
                        response.tpv ||
                        (response.inscription &&
                            response.inscription.payment &&
                            response.inscription.payment.tpv);

                    history.push(getPath('tpvSwitcher', { id: tpv.id }));
                } else if (onInscription) {
                    onInscription();
                }

                setSignupCat(null);
            },
            error: (response) => {
                if (response.error) {
                    cxt.showMessage('E', response.error.msg);
                }
            },
        });
    }

    function addToTeam(idTeam, participants) {
        cxt.api('POST', '/batch', {
            params: {
                calls: participants.map((participant) => ({
                    method: 'POST',
                    endpoint: `/events/categories/${signupCat.id}/participants`,
                    data: { ...participant, idTeam },
                })),
            },
            success: () => {
                if (onInscription) {
                    onInscription();
                }
                setSignupCat(null);
            },
            error: (e) => {
                cxt.showMessage('E', e?.error?.msg);
                setSignupCat(null);
            },
        });
    }

    function saveTeam(teamInscription) {
        if (
            !teamInscription.participants ||
            teamInscription.participants.length === 0 ||
            !signupCat
        ) {
            return;
        }

        /* LA FORMA BUENA: No vale de momento porque "Call to undefined method APIEvent::addTeam() /home/sporttia/webs/sporttia-apis/pre/versions/v6/index.php [38]"
		if(!teamInscription.idTeam) {
			//crear equipo antes (?)
			Post(`/events/categories/${signupCat.id}/teams`, {name: teamInscription.teamName}).then(result => {
				if(result.team) {
					const {idTeam} = result.team;
					addToTeam(idTeam, teamInscription.participants);
				}
			}).catch(() => {})
		} else {
			addToTeam(teamInscription.idTeam, teamInscription.participants);
		}
		*/

        // console.log(teamInscription); return;

        // Chapuza: me inscribo yo primero y luego uso el idTeam generado para inscribir a los demás
        if (!teamInscription.idTeam) {
            const participants = [...teamInscription.participants];
            const me = participants.splice(
                participants.findIndex((p) => p.admin),
                1,
            );
            const { paymentForm, teamName } = teamInscription;

            Post(`/events/categories/${signupCat.id}/participants`, {
                ...me[0],
                paymentForm,
                teamName,
            })
                .then((response) => {
                    if (response.participant && response.participant.team) {
                        // Bug: si inscribo a más de uno a la vez, nunca entraremos aquí y por tanto no habrá pago con TPV
                        if (participants.length === 0) {
                            if (
                                response.tpv ||
                                (response.participant &&
                                    response.participant.enrollment &&
                                    response.participant.enrollment.tpv)
                            ) {
                                const tpv =
                                    response.tpv ||
                                    (response.participant &&
                                        response.participant.enrollment &&
                                        response.participant.enrollment.tpv);

                                history.push(
                                    getPath('tpvSwitcher', { id: tpv.id }),
                                );
                            } else {
                                if (onInscription) {
                                    onInscription();
                                }
                                setSignupCat(null);
                            }
                        } else {
                            addToTeam(
                                response.participant.team.id,
                                participants,
                            );
                        }
                    }
                })
                .catch((response) => {
                    if (response.error) {
                        cxt.showMessage('E', response.error.msg);
                    }
                });
        } else {
            addToTeam(teamInscription.idTeam, teamInscription.participants);
        }
    }

    function loadMe() {
        Get(`/scs/${event.sc.id}/me`)
            .then((response) => {
                if (response.mship) {
                    setIdMship(response.mship.id);
                }
            })
            .catch(() => {});
    }

    useEffect(() => {
        if (
            cxt.logged &&
            cxt.user.role !== 'SPORTCENTER' &&
            event &&
            event.sc
        ) {
            loadMe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event]);

    if (!event) {
        return null;
    }

    const eventIsFull = event.teamable
        ? false
        : event.maxParticipants !== 0 &&
          event.numParticipants >= event.maxParticipants;

    return (
        <Box>
            <Box mb={3}>
                <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: event.description }}
                    className={classes.eventName}
                />
            </Box>
            <Box mb={3}>
                {/* MAX PARTICIPANTS */}
                {event.teamable ? (
                    <Typography variant="body1">{`${cxt.t('Teams')}: ${
                        event.numTeams
                    }`}</Typography>
                ) : (
                    <Typography
                        variant="body1"
                        className={eventIsFull ? classes.full : undefined}
                    >{`${cxt.t('Participants')}: ${event.numParticipants}${
                        event.maxParticipants
                            ? ` / ${event.maxParticipants}`
                            : ''
                    }`}</Typography>
                )}
            </Box>
            <Box pt={3}>
                {/* LIST OF CATEGORIES */}
                {event.categories &&
                    event.categories.map((category, index) => {
                        const categoryIsFull = event.teamable
                            ? category.maxTeams !== 0 &&
                              category.numTeams >= category.maxTeams
                            : category.maxParticipants !== 0 &&
                              category.numParticipants >=
                                  category.maxParticipants;
                        const cur = event.teamable
                            ? category.numTeams
                            : category.numParticipants;
                        const max = event.teamable
                            ? category.maxTeams
                            : category.maxParticipants;

                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={index}>
                                {index > 0 && (
                                    <Box mt={3} mb={2}>
                                        <Divider />
                                    </Box>
                                )}
                                <Box p={1}>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Typography className={classes.catName}>
                                            {category.name}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            className={
                                                categoryIsFull
                                                    ? classes.full
                                                    : undefined
                                            }
                                        >
                                            {`${cur}${
                                                max !== 0 ? ` / ${max}` : ''
                                            } ${
                                                event.teamable
                                                    ? cxt
                                                          .t('Teams')
                                                          .toLowerCase()
                                                    : cxt
                                                          .t('Participants')
                                                          .toLowerCase()
                                            }`}
                                        </Typography>
                                    </Box>
                                    {category.description && (
                                        <Box p={1}>
                                            <Typography
                                                className={classes.catDesc}
                                            >
                                                {category.description}
                                            </Typography>
                                        </Box>
                                    )}
                                    <Box
                                        mt={3}
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography variant="body1">{`${cxt.t(
                                            'Inscription',
                                        )}: ${
                                            category.enrollment > 0
                                                ? formatPriceByLocale(
                                                      category?.enrollment,
                                                      category?.enrollmentShape
                                                          ?.currency,
                                                      category?.enrollmentShape
                                                          ?.locale,
                                                  )
                                                : cxt.t('Free')
                                        }`}</Typography>
                                        {event.signupOnline && (
                                            <SttButtonAccept
                                                disabled={
                                                    inscriptionDisabled ||
                                                    eventIsFull ||
                                                    categoryIsFull
                                                }
                                                caption={cxt.t('SignUp')}
                                                onClick={() =>
                                                    cxt.logged
                                                        ? setSignupCat(category)
                                                        : redirectLogin(history)
                                                }
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </div>
                        );
                    })}
            </Box>
            {signupCat && (
                <ModalSignUp
                    event={event}
                    team={team}
                    category={signupCat}
                    onSaveTeam={saveTeam}
                    onSaveInscription={saveInscription}
                    onClose={() => setSignupCat(null)}
                    idMship={idMship}
                />
            )}
        </Box>
    );
}
