import Link from '@material-ui/core/Link';
import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { AppContext } from './AppContext';
import constants from '../config/constants';

function SttAppStoresLogos() {
    const cxt = useContext(AppContext)!;

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
        >
            <Grid item>
                <Link href={constants.appStores.play.link}>
                    <img
                        src={cxt.getResource('/public/images/app.png')}
                        alt="Sporttia Android App"
                        width={130.28}
                        height={45.45}
                    />
                </Link>
            </Grid>
            <Grid item>
                <Link href={constants.appStores.apple.link}>
                    <img
                        src={cxt.getResource('/public/images/appStoreIOS.png')}
                        alt="Sporttia App Store"
                        width={130.28}
                        height={45}
                    />
                </Link>
            </Grid>
        </Grid>
    );
}

export default SttAppStoresLogos;
