import {
    DiscoverResult,
    Terminal,
    loadStripeTerminal,
} from '@stripe/terminal-js';
import { useEffect, useRef, useState } from 'react';
import useTpvsService from '../../services/TpvsService';
import generalConfig from '../../config/general';

type Status = 'success' | 'loading' | 'error' | 'idle';

type UseStripeTerminalOptions = {
    onConnection?: () => void;
    onError?: (error?: unknown) => void;
    onDisconnect?: () => void;
};

/*
 * Hook that initializes the Stripe Terminal for a specific location.
 */
export const useGetStripeTerminal = (
    scId: number | undefined,
    options: UseStripeTerminalOptions = {},
) => {
    const [terminal, setTerminal] = useState<Terminal>();
    const [status, setStatus] = useState<Status | 'connected' | 'disconnected'>(
        'idle',
    );
    const optionsRef = useRef(options);
    optionsRef.current = options;

    const tpvService = useTpvsService();

    const { mutateAsync: createTpvReaderConnectionMutateAsync } =
        tpvService.useCreateTpvReaderConnection({
            onError: (error) => {
                setStatus('error');
                optionsRef.current.onError?.(error);
            },
        });

    useEffect(() => {
        const loadTerminal = async () => {
            if (!scId) {
                return;
            }

            setStatus('loading');

            const stripeTerminal = await loadStripeTerminal();

            if (!stripeTerminal) {
                optionsRef.current.onError?.();
                setStatus('error');
                return;
            }

            // Esta callback no se ejecutará ahora sino cuando se utilize la terminal de Stripe
            const useConnectionToken = async () => {
                setStatus('loading');

                const response = await createTpvReaderConnectionMutateAsync({
                    scId,
                });

                setStatus('connected');
                optionsRef.current.onConnection?.();

                return response.connectionToken;
            };

            const useUnexpectedReaderDisconnect = () => {
                setStatus('disconnected');
                optionsRef.current.onDisconnect?.();
            };

            const terminalResult = stripeTerminal.create({
                onFetchConnectionToken: useConnectionToken,
                onUnexpectedReaderDisconnect: useUnexpectedReaderDisconnect,
            });

            setTerminal(terminalResult);
            setStatus('success');
        };

        loadTerminal();
    }, [createTpvReaderConnectionMutateAsync, scId]);

    return { terminal, status };
};

type UseGetStripeReadersOptions = {
    onSuccess?: (
        readers: DiscoverResult['discoveredReaders'],
    ) => void | Promise<void>;
    onError?: (error: unknown) => void;
};

/*
 * Hook that retrieves available Stripe card readers for a specific location.
 */
export const useGetStripeReaders = (
    terminal: Terminal | undefined,
    locationId: string | undefined,
    options: UseGetStripeReadersOptions = {},
) => {
    const [status, setStatus] = useState<Status>('idle');
    const optionsRef = useRef(options);
    optionsRef.current = options;

    useEffect(() => {
        const loadReaders = async () => {
            if (!terminal || !locationId) {
                return;
            }

            setStatus('loading');

            const discoveredReadersResult = await terminal.discoverReaders({
                simulated: generalConfig.testStripeTerminal.isEnabled,
                location: locationId,
            });

            if ('error' in discoveredReadersResult) {
                optionsRef.current.onError?.(discoveredReadersResult.error);
                setStatus('error');
            } else {
                const { discoveredReaders } = discoveredReadersResult;
                optionsRef.current.onSuccess?.(discoveredReaders);
                setStatus('success');
            }
        };

        loadReaders();
    }, [locationId, terminal]);

    return { status };
};

type UseCollectStripePaymentOptions = {
    onSuccess?: () => void;
    onCancel?: () => void;
    onError?: (error: unknown) => void;
    enabled?: boolean;
};

type UseCollectStripePaymentProps = {
    terminal: Terminal | undefined;
    paymentAmount: number;
    stripeConnectAccountId: string | undefined;
    tpvId: number;
    options?: UseCollectStripePaymentOptions;
};

/**
 * Custom React hook for collecting Stripe payments using the Stripe Terminal SDK.
 */
export const useCollectStripePayment = ({
    terminal,
    paymentAmount,
    stripeConnectAccountId,
    tpvId,
    options = { enabled: true },
}: UseCollectStripePaymentProps) => {
    const [paymentStatus, setPaymentStatus] = useState<
        Status | 'waiting' | 'canceled'
    >('idle');
    const optionsRef = useRef(options);
    optionsRef.current = options;

    const tpvsService = useTpvsService();

    const getTpvQuery = tpvsService.useGetTpv(
        tpvId,
        {
            paymentAmount,
            stripeConnectAccountId,
        },
        {
            enabled: options.enabled && !!stripeConnectAccountId && !!terminal,
            onError: options.onError,
        },
    );

    const handleCollectPaymentError = (message: string, error: unknown) => {
        // eslint-disable-next-line no-console
        console.error(message, error);
        setPaymentStatus('error');
        optionsRef.current.onError?.(error);
    };

    const status = !getTpvQuery.isSuccess ? getTpvQuery.status : paymentStatus;

    useEffect(() => {
        const collectPayment = async () => {
            if (!getTpvQuery.isSuccess || !terminal) {
                return;
            }

            if (generalConfig.testStripeTerminal.isEnabled) {
                terminal.setSimulatorConfiguration({
                    testCardNumber: generalConfig.testStripeTerminal.testCard,
                });
            }

            setPaymentStatus('waiting');

            const collectedMethod = await terminal.collectPaymentMethod(
                getTpvQuery.data.stripeClientSecret,
            );

            if ('error' in collectedMethod) {
                if (collectedMethod.error.code === 'canceled') {
                    setPaymentStatus('canceled');
                    optionsRef.current.onCancel?.();
                    return;
                }

                handleCollectPaymentError(
                    'Error on collect payment method',
                    collectedMethod.error,
                );
                return;
            }

            setPaymentStatus('loading');

            const paymentResult = await terminal.processPayment(
                collectedMethod.paymentIntent,
            );

            if ('error' in paymentResult) {
                handleCollectPaymentError(
                    'Error on payment result. Handle processing failures: https://stripe.com/docs/terminal/payments/collect-payment?terminal-sdk-platform=js#handling-processing-failures',
                    paymentResult.error,
                );
                return;
            }

            setPaymentStatus('success');
            optionsRef.current.onSuccess?.();
        };

        collectPayment();
        return () => {
            // PaymentStatus can be one of not_ready, ready, waiting_for_input, or processing.
            if (terminal?.getPaymentStatus() === 'waiting_for_input') {
                terminal.cancelCollectPaymentMethod();
            }
        };
    }, [getTpvQuery.data?.stripeClientSecret, getTpvQuery.isSuccess, terminal]);

    return { status };
};
