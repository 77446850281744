import React, { useState, useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import { makeStyles } from '@material-ui/core';
import {
    AppContext,
    SttPrivilegeList,
    SttFormSwitch,
} from '../../../sporttia/all';
import useScsService from '../../../services/ScsService';
import { SPORTCENTER_CONFIGURATION_MANDATORYDATA } from '../../../lib/queryKeys';

const useStyles = makeStyles({
    highlight: {
        paddingBottom: '5px',
        borderBottom: '2px #ddd solid',
    },
    maxWidthList: {
        maxWidth: '50%',
    },
});

export default function ConfigurationMandatoryData() {
    const cxt = useContext(AppContext);
    const [sc, setSC] = useState(cxt?.sc);
    const scsService = useScsService();
    const classes = useStyles();
    // const queryClient = useQueryClient();

    useQuery(
        [SPORTCENTER_CONFIGURATION_MANDATORYDATA, { id: sc?.id }],
        () =>
            scsService.get(sc?.id).then((response) => {
                setSC(response?.sc);
            }),
        { enabled: !!sc?.id },
    );

    const mandatoryDataMutation = useMutation(
        (params) => scsService.updateScsField(cxt?.sc?.id, params),
        {
            onSuccess: (data) => {
                setSC(data.sc);
            },
            onError: (err) => cxt.showMessage('E', err?.error),
        },
    );

    // Adapt data format to expected fields for STTPrivilegeList component reusability
    const zip = (a, b) =>
        a.map((aField, aIndexField) =>
            Object.create({
                id: aField,
                name: b[aIndexField],
                description: null,
                active: sc[aField],
            }),
        );

    const userFields = [
        'userNameMandatory',
        'userSurname1Mandatory',
        'userSurname2Mandatory',
        'userBirthdayMandatory',
        'userPhoneMandatory',
        'userEmailMandatory',
        'userDniMandatory',
        'userAddressMandatory',
        'userPostalCodeMandatory',
        'userCityMandatory',
        'userIbanMandatory',
    ];

    const userFieldsTranslation = [
        'username',
        'surname1',
        'surname2',
        'birthday',
        'phone',
        'email',
        'dni',
        'address',
        'postalcode',
        'city',
        'IBAN',
    ];

    const mandatoryFields = zip(userFields, userFieldsTranslation);

    // -----| Render |-----
    return (
        <>
            <SttFormSwitch
                color="primary"
                caption={cxt.t('autoUserDataSaving')}
                name="updatePursesOnCascadeAllowed"
                checked={sc.updatePursesOnCascadeAllowed || false}
                onChange={({ name, value }) =>
                    mandatoryDataMutation.mutate({ [name]: value })
                }
            />
            <SttPrivilegeList
                privileges={mandatoryFields}
                onToggle={(data) =>
                    mandatoryDataMutation.mutate({ [data.id]: data.value })
                }
                title={cxt.t('Data')}
                useHighlight
                classes={classes}
            />
        </>
    );
}
/*

*/
