import useRestService from './RestService';

const resource = '/translations';

/**
 * Hook to access all 'translations' resources.
 */
export default function useTranslationsService() {
    const services = useRestService(resource);

    return {
        ...services,
    };
}
