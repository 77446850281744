import React, { useState, useEffect, useContext } from 'react';
import { Paper } from '@material-ui/core';
import moment from 'moment';
import {
    AppContext,
    SttButtonFab,
    SttChipEventKind,
    SttChipSport,
    SttTable,
    SttTopControls,
} from '../../../sporttia/all';
import { useToggle, useTuples, useCrud } from '../../../lib/hooks';
import Event from './Event';

export default function Events() {
    const cxt = useContext(AppContext);

    const [events, setEvents, , Get] = useCrud();
    const [event, setEvent] = useState({ id: null, trash: false });
    const [filters, setFilter] = useTuples({
        name: '',
        year: moment().year(),
        status: 'ACTIVE',
    });
    const [trash, toggleTrash] = useToggle(() => {
        loadEvents();
    });

    useEffect(() => {
        cxt.setHeader({ title: cxt.t('Events') });
    }, []);

    function loadEvents(params = { rows: 20 }) {
        if (filters?.status == 'ALL') {
            filters.status = '';
        }
        Get(`/scs/${cxt.sc.id}/events`, { ...params, ...filters, trash }).then(
            setEvents,
        );
        if (filters?.status == '') {
            filters.status = 'ALL';
        }
    }

    function goCreateNew() {
        setEvent({
            id: 'create',
            trash: 0,
        });
    }

    return (
        <Paper>
            <SttTopControls
                p={2}
                fields={[
                    {
                        caption: cxt.t('Name'),
                        type: 'text',
                        name: 'name',
                        value: filters.name,
                        onEnterKey: loadEvents,
                    },
                ]}
                extraFields={[
                    {
                        caption: cxt.t('Year'),
                        type: 'year',
                        name: 'year',
                        value: filters.year,
                        years: {
                            start: moment().year(),
                            past: 5,
                            future: 5,
                        },
                    },
                    {
                        caption: cxt.t('Active'),
                        type: 'select',
                        name: 'status',
                        value: filters.status,
                        options: [
                            {
                                label: cxt.t('All'),
                                value: 'ALL',
                            },
                            {
                                label: cxt.t('Active'),
                                value: 'ACTIVE',
                            },
                            {
                                label: cxt.t('Inactive'),
                                value: 'NOT_ACTIVE',
                            },
                        ],
                    },
                ]}
                onToggleTrash={toggleTrash}
                onChange={setFilter}
                onFilter={loadEvents}
            />
            <SttTable
                onFetch={loadEvents}
                data={events}
                columns={[
                    {
                        title: cxt.t('Name'),
                        type: 'link',
                        field: 'name',
                        align: 'left',
                        onClick: (row) =>
                            setEvent({
                                id: row.id,
                                trash: row.trash,
                            }),
                    },
                    {
                        title: cxt.t('Type'),
                        value: (row) => <SttChipEventKind kind={row.kind} />,
                    },
                    {
                        title: cxt.t('Active'),
                        type: 'binaryStatus',
                        value: (row) => row.status === 'ACTIVE',
                    },
                    {
                        title: cxt.t('Sport'),
                        value: (row) => <SttChipSport sport={row.sport} />,
                    },
                    {
                        title: cxt.t('Timetables'),
                        type: 'nonZero',
                        field: 'numPeriods',
                    },
                    {
                        title: cxt.t('Teams'),
                        type: 'text',
                        field: 'numTeams',
                    },
                    {
                        title: cxt.t('Inscription'),
                        type: 'period',
                        value: (row) => ({
                            ini: row.inscIni,
                            end: row.inscEnd,
                        }),
                    },
                    {
                        title: cxt.t('Users'),
                        type: 'text',
                        field: 'numParticipants',
                    },
                    {
                        title: cxt.t('Period'),
                        type: 'period',
                        value: (row) => ({ ini: row.ini, end: row.end }),
                    },
                    {
                        title: cxt.t('Total'),
                        type: 'price',
                        field: 'total',
                        align: 'right',
                    },
                    {
                        title: cxt.t('Debt'),
                        type: 'debt',
                        field: 'debt',
                        align: 'right',
                    },
                ]}
            />
            <SttButtonFab onClick={goCreateNew} />

            <Event
                id={event.id}
                trash={event.trash}
                // apply changes locally, remove items with 'trash'
                onChange={(newEvent) => {
                    setEvents({
                        ...events,
                        rows: events.rows
                            .map((event) =>
                                event.id === newEvent.id ? newEvent : event,
                            )
                            .filter(
                                (event) => !!trash || (!trash && !event.trash),
                            ),
                    });
                }}
                onClose={() => {
                    setEvent({ id: null, trash: false });
                    loadEvents();
                }}
            />
        </Paper>
    );
}
