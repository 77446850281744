import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { SttSelectMship } from '../../sporttia/selectors/SttSelectMship';
import { AppContext } from '../../sporttia/AppContext';
import { SttDialog } from '../../sporttia/dialogs/SttDialog';

/**
 * Dialog that allow to sport center admin consume a ticket in the name of chosen user.
 * @param open
 * @param defaultUser
 * @param onClose
 * @returns {JSX.Element}
 */
export default function UseBonoDialog({
    open,
    defaultMship,
    bookingId,
    onClose,
}) {
    const cxt = useContext(AppContext);
    const [mshipChosen, setMshipChosen] = useState();
    const [userIndividualBonos, setUserIndividualBonos] = useState([]);
    const [userFullBonos, setUserFullBonos] = useState([]);
    const [selectedBono, setSelectedBono] = useState({
        id: null,
    });

    /**
     * If "defaultUser" value was changed, "userChosen" value will be update.
     */
    useEffect(() => {
        if (defaultMship) setMshipChosen(defaultMship);
    }, [defaultMship]);

    /**
     * If "mshipChosen" value was changed, "userBoletos" value will be update loading boletos with loadBoletos().
     */
    useEffect(() => {
        if (open) {
            if (mshipChosen) loadBonos();
            else {
                setUserFullBonos([]);
                setUserIndividualBonos([]);
            }
        }
    }, [mshipChosen]);

    /**
     * If is possible always will be reload bonus data when open dialog.
     */
    useEffect(() => {
        if (open) loadBonos();
    }, [open]);

    /**
     * Load boletos associated to user, facility, booking initial datetime and booking end datetime.
     */
    const loadBonos = () => {
        if (
            ((mshipChosen && mshipChosen.user && mshipChosen.user.id) ||
                (mshipChosen && mshipChosen.id)) &&
            bookingId
        ) {
            cxt.api('GET', `/bookings/${bookingId}`, {
                params: { trash: true },
                success: (r) => {
                    cxt.api('GET', `/bookings/fares`, {
                        params: {
                            idFacility: r.booking.facility.id,
                            ini: r.booking.ini,
                            end: r.booking.end,
                            idUser: mshipChosen.user
                                ? mshipChosen.user.id
                                : mshipChosen.id,
                        },
                        success: (response) => {
                            if (response.individualBonos)
                                setUserIndividualBonos(
                                    response.individualBonos,
                                );
                            if (response.fullBonos)
                                setUserFullBonos(response.fullBonos);
                        },
                    });
                },
            });
        }
    };

    /**
     * Use selected bono by user.
     */
    const useBono = () => {
        cxt.api('POST', `/bonos/boletos/${selectedBono.id}/movs`, {
            params: {
                idBooking: bookingId,
            },
            success: () => {
                cxt.showMessage('S', cxt.t('ConsumedBono'));
                onClose();
                setSelectedBono({ id: null });
            },
        });
    };

    // -----| Render |-----
    return (
        <SttDialog
            title={cxt.t('UseFare')}
            open={open}
            onClose={onClose}
            content={
                <Grid container spacing={3}>
                    {/* Mship selector. */}
                    <SttSelectMship
                        mship={mshipChosen}
                        onSelect={(mship) => setMshipChosen(mship)}
                    />

                    {/* User bonos list. */}
                    <Grid item xs={12}>
                        {userIndividualBonos.length > 0 ||
                        userFullBonos.length > 0 ? (
                            <Paper>
                                <List
                                    component="nav"
                                    aria-label="secondary mailbox folders"
                                >
                                    {userIndividualBonos.map(
                                        (indvBono) =>
                                            indvBono.consumed !==
                                                indvBono.amount && (
                                                <ListItem
                                                    button
                                                    selected={
                                                        selectedBono.id ===
                                                        indvBono.id
                                                    }
                                                    onClick={() =>
                                                        setSelectedBono(
                                                            indvBono,
                                                        )
                                                    }
                                                    key={indvBono.id}
                                                >
                                                    <ListItemText
                                                        primary={`${indvBono.bono.name} [${indvBono.consumed}/${indvBono.amount}]`}
                                                    />
                                                </ListItem>
                                            ),
                                    )}
                                    {userFullBonos.map(
                                        (fullBono) =>
                                            fullBono.consumed !==
                                                fullBono.amount && (
                                                <ListItem
                                                    button
                                                    selected={
                                                        selectedBono.id ===
                                                        fullBono.id
                                                    }
                                                    onClick={() =>
                                                        setSelectedBono(
                                                            fullBono,
                                                        )
                                                    }
                                                    key={fullBono.id}
                                                >
                                                    <ListItemText
                                                        primary={`${fullBono.bono.name} [${fullBono.consumed}/${fullBono.amount}]`}
                                                    />
                                                </ListItem>
                                            ),
                                    )}
                                </List>
                            </Paper>
                        ) : (
                            <Paper>
                                <Box p={2}>{cxt.t('UnavailableUserBonos')}</Box>
                            </Paper>
                        )}
                    </Grid>
                </Grid>
            }
            buttons={[
                {
                    show: selectedBono.id !== null,
                    caption: cxt.t('Consume'),
                    type: 'accept',
                    onClick: useBono,
                },
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
        />
    );
}
