import React from 'react';
import { usePageHeader } from '../../../../lib/hooks';
import Report from '../../../sc/Report';

/**
 * View where the sporttia superadmin can see the list of push notifications.
 * @returns {JSX.Element}
 */
function AdminPushNotifications({}) {
    usePageHeader('pushNotifications');

    return <Report id={31} navigateBack="/home" />;
}

export default AdminPushNotifications;
