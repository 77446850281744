import React from 'react';
import DateBuilder from '../../lib/DateBuilder';

/**
 * Label date
 */
export function SttLabelDate({
    date,
    timezoneName = 'Europe/Madrid',
    locale = null,
}) {
    return <>{new DateBuilder(date, timezoneName, locale).format('DMY')}</>;
}
