import { useContext } from 'react';
import {
    MutationFunction,
    UseMutationOptions,
    UseQueryOptions,
    useMutation,
    useQuery,
} from 'react-query';
import { useAPI } from '../lib/hooks';
import { AppContext } from '../sporttia/AppContext';
import useRestService from './RestService';
import {
    CreateScRate200,
    CreateScRateBody,
    CreateScsDiscount200,
    CreateScsDiscountBody,
    GetScTpvConfs200,
    GetSc200,
    GetScParams,
    GetScsDiscounts200,
    GetScsDiscountsParams,
    GetScsGroups200,
    GetScsGroupsParams,
    UpdateScRate200,
    UpdateScRateBody,
    GetScTpvConfsParams,
    GetScMe200,
    GetScMeParams,
    GetScsDeviceSpaceParams,
    GetScsDeviceSpaces200,
} from '../types/api/paths/Scs';
import { SPORTCENTER_PRIVILEGES_QUERY_KEY } from '../lib/queryKeys';

const resource = '/scs';

export const getGetScQueryKey = (id: number, params?: GetScParams) => [
    `${resource}/${id}`,
    ...(params ? [params] : []),
];

export const getGetScMeQueryKey = (id: number, params?: GetScMeParams) => [
    `${resource}/${id}/me`,
    ...(params ? [params] : []),
];

export const getGetScsGroupsQueryKey = (
    id: number,
    params?: GetScsGroupsParams,
) => [`${resource}/${id}/groups`, ...(params ? [params] : [])];

export const getGetScsDiscountsQueryKey = (
    id: number,
    params?: GetScsDiscountsParams,
) => [`${resource}/${id}/discounts`, ...(params ? [params] : [])];

export const getGetScTpvConfsQueryKey = (
    id: number,
    params?: GetScTpvConfsParams,
) => [`${resource}/${id}/tpvs/configurations`, ...(params ? [params] : [])];

/**
 * Hook to access all 'scs' resources.
 */
export default function useScsService() {
    const { migrationApis } = useContext(AppContext)!;
    const [request] = useAPI(migrationApis);
    const services = useRestService(resource);

    /**
     * GET /scs/:id request.
     */
    function useGetSc<TData = GetSc200>(
        id: number,
        params?: GetScParams,
        queryOptions?: UseQueryOptions<GetSc200, unknown, TData>,
    ) {
        const queryKey = queryOptions?.queryKey ?? getGetScQueryKey(id, params);

        const queryFn = () =>
            request<GetSc200>('GET', `${resource}/${id}`, params);

        const query = useQuery(queryKey, queryFn, queryOptions);

        return {
            ...query,
            queryKey,
        };
    }

    /**
     * GET /scs/:id/me request.
     */
    function useGetScMe<TData = GetScMe200>(
        id: number,
        params?: GetScMeParams,
        queryOptions?: UseQueryOptions<GetScMe200, unknown, TData>,
    ) {
        const queryKey =
            queryOptions?.queryKey ?? getGetScMeQueryKey(id, params);

        const queryFn = () =>
            request<GetScMe200>('GET', `${resource}/${id}/me`, params);

        const query = useQuery(queryKey, queryFn, queryOptions);

        return {
            ...query,
            queryKey,
        };
    }

    /**
     * GET /scs/:id/groups request.
     */
    function useGetScsGroups<TData = GetScsGroups200>(
        id: number,
        params?: GetScsGroupsParams,
        queryOptions?: UseQueryOptions<GetScsGroups200, unknown, TData>,
    ) {
        const queryKey =
            queryOptions?.queryKey ?? getGetScsGroupsQueryKey(id, params);

        const queryFn = () =>
            request<GetScsGroups200>('GET', `${resource}/${id}/groups`, params);

        const query = useQuery(queryKey, queryFn, queryOptions);

        return {
            ...query,
            queryKey,
        };
    }

    /**
     * GET /scs/:id/devices/spaces request.
     */
    function useGetScsDevicesCapacities<TData = GetScsDeviceSpaces200>(
        id: number,
        params?: GetScsDeviceSpaceParams,
        queryOptions?: UseQueryOptions<GetScsDeviceSpaces200, unknown, TData>,
    ) {
        const queryKey = queryOptions?.queryKey ?? [
            `${resource}/${id}/devices/spaces`,
            ...(params ? [params] : []),
        ];

        const queryFn = () =>
            request<GetScsDeviceSpaces200>(
                'GET',
                `${resource}/${id}/devices/spaces`,
                params,
            );

        const query = useQuery(queryKey, queryFn, queryOptions);

        return {
            ...query,
            queryKey,
        };
    }

    /**
     * GET /scs/:id/mships.pdf request.
     */
    function getScsMshipsPDF(id: number, params: Record<string, unknown>) {
        return request('GET', `${resource}/${id}/mships.pdf`, params);
    }

    /**
     * GET /scs/:id/mships.csv request.
     */
    function getScsMshipsCSV(id: number, params: Record<string, unknown>) {
        return request('GET', `${resource}/${id}/mships.csv`, params);
    }

    /**
     * PUT /scs/:id/recover request.
     */
    function updateScsRecover(id: number) {
        return request('PUT', `${resource}/${id}/recover`);
    }

    /**
     * GET /scs/:id/devices request.
     */
    function getScsDevices(id: number, params: Record<string, unknown>) {
        return request('GET', `${resource}/${id}/devices`, params);
    }

    /**
     * GET /scs/:id/devices request.
     * Añadida funcion extra porque hay prisa y necesito que los parametros se reciban como un objeto TODO: adaptar la otra
     */
    function getScsDevices2({
        id,
        params,
    }: {
        id: number;
        params: Record<string, unknown>;
    }) {
        return request('GET', `${resource}/${id}/devices`, params);
    }
    /**
     * POST /scs/:id/devices request.
     */
    function createScsDevice(id: number, params: Record<string, unknown>) {
        return request('POST', `${resource}/${id}/devices`, params);
    }

    /**
     * GET /scs/:id/facilities request.
     */
    function getScsFacilities(id: number, params: Record<string, unknown>) {
        return request('GET', `${resource}/${id}/facilities`, params);
    }

    /**
     * POST /scs/:id/cores/triggers/:id request.
     */
    function createScsCoreTrigger(
        id: number,
        triggerId: number,
        params: Record<string, unknown>,
    ) {
        return request(
            'POST',
            `${resource}/${id}/cores/triggers/${triggerId}`,
            params,
        );
    }

    /**
     * DELETE /scs/:id/cores/triggers/:id request.
     */
    function removeScsCoreTrigger(id: number, triggerId: number) {
        return request(
            'DELETE',
            `${resource}/${id}/cores/triggers/${triggerId}`,
        );
    }

    /**
     * POST /scs/:id/remittences?:queryString request.
     */
    function createScsRemittences(id: number, params: Record<string, unknown>) {
        return request('POST', `${resource}/${id}/remittences`, params);
    }

    /**
     * POST /scs/:id/golf/courses request.
     */
    function createScsGolfCourse(id: number, params: Record<string, unknown>) {
        return request('POST', `${resource}/${id}/golf/courses`, params);
    }

    /**
     * GET /scs/:id/golf/courses request.
     */
    function getScsGolfCourses(id: number, params: Record<string, unknown>) {
        return request('GET', `${resource}/${id}/golf/courses`, params);
    }

    /**
     * PUT /scs/:id/follow request.
     */
    function updateScsFollow(id: number) {
        return request('PUT', `${resource}/${id}/follow`);
    }

    /**
     * PUT /scs/:id/unfollow request.
     */
    function updateScsUnfollow(id: number) {
        return request('PUT', `${resource}/${id}/unfollow`);
    }

    function updateScsField(id: number, params: Record<string, unknown>) {
        return request('PUT', `${resource}/${id}`, params);
    }

    /**
     * GET /scs/:id/mships request.
     */
    function getScsMships(id: number, params: Record<string, unknown>) {
        return request('GET', `${resource}/${id}/mships`, params);
    }

    /**
     * GET /scs/:id/frees/schedules request.
     */
    function getFreeSchedules(id: number, params: Record<string, unknown>) {
        return request('GET', `${resource}/${id}/frees/schedules`, params);
    }

    /**
     * GET /scs/:id/teachers request.
     */
    function getTeachers(id: number, params: Record<string, unknown>) {
        return request('GET', `${resource}/${id}/teachers`, params);
    }

    /**
     * GET /scs/:id/activities request.
     */
    function getActivities(id: number, params: Record<string, unknown>) {
        return request('GET', `${resource}/${id}/activities`, params);
    }

    /**
     * GET /scs/:id/discounts request.
     */
    function getScsDiscounts(id: number, params: GetScsDiscountsParams) {
        return request<GetScsDiscounts200>(
            'GET',
            `${resource}/${id}/discounts`,
            params,
        );
    }

    /**
     * GET /scs/:id/discounts request.
     */
    function useGetScsDiscounts<TData = GetScsDiscounts200>(
        id: number,
        params?: GetScsDiscountsParams,
        queryOptions?: UseQueryOptions<GetScsDiscounts200, unknown, TData>,
    ) {
        const queryKey =
            queryOptions?.queryKey ?? getGetScsDiscountsQueryKey(id, params);

        const queryFn = () =>
            request<GetScsDiscounts200>(
                'GET',
                `${resource}/${id}/discounts`,
                params,
            );

        const query = useQuery(queryKey, queryFn, queryOptions);

        return {
            ...query,
            queryKey,
        };
    }

    /**
     * POST scs/:id/discounts request.
     */
    function useCreateDiscount(
        mutationOptions?: UseMutationOptions<
            CreateScsDiscount200,
            unknown,
            { id: number; params: CreateScsDiscountBody }
        >,
    ) {
        const mutationFn: MutationFunction<
            CreateScsDiscount200,
            { id: number; params: CreateScsDiscountBody }
        > = ({ id, params }) =>
            request('POST', `${resource}/${id}/discounts`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * PUT /scs/rates/:rateId request.
     */
    function useUpdateScRate(
        mutationOptions?: UseMutationOptions<
            UpdateScRate200,
            unknown,
            { rateId: number; params: UpdateScRateBody }
        >,
    ) {
        const mutationFn: MutationFunction<
            UpdateScRate200,
            { rateId: number; params: UpdateScRateBody }
        > = ({ rateId, params }) =>
            request('PUT', `/scs/rates/${rateId}`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * POST /scs/:scId/rates request.
     */
    function useCreateScRate(
        mutationOptions?: UseMutationOptions<
            CreateScRate200,
            unknown,
            { scId: number; params: CreateScRateBody }
        >,
    ) {
        const mutationFn: MutationFunction<
            CreateScRate200,
            { scId: number; params: CreateScRateBody }
        > = ({ scId, params }) => request('POST', `/scs/${scId}/rates`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * GET /scs/:id/tpvs/configurations
     */
    function useGetScTpvConfs<TData = GetScTpvConfs200>(
        id: number,
        params?: GetScTpvConfsParams,
        queryOptions?: UseQueryOptions<GetScTpvConfs200, unknown, TData>,
    ) {
        const queryKey = queryOptions?.queryKey ?? getGetScTpvConfsQueryKey(id);

        const queryFn = () =>
            request<GetScTpvConfs200>(
                'GET',
                `${resource}/${id}/tpvs/configurations`,
                params,
            );

        const query = useQuery(queryKey, queryFn, queryOptions);

        return {
            ...query,
            queryKey,
        };
    }

    function useGetSportcenterPrivileges<GetSportcenterPrivileges200>(
        scId: number,
    ) {
        const queryFn = () =>
            request<GetSportcenterPrivileges200>(
                'GET',
                `${resource}/${scId}/privileges`,
            );

        const query = useQuery(SPORTCENTER_PRIVILEGES_QUERY_KEY, queryFn);

        return {
            ...query,
        };
    }

    return {
        ...services,
        useGetSc,
        useGetScMe,
        useGetScsGroups,
        getScsMshipsPDF,
        getScsMshipsCSV,
        updateScsRecover,
        getScsDevices,
        createScsDevice,
        getScsFacilities,
        createScsCoreTrigger,
        removeScsCoreTrigger,
        createScsRemittences,
        createScsGolfCourse,
        getScsGolfCourses,
        updateScsFollow,
        updateScsUnfollow,
        getScsMships,
        updateScsField,
        getFreeSchedules,
        getTeachers,
        getActivities,
        getScsDiscounts,
        useCreateDiscount,
        useGetScsDiscounts,
        useUpdateScRate,
        useCreateScRate,
        useGetScTpvConfs,
        useGetSportcenterPrivileges,
        useGetScsDevicesCapacities,
        getScsDevices2,
    };
}
