import React, { useContext } from 'react';
import { Paper } from '@material-ui/core';
import { AppContext, SttTabs } from '../../../sporttia/all';

import DeviceDetails from './DeviceDetails';
import DeviceAccesses from './DeviceAccesses';
import DeviceAuths from './DeviceAuths';

export default function Device({ match }) {
    const cxt = useContext(AppContext);

    // Tabs
    const tabs = [
        {
            caption: cxt.t('Detail'),
            component: <DeviceDetails id={match.params.id} />,
        },
        {
            caption: cxt.t('Authorizations'),
            component: <DeviceAuths id={match.params.id} />,
        },
        {
            caption: cxt.t('Accesses'),
            component: <DeviceAccesses id={match.params.id} />,
            show: match.params.id !== 'create',
        },
    ];

    return (
        <Paper>
            <SttTabs tabs={tabs} />
        </Paper>
    );
}
