import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { AppContext, SttFormSelect } from '../all';

export const GENDER = {
    MALE: 'MALE',
    FEMALE: 'FEMALE',
    UNKNOWN: 'UNKNOWN',
    OTHER: 'OTHER',
};

export function SttSelectGender({
    grid = false,
    xs = 12,
    md = 3,
    caption,
    name,
    defVal = GENDER.UNKNOWN,
    onChange,
    ...rest
}) {
    const cxt = useContext(AppContext);

    const content = (
        <SttFormSelect
            {...rest}
            name={name}
            defVal={defVal}
            caption={cxt.t('Gender')}
            options={[
                { caption: cxt.t('Male'), value: GENDER.MALE },
                { caption: cxt.t('Female'), value: GENDER.FEMALE },
                { caption: cxt.t('Other'), value: GENDER.OTHER },
                { caption: cxt.t('Unknown'), value: GENDER.UNKNOWN },
            ]}
            onChange={onChange}
        />
    );

    return grid ? (
        <Grid item xs={xs} md={md} {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
