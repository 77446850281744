import React, { useEffect, useState, useContext } from 'react';
import { Box, Grid, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useCrud } from '../../../lib/hooks';
import {
    AppContext,
    SttButtonCancel,
    SttButtonMonths,
    SttSelectWeekdays,
    SttButtonDelete,
    SttButtonRecover,
    SttFormInterval,
    SttButtonSave,
    SttFacilityList,
    SttFormDate,
    SttFormSelect,
    SttFormText,
    SttFormTime,
    SttSelectFixedInterval,
} from '../../../sporttia/all';
import { getPath } from '../../Pages';

export default function RestrictionDetails({
    id,
    restrictionInit,
    weekdaysInit,
    monthsInit,
}) {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const [restriction, setRestriction, setProperty, , Post, Put, Delete] =
        useCrud(restrictionInit);
    const [months, setMonths] = useState(monthsInit);
    const [weekdays, setWeekdays] = useState(weekdaysInit);
    const [showNumBookings, setShowNumBookings] = useState(false);
    const [showPeriod, setShowPeriod] = useState(false);
    const [showWeekdays, setShowWeekdays] = useState(false);
    const [showMonths, setShowMonths] = useState(false);
    const [showSpecialDays, setShowSpecialDays] = useState(false);
    const [showWindow, setShowWindow] = useState(false);

    useEffect(() => {
        if (id === 'create') {
            setRestriction({
                name: '',
                type: 'NUM_BOOKINGS',
                dateini: moment().format('YYYY-01-01'),
                dateend: moment().format('YYYY-12-31'),
                timeini: '08:00',
                timeend: '23:30',
                isDefault: '0',
                windowIniInterval: '0000-00-00 00:00:00',
                windowEndInterval: '0000-00-00 00:00:00',
            });
            /* cxt.setHeader({
                title: cxt.t('CreateRestriction'),
                urlBack: getPath('restrictions'),
            }); */
        }
    }, [id, setRestriction]);

    /**
     * Restriction type changes
     */
    useEffect(() => {
        if (restriction) {
            setShowNumBookings(
                ['NUM_BOOKINGS', 'NUM_ACTIVE_BOOKINGS'].includes(
                    restriction.type,
                ),
            );
            setShowPeriod(restriction.type === 'NUM_BOOKINGS');
            setShowWeekdays(restriction.type === 'DAYS');
            setShowMonths(restriction.type === 'DAYS');
            setShowSpecialDays(restriction.type === 'DAYS');
            setShowWindow(restriction.type === 'WINDOW');
        }
    }, [restriction]);

    /**
     * Create or update restriction
     */
    function saveRestriction() {
        // Updating
        if (restriction.id) {
            const {
                trash,
                created,
                id: idRestriction,
                facilities,
                groups,
                ...putRestrictionRequestBody
            } = restriction;

            Put(`/bookings/restrictions/${restriction.id}`, {
                ...putRestrictionRequestBody,
                weekdays,
                months,
            }).then(() => {});

            // Creating
        } else {
            Post(`/scs/${cxt.sc.id}/bookings/restrictions`, {
                ...restriction,
                weekdays,
                months,
            }).then((response) => {
                if (response.bookingRestriction) {
                    setRestriction(response.bookingRestriction);
                    history.replace(
                        getPath(`restriction`, {
                            id: response.bookingRestriction.id,
                        }),
                    );
                }
            });
        }
    }

    /**
     * Delete restriction
     */
    function deleteRestriction() {
        Delete(`/bookings/restrictions/${restriction.id}`).then(() => {
            history.replace(getPath('restrictions'));
        });
    }

    /**
     * Recover restriction
     */
    function recoverRestriction() {
        Put(
            `/bookings/restrictions/${restriction.id}/recover`,
            null,
            true,
        ).then(() => {
            setRestriction({
                ...restriction,
                trash: 0,
            });
        });
    }

    /**
     * Add facility to restrcition
     */
    function addFacility(facility) {
        Post(`/bookings/restrictions/${id}/facilities/${facility.id}`).then(
            () => {
                setProperty({
                    name: 'facilities',
                    value: restriction.facilities.concat([facility]),
                });
            },
        );
    }

    /**
     * Delete facility from restriction
     */
    function removeFacility(facility) {
        Delete(`/bookings/restrictions/${id}/facilities/${facility.id}`).then(
            () => {
                setProperty({
                    name: 'facilities',
                    value: restriction.facilities.filter(
                        (fct) => fct.id !== facility.id,
                    ),
                });
            },
        );
    }

    /**
     * On month updated
     */
    const onMonthUpdated = (month) => {
        // Set it off
        if (months.includes(month)) {
            const newMonths = [...months];
            newMonths.splice(
                newMonths.findIndex((e) => e === month),
                1,
            );
            setMonths(newMonths);

            // Set it on
        } else {
            setMonths(months.concat([month]));
        }
    };

    if (!restriction) {
        return false;
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3}>
                        <SttFormText
                            grid
                            md={12}
                            sm={12}
                            xs={12}
                            caption={cxt.t('NameAndDescription')}
                            name="name"
                            defVal={restriction.name}
                            onChange={setProperty}
                        />

                        <SttFormSelect
                            grid
                            xs={12}
                            xl={4}
                            caption={cxt.t('Type')}
                            name="type"
                            defVal={restriction.type}
                            options={cxt.constants.restrictionTypes.map(
                                (restr) => ({
                                    caption: restr.label,
                                    value: restr.id,
                                }),
                            )}
                            onChange={setProperty}
                        />

                        <SttFormDate
                            grid
                            xs={12}
                            md={6}
                            xl={3}
                            caption={cxt.t('ValidFrom')}
                            name="dateini"
                            defVal={restriction.dateini}
                            onChange={({ name, value }) =>
                                setProperty({
                                    name,
                                    value: moment(value).format('YYYY-MM-DD'),
                                })
                            }
                        />

                        <SttFormDate
                            grid
                            xs={12}
                            md={6}
                            xl={3}
                            caption={cxt.t('until')}
                            name="dateend"
                            defVal={restriction.dateend}
                            onChange={({ name, value }) =>
                                setProperty({
                                    name,
                                    value: moment(value).format('YYYY-MM-DD'),
                                })
                            }
                        />
                        <SttFormTime
                            grid
                            xs={12}
                            md={6}
                            xl={3}
                            caption={cxt.t('TimeIni')}
                            name="timeini"
                            min="00:00"
                            max="23:59"
                            defVal={restriction.timeini}
                            interval={30}
                            onChange={setProperty}
                            timeRangeOptions={{ includeMaxTime: true }}
                        />
                        <SttFormTime
                            grid
                            xs={12}
                            md={6}
                            xl={3}
                            caption={cxt.t('TimeEnd')}
                            name="timeend"
                            min="00:00"
                            max="23:59"
                            defVal={restriction.timeend}
                            interval={30}
                            onChange={setProperty}
                            timeRangeOptions={{ includeMaxTime: true }}
                        />

                        <SttFormSelect
                            grid
                            md={12}
                            sm={12}
                            xs={12}
                            caption={cxt.t('Default')}
                            name="isDefault"
                            defVal={+restriction.isDefault}
                            options={[
                                {
                                    caption: cxt.t(
                                        'page.sc.restriction.default.no',
                                    ),
                                    value: '0',
                                },
                                {
                                    caption: cxt.t(
                                        'page.sc.restriction.default.yes',
                                    ),
                                    value: '1',
                                },
                            ]}
                            onChange={setProperty}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box my={4}>
                            <Divider />
                        </Box>
                    </Grid>

                    <Grid container spacing={3}>
                        {showNumBookings && (
                            <SttFormSelect
                                grid
                                md={5}
                                xs={12}
                                xl={4}
                                cleanable
                                caption={cxt.t('NumberOfBookings')}
                                name="numBookings"
                                defVal={restriction.numBookings}
                                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                                    (i) => ({
                                        caption: `${i} ${cxt.t('bookings')}`,
                                        value: i.toString(),
                                    }),
                                )}
                                onChange={setProperty}
                            />
                        )}

                        {showPeriod && (
                            <Grid item md={7} xs={12} xl={4}>
                                <SttSelectFixedInterval
                                    cleanable
                                    caption={cxt.t('Period')}
                                    name="interval"
                                    options={['!NO PERIOD']}
                                    defVal={restriction.interval}
                                    onChange={setProperty}
                                />
                            </Grid>
                        )}

                        {showMonths && (
                            <Grid item xs={12} align="center">
                                <SttButtonMonths
                                    tinyLabels
                                    selectedMonths={months}
                                    onChangeMonth={onMonthUpdated}
                                />
                            </Grid>
                        )}

                        {showWeekdays && (
                            <Grid item xs={12} align="center">
                                <SttSelectWeekdays
                                    selected={weekdays}
                                    onChange={({ value }) => {
                                        setWeekdays(value);
                                    }}
                                />
                            </Grid>
                        )}

                        {showSpecialDays && (
                            <SttFormSelect
                                grid
                                cleanable
                                md={5}
                                sm={6}
                                xs={12}
                                caption={cxt.t('SpecialDays')}
                                name="specificDays"
                                defVal={restriction.specificDays}
                                options={cxt.constants.restrictionSpecificDays}
                                onChange={setProperty}
                            />
                        )}

                        {showWindow && (
                            <>
                                <SttFormInterval
                                    grid
                                    md={8}
                                    xs={12}
                                    xl={4}
                                    caption={cxt.t('Begin')}
                                    name="windowIniInterval"
                                    defVal={restriction.windowIniInterval}
                                    onChange={setProperty}
                                />

                                <SttFormTime
                                    grid
                                    md={4}
                                    xs={12}
                                    xl={3}
                                    caption={cxt.t('TimeIni')}
                                    name="windowIniTime"
                                    min="00:00"
                                    defVal={restriction.windowIniTime}
                                    interval={30}
                                    onChange={setProperty}
                                />

                                <SttFormInterval
                                    grid
                                    md={8}
                                    xs={12}
                                    xl={4}
                                    caption={cxt.t('End')}
                                    name="windowEndInterval"
                                    defVal={restriction.windowEndInterval}
                                    onChange={setProperty}
                                />

                                <SttFormTime
                                    grid
                                    md={4}
                                    xs={12}
                                    xl={3}
                                    caption={cxt.t('TimeEnd')}
                                    name="windowEndTime"
                                    min="00:00"
                                    defVal={restriction.windowEndTime}
                                    interval={30}
                                    onChange={setProperty}
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={5}>
                    {restriction?.facilities !== undefined &&
                        restriction?.facilities !== null && (
                            <SttFacilityList
                                onAdd={addFacility}
                                onDelete={removeFacility}
                                facilities={restriction.facilities ?? []}
                            />
                        )}
                </Grid>
            </Grid>

            <Box mt={3}>
                <Grid mt={3} container spacing={3}>
                    {!restriction.trash && (
                        <Grid item xs={12} sm={6} md={3}>
                            <SttButtonSave
                                fullWidth
                                onClick={saveRestriction}
                            />
                        </Grid>
                    )}

                    {!restriction.id && (
                        <Grid item xs={12} sm={6} md={3}>
                            <SttButtonCancel
                                fullWidth
                                onClick={history.goBack}
                            />
                        </Grid>
                    )}

                    {restriction.id && !restriction.trash && (
                        <Grid item xs={12} sm={6} md={3}>
                            <SttButtonDelete
                                fullWidth
                                onClick={deleteRestriction}
                            />
                        </Grid>
                    )}

                    {restriction.trash && (
                        <Grid item xs={12} sm={6} md={3}>
                            <SttButtonRecover
                                fullWidth
                                onClick={recoverRestriction}
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
        </>
    );
}
