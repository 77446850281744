import React, { useState, useEffect, useContext } from 'react';
import { Grid, Box } from '@material-ui/core';
import { SttDialog } from '../../../sporttia/dialogs/SttDialog';
import { AppContext } from '../../../sporttia/AppContext';
import {
    SttFormTime,
    SttSelectWeekdays,
    SttPriceList,
} from '../../../sporttia/all';
import {
    subsetObject,
    deleteElementFromArray,
    updateElementInArray,
} from '../../../lib/utils';

/**
 * Free schedule slot
 * ==================
 *
 * Props
 *
 * 	* integer|string idSlot: if it's "create" then start creating process
 * 	* object freeSchedule: only used when creating
 *	* function onClose
 *	* function onUpdate(slot): execute when the slot has changed on server
 */
export default function FreeScheduleSlotDialog({
    idSlot = null,
    freeSchedule,
    onClose,
    onUpdate,
}) {
    const cxt = useContext(AppContext);
    const [slot, setSlot] = useState(null);
    const [creating, setCreating] = useState(false);

    /**
     * Load slot
     */
    const loadSlot = () => {
        cxt.api('GET', `/frees/schedules/slots/${idSlot}`, {
            success: (r) => {
                setSlot(r.slot);
                setCreating(false);
            },
        });
    };

    /**
     * Save
     */
    const saveSlot = () => {
        const params = subsetObject(slot, ['ini', 'end', 'weekdays']);

        // Create
        if (creating) {
            cxt.api('POST', `/frees/schedules/${freeSchedule.id}/slots`, {
                params,
                success: (r) => {
                    cxt.showMessage('S', cxt.t('Saved'));
                    setSlot(r.slot);
                    setCreating(false);
                    if (onUpdate) onUpdate(r.slot);
                },
            });

            // Update
        } else {
            cxt.api('PUT', `/frees/schedules/slots/${slot.id}`, {
                params,
                success: (r) => {
                    cxt.showMessage('S', cxt.t('Saved'));
                    if (onUpdate) onUpdate(r.slot);
                    if (onClose) onClose();
                },
            });
        }
    };

    /**
     * Add price to slot
     */
    const addPrice = (price) => {
        if (price.prices[0]) {
            cxt.api(
                'POST',
                `/frees/schedules/slots/${slot.id}/prices/${price.id}`,
                {
                    success: () => {
                        const priceAdded = {
                            id: price.id,
                            name: price.name,
                            price: price.prices[0].price,
                        };
                        const slotUpdated = {
                            ...slot,
                            prices: updateElementInArray(
                                slot.prices,
                                priceAdded,
                            ),
                        };
                        setSlot(slotUpdated);
                        if (onUpdate) onUpdate(slotUpdated);
                    },
                },
            );
        }
    };

    /**
     * Delete price
     */
    const deletePrice = (price) => {
        cxt.api(
            'DELETE',
            `/frees/schedules/slots/${slot.id}/prices/${price.id}`,
            {
                success: () => {
                    const newSlot = {
                        ...slot,
                        prices: deleteElementFromArray(slot.prices, price),
                    };
                    setSlot(newSlot);
                    if (onUpdate) onUpdate(newSlot);
                },
            },
        );
    };

    /**
     * Handle change slot
     */
    const handleChangeSlot = ({ name, value }) => {
        setSlot({ ...slot, [name]: value });
    };

    /**
     * Slot id changes
     */
    useEffect(() => {
        if (idSlot) {
            if (idSlot === 'create') {
                setSlot({
                    weekdays: [1, 2, 3, 4, 5, 6, 0],
                    ini: '07:00',
                    end: '23:00',
                });
                setCreating(true);
            } else {
                loadSlot();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idSlot]);

    // Render
    return (
        <SttDialog
            open={idSlot != null}
            onClose={onClose}
            title={creating ? cxt.t('CreateTimeSlot') : cxt.t('Slot')}
            content={
                slot && (
                    <>
                        <Grid container spacing={3}>
                            <Grid item md={12} xs={12} sm={12}>
                                <SttSelectWeekdays
                                    selected={slot.weekdays}
                                    onChange={handleChangeSlot}
                                />
                            </Grid>

                            <SttFormTime
                                grid
                                md={6}
                                xs={6}
                                sm={6}
                                name="ini"
                                caption={cxt.t('From')}
                                defVal={slot.ini}
                                min="00:00:00"
                                max="23:45:00"
                                interval={5}
                                onChange={handleChangeSlot}
                            />

                            <SttFormTime
                                grid
                                md={6}
                                xs={6}
                                sm={6}
                                name="end"
                                caption={cxt.t('Until')}
                                defVal={slot.end}
                                min="00:00:00"
                                max="23:50:00"
                                interval={5}
                                onChange={handleChangeSlot}
                            />
                        </Grid>

                        {!creating && (
                            <Box mt={2}>
                                <SttPriceList
                                    paper
                                    prices={slot.prices}
                                    onAdd={addPrice}
                                    onDelete={deletePrice}
                                />
                            </Box>
                        )}
                    </>
                )
            }
            buttons={[
                {
                    type: 'save',
                    onClick: () => saveSlot(),
                },
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
        />
    );
}
