import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext, SttTabs, SttFullDialog } from '../../../sporttia/all';
import { useQuery, useCrud, useTranslations } from '../../../lib/hooks';
import RestrictionDetails from './RestrictionDetails';
import RestrictionUsers from './RestrictionUsers';
import RestrictionGroups from './RestrictionGroups';
import { getPath } from '../../Pages';

export default function Restriction({ match }) {
    const { id } = match.params;
    const { trash } = useQuery();
    const history = useHistory();
    const cxt = useContext(AppContext);
    const { translate } = useTranslations();
    const [restriction, setRestriction, , Get, ,] = useCrud(null);
    const tabs = [
        {
            caption: cxt.t('Detail'),
            component: (
                <RestrictionDetails
                    id={id}
                    trash={trash}
                    restrictionInit={
                        restriction?.facilities
                            ? restriction
                            : { ...restriction, facilities: [] }
                    }
                    weekdaysInit={restriction?.weekdays}
                    monthsInit={restriction?.months}
                />
            ),
        },
        {
            caption: cxt.t('Users'),
            component: <RestrictionUsers id={id} trash={trash} />,
            show: id !== 'create',
        },
        {
            caption: cxt.t('Groups'),
            component: <RestrictionGroups idRestriction={id} />,
            show: id !== 'create',
        },
    ];
    /**
     * Load restriction
     */
    function loadRestriction() {
        Get(`/bookings/restrictions/${id}`, {
            trash,
        }).then((response) => {
            if (response.bookingRestriction) {
                setRestriction(response.bookingRestriction);
            }
        });
    }

    useEffect(() => {
        if (id && id !== 'create') {
            loadRestriction();
        }
    }, [id]);

    return (
        <SttFullDialog
            open={!!(restriction?.id || id === 'create')}
            onClose={() => history.push(getPath('restrictions'))}
            title={
                restriction ? restriction.name : translate('CreateRestriction')
            }
        >
            <SttTabs tabs={tabs} />
        </SttFullDialog>
    );
}
