import { useContext } from 'react';
import { MutationFunction, UseMutationOptions, useMutation } from 'react-query';
import { useAPI } from '../lib/hooks';
import { AppContext } from '../sporttia/AppContext';
import {
    UpdateProductTicketClose200,
    UpdateProductTicketCloseBody,
} from '../types/api/paths/Product';

const resource = '/products';

/**
 * Hook to access all 'products' resources.
 */
export default function useProductsService() {
    const { migrationApis } = useContext(AppContext)!;
    const [request] = useAPI(migrationApis);

    /**
     * PUT /products/tickets/id/close request.
     */
    function useCloseProductTicket(
        mutationOptions?: UseMutationOptions<
            UpdateProductTicketClose200,
            unknown,
            { id: number; params: UpdateProductTicketCloseBody }
        >,
    ) {
        const mutationFn: MutationFunction<
            UpdateProductTicketClose200,
            { id: number; params: UpdateProductTicketCloseBody }
        > = ({ id, params }) =>
            request('PUT', `${resource}/tickets/${id}/close`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    return {
        useCloseProductTicket,
    };
}
