import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import { AppContext } from '../AppContext';

/**
 * Label for deleted message.
 *
 * Muestra "Eliminado" en rojo.
 */
export function SttLabelDeleted({}) {
    const cxt = useContext(AppContext);

    return (
        <Typography variant="caption" style={{ color: 'red' }}>
            {cxt.t('Deleted')}
        </Typography>
    );
}
