import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid } from '@material-ui/core';
import moment from 'moment';
import {
    AppContext,
    SttDialog,
    SttTable,
    SttFormCheck,
    SttFormSearch,
    SttFormTime,
} from '../../../sporttia/all';
import { updateElementInArray } from '../../../lib/utils';

export default function PieceCapacityDialog({
    open = false,
    facility,
    date,
    piece,
    onClose,
}) {
    const cxt = useContext(AppContext);
    const [occupants, setOccupants] = useState();
    const [name, setName] = useState('');
    const [time, setTime] = useState();

    /**
     * Piece changes
     */
    useEffect(() => {
        if (piece && piece.ini) {
            setTime(moment(piece.ini).format('H:mm'));
        }
    }, [piece]);

    /**
     * Load occupants
     */
    const loadOccupants = (p = { page: 1, rows: 6 }) => {
        cxt.api('GET', `/facilities/${facility.id}/bookings/occupants`, {
            params: {
                date,
                name,
                time,
                page: p.page,
                rows: p.rows,
            },
            success: (r) => {
                setOccupants(r);
            },
        });
    };

    /**
     * Toggle confirmed
     */
    const toggleConfirmed = (occupant) => {
        cxt.api('PUT', `/bookings/occupants/${occupant.id}`, {
            params: {
                confirmed: !occupant.confirmed,
            },
            success: (r) => {
                setOccupants({
                    ...occupants,
                    rows: updateElementInArray(occupants.rows, {
                        ...occupant,
                        confirmed: !occupant.confirmed,
                    }),
                });
            },
        });
    };

    /**
     * Init
     */
    useEffect(() => {
        if (facility && facility.id && time) loadOccupants();
    }, [facility, time]);

    // -----| Render |-----
    return (
        <SttDialog
            fullWidth
            title={cxt.t('Seating')}
            open={open}
            onClose={onClose}
            content={
                <>
                    <Box mb={3}>
                        <Grid container spacing={3}>
                            <SttFormSearch
                                grid
                                md={5}
                                sm
                                xs
                                defVal={name}
                                onChange={({ value }) => setName(value)}
                                onClickSearch={loadOccupants}
                            />
                        </Grid>
                    </Box>

                    <SttTable
                        data={occupants}
                        perPage={6}
                        columns={[
                            {
                                title: cxt.t('Name'),
                                field: 'userName',
                                align: 'left',
                                value: (row) => row.user.fullName,
                            },
                        ]}
                        onFetch={loadOccupants}
                    />
                </>
            }
            buttons={[
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
        />
    );
}
