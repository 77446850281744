import React, { useContext, useState } from 'react';
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Link,
    Paper,
} from '@material-ui/core';
import SttError from '../../../components/error/SttError';
import SttTable from '../../../sporttia/SttTable';
import { SttTopFilteringControls } from '../../../sporttia/SttTopFilteringControls';
import useScsService from '../../../services/ScsService';
import { GetScsDeviceSpaceParams } from '../../../types/api/paths/Scs';
import DeviceSpace, { Device } from '../../../types/models/DeviceSpace';
import { useTranslations } from '../../../lib/hooks';
import translations from '../../../translations';
import DeviceSpaceDialog from '../../../components/dialogs/DeviceSpaceDialog';
import { AppContext, SttButtonFab } from '../../../sporttia/all';
import useDevicesService from '../../../services/DevicesService';
import { copyToClipboard, getErrorMessage } from '../../../lib/utils';

type DevicesSpacesProps = {
    item: { id: number } | undefined; // Sportcenter id
};
type DevicesSpacesFilterParams = {
    name: string; // Sportcenter id
    trash: boolean;
};

export default function DevicesSpaces({
    item = undefined,
}: DevicesSpacesProps) {
    const { useGetScsDevicesCapacities } = useScsService();
    const { useUpdateDeviceSpace } = useDevicesService();
    const cxt = useContext(AppContext)!;
    const scId = cxt?.sc ? cxt.sc.id : -1;
    const [deviceSpace, setDeviceSpace] = useState<DeviceSpace | null>();
    const [queryParams, setQueryParams] = useState<GetScsDeviceSpaceParams>({
        page: 1,
        rows: 20,
        name: '',
        trash: false,
    });
    const capacitiesQuery = useGetScsDevicesCapacities(
        item ? item?.id : scId,
        queryParams,
    );
    const updateDeviceSpaceMutation = useUpdateDeviceSpace({
        onSuccess: () => {
            capacitiesQuery.refetch();
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });
    const { translate } = useTranslations();

    if (capacitiesQuery.isLoading) {
        return (
            <Box display="flex" justifyContent="center">
                <CircularProgress />
            </Box>
        );
    }

    if (capacitiesQuery.isError) {
        return <SttError />;
    }

    if (!capacitiesQuery.isSuccess) {
        return null;
    }

    return (
        <Paper style={{ padding: 25 }}>
            <SttTopFilteringControls
                mb={2}
                fields={[
                    {
                        caption: translate(translations.generic.name),
                        name: 'name',
                        type: 'text',
                        value: '',
                    },
                ]}
                menu={undefined}
                menuIcon={undefined}
                filterButton={undefined}
                downloadButton={undefined}
                iconButtons={undefined}
                onFilter={(value: DevicesSpacesFilterParams) => {
                    setQueryParams({
                        ...queryParams,
                        name: value.name,
                        trash: value.trash,
                    });
                }}
                trashAction
                mainAction={undefined}
            />
            <SttTable
                data={capacitiesQuery?.data}
                // @ts-expect-error hay prisa
                onChangePagination={(page: number) =>
                    setQueryParams({ ...queryParams, page })
                }
                onFetch={() => {}}
                columns={[
                    {
                        title: '#',
                        type: 'tooltip',
                        value: (row: DeviceSpace) => ({
                            label: '#',
                            value: row.id,
                        }),
                        onClick: (row: DeviceSpace) =>
                            copyToClipboard(row.id, () =>
                                cxt.showMessage(
                                    'S',
                                    cxt.t('copiedToClipboard'),
                                ),
                            ),
                    },
                    {
                        title: translate(translations.generic.name),
                        name: 'name',
                        field: 'name',
                        type: 'link',
                        align: 'center',
                        value: (row: DeviceSpace) => (
                            <Link onClick={() => setDeviceSpace(row)}>
                                {row.name}
                            </Link>
                        ),
                    },
                    {
                        title: translate(
                            translations.deviceSpace.currentCapacity,
                        ),
                        name: 'currentCapacity',
                        field: 'currentCapacity',
                        align: 'center',
                        value: (row: DeviceSpace) => row?.currentCapacity,
                    },
                    {
                        title: translate(
                            translations.deviceSpace.maximunCapacity,
                        ),
                        name: 'maximumCapacity',
                        field: 'maximumCapacity',
                        align: 'center',
                        value: (row: DeviceSpace) => row?.maximumCapacity,
                    },
                    {
                        field: 'devices',
                        title: translate(translations.generic.devices),
                        align: 'center',
                        value: (row: DeviceSpace) => (
                            <Box mt={1}>
                                {row?.devices?.map((device: Device) => (
                                    <Chip
                                        key={device?.id}
                                        size="small"
                                        label={device?.name}
                                    />
                                ))}
                            </Box>
                        ),
                    },
                    {
                        align: 'center',
                        value: (row: DeviceSpace) => (
                            <Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ borderRadius: 5, margin: 5 }}
                                    onClick={() => {
                                        updateDeviceSpaceMutation.mutate({
                                            deviceSpaceId: row?.id,
                                            params: { currentCapacity: 0 },
                                        });
                                    }}
                                >
                                    Reset
                                </Button>
                            </Box>
                        ),
                    },
                ]}
            />
            <SttButtonFab
                onClick={() =>
                    setDeviceSpace({
                        id: -1,
                        created: '',
                        maximumCapacity: 0,
                        currentCapacity: 0,
                        name: '',
                        devices: [],
                    })
                }
            />
            {deviceSpace && (
                <DeviceSpaceDialog
                    item={deviceSpace}
                    onSave={() => {
                        capacitiesQuery.refetch();
                    }}
                    onClose={() => {
                        setDeviceSpace(null);
                    }}
                />
            )}
        </Paper>
    );
}
