import React, { useState, useContext } from 'react';
import { Paper } from '@material-ui/core';
import moment from 'moment';
import {
    AppContext,
    SttButtonFab,
    SttChipFacilityRow,
    SttTable,
    SttTopControls,
} from '../../../sporttia/all';
import { usePageHeader, useToggle } from '../../../lib/hooks';
import BusySchedule from './BusySchedule';
import translations from '../../../translations';
import { fullName } from '../../../lib/utils';

const statusFilters = {
    all: 'ALL',
    active: 'ACTIVE',
    inactive: 'INACTIVE',
    current: 'CURRENT',
};

export default function BusySchedules() {
    usePageHeader('ClosedFields');
    const cxt = useContext(AppContext);

    const [busySchedules, setBusySchedules] = useState();
    const [busyScheduleId, setBusyScheduleId] = useState(null);
    const [filters, setFilters] = useState({
        name: '',
        year: moment().year(),
        status: statusFilters.current,
    });
    const [trash, toggleTrash] = useToggle(() => {
        // eslint-disable-next-line no-use-before-define
        loadBusySchedules();
    });
    // Variable para resolver el problema (chapuza) de Material UI que no se puede usar como valor en un selector un valor vacío.
    const [statusFilter, setStatusFilter] = useState({
        status: statusFilters.current,
    });

    function loadBusySchedules(params = { page: 1, rows: 20 }) {
        const filterParams = {
            name: filters.name,
            year: filters.year,
        };

        if (statusFilter?.status === statusFilters.current) {
            filterParams.inTime = true;
        } else {
            filterParams.status =
                statusFilter.status === statusFilters.all
                    ? ''
                    : statusFilter.status;
        }

        cxt.api('GET', `/scs/${cxt.sc.id}/busies/schedules`, {
            params: {
                ...params,
                ...filterParams,
                trash,
            },
            success: (response) => {
                if (response.rows) {
                    setBusySchedules(response);
                }
            },
        });
    }

    function goCreateNew() {
        setBusyScheduleId('create');
    }

    return (
        <>
            <Paper>
                <SttTopControls
                    p={2}
                    fields={[
                        {
                            name: 'name',
                            type: 'text',
                            value: filters.name,
                            onEnterKey: loadBusySchedules,
                        },
                    ]}
                    extraFields={[
                        {
                            caption: cxt.t(translations.generic.status),
                            name: 'status',
                            type: 'select',
                            value: statusFilter.status,
                            options: [
                                {
                                    caption: cxt.t(translations.generic.all),
                                    value: statusFilters.all,
                                },
                                {
                                    caption: cxt.t(translations.generic.active),
                                    value: statusFilters.active,
                                },
                                {
                                    caption: cxt.t(
                                        translations.generic.inactive,
                                    ),
                                    value: statusFilters.inactive,
                                },
                                {
                                    caption: cxt.t(
                                        translations.generic.current,
                                    ),
                                    value: statusFilters.current,
                                },
                            ],
                        },
                        {
                            caption: cxt.t(translations.generic.year),
                            name: 'year',
                            type: 'year',
                            value: filters.year,
                            years: {
                                past: 5,
                                start: moment().year(),
                                future: 5,
                            },
                        },
                    ]}
                    onChange={({ name, value }) => {
                        setStatusFilter({ ...statusFilter, [name]: value });
                        setFilters({ ...filters, [name]: value });
                    }}
                    onFilter={loadBusySchedules}
                    onToggleTrash={toggleTrash}
                />
                <SttTable
                    data={busySchedules}
                    onFetch={loadBusySchedules}
                    onClickRow={(row) => setBusyScheduleId(row.id)}
                    columns={[
                        {
                            title: cxt.t(translations.generic.name),
                            type: 'link',
                            align: 'left',
                            field: 'name',
                        },
                        {
                            title: cxt.t(translations.generic.creator),
                            type: 'text',
                            value: (row) => fullName(row?.creator),
                        },
                        {
                            title: cxt.t(translations.generic.period),
                            type: 'period',
                            value: (row) => ({ ini: row.ini, end: row.end }),
                        },
                        {
                            title: cxt.t(translations.generic.periods),
                            type: 'nonZero',
                            field: 'numBusies',
                        },
                        {
                            title: cxt.t(translations.generic.facilities),
                            type: 'text',
                            value: (row) => (
                                <SttChipFacilityRow
                                    facilities={row.facilities}
                                />
                            ),
                        },
                    ]}
                />
                <SttButtonFab onClick={goCreateNew} />
            </Paper>

            {busyScheduleId && (
                <BusySchedule
                    idBusySchedule={busyScheduleId}
                    trash={trash}
                    onClose={() => {
                        setBusyScheduleId(null);
                        loadBusySchedules();
                    }}
                />
            )}
        </>
    );
}
