import React, { useContext, useEffect, useState } from 'react';
import { Chip, Tooltip, Box, makeStyles, Link } from '@material-ui/core';
import moment from 'moment';
import {
    CheckCircleOutline,
    CloudDownload,
    HighlightOff,
} from '@material-ui/icons';
import ReplayIcon from '@material-ui/icons/Replay';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import {
    useCrud,
    useInteractionsFiles,
    useLoader,
    useToggle,
} from '../../../lib/hooks';
import { AppContext, SttTable, SttTopControls } from '../../../sporttia/all';
import {
    composeFormData,
    formatPriceByLocale,
    fullName,
    getErrorMessage,
} from '../../../lib/utils';
import ModalParticipant from './ModalParticipant';
import PaymentModal from '../../../components/paymentModal/PaymentModal';
import colors from '../../../styles/Colors';
import SttMshipDialog from '../../../sporttia/dialogs/SttMshipDialog';
import POSPaymentDialog from '../../../components/dialogs/POSPaymentDialog';
import POSStripePaymentDialog from '../../../components/stripe/POSStripePaymentDialog';
import useEventsService from '../../../services/EventsService';
import constants from '../../../config/constants';

const useStyles = makeStyles(() => ({
    formField: {
        marginLeft: 4,
        marginRight: 4,
        marginTop: 2,
        marginBottom: 2,
    },
    infoButton: {
        '&:hover': {
            cursor: 'pointer',
            color: 'rgba(33, 150, 243, 0.5)',
        },
    },
}));

export default function EventParticipants({ event }) {
    const classes = useStyles();

    const cxt = useContext(AppContext);
    const { downloadFile } = useInteractionsFiles();

    const [participants, setParticipants, , Get] = useCrud();
    const [category, setCategory] = useState(event.categories?.[0]);
    const [participant, setParticipant] = useState(null);
    const [openMshipDialog, setOpenMshipDialog] = useState(false);
    const [selectedMshipId, setSelectedMshipId] = useState(null);
    const [filters, setFilters] = useState({
        name: '',
    });
    const [paymentId, setPaymentId] = useState(null);

    const [trash, toggleTrash] = useToggle(() => {
        // eslint-disable-next-line no-use-before-define
        loadParticipants();
    });

    // POS (datafono)
    const [posPaymentParams, setPosPaymentParams] = useState(null);

    useEffect(() => {
        if (event?.categories) {
            setCategory(event.categories[0]);
        }
    }, [event]);

    useEffect(() => {
        if (category.id) {
            // eslint-disable-next-line no-use-before-define
            loadParticipants();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category]);

    function loadParticipants(params = { page: 1, rows: 25 }) {
        Get(`/events/categories/${category.id}/participants`, {
            ...params,
            ...filters,
            trash,
        }).then((result) => {
            if (result.rows) {
                setParticipants(result);
            }
        });
    }

    function download(mode) {
        const type = mode === 'pdf' ? 'application/pdf' : 'text/csv';
        downloadFile(
            `/events/categories/${category.id}/participants.${mode}`,
            type,
            { ...filters, trash },
            `${event.name}_${category.name}_${cxt.t('Participants')}.${mode}`,
        );
    }

    function updateParticipants(payment) {
        if (payment === undefined) {
            return;
        }

        setParticipants({
            ...participants,
            rows: participants.rows.map((row) =>
                !row.enrollment || row.enrollment?.id !== paymentId
                    ? row
                    : { ...row, enrollment: payment },
            ),
        });
    }

    const eventsService = useEventsService();
    const updateParticipantMutation = eventsService.useUpdateParticipant({
        onSuccess: (response) => {
            const { participant: participantResponse, tpv } = response;

            if (participantResponse) {
                setParticipant(null);
                loadParticipants();
            } else if (
                tpv?.payment?.paymentForm ===
                constants.payment.paymentForms.dataphoneConnected.name
            ) {
                // params for the POS dialog (datáfono físico)
                setPosPaymentParams({
                    amount: tpv.price,
                    concept: tpv.concept,
                    idTpv: tpv.id,
                    sc: {
                        id: tpv?.sc?.id,
                        scName: tpv?.sc?.short,
                        entityName: tpv?.sc?.customer?.name,
                        address: tpv?.sc?.address,
                        cif: tpv?.sc?.customer?.cif,
                        phone: tpv?.sc?.phonePublic,
                    },
                });
            }
        },
        onError: (error) => {
            setParticipant(null);
            cxt.showMessage('E', getErrorMessage(error));
        },
    });
    const createEventCategoryParticipantMutation =
        eventsService.useCreateEventCategoryParticipant({
            onSuccess: (response) => {
                const { participant: participantResponse, tpv } = response;

                if (participantResponse) {
                    setParticipant(null);
                    loadParticipants();
                } else if (
                    tpv?.payment?.paymentForm ===
                    constants.payment.paymentForms.dataphoneConnected.name
                ) {
                    // params for the POS dialog (datáfono físico)
                    setPosPaymentParams({
                        amount: tpv.price,
                        concept: tpv.concept,
                        idTpv: tpv.id,
                        sc: {
                            id: tpv?.sc?.id,
                            scName: tpv?.sc?.short,
                            entityName: tpv?.sc?.customer?.name,
                            address: tpv?.sc?.address,
                            cif: tpv?.sc?.customer?.cif,
                            phone: tpv?.sc?.phonePublic,
                        },
                    });
                }
            },
            onError: (error) => {
                cxt.showMessage('E', getErrorMessage(error));
            },
        });
    const deleteParticipantMutation = eventsService.useDeleteParticipant({
        onSuccess: () => {
            setParticipant(null);
            loadParticipants();
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });

    function saveParticipant(participantToSave) {
        const {
            name,
            mobile,
            email,
            notes,
            user,
            team,
            admin,
            paymentForm,
            form,
        } = participantToSave;
        const idTeamExists = team && team.id && team.id !== '0';
        const params = {
            name,
            mobile: mobile || undefined,
            email: email || undefined,
            notes: notes || undefined,
            idUser: user?.id,
            paymentForm:
                paymentForm || paymentForm === '' ? paymentForm : undefined,
            idTeam: idTeamExists ? team.id : null,
            teamName: team && !idTeamExists ? team.name : null,
            admin: idTeamExists ? admin : null,
            form: form ? composeFormData(form) : null,
        };

        delete params.team;
        delete params.user;
        delete params.category;

        if (participantToSave.id) {
            const putParams = {
                name: params?.name,
                email: params?.email,
                notes: params?.notes,
                mobile: params?.mobile,
                admin: params?.admin,
                form: params?.form,
            };

            updateParticipantMutation.mutate({
                participantId: participantToSave.id,
                params: putParams,
            });
        } else {
            createEventCategoryParticipantMutation.mutate({
                categoryId: category.id,
                params,
            });
        }
    }

    const [isMutationsLoading, mutationsLoader] = useLoader([
        updateParticipantMutation.status,
        createEventCategoryParticipantMutation.status,
        deleteParticipantMutation.status,
    ]);

    return (
        <>
            {mutationsLoader}

            <SttTopControls
                pl={0}
                mainAction={{
                    type: 'create',
                    onClick: () => {
                        setParticipant({
                            user: {
                                id: 0,
                                name: '',
                            },
                            mobile: '',
                            email: '',
                            notes: '',
                            form: event.form ? [...event.form] : [],
                        });
                    },
                }}
                menu={[
                    {
                        caption: `${cxt.t('Download')} PDF`,
                        onClick: () => download('pdf'),
                    },
                    {
                        caption: `${cxt.t('Download')} CSV`,
                        onClick: () => download('csv'),
                    },
                ]}
                menuIcon={<CloudDownload />}
                fields={[
                    {
                        caption: cxt.t('Category'),
                        name: 'category',
                        type: 'select',
                        value: category.id,
                        options:
                            event.categories &&
                            event.categories.map((cat) => ({
                                label: cat.name,
                                value: cat.id,
                            })),
                        onChange: ({ value }) => {
                            setCategory(
                                event.categories &&
                                    event.categories.find(
                                        (cat) => cat.id === value,
                                    ),
                            );
                        },
                    },
                    {
                        caption: cxt.t('Name'),
                        name: 'name',
                        type: 'text',
                        value: filters.name,
                        onEnterKey: loadParticipants,
                    },
                ]}
                onChange={({ name, value }) =>
                    setFilters({ ...filters, [name]: value })
                }
                onFilter={loadParticipants}
                onToggleTrash={toggleTrash}
            />
            <SttTable
                autoload={false}
                onFetch={loadParticipants}
                data={participants}
                columns={[
                    {
                        title: cxt.t('Name'),
                        align: 'left',
                        type: 'link',
                        value: (row) =>
                            fullName({
                                ...row.user,
                                name: row.name,
                                surname1: row.surname1,
                                surname2: row.surname2,
                                displayName: row.displayName,
                            }),
                        onClick: (row) => setParticipant(row),
                    },
                    {
                        title: cxt.t('SignedBy'),
                        type: 'text',
                        align: 'left',
                        value: (row) => fullName(row.creator),
                    },
                    {
                        title: cxt.t('Form'),
                        value: (row) =>
                            row.form &&
                            row.form.map(
                                (item) =>
                                    item.answer?.value && (
                                        <Tooltip
                                            key={item.id}
                                            arrow
                                            placement="top"
                                            title={item.name}
                                            className={classes.formField}
                                        >
                                            <Chip
                                                size="small"
                                                label={(() => {
                                                    if (item.type !== 'CHECK') {
                                                        return item.answer
                                                            ?.value;
                                                    }

                                                    // En caso de tipo check, usar icono SI/NO (el estilo es una ñapilla para que salga centrado verticalmente)
                                                    if (item.answer.value) {
                                                        return (
                                                            <CheckCircleOutline
                                                                style={{
                                                                    position:
                                                                        'relative',
                                                                    top: 2,
                                                                    color: colors.green,
                                                                }}
                                                            />
                                                        );
                                                    }

                                                    return (
                                                        <HighlightOff
                                                            style={{
                                                                position:
                                                                    'relative',
                                                                top: 2,
                                                                color: colors.red,
                                                            }}
                                                        />
                                                    );
                                                })()}
                                            />
                                        </Tooltip>
                                    ),
                            ),
                    },
                    {
                        title: cxt.t('Inscription'),
                        value: (row) =>
                            row.enrollment && (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        color:
                                            row.enrollment.status ===
                                            constants.payment.status.paid.name
                                                ? colors.green
                                                : colors.red,
                                    }}
                                >
                                    <div>
                                        {row.enrollment.paymentDate &&
                                            moment(
                                                row.enrollment.paymentDate,
                                            ).format('D MMM YYYY')}
                                    </div>
                                    <Box mx={2}>
                                        {row.enrollment.paymentFormLang}
                                    </Box>
                                    {row.enrollment.status ===
                                    constants.payment.status.paid.name ? (
                                        <Link
                                            style={{ color: colors.green }}
                                            onClick={() =>
                                                setPaymentId(row.enrollment.id)
                                            }
                                        >
                                            {formatPriceByLocale(
                                                row.enrollment.price,
                                                cxt.sc.city.country.currency,
                                            )}
                                        </Link>
                                    ) : (
                                        <Link
                                            style={{ color: colors.red }}
                                            onClick={() =>
                                                setPaymentId(row.enrollment.id)
                                            }
                                        >
                                            {formatPriceByLocale(
                                                row.enrollment.price,
                                                cxt.sc.city.country.currency,
                                            )}
                                        </Link>
                                    )}

                                    {'refund' in row.enrollment &&
                                    row.enrollment.refund ? (
                                        <ReplayIcon fontSize="small" />
                                    ) : (
                                        ''
                                    )}
                                </div>
                            ),
                    },
                    {
                        title: '',
                        value: (row) => (
                            <PersonOutlineOutlinedIcon
                                fontSize="small"
                                className={classes.infoButton}
                                onClick={() => {
                                    setOpenMshipDialog(true);
                                    setSelectedMshipId(row.user.mship.id);
                                }}
                            />
                        ),
                    },
                ]}
            />

            {!isMutationsLoading && event && category && participant && (
                <ModalParticipant
                    item={{
                        ...participant,
                        paymentForm: participant?.paymentForm || '',
                    }}
                    event={event}
                    category={category}
                    onSave={(participantToSave) => {
                        setParticipant(participantToSave);
                        saveParticipant(participantToSave);
                    }}
                    onClose={() => {
                        setParticipant(null);
                    }}
                    onDelete={(participantToDelete) => {
                        deleteParticipantMutation.mutate({
                            participantId: participantToDelete.id,
                        });
                    }}
                    disableFieldsForParticipantWithUser
                />
            )}

            {paymentId && (
                <PaymentModal
                    idPayment={paymentId}
                    onClose={() => setPaymentId(null)}
                    onPay={updateParticipants}
                    onRefund={updateParticipants}
                    onRecovered={updateParticipants}
                    onDeleted={updateParticipants}
                />
            )}

            <SttMshipDialog
                open={openMshipDialog}
                mshipId={selectedMshipId}
                onClose={() => {
                    setOpenMshipDialog(false);
                    loadParticipants();
                }}
            />

            {cxt.sc?.sporttiaStripeLocationId && posPaymentParams !== null ? (
                <POSStripePaymentDialog
                    paymentData={posPaymentParams}
                    onClose={() => setPosPaymentParams(null)}
                    onSuccess={() => {
                        setParticipant(null);
                        loadParticipants();
                    }}
                />
            ) : (
                <POSPaymentDialog
                    open={posPaymentParams !== null}
                    paymentData={posPaymentParams}
                    onClose={() => setPosPaymentParams(null)}
                    onSuccess={() => {
                        setParticipant(null);
                        loadParticipants();
                    }}
                />
            )}
        </>
    );
}
