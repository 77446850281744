import React from 'react';
import { SttFormSelect } from '../all';

const thisYear = new Date().getFullYear();

/**
 * SttSelectYear: draw a <select> featuring years, from startYear, up to pastYears into the past and futureYears into the future
 * Note that this component deals with year values only, i.e. 2020 instead of "2020-01-01" for example
 * string name - control name
 * string caption - text field caption
 * string defVal - currently selected year
 * int startYear - year to start counting, for example 2020
 * int pastYears - years into the past, for example 5
 * int futureYears - same, but for the future
 * function onChange - callback for when an option is selected
 * NOTE AGAIN: KEEP IN MIND THIS COMPONENT DEALS IN NUMBERS (2019, 2020, etc) NOT DATE OBJECTS OR DATE STRINGS
 */
export function SttSelectYear({
    name,
    caption,
    defVal,
    startYear = thisYear,
    pastYears = 5,
    futureYears = 0,
    onChange,
}) {
    const options = [];

    for (let i = startYear - pastYears; i <= startYear + futureYears; i++) {
        options.push({
            caption: i,
            value: i,
        });
    }

    return (
        <SttFormSelect
            name={name}
            caption={caption}
            defVal={defVal}
            options={options}
            onChange={onChange}
        />
    );
}
