import React, { useState, useContext } from 'react';
import {
    Box,
    Divider,
    Grid,
    Typography,
    makeStyles,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../sporttia/AppContext';
import { SttButtonAccept } from '../../../sporttia/buttons/SttButtonAccept';
import { SttDialog } from '../../../sporttia/all';
import { formatPriceByLocale, redirectLogin } from '../../../lib/utils';

const useStyles = makeStyles((theme) => ({
    rateName: {
        fontSize: 18,
    },
    rateGridItem: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    free: {
        color: '#3c763d',
    },
}));

export default function RateList({ rates, onSelected, inIscTime }) {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'));

    function SignupButton({ rate }) {
        return (
            <SttButtonAccept
                style={{ width: 270 }}
                fullWidth={!!smUp}
                caption={
                    rate.signupOnline
                        ? `${cxt.t('page.homeA.at.1')}`
                        : cxt.t('MoreInfo')
                }
                onClick={() =>
                    rate.signupOnline && !cxt.logged
                        ? redirectLogin(history)
                        : onSelected(rate)
                }
                disabled={!inIscTime}
            />
        );
    }

    if (!rates || rates.length === 0) {
        return (
            <Typography variant="body1">{cxt.t('activity.noRates')}</Typography>
        );
    }

    return (
        <div>
            {rates.map((rate, i) => (
                <React.Fragment key={i}>
                    {i > 0 && (
                        <Box my={1}>
                            <Divider />
                        </Box>
                    )}
                    <Box p={1}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Typography className={classes.rateName}>
                                    {rate.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    display="flex"
                                    justifyContent={
                                        smUp ? 'flex-end' : 'flex-start'
                                    }
                                >
                                    <Typography variant="body1">
                                        <span>{cxt.t('SignedCount')}:</span>
                                        <span>&nbsp;</span>
                                        <b>
                                            {rate.maxStudents
                                                ? `${rate.numStudents} / ${rate.maxStudents}`
                                                : rate.numStudents}
                                        </b>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box className={classes.rateGridItem}>
                                    <span>
                                        {`${cxt.t('Enrollment')}:`}&nbsp;
                                    </span>
                                    {rate.enrollment ? (
                                        <b>
                                            {formatPriceByLocale(
                                                rate?.enrollment,
                                                rate?.enrollmentShape?.currency,
                                                rate?.enrollmentShape?.locale,
                                            )}
                                        </b>
                                    ) : (
                                        <span className={classes.free}>
                                            {cxt.t('Free')}
                                        </span>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box className={classes.rateGridItem}>
                                    <span>{`${cxt.t('Fee')}:`}&nbsp;</span>
                                    {rate.fee ? (
                                        <b>{`${formatPriceByLocale(
                                            rate?.fee,
                                            rate?.feeShape?.currency,
                                            rate?.feeShape?.locale,
                                        )} / ${rate.periodLang}`}</b>
                                    ) : (
                                        <span className={classes.free}>
                                            {cxt.t('NoFees')}
                                        </span>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                {smUp ? (
                                    <Box
                                        display="flex"
                                        justifyContent="flex-end"
                                    >
                                        <SignupButton rate={rate} />
                                    </Box>
                                ) : (
                                    <SignupButton rate={rate} />
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </React.Fragment>
            ))}
        </div>
    );
}
