import React, { useContext, useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import moment from 'moment';
import { AppContext, SttDialog, SttList } from '../all';
import { useCrud, useInteractionsFiles } from '../../lib/hooks';
import SigCapture from '../../components/signature/SigCapture';
import { fullName } from '../../lib/utils';

/**
 * SttConsentList - list of user-accepted consent
 * @param consents
 * @param onAdd
 * @param onDelete
 */
export function SttSelectConsentList({
    user,
    consents,
    onDelete,
    onSign,
    onClose,
}) {
    const { uploadFile, downloadFile } = useInteractionsFiles();
    const [addModal, setAddModal] = useState(false);
    const [signing, setSigning] = useState(null);
    const [templates, setTemplates, , Get] = useCrud();

    const cxt = useContext(AppContext);

    useEffect(() => {
        loadTemplates();
    }, []);

    function loadTemplates() {
        Get(`/scs/${cxt.sc.id}/consents/templates`, { rows: 1000 }).then(
            ({ rows }) => {
                setTemplates(rows);
            },
        );
    }

    function selected(consent) {
        if (consent.file && consent.file.id) {
            downloadFile(
                `/files/${consent.file.id}.pdf`,
                'application/pdf',
                null,
                `${cxt.t('Consent')} ${fullName(user)} - ${
                    consent.template.name
                }.pdf`,
            );
        }
    }

    function selectTemplate(template) {
        setSigning(template);
        setAddModal(false);
    }

    function finish() {
        onSign && onSign();
        onClose && onClose();
    }

    function captureImage(dataUrl) {
        fetch(dataUrl)
            .then((response) => response.blob())
            .then(
                (blob) => new File([blob], 'temp.jpg', { type: 'image/jpeg' }),
            )
            .then((file) => {
                uploadFile(
                    {
                        name: `Firma_${user.name}_${moment().format(
                            'DD-MM-YYYY',
                        )}`,
                        type: 'SIGNATURE',
                        format: 'image/jpeg',
                        idUser: user.id,
                        idTemplate: signing.id,
                    },
                    file,
                )
                    .then((response) => {
                        const { file } = response;
                        if (file?.id) {
                            finish();
                        } else {
                            console.log("No 'file' en la respuesta.");
                        }
                    })
                    .catch(console.log);
            });
    }

    function closeModal() {
        setAddModal(false);
    }

    return (
        <>
            {signing ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <SigCapture
                        text={
                            signing.textShort ? signing.textShort : signing.text
                        }
                        width={300}
                        height={200}
                        onCapture={(dataUrl) => captureImage(dataUrl)}
                    />
                </Box>
            ) : (
                <SttList
                    fullWidth
                    title={cxt.t('Consents')}
                    data={(consents || []).map((consent) => ({
                        caption: consent.template.name,
                        objToSelect: consent,
                    }))}
                    onAdd={() => setAddModal(true)}
                    onSelect={(consent) => selected(consent)}
                    // onDelete={consent => onDelete && onDelete(consent)}
                />
            )}
            <SttDialog
                open={addModal}
                onClose={closeModal}
                title={cxt.t('selectConsentTemplate')}
                content={
                    <SttList
                        paper
                        fullWidth
                        title={cxt.t('consentTemplates')}
                        data={(templates || []).map((template) => ({
                            caption: template.name,
                            objToSelect: template,
                        }))}
                        onSelect={(template) => selectTemplate(template)}
                    />
                }
                buttons={[
                    {
                        type: 'close',
                        onClick: closeModal,
                    },
                ]}
            />
        </>
    );
}
