import React, { useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import DeviceAuthDialog from '../../../components/devices/DeviceAuthDialog';
import DeviceList from '../../../components/devices/DeviceList';
import deviceConstants from '../../../components/devices/deviceConstants';
import useBonosService from '../../../services/BonosService';
import SttError from '../../../components/error/SttError';
import BonoDeviceAuth from '../../../types/models/BonoDeviceAuth';
import Bono from '../../../types/models/Bono';

type BonoDevicesProps = {
    item: Bono;
};

/**
 * BonoDevices : Bono screen devices tab section. Contains the list of authorized devices for this bono
 */
export default function BonoDevices({ item }: BonoDevicesProps) {
    const queryClient = useQueryClient();

    const [viewAuth, setViewAuth] = useState<BonoDeviceAuth>();

    const bonosService = useBonosService();
    const getBonoDevicesAuthsQuery = bonosService.useGetBonoDevicesAuths(
        item.id,
        { rows: 25 },
    );

    if (getBonoDevicesAuthsQuery.isLoading) {
        return (
            <Box display="flex" justifyContent="center">
                <CircularProgress />
            </Box>
        );
    }

    if (getBonoDevicesAuthsQuery.isError) {
        return <SttError />;
    }

    if (!getBonoDevicesAuthsQuery.isSuccess) {
        return null;
    }

    return (
        <>
            <DeviceList
                item={item}
                type={deviceConstants.BONO}
                auths={getBonoDevicesAuthsQuery.data}
                onCreate={(device: BonoDeviceAuth) => setViewAuth(device)}
                onSelect={(device: BonoDeviceAuth) => setViewAuth(device)}
            />

            {viewAuth && (
                <DeviceAuthDialog
                    open
                    item={viewAuth}
                    onSave={() =>
                        queryClient.invalidateQueries(
                            getBonoDevicesAuthsQuery.queryKey,
                        )
                    }
                    onClose={() => setViewAuth(undefined)}
                />
            )}
        </>
    );
}
