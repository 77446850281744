import React, { useContext, useState } from 'react';
import { Box, CircularProgress, Dialog, DialogTitle } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import useGroupsService from '../../../services/GroupsService';
import DiscountList from '../../../components/discounts/DiscountsList';
import SttError from '../../../components/error/SttError';
import { AppContext } from '../../../sporttia/AppContext';
import { getErrorMessage } from '../../../lib/utils';
import { GetGroupDiscounts200 } from '../../../types/api/paths/Group';
import translations from '../../../translations';
import useScsService from '../../../services/ScsService';

type GroupDiscountsListProps = {
    groupId: number;
};

export default function GroupDiscountsList({
    groupId,
}: GroupDiscountsListProps) {
    const cxt = useContext(AppContext)!;

    const [openDialog, setOpenDialog] = useState(false);

    const queryClient = useQueryClient();

    const groupsService = useGroupsService();
    const getGroupDiscountsQuery = groupsService.useGetGroupDiscounts(groupId, {
        rows: 1000,
    });

    const scsService = useScsService();
    const getScDiscountsQuery = scsService.useGetScsDiscounts(
        cxt.sc!.id,
        { rows: 1000 },
        { enabled: openDialog },
    );

    const createGroupDiscountMutation = groupsService.useCreateGroupDiscount({
        onSuccess: (result) => {
            queryClient.setQueryData<GetGroupDiscounts200 | undefined>(
                getGroupDiscountsQuery.queryKey,
                (old) => {
                    if (!old) {
                        return undefined;
                    }

                    return {
                        ...old,
                        count: old.count + 1,
                        rows: [...old.rows, result.discount],
                    };
                },
            );

            cxt.showMessage('S', cxt.t(translations.group.discountAdded));
            setOpenDialog(false);
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });

    const deleteGroupDiscountMutation = groupsService.useDeleteGroupDiscount({
        onSuccess: (result, variables) => {
            queryClient.setQueryData<GetGroupDiscounts200 | undefined>(
                getGroupDiscountsQuery.queryKey,
                (old) => {
                    if (!old) {
                        return undefined;
                    }

                    return {
                        ...old,
                        count: old.count - 1,
                        rows: old?.rows.filter(
                            (row) => row.id !== variables.discountId,
                        ),
                    };
                },
            );

            cxt.showMessage('S', cxt.t(translations.group.discountDeleted));
            setOpenDialog(false);
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });

    if (
        getGroupDiscountsQuery.isLoading ||
        createGroupDiscountMutation.isLoading ||
        deleteGroupDiscountMutation.isLoading
    ) {
        return (
            <Box display="flex" justifyContent="center" paddingTop={12}>
                <CircularProgress />
            </Box>
        );
    }

    if (getGroupDiscountsQuery.isError || getScDiscountsQuery.isError) {
        return <SttError />;
    }

    if (!getGroupDiscountsQuery.isSuccess) {
        return null;
    }

    return (
        <>
            <DiscountList
                discounts={getGroupDiscountsQuery.data.rows}
                onAdd={() => setOpenDialog(true)}
                onDelete={(discount) => {
                    deleteGroupDiscountMutation.mutate({
                        discountId: discount.id,
                        groupId,
                    });
                }}
            />

            <Dialog
                onClose={() => setOpenDialog(false)}
                open={openDialog}
                fullWidth
                maxWidth="xs"
            >
                {getScDiscountsQuery.isLoading && (
                    <Box display="flex" justifyContent="center" paddingY={12}>
                        <CircularProgress />
                    </Box>
                )}

                {getScDiscountsQuery.isSuccess && (
                    <>
                        <DialogTitle>
                            {cxt.t(translations.generic.discounts)}
                        </DialogTitle>
                        <DiscountList
                            // Filtrar descuentos ya añadidos al grupo
                            discounts={getScDiscountsQuery.data.rows.filter(
                                (scDiscount) =>
                                    !getGroupDiscountsQuery.data.rows.some(
                                        (groupDiscount) =>
                                            groupDiscount.id === scDiscount.id,
                                    ),
                            )}
                            onSelect={(discount) => {
                                createGroupDiscountMutation.mutate({
                                    discountId: discount.id,
                                    groupId,
                                });
                            }}
                            showTitle={false}
                        />
                    </>
                )}
            </Dialog>
        </>
    );
}
