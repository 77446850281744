import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import {
    AppContext,
    SttTopControls,
    SttButtonFab,
} from '../../../sporttia/all';
import SttCachedTable from '../../../sporttia/SttCachedTable';
import { useToggle, useTranslations, usePageHeader } from '../../../lib/hooks';
import Teacher from './Teacher';
import { SPORTCENTER_ACTIVITY_TEACHERS } from '../../../lib/queryKeys';
import useScsService from '../../../services/ScsService';

export default function Teachers({}) {
    usePageHeader('Teachers');

    const cxt = useContext(AppContext);
    const history = useHistory();
    const scsService = useScsService();
    const { translate } = useTranslations();
    const [teachers, setTeachers] = useState();
    const [filters, setFilters] = useState({
        name: '',
    });
    const queryClient = useQueryClient();
    const [teacherIdAndName, setTeacherIdAndName] = useState(null);

    // Trash hook
    const [trash, toggleTrash] = useToggle(() => {
        queryClient.invalidateQueries(SPORTCENTER_ACTIVITY_TEACHERS);
    });

    /**
     * Go to create
     */
    const goCreateNew = () => {
        setTeacherIdAndName({ name: translate('New'), id: 'create' });
    };

    // -----| Render |-----
    return (
        <>
            <Paper>
                <SttTopControls
                    p={2}
                    fields={[
                        {
                            caption: cxt.t('Name'),
                            name: 'name',
                            type: 'text',
                            value: filters.name,
                            onEnterKey: queryClient.invalidateQueries(
                                SPORTCENTER_ACTIVITY_TEACHERS,
                            ),
                        },
                    ]}
                    onChange={({ name, value }) =>
                        setFilters({ ...filters, [name]: value })
                    }
                    onToggleTrash={toggleTrash}
                />

                <SttCachedTable
                    queryKey={SPORTCENTER_ACTIVITY_TEACHERS}
                    queryFn={(params) =>
                        scsService.getTeachers(cxt?.sc?.id, params)
                    }
                    queryParams={{ ...filters, trash }}
                    onClickRow={(row) => {
                        setTeacherIdAndName({ name: row.name, id: row.id });
                    }}
                    columns={[
                        {
                            title: cxt.t('Login'),
                            field: 'login',
                            align: 'left',
                        },
                        {
                            type: 'link',
                            title: cxt.t('Name'),
                            field: 'fullName',
                            align: 'left',
                        },
                        {
                            title: cxt.t('Email'),
                            field: 'email',
                        },
                        {
                            title: cxt.t('Phone'),
                            field: 'phone',
                        },
                        {
                            title: cxt.t('DNI'),
                            field: 'dni',
                        },
                    ]}
                />

                <SttButtonFab onClick={goCreateNew} />
            </Paper>

            {teacherIdAndName && (
                <Teacher
                    teacherId={teacherIdAndName.id}
                    onClose={() => {
                        setTeacherIdAndName(null);
                    }}
                    title={teacherIdAndName.name}
                />
            )}
        </>
    );
}
