import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';

/**
 * Generates an array of time options based on the given min time, max time, and interval.
 * @param {string} minTime - The minimum time in the format 'HH:mm'.
 * @param {string} maxTime - The maximum time in the format 'HH:mm'.
 * @param {number} interval - The interval in minutes between each time option.
 * @param {Object} options - Additional options
 * @param {boolean} options.includeMaxTime - Whether or not to include the max time in the generated options.
 * @return {Array} An array of time options in the format { minutes, label, hhmmss }.
 */
function getTimeRangeOptions(minTime, maxTime, interval, options = {}) {
    // Init time array (time format HH:mm)
    let times = [];
    let currentTime = moment(minTime, 'HH:mm');
    const max = moment(maxTime, 'HH:mm');
    while (currentTime.isSameOrBefore(max)) {
        times.push({
            minutes: currentTime.hours() * 60 + currentTime.minutes(),
            label: currentTime.format('HH:mm'),
            hhmmss: currentTime.format('HH:mm:ss'),
        });
        currentTime = currentTime.clone().add(interval, 'minutes');
    }
    if (options.includeMaxTime) {
        times.push({
            minutes: max.hours() * 60 + max.minutes(),
            label: max.format('HH:mm'),
            hhmmss: max.format('HH:mm:ss'),
        });
    } else {
        times = times.filter((e) => e.minutes % interval === 0);
    }
    return times;
}

/**
 * Form time component.
 * Presents a dropdown list of time options with a specified interval, starting at a given minimum time and ending at a given maximum time.
 *
 * @param {boolean} [grid=false] - Indicates if the component should be wrapped in a Grid element
 * @param {string} name - Name of the input field
 * @param {string} caption - Label text for the input field
 * @param {string} defVal - Default selected value
 * @param {string} [min='06:00:00'] - Starting hour, in the format of 'HH:mm:ss'
 * @param {string} [max='23:55:00'] - Ending hour, in the format of 'HH:mm:ss'
 * @param {number} [interval=30] - Interval between options, in minutes
 * @param {boolean} [disableClearable=true] - Indicates if the clear button in the dropdown list should be disabled
 * @param {boolean} [disabled=false] - Indicates if the component should be disabled
 * @param {Function} onChange - Callback function when the selected time is changed
 * @param {Object} timeRangeOptions - Additional time range options
 * @param {boolean} timeRangeOptions.includeMaxTime - Whether or not to include the max time in the generated options
 * @param {Object} rest - additional props
 */
export function SttFormTime({
    grid = false,
    name,
    caption,
    defVal,
    min = '06:00:00',
    max = '23:55:00',
    interval = 30,
    disableClearable = true,
    disabled = false,
    onChange,
    timeRangeOptions = { includeMaxTime: false },
    ...rest
}) {
    const times = getTimeRangeOptions(min, max, interval, timeRangeOptions);

    // Main content
    const content = (
        <Autocomplete
            disabled={disabled}
            options={times}
            getOptionLabel={(option) => option?.label || ''}
            value={
                times.find(
                    (time) => time?.label === defVal || time?.hhmmss === defVal,
                ) || ''
            }
            onChange={(_, time) =>
                onChange({
                    name,
                    value: time?.label || '',
                })
            }
            disableClearable={disableClearable}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    size="small"
                    label={caption}
                    variant="outlined"
                />
            )}
        />
    );

    // Wrapped by grid
    return grid ? (
        <Grid item {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
