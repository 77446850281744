import React, { useState, useEffect, useContext } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { AppContext } from '../sporttia/all';
import SCHeader from './sc/SCHeader';
import AdminHeader from './admin/AdminHeader';
import UserHeader from './user/UserHeader';
import PublicHeader from './PublicHeader';
import { paths } from '../pages/Pages';
import constants from '../config/constants';

export default function Header() {
    const cxt = useContext(AppContext)!;
    const location = useLocation();
    const [, setLocationRoute] = useState<unknown>();

    /**
     * When "pathname" was change we will update "locationRoute".
     */
    useEffect(() => {
        setLocationRoute(
            paths.find((route) => matchPath(location.pathname, route)),
        );
    }, [location.pathname]);

    /**
     * Render header.
     * Existen 4 tipos de header:
     *  - Usuario conectado con rol "SPORTCENTER".
     *  - Usuario conectado con rol "USER" o "TEACHER".
     *  - Usuario conectado con rol "ADMIN".
     *  - Usuario no conectado.
     *
     * Destacar que en el caso de que la ruta tenga el parámetro "common" activado se renderizará
     * el header correspondiente al usuario conectado (si lo está), en caso contrario se renderizará
     * el header publico.
     */
    if (cxt.user && cxt.user.role === constants.roles.sportcenter) {
        // TODO: Left menu rendering (included in SCHeader) is gated behind user data loading, this shouldn't be the case (the menu visibly pops in)
        return <SCHeader />;
    }
    if (
        cxt.user &&
        (cxt.user.role === constants.roles.user ||
            cxt.user.role === constants.roles.teacher)
    ) {
        return <UserHeader />;
    }
    if (cxt.user && cxt.user.role === constants.roles.admin) {
        return <AdminHeader />;
    }
    return <PublicHeader />;
}
