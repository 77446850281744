import React, { useContext, useState } from 'react';
import { Grid, Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { AppContext, SttDialog } from '../../sporttia/all';
import SttValidatedForm from '../../sporttia/SttValidatedForm';
import { useLoader } from '../../lib/hooks';
import useUsersService from '../../services/UsersService';
import { getObjectWithoutEmptyStrings } from '../../lib/utils';

/**
 * User creation dialog.
 * @param open If the value is true, the dialog will open and close otherwise.
 * @param onClose Action taken when closing the dialog.
 * @param onCreate Launched when a new customer is created.
 * @returns {JSX.Element}
 */
export default function UserCreationDialog({ open, onClose, onCreate }) {
    const cxt = useContext(AppContext);
    const form = useForm();
    const usersService = useUsersService();
    const [lastUserCreated, setLastUserCreated] = useState(null);
    const watchProvince = form.watch('province');
    const createMutation = useMutation(
        (params) => usersService.create(params?.params),
        {
            onSuccess: (data) => {
                if (data?.user) {
                    setLastUserCreated(data?.user);
                    onCreate?.(data?.user);
                } else if (data?.error) {
                    cxt.showMessage('W', data?.error?.msg);
                }
            },
            onError: (error) => {
                cxt.showMessage('W', error.error.msg);
            },
        },
    );
    const [, loader] = useLoader([createMutation.status]);

    /**
     * Method executed when the user clicks on save or create client.
     */
    const mutateUser = (formData) => {
        const formatedFormData = getObjectWithoutEmptyStrings(formData);

        delete formatedFormData.province;
        delete formatedFormData.city;
        delete formatedFormData.group;

        if (!formatedFormData.gender) {
            formatedFormData.gender = 'UNKNOWN';
        }

        if (formData?.city) {
            formatedFormData.idCity = formData?.city?.id;
        }

        if (formData?.group) {
            formatedFormData.idGroup = formData?.group?.id;
        }

        createMutation.mutate({ params: formatedFormData });
    };

    return (
        <SttDialog
            title={cxt.t('CreateNewUser')}
            open={open}
            onClose={onClose}
            content={
                <>
                    {loader}
                    <Grid container spacing={3}>
                        <SttValidatedForm
                            form={form}
                            fields={[
                                {
                                    type: 'textInput',
                                    name: 'name',
                                    caption: cxt.t('Name'),
                                    md: 6,
                                },
                                {
                                    type: 'textInput',
                                    name: 'surname1',
                                    caption: cxt.t('Surname1'),
                                    md: 6,
                                },
                                {
                                    type: 'textInput',
                                    name: 'surname2',
                                    caption: cxt.t('Surname2'),
                                    md: 6,
                                },
                                {
                                    type: 'textInput',
                                    name: 'dni',
                                    caption: cxt.t('DNI'),
                                    md: 6,
                                },
                                {
                                    type: 'province',
                                    name: 'province',
                                    caption: cxt.t('Province'),
                                    md: 6,
                                },
                                {
                                    type: 'city',
                                    name: 'city',
                                    caption: cxt.t('City'),
                                    idProvince: watchProvince?.id,
                                    md: 6,
                                },
                                {
                                    type: 'textInput',
                                    name: 'address',
                                    caption: cxt.t('Address'),
                                    md: 6,
                                },
                                {
                                    type: 'textInput',
                                    name: 'postalCode',
                                    caption: cxt.t('PostalCode'),
                                    md: 6,
                                },
                                {
                                    type: 'textInput',
                                    name: 'email',
                                    caption: cxt.t('Email'),
                                    md: 6,
                                },
                                {
                                    type: 'textInput',
                                    name: 'mobile',
                                    caption: cxt.t('Mobile'),
                                    md: 6,
                                },
                                {
                                    type: 'date',
                                    name: 'birthday',
                                    caption: cxt.t('Birthday'),
                                    md: 6,
                                },
                                {
                                    type: 'select',
                                    name: 'gender',
                                    caption: cxt.t('Gender'),
                                    options: [
                                        {
                                            caption: cxt.t('PT.SELECT'),
                                            value: 'UNKNOWN',
                                        },
                                        {
                                            caption: cxt.t('MALE'),
                                            value: 'MALE',
                                        },
                                        {
                                            caption: cxt.t('FEMALE'),
                                            value: 'FEMALE',
                                        },
                                    ],
                                    disableClearable: true,
                                    md: 6,
                                },
                                {
                                    type: 'textInput',
                                    name: 'iban',
                                    caption: cxt.t('IBAN'),
                                    md: 6,
                                },
                                {
                                    type: 'textInput',
                                    name: 'password',
                                    caption: cxt.t('Password'),
                                    md: 6,
                                },
                                {
                                    type: 'group',
                                    name: 'group',
                                    caption: cxt.t('Group'),
                                    md: 6,
                                },
                                {
                                    type: 'check',
                                    name: 'sendWelcomen',
                                    caption: cxt.t('NotificationUserSC'),
                                    md: 6,
                                },
                            ]}
                        />
                    </Grid>

                    <Box mt={2}>
                        <Alert severity="warning">
                            {cxt.t('popup.purse.alertNoEmail')}
                        </Alert>
                    </Box>

                    {lastUserCreated && (
                        <Box mt={2}>
                            <Alert severity="success">
                                {cxt.t('popup.purse.userCreated')}
                                <br />
                                <br />
                                {cxt.t('User')}: {lastUserCreated.login}
                            </Alert>
                        </Box>
                    )}
                </>
            }
            buttons={[
                {
                    show: lastUserCreated == null,
                    type: 'accept',
                    onClick: form.handleSubmit((formData) => {
                        mutateUser(formData);
                    }),
                },
                {
                    show: lastUserCreated !== null,
                    caption: cxt.t('popup.purse.cleanAndAnother'),
                    onClick: () => {
                        form.reset({});
                        setLastUserCreated(null);
                    },
                },
            ]}
        />
    );
}
