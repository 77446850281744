import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { AppContext, SttFormSelect } from '../all';

/**
 * Select interval
 *
 * 1 day, 2 day, 1 hour, 2 hour, etc...
 *
 */
export function SttFormInterval({
    grid = false,
    name,
    caption,
    defVal,
    onChange,
    ...rest
}) {
    const cxt = useContext(AppContext);

    const intervals = [
        { id: '0000-00-00 00:00:00', label: cxt.t('0000-00-00 00:00:00') },
        { id: '0000-00-00 01:00:00', label: cxt.t('0000-00-00 01:00:00') },
        { id: '0000-00-00 02:00:00', label: cxt.t('0000-00-00 02:00:00') },
        { id: '0000-00-00 03:00:00', label: cxt.t('0000-00-00 03:00:00') },
        { id: '0000-00-00 04:00:00', label: cxt.t('0000-00-00 04:00:00') },
        { id: '0000-00-01 00:00:00', label: cxt.t('0000-00-01 00:00:00') },
        { id: '0000-00-02 00:00:00', label: cxt.t('0000-00-02 00:00:00') },
        { id: '0000-00-03 00:00:00', label: cxt.t('0000-00-03 00:00:00') },
        { id: '0000-00-04 00:00:00', label: cxt.t('0000-00-04 00:00:00') },
        { id: '0000-00-05 00:00:00', label: cxt.t('0000-00-05 00:00:00') },
        { id: '0000-00-06 00:00:00', label: cxt.t('0000-00-06 00:00:00') },
        { id: '0000-00-07 00:00:00', label: cxt.t('0000-00-07 00:00:00') },
        { id: '0000-00-14 00:00:00', label: cxt.t('0000-00-14 00:00:00') },
        { id: '0000-01-00 00:00:00', label: cxt.t('0000-01-00 00:00:00') },
        { id: '0000-02-00 00:00:00', label: cxt.t('0000-02-00 00:00:00') },
        { id: '0000-03-00 00:00:00', label: cxt.t('0000-03-00 00:00:00') },
        { id: '0000-04-00 00:00:00', label: cxt.t('0000-04-00 00:00:00') },
        { id: '0000-05-00 00:00:00', label: cxt.t('0000-05-00 00:00:00') },
        { id: '0000-06-00 00:00:00', label: cxt.t('0000-06-00 00:00:00') },
    ];

    // Render
    return (
        <SttFormSelect
            grid={grid}
            name={name}
            caption={caption || cxt.t('Interval')}
            defVal={defVal}
            onChange={onChange}
            options={intervals}
            {...rest}
        />
    );
}
