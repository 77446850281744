import React, { useState, useEffect, useContext } from 'react';
import { Box, Link } from '@material-ui/core';
import { SttDialog } from '../../../sporttia/dialogs/SttDialog';

export default function SttLinkList({ items }) {
    const [open, setOpen] = useState(false);
    const [viewContent, setViewContent] = useState(null);

    useEffect(() => {
        setOpen(viewContent !== null);
    }, [viewContent]);

    // Nothing to render
    if (!items) {
        return null;
    }

    return (
        <>
            <Box>
                {items.map((item, i) => (
                    <Box key={i} mt={3}>
                        <Link
                            onClick={() =>
                                setViewContent(
                                    // Interceptamos el item para clonarlo añadiéndole un prop onClose, que llama al onClose original del item (si existe) antes de cerrar el modal general. Parece magia negra porque, de hecho, lo es.
                                    <item.content.type
                                        {...item.content.props}
                                        onClose={() => {
                                            item.content.props.onClose &&
                                                item.content.props.onClose();
                                            setViewContent(null);
                                        }}
                                    />,
                                )
                            }
                        >
                            {item.caption}
                        </Link>
                    </Box>
                ))}
            </Box>
            <SttDialog
                open={open}
                onClose={() => setOpen(false)}
                title={undefined}
                content={viewContent}
                buttons={[
                    {
                        type: 'close',
                        onClick: () => setOpen(false),
                    },
                ]}
            />
        </>
    );
}
