import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { SttDialog } from '../../sporttia/dialogs/SttDialog';
import SttValidatedForm from '../../sporttia/SttValidatedForm';
import { useLoader, useTranslations } from '../../lib/hooks';
import usePaymentsService from '../../services/PaymentsService';
import { AppContext } from '../../sporttia/AppContext';

/**
 * Dialog to choose concept and user to associate the payments to be paid.
 * @param paymentsIds Payments IDs array.
 * @param onClose Function executed when closing the dialog.
 * @param onCreate Launched when a new payment is created.
 * @returns {JSX.Element}
 */
function PayMultiplePaymentsDialog({ paymentsIds, onClose, onCreate, userID }) {
    const cxt = useContext(AppContext);
    const { translate } = useTranslations();
    const form = useForm();
    const paymentsService = usePaymentsService();

    const createMutation = useMutation(
        (params) => paymentsService.create(params?.params),
        {
            onSuccess: (response) => {
                if (response?.error?.msg) {
                    cxt.showMessage('E', response?.error?.msg);
                } else {
                    onCreate(response);
                }
            },
            onError: (response) => cxt.showMessage('E', response?.error?.msg),
        },
    );

    const [, loader] = useLoader([createMutation.status]);

    useEffect(() => {
        if (paymentsIds === null) {
            form.reset({
                childrenConcept: '',
                childrenUserId: '',
            });
        }
    }, [paymentsIds]);

    return (
        <SttDialog
            title="Cobrar pagos"
            open={paymentsIds !== null}
            onClose={onClose}
            content={
                <>
                    {loader}
                    <SttValidatedForm
                        form={form}
                        fields={[
                            {
                                type: 'textinput',
                                name: 'childrenConcept',
                                caption: translate('Concept'),
                            },
                        ]}
                    />
                </>
            }
            buttons={[
                {
                    type: 'save',
                    onClick: form.handleSubmit((formData) =>
                        createMutation.mutate({
                            params: {
                                childrenConcept: formData?.childrenConcept,
                                childrenUserId: userID,
                                childrenPaymentIds: paymentsIds,
                            },
                        }),
                    ),
                },
            ]}
        />
    );
}

export default PayMultiplePaymentsDialog;
