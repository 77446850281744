import React, { useState, useEffect, useContext } from 'react';
import { Box, Button } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { AppContext, SttFormTextarea } from '../../../sporttia/all';

export default function MessageCommentBox({
    onSendComment,
    onSendPrivateMessage,
    senderName,
}) {
    const cxt = useContext(AppContext);
    const [text, setText] = useState('');

    function SendButton() {
        return (
            <IconButton
                onClick={() => sendComment(text)}
                style={{ color: '#495e0b' }}
            >
                <Typography variant="button">
                    {cxt.t('Comment')}&nbsp;
                </Typography>

                <SendIcon style={{ fontSize: 20 }} />
            </IconButton>
        );
    }

    /**
     * Send comment
     */
    const sendComment = () => {
        onSendComment(text);
        setText('');
    };

    // -----| Render |-----
    return (
        <Box ml={8} mb={3}>
            <SttFormTextarea
                grid
                autoFocus
                rows={2}
                defVal={text}
                onChange={({ value }) => setText(value)}
                InputProps={{ endAdornment: <SendButton /> }}
            />

            {onSendPrivateMessage &&
                cxt.logged &&
                cxt.user &&
                cxt.user.role !== cxt.constants.roleSC && (
                    <Box mt={1} justifyContent="flex-end">
                        <Link onClick={() => onSendPrivateMessage()}>
                            {cxt.t('popup.message.startPrivate')}: {senderName}
                        </Link>
                    </Box>
                )}
        </Box>
    );
}
