import React, { useContext } from 'react';
import { Grid, GridProps } from '@material-ui/core';
import { SttFormDate } from './SttFormDate';
import { SttFormCheck, AppContext } from '../all';

type SttFormPeriodProps = {
    grid?: boolean;
    caption?: string;
    captionIni?: string;
    captionEnd?: string;
    checked?: boolean;
    onToggle?: (value: boolean) => void;
    onChange?: ({ name, value }: { name: string; value: string }) => void;
    nameIni: string;
    nameEnd: string;
    defValIni?: string;
    defValEnd?: string;
    disabled?: boolean;
} & Omit<GridProps, 'onChange'>;

export function SttFormPeriod({
    grid = false,
    caption,
    captionIni,
    captionEnd,
    checked,
    onToggle,
    onChange,
    nameIni,
    nameEnd,
    defValIni,
    defValEnd,
    disabled,
    ...rest
}: SttFormPeriodProps) {
    const cxt = useContext(AppContext)!;
    const checkingEnabled = checked !== undefined;

    const content = (
        <>
            {checkingEnabled && (
                <SttFormCheck
                    name="check"
                    caption={caption}
                    checked={checked}
                    onChange={({ value }) => onToggle?.(value)}
                />
            )}

            <Grid container spacing={3}>
                <SttFormDate
                    grid
                    xs={12}
                    sm={6}
                    caption={
                        !checkingEnabled
                            ? captionIni || cxt.t('PeriodFrom')
                            : undefined
                    }
                    name={nameIni}
                    defVal={defValIni}
                    onChange={onChange}
                    disabled={(checkingEnabled && !checked) || disabled}
                />

                <SttFormDate
                    grid
                    xs={12}
                    sm={6}
                    caption={
                        !checkingEnabled
                            ? captionEnd || cxt.t('Until')
                            : undefined
                    }
                    name={nameEnd}
                    defVal={defValEnd}
                    onChange={onChange}
                    disabled={(checkingEnabled && !checked) || disabled}
                />
            </Grid>
        </>
    );

    return grid ? (
        <Grid item {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
