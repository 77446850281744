import React, { useContext } from 'react';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import {
    AppContext,
    SttDialog,
    SttFormDate,
    SttFormSelect,
    SttFormText,
    SttFormTextarea,
    SttFormTime,
    SttSelectFacility,
    SttSelectWeekdays,
} from '../../../sporttia/all';
import { useCrud } from '../../../lib/hooks';

export default function ModalPeriod({ item, onClose, onSave, onDelete }) {
    const [period, , setProperty] = useCrud(item);

    const cxt = useContext(AppContext);

    let subtitle;
    if (item.id) {
        subtitle = `${period.name}, ${moment(period.ini).format(
            'dddd',
        )}, ${moment(period.ini).format('D MMM YYYY')}, ${moment(
            period.ini,
        ).format('HH:mm')} - ${moment(period.end).format('HH:mm')}`;
    } else {
        subtitle = `${cxt.t('NewPeriod')}, ${period.name}`;
    }

    function setDate({ name, value }) {
        setProperty({
            name,
            value: `${moment(value).format('YYYY-MM-DD')} ${moment(
                period[name],
            ).format('HH:mm:ss')}`,
        });
    }

    function setTime({ name, value }) {
        setProperty({
            name,
            value: moment(period[name]).format(`YYYY-MM-DD ${value}:00`),
        });
    }

    return (
        <SttDialog
            open
            title={cxt.t('Period')}
            subtitle={subtitle}
            onClose={onClose}
            maxWidth="sm"
            content={
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SttFormText
                            caption={cxt.t('Name')}
                            name="name"
                            defVal={period.name}
                            onChange={setProperty}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SttFormTextarea
                            caption={cxt.t('Notes')}
                            name="notes"
                            defVal={period.notes}
                            onChange={setProperty}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <SttSelectFacility
                            caption={cxt.t('Facility')}
                            defVal={period?.facility?.id || period?.idFacility}
                            onChange={(facility) =>
                                setProperty({
                                    name: 'idFacility',
                                    value: facility.id,
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SttFormSelect
                            caption={cxt.t('Status')}
                            name="status"
                            defVal={period.status}
                            options={[
                                {
                                    caption: cxt.t('Active'),
                                    value: 'ACTIVE',
                                },
                                {
                                    caption: cxt.t('Inactive'),
                                    value: 'INACTIVE',
                                },
                            ]}
                            onChange={setProperty}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SttFormDate
                            disabled={!!item.id}
                            caption={cxt.t('Date')}
                            name="ini"
                            defVal={period.ini}
                            onChange={setDate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SttFormDate
                            disabled={!!item.id}
                            caption={cxt.t('End')}
                            name="end"
                            defVal={period.end}
                            onChange={setDate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SttFormTime
                            disabled={!!item.id}
                            caption={cxt.t('Begin')}
                            defVal={moment(period.ini).format('HH:mm')}
                            name="ini"
                            onChange={setTime}
                            interval={5}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SttFormTime
                            disabled={!!item.id}
                            caption={cxt.t('Until')}
                            defVal={moment(period.end).format('HH:mm')}
                            name="end"
                            onChange={setTime}
                            interval={5}
                        />
                    </Grid>
                    {!item.id && (
                        <Grid item xs={12}>
                            <SttSelectWeekdays
                                name="weekdays"
                                selected={period.weekdays}
                                onChange={setProperty}
                            />
                        </Grid>
                    )}
                </Grid>
            }
            buttons={[
                {
                    type: 'accept',
                    onClick: () => onSave(period),
                },
                period.id && {
                    type: 'delete',
                    onClick: () => onDelete(period),
                },
                {
                    type: 'cancel',
                    onClick: onClose,
                },
            ]}
        />
    );
}
