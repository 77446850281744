import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import { AppContext, SttNoResults } from '../all';
import SttTimetableColumn from './SttTimetableColumn';

/**
 * Timetable
 */
export function SttTimetable({ timetable, columnWidth = 100, onSelectPiece }) {
    const cxt = useContext(AppContext);

    return (
        <div style={{ height: '57vh', margin: 0, padding: 0 }}>
            <Box
                style={{
                    maxHeight: '100%',
                    overflowX: 'auto',
                    overflowY: 'hidden',
                }}
            >
                <Box
                    className={`timetable ${cxt.loading ? 'loading' : ''}`}
                    style={{ width: timetable.count * columnWidth }}
                >
                    {timetable?.columns.map((col) => (
                        <Box
                            key={col.facility.id}
                            className={`column ${
                                col.facility?.status === 'ONLY_SC'
                                    ? 'onlySC'
                                    : ''
                            }`}
                            style={{ width: columnWidth }}
                        >
                            <Box className="hd">{col.facility?.name}</Box>
                        </Box>
                    ))}
                </Box>

                <Box
                    className={`timetable ${cxt.loading ? 'loading' : ''}`}
                    style={{
                        width: timetable.count
                            ? timetable.count * columnWidth + 18
                            : 200,
                    }}
                >
                    <Box
                        style={{
                            maxHeight: '47vh',
                            overflow: 'auto',
                            overflowX: 'hidden',
                        }}
                    >
                        {timetable?.columns.map((col) => (
                            <SttTimetableColumn
                                key={col.facility.id}
                                column={col}
                                onOpenPiece={onSelectPiece}
                                width={columnWidth}
                            />
                        ))}

                        {timetable && timetable.count === 0 && (
                            <SttNoResults
                                paper
                                text={cxt.t('timetable.noFields')}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </div>
    );
}
