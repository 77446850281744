import React, { useContext, useEffect, useState } from 'react';
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import {
    AppContext,
    SttButton,
    SttDialog,
    SttFacilityList,
} from '../../sporttia/all';
import { useLoader } from '../../lib/hooks';
import useDevicesService from '../../services/DevicesService';
import useScsService from '../../services/ScsService';
import SttValidatedForm from '../../sporttia/SttValidatedForm';
import SimulateAccessDialog from '../../pages/sc/devices/SimulateAccessDialog';
import { DIALOG_DEVICE } from '../../lib/queryKeys';

/**
 * Dialog to create, modify, delete and recover devices from a center.
 * @param device Device object.
 * @param scId Sport center id.
 * @param onClose Action taken when closing the dialog.
 * @returns {JSX.Element}
 */
export default function DeviceDialog({ id, scId, onClose }) {
    const cxt = useContext(AppContext);
    const queryClient = useQueryClient();
    const devicesService = useDevicesService();
    const scsService = useScsService();
    const [deviceId, setDeviceId] = useState(null);
    const deviceQuery = useQuery(
        [DIALOG_DEVICE, { id: deviceId }],
        () => devicesService.get(deviceId, { trash: true }),
        { enabled: !!deviceId && deviceId !== 'create' },
    );
    const deleteMutation = useMutation(
        (params) => devicesService.remove(params?.id),
        {
            onSuccess: (data) =>
                queryClient.setQueryData(
                    [DIALOG_DEVICE, { id: deviceId }],
                    data,
                ),
        },
    );
    const updateMutation = useMutation(
        (params) => devicesService.update(params?.id, params?.params),
        {
            onSuccess: (data) =>
                queryClient.setQueryData(
                    [DIALOG_DEVICE, { id: deviceId }],
                    data,
                ),
        },
    );
    const createMutation = useMutation(
        (params) => scsService.createScsDevice(params?.id, params?.params),
        {
            onSuccess: (data) => setDeviceId(data?.device?.id),
        },
    );
    const recoverMutation = useMutation(
        (params) => devicesService.updateDevicesRecover(params?.id),
        {
            onSuccess: (data) =>
                queryClient.setQueryData(
                    [DIALOG_DEVICE, { id: deviceId }],
                    data,
                ),
        },
    );
    const createFacilityMutation = useMutation(
        (params) =>
            devicesService.createDevicesFacilities(
                params?.deviceId,
                params?.facilityId,
            ),
        {
            onSuccess: () =>
                queryClient.invalidateQueries([
                    DIALOG_DEVICE,
                    { id: deviceId },
                ]),
        },
    );
    const deleteFacilityMutation = useMutation(
        (params) =>
            devicesService.removeDevicesFacilities(
                params?.deviceId,
                params?.facilityId,
            ),
        {
            onSuccess: () =>
                queryClient.invalidateQueries([
                    DIALOG_DEVICE,
                    { id: deviceId },
                ]),
        },
    );
    const form = useForm();
    const [, loader] = useLoader([
        deleteMutation.status,
        updateMutation.status,
        createMutation.status,
        recoverMutation.status,
        createFacilityMutation.status,
        deleteFacilityMutation.status,
    ]);
    const [simulateAccessOpen, setSimulateAccessOpen] = useState(false);

    /**
     * Method executed when the user clicks on save or create device.
     */
    const mutateDevice = (formData) => {
        if (deviceQuery?.data?.device?.id) {
            updateMutation.mutate({
                id: deviceQuery?.data?.device?.id,
                params: { ...formData, idSC: scId },
            });
        } else {
            createMutation.mutate({ id: scId, params: formData });
        }
    };

    /**
     * When device was changed, it is set in the form.
     */
    useEffect(() => {
        if (deviceQuery?.data?.device?.id) {
            form.reset({
                name: deviceQuery?.data?.device?.name,
                type: deviceQuery?.data?.device?.type,
                description: deviceQuery?.data?.device?.description,
                status: deviceQuery?.data?.device?.status,
                signature: deviceQuery?.data?.device?.signature,
                preLight: deviceQuery?.data?.device?.preLight,
                postLight: deviceQuery?.data?.device?.postLight,
                preCortesy: deviceQuery?.data?.device?.preCortesy,
                margin: deviceQuery?.data?.device?.margin,
                soundTime: deviceQuery?.data?.device?.soundTime,
                pendingPaymentsMargin:
                    deviceQuery?.data?.device?.pendingPaymentsMargin,
                antiPassback: deviceQuery?.data?.device?.antiPassback || false,
                checkDirectionOut:
                    deviceQuery?.data?.device?.checkDirectionOut || false,
                remoteOpening:
                    deviceQuery?.data?.device?.remoteOpening || false,
            });
        } else {
            form.reset({
                name: null,
                type: 'CARD',
                description: null,
                status: null,
                signature: null,
                preLight: null,
                postLight: null,
                preCortesy: null,
                margin: null,
                soundTime: null,
                pendingPaymentsMargin: null,
                antiPassback: false,
                checkDirectionOut: false,
                remoteOpening: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceQuery?.data?.device]);

    /**
     * Update sport center id if id prop was changed.
     */
    useEffect(() => {
        setDeviceId(id);
    }, [id]);

    return (
        <SttDialog
            open={deviceId !== null}
            maxWidth="lg"
            title={
                deviceQuery?.data?.device?.id
                    ? deviceQuery?.data?.device?.name
                    : cxt.t('CreateDevice')
            }
            onClose={onClose}
            content={
                <>
                    {loader}
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={8}>
                            <SttValidatedForm
                                form={form}
                                loadingData={deviceQuery?.status === 'loading'}
                                fields={[
                                    {
                                        type: 'textInput',
                                        name: 'name',
                                        caption: cxt.t('name'),
                                        sm: 9,
                                    },
                                    {
                                        type: 'select',
                                        name: 'type',
                                        caption: cxt.t('Type'),
                                        options: [
                                            {
                                                caption: cxt.t('UT.CARD'),
                                                value: 'CARD',
                                            },
                                            {
                                                caption: cxt.t('UT.PIN'),
                                                value: 'PIN',
                                            },
                                            {
                                                caption: cxt.t('UT.QR'),
                                                value: 'QR',
                                            },
                                        ],
                                        disableClearable: true,
                                        sm: 3,
                                    },
                                    {
                                        type: 'textarea',
                                        name: 'description',
                                        caption: cxt.t('Description'),
                                        rows: 5,
                                    },
                                    {
                                        type: 'select',
                                        name: 'status',
                                        caption: cxt.t('Status'),
                                        options: [
                                            {
                                                caption: cxt.t('DS.ACTIVE'),
                                                value: 'ACTIVE',
                                            },
                                            {
                                                caption: cxt.t('DS.INACTIVE'),
                                                value: 'INACTIVE',
                                            },
                                            {
                                                caption: cxt.t('DS.OPEN'),
                                                value: 'OPEN',
                                            },
                                        ],
                                        disableClearable: true,
                                        sm: 6,
                                    },
                                    {
                                        type: 'textInput',
                                        name: 'signature',
                                        caption: cxt.t('Signature'),
                                        sm: 6,
                                    },
                                    {
                                        type: 'textInput',
                                        name: 'preLight',
                                        caption: cxt.t('PreLight'),
                                        sm: 3,
                                    },
                                    {
                                        type: 'textInput',
                                        name: 'postLight',
                                        caption: cxt.t('PostLight'),
                                        sm: 3,
                                    },
                                    {
                                        type: 'textInput',
                                        name: 'preCortesy',
                                        caption: cxt.t('PreCortesy'),
                                        sm: 3,
                                    },
                                    {
                                        type: 'textInput',
                                        name: 'margin',
                                        caption: cxt.t('Margin'),
                                        sm: 3,
                                    },
                                    {
                                        type: 'textInput',
                                        name: 'soundTime',
                                        caption: cxt.t('SirenTime'),
                                        sm: 3,
                                    },
                                    {
                                        type: 'textInput',
                                        name: 'pendingPaymentsMargin',
                                        caption: cxt.t('PendingPaymentsMargin'),
                                        sm: 6,
                                    },
                                    {
                                        type: 'check',
                                        name: 'antiPassback',
                                        caption: cxt.t('AntiPassback'),
                                    },
                                    {
                                        type: 'check',
                                        name: 'checkDirectionOut',
                                        caption: cxt.t(
                                            'CheckingDirectionOutDesc',
                                        ),
                                    },
                                    {
                                        type: 'check',
                                        name: 'remoteOpening',
                                        caption: cxt.t('RemoteOpeningDesc'),
                                    },
                                ]}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            {deviceQuery?.data?.device?.id &&
                                !deviceQuery?.data?.device?.trash && (
                                    <SttFacilityList
                                        facilities={
                                            deviceQuery?.data?.device
                                                ?.facilities || []
                                        }
                                        onAdd={(facility) => {
                                            createFacilityMutation.mutate({
                                                deviceId:
                                                    deviceQuery?.data?.device
                                                        ?.id,
                                                facilityId: facility?.id,
                                            });
                                        }}
                                        onDelete={(facility) => {
                                            deleteFacilityMutation.mutate({
                                                deviceId:
                                                    deviceQuery?.data?.device
                                                        ?.id,
                                                facilityId: facility?.id,
                                            });
                                        }}
                                        scId={scId}
                                    />
                                )}
                        </Grid>
                    </Grid>

                    <SimulateAccessDialog
                        open={simulateAccessOpen}
                        device={deviceQuery?.data?.device}
                        onClose={() => setSimulateAccessOpen(false)}
                    />
                </>
            }
            buttons={[
                {
                    show:
                        deviceQuery.status !== 'loading' &&
                        !deviceQuery?.data?.device?.trash,
                    type: 'save',
                    onClick: form.handleSubmit((formData) =>
                        mutateDevice(formData),
                    ),
                },
                {
                    show:
                        deviceQuery.status !== 'loading' &&
                        deviceQuery?.data?.device?.id &&
                        !deviceQuery?.data?.device?.trash,
                    type: 'delete',
                    confirmation: true,
                    onClick: () =>
                        deleteMutation.mutate({
                            id: deviceQuery?.data?.device?.id,
                        }),
                },
                {
                    show:
                        deviceQuery.status !== 'loading' &&
                        deviceQuery?.data?.device?.trash,
                    type: 'recover',
                    onClick: () =>
                        recoverMutation.mutate({
                            id: deviceQuery?.data?.device?.id,
                        }),
                },
                {
                    show:
                        deviceQuery.status !== 'loading' &&
                        deviceQuery?.data?.device?.id &&
                        !deviceQuery?.data?.device?.trash,
                    type: 'component',
                    component: (
                        <SttButton
                            caption={cxt.t('SimultatePass')}
                            startIcon={<CenterFocusWeakIcon />}
                            onClick={() => setSimulateAccessOpen(true)}
                        />
                    ),
                },
            ]}
        />
    );
}
