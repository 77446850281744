import React, { useContext } from 'react';
import { AppContext, SttFormSelect } from '../all';

const thisMonth = new Date().getMonth() + 1;
const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

/**
 * SttSelectMonth: draw a <select> featuring months
 * Note that this component deals with month values only, i.e. 1 (January) instead of "2020-01-01" for example
 * string name - control name
 * string caption - text field caption
 * string defVal - currently selected month
 * int firstMonth - first month you can choose (min value is 1)
 * int lastMonth - last month you can choose (max value is 12)
 * function onChange - callback for when an option is selected
 * NOTE AGAIN: KEEP IN MIND THIS COMPONENT DEALS IN NUMBERS (1, 2, etc) NOT DATE OBJECTS OR DATE STRINGS
 */
export function SttSelectMonth({
    name,
    caption,
    defVal = thisMonth,
    firstMonth = 1,
    lastMonth = 12,
    onChange,
}) {
    // TODO Hay un bug con defVal el cual no deja de poner exactamente el valor que quiero.
    const cxt = useContext(AppContext);
    const options = [];

    for (let i = firstMonth; i <= lastMonth; i++) {
        options.push({
            caption: cxt.t(`month.${months[i - 1]}`),
            value: i,
        });
    }

    return (
        <SttFormSelect
            name={name}
            caption={caption}
            defVal={defVal}
            options={options}
            onChange={onChange}
        />
    );
}
