import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import { AppContext, SttNoResults } from '../all';
import SttTimetableColumn from './SttTimetableColumn';

/**
 * Timetable week
 * ==============
 *
 * Props
 *
 *    * function onSelectPiece(piece)
 */
export function SttTimetableWeek({
    weekTimetable,
    columnWidth = 100,
    onSelectPiece,
}) {
    const cxt = useContext(AppContext);

    // -----| Render |-----
    return (
        <div style={{ height: '50vh', margin: 0, padding: 0 }}>
            <Box
                style={{
                    maxHeight: '100%',
                    overflowX: 'auto',
                    overflowY: 'hidden',
                }}
            >
                <Box
                    className={`timetable ${cxt.loading ? 'loading' : ''}`}
                    style={{ width: weekTimetable.length * columnWidth }}
                >
                    {weekTimetable &&
                        weekTimetable.map((col, idx) => (
                            <Box
                                key={idx}
                                className={`column ${
                                    col.facility &&
                                    col.facility.status === 'ONLY_SC'
                                        ? 'onlySC'
                                        : ''
                                }`}
                                style={{ width: columnWidth }}
                            >
                                <Box className="hd">
                                    {cxt.t(`day.${idx === 6 ? 0 : idx + 1}`)}
                                </Box>
                            </Box>
                        ))}
                </Box>
                <Box
                    className={`timetable ${cxt.loading ? 'loading' : ''}`}
                    style={{ width: weekTimetable.length * columnWidth + 18 }}
                >
                    <Box
                        style={{
                            maxHeight: '40vh',
                            overflow: 'auto',
                            overflowX: 'hidden',
                        }}
                    >
                        {weekTimetable &&
                            weekTimetable.map((col, idx) => (
                                <SttTimetableColumn
                                    key={idx}
                                    column={col}
                                    onOpenPiece={onSelectPiece}
                                    width={columnWidth}
                                />
                            ))}

                        {weekTimetable && weekTimetable.length === 0 && (
                            <SttNoResults
                                paper
                                text={cxt.t('timetable.noFields')}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </div>
    );
}
