import React, { useState, useEffect, useContext } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, TextField } from '@material-ui/core';
import { AppContext } from '../all';

/*
 * Generic <SELECT> with Autocomplete that loads arbitrary data using a provided 'loader' function
 * Due to AppContext.api() not returning Promises, the loader function that this component requires must return a promise (to preserve response context).
 *
 * <Promise> function loader(): loading function that must return a Promise that resolves with a list of rows, or rejects with the error message
 * String labelField: the key of the option's name
 * String valueField: the key of the option's id
 *
 * */
export function SttSelectAsync({
    grid,
    name,
    defVal,
    caption,
    size = 'small',
    variant = 'outlined',
    loader,
    onChange,
    labelField = 'name',
    valueField = 'id',
    ...rest
}) {
    const cxt = useContext(AppContext);
    const [items, setItems] = useState([]);
    const [selected, setSelected] = useState({
        [labelField]: '',
        [valueField]: defVal,
    });

    // Set the selected item (must search in the array after loading the data)
    useEffect(() => {
        if (items.length > 0 && defVal !== undefined) {
            setSelected(items.find((item) => item[valueField] === defVal));
        }
    }, [items]);

    function change(item) {
        setSelected(item);

        if (onChange && onChange.constructor == Function) {
            onChange({ name, value: item ? item[valueField] : undefined });
        }
    }

    // Run loader function and load items / throw error
    useEffect(() => {
        if (items.length == 0) {
            if (loader && loader.constructor === Function) {
                const promise = loader();
                if (promise.constructor === Promise) {
                    promise
                        .then((rows) => {
                            setItems(rows);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    console.log(
                        'Warning: SttSelectAsyncData requires that the passed loader() function returns a Promise.',
                    );
                }
            } else {
                console.log(
                    'Warning: no loader() function provided for SttSelectAsyncData component!',
                );
            }
        }
    }, []);

    // Render item
    const content = (
        <Autocomplete
            options={items}
            getOptionLabel={(option) => option[labelField]}
            onChange={(ev, item) => change(item)}
            value={selected}
            renderInput={(params) => (
                <TextField
                    fullWidth
                    {...params}
                    size={size}
                    label={caption}
                    variant={variant}
                />
            )}
        />
    );

    return grid ? (
        <Grid item {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
