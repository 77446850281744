import React, { useContext, useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { AppContext } from '../all';

/**
 * SttSelectActivityRate: draw a <select> featuring activity rates.
 * string name - control name.
 * string caption - text field caption.
 * string defVal - currently selected rate.
 * function onChange - callback for when an option is selected.
 */

export function SttSelectActivityRate({
    activityId,
    caption,
    defVal,
    onChange,
}) {
    const cxt = useContext(AppContext);
    const [options, setOptions] = useState();

    const loadActivityRates = () => {
        cxt.api('GET', `/activities/${activityId}/rates`, {
            params: { page: 1, rows: 500 },
            success: (r) => {
                setOptions(r.activityRates);
            },
        });
    };

    useEffect(() => {
        loadActivityRates();
    }, [activityId]);

    return options && options.length > 0 ? (
        <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
            <InputLabel>{caption}</InputLabel>
            <Select value={defVal} onChange={(e) => onChange(e.target.value)}>
                {options &&
                    options.map((op, i) => (
                        <MenuItem key={i} value={op.id}>
                            {op.name}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    ) : (
        <></>
    );
}
