import React, { useState, useEffect, useContext, Fragment } from 'react';
import { Grid, Box, Button, Divider } from '@material-ui/core';
import moment from 'moment';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SchoolIcon from '@material-ui/icons/School';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import PeopleIcon from '@material-ui/icons/People';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Booking from '../booking/Booking';
import ActivityClassDialog from '../../sc/activities/ActivityClassDialog';
import CreateBookingScheduleDialog from '../../../components/dialogs/CreateBookingScheduleDialog';
import BusyScheduleDialog from '../../../components/dialogs/BusyScheduleDialog';
import SeatingDialog from '../../sc/timetable/SeatingDialog';
import TimetableBookingsDialog from './TimetableBookingsDialog';
import { AppContext, SttDialog, SttFormSelect } from '../../../sporttia/all';
import PieceCapacityDialog from '../../sc/timetable/PieceCapacityDialog';
import { formatPriceByLocale } from '../../../lib/utils';
/**
 * Timetable operate
 * =================
 *
 */
export default function TimetableOperate({
    sc,
    column,
    piece,
    bookingCut = null,
    setBookingCut,
    onClose,
    onChangeTimetable,
    cutBooking = null,
    privileged = false,
}) {
    const cxt = useContext(AppContext);

    const [free, setFree] = useState();
    const [timeini, setTimeini] = useState();
    const [timeend, setTimeend] = useState();
    const [actions, setActions] = useState();
    const [bookingAttempt, setBookingAttempt] = useState(null);
    const [createClass, setCreateClass] = useState(null);
    const [createFixedBooking, setCreateFixedBooking] = useState(null);
    const [createBusySchedule, setCreateBusySchedule] = useState(null);
    const [openSeatingDialog, setOpenSeatingDialog] = useState(false);
    const [openBookingsDialog, setOpenBookingsDialog] = useState(false);
    const [ImSCOwner, setImSCOwner] = useState(false);
    const [openBusySchedule, setOpenBusySchedule] = useState(false);
    const [openFixedBooking, setOpenFixedBooking] = useState(false);
    const [openPieceCapacityDialog, setOpenPieceCapacityDialog] =
        useState(false);

    /**
     * Im sc owner
     */
    useEffect(() => {
        if (cxt.sc && sc) {
            setImSCOwner(
                cxt.user.role === cxt.constants.roleSC &&
                    parseInt(cxt.sc.id) === parseInt(sc.id),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Open create booking
     */
    const openCreateBooking = () => {
        setBookingAttempt({
            ini: moment().format('YYYY-MM-DDTHH:00:00'),
            end: moment().format('YYYY-MM-DDTHH:00:00'),
            facility: column.facility,
        });
    };

    /**
     * Open creating class
     */
    const openCreateClass = () => {
        setCreateClass({
            id: null,
            status: 'ACTIVE',
            ini: piece.ini,
            end: piece.end,
            facility: column.facility
                ? { id: column.facility.id, name: column.facility.name }
                : null,
            weekdays: [moment(piece.ini).day()],
        });
    };

    /**
     * If 'timeini' or 'timeend' was changed, createBusySchedule and createFixedBooking object will be update.
     */
    useEffect(() => {
        setCreateBusySchedule({
            idFacility: column.facility ? column.facility.id : null,
            status: 'ACTIVE',
            name: cxt.t('Closed'),
            ini: moment(timeini).format('YYYY-MM-DD'),
            end: moment(timeend).format('YYYY-MM-DD'),
            timeini: moment(timeini).format('HH:mm'),
            timeend: moment(timeend).format('HH:mm'),
        });

        setCreateFixedBooking({
            name: '',
            status: 'ACTIVE',
            dateini: moment(timeini).format('YYYY-MM-DD'),
            dateend: moment(timeend).format('YYYY-MM-DD'),
            timeini: moment(timeini).format('HH:mm'),
            timeend: moment(timeend).format('HH:mm'),
            facility: column.facility
                ? { id: column.facility.id, name: column.facility.name }
                : null,
            user: null,
            weekdays: [moment(timeini).day()],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeini, timeend]);

    /**
     * Paste booking
     *
     * params
     *
     * 	- facility
     * 	- piece
     */
    const pasteBooking = (p = {}) => {
        // Calculo la diferencia entre la hora inicial y final del alquiler a cortar para luego sumarselo al tiempo inicial de
        // del nuevo slot elegido.
        const horaIni = moment.duration(
            moment(bookingCut.ini).format('HH:mm'),
            'HH:mm',
        );
        const horaFin = moment.duration(
            moment(bookingCut.end).format('HH:mm'),
            'HH:mm',
        );
        const diffHours = horaFin.subtract(horaIni);

        // Recalcular la hora final en función del nuevo tiempo inicial
        const newStart = moment(p.piece.ini);
        const newEnd = moment(newStart)
            .add(diffHours.hours(), 'hours')
            .add(diffHours.minutes(), 'minutes');

        // En el caso de que sea la hora final a las 12, para que haga bien la conversion tendriamos que sumarle 1 dia
        // a la hora final, ya que por el contrario la fecha final seria antes que la inicial
        if (newEnd.isBefore(newStart)) {
            newEnd.add(1, 'day');
        }

        cxt.api('PUT', `/bookings/${bookingCut.id}`, {
            params: {
                idFacility: p.facility.id,
                ini: newStart.format('YYYY-MM-DD HH:mm:ss'),
                end: newEnd.format('YYYY-MM-DD HH:mm:ss'),
            },
            success: () => {
                onChangeTimetable();
                onClose();
                setBookingCut(null);
            },
        });
    };

    /**
     * Piece changes
     */
    useEffect(() => {
        if (piece) {
            // Set actions
            setActions([
                {
                    condition: () =>
                        piece.actions && piece.actions.includes('book'),
                    caption: cxt.t('Book'),
                    onClick: () => openCreateBooking(),
                    icon: <ConfirmationNumberIcon />,
                },
                {
                    condition: () =>
                        piece.actions &&
                        piece.actions.includes('createFixedBooking'),
                    caption: cxt.t('timetable.createFixedBook'),
                    onClick: () => setOpenFixedBooking(true),
                    icon: <ViewModuleIcon />,
                },
                {
                    condition: () =>
                        piece.actions && piece.actions.includes('createClass'),
                    caption: cxt.t('CreateClass'),
                    onClick: () => openCreateClass(),
                    icon: <SchoolIcon />,
                },
                {
                    condition: () =>
                        piece.actions &&
                        piece.actions.includes('closeFacilities'),
                    caption: cxt.t('CloseFacility'),
                    onClick: () => setOpenBusySchedule(true),
                    icon: <CancelPresentationIcon />,
                },
                {
                    condition: () => bookingCut !== null,
                    caption: cxt.t('PasteBooking'),
                    onClick: () =>
                        pasteBooking({
                            facility: column.facility,
                            piece,
                        }),
                    icon: <FileCopyIcon />,
                },
                {
                    separator: true,
                    type: 'consult',
                    condition: () => piece.bookings && ImSCOwner,
                    caption: cxt.t('ConsultSeating'),
                    onClick: () => setOpenSeatingDialog(true),
                    icon: <PeopleIcon />,
                },
                {
                    type: 'consult',
                    condition: () => piece.bookings && ImSCOwner,
                    caption: cxt.t('Bookings'),
                    onClick: () => setOpenBookingsDialog(true),
                    icon: <ClearAllIcon />,
                },
                {
                    type: 'consult',
                    condition: () => piece.bookings && privileged,
                    caption: cxt.t('ConsultSeating'),
                    onClick: () => setOpenPieceCapacityDialog(true),
                    icon: <PeopleIcon />,
                },
            ]);

            // Load free (schedule name, etc)
            if (piece.mark === 'FREE') {
                cxt.api('GET', `/frees/${piece.free.id}`, {
                    success: (r) => {
                        setFree(r.free);
                    },
                });
            }

            setTimeini(piece.ini);

            // si el piece.duration es menor que el minBookingPeriod no hay que rallarse más
            const minBookingPeriod = column.facility.defaultBooking;
            if (minBookingPeriod && piece.duration < minBookingPeriod) {
                // El timeend deberá ser timeini + minBookingPeriod
                const finalTime = moment(piece.ini)
                    .add(minBookingPeriod, 'minutes')
                    .format('YYYY-MM-DDTHH:mm:ss');

                // Compruebo si existen tramos horarios con la hora que hemos generado y si no ponemos como hora final la hora final del tramo.
                if (
                    column.pieces.filter((pc) => pc.end === finalTime).length >
                    0
                ) {
                    setTimeend(finalTime);
                } else {
                    setTimeend(piece.end);
                }
            } else {
                setTimeend(piece.end);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [piece, ImSCOwner, privileged]);

    // Render
    return (
        <>
            <SttDialog
                title={`${column.facility.name}, ${moment(piece.ini).format(
                    'D-MM-YYYY',
                )}`}
                open={['FREE', 'FULL'].includes(piece.mark)}
                onClose={onClose}
                content={
                    <>
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                            spacing={3}
                        >
                            {/* --- Time ini --- */}
                            <SttFormSelect
                                grid
                                xs={12}
                                sm={6}
                                md={6}
                                disabled={piece.mark !== 'FREE'}
                                defVal={timeini}
                                onChange={({ value }) => setTimeini(value)}
                                options={column.pieces
                                    .filter((pc) => pc.mark === 'FREE')
                                    .map((pc) => ({
                                        caption: moment(pc.ini).format('H:mm'),
                                        value: pc.ini,
                                    }))}
                            />

                            {/* --- Time end --- */}
                            <SttFormSelect
                                grid
                                xs={12}
                                sm={6}
                                md={6}
                                disabled={piece.mark !== 'FREE'}
                                defVal={timeend}
                                onChange={({ value }) => setTimeend(value)}
                                options={column.pieces
                                    .filter((pc) => pc.mark === 'FREE')
                                    .map((pc) => ({
                                        caption: moment(pc.end).format('H:mm'),
                                        value: pc.end,
                                    }))}
                            />
                        </Grid>

                        {/* --- Render all actions --- */}
                        <Box my={5}>
                            {actions &&
                                actions.map((action) => {
                                    if (action.condition()) {
                                        return (
                                            <Fragment key={action.caption}>
                                                {/* --- Separator --- */}
                                                {action.separator && (
                                                    <Box my={3}>
                                                        <Divider />
                                                    </Box>
                                                )}

                                                <Box my={2}>
                                                    <Button
                                                        fullWidth
                                                        variant={
                                                            action.type ===
                                                            'consult'
                                                                ? 'outlined'
                                                                : 'contained'
                                                        }
                                                        color="primary"
                                                        onClick={action.onClick}
                                                        startIcon={
                                                            action.icon || (
                                                                <ArrowRightIcon />
                                                            )
                                                        }
                                                    >
                                                        {action.caption}
                                                    </Button>
                                                </Box>
                                            </Fragment>
                                        );
                                    }
                                    return null;
                                })}
                        </Box>

                        {free && (
                            <Box mt={3}>
                                {cxt.t('Horary')}: <b>{free.schedule.name}</b>
                                {piece.rates &&
                                    piece.rates.map((rate) => (
                                        <Box key={rate.id}>
                                            - <b>{rate.name}</b> -{' '}
                                            {formatPriceByLocale(
                                                rate?.price,
                                                rate?.priceShape?.currency,
                                                rate?.priceShape?.locale,
                                            )}{' '}
                                            / {rate.duration} m
                                        </Box>
                                    ))}
                            </Box>
                        )}
                    </>
                }
                buttons={[
                    {
                        type: 'close',
                        onClick: onClose,
                    },
                ]}
            />

            {bookingAttempt && (
                <Booking
                    open={bookingAttempt !== null}
                    onClose={() => setBookingAttempt(null)}
                    idSC={sc.id}
                    column={column}
                    piece={piece}
                    ini={timeini}
                    end={timeend}
                    onChangeTimetable={onChangeTimetable}
                />
            )}

            {/* --- Create class / activity --- */}
            {createClass && (
                <ActivityClassDialog
                    open={createClass !== null}
                    item={createClass}
                    onSave={onChangeTimetable}
                    onClose={() => setCreateClass(false)}
                />
            )}

            {/* --- Create fixed booking --- */}
            {openFixedBooking && (
                <CreateBookingScheduleDialog
                    item={createFixedBooking}
                    onClose={() => setOpenFixedBooking(false)}
                    onSave={onChangeTimetable}
                />
            )}

            {/* --- Create busy --- */}
            {openBusySchedule && (
                <BusyScheduleDialog
                    item={createBusySchedule}
                    facility={column.facility}
                    onClose={() => setOpenBusySchedule(false)}
                    onSave={onChangeTimetable}
                />
            )}

            {/* --- Seatings --- */}
            {openSeatingDialog && (
                <SeatingDialog
                    open={openSeatingDialog}
                    facility={column.facility}
                    date={piece.ini}
                    piece={piece}
                    onClose={() => setOpenSeatingDialog(false)}
                />
            )}

            {/* --- Booking list dialog --- */}
            {openBookingsDialog &&
                piece.bookings &&
                piece.bookings.length > 0 && (
                    <TimetableBookingsDialog
                        open={openBookingsDialog}
                        bookings={piece.bookings}
                        onClose={() => {
                            setOpenBookingsDialog(false);
                            onChangeTimetable();
                        }}
                        cutBooking={cutBooking}
                    />
                )}

            {/* --- PieceCapacityDialog (Only for users with '19' privilege) --- */}
            {openPieceCapacityDialog && (
                <PieceCapacityDialog
                    open={openPieceCapacityDialog}
                    facility={column.facility}
                    date={piece.ini}
                    piece={piece}
                    onClose={() => setOpenPieceCapacityDialog(false)}
                />
            )}
        </>
    );
}
