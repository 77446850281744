import React, { useContext, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useCrud } from '../../lib/hooks';
import {
    SttFormText,
    SttDialog,
    SttFormTextarea,
    AppContext,
    SttFormCheck,
} from '../../sporttia/all';

export default function ConsentDialog({ item, onClose, onSave }) {
    const cxt = useContext(AppContext);

    const [consent, setConsent, setProperty] = useCrud();

    useEffect(() => {
        setConsent(item);
    }, [item]);

    return (
        <SttDialog
            open={consent !== null}
            onClose={onClose}
            title={
                consent && consent.id
                    ? cxt.t('newConsentTemplate')
                    : cxt.t('Consent')
            }
            content={
                <Box>
                    <Box mb={3}>
                        <SttFormText
                            caption={cxt.t('Name')}
                            name="name"
                            defVal={consent ? consent.name : ''}
                            onChange={setProperty}
                        />
                    </Box>
                    <Box mb={3}>
                        <SttFormTextarea
                            caption={cxt.t('text')}
                            name="text"
                            rows={15}
                            defVal={consent ? consent.text : ''}
                            onChange={setProperty}
                        />
                    </Box>
                    <Box>
                        <SttFormTextarea
                            caption={cxt.t('textShort')}
                            name="textShort"
                            rows={10}
                            defVal={consent ? consent.textShort : ''}
                            onChange={setProperty}
                        />
                    </Box>
                    <Box>
                        <SttFormCheck
                            grid
                            md={12}
                            name="forBooking"
                            caption={cxt.t('consents.for.bookings')}
                            checked={consent ? consent.forBooking : false}
                            onChange={setProperty}
                        />
                    </Box>
                </Box>
            }
            buttons={[
                {
                    type: 'save',
                    onClick: () => onSave(consent),
                },
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
        />
    );
}
