import React, { useContext } from 'react';
import {
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { AppContext } from '../AppContext';
import { formatPriceByLocale, fullName } from '../../lib/utils';

export function SttInscriptionList({ inscriptions, hidePrice, onDelete }) {
    const cxt = useContext(AppContext);

    // -----| Render |-----
    return (
        <List>
            {inscriptions &&
                inscriptions.map((inscription, i) => {
                    let price = '';
                    if (!hidePrice && inscription.price !== undefined) {
                        if (inscription.price > 0) {
                            price = formatPriceByLocale(
                                inscription.price,
                                inscription?.priceShape?.currency,
                                inscription?.priceShape?.locale,
                            );
                        } else {
                            price = cxt.t('Free');
                        }
                    }

                    return (
                        <div key={i}>
                            {i > 0 && <Divider />}
                            <ListItem button>
                                <ListItemText
                                    primary={fullName(inscription)}
                                    secondary={price}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        onClick={() => onDelete(inscription)}
                                        edge="end"
                                        aria-label="delete"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </div>
                    );
                })}
            {inscriptions && inscriptions.length == 0 && (
                <ListItem>
                    <ListItemText primary={cxt.t('None')} />
                </ListItem>
            )}
        </List>
    );
}
