import React, { useState, useEffect, useContext } from 'react';
import {} from '@material-ui/core';
import { AppContext, SttList } from '../../../sporttia/all';
import ConfigurationRechargingOptionDialog from './ConfigurationRechargingOptionDialog';

export default function ConfigurationRecharges({}) {
    const cxt = useContext(AppContext);
    const [chargingOptions, setChargingOptions] = useState();
    const [selectedChargingOption, setSelectedChargingOption] = useState(null);

    /**
     * Load charging options
     */
    const loadChargingOptions = () => {
        cxt.api('GET', `/scs/${cxt.sc.id}/chargings/options`, {
            params: {
                page: 1,
                rows: 500,
            },
            success: (r) => {
                setChargingOptions(r);
            },
        });
    };

    /**
     * SC changes
     */
    useEffect(() => {
        if (cxt.sc.id) loadChargingOptions();
    }, [cxt.sc.id]);

    /**
     * Delete charging option
     */
    const delChargingOption = (chOpt) => {
        cxt.api('DELETE', `/chargings/options/${chOpt.id}`, {
            confirmation: true,
            success: (r) => {
                loadChargingOptions();
                cxt.showMessage('S', cxt.t('Deleted'));
            },
        });
    };

    // -----| Render |-----
    return (
        <>
            <SttList
                title={cxt.t('Recharges')}
                onSelect={(e) => setSelectedChargingOption(e)}
                onAdd={() => setSelectedChargingOption({})}
                onDelete={(e) => delChargingOption(e)}
                data={
                    chargingOptions &&
                    chargingOptions.rows.map((opt) => ({
                        caption: opt.name,
                        objToSelect: opt,
                    }))
                }
            />

            <ConfigurationRechargingOptionDialog
                chargingOption={selectedChargingOption}
                onClose={() => {
                    loadChargingOptions();
                    setSelectedChargingOption(null);
                }}
            />
        </>
    );
}
