import React from 'react';
import { usePageHeader } from '../../../../lib/hooks';
import Report from '../../../sc/Report';

/**
 * View where the sporttia superadmin can see the list of tpv records by sc.
 * @returns {JSX.Element}
 */
function AdminTpvRecordsBySc({}) {
    usePageHeader('tpvRecordsBySc');

    return <Report id={27} navigateBack="/home" />;
}

export default AdminTpvRecordsBySc;
