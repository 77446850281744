import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Grid,
    Typography,
    Divider,
    Switch,
    Tooltip,
} from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import DoneIcon from '@material-ui/icons/Done';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { AppContext } from '../../../sporttia/all';
import { useCrud } from '../../../lib/hooks';

const useStyles = makeStyles({
    heading: {
        fontSize: 18,
        color: 'black',
        marginRight: 8,
    },
    warning: {
        backgroundColor: '#9EB319',
        height: '50px',
        lineHeight: '50px',
        paddingRight: '15px',
        paddingLeft: '15px',
        fontSize: 16,
        fontWeight: 'bold',
        marginRight: 8,
        borderRadius: 10,
        color: 'white',
    },
});

export default function ConfigurationNotifications() {
    const cxt = useContext(AppContext);

    const [devices, setDevices, ,] = useCrud();
    const [registeredDevices, setRegisteredDevices] = useState(
        localStorage.getItem('registeredDevices')
            ? JSON.parse(localStorage.getItem('registeredDevices'))
            : [],
    );
    const [updatedNotifications, setUpdatedNotifications] = useState(false);

    // Load devices
    useEffect(() => {
        cxt.api('GET', `/scs/${cxt.sc.id}/devices`, {
            success: (response) => {
                if (response.count > 0) {
                    setDevices(response.rows);
                }
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Save devices ids to localStorage
    const updateRegisteredDevices = () => {
        localStorage.setItem(
            'registeredDevices',
            JSON.stringify(registeredDevices),
        );
    };

    // Trigger saving device ids when they change
    useEffect(() => {
        updateRegisteredDevices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registeredDevices]);

    // Register device id for this user
    const registerDevice = (id, enabled) => {
        if (enabled) {
            setRegisteredDevices([...registeredDevices, id]);
        } else {
            setRegisteredDevices(
                registeredDevices.filter((device) => device !== id),
            );
        }
        setUpdatedNotifications(true);
        cxt.sendMessageToSocket({
            data: {
                deviceId: id,
                enabled,
            },
        });
    };

    const notConfGroups = [
        {
            title: cxt.t('Booking'),
            notifications: [
                { name: cxt.t('not16.hart.name'), email: true, sms: true },
            ],
        },
        {
            title: cxt.t('Events'),
            notifications: [
                { name: cxt.t('not14.hart.name'), email: true, sms: false },
                { name: cxt.t('not45.hart.name'), email: true, sms: false },
                { name: cxt.t('not27.hart.name'), email: true, sms: true },
                { name: cxt.t('not41.hart.name'), email: true, sms: true },
            ],
        },
        {
            title: cxt.t('Activities'),
            notifications: [
                { name: cxt.t('not11.hart.name'), email: true, sms: false },
                { name: cxt.t('not10.hart.name'), email: true, sms: false },
                { name: cxt.t('not23.hart.name'), email: true, sms: false },
                { name: cxt.t('not28.hart.name'), email: true, sms: true },
            ],
        },
        {
            title: cxt.t('Games'),
            notifications: [
                { name: cxt.t('not12.hart.name'), email: true, sms: false },
                { name: cxt.t('not3.hart.name'), email: true, sms: false },
                { name: cxt.t('not26.hart.name'), email: true, sms: false },
                { name: cxt.t('not4.hart.name'), email: true, sms: false },
                { name: cxt.t('not2.hart.name'), email: true, sms: true },
                { name: cxt.t('not32.hart.name'), email: true, sms: false },
                { name: cxt.t('not31.hart.name'), email: true, sms: false },
                { name: cxt.t('not48.hart.name'), email: true, sms: false },
                { name: cxt.t('not1.hart.name'), email: true, sms: false },
                { name: cxt.t('not47.hart.name'), email: true, sms: false },
            ],
        },
    ];

    const classes = useStyles();

    // -----| Render |-----
    return (
        <>
            {devices && (
                <Box>
                    <Box mb={2} display="flex">
                        <span className={classes.heading}>
                            {cxt.t(
                                'page.sc.configuration.notifications.realTime',
                            )}
                        </span>
                        <Tooltip
                            arrow
                            placement="top"
                            title="Esta configuración es local a este dispositivo."
                        >
                            <HelpOutline />
                        </Tooltip>
                    </Box>
                    <div>
                        {updatedNotifications && (
                            <Box mb={2} display="flex">
                                <span className={classes.warning}>
                                    {cxt.t(
                                        'page.sc.configuration.notifications.realTimeRefreshPage',
                                    )}
                                </span>
                            </Box>
                        )}
                    </div>

                    <div>
                        {devices.map((device) => (
                            <Box
                                key={device?.id}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <div>
                                    <Typography variant="body1">
                                        {device.name}
                                    </Typography>
                                </div>
                                <Switch
                                    checked={
                                        registeredDevices.find(
                                            (id) => id === device.id,
                                        ) !== undefined
                                    }
                                    onChange={(ev) =>
                                        registerDevice(
                                            device.id,
                                            ev.target.checked,
                                        )
                                    }
                                />
                            </Box>
                        ))}
                    </div>
                </Box>
            )}
            <br />
            <Divider />
            <br />
            <Box>
                <Grid container spacing={3}>
                    <Grid item md={8}>
                        {cxt.t('Description')}
                    </Grid>
                    <Grid item md={2}>
                        {cxt.t('Email')}
                    </Grid>
                    {/* <Grid item md={2}>
					SMS
				</Grid> */}
                </Grid>
                {notConfGroups.map((notConfGroup) => (
                    <Box key={notConfGroup.title} mt={2}>
                        <Typography variant="h5">
                            {notConfGroup.title}
                        </Typography>
                        <Box mb={1}>
                            <Divider />
                        </Box>
                        <Box mt={1}>
                            {notConfGroup.notifications.map((not) => (
                                <Grid key={not?.name} container spacing={3}>
                                    <Grid item md={8}>
                                        {not.name}
                                    </Grid>
                                    <Grid item md={2}>
                                        {not.email && <DoneIcon />}
                                    </Grid>
                                    {/* <Grid item md={2}>
							{not.sms &&
								<DoneIcon />
							}
						</Grid> */}
                                </Grid>
                            ))}
                        </Box>
                    </Box>
                ))}
            </Box>
        </>
    );
}
