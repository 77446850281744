import React, { useContext } from 'react';
import { Paper, Box, Grid, Typography } from '@material-ui/core';
import { AppContext } from './all';
import emptyImage from '../images/empty.svg';

type SttNoResultsProps = {
    text?: string;
    paper?: boolean;
};

export function SttNoResults({ text, paper = false }: SttNoResultsProps) {
    const cxt = useContext(AppContext)!;

    const content = (
        <Grid container>
            <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                    <img
                        src={emptyImage}
                        width={100}
                        height={100}
                        alt={cxt.t('NoResults')}
                    />
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Typography align="center">
                    {text || cxt.t('NoResults')}
                </Typography>
            </Grid>
        </Grid>
    );

    if (paper) {
        return <Paper>{content}</Paper>;
    }

    return content;
}
