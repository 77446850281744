import React, { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { AppContext } from '../../../../sporttia/AppContext';
import useApisService from '../../../../services/ApisService';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import { SUPERADMIN_API_MODEL_PROPERTIES } from '../../../../lib/queryKeys';
import AdminApiModelPropertyDialog from './AdminApiModelPropertyDialog';

/**
 * API Model properties view. View with a table that lists the properties of an api model.
 * @param apiModel API Model object.
 * @returns {JSX.Element}
 */
export default function AdminApiModelProperties({ apiModel }) {
    const cxt = useContext(AppContext);
    const [property, setProperty] = useState(null);
    const queryClient = useQueryClient();
    const apisService = useApisService();

    return (
        <>
            <SttTopFilteringControls
                p={2}
                mainAction={{
                    type: 'create',
                    caption: cxt.t('Create'),
                    onClick: () => setProperty({}),
                }}
            />

            {/* Aqui no podemos paginar directamente las columnas ya que no tenemos un end point que lo haga, tenemos que usar
            GET /api/models/:id para de dentro coger properties y ya listarlo, de ahí que se pidan 200 filas. */}
            <SttCachedTable
                queryKey={SUPERADMIN_API_MODEL_PROPERTIES}
                queryFn={(params) =>
                    apisService.getApiModel(params?.id, {}).then((data) => ({
                        count: data?.apiModel?.properties?.rows
                            ? data?.apiModel?.properties?.rows?.length
                            : 0,
                        rows: data?.apiModel?.properties?.rows
                            ? data?.apiModel?.properties?.rows
                            : [],
                    }))
                }
                queryOptions={{
                    enabled: !!apiModel?.id && apiModel?.id !== 'create',
                }}
                queryParams={{
                    id: apiModel?.id,
                }}
                onClickRow={(row) => setProperty(row)}
                rowsPerPage={200}
                columns={[
                    {
                        title: 'Name',
                        field: 'name',
                        value: (row) => <b>{row.name}</b>,
                    },
                    {
                        title: 'Description',
                        field: 'description',
                        align: 'left',
                    },
                    {
                        title: 'Type',
                        field: 'type',
                    },
                ]}
            />

            {/* En el futuro si tenemos un GET /api/models/properties/:id podríamos quitar propertyValue y pasar el id de la propiedad. */}
            <AdminApiModelPropertyDialog
                apiModelId={apiModel?.id}
                propertyValue={property}
                onClose={() => setProperty(null)}
                onCreate={() =>
                    queryClient.invalidateQueries(
                        SUPERADMIN_API_MODEL_PROPERTIES,
                    )
                }
                onUpdate={() =>
                    queryClient.invalidateQueries(
                        SUPERADMIN_API_MODEL_PROPERTIES,
                    )
                }
                onDelete={() => {
                    setProperty(null);
                    queryClient.invalidateQueries(
                        SUPERADMIN_API_MODEL_PROPERTIES,
                    );
                }}
            />
        </>
    );
}
