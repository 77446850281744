import React from 'react';
import { usePageHeader } from '../../../../lib/hooks';
import Report from '../../../sc/Report';

/**
 * View where the sporttia superadmin can see the list of events.
 * @returns {JSX.Element}
 */
function AdminEvents({}) {
    usePageHeader('events');

    return <Report id={14} navigateBack="/home" />;
}

export default AdminEvents;
