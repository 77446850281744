import React, { useState, useContext, useEffect } from 'react';
import { Chip } from '@material-ui/core';
import { AppContext } from '../AppContext';

/**
 * Chip file format: png, jpg, text, etc ...
 */
export function SttChipFileFormat({ format }) {
    const cxt = useContext(AppContext);
    const [fileFormat, setFileFormat] = useState();

    useEffect(() => {
        if (format)
            setFileFormat(
                cxt.constants.fileFormats.find((e) => e.id == format),
            );
    }, [format]);

    return (
        <>
            {format && fileFormat && (
                <Chip
                    size="small"
                    label={fileFormat.label}
                    style={{
                        backgroundColor: fileFormat.color,
                        color: 'white',
                    }}
                />
            )}
        </>
    );
}
