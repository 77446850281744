import React, { useContext, useState } from 'react';
import { Paper } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import { AppContext } from '../../../../sporttia/AppContext';
import { usePageHeader, useQueryFilters } from '../../../../lib/hooks';
import useCitiesService from '../../../../services/CitiesService';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';
import { SUPERADMIN_CITIES } from '../../../../lib/queryKeys';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import AdminCity from './AdminCity';
import { SttButtonFab } from '../../../../sporttia/buttons/SttButtonFab';

/**
 * View where the sporttia superadmin can see the list of cities.
 * @returns {JSX.Element}
 */
export default function AdminCities() {
    const cxt = useContext(AppContext);
    usePageHeader('Cities');
    const citiesService = useCitiesService();
    const queryClient = useQueryClient();
    const [filters, setFilters] = useQueryFilters({}, SUPERADMIN_CITIES);
    const [cityId, setCityId] = useState(null);

    return (
        <Paper>
            <SttTopFilteringControls
                p={2}
                fields={[
                    {
                        name: 'name',
                        type: 'text',
                        value: '',
                        caption: cxt.t('Search'),
                    },
                ]}
                trashAction
                onFilter={setFilters}
            />

            <SttCachedTable
                queryKey={SUPERADMIN_CITIES}
                queryFn={(params) => citiesService.getList(params)}
                queryParams={filters}
                onClickRow={(row) => setCityId(row?.id)}
                columns={[
                    {
                        title: 'Id',
                        align: 'left',
                        field: 'id',
                    },
                    {
                        title: cxt.t('Name'),
                        align: 'left',
                        field: 'name',
                    },
                ]}
            />

            <AdminCity
                id={cityId}
                onClose={() => setCityId(null)}
                onCreate={() =>
                    queryClient.invalidateQueries(SUPERADMIN_CITIES)
                }
                onUpdate={() =>
                    queryClient.invalidateQueries(SUPERADMIN_CITIES)
                }
                onDelete={() => {
                    setCityId(null);
                    queryClient.invalidateQueries(SUPERADMIN_CITIES);
                }}
            />

            <SttButtonFab onClick={() => setCityId('create')} />
        </Paper>
    );
}
