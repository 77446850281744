import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import {
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Box,
    CircularProgress,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import { AppContext } from '../AppContext';
import { SttList } from './SttList';
/*
 * Generalización del componente SttTerrainList
 */

export function SttItemList({
    title,
    items,
    onAdd,
    onDelete,
    queryKey,
    itemQueryCall,
    queryCallParameters,
}) {
    const cxt = useContext(AppContext);
    const [selectionOpen, setSelectionOpen] = useState(false);
    const [optionalItems, setOptionalItems] = useState(null);

    const getItemsQuery = useQuery(
        [queryKey],
        () =>
            itemQueryCall(queryCallParameters)
                .then((response) => {
                    const filteredItems = response?.rows?.filter(
                        (optionalItem) =>
                            !items?.find((t) => t.id === optionalItem.id),
                    );
                    setOptionalItems(filteredItems);
                })
                .catch((error) => {
                    cxt.showMessage('E', cxt.t(error?.msg));
                }),
        { enabled: true },
    );

    function openAddModal() {
        setSelectionOpen(true);
    }

    function selectItem(item) {
        setSelectionOpen(false);
        setOptionalItems(
            optionalItems?.filter(
                (optionalItem) => optionalItem?.id !== item?.id,
            ),
        );
        onAdd(item?.id);
    }

    if (getItemsQuery?.isLoading) {
        return (
            <Box display="flex" justifyContent="center" paddingY={15}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <SttList
                title={title}
                onAdd={() => openAddModal()}
                onDelete={(item) => onDelete(item?.id)}
                data={
                    items &&
                    items.map((item) => ({
                        caption: `${item?.name}`,
                        objToSelect: item,
                    }))
                }
            />
            <Dialog
                onClose={() => setSelectionOpen(false)}
                open={selectionOpen}
            >
                <DialogTitle>{cxt.t(title)}</DialogTitle>
                <List>
                    {optionalItems?.map((item) => (
                        <ListItem
                            button
                            key={item?.id}
                            onClick={() => selectItem(item)}
                        >
                            {!item?.id && (
                                <ListItemIcon>
                                    <AddIcon />
                                </ListItemIcon>
                            )}

                            <ListItemText primary={`${item?.name}`} />
                        </ListItem>
                    ))}
                </List>
            </Dialog>
        </>
    );
}
