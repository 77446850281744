import React, { useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import { AppContext } from '../../../../sporttia/AppContext';
import { useLoader } from '../../../../lib/hooks';
import useCustomersService from '../../../../services/CustomersService';
import SttValidatedForm from '../../../../sporttia/SttValidatedForm';
import { SttList } from '../../../../sporttia/lists/SttList';

/**
 * Customer details view. View with form to modify customer data.
 * @param customer Customer object.
 * @param status React-query status.
 * @param onCreate Launched when a new customer is created.
 * @param onUpdate Launched when a new customer is updated.
 * @param onDelete Launched when a new customer is removed.
 * @returns {JSX.Element}
 */
export default function AdminCustomerDetails({
    customer,
    status,
    onCreate,
    onUpdate,
    onDelete,
}) {
    const cxt = useContext(AppContext);
    const customersService = useCustomersService();
    const deleteMutation = useMutation(
        (params) => customersService.remove(params?.id),
        {
            onSuccess: onDelete,
        },
    );
    const updateMutation = useMutation(
        (params) => customersService.update(params?.id, params?.params),
        {
            onSuccess: onUpdate,
        },
    );
    const createMutation = useMutation(
        (params) => customersService.create(params?.params),
        {
            onSuccess: (data) => onCreate(data?.customer?.id),
        },
    );
    const form = useForm();
    const [loading, loader] = useLoader([
        deleteMutation.status,
        updateMutation.status,
        createMutation.status,
    ]);

    /**
     * Method executed when the user clicks on save or create client.
     */
    const mutateCustomer = (formData) => {
        if (customer?.id) {
            updateMutation.mutate({ id: customer?.id, params: formData });
        } else {
            createMutation.mutate({ params: formData });
        }
    };

    /**
     * When the data is returned by the API, it is set in the form.
     */
    useEffect(() => {
        if (status === 'success' && customer) {
            form.reset({
                name: customer?.name,
                type: customer?.type,
                cif: customer?.cif,
                numInvoiceFormat: customer?.numInvoiceFormat,
                address: customer?.address,
                phone: customer?.phone,
                contactPerson: customer?.contactPerson,
                email: customer?.email,
                bic: customer?.bic,
                iban: customer?.iban,
                notes: customer?.notes,
            });
        } else {
            form.reset({
                name: '',
                type: 'TOWNHALL',
                cif: '',
                numInvoiceFormat: '',
                address: '',
                phone: '',
                contactPerson: '',
                email: '',
                bic: '',
                iban: '',
                notes: '',
            });
        }
    }, [customer]);

    return (
        <>
            {loader}
            <Grid container spacing={3}>
                <Grid item xs={12} md={9} sm={12}>
                    <SttValidatedForm
                        form={form}
                        loadingData={status === 'loading'}
                        fields={[
                            {
                                type: 'textInput',
                                name: 'name',
                                caption: cxt.t('Name'),
                                rules: {
                                    required:
                                        'Es obligatorio añadir el nombre del centro.',
                                    minLength: {
                                        value: 5,
                                        message:
                                            'Por favor el centro debe contener al menos 5 carácteres.',
                                    },
                                },
                                disabled: false,
                                md: 6,
                                sm: 6,
                            },
                            {
                                type: 'select',
                                name: 'type',
                                caption: cxt.t('Type'),
                                options: [
                                    {
                                        caption: cxt.t('CT.TOWNHALL'),
                                        value: 'TOWNHALL',
                                    },
                                    {
                                        caption: cxt.t('CT.CLUB'),
                                        value: 'CLUB',
                                    },
                                ],
                                rules: {
                                    required:
                                        'Es obligatorio añadir el tipo de centro.',
                                },
                                disableClearable: true,
                                md: 2,
                                sm: 6,
                            },
                            {
                                type: 'textInput',
                                name: 'cif',
                                caption: 'CIF',
                                md: 2,
                                sm: 6,
                            },
                            {
                                type: 'textInput',
                                name: 'numInvoiceFormat',
                                caption: cxt.t('BillNumber'),
                                md: 2,
                                sm: 6,
                            },
                            {
                                type: 'textInput',
                                name: 'address',
                                caption: cxt.t('address'),
                                md: 4,
                                sm: 6,
                            },
                            {
                                type: 'textInput',
                                name: 'phone',
                                caption: cxt.t('Phone'),
                                rules: {
                                    pattern: {
                                        value: /^\s*\+?\s*([0-9][\s-]*){9,}$/,
                                        message:
                                            'Por favor escribe un número de teléfono válido.',
                                    },
                                },
                                md: 4,
                                sm: 6,
                            },
                            {
                                type: 'textInput',
                                name: 'contactPerson',
                                caption: cxt.t('contactPerson'),
                                md: 4,
                                sm: 6,
                            },
                            {
                                type: 'textInput',
                                name: 'email',
                                caption: cxt.t('Email'),
                                md: 4,
                                sm: 6,
                            },
                            {
                                type: 'textInput',
                                name: 'bic',
                                caption: 'BIC',
                                md: 4,
                                sm: 6,
                            },
                            {
                                type: 'textInput',
                                name: 'iban',
                                caption: 'IBAN',
                                md: 4,
                                sm: 6,
                            },
                            {
                                type: 'textArea',
                                name: 'notes',
                                caption: cxt.t('notes'),
                                rows: 8,
                            },
                        ]}
                        buttons={[
                            {
                                show: !customer?.trash,
                                type: 'save',
                                onClick: form.handleSubmit((formData) =>
                                    mutateCustomer(formData),
                                ),
                            },
                            {
                                show: customer?.id && !customer?.trash,
                                type: 'delete',
                                onClick: () =>
                                    deleteMutation.mutate({ id: customer?.id }),
                            },
                        ]}
                    />
                </Grid>
                <Grid item xs={12} md={3} sm={12}>
                    <Grid container spacing={3}>
                        {customer?.id && customer?.scs && (
                            <Grid item xs={12} md={12} sm={12}>
                                <SttList
                                    fullWidth
                                    title={cxt.t('Centers')}
                                    data={
                                        customer &&
                                        customer?.scs.map((sc) => ({
                                            caption: sc?.name,
                                        }))
                                    }
                                    loadingData={loading}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
