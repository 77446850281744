import React, { useState, useContext } from 'react';
import {
    Paper,
    Box,
    Button,
    Container,
    Grid,
    Link,
    Typography,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import LogoHeading from '../../layout/LogoHeading';
import { AppContext, SttFormText } from '../../sporttia/all';
import { useAPI } from '../../lib/hooks';

type ApiError = {
    error: {
        msg: string;
    };
};

type OnChangeValue = {
    value: string;
};

// Recover password screen.
export default function RecoverPassword() {
    const cxt = useContext(AppContext)!;
    const { migrationApis } = cxt;
    const [request] = useAPI(migrationApis);
    const [email, setEmail] = useState<string>('');

    function send() {
        if (!email) {
            return;
        }

        request('PUT', `/recover`, { email })
            .then(() => {
                cxt.showMessage('S', cxt.t('view.recoverPassword.sendEmail'));
            })
            .catch((response: ApiError) => {
                cxt.showMessage('E', response.error.msg);
            });
    }

    return (
        <Container maxWidth="sm">
            <Box mt={9}>
                <Paper>
                    <Box p={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <LogoHeading
                                    caption={cxt.t(
                                        'view.recoverPassword.title',
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: cxt.t(
                                            'view.recoverPassword.instructions',
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* @ts-expect-error pasar SttFormText a TS */}
                                <SttFormText
                                    caption={cxt.t(
                                        'view.recoverPassword.writeYourEmail',
                                    )}
                                    defVal={email}
                                    onChange={({ value }: OnChangeValue) =>
                                        setEmail(value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box textAlign="center">
                                    <Button
                                        style={{ width: 200 }}
                                        variant="contained"
                                        color="primary"
                                        startIcon={<SendIcon />}
                                        onClick={send}
                                    >
                                        {cxt.t('Send')}
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box textAlign="center">
                                    <Link href="/login">
                                        {cxt.t('app.goBack')}
                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        </Container>
    );
}
