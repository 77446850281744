import React from 'react';
import { usePageHeader } from '../../../../lib/hooks';
import Report from '../../../sc/Report';

/**
 * View where the sporttia superadmin can see the list of rentals ratio by sport center.
 * @returns {JSX.Element}
 */
function AdminRentalsRatioByCenter({}) {
    usePageHeader('rentalsRatioByCenter');

    return <Report id={30} navigateBack="/home" />;
}

export default AdminRentalsRatioByCenter;
