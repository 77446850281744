import React, { useState, useContext, Fragment } from 'react';
import { Box, CircularProgress, Link, Typography } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import { AppContext } from '../../../sporttia/AppContext';
import { SttFeeRow, SttNoResults, SttPagination } from '../../../sporttia/all';
import PaymentModal from '../../../components/paymentModal/PaymentModal';
import { fullName, updateElementInArray } from '../../../lib/utils';
import useGroupsService from '../../../services/GroupsService';
import translations from '../../../translations';
import SttError from '../../../components/error/SttError';
import { SttTopFilteringControls } from '../../../sporttia/SttTopFilteringControls';
import { Group } from '../../../types/api/models';
import Payment from '../../../types/models/Payment';
import { GetGroupMembers200 } from '../../../types/api/paths/Group';
import GroupMember, { Fee } from '../../../types/models/GroupMember';

type GroupFeesProps = {
    item: Group;
};

/**
 * GroupFees - List of memers' fees
 * Object item - The group object
 */
export default function GroupFees({ item }: GroupFeesProps) {
    const cxt = useContext(AppContext)!;
    const [idPayModal, setIdPayModal] = useState<number | null>(null);

    const queryClient = useQueryClient();

    const groupsService = useGroupsService();

    const [getGroupMembersPage, setGetGroupMembersPage] = useState(1);
    const [getGroupMembersFilters, setGetGroupMembersFilters] = useState({
        name: '',
    });
    const getGroupMembersQuery = groupsService.useGetGroupMembers(item.id, {
        rows: 25,
        page: getGroupMembersPage,
        ...getGroupMembersFilters,
    });

    function updateFeeFromPayment(payment: Payment) {
        queryClient.setQueryData<GetGroupMembers200 | undefined>(
            getGroupMembersQuery.queryKey,
            (old) => {
                if (!old) {
                    return undefined;
                }

                return {
                    ...old,
                    rows: old.rows.map((row) => {
                        if (payment.user.id === row.user.id) {
                            return {
                                ...row,
                                fees: updateElementInArray(row.fees, payment, {
                                    merge: true,
                                }),
                            };
                        }

                        // When a fee is paid for a child, the payment is made by the parent.
                        // Therefore, we search among the children of the payer and add the payment to the child's fees.
                        const foundChildrenPayment =
                            payment.children?.rows?.find(
                                (children) => children.user.id === row.user.id,
                            );
                        if (row.fees && foundChildrenPayment) {
                            return {
                                ...row,
                                fees: row.fees.map((fee) =>
                                    fee.id === foundChildrenPayment.id
                                        ? payment
                                        : fee,
                                ),
                            };
                        }

                        return row;
                    }),
                };
            },
        );
    }

    function deleteFeeFromPayment(payment: Payment) {
        queryClient.setQueryData<GetGroupMembers200 | undefined>(
            getGroupMembersQuery.queryKey,
            (old) => {
                if (!old) {
                    return undefined;
                }

                return {
                    ...old,
                    rows: old.rows.map((row) => {
                        if (row.fees && row.user.id === payment.user.id) {
                            return {
                                ...row,
                                fees: row.fees.map((fee) => {
                                    if (fee.id !== payment.id) {
                                        return fee;
                                    }

                                    return {
                                        ...fee,
                                        status: null,
                                        trash: true,
                                    };
                                }),
                            };
                        }

                        // When a fee is paid for a child, the payment is made by the parent.
                        // Therefore, we search among the children of the payer and add the payment to the child's fees.
                        const foundChildrenPayment =
                            payment.children?.rows?.find(
                                (children) => children.user.id === row.user.id,
                            );
                        if (row.fees && foundChildrenPayment) {
                            return {
                                ...row,
                                fees: row.fees.map((fee) =>
                                    fee.id === payment.id
                                        ? {
                                              ...fee,
                                              status: null,
                                              trash: true,
                                          }
                                        : fee,
                                ),
                            };
                        }

                        return row;
                    }),
                };
            },
        );
    }

    function onFeeGenerated(
        updatedFee: Fee,
        indexFee: number,
        selectedMember: GroupMember,
    ) {
        if (
            updatedFee === undefined ||
            indexFee === undefined ||
            selectedMember === undefined
        ) {
            return;
        }

        queryClient.setQueryData<GetGroupMembers200 | undefined>(
            getGroupMembersQuery.queryKey,
            (old) => {
                if (!old) {
                    return undefined;
                }

                // Copio los miembros.
                const currentMembers = { ...old };

                // Copio el miembro que voy actualizar.
                const updatedMember = { ...selectedMember };

                // Actualizo la cuota que se ha actualizado.
                updatedMember.fees![indexFee] = {
                    ...updatedMember.fees![indexFee],
                    ...updatedFee,
                };

                // Busco el indice del miembro dentro del array de miembros anterior.
                const memberIndex = currentMembers.rows.findIndex(
                    (member) => member.id === updatedMember.id,
                );

                // Con el indice anterior sustituyo el miembro antiguo por el actualizado (ya tiene la cuota actualizada).
                currentMembers.rows[memberIndex] = {
                    ...currentMembers.rows[memberIndex],
                    ...updatedMember,
                };

                // Actualizo el objeto de miembros para pintarlo definitivamente en la vista.
                return currentMembers;
            },
        );
    }

    if (getGroupMembersQuery.isError) {
        return <SttError />;
    }

    return (
        <>
            {/* @ts-expect-error: Migrate SttTopFilteringControls  */}
            <SttTopFilteringControls
                p={2}
                fields={[
                    {
                        type: 'search',
                        caption: cxt.t(translations.generic.name),
                        name: 'name',
                        value: '',
                    },
                ]}
                onFilter={(filters: { name: string }) => {
                    setGetGroupMembersFilters(filters);
                    setGetGroupMembersPage(1);
                }}
            />

            {getGroupMembersQuery.isLoading && (
                <Box display="flex" justifyContent="center" paddingY={12}>
                    <CircularProgress />
                </Box>
            )}

            {getGroupMembersQuery.isSuccess &&
                getGroupMembersQuery.data.count > 0 && (
                    <Box display="flex" justifyContent="flex-end" mb={3}>
                        <SttPagination
                            page={getGroupMembersPage}
                            pages={getGroupMembersQuery.data.pages}
                            count={getGroupMembersQuery.data.count}
                            onChangePage={(page) => {
                                setGetGroupMembersPage(page);
                            }}
                        />
                    </Box>
                )}

            <Box m={3}>
                {getGroupMembersQuery.isSuccess &&
                    getGroupMembersQuery.data.count > 0 &&
                    getGroupMembersQuery.data.rows.map((member) => (
                        <Fragment key={member.id}>
                            <Box mt={1} mb={1} display="flex">
                                <Link
                                    style={{ flexGrow: 0, cursor: 'pointer' }}
                                >
                                    <Typography
                                        style={{ fontSize: 16 }}
                                        variant="body2"
                                    >
                                        {fullName(member.user)}
                                    </Typography>
                                </Link>
                            </Box>
                            {/* @ts-expect-error: Migrate SttFeeRow */}
                            <SttFeeRow
                                fees={member.fees}
                                onFeeClicked={(id: number) => setIdPayModal(id)}
                                onGenerated={(fee: Fee, indexFee: number) =>
                                    onFeeGenerated(fee, indexFee, member)
                                }
                            />
                        </Fragment>
                    ))}

                {getGroupMembersQuery.data?.count === 0 && <SttNoResults />}
            </Box>

            {idPayModal && (
                <PaymentModal
                    idPayment={idPayModal}
                    onClose={() => {
                        setIdPayModal(null);
                    }}
                    onPay={updateFeeFromPayment}
                    onSave={updateFeeFromPayment}
                    onDeleted={deleteFeeFromPayment}
                    onRecovered={updateFeeFromPayment}
                    onRefund={deleteFeeFromPayment}
                />
            )}
        </>
    );
}
