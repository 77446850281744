import React, { useState, useEffect, useContext } from 'react';
import { Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
    AppContext,
    SttButtonFab,
    SttTable,
    SttTopControls,
} from '../../../sporttia/all';
import { useToggle, useTuples, useCrud } from '../../../lib/hooks';
import { getPath } from '../../Pages';
import RestrictionUserDialog from './RestrictionUserDialog';
import { fullName } from '../../../lib/utils';

export default function RestrictionUsers({ id }) {
    const cxt = useContext(AppContext);
    const history = useHistory();

    const [userRestrictions, setUserRestrictions, , Get, Post, Put, Delete] =
        useCrud();
    const [filters, setFilter] = useTuples({
        name: '',
    });

    const [viewRestriction, setViewRestriction] = useState(null);

    function loadUsers(params = { rows: 20 }) {
        Get(`/bookings/restrictions/${id}/users`, {
            ...params,
            ...filters,
        }).then(setUserRestrictions);
    }

    function saveRestriction(restriction) {
        if (restriction.id) {
            Put(`/bookings/restrictions/users/${restriction.id}`, {
                ini: restriction.ini,
                end: restriction.end,
            }).then((result) => {
                setUserRestrictions({
                    ...userRestrictions,
                    rows: userRestrictions.rows.map((item) =>
                        item.id !== restriction.id ? item : restriction,
                    ),
                });
                setViewRestriction(null);
            });
        } else {
            Post(`/bookings/restrictions/${id}/users/${restriction.user.id}`, {
                ini: restriction.ini,
                end: restriction.end,
            }).then((result) => {
                if (result.bookingRestrictionUser) {
                    setUserRestrictions({
                        count: userRestrictions.count + 1,
                        rows: userRestrictions.rows.concat([
                            result.bookingRestrictionUser,
                        ]),
                    });
                    setViewRestriction(null);
                }
            });
        }
    }

    function deleteRestriction(restriction) {
        Delete(`/bookings/restrictions/users/${restriction.id}`).then(
            (result) => {
                setUserRestrictions({
                    count: userRestrictions.count - 1,
                    rows: userRestrictions.rows.filter(
                        (item) => item.id !== restriction.id,
                    ),
                });
                setViewRestriction(null);
            },
        );
    }

    console.log(userRestrictions);

    return (
        <>
            <SttTopControls
                p={2}
                fields={[
                    {
                        caption: cxt.t('Name'),
                        type: 'text',
                        name: 'name',
                        value: filters.name,
                        onEnterKey: loadUsers,
                    },
                ]}
                onChange={setFilter}
                onFilter={loadUsers}
                mainAction={{
                    type: 'create',
                    onClick: () =>
                        setViewRestriction({
                            ini: moment().format('YYYY-MM-DD'),
                            end: moment().format('YYYY-12-31'),
                            user: {
                                name: '',
                            },
                        }),
                }}
            />
            <SttTable
                onFetch={loadUsers}
                data={userRestrictions}
                onClickRow={(row) => setViewRestriction(row)}
                columns={[
                    {
                        title: cxt.t('Name'),
                        align: 'left',
                        type: 'text',
                        value: (row) => row.user && fullName(row.user),
                    },
                    {
                        title: cxt.t('Begin'),
                        type: 'dmy',
                        field: 'ini',
                    },
                    {
                        title: cxt.t('End'),
                        type: 'dmy',
                        field: 'end',
                    },
                ]}
            />
            {viewRestriction && (
                <RestrictionUserDialog
                    item={viewRestriction}
                    onSave={saveRestriction}
                    onClose={() => setViewRestriction(null)}
                    onDelete={deleteRestriction}
                />
            )}
        </>
    );
}
