import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import { AppContext, SttTopControls, SttTable } from '../../../sporttia/all';
import { useToggle } from '../../../lib/hooks';
import { paths, getPath } from '../../Pages';
import { copyToClipboard } from '../../../lib/utils';

export default function Remittences() {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const [remittences, setRemittences] = useState();
    const [filters, setFilters] = useState({
        name: '',
    });

    // Set header
    useEffect(() => {
        cxt.setHeader({
            title: cxt.t('Remittences'),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Load free schedules
     */
    const loadRemittences = (params = { rows: 20, page: 1 }) => {
        cxt.api('GET', `/scs/${cxt.sc.id}/remittences`, {
            params: {
                ...{
                    ...filters,
                    name: filters?.name ? filters.name : undefined,
                },
                ...params,
                // eslint-disable-next-line no-use-before-define
                trash,
            },
            success: (r) => {
                setRemittences(r);
            },
        });
    };

    // Trash hook
    const [trash, toggleTrash] = useToggle(() => {
        loadRemittences();
    });

    /**
     * Go to generate remittence
     */
    const goToGenerateRemittend = () => {
        history.push(paths.find((e) => e.id === 'genRemittences').path); // '/scs/remittences/generate')
    };

    // -----| Render |-----
    return (
        <Paper>
            <SttTopControls
                p={2}
                fields={[
                    {
                        caption: cxt.t('Name'),
                        name: 'name',
                        type: 'text',
                        value: filters.name,
                        onEnterKey: loadRemittences,
                    },
                ]}
                onChange={({ name, value }) =>
                    setFilters({ ...filters, [name]: value })
                }
                onFilter={loadRemittences}
                onToggleTrash={toggleTrash}
                mainAction={{
                    type: 'create',
                    onClick: () => goToGenerateRemittend(),
                }}
            />

            <SttTable
                data={remittences}
                loading={false}
                columns={[
                    {
                        title: '#',
                        type: 'tooltip',
                        value: (row) => ({ label: '#', value: row.id }),
                        onClick: (row) =>
                            copyToClipboard(row.id, () =>
                                cxt.showMessage(
                                    'S',
                                    cxt.t('copiedToClipboard'),
                                ),
                            ),
                    },
                    {
                        type: 'link',
                        title: cxt.t('Name'),
                        field: 'name',
                        // to: row => `/scs/remittences/${row.id}`,
                        to: (row) => getPath('remittence', { id: row.id }),
                    },
                    {
                        type: 'active',
                        title: cxt.t('Sent'),
                        field: 'status',
                        value: (row) => row.status === 'SENT',
                    },
                    {
                        type: 'dmy',
                        title: cxt.t('Paid'),
                        field: 'issueDate',
                    },
                    {
                        type: 'dmy',
                        title: cxt.t('downloaded'),
                        field: 'downloadedDate',
                    },
                    /* {
						type: 'counter',
						title: cxt.t('Receipts'),
						value: row => 'TODO'
					}, */
                    {
                        type: 'price',
                        title: cxt.t('Total'),
                        field: 'total',
                    },
                ]}
                onFetch={loadRemittences}
            />
        </Paper>
    );
}
