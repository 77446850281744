import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { FilterList as FilterListIcon } from '@material-ui/icons';
import { useTranslations } from '../../lib/hooks';

type SttButtonFilterProps = {
    onClick: () => void;
    caption?: string;
} & ButtonProps;

/**
 * Filter button
 */
export function SttButtonFilter({
    onClick,
    caption,
    ...rest
}: SttButtonFilterProps) {
    const { translate } = useTranslations();

    return (
        <Button
            variant="outlined"
            onClick={onClick}
            startIcon={<FilterListIcon />}
            {...rest}
        >
            {caption || translate('Filter')}
        </Button>
    );
}
