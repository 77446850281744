import React, { useState, useContext } from 'react';
import {
    Avatar,
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    DialogContent,
} from '@material-ui/core';
import { useQuery } from 'react-query';
import { useForm } from 'react-hook-form';
import { AppContext, SttList } from '../all';
import { LIST_FACILITIES } from '../../lib/queryKeys';
import useScsService from '../../services/ScsService';
import SttValidatedForm from '../SttValidatedForm';

/**
 * Component to list facilities. A button appears to add new installations.
 * @param facilities Facilities array.
 * @param onAdd Method executed when adding new installation.
 * @param onDelete Method executed when removing an installation.
 * @param scId Sport center id.
 * @param disabled Disable functions.
 * @param status "status" parameter sent in the api request.
 * @param trash Get removed or not facilities.
 * @returns {JSX.Element}
 */
export function SttFacilityList({
    facilities,
    onAdd,
    onDelete,
    scId = null,
    disabled = false,
    status = null,
    trash = false,
}) {
    const cxt = useContext(AppContext);
    const scsService = useScsService();
    const form = useForm();
    const [filters, setFilters] = useState({});
    const facilityQuery = useQuery(
        [
            LIST_FACILITIES,
            {
                id: scId || cxt.sc.id,
                trash,
                ...{
                    ...filters,
                    rows: 200,
                    ...(status && { status }),
                },
            },
        ],
        () =>
            scsService.getScsFacilities(scId || cxt.sc.id, {
                trash,
                ...{
                    ...filters,
                    rows: 200,
                    ...(status && { status }),
                },
            }),
    );

    const [selectingFacilityOpen, setSelectingFacilityOpen] = useState(false);

    return (
        <>
            <SttList
                title={cxt.t('Facilities')}
                onAdd={
                    !disabled && onAdd
                        ? () => setSelectingFacilityOpen(true)
                        : null
                }
                onDelete={!disabled && onDelete}
                data={
                    facilities &&
                    facilities.map((facility) => ({
                        avatar: facility.sport ? (
                            <Avatar
                                style={{
                                    width: 30,
                                    height: 30,
                                    borderWidth: 1,
                                    borderStyle: 'solid',
                                    borderColor: '#ddd',
                                    padding: 2,
                                }}
                                src={cxt.getResource(
                                    `/sports/${facility.sport.id}.png`,
                                )}
                            />
                        ) : null,
                        caption: facility.name,
                        objToSelect: facility,
                    }))
                }
            />

            <Dialog
                onClose={() => setSelectingFacilityOpen(false)}
                open={selectingFacilityOpen}
            >
                <DialogTitle>{cxt.t('Facilities')}</DialogTitle>

                <DialogContent>
                    <SttValidatedForm
                        form={form}
                        fields={[
                            {
                                type: 'textInput',
                                name: 'name',
                                caption: cxt.t('Facility'),
                                onEnterKey: (value) =>
                                    setFilters({ name: value }),
                            },
                        ]}
                    />

                    <List>
                        {facilityQuery?.data?.rows &&
                            facilityQuery?.data?.rows.map((facility, idx) => (
                                <ListItem
                                    button
                                    key={idx}
                                    onClick={() => {
                                        setSelectingFacilityOpen(false);
                                        onAdd(facility);
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar
                                            style={{
                                                width: 30,
                                                height: 30,
                                                borderWidth: 1,
                                                borderStyle: 'solid',
                                                borderColor: '#ddd',
                                                padding: 2,
                                            }}
                                            src={cxt.getResource(
                                                `/sports/${facility.sport.id}.png`,
                                            )}
                                        />
                                    </ListItemAvatar>

                                    <ListItemText primary={facility.name} />
                                </ListItem>
                            ))}
                    </List>
                </DialogContent>
            </Dialog>
        </>
    );
}
