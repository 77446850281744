import React, { useState, useEffect, useContext, Fragment } from 'react';
import { Box, Paper } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { AppContext, SttTabs, SttButtonFab } from '../../../sporttia/all';
import MessageList from './MessageList';
import NewMessageDialog from '../newMessageDialog/NewMessageDialog';

export default function SCMessages({}) {
    const cxt = useContext(AppContext);
    const [openedNewMessage, setOpenedNewMessage] = useState(false);

    // -----| Render |-----
    return (
        <>
            <SttTabs
                tabs={[
                    {
                        caption: cxt.t('Inbox'),
                        // icon: <ArrowDownwardIcon />,
                        component: <MessageList fold="INBOX" />,
                    },
                    {
                        caption: cxt.t('Sent'),
                        // icon: <ArrowUpwardIcon />,
                        component: <MessageList fold="SENT" />,
                    },
                ]}
            />

            <SttButtonFab onClick={() => setOpenedNewMessage(true)} />

            <NewMessageDialog
                open={openedNewMessage}
                onClose={() => setOpenedNewMessage(false)}
            />
        </>
    );
}
