import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

/**
 * Draw golf tee with or without time and players list.
 * @param teeNumber Integer with tee number.
 * @param disableTime Boolean {true, false} Enable or not the time on the left side of the tee.
 * @returns {JSX.Element}
 */
export default function GolfTimetableTee({
    teeNumber = 0,
    disableTime = true,
}) {
    const classes = useStyles();

    /**
     * Render line with players inside squares.
     * @param players [] Players array.
     * @returns {JSX.Element}
     */
    const renderPlayersLine = (players) => (
        <Box display="flex">
            {players &&
                players.map((player) => (
                    <Box
                        className={`${classes.playerBox} ${
                            player.name
                                ? classes.playerBoxBusy
                                : classes.playerBoxAvailable
                        }`}
                        display="flex"
                    >
                        <Box m="auto">{player.name}</Box>
                    </Box>
                ))}
        </Box>
    );

    /**
     * Render the tee including the time on the left side (if active) and players.
     * @param hours [] Array with tee time lines (include time and player list).
     * @returns {*}
     */
    const renderTee = (hours) =>
        hours &&
        hours.map((hour) => (
            <Box className={classes.teeBox} display="flex">
                {!disableTime && (
                    <Box m="auto" className={classes.timeLabel}>
                        <Typography variant="subtitle2">{hour.time}</Typography>
                    </Box>
                )}
                {renderPlayersLine(hour.players)}
            </Box>
        ));

    {
        /* Render golf tee number and tee data with time (if active) and player line. */
    }
    return (
        <Box>
            <Typography
                variant="subtitle2"
                align="center"
            >{`TEE ${teeNumber}`}</Typography>
            {renderTee(teeDataMockUp.hours)}
        </Box>
    );
}

const useStyles = makeStyles(() => ({
    playerBox: {
        height: 40,
        width: 80,
        margin: 3,
        color: 'white',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'center',
        fontSize: 12,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgba(33, 150, 243, 0.5)',
        },
        borderRadius: 2,
    },
    playerBoxBusy: {
        backgroundColor: '#F65757',
    },
    playerBoxAvailable: {
        backgroundColor: '#C4D745',
    },
    teeBox: {
        margin: 3,
    },
    timeLabel: {
        paddingRight: 10,
    },
}));

{
    /* This data will be removed when golf module development finished. */
}
const teeDataMockUp = {
    hours: [
        {
            time: '09:00',
            players: [
                { id: 0, name: 'Jugador 1' },
                { id: 1, name: 'Jugador 2' },
                { id: 2, name: 'Jugador 3' },
                { id: 3, name: null },
            ],
        },
        {
            time: '09:10',
            players: [
                { id: 0, name: 'Jugador 1' },
                { id: 1, name: 'Jugador 2' },
                { id: 2, name: null },
                { id: 3, name: null },
            ],
        },
        {
            time: '09:20',
            players: [
                { id: 0, name: 'Jugador 1' },
                { id: 1, name: 'Jugador 2' },
                { id: 2, name: 'Jugador 3' },
                { id: 3, name: 'Jugador 4' },
            ],
        },
        {
            time: '09:30',
            players: [
                { id: 0, name: 'Jugador 1' },
                { id: 1, name: 'Jugador 2' },
                { id: 2, name: 'Jugador 3' },
                { id: 3, name: 'Jugador 4' },
            ],
        },
        {
            time: '09:40',
            players: [
                { id: 0, name: 'Jugador 1' },
                { id: 1, name: 'Jugador 2' },
                { id: 2, name: 'Jugador 3' },
                { id: 3, name: 'Jugador 4' },
            ],
        },
        {
            time: '09:50',
            players: [
                { id: 0, name: 'Jugador 1' },
                { id: 1, name: 'Jugador 2' },
                { id: 2, name: 'Jugador 3' },
                { id: 3, name: 'Jugador 4' },
            ],
        },
        {
            time: '10:00',
            players: [
                { id: 0, name: 'Jugador 1' },
                { id: 1, name: 'Jugador 2' },
                { id: 2, name: 'Jugador 3' },
                { id: 3, name: 'Jugador 4' },
            ],
        },
        {
            time: '10:10',
            players: [
                { id: 0, name: 'Jugador 1' },
                { id: 1, name: 'Jugador 2' },
                { id: 2, name: 'Jugador 3' },
                { id: 3, name: 'Jugador 4' },
            ],
        },
        {
            time: '10:20',
            players: [
                { id: 0, name: 'Jugador 1' },
                { id: 1, name: 'Jugador 2' },
                { id: 2, name: 'Jugador 3' },
                { id: 3, name: 'Jugador 4' },
            ],
        },
        {
            time: '10:30',
            players: [
                { id: 0, name: 'Jugador 1' },
                { id: 1, name: 'Jugador 2' },
                { id: 2, name: 'Jugador 3' },
                { id: 3, name: 'Jugador 4' },
            ],
        },
    ],
};
