import React, { useContext, useState } from 'react';
import { Box, Link } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useMutation, useQueryClient } from 'react-query';
import { SUPERADMIN_API_TRIGGERS_EXECUTIONS } from '../../../../lib/queryKeys';
import { AppContext } from '../../../../sporttia/AppContext';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';
import AdminApiTriggerExecutionDialog from './AdminApiTriggerExecutionDialog';
import {
    useLoader,
    usePageHeader,
    useQueryFilters,
    useTranslations,
} from '../../../../lib/hooks';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import useCoresService from '../../../../services/CoresService';
import { SttButtonAccept } from '../../../../sporttia/all';
import FilterDialog from '../../../../components/filter/FilterDialog';
import DateBuilder from '../../../../lib/DateBuilder';

const useStyles = makeStyles({
    playButton: {
        '&:hover': {
            cursor: 'pointer',
            color: 'rgba(33, 150, 243, 0.5)',
        },
    },
});

/**
 * View where the sporttia superadmin can see the list of apis triggers executions.
 * @returns {JSX.Element}
 */
export default function AdminApiTriggersExecutions() {
    usePageHeader('apiTriggersExecutions');

    const cxt = useContext(AppContext);
    const classes = useStyles();
    const { translate } = useTranslations();

    const queryClient = useQueryClient();
    const coresService = useCoresService();
    const [filters, setFilters] = useQueryFilters(
        {},
        SUPERADMIN_API_TRIGGERS_EXECUTIONS,
    );
    const [triggerExecution, setTriggerExecution] = useState(null);
    const [executionRunParams, setExecutionRunParams] = useState({
        scId: null,
        maxExecutions: 100,
    });
    const [triggersExecutionDialog, setTriggerExecutionDialog] =
        useState(false);

    const createMutation = useMutation(
        (params) => coresService.createCoresTriggersExecutionsRun(params?.id),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(
                    SUPERADMIN_API_TRIGGERS_EXECUTIONS,
                );
            },
        },
    );

    const coresTriggersMaxExecutionRun = useMutation(
        (params) => coresService.coresTriggersMaxExecutionRun(params),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(
                    SUPERADMIN_API_TRIGGERS_EXECUTIONS,
                );
            },
        },
    );

    const [, loader] = useLoader([createMutation.status]);

    return (
        <Paper>
            {loader}
            <SttTopFilteringControls
                p={2}
                fields={[
                    {
                        name: 'name',
                        type: 'text',
                        value: '',
                        caption: cxt.t('SportcenterName'),
                    },
                ]}
                extraFields={[
                    {
                        name: 'id',
                        type: 'text',
                        value: '',
                        caption: 'Id',
                    },
                    {
                        name: 'code',
                        type: 'text',
                        value: '',
                        caption: cxt.t('Code'),
                    },
                    {
                        name: 'request',
                        type: 'text',
                        value: '',
                        caption: cxt.t('Request'),
                    },
                    {
                        name: 'answer',
                        type: 'text',
                        value: '',
                        caption: cxt.t('Responses'),
                    },
                    {
                        name: 'dateini',
                        type: 'date',
                        value: '',
                        caption: cxt.t('dateini'),
                    },
                    {
                        name: 'dateend',
                        type: 'date',
                        value: '',
                        caption: cxt.t('dateend'),
                    },
                    {
                        name: 'timeini',
                        type: 'time',
                        value: '',
                        caption: cxt.t('Timeini'),
                    },
                    {
                        name: 'timeend',
                        type: 'time',
                        value: '',
                        caption: cxt.t('Timeend'),
                    },
                    {
                        caption: cxt.t('status'),
                        name: 'status',
                        type: 'select',
                        value: 'ALL',
                        options: [
                            { id: 'ALL', label: cxt.t('All') },
                            { id: 'OK', label: 'OK' },
                            { id: 'ERROR', label: cxt.t('ERROR') },
                            { id: 'SKIP', label: 'Skip' },
                        ],
                    },
                ]}
                onFilter={(value) => {
                    let { dateend, dateini } = { ...value };
                    const { timeend, timeini, ...newValues } = {
                        ...value,
                    };

                    if (dateini) {
                        dateini = timeini
                            ? new DateBuilder(dateini).setTime(timeini).ymdhms()
                            : new DateBuilder(dateini).ymd();
                    }
                    if (dateend) {
                        dateend = timeend
                            ? new DateBuilder(dateend).setTime(timeend).ymdhms()
                            : new DateBuilder(dateend).ymd();
                    }

                    if (newValues.status === 'ALL') {
                        newValues.status = null;
                    }

                    setFilters({ ...newValues, dateini, dateend });
                }}
            />
            <Box md={6}>
                <SttButtonAccept
                    caption={translate('CoreTriggerMaxExecutions')}
                    md={3}
                    onClick={() => {
                        setTriggerExecutionDialog(true);
                    }}
                    style={{ marginLeft: 25 }}
                />
            </Box>
            <FilterDialog
                title={cxt.t('Filter')}
                open={triggersExecutionDialog}
                onClose={() => {
                    setTriggerExecutionDialog(false);
                }}
                fields={[
                    {
                        caption: cxt.t('code'),
                        name: 'scId',
                        type: 'text',
                        value: executionRunParams.scId,
                    },
                    {
                        caption: cxt.t('numberOfExecutions'),
                        name: 'maxExecutions',
                        type: 'text',
                        value: executionRunParams.maxExecutions,
                    },
                ]}
                onChange={({ value, name }) => {
                    setExecutionRunParams({
                        ...executionRunParams,
                        [name]: value === '' ? null : value,
                    });
                }}
                onApply={() => {
                    coresTriggersMaxExecutionRun.mutate({
                        scId: executionRunParams.scId,
                        maxExecutions: executionRunParams.maxExecutions,
                    });
                    setTriggerExecutionDialog(false);
                }}
            />
            <SttCachedTable
                queryKey={SUPERADMIN_API_TRIGGERS_EXECUTIONS}
                queryFn={(params) =>
                    coresService.getCoresTriggersExecutions(params)
                }
                queryParams={filters}
                columns={[
                    {
                        title: 'Id',
                        field: 'id',
                        value: (row) => (
                            <Link
                                onClick={() => setTriggerExecution(row)}
                                variant="body2"
                            >
                                {row.id}
                            </Link>
                        ),
                    },
                    {
                        title: 'Created',
                        field: 'created',
                        type: 'dmyhm',
                    },

                    {
                        title: 'Execution',
                        field: 'execution',
                        type: 'dmyhm',
                    },
                    {
                        title: 'Status',
                        field: 'status',
                        value: (row) => {
                            let color = 'red';
                            if (row?.status === 'OK') {
                                color = 'green';
                            }
                            if (row?.status === 'SKIP') {
                                color = 'blue';
                            }

                            return <b style={{ color }}>{row?.status}</b>;
                        },
                    },
                    {
                        title: 'SC',
                        field: 'sc',
                        value: (row) => row?.coreTriggerSC?.sc?.name,
                    },
                    {
                        title: 'Trigger',
                        value: (row) => row?.coreTriggerSC?.coreTrigger?.script,
                    },
                    {
                        title: 'Code',
                        value: (row) => row?.coreTriggerSC?.coreTrigger?.code,
                    },
                    {
                        title: 'Request',
                        width: 50,
                        value: (row) => row?.request?.slice(0, 30),
                    },
                    {
                        title: 'Answer',
                        width: 50,
                        value: (row) => row?.answer?.slice(0, 30),
                    },
                    {
                        title: 'Object',
                        width: 50,
                        value: (row) =>
                            (row?.mship && row?.mship?.id) ||
                            (row?.purse && row?.purse?.id) ||
                            (row?.group && row?.group?.id) ||
                            '',
                    },
                    {
                        title: '',
                        value: (row) => (
                            <PlayArrowIcon
                                className={classes.playButton}
                                onClick={() =>
                                    createMutation.mutate({ id: row?.id })
                                }
                            />
                        ),
                    },
                ]}
            />

            {triggerExecution && (
                <AdminApiTriggerExecutionDialog
                    triggerExecution={triggerExecution}
                    onClose={() => {
                        setTriggerExecution(null);
                    }}
                />
            )}
        </Paper>
    );
}
