import React, { useContext } from 'react';
import { Box, Divider } from '@material-ui/core';
import moment from 'moment';
import {
    AppContext,
    SttList,
    SttChipPForm,
    SttChipPaymentStatus,
    SttLabelDate,
} from '../all';
import { formatPriceByLocale } from '../../lib/utils';

/**
 *	Booking list
 *	============
 *
 *	Props:
 *  Array bookings
 *  function onSelect
 *  bool showTotals
 *
 */
export function SttBookingList({ bookings, onSelect, showTotals }) {
    const cxt = useContext(AppContext);

    // -----| Render |-----
    return (
        <SttList
            title={cxt.t('Bookings')}
            onSelect={onSelect}
            data={bookings.map((booking) => {
                const paid = booking.paid >= booking.price;

                return {
                    caption: (
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            style={{ fontSize: 14 }}
                        >
                            <div>{booking.name}</div>
                            <SttLabelDate date={booking.ini} />
                            <div>{`${moment(booking.ini).format(
                                'HH:mm',
                            )} - ${moment(booking.end).format('HH:mm')}`}</div>
                            <div style={{ color: paid ? 'green' : 'red' }}>
                                {formatPriceByLocale(
                                    booking.price,
                                    booking?.totalBillShape?.currency,
                                    booking?.totalBillShape?.locale,
                                )}
                            </div>
                        </Box>
                    ),
                    objToSelect: booking,
                };
            })}
        />
    );
}
