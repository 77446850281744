import React, { useState, useContext } from 'react';
import { makeStyles, Box, Link } from '@material-ui/core';
import moment from 'moment';
import clsx from 'clsx';
// import { Close } from '@material-ui/icons'; TODO eliminar tras comprobar que no se usa 8693tq2yt
import { AppContext } from './all';
import { formatPriceByLocale } from '../lib/utils';
/**
 *	Fee row
 *	=======
 *
 * Props
 *
 * 	* array fees:
 *
 * 		- double price
 * 		- Date date: ISO date
 * 		- string paymentForm
 *
 *  String (datetime) ini -> si una cuota es anterior a esta fecha, es que no hay que pintarla
 *  Function onFeeClicked callback
 *
 */

const useStyles = makeStyles((theme) => ({
    fee: {
        minWidth: 78,
        fontSize: 10,
        padding: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    feePending: {
        backgroundColor: '#f7d2d2',
        color: '#e23434',
        borderColor: '#e23434',
        cursor: 'pointer',
    },
    feePaid: {
        backgroundColor: '#a7f1b1',
        color: '#0d980d',
        borderColor: '#0d980d',
        cursor: 'pointer',
    },
    feeEmpty: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        color: '#7b7b7b',
        fontSize: 14,
    },
    generate: {
        cursor: 'pointer',
    },
}));

export function SttFeeRow({ fees, onFeeClicked, showUntil, onGenerated }) {
    const cxt = useContext(AppContext);
    const classes = useStyles();
    const [showMore, setShowMore] = useState(false);

    /**
     * Generate fee
     */
    const generateFee = (fee, indexFee) => {
        if (!fee.feeslot) {
            return false;
        }

        cxt.api('POST', `/feeslot/${fee.feeslot.id}/fees`, {
            // confirmation: true,
            params: { dates: [fee.ini.replace(' ', 'T')] },
            success: (response) => {
                if (typeof onGenerated === 'function' && response.fees?.[0]) {
                    onGenerated(response.fees[0], indexFee);
                }
            },
        });
    };

    let showFees;
    let seeMoreLink;

    if (fees && showUntil && !showMore) {
        showFees = fees.filter((fee) =>
            moment(fee.ini).isBefore(moment(showUntil)),
        );

        if (showFees.length === 0) {
            showFees = fees;
        }

        seeMoreLink = fees.length !== showFees.length;
    } else {
        showFees = fees;
    }

    // -----| Render |-----
    return (
        <>
            {showFees && (
                <Box display="flex" overflow="auto">
                    {showFees.map((fee, idx) => {
                        if (
                            (!fee.moment || fee.moment !== 'BLANK') &&
                            Number.parseFloat(fee.price) > 0
                        ) {
                            return (
                                <Box
                                    key={fee?.id ?? `${fee?.id}-${fee?.ini}`}
                                    display="inline"
                                    border={1}
                                    borderColor="grey.500"
                                    borderRadius="borderRadius"
                                    className={clsx({
                                        [classes.fee]: true,
                                        [classes.feePaid]:
                                            fee.status === 'PAID',
                                        [classes.feePending]:
                                            fee.id &&
                                            !fee.trash &&
                                            fee.status !== 'PAID',
                                    })}
                                    mb={2}
                                    onClick={() => {
                                        onFeeClicked(
                                            !fee.trash ? fee.id : undefined,
                                        );
                                    }}
                                >
                                    <div>
                                        {moment(fee.ini).format('D MMM YYYY')}
                                    </div>
                                    <div>
                                        <b>
                                            {formatPriceByLocale(
                                                fee.price,
                                                fee?.priceShape?.currency,
                                                fee?.priceShape?.locale,
                                            )}
                                        </b>
                                    </div>

                                    {fee.id && !fee.trash ? (
                                        <div>
                                            {fee.paymentForm &&
                                                cxt.t(`PF.${fee.paymentForm}`)}
                                        </div>
                                    ) : (
                                        <div>
                                            <Link
                                                className={classes.generate}
                                                onClick={() => {
                                                    generateFee(fee, idx);
                                                }}
                                            >
                                                {cxt.t('Generate')}
                                            </Link>
                                        </div>
                                    )}
                                </Box>
                            );
                        }
                        return null;
                        /* TODO eliminar esto tras prueba en at. cliente de que ya no se usa 8693tq2yt

                    return (
                        <Box
                            key={fee?.id ?? `${fee?.ini}-${idx}`}
                            className={clsx({
                                [classes.fee]: true,
                                [classes.feeEmpty]: true,
                            })}
                        >
                            <Close className={classes.icon} />
                        </Box>
                    ); */
                    })}
                </Box>
            )}

            {!showMore && seeMoreLink && (
                <Box
                    px={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Link
                        style={{ fontSize: 14 }}
                        onClick={() => setShowMore(true)}
                    >{`${cxt.t('feeRow.showAll')}...`}</Link>
                </Box>
            )}
        </>
    );
}
