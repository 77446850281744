import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { SttDialog } from './SttDialog';
import { AppContext } from '../AppContext';
import { useInteractionsFiles, useTranslations } from '../../lib/hooks';
import { SttButton } from '../buttons/SttButton';

function SttUserUnlockerDialog({
    unlocker = null,
    title = null,
    subtitle = null,
    onClose,
}) {
    const cxt = useContext(AppContext);
    const { translate } = useTranslations();
    const { downloadFile } = useInteractionsFiles();

    return (
        <SttDialog
            title={translate('Unlocker')}
            open={unlocker !== null}
            onClose={onClose}
            content={
                <>
                    {unlocker && unlocker.type === 'PIN' && unlocker.code && (
                        <Box align="center" mx="auto">
                            <Typography
                                variant="h3"
                                gutterBottom
                                component="div"
                            >
                                {unlocker.code}
                            </Typography>
                        </Box>
                    )}

                    {unlocker && unlocker.type === 'QR' && unlocker.code && (
                        <Box align="center" mx="auto">
                            <img
                                src={cxt.getResource(
                                    `/unlockers/${unlocker.id}.svg`,
                                )}
                                width="250px"
                            />
                        </Box>
                    )}

                    {title && (
                        <Typography
                            variant="h6"
                            gutterBottom
                            component="div"
                            align="center"
                        >
                            {title}
                        </Typography>
                    )}

                    {subtitle && (
                        <Typography
                            variant="subtitle1"
                            gutterBottom
                            component="div"
                            align="center"
                        >
                            {subtitle}
                        </Typography>
                    )}

                    <Box display="flex" justifyContent="center">
                        <SttButton
                            caption={translate('Download')}
                            onClick={() =>
                                downloadFile(
                                    `/unlockers/${unlocker?.id}.pdf`,
                                    'application/pdf',
                                    {},
                                    `Unlocker_${unlocker?.id}.pdf`,
                                )
                            }
                        />
                    </Box>
                </>
            }
        />
    );
}

export default SttUserUnlockerDialog;
