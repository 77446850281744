/**
 * List of keys used in react-query. This list is used to see if there is duplication of keys.
 * Las claves se formar de la siguiente forma:
 *      - [SUPERADMIN|USER|SPORTCENTER|TEACHER]_[SUBMENU]_[LO QUE SE CONSULTA]
 *      - [SELECT|SEARCHBOX|PAGE|NOMBRE DE COMPONENTE]_[ENTIDAD A CONSULTAR]
 */

// LAST TRANSLATIONS UPDATE

export const TRANSLATIONS_UPDATE = 'translationsUpdate';

// PROVIDERS - All PROVIDERS requests keys.//
// APPPROVIDER
export const APPPROVIDER_V7MIGRATIONENDPOINTS =
    'appProviderV7MigrationEndpoints';
export const APPPROVIDER_TRANSLATIONS = 'appProviderTranslations';
export const APPPROVIDER_ME = 'appProviderMe';
export const APPPROVIDER_INFO = 'appProviderInfo';

// COMPONENTS - All SELECTORS, SEARCHBOXES... components keys.//
// SELECTORS
export const SELECT_CITIES = 'selectCities';
export const SELECT_CUSTOMERS = 'selectCustomers';
export const SELECT_TPV_CONFIGURATION = 'selectTpvConfiguration';
export const SELECT_GROUPS = 'selectGroups';
export const SELECT_MSHIPS = 'selectMships';
export const SELECT_PROVINCES = 'selectProvinces';
export const LIST_FACILITIES = 'listFacilities';
export const DIALOG_DEVICE = 'dialogDevice';
export const PAGE_REPORT = 'pageReport';
export const PAGE_REPORT_ROWS = 'pageReportRows';

// SUPERADMIN - All SUPERADMIN views query keys.//
// CUSTOMERS
export const SUPERADMIN_CUSTOMERS = 'superadminCustomers';
export const SUPERADMIN_CUSTOMER = 'superadminCustomer';
export const SUPERADMIN_CUSTOMER_SUBSCRIPTIONS =
    'superadminCustomerSubscriptions';
export const SUPERADMIN_CUSTOMER_SUBSCRIPTION_LICENSES =
    'superadminCustomerSubscriptionLicenses';
// SPORTSCENTERS
export const SUPERADMIN_SCS = 'superadminScs';
export const SUPERADMIN_SC = 'superadminSc';
export const SUPERADMIN_SC_DEVICES = 'superadminScDevices';
// PAYMENTS
export const SUPERADMIN_PAYMENTS = 'superadminPayments';
export const SUPERADMIN_PAYMENT = 'superadminPayment';
export const SUPERADMIN_TPV = 'superadminTpv';
// TPV CONFIGURATIONS
export const SUPERADMIN_TPV_CONFIGURATIONS = 'superadminTpvConfigurations';
export const SUPERADMIN_TPV_CONFIGURATION = 'superadminTpvConfiguration';
// TPV RECORDS
export const SUPERADMIN_TPV_RECORDS = 'superadminTpvRecords';
// USERS
export const SUPERADMIN_USERS = 'superadminUsers';
export const SUPERADMIN_USER = 'superadminUser';
// REPORTS
export const SUPERADMIN_REPORTS = 'superadminReports';
export const SUPERADMIN_REPORT = 'superadminReport';
export const SUPERADMIN_REPORT_COLUMNS = 'superadminReportColumns';
export const SUPERADMIN_REPORT_FILTERS = 'superadminReportFilters';
// TRANSLATIONS
export const SUPERADMIN_TRANSLATIONS = 'superadminTranslations';
export const SUPERADMIN_TRANSLATION = 'superadminTranslation';
// CITIES
export const SUPERADMIN_CITIES = 'superadminCities';
export const SUPERADMIN_CITY = 'superadminCity';
// APIS
export const SUPERADMIN_APIS = 'superadminApis';
export const SUPERADMIN_API = 'superadminApi';
export const SUPERADMIN_API_PARAMETERS = 'superadminApiParameters';
export const SUPERADMIN_API_RESPONSES = 'superadminApiResponses';
export const SUPERADMIN_API_TESTS = 'superadminApiTests';
// API MODELS
export const SUPERADMIN_API_MODELS = 'superadminApiModels';
export const SUPERADMIN_API_MODEL = 'superadminApiModel';
export const SUPERADMIN_API_MODEL_PROPERTIES = 'superadminApiModelProperties';
// API LOGS
export const SUPERADMIN_API_LOGS = 'superadminApiLogs';
// API TRIGGERS BY SC
export const SUPERADMIN_API_TRIGGERS_BY_SC = 'superadminApiTriggersBySc';
// API TRIGGERS EXECUTIONS
export const SUPERADMIN_API_TRIGGERS_EXECUTIONS =
    'superadminApiTriggersExecutions';
// TESTS
export const SUPERADMIN_TESTS = 'superadminTests';
export const SUPERADMIN_TEST = 'superadminTest';
// MIGRATION
export const SUPERADMIN_MIGRATION_ENDPOINTS = 'superadminMigrationEndpoints';
export const SUPERADMIN_MIGRATION_ENDPOINTS_UPDATE_WITH_SWAGGER =
    'superadminMigrationEndpointsWithSwagger';

export const SUPERADMIN_MIGRATION_ENDPOINT_UPDATE =
    'superAdminMigrationEndpoints';
// SPORTCENTER - All SPORTCENTER views query keys.//
// CONFIGURATION
export const SPORTCENTER_PRIVILEGES_QUERY_KEY = 'sportcenterPrivileges';
export const SPORTCENTER_DEVICES_QUERY_KEY = 'sportcenterDeviceQueryKey';
export const SPORTCENTER_CONFIGURATION_MANDATORYDATA =
    'sportcenterConfigurationMandatoryData';
// GOLF
export const SPORTCENTER_GOLF_COURSE = 'sportcenterGolfCourse';
export const SPORTCENTER_GOLF_COURSES = 'sportcenterGolfCourses';
export const SPORTCENTER_GOLF_COURSE_HOLES = 'sportcenterGolfCourseHoles';
// GOLF COURSE SCHEDULE
export const SPORTCENTER_GOLF_COURSE_SCHEDULES =
    'sportcenterGolfCourseSchedules';
export const SPORTCENTER_GOLF_COURSE_SCHEDULE = 'sportcenterGolfCourseSchedule';
export const SPORTCENTER_GOLF_COURSE_RATES = 'sportcenterGolfCourseRates';
// USERS
export const SPORTCENTER_USERS = 'sportcenterUsers';
// GROUPS
export const FAMILY_BENEFICIARIES = 'familyBeneficiaries';
export const GROUP_MEMBERS = 'groupMembers';
export const SPORTCENTER_GROUPS = 'groups';
// FREE SCHEDULES
export const SPORTCENTER_FREE_SCHEDULES = 'sportcenterFreeSchedules';
// ACTIVITIES
export const SPORTCENTER_ACTIVITY_TEACHERS = 'sportcenterActivityTeachers';
export const SPORTCENTER_ACTIVITIES = 'sportcenterActivities';
export const SPORTCENTER_ACTIVITIES_STUDENTS = 'sportcenterActivitiesStudents';
// USER - All USER views query keys.//
// TPV
export const USER_TPV = 'userTpv';
export const USER_TPV_RESULT = 'userTpvResult';
// SEARCH
export const USER_SEARCH_SCS = 'userSearchScs';
export const USER_SEARCH_ACTIVITIES = 'userSearchActivities';
export const USER_SEARCH_EVENTS = 'userSearchEvents';
// UNLOCKERS
export const USER_UNLOCKERS = 'userUnlockers';
// AGENDAS
export const USER_AGENDAS = 'userAgendas';
// MESSAGES
export const USER_MESSAGES = 'userMessages';
// DISCOUNTS
export const SPORTCENTER_DISCOUNTS = 'sportcenterDiscounts';
export const GROUP_DISCOUNTS = 'groupsDiscounts';
