import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { OpenInNewOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { getPath } from '../../pages/Pages';
import { useCrud } from '../../lib/hooks';
import {
    AppContext,
    SttDialog,
    SttFormDate,
    SttFormText,
    SttFormTime,
} from '../../sporttia/all';
import ErrorsDialog from '../../pages/sc/busySchedules/ErrorsDialog';

// En realidad 'busySchedule' hace más bien referencia a un tramo cerrado que a un schedule, pero todo esto es confuso porque desde el menú 'cerrar tramo' se puede
// definir un periodo de cierre entero (de tal a tal día, entre tal y tal fecha).
export default function BusyScheduleDialog({
    item,
    facility,
    onSave,
    onClose,
}) {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const [fails, setFails] = useState([]);
    const [busySchedule, setBusySchedule, setProperty, Get, Post, Put, Delete] =
        useCrud(item);
    const [creating, setCreating] = useState(false);

    /**
     * loadBusySchedule
     */
    function loadBusySchedule() {
        Get(`/busies/${item.id}`).then((r) => {
            setProperty({ name: 'timeini', value: item.timeini });
            setProperty({ name: 'timeend', value: item.timeend });
            setProperty({ name: 'slot', value: r.busy.slot });
            setBusySchedule(r.busy);
        });
    }

    // init
    useEffect(() => {
        setCreating(!item.id);

        if (item.id) {
            loadBusySchedule();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.id]);

    /**
     * saveAndClose
     */
    function saveAndClose() {
        if (onSave) {
            onSave();
        }

        if (onClose) {
            onClose();
        }
    }
    /**
     * saveBusySchedule
     */
    function saveBusySchedule() {
        // Creating
        if (creating) {
            Post(`/scs/${cxt.sc.id}/busies/schedules`, busySchedule).then(
                (response) => {
                    if (response?.fails?.length > 0) {
                        setFails(response.fails);
                    } else {
                        saveAndClose();
                    }
                },
            );

            // Updating
        } else {
            Put(`/busies/${item.id}`, {
                name: busySchedule.name, // Actually, it's not a busySchedule, it's a busy
                timeini: busySchedule.timeini,
                timeend: busySchedule.timeend,
            }).then(() => {
                saveAndClose();
            });
        }
    }

    /**
     * Liberate
     */
    function liberate() {
        Delete(`/busies/${item.id}`).then(() => {
            saveAndClose();
        });
    }

    /**
     * Close all sport center.
     */
    function closeAllSportCenter() {
        cxt.api('POST', `/schedules/busies/all`, {
            params: {
                date: busySchedule.ini,
            },
            success: (response) => {
                if (response?.fails?.length > 0) {
                    setFails(response.fails);
                } else {
                    saveAndClose();
                }
            },
        });
    }

    if (!item) {
        return null;
    }

    return (
        <>
            <SttDialog
                open={!!item}
                onClose={onClose}
                title={creating ? cxt.t('NewBusy') : cxt.t('Busy')}
                subtitle={
                    busySchedule.creator &&
                    `${cxt.t('createdBy')}: ${busySchedule.creator.name}`
                }
                content={
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <SttFormText
                                name="name"
                                caption={cxt.t('Name')}
                                defVal={busySchedule.name}
                                onChange={setProperty}
                            />
                        </Grid>

                        {facility && (
                            <SttFormText
                                grid
                                disabled
                                md={12}
                                sm={12}
                                xs={12}
                                caption={cxt.t('Facility')}
                                defVal={facility.name}
                            />
                        )}

                        {/* JPB 26 May 2020. It doesn't event work in production
					<Grid item xs={12}>
						<SttFormTextarea
							name={"notes"}
							caption={cxt.t('Notes')}
							defVal={busySchedule.notes}
							onChange={setProperty}
						/>
					</Grid> */}
                        <Grid item xs={12} sm={6} md={6}>
                            <SttFormDate
                                disabled={!creating}
                                name="ini"
                                caption={cxt.t('Begin')}
                                defVal={busySchedule.ini}
                                onChange={setProperty}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <SttFormDate
                                disabled={!creating}
                                name="end"
                                caption={cxt.t('Until')}
                                defVal={busySchedule.end}
                                onChange={setProperty}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SttFormTime
                                name="timeini"
                                caption={cxt.t('TimeIni')}
                                defVal={busySchedule.timeini}
                                min="06:00"
                                max="23:45"
                                timeRangeOptions={{ includeMaxTime: true }}
                                interval={10}
                                onChange={setProperty}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SttFormTime
                                name="timeend"
                                caption={cxt.t('TimeEnd')}
                                defVal={busySchedule.timeend}
                                min="06:00"
                                max="23:45"
                                timeRangeOptions={{ includeMaxTime: true }}
                                interval={10}
                                onChange={setProperty}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <b>{`${cxt.t('creator')}: ${
                                busySchedule?.creator?.name
                            }`}</b>
                        </Grid>
                    </Grid>
                }
                buttons={[
                    {
                        type: 'delete',
                        caption: cxt.t('CloseAllSportcenter'),
                        onClick: closeAllSportCenter,
                    },
                    {
                        type: 'save',
                        onClick: saveBusySchedule,
                    },
                    {
                        show: !creating,
                        type: 'delete',
                        caption: cxt.t('Liberate'),
                        onClick: liberate,
                    },
                    {
                        show: !creating,
                        icon: <OpenInNewOutlined fontSize="small" />,
                        caption: cxt.t('timetable.gotoBusySchedule'),
                        onClick: () =>
                            history.push(
                                getPath('busySchedule', {
                                    id: busySchedule.slot.schedule.id,
                                }),
                            ),
                    },
                    {
                        type: 'close',
                        onClick: onClose,
                    },
                ]}
            />
            {fails.length > 0 && (
                <ErrorsDialog
                    errors={fails}
                    onClose={() => {
                        setFails([]);
                        saveAndClose();
                    }}
                />
            )}
        </>
    );
}
