import React from 'react';
import { makeStyles } from '@material-ui/core';
import Spinner from 'react-spinkit';

const useStyles = makeStyles({
    spinnerContainer: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        background: '#f8f8f8ad',
        zIndex: 1000,
    },
    spinnerImage: {
        top: '50%',
        left: '50%',
        zIndex: 1000,
        position: 'absolute',
        marginTop: '-22px',
        marginLeft: '-20px',
    },
});

/**
 * Draw sporttia spinner.
 */
function SttFullPageSpinner() {
    const classes = useStyles();

    return (
        <div className={classes.spinnerContainer}>
            <Spinner
                name="line-scale"
                color="#DBEA7B"
                fadeIn="none"
                className={classes.spinnerImage}
            />
        </div>
    );
}

export default SttFullPageSpinner;
