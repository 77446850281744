import { FormControlLabel, Grid, Switch, SwitchProps } from '@material-ui/core';
import React from 'react';

type SttFormSwitchProps = {
    name: string;
    caption: string;
    color: SwitchProps['color'];
    checked?: boolean;
    onChange: ({ name, value }: { name: string; value: boolean }) => void;
    grid?: boolean;
    disabled?: boolean;
};

/**
 * Form switch
 */
export function SttFormSwitch({
    name,
    caption,
    color,
    checked = false,
    onChange,
    grid = false,
    disabled,
    ...rest
}: SttFormSwitchProps) {
    const content = (
        <FormControlLabel
            control={
                <Switch
                    checked={checked}
                    disabled={disabled}
                    onChange={(e) =>
                        onChange({ name, value: e.target.checked })
                    }
                    color={color}
                />
            }
            label={caption}
        />
    );

    return grid ? (
        <Grid item {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
