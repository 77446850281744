import React, { useState, useContext, useEffect } from 'react';
import { Euro, Close } from '@material-ui/icons';
import { AppContext, SttChipUser, SttTable } from '../../../sporttia/all';
import BookingDialog from '../../../components/dialogs/bookingDialog/BookingDialog';
import ModalCollectBatch from './ModalCollectBatch';
import PaymentModal from '../../../components/paymentModal/PaymentModal';

export default function BookingScheduleBookings({ bookings, onFetch }) {
    const cxt = useContext(AppContext);

    const [viewBooking, setViewBooking] = useState(null);
    const [_bookings, setBookings] = useState(bookings);
    const [batch, setBatch] = useState([]);
    const [idPayModal, setIdPayModal] = useState(null);

    useEffect(() => {
        setBookings(bookings);
    }, [bookings]);

    function openCollectBookings(rows) {
        setBatch(rows);
    }

    // Collect payment for bookings via POST /batch
    // The API contains a 'responses' array with the response of each individual request
    function collectBookings(paymentForm) {
        if (batch.length > 0) {
            cxt.api('POST', '/batch', {
                params: {
                    calls: batch
                        .filter((booking) => !booking.payment)
                        .map((booking) => ({
                            method: 'PUT',
                            endpoint: `/bookings/${booking.id}/pay`,
                            data: {
                                paymentForm,
                            },
                        })),
                },
                success: (response) => {
                    setBatch([]);

                    if (response.responses) {
                        let existing = { ..._bookings };

                        response.responses.forEach((responseToSet) => {
                            if (responseToSet.booking) {
                                existing = {
                                    ...existing,
                                    rows: existing.rows.map((row) =>
                                        responseToSet.booking.id === row.id
                                            ? responseToSet.booking
                                            : row,
                                    ),
                                };
                            }

                            if (
                                responseToSet.payment &&
                                idPayModal === null &&
                                responseToSet.payment.parent
                            ) {
                                setIdPayModal(responseToSet.payment.parent.id);
                            }
                        });

                        setBookings(existing);
                    }
                },
                error: () => {
                    setBatch([]);
                },
            });
        }
    }

    // Delete all selected bookings using POST /batch. Api retuns responses[], each one representing the response of each request.
    function cancelBookings(rows) {
        if (rows.length > 0) {
            cxt.api('POST', '/batch', {
                confirmation: true,
                params: {
                    calls: rows.map((booking) => ({
                        method: 'DELETE',
                        endpoint: `/bookings/${booking.id}`,
                    })),
                },
                success: () => {
                    setBookings({
                        count: _bookings.count - rows.length,
                        rows: _bookings.rows.filter(
                            (booking) =>
                                rows.find((row) => row.id === booking.id) ===
                                undefined,
                        ),
                    });
                },
            });
        }
    }

    return (
        <>
            <SttTable
                autoload={false}
                onFetch={onFetch}
                data={_bookings}
                onClickRow={(row) => setViewBooking(row)}
                // TODO: Descomentar para habilitar procesamiento por lotes de los alquileres (solo borar y cobrar). Añadir además en data={_bookings}
                selection={{
                    actions: [
                        {
                            caption: cxt.t('Collect'),
                            icon: <Euro />,
                            onClick: openCollectBookings,
                        },
                        {
                            caption: cxt.t('Delete'),
                            icon: <Close />,
                            onClick: cancelBookings,
                        },
                    ],
                }}
                columns={[
                    {
                        align: 'left',
                        title: cxt.t('User'),
                        value: (row) => (
                            <SttChipUser
                                user={{ login: row.user?.mship?.name }}
                            />
                        ),
                    },
                    {
                        title: cxt.t('Date'),
                        type: 'period',
                        value: (row) => ({ ini: row.ini, end: row.end }),
                    },
                    {
                        title: cxt.t('Time'),
                        type: 'periodTime',
                        value: (row) => ({ ini: row.ini, end: row.end }),
                    },
                    {
                        title: cxt.t('Facility'),
                        type: 'facility',
                        field: 'facility',
                    },
                    {
                        title: cxt.t('Debt'),
                        align: 'right',
                        type: 'debt',
                        value: (row) => row.price - row.totalPaid,
                    },
                    {
                        title: cxt.t('Total'),
                        align: 'right',
                        type: 'price',
                        field: 'price',
                    },
                    {
                        title: cxt.t('Pending'),
                        align: 'right',
                        type: 'price',
                        value: (row) => row.totalBill - row.totalPaid,
                    },
                ]}
            />

            {viewBooking && (
                <BookingDialog
                    idBooking={viewBooking.id}
                    onClose={() => setViewBooking(null)}
                    onUpdate={onFetch}
                />
            )}

            <ModalCollectBatch
                open={batch.length > 0}
                onClose={() => setBatch([])}
                bookings={batch}
                onCollect={(paymentForm) => collectBookings(paymentForm)}
            />

            {idPayModal && (
                <PaymentModal
                    idPayment={idPayModal}
                    onClose={() => {
                        setIdPayModal(null);
                    }}
                />
            )}
        </>
    );
}
