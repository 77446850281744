import React, { useContext } from 'react';
import moment from 'moment';
import { AppContext } from '../all';

/**
 *	Label weekday list
 *	==================
 *
 * Renders all weekdays as localized letters, with a darker color for those passed in the weekdays prop.
 * For ex. passing [1, 3, 4] will yield (in spanish) L M X J V S D, with L, X and J in black and the rest in gray.
 *
 * Props
 * 		* integer[] weekdays
 */
export function SttLabelWeekdays({ weekdays = [] }) {
    const cxt = useContext(AppContext);

    // Render
    return (
        <div>
            {weekdays.length === 7
                ? cxt.t('Everyday')
                : [1, 2, 3, 4, 5, 6, 0].map((wd) => (
                      <span
                          key={wd}
                          style={
                              !weekdays.includes(wd) ? { color: '#a7a7a7' } : {}
                          }
                      >{` ${cxt.t(`weekday.${wd}.letter`)}`}</span>
                  ))}
        </div>
    );
}
