import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {
    AppContext,
    SttList,
    SttButton,
    SttTable,
} from '../../../sporttia/all';
import { getPath } from '../../Pages';
import { SttTabs } from '../../../sporttia/SttTabs';
import UserAbonosTable from './UserAbonosTable';

export default function UserAbonos() {
    const cxt = useContext(AppContext);

    const availableTabs = [
        {
            caption: cxt.t('Abonos'),
            component: <UserAbonosTable type="ABONADOS" />,
        },
        {
            caption: cxt.t('GT.NORMAL'),
            component: <UserAbonosTable type="NORMAL" />,
        },
    ];

    // -----| Render |-----
    return (
        <Container maxWidth="md">
            <Paper>
                <SttTabs tabs={availableTabs} />
            </Paper>
        </Container>
    );
}
