import React, { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { AppContext } from '../../../../sporttia/AppContext';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';
import { SUPERADMIN_REPORT_COLUMNS } from '../../../../lib/queryKeys';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import useReportsService from '../../../../services/ReportsService';
import AdminReportColumnDialog from './AdminReportColumnDialog';

/**
 * View to create and modify the details of a report columns.
 * @param report Report object.
 * @returns {JSX.Element}
 */
export default function AdminReportColumns({ report }) {
    const cxt = useContext(AppContext);
    const reportsService = useReportsService();
    const queryClient = useQueryClient();
    const [column, setColumn] = useState(null);

    return (
        <>
            <SttTopFilteringControls
                p={2}
                mainAction={{
                    type: 'create',
                    caption: cxt.t('Create'),
                    onClick: () => setColumn({}),
                }}
            />

            {/* Aqui no podemos paginar directamente las columnas ya que no tenemos un end point que lo haga, tenemos que usar
            GET /reports/:id para de dentro coger columns y ya listarlo, de ahí que se pidan 200 filas. */}
            <SttCachedTable
                queryKey={SUPERADMIN_REPORT_COLUMNS}
                queryFn={(params) =>
                    reportsService
                        .get(params?.id, { trash: true })
                        .then((data) => ({
                            count: data.report.columns
                                ? data.report.columns.length
                                : 0,
                            rows: data.report.columns
                                ? data.report.columns
                                : [],
                        }))
                }
                queryOptions={{
                    enabled: !!report?.id && report?.id !== 'create',
                }}
                queryParams={{
                    id: report?.id,
                }}
                onClickRow={(row) => setColumn(row)}
                rowsPerPage={200}
                columns={[
                    {
                        title: cxt.t('position'),
                        align: 'left',
                        type: 'text',
                        field: 'position',
                    },
                    {
                        title: cxt.t('name'),
                        align: 'left',
                        type: 'text',
                        field: 'name',
                    },
                    {
                        title: cxt.t('field'),
                        align: 'left',
                        type: 'text',
                        field: 'field',
                    },
                    {
                        title: cxt.t('width'),
                        align: 'left',
                        type: 'text',
                        field: 'width',
                    },
                    {
                        title: cxt.t('align'),
                        align: 'left',
                        type: 'text',
                        field: 'align',
                    },
                    {
                        title: cxt.t('format'),
                        align: 'left',
                        type: 'text',
                        field: 'format',
                    },
                    {
                        title: cxt.t('summatory'),
                        align: 'left',
                        type: 'binaryStatus',
                        field: 'summatory',
                    },
                ]}
            />

            {/* En el futuro si tenemos un GET /reports/columns/:id podríamos quitar columnValue y pasar el id de la columna. */}
            <AdminReportColumnDialog
                reportId={report?.id}
                columnValue={column}
                onClose={() => setColumn(null)}
                onCreate={() =>
                    queryClient.invalidateQueries(SUPERADMIN_REPORT_COLUMNS)
                }
                onUpdate={() =>
                    queryClient.invalidateQueries(SUPERADMIN_REPORT_COLUMNS)
                }
                onDelete={() => {
                    setColumn(null);
                    queryClient.invalidateQueries(SUPERADMIN_REPORT_COLUMNS);
                }}
            />
        </>
    );
}
