import React, { useContext } from 'react';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import { AppContext, SttFullDialog } from '../../../../sporttia/all';
import { useLoader, useTranslations } from '../../../../lib/hooks';
import useMigrationEndpointsService from '../../../../services/MigrationEndpointsService';
import Migration from '../../../../types/models/Migration';
import SttValidatedForm from '../../../../sporttia/SttValidatedForm';
import { PutMigration200 } from '../../../../types/api/paths/Migration';
import translations from '../../../../translations';

type MigrationEndpointPropTypes = Migration;

export default function MigrationEndpoint({
    onClose,
    onUpdate,
    migrationEndpoint,
}: {
    migrationEndpoint: MigrationEndpointPropTypes;
    onClose: () => Record<string, never>;
    onUpdate: () => void;
}) {
    const cxt = useContext(AppContext);
    const migrationEndpointsService = useMigrationEndpointsService();
    const { translate } = useTranslations();
    const { watch, ...form } = useForm({ defaultValues: migrationEndpoint });
    const updateMigrationEndpointMutation = useMutation(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
        ({ id, requestBody }: { id: number; requestBody: PutMigration200 }) =>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
            migrationEndpointsService.updateMigrationEndpoint(id, requestBody),
        {
            onSuccess: (body: { migrationEndpoint: Migration }) => {
                cxt?.showMessage('S', translations.generic.OperationSuccessful);
                form.reset(body?.migrationEndpoint);
                onUpdate();
            },
        },
    );

    const deleteMigrationEndpointMutation = useMutation(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
        () =>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
            migrationEndpointsService.deleteMigrationEndpoint(
                migrationEndpoint?.id,
            ),
        {
            onSuccess: () => {
                cxt?.showMessage('S', translations.generic.OperationSuccessful);
                onUpdate();
                onClose();
            },
        },
    );
    const [, loader] = useLoader([updateMigrationEndpointMutation.status]);
    const defaultValues = watch();

    return (
        <>
            {loader}
            <SttFullDialog
                title={migrationEndpoint?.endpoint}
                open={migrationEndpoint?.id >= 0}
                onClose={() => onClose()}
            >
                <SttValidatedForm
                    form={form}
                    loadingData={
                        updateMigrationEndpointMutation?.status === 'loading'
                    }
                    fields={[
                        {
                            type: 'textinput',
                            name: 'endpoint',
                            caption: translate('Endpoint'),
                            value: defaultValues?.endpoint,
                            sm: 5,
                        },
                        {
                            caption: translate('Method'),
                            type: 'select',
                            name: 'method',
                            sm: 5,
                            options: [
                                {
                                    caption: 'GET',
                                    value: 'GET',
                                },
                                {
                                    caption: 'POST',
                                    value: 'POST',
                                },
                                {
                                    caption: 'PUT',
                                    value: 'PUT',
                                },
                                {
                                    caption: 'DELETE',
                                    value: 'DELETE',
                                },
                            ],
                        },
                        {
                            type: 'date',
                            rows: 10,
                            name: 'activeProduction',
                            value: defaultValues?.activeProduction,
                            caption: 'PRO',
                            sm: 5,
                        },
                        {
                            type: 'date',
                            rows: 10,
                            name: 'activePreproduction',
                            value: defaultValues?.activePreproduction,
                            caption: 'PRE',
                            sm: 5,
                        },
                        {
                            type: 'textarea',
                            rows: 10,
                            name: 'notes',
                            value: defaultValues?.notes,
                            caption: translate('notes'),
                            sm: 5,
                        },
                    ]}
                    buttons={[
                        {
                            caption: translate('Save'),
                            type: 'save',
                            sm: 4,
                            onClick: () => {
                                updateMigrationEndpointMutation.mutate({
                                    id: form.getValues<'id'>('id'),
                                    requestBody: {
                                        endpoint:
                                            form.getValues<'endpoint'>(
                                                'endpoint',
                                            ),
                                        method: form.getValues<'method'>(
                                            'method',
                                        ),
                                        notes: form.getValues<'notes'>('notes'),
                                        uploaded:
                                            form.getValues<'uploaded'>(
                                                'uploaded',
                                            ),
                                        activePreproduction:
                                            form.getValues<'activePreproduction'>(
                                                'activePreproduction',
                                            ),
                                        activeProduction:
                                            form.getValues<'activeProduction'>(
                                                'activeProduction',
                                            ),
                                    },
                                });
                            },
                        },
                        {
                            type: 'delete',
                            caption: translate('Delete'),
                            sm: 2,
                            onClick: () => {
                                deleteMigrationEndpointMutation.mutate();
                            },
                        },
                    ]}
                />
            </SttFullDialog>
        </>
    );
}
