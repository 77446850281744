import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, makeStyles, Divider } from '@material-ui/core';
import { AppContext, SttParticipantsList } from '../../../sporttia/all';
import { formatPrice, fullName } from '../../../lib/utils';
import { useCrud } from '../../../lib/hooks';

const useStyles = makeStyles((theme) => ({
    teamName: {
        fontSize: 20,
        textTransform: 'uppercase',
    },
    participants: {
        fontSize: 16,
        fontWeight: 500,
    },
    cat: {
        fontsize: 14,
        color: '#5b5b5b',
    },
    heading: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: theme.spacing(1),
        borderBottom: '1px solid #cdcdcd',
    },
    name: {
        fontSize: 16,
        minWidth: 300,
    },
    pricePending: {
        fontSize: 16,
        color: 'red',
    },
    pricePaid: {
        fontSize: 16,
        color: 'green',
    },
}));

export default function EventTeam({ event, team, onDelete }) {
    const cxt = useContext(AppContext);
    const classes = useStyles();
    const [_team, setTeam, , Get, , , Delete] = useCrud(team);

    useEffect(() => {
        if (team && team.participants.length !== _team.participants.length) {
            setTeam(team);
        }
    }, [team]);

    function deleteParticipant(participant) {
        Delete(`/events/participants/${participant.id}`)
            .then((response) => {
                onDelete();
            })
            .catch(() => {});
    }

    if (!team) {
        return null;
    }

    return (
        <>
            <Box mb={3}>
                <Typography className={classes.teamName}>
                    {_team?.name}
                </Typography>
                <Typography className={classes.cat}>{`${cxt.t('Category')}: ${
                    _team.category?.name
                }`}</Typography>
            </Box>
            <Box mb={1}>
                <Typography className={classes.participants}>
                    {cxt.t('Participants')}:
                </Typography>
            </Box>
            <Box>
                <SttParticipantsList
                    participants={_team.participants}
                    onDelete={deleteParticipant}
                />
            </Box>
        </>
    );
}
