import React, { useContext } from 'react';
import { Chip } from '@material-ui/core';
import { AppContext } from '../AppContext';
import constants from '../../config/constants';
import { PaymentForm } from '../../types/payment';

const getColor = (key: string) => {
    switch (key) {
        case 'PURSE':
            return '#3186a7';
        case 'DATAFONO':
            return '#a341bb';
        case 'DATAFONO_CONNECTED':
            return '#c792d7';
        case 'CASH':
            return '#f35b77';
        case 'TPV':
            return '#d8af54';
        case 'ZITYCARD':
        case 'FREE':
            return '#75bb41';
        case 'BANK':
            return '#4831a7';
        case 'RECEIPT':
            return '#a86359';
        default:
            return '#b02a00';
    }
};

type SttChipPFormProps = {
    pf: PaymentForm;
};

export function SttChipPForm({ pf }: SttChipPFormProps) {
    const cxt = useContext(AppContext)!;

    const paymentForm: string = pf ?? '';

    return (
        <Chip
            size="small"
            label={cxt.t(
                `PF.${
                    cxt.user!.role === constants.roles.user &&
                    paymentForm === ''
                        ? 'LATER.USER'
                        : paymentForm
                }`,
            )}
            style={{
                backgroundColor: getColor(pf),
                color: 'white',
            }}
        />
    );
}
