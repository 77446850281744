import { useContext } from 'react';
import { MutationFunction, UseMutationOptions, useMutation } from 'react-query';
import { useAPI } from '../lib/hooks';
import { AppContext } from '../sporttia/AppContext';
import {
    ChangeRate200,
    ChangeRateBody,
    GenerateStudentEnrollment200,
    UpdateStudent200,
    UpdateStudentBody,
} from '../types/api/paths/Student';

const resource = '/students';

/**
 * Hook to access all 'students' resources.
 */
export default function useStudentsService() {
    const { migrationApis } = useContext(AppContext)!;
    const [request] = useAPI(migrationApis);

    /**
     * PUT /students/id request.
     */
    function useUpdateStudent(
        mutationOptions?: UseMutationOptions<
            UpdateStudent200,
            unknown,
            { id: number; params: UpdateStudentBody }
        >,
    ) {
        const mutationFn: MutationFunction<
            UpdateStudent200,
            { id: number; params: UpdateStudentBody }
        > = ({ id, params }) => request('PUT', `${resource}/${id}`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * PUT /students/:idStudent/changeRate request.
     */
    function useChangeRate(
        mutationOptions?: UseMutationOptions<
            ChangeRate200,
            unknown,
            { idStudent: number; params: ChangeRateBody }
        >,
    ) {
        const mutationFn: MutationFunction<
            ChangeRate200,
            { idStudent: number; params: ChangeRateBody }
        > = ({ idStudent, params }) =>
            request('PUT', `${resource}/${idStudent}/changeRate`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * POST /students/:id/enrollment/generate request.
     */
    function useGenerateStudentEnrollment(
        mutationOptions?: UseMutationOptions<
            GenerateStudentEnrollment200,
            unknown,
            { id: number }
        >,
    ) {
        const mutationFn: MutationFunction<
            GenerateStudentEnrollment200,
            { id: number }
        > = ({ id }) => request('POST', `/students/${id}/enrollment/generate`);

        return useMutation(mutationFn, mutationOptions);
    }

    return {
        useUpdateStudent,
        useChangeRate,
        useGenerateStudentEnrollment,
    };
}
