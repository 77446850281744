import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import LoopIcon from '@material-ui/icons/Loop';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import {
    AppContext,
    SttDialog,
    SttTabs,
    SttFormDate,
    SttFormSelect,
    SttFormTextarea,
    SttSelectPForm,
    SttFeeList,
    SttLabelForm,
    SttSelectUser,
    SttFormCheck,
} from '../../sporttia/all';
import { useCrud, useInteractionsFiles, useLoader } from '../../lib/hooks';
import {
    generateFormField,
    fullName,
    formatPriceByLocale,
    composeFormData,
    getErrorMessage,
} from '../../lib/utils';
import HandlePaymentsModal from '../../pages/sc/groups/HandlePaymentsModal';
import { SttSelectClassAndRateDialog } from '../../sporttia/dialogs/SttSelectClassAndRateDialog';
import POSPaymentDialog from './POSPaymentDialog';
import POSStripePaymentDialog from '../stripe/POSStripePaymentDialog';
import constants from '../../config/constants';
import translations from '../../translations';
import useStudentsService from '../../services/StudentsService';
import useActivitiesService from '../../services/ActivitiesService';
import useActivityRatesService from '../../services/ActivityRatesService';
import DiscountList from '../discounts/DiscountsList';

/**
 * Student dialog
 */
export default function StudentDialog({
    open = false,
    idStudent = null,
    defaultIdRate = null,
    activity = null,
    onSave,
    onClose,
    trash,
}) {
    const cxt = useContext(AppContext);
    const { downloadFile } = useInteractionsFiles();
    const [student, setStudent, setProperty, Get, , , Delete] = useCrud();
    const [title, setTitle] = useState(null);
    const [history, setHistory] = useState();
    const [idRate, setIdRate] = useState(defaultIdRate);
    const [openAskingPaymentProcedure, setOpenAskingPaymentProcedure] =
        useState(false);
    const [openChangeClassAndRateDialog, setOpenChangeClassAndRateDialog] =
        useState(false);
    const [posPaymentParams, setPosPaymentParams] = useState(null);
    const [discountsForm, setDiscountsForm] = useState([]);
    const creating = idStudent === 0;

    const selectedRate =
        activity?.rates?.find((rate) => rate.id === idRate) ?? student?.rate;

    function saveAndClose() {
        onSave();
        onClose();
    }

    function handleChangeDiscounts({ name: id, value }) {
        setDiscountsForm((prev) =>
            prev.map((discount) =>
                discount.id === id
                    ? {
                          ...discount,
                          checked: value,
                      }
                    : discount,
            ),
        );
    }

    const loadStudent = () => {
        Get(`/students/${idStudent}`, { trash })
            .then((response) => {
                if (response.student) {
                    setStudent(response.student);
                    setIdRate(response.student.rate.id);
                }
            })
            .catch(() => {});
    };

    const loadHistory = () => {
        Get(`/students/${idStudent}/history`)
            .then((result) => {
                if (result.rows) {
                    setHistory(result.rows);
                }
            })
            .catch(() => {});
    };

    // Init. If an idStudent is provided load it, otherwise create an 'empty' student for addition
    useEffect(() => {
        if (!creating) {
            setStudent(null);
            loadStudent();
            loadHistory();
            setTitle(cxt.t(translations.generic.student));
        } else {
            // Aquí activity debe existir siempre...
            if (!activity || !selectedRate) {
                return;
            }

            const now = moment();
            const then = selectedRate.ini;

            setStudent({
                name: '',
                user: null,
                ini: now.isBefore(then)
                    ? moment(then).format('YYYY-MM-DD HH:mm:ss')
                    : now.format('YYYY-MM-DD 00:00:00'),
                end: moment(selectedRate.end).format('YYYY-MM-DD HH:mm:ss'),
                notes: '',
                rate: selectedRate,
                enrollment: {},
                feePaymentForm: '',
                form: activity.form
                    ? activity.form.map((question) => ({
                          ...question,
                          answer: { value: '' },
                      }))
                    : null,
            });

            setHistory(null);
        }
        // Eliminar useEffect cuando se migre a react-query
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idStudent]);

    const studentsService = useStudentsService();
    const updateStudentMutation = studentsService.useUpdateStudent({
        onSuccess: () => {
            onSave();
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });

    const generateStudentEnrollmentMutation =
        studentsService.useGenerateStudentEnrollment({
            onSuccess: () => {
                cxt.showMessage('S', cxt.t('GeneratedEnrollment'));
                loadStudent();
            },
            onError: (error) => {
                cxt.showMessage('E', getErrorMessage(error));
            },
        });

    const activitiesService = useActivitiesService();
    const createActivityRateStudentMutation =
        activitiesService.useCreateActivityRateStudent({
            onSuccess: (response) => {
                const { tpv } = response;

                if (
                    tpv?.payment?.paymentForm ===
                    constants.payment.paymentForms.dataphoneConnected.name
                ) {
                    // params for the POS dialog (datáfono físico)
                    setPosPaymentParams({
                        amount: tpv.price,
                        concept: tpv.concept,
                        idTpv: tpv.id,
                        sc: {
                            id: tpv?.sc?.id,
                            scName: tpv?.sc?.short,
                            entityName: tpv?.sc?.customer?.name,
                            address: tpv?.sc?.address,
                            cif: tpv?.sc?.customer?.cif,
                            phone: tpv?.sc?.phonePublic,
                        },
                    });
                } else {
                    saveAndClose();
                }
            },
            onError: (error) => {
                cxt.showMessage('E', getErrorMessage(error));
            },
        });

    const upsertStudent = () => {
        if (!student || !student.user) {
            return;
        }
        let requestBody = {};

        if (student.id) {
            const { ini, end, notes, feePaymentForm, enrollment, form } =
                student;
            requestBody = {
                notes,
                ini,
                end,
                feePaymentForm,
                enrollmentPaymentForm: enrollment
                    ? enrollment.paymentForm
                    : null,
            };
            if (form) {
                requestBody.form = composeFormData(form);
            }
            updateStudentMutation.mutate({
                id: idStudent,
                params: requestBody,
            });
        } else {
            const { user, ini, end, notes, feePaymentForm, enrollment, form } =
                student;

            const discountsIdsToSend = discountsForm
                .filter((discount) => discount.checked)
                .map((discount) => discount.id);

            requestBody = {
                idUser: user.id,
                notes,
                ini,
                end,
                feePaymentForm,
                enrollmentPaymentForm: enrollment.paymentForm,
                discountsIds: discountsIdsToSend,
            };

            if (form) {
                requestBody.form = composeFormData(form);
            }

            createActivityRateStudentMutation.mutate({
                idRate,
                params: requestBody,
            });
        }
    };

    const activityRateService = useActivityRatesService();
    activityRateService.useGetActivityRateDiscounts(
        selectedRate?.id,
        {
            rows: 1000,
        },
        {
            onSuccess: (response) => {
                setDiscountsForm(
                    response.rows.map((discount) => ({
                        ...discount,
                        checked: false,
                    })),
                );
            },
            refetchOnMount: true,
            enabled: creating,
        },
    );

    const download = () => {
        downloadFile(
            `/students/${idStudent}.pdf`,
            'application/pdf',
            null,
            'student.pdf',
        );
    };

    const handleForm = ({ name, value }) => {
        setStudent((prev) => ({ ...prev, [name]: value }));
    };

    function handleFormFieldChange({ name, value }) {
        const { form } = student;
        const foundQuestion = form.find(
            (question) => question.id.toString() === name,
        );

        form.splice(form.indexOf(foundQuestion), 1, {
            ...foundQuestion,
            answer: { value },
        });

        setStudent((prev) => ({ ...prev, form }));
    }

    function setEnrollmentPaymentForm(paymentForm) {
        setStudent({
            ...student,
            enrollment: {
                ...student.enrollment,
                paymentForm,
            },
        });
    }

    function cancelActivity(deletingPaymentProcedure) {
        let params;
        if (deletingPaymentProcedure !== undefined) {
            params = {
                deletingPaymentAction: deletingPaymentProcedure,
            };
        }
        Delete(`/students/${idStudent}`, params).then(() => {
            setOpenAskingPaymentProcedure(false);
            saveAndClose();
        });
    }

    const deleteInscription = () => {
        cxt.api('DELETE', `/activities/students/${student.id}`, {
            confirmation: true,
            success: () => {
                cxt.showMessage('S', 'Deleted');
                onClose();
            },
        });
    };

    const [isMutationsLoading, mutationsLoader] = useLoader([
        createActivityRateStudentMutation.status,
        updateStudentMutation.status,
        generateStudentEnrollmentMutation.status,
    ]);

    if (isMutationsLoading) {
        return mutationsLoader;
    }

    if (!student) {
        return null;
    }

    const hasTutor = student.user?.mship?.tutor;

    const discounts = creating ? discountsForm : student.discounts;

    const showDiscountsSelection =
        (selectedRate?.fee > 0 || selectedRate?.enrollment > 0) &&
        creating &&
        discounts?.length > 0;

    const showDiscountsList =
        selectedRate?.fee > 0 && !creating && discounts?.length > 0;

    const tabStudent = (
        <Grid container spacing={3}>
            <>
                {student.id ? (
                    <SttLabelForm
                        grid
                        xs={12}
                        sm={hasTutor ? 6 : 12}
                        md={hasTutor ? 3 : 6}
                        title={cxt.t(translations.generic.name)}
                        text={fullName(student.user)}
                    />
                ) : (
                    <SttSelectUser
                        grid
                        xs={12}
                        md={6}
                        caption={cxt.t(translations.generic.name)}
                        name="user"
                        user={student.user}
                        onSelect={(mship) => {
                            if (mship) {
                                setProperty({
                                    name: 'user',
                                    value: {
                                        ...mship.user,
                                        mship,
                                    },
                                });
                            } else {
                                setProperty({
                                    name: 'user',
                                    value: undefined,
                                });
                            }
                        }}
                        returnMship
                    />
                )}

                {hasTutor && (
                    <SttLabelForm
                        grid
                        xs={12}
                        sm={6}
                        md={3}
                        title={cxt.t('Tutor')}
                        text={fullName(student.user.mship.tutor)}
                    />
                )}

                <SttFormDate
                    grid
                    md={3}
                    sm={6}
                    xs={12}
                    name="ini"
                    caption={cxt.t('Begin')}
                    defVal={student.ini}
                    onChange={handleForm}
                />

                <SttFormDate
                    grid
                    md={3}
                    sm={6}
                    xs={12}
                    name="end"
                    caption={cxt.t('End')}
                    defVal={student.end}
                    onChange={handleForm}
                />

                <SttFormTextarea
                    grid
                    name="notes"
                    caption={cxt.t(translations.generic.notes)}
                    defVal={student.notes}
                    onChange={handleForm}
                />

                <SttFormSelect
                    grid
                    xs={12}
                    md={6}
                    name="rate"
                    caption={cxt.t(translations.generic.rate)}
                    defVal={idRate}
                    disabled
                    options={(() => {
                        if (activity?.rates) {
                            return activity.rates.map((rate) => ({
                                caption: rate.name,
                                value: rate.id,
                            }));
                        }

                        if (student?.rate) {
                            return [
                                {
                                    caption: student.rate.name,
                                    value: student.rate.id,
                                },
                            ];
                        }

                        return [];
                    })()}
                    onChange={({ value }) => setIdRate(value)}
                />

                {/* --- Payment form - signup --- */}
                <SttSelectPForm
                    grid
                    xs={12}
                    md={3}
                    disabled={
                        !!student.id ||
                        (selectedRate && selectedRate.enrollment === 0)
                    }
                    name="enrollmentPaymentForm"
                    caption={`${cxt.t(translations.generic.inscription)}${
                        selectedRate
                            ? `[ ${formatPriceByLocale(
                                  selectedRate.enrollment,
                                  selectedRate.enrollmentShape?.currency,
                                  selectedRate.enrollmentShape?.locale,
                              )} ]`
                            : ''
                    }`}
                    defVal={
                        student.enrollment ? student.enrollment.paymentForm : ''
                    }
                    onChange={({ value }) => setEnrollmentPaymentForm(value)}
                    activePFs={[
                        '',
                        constants.payment.paymentForms.purse.name,
                        constants.payment.paymentForms.cash.name,
                        constants.payment.paymentForms.bank.name,
                        cxt?.sc?.dataphoneConnected
                            ? constants.payment.paymentForms.dataphoneConnected
                                  .name
                            : constants.payment.paymentForms.dataphone.name,
                        constants.payment.paymentForms.receipt.name,
                        constants.payment.paymentForms.free.name,
                    ]}
                />

                {/* --- Payment form - fee --- */}
                {selectedRate?.fee > 0 && (
                    <SttSelectPForm
                        grid
                        xs={12}
                        md={3}
                        name="feePaymentForm"
                        caption={`${cxt.t(translations.generic.fee)}${
                            selectedRate
                                ? `[ ${formatPriceByLocale(
                                      selectedRate.fee,
                                      selectedRate.feeShape?.currency,
                                      selectedRate.feeShape?.locale,
                                  )} ]`
                                : ''
                        }`}
                        defVal={
                            selectedRate && selectedRate.fee !== 0
                                ? student.feePaymentForm
                                : ''
                        }
                        onChange={handleForm}
                        activePFs={
                            selectedRate && selectedRate.fee !== 0
                                ? [
                                      '',
                                      constants.payment.paymentForms.receipt
                                          .name,
                                      cxt.sc.TPVFeePayment &&
                                          constants.payment.paymentForms.tpv
                                              .name,
                                  ]
                                : [constants.payment.paymentForms.free.name]
                        }
                    />
                )}

                {showDiscountsSelection && (
                    <Grid item xs={12}>
                        <Grid container>
                            {discounts.map((discount) => (
                                <Grid item key={discount.id} xs={12}>
                                    <SttFormCheck
                                        name={discount.id}
                                        caption={`${discount.name} - ${discount.discountPercentage}%`}
                                        checked={discount.checked}
                                        onChange={handleChangeDiscounts}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                )}

                {showDiscountsList && (
                    <Grid item xs={12}>
                        <DiscountList
                            discounts={discounts}
                            showEmptyMessage={false}
                        />
                    </Grid>
                )}

                {history && (
                    <Grid item xs={12}>
                        <Box pt={3}>
                            <SttLabelForm text={cxt.t('Historical')} />
                            <Box
                                style={{
                                    textAlign: 'center',
                                    border: '1px solid #cdcdcd',
                                }}
                                py={1}
                                mt={1}
                            >
                                <SttLabelForm text={cxt.t('Period')} />
                            </Box>

                            {history.map((item) => (
                                <Box
                                    key={item.id}
                                    display="flex"
                                    style={{
                                        border: '1px solid #cdcdcd',
                                        borderTop: 0,
                                    }}
                                >
                                    <Box
                                        p={1}
                                        flex={1}
                                        style={{ textAlign: 'center' }}
                                    >
                                        {moment(item.ini).format(
                                            'DD MMM YYYY, HH:mm',
                                        )}
                                    </Box>
                                    <Box
                                        p={1}
                                        flex={1}
                                        style={{
                                            textAlign: 'center',
                                            borderColor: '#cdcdcd',
                                            borderStyle: 'solid',
                                            borderWidth: 0,
                                            borderLeftWidth: 1,
                                        }}
                                    >
                                        {moment(item.end).format(
                                            'DD MMM YYYY, HH:mm',
                                        )}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Grid>
                )}
            </>
        </Grid>
    );

    const tabFees = student.fees ? (
        <SttFeeList fees={student.fees} />
    ) : (
        <Typography>{cxt.t(translations.generic.noFees)}</Typography>
    );

    const tabForm = student.form && (
        <Grid container spacing={3}>
            {student?.form?.map((item) => (
                <Grid item key={item.id} xs={12}>
                    {generateFormField(item, handleFormFieldChange)}
                </Grid>
            ))}
        </Grid>
    );

    const availableTabs = [
        {
            caption: cxt.t('Student'),
            component: tabStudent,
        },
        {
            caption: cxt.t(translations.generic.fees),
            component: tabFees,
            show: !!student.id,
        },
        {
            caption: cxt.t('Form'),
            component: tabForm,
            show: !!student.form,
        },
    ];

    return (
        <>
            <SttDialog
                title={title}
                subtitle={
                    selectedRate?.activity
                        ? `${selectedRate.activity.name}, ${selectedRate.name}`
                        : ''
                }
                maxWidth="md"
                open={open}
                onClose={onClose}
                content={<SttTabs tabs={availableTabs} />}
                buttons={[
                    student.id && {
                        caption: `${cxt.t(translations.generic.delete)} ${cxt.t(
                            translations.generic.inscription,
                        )}`,
                        icon: <KeyboardReturnIcon />,
                        onClick: () => deleteInscription(),
                    },
                    student.id && {
                        caption: cxt.t('ChangeClassAndRate'),
                        icon: <LoopIcon />,
                        onClick: () => setOpenChangeClassAndRateDialog(true),
                    },
                    !student.enrollment &&
                        selectedRate?.enrollment > 0 && {
                            caption: cxt.t('popup.student.generateSignup'),
                            onClick: () => {
                                generateStudentEnrollmentMutation.mutate({
                                    id: student.id,
                                });
                            },
                        },
                    {
                        type: 'save',
                        onClick: () => upsertStudent(),
                        disabled: !student || (!student.id && !student.user),
                    },
                    student.id && {
                        type: 'unsubscribe',
                        onClick: () => setOpenAskingPaymentProcedure(true),
                    },
                    {
                        type: 'close',
                        onClick: onClose,
                    },
                ]}
                menu={[
                    {
                        caption: `${cxt.t(translations.generic.download)} PDF`,
                        onClick: () => download(),
                    },
                ]}
            />

            <HandlePaymentsModal
                warningText={cxt.t(
                    'sc.bookings.delete.howToHandleActivityPayments',
                )}
                open={openAskingPaymentProcedure}
                onClose={() => setOpenAskingPaymentProcedure(false)}
                options={[
                    {
                        caption: cxt.t('associatedPayments.doNothing'),
                        value: '0',
                    },
                    {
                        caption: cxt.t('associatedPayments.delete'),
                        value: '1',
                    },
                    {
                        caption: cxt.t('associatedPayments.pending'),
                        value: '2',
                    },
                ]}
                onAccept={(option) => {
                    switch (option) {
                        case '0':
                            cancelActivity();
                            break; // PBM: 'do nothing' case was missing
                        case '1':
                            cancelActivity(1);
                            break;
                        case '2':
                            cancelActivity(2);
                            break;
                        default:
                    }
                }}
            />

            {/* Rate selector. Opened when user click in 'ChangeClassAndRate' button. */}
            <SttSelectClassAndRateDialog
                open={openChangeClassAndRateDialog}
                studentId={idStudent}
                onClose={() => {
                    setOpenChangeClassAndRateDialog(false);
                    onClose();
                }}
            />

            {cxt.sc?.sporttiaStripeLocationId && posPaymentParams !== null ? (
                <POSStripePaymentDialog
                    paymentData={posPaymentParams}
                    onClose={() => setPosPaymentParams(null)}
                    onSuccess={() => {
                        saveAndClose();
                    }}
                />
            ) : (
                <POSPaymentDialog
                    open={posPaymentParams !== null}
                    paymentData={posPaymentParams}
                    onClose={() => setPosPaymentParams(null)}
                    onSuccess={() => {
                        saveAndClose();
                    }}
                />
            )}
        </>
    );
}
