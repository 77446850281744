import React, { useState } from 'react';
import {
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    Divider,
    Button,
} from '@material-ui/core';
import { MoreVert as MoreIcon } from '@material-ui/icons';

type Item = {
    show?: boolean;
    divider?: boolean;
    caption: string;
    icon?: JSX.Element;
    onClick: () => void;
};

type SttMenuProps = {
    type?: 'icon' | 'button';
    caption?: string;
    icon?: JSX.Element;
    items: Item[];
};

export function SttMenu({
    type = 'icon',
    caption,
    icon,
    items,
    ...rest
}: SttMenuProps) {
    const [buttonMenuElm, setButtonMenuElm] =
        useState<HTMLButtonElement | null>(null);

    return (
        <>
            {type === 'icon' && (
                <IconButton
                    color="inherit"
                    onClick={(event) => setButtonMenuElm(event.currentTarget)}
                    {...rest}
                >
                    {icon || <MoreIcon />}
                </IconButton>
            )}

            {type === 'button' && (
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={icon}
                    onClick={(ev) => setButtonMenuElm(ev.currentTarget)}
                    {...rest}
                >
                    {caption}
                </Button>
            )}

            <Menu
                anchorEl={buttonMenuElm}
                open={buttonMenuElm !== null}
                onClose={() => setButtonMenuElm(null)}
            >
                {items
                    .filter(({ show = true }) => show)
                    .map((item) => (
                        <div key={item.caption}>
                            {item.divider && <Divider />}
                            <MenuItem
                                onClick={() => {
                                    item.onClick();
                                    setButtonMenuElm(null);
                                }}
                            >
                                {item.icon && (
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                )}
                                {item.caption}
                            </MenuItem>
                        </div>
                    ))}
            </Menu>
        </>
    );
}
