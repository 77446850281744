import React, { useState, useContext } from 'react';
import { useQueryClient } from 'react-query';
import { AppContext, SttFullDialog, SttTabs } from '../../../sporttia/all';
import GroupDetails from './GroupDetails';
import GroupMembers from './GroupMembers';
import GroupPrivileges from './GroupPrivileges';
import GroupFees from './GroupFees';
import GroupDevices from './GroupDevices';
import { useLoader } from '../../../lib/hooks';
import { Group as IGroup } from '../../../types/api/models';
import useGroupsService from '../../../services/GroupsService';
import SttError from '../../../components/error/SttError';
import { GetGroup200 } from '../../../types/api/paths/Group';
import translations from '../../../translations';
import constants from '../../../config/constants';

type GroupProps = {
    id: number;
    trash: boolean;
    onChange: (group: IGroup) => void;
    onClose: () => void;
};

/**
 * Group screen
 */
export default function Group({ id, trash, onChange, onClose }: GroupProps) {
    const cxt = useContext(AppContext)!;
    const queryClient = useQueryClient();

    const [activeFeeTab, setActiveFeeTab] = useState(false);

    const groupsService = useGroupsService();
    const getGroupQuery = groupsService.useGetGroup(id, {
        trash,
    });
    const getGroupMembersQuery = groupsService.useGetGroupMembers(
        id,
        { rows: 25, page: 1 },
        {
            onSuccess: (response) => {
                if (response.rows.some((m) => 'fees' in m)) {
                    setActiveFeeTab(true);
                } else {
                    setActiveFeeTab(false);
                }
            },
        },
    );

    const [isLoading, loader] = useLoader([
        getGroupQuery.status,
        getGroupMembersQuery.status,
    ]);

    if (isLoading) {
        return loader;
    }

    if (getGroupQuery.isError || getGroupMembersQuery.isError) {
        return (
            <SttFullDialog open onClose={onClose}>
                <SttError />
            </SttFullDialog>
        );
    }

    if (!getGroupQuery.isSuccess || !getGroupMembersQuery.isSuccess) {
        return null;
    }

    // Mandatory tabs for all groups
    const tabs = [
        {
            caption: cxt.t(translations.generic.detail),
            component: (
                <GroupDetails
                    item={getGroupQuery.data.group}
                    onSave={(savedGroup: IGroup) => {
                        queryClient.setQueryData<GetGroup200>(
                            getGroupQuery.queryKey,
                            () => ({
                                group: savedGroup,
                            }),
                        );
                        onChange(savedGroup);
                    }}
                    onDelete={(deletedGroup: IGroup) => {
                        onChange(deletedGroup);
                        onClose();
                    }}
                />
            ),
        },
        {
            caption: cxt.t('sc.group.members'),
            component: (
                <GroupMembers
                    item={getGroupQuery.data.group}
                    haveFees={activeFeeTab}
                />
            ),
        },
        {
            caption: cxt.t(translations.generic.fees),
            component: <GroupFees item={getGroupQuery.data.group} />,
            show:
                getGroupQuery.data.group.type !==
                    constants.group.types.sport.name &&
                getGroupQuery.data.group.feePrice !== undefined &&
                getGroupQuery.data.group.feeInterval !== null,
        },
        {
            caption: cxt.t(translations.generic.privileges),
            component: <GroupPrivileges item={getGroupQuery.data.group} />,
        },
        {
            caption: cxt.t('Devices'),
            component: <GroupDevices item={getGroupQuery.data.group} />,
        },
    ];

    // For non-sport groups we also need a 'fees' tab which, inconveniently enough, shows up in the 3rd place.
    if (getGroupQuery.data.group.type !== constants.group.types.sport.name) {
        tabs.splice(2, 0);
    }

    return (
        <SttFullDialog
            open
            title={getGroupQuery.data.group.name}
            onClose={onClose}
        >
            <SttTabs tabs={tabs} />
        </SttFullDialog>
    );
}
