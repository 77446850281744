import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Container,
    Box,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Link,
    Avatar,
    Tooltip,
} from '@material-ui/core';
import EventNoteIcon from '@material-ui/icons/EventNote';
import { MailOutline as MailOutlineIcon, VpnKey } from '@material-ui/icons';
import { AppContext } from '../../sporttia/all';
import { getPath } from '../../pages/Pages';
import UserHeaderAvatar from './UserHeaderAvatar';
import sporttiaSVG from '../../images/sporttia.svg';
import UserHeaderTopBanner from './UserHeaderTopBanner';

export default function AdminHeader() {
    const cxt = useContext(AppContext)!;
    const history = useHistory();

    return (
        <AppBar
            position="static"
            style={{
                backgroundColor:
                    localStorage.getItem('environment') === 'PRE'
                        ? '#fbc02d'
                        : 'default',
                alignSelf: 'flex-start',
            }}
        >
            <UserHeaderTopBanner />
            <Container maxWidth="md">
                <Toolbar>
                    <Box mr={2}>
                        <Avatar
                            style={{
                                backgroundColor: 'white',
                            }}
                        >
                            <img
                                style={{
                                    width: 30,
                                    height: 30,
                                }}
                                src={sporttiaSVG}
                                alt=""
                            />
                        </Avatar>
                    </Box>
                    <Typography
                        noWrap
                        component="h1"
                        variant="h6"
                        color="inherit"
                    >
                        <Link
                            onClick={() => history.push('/')}
                            style={{
                                color: 'white',
                                textDecoration: 'none',
                            }}
                        >
                            SPORTTIA
                        </Link>
                    </Typography>

                    <Box flex={1} display="flex" justifyContent="flex-end">
                        <Tooltip title={cxt.t('userKeys')} arrow>
                            <IconButton
                                color="inherit"
                                className="float-right"
                                onClick={() =>
                                    history.push(getPath('userKeys'))
                                }
                            >
                                <VpnKey />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={cxt.t('bookings')} arrow>
                            <IconButton
                                color="inherit"
                                className="float-right"
                                onClick={() =>
                                    history.push(getPath('userAgenda'))
                                }
                            >
                                <EventNoteIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={cxt.t('Messages')} arrow>
                            <IconButton
                                color="inherit"
                                className="float-right"
                                onClick={() =>
                                    history.push(getPath('userMessages'))
                                }
                            >
                                <MailOutlineIcon />
                            </IconButton>
                        </Tooltip>

                        <UserHeaderAvatar />
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
