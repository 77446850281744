import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { AppContext } from '../../../sporttia/AppContext';
import { SttLabelDate } from '../../../sporttia/labels/SttLabelDate';
import MessageCommentBox from '../../sc/messages/MessageCommentBox';

/**
 * Sport Center Wall Comments List component. Visualize all Post comments.
 * @param post Object with Post value.
 * @returns {JSX.Element} JSX with component.
 */
export default function MessageCommentList({ post, scId }) {
    const cxt = useContext(AppContext);
    const [comments, setComments] = useState();
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(5);
    const [postValue, setPostValue] = useState({});

    /**
     * Load comments.
     */
    const loadComments = () => {
        cxt.api('GET', `/posts/${postValue.id}/comments`, {
            params: {
                page,
                rows,
            },
            success: (r) => {
                setComments(r);
            },
        });
    };

    /**
     * Send post comment.
     * @param text String with comment text value.
     */
    const sendComment = (text) => {
        cxt.api('POST', `/posts/${postValue.id}/comments`, {
            params: {
                text,
            },
            success: () => {
                loadComments();
            },
        });
    };

    /**
     * Delete post comment.
     * @param commentId comment id.
     */
    const deleteComment = (commentId) => {
        cxt.api('DELETE', `/posts/comments/${commentId}`, {
            confirmation: true,
            success: (r) => {
                cxt.showMessage('S', 'Deleted');
                loadComments();
            },
        });
    };

    /**
     * If 'post' was changed we set postValue variable.
     */
    useEffect(() => {
        if (post && post.id) setPostValue(post);
    }, [post]);

    /**
     * If 'postValue' was changed we load comments.
     */
    useEffect(() => {
        if (postValue && postValue.id) loadComments();
    }, [postValue]);

    /**
     * If 'rows' was changed we load comments.
     */
    useEffect(() => {
        if (rows && postValue && postValue.id) loadComments();
    }, [rows]);

    // -----| Render |-----
    return (
        <Box>
            <List>
                {comments && comments.count > comments.rows.length && (
                    <Button fullWidth onClick={() => setRows(rows + 8)}>
                        {cxt.t('ShowMoreResults')}
                    </Button>
                )}

                {comments &&
                    comments.rows.map((comment, idxComm) => (
                        <Fragment key={idxComm}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar
                                        alt={comment.creator.name}
                                        src={cxt.getResource(
                                            `/images/${comment.img?.id}.jpg`,
                                        )}
                                    />
                                </ListItemAvatar>

                                <ListItemText
                                    primary={comment.creator.name}
                                    secondary={
                                        <Typography
                                            variant="body2"
                                            color="textPrimary"
                                        >
                                            <SttLabelDate
                                                date={comment.created}
                                            />
                                        </Typography>
                                    }
                                />
                            </ListItem>

                            <Box ml={9} mb={2}>
                                <Typography variant="body1">
                                    {comment.text}
                                </Typography>
                                {cxt?.logged &&
                                    (cxt?.user?.id === comment?.creator?.id ||
                                        (cxt?.sc?.id &&
                                            scId &&
                                            (cxt?.sc?.id).toString() ===
                                                scId?.toString())) && (
                                        <Button
                                            size="small"
                                            startIcon={<DeleteIcon />}
                                            onClick={() =>
                                                deleteComment(comment.id)
                                            }
                                            style={{ padding: '0' }}
                                        >
                                            {cxt.t('Delete')}
                                        </Button>
                                    )}
                            </Box>

                            {idxComm !== comments.rows.length - 1 && (
                                <Divider variant="inset" component="li" />
                            )}
                        </Fragment>
                    ))}
            </List>

            {cxt.logged && (
                <MessageCommentBox
                    onSendComment={sendComment}
                    senderName={
                        postValue &&
                        postValue.sender &&
                        post.sender &&
                        post.sender.fullName
                            ? post.sender.fullName
                            : post.sender.name
                    }
                />
            )}
        </Box>
    );
}
