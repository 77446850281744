import React, { useState, useEffect, useContext } from 'react';
import { Chip } from '@material-ui/core';
import { SupervisedUserCircle } from '@material-ui/icons';
import { AppContext } from '../AppContext';

/*
 * Chip to repreent a group
 * Props:
 *   type: should be any of 'NORMAL', 'SPORT' or 'ABONADOS' but can technically be anything
 * */
export function SttChipGroup({ type, icon = false }) {
    const cxt = useContext(AppContext);

    const color = {
        NORMAL: '#578BC1',
        SPORT: '#EA9935',
        ABONADOS: '#CE65F1',
        FAMILY: '#46a300',
    };
    let style;
    let iconStyle;

    if (color[type]) {
        style = { backgroundColor: color[type], color: 'white' };
        iconStyle = { color: 'white' };
    } else {
        style = { backgroundColor: '#f0f0f0', color: '#2b2b2b' }; // Fallback color in case the group's color is unspecified
        iconStyle = { color: '#2b2b2b' };
    }

    return (
        <Chip
            size="small"
            icon={icon ? <SupervisedUserCircle style={iconStyle} /> : null}
            label={cxt.t(`GT.${type}`)}
            style={style}
        />
    );
}
