import React from 'react';
import { makeStyles } from '@material-ui/core';
import { getPath } from '../pages/Pages';

const useStyles = makeStyles((theme) => ({
    link: {
        color: 'rgba(0, 0, 0, 0.87)',
        '&:hover': {
            textDecoration: 'none',
        },
    },
}));

/**
 * LinkWrapper creates a style-less <a> tag which will run 'callback' when clicked with the left mouse button, but operate normally for other buttons.
 * ReactNode children
 * String href - the url to open when operating as a link
 * String target - "_new", "_blank", etc
 * Function callback - function to run when clicked with the left mouse button
 */
export default function LinkWrapper({
    children,
    href,
    target = '_blank',
    callback,
}) {
    const classes = useStyles();

    return (
        <a
            className={classes.link}
            href={href}
            target={target}
            onClick={(e) => e.preventDefault()}
            onMouseUp={(e) => {
                if (callback && e.button === 0) {
                    e.preventDefault();
                    callback();
                }
            }}
        >
            {children}
        </a>
    );
}
