import React, { useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Chip, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    chip: {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
    },
}));

/*
 * SttSelectMultiple renders a Select with Autocomplete and multi-selection
 * string name: name of the field
 * string caption: label for the text field
 * array options: [{label, value}]
 * string defVal: comma separated values like "Val1,Val2,Val3"... basically the currently selected options
 * func onChange: what to do on option added / removed
 * func renderTags: optional function with signature: (value, getTagProps) => value.map((option, index)) => <react nodes> to specify rendering of the selected options (tags)
 * */
export function SttSelectMultiple({
    grid,
    name,
    caption,
    options,
    defVal,
    onChange,
    renderTags,
    ...rest
}) {
    const values = defVal ? defVal.split(',') : [];
    const [value, setValue] = useState(
        options.filter((option) => values.includes(option.value.toString())),
    );
    const classes = useStyles();

    if (!renderTags) {
        renderTags = (value, getTagProps) =>
            value.map((option, index) => (
                <Chip
                    className={classes.chip}
                    size="small"
                    label={option.label}
                    {...getTagProps({ index })}
                />
            ));
    }

    const content = (
        <Autocomplete
            className={classes.textField}
            multiple
            options={options}
            getOptionLabel={(option) => option.label}
            value={value}
            onChange={(ev, options) => {
                const reducedVal = options.reduce(
                    (res, opt, i) => res + ((i > 0 ? ',' : '') + opt.value),
                    '',
                );
                setValue(options);
                onChange({ name, value: reducedVal });
            }}
            filterOptions={(options, state) => {
                if (!value || value.length == 0) {
                    return options;
                }
                return options.filter(
                    (option) => !value.find((opt) => opt.value == option.value),
                );
            }}
            filterSelectedOptions
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    size="small"
                    label={caption}
                    variant="outlined"
                />
            )}
            renderTags={renderTags}
        />
    );

    return grid ? (
        <Grid item {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
