import { useContext } from 'react';
import useRestService from './RestService';
import { useAPI } from '../lib/hooks';
import { AppContext } from '../sporttia/AppContext';

const resource = '/messages';

/**
 * Hook to access all 'messages' resources.
 */
export default function useMessagesService() {
    const { migrationApis } = useContext(AppContext);
    const [request] = useAPI(migrationApis);
    const services = useRestService(resource);

    /**
     * PUT /messages/:id/read request.
     * @param id Message ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function updateMessagesRead(id, params) {
        return request('PUT', `${resource}/${id}/read`, params);
    }

    return {
        ...services,
        updateMessagesRead,
    };
}
