import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { CloudDownload as CloudDownloadIcon } from '@material-ui/icons';
import { Box, Paper } from '@material-ui/core';
import BookingDialog from '../../../components/dialogs/bookingDialog/BookingDialog';
import {
    AppContext,
    SttTopControls,
    SttTable,
    SttChipUser,
} from '../../../sporttia/all';
import { useInteractionsFiles, useToggle } from '../../../lib/hooks';
import {
    copyToClipboard,
    formatPriceByLocale,
    fullName,
} from '../../../lib/utils';
import DateBuilder from '../../../lib/DateBuilder';

export default function Bookings() {
    const cxt = useContext(AppContext);
    const { downloadFile } = useInteractionsFiles();
    const [filters, setFilters] = useState({
        name: '',
        ini: moment().startOf('month').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
    });
    const [bookings, setBookings] = useState([]);
    const [bookingId, setBookingId] = useState(null);

    function loadBookings(params = { page: 1, rows: 20 }) {
        cxt.api('GET', `/scs/${cxt.sc.id}/bookings`, {
            params: {
                ...params,
                ...filters,
                // eslint-disable-next-line no-use-before-define
                trash,
            },
            success: (result) => {
                setBookings(result);
            },
        });
    }

    // Trash hook
    const [trash, toggleTrash] = useToggle(() => {
        loadBookings();
    });

    // set page title
    useEffect(() => {
        cxt.setHeader({ title: cxt.t('bookings') });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Download booking PDF
     */
    function download(format) {
        const type = format === 'pdf' ? 'application/pdf' : 'text/csv';
        downloadFile(
            `/scs/${cxt.sc.id}/bookings.${format}`,
            type,
            { page: 1, ...filters },
            `${cxt.t('Bookings')} ${filters.ini
                .split('-')
                .reverse()
                .join('-')} - ${filters.end
                .split('-')
                .reverse()
                .join('-')}.${format}`,
        );
    }

    // Render
    return (
        <Paper>
            <SttTopControls
                p={2}
                fields={[
                    {
                        caption: cxt.t('Name'),
                        name: 'name',
                        type: 'text',
                        value: filters.name,
                        onEnterKey: loadBookings,
                    },
                ]}
                extraFields={[
                    {
                        caption: cxt.t('Status'),
                        name: 'status',
                        type: 'select',
                        value: filters.status,
                        options: cxt.constants.paymentStatuses,
                        cleanable: true,
                    },
                    {
                        caption: cxt.t('Facility'),
                        name: 'idFacility',
                        type: 'selectAsync',
                        value: filters.idFacility,
                        loader: () =>
                            new Promise((resolve, reject) => {
                                cxt.api('GET', `/scs/${cxt.sc.id}/facilities`, {
                                    params: { page: 1, rows: 1000 },
                                    success: (response) =>
                                        resolve(response.rows),
                                    error: (response) => reject(response.msg),
                                });
                            }),
                    },
                    {
                        caption: cxt.t('Source'),
                        name: 'source',
                        type: 'select',
                        value: filters.source,
                        options: cxt.constants.bookingSources,
                        cleanable: true,
                    },
                    {
                        caption: cxt.t('Begin'),
                        name: 'ini',
                        type: 'date',
                        value: filters.ini,
                        onChange: ({ name, value }) =>
                            setFilters({
                                ...filters,
                                [name]: value
                                    ? moment(value).format('YYYY-MM-DD')
                                    : '',
                            }),
                    },
                    {
                        caption: cxt.t('End'),
                        name: 'end',
                        type: 'date',
                        value: filters.end,
                        onChange: ({ name, value }) =>
                            setFilters({
                                ...filters,
                                [name]: value
                                    ? moment(value).format('YYYY-MM-DD')
                                    : '',
                            }),
                    },
                    {
                        caption: `${cxt.t('dni')}/CIF`,
                        name: 'dni',
                        type: 'text',
                        value: filters.dni,
                    },
                ]}
                menu={[
                    {
                        caption: `${cxt.t('Download')} PDF`,
                        onClick: () => download('pdf'),
                    },
                    {
                        caption: `${cxt.t('Download')} CSV`,
                        onClick: () => download('csv'),
                    },
                ]}
                menuIcon={<CloudDownloadIcon />}
                onChange={({ name, value }) => {
                    setFilters({ ...filters, [name]: value });
                }}
                onFilter={() => loadBookings()}
                onToggleTrash={toggleTrash}
            />

            <SttTable
                data={bookings}
                loading={false}
                onClickRow={(row) => setBookingId(row.id)}
                columns={[
                    {
                        title: '#',
                        type: 'tooltip',
                        value: (row) => ({ label: '#', value: row.id }),
                        onClick: (row) =>
                            copyToClipboard(row.id, () =>
                                cxt.showMessage(
                                    'S',
                                    cxt.t('copiedToClipboard'),
                                ),
                            ),
                    },
                    {
                        title: cxt.t('Created'),
                        value: (row) => (
                            <>
                                <Box mb={1}>
                                    {new DateBuilder(
                                        row?.created,
                                        cxt?.sc?.timezone?.name,
                                        cxt?.lang.key,
                                    ).dmy()}
                                </Box>
                                {'creator' in row &&
                                    (row.creator.id === row.user.id ? (
                                        <SttChipUser user={row.user} />
                                    ) : (
                                        <SttChipUser user={row.creator} />
                                    ))}
                            </>
                        ),
                    },
                    {
                        field: 'name',
                        title: cxt.t('User'),
                        align: 'left',
                        value: (row) => fullName(row.user),
                    },
                    {
                        field: 'ini',
                        type: 'dmy',
                        checkOld: true,
                        title: cxt.t('Date'),
                    },
                    {
                        type: 'hmRange',
                        title: cxt.t('Time'),
                        value: (row) => ({ ini: row.ini, end: row.end }),
                    },
                    {
                        title: cxt.t('Facility'),
                        type: 'facility',
                        field: 'facility',
                    },
                    {
                        field: 'debt',
                        align: 'right',
                        type: 'debt',
                        title: cxt.t('Debt'),
                        value: (row) => row.price - row.totalPaid,
                    },
                    {
                        field: 'price',
                        align: 'right',
                        type: 'price',
                        title: cxt.t('Total'),
                        value: (row) =>
                            formatPriceByLocale(
                                row.price,
                                row.priceShape?.currency,
                                row.priceShape?.locale,
                            ),
                    },
                ]}
                onFetch={loadBookings}
            />

            <BookingDialog
                idBooking={bookingId}
                onUpdate={() => loadBookings()}
                onClose={() => setBookingId(null)}
            />
        </Paper>
    );
}
