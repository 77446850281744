import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Typography,
    makeStyles,
    Tooltip,
    Chip,
    IconButton,
    Link,
} from '@material-ui/core';
import moment from 'moment';
import {
    CheckCircleOutline,
    NavigateBefore as NavigateBeforeIcon,
    NavigateNext as NavigateNextIcon,
} from '@material-ui/icons';
import { useCrud, useInteractionsFiles, useToggle } from '../../../lib/hooks';
import { AppContext, SttTable, SttTopControls } from '../../../sporttia/all';
import { formatPriceByLocale, fullName } from '../../../lib/utils';
import ModalParticipant from './ModalParticipant';
import { useParticipantFuncs } from './ParticipantFunctions';
import colors from '../../../styles/Colors';
import PaymentModal from '../../../components/paymentModal/PaymentModal';
import translations from '../../../translations';

const useStyles = makeStyles(() => ({
    teamName: {
        color: '#000',
        fontSize: 18,
    },
}));

const rowsPerPage = 20;

export default function EventTeams({ event }) {
    const cxt = useContext(AppContext);
    const { downloadFile } = useInteractionsFiles();
    const classes = useStyles();

    const [teams, setTeams, , Get, , , Delete] = useCrud({
        count: 0,
        rows: [],
    });
    const [saveParticipant, deleteParticipant] = useParticipantFuncs();
    const [participant, setParticipant] = useState();
    const [category, setCategory] = useState(
        event.categories && event.categories[0],
    );
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState({
        name: '',
    });
    const [trash, toggleTrash] = useToggle(() => {
        // eslint-disable-next-line no-use-before-define
        loadTeams();
    });
    const [idPayModal, setIdPayModal] = useState(null);

    function loadTeams(params = { page, rows: rowsPerPage }) {
        Get(`/events/categories/${category.id}/teams`, {
            ...params,
            trash,
        }).then((result) => {
            if (result.rows) {
                setTeams(result);
            }
        });
    }

    useEffect(() => {
        if (category.id) {
            loadTeams({ page, rows: rowsPerPage });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, category]);

    function download(mode) {
        const type = mode === 'pdf' ? 'application/pdf' : 'text/plain';
        downloadFile(
            `/events/categories/${category.id}/participants.${mode}`,
            type,
            { page, ...filters, trash },
            `${event.name}_${category.name}_${cxt.t('Teams')}.${mode}`,
        );
    }

    function deleteTeam(team) {
        Delete(`/events/teams/${team.id}`).then(() => {
            const filteredTeams = teams.rows.filter((t) => t.id !== team.id);
            setTeams({
                count: filteredTeams.length,
                rows: filteredTeams,
            });
        });
    }

    function Pagination({ page: receivedPage, perPage, total }) {
        return (
            <Box>
                {(receivedPage - 1) * perPage + 1} -{' '}
                {receivedPage * perPage > total
                    ? total
                    : receivedPage * perPage}{' '}
                {cxt.t('of')} {total}
                <IconButton
                    disabled={receivedPage < 2}
                    onClick={() => {
                        setPage(receivedPage - 1);
                    }}
                >
                    <NavigateBeforeIcon />
                </IconButton>
                <IconButton
                    disabled={receivedPage < Math.floor(total / perPage)}
                    onClick={() => {
                        setPage(receivedPage + 1);
                    }}
                >
                    <NavigateNextIcon />
                </IconButton>
            </Box>
        );
    }

    return (
        <>
            <SttTopControls
                pl={0}
                fields={[
                    {
                        caption: cxt.t('Category'),
                        name: 'category',
                        type: 'select',
                        value: category.id,
                        options:
                            event.categories &&
                            event.categories.map((cat) => ({
                                label: cat.name,
                                value: cat.id,
                            })),
                        onChange: ({ value }) => {
                            setCategory(
                                event.categories.find(
                                    (cat) => cat.id === value,
                                ),
                            );
                        },
                    },
                ]}
                mainAction={{
                    type: 'create',
                    onClick: () => {
                        setParticipant({
                            user: {
                                id: 0,
                                name: '',
                            },
                            team: {
                                id: '0', // "0" instead of 0 or null or whatever because material-ui can't handle falsy values properly as options
                            },
                            mobile: '',
                            email: '',
                            notes: '',
                            form: event.form ? event.form : [],
                            admin: false,
                        });
                    },
                }}
                menu={[
                    {
                        caption: `${cxt.t(translations.generic.download)} PDF`,
                        onClick: () => {
                            download('pdf');
                        },
                    },
                    {
                        caption: `${cxt.t(translations.generic.download)} CSV`,
                        onClick: () => {
                            download('csv');
                        },
                    },
                ]}
                onChange={({ name, value }) => {
                    setFilters({ ...filters, [name]: value });
                }}
                onFilter={loadTeams}
                onToggleTrash={toggleTrash}
            />
            <Box display="flex" justifyContent="flex-end" mt={3}>
                {teams && (
                    <Pagination
                        page={page}
                        perPage={rowsPerPage}
                        total={teams.count}
                    />
                )}
            </Box>
            <Box>
                {teams.rows.map((team) => (
                    <Box mb={3} key={team.id}>
                        <Box mb={1} display="flex" alignItems="center">
                            <Typography className={classes.teamName}>
                                {team.name}
                            </Typography>
                            <Link
                                style={{ marginLeft: 16 }}
                                to=""
                                onClick={() => deleteTeam(team)}
                            >
                                [{cxt.t('Delete')}]
                            </Link>
                        </Box>
                        {team.participants && (
                            <SttTable
                                autoload={false}
                                hidePagination
                                data={{
                                    count: team.participants.length,
                                    rows: team.participants,
                                }}
                                columns={[
                                    {
                                        title: cxt.t('Name'),
                                        align: 'left',
                                        type: 'link',
                                        value: (row) =>
                                            fullName({
                                                ...row.user,
                                                name: row.name,
                                                surname1: row.surname1,
                                                surname2: row.surname2,
                                                displayName: row.displayName,
                                            }),
                                        onClick: (row) => {
                                            setParticipant(row);
                                        },
                                        style: { width: 330 },
                                    },
                                    {
                                        title: cxt.t('SignedBy'),
                                        type: 'text',
                                        align: 'left',
                                        value: (row) => fullName(row.creator),
                                        style: { width: 120 },
                                    },
                                    {
                                        title: cxt.t('Admin'),
                                        type: 'active',
                                        field: 'admin',
                                        style: { width: 100 },
                                    },
                                    {
                                        title: cxt.t('Form'),
                                        value: (row) =>
                                            row.form?.map(
                                                (item) =>
                                                    item.answer?.value && (
                                                        <Tooltip
                                                            arrow
                                                            key={item.id}
                                                            placement="top"
                                                            title={item.name}
                                                            className={
                                                                classes.formField
                                                            }
                                                        >
                                                            <Chip
                                                                size="small"
                                                                label={(() => {
                                                                    let label;

                                                                    if (
                                                                        item.type ===
                                                                        'DATE'
                                                                    ) {
                                                                        label =
                                                                            moment(
                                                                                item
                                                                                    .answer
                                                                                    .value,
                                                                            ).format(
                                                                                'DD-MM-YYYY',
                                                                            );
                                                                    } else if (
                                                                        item.type ===
                                                                        'CHECK'
                                                                    ) {
                                                                        label =
                                                                            (
                                                                                <CheckCircleOutline
                                                                                    style={{
                                                                                        position:
                                                                                            'relative',
                                                                                        top: 2,
                                                                                        color: colors.green,
                                                                                    }}
                                                                                />
                                                                            );
                                                                    } else {
                                                                        label =
                                                                            item
                                                                                .answer
                                                                                .value;
                                                                    }

                                                                    return label;
                                                                })()}
                                                            />
                                                        </Tooltip>
                                                    ),
                                            ),
                                        style: { width: 300 },
                                    },
                                    {
                                        title: cxt.t('Inscription'),
                                        value: (row) =>
                                            row.enrollment && (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <div style={{ flex: 1 }}>
                                                        {row.enrollment
                                                            .paymentDate &&
                                                            moment(
                                                                row.enrollment
                                                                    .paymentDate,
                                                            ).format(
                                                                'D MMM YYYY',
                                                            )}
                                                    </div>
                                                    <Box mx={2}>
                                                        {
                                                            row.enrollment
                                                                .paymentFormLang
                                                        }
                                                    </Box>
                                                    <Link
                                                        onClick={() => {
                                                            setIdPayModal(
                                                                row.enrollment
                                                                    .id,
                                                            );
                                                        }}
                                                        style={{
                                                            flex: 1,
                                                            color:
                                                                row.enrollment
                                                                    .status ===
                                                                'PAID'
                                                                    ? colors.green
                                                                    : colors.red,
                                                        }}
                                                    >
                                                        {formatPriceByLocale(
                                                            row.enrollment
                                                                .price,
                                                            cxt.sc.city.country
                                                                .currency,
                                                        )}
                                                    </Link>
                                                </div>
                                            ),
                                    },
                                ]}
                            />
                        )}
                    </Box>
                ))}
            </Box>

            {event && category && participant && (
                <ModalParticipant
                    item={participant}
                    event={event}
                    category={category}
                    teams={teams.rows}
                    onSave={(participantToSave) => {
                        saveParticipant(participantToSave, category.id)
                            .then(() => {
                                setParticipant(null);
                                loadTeams();
                            })
                            .catch(() => {});
                    }}
                    onClose={() => {
                        setParticipant(null);
                    }}
                    onDelete={(participantToDelete) => {
                        deleteParticipant(participantToDelete)
                            .then(() => {
                                setParticipant(null);
                                loadTeams();
                            })
                            .catch(() => {});
                    }}
                />
            )}

            {idPayModal && (
                <PaymentModal
                    idPayment={idPayModal}
                    onPay={loadTeams}
                    onDeleted={loadTeams}
                    onRecovered={loadTeams}
                    onRefund={loadTeams}
                    onClose={() => {
                        setIdPayModal(null);
                    }}
                />
            )}
        </>
    );
}
