import React, { useState, useContext } from 'react';
import { Box, Container, Button } from '@material-ui/core';
import {
    Payment as PaymentIcon,
    Delete as DeleteIcon,
    Replay as ReplayIcon,
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useQueryClient } from 'react-query';
import {
    AppContext,
    SttTabs,
    SttFullDialog,
    SttChipPaymentStatus,
} from '../../sporttia/all';
import PaymentModalDetail from './PaymentModalDetail';
import PaymentModalChildren from './PaymentModalChildren';
import PaymentFormDialog from '../dialogs/PaymentFormDialog';
import BookingDialog from '../dialogs/bookingDialog/BookingDialog';
import { useInteractionsFiles, useLoader } from '../../lib/hooks';
import POSPaymentDialog from '../dialogs/POSPaymentDialog';
import POSStripePaymentDialog from '../stripe/POSStripePaymentDialog';
import translations from '../../translations';
import constants from '../../config/constants';
import usePaymentsService from '../../services/PaymentsService';
import SttError from '../error/SttError';
import SttFullPageSpinner from '../../sporttia/spinners/SttFullPageSpinner';
import { getErrorMessage } from '../../lib/utils';
import ModalConfirmation from '../../layout/ModalConfirmation';
import Payment from '../../types/models/Payment';
import { GetPayment200 } from '../../types/api/paths/Payment';
import { PosPaymentParams } from '../../types/payment';
import CashdroControlDialog from '../dialogs/CashdroControlDialog';

type PaymentModalProps = {
    idPayment: number;
    onPay?: (payment: Payment) => void;
    onDeleted?: (payment: Payment) => void;
    onRecovered?: (payment: Payment) => void;
    onRefund?: (payment: Payment) => void;
    onSave?: (payment: Payment) => void;
    onClose?: () => void;
};

export default function PaymentModal({
    idPayment,
    onPay,
    onDeleted,
    onRecovered,
    onRefund,
    onSave,
    onClose,
}: PaymentModalProps) {
    const cxt = useContext(AppContext)!;
    const { openFile } = useInteractionsFiles();
    const [openSelectPF, setOpenSelectPF] = useState(false);
    const [dialogBookingId, setDialogBookingId] = useState<number | null>(null);
    const [idPaymentModal, setIdPaymentModal] = useState(idPayment);
    const [posPaymentParams, setPosPaymentParams] =
        useState<PosPaymentParams | null>(null);

    // Open confirmation modal on delete or recover
    const [operationConfirmation, setOperationConfirmation] = useState<
        'delete' | 'recover'
    >();

    const queryClient = useQueryClient();

    const paymentsService = usePaymentsService();
    const getPaymentQuery = paymentsService.useGetPayment(idPaymentModal, {
        trash: true,
    });

    const CASHDRO = cxt?.sc?.cashdroConnected;

    const [cashdroPayment, setCashdroPayment] = useState(false);
    const [cashdroPaymentAmount, setCashdroPaymentAmount] = useState(0);

    const deletePaymentMutation = paymentsService.useDeletePayment({
        onSuccess: (response) => {
            queryClient.invalidateQueries(getPaymentQuery.queryKey);

            onDeleted?.(response.payment);
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });

    const recoverPaymentMutation = paymentsService.useRecoverPayment({
        onSuccess: (response) => {
            queryClient.invalidateQueries(getPaymentQuery.queryKey);

            onRecovered?.(response.payment);
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });

    const collectPaymentMutation = paymentsService.useCollectPayment({
        onSuccess: (response) => {
            queryClient.invalidateQueries(getPaymentQuery.queryKey);

            setOpenSelectPF(false);

            const { tpv, payment: paymentResponse } = response;
            if (
                tpv?.payment?.paymentForm ===
                constants.payment.paymentForms.dataphoneConnected.name
            ) {
                // params for the POS dialog (datáfono físico)
                setPosPaymentParams({
                    amount: tpv.price,
                    concept: tpv.concept,
                    idTpv: tpv.id,
                    sc: {
                        id: tpv.sc?.id,
                        scName: tpv.sc?.short,
                        entityName: tpv.sc?.customer?.name,
                        address: tpv.sc?.address,
                        cif: tpv.sc?.customer?.cif,
                        phone: tpv.sc?.phonePublic,
                    },
                    returnParams: {
                        payment: paymentResponse,
                    },
                });
            } else if (paymentResponse) {
                onPay?.(paymentResponse);
            }
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });

    const refundPaymentMutation = paymentsService.useRefundPayment({
        onSuccess: (response) => {
            queryClient.invalidateQueries(getPaymentQuery.queryKey);

            if (response.payment) {
                onRefund?.(response.payment);
            }
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });

    const ticket = (type: number) => {
        openFile(
            `/payments/${idPaymentModal}.pdf?format=${type}`,
            'application/pdf',
            null,
            `${cxt.t(translations.generic.ticket)}_${idPaymentModal}`,
        );
    };

    const generateInvoice = () => {
        openFile(
            `/payments/${idPaymentModal}/invoice.pdf`,
            'application/pdf',
            null,
            `${cxt.t(translations.generic.invoice)}_${idPaymentModal}`,
        );
    };

    const openPayment = (paymentToOpen: Payment) => {
        setIdPaymentModal(paymentToOpen.id);
    };

    const [, mutationsLoader] = useLoader([
        collectPaymentMutation.status,
        recoverPaymentMutation.status,
        refundPaymentMutation.status,
        deletePaymentMutation.status,
    ]);

    const setupPaymentCollection = (paymentForm: string) => {
        if (
            CASHDRO &&
            paymentForm === constants.payment.paymentForms.cashdro.name
        ) {
            setCashdroPayment(true);
            setCashdroPaymentAmount(getPaymentQuery?.data?.payment?.price || 0);
        } else {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            collectPayment(paymentForm);
        }
    };

    const collectPayment = (paymentForm: string) => {
        collectPaymentMutation.mutate({
            id: idPaymentModal,
            params: {
                // @ts-expect-error no hay tiempo
                paymentForm,
            },
        });
    };

    // Do not show SttFullDialog since payment is loaded
    if (getPaymentQuery.isLoading) {
        return <SttFullPageSpinner />;
    }

    if (getPaymentQuery.isError) {
        return (
            <SttFullDialog
                open
                title={cxt.t(translations.generic.error)}
                onClose={onClose}
            >
                <SttError heading={false} />
            </SttFullDialog>
        );
    }

    if (!getPaymentQuery.isSuccess) {
        return null;
    }

    return (
        <>
            {getPaymentQuery.data.payment.privileges && (
                <SttFullDialog
                    open
                    onClose={onClose}
                    title={
                        <Box>
                            {cxt.t(translations.payment.payment)}
                            &nbsp;&nbsp;&nbsp;
                            <SttChipPaymentStatus
                                status={getPaymentQuery.data.payment.status}
                            />
                        </Box>
                    }
                    buttons={[
                        {
                            show: getPaymentQuery.data.payment.privileges.includes(
                                constants.payment.privileges.pay,
                            ),
                            caption: cxt.t(translations.generic.collect),
                            onClick: () => setOpenSelectPF(true),
                            icon: <PaymentIcon />,
                        },
                    ]}
                    menu={[
                        {
                            caption: cxt.t(translations.generic.recover),
                            icon: <ReplayIcon />,
                            show: getPaymentQuery.data.payment.privileges.includes(
                                constants.payment.privileges.recover,
                            ),
                        },
                        {
                            caption: cxt.t(translations.generic.delete),
                            onClick: () => {
                                setOperationConfirmation('delete');
                            },
                            icon: <DeleteIcon />,
                            show: getPaymentQuery.data.payment.privileges.includes(
                                constants.payment.privileges.delete,
                            ),
                        },
                        {
                            caption: `${cxt.t(
                                translations.generic.refund,
                            )} - ${cxt.t(
                                translations.payment.paymentFormCash,
                            )}`,
                            divider: true,
                            onClick: () => {
                                refundPaymentMutation.mutate({
                                    id: idPaymentModal,
                                    params: {
                                        paymentForm:
                                            constants.payment.paymentForms.cash
                                                .name,
                                    },
                                });
                            },
                            show: getPaymentQuery.data.payment.privileges.includes(
                                constants.payment.privileges.refund,
                            ),
                        },
                        {
                            caption: `${cxt.t(
                                translations.generic.refund,
                            )} - ${cxt.t(
                                translations.payment.paymentFormPurse,
                            )}`,
                            onClick: () => {
                                refundPaymentMutation.mutate({
                                    id: idPaymentModal,
                                    params: {
                                        paymentForm:
                                            constants.payment.paymentForms.purse
                                                .name,
                                    },
                                });
                            },
                            show: getPaymentQuery.data.payment.privileges.includes(
                                constants.payment.privileges.refund,
                            ),
                        },
                        {
                            caption: `${cxt.t(
                                translations.generic.refund,
                            )} - ${cxt.t(translations.payment.paymentFormTpv)}`,
                            onClick: () => {
                                refundPaymentMutation.mutate({
                                    id: idPaymentModal,
                                    params: {
                                        paymentForm:
                                            constants.payment.paymentForms.tpv
                                                .name,
                                    },
                                });
                            },
                            show: getPaymentQuery.data.payment.privileges.includes(
                                constants.payment.privileges.refundTPV,
                            ),
                        },
                        {
                            caption: cxt.t(translations.generic.ticket),
                            divider: true,
                            onClick: () => ticket(1),
                        },
                        {
                            caption: `${cxt.t(translations.generic.ticket)} A4`,
                            onClick: () => ticket(0),
                        },
                        {
                            caption: `${cxt.t(
                                translations.generic.ticket,
                            )} A4 triple`,
                            onClick: () => ticket(2),
                        },
                        {
                            caption: cxt.t(translations.generic.invoice),
                            divider: true,
                            onClick: () => generateInvoice(),
                            show:
                                getPaymentQuery.data.payment.numInvoice !==
                                    null &&
                                getPaymentQuery.data.payment.numInvoice !== '',
                        },
                        {
                            caption: cxt.t(translations.generic.booking),
                            divider: true,
                            show:
                                getPaymentQuery.data.payment.module ===
                                constants.modules.renting.name,
                            onClick: () =>
                                setDialogBookingId(
                                    getPaymentQuery.data.payment.booking!.id,
                                ),
                        },
                    ]}
                >
                    {mutationsLoader}
                    <Container>
                        <Box my={3}>
                            {getPaymentQuery.data.payment.trash && (
                                <Alert
                                    severity="warning"
                                    action={
                                        <Button
                                            color="inherit"
                                            onClick={() => {
                                                setOperationConfirmation(
                                                    'recover',
                                                );
                                            }}
                                        >
                                            {cxt.t(
                                                translations.generic.recover,
                                            )}
                                        </Button>
                                    }
                                >
                                    {cxt.t(translations.payment.deleted)}
                                </Alert>
                            )}

                            {getPaymentQuery.data.payment.refund && (
                                <Alert
                                    severity="info"
                                    action={
                                        <Button
                                            color="inherit"
                                            onClick={() => {
                                                if (
                                                    getPaymentQuery.data.payment
                                                        .refund
                                                ) {
                                                    openPayment(
                                                        getPaymentQuery.data
                                                            .payment.refund,
                                                    );
                                                }
                                            }}
                                        >
                                            {cxt.t(
                                                translations.payment.payment,
                                            )}
                                        </Button>
                                    }
                                >
                                    {cxt.t(translations.payment.refunded)}
                                </Alert>
                            )}

                            {getPaymentQuery.data.payment.parent && (
                                <Alert
                                    severity="info"
                                    action={
                                        <Button
                                            color="inherit"
                                            onClick={() => {
                                                if (
                                                    getPaymentQuery.data.payment
                                                        .parent
                                                ) {
                                                    openPayment(
                                                        getPaymentQuery.data
                                                            .payment.parent,
                                                    );
                                                }
                                            }}
                                        >
                                            {cxt.t(
                                                translations.generic.openParent,
                                            )}
                                        </Button>
                                    }
                                >
                                    {cxt.t(translations.payment.isChild)}
                                </Alert>
                            )}

                            <SttTabs
                                tabs={[
                                    {
                                        caption: cxt.t(
                                            translations.payment.payment,
                                        ),
                                        component: (
                                            <PaymentModalDetail
                                                payment={
                                                    getPaymentQuery.data.payment
                                                }
                                                onSave={(payment) => {
                                                    queryClient.setQueryData<GetPayment200>(
                                                        getPaymentQuery.queryKey,
                                                        () => ({
                                                            payment,
                                                        }),
                                                    );

                                                    onSave?.(payment);
                                                }}
                                            />
                                        ),
                                    },
                                    {
                                        show:
                                            getPaymentQuery.data.payment
                                                .children !== null,
                                        caption: cxt.t(
                                            translations.generic.children,
                                        ),
                                        component: (
                                            <PaymentModalChildren
                                                payment={
                                                    getPaymentQuery.data.payment
                                                }
                                            />
                                        ),
                                    },
                                ]}
                            />
                        </Box>
                    </Container>

                    <PaymentFormDialog
                        open={openSelectPF}
                        onClose={() => setOpenSelectPF(false)}
                        pfs={[
                            constants.payment.paymentForms.purse.name,
                            constants.payment.paymentForms.cash.name,
                            constants.payment.paymentForms.bank.name,
                            cxt?.sc?.dataphoneConnected
                                ? constants.payment.paymentForms
                                      .dataphoneConnected.name
                                : constants.payment.paymentForms.dataphone.name,
                            constants.payment.paymentForms.receipt.name,
                            constants.payment.paymentForms.free.name,
                        ].concat(
                            // @ts-expect-error no hay tiempo
                            CASHDRO
                                ? [constants.payment.paymentForms.cashdro.name]
                                : [],
                        )}
                        onSelect={(paymentForm) => {
                            setupPaymentCollection(paymentForm);
                            setOpenSelectPF(false);
                        }}
                    />

                    {cxt.sc?.sporttiaStripeLocationId &&
                    posPaymentParams !== null ? (
                        <POSStripePaymentDialog
                            paymentData={posPaymentParams}
                            onClose={() => setPosPaymentParams(null)}
                            onSuccess={() => {
                                // No podemos hacer refetch ya que el status en caso de Stripe se actualiza con un cronjob
                                // y se actualiza de forma asíncrona en el backend
                                const optimisticPayment = {
                                    ...getPaymentQuery.data.payment,
                                    status: constants.payment.status.paid.name,
                                    privileges: [
                                        ...getPaymentQuery.data.payment.privileges.filter(
                                            (privilege) =>
                                                privilege !==
                                                constants.payment.privileges
                                                    .pay,
                                        ),
                                    ],
                                };

                                queryClient.setQueryData<GetPayment200>(
                                    getPaymentQuery.queryKey,
                                    () => ({
                                        payment: optimisticPayment,
                                    }),
                                );

                                onPay?.(optimisticPayment);
                            }}
                        />
                    ) : (
                        <POSPaymentDialog
                            open={posPaymentParams !== null}
                            paymentData={posPaymentParams}
                            onClose={() => setPosPaymentParams(null)}
                            // @ts-expect-error: Migrate POSPaymentDialog
                            onSuccess={(t, returnParams) => {
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                                onPay?.(returnParams?.payment);
                            }}
                        />
                    )}

                    {cashdroPayment && (
                        <CashdroControlDialog
                            open={cashdroPayment}
                            amount={cashdroPaymentAmount}
                            onClose={() => {
                                setCashdroPayment(false);
                                setCashdroPaymentAmount(0);
                            }}
                            onSuccess={() => {
                                setCashdroPayment(false);
                                setCashdroPaymentAmount(0);
                                collectPayment('CASHDRO');
                            }}
                        />
                    )}
                </SttFullDialog>
            )}

            {operationConfirmation && (
                <ModalConfirmation
                    show
                    onAccept={() => {
                        if (operationConfirmation === 'delete') {
                            deletePaymentMutation.mutate({
                                id: idPaymentModal,
                            });
                        }

                        if (operationConfirmation === 'recover') {
                            recoverPaymentMutation.mutate({
                                id: idPaymentModal,
                            });
                        }

                        setOperationConfirmation(undefined);
                    }}
                    onClose={() => {
                        setOperationConfirmation(undefined);
                    }}
                />
            )}

            {/* @ts-expect-error: Migrate BookingDialog */}
            <BookingDialog
                idBooking={dialogBookingId}
                onClose={() => setDialogBookingId(null)}
            />
        </>
    );
}
