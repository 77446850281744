import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Paper, Grid } from '@material-ui/core';
import moment from 'moment';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import { useMutation } from 'react-query';
import {
    AppContext,
    SttTable,
    SttFormDate,
    SttFormText,
    SttButton,
    SttFormPeriod,
    SttButtonFilter,
    SttFullDialog,
} from '../../../sporttia/all';
import PaymentModal from '../../../components/paymentModal/PaymentModal';
import { getPath } from '../../Pages';
import { getErrorMessage } from '../../../lib/utils';
import useScsService from '../../../services/ScsService';
import { useLoader, useTranslations } from '../../../lib/hooks';

export default function GenerateRemittence() {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const scsService = useScsService();
    const { translate } = useTranslations();
    const [payments, setPayments] = useState({});
    const [filter, setFilter] = useState({
        concept: '',
        useIni: moment().startOf('month').format('YYYY-MM-DD'),
        useEnd: moment().endOf('month').format('YYYY-MM-DD'),
    });
    const [paymentDialogId, setPaymentDialogId] = useState();
    const [paymentDate, setPaymentDate] = useState(
        moment().format('YYYY-MM-DD'),
    );
    const createMutation = useMutation(
        (params) => scsService.createScsRemittences(params?.id, params?.params),
        {
            onSuccess: (data) =>
                history.push(
                    getPath('remittence', { id: data.remittence?.id }),
                ),
            onError: (error) => {
                cxt.showMessage('E', getErrorMessage(error));
            },
        },
    );
    const [, loader] = useLoader([createMutation.status]);

    const handleForm = ({ name, value }) => {
        setFilter({ ...filter, [name]: value });
    };

    const loadPayments = (p = { page: 1, rows: 20 }) => {
        cxt.api('GET', `/scs/${cxt.sc.id}/remittences/payments`, {
            params: {
                ...filter,
                page: p.page,
                rows: p.rows,
            },
            success: (r) => {
                setPayments(r);
            },
        });
    };

    return (
        <>
            {loader}
            <SttFullDialog
                open
                onClose={() => history.push(getPath('remittences'))}
                title={translate('GenerateRemittence')}
            >
                <Paper>
                    <Box p={3}>
                        <Grid container spacing={3}>
                            <SttFormText
                                grid
                                md={3}
                                sm={12}
                                xs={12}
                                name="concept"
                                caption={cxt.t('Concept')}
                                defVal={filter.concept}
                                onChange={handleForm}
                            />

                            <SttFormPeriod
                                grid
                                md={6}
                                sm={12}
                                xs={12}
                                caption={cxt.t('Period')}
                                nameIni="useIni"
                                nameEnd="useEnd"
                                defValIni={filter.useIni}
                                defValEnd={filter.useEnd}
                                onChange={handleForm}
                            />

                            <SttFormDate
                                grid
                                md={3}
                                sm={12}
                                xs={12}
                                caption={cxt.t('Issue')}
                                defVal={paymentDate}
                                onChange={({ value }) => setPaymentDate(value)}
                            />
                        </Grid>
                    </Box>
                </Paper>

                <Box mt={2}>
                    <Grid container spacing={3}>
                        <Grid item md={3} sm={12} xs={12}>
                            <SttButtonFilter
                                fullWidth
                                onClick={() => loadPayments()}
                            />
                        </Grid>

                        <Grid item md={3} sm={12} xs={12}>
                            <SttButton
                                fullWidth
                                variant="contained"
                                color="primary"
                                startIcon=<FlashOnIcon />
                                caption={cxt.t('Generate')}
                                confirmation
                                onClick={() =>
                                    createMutation.mutate({
                                        id: cxt.sc.id,
                                        // #86944gzv0 En v7 todos los parameters se enviarán por BODY.
                                        // Eliminar los query string cuando el EP esté subido a PRO.
                                        params: {
                                            concept: filter.concept,
                                            paymentDate,
                                            useIni: filter.useIni,
                                            useEnd: filter.useEnd,
                                        },
                                    })
                                }
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box mt={4}>
                    <Paper>
                        {payments && (
                            <SttTable
                                data={payments}
                                loading={false}
                                columns={[
                                    {
                                        type: 'link',
                                        title: cxt.t('Concept'),
                                        align: 'left',
                                        field: 'concept',
                                        onClick: (row) =>
                                            setPaymentDialogId(row.id),
                                    },
                                    {
                                        title: cxt.t('Period'),
                                        type: 'periodPlain',
                                        value: (row) => ({
                                            ini: row.ini,
                                            end: row.end,
                                        }),
                                    },
                                    {
                                        title: cxt.t('User'),
                                        width: 160,
                                        align: 'left',
                                        value: (row) =>
                                            row.user.mship
                                                ? row.user.mship.name
                                                : row.user.name,
                                    },
                                    {
                                        title: cxt.t('Price'),
                                        type: 'price',
                                        field: 'price',
                                    },
                                ]}
                                onFetch={loadPayments}
                            />
                        )}
                    </Paper>
                </Box>

                {paymentDialogId && (
                    <PaymentModal
                        idPayment={paymentDialogId}
                        onClose={() => setPaymentDialogId(null)}
                    />
                )}
            </SttFullDialog>
        </>
    );
}
