import { useQuery, UseQueryOptions } from 'react-query';
import { useContext } from 'react';
import { useAPI } from '../lib/hooks';
import { AppContext } from '../sporttia/AppContext';
import { GetCountries200, GetCountriesQuery } from '../types/api/paths/Country';

const resource = '/countries';

export const getCountriesQueryKey = (params?: GetCountriesQuery) => [
    `${resource}`,
    ...(params ? [params] : []),
];

/**
 * Hook to access 'activities' resources.
 */
export default function useCountriesService() {
    const { migrationApis } = useContext(AppContext)!;
    const [request] = useAPI(migrationApis);

    function useGetCountries<TData = GetCountries200>(
        params: GetCountriesQuery,
        queryOptions?: UseQueryOptions<GetCountries200, unknown, TData>,
    ) {
        const queryKey = queryOptions?.queryKey ?? getCountriesQueryKey(params);
        const queryFn = () =>
            request<GetCountries200>('GET', `${resource}`, params);

        const query = useQuery(queryKey, queryFn, queryOptions);

        return {
            ...query,
            queryKey,
        };
    }

    return { useGetCountries };
}
