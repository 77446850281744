import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AppStoresLogos from '../../sporttia/SttAppStoresLogos';
import { SttButtonClose } from '../../sporttia/all';
import { useTranslations } from '../../lib/hooks';

function UserHeaderTopBanner() {
    const { translate } = useTranslations();
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Collapse in={open}>
            <Box color="white" bgcolor="#8c43a4" paddingY={1}>
                <Container maxWidth="md">
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item>
                            <Typography align="center" gutterBottom>
                                {translate('usersWebToApp')}
                            </Typography>
                            <AppStoresLogos />
                        </Grid>
                        <Grid item>
                            <SttButtonClose
                                onClick={handleClose}
                                textColor="inherit"
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Collapse>
    );
}

export default UserHeaderTopBanner;
