import React, { useState, useContext } from 'react';
import { Box, Typography, Dialog, List, ListItem } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { AppContext } from '../sporttia/all';

export default function LanguageSelector() {
    const cxt = useContext(AppContext);
    const [dialogOpen, setDialogOpen] = useState(false);

    /**
     * Open language dialog
     */
    const openDialog = () => {
        setDialogOpen(true);
    };

    /**
     * Select language
     */
    const selectLangage = (lg) => {
        cxt.setLang(lg);
        setDialogOpen(false);
    };

    // -----| Render |-----
    return (
        <div>
            {cxt.lang && (
                <Box mx={1}>
                    <Link
                        onClick={() => openDialog()}
                        style={{ color: 'black' }}
                    >
                        <Typography variant="caption">
                            {cxt.lang.caption}
                        </Typography>
                    </Link>
                </Box>
            )}

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <List>
                    {cxt.config &&
                        cxt.config.languages.map((lg) => (
                            <ListItem
                                key={lg.key}
                                button
                                onClick={() => selectLangage(lg)}
                            >
                                {lg.caption}
                            </ListItem>
                        ))}
                </List>
            </Dialog>
        </div>
    );
}
