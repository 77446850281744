/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAPI } from '../lib/hooks';
import useRestService from './RestService';
import { AppContext } from '../sporttia/AppContext';
import {
    GetReports200Response,
    GetReportsQueryParams,
} from '../types/api/paths/Report';

const resource = '/reports';

/**
 * Hook to access all 'reports' resources.
 */
export default function useReportsService() {
    const { migrationApis } = useContext(AppContext)!;
    const [request] = useAPI(migrationApis);
    const services = useRestService(resource);

    /**
     * GET /reports/:id/rows request.
     * @param id Report ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function getReportsRows(
        id: any,
        params: Record<string, unknown> | undefined,
    ) {
        return request('GET', `${resource}/${id}/rows`, params);
    }

    /**
     * GET /reports/:id/columns request.
     * @param id Report ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function getReportsColumns(
        id: any,
        params: Record<string, unknown> | undefined,
    ) {
        return request('GET', `${resource}/${id}/columns`, params);
    }

    /**
     * GET /reports/:id/filters request.
     * @param id Report ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function getReportsFilters(
        id: any,
        params: Record<string, unknown> | undefined,
    ) {
        return request('GET', `${resource}/${id}/filters`, params);
    }

    /**
     * PUT /reports/columns/:id request.
     * @param id Report column ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function updateReportColumn(
        id: any,
        params: Record<string, unknown> | undefined,
    ) {
        return request('PUT', `${resource}/columns/${id}`, params);
    }

    /**
     * POST /reports/:id/columns request.
     * @param id Report ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function createReportColumn(
        id: any,
        params: Record<string, unknown> | undefined,
    ) {
        return request('POST', `${resource}/${id}/columns`, params);
    }

    /**
     * DELETE /reports/columns/:id request.
     * @param id Report column ID.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function removeReportColumn(id: any) {
        return request('DELETE', `${resource}/columns/${id}`);
    }

    /**
     * PUT /reports/filters/:id request.
     * @param id Report filter ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function updateReportFilter(
        id: any,
        params: Record<string, unknown> | undefined,
    ) {
        return request('PUT', `${resource}/filters/${id}`, params);
    }

    /**
     * POST /reports/:id/filters request.
     * @param id Report ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function createReportFilter(
        id: any,
        params: Record<string, unknown> | undefined,
    ) {
        return request('POST', `${resource}/${id}/filters`, params);
    }

    /**
     * DELETE /reports/columns/:id request.
     * @param id Report filter ID.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function removeReportFilter(id: any) {
        return request('DELETE', `${resource}/filters/${id}`);
    }

    /**
     * GET /reports request.
     */
    function useGetReports<TData = GetReports200Response>(
        params?: GetReportsQueryParams,
        queryOptions?: UseQueryOptions<GetReports200Response, unknown, TData>,
    ) {
        const queryKey = queryOptions?.queryKey ?? [
            `${resource}`,
            ...(params ? [params] : []),
        ];

        const queryFn = () =>
            request<GetReports200Response>('GET', `${resource}`, params);

        const query = useQuery(queryKey, queryFn, queryOptions);

        return {
            ...query,
            queryKey,
        };
    }

    return {
        ...services,
        getReportsRows,
        getReportsColumns,
        getReportsFilters,
        updateReportColumn,
        createReportColumn,
        removeReportColumn,
        updateReportFilter,
        createReportFilter,
        removeReportFilter,
        useGetReports,
    };
}
