import React, { useContext } from 'react';
import { Chip } from '@material-ui/core';
import { AppContext, SttTable, SttTopControls } from '../../sporttia/all';

/**
 * DeviceList List page for device authorizations. Device authorizations can be related to groups, activities, events...
 * Object item - The parent item for this device list, typically a Group, Activity etc.
 * String type - A string like "idGroup", "idActivity" as defined in /components/devices/DeviceTypes
 * Array auths - list of device auths
 * Function loaderFunction - callback function for loading table data (like every other SttTable)
 * Function onCreate - callback for the +create button
 * Function onSelect - callback for row selection
 */
export default function DeviceList({
    item,
    type,
    auths,
    loaderFunction = undefined,
    onCreate,
    onSelect,
}) {
    const cxt = useContext(AppContext);

    // Create a new device auth and pass it to the onCreate callback
    function create() {
        if (typeof onCreate === 'function') {
            onCreate({
                [type]: item.id,
                dateini: item.ini ?? item.dateini,
                dateend: item.end ?? item.dateend,
                timeini: '08:00:00', // default value
                timeend: '22:00:00',
                weekdays: [0, 1, 2, 3, 4, 5, 6],
            });
        }
    }

    // Select one row in the list
    function select(row) {
        if (typeof onSelect === 'function') {
            onSelect(row);
        }
    }

    return (
        <>
            <SttTopControls
                mb={2}
                mainAction={{
                    type: 'create',
                    onClick: create,
                }}
                menu={false}
            />

            <SttTable
                data={auths}
                onFetch={loaderFunction}
                onClickRow={(row) => select(row)}
                columns={[
                    {
                        title: cxt.t('Period'),
                        name: 'period',
                        type: 'period',
                        value: (row) => ({
                            ini: row.dateini,
                            end: row.dateend,
                        }),
                    },
                    {
                        title: cxt.t('Horary'),
                        name: 'schedule',
                        type: 'hmRange',
                        value: (row) => ({
                            ini: `2020-01-01 ${row.timeini}`,
                            end: `2020-01-01 ${row.timeend}`,
                        }),
                    },
                    {
                        title: cxt.t('Devices'),
                        name: 'devices',
                        value: (row) => (
                            <div>
                                {row.devices &&
                                    row.devices.map((device) => (
                                        <Chip
                                            key={device.id}
                                            label={device.name}
                                            size="small"
                                            style={{
                                                marginLeft: 4,
                                                marginRight: 4,
                                            }}
                                        />
                                    ))}
                            </div>
                        ),
                    },
                    {
                        title: cxt.t('WeekDays'),
                        name: 'weekdays',
                        type: 'weekdays',
                        value: (row) => row.weekdays,
                    },
                ]}
            />
        </>
    );
}
