import React from 'react';
import {
    Avatar,
    Box,
    Button,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    makeStyles,
    Paper,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
import { Alert, AlertTitle } from '@material-ui/lab';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import { useTranslations } from '../../../lib/hooks';
import { SttFullDialog } from '../../../sporttia/dialogs/SttFullDialog';

export default function GolfBookingCardDialog({ id, onClose }) {
    const classes = useStyles();
    const { translate } = useTranslations();
    const [paymentForm, setPaymentForm] = React.useState('TPV');

    const handleChange = (event) => {
        console.log(`TEST cambia: ${JSON.stringify(event.target.value)}`);
        setPaymentForm(event.target.value);
    };

    return (
        <SttFullDialog
            open={id !== null}
            onClose={onClose}
            title={translate('Booking')}
            bottomContent={
                <Container>
                    <Grid container spacing={1} justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <Box
                                display="flex"
                                flexGrow={1}
                                justifyContent="flex-start"
                                alignItems="center"
                                flexDirection="row"
                            >
                                <Typography variant="h6">Total:</Typography>
                                <Box marginLeft={1}>
                                    <Typography
                                        variant="h5"
                                        style={{
                                            color: 'green',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        200€
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid container item spacing={1} xs={12} md={8}>
                            <Grid item>
                                <Box
                                    p={1}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    className={`${classes.formPaymentCard} ${classes.hoverCard}`}
                                >
                                    <Grid container spacing={1}>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <CreditCardOutlinedIcon />
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="caption"
                                                align="center"
                                            >
                                                Tarj. crédito/débito
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box
                                    p={1}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    className={`${classes.formPaymentCard} ${classes.hoverCard}`}
                                >
                                    <Grid container spacing={1}>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <AccountBalanceWalletOutlinedIcon />
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="caption"
                                                align="center"
                                            >
                                                Monedero (22.5€)
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box
                                    p={1}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    className={`${classes.formPaymentCard} ${classes.hoverCard}`}
                                >
                                    <Grid container spacing={1}>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <LocalAtmOutlinedIcon />
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="caption"
                                                align="center"
                                            >
                                                Metálico
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            }
        >
            <Container>
                <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12} md={8}>
                        <Paper>
                            <Box p={2}>
                                <Box>
                                    <Typography variant="h6">
                                        Reserva 18 hoyos golf - Salida Tee 1
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        style={{
                                            color: 'red',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Lunes 21 Abri. 10:00
                                    </Typography>
                                    <Typography variant="caption">
                                        Club de Golf La Cartuja
                                    </Typography>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <Paper>
                            <Box display="flex" flexWrap="wrap" p={2}>
                                <Box p={1} m={1} className={classes.userCard}>
                                    <Grid
                                        container
                                        spacing={1}
                                        className={classes.hoverCard}
                                    >
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Avatar>H</Avatar>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Box style={{ maxWidth: 170 }}>
                                                <Typography noWrap>
                                                    Carlos Garcia Garcia JOSE
                                                    MANUEL PRUEBA
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="caption"
                                                noWrap
                                            >
                                                Tarifa jubilado, 50€
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={1}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <IconButton
                                                style={{ color: '#957547' }}
                                            >
                                                <DeleteOutlineOutlinedIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box p={1} m={1} className={classes.userCard}>
                                    <Grid
                                        container
                                        spacing={1}
                                        className={classes.hoverCard}
                                    >
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Avatar>H</Avatar>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Box style={{ maxWidth: 170 }}>
                                                <Typography noWrap>
                                                    Carlos Garcia Garcia JOSE
                                                    MANUEL PRUEBA
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="caption"
                                                noWrap
                                            >
                                                Tarifa jubilado, 50€
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={1}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <IconButton
                                                style={{ color: '#957547' }}
                                            >
                                                <DeleteOutlineOutlinedIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box p={1} m={1} className={classes.userCard}>
                                    <Grid
                                        container
                                        spacing={1}
                                        className={classes.hoverCard}
                                    >
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Avatar>H</Avatar>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Box style={{ maxWidth: 170 }}>
                                                <Typography noWrap>
                                                    Carlos Garcia Garcia JOSE
                                                    MANUEL PRUEBA
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="caption"
                                                noWrap
                                            >
                                                Tarifa jubilado, 50€
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={1}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <IconButton
                                                style={{ color: '#957547' }}
                                            >
                                                <DeleteOutlineOutlinedIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box
                                    p={1}
                                    m={1}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    className={`${classes.userAddCard} ${classes.hoverCard}`}
                                >
                                    <Grid container spacing={1}>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <AddCircleOutlineOutlinedIcon />
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography noWrap>
                                                Añadir usuario
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <Paper>
                            <Box display="flex" flexWrap="wrap" p={2}>
                                <Box p={1} m={1} className={classes.userCard}>
                                    <Grid container spacing={2}>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <LocalShippingOutlinedIcon />
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Box style={{ maxWidth: 170 }}>
                                                <Typography noWrap>
                                                    Carrito de golf
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="caption"
                                                noWrap
                                            >
                                                50€ / ud.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={1}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid
                                            container
                                            item
                                            xs={5}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <IconButton
                                                style={{ color: '#957547' }}
                                            >
                                                <RemoveOutlinedIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={2}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography variant="caption">
                                                2
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={5}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <IconButton
                                                style={{ color: '#957547' }}
                                            >
                                                <AddOutlinedIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box p={1} m={1} className={classes.userCard}>
                                    <Grid container spacing={2}>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <LocalShippingOutlinedIcon />
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography noWrap>
                                                Carrito de golf
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="caption"
                                                noWrap
                                            >
                                                50€ / ud.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={1}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid
                                            container
                                            item
                                            xs={5}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <IconButton
                                                style={{ color: '#957547' }}
                                            >
                                                <RemoveOutlinedIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={2}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography variant="caption">
                                                2
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={5}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <IconButton
                                                style={{ color: '#957547' }}
                                            >
                                                <AddOutlinedIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box p={1} m={1} className={classes.userCard}>
                                    <Grid container spacing={2}>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <LocalShippingOutlinedIcon />
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography noWrap>
                                                Carrito de golf
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="caption"
                                                noWrap
                                            >
                                                50€ / ud.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={1}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid
                                            container
                                            item
                                            xs={5}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <IconButton
                                                style={{ color: '#957547' }}
                                            >
                                                <RemoveOutlinedIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={2}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography variant="caption">
                                                2
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={5}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <IconButton
                                                style={{ color: '#957547' }}
                                            >
                                                <AddOutlinedIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box
                                    p={1}
                                    m={1}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    className={`${classes.userAddCard} ${classes.hoverCard}`}
                                >
                                    <Grid container spacing={1}>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <AddCircleOutlineOutlinedIcon />
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography noWrap>
                                                Añadir servicio
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <Alert severity="info">
                            <AlertTitle>
                                Política de venta y cancelación
                            </AlertTitle>
                            Servicio Municipal de Deportes Sporttia, B90061284 ,
                            , Yecla , (Murcia), España Sólo se admiten
                            cancelaciones por inclemencias climatológicas y la
                            devolución del importe pagado por parte del usuario
                            será al monedero virtual del propio usuario.
                        </Alert>
                    </Grid>
                </Grid>
            </Container>
        </SttFullDialog>
    );
}

const useStyles = makeStyles((theme) => ({
    smallRadioButton: {
        '& svg': {
            width: '0.75em',
            height: '0.75em',
        },
    },
    hoverCard: {
        borderRadius: '5px',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgba(33, 150, 243, 0.5)',
        },
    },
    userCard: {
        // width: 179,
        height: 167,
        color: '#957547',
        // border: '2px solid #E0E0E0',
        borderRadius: '5px',
        backgroundColor: '#FFF4E5',
        flex: 1,
        // [theme.breakpoints.up("xs")]: {
        //     //maxWidth: '100%',
        // },
        // [theme.breakpoints.up("sm")]: {
        //     maxWidth: 179,
        // },
        // [theme.breakpoints.up("md")]: {
        //     width: 179,
        // },
        // [theme.breakpoints.up("lg")]: {
        //     width: 179,
        // }
    },
    userAddCard: {
        flex: 1,
        // width: 179,
        height: 167,
        color: '#2D532F',
        borderRadius: '5px',
        backgroundColor: '#EDF7ED',
    },
    formPaymentCard: {
        width: 100,
        height: 80,
        color: '#014361',
        borderRadius: '5px',
        backgroundColor: '#E5F6FD',
    },
}));
