import React, { useContext } from 'react';
import { Chip, Avatar } from '@material-ui/core';
import { AppContext } from './all';

export function SttSportChips({ sports }) {
    const cxt = useContext(AppContext);

    // -----| Render |-----
    return (
        <>
            {sports &&
                sports.map((sport, idx) => (
                    <Chip
                        style={{ marginRight: 3, marginBottom: 3 }}
                        key={idx}
                        avatar={
                            <Avatar
                                style={{ backgroundColor: 'white' }}
                                alt={cxt.t(`sport.${sport.id}`)}
                                src={cxt.getResource(`/sports/${sport.id}.png`)}
                            />
                        }
                        label={cxt.t(`sport.${sport.id}`)}
                    />
                ))}
        </>
    );
}
