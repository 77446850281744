import React, { useState, useEffect, useContext } from 'react';
import { IconButton } from '@material-ui/core';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { AppContext } from '../all';

/**
 *	Button following
 *	================
 *
 * Props
 *
 * 	* boolean isFollowing
 *	* function onToggleFollowing(boolean)
 */
export function SttButtonFollow({ isFollowing, onToggleFollowing }) {
    const cxt = useContext(AppContext);

    // -----| Render |-----
    return (
        <IconButton
            onClick={() => onToggleFollowing(!isFollowing)}
            style={{
                color: '#a800ff8a',
            }}
        >
            {isFollowing ? (
                <FavoriteIcon fontSize="large" />
            ) : (
                <FavoriteBorderIcon fontSize="large" />
            )}
        </IconButton>
    );
}
