import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import { AppContext } from '../../../sporttia/AppContext';
import { SttNoResults } from '../../../sporttia/SttNoResults';
import { SttButtonSave } from '../../../sporttia/buttons/SttButtonSave';
import { SttSportCard } from '../../../sporttia/public/cards/SttSportCard';
import { SttSelectSportDialog } from '../../../sporttia/dialogs/SttSelectSportDialog';

export default function UserSports({ userSportsList, onUpdate }) {
    const cxt = useContext(AppContext);
    const [userSports, setUserSports] = useState(userSportsList);
    const [openSportSelector, setOpenSportSelector] = useState(false);

    useEffect(() => {
        setUserSports(userSportsList);
    }, [userSportsList]);

    const addSport = (sport) => {
        cxt.api('POST', `/my/sports/${sport.id}`, {
            success: (r) => {
                onUpdate();
            },
        });
    };

    const deleteSport = (sportId) => {
        cxt.api('DELETE', `/my/sports/${sportId}`, {
            success: (r) => {
                onUpdate();
            },
        });
    };

    return (
        <>
            <Box mb={3}>
                <Grid container justifyContent="flex-end">
                    <SttButtonSave
                        caption={cxt.t('Add')}
                        style={{ maxWidth: '100px' }}
                        onClick={() => setOpenSportSelector(true)}
                    />
                </Grid>
            </Box>
            <Box>
                <Grid container spacing={3}>
                    {userSports && userSports.length > 0 ? (
                        userSports.map((userSport, i) => (
                            <React.Fragment key={i}>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <Box key={userSport.id}>
                                        <SttSportCard
                                            sport={userSport}
                                            onDelete={deleteSport}
                                        />
                                    </Box>
                                </Grid>
                            </React.Fragment>
                        ))
                    ) : (
                        <SttNoResults paper />
                    )}
                </Grid>
            </Box>
            <SttSelectSportDialog
                open={openSportSelector}
                onSelected={(sport) => addSport(sport)}
                onClose={() => setOpenSportSelector(false)}
            />
        </>
    );
}
