import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

export function SttLabelLink({
    grid = false,
    xs = 12,
    title,
    text,
    to = '#',
    ...rest
}) {
    const content = (
        <div>
            <div>
                <Typography variant="caption">{title}</Typography>
            </div>
            <Link to={to}>{text}</Link>
        </div>
    );

    return grid ? (
        <Grid item xs={xs} {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
