import React, { useState, useEffect, useContext } from 'react';
import { Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
    AppContext,
    SttButtonFab,
    SttTable,
    SttTopControls,
} from '../../../sporttia/all';
import { useToggle, useTuples, useCrud } from '../../../lib/hooks';
import { getPath } from '../../Pages';

export default function Students() {
    const cxt = useContext(AppContext);
    const history = useHistory();

    const [students, setStudents, , Get] = useCrud();
    const [filters, setFilter] = useTuples({
        name: '',
    });
    const [trash, toggleTrash] = useToggle(() => {
        loadStudents();
    });

    useEffect(() => {
        cxt.setHeader({ title: cxt.t('Students') });
    }, []);

    function loadStudents(params = { rows: 20 }) {
        // Get(`/scs/${cxt.sc.id}/students`, {...params, ...filters, trash}).then(setStudents);
    }

    function goCreateNew() {
        // TODO: Put proper path ID here (likey singular)
        history.push(getPath('students', { id: 'create' }));
    }

    return (
        <Paper>
            <SttTopControls
                p={2}
                fields={[
                    {
                        caption: cxt.t('Name'),
                        type: 'text',
                        name: 'name',
                        value: filters.name,
                        onEnterKey: loadStudents,
                    },
                ]}
                onToggleTrash={toggleTrash}
                onChange={setFilter}
                onFilter={loadStudents}
            />
            <SttTable
                onFetch={loadStudents}
                data={students}
                columns={[
                    {
                        title: cxt.t('Name'),
                        type: 'text',
                        field: 'name',
                    },
                ]}
            />
            <SttButtonFab onClick={goCreateNew} />
        </Paper>
    );
}
