import React from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ButtonProps } from '@material-ui/core';
import { SttButton } from '../all';
import { useTranslations } from '../../lib/hooks';

type SttButtonRefreshProps = {
    onClick: () => void;
    caption?: string;
} & ButtonProps;

/**
 * Refresh button
 */
export function SttButtonRefresh({
    onClick,
    caption,
    ...rest
}: SttButtonRefreshProps) {
    const { translate } = useTranslations();

    return (
        <SttButton
            variant="text"
            onClick={onClick}
            startIcon={<RefreshIcon />}
            color="primary"
            caption={caption || translate('Refresh')}
            {...rest}
        />
    );
}
