import React, { useContext, useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import SttValidatedForm from '../../sporttia/SttValidatedForm';
import { SttLabelForm } from '../../sporttia/labels/SttLabelForm';
import { AppContext } from '../../sporttia/AppContext';
import { SttDialog } from '../../sporttia/dialogs/SttDialog';
import useSubscriptionsService from '../../services/SubscriptionsService';
import { useLoader, useTranslations } from '../../lib/hooks';
import constants from '../../config/constants';

/**
 * Modal to modify and delete licenses.
 * @param licence Licence object.
 * @param onClose Action taken when closing the modal.
 * @param onDelete Action taken when removing a license.
 * @param onUpdate Action taken when updating a license.
 * @returns {JSX.Element}
 */
export default function LicenceModal({ licence, onClose, onDelete, onUpdate }) {
    const cxt = useContext(AppContext);
    const form = useForm();
    const subscriptionsService = useSubscriptionsService();
    const { translate } = useTranslations();
    const deleteMutation = useMutation(
        (params) => subscriptionsService.removeSubscriptionLicenses(params?.id),
        {
            onSuccess: onDelete,
        },
    );
    const updateMutation = useMutation(
        (params) =>
            subscriptionsService.updateSubscriptionLicenses(
                params?.id,
                params?.params,
            ),
        {
            onSuccess: onUpdate,
        },
    );
    const [, loader] = useLoader([
        deleteMutation.status,
        updateMutation.status,
    ]);

    useEffect(() => {
        form.reset({ ...licence, paymentForm: licence?.paymentForm || '' });
    }, [licence, form]);

    // -----| Render |-----
    return (
        <SttDialog
            title={cxt.t('Collection')}
            open={licence !== null}
            onClose={onClose}
            maxWidth="md"
            content={
                <Paper>
                    {loader}
                    <Box m={2}>
                        {licence && (
                            <Grid container spacing={3}>
                                <SttLabelForm
                                    grid
                                    title={cxt.t('Created')}
                                    xs={12}
                                    sm={4}
                                    md={3}
                                    text={moment(licence.created).format(
                                        'D MMM YYYY, H:mm',
                                    )}
                                />

                                <SttLabelForm
                                    grid
                                    title={cxt.t('Status')}
                                    xs={12}
                                    sm={4}
                                    md={3}
                                    text={
                                        licence.paymentForm ||
                                        licence.status ===
                                            constants.payment.status.notPaid
                                            ? translate(
                                                  `PF.${licence.paymentForm}`,
                                              )
                                            : cxt.t('Pending')
                                    }
                                />
                            </Grid>
                        )}

                        <Grid container spacing={3}>
                            <SttValidatedForm
                                form={form}
                                fields={[
                                    {
                                        type: 'price',
                                        name: 'price',
                                        caption: cxt.t('price'),
                                        sm: 3,
                                    },
                                    {
                                        type: 'select',
                                        name: 'paymentForm',
                                        caption: cxt.t('Type'),
                                        options: cxt.constants.paymentForms.map(
                                            (item) => ({
                                                caption: item.label,
                                                value: item.id,
                                            }),
                                        ),
                                        disableClearable: true,
                                        sm: 3,
                                    },
                                    {
                                        type: 'date',
                                        name: 'ini',
                                        caption: cxt.t('PeriodFrom'),
                                        disabled: true,
                                        sm: 3,
                                    },
                                    {
                                        type: 'date',
                                        name: 'end',
                                        caption: cxt.t('Until'),
                                        disabled: true,
                                        sm: 3,
                                    },
                                ]}
                            />
                        </Grid>
                    </Box>
                </Paper>
            }
            buttons={[
                {
                    type: 'delete',
                    confirmation: true,
                    onClick: () => deleteMutation.mutate({ id: licence?.id }),
                },
                {
                    type: 'save',
                    onClick: form.handleSubmit((formData) =>
                        updateMutation.mutate({
                            id: licence?.id,
                            params: {
                                price: formData.price,
                                paymentForm: formData.paymentForm,
                            },
                        }),
                    ),
                },
            ]}
        />
    );
}
