import React, { useState, useEffect, useContext } from 'react';
import { AppContext, SttPrivilegeList } from '../../../sporttia/all';

/**
 * Privileges for the user group
 * Group item - A user group as obtained in /group/Group.js
 */
export default function GroupPrivileges({ item }) {
    const [privileges, setPrivileges] = useState([]);

    const cxt = useContext(AppContext);

    // Load privilege list on 'item' change
    useEffect(() => {
        if (item) {
            loadPrivileges();
        }
    }, [item]);

    // Load privileges from API
    function loadPrivileges() {
        cxt.api('GET', `/groups/${item.id}/privileges`, {
            params: {
                includeInactive: 1,
            },
            success: (response) => {
                if (response.rows) {
                    setPrivileges(response.rows);
                }
            },
            error: console.log,
        });
    }

    // Set the privilege to <bool>value
    function togglePrivilege({ id, value }) {
        const privilege = privileges.find((prv) => prv.id === id);

        if (!privilege) {
            return;
        }

        // Asynchronous local update for smoothness. The privilege is not successfully altered until the API call is executed, though
        updateLocally({ id, value });

        if (value) {
            cxt.api('POST', `/groups/${item.id}/privileges/${id}`);
        } else {
            cxt.api('DELETE', `/groups/${item.id}/privileges/${id}`);
        }
    }

    // Update the list locally to display purported changes
    function updateLocally({ id, value }) {
        const privs = [...privileges];
        const privilege = privs.find((prv) => prv.id === id);
        privs.splice(privs.indexOf(privilege), 1, {
            ...privilege,
            active: value,
        });
        setPrivileges(privs);
    }

    return (
        <SttPrivilegeList
            privileges={privileges}
            onToggle={togglePrivilege}
            title={cxt.t('Privilege')}
        />
    );
}
