import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import { useTranslations } from '../../lib/hooks';

type SttButtonTicketProps = {
    caption?: string;
    block?: boolean;
    onClick: () => void;
} & ButtonProps;

/**
 * Ticket button
 */
export function SttButtonTicket({
    caption,
    block,
    onClick,
    ...rest
}: SttButtonTicketProps) {
    const { translate } = useTranslations();

    return (
        <Button
            {...rest}
            variant="contained"
            color="primary"
            fullWidth={block}
            startIcon={<ConfirmationNumberIcon />}
            onClick={onClick}
        >
            {caption || translate('Ticket')}
        </Button>
    );
}
