import { useContext } from 'react';
import { useAPI } from '../lib/hooks';
import useRestService from './RestService';
import { AppContext } from '../sporttia/AppContext';

const resource = '/timezones';

/**
 * Hook to access all 'subscriptions' resources.
 */
export default function useTimezoneService() {
    const { migrationApis } = useContext(AppContext)!;
    const [request] = useAPI(migrationApis);
    const services = useRestService(resource);

    /**
     * GET /timezones.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function getTimezones() {
        return request('GET', `${resource}`);
    }

    return {
        ...services,
        getTimezones,
    };
}
