import GroupMember from '../../../types/models/GroupMember';
import { formatPriceByLocale } from '../../../lib/utils';

export function getFormattedEnrollmentFromAbono(row: GroupMember) {
    if (row.enrollment) {
        return formatPriceByLocale(
            row.enrollment.price,
            row.enrollment.priceShape?.currency ?? undefined,
            row.enrollment.priceShape?.locale,
        );
    }

    return null;
}

export function getFormattedFeeFromAbono(row: GroupMember) {
    if (row.group?.fee) {
        return formatPriceByLocale(
            row.group.fee,
            row.group.feeShape?.currency ?? undefined,
            row.group.feeShape?.locale,
        );
    }

    return null;
}
