import React, { useContext, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { useQueryClient } from 'react-query';
import { AppContext } from '../../../../sporttia/AppContext';
import AdminUser from './AdminUser';
import { copyToClipboard } from '../../../../lib/utils';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';
import { SUPERADMIN_USERS } from '../../../../lib/queryKeys';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import useUsersService from '../../../../services/UsersService';
import { useQueryFilters } from '../../../../lib/hooks';

/**
 * View where the sporttia superadmin can see the list of users.
 * @returns {JSX.Element}
 */
export default function AdminUsers() {
    const cxt = useContext(AppContext);
    const queryClient = useQueryClient();
    const usersService = useUsersService();
    const [userId, setUserId] = useState(null);
    const [filters, setFilters] = useQueryFilters({}, SUPERADMIN_USERS);

    return (
        <Paper>
            <SttTopFilteringControls
                p={2}
                extraFields={[
                    {
                        caption: 'Fullname',
                        name: 'fullname',
                        value: '',
                    },
                    {
                        caption: 'idUser',
                        name: 'idUser',
                        value: '',
                    },
                    {
                        caption: 'login',
                        name: 'login',
                        value: '',
                    },
                    {
                        caption: 'status',
                        name: 'status',
                        value: '',
                    },
                    {
                        caption: 'dni',
                        name: 'dni',
                        value: '',
                    },
                    {
                        caption: 'phone',
                        name: 'phone',
                        value: '',
                    },
                    {
                        caption: 'rol',
                        name: 'privilege',
                        value: '',
                    },
                    {
                        caption: 'email',
                        name: 'email',
                        value: '',
                    },
                    {
                        caption: 'created',
                        name: 'created',
                        value: '',
                    },
                    {
                        caption: 'followingSCName',
                        name: 'followingSCName',
                        value: '',
                    },
                    {
                        caption: 'followingCityName',
                        name: 'followingCityName',
                        value: '',
                    },
                    {
                        caption: 'isActive',
                        name: 'isActive',
                        type: 'check',
                        value: '',
                    },
                ]}
                trashAction
                onFilter={setFilters}
            />

            <SttCachedTable
                queryKey={SUPERADMIN_USERS}
                queryFn={(params) => usersService.getList(params)}
                queryParams={filters}
                queryOptions={{ staleTime: 60000 * 5 }}
                columns={[
                    {
                        title: 'Id',
                        type: 'tooltip',
                        value: (row) => ({ label: row.id, value: row.id }),
                        onClick: (row) =>
                            copyToClipboard(row.id, () =>
                                cxt.showMessage(
                                    'S',
                                    cxt.t('copiedToClipboard'),
                                ),
                            ),
                    },
                    {
                        title: cxt.t('created'),
                        align: 'left',
                        type: 'dmy',
                        field: 'created',
                    },
                    {
                        title: cxt.t('login'),
                        field: 'login',
                        type: 'link',
                        onClick: (row) => setUserId(row.id),
                    },
                    {
                        title: cxt.t('name'),
                        field: 'fullName',
                    },
                    {
                        title: cxt.t('role'),
                        field: 'role',
                    },
                    {
                        title: cxt.t('status'),
                        field: 'status',
                    },
                    {
                        title: cxt.t('dni'),
                        field: 'dni',
                    },
                    {
                        title: cxt.t('email'),
                        field: 'email',
                    },
                    {
                        title: cxt.t('gender'),
                        field: 'gender',
                    },
                    {
                        title: cxt.t('phone'),
                        field: 'mobile',
                    },
                ]}
            />

            <AdminUser
                id={userId}
                onClose={() => setUserId(null)}
                onCreate={() => queryClient.invalidateQueries(SUPERADMIN_USERS)}
                onUpdate={() => queryClient.invalidateQueries(SUPERADMIN_USERS)}
                onDelete={() => {
                    setUserId(null);
                    queryClient.invalidateQueries(SUPERADMIN_USERS);
                }}
            />
        </Paper>
    );
}
