import React, { useState, useContext } from 'react';
import { AppContext } from '../AppContext';
import { SttList } from './SttList';
import { SttSelectGroupDialog } from '../all';
import translations from '../../translations';

export function SttGroupList({ groups, onAdd, onDelete }) {
    const cxt = useContext(AppContext);

    const [selectionOpen, setSelectionOpen] = useState(false);

    function openAddTerrain() {
        setSelectionOpen(true);
    }

    function selectGroup(group) {
        setSelectionOpen(false);
        if (!groups.find((g) => g.id === group.id)) {
            onAdd(group);
        }
    }

    return (
        <>
            <SttList
                title={cxt.t(translations.generic.groups)}
                onAdd={openAddTerrain}
                onDelete={onDelete}
                data={groups?.map((group) => ({
                    caption: group.name,
                    objToSelect: group,
                }))}
            />

            {selectionOpen && (
                <SttSelectGroupDialog
                    open
                    types=""
                    ontime
                    onlyActive
                    onClose={() => setSelectionOpen(false)}
                    onSelect={(group) => selectGroup(group)}
                />
            )}
        </>
    );
}
