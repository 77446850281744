import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import { useHistory } from 'react-router-dom';
import { AppContext, SttButton } from '../sporttia/all';
import coffeeImage from '../images/coffee.svg';
import { getPath } from './Pages';

export default function Page401(props) {
    const cxt = useContext(AppContext);
    const history = useHistory();

    return (
        <Box m={8} align="center">
            <img src={coffeeImage} width={230} />

            <h1>Página protegida</h1>
            <p style={{ color: '#888' }}>
                Lo sentimos pero no tienes permisos para acceder a esta página.
            </p>
            <Box mt={5}>
                <SttButton
                    caption={cxt.t('BackIndexPage')}
                    icon={<HomeIcon />}
                    onClick={() => history.push(getPath('loadingSporttia'))}
                />
            </Box>
        </Box>
    );
}
