import React, { useEffect, useContext, useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import Link from '@material-ui/core/Link';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import DoneOutlineOutlinedIcon from '@material-ui/icons/DoneOutlineOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { AppContext } from '../../../sporttia/all';
import { getPath } from '../../Pages';
import {
    useCountDownTimer,
    useLoader,
    useTranslations,
} from '../../../lib/hooks';
import useTpvsService from '../../../services/TpvsService';
import { AlertDialog } from '../../../components/dialogs/AlertDialog';
import { formatPrice } from '../../../lib/utils';

const useStyles = makeStyles(() => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '120vh',
    },
    iframe: {
        height: '100%',
        border: 0,
        flex: 1,
        display: 'flex',
    },
    titleRow: {
        display: 'flex',
        justifyContent: 'center',
    },
    timer: {
        fontSize: 18,
    },
}));

/**
 * Page used to make payments with POS, an iframe appears with the virtual POS of the corresponding bank and a countdown.
 * @returns {JSX.Element}
 */
export default function TPVSwitcherLegacy({ data }) {
    const cxt = useContext(AppContext);
    const { translate } = useTranslations();
    const history = useHistory();
    const classes = useStyles();
    const tpvsService = useTpvsService();
    const [tpvsLoads, setTpvLoads] = useState(0);
    const [tokenizedPaycomet, setTokenizedPaycomet] = useState(false);
    const [termsAndConditions, setTermsAndConditions] = useState(false);

    /*
    const {
        formatTime: sporttiaRedirectTime,
        toggleTimer: sporttiaRedirectToggleTimer,
    } = useCountDownTimer({
        initialSeconds: 10,
        pause: true,
        onTimeOut: () => history.push('/'),
    });
    */

    /*    const { formatTime, timeOut, toggleTimer } = useCountDownTimer({
        initialSeconds: cxt.curtesyMinutesForTPV * 60,
        pause: true,
        onTimeOut: () => sporttiaRedirectToggleTimer(),
    }); */

    const updateTpvPayMutation = useMutation(
        (params) => tpvsService.updateTpvPay(params?.id, params?.params),
        {
            onSettled: (response) => {
                if (response.paycomet?.challengeUrl) {
                    window.location.href = response.paycomet?.challengeUrl;
                } else {
                    history.push(getPath('tpvResult', { id: data.tpv.id }));
                }
            },
        },
    );
    const [, loader] = useLoader([updateTpvPayMutation.status]);

    /**
     * Returns the color of the background according to the type of POS to paint the background of the meter
     * and make it appear embedded in the virtual POS.
     * @returns {string} String color.
     */
    const getBackgroundColorByTpvConf = () => {
        switch (data.tpv?.tpvConf?.bank) {
            case 'REDSYS_HMAC256':
                return '#F8F8F8';
            case 'PAYCOMET':
            case 'CCM':
            default:
                return 'white';
        }
    };

    /**
     * If the POS value obtained from the back-end is a form, the form is sent and the result is painted inside
     * the iframe (Ceca Bank and Redsys). In the case that it has the value of paycomet directly, an attribute
     * "src" is added with the link of paycomet to the iframe.
     */
    useEffect(() => {
        if (data.form) {
            document
                .getElementById('tpv_form')
                .setAttribute('target', 'iframe');
            document.getElementById('tpv_form').submit();
            // toggleTimer();
            setTermsAndConditions(true);
        } else if (data.paycomet) {
            if (cxt?.user?.paycometIdUser) {
                setTokenizedPaycomet(true);
            } else {
                document
                    .getElementById('tpvIframe')
                    .setAttribute('src', data.paycomet?.challengeUrl);
                // toggleTimer();
                setTermsAndConditions(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Trick so that if the user clicks more than 2 times inside the iframe (they are actually redirects) the counter stops.
     */
    useEffect(() => {
        if (tpvsLoads === 2) {
            // toggleTimer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tpvsLoads]);

    return (
        <>
            {loader}
            <Box
                display="flex"
                flexDirection="column"
                style={{
                    height: '100vh',
                    backgroundColor: getBackgroundColorByTpvConf(),
                }}
            >
                {/*! timeOut && tpvsLoads < 2 && !tokenizedPaycomet && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                    >
                        <Typography variant="subtitle1" gutterBottom>
                            {translate('tpvPress')}&nbsp;
                            <Link href="/">{translate('here')}</Link>
                            &nbsp;{translate('tpvCountDownTimerText')}
                        </Typography>
                        <Typography variant="h6">{formatTime}</Typography>
                    </Box>
                ) */}
                {
                    <Box display="flex" flex={1}>
                        {/* Cogemos el formulario que devuelve GET /tpvs/:id y lo dibujamos de forma oculta,
                                    este formulario será enviar y realiza una petición POST que reenvia a la web del
                                    banco que es la que pintamos en el iframe. */}
                        <div
                            style={{ display: 'none' }}
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: data.form,
                            }}
                        />

                        {/* Creo un iframe donde se va a pintar la página devuelta al enviar el POST del
                                    formulario del banco. */}
                        <iframe
                            title="tpvIframe"
                            id="tpvIframe"
                            name="iframe"
                            className={classes.iframe}
                            onLoad={() => setTpvLoads(tpvsLoads + 1)}
                        />
                    </Box>
                }
                {/*

                    timeOut && (
                        <AlertDialog
                            title={translate('exhaustedPaymentTime')}
                            open
                            buttons={[
                                {
                                    icon: <ExitToAppOutlinedIcon />,
                                    caption: translate('backToSporttia'),
                                    onClick: () => history.push('/'),
                                },
                            ]}
                            subtitle={
                                <>
                                    {translate('countdownTpvMessage')}{' '}
                                    <strong>{sporttiaRedirectTime}</strong>
                                </>
                            }
                        />
                    )
                        */}
                {
                    /* Dialog to confirm the payment with paycomet for those users who are tokenized. */
                    tokenizedPaycomet && (
                        <AlertDialog
                            title={translate('payWithPaycomet')}
                            open
                            buttons={[
                                {
                                    icon: <ExitToAppOutlinedIcon />,
                                    caption: translate('backToSporttia'),
                                    onClick: () => history.push('/'),
                                },
                                {
                                    icon: <DoneOutlineOutlinedIcon />,
                                    caption: translate('pay'),
                                    onClick: () =>
                                        updateTpvPayMutation.mutate({
                                            id: data.tpv.id,
                                        }),
                                },
                            ]}
                            subtitle={
                                <>
                                    <Box marginBottom={1}>
                                        {cxt.t('paycometConfirmationText')}
                                        <strong>
                                            {' '}
                                            {formatPrice(data.tpv?.price)}
                                        </strong>
                                    </Box>

                                    <Box>
                                        {translate('IHaveReadAndAccept')}&nbsp;
                                        <Link
                                            onClick={() =>
                                                window
                                                    .open(
                                                        'https://www.sporttia.com/es/condiciones-generales-de-contratacion/',
                                                        '_blank',
                                                    )
                                                    .focus()
                                            }
                                        >
                                            {translate('TermsAndConditions')}
                                        </Link>
                                        .
                                    </Box>
                                </>
                            }
                        />
                    )
                }
                {
                    /* Dialog to confirm conditions and terms. */
                    termsAndConditions && (
                        <AlertDialog
                            title={translate('TermsAndConditions')}
                            open
                            buttons={[
                                {
                                    icon: <CloseOutlinedIcon />,
                                    caption: 'Denegar',
                                    onClick: () => history.push('/'),
                                },
                                {
                                    icon: <DoneOutlineOutlinedIcon />,
                                    caption: 'Aceptar',
                                    onClick: () => {
                                        setTermsAndConditions(false);
                                    },
                                },
                            ]}
                            subtitle={
                                <>
                                    {translate('IHaveReadAndAccept')}&nbsp;
                                    <Link
                                        onClick={() =>
                                            window
                                                .open(
                                                    'https://www.sporttia.com/es/condiciones-generales-de-contratacion/',
                                                    '_blank',
                                                )
                                                .focus()
                                        }
                                    >
                                        {translate('TermsAndConditions')}
                                    </Link>
                                </>
                            }
                        />
                    )
                }
            </Box>
        </>
    );
}
