import React, { useState } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { Done as DoneIcon } from '@material-ui/icons';
import ModalConfirmation from '../../layout/ModalConfirmation';
import { useTranslations } from '../../lib/hooks';

type SttButtonAcceptProps = {
    caption?: string;
    onClick: () => void;
    confirmation?: boolean;
} & ButtonProps;

/**
 * Accept button
 */
export function SttButtonAccept({
    caption,
    onClick,
    confirmation = false,
    ...rest
}: SttButtonAcceptProps) {
    const { translate } = useTranslations();
    const [confirmationDialog, setConfirmationDialog] = useState({
        show: false,
    });

    return (
        <>
            <Button
                {...rest}
                variant="contained"
                color="primary"
                startIcon={<DoneIcon />}
                onClick={
                    confirmation
                        ? () => setConfirmationDialog({ show: true })
                        : onClick
                }
            >
                {caption || translate('Accept')}
            </Button>
            <ModalConfirmation
                show={confirmationDialog.show}
                onClose={() => setConfirmationDialog({ show: false })}
                onAccept={() => {
                    onClick();
                    setConfirmationDialog({ show: false });
                }}
            />
        </>
    );
}
