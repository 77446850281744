import { Box, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { AppContext, SttFormSwitch, SttLabelTitle } from '../all';

/**
 * Styled vertical list of privileges
 *
 * 	* Privileges[] privileges - list of objects as obtained from the API (or similar)
 * 	* Function onToggle({id, value}) - callback for each privilege row's switch
 */

export function SttPrivilegeList({
    privileges,
    onToggle,
    title,
    useHighlight = false,
    classes = null,
}) {
    const cxt = useContext(AppContext);
    const [option, setOption] = useState(null);

    return (
        <div>
            <Box
                pt={1}
                pb={1}
                mb={1}
                className={classes?.maxWidthList ? classes.maxWidthList : ''}
                display="flex"
                justifyContent="space-between"
                borderBottom="1px solid #f0f0f0"
            >
                <Box flex={1}>
                    <Typography style={{ fontSize: 20 }} variant="caption">
                        {title}
                    </Typography>
                </Box>
                <Box
                    minWidth={200}
                    flex={0}
                    display="flex"
                    justifyContent="center"
                >
                    <Typography style={{ fontSize: 20 }} variant="caption">
                        {cxt.t('Status')}
                    </Typography>
                </Box>
            </Box>
            <div>
                {privileges.map((privilege, index) => (
                    <Box
                        mb={2}
                        className={
                            classes?.maxWidthList ? classes.maxWidthList : ''
                        }
                        key={privilege.id}
                        display="flex"
                        onMouseEnter={() =>
                            useHighlight ? setOption(index) : null
                        }
                        onMouseLeave={() =>
                            useHighlight ? setOption(null) : null
                        }
                    >
                        <Box
                            flex={1}
                            className={
                                option === index && useHighlight
                                    ? classes.highlight
                                    : null
                            }
                        >
                            <SttLabelTitle>
                                {cxt.t(privilege.name)}
                            </SttLabelTitle>
                            <Typography color="textSecondary" variant="body2">
                                {privilege.description
                                    ? cxt.t(privilege.description)
                                    : ''}
                            </Typography>
                        </Box>
                        <Box
                            minWidth={200}
                            flex={0}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {/* TODO: Add a tooltip or something indicating that the privilege is enabled for the whole SC when it is so. */}
                            {privilege.isDefaultOnSC ? (
                                <Typography variant="body1">
                                    {cxt.t(
                                        'sc.groups.privileges.activeDefault',
                                    )}
                                </Typography>
                            ) : (
                                <SttFormSwitch
                                    color="primary"
                                    name={privilege.id}
                                    checked={privilege.active}
                                    onChange={({ name, value }) =>
                                        onToggle({ id: name, value })
                                    }
                                />
                            )}
                        </Box>
                    </Box>
                ))}
            </div>
        </div>
    );
}
