import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { usePageHeader } from '../../lib/hooks';

import { getPath } from '../Pages';

const menus = require('../../config/menus.json');

export default function AdminHome() {
    usePageHeader('Home');

    return (
        <Grid
            container
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            }}
        >
            {menus.admin &&
                menus.admin
                    .filter((item) => item?.submenus?.length > 0)
                    .map((item) => (
                        <Grid
                            item
                            xs={12}
                            md={3}
                            key={item.id}
                            style={{
                                margin: 25,
                            }}
                        >
                            <Card
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    height: '100%',
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        {item.caption}
                                    </Typography>
                                    <ul>
                                        {item?.submenus?.map((subitem) => (
                                            <li
                                                style={{ fontSize: 18 }}
                                                key={subitem.path}
                                            >
                                                <Link
                                                    to={getPath(subitem.path)}
                                                >
                                                    {subitem.caption}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
        </Grid>
    );
}
