import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getFormattedEnrollmentFromAbono } from './UserAbonoHelper';
import { AppContext, SttTable } from '../../../sporttia/all';
import { getPath } from '../../Pages';
/**
 * Table with "Abonados" or "Normal" groups.
 * @param type [ABONADOS or NORMAL] type.
 * @returns {JSX.Element}
 */

export default function UserAbonosTable({ type }) {
    const cxt = useContext(AppContext);
    const history = useHistory();

    const [groups, setGroups] = useState();

    /**
     * Load groups data.
     * @param p {{page: number, rows: number}}
     */
    const loadGroups = (p = { page: 1, rows: 20 }) => {
        cxt.api('GET', '/my/groups/members', {
            params: {
                ...p,
                type,
                onlyActive: true,
                onTime: true,
            },
            success: (r) => {
                setGroups(r);
            },
        });
    };

    return (
        <SttTable
            data={groups}
            loading={false}
            onClickRow={(groupMember) =>
                history.push(getPath('userAbono', { id: groupMember.id }))
            }
            columns={[
                {
                    title: cxt.t('Name'),
                    field: 'groupName',
                    align: 'left',
                    value: (row) => row.group.name,
                },
                {
                    title: cxt.t('Period'),
                    type: 'period',
                    value: (row) => ({ ini: row.ini, end: row.end }),
                },
                {
                    title: cxt.t('Inscription'),
                    type: 'price',
                    value: (row) => getFormattedEnrollmentFromAbono(row),
                },
            ]}
            onFetch={loadGroups}
        />
    );
}
