import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { SttTopControls } from '../../../sporttia/SttTopControls';
import { AppContext, SttTable } from '../../../sporttia/all';
import { useCrud, useToggle } from '../../../lib/hooks';
import ModalPeriod from './ModalPeriod';

export default function EventPeriods({ event }) {
    const cxt = useContext(AppContext);
    const [periods, setPeriods, , Get, Post, Put, Delete] = useCrud();
    const [period, setPeriod] = useState();
    const [trash, toggleTrash] = useToggle(() => {
        // loadPeriods
    });

    function loadPeriods(params = { page: 1, rows: 25 }) {
        Get(`/events/${event.id}/periods`).then((response) => {
            if (response.rows) {
                setPeriods(response);
            }
        });
    }

    function savePeriod(period) {
        if (period.id) {
            const { name, notes, idFacility, status } = period;
            Put(`/events/periods/${period.id}`, {
                name,
                notes,
                idFacility,
                status,
            })
                .then((response) => {
                    setPeriod(null);
                    loadPeriods();
                })
                .catch(console.log);
        } else {
            const { name, notes, idFacility, status, ini, end, weekdays } =
                period;
            Post(`/events/${event.id}/periods`, {
                name,
                notes,
                idFacility,
                status,
                ini,
                end,
                weekdays,
            })
                .then((response) => {
                    setPeriod(null);
                    loadPeriods();
                })
                .catch(console.log);
            /*
			idFacility, status, notes, ini, end, weekdays, name
			*/
        }
    }

    function deletePeriod(period) {
        Delete(`/events/periods/${period.id}`)
            .then((response) => {
                setPeriod(null);
                loadPeriods();
            })
            .catch(console.log);
    }

    return (
        <>
            <SttTopControls
                mainAction={{
                    type: 'create',
                    caption: cxt.t('Create'),
                    onClick: () => {
                        setPeriod({
                            name: event.name,
                            ini: event.ini,
                            end: event.end,
                            weekdays: [],
                        });
                    },
                }}
                // onToggleTrash={toggleTrash}
                pl={0}
            />
            <SttTable
                data={periods}
                onFetch={loadPeriods}
                columns={[
                    {
                        title: cxt.t('Name'),
                        type: 'link',
                        field: 'name',
                        align: 'left',
                        onClick: (row) => setPeriod(row),
                    },
                    {
                        title: cxt.t('Facility'),
                        type: 'facility',
                        field: 'facility',
                    },
                    {
                        title: cxt.t('Day'),
                        type: 'text',
                        value: (row) => moment(row.ini).format('dddd'),
                    },
                    {
                        title: cxt.t('Date'),
                        type: 'dmy',
                        field: 'ini',
                    },
                    {
                        title: cxt.t('Time'),
                        type: 'hmRange',
                        value: (row) => ({ ini: row.ini, end: row.end }),
                    },
                ]}
            />
            {period && (
                <ModalPeriod
                    item={period}
                    event={event}
                    onClose={() => setPeriod(null)}
                    onSave={savePeriod}
                    onDelete={deletePeriod}
                />
            )}
        </>
    );
}
