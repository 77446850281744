import React from 'react';
import { usePageHeader } from '../../../../lib/hooks';
import Report from '../../../sc/Report';

/**
 * View where the sporttia superadmin can see the list of notifications types.
 * @returns {JSX.Element}
 */
function AdminNotificationsTypes({}) {
    usePageHeader('notificationsTypes');

    return <Report id={17} navigateBack="/home" />;
}

export default AdminNotificationsTypes;
