import React, { useContext } from 'react';
import { Box, Grid } from '@material-ui/core';
import { SttFormDate } from './SttFormDate';
import { AppContext, SttFormSelect } from '../all';

type SelectOnChangeParams = {
    name: string;
    value: string;
};

type SttFormSelectPeriodProps = {
    selectCaption: string;
    selectName: string;
    selectValue: string;
    selectOptions: { caption: string; value: number | string }[];
    onChange: ({ name, value }: SelectOnChangeParams) => void;
    nameIni: string;
    nameEnd: string;
    valIni: string;
    valEnd: string;
    disableOnValue?: string;
};

export function SttFormSelectPeriod({
    selectCaption,
    selectName,
    selectValue,
    selectOptions,
    onChange,
    nameIni,
    nameEnd,
    valIni,
    valEnd,
    disableOnValue,
}: SttFormSelectPeriodProps) {
    const cxt = useContext(AppContext)!;

    const isDisabled = disableOnValue === selectValue;

    return (
        <>
            <SttFormSelect
                name={selectName}
                // @ts-expect-error Migrate
                options={selectOptions}
                defVal={selectValue}
                caption={selectCaption}
                onChange={onChange}
            />

            <Box pt={2}>
                <Grid container spacing={1}>
                    <SttFormDate
                        grid
                        xs={12}
                        sm={6}
                        caption={cxt.t('From')}
                        name={nameIni}
                        defVal={valIni}
                        onChange={onChange}
                        disabled={isDisabled}
                    />

                    <SttFormDate
                        grid
                        xs={12}
                        sm={6}
                        caption={cxt.t('Until')}
                        name={nameEnd}
                        defVal={valEnd}
                        onChange={onChange}
                        disabled={isDisabled}
                    />
                </Grid>
            </Box>
        </>
    );
}
