import React, { useContext } from 'react';
import {
    Box,
    Link,
    Grid,
    Typography,
    useTheme,
    useMediaQuery,
    makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { getPath } from '../../../pages/Pages';
import { formatPriceByLocale, formatPeriod } from '../../../lib/utils';
import { AppContext } from '../../AppContext';
/**
 * Card representing an activity.
 * @param activity Activity object.
 * @returns {JSX.Element|null}
 */
export function SttActivityCard({ activity }) {
    const cxt = useContext(AppContext);
    const history = useHistory();

    const classes = useStyles();

    const theme = useTheme();
    const mdUp = useMediaQuery(theme.breakpoints.up('sm'));

    if (!activity) {
        return null;
    }

    // Is the activity "in time"?
    const inTime =
        moment().isAfter(moment(activity.ini)) &&
        moment().isBefore(moment(activity.end));
    const inIscTime =
        moment().isAfter(moment(activity.inscIni)) &&
        moment().isBefore(moment(activity.inscEnd));

    const dateFormat = mdUp ? 'D MMMM YYYY' : 'DD/MM/YYYY';
    const [period, , periodLabel] = formatPeriod(
        activity.ini,
        activity.end,
        { dateFormat },
        cxt.t,
    );
    const [inscPeriod] = formatPeriod(
        activity.inscIni,
        activity.inscEnd,
        { dateFormat },
        cxt.t,
    );

    return (
        <Box p={3}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={10}>
                    <div>
                        <Link
                            onClick={() =>
                                history.push(
                                    getPath('activityPublic', {
                                        id: activity.id,
                                    }),
                                )
                            }
                        >
                            <Typography variant="h5">
                                {activity.name}
                            </Typography>
                        </Link>
                    </div>
                    <div>
                        <Link
                            onClick={() =>
                                history.push(
                                    getPath('timetable', {
                                        id: activity.sc.id,
                                    }),
                                )
                            }
                        >
                            <Typography variant="body2">
                                {activity.sc.name}
                            </Typography>
                        </Link>
                    </div>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Box textAlign={mdUp ? 'right' : 'left'}>
                        <Typography variant="button">
                            {activity.sport.name}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography variant="body1">
                        <span className={classes.dateLabel}>
                            {periodLabel}:{' '}
                        </span>
                        <span className={inTime ? classes.green : classes.red}>
                            {period}
                        </span>{' '}
                        {/* Change the color based on wether the activity is "in time" */}
                    </Typography>
                    <Typography variant="body1">
                        <span className={classes.dateLabel}>
                            {cxt.t('Inscription')}:{' '}
                        </span>
                        <span
                            className={inIscTime ? classes.green : classes.red}
                        >
                            {inscPeriod}
                        </span>{' '}
                        {/* Change the color based on wether the inscription period is "in time" */}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box display="flex" justifyContent="space-between">
                        <Box>
                            <Typography variant="body2">
                                {cxt.t('Price')}
                            </Typography>
                            <Typography variant="body1">{`${cxt.t(
                                'From',
                            )} ${formatPriceByLocale(
                                activity.price,
                                activity?.priceShape?.currency,
                                activity?.priceShape?.locale,
                            )}`}</Typography>
                        </Box>
                        <Box textAlign="right">
                            <Typography variant="body2">
                                {cxt.t('Students')}
                            </Typography>
                            <Typography variant="body1">{`${
                                activity.numStudents
                            } / ${
                                activity.maxStudents > 0
                                    ? activity.maxStudents
                                    : '∞'
                            }`}</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

const useStyles = makeStyles(() => ({
    green: {
        color: 'green',
    },
    red: {
        color: 'red',
    },
}));
