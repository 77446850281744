import React, { useContext } from 'react';
import { AppContext, SttSelectableItems } from '../all';

/**
 * SttSelectWeekdays - Present a SttSelectableItems preloaded with the weekdays
 * string name - field name
 * int[] Array selected - values of the selected days: 0,1,2...
 * Function onChange({name, value}) - callback for when selection changes.
 */
export function SttSelectWeekdays({ name, selected, onChange, disabled }) {
    const cxt = useContext(AppContext);

    // TODO: Make Sunday's position locale-dependant?
    return (
        <SttSelectableItems
            disabled={disabled}
            name="weekdays"
            items={[1, 2, 3, 4, 5, 6, 0].map((day) => ({
                label: cxt.t(`day.${day}.mini`),
                value: day,
            }))}
            selected={selected}
            onChange={onChange}
        />
    );
}
