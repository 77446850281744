import React, { useContext, useState } from 'react';
import { Paper, IconButton, makeStyles } from '@material-ui/core';
import { FileCopy, CheckCircleOutline, HighlightOff } from '@material-ui/icons';
import Link from '@material-ui/core/Link';
import { useMutation, useQueryClient } from 'react-query';
import Box from '@material-ui/core/Box';
import { AppContext } from '../../../../sporttia/AppContext';
import {
    useLoader,
    usePageHeader,
    useQueryFilters,
    useEnvironment,
} from '../../../../lib/hooks';
import { copyToClipboard } from '../../../../lib/utils';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';
import { SUPERADMIN_TRANSLATIONS } from '../../../../lib/queryKeys';
import useTranslationsService from '../../../../services/TranslationsService';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import { SttButtonFab } from '../../../../sporttia/buttons/SttButtonFab';
import AdminTranslation from './AdminTranslation';
import { SttButton } from '../../../../sporttia/all';

/**
 * View where the sporttia superadmin can see the list of translations.
 * @returns {JSX.Element}
 */
export default function AdminTranslations({}) {
    const cxt = useContext(AppContext);
    const environment = useEnvironment();
    usePageHeader('Translations');
    const classes = useStyles();
    const queryClient = useQueryClient();
    const translationsService = useTranslationsService();
    const [filters, setFilters] = useQueryFilters({}, SUPERADMIN_TRANSLATIONS);
    const [translationId, setTranslationId] = useState(null);
    const updateMutation = useMutation(
        (params) => translationsService.update(params?.id, params?.params),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(SUPERADMIN_TRANSLATIONS);
            },
        },
    );
    const [, loader] = useLoader([updateMutation.status]);

    /**
     * In this case, before making the request (due to Material UI problems), if the value of "translationsType"
     * takes the value of ALL, the "web", "app" and zero parameters are eliminated. If it takes the value WEB,
     * the "app" and zero parameters are eliminated and web is sent to true and so on with all of them.
     * @param updatedFilters Object with the parameters returned by the SttTopFilteringControls component.
     */
    const doFiltering = (updatedFilters) => {
        const formatedFilters = { ...updatedFilters };

        if (updatedFilters?.translationType === 'ALL') {
            delete formatedFilters.app;
            delete formatedFilters.web;
            delete formatedFilters.zero;
        } else if (updatedFilters?.translationType === 'WEB') {
            delete formatedFilters.app;
            delete formatedFilters.zero;
            formatedFilters.web = true;
        } else if (updatedFilters?.translationType === 'APP') {
            delete formatedFilters.web;
            delete formatedFilters.zero;
            formatedFilters.app = true;
        } else {
            delete formatedFilters.app;
            delete formatedFilters.web;
            formatedFilters.zero = true;
        }

        setFilters(formatedFilters);
    };

    const changeTranslation = (translation, type) => {
        updateMutation.mutate({
            id: translation?.id,
            params: {
                [type]: !translation[type],
            },
        });
    };

    return (
        <>
            {loader}
            <Paper>
                <SttTopFilteringControls
                    p={2}
                    fields={[
                        {
                            type: 'text',
                            caption: cxt.t('Code'),
                            name: 'code',
                            value: '',
                        },
                        {
                            type: 'text',
                            caption: cxt.t('SpanishText'),
                            name: 'es',
                            value: '',
                        },
                        {
                            type: 'text',
                            caption: cxt.t('CatalanText'),
                            name: 'ca',
                            value: '',
                        },
                        {
                            type: 'text',
                            caption: cxt.t('EnglishText'),
                            name: 'en',
                            value: '',
                        },
                        {
                            type: 'text',
                            caption: cxt.t('FrenchText'),
                            name: 'fr',
                            value: '',
                        },
                        {
                            type: 'text',
                            caption: cxt.t('GermanText'),
                            name: 'de',
                            value: '',
                        },
                        {
                            caption: cxt.t('Type'),
                            type: 'select',
                            name: 'translationType',
                            value: 'ALL',
                            options: [
                                {
                                    caption: cxt.t('All'),
                                    value: 'ALL',
                                },
                                {
                                    caption: cxt.t('WebApp'),
                                    value: 'WEB',
                                },
                                {
                                    caption: cxt.t('App'),
                                    value: 'APP',
                                },
                                {
                                    caption: cxt.t('Zero'),
                                    value: 'ZERO',
                                },
                            ],
                        },
                    ]}
                    trashAction
                    onFilter={doFiltering}
                />

                {/* Botón pasar traducciones */}
                {environment !== 'PRO' && (
                    <Box align="right" mt={1} mb={3} mr={1}>
                        <SttButton
                            caption="PASAR TRADUCCIONES"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                cxt.api('POST', '/deployTranslations', {
                                    success: (response) => {
                                        if (response && response.count) {
                                            const { count } = response;
                                            cxt.showMessage(
                                                'S',
                                                `¡${count} traducciones pasadas de PRO a PRE!`,
                                            );
                                        }
                                    },
                                    error: (e) => {
                                        cxt.showMessage('E', e.msg);
                                    },
                                });
                            }}
                        />
                    </Box>
                )}

                <SttCachedTable
                    queryKey={SUPERADMIN_TRANSLATIONS}
                    queryFn={(params) => translationsService.getList(params)}
                    queryParams={filters}
                    columns={[
                        {
                            title: cxt.t('Actions'),
                            value: (row) => (
                                <IconButton
                                    size="small"
                                    aria-label="delete"
                                    onClick={() =>
                                        copyToClipboard(row.code, () =>
                                            cxt.showMessage(
                                                'S',
                                                cxt.t('copiedToClipboard'),
                                            ),
                                        )
                                    }
                                >
                                    <FileCopy fontSize="inherit" />
                                </IconButton>
                            ),
                        },
                        {
                            title: cxt.t('Code'),
                            align: 'left',
                            field: 'code',
                            type: 'link',
                            value: (row) => (
                                <Link onClick={() => setTranslationId(row.id)}>
                                    {row.code}
                                </Link>
                            ),
                        },
                        {
                            title: 'ES',
                            align: 'left',
                            field: 'es',
                        },
                        {
                            title: 'ES-CA',
                            align: 'left',
                            field: 'ca',
                        },
                        {
                            title: 'EN',
                            align: 'left',
                            field: 'en',
                        },
                        {
                            title: 'FR',
                            align: 'left',
                            field: 'fr',
                        },
                        {
                            title: 'DE',
                            align: 'left',
                            field: 'de',
                        },
                        {
                            title: 'APP',
                            value: (row) =>
                                row.mobile ? (
                                    <CheckCircleOutline
                                        className={`${classes.notificationButton} ${classes.notificationButtonActive}`}
                                        onClick={() =>
                                            changeTranslation(row, 'mobile')
                                        }
                                    />
                                ) : (
                                    <HighlightOff
                                        className={`${classes.notificationButton} ${classes.notificationButtonDisabled}`}
                                        onClick={() =>
                                            changeTranslation(row, 'mobile')
                                        }
                                    />
                                ),
                        },
                        {
                            title: 'Web APP',
                            value: (row) =>
                                row.web ? (
                                    <CheckCircleOutline
                                        className={`${classes.notificationButton} ${classes.notificationButtonActive}`}
                                        onClick={() =>
                                            changeTranslation(row, 'web')
                                        }
                                    />
                                ) : (
                                    <HighlightOff
                                        className={`${classes.notificationButton} ${classes.notificationButtonDisabled}`}
                                        onClick={() =>
                                            changeTranslation(row, 'web')
                                        }
                                    />
                                ),
                        },
                        {
                            title: 'Zero',
                            value: (row) =>
                                row.zero ? (
                                    <CheckCircleOutline
                                        className={`${classes.notificationButton} ${classes.notificationButtonActive}`}
                                        onClick={() =>
                                            changeTranslation(row, 'zero')
                                        }
                                    />
                                ) : (
                                    <HighlightOff
                                        className={`${classes.notificationButton} ${classes.notificationButtonDisabled}`}
                                        onClick={() =>
                                            changeTranslation(row, 'zero')
                                        }
                                    />
                                ),
                        },
                    ]}
                />

                <AdminTranslation
                    id={translationId}
                    onClose={() => setTranslationId(null)}
                    onCreate={() =>
                        queryClient.invalidateQueries(SUPERADMIN_TRANSLATIONS)
                    }
                    onUpdate={() =>
                        queryClient.invalidateQueries(SUPERADMIN_TRANSLATIONS)
                    }
                    onDelete={() => {
                        setTranslationId(null);
                        queryClient.invalidateQueries(SUPERADMIN_TRANSLATIONS);
                    }}
                />

                <SttButtonFab onClick={() => setTranslationId('create')} />
            </Paper>
        </>
    );
}

const useStyles = makeStyles({
    notificationButton: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    notificationButtonActive: {
        color: '#18b432',
        '&:hover': {
            color: 'rgba(33, 150, 243, 0.5)',
        },
    },
    notificationButtonDisabled: {
        color: '#df3525',
        '&:hover': {
            color: 'rgba(33, 150, 243, 0.5)',
        },
    },
});
