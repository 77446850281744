import React, { useState, useEffect, useContext } from 'react';
import {} from '@material-ui/core';
import { AppContext, SttDialog, SttForm } from '../../../sporttia/all';

export default function ConfigurationRechargingOptionDialog({
    chargingOption = null,
    onClose,
}) {
    const cxt = useContext(AppContext);
    const [internalChargingOption, setInternalChargingOption] = useState();

    /**
     * Charing options changes
     */
    useEffect(() => {
        setInternalChargingOption(chargingOption);
    }, [chargingOption]);

    /**
     * Save charging option
     */
    const saveChargingOption = () => {
        // Updating
        if (chargingOption.id) {
            cxt.api('PUT', `/chargings/options/${chargingOption.id}`, {
                params: {
                    amount: internalChargingOption.amount,
                    bonus: internalChargingOption.bonus,
                },
                success: (r) => {
                    onClose();
                },
            });

            // Creating
        } else {
            cxt.api('POST', `/scs/${cxt.sc.id}/chargings/options`, {
                params: {
                    amount: internalChargingOption.amount,
                    bonus: internalChargingOption.bonus,
                },
                success: (r) => {
                    onClose();
                },
            });
        }
    };

    /**
     * Handle form
     */
    const handleForm = ({ name, value }) => {
        setInternalChargingOption({ ...internalChargingOption, [name]: value });
    };

    // -----| Render |-----
    return (
        <SttDialog
            title={cxt.t('Recharge')}
            open={chargingOption != null}
            onClose={onClose}
            content={
                <>
                    {internalChargingOption && (
                        <SttForm
                            onChangeForm={handleForm}
                            elements={[
                                {
                                    md: 6,
                                    caption: cxt.t('Amount'),
                                    name: 'amount',
                                    value: internalChargingOption.amount,
                                },
                                {
                                    md: 6,
                                    caption: cxt.t('Bonus'),
                                    name: 'bonus',
                                    value: internalChargingOption.bonus,
                                },
                            ]}
                        />
                    )}
                </>
            }
            buttons={[
                {
                    type: 'save',
                    onClick: () => saveChargingOption(),
                },
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
        />
    );
}
