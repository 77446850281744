import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { AppContext } from '../../../sporttia/AppContext';
import { useCrud } from '../../../lib/hooks';
import { SttSelectWeekdays } from '../../../sporttia/selectors/SttSelectWeekdays';
import { SttFormTime } from '../../../sporttia/forms/SttFormTime';
import { SttList } from '../../../sporttia/lists/SttList';
import moment from '../busySchedules/BusySlotDialog';
import { SttDialog } from '../../../sporttia/dialogs/SttDialog';

export default function BookingScheduleSlotDialog({
    item,
    onSave,
    onDelete,
    onClose,
    ...rest
}) {
    const cxt = useContext(AppContext);

    const [slot, setSlot, setProperty, Get, Post, Put, Delete] = useCrud(item);
    const [periods, setPeriods] = useState();

    // item cambia. Si el contiene id, cargar los periodos asociados.
    useEffect(() => {
        setSlot(item);
    }, [item]);

    if (!slot) {
        return null;
    }

    return (
        <SttDialog
            open
            onClose={onClose}
            title={slot.id ? cxt.t('Slot') : cxt.t('CreateTimeSlot')}
            content={
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SttSelectWeekdays
                            disabled={!!slot.id}
                            selected={slot.weekdays}
                            onChange={setProperty}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {/* El output de esto es tipo HH:mm:ss así que lo convertimos a HH:mm para el Slot */}
                        <SttFormTime
                            disabled={!!slot.id}
                            name="timeini"
                            caption={cxt.t('From')}
                            defVal={slot.timeini}
                            min="00:00:00"
                            max="23:45:00"
                            interval={5}
                            // onChange={({name, value}) => handleChange(({name, value: value.split(":").splice(0,2).join(":")}))}
                            onChange={setProperty}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {/* El output de esto es tipo HH:mm:ss así que lo convertimos a HH:mm para el Slot */}
                        <SttFormTime
                            disabled={!!slot.id}
                            name="timeend"
                            caption={cxt.t('Until')}
                            defVal={slot.timeend}
                            min="00:00:00"
                            max="23:45:00"
                            interval={5}
                            // onChange={({name, value}) => handleChange(({name, value: value.split(":").splice(0,2).join(":")}))}
                            onChange={setProperty}
                        />
                    </Grid>
                </Grid>
            }
            buttons={[
                !slot.id && {
                    type: 'save',
                    onClick: () => onSave(slot),
                    loading: rest.loading,
                },
                slot.id && {
                    type: 'delete',
                    onClick: () => onDelete(slot),
                },
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
        />
    );
}
