import React, { useState, useEffect, useContext } from 'react';
import {
    Checkbox,
    Box,
    Paper,
    Grid,
    FormControlLabel,
} from '@material-ui/core';
import { Security } from '@material-ui/icons';
import {
    AppContext,
    SttFormText,
    SttButtonSave,
    SttFormSelect,
} from '../../sporttia/all';
import { useTranslations } from '../../lib/hooks';
import translations from '../../translations';

export default function Account() {
    const cxt = useContext(AppContext);

    const [user, setUser] = useState({});
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [languages, setLanguages] = useState([]);
    const twoFAEnabled = user?.twoFactorEnabled ?? false;
    const { translate } = useTranslations();
    /**
     * Change password
     */
    const changePassword = () => {
        // Same password ???
        if (password == password2) {
            // Update password
            cxt.api('PUT', '/me', {
                params: {
                    password,
                },
                success: (r) => {
                    cxt.showMessage('S', cxt.t('page.sc.conf.password.saved'));
                },
            });
        } else {
            cxt.showMessage('E', cxt.t('app.profile.passwordsDontMatch'));
        }
    };

    const changeLanguage = (language) => {
        cxt.setLang(languages.find((lg) => lg.key === language));
        cxt.api('PUT', '/me', {
            params: {
                lang: language,
            },
            success: (r) => {
                setUser(r.user);
                cxt.showMessage('S', cxt.t('page.sc.conf.language.change'));
            },
        });
    };

    const post2FA = (is2FAEnabled) => {
        cxt.api('PUT', '/me', {
            params: {
                twoFactorEnabled: is2FAEnabled,
            },
            success: (r) => {
                setUser(r.user);
                cxt.showMessage('S', cxt.t(translations.user.enabled2FA));
            },
            error: (r) => {
                if (r.error.msg) cxt.showMessage('E', r.error.msg);
            },
        });
    };

    /**
     * Load user data.
     */
    const loadUserData = () => {
        cxt.api('GET', `/me`, {
            success: (r) => {
                setUser(r.user);
            },
        });
    };

    /**
     * Init
     */
    useEffect(() => {
        cxt.setHeader({
            title: cxt.t('MyAccount'),
        });

        if (cxt.config && cxt.config.languages) {
            const languages = [];
            cxt.config.languages.map((lg) =>
                languages.push({
                    key: lg.key,
                    caption: lg.caption,
                    value: lg.key,
                }),
            );
            setLanguages(languages);
        }

        loadUserData();
    }, []);

    // -----| Render |-----
    return (
        <Paper>
            <Box p={5}>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <b>
                            {cxt.t('page.sc.conf.password.adminPassword')} (
                            {cxt.user.name})
                        </b>
                        <br />
                        <br />

                        <SttFormText
                            grid
                            sm={12}
                            md={12}
                            xs={12}
                            password
                            name="password"
                            caption={cxt.t('page.sc.conf.password.newPassword')}
                            defVal={password}
                            onChange={({ value }) => setPassword(value)}
                        />

                        <br />

                        <SttFormText
                            grid
                            sm={12}
                            md={12}
                            xs={12}
                            password
                            name="password2"
                            caption={cxt.t(
                                'page.sc.conf.password.repeatPassword',
                            )}
                            defVal={password2}
                            onChange={({ value }) => setPassword2(value)}
                        />
                        <br />
                        <SttButtonSave
                            fullWidth
                            caption={cxt.t(
                                'page.sc.conf.password.changePassword',
                            )}
                            disabled={!password}
                            onClick={() => changePassword()}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <b>{cxt.t('Language')}</b>
                        <br />
                        <br />
                        <SttFormSelect
                            grid
                            md={4}
                            name="lang"
                            caption={cxt.t('Language')}
                            defVal={user.lang || ''}
                            onChange={(lang) => changeLanguage(lang.value)}
                            options={languages}
                        />
                        <br />
                        <b>{translate(translations.user.label2FA)}</b>
                        <br />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={twoFAEnabled ?? false}
                                    onChange={() => {
                                        post2FA(!twoFAEnabled);
                                    }}
                                />
                            }
                            icon={<Security color="action" />}
                            label={translate(translations.user.label2FA)}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
}
