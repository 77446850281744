import React, { useState, useEffect, useContext } from 'react';
import {
    Container,
    Box,
    Grid,
    Paper,
    TableContainer,
    TableRow,
    TableHead,
    TableCell,
    Table,
    TableBody,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import {
    AppContext,
    SttFormSelect,
    SttList,
    SttFormText,
    SttTabs,
} from '../../../sporttia/all';
import ApiModelDialog from './ApiModelDialog';

export default function Apis({}) {
    // Methods
    const methods = ['GET', 'POST', 'PUT', 'DELETE'];

    const cxt = useContext(AppContext);
    // const history = useHistory();
    const [apis, setApis] = useState();
    const [versions, setVersions] = useState([]);
    const [filter, setFilter] = useState({});
    const [selectedApi, setSelectedApi] = useState();
    const [categories, setCategories] = useState([]);
    const [models, setModels] = useState([]);
    const [openModel, setOpenModel] = useState(null);

    /**
     * Load API's
     */
    const loadApis = () => {
        cxt.api('GET', '/apis', {
            params: {
                idVersion: filter.version.id,
                page: 1,
                rows: 1000,
            },
            success: (r) => {
                setApis(r);

                // Set first one
                selectApi(r.rows[0]);

                // Build category list
                const auxCats = [];
                r.rows.forEach((api) => {
                    if (!auxCats.includes(api.category))
                        auxCats.push(api.category);
                });
                auxCats.forEach((cat) => {
                    categories.push({
                        value: cat,
                        caption: cat,
                    });
                });
            },
        });
    };

    /**
     * Load versions
     */
    const loadVersions = () => {
        cxt.api('GET', '/apis/versions', {
            success: (r) => {
                setVersions(r.rows);

                // Set actual version
                // setFilter({version: r.rows[0]})
                setFilter({
                    endpoint: '',
                    method: 'GET',
                    version: r.rows.find((e) => e.code == 'v6'),
                });
            },
        });
    };

    /**
     * Handle form
     */
    const handleForm = ({ name, value }) => {
        setFilter({ ...filter, [name]: value });
    };

    /**
     * Actual version changes, load apis
     */
    useEffect(() => {
        if (filter.version) {
            loadApis();

            // Load all models
            cxt.api('GET', '/apis/models', {
                params: { version: filter.version.id },
                success(r) {
                    setModels(r);
                },
            });
        }
    }, [filter.version]);

    /**
     * Init
     */
    useEffect(() => {
        cxt.setHeader({
            title: "API's",
        });

        // Load versions
        loadVersions();

        setFilter({
            version: null,
            endpoint: '',
            method: 'GET',
        });
    }, []);

    /**
     * On select version
     */
    const onSelectVersion = ({ name, value }) => {
        setFilter({
            version: versions.find((e) => e.id == value),
        });
    };

    /**
     * On open model
     */
    const onOpenModel = (model) => {
        setOpenModel(model);
    };

    /**
     * Select API
     */
    const selectApi = (api) => {
        cxt.api('GET', `/apis/${api.id}`, {
            success: (r) => {
                setSelectedApi(r.api);
            },
        });
    };

    // -----| Render |-----
    return (
        <Container>
            <h1>Sporttia API documentation</h1>

            <Box pt={2}>
                <Paper>
                    <Box p={4}>
                        {filter && (
                            <Grid container spacing={3}>
                                <SttFormSelect
                                    grid
                                    md={2}
                                    name="version"
                                    caption={cxt.t('Version')}
                                    defVal={filter.version && filter.version.id}
                                    onChange={onSelectVersion}
                                    options={versions.map((version) => ({
                                        caption: version.code,
                                        value: version.id,
                                    }))}
                                />

                                <SttFormSelect
                                    grid
                                    cleanable
                                    md={4}
                                    name="category"
                                    caption={cxt.t('Category')}
                                    defVal={filter.category}
                                    onChange={handleForm}
                                    options={categories}
                                />

                                <SttFormSelect
                                    grid
                                    md={2}
                                    name="method"
                                    caption={cxt.t('Method')}
                                    defVal={filter.method}
                                    onChange={handleForm}
                                    options={methods.map((m) => ({
                                        caption: m,
                                        value: m,
                                    }))}
                                />

                                <SttFormText
                                    grid
                                    md={4}
                                    name="endpoint"
                                    caption="Endpoint"
                                    defVal={filter.endpoint}
                                    onChange={handleForm}
                                />
                            </Grid>
                        )}

                        {apis && (
                            <Grid container spacing={3}>
                                <Grid item md={4}>
                                    {apis && (
                                        <SttList
                                            title="API's"
                                            onSelect={selectApi}
                                            data={apis.rows
                                                .filter(
                                                    (api) =>
                                                        filter.method ==
                                                        api.method,
                                                )
                                                .filter(
                                                    (api) =>
                                                        api.endpoint.indexOf(
                                                            filter.endpoint,
                                                        ) != -1,
                                                )
                                                .filter(
                                                    (api) =>
                                                        !filter.category ||
                                                        api.category ==
                                                            filter.category,
                                                )
                                                .map((api) => ({
                                                    caption: `${api.method} ${api.endpoint}`,
                                                    text: api.name,
                                                    objToSelect: api,
                                                    selected:
                                                        selectedApi &&
                                                        selectedApi.id ==
                                                            api.id,
                                                }))}
                                        />
                                    )}
                                </Grid>

                                {selectedApi && (
                                    <Grid item md={8}>
                                        <h2>{selectedApi.endpoint}</h2>
                                        <p>{selectedApi.description}</p>

                                        <SttTabs
                                            tabs={[
                                                {
                                                    caption:
                                                        cxt.t('Parameters'),
                                                    component: (
                                                        <ApisParameters
                                                            selectedApi={
                                                                selectedApi
                                                            }
                                                            models={models}
                                                            onOpenModel={
                                                                onOpenModel
                                                            }
                                                        />
                                                    ),
                                                },
                                                {
                                                    caption: cxt.t('Responses'),
                                                    component: (
                                                        <ApisResponses
                                                            selectedApi={
                                                                selectedApi
                                                            }
                                                            models={models}
                                                            onOpenModel={
                                                                onOpenModel
                                                            }
                                                        />
                                                    ),
                                                },
                                            ]}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </Box>
                </Paper>
            </Box>

            <ApiModelDialog
                idModel={openModel && openModel.id}
                onClose={() => setOpenModel(null)}
            />
        </Container>
    );
}

function ApisParameters({ selectedApi, models, onOpenModel }) {
    const cxt = useContext(AppContext);

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{cxt.t('Name')}</TableCell>
                        <TableCell>{cxt.t('Description')}</TableCell>
                        <TableCell>{cxt.t('Type')}</TableCell>
                        <TableCell>{cxt.t('Mandatory')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {selectedApi &&
                        selectedApi.parameters &&
                        selectedApi.parameters.rows.map((param) => (
                            <TableRow key={param.id}>
                                <TableCell align="center">
                                    <b>{param.name}</b>
                                </TableCell>
                                <TableCell align="left">
                                    {param.description}
                                </TableCell>
                                <TableCell align="center">
                                    <ApisModel
                                        modelName={param.type}
                                        models={models}
                                        onOpenModel={onOpenModel}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    {param.madatory ? <DoneIcon /> : null}
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function ApisResponses({ selectedApi, models, onOpenModel }) {
    const cxt = useContext(AppContext);

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{cxt.t('Name')}</TableCell>
                        <TableCell>{cxt.t('Description')}</TableCell>
                        <TableCell>{cxt.t('Type')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {selectedApi &&
                        selectedApi.responses &&
                        selectedApi.responses.rows.map((resp) => (
                            <TableRow key={resp.id}>
                                <TableCell align="center">
                                    <b>{resp.name}</b>
                                </TableCell>
                                <TableCell align="left">
                                    {resp.description}
                                </TableCell>
                                <TableCell align="center">
                                    <ApisModel
                                        modelName={resp.type}
                                        models={models}
                                        onOpenModel={onOpenModel}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function ApisModel({ modelName, models, onOpenModel }) {
    const [model, setModel] = useState();

    useEffect(() => {
        if (modelName && models) {
            setModel(
                models.rows.find((m) => m.name == modelName.replace('[]', '')),
            );
        }
    }, [modelName, models]);

    return (
        <>
            {model ? (
                <a onClick={() => onOpenModel(model)}>{modelName}</a>
            ) : (
                modelName
            )}
        </>
    );
}
