import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar, Tooltip } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SpeedIcon from '@material-ui/icons/Speed';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import { getPath } from '../../pages/Pages';
import { AppContext, SttMenu } from '../../sporttia/all';

export default function SCHeaderAvatar() {
    const cxt = useContext(AppContext);
    const history = useHistory();

    /**
     * Logout
     */
    const logout = () => {
        cxt.api('POST', '/logout', {
            confirmation: true,
            success: () => {
                cxt.setLoggedUser(false);
                cxt.setHeader(null);
            },
        });
    };

    /**
     * If "cxt.logged" change and is false, we will redirect to login page.
     */
    useEffect(() => {
        if (!cxt.logged) history.push(getPath('loadingSporttia'));
    }, [cxt.logged, history]);

    return (
        <SttMenu
            icon={
                <Tooltip title={cxt.t('MyAccount')} arrow>
                    <Avatar
                        style={{
                            backgroundColor: 'white',
                            color: '#666',
                            width: 25,
                            height: 25,
                        }}
                    >
                        {cxt &&
                            cxt.user &&
                            cxt.user.name &&
                            cxt.user.name.substring(0, 1)}
                    </Avatar>
                </Tooltip>
            }
            items={[
                {
                    caption: cxt.user.name,
                    onClick: () => history.push(getPath('account')),
                },
                {
                    show: !!(cxt.privileges && cxt.privileges.includes(11)),
                    divider: true,
                    caption: cxt.t('Configuration'),
                    icon: <SettingsIcon />,
                    onClick: () => history.push(getPath('scConfiguration')),
                },
                {
                    show: !!(cxt.privileges && cxt.privileges.includes(11)),
                    caption: cxt.t('Administrators'),
                    icon: <PeopleOutlineIcon />,
                    onClick: () => history.push(getPath('scRoles')),
                },
                {
                    divider: true,
                    caption: cxt.t('MyAccount'),
                    icon: <PersonOutlineIcon />,
                    onClick: () => history.push(getPath('account')),
                },
                {
                    caption: cxt.t('Subscription'),
                    icon: <SpeedIcon />,
                    onClick: () => history.push(getPath('subscriptions')),
                },
                {
                    divider: true,
                    caption: cxt.t('Help'),
                    icon: <HelpOutlineIcon />,
                    onClick: () => history.push(getPath('help')),
                },
                {
                    divider: true,
                    caption: cxt.t('Exit'),
                    icon: <PowerSettingsNewIcon />,
                    onClick: () => logout(),
                },
            ]}
        />
    );
}
