import React, { useState, useEffect, useContext } from 'react';
import { Container, Box, Typography } from '@material-ui/core';
import {
    AppContext,
    SttLabelPeriod,
    SttLabelDate,
    SttPage,
} from '../../../sporttia/all';
import {
    getFormattedEnrollmentFromAbono,
    getFormattedFeeFromAbono,
} from './UserAbonoHelper';

export default function UserAbono(props) {
    const cxt = useContext(AppContext);
    const [groupMember, setGroupMember] = useState();

    /**
     * Load abono
     */
    const loadGroupMember = () => {
        cxt.api('GET', `/groups/members/${props.match.params.id}`, {
            success: (r) => {
                setGroupMember(r.groupMember);
            },
        });
    };

    /**
     * Init
     */
    useEffect(() => {
        loadGroupMember();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -----| Render |-----
    return (
        <Container maxWidth="md">
            <SttPage innerTitle={cxt.t('Abono')}>
                {groupMember && (
                    <Box mt={2} mb={1}>
                        <Box>
                            {cxt.t('BuyedDay')}:{' '}
                            <SttLabelDate date={groupMember.created} />
                        </Box>
                        <Box>
                            <Typography>
                                {cxt.t('Abono')}: {groupMember.group?.name}
                            </Typography>
                        </Box>
                        {groupMember.enrollment?.price && (
                            <Box>
                                {cxt.t('Enrollment')}:{' '}
                                <b>
                                    {getFormattedEnrollmentFromAbono(
                                        groupMember,
                                    )}
                                </b>
                            </Box>
                        )}

                        {groupMember.group?.fee && (
                            <Box>
                                {cxt.t('Fee')}:{' '}
                                <b>{getFormattedFeeFromAbono(groupMember)}</b>
                            </Box>
                        )}

                        <Box>
                            {cxt.t('Period')}:{' '}
                            <SttLabelPeriod
                                ini={groupMember.ini}
                                end={groupMember.end}
                            />
                        </Box>
                    </Box>
                )}
            </SttPage>
        </Container>
    );
}
