import React, { useState, useEffect, useContext } from 'react';
import { Box, Container } from '@material-ui/core';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { SttPage } from '../../../sporttia/SttPage';
import {
    AppContext,
    SttButtonAccept,
    SttLabelTitle,
} from '../../../sporttia/all';
import { useCrud } from '../../../lib/hooks';
import { getPath } from '../../Pages';
import { formatPriceByLocale } from '../../../lib/utils';

export default function UserBonos({}) {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const [ready, setReady] = useState(false);
    const [boletos, setBoletos, , Get] = useCrud([]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        loadBoletos();
    }, []);

    function loadBoletos() {
        Get(`/my/bonos`, { isActive: true }).then((response) => {
            if (response.rows) {
                setCount(response.count);
                setBoletos(sortBySc(response.rows));
                setReady(true);
            }
        });
    }

    function sortBySc(rows) {
        if (rows.length == 0) {
            return rows;
        }

        const result = {};
        rows.forEach((row) => {
            if (!result[row.bono.sc.short]) {
                result[row.bono.sc.short] = [];
            }

            result[row.bono.sc.short].push(row);
        });

        return result;
    }

    if (!ready) {
        return null;
    }

    return (
        <Container maxWidth="md">
            <SttPage innerTitle={cxt.t('MyBonos')}>
                <Box p={1}>
                    {count > 0 ? (
                        <div>
                            {Object.keys(boletos).map((key) => (
                                <React.Fragment key={key}>
                                    <SttLabelTitle>{key}</SttLabelTitle>
                                    <Box>
                                        {boletos[key].map((boleto, i) => (
                                            <Box
                                                mt={1}
                                                pt={1}
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="flex-end"
                                                style={{
                                                    borderTop:
                                                        '1px solid #cdcdcd',
                                                }}
                                                key={boleto.id}
                                            >
                                                <div>
                                                    <Box>
                                                        <b>
                                                            {boleto.bono.name}
                                                        </b>
                                                    </Box>
                                                    <Box mt={0.5}>
                                                        <span>
                                                            {cxt.t('BuyedDay')}:{' '}
                                                            <b>
                                                                {moment(
                                                                    boleto.created,
                                                                ).format(
                                                                    'D MMMM YYYY',
                                                                )}
                                                            </b>
                                                        </span>
                                                    </Box>
                                                    {boleto.expiration && (
                                                        <Box mt={0.5}>
                                                            <span>
                                                                {cxt.t(
                                                                    'Expiration',
                                                                )}
                                                                :{' '}
                                                                <b>
                                                                    {moment(
                                                                        boleto.expiration,
                                                                    ).format(
                                                                        'D MMMM YYYY',
                                                                    )}
                                                                </b>
                                                            </span>
                                                        </Box>
                                                    )}
                                                    <Box mt={0.5}>
                                                        <span>
                                                            {cxt.t('Consumed')}:{' '}
                                                            <b>
                                                                {
                                                                    boleto.consumed
                                                                }{' '}
                                                                /{' '}
                                                                {boleto.amount}
                                                            </b>
                                                        </span>
                                                    </Box>
                                                </div>
                                                <SttButtonAccept
                                                    style={{ height: 42 }}
                                                    caption={`${cxt.t(
                                                        'BuyAnother',
                                                    )} - ${formatPriceByLocale(
                                                        boleto.bono.price,
                                                        boleto?.bono?.priceShape
                                                            ?.currency,
                                                        boleto?.bono?.priceShape
                                                            ?.locale,
                                                    )}`}
                                                    onClick={() =>
                                                        history.push(
                                                            getPath('buyBono', {
                                                                idSC: boleto
                                                                    .bono.sc.id,
                                                                id: boleto.bono
                                                                    .id,
                                                            }),
                                                        )
                                                    }
                                                />
                                            </Box>
                                        ))}
                                    </Box>
                                </React.Fragment>
                            ))}
                        </div>
                    ) : (
                        <Box my={3}>{cxt.t('app.account.bonos.noBonos')}</Box>
                    )}
                </Box>
            </SttPage>
        </Container>
    );
}
