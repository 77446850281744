import React, { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import ReplayIcon from '@material-ui/icons/Replay';
import { CloudDownload as CloudDownloadIcon } from '@material-ui/icons';
import { SttTopControls } from '../SttTopControls';
import { useInteractionsFiles } from '../../lib/hooks';
import { AppContext } from '../AppContext';
import { SttButton } from '../all';
import { SttAttendanceTable } from '../SttAttendanceTable';
import AttendanceDialog from '../../pages/sc/activities/AttendanceDialog';
import RecoverAttendanceDialog from '../../pages/sc/activities/RecoverAttendanceDialog';
import translations from '../../translations';
import Activity from '../../types/models/Activity';
import { DownloadFileFormat } from '../../types/components';
import ActivityAssistance from '../../types/models/ActivityAssistance';
import useActivitiesService from '../../services/ActivitiesService';

type DayColumn = {
    title: string;
    field: string;
    type: 'text';
};

type SttFormAttendanceProps = {
    activity: Activity;
};

export function SttFormAttendance({ activity }: SttFormAttendanceProps) {
    const cxt = useContext(AppContext)!;
    const { downloadFile } = useInteractionsFiles();
    const [filters, setFilters] = useState({
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        page: 1,
        rows: 10,
    });

    const [dayColumns, setDayColumns] = useState<DayColumn[]>([]);
    const [open, setOpen] = useState(false);
    const [selectedAttendance, setSelectedAttendance] =
        useState<ActivityAssistance | null>(null);
    const [selectedAttendanceDay, setSelectedAttendanceDay] = useState<
        string | null
    >(null);
    const [openRecoverDialog, setOpenRecoverDialog] = useState(false);

    const getDayColumnsByMonthAndYear = (year: number, month: number) => {
        const daysInMonth = new Date(year, month, 0).getDate();
        const columns: DayColumn[] = [];

        for (let i = 1; i <= daysInMonth; i++) {
            columns.push({
                title: i.toString(),
                field: i.toString(),
                type: 'text',
            });
        }

        setDayColumns(columns);
    };
    const { useGetActivityStudentAssistences } = useActivitiesService();

    const getActivityStudentsAssistencesQuery =
        useGetActivityStudentAssistences(activity.id, filters, {
            enabled: false,
        });

    useEffect(() => {
        getDayColumnsByMonthAndYear(filters.year, filters.month);
    }, [filters]);

    useEffect(() => {
        if (dayColumns && dayColumns.length > 0) {
            getActivityStudentsAssistencesQuery.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dayColumns]);

    const handleCell = (row: ActivityAssistance, col: DayColumn) => {
        setOpen(true);
        setSelectedAttendance(row);
        setSelectedAttendanceDay(col.field);
    };

    function download(format: DownloadFileFormat) {
        if (format === 'pdf') {
            downloadFile(
                `/activities/${activity.id}/students/assistences.pdf`,
                'application/pdf',
                filters,
                `${activity.name}.${format}`,
            );
        }

        if (format === 'csv') {
            downloadFile(
                `/activities/${activity.id}/students/assistences.csv`,
                'text/csv',
                filters,
                `${activity.name}.${format}`,
            );
        }
    }
    return (
        <>
            <Paper>
                <Box mb={2}>
                    {/* @ts-expect-error: Migrate SttTopControls */}
                    <SttTopControls
                        p={2}
                        fields={[
                            {
                                caption: cxt.t(translations.generic.year),
                                name: 'year',
                                type: 'year',
                                value: filters.year,
                            },
                            {
                                caption: cxt.t(translations.generic.month),
                                name: 'month',
                                type: 'month',
                                value: filters.month,
                            },
                        ]}
                        mainAction={{
                            type: 'component',
                            component: (
                                <SttButton
                                    caption={cxt.t(
                                        translations.generic.recover,
                                    )}
                                    icon={<ReplayIcon />}
                                    onClick={() => {
                                        setOpenRecoverDialog(true);
                                    }}
                                />
                            ),
                        }}
                        menu={[
                            {
                                caption: `${cxt.t(
                                    translations.generic.download,
                                )} PDF`,
                                onClick: () => download('pdf'),
                            },
                            {
                                caption: `${cxt.t(
                                    translations.generic.download,
                                )} CSV`,
                                onClick: () => download('csv'),
                            },
                        ]}
                        menuIcon={<CloudDownloadIcon />}
                        onChange={({
                            name,
                            value,
                        }: {
                            name: string;
                            value: string;
                        }) =>
                            setFilters({
                                ...filters,
                                [name]: value,
                            })
                        }
                        onFilter={() =>
                            getActivityStudentsAssistencesQuery?.refetch()
                        }
                    />
                </Box>
            </Paper>

            <Paper>
                <Box
                    pl={2}
                    pr={2}
                    pt={2}
                    display="flex"
                    justifyContent="flex-end"
                >
                    <Box>
                        <b>X</b> = {cxt.t('CAS.MISSED')} <b>R</b> ={' '}
                        {cxt.t('CAS.RECOVERABLE')} <b>Rc</b> ={' '}
                        {cxt.t('CAS.RECOVERER')} y <b>Rd</b> ={' '}
                        {cxt.t('CAS.RECOVERED')}
                    </Box>
                </Box>
                <Box pl={2} pr={2}>
                    <SttAttendanceTable
                        columns={dayColumns}
                        data={
                            getActivityStudentsAssistencesQuery?.data
                                ? getActivityStudentsAssistencesQuery?.data
                                : { count: 0, pages: 0, rows: [] }
                        }
                        onClickCell={handleCell}
                        onChangePageCallback={(p) =>
                            setFilters({ ...filters, page: p })
                        }
                    />
                </Box>
            </Paper>

            <AttendanceDialog
                open={open}
                onClose={() => {
                    setOpen(false);
                    getActivityStudentsAssistencesQuery.refetch();
                }}
                // @ts-expect-error Migrate AttendanceDialog
                attendance={selectedAttendance}
                // @ts-expect-error Migrate AttendanceDialog
                attendanceDay={selectedAttendanceDay}
            />

            <RecoverAttendanceDialog
                open={openRecoverDialog}
                onClose={() => {
                    setOpenRecoverDialog(false);
                    getActivityStudentsAssistencesQuery.refetch();
                }}
                activityId={activity.id}
            />
        </>
    );
}
