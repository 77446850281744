import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../AppContext';
import { SttFormSelect } from '../forms/SttFormSelect';

export function SttSelectDeviceStatus({ name, defVal, onChange, ...rest }) {
    const cxt = useContext(AppContext);

    return (
        <SttFormSelect
            caption={cxt.t('Status')}
            name={name}
            defVal={defVal}
            {...rest}
            options={[
                {
                    caption: cxt.t('DS.ACTIVE'),
                    value: 'ACTIVE',
                },
                {
                    caption: cxt.t('DS.INACTIVE'),
                    value: 'INACTIVE',
                },
                {
                    caption: cxt.t('DS.OPEN'),
                    value: 'OPEN',
                },
            ]}
            onChange={onChange}
            mandatory
        />
    );
}
