import React, { useContext, useState } from 'react';
import moment from 'moment';
import {
    Box,
    Grid,
    Chip,
    CircularProgress,
    TextField,
    InputAdornment,
    IconButton,
} from '@material-ui/core';
import { Clear as ClearIcon, Search as SearchIcon } from '@material-ui/icons';
import { useQuery } from '../../../lib/hooks';
import useScsService from '../../../services/ScsService';
import {
    AppContext,
    SttDialog,
    SttFormSearch,
    SttLabelPeriod,
    SttList,
    SttNoResults,
    SttPagination,
} from '../../../sporttia/all';
import SttError from '../../../components/error/SttError';
import { Group } from '../../../types/api/models';
import { isDefined } from '../../../lib/utils';
import translations from '../../../translations';

type SelectGroupParentDialogProps = {
    groupId: number;
    open?: boolean;
    onClose: () => void;
    onSelect: (group: Group) => void;
};

/**
 * Select group dialog
 */
function SelectGroupParentDialog({
    groupId,
    open = false,
    onClose,
    onSelect,
}: SelectGroupParentDialogProps) {
    const cxt = useContext(AppContext)!;
    const { year } = useQuery();

    const [searchForm, setSearchForm] = useState('');

    const [getGroupsPage, setGetGroupsPage] = useState(1);
    const [getGroupsFilters, setGetGroupsFilters] = useState({
        name: '',
    });

    const scsService = useScsService();
    const getScParentGroupsQuery = scsService.useGetScsGroups(
        cxt.sc!.id,
        {
            year: Number(year) || moment().get('year'),
            types: 'NORMAL,ABONADOS',
            page: getGroupsPage,
            rows: 7,
            ...getGroupsFilters,
        },
        {
            select: (data) => ({
                ...data,
                rows: data.rows.filter((row) => row.id !== groupId),
            }),
        },
    );

    // Handler to ensure page is reset to the first page when the filter is changed
    const handleOnFilter = ({
        name,
        value,
    }: {
        name: string;
        value: string;
    }) => {
        setGetGroupsPage(1);
        setGetGroupsFilters((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    if (!open) {
        return null;
    }

    if (getScParentGroupsQuery.isError) {
        return (
            <SttDialog
                open
                // @ts-expect-error Migrate SttDialog
                minHeight="60%"
                // @ts-expect-error Migrate SttDialog
                maxHeight="60%"
                onClose={onClose}
                maxWidth="md"
                content={
                    <Box paddingY={12}>
                        <SttError />
                    </Box>
                }
            />
        );
    }

    return (
        <SttDialog
            open
            // @ts-expect-error Migrate SttDialog
            minHeight="60%"
            // @ts-expect-error Migrate SttDialog
            maxHeight="60%"
            onClose={onClose}
            maxWidth="md"
            content={
                <>
                    <SttFormSearch
                        defVal={searchForm}
                        onClean={() => {
                            setSearchForm('');
                            handleOnFilter({ name: 'name', value: '' });
                        }}
                        onChange={({ value }) => {
                            setSearchForm(value);
                        }}
                        onClickSearch={() => {
                            handleOnFilter({
                                name: 'name',
                                value: searchForm,
                            });
                        }}
                    />

                    {getScParentGroupsQuery.isLoading && (
                        <Box
                            display="flex"
                            justifyContent="center"
                            paddingY={25}
                        >
                            <CircularProgress />
                        </Box>
                    )}

                    {getScParentGroupsQuery.isSuccess &&
                        getScParentGroupsQuery.data.count > 0 && (
                            // @ts-expect-error SttList
                            <SttList
                                onSelect={(row: Group) => onSelect(row)}
                                data={getScParentGroupsQuery.data.rows.map(
                                    (row) => ({
                                        caption: (
                                            <Grid container>
                                                <Grid item md={8}>
                                                    {row.name}
                                                    {isDefined(
                                                        row.numActiveUsers,
                                                    ) && (
                                                        <Box
                                                            ml={2}
                                                            display="inline"
                                                        >
                                                            <Chip
                                                                label={
                                                                    row.numActiveUsers
                                                                }
                                                                size="small"
                                                            />
                                                        </Box>
                                                    )}
                                                </Grid>
                                                <Grid item md={3}>
                                                    <SttLabelPeriod
                                                        ini={row.ini}
                                                        end={row.end}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ),
                                        text: row.description,
                                        objToSelect: row,
                                    }),
                                )}
                            />
                        )}

                    {getScParentGroupsQuery.data?.count === 0 && (
                        <Box paddingY={12}>
                            <SttNoResults />
                        </Box>
                    )}
                </>
            }
            buttons={
                getScParentGroupsQuery.isSuccess &&
                getScParentGroupsQuery.data.count > 0 && [
                    {
                        type: 'component',
                        show: true,
                        component: (
                            <Box m="auto">
                                <SttPagination
                                    page={getGroupsPage}
                                    pages={getScParentGroupsQuery.data.pages}
                                    count={getScParentGroupsQuery.data.count}
                                    onChangePage={(page) => {
                                        setGetGroupsPage(page);
                                    }}
                                />
                            </Box>
                        ),
                    },
                ]
            }
        />
    );
}

type GroupParent = {
    id: number;
    name: string;
};

type OnChangeProps = {
    name: string;
    value: GroupParent | null;
};

type SelectGroupParentProps = {
    groupId: number;
    groupParent: GroupParent | null;
    onChange: ({ name, value }: OnChangeProps) => void;
    caption: string;
};

export default function SelectGroupParent({
    groupId,
    groupParent,
    onChange,
    caption,
}: SelectGroupParentProps) {
    const cxt = useContext(AppContext)!;

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    function onChangeGroupParent(selectedGroup: Group | null) {
        onChange({
            name: 'parent',
            value: selectedGroup
                ? { id: selectedGroup.id, name: selectedGroup.name! }
                : null,
        });
    }

    return (
        <>
            {isDialogOpen && (
                <SelectGroupParentDialog
                    open={isDialogOpen}
                    groupId={groupId}
                    onClose={() => {
                        setIsDialogOpen(false);
                    }}
                    onSelect={(selectedGroup) => {
                        setIsDialogOpen(false);
                        onChangeGroupParent(selectedGroup);
                    }}
                />
            )}

            <TextField
                label={caption}
                fullWidth
                size="small"
                variant="outlined"
                value={
                    groupParent?.name ?? cxt.t(translations.generic.anyGroup)
                }
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => {
                                    onChangeGroupParent(null);
                                }}
                            >
                                <ClearIcon />
                            </IconButton>

                            <IconButton
                                onClick={() => {
                                    setIsDialogOpen(true);
                                }}
                            >
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </>
    );
}
