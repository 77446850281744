import React, { useEffect, useContext } from 'react';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import { AppContext } from '../../../../sporttia/all';
import { useLoader } from '../../../../lib/hooks';
import useApisService from '../../../../services/ApisService';
import SttValidatedForm from '../../../../sporttia/SttValidatedForm';

/**
 * API Model details view. View with form to modify api model data.
 * @param apiModel API Model object.
 * @param apiVersion API Model version.
 * @param status React-query status.
 * @param onCreate Launched when a new customer is created.
 * @param onUpdate Launched when a new customer is updated.
 * @param onDelete Launched when a new customer is removed.
 * @returns {JSX.Element}
 */
export default function AdminApiModelDetails({
    apiModel,
    apiVersion,
    status,
    onCreate,
    onUpdate,
    onDelete,
}) {
    const cxt = useContext(AppContext);
    const apisService = useApisService();
    const deleteMutation = useMutation(
        (params) => apisService.removeApiModel(params?.id),
        {
            onSuccess: onDelete,
        },
    );
    const updateMutation = useMutation(
        (params) => apisService.updateApiModel(params?.id, params?.params),
        {
            onSuccess: onUpdate,
        },
    );
    const createMutation = useMutation(
        (params) => apisService.createApiModel(params?.params),
        {
            onSuccess: (data) => onCreate(data?.apiModel?.id),
        },
    );
    const form = useForm();
    const [, loader] = useLoader([
        deleteMutation.status,
        updateMutation.status,
        createMutation.status,
    ]);

    /**
     * Method executed when the user clicks on save or create api model.
     */
    const mutateApiModel = (formData) => {
        if (apiModel?.id) {
            updateMutation.mutate({ id: apiModel?.id, params: formData });
        } else {
            createMutation.mutate({ params: formData });
        }
    };

    /**
     * When the data is returned by the API, it is set in the form.
     */
    useEffect(() => {
        if (status === 'success' && apiModel) {
            form.reset({
                id: apiModel?.id,
                idVersion: apiVersion,
                name: apiModel?.name,
                description: apiModel?.description,
                example: apiModel?.example,
            });
        } else {
            form.reset({
                id: '',
                idVersion: apiVersion,
                name: '',
                description: '',
                example: '',
            });
        }
    }, [apiModel]);

    return (
        <>
            {loader}
            <SttValidatedForm
                form={form}
                loadingData={status === 'loading'}
                fields={[
                    {
                        type: 'textInput',
                        name: 'id',
                        caption: 'ID',
                        disabled: true,
                    },
                    {
                        type: 'select',
                        name: 'idVersion',
                        caption: cxt.t('Version'),
                        options: [
                            { caption: 'v6', value: 3 },
                            { caption: 'v5', value: 1 },
                        ],
                        disableClearable: true,
                        disabled: true,
                    },
                    {
                        type: 'textInput',
                        name: 'name',
                        caption: cxt.t('Name'),
                    },
                    {
                        type: 'textInput',
                        name: 'description',
                        caption: cxt.t('Description'),
                    },
                    {
                        type: 'textArea',
                        name: 'example',
                        caption: cxt.t('Example'),
                        rows: 20,
                    },
                ]}
                buttons={[
                    {
                        show: !apiModel?.trash,
                        type: 'save',
                        onClick: form.handleSubmit((formData) =>
                            mutateApiModel(formData),
                        ),
                    },
                    {
                        show: apiModel?.id && !apiModel?.trash,
                        type: 'delete',
                        onClick: () =>
                            deleteMutation.mutate({ id: apiModel?.id }),
                    },
                ]}
            />
        </>
    );
}
