import React, { useState, useContext } from 'react';
import { AppContext } from '../../../sporttia/all';
import DeviceAuthDialog from '../../../components/devices/DeviceAuthDialog';
import DeviceList from '../../../components/devices/DeviceList';
import deviceConstants from '../../../components/devices/deviceConstants';

/**
 * GroupDevices : Group screen devices tab section. Contains the list of authorized devices for this group
 * params:
 *  Object item - the group
 */
export default function GroupDevices({ item }) {
    const [auths, setAuths] = useState([]); // auth rows
    const [viewAuth, setViewAuth] = useState(null); // auth to open in the DeviceAuthDialog

    const cxt = useContext(AppContext);

    // Load from API
    function loadAuths() {
        cxt.api('GET', `/groups/${item.id}/devices/auths`, {
            params: { rows: 25, page: 1 },
            success: (result) => {
                if (result.rows) {
                    setAuths(result);
                }
            },
        });
    }

    return (
        <div>
            <DeviceList
                item={item}
                type={deviceConstants.GROUP}
                auths={auths}
                loaderFunction={loadAuths}
                onCreate={(device) => setViewAuth(device)}
                onSelect={(device) => setViewAuth(device)}
            />
            {viewAuth && (
                <DeviceAuthDialog
                    open
                    item={viewAuth}
                    onSave={loadAuths}
                    onClose={() => setViewAuth(null)}
                />
            )}
        </div>
    );
}
