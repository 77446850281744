import React, { useState, useContext } from 'react';
import moment from 'moment';
import { Close } from '@material-ui/icons';
import { useQueryClient } from 'react-query';
import {
    AppContext,
    SttChipUser,
    SttTable,
    SttTopControls,
} from '../../../sporttia/all';
import ActivityClassDialog from './ActivityClassDialog';
import useActivitiesService from '../../../services/ActivitiesService';
import DateBuilder from '../../../lib/DateBuilder';

/**
 * ActivityClasses - 'Classes' tab in the Activity screen
 * Object item - The Activity
 * Array teachers - A list of teachers from this Sports Center
 */
export default function ActivityClasses({ item, teachers = [] }) {
    const queryClient = useQueryClient();

    // Selected class to display in the modal
    const [selected, setSelected] = useState(null);

    const cxt = useContext(AppContext);
    const [classesQueryParams, setClassesQueryParams] = useState({
        page: 1,
        rows: 20,
    });

    const { useGetActivityClasses } = useActivitiesService();

    const classesQuery = useGetActivityClasses(item?.id, classesQueryParams);
    function deleteClasses(rows) {
        if (rows.length > 0) {
            cxt.api('POST', '/batch', {
                confirmation: true,
                params: {
                    calls: rows.map((c) => ({
                        method: 'DELETE',
                        endpoint: `/classes/${c.id}`,
                    })),
                },
                success: () => {
                    queryClient.invalidateQueries(classesQuery.queryKey);
                },
            });
        }
    }

    return (
        <>
            <SttTopControls
                mb={2}
                mainAction={{
                    type: 'create',
                    onClick: () =>
                        setSelected({
                            id: null,
                            status: 'ACTIVE',
                            ini: moment(item.ini).format('YYYY-MM-DD 00:00:00'),
                            end: moment(item.end).format('YYYY-MM-DD 23:55:55'),
                            weekdays: [],
                        }),
                }}
            />
            <SttTable
                data={classesQuery?.data}
                onChangePagination={(page) =>
                    setClassesQueryParams({ ...classesQueryParams, page })
                }
                selection={{
                    actions: [
                        {
                            caption: cxt.t('Delete'),
                            icon: <Close />,
                            onClick: deleteClasses,
                        },
                    ],
                }}
                onFetch={() => {}}
                columns={[
                    {
                        title: cxt.t('Created'),
                        name: 'created',
                        field: 'created',
                        value: (row) =>
                            new DateBuilder(
                                row.created,
                                cxt?.sc?.timezone?.name,
                                cxt?.lang?.key,
                            ).dmy(),
                    },
                    {
                        title: cxt.t('Name'),
                        name: 'name',
                        field: 'name',
                        type: 'link',
                        onClick: (row) => setSelected(row),
                    },
                    {
                        title: cxt.t('Teacher'),
                        name: 'teacher',
                        field: 'teacher',
                        value: (row) =>
                            row.teacher ? (
                                <SttChipUser
                                    user={{ login: row.teacher.name }}
                                />
                            ) : null,
                    },
                    {
                        title: cxt.t('Facility'),
                        name: 'facility',
                        type: 'facility',
                        field: 'facility',
                    },
                    {
                        title: cxt.t('Day'),
                        name: 'day',
                        value: (row) => moment(row.ini).format('ddd'),
                    },
                    {
                        title: cxt.t('Date'),
                        name: 'date',
                        type: 'dmy',
                        field: 'ini',
                    },
                    {
                        title: cxt.t('Time'),
                        name: 'time',
                        type: 'periodTime',
                        value: (row) => ({ ini: row.ini, end: row.end }),
                    },
                ]}
            />
            <ActivityClassDialog
                open={selected !== null}
                item={selected}
                activity={item}
                onSave={() => {
                    classesQuery.refetch();
                }}
                onClose={() => setSelected(false)}
                availableTeachers={teachers}
            />
        </>
    );
}
