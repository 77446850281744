import React, { useState, useEffect, useContext } from 'react';
import {
    Container,
    Paper,
    Box,
    Switch,
    Grid,
    FormControlLabel,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid';
import {
    AppContext,
    SttFullDialog,
    SttFormTextarea,
    SttButton,
    SttButtonCancel,
    SttList,
    SttUploadDialog,
} from '../../../sporttia/all';
import Receptors from './Receptors';
import { deleteElementFromArray } from '../../../lib/utils';
import NewMessageResultDialog from './NewMessageResultDialog';

export default function NewMessageDialog({ open = false, onClose }) {
    const cxt = useContext(AppContext);
    const [text, setText] = useState('');
    const [shortText, setShortText] = useState('');
    const [sendAllSC, setSendAllSC] = useState(false);
    const [SMSCount, setSMSCount] = useState(0);
    const [receptors, setReceptors] = useState([]); // {caption, user, group}

    // Attach file
    const [uploadFileOpen, setUploadFileOpen] = useState(false);
    const [attachedFiles, setAttachedFiles] = useState([]);

    // Sent
    const [messageSent, setMessageSent] = useState(false);
    const [errors, setErrors] = useState();

    /**
     * Send message
     */
    const sendMessage = () => {
        const groups = receptors
            .filter((r) => r.group != undefined)
            .map((r) => r.group.id);

        const users = receptors
            .filter((r) => r.mship != undefined)
            .map((r) => r.mship.user.id);

        // Call API
        cxt.api('POST', '/messages', {
            params: {
                text,
                short: shortText,
                scs: sendAllSC ? [cxt.sc.id] : null,
                groups: !sendAllSC ? groups : null,
                users: !sendAllSC ? users : null,
                files: attachedFiles.map((file) => file.id),
            },
            success: (r) => {
                setMessageSent(true);
                setErrors(r.errors);
            },
        });
    };

    /**
     * Clean all
     */
    const cleanAll = () => {
        setText('');
        setShortText('');
        setReceptors([]);
        setAttachedFiles([]);
    };

    /**
     * Add receptor
     */
    const addReceptor = (receptor) => {
        setReceptors([...receptors, receptor]);
    };

    /**
     * Delete receptor
     */
    const delReceptor = (receptor) => {
        setReceptors(deleteElementFromArray(receptors, receptor));
    };

    /**
     * Short text changes
     */
    useEffect(() => {
        setSMSCount(shortText.length);
    }, [shortText]);

    /**
     * Open this dialog
     */
    useEffect(() => {
        if (open) cleanAll();
    }, [open]);

    // -----| Render |-----
    return (
        <>
            <SttFullDialog
                title={cxt.t('Message')}
                open={open}
                onClose={onClose}
                buttons={[
                    {
                        caption: cxt.t('CleanAll'),
                        icon: <FlipCameraAndroidIcon />,
                        onClick: () => cleanAll(),
                    },
                ]}
            >
                <Container>
                    <Box mt={3}>
                        <Grid container spacing={3}>
                            <Grid item md={8} sm={8} xs={12}>
                                <Paper>
                                    <Box p={3}>
                                        <SttFormTextarea
                                            rows={13}
                                            helperText={cxt.t(
                                                'sc.user.emailText',
                                            )}
                                            caption={cxt.t('Text')}
                                            defVal={text}
                                            onChange={({ value }) =>
                                                setText(value)
                                            }
                                        />

                                        {/* <Box mt={2}>
											<SttFormTextarea
												rows={3}
												helperText={ cxt.t('sc.user.smsText') }
												caption={`SMS / Push Notification - [ ${SMSCount} / 160 ]`}
												defVal={shortText}
												onChange={({value}) => setShortText(value)}
											/>
										</Box> */}
                                    </Box>
                                </Paper>
                            </Grid>

                            <Grid item md={4} sm={4} xs={12}>
                                <Receptors
                                    receptors={receptors}
                                    sendAllSC={sendAllSC}
                                    setSendAllSC={setSendAllSC}
                                    onAddReceptor={addReceptor}
                                    onDelReceptor={delReceptor}
                                />

                                <Box mt={2}>
                                    <SttList
                                        paper
                                        title={cxt.t('Attached')}
                                        onAdd={() => setUploadFileOpen(true)}
                                        onDelete={(file) =>
                                            setAttachedFiles(
                                                deleteElementFromArray(
                                                    attachedFiles,
                                                    file,
                                                ),
                                            )
                                        }
                                        data={attachedFiles.map((file) => ({
                                            caption: file.name,
                                            objToSelect: file,
                                        }))}
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        <Box mt={3}>
                            <Grid container spacing={3}>
                                <Grid item md={3} sm={6} xs={6}>
                                    <SttButton
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        endIcon={<SendIcon />}
                                        onClick={() => sendMessage()}
                                    >
                                        {cxt.t('Send')}
                                    </SttButton>
                                </Grid>

                                <Grid item md={3} sm={6} xs={6}>
                                    <SttButtonCancel
                                        fullWidth
                                        onClick={() => onClose()}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </SttFullDialog>

            <NewMessageResultDialog
                open={messageSent}
                errors={errors}
                onClose={() => {
                    setMessageSent(false);
                    cleanAll();
                }}
            />

            <SttUploadDialog
                open={uploadFileOpen}
                types={['SC']}
                onClose={() => setUploadFileOpen(false)}
                onUploaded={(r) => {
                    const { file } = r;
                    setUploadFileOpen(false);
                    setAttachedFiles([...attachedFiles, file]);
                }}
            />
        </>
    );
}
