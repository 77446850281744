import React, { useContext } from 'react';
import { IconButton } from '@material-ui/core';
import { Delete, VpnKey } from '@material-ui/icons';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import { AppContext } from '../../AppContext';

export function SttSportCard({ sport, onDelete }) {
    const cxt = useContext(AppContext);

    return (
        <>
            {sport && (
                <Card>
                    <CardHeader
                        avatar={
                            <Avatar
                                src={cxt.getResource(`/sports/${sport.id}.png`)}
                            />
                        }
                        action={
                            <IconButton
                                aria-label="remove"
                                onClick={() => onDelete(sport.id)}
                            >
                                <Delete />
                            </IconButton>
                        }
                        title={sport.name}
                    />
                </Card>
            )}
        </>
    );
}
