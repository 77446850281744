import React, { useState, useEffect, useContext } from 'react';
import { Chip, Link } from '@material-ui/core';
import moment from 'moment';
import { AppContext, SttTable, SttTopControls } from '../../../sporttia/all';
import { useCrud, usePageHeader } from '../../../lib/hooks';
import BookingDialog from '../../../components/dialogs/bookingDialog/BookingDialog';
import Group from '../groups/Group';
import Event from '../events/Event';
import Activity from '../activities/Activity';
import Bono from '../bonos/Bono';

export default function DeviceAuths({ id }) {
    usePageHeader('Authorizations');

    const cxt = useContext(AppContext);

    const [deviceAuths, setDeviceAuths, , Get] = useCrud();
    const [dateFilter, setDateFilter] = useState(
        moment(new Date()).format('YYYY-MM-DD'),
    );
    const [bookingId, setBookingId] = useState(null);
    const [eventId, setEventId] = useState(null);
    const [activityId, setActivityId] = useState(null);
    const [groupId, setGroupId] = useState(null);
    const [bonoId, setBonoId] = useState(null);

    useEffect(() => {
        // eslint-disable-next-line no-use-before-define
        loadDeviceAuths();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFilter]);

    function loadDeviceAuths(params = { rows: 20 }) {
        Get(`/devices/${id}/auths`, {
            ...params,
            allDay: true,
            date: dateFilter,
        }).then(setDeviceAuths);
    }

    return (
        <>
            <SttTopControls
                p={2}
                fields={[
                    {
                        caption: cxt.t('Date'),
                        type: 'date',
                        name: 'date',
                        value: dateFilter,
                        onChange: ({ value }) =>
                            setDateFilter(
                                value ? moment(value).format('YYYY-MM-DD') : '',
                            ),
                    },
                ]}
            />
            <SttTable
                autoload={false}
                onFetch={loadDeviceAuths}
                data={deviceAuths}
                columns={[
                    {
                        title: cxt.t('Date'),
                        type: 'period',
                        value: (row) => ({
                            ini: row.dateini,
                            end: row.dateend,
                        }),
                    },
                    {
                        title: cxt.t('Time'),
                        type: 'text',
                        value: (row) =>
                            `${row.timeini.slice(0, -3)} - ${row.timeend.slice(
                                0,
                                -3,
                            )}`,
                    },
                    {
                        title: cxt.t('Weekdays'),
                        type: 'weekdays',
                        field: 'weekdays',
                    },
                    {
                        title: cxt.t('Type'),
                        value: (row) => {
                            switch (row.type) {
                                case 'BK':
                                    return (
                                        <Chip
                                            size="small"
                                            label={cxt.t('Booking')}
                                        />
                                    );
                                case 'EV':
                                    return (
                                        <Chip
                                            size="small"
                                            label={cxt.t('Event')}
                                        />
                                    );
                                case 'AC':
                                    return (
                                        <Chip
                                            size="small"
                                            label={cxt.t('Activity')}
                                        />
                                    );
                                case 'GP':
                                    return (
                                        <Chip
                                            size="small"
                                            label={cxt.t('Group')}
                                        />
                                    );
                                case 'BN':
                                    return (
                                        <Chip
                                            size="small"
                                            label={cxt.t('Bono')}
                                        />
                                    );
                                default:
                            }
                        },
                    },
                    {
                        title: cxt.t('Name'),
                        type: 'text',
                        value: (row) => {
                            switch (row.type) {
                                case 'BK':
                                    return (
                                        <Link
                                            onClick={() =>
                                                setBookingId(row?.booking?.id)
                                            }
                                        >
                                            {row?.booking?.name}
                                        </Link>
                                    );
                                case 'EV':
                                    return (
                                        <Link
                                            onClick={() =>
                                                setEventId(row?.event?.id)
                                            }
                                        >
                                            {row?.event?.name}
                                        </Link>
                                    );
                                case 'AC':
                                    return (
                                        <Link
                                            onClick={() =>
                                                setActivityId(row?.activity?.id)
                                            }
                                        >
                                            {row?.activity?.name}
                                        </Link>
                                    );
                                case 'GP':
                                    return (
                                        <Link
                                            onClick={() =>
                                                setGroupId(row?.group?.id)
                                            }
                                        >
                                            {row?.group?.name}
                                        </Link>
                                    );
                                case 'BN':
                                    return (
                                        <Link
                                            onClick={() =>
                                                setBonoId(row?.bono?.id)
                                            }
                                        >
                                            {row?.bono?.name}
                                        </Link>
                                    );
                                default:
                            }
                        },
                    },
                ]}
            />

            <BookingDialog
                idBooking={bookingId}
                onUpdate={() => loadDeviceAuths()}
                onClose={() => setBookingId(null)}
            />

            <Event
                id={eventId}
                trash
                onChange={() => loadDeviceAuths()}
                onClose={() => {
                    setEventId(null);
                }}
            />

            <Activity
                id={activityId}
                trash
                onChange={() => loadDeviceAuths()}
                onClose={() => {
                    setActivityId(null);
                }}
            />

            {groupId && (
                <Group
                    id={groupId}
                    trash
                    onChange={() => loadDeviceAuths()}
                    onClose={() => {
                        setGroupId(null);
                    }}
                />
            )}

            <Bono
                id={bonoId}
                trash={false}
                // Apply changes locally to avoid subsequent petitions (once change is applied, remove items marked with trash)
                onClose={() => {
                    setBonoId(null);
                }}
                onChange={() => {
                    loadDeviceAuths();
                }}
            />
        </>
    );
}
