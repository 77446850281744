import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { AppContext } from '../../sporttia/AppContext';
import { useCrud } from '../../lib/hooks';
import { SttDialog } from '../../sporttia/dialogs/SttDialog';
import { SttFormCheck } from '../../sporttia/forms/SttFormCheck';

export default function BookingsConsentsPopUp({ scId, onResponse }) {
    const cxt = useContext(AppContext);
    const [consents, setConsents, , Get, , ,] = useCrud();
    const [acceptConsents, setAcceptConsents] = useState(true);
    const [consentsResponses, setConsentsResponses] = useState([]);

    /**
     * Init
     */
    useEffect(() => {
        if (cxt.logged && cxt.user.role !== 'SPORTCENTER') {
            loadConsents();
        } else {
            setAcceptConsents(true);
            onResponse(true);
        }
    }, []);

    /**
     * Load constents.
     * @param params Object with params like rows, page...
     */
    function loadConsents(params = { rows: 20 }) {
        Get(`/scs/${scId}/consents/templates`, {
            ...params,
            forBooking: true,
        }).then((response) => {
            if (response.count !== 0) {
                setConsents(response.rows);
                setAcceptConsents(false);
                setConsentsResponses(Array(response.count).fill(false));
            } else {
                setAcceptConsents(true);
                onResponse(true);
            }
        });
    }

    /**
     * Change ConsentsResponses values.
     * @param index key value.
     * @param value value.
     */
    const updateConsentsResponses = (index, value) => {
        const newArr = [...consentsResponses];
        newArr[index] = value;

        setConsentsResponses(newArr);
    };

    /**
     * Check if array values are equals.
     * @param arr Array.
     * @returns {*} True or False.
     */
    const allEqual = (arr) => arr.every((v) => v === arr[0] && v === true);

    return (
        <SttDialog
            open={!acceptConsents}
            title={cxt.t('Consents')}
            onClose={() => {
                onResponse(false);
                setAcceptConsents(false);
            }}
            content={
                consents &&
                consents.map((consent, key) => (
                    <Box>
                        <SttFormCheck
                            name="forBooking"
                            checked={consentsResponses[key]}
                            onChange={() =>
                                updateConsentsResponses(
                                    key,
                                    !consentsResponses[key],
                                )
                            }
                        />
                        <b>{consent.name} </b>
                        <Typography
                            variant="body2"
                            align="justify"
                            gutterBottom
                        >
                            {consent.text}
                        </Typography>
                    </Box>
                ))
            }
            buttons={[
                {
                    type: 'accept',
                    disabled: !allEqual(consentsResponses),
                    caption: cxt.t('Accept'),
                    onClick: () => {
                        onResponse(true);
                        setAcceptConsents(false);
                    },
                },
                {
                    type: 'cancel',
                    onClick: () => {
                        onResponse(false);
                        setAcceptConsents(false);
                    },
                },
            ]}
        />
    );
}
