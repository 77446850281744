import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AppContext } from '../../../sporttia/all';
import { useTranslations, useLoader } from '../../../lib/hooks';
import SttValidatedForm from '../../../sporttia/SttValidatedForm';
import useTpvsService from '../../../services/TpvsService';
import { isObject } from '../../../types/common';
import { CreateTPVConfirmationFix200Body } from '../../../types/api/paths/Tpv';
import constants from '../../../config/constants';
import translations from '../../../translations';
import Error from '../../../types/models/Error';

export default function AdminFixTPV() {
    const { translate } = useTranslations();

    const cxt = useContext(AppContext)!;

    useEffect(() => {
        cxt.setHeader({ title: 'Arreglar TPV' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const form = useForm({
        defaultValues: {
            paymentForm: constants.payment.paymentForms.bank.name,
            idTPV: 0,
        },
    });
    const tpvService = useTpvsService();
    const { mutate: mutateTPVConfirmationFix, status } =
        tpvService.useCreateTPVConfirmationFix({
            onError: (res: Error) => {
                if (
                    !isObject(res.error) ||
                    !(typeof res.error.msg === 'string')
                ) {
                    return;
                }
                // ¿Es necesario traducir `error.description`?
                cxt.showMessage('E', res.error.msg, 3000);
            },
            onSuccess: () => {
                cxt.showMessage('S', 'Operación realizada con éxito');
            },
        });
    const [, loader] = useLoader([status === 'error' ? 'idle' : status]);

    return (
        <>
            {loader}
            <SttValidatedForm
                form={form}
                fields={[
                    {
                        type: 'textInput',
                        name: 'idTPV',
                        caption: 'Id del tpv',
                        md: 3,
                    },
                    {
                        type: 'select',
                        name: 'paymentForm',
                        caption: translate(translations.payment.paymentForm),
                        options: [
                            {
                                caption: translate(
                                    constants.payment.paymentForms.dataphone
                                        .translationCode,
                                ),
                                value: constants.payment.paymentForms.dataphone
                                    .name,
                            },
                            {
                                caption: translate(
                                    constants.payment.paymentForms.tpv
                                        .translationCode,
                                ),
                                value: constants.payment.paymentForms.tpv.name,
                            },
                        ],
                        disableClearable: true,
                        md: 4,
                    },
                ]}
                buttons={[
                    {
                        show: true,
                        type: 'accept',
                        caption: 'Arreglar',
                        onClick: form.handleSubmit(
                            (formData: CreateTPVConfirmationFix200Body) => {
                                mutateTPVConfirmationFix(formData);
                            },
                        ),
                    },
                ]}
            />
        </>
    );
}
