import React, { useState } from 'react';
import { Box, Link } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import moment from 'moment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Mship from '../../pages/sc/mships/Mship';
import userImage from '../../images/user.svg';

const useStyles = makeStyles({
    container: {
        position: 'fixed',
        right: 32,
        bottom: 32,
    },
    item: {
        position: 'relative',
        backgroundColor: '#e9f6e9',
        border: '1px solid #7fcb7f',
        borderRadius: 6,
        padding: 16,
        display: 'flex',
        paddingRight: 32,
        marginTop: 8,
        marginBottom: 8,
    },
    close: {
        position: 'absolute',
        cursor: 'pointer',
        right: 8,
        top: 8,
    },
    img: {
        width: 96,
        height: 96,
        marginRight: 12,
    },
});

const accessTypes = {
    BOOKING: 'BOOKING',
    CLASS: 'ACTIVITY',
    GROUP: 'GROUP',
    BONO: 'BONO',
};

/**
 * Componente que pinta los accesos de usuarios según van entrando por el torno, puerta, etc.
 * @param visible
 * @param messages array de mensajes de entrada (se van eliminando solos cada 10 segundos)
 * @param onDelete
 * @returns {JSX.Element|null}
 */
export default function UserAccessPopup({ visible, messages, onDelete }) {
    const [mshipOpen, setMshipOpen] = useState(null);

    const classes = useStyles();

    function accessTypeString(access) {
        if (!access) {
            return '';
        }

        switch (access?.type) {
            case accessTypes.BOOKING:
                return `${access?.booking?.field?.name} ${moment(
                    access?.booking?.ini,
                ).format('HH:mm')} - ${moment(access?.booking?.end).format(
                    'HH:mm',
                )}`;
                break;
            case accessTypes.GROUP:
                return `${access?.group?.name}`;
                break;
            case accessTypes.CLASS:
                return `${access?.activity?.name} ${moment(
                    access?.activity?.ini,
                ).format('HH:mm')} - ${moment(access?.activity?.end).format(
                    'HH:mm',
                )}`;
                break;
            case accessTypes.BONO:
                return `${access?.bono?.name} (${access?.bono?.consumed} / ${access?.bono?.amount})`;
                break;
            default:
                return 'Acceso concedido';
                break;
        }
    }

    if (visible || mshipOpen) {
        return mshipOpen ? (
            <Mship open id={mshipOpen} onClose={() => setMshipOpen(false)} />
        ) : (
            <div className={classes.container}>
                {messages.map((message, i) => (
                    <div className={classes.item} key={i}>
                        <Box>
                            {message?.mship?.img?.url ? (
                                <img
                                    className={classes.img}
                                    src={message?.mship?.img?.url}
                                />
                            ) : (
                                <img className={classes.img} src={userImage} />
                            )}
                        </Box>
                        <Box>
                            <Link
                                onClick={() => setMshipOpen(message?.mship?.id)}
                                style={{ fontSize: 18, marginBottom: 8 }}
                            >
                                {message?.mship?.fullName}
                            </Link>
                            <Box my={1} style={{ fontSize: 16, color: '#333' }}>
                                {accessTypeString(message?.access)}
                            </Box>
                            <div style={{ fontSize: 16, color: '#666' }}>
                                {message?.device?.name}
                            </div>
                        </Box>
                        <Close
                            onClick={() => onDelete(message)}
                            className={classes.close}
                        />
                    </div>
                ))}
            </div>
        );
    }

    return null;
}
