import React, { useState, useContext } from 'react';
import { Paper } from '@material-ui/core';
import { AppContext, SttButtonFab } from '../../../../sporttia/all';
import { usePageHeader, useQueryFilters } from '../../../../lib/hooks';
import AdminApiModel from './AdminApiModel';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';
import { SUPERADMIN_API_MODELS } from '../../../../lib/queryKeys';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import useApisService from '../../../../services/ApisService';

/**
 * View where the sporttia superadmin can see the list of apis models.
 * @returns {JSX.Element}
 */
export default function AdminApiModels({}) {
    const cxt = useContext(AppContext);
    usePageHeader('apiModels');
    const apisService = useApisService();
    const [filters, setFilters] = useQueryFilters({}, SUPERADMIN_API_MODELS);
    const [apiModelId, setApiModelId] = useState(null);

    return (
        <Paper>
            <SttTopFilteringControls
                p={2}
                fields={[
                    {
                        type: 'select',
                        caption: cxt.t('Version'),
                        name: 'idVersion',
                        value: 3,
                        options: [
                            {
                                caption: 'v6',
                                value: 3,
                            },
                            {
                                caption: 'v5',
                                value: 1,
                            },
                        ],
                    },
                    {
                        type: 'search',
                        caption: cxt.t('Name'),
                        name: 'name',
                        value: '',
                    },
                ]}
                onFilter={setFilters}
            />

            <SttCachedTable
                queryKey={SUPERADMIN_API_MODELS}
                queryFn={(params) => apisService.getApiModels(params)}
                queryParams={filters}
                onClickRow={(row) => setApiModelId(row?.id)}
                columns={[
                    {
                        title: cxt.t('Name'),
                        field: 'name',
                        align: 'left',
                        value: (row) => <b>{row?.name}</b>,
                    },
                    {
                        title: cxt.t('Description'),
                        field: 'description',
                        align: 'left',
                    },
                ]}
            />

            <AdminApiModel
                id={apiModelId}
                apiVersion={filters?.idVersion}
                onClose={() => setApiModelId(null)}
            />

            <SttButtonFab onClick={() => setApiModelId('create')} />
        </Paper>
    );
}
