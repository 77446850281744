import React, { useContext, useEffect, useState } from 'react';
import { Box, Chip, Avatar, Paper, Link } from '@material-ui/core';
import moment from 'moment';
import { CloudDownload as CloudDownloadIcon } from '@material-ui/icons';
import { useMutation } from 'react-query';
import {
    AppContext,
    SttButtonFab,
    SttChipUser,
    SttTopControls,
    SttTable,
} from '../../../sporttia/all';
import {
    useInteractionsFiles,
    useLoader,
    usePageHeader,
    useToggle,
} from '../../../lib/hooks';
import PaymentModal from '../../../components/paymentModal/PaymentModal';
import PaymentCreationDialog from '../../../components/dialogs/PaymentCreationDialog';
import { copyToClipboard, fullName, subsetObject } from '../../../lib/utils';
import Q60DownloadDialog from '../../../components/dialogs/Q60DownloadDialog';
import Mship from '../mships/Mship';
import DiscountTooltip from '../../../components/discounts/DiscountTooltip';
import DateBuilder from '../../../lib/DateBuilder';

const filterConfigurations = {
    NORMAL: {
        concept: '',
        userName: '',
        subpayments: false,
        usePeriodEnabled: false,
        paymentPeriodEnabled: false,
        useIni: moment().format('YYYY-MM-DD'),
        useEnd: moment().format('YYYY-MM-DD'),
        paidIni: moment().format('YYYY-MM-DD'),
        paidEnd: moment().format('YYYY-MM-DD'),
        paymentForms: '',
        withDiscounts: false,
    },
    CASH_CONTROL: {
        concept: '',
        userName: '',
        subpayments: false,
        usePeriodEnabled: false,
        paymentPeriodEnabled: true,
        useIni: moment().format('YYYY-MM-DD'),
        useEnd: moment().format('YYYY-MM-DD'),
        paidIni: moment().format('YYYY-MM-DD'),
        paidEnd: moment().format('YYYY-MM-DD'),
        paymentForms:
            'CASH,DATAFONO,DATAFONO_CONNECTED,TPV,BANK,RECEIPT,CASHDRO',
        withDiscounts: false,
    },
    PURSE: {
        concept: '',
        userName: '',
        subpayments: false,
        usePeriodEnabled: false,
        paymentPeriodEnabled: true,
        useIni: moment().format('YYYY-MM-DD'),
        useEnd: moment().format('YYYY-MM-DD'),
        paidIni: moment().format('YYYY-MM-DD'),
        paidEnd: moment().format('YYYY-MM-DD'),
        paymentForms: 'PURSE',
        withDiscounts: false,
    },
};

// TODO No está realizado de la mejor forma, hay que cambiar en el futuro, chapuza para entrega rápida.
const SCsWithXMLPermissions = ['2525', '3042', '3271', '3128'];

const getParamsToAPI = (filterParam) => {
    let params = subsetObject(
        filterParam,
        'concept,subpayments,userName,modules,status,idCreator,idCollector,withDiscounts',
    );

    if (params.modules === '') {
        delete params.modules;
    }

    if (filterParam.paymentForms !== '') {
        const paymentsType = filterParam.paymentForms.replace('PENDING', '');

        params = {
            ...params,
            paymentForms: paymentsType,
        };
    }

    // Add paid period
    if (filterParam.paymentPeriodEnabled) {
        params.paidIni = filterParam.paidIni;
        params.paidEnd = filterParam.paidEnd;
    }

    // Add use period
    if (filterParam.usePeriodEnabled) {
        params.useIni = filterParam.useIni;
        params.useEnd = filterParam.useEnd;
    }

    return params;
};

/*
 * Página de cobros /sc/payments.
 */
export default function Payments() {
    usePageHeader('Collections');

    const cxt = useContext(AppContext);
    const { downloadFile } = useInteractionsFiles();

    const [payments, setPayments] = useState(null);
    const [paymentId, setPaymentId] = useState(null);
    const [preset, setPreset] = useState('CASH_CONTROL');
    const [admins, setAdmins] = useState([]);
    const [creatingPayment, setCreatingPayment] = useState(false);
    const [filters, setFilters] = useState(filterConfigurations.CASH_CONTROL);
    const [loadingData, setLoadingData] = useState(false);
    const [openQ60Dialog, setOpenQ60Dialog] = useState(false);
    const [selectedMship, setSelectedMship] = useState(null);

    const [trash, toggleTrash] = useToggle(() => {
        // eslint-disable-next-line no-use-before-define
        loadPayments();
    });

    /**
     * Init
     *
     * Title & load administrators (used in 2 different filter fields)
     */

    /**
     * Accepts an optional parameter 'fltr' to enforce a set of filters regardless of 'filters' state. Used to enforce filterConfigurations.
     */
    function loadPayments(p = { page: 1, rows: 20 }, filterParam = filters) {
        setLoadingData(true);
        const params = getParamsToAPI(filterParam);

        // Call API
        cxt.api('GET', `/scs/${cxt.sc.id}/payments`, {
            params: {
                ...params,
                summatory: true,
                trash,
                page: p.page,
                rows: p.rows,
            },
            success: (r) => {
                setPayments(r);
                setLoadingData(false);
            },
        });
    }

    // If the preset changes, we must save the filters AND request the data manually
    useEffect(() => {
        const newFilters = filterConfigurations[preset];
        setFilters(newFilters);
        loadPayments(undefined, newFilters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preset]);

    /**
     * Convenient because we'll use the list of admins on 2 different filter fields that are rendered simultaneously and are thus prone to the 404 API bug
     */
    function loadAdmins() {
        cxt.api('GET', `/scs/${cxt.sc.id}/admins`, {
            success: (response) => setAdmins(response?.rows),
        });
    }

    useEffect(() => {
        loadAdmins();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generateORGTFilesMutation = useMutation(
        (bodyParams) =>
            cxt.api('POST', '/files/sgttxs', { params: bodyParams }),
        {
            onSuccess: () => {
                cxt.showMessage('S', 'Ficheros generados exitosamente.');
            },
            onError: () => {
                cxt.showMessage('E', 'Problema en la generación de ficheros');
            },
        },
    );

    function download(format) {
        const params = getParamsToAPI(filters);
        let type = '';
        let downloadURL = '';

        switch (format) {
            case 'pdf':
                type = 'application/pdf';
                break;
            case 'csv':
                type = 'text/csv';
                break;
            case 'xml':
                type = 'text/xml';
                break;
            default:
                break;
        }

        if (format === 'xml') {
            downloadURL = `/scs/${cxt.sc.id}/payments/navision.xml`;
        } else {
            downloadURL = `/scs/${cxt.sc.id}/payments.${format}`;
        }

        downloadFile(
            downloadURL,
            type,
            params,
            `${cxt.t('Payments')}.${format}`,
        );
    }

    function updatePayment(payment) {
        if (payment) {
            setPayments({
                ...payments,
                rows: payments.rows.map((p) =>
                    p.id === payment.id ? payment : p,
                ),
            });
        }
    }

    function deletePayment(payment) {
        if (payment) {
            setPayments({
                ...payments,
                count: payments.count - 1,
                rows: payments.rows.filter((p) => p.id !== payment.id),
            });
        }
    }
    const [, loader] = useLoader([
        generateORGTFilesMutation?.isLoading && 'loading',
    ]);
    return (
        <>
            {loader}
            <Paper>
                <SttTopControls
                    p={2}
                    onToggleTrash={toggleTrash}
                    fields={[
                        {
                            md: 8,
                            sm: 6,
                            caption: cxt.t('Concept'),
                            type: 'text',
                            name: 'concept',
                            value: filters.concept,
                            onEnterKey: loadPayments,
                        },
                    ]}
                    extraFields={[
                        // User name
                        {
                            md: 6,
                            sm: 6,
                            caption: cxt.t('User'),
                            name: 'userName',
                            type: 'text',
                            value: filters.userName,
                        },
                        // Payment status
                        {
                            md: 6,
                            sm: 6,
                            caption: cxt.t('Status'),
                            name: 'status',
                            type: 'selectAutocomplete',
                            value: filters.status,
                            labelField: 'label',
                            valueField: 'id',
                            options: cxt.constants.paymentStatuses,
                        },
                        // Date of use
                        {
                            xs: 12,
                            sm: 12,
                            md: 6,
                            caption: cxt.t('DateOfUse'),
                            type: 'period',
                            enabled: filters.usePeriodEnabled,
                            nameIni: 'useIni',
                            valIni: filters.useIni,
                            nameEnd: 'useEnd',
                            valEnd: filters.useEnd,
                            onToggle: (value) =>
                                setFilters({
                                    ...filters,
                                    usePeriodEnabled: value,
                                }),
                        },
                        // Date of payment
                        {
                            xs: 12,
                            sm: 12,
                            md: 6,
                            caption: cxt.t('DateOfPayment'),
                            type: 'period',
                            enabled: filters.paymentPeriodEnabled,
                            nameIni: 'paidIni',
                            valIni: filters.paidIni,
                            nameEnd: 'paidEnd',
                            valEnd: filters.paidEnd,
                            onToggle: (value) =>
                                setFilters({
                                    ...filters,
                                    paymentPeriodEnabled: value,
                                }),
                        },
                        // Payment form
                        {
                            caption: cxt.t('PaymentForm'),
                            name: 'paymentForms',
                            type: 'selectMulti',
                            value: filters.paymentForms,
                            sm: 12,
                            md: 12,
                            options:
                                cxt.constants &&
                                cxt.constants.paymentForms &&
                                cxt.constants.paymentForms.map((pf) => ({
                                    label: pf.label,
                                    value: pf.id === '' ? 'PENDING' : pf.id,
                                })),
                        },
                        // Creator (user)
                        {
                            caption: cxt.t('Creator'),
                            name: 'idCreator',
                            type: 'selectAutocomplete',
                            value: filters.idCreator,
                            options: admins,
                        },
                        // Collector (user)
                        {
                            caption: cxt.t('Collector'),
                            name: 'idCollector',
                            type: 'selectAutocomplete',
                            value: filters.idCollector,
                            options: admins,
                        },
                        // Sub payments
                        {
                            caption: cxt.t('ChildPayments'),
                            name: 'subpayments',
                            type: 'check',
                            checked: !!filters.subpayments,
                            onChange: ({ name, value }) =>
                                setFilters({ ...filters, [name]: +value }),
                        },
                        // With discounts
                        {
                            caption: cxt.t('Discounts'),
                            name: 'withDiscounts',
                            type: 'check',
                            checked: !!filters.withDiscounts,
                            onChange: ({ name, value }) =>
                                setFilters({
                                    ...filters,
                                    [name]:
                                        name === 'withDiscounts'
                                            ? !!value
                                            : value,
                                }),
                        },
                        // Module
                        {
                            caption: cxt.t('Module'),
                            name: 'modules',
                            type: 'selectMulti',
                            value: filters.modules,
                            sm: 8,
                            md: 8,
                            options: cxt.constants?.modules?.map((m) => {
                                // El back end entiende que el valor del modulo para eventos es TOURNAMENT
                                // EVENT falla ya que es un modulo que no está permitido
                                if (m.id === 'EVENT') {
                                    return {
                                        label: m.label,
                                        value: 'TOURNAMENT',
                                    };
                                }

                                return {
                                    label: m.label,
                                    value: m.id,
                                };
                            }),
                        },
                    ]}
                    menu={[
                        {
                            caption: `${cxt.t('Download')} PDF`,
                            onClick: () => download('pdf'),
                        },
                        {
                            caption: `${cxt.t('Download')} CSV`,
                            onClick: () => download('csv'),
                        },
                        (cxt?.sc?.id === 3444 || cxt?.sc?.id === 2401) && {
                            caption: `${cxt.t('Download')} ORGT`,
                            onClick: () => {
                                generateORGTFilesMutation.mutate({
                                    useIni: filters?.useIni,
                                    useEnd: filters?.useEnd,
                                    paymentForms: filters?.paymentForms,
                                    paymentStatus: filters?.status,
                                });
                            },
                        },
                        ...(SCsWithXMLPermissions.includes(
                            localStorage.getItem('idSC'),
                        )
                            ? [
                                  {
                                      caption: `${cxt.t('Download')} XML`,
                                      onClick: () => download('xml'),
                                  },
                              ]
                            : [{}]),
                        cxt.sc &&
                            cxt.sc.id === 2359 && {
                                caption: cxt.t('XabiaDocuments'),
                                onClick: () => setOpenQ60Dialog(true),
                            },
                    ]}
                    menuIcon={<CloudDownloadIcon />}
                    onChange={({ name, value }) => {
                        setFilters({ ...filters, [name]: value });
                    }}
                    onFilter={loadPayments}
                    loadingData={loadingData}
                    mainAction={{
                        type: 'select',
                        options: [
                            {
                                caption: cxt.t('Normal'),
                                value: 'NORMAL',
                            },
                            {
                                caption: cxt.t(
                                    'page.sc.collections.type.cashControl',
                                ),
                                value: 'CASH_CONTROL',
                            },
                            {
                                caption: cxt.t(
                                    'page.sc.collections.type.purse',
                                ),
                                value: 'PURSE',
                            },
                        ],
                        selected: preset,
                        onChange: (value) => setPreset(value),
                    }}
                />

                <SttTable
                    data={payments}
                    loading={loadingData}
                    perPage={20}
                    columns={[
                        {
                            title: '#',
                            type: 'tooltip',
                            value: (row) => ({ label: '#', value: row.id }),
                            onClick: (row) =>
                                copyToClipboard(row.id, () =>
                                    cxt.showMessage(
                                        'S',
                                        cxt.t('copiedToClipboard'),
                                    ),
                                ),
                        },
                        {
                            title: cxt.t('Created'),
                            align: 'center',
                            value: (row) => (
                                <>
                                    <SttChipUser user={row.creator} />
                                    <Box mt={1}>
                                        {new DateBuilder(
                                            row.created,
                                            cxt?.sc?.timezone?.name,
                                            cxt?.lang?.key,
                                        ).dmy()}
                                    </Box>
                                </>
                            ),
                        },
                        {
                            field: 'module',
                            title: cxt.t('Module'),
                            type: 'module',
                        },
                        {
                            title: `${cxt.t('User')} / ${cxt.t('Tutor')}`,
                            width: 160,
                            align: 'left',
                            value: (row) => {
                                if (!row.user?.mship) {
                                    return null;
                                }

                                return (
                                    <Link
                                        onClick={() =>
                                            setSelectedMship(row.user.mship)
                                        }
                                    >
                                        {fullName(row.user)}
                                    </Link>
                                );
                            },
                        },
                        {
                            title: cxt.t('Concept'),
                            align: 'left',
                            value: (row) => (
                                <>
                                    <Link onClick={() => setPaymentId(row.id)}>
                                        {row.concept}
                                    </Link>

                                    {row.children && (
                                        <Box mt={1}>
                                            <Chip
                                                size="small"
                                                avatar={
                                                    <Avatar>
                                                        {row.children.count}
                                                    </Avatar>
                                                }
                                                label={cxt.t('Children')}
                                            />
                                        </Box>
                                    )}

                                    {row.parent && (
                                        <Box mt={1}>
                                            <Chip
                                                size="small"
                                                label={cxt.t('Father')}
                                            />
                                        </Box>
                                    )}

                                    {row.discounts && (
                                        <DiscountTooltip
                                            discounts={row.discounts}
                                        />
                                    )}
                                </>
                            ),
                        },
                        {
                            title: cxt.t('Period'),
                            type: 'periodPlain',
                            value: (row) => ({ ini: row.ini, end: row.end }),
                        },
                        {
                            title: cxt.t('Paid'),
                            type: 'text',
                            value: (row) =>
                                row.paymentDate && (
                                    <>
                                        <SttChipUser user={row.collector} />
                                        <Box mt={1}>
                                            {moment(row.paymentDate).format(
                                                'D MMM YYYY',
                                            )}
                                        </Box>
                                    </>
                                ),
                        },
                        {
                            title: cxt.t('PForm'),
                            type: 'pf',
                            field: 'paymentForm',
                        },
                        {
                            title: cxt.t('Price'),
                            align: 'right',
                            type: 'price',
                            field: 'price',
                            value: (row) => ({
                                paid: row.status === 'PAID',
                                price: row.price,
                                priceShape: row.priceShape,
                            }),
                        },
                    ]}
                    totals={
                        payments && payments.summatory
                            ? [
                                  {
                                      index: 7,
                                      value: payments
                                          ? payments.summatory.total
                                          : null,
                                      adornment: cxt.sc.city.country
                                          .currencySymbol
                                          ? ` ${cxt.sc.city.country.currencySymbol}`
                                          : ' €',
                                  },
                              ]
                            : null
                    }
                    // Llamamos a la autoCarga con el 'preset' inicial
                    onFetch={loadPayments}
                />

                {paymentId && (
                    <PaymentModal
                        idPayment={paymentId}
                        onClose={() => setPaymentId(null)}
                        onPay={updatePayment}
                        onRecovered={updatePayment}
                        onRefund={loadPayments}
                        onDeleted={deletePayment}
                        onSave={updatePayment}
                    />
                )}

                <PaymentCreationDialog
                    open={creatingPayment}
                    onPaymentSuccess={() => {
                        setCreatingPayment(false);
                        loadPayments();
                    }}
                    onClose={() => setCreatingPayment(false)}
                />

                <Q60DownloadDialog
                    open={openQ60Dialog}
                    onClose={() => setOpenQ60Dialog(false)}
                    filters={getParamsToAPI(filters)}
                />

                <Mship
                    id={selectedMship?.id}
                    open={selectedMship !== null}
                    onClose={() => setSelectedMship(null)}
                />

                <SttButtonFab onClick={() => setCreatingPayment(true)} />
            </Paper>
        </>
    );
}
