import React, { useContext, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { useQueryClient } from 'react-query';
import { AppContext } from '../../../../sporttia/AppContext';
import { SttButtonFab } from '../../../../sporttia/buttons/SttButtonFab';
import { usePageHeader, useQueryFilters } from '../../../../lib/hooks';
import AdminReport from './AdminReport';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';
import { SUPERADMIN_REPORTS } from '../../../../lib/queryKeys';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import useReportsService from '../../../../services/ReportsService';
import constants from '../../../../config/constants';

/**
 * View where the sporttia superadmin can see the list of reports.
 * @returns {JSX.Element}
 */
export default function AdminReports() {
    const cxt = useContext(AppContext);
    const queryClient = useQueryClient();
    usePageHeader('Reports');
    const reportsService = useReportsService();
    const [filters, setFilters] = useQueryFilters({}, SUPERADMIN_REPORTS);
    const [reportId, setReportId] = useState(null);

    /**
     * In this case, before making the request (due to Material UI problems), if the value of "scope"
     * takes the value of "ALL", it must be removed from the parameters sent.
     * @param updatedFilters Object with the parameters returned by the SttTopFilteringControls component.
     */
    const doFiltering = (updatedFilters) => {
        const newFilters = { ...updatedFilters };
        if (newFilters.scope === 'ALL') {
            delete newFilters.scope;
        }
        setFilters(newFilters);
    };

    return (
        <Paper>
            <SttTopFilteringControls
                p={2}
                fields={[
                    {
                        type: 'search',
                        caption: cxt.t('Name'),
                        name: 'name',
                        value: '',
                    },
                    {
                        caption: cxt.t('Type'),
                        type: 'select',
                        name: 'scope',
                        value: 'ALL',
                        options: [
                            {
                                caption: cxt.t('All'),
                                value: 'ALL',
                            },
                            {
                                caption: cxt.t('Admin'),
                                value: 'ADMIN',
                            },
                            {
                                caption: cxt.t('Center'),
                                value: 'SC',
                            },
                        ],
                    },
                ]}
                onFilter={doFiltering}
            />

            <SttCachedTable
                queryKey={SUPERADMIN_REPORTS}
                queryFn={(params) => reportsService.getList(params)}
                queryParams={filters}
                onClickRow={(row) => setReportId(row?.id)}
                columns={[
                    {
                        title: 'Id',
                        align: 'center',
                        field: 'id',
                    },
                    {
                        title: cxt.t('Created'),
                        align: 'left',
                        type: 'dmy',
                        field: 'created',
                    },
                    {
                        title: cxt.t('Name'),
                        type: 'link',
                        field: 'name',
                        align: 'left',
                        width: 300,
                    },
                    {
                        title: cxt.t('Description'),
                        align: 'left',
                        field: 'description',
                    },
                    {
                        title: cxt.t('Group'),
                        align: 'center',
                        field: 'group',
                    },
                    {
                        title: cxt.t('Type'),
                        align: 'center',
                        field: 'scope',
                    },
                    {
                        title: cxt.t('Active'),
                        align: 'center',
                        field: 'status',
                        type: 'active',
                        value: (row) =>
                            row?.status === constants.report.status.ACTIVE,
                    },
                ]}
            />
            {reportId && (
                <AdminReport
                    id={reportId}
                    onClose={() => {
                        setReportId(null);
                        queryClient.invalidateQueries(SUPERADMIN_REPORTS);
                    }}
                />
            )}

            <SttButtonFab onClick={() => setReportId('create')} />
        </Paper>
    );
}
