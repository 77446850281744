import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Card,
    Typography,
    Button,
    Container,
    Grid,
    LinearProgress,
    Link,
    Paper,
    makeStyles,
} from '@material-ui/core';
import { SentimentSatisfiedAlt, Check } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import useKey from '@rooks/use-key';
import {
    AppContext,
    SttFormText,
    SttFormPassword,
    SttButton,
} from '../../../../sporttia/all';
import { useCrud } from '../../../../lib/hooks';

const useStyles = makeStyles((theme) => ({
    widget: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        boxSizing: 'border-box',
    },
    title: {
        padding: theme.spacing(2),
    },
    controls: {
        padding: theme.spacing(2),
    },
}));

const Modes = {
    widget: 'widget',
    dialog: 'dialog',
};

/**
 * WidgetLogin: Present a self-contained widget login. NOTE: this component will call Context.setLoggedUser, which will force a refresh of the entire app
 * Props:
 *   string mode: either "widget" (fits anywhere) or "dialog" (column)
 *   function onLogin: callback to run after a successful login
 */
export default function WidgetLogin({ mode = 'widget', onLogin }) {
    const cxt = useContext(AppContext);
    const classes = useStyles();
    const history = useHistory();

    const [params, , setProperty, , Post] = useCrud(
        {
            login: '',
            password: '',
        },
        false,
    );

    useKey(['Enter'], doLogin);

    function doLogin() {
        Post(`/login`, params)
            .then(({ user, signature }) => {
                if (user) {
                    if (
                        user.role &&
                        (user.role !== cxt.constants.roleUser ||
                            user.role !== cxt.constants.roleTeacher)
                    ) {
                        cxt.showMessage(
                            'E',
                            cxt.t('login.onlyNormalUserAllowed'),
                        );
                    } else if (user.status === 'ACTIVE') {
                        cxt.setLoggedUser(user);
                        onLogin && onLogin();
                    } else {
                        cxt.showMessage('E', cxt.t('login.userNotConfirmed'));
                    }
                }
            })
            .catch(({ error }) => {
                cxt.showMessage('E', error.msg);
            });
    }

    return (
        <div className={classes.widget}>
            <div className={classes.title}>
                <Typography variant="body1">
                    {cxt.t('page.booking.mustLogin')}
                </Typography>
            </div>
            <div className={classes.controls}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={mode === Modes.dialog ? 12 : 4}>
                        <SttFormText
                            autoFocus
                            name="login"
                            caption={cxt.t('page.login.loginOrEmail')}
                            defVal={params.login}
                            onChange={setProperty}
                        />
                    </Grid>
                    <Grid item xs={12} sm={mode === Modes.dialog ? 12 : 4}>
                        <SttFormPassword
                            name="password"
                            caption={cxt.t('Password')}
                            defVal={params.password}
                            onChange={setProperty}
                        />
                    </Grid>
                    <Grid item xs={12} sm={mode === Modes.dialog ? 12 : 4}>
                        <SttButton
                            fullWidth
                            variant="contained"
                            color="primary"
                            caption={cxt.t('page.login.Enter')}
                            startIcon={<Check />}
                            onClick={doLogin}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
