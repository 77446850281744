import useRestService from './RestService';

const resource = '/tests';

/**
 * Hook to access all 'tests' resources.
 */
export default function useTestsService() {
    const services = useRestService(resource);

    return {
        ...services,
    };
}
