import React, { useState, useEffect, useContext } from 'react';
import { Box, Divider } from '@material-ui/core';
import moment from 'moment';
import { AppContext, SttTable } from '../../../sporttia/all';

export default function ClassesList({ classes, onFetch }) {
    const cxt = useContext(AppContext);

    return (
        <SttTable
            autoload={false}
            onFetch={onFetch}
            data={classes}
            columns={[
                {
                    title: cxt.t('date'),
                    value: (row) => moment(row.ini).format('D MMM YYYY'),
                },
                {
                    title: cxt.t('Time'),
                    type: 'periodTime',
                    value: (row) => ({ ini: row.ini, end: row.end }),
                },
                {
                    title: cxt.t('Facility'),
                    type: 'text',
                    value: (row) => (row.facility ? row.facility.name : ''),
                },
                {
                    title: cxt.t('Teacher'),
                    type: 'text',
                    value: (row) => (row.teacher ? row.teacher.name : ''),
                },
            ]}
        />
    );
}
