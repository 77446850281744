import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Chip, Paper } from '@material-ui/core';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import {
    AppContext,
    SttTopControls,
    SttButtonFab,
    SttTable,
    SttChipSport,
} from '../../../sporttia/all';
import {
    useCrud,
    useToggle,
    useTuples,
    usePageHeader,
} from '../../../lib/hooks';
import { getPath } from '../../Pages';

import Facility from './Facility';

export default function Facilities() {
    usePageHeader('facilities');

    const cxt = useContext(AppContext);
    const history = useHistory();

    const [filters, setFilter] = useTuples({
        status: 'ACTIVE',
    });

    // crud
    const [facilities, setFacilities, , Get] = useCrud();

    const [trash, toggleTrash] = useToggle(() => {
        // eslint-disable-next-line no-use-before-define
        loadFacilities();
    }, false);

    const [facilityChosen, setFacilityChosen] = useState({
        id: null,
        trash: null,
    });

    // Load list
    const loadFacilities = (params = { page: 1, rows: 20 }) => {
        if (filters?.status === 'ALL') {
            filters.status = '';
        }
        Get(`/scs/${cxt.sc.id}/facilities`, {
            ...params,
            ...filters,
            trash,
        }).then((response) => {
            setFacilities(response);
        });
    };

    // Render
    return (
        <Paper>
            <SttTopControls
                p={2}
                fields={[
                    {
                        name: 'name',
                        type: 'text',
                        value: filters.name,
                        caption: cxt.t('Name'),
                        onEnterKey: loadFacilities,
                    },
                ]}
                extraFields={[
                    {
                        xs: 12,
                        sm: 6,
                        md: 3,
                        name: 'status',
                        type: 'select',
                        value: filters.status,
                        options: [
                            {
                                caption: cxt.t('All'),
                                value: 'ALL',
                            },
                            {
                                caption: cxt.t('Actives'),
                                value: 'ACTIVE',
                            },
                        ],
                    },
                ]}
                onChange={setFilter}
                onFilter={loadFacilities}
                onToggleTrash={toggleTrash}
                menu={[
                    {
                        caption: cxt.t('FacilityGroups'),
                        onClick: () => history.push(getPath('facilitySets')),
                        icon: <ViewModuleIcon />,
                    },
                ]}
            />

            <SttTable
                data={facilities}
                onClickRow={(row) =>
                    setFacilityChosen({ id: row.id, trash: row.trash })
                }
                columns={[
                    {
                        field: 'position',
                        type: 'counter',
                        title: '#',
                        width: 10,
                    },
                    {
                        field: 'name',
                        title: cxt.t('Name'),
                        align: 'left',
                        value: (facility) => (
                            <>
                                <Box mb={1}>{facility.name}</Box>
                                <SttChipSport sport={facility.sport} />
                            </>
                        ),
                    },
                    {
                        width: 215,
                        title: cxt.t('Status'),
                        type: 'text',
                        value: (row) => cxt.t(`FS.${row.status}`),
                    },
                    {
                        field: 'mandatoryMobile',
                        title: 'M',
                        type: 'active',
                    },
                    {
                        field: 'mandatoryEmail',
                        title: 'E',
                        type: 'active',
                    },
                    {
                        field: 'sets',
                        title: cxt.t('Groups'),
                        value: (row) =>
                            row.sets &&
                            row.sets.map((set, i) => (
                                <Box key={set.id} mt={+(i > 0)}>
                                    <Chip label={set.name} size="small" />
                                </Box>
                            )),
                    },
                    {
                        field: 'terrains',
                        title: cxt.t('Terrains'),
                        value: (row) =>
                            row.terrains &&
                            row.terrains.map((terrain, i) => (
                                <Box key={terrain.id} mt={+(i > 0)}>
                                    <Chip label={terrain.name} size="small" />
                                </Box>
                            )),
                    },
                    {
                        field: 'numBookings',
                        title: cxt.t('Bookings'),
                        type: 'counter',
                    },
                ]}
                onFetch={loadFacilities}
            />
            <Facility
                item={facilityChosen}
                onClose={() => {
                    setFacilityChosen({ id: null, trash: false });
                    loadFacilities();
                }}
            />
            <SttButtonFab
                onClick={() => setFacilityChosen({ id: 0, trash: false })}
            />
        </Paper>
    );
}
