import React, { useState } from 'react';
import { Save as SaveIcon } from '@material-ui/icons';
import { ButtonProps } from '@material-ui/core';
import { SttButton } from './SttButton';
import ModalConfirmation from '../../layout/ModalConfirmation';
import { useTranslations } from '../../lib/hooks';

type SttButtonSaveProps = {
    onClick: () => void;
    caption?: string;
    confirmation?: boolean;
    loading?: boolean;
} & ButtonProps;

/**
 * Save button
 */
export function SttButtonSave({
    onClick,
    caption,
    confirmation = false,
    ...rest
}: SttButtonSaveProps) {
    const { translate } = useTranslations();
    const [confirmationDialog, setConfirmationDialog] = useState({
        show: false,
    });

    return (
        <>
            <SttButton
                fullWidth
                onClick={
                    confirmation
                        ? () => setConfirmationDialog({ show: true })
                        : onClick
                }
                caption={caption || translate('Save')}
                color="primary"
                variant="contained"
                icon={<SaveIcon />}
                {...rest}
            />

            <ModalConfirmation
                show={confirmationDialog.show}
                onClose={() => setConfirmationDialog({ show: false })}
                onAccept={() => {
                    onClick();
                    setConfirmationDialog({ show: false });
                }}
            />
        </>
    );
}
