import React, { useState, useEffect, useContext } from 'react';
import {
    AppContext,
    SttList,
    SttSelectGroupDialog,
} from '../../../sporttia/all';
import { deleteElementFromArray } from '../../../lib/utils';
import translations from '../../../translations';

export default function RestrictionGroups({ idRestriction }) {
    const cxt = useContext(AppContext);
    const [groups, setGroups] = useState([]);
    const [inclusiveGroups, setInclusiveGroups] = useState();
    const [excludedGroups, setExcludedGroups] = useState();
    const [openedSelectingGroup, setOpenedSelectingGroup] = useState({
        open: false,
        isExcluding: false,
    });

    const loadGroups = () => {
        cxt.api('GET', `/bookings/restrictions/${idRestriction}/groups`, {
            params: {
                page: 1,
                rows: 300,
            },
            success: (r) => {
                setGroups(r.rows);
            },
        });
    };

    const addGroupToRestriction = (group) => {
        const params = {};

        if (openedSelectingGroup.isExcluding)
            params.isExcluding = openedSelectingGroup.isExcluding;

        cxt.api(
            'POST',
            `/bookings/restrictions/${idRestriction}/groups/${group.id}`,
            {
                params: {
                    ...params,
                },
                success: () => {
                    cxt.showMessage('S', cxt.t(translations.generic.added));

                    loadGroups();

                    setOpenedSelectingGroup({
                        open: false,
                        isExcluding: false,
                    });
                },
            },
        );
    };

    const delGroupFromRestriction = (group) => {
        if (group?.group) {
            cxt.api(
                'DELETE',
                `/bookings/restrictions/${idRestriction}/groups/${group.group.id}`,
                {
                    success: () => {
                        cxt.showMessage(
                            'S',
                            cxt.t(translations.generic.deleted),
                        );

                        if (group.excluding) {
                            setExcludedGroups(
                                deleteElementFromArray(excludedGroups, group),
                            );
                        } else {
                            setInclusiveGroups(
                                deleteElementFromArray(inclusiveGroups, group),
                            );
                        }
                    },
                },
            );
        }
    };

    const filterGroups = () => {
        const inclusiveGroupsFilter = [];
        const excludedGroupsFilter = [];

        groups.forEach((group) => {
            if (group.excluding) {
                excludedGroupsFilter.push(group);
            } else {
                inclusiveGroupsFilter.push(group);
            }
        });

        setInclusiveGroups(inclusiveGroupsFilter);
        setExcludedGroups(excludedGroupsFilter);
    };

    /**
     * Restriction id changes
     */
    useEffect(() => {
        if (idRestriction) {
            loadGroups();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idRestriction]);

    useEffect(() => {
        if (groups) {
            filterGroups();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groups]);

    return (
        <>
            <SttList
                title={cxt.t('InclusiveGroups')}
                onDelete={delGroupFromRestriction}
                onAdd={() =>
                    setOpenedSelectingGroup({ open: true, isExcluding: false })
                }
                data={inclusiveGroups?.map((group) => ({
                    caption: group?.group ? group.group.name : group.name,
                    value: group.id,
                    objToSelect: group,
                }))}
            />

            <SttList
                title={cxt.t('ExcludedGroups')}
                onDelete={delGroupFromRestriction}
                onAdd={() => {
                    setOpenedSelectingGroup({ open: true, isExcluding: true });
                }}
                data={excludedGroups?.map((group) => ({
                    caption: group?.group ? group.group.name : group.name,
                    value: group.id,
                    objToSelect: group,
                }))}
            />

            {openedSelectingGroup.open && (
                <SttSelectGroupDialog
                    open
                    onClose={() =>
                        setOpenedSelectingGroup({
                            open: false,
                            isExcluding: false,
                        })
                    }
                    onSelect={addGroupToRestriction}
                />
            )}
        </>
    );
}
