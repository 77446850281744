import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import WacomGSS from './lib/wgssStuSdk';

let tablet;

export default function SigCapture({ width, height, text, onCapture }) {
    let interval;
    let m_btns; // The array of buttons that we are emulating,
    let m_clickBtn = -1;
    let m_usbDevices;
    let m_capability;
    let m_inkThreshold;
    let m_imgData;
    let m_encodingMode;
    let ctx;
    let canvas;
    let m_penData;
    let lastPoint;
    let isDown;

    const [serviceReady, setServiceReady] = useState(false);
    const [serviceFailure, setServiceFailure] = useState(false);
    const [tabletReady, setTabletReady] = useState(false);
    const [tabletFailure, setTabletFailure] = useState(false);
    const [tableWorking, setTabletWorking] = useState(false);
    const [tabletDone, setTableDone] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);

    let waitCounter = 0;

    // Init: check for Wacom's local service
    useEffect(() => {
        interval = setInterval(checkForSigCaptX, 1000);
        return () => {
            clearInterval(interval);
        };
    });

    // If service was detected, check for device
    useEffect(() => {
        if (serviceReady) {
            waitCounter = 0;
            clearInterval(interval);
            interval = setInterval(checkForTablet, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [serviceReady]);

    // Image content modified
    useEffect(() => {
        if (imageSrc && onCapture && onCapture.constructor === Function) {
            onCapture(imageSrc);
        }
    }, [imageSrc]);

    useEffect(() => {
        if (serviceFailure) {
            clearInterval(interval);
        }
    }, [serviceFailure]);

    useEffect(() => {
        if (tabletFailure) {
            clearInterval(interval);
        }
    }, [tabletFailure]);

    // cleanup
    useEffect(() => btnCancel_Click, []);

    // Check for local service
    function checkForSigCaptX() {
        if (waitCounter >= 3) {
            setServiceFailure(true);
            return;
        }

        if (WacomGSS.STU.isServiceReady()) {
            setServiceReady(true);
        } else {
            waitCounter++;
        }
    }

    // Check for device
    function checkForTablet() {
        if (waitCounter >= 3) {
            setTabletFailure(true);
            return;
        }

        WacomGSS.STU.isDCAReady()
            .then((msg) => {
                if (!msg) {
                    return false;
                }
                WacomGSS.STU.onDCAtimeout = onDCAtimeout;
                return WacomGSS.STU.getUsbDevices();
            })
            .then((msg) => {
                if (msg && msg.length > 0) {
                    setTabletReady(true);
                } else {
                    waitCounter++;
                    setTabletReady(false);
                }
            });
    }

    function onDCAtimeout() {
        // Device Control App has timed-out and shut down
        setTimeout(close, 0);
    }

    function Rectangle(x, y, width, height) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;

        this.Contains = function (pt) {
            return (
                pt.x >= this.x &&
                pt.x <= this.x + this.width &&
                pt.y >= this.y &&
                pt.y <= this.y + this.height
            );
        };
    }

    // In order to simulate buttons, we have our own Button class that stores the bounds and event handler.
    // Using an array of these makes it easy to add or remove buttons as desired.
    //  delegate void ButtonClick();
    function VirtualButton() {
        this.Bounds = new Rectangle(0, 0, 0, 0); // in Screen coordinates
        this.Text = '';
        this.Click = null;
    }

    function Point(x, y) {
        this.x = x;
        this.y = y;
    }

    function createCanvas() {
        canvas = document.createElement('canvas');
        canvas.id = 'myCanvas';
        canvas.width = width;
        canvas.height = height;
        ctx = canvas.getContext('2d');
    }

    function disconnect() {
        // var deferred = Q.defer();

        return new Promise((resolve, reject) => {
            // let tablet = getTablet();
            if (tablet) {
                const p = new WacomGSS.STU.Protocol();
                tablet
                    .setInkingMode(p.InkingMode.InkingMode_Off)
                    .then((message) => tablet.endCapture())
                    .then((message) => {
                        if (m_imgData) {
                            return m_imgData.remove();
                        }
                        return message;
                    })
                    .then((message) => {
                        m_imgData = null;
                        return tablet.setClearScreen();
                    })
                    .then((message) => tablet.disconnect())
                    .then((message) => {
                        tablet = null;
                        // clear canvas
                        clearCanvas(canvas, ctx);
                    })
                    .then((message) => {
                        resolve(message);
                    })
                    .catch((err) => {
                        resolve(`disconnect error: ${err}`);
                    });
            } else {
                resolve();
            }
        });
    }

    window.addEventListener('beforeunload', (e) => {
        const confirmationMessage = '';
        WacomGSS.STU.close();
        (e || window.event).returnValue = confirmationMessage; // Gecko + IE
        return confirmationMessage; // Webkit, Safari, Chrome
    });

    // Error-derived object for Device Control App not ready exception
    function DCANotReady() {}
    DCANotReady.prototype = new Error();

    function beginCaptureProcess() {
        const p = new WacomGSS.STU.Protocol();
        let intf;

        WacomGSS.STU.isDCAReady()
            .then((msg) => {
                if (!msg) {
                    throw new DCANotReady();
                }
                // Set handler for Device Control App timeout
                WacomGSS.STU.onDCAtimeout = onDCAtimeout;

                return WacomGSS.STU.getUsbDevices();
            })
            .then((message) => {
                if (message == null || message.length == 0) {
                    throw new DCANotReady('No STU devices found');
                }
                m_usbDevices = message;
                return WacomGSS.STU.isSupportedUsbDevice(
                    m_usbDevices[0].idVendor,
                    m_usbDevices[0].idProduct,
                );
            })
            .then((message) => {
                intf = new WacomGSS.STU.UsbInterface();
                return intf.Constructor();
            })
            .then((message) => intf.connect(m_usbDevices[0], true))
            .then((message) => {
                tablet = new WacomGSS.STU.Tablet();
                return tablet.Constructor(intf, null, null);
            })
            .then((message) => {
                intf = null;
                return tablet.getInkThreshold();
            })
            .then((message) => {
                m_inkThreshold = message;
                return tablet.getCapability();
            })
            .then((message) => {
                m_capability = message;
                createCanvas(
                    m_capability.screenWidth,
                    m_capability.screenHeight,
                );
                return tablet.getInformation();
            })
            .then((message) => tablet.getInkThreshold())
            .then((message) => tablet.getProductId())
            .then((message) =>
                WacomGSS.STU.ProtocolHelper.simulateEncodingFlag(
                    message,
                    m_capability.encodingFlag,
                ),
            )
            .then((message) => {
                const encodingFlag = message;
                if ((encodingFlag & p.EncodingFlag.EncodingFlag_24bit) != 0) {
                    return tablet.supportsWrite().then((message) => {
                        m_encodingMode = message
                            ? p.EncodingMode.EncodingMode_24bit_Bulk
                            : p.EncodingMode.EncodingMode_24bit;
                    });
                }
                if ((encodingFlag & p.EncodingFlag.EncodingFlag_16bit) != 0) {
                    return tablet.supportsWrite().then((message) => {
                        m_encodingMode = message
                            ? p.EncodingMode.EncodingMode_16bit_Bulk
                            : p.EncodingMode.EncodingMode_16bit;
                    });
                }
                // assumes 1bit is available
                m_encodingMode = p.EncodingMode.EncodingMode_1bit;
            })
            .then(
                (message) =>
                    tablet.isSupported(p.ReportId.ReportId_EncryptionStatus), // v2 encryption
            )
            .then((message) => tablet.getDHprime())
            .then(
                (dhPrime) =>
                    WacomGSS.STU.ProtocolHelper.supportsEncryption_DHprime(
                        dhPrime,
                    ), // v1 encryption
            )
            .then((message) => tablet.setClearScreen())
            .then((message) =>
                tablet.isSupported(p.ReportId.ReportId_PenDataOptionMode),
            )
            .then((message) => {
                if (message) {
                    return tablet.getProductId().then((message) => {
                        let penDataOptionMode =
                            p.PenDataOptionMode.PenDataOptionMode_None;
                        switch (message) {
                            case WacomGSS.STU.ProductId.ProductId_520A:
                                penDataOptionMode =
                                    p.PenDataOptionMode
                                        .PenDataOptionMode_TimeCount;
                                break;
                            case WacomGSS.STU.ProductId.ProductId_430:
                            case WacomGSS.STU.ProductId.ProductId_530:
                                penDataOptionMode =
                                    p.PenDataOptionMode
                                        .PenDataOptionMode_TimeCountSequence;
                                break;
                            default:
                                console.log(
                                    'Unknown tablet supporting PenDataOptionMode, setting to None.',
                                );
                        }
                        return tablet.setPenDataOptionMode(penDataOptionMode);
                    });
                }
                m_encodingMode = p.EncodingMode.EncodingMode_1bit;
                return m_encodingMode;
            })
            .then((message) => {
                // BEGIN!
                addButtons();

                drawText(text);

                const canvasImage = canvas.toDataURL('image/jpeg');
                return WacomGSS.STU.ProtocolHelper.resizeAndFlatten(
                    canvasImage,
                    0,
                    0,
                    0,
                    0,
                    m_capability.screenWidth,
                    m_capability.screenHeight,
                    m_encodingMode,
                    1,
                    false,
                    0,
                    true,
                );
            })
            .then((message) => {
                m_imgData = message;
                return tablet.writeImage(m_encodingMode, message);
            })
            .then((message) => tablet.setInkingMode(p.InkingMode.InkingMode_On))
            .then((message) => {
                const reportHandler =
                    new WacomGSS.STU.ProtocolHelper.ReportHandler();
                lastPoint = { x: 0, y: 0 };
                isDown = false;
                ctx.lineWidth = 1;

                const penData = function (report) {
                    const shouldDrawPoint = processButtons(report, canvas);

                    processPoint(report, canvas, ctx);

                    m_penData.push({ ...report, shouldDrawPoint });
                };
                /* let penDataEncryptedOption = function (report) {
					//console.log("reportOp: " + JSON.stringify(report));
					m_penData.push(report.penData[0], report.penData[1]);
					processButtons(report.penData[0], canvas);
					processPoint(report.penData[0], canvas, ctx);
					processButtons(report.penData[1], canvas);
					processPoint(report.penData[1], canvas, ctx);
				}

				let log = function (report) {
					//console.log("report: " + JSON.stringify(report));
				}

				let decrypted = function (report) {
					//console.log("decrypted: " + JSON.stringify(report));
				} */
                m_penData = new Array();
                reportHandler.onReportPenData = penData;
                reportHandler.onReportPenDataOption = penData;
                reportHandler.onReportPenDataTimeCountSequence = penData;
                /* reportHandler.onReportPenDataEncrypted = penDataEncryptedOption;
				reportHandler.onReportPenDataEncryptedOption = penDataEncryptedOption;
				reportHandler.onReportPenDataTimeCountSequenceEncrypted = penData;
				reportHandler.onReportDevicePublicKey = log;
				reportHandler.onReportEncryptionStatus = log;
				reportHandler.decrypt = decrypted; */

                // STATE
                setTabletWorking(true);

                return reportHandler.startReporting(tablet, true);
            })
            .catch((ex) => {
                if (ex instanceof DCANotReady) {
                    // Device Control App not detected
                    // Reinitialize and re-try
                    WacomGSS.STU.Reinitialize();
                    setTabletReady(false);
                    // setTimeout(beginCaptureProcess, 1000);
                } else {
                    alert(`Ocurrió un error inesperado:\n${ex}`);
                    setTimeout(close(), 0);
                }
            });
    }

    function addButtons() {
        m_btns = new Array(3);
        m_btns[0] = new VirtualButton();
        m_btns[1] = new VirtualButton();
        m_btns[2] = new VirtualButton();

        if (m_usbDevices[0].idProduct != WacomGSS.STU.ProductId.ProductId_300) {
            // Place the buttons across the bottom of the screen.
            const w2 = m_capability.screenWidth / 3;
            const w3 = m_capability.screenWidth / 3;
            const w1 = m_capability.screenWidth - w2 - w3;
            const y = (m_capability.screenHeight * 6) / 7;
            const h = m_capability.screenHeight - y;

            m_btns[0].Bounds = new Rectangle(0, y, w1, h);
            m_btns[1].Bounds = new Rectangle(w1, y, w2, h);
            m_btns[2].Bounds = new Rectangle(w1 + w2, y, w3, h);
        } else {
            // The STU-300 is very shallow, so it is better to utilise
            // the buttons to the side of the display instead.

            const x = (m_capability.screenWidth * 3) / 4;
            const w = m_capability.screenWidth - x;

            const h2 = m_capability.screenHeight / 3;
            const h3 = m_capability.screenHeight / 3;
            const h1 = m_capability.screenHeight - h2 - h3;

            m_btns[0].Bounds = new Rectangle(x, 0, w, h1);
            m_btns[1].Bounds = new Rectangle(x, h1, w, h2);
            m_btns[2].Bounds = new Rectangle(x, h1 + h2, w, h3);
        }

        m_btns[0].Text = 'OK';
        m_btns[1].Text = 'Borrar';
        m_btns[2].Text = 'Cancelar';
        m_btns[0].Click = btnOk_Click;
        m_btns[1].Click = btnClear_Click;
        m_btns[2].Click = btnCancel_Click;
        clearCanvas(canvas, ctx);
        drawButtons();
    }

    function drawCaption(text, line) {
        ctx.save();

        ctx.strokeStyle = 'black';
        ctx.font = '11px Arial';

        ctx.fillText(text, 8, 16 + 12 * line);

        ctx.restore();
    }

    /*
     * Pintar un texto partiéndolo en frases que encajen en el ancho de la pantalla.
     * El algoritmo suma palabras y las mide con canvas.context.measureText hasta que se supera el ancho y entonces crea una nueva frase.
     * */
    function drawText(text) {
        ctx.save();

        ctx.strokeStyle = 'black';
        ctx.font = '14px Arial';

        const words = text.split(' ');

        let phrase = '';
        let word;
        let index = 0;
        let line = 0;
        const xOffset = 10;
        const yOffset = 20;
        const lineHeight = 15;
        while ((word = words.shift())) {
            const predict = phrase + (index > 0 ? ' ' : '') + word;
            const metrics = ctx.measureText(predict);

            if (metrics.width <= canvas.width - xOffset * 2) {
                phrase = predict;
                index++;
            } else {
                ctx.fillText(phrase, xOffset, yOffset + lineHeight * line);
                phrase = '';
                words.unshift(word);
                line++;
                index = 0;
            }
        }

        ctx.fillText(phrase, xOffset, yOffset + lineHeight * line);

        ctx.restore();
    }

    function drawButtons() {
        ctx.save();
        ctx.setTransform(1, 0, 0, 1, 0, 0);

        ctx.beginPath();
        ctx.lineWidth = 1;
        ctx.strokeStyle = 'black';
        ctx.font = '22px Arial';

        // Draw the buttons
        for (let i = 0; i < m_btns.length; ++i) {
            // if (useColor)
            {
                ctx.fillStyle = 'lightgrey';
                ctx.fillRect(
                    m_btns[i].Bounds.x,
                    m_btns[i].Bounds.y,
                    m_btns[i].Bounds.width,
                    m_btns[i].Bounds.height,
                );
            }

            ctx.fillStyle = 'black';
            ctx.rect(
                m_btns[i].Bounds.x,
                m_btns[i].Bounds.y,
                m_btns[i].Bounds.width,
                m_btns[i].Bounds.height,
            );
            const xPos =
                m_btns[i].Bounds.x +
                (m_btns[i].Bounds.width / 2 -
                    ctx.measureText(m_btns[i].Text).width / 2);
            var yOffset;
            if (
                m_usbDevices[0].idProduct ==
                WacomGSS.STU.ProductId.ProductId_300
            )
                yOffset = 28;
            else if (
                m_usbDevices[0].idProduct ==
                WacomGSS.STU.ProductId.ProductId_430
            )
                // Esta es la nuestra
                yOffset = 22;
            else yOffset = 40;
            ctx.fillText(m_btns[i].Text, xPos, m_btns[i].Bounds.y + yOffset);
        }
        ctx.stroke();
        ctx.closePath();

        ctx.restore();
    }

    function clearScreen() {
        clearCanvas(canvas, ctx);
        drawButtons();
        m_penData = new Array();
        tablet.writeImage(m_encodingMode, m_imgData);
    }

    function btnOk_Click() {
        // If there is at least one valid drawn point
        if (
            m_penData.reduce((res, item) => res || item.shouldDrawPoint, false)
        ) {
            generateImage();
            setTimeout(close, 0);

            setTableDone(true);
        }
    }

    function btnCancel_Click() {
        setTimeout(close, 0);

        setTableDone(false);
        setTabletWorking(false);
    }

    function btnClear_Click() {
        clearScreen();
    }

    function distance(a, b) {
        return (a.x - b.x) ** 2 + (a.y - b.y) ** 2;
    }

    function clearCanvas(in_canvas, in_ctx) {
        in_ctx.save();
        in_ctx.setTransform(1, 0, 0, 1, 0, 0);
        in_ctx.fillStyle = 'white';
        in_ctx.fillRect(0, 0, in_canvas.width, in_canvas.height);
        in_ctx.restore();
    }

    function processButtons(point, in_canvas) {
        const nextPoint = {};
        nextPoint.x = Math.round(
            (in_canvas.width * point.x) / m_capability.tabletMaxX,
        );
        nextPoint.y = Math.round(
            (in_canvas.height * point.y) / m_capability.tabletMaxY,
        );
        const isDown2 = isDown
            ? !(point.pressure <= m_inkThreshold.offPressureMark)
            : point.pressure > m_inkThreshold.onPressureMark;

        // trash point: discard
        if (nextPoint.x === 0 && nextPoint.y === 0) {
            return false;
        }

        let btn = -1;
        let derp = false;
        for (let i = 0; i < m_btns.length; ++i) {
            if (m_btns[i].Bounds.Contains(nextPoint)) {
                btn = i;
                derp = true;
                break;
            }
        }

        if (isDown && !isDown2) {
            if (btn != -1 && m_clickBtn === btn) {
                m_btns[btn].Click();
            }
            m_clickBtn = -1;
        } else if (btn != -1 && !isDown && isDown2) {
            m_clickBtn = btn;
        }

        return btn == -1;
    }

    function processPoint(point, in_canvas, in_ctx, ignore) {
        const nextPoint = {};
        nextPoint.x = Math.round(
            (in_canvas.width * point.x) / m_capability.tabletMaxX,
        );
        nextPoint.y = Math.round(
            (in_canvas.height * point.y) / m_capability.tabletMaxY,
        );

        const isDown2 = isDown
            ? !(point.pressure <= m_inkThreshold.offPressureMark)
            : point.pressure > m_inkThreshold.onPressureMark;

        if (!isDown && isDown2) {
            lastPoint = nextPoint;
        }

        if (
            !ignore &&
            ((isDown2 && distance(lastPoint, nextPoint) > 10) ||
                (isDown && !isDown2))
        ) {
            in_ctx.beginPath();
            in_ctx.moveTo(lastPoint.x, lastPoint.y);
            in_ctx.lineTo(nextPoint.x, nextPoint.y);
            in_ctx.stroke();
            in_ctx.closePath();
            lastPoint = nextPoint;
        }

        isDown = isDown2;
    }

    function generateImage() {
        const signatureCanvas = document.createElement('canvas');
        signatureCanvas.id = 'signatureCanvas';
        signatureCanvas.width = width;
        signatureCanvas.height = height;
        const signatureCtx = signatureCanvas.getContext('2d');

        clearCanvas(signatureCanvas, signatureCtx);
        signatureCtx.lineWidth = 1;
        signatureCtx.strokeStyle = 'black';
        lastPoint = { x: 0, y: 0 };
        isDown = false;

        for (let i = 0; i < m_penData.length; i++) {
            if (m_penData[i].shouldDrawPoint) {
                processPoint(m_penData[i], signatureCanvas, signatureCtx);
            }
        }

        setImageSrc(signatureCanvas.toDataURL('image/jpeg'));
    }

    function close() {
        setTabletWorking(false);

        // Clear handler for Device Control App timeout
        WacomGSS.STU.onDCAtimeout = null;

        disconnect()
            .then((msg) => {
                console.log('Disconnected', msg);
            })
            .catch((err) => {
                console.log(err);
            });
        // document.getElementsByTagName('body')[0].removeChild(modalBackground);
        // document.getElementsByTagName('body')[0].removeChild(formDiv);
    }

    /* function onCanvasClick(event) {
		// Enable the mouse to click on the simulated buttons that we have displayed.

		// Note that this can add some tricky logic into processing pen data
		// if the pen was down at the time of this click, especially if the pen was logically
		// also 'pressing' a button! This demo however ignores any that.

		var posX = event.pageX - formDiv.offsetLeft;
		var posY = event.pageY - formDiv.offsetTop;

		for (var i = 0; i < m_btns.length; i++) {
			if (m_btns[i].Bounds.Contains(new Point(posX, posY))) {
				m_btns[i].Click();
				break;
			}
		}
	} */

    return (
        <div>
            {serviceReady ? (
                <div>
                    {tabletDone ? (
                        <img style={{ width, height }} src={imageSrc} />
                    ) : (
                        <Box
                            p={2}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {tableWorking ? (
                                <Box
                                    flex={1}
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Box mb={2}>
                                        <Typography variant="body1">
                                            Dispositivo ocupado...
                                        </Typography>
                                    </Box>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className="button-delete"
                                        onClick={btnCancel_Click}
                                    >
                                        Cancelar
                                    </Button>
                                </Box>
                            ) : (
                                <div>
                                    {tabletReady ? (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                beginCaptureProcess();
                                            }}
                                        >
                                            Capturar Firma
                                        </Button>
                                    ) : tabletFailure ? (
                                        <Typography variant="body1">
                                            ERROR: No se detectó un dispositivo
                                            Wacom compatible.
                                        </Typography>
                                    ) : (
                                        <Typography variant="body1">
                                            Esperando al dispositivo...
                                        </Typography>
                                    )}
                                </div>
                            )}
                        </Box>
                    )}
                </div>
            ) : (
                <Box
                    p={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    {serviceFailure ? (
                        <Typography variant="body1">
                            ERROR: No se detectó el servicio SigCaptX en la
                            máquina local.
                        </Typography>
                    ) : (
                        <Typography variant="body1">
                            Esperando al servicio...
                        </Typography>
                    )}
                </Box>
            )}
        </div>
    );
}
