import React, { useState, useEffect, useContext } from 'react';
import { AppContext, SttTable, SttTopControls } from '../../../sporttia/all';
import FormQuestionDialog from '../../../components/dialogs/FormQuestionDialog';

// Pestaña 'Formulario' para los eventos
export default function EventForm({ event, onChange }) {
    const [questions, setQuestions] = useState({ rows: [] });
    const [viewQuestion, setViewQuestion] = useState(null);

    const cxt = useContext(AppContext);

    // Si cambia el event, recargamos el form (necesario al actualizar)
    useEffect(() => {
        if (event.form) {
            setQuestions({ count: event.form.count, rows: event.form });
        }
    }, [event]);

    return (
        <>
            <SttTopControls
                mb={6}
                mainAction={{
                    type: 'create',
                    onClick: () => setViewQuestion({}),
                }}
            />
            <SttTable
                data={questions}
                hidePagination
                columns={[
                    {
                        title: cxt.t('Question'),
                        align: 'left',
                        type: 'link',
                        field: 'name',
                        onClick: (row) => setViewQuestion(row),
                    },
                    {
                        title: cxt.t('Type'),
                        type: 'text',
                        field: 'typeLang',
                    },
                    {
                        title: cxt.t('Mandatory'),
                        type: 'active',
                        value: (row) => !!row.mandatory,
                    },
                ]}
            />
            <FormQuestionDialog
                parentCategory="events"
                idParent={event.id}
                item={viewQuestion}
                onSave={(question) => {
                    if (
                        questions.rows.find((q) => q.id === question.id) ===
                        undefined
                    ) {
                        onChange([...questions.rows, question]);
                    } else {
                        onChange(
                            questions.rows.map((q) =>
                                q.id === question.id ? question : q,
                            ),
                        );
                    }
                }}
                onDelete={(question) => {
                    onChange(
                        questions.rows.filter((q) => q.id !== question.id),
                    );
                }}
                onClose={() => setViewQuestion(null)}
            />
        </>
    );
}
