import React, { useState, useEffect, useContext } from 'react';
import {
    Grid,
    Box,
    Paper,
    Table,
    TableContent,
    TableRow,
    TableCell,
    TableContainer,
    TableHead,
    TableBody,
} from '@material-ui/core';
import {
    AppContext,
    SttFullDialog,
    SttFormTextarea,
} from '../../../sporttia/all';

export default function ApiModelDialog({ idModel, onClose }) {
    const cxt = useContext(AppContext);
    const [model, setModel] = useState();

    /**
     * Load model
     */
    const loadModel = () => {
        cxt.api('GET', `/apis/models/${idModel}`, {
            success: (r) => {
                setModel(r.apiModel);
            },
        });
    };

    /**
     * Id model changes
     */
    useEffect(() => {
        if (idModel) loadModel();
    }, [idModel]);

    // -----| Render |-----
    return (
        <SttFullDialog
            title={cxt.t('Model')}
            open={idModel != null}
            onClose={onClose}
            buttons={[
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
        >
            <Box mt={4}>
                {model && (
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <Grid container>
                                <SttFormTextarea
                                    grid
                                    md={12}
                                    disabled
                                    rows={50}
                                    caption="JSON"
                                    defVal={model.example}
                                />
                            </Grid>
                        </Grid>

                        <Grid item md={6}>
                            <Paper>
                                <Box p={3}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        {cxt.t('Name')}
                                                    </TableCell>
                                                    <TableCell>
                                                        {cxt.t('Description')}
                                                    </TableCell>
                                                    <TableCell>
                                                        {cxt.t('Type')}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {model.properties &&
                                                    model.properties.rows.map(
                                                        (prop) => (
                                                            <TableRow
                                                                key={prop.id}
                                                            >
                                                                <TableCell align="center">
                                                                    <b>
                                                                        {
                                                                            prop.name
                                                                        }
                                                                    </b>
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {
                                                                        prop.description
                                                                    }
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    {prop.type}
                                                                </TableCell>
                                                            </TableRow>
                                                        ),
                                                    )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </SttFullDialog>
    );
}
