import React, { useState } from 'react';
import { Box, Button, ButtonProps } from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import moment from 'moment';
import { SttCalendarDialog } from '../all';

type SttButtonDateProps = {
    date: string;
    onSelectDate: (date: string) => void;
} & ButtonProps;

/**
 * Button date
 */
export function SttButtonDate({
    date,
    onSelectDate,
    ...rest
}: SttButtonDateProps) {
    const [calendarOpen, setCalendarOpen] = useState(false);

    /**
     * Select date
     */
    const selectDate = (selectedDate: string) => {
        onSelectDate(selectedDate);
        setCalendarOpen(false);
    };

    return (
        <>
            <Button
                {...rest}
                variant="outlined"
                onClick={() => setCalendarOpen(true)}
            >
                <TodayIcon />
                {date && <Box ml={1}>{moment(date).format('D-MM-YYYY')}</Box>}
            </Button>

            <SttCalendarDialog
                open={calendarOpen}
                date={date}
                onSelectDate={selectDate}
                onClose={() => setCalendarOpen(false)}
            />
        </>
    );
}
