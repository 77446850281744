import React, { useContext, useState } from 'react';
import { Box, Grid, TextField } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { SttButton } from '../../../sporttia/buttons/SttButton';
import { AppContext } from '../../../sporttia/all';

export default function ConfigurationCashdro() {
    const [ip, setIp] = useState(localStorage.getItem('CashdroIp'));
    const [user, setUser] = useState(localStorage.getItem('CashdroUser'));
    const [pass, setPass] = useState(localStorage.getItem('CashdroPass'));

    const cxt = useContext(AppContext);

    function setIpValue(value) {
        setIp(value);
    }

    function setUserValue(value) {
        setUser(value);
    }

    function setPassValue(value) {
        setPass(value);
    }

    function save() {
        cxt.showMessage('S', cxt.t('Saved'));
        localStorage.setItem('CashdroIp', ip);
        localStorage.setItem('CashdroUser', user);
        localStorage.setItem('CashdroPass', pass);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    label="Ip del dispositivo"
                    size="small"
                    value={ip}
                    onChange={(e) => {
                        setIpValue(e.target.value);
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Box>
                    {`Por ejemplo: 192.168.0.25; Consulte este valor en el panel
                    de control del CashDro o en la configuración de red.`}
                </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    label="Ususario"
                    size="small"
                    value={user}
                    onChange={(e) => {
                        setUserValue(e.target.value);
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    label="Contraseña"
                    size="small"
                    value={pass}
                    onChange={(e) => {
                        setPassValue(e.target.value);
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Box>
                    {`Introduzca las credenciales para el usuario 'admin' proporcionadas por el técnico de CashDro`}
                </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
                <SttButton
                    startIcon={<Save />}
                    caption={cxt.t('Save')}
                    color="primary"
                    variant="contained"
                    // disabled={testing}
                    onClick={() => {
                        save();
                    }}
                />
            </Grid>
        </Grid>
    );
}
