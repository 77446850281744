import React, { useContext, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Paper } from '@material-ui/core';
import { useMutation, useQueryClient } from 'react-query';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { AppContext } from '../../../../sporttia/AppContext';
import { useLoader, usePageHeader } from '../../../../lib/hooks';
import useCoresService from '../../../../services/CoresService';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';
import { SUPERADMIN_API_TRIGGERS_BY_SC } from '../../../../lib/queryKeys';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import { SttButtonFab } from '../../../../sporttia/buttons/SttButtonFab';
import AdminApiTriggerByScDialog from './AdminApiTriggerByScDialog';
import useScsService from '../../../../services/ScsService';

/**
 * View where the sporttia superadmin can see the list of apis triggers by sports centers.
 * @returns {JSX.Element} JSX Element.
 */
export default function AdminApiTriggersBySc({}) {
    const cxt = useContext(AppContext);
    const classes = useStyles();
    const queryClient = useQueryClient();
    usePageHeader('apiTriggersBySc');
    const coresService = useCoresService();
    const scsService = useScsService();
    const [apiTrigger, setApiTrigger] = useState(null);
    const deleteMutation = useMutation(
        (params) =>
            scsService.removeScsCoreTrigger(params?.scId, params?.triggerId),
        {
            onSuccess: () =>
                queryClient.invalidateQueries(SUPERADMIN_API_TRIGGERS_BY_SC),
        },
    );
    const [, loader] = useLoader([deleteMutation.status]);

    return (
        <Paper>
            {loader}
            <SttTopFilteringControls
                p={2}
                iconButtons={[
                    {
                        icon: <AutorenewIcon />,
                        onClick: () =>
                            queryClient.invalidateQueries(
                                SUPERADMIN_API_TRIGGERS_BY_SC,
                            ),
                    },
                ]}
            />

            <SttCachedTable
                queryKey={SUPERADMIN_API_TRIGGERS_BY_SC}
                queryFn={(params) => coresService.getCoresTriggersBySc(params)}
                columns={[
                    {
                        title: 'Id',
                        field: 'id',
                        value: (row) => row?.id,
                    },
                    {
                        title: cxt.t('Center'),
                        value: (row) => row?.sc?.id,
                    },
                    {
                        title: cxt.t('Code'),
                        value: (row) => row?.coreTrigger?.code,
                    },
                    {
                        title: 'Script',
                        value: (row) => row?.coreTrigger?.script,
                    },
                    {
                        title: '',
                        value: (row) => (
                            <DeleteOutlineOutlinedIcon
                                className={`${classes.deleteButton}`}
                                onClick={() =>
                                    deleteMutation.mutate({
                                        triggerId: row?.coreTrigger?.id,
                                        scId: row?.sc?.id,
                                    })
                                }
                            />
                        ),
                    },
                ]}
            />

            {/* En el futuro si tenemos un GET /cores/triggers/:id podríamos quitar apiTriggerValue y pasar el id del trigger. */}
            <AdminApiTriggerByScDialog
                apiTriggerValue={apiTrigger}
                onClose={() => setApiTrigger(null)}
                onCreate={() =>
                    queryClient.invalidateQueries(SUPERADMIN_API_TRIGGERS_BY_SC)
                }
            />

            <SttButtonFab onClick={() => setApiTrigger({})} />
        </Paper>
    );
}

const useStyles = makeStyles({
    deleteButton: {
        '&:hover': {
            cursor: 'pointer',
            color: 'rgba(33, 150, 243, 0.5)',
        },
    },
});
