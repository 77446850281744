import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { SttDialog } from '../../../sporttia/dialogs/SttDialog';
import { AppContext } from '../../../sporttia/AppContext';
import { SttFormText } from '../../../sporttia/forms/SttFormText';
import { SttFormTextarea } from '../../../sporttia/forms/SttFormTextarea';
import { SttSelectableItems } from '../../../sporttia/SttSelectableItems';

const defaultAttendanceDayChosen = {
    id: '',
    status: 'ASSIST',
    class: {
        id: 0,
        ini: '',
        end: '',
    },
    notes: '',
};

export default function AttendanceDialog({
    attendance = null,
    attendanceDay = null,
    open,
    onClose,
}) {
    const cxt = useContext(AppContext);
    const [attendanceChosen, setAttendanceChosen] = useState(attendance);
    const [attendanceDayChosen, setAttendanceDayChosen] = useState(
        defaultAttendanceDayChosen,
    );

    useEffect(() => {
        if (attendance && attendanceDay) {
            const assistanceID = attendance.assistences.find(
                (assistance) =>
                    new Date(assistance.class.ini).getDate() ===
                    parseInt(attendanceDay),
            ).id;
            if (assistanceID) {
                cxt.api('GET', `/classes/assistences/${assistanceID}`, {
                    success: (result) => {
                        if (result.classAssistence) {
                            setAttendanceDayChosen(result.classAssistence);
                        }
                    },
                });
            } else {
                setAttendanceDayChosen(defaultAttendanceDayChosen);
            }
            setAttendanceChosen(attendance);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attendance, attendanceDay]);

    const saveAttendance = () => {
        if (attendanceDayChosen.id) {
            cxt.api('PUT', `/classes/assistences/${attendanceDayChosen.id}`, {
                params: {
                    status: attendanceDayChosen.status,
                    notes: attendanceDayChosen.notes,
                },
                success: () => {
                    onClose();
                },
            });
        } else {
            const classId = attendance.assistences.find(
                (assistance) =>
                    new Date(assistance.class.ini).getDate() ===
                    parseInt(attendanceDay),
            ).class.id;
            cxt.api('POST', `/classes/${classId}/assistences`, {
                params: {
                    idStudent: attendanceChosen.id,
                    status: attendanceDayChosen.status,
                    notes: attendanceDayChosen.notes,
                },
                success: () => {
                    onClose();
                },
            });
        }
    };

    return (
        <SttDialog
            title={cxt.t('Assistence')}
            open={open}
            onClose={onClose}
            maxWidth="sm"
            buttons={[
                {
                    type: 'save',
                    onClick: saveAttendance,
                },
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
            content={
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                        <SttFormText
                            caption={cxt.t('User')}
                            name="name"
                            disabled
                            defVal={(attendance && attendance.name) || ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <SttSelectableItems
                            name="status"
                            items={[
                                {
                                    label: cxt.t('CAS.ASSIST'),
                                    value: 'ASSIST',
                                },
                                {
                                    label: cxt.t('CAS.MISSED'),
                                    value: 'MISSED',
                                },
                                {
                                    label: cxt.t('CAS.RECOVERABLE'),
                                    value: 'RECOVERABLE',
                                },
                            ]}
                            exclusive
                            selected={attendanceDayChosen.status}
                            onChange={({ name, value }) => {
                                setAttendanceDayChosen({
                                    ...attendanceDayChosen,
                                    [name]: value,
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <SttFormTextarea
                            name="notes"
                            rows={3}
                            caption={cxt.t('Notes')}
                            defVal={
                                (attendanceDayChosen &&
                                    attendanceDayChosen.notes) ||
                                ''
                            }
                            onChange={({ name, value }) => {
                                setAttendanceDayChosen({
                                    ...attendanceDayChosen,
                                    [name]: value,
                                });
                            }}
                        />
                    </Grid>
                </Grid>
            }
        />
    );
}
