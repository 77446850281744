import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Grid,
    FormControl,
    InputLabel,
    InputAdornment,
    OutlinedInput,
    IconButton,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import useKey from '@rooks/use-key';
import {
    AppContext,
    SttFormSearch,
    SttFormText,
    SttMshipList,
    SttSelectMshipDialog,
} from '../all';

/**
 *	Select a mship (user)
 *
 *	Props:
 *
 *	* function onSelect(mship)
 *	* object mship: mship data
 *	* boolean readOnly
 */
export function SttSelectMship({
    grid = true,
    md = 12,
    xs = 12,
    caption,
    name,
    mship,
    readOnly = false,
    onChange,
    onSelect,
    ...rest
}) {
    const cxt = useContext(AppContext);
    const [dialogOpened, setDialogOpened] = useState(false);

    /**
     * Click search
     */
    const handleClickSearch = () => {
        setDialogOpened(true);
    };

    useEffect(() => {
        if (!mship) {
            onSelect({ fullName: '' });
        }
    }, [mship]);

    // -----| Render |-----
    const content = (
        <>
            {mship && (
                <SttFormSearch
                    disabled
                    caption={caption || cxt.t('User')}
                    defVal={mship && mship.fullName}
                    onClean={() => onSelect(null)}
                    onChange={onChange}
                    onClickSearch={handleClickSearch}
                    readOnly={readOnly}
                />
            )}

            <SttSelectMshipDialog
                open={dialogOpened}
                onClose={() => setDialogOpened(false)}
                onSelectMship={(mship) => {
                    setDialogOpened(false);
                    onSelect(mship);
                }}
            />
        </>
    );
    return grid ? (
        <Grid item xs={xs} md={md} {...rest}>
            {content}
        </Grid>
    ) : (
        <>{content}</>
    );
}
