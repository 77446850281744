import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { AppContext, SttDialog, SttForm } from '../../../sporttia/all';
import { subsetObject } from '../../../lib/utils';

export default function SCAdminDialog({ scAdmin = null, onClose }) {
    const cxt = useContext(AppContext);
    const [internalAdmin, setInternalAdmin] = useState();

    /**
     * Handle form
     */
    const handleForm = ({ name, value }) => {
        setInternalAdmin({ ...internalAdmin, [name]: value });
    };

    /**
     * Input admin changes
     */
    useEffect(() => {
        setInternalAdmin(scAdmin);
    }, [scAdmin]);

    /**
     * Save admin
     */
    const saveSCAdmin = () => {
        const params = subsetObject(internalAdmin, 'name,email,login,password');

        // Updating
        if (internalAdmin.id) {
            cxt.api('PUT', `/scs/admins/${internalAdmin.id}`, {
                params,
                success: (r) => {
                    cxt.showMessage('S', cxt.t('Saved'));
                    onClose(r.scAdmin);
                },
            });

            // Creating
        } else {
            cxt.api('POST', `/scs/${cxt.sc.id}/admins`, {
                params,
                success: (r) => {
                    cxt.showMessage('S', cxt.t('Saved'));
                    onClose(r.scAdmin);
                },
            });
        }
    };

    // -----| Render |-----
    return (
        <>
            {internalAdmin && (
                <SttDialog
                    title={cxt.t('Administrator')}
                    open={scAdmin != null}
                    onClose={() => onClose(internalAdmin)}
                    content={
                        <>
                            <SttForm
                                onChangeForm={handleForm}
                                elements={[
                                    {
                                        md: 12,
                                        sm: 12,
                                        xs: 12,
                                        name: 'name',
                                        caption: cxt.t('Name'),
                                        value: internalAdmin.name,
                                    },
                                    {
                                        md: 12,
                                        sm: 12,
                                        xs: 12,
                                        name: 'email',
                                        caption: cxt.t('Email'),
                                        value: internalAdmin.email,
                                    },
                                    {
                                        md: 6,
                                        sm: 6,
                                        xs: 12,
                                        name: 'login',
                                        caption: cxt.t('Login'),
                                        value: internalAdmin.login,
                                    },
                                    {
                                        md: 6,
                                        sm: 6,
                                        xs: 12,
                                        name: 'password',
                                        caption: cxt.t('Password'),
                                        value: internalAdmin.password,
                                    },
                                ]}
                            />

                            {/* --- token --- */}
                            {scAdmin && (
                                <Box mt={3}>
                                    <Typography variant="caption">
                                        Token: {scAdmin.token}
                                    </Typography>
                                </Box>
                            )}
                        </>
                    }
                    buttons={[
                        {
                            type: 'save',
                            onClick: () => saveSCAdmin(),
                        },
                    ]}
                />
            )}
        </>
    );
}
