import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid } from '@material-ui/core';
import moment from 'moment';
import {
    AppContext,
    SttDialog,
    SttTable,
    SttFormCheck,
    SttFormSearch,
    SttFormTime,
} from '../../../sporttia/all';
import { updateElementInArray, formatPriceByLocale } from '../../../lib/utils';

export default function SeatingDialog({
    open = false,
    facility,
    date,
    piece,
    onClose,
}) {
    const cxt = useContext(AppContext);
    const [occupants, setOccupants] = useState();
    const [name, setName] = useState('');
    const [time, setTime] = useState();

    /**
     * Piece changes
     */
    useEffect(() => {
        if (piece && piece.ini) {
            setTime(moment(piece.ini).format('H:mm'));
        }
    }, [piece]);

    /**
     * Load occupants
     */
    const loadOccupants = (p = { page: 1, rows: 6 }) => {
        cxt.api('GET', `/facilities/${facility.id}/bookings/occupants`, {
            params: {
                date: moment(date).format('YYYY-MM-DD'),
                name,
                time,
                page: p.page,
                rows: p.rows,
            },
            success: (r) => {
                const filteredOccupants = r.rows.filter((occupant) => {
                    const fullName =
                        occupant.user?.mship?.fullName ||
                        occupant.user?.fullName ||
                        '';
                    return fullName.toLowerCase().includes(name.toLowerCase());
                });
                setOccupants({
                    ...r,
                    rows: filteredOccupants,
                });
            },
        });
    };

    /**
     * Toggle confirmed
     */
    const toggleConfirmed = (occupant) => {
        cxt.api('PUT', `/bookings/occupants/${occupant.id}`, {
            params: {
                confirmed: !occupant.confirmed,
            },
            success: () => {
                setOccupants({
                    ...occupants,
                    rows: updateElementInArray(occupants.rows, {
                        ...occupant,
                        confirmed: !occupant.confirmed,
                    }),
                });
            },
        });
    };

    /**
     * Init
     */
    useEffect(() => {
        if (facility && facility.id && time) loadOccupants();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facility, time]);

    // -----| Render |-----
    return (
        <SttDialog
            fullWidth
            maxWidth="lg"
            title={cxt.t('Seating')}
            open={open}
            onClose={onClose}
            content={
                <>
                    <Box mb={3}>
                        <Grid container spacing={3}>
                            <SttFormSearch
                                grid
                                md={5}
                                sm
                                xs
                                defVal={name}
                                onChange={({ value }) => setName(value)}
                                onClickSearch={loadOccupants}
                            />

                            <SttFormTime
                                grid
                                md={3}
                                sm
                                xs
                                defVal={time}
                                onChange={({ value }) => setTime(value)}
                            />
                        </Grid>
                    </Box>

                    <SttTable
                        data={occupants}
                        perPage={6}
                        columns={[
                            {
                                title: cxt.t('Name'),
                                field: 'userName',
                                align: 'left',
                                value: (row) => {
                                    const fullName =
                                        row.user?.mship?.fullName ||
                                        row.user?.fullName ||
                                        'N/A';
                                    return fullName;
                                },
                            },
                            {
                                title: cxt.t('Creator'),
                                field: 'creator',
                                align: 'left',
                                value: (row) =>
                                    row.booking.creator &&
                                    row.booking.creator.fullName,
                            },
                            {
                                title: cxt.t('Period'),
                                type: 'periodTime',
                                value: (row) => ({
                                    ini: row.booking.ini,
                                    end: row.booking.end,
                                }),
                            },
                            {
                                title: `${cxt.t('Rate')} / ${cxt.t('Bono')}`,
                                value: (row) =>
                                    row.rate && row.rate.length > 0
                                        ? row.rate
                                              .map(
                                                  (price) =>
                                                      `${
                                                          price.name
                                                      } [${formatPriceByLocale(
                                                          price.price,
                                                          cxt.sc?.city?.country
                                                              ?.currency,
                                                      )}]`,
                                              )
                                              .join(' - ')
                                        : row.bonoMov &&
                                          row.bonoMov.boleto.bono.name,
                            },
                            {
                                title: cxt.t('Confirmed'),
                                value: (row) => (
                                    <SttFormCheck
                                        color="primary"
                                        checked={row.confirmed}
                                        onChange={() => toggleConfirmed(row)}
                                    />
                                ),
                            },
                        ]}
                        onFetch={loadOccupants}
                    />
                </>
            }
            buttons={[
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
        />
    );
}
