import React, { useState, useEffect, useContext } from 'react';
import { Paper, Box } from '@material-ui/core';
import moment from 'moment';
import { AppContext, SttFullDialog, SttTabs } from '../../../sporttia/all';
import ActivityRates from './ActivityRates';
import ActivityClasses from './ActivityClasses';
import ActivityStudents from './ActivityStudents';
import ActivityForm from './ActivityForm';
import ActivityDevices from './ActivityDevices';
import ActivityDetails from './ActivityDetails';
import { useCrud } from '../../../lib/hooks';
import ActivityDocuments from './ActivityDocuments';

export default function Activity({ id, trash, onChange, onClose }) {
    const cxt = useContext(AppContext);
    const [activity, setActivity, , Get] = useCrud();
    const [teachers, setTeachers] = useState([]);

    function loadTeachers() {
        return Get(`/scs/${cxt.sc.id}/teachers`, { page: 1, rows: 1000 }).then(
            (response) => {
                if (response.rows) {
                    setTeachers(response.rows);
                }
            },
        );
    }

    function loadActivity() {
        return Get(`/activities/${id}`, { trash }).then((response) => {
            if (response.activity) {
                setActivity(response.activity);
            }
        });
    }

    function loadRates(idActivity) {
        const idParam = activity.id || idActivity || id;
        cxt.api('GET', `/activities/${idParam}/rates`, {
            success: (response) => {
                if (response.activityRates) {
                    setActivity({
                        ...activity,
                        rates: response.activityRates,
                    });
                }
            },
        });
    }

    useEffect(() => {
        loadTeachers();
        // load
        if (!Number.isNaN(parseInt(id))) {
            Promise.all([loadActivity()]).finally(() => {});
        }
        // creating
        else if (id === 'create') {
            setActivity({
                name: '',
                description: '',
                status: null,
                type: null,
                idSport: null,
                idTeacher: null,
                maxStudents: 0,
                inscIni: moment().format('YYYY-MM-DD 07:00:00'),
                inscEnd: moment().format('YYYY-12-31 23:59:59'),
                ini: moment().format('YYYY-MM-DD 00:00:00'),
                end: moment().format('YYYY-12-31 23:59:59'),
            });
        }
        // offload
        else {
            setActivity(null);
            setTeachers([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (activity && activity.id && !activity.rates) {
            loadRates(activity.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity]);

    if (!activity) {
        return null;
    }

    // Tabs
    const tabs = [
        {
            caption: cxt.t('Detail'),
            component: (
                <ActivityDetails
                    item={activity}
                    teachers={teachers}
                    onSave={(activityToSave) => {
                        setActivity(activityToSave);
                        onChange(activityToSave);
                    }}
                    onDelete={(activityToDelete) => {
                        onChange(activityToDelete);
                        onClose();
                    }}
                />
            ),
        },
        {
            show: !!activity.id,
            caption: cxt.t('Rates'),
            component: <ActivityRates item={activity} onSave={loadRates} />,
        },
        {
            show: !!activity.id,
            caption: cxt.t('Classes'),
            component: <ActivityClasses teachers={teachers} item={activity} />,
        },
        {
            show: !!activity.id,
            caption: cxt.t('Students'),
            component: (
                <ActivityStudents
                    item={activity}
                    onSave={() => {
                        onChange();
                    }}
                    onPayment={() => {
                        onChange();
                    }}
                    onGenerateFee={() => {
                        onChange();
                    }}
                />
            ),
        },
        {
            show: !!activity.id,
            caption: cxt.t('Form'),
            component: <ActivityForm onChange={loadActivity} item={activity} />,
        },
        {
            show: !!activity.id,
            caption: cxt.t('Devices'),
            component: <ActivityDevices item={activity} />,
        },
        {
            show: !!activity.id,
            caption: cxt.t('Documents'),
            component: <ActivityDocuments activity={activity} />,
        },
    ];

    return (
        <SttFullDialog
            open
            onClose={onClose}
            title={id === 'create' ? cxt.t('CreateActivity') : activity.name}
        >
            <Paper>
                <Box p={3}>
                    <SttTabs tabs={tabs} />
                </Box>
            </Paper>
        </SttFullDialog>
    );
}
