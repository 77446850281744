import React, { useState, useEffect, useContext } from 'react';
import { Box, Paper, Button, Grid } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DoneIcon from '@material-ui/icons/Done';
import { AppContext, SttFormText } from '../../../sporttia/all';
import { formatPriceByLocale } from '../../../lib/utils';
import { useTranslations } from '../../../lib/hooks';

export default function BookingSectionPrice({
    price,
    sc,
    onChangePrice,
    modify = false,
}) {
    const cxt = useContext(AppContext);
    const [updating, setUpdating] = useState(false);
    const { translate } = useTranslations();

    // -----| Render |-----
    return (
        <Box mt={3}>
            <Grid container spacing={3}>
                <Grid item md={4} sm={5} xs={12}>
                    <Paper
                        style={{
                            backgroundColor: '#3e9cfd',
                            color: 'white',
                        }}
                    >
                        <Box py={3} align="center">
                            <Box mb={2}>{cxt.t('FinalPriceToPay')}</Box>

                            {updating ? (
                                <Box style={{ width: 100 }}>
                                    <SttFormText
                                        defVal={price}
                                        onChange={({ value }) =>
                                            onChangePrice(value)
                                        }
                                    />

                                    <Box mt={1}>
                                        <Button
                                            size="small"
                                            startIcon={<DoneIcon />}
                                            onClick={() => setUpdating(false)}
                                        >
                                            {cxt.t('Save')}
                                        </Button>
                                    </Box>
                                </Box>
                            ) : (
                                <Box align="center">
                                    <Box p={0} style={{ fontSize: 30 }}>
                                        {formatPriceByLocale(
                                            price,
                                            sc.city?.country?.currency,
                                            sc.city?.country?.locale,
                                        )}
                                    </Box>

                                    {modify && (
                                        <Button
                                            size="small"
                                            startIcon={<CreateIcon />}
                                            onClick={() => setUpdating(true)}
                                        >
                                            {cxt.t('Modify')}
                                        </Button>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Paper>
                </Grid>

                <Grid item md={8} sm={7} xs={12}>
                    <p>
                        <b>{cxt.t('sellingPolicy.title')}</b>
                    </p>
                    <p>
                        {sc.name || ''}
                        {sc?.customer?.cif ? `, ${sc.customer.cif}` : ''}
                        {sc.address ? `, ${sc.address}` : ''}
                        {sc.city &&
                            `, ${sc.city.name}${
                                sc.city.province
                                    ? `, ${sc.city.province.name}`
                                    : ''
                            }${
                                sc.city.country
                                    ? `, ${translate(
                                          `country.${sc.city.country.name}`,
                                      )}`
                                    : ''
                            }`}
                    </p>
                    <Box>{sc.salesPolicy || cxt.t('sellingPolicy.desc')}</Box>
                </Grid>
            </Grid>
        </Box>
    );
}
