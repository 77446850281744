import React, { useContext } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { AppContext } from '../sporttia/all';
import LanguageSelector from '../components/LanguageSelector';
import FooterMenuItem from './FooterMenuItem';
import { getPath } from '../pages/Pages';

const useStyles = makeStyles(() => ({
    footer: {
        flexShrink: 0,
        color: 'white',
        textAlign: 'center',
    },
}));

export default function Footer() {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const classes = useStyles();

    function goContactPage() {
        history.push(getPath('contact'));
    }

    // -----| Render |-----
    return (
        <Box mt={1} pb={2} align="center" className={classes.footer}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
            >
                <Box display="flex" justifyContent="center" alignItems="center">
                    {(!cxt.logged ||
                        (cxt?.logged && cxt?.user?.role === 'ADMIN')) && (
                        <>
                            <LanguageSelector />
                            <FooterMenuItem
                                caption={cxt.t('Contact')}
                                onClick={goContactPage}
                            />
                        </>
                    )}
                    <Box mx={1}>
                        <FooterMenuItem
                            caption={cxt.t('LegalWarning')}
                            onClick={() => {
                                history.push(getPath('privacy'));
                            }}
                        />
                    </Box>
                    <Box mx={1}>
                        <Link
                            href="https://www.sporttia.com/es/condiciones-generales-de-contratacion/"
                            style={{ color: 'black' }}
                        >
                            <Typography variant="caption">
                                Cond. Generales de Contratación
                            </Typography>
                        </Link>
                    </Box>
                </Box>
                <Typography variant="overline" style={{ color: 'black' }}>
                    © {new Date().getFullYear()} Sporttia ({cxt.config.version}{' '}
                    - {cxt.backendVersion}). All rights reserved
                </Typography>
            </Box>
        </Box>
    );
}
