import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

/**
 * Draw column with golf cart availability.
 * @returns {JSX.Element}
 */
export default function GolfTimetableCart({}) {
    const classes = useStyles();

    /**
     * Render golf cart availability without golf cart icon.
     * @param golfCarts [] Array with golf cart availability values.
     * @returns {*}
     */
    const renderGolfCartLine = (golfCarts) =>
        golfCarts &&
        golfCarts.map((golfCart) => (
            <Box className={classes.golfCartBox} display="flex">
                <Box m="auto">{golfCart.amount}</Box>
            </Box>
        ));

    {
        /* Render golf cart icon and golf cart availability column using renderGolfCartLine() method. */
    }
    return (
        <Box>
            <Box align="center" className={classes.golfCartIconBox}>
                <LocalShippingIcon />
            </Box>
            {renderGolfCartLine(golfCartDataMockup.carts)}
        </Box>
    );
}

const useStyles = makeStyles(() => ({
    golfCartBox: {
        height: 40,
        width: 40,
        backgroundColor: '#ECECEC',
        color: 'black',
        fontSize: 20,
        margin: 6,
        marginBottom: 9,
        borderRadius: 2,
    },
    golfCartIconBox: {
        height: 21,
    },
}));

{
    /* This data will be removed when golf module development finished. */
}
const golfCartDataMockup = {
    carts: [
        {
            id: 0,
            amount: 7,
        },
        {
            id: 1,
            amount: 2,
        },
        {
            id: 2,
            amount: 2,
        },
        {
            id: 3,
            amount: 2,
        },
        {
            id: 4,
            amount: 2,
        },
        {
            id: 5,
            amount: 2,
        },
        {
            id: 6,
            amount: 2,
        },
        {
            id: 7,
            amount: 2,
        },
        {
            id: 8,
            amount: 2,
        },
        {
            id: 9,
            amount: 2,
        },
    ],
};
