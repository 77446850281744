import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { AppContext } from '../../../../sporttia/AppContext';
import { useLoader } from '../../../../lib/hooks';
import useApisService from '../../../../services/ApisService';
import SttValidatedForm from '../../../../sporttia/SttValidatedForm';
import { SttDialog } from '../../../../sporttia/dialogs/SttDialog';

/**
 * Dialog to modify, create and delete api model property.
 * @param apiModelId API Model ID.
 * @param propertyValue Property object.
 * @param onClose Function executed when closing the dialog.
 * @param onCreate Action taken when creating the tpv configuration.
 * @param onUpdate Action taken when updating the tpv configuration.
 * @param onDelete Method executed when removing tpv configuration.
 * @returns {JSX.Element}
 */
export default function AdminApiModelPropertyDialog({
    apiModelId,
    propertyValue,
    onClose,
    onCreate,
    onUpdate,
    onDelete,
}) {
    const cxt = useContext(AppContext);
    const apisService = useApisService();
    const form = useForm();
    const [property, setProperty] = useState(propertyValue);
    const deleteMutation = useMutation(
        (params) => apisService.removeApiModelProperty(params?.id),
        {
            onSuccess: onDelete,
        },
    );
    const updateMutation = useMutation(
        (params) =>
            apisService.updateApiModelProperty(params?.id, params?.params),
        {
            onSuccess: (data) => {
                onUpdate(data);
                setProperty(data?.apiModelProperty);
            },
        },
    );
    const createMutation = useMutation(
        (params) =>
            apisService.createApiModelProperty(params?.id, params?.params),
        {
            onSuccess: (data) => {
                onCreate(data);
                setProperty(data?.apiModelProperty);
            },
        },
    );
    const [, loader] = useLoader([
        deleteMutation.status,
        updateMutation.status,
        createMutation.status,
    ]);

    /**
     * Method executed when the user clicks on save or create api model property.
     * @param formData Form data.
     */
    const mutateProperty = (formData) => {
        if (property?.id) {
            updateMutation.mutate({ id: property?.id, params: formData });
        } else {
            createMutation.mutate({ id: apiModelId, params: formData });
        }
    };

    /**
     * When the "property" data is returned by the API, it is set in the form.
     */
    useEffect(() => {
        if (property) {
            form.reset({
                id: property?.id,
                created: property?.created,
                type: property?.type,
                name: property?.field,
                description: property?.description,
            });
        } else {
            form.reset({
                id: '',
                created: new Date(),
                type: '',
                name: '',
                description: '',
            });
        }
    }, [property]);

    /**
     * When the value of the column passed to the component is modified, the selected internal
     * column saved in the "property" state will be modified.
     */
    useEffect(() => {
        setProperty(propertyValue);
    }, [propertyValue]);

    return (
        <SttDialog
            title={cxt.t('Property')}
            maxWidth="sm"
            open={property !== null}
            onClose={onClose}
            content={
                <>
                    {loader}
                    <SttValidatedForm
                        form={form}
                        fields={[
                            {
                                type: 'textinput',
                                name: 'id',
                                caption: 'ID',
                                disabled: true,
                            },
                            {
                                type: 'date',
                                name: 'created',
                                caption: cxt.t('created'),
                                disabled: true,
                            },
                            {
                                type: 'textinput',
                                name: 'type',
                                caption: cxt.t('Type'),
                            },
                            {
                                type: 'textinput',
                                name: 'name',
                                caption: cxt.t('Name'),
                            },
                            {
                                type: 'textinput',
                                name: 'description',
                                caption: cxt.t('Description'),
                            },
                        ]}
                    />
                </>
            }
            buttons={[
                {
                    type: 'save',
                    onClick: form.handleSubmit((formData) =>
                        mutateProperty(formData),
                    ),
                },
                {
                    type: 'delete',
                    onClick: () => deleteMutation.mutate({ id: property?.id }),
                },
            ]}
        />
    );
}
