import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import React, { useState, ReactNode } from 'react';
import ModalConfirmation from '../../layout/ModalConfirmation';

type SttButtonProps = {
    caption?: string;
    icon?: ReactNode;
    variant?: ButtonProps['variant'];
    disabled?: boolean;
    loading?: boolean;
    confirmation?: boolean;
    onClick?: () => void;
    children?: ReactNode;
} & ButtonProps;

/**
 * General button
 */
export function SttButton({
    caption,
    icon,
    variant = 'outlined',
    disabled = false,
    loading = false,
    confirmation = false,
    onClick,
    ...rest
}: SttButtonProps) {
    const [confirmationDialog, setConfirmationDialog] = useState({
        show: false,
    });

    return (
        <span
            style={{
                position: 'relative',
            }}
        >
            <Button
                variant={variant}
                disabled={!!(loading || disabled)}
                startIcon={icon}
                onClick={
                    confirmation
                        ? () => setConfirmationDialog({ show: true })
                        : onClick
                }
                {...rest}
            >
                <div>{rest.children ? rest.children : caption}</div>
            </Button>

            {loading && (
                <CircularProgress
                    size={24}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: -12,
                        marginLeft: -12,
                    }}
                />
            )}

            <ModalConfirmation
                show={confirmationDialog.show}
                onClose={() => setConfirmationDialog({ show: false })}
                onAccept={() => {
                    onClick?.();
                    setConfirmationDialog({ show: false });
                }}
            />
        </span>
    );
}
