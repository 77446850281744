import React, { useContext } from 'react';
import {
    Clear,
    Create,
    Done,
    Timer,
    Error,
    HelpOutline,
} from '@material-ui/icons';
import { Chip } from '@material-ui/core';
import { AppContext } from '../AppContext';

const theme: Record<string, { color: string; icon: JSX.Element }> = {
    PAID: { color: '#75bb41', icon: <Done style={{ color: 'white' }} /> },
    NOT_PAID: {
        color: '#ff5353',
        icon: <Clear style={{ color: 'white' }} />,
    },
    REFUNDED: {
        color: '#b058d7',
        icon: <Clear style={{ color: 'white' }} />,
    },
    PAYING: {
        color: '#eaab1e',
        icon: <Timer style={{ color: 'white' }} />,
    },
    CREATING: {
        color: '#4287f5',
        icon: <Create style={{ color: 'white' }} />,
    },
    ERROR: {
        color: '#ff0000',
        icon: <Error style={{ color: 'white' }} />,
    },
};

const defaultTheme = {
    color: '#ccc',
    icon: <HelpOutline style={{ color: 'white' }} />,
};

type SttChipPaymentStatusProps = {
    status: string | undefined;
};

/**
 * Chip payment status
 */
export function SttChipPaymentStatus({ status }: SttChipPaymentStatusProps) {
    const cxt = useContext(AppContext)!;

    if (!status) {
        return null;
    }

    const selectedTheme = theme[status] ?? defaultTheme;

    return (
        <Chip
            icon={selectedTheme.icon}
            size="small"
            label={cxt.t(`PS.${status}`)}
            style={{
                backgroundColor: selectedTheme.color,
                color: 'white',
            }}
        />
    );
}
