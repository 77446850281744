import React, { useContext } from 'react';
import { AppContext, SttButton, SttDialog } from '../../../sporttia/all';

export default function BookingScheduleDeleteDialog({
    open,
    onResponse,
    onClose,
}) {
    const cxt = useContext(AppContext);

    return (
        <SttDialog
            open={open}
            onClose={onClose}
            title={cxt.t('removeFixedRental')}
            buttons={[
                {
                    type: 'component',
                    component: (
                        <SttButton
                            caption={cxt.t(`Yes`)}
                            onClick={() => onResponse(true)}
                        />
                    ),
                },
                {
                    type: 'component',
                    component: (
                        <SttButton
                            caption={cxt.t(`No`)}
                            onClick={() => onResponse(false)}
                        />
                    ),
                },
            ]}
            content={<div>{cxt.t('question.remove.fixedRentals')}</div>}
        />
    );
}
