import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import {
    NavigateBefore as NavigateBeforeIcon,
    NavigateNext as NavigateNextIcon,
} from '@material-ui/icons';

type SttPaginationProps = {
    page?: number;
    pages?: number;
    count?: number;
    onChangePage: (page: number) => void;
};

export function SttPagination({
    page = 0,
    pages = 0,
    count = 0,
    onChangePage,
}: SttPaginationProps) {
    // -----| Render |-----
    return (
        <Box>
            <IconButton
                disabled={page < 2}
                onClick={() => onChangePage(page - 1)}
            >
                <NavigateBeforeIcon />
            </IconButton>
            {page} / {pages} [ {count} ]
            <IconButton
                disabled={page >= pages}
                onClick={() => onChangePage(page + 1)}
            >
                <NavigateNextIcon />
            </IconButton>
        </Box>
    );
}
