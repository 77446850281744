import React, { useContext, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import { Box, Grid, Typography } from '@material-ui/core';
import useScsService from '../../../services/ScsService';
import { useLoader, useTranslations } from '../../../lib/hooks';
import SttValidatedForm from '../../../sporttia/SttValidatedForm';
import useGolfService from '../../../services/GolfService';
import { AppContext } from '../../../sporttia/AppContext';
import GolfHoleCard from './GolfHoleCard';

/**
 * Golf course configuration view. View to modify the data of a golf course.
 * @param golfCourse Golf course object.
 * @param status API Request status.
 * @param onCreate Launched when a new golf course is created.
 * @param onUpdate Launched when a golf course is updated.
 * @param onUpdateHole Launched when a golf course hole is updated.
 * @param onDelete Launched when a golf course is removed.
 * @returns {JSX.Element}
 */
export default function GolfCourseConfiguration({
    golfCourse,
    status,
    onCreate,
    onUpdate,
    onUpdateHole,
    onDelete,
}) {
    const cxt = useContext(AppContext);
    const { translate } = useTranslations();
    const scsService = useScsService();
    const golfService = useGolfService();
    const deleteMutation = useMutation(
        (params) => golfService.removeGolfCourse(params?.id),
        {
            onSuccess: onDelete,
            onError: (error) => cxt.showMessage('E', error?.error?.msg),
        },
    );
    const updateMutation = useMutation(
        (params) => golfService.updateGolfCourse(params?.id, params?.params),
        {
            onSuccess: onUpdate,
            onError: (error) => cxt.showMessage('E', error?.error?.msg),
        },
    );
    const createMutation = useMutation(
        (params) => scsService.createScsGolfCourse(cxt?.sc?.id, params?.params),
        {
            onSuccess: onCreate,
            onError: (error) => cxt.showMessage('E', error?.error?.msg),
        },
    );
    const form = useForm();
    const [, loader] = useLoader([
        createMutation.status,
        updateMutation.status,
        deleteMutation.status,
    ]);

    /**
     * Method executed when the user clicks on save or create golf course.
     */
    const mutateGolfCourse = (formData) => {
        if (golfCourse?.id) {
            updateMutation.mutate({ id: golfCourse?.id, params: formData });
        } else {
            createMutation.mutate({ params: formData });
        }
    };

    /**
     * When the data is returned by the API, it is set in the form.
     */
    useEffect(() => {
        if (status === 'success' && golfCourse) {
            form.reset({
                name: golfCourse?.name,
                description: golfCourse?.description,
                type: golfCourse?.type,
                status: golfCourse?.status,
                holes: golfCourse?.holes?.count,
                duration: golfCourse?.duration,
            });
        } else {
            form.reset({
                name: '',
                description: '',
                type: 'ONLY9',
                status: 'ACTIVE',
                holes: '',
                duration: 0,
            });
        }
    }, [golfCourse]);

    return (
        <>
            {loader}
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <SttValidatedForm
                        form={form}
                        loadingData={status === 'loading'}
                        fields={[
                            {
                                type: 'textInput',
                                name: 'name',
                                caption: translate('Name'),
                            },
                            {
                                type: 'textArea',
                                name: 'description',
                                caption: translate('description'),
                                rows: 8,
                            },
                            {
                                type: 'select',
                                name: 'type',
                                caption: translate('Type'),
                                options: [
                                    {
                                        caption: translate('ONLY18'),
                                        value: 'ONLY18',
                                    },
                                    {
                                        caption: translate('ONLY9'),
                                        value: 'ONLY9',
                                    },
                                    {
                                        caption: translate('9AND18'),
                                        value: '9AND18',
                                    },
                                ],
                                disableClearable: true,
                                md: 4,
                            },
                            {
                                type: 'select',
                                name: 'status',
                                caption: translate('Status'),
                                options: [
                                    {
                                        caption: translate('ACTIVE'),
                                        value: 'ACTIVE',
                                    },
                                    {
                                        caption: translate('INACTIVE'),
                                        value: 'INACTIVE',
                                    },
                                ],
                                disableClearable: true,
                                md: 4,
                            },
                            {
                                type: 'textInput',
                                name: 'duration',
                                caption: translate('Duration'),
                                md: 4,
                            },
                        ]}
                        buttons={[
                            {
                                show: !golfCourse?.trash,
                                type: 'save',
                                onClick: form.handleSubmit((formData) =>
                                    mutateGolfCourse(formData),
                                ),
                            },
                            {
                                show: !!golfCourse?.id && !golfCourse?.trash,
                                type: 'delete',
                                onClick: () =>
                                    deleteMutation.mutate({
                                        id: golfCourse?.id,
                                    }),
                            },
                        ]}
                    />
                </Grid>
                {golfCourse?.id && (
                    <Grid item md={6}>
                        <Grid container spacing={2}>
                            {golfCourse?.holes?.rows.map((hole) => (
                                <Grid item xs={12} md={4}>
                                    <GolfHoleCard
                                        hole={hole}
                                        onUpdate={onUpdateHole}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                        <Box marginTop={1}>
                            <Typography noWrap style={{ fontSize: '10px' }}>
                                {translate('legendTee')}
                            </Typography>
                            <Typography noWrap style={{ fontSize: '10px' }}>
                                {translate('legendHole')}
                            </Typography>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </>
    );
}
