import React from 'react';
import { CircularProgress, Container } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useQuery } from 'react-query';
import { SttKeyCard } from '../../../sporttia/public/cards/SttKeyCard';
import { USER_UNLOCKERS } from '../../../lib/queryKeys';
import useMyService from '../../../services/MyService';
import SttError from '../../../components/error/SttError';

/**
 * View where the user can consult all their unlockers.
 * @returns {JSX.Element}
 */
export default function UserKeys() {
    const myService = useMyService();

    const { data, status } = useQuery(
        [USER_UNLOCKERS],
        () => myService.getMyUnlockers(),
        {
            onSuccess: (response) => {
                const filteredResponse = { ...response };

                filteredResponse.count = 0;
                filteredResponse.rows = [];

                response.rows.forEach((unlocker) => {
                    if (unlocker.type === 'PIN' || unlocker.type === 'QR') {
                        filteredResponse.count += 1;
                        filteredResponse.rows.push(unlocker);
                    }
                });

                return filteredResponse;
            },
        },
    );

    if (status === 'idle') {
        return null;
    }

    if (status === 'loading') {
        return (
            <Container maxWidth="sm">
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    if (status === 'error') {
        return (
            <Container maxWidth="sm">
                <SttError />
            </Container>
        );
    }

    return (
        <>
            {data.rows.map((unlocker) => (
                <Box key={unlocker.id}>
                    <SttKeyCard unlocker={unlocker} />
                </Box>
            ))}
        </>
    );
}
