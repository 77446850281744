import React, { useState, useEffect, useContext } from 'react';
import {} from '@material-ui/core';
import { AppContext, SttFullDialog, SttTabs } from '../../../sporttia/all';
import TeacherDetail from './TeacherDetail';
import TeacherClasses from './TeacherClasses';

export default function Teacher({ teacherId, onClose, title }) {
    const cxt = useContext(AppContext);
    const [teacherName, setTeacherName] = useState(title);
    const [idTeacher, setIdTeacher] = useState(teacherId);

    // -----| Render |-----
    return (
        <SttFullDialog
            open={teacherId !== null}
            onClose={onClose}
            title={teacherName}
        >
            <SttTabs
                tabs={[
                    {
                        caption: cxt.t('Detail'),
                        component: (
                            <TeacherDetail
                                idTeacher={teacherId}
                                onClose={onClose}
                                onCreate={(teacherName, id) => {
                                    setTeacherName(teacherName);
                                    setIdTeacher(id);
                                }}
                            />
                        ),
                    },
                    {
                        caption: cxt.t('Classes'),
                        component: (
                            <TeacherClasses
                                idTeacher={
                                    idTeacher !== 'create' ? idTeacher : null
                                }
                            />
                        ),
                    },
                ]}
            />
        </SttFullDialog>
    );
}
