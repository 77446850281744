import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Link } from '@material-ui/core';
import DoneOutlineOutlinedIcon from '@material-ui/icons/DoneOutlineOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import TPVSwitcherLegacy from './TPVSwitcherLegacy';
import useTpvsService from '../../../services/TpvsService';
import SttFullPageSpinner from '../../../sporttia/spinners/SttFullPageSpinner';
import SttFullPageError from '../../../components/error/SttFullPageError';
import { useTranslations } from '../../../lib/hooks';
import { AppContext } from '../../../sporttia/AppContext';
import { isObject } from '../../../types/common';
import { AlertDialog } from '../../../components/dialogs/AlertDialog';

/**
 * Sporttia ofrece diferentes métodos de pago. Este componente renderiza el componente para pagar según el banco del TPV.
 * Anteriormente, se utilizaba TPVSwitcherLegacy con este propósito. Dado que no se han migrado todos los métodos de pago al nuevo componente,
 * TPVSwitcherLegacy se utiliza como fallback.
 */
export default function TPVSwitcher() {
    const cxt = useContext(AppContext)!;
    const history = useHistory();
    const { translate } = useTranslations();
    const { id } = useParams<{ id: string }>();

    const tpvService = useTpvsService();

    const { mutate, data, status } = tpvService.useCreateTpvPurchase({
        onError: (error) => {
            if (!isObject(error) || !(typeof error.description === 'string')) {
                return;
            }
            // ¿Es necesario traducir `error.description`?
            cxt.showMessage('E', translate(error.description), 3000);
        },
    });

    useEffect(() => {
        mutate({
            id: Number(id),
        });
    }, [id, mutate]);

    if (status === 'error') {
        return <SttFullPageError />;
    }

    if (status === 'loading') {
        return <SttFullPageSpinner />;
    }

    if (!data) {
        return null;
    }

    if (data.urlRedirection) {
        const { urlRedirection } = data;
        return (
            <AlertDialog
                title={translate('TermsAndConditions')}
                open
                buttons={[
                    {
                        icon: <CloseOutlinedIcon />,
                        caption: 'Denegar',
                        onClick: () => {
                            history.push('/');
                        },
                    },
                    {
                        icon: <DoneOutlineOutlinedIcon />,
                        caption: 'Aceptar',
                        onClick: () => {
                            window.location.replace(urlRedirection);
                        },
                    },
                ]}
                // @ts-expect-error Link is not a string
                subtitle={
                    <>
                        {translate('IHaveReadAndAccept')}{' '}
                        <Link
                            onClick={() => {
                                window
                                    .open(
                                        'https://www.sporttia.com/es/condiciones-generales-de-contratacion/',
                                        '_blank',
                                    )
                                    ?.focus();
                            }}
                        >
                            {translate('TermsAndConditions')}
                        </Link>
                    </>
                }
            />
        );
    }

    return <TPVSwitcherLegacy data={data} />;
}
