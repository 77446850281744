import React, { useContext, useEffect, useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box } from '@material-ui/core';
import { SttPagination } from './SttPagination';
import { AppContext } from './AppContext';
import ActivityAssistance from '../types/models/ActivityAssistance';
import { GetActivityStudentAssistences200 } from '../types/api/paths/Student';

const useStyles = makeStyles({
    generalTable: {
        tableLayout: 'fixed',
    },
    tableRightBorder: {
        borderWidth: 1,
        borderColor: '#E0E0E0',
        borderStyle: 'solid',
    },
    firstTableColumn: {
        width: '250px',
        minWidth: '250px',
    },
    tableColumn: {
        fontSize: '12px',
        width: '30px',
        minWidth: '30px',
    },
    tableCell: {
        boxSizing: 'border-box',
        height: 30,
    },
    tableCellData: {
        '&:hover': {
            backgroundColor: 'rgba(33, 150, 243, 0.5)',
            cursor: 'pointer',
        },
        fontWeight: 'bold',
        fontSize: '10px',
        position: 'relative',
    },
    tableCellNoData: {
        backgroundColor: '#e2f1f8',
    },
    tableCellAssit: {
        backgroundColor: '#f8ffd7',
    },
    tableCellMissed: {
        backgroundColor: '#ffddc1',
    },
    tableCellRecoverable: {
        backgroundColor: '#ffffb0',
    },
    tableCellRecovered: {
        backgroundColor: '#f0e2ff',
    },
    tableCellRecoverer: {
        backgroundColor: '#cfffdd',
    },
    notes: {
        position: 'absolute',
        bottom: '0',
        right: '0',
        width: '5px',
        height: '5px',
        backgroundColor: 'red',
    },
    nameCell: {
        height: 30,
        display: 'flex',
        alignItems: 'center',
        padding: '0px 6px',
    },
    name: {
        fontSize: '12px',
    },
});

type Column = {
    title: string;
    field: string;
    type: 'text';
};

type SttAttendanceTableProps = {
    columns: Column[];
    data: GetActivityStudentAssistences200;
    onClickCell: (row: ActivityAssistance, col: Column) => void;
    onChangePageCallback: (p: number) => void;
};

export function SttAttendanceTable({
    columns,
    onChangePageCallback,
    data,
    onClickCell,
}: SttAttendanceTableProps) {
    const cxt = useContext(AppContext)!;
    const classes = useStyles();
    const [page, setPage] = useState(1);
    const [internalData, setInternalData] = useState<
        GetActivityStudentAssistences200 | undefined
    >(data);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const onChangePage = (p: number) => {
        setPage(p);
        onChangePageCallback(p);
    };
    /*
    useEffect(() => {
        if (autoload && onFetch) {
            onFetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
*/
    useEffect(() => {
        if (data?.rows) {
            setInternalData(data);

            if (page === 1 && data?.rows?.length < data?.count) {
                setRowsPerPage(data?.rows.length);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    function getLetterByAttendanceStatus(status: ActivityAssistance['status']) {
        switch (status) {
            case 'ASSIST':
                return 'A';
            case 'MISSED':
                return 'X';
            case 'RECOVERABLE':
                return 'R';
            case 'RECOVERER':
                return 'Rc';
            case 'RECOVERED':
                return 'Rd';
            default:
                return null;
        }
    }

    return (
        <>
            <Box display="flex" flexDirection="row-reverse">
                <SttPagination
                    page={page}
                    pages={Math.ceil(data.count / rowsPerPage)}
                    count={data?.count}
                    onChangePage={(p) => {
                        onChangePage(p);
                    }}
                />
            </Box>

            <Box display="flex" flexWrap="nowrap">
                {/* Los nombres de los alumnos se pintan por separado a la izquierda, para que sean independientes del scroll de la tabla de días */}
                <Box
                    display="flex"
                    flexWrap="nowrap"
                    flexDirection="column"
                    alignItems="stretch"
                    style={{ paddingRight: 8 }}
                >
                    <div
                        className={`${classes.nameCell}`}
                        style={{ height: 37 }}
                    >
                        {cxt.t('Name')}
                    </div>
                    {internalData?.rows &&
                        internalData?.rows?.map((row: ActivityAssistance) => (
                            <div key={row.id} className={`${classes.nameCell}`}>
                                <span className={classes.name}>
                                    {row?.user?.fullName}
                                </span>
                            </div>
                        ))}
                </Box>
                {/* A continuación se pinta la tabla de días */}
                <Box flex={1} style={{ overflowX: 'auto' }}>
                    <TableContainer>
                        <Table
                            size="small"
                            aria-label="a dense table"
                            className={classes.generalTable}
                        >
                            <TableHead>
                                <TableRow>
                                    {columns?.map((col) => (
                                        <TableCell
                                            className={`${classes.tableRightBorder} ${classes.tableColumn}`}
                                            key={col.title}
                                        >
                                            {col.title}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {internalData?.rows &&
                                    internalData?.rows?.map((row) => (
                                        <TableRow key={row.id}>
                                            {columns?.map((col) => {
                                                const assistenceFound =
                                                    row.assistences.find(
                                                        (assistence) =>
                                                            new Date(
                                                                assistence.class.ini,
                                                            ).getDate() ===
                                                            parseInt(col.field),
                                                    );
                                                const assistenceLetter =
                                                    assistenceFound
                                                        ? getLetterByAttendanceStatus(
                                                              assistenceFound.status,
                                                          )
                                                        : null;
                                                return assistenceFound ? (
                                                    <TableCell
                                                        key={col.title}
                                                        onClick={() =>
                                                            onClickCell
                                                                ? onClickCell(
                                                                      row,
                                                                      col,
                                                                  )
                                                                : null
                                                        }
                                                        className={`${
                                                            classes.tableRightBorder
                                                        } ${
                                                            classes.tableCell
                                                        } ${
                                                            classes.tableCellData
                                                        }
                                                    ${
                                                        assistenceLetter === 'A'
                                                            ? classes.tableCellAssit
                                                            : ''
                                                    }
                                                    ${
                                                        assistenceLetter === 'X'
                                                            ? classes.tableCellMissed
                                                            : ''
                                                    }
                                                    ${
                                                        assistenceLetter === 'R'
                                                            ? classes.tableCellRecoverable
                                                            : ''
                                                    }
                                                    ${
                                                        assistenceLetter ===
                                                        'Rd'
                                                            ? classes.tableCellRecovered
                                                            : ''
                                                    }
                                                    ${
                                                        assistenceLetter ===
                                                        'Rc'
                                                            ? classes.tableCellRecoverer
                                                            : ''
                                                    }`}
                                                        align="center"
                                                    >
                                                        {getLetterByAttendanceStatus(
                                                            assistenceFound.status,
                                                        )}
                                                        {assistenceFound.notes &&
                                                        assistenceFound.notes !==
                                                            '' ? (
                                                            <div
                                                                className={
                                                                    classes.notes
                                                                }
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </TableCell>
                                                ) : (
                                                    <TableCell
                                                        key={col.title}
                                                        className={`${classes.tableRightBorder} ${classes.tableCell} ${classes.tableCellNoData}`}
                                                        align="center"
                                                    >
                                                        {' '}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))}

                                {internalData?.count === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={100}>
                                            <div
                                                className="text-center mt-4 mb-4"
                                                style={{
                                                    fontSize: 20,
                                                    color: '#ccc',
                                                }}
                                            >
                                                {cxt.t('NoResults')}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>

            <Box display="flex" flexDirection="row-reverse">
                <SttPagination
                    page={page}
                    pages={Math.ceil(data.count / rowsPerPage)}
                    count={data?.count}
                    onChangePage={(p) => {
                        onChangePage(p);
                    }}
                />
            </Box>
        </>
    );
}
