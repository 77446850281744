import React, { useContext } from 'react';
import { AppContext, SttList } from '../all';

/**
 * SttConsentList - list of consent templates
 * @param consents
 * @param onAdd
 * @param onDelete
 */
export function SttConsentList({ consents, onAdd, onSelect, onDelete }) {
    const cxt = useContext(AppContext);

    return (
        <SttList
            fullWidth
            title={cxt.t('consentTemplates')}
            data={(consents || []).map((consent) => ({
                caption: consent.name,
                objToSelect: consent,
            }))}
            onAdd={onAdd}
            onSelect={(consent) => onSelect && onSelect(consent)}
            onDelete={(consent) => onDelete && onDelete(consent)}
        />
    );
}
