import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import constants from '../../../config/constants';
import {
    AppContext,
    SttDialog,
    SttFormPeriod,
    SttFormSelect,
    SttFormText,
    SttFormTextarea,
    SttSelectFixedInterval,
} from '../../../sporttia/all';
import translations from '../../../translations';
import { useTranslations } from '../../../lib/hooks';

export default function CreateGroupDialog({ open, onSave, onClose }) {
    const [group, setGroup] = useState();
    const [parentGroups, setParentGroups] = useState([]);
    // const [parentGroup, setParentGroup] = useState(null);

    const cxt = useContext(AppContext);
    const { translate } = useTranslations();

    /**
     * Load groups that they can be parents
     */
    function loadParentGroups() {
        cxt.api('GET', `/scs/${cxt.sc.id}/groups`, {
            params: {
                year: moment().get('year'),
                types: 'NORMAL,ABONADOS',
                page: 1,
                rows: 1000,
            },
            success: (result) => {
                if (result.rows) {
                    setParentGroups(result.rows);
                }
                // console.log("GROUPS", result.rows.reduce((res, val, i) => ( res + (i>0?", ":"") + val.name),""));
            },
        });
    }

    useEffect(() => {
        if (open) loadParentGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        // Set init group data
        if (open) {
            setGroup({
                name: '',
                maxMembers: 0,
                type: 'NORMAL',
                idParent: null,
                description: '',
                ini: moment().format('YYYY-MM-DD'),
                end: moment().endOf('year').format('YYYY-MM-DD'),
                enrollmentPrice: 0,
                expiration: '0000-00-00 00:00:00',
                feeTaxConceptCode: Object.values(constants.orgt.scIds).includes(
                    cxt?.sc?.id,
                )
                    ? constants.orgt.taxCodes.noOption
                    : undefined,
                signupTaxConceptCode: Object.values(
                    constants.orgt.scIds,
                ).includes(cxt?.sc?.id)
                    ? constants.orgt.taxCodes.noOption
                    : undefined,
            });
        }
    }, [open]);

    useEffect(() => {
        if (group) {
            if (group.type === 'NORMAL') {
                if ('feePrice' in group) delete group.feePrice;
                if ('feeInterval' in group) delete group.feeInterval;
            } else if (group.type === 'ABONADOS') {
                if (!('feePrice' in group)) setGroup({ ...group, feePrice: 0 });
                if (!('feeInterval' in group))
                    setGroup({ ...group, feeInterval: '0000-00-00 00:00:00' });
            }
        }
    }, [group]);

    function saveAndClose() {
        if (onSave && onSave.constructor === Function) {
            onSave();
        }
        if (onClose && onClose.constructor === Function) {
            onClose();
        }
    }

    /**
     * Create group
     */
    function save() {
        if (group?.type === constants.group.types.family.name) {
            group.maxMembers = 0;
        }

        if (group?.feeInterval === '0000-00-00 00:00:00') {
            group.feeInterval = '';
        }

        cxt.api('POST', `/scs/${cxt.sc.id}/groups`, {
            params: { ...group },
            success: (response) => {
                if (response.group) {
                    cxt.showMessage('S', cxt.t('Saved'));
                    saveAndClose();
                }
            },
        });
    }

    // Set named field to specified value
    function handleChange({ name, value }) {
        setGroup({ ...group, [name]: value });
    }

    if (!group) {
        return null;
    }

    return (
        <SttDialog
            title={cxt.t('NewGroup')}
            open={open}
            onClose={onClose}
            buttons={[
                {
                    type: 'save',
                    onClick: save,
                },
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
            content={
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={9}>
                        <SttFormText
                            name="name"
                            caption={cxt.t('Name')}
                            defVal={group.name}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <SttFormSelect
                            name="type"
                            caption={cxt.t('Type')}
                            defVal={group.type}
                            options={[
                                {
                                    caption: cxt.t(
                                        constants.group.types.normal
                                            .translationCode,
                                    ),
                                    value: constants.group.types.normal.name,
                                },
                                {
                                    caption: cxt.t(
                                        constants.group.types.abonados
                                            .translationCode,
                                    ),
                                    value: constants.group.types.abonados.name,
                                },
                                {
                                    caption: cxt.t(
                                        constants.group.types.family
                                            .translationCode,
                                    ),
                                    value: constants.group.types.family.name,
                                },
                                /* , {
								caption: cxt.t(`GT.SPORT`),
								value: 'SPORT',
							} */
                            ]}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {group.type !== 'FAMILY' ? (
                            <SttFormText
                                name="maxMembers"
                                caption={cxt.t('MaxUsers')}
                                defVal={group.maxMembers}
                                onChange={handleChange}
                            />
                        ) : (
                            <SttFormText
                                name="maxBeneficiaries"
                                caption={cxt.t('Beneficiaries')}
                                defVal={group.maxBeneficiaries}
                                onChange={handleChange}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SttFormText
                            name="enrollmentPrice"
                            caption={`${cxt.t('Inscription')} ${
                                cxt.sc?.city?.country?.currencySymbol
                            }`}
                            defVal={group.enrollmentPrice}
                            onChange={(price) => {
                                const processPrice = price;
                                processPrice.value = price.value.replace(
                                    /[^0-9]/g,
                                    '.',
                                );
                                handleChange(processPrice);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SttFormSelect
                            name="idParent"
                            caption={cxt.t('ParentGroup')}
                            defVal={group.idParent}
                            options={parentGroups.map((currentGroup) => ({
                                caption: currentGroup.name,
                                value: currentGroup.id,
                            }))}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SttFormTextarea
                            name="description"
                            caption={cxt.t('Description')}
                            defVal={group.description}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SttFormPeriod
                            nameIni="ini"
                            nameEnd="end"
                            defValIni={group.ini}
                            defValEnd={group.end}
                            onChange={handleChange}
                        />
                    </Grid>
                    {(group.type === 'ABONADOS' || group.type === 'AF') && (
                        <>
                            <Grid item xs={12}>
                                <SttFormText
                                    name="feePrice"
                                    caption={cxt.t('feePrice')}
                                    defVal={group.feePrice}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SttSelectFixedInterval
                                    caption={cxt.t('generateFeesEvery')}
                                    name="feeInterval"
                                    defVal={group.feeInterval}
                                    options={[
                                        'NO PERIOD',
                                        '1 WEEK',
                                        '2 WEEK',
                                        '1 MONTH',
                                        '2 MONTH',
                                        '3 MONTH',
                                        '4 MONTH',
                                        '6 MONTH',
                                        '1 YEAR',
                                    ]}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12}>
                        <SttSelectFixedInterval
                            caption={cxt.t('Expiration')}
                            name="expiration"
                            defVal={group.expiration}
                            options={['!FORTNIGHT']}
                            onChange={handleChange}
                        />
                    </Grid>
                    {Object.values(constants.orgt.scIds).includes(
                        cxt?.sc?.id,
                    ) && (
                        <Grid item md={6} sm={6} xs={6}>
                            <SttFormSelect
                                name="feeTaxConceptCode"
                                caption={cxt.t(translations.orgt.feeTaxConcept)}
                                defVal={
                                    group?.feeTaxConceptCode ??
                                    constants.orgt.taxCodes.noOption
                                }
                                options={[
                                    {
                                        caption: translate(
                                            translations.orgt.noOption,
                                        ),
                                        value: null,
                                    },
                                    {
                                        caption: translate(
                                            translations.orgt.feePass,
                                        ),
                                        value: constants.orgt.taxCodes
                                            .feePassCode,
                                    },
                                    {
                                        caption: translate(
                                            translations.orgt.feeRoomActivity,
                                        ),
                                        value: constants.orgt.taxCodes
                                            .feeRoomActivity,
                                    },
                                    {
                                        caption: translate(
                                            translations.orgt
                                                .feeAquaticActivity,
                                        ),
                                        value: constants.orgt.taxCodes
                                            .feeAquaticActivity,
                                    },
                                    {
                                        caption: translate(
                                            translations.orgt.feeSchoolActivity,
                                        ),
                                        value: constants.orgt.taxCodes
                                            .feeSchoolActivity,
                                    },
                                    {
                                        caption: translate(
                                            translations.orgt.assurance,
                                        ),
                                        value: constants.orgt.taxCodes
                                            .assurance,
                                    },
                                ]}
                                onChange={handleChange}
                            />
                        </Grid>
                    )}
                    {Object.values(constants.orgt.scIds).includes(
                        cxt?.sc?.id,
                    ) && (
                        <Grid item md={6} sm={6} xs={6}>
                            <SttFormSelect
                                name="signupTaxConceptCode"
                                caption={cxt.t(
                                    translations.orgt.signupTaxConcept,
                                )}
                                defVal={
                                    group?.signupTaxConceptCode ??
                                    constants.orgt.taxCodes.noOption
                                }
                                options={[
                                    {
                                        caption: translate(
                                            translations.orgt.noOption,
                                        ),
                                        value: null,
                                    },
                                    {
                                        caption: translate(
                                            translations.orgt.enrollment,
                                        ),
                                        value: constants.orgt.taxCodes
                                            .enrollment,
                                    },
                                ]}
                                onChange={handleChange}
                            />
                        </Grid>
                    )}
                </Grid>
            }
        />
    );
}
