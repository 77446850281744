import React, { useState, useEffect, useContext } from 'react';
import { Box, Paper, Grid } from '@material-ui/core';
import moment from 'moment';
import {
    AppContext,
    SttLabelForm,
    SttFormTextarea,
    SttFormText,
    SttFormSelect,
} from '../../../sporttia/all';

/**
 * FIRST STAGE: user
 */
export default function BookingSectionMainData({
    ImSCOwner,
    sc,
    piece,
    column,
    notes,
    timeini,
    timeend,
    onChangeTimeini,
    onChangeTimeend,
    onChangeNotes,
}) {
    const cxt = useContext(AppContext);

    return (
        <Box mt={3}>
            <Paper>
                <Box p={3}>
                    <Grid container spacing={3} justifyContent="center">
                        <SttLabelForm
                            grid
                            md={5}
                            title={cxt.t('Date')}
                            text={moment(piece.ini).format('dddd, D MMMM YYYY')}
                        />

                        <SttLabelForm
                            grid
                            md={7}
                            title={cxt.t('Facility')}
                            text={`${sc.short}, ${column.facility.name}`}
                        />

                        {cxt.user.role == cxt.constants.roleSC && (
                            <SttFormTextarea
                                grid
                                md={12}
                                sm={12}
                                xs={12}
                                caption={cxt.t('Notes')}
                                defVal={notes}
                                onChange={({ value }) => onChangeNotes(value)}
                            />
                        )}

                        {column && (
                            <>
                                <SttFormSelect
                                    grid
                                    md={4}
                                    xs={6}
                                    sm={6}
                                    defVal={timeini}
                                    onChange={({ value }) =>
                                        onChangeTimeini(value)
                                    }
                                    options={column.pieces.map((piece) => ({
                                        caption: moment(piece.ini).format(
                                            'H:mm',
                                        ),
                                        value: piece.ini,
                                    }))}
                                />

                                <SttFormSelect
                                    grid
                                    md={4}
                                    xs={6}
                                    sm={6}
                                    defVal={timeend}
                                    onChange={({ value }) =>
                                        onChangeTimeend(value)
                                    }
                                    options={column.pieces.map((piece) => ({
                                        caption: moment(piece.end).format(
                                            'H:mm',
                                        ),
                                        value: piece.end,
                                    }))}
                                />
                            </>
                        )}
                    </Grid>
                </Box>
            </Paper>
        </Box>
    );
}
