import React, { useState, useEffect, useContext } from 'react';
import {
    Avatar,
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import { AppContext, SttList } from '../all';

/**
 *	Privilege list
 *	============
 *
 *	Props
 *
 *		* array privileges
 *		* string scopr: on selecting privilege dialog only show with this "scope"
 *		* function onAdd(privilege)
 *		* function onDelete(privilege)
 */
export function SttPrivilegePureList({ privileges, scope, onAdd, onDelete }) {
    const cxt = useContext(AppContext);
    const [selectingPrivilegeOpen, setSelectingPrivilegeOpen] = useState(false);
    const [allPrivileges, setAllPrivileges] = useState();

    /**
     * Open adding privilege
     */
    const openAddingPrivilege = () => {
        setSelectingPrivilegeOpen(true);
    };

    /**
     * Load all privileges
     */
    useEffect(() => {
        // Fetch all privileges on the sports center
        if (selectingPrivilegeOpen && !allPrivileges) {
            cxt.api('GET', `/scs/${cxt.sc.id}/privileges`, {
                params: {
                    scope,
                    rows: 200,
                },
                success: (r) => {
                    setAllPrivileges(r.rows);
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectingPrivilegeOpen]);

    /**
     * Select privilege
     */
    const selectPrivilege = (privilege) => {
        setSelectingPrivilegeOpen(false);
        onAdd(privilege);
    };

    // -----| Render |-----
    return (
        <>
            <SttList
                title={cxt.t('Privileges')}
                onAdd={onAdd ? () => openAddingPrivilege() : null}
                onDelete={onDelete}
                data={
                    privileges &&
                    privileges.map((privilege) => ({
                        avatar: privilege.sport ? (
                            <Avatar
                                style={{
                                    width: 30,
                                    height: 30,
                                    borderWidth: 1,
                                    borderStyle: 'solid',
                                    borderColor: '#ddd',
                                    padding: 2,
                                }}
                                src={cxt.getResource(
                                    `/sports/${privilege.sport.id}.png`,
                                )}
                            />
                        ) : null,
                        caption: cxt.t(privilege.name),
                        objToSelect: privilege,
                    }))
                }
            />

            <Dialog
                onClose={() => setSelectingPrivilegeOpen(false)}
                open={selectingPrivilegeOpen}
            >
                <DialogTitle>{cxt.t('Privileges')}</DialogTitle>

                <List>
                    {allPrivileges &&
                        allPrivileges
                            .filter(
                                (e) =>
                                    !privileges
                                        .map((e2) => e2.id)
                                        .includes(e.id),
                            )
                            .map((privilege) => (
                                <ListItem
                                    button
                                    key={privilege.id}
                                    onClick={() => selectPrivilege(privilege)}
                                >
                                    <ListItemText
                                        primary={cxt.t(privilege.name)}
                                    />
                                </ListItem>
                            ))}
                </List>
            </Dialog>
        </>
    );
}
