import React, { useState, useContext } from 'react';
import { AppContext } from '../../../sporttia/all';
import DeviceAuthDialog from '../../../components/devices/DeviceAuthDialog';
import DeviceList from '../../../components/devices/DeviceList';
import deviceConstants from '../../../components/devices/deviceConstants';

/* 'Devices' tab for the Activity page. Uses DeviceList with type=DeviceTypes.ACTIVITIES for rendering.
 *  props:
 *   Object item : the Activity objec*t
 */
export default function ActivityDevices({ item }) {
    const cxt = useContext(AppContext);

    const [viewAuth, setViewAuth] = useState(null); // auth to open in the DeviceAuthDialog
    const [auths, setAuths] = useState([]);

    function loadAuths(params = { rows: 20, page: 1 }) {
        cxt.api('GET', `/activities/${item.id}/devices/auths`, {
            params,
            success: (response) => {
                if (response.count) {
                    setAuths(response);
                }
            },
        });
    }

    return (
        <div>
            <DeviceList
                item={item}
                type={deviceConstants.ACTIVITIES}
                auths={auths}
                loaderFunction={loadAuths}
                onCreate={(device) => setViewAuth(device)}
                onSelect={(device) => setViewAuth(device)}
            />
            {viewAuth && (
                <DeviceAuthDialog
                    open
                    item={viewAuth}
                    onSave={loadAuths}
                    onClose={() => setViewAuth(null)}
                />
            )}
        </div>
    );
}
