import React, { useState, useEffect, useContext } from 'react';
import { Chip } from '@material-ui/core';
import { AppContext } from '../all';

export function SttChipRestrictionType({ type }) {
    const cxt = useContext(AppContext);
    const [restType, setRestType] = useState({});

    /**
     * Type changes
     */
    useEffect(() => {
        if (type)
            setRestType(
                cxt.constants.restrictionTypes.find((e) => e.id == type),
            );
    }, [type]);

    return (
        <Chip
            size="small"
            style={{ backgroundColor: restType.color, color: 'white' }}
            label={restType.short}
        />
    );
}
