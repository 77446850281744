import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { AppContext } from '../../../sporttia/all';
import { getPath } from '../../Pages';
import SttCachedTable from '../../../sporttia/SttCachedTable';
import { USER_AGENDAS } from '../../../lib/queryKeys';
import useUsersService from '../../../services/UsersService';

/**
 * View where the user can consult all their bookings, events, activities...
 * @returns {JSX.Element}
 */
export default function Agenda({}) {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const userService = useUsersService();

    /**
     * Render schedule row.
     * @param agenda Schedule item.
     * @returns {string}
     */
    const renderAgendaRow = (agenda) => {
        let result = '';

        switch (agenda.type) {
            case 'RENTING':
                result = cxt.t('Booking');
                break;
            case 'CLASS':
                result = cxt.t('Class');
                break;
            case 'TOURNAMENT':
                result = cxt.t('EK.TOURNAMENT');
        }

        return result;
    };

    /**
     * Open schedule item.
     * @param agenda Schedule item.
     */
    const openAgenda = (agenda) => {
        switch (agenda.type) {
            case 'RENTING':
                history.push(getPath('booked', { id: agenda.booking.id }));
                break;
        }
    };

    return (
        <Container maxWidth="md">
            <Paper>
                <SttCachedTable
                    queryKey={USER_AGENDAS}
                    queryFn={(params) =>
                        userService.getUsersAgendas(cxt.user.id, params)
                    }
                    onClickRow={openAgenda}
                    columns={[
                        {
                            title: cxt.t('Type'),
                            value: (row) => renderAgendaRow(row),
                        },
                        {
                            title: cxt.t('Date'),
                            type: 'dmy',
                            value: (row) => row.ini,
                        },
                        {
                            title: cxt.t('Time'),
                            type: 'hm',
                            value: (row) => row.ini,
                        },
                        {
                            title: cxt.t('Facility'),
                            value: (row) => {
                                if (row.activity) {
                                    return (
                                        (row.activity.sc
                                            ? `${
                                                  row.booking.sc.short ||
                                                  row.activity.sc.name
                                              } - `
                                            : '') + row.activity.name
                                    );
                                }
                                if (row.booking) {
                                    return (
                                        (row.booking.facility &&
                                        row.booking.facility.sc
                                            ? `${
                                                  row.booking.facility.sc
                                                      .short ||
                                                  row.booking.facility.sc.name
                                              } - `
                                            : '') +
                                        (row.booking.facility &&
                                            row.booking.facility.name)
                                    );
                                }
                                if (row.event) {
                                    return row.event.name;
                                }
                            },
                        },
                    ]}
                />
            </Paper>
        </Container>
    );
}
