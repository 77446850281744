import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import { AppContext } from '../../sporttia/AppContext';
import { formatPrice } from '../../lib/utils';
import Cashdro, { OP_ERROR } from '../../lib/Cashdro';
import constants from '../../config/constants';

export default function CashdroControlDialog({
    open,
    amount,
    onClose,
    onSuccess,
}) {
    const cxt = useContext(AppContext);

    const [operationId, setOperationId] = useState(null);
    const [operationOngoing, setOperationOngoing] = useState(false);
    const [noChange, setnoChange] = useState(false);
    const [error, setError] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [cashdroService, setCashdroService] = useState(null);

    useEffect(() => {
        if (open) {
            try {
                const service = new Cashdro();
                setCashdroService(service);
            } catch (err) {
                setError(err?.message || err);
                setDisabled(true);
            }
        }
        // cleanup
        return () => {
            setCashdroService(null);
        };
    }, [open]);

    const onCancel = async () => {
        if (cashdroService) {
            const result = await cashdroService
                .cancelOperation(operationId)
                .catch((err) => {
                    cxt.showMessage('E', err?.error?.msg || err);
                });

            if (result && result.code === 1) {
                cxt.showMessage('E', 'Cancelado!');
            }
        }
        onClose();
    };

    const onAccept = async () => {
        setOperationOngoing(true);

        let result = await cashdroService
            .startOperation(
                constants.cashdro.op.SALE,
                {
                    amount: amount * 100,
                } /* El cashdro representa las perras en plan 550 = 5,50 € */,
            )
            .catch((err) => {
                setOperationOngoing(false);
                setError(err?.error?.msg || err);
            });

        if (result && result.code === 1) {
            const opId = result.data;

            if (opId) {
                setOperationId(opId);

                result = await cashdroService
                    .confirmOperation(opId)
                    .catch((err) => {
                        setError(err?.error?.msg || err);
                        setOperationOngoing(false);
                    });

                if (result && result.code === 1) {
                    /* trozo cortado */

                    await cashdroService.whileQueryingOperation(
                        {
                            operationId: opId,
                        },
                        async ({ operation, devices }) => {
                            console.log(`Operación ${opId} finalizada`);

                            console.log(operation);

                            // Evaluamos si se introdujo el dinero y cuadra el dinero introducido + el devuelto
                            // eslint-disable-next-line no-shadow
                            const { success, error } =
                                Cashdro.saleOperationSuccessful(operation);

                            // Ocurrió algún error conocido
                            if (!success) {
                                switch (error) {
                                    case OP_ERROR.CANCELLED:
                                        console.log('OP_ERROR.CANCELLED');
                                        await onCancel();
                                        break;
                                    case OP_ERROR.HARDWARE:
                                        console.log('OP_ERROR.HARDWARE');
                                        setOperationOngoing(false);
                                        break;
                                    case OP_ERROR.NO_CHANGE:
                                        console.log('OP_ERROR.NO_CHANGE');
                                        setnoChange(true);

                                        result = await cashdroService
                                            .startOperation(
                                                constants.cashdro.op.REFUND,
                                            )
                                            .catch((err) => {
                                                setError(
                                                    err?.error?.msg || err,
                                                );
                                                setOperationOngoing(false);
                                                console.log(
                                                    'Error al iniciar op de reembolso',
                                                );
                                            });

                                        if (result && result.code === 1) {
                                            const refundOpId = result.data;
                                            result = await cashdroService
                                                .confirmOperation(refundOpId)
                                                .catch((err) => {
                                                    setError(
                                                        err?.error?.msg || err,
                                                    );
                                                    setOperationOngoing(false);
                                                    console.log(
                                                        'Error al confirmar op. de reembolso',
                                                    );
                                                });

                                            if (result && result.code === 1) {
                                                await cashdroService.whileQueryingOperation(
                                                    {
                                                        operationId: refundOpId,
                                                        updateOnlyOnce: true,
                                                    },
                                                    async () => {
                                                        console.log(
                                                            'Op de reembolso "completada"',
                                                        );

                                                        await cashdroService
                                                            .importOperation(
                                                                refundOpId,
                                                            )
                                                            .catch((err) => {
                                                                cxt.showMessage(
                                                                    'E',
                                                                    err?.error
                                                                        ?.msg ||
                                                                        err,
                                                                );
                                                            });

                                                        setnoChange(false);

                                                        // Cancelamos la op. inicial
                                                        await onCancel();
                                                    },
                                                    (err) => {
                                                        console.log(
                                                            'Err al consultar reembolso: ',
                                                            err,
                                                        );
                                                    },
                                                    async () => {
                                                        const availablePieces =
                                                            {
                                                                monedas:
                                                                    devices?.find(
                                                                        (
                                                                            device,
                                                                        ) =>
                                                                            device.type ===
                                                                            '2',
                                                                    )?.pieces ||
                                                                    [],
                                                                billetes:
                                                                    devices?.find(
                                                                        (
                                                                            device,
                                                                        ) =>
                                                                            device.type ===
                                                                            '3',
                                                                    )?.pieces ||
                                                                    [],
                                                            };

                                                        // Cargamos la disponibilidad de piezas (monedas y billetes) devuelta por la operación de pago
                                                        cashdroService.setAvailablePieces(
                                                            availablePieces,
                                                        );

                                                        // Calculamos las piezas necesarias para efectuar la devolución
                                                        const refundPieces =
                                                            cashdroService.calculateNecessaryPieces(
                                                                operation.totalin,
                                                            );

                                                        // Comprobamos si las piezas generadas son válidas (por si acaso).
                                                        if (
                                                            Cashdro.validRefundPieces(
                                                                refundPieces,
                                                                operation.totalin,
                                                            )
                                                        ) {
                                                            // Finalizar operación de devolución
                                                            await cashdroService
                                                                .confirmRefund(
                                                                    refundOpId,
                                                                    refundPieces,
                                                                )
                                                                .catch(
                                                                    (err) => {
                                                                        console.log(
                                                                            err,
                                                                        );
                                                                        cxt.showMessage(
                                                                            'E',
                                                                            err
                                                                                ?.error
                                                                                ?.msg ||
                                                                                err,
                                                                        );
                                                                    },
                                                                );
                                                        } else {
                                                            // Finalizar op. pero con cancelación
                                                            await cashdroService.finishOperation(
                                                                refundOpId,
                                                                {},
                                                                constants
                                                                    .cashdro.op
                                                                    .CANCEL_WITHDRAWAL,
                                                            );

                                                            setError(
                                                                'Ocurrió un error con la devolución. Verifique el dispositivo CashDro',
                                                            );
                                                            setnoChange(false);
                                                            await onCancel();
                                                        }
                                                    },
                                                );
                                            }
                                        }
                                        break;
                                    case OP_ERROR.UNAVAILABLE:
                                    default:
                                        console.log('OP_ERROR.UNAVAILABLE');
                                        setError(OP_ERROR.UNAVAILABLE);
                                        setOperationOngoing(false);
                                        break;
                                }

                                // op iniciada

                                // op confirmada
                            } else {
                                await cashdroService
                                    .importOperation(opId)
                                    .catch((err) => {
                                        cxt.showMessage(
                                            'E',
                                            err?.error?.msg || err,
                                        );
                                    });

                                // Éxito en la op. de venta
                                onSuccess();
                            }

                            setOperationOngoing(false);
                        },
                        (err) => {
                            console.log(err);
                            setError(err);
                        },
                    );
                } else {
                    cxt.showMessage('E', `Error: ${result.data}`);
                }
            } else {
                cxt.showMessage(
                    'E',
                    'Error: no se pudo recuperar "operationID"',
                );
            }
        }
    };

    /**
     * Decidir qué mensaje pintamos en el diálogo
     * @returns {JSX.Element}
     */
    const renderMessage = () => {
        let result = null;
        if (noChange) {
            result = (
                <Box style={{ color: '#e51919' }}>
                    El dispositivo no tiene cambio; reembolsando...
                </Box>
            );
        } else if (operationOngoing) {
            result = <Box>Esperando al dispositivo...</Box>;
        } else if (error) {
            result = <Box style={{ color: '#e51919' }}>{error}</Box>;
        } else {
            result = (
                <Box>
                    {`Va a enviar al dispositivo una orden de cobro por valor de ${formatPrice(
                        amount,
                        '€',
                    )}`}
                </Box>
            );
        }

        return result;
    };

    /**
     * Decidir qué botones pintamos en el diálogo
     * @returns {*[]}
     */
    const renderActions = () => {
        const actions = [];

        if (!disabled) {
            if (operationOngoing || error) {
                actions.push(
                    <Button
                        key="abort"
                        onClick={onCancel}
                        color="primary"
                        variant="contained"
                        className="button-delete"
                    >
                        Abortar operación
                    </Button>,
                );
            } else if (!noChange) {
                actions.push(
                    <Button
                        onClick={onAccept}
                        key="accept"
                        variant="contained"
                        color="primary"
                    >
                        {cxt.t('Accept')}
                    </Button>,
                );
            }
        }

        actions.push(
            <Button
                onClick={onCancel}
                key="cancel"
                variant="contained"
                color="default"
            >
                {cxt.t('Cancel')}
            </Button>,
        );

        return actions;
    };

    const title = 'Pago mediante CashDro';

    return (
        <Dialog open={open} onClose={onClose}>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>
                {renderMessage()}
                {(operationOngoing || noChange) && (
                    <Box display="flex" justifyContent="center" mt={2} mb={2}>
                        <CircularProgress />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>{renderActions()}</DialogActions>
        </Dialog>
    );
}
