import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import {
    SttDropdownButton,
    SttFormText,
    SttButtonCancel,
    SttButtonDelete,
    SttButtonSave,
    SttFacilityList,
    SttFullDialog,
} from '../../../sporttia/all';
import { AppContext } from '../../../sporttia/AppContext';
import { getPath } from '../../Pages';
import { useCrud, usePageHeader, useTranslations } from '../../../lib/hooks';

export default function FacilitySet({ facilitySetId, onClose, title }) {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const [setPageTitle] = usePageHeader();
    const [facilities, setFacilities] = useState([]);
    const [facilitySet, setFacilitySet, setProperty, Get, Post, Put, Delete] =
        useCrud();
    useEffect(() => {
        // Creating new facility set
        if (facilitySetId === 'create') {
            setFacilitySet({
                name: null,
                position: null,
                status: 'ACTIVE',
                facilities: {
                    count: 0,
                    rows: [],
                },
            });

            // Load faclility set
        } else {
            loadFacilitySet();
        }

        // Load all facilities to pick up from them
        loadFacilities();
    }, []);

    /**
     * Load facility group
     */
    function loadFacilitySet(params = {}) {
        Get(`/facilitysets/${facilitySetId}`, (params = { trash: true })).then(
            (response) => {
                if (response.facilityset) {
                    setPageTitle(response.facilityset.name);

                    setFacilitySet(response.facilityset);
                }
            },
        );
    }

    /**
     * Load all facilitites
     */
    function loadFacilities(params = { page: 1, rows: 1000 }) {
        Get(`/scs/${cxt.sc.id}/facilities`, params).then((response) => {
            if (response.rows) {
                setFacilities(response.rows);
            }
        });
    }

    /**
     * Create facility to group
     */
    function addFacility(facility) {
        Post(`/facilitysets/${facilitySet.id}/facilities`, {
            idFacility: facility.id,
        }).then((response) => {
            setProperty({
                name: 'facilities',
                value: {
                    count: facilitySet.facilities.count + 1,
                    rows: [...facilitySet.facilities.rows, facility],
                },
            });
        });
    }

    /**
     * Delete facility from group
     */
    function removeFacility(facility) {
        Delete(
            `/facilitysets/${facilitySet.id}/facilities/${facility.id}`,
        ).then(() => {
            setProperty({
                name: 'facilities',
                value: {
                    count: facilitySet.facilities.count - 1,
                    rows: facilitySet.facilities.rows.filter(
                        (fct) => fct.id !== facility.id,
                    ),
                },
            });
        });
    }

    // Dependiendo de si estamos creando nueva o modificando, llamamos a distintas funciones
    function save() {
        const { name, position, status } = facilitySet;

        // Update facility set
        if (facilitySet.id) {
            Put(`/facilitysets/${facilitySet.id}`, {
                name,
                position,
                status,
            }).then(() => {
                cxt.showMessage('S', cxt.t('Saved'));
            });

            // Creating new facility set
        } else {
            Post(`/scs/${cxt.sc.id}/facilitysets`, {
                name,
                position,
                status,
            }).then((response) => {
                if (response.facilityset) {
                    setFacilitySet(response.facilityset);
                    setPageTitle(name);
                    // history.replace(getPath('facilitySets', response.facilityset.id));
                }
            });
        }
    }

    // Borra grupo, tras pedir confirmación, y vuelve al listado
    function deleteFacilitySet() {
        Delete(`/facilitysets/${facilitySet.id}`).then(() => {
            onClose();
        });
    }

    /*
	const availableFacilities =
		facilities
		.filter(facility => {
			return facilitySet.facilities.rows.find(f => (f.id == facility.id && !f.deleted)) === undefined
		});
		*/

    return (
        <>
            {facilitySet && (
                <SttFullDialog open onClose={onClose} title={title}>
                    <Paper padding={3}>
                        <Box padding={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={3}>
                                        <SttFormText
                                            grid
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            name="name"
                                            caption={cxt.t(
                                                'NameAndDescription',
                                            )}
                                            defVal={facilitySet.name}
                                            onChange={setProperty}
                                        />

                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                            >
                                                <InputLabel>
                                                    {cxt.t('Status')}
                                                </InputLabel>
                                                <Select
                                                    value={facilitySet.status}
                                                    onChange={(e) =>
                                                        setProperty({
                                                            name: 'status',
                                                            value: e.target
                                                                .value,
                                                        })
                                                    }
                                                    labelWidth={60}
                                                >
                                                    <MenuItem value="ACTIVE">
                                                        {cxt.t('FS.ACTIVE')}
                                                    </MenuItem>
                                                    <MenuItem value="INACTIVE">
                                                        {cxt.t('FS.INACTIVE')}
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <SttFormText
                                            grid
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            name="position"
                                            caption={cxt.t('Position')}
                                            defVal={facilitySet.position}
                                            onChange={setProperty}
                                        />
                                    </Grid>
                                </Grid>

                                {facilitySet.id && (
                                    <Grid item xs={12} md={6}>
                                        <SttFacilityList
                                            facilities={
                                                facilitySet.facilities &&
                                                facilitySet.facilities.rows.filter(
                                                    (row) => !row.deleted,
                                                )
                                            }
                                            onAdd={addFacility}
                                            onDelete={removeFacility}
                                        />
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={4} md={3} xl={2}>
                                            <SttButtonSave
                                                fullWidth
                                                onClick={save}
                                            >
                                                {cxt.t('Save')}
                                            </SttButtonSave>
                                        </Grid>

                                        <Grid item xs={12} sm={4} md={3} xl={2}>
                                            {facilitySet.id ? (
                                                <SttButtonDelete
                                                    fullWidth
                                                    onClick={deleteFacilitySet}
                                                />
                                            ) : (
                                                <SttButtonCancel
                                                    fullWidth
                                                    onClick={history.goBack}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </SttFullDialog>
            )}
        </>
    );
}
