import React, { useContext, useEffect, useState } from 'react';
import {
    Grid,
    Paper,
    Box,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    Typography,
} from '@material-ui/core';
import {
    AppContext,
    SttSportSelector,
    SttSelectInterval,
    SttGoogleMap,
    SttFormText,
    SttFormTextarea,
    SttFormCheck,
    SttButtonSave,
    SttButtonDelete,
    SttButtonCancel,
    SttButtonRecover,
    SttFormTime,
    SttFullDialog,
} from '../../../sporttia/all';
import { SttTerrainList } from '../../../sporttia/lists/SttTerrainList';
import { SttSelectNumbersInterval } from '../../../sporttia/selectors/SttSelectNumbersInterval';
import useFacilitiesService from '../../../services/FacilitiesService';
import Sport from '../../../types/models/Sport';
import Terrain from '../../../types/models/Terrain';
import { FacilityResponse } from '../../../types/api/paths/Facility';
import Facility from '../../../types/models/Facility';
import { getErrorMessage } from '../../../lib/utils';
import constants from '../../../config/constants';
import translations from '../../../translations';
import Error from '../../../types/models/Error';

type FacilityParam = {
    id: number;
    trash: boolean;
};

type FacilityProps = {
    item: FacilityParam;
    onClose: () => void;
};

type GmapsParams = {
    lat: number;
    lng: number;
};

export default function FacilityView({ item, onClose }: FacilityProps) {
    const cxt = useContext(AppContext)!;
    const [facility, setFacility] = useState<Facility>();
    const facilitiesService = useFacilitiesService();

    /**
     * Load facility
     */
    function loadFacility(params = { page: 1, numRows: 20 }) {
        facilitiesService
            .get<FacilityResponse>(item.id, { ...params, trash: item.trash })
            .then((response) => {
                if (response.facility) {
                    const loaded = {
                        ...response.facility,
                        mandatoryEmail:
                            response.facility.mandatoryEmail || false,
                        mandatoryMobile:
                            response.facility.mandatoryMobile || false,
                    };
                    setFacility(loaded);
                }
            })
            .catch((error) => {
                cxt.showMessage('E', getErrorMessage(error));
            });
    }

    /**
     * Init
     */
    useEffect(() => {
        // Creating new facility
        if (typeof item.id === 'number') {
            if (item.id === 0) {
                setFacility({
                    defaultBooking: 0,
                    id: 0,
                    maxBooking: 0,
                    timeiniLightsOn: '',
                    name: '',
                    description: '',
                    address: '',
                    status: '',
                    position: null,
                    mobile: '',
                    cancelation: null,
                    bookingInfo: '',
                    mandatoryMobile: false,
                    mandatoryEmail: false,
                    sport: null,
                    terrains: [],
                    sets: [],
                    maxOccupants: 0,
                    generateOccupantUnlocker: 'NULL',
                });

                cxt.setHeader({
                    title: cxt.t('NewFacility'),
                });

                // Updating facility
            } else {
                loadFacility();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    // Actualizar [x,y] de un terreno devolviendo una promesa
    function updateTerrainLatLng(terrain: Terrain, lat: number, lng: number) {
        return facilitiesService.updateTerrain(terrain.id, lat, lng);
    }

    // Si cambian lat,lng (evento lanzado al buscar en el mapa o mover el marcador), tenemos que actualizar los [x,y] de los terrenos.
    function onChangeLatLng({ lat, lng }: GmapsParams) {
        const terrains = [...facility!.terrains];

        let terrain;
        // eslint-disable-next-line no-cond-assign
        while ((terrain = terrains.shift())) {
            updateTerrainLatLng(terrain, lat, lng)
                .then((modifiedTerrain) => {
                    const modified = facility!;
                    modified.terrains =
                        facility?.terrains.map((trr) =>
                            trr.id === modifiedTerrain.id
                                ? modifiedTerrain
                                : trr,
                        ) || [];
                    setFacility(modified);
                })
                .catch(() => {});
        }
    }

    /**
     * Update facility
     * Dependiendo de si estamos creando nueva o modificando, llamamos a distintas funciones
     */
    function save() {
        if (!facility) {
            return;
        }

        const params = {
            name: facility.name,
            description: facility.description,
            address: facility.address,
            status: facility.status,
            position: facility.position,
            mobile: facility.mobile,
            cancelation: facility.cancelation ?? 0,
            bookingInfo: facility.bookingInfo,
            mandatoryMobile: facility.mandatoryMobile,
            mandatoryEmail: facility.mandatoryEmail,
            defaultBooking: facility.defaultBooking,
            maxBooking: facility.maxBooking,
            timeiniLightsOn: facility.timeiniLightsOn,
            maxOccupants: facility.maxOccupants,
            idSport: facility?.sport?.id || null,
            generateOccupantUnlocker:
                facility?.generateOccupantUnlocker ===
                constants.unlocker.types.noUnlocker
                    ? null
                    : facility?.generateOccupantUnlocker,
        };

        if (facility.id) {
            facilitiesService
                .update<FacilityResponse>(facility.id, params)
                .then(() => {
                    cxt.showMessage('S', cxt.t('Modified'));
                })
                .catch((errorWrapper: Error) => {
                    cxt.showMessage('E', cxt.t(errorWrapper.error.msg));
                });
        } else {
            facilitiesService
                .create(cxt?.sc?.id, params)
                .then((response) => {
                    if (response.facility) {
                        setFacility(response.facility);
                        cxt.showMessage('S', cxt.t('Created'));
                    }
                })
                .catch((errorWrapper: Error) => {
                    cxt.showMessage('E', cxt.t(errorWrapper.error.msg));
                });
        }
    }

    /**
     * Delete facility
     */
    function deleteFacility() {
        if (facility && facility.id) {
            facilitiesService
                .remove(facility.id)
                .then(onClose)
                .catch(() => {});
        }
    }

    /**
     * Recover facility
     */
    function recoverFacility() {
        if (facility && facility.id) {
            facilitiesService
                .recover<FacilityResponse>(facility.id)
                .then(() => {
                    loadFacility();
                });
        }
    }

    // Añadir un nuevo terreno mediante API
    function addNewTerrain() {
        if (facility && facility.id) {
            facilitiesService.createTerrain(facility?.id).then((response) => {
                if (response.terrain) {
                    const newTerrain = {
                        id: response.terrain.id,
                        name: response.terrain.name,
                    };
                    setFacility({
                        ...facility,
                        terrains: facility.terrains
                            ? facility.terrains.concat([newTerrain])
                            : [newTerrain],
                    });
                }
            });
        }
    }

    // Añadir terreno existente mediante API
    function addExistingTerrain(id: number) {
        if (facility && facility.id) {
            facilitiesService
                .addExistingTerrain(facility?.id, id)
                .then((response) => {
                    if (response.terrain) {
                        const newTerrain = {
                            id: response.terrain.id,
                            name: response.terrain.name,
                        };
                        setFacility({
                            ...facility,
                            terrains: facility.terrains
                                ? facility.terrains.concat([newTerrain])
                                : [newTerrain],
                        });
                    }
                });
        }
    }

    // Eliminar terreno mediante API
    function removeTerrain(terrain: Terrain) {
        if (facility && facility.id) {
            facilitiesService
                .removeTerrain(facility?.id, terrain.id)
                .then(() => {
                    setFacility({
                        ...facility,
                        terrains: facility.terrains.filter(
                            (trn) => trn.id !== terrain.id,
                        ),
                    });
                });
        }
    }

    let lat;
    let lng;

    if (facility && facility.terrains.length > 0) {
        lat = facility?.terrains[0]?.x || cxt?.sc?.mapX;
        lng = facility?.terrains[0]?.y || cxt?.sc?.mapY;
    } else {
        lat = cxt?.sc?.mapX;
        lng = cxt?.sc?.mapY;
    }

    if (!facility) {
        return null;
    }

    return (
        <SttFullDialog
            open={item.id !== null}
            onClose={onClose}
            title={item.id === 0 ? cxt.t('CreateFacility') : facility.name}
        >
            <Paper>
                <Box padding={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <SttFormText
                                        name="name"
                                        caption={cxt.t('Name')}
                                        defVal={facility.name}
                                        onChange={({ value }) => {
                                            setFacility({
                                                ...facility,
                                                name: value,
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SttFormTextarea
                                        name="description"
                                        caption={cxt.t('Description')}
                                        defVal={facility.description}
                                        onChange={({ value }) => {
                                            setFacility({
                                                ...facility,
                                                description: value,
                                            });
                                        }}
                                    />
                                </Grid>

                                <SttFormText
                                    grid
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    name="address"
                                    caption={cxt.t('Address')}
                                    defVal={facility.address}
                                    onChange={({ value }) => {
                                        setFacility({
                                            ...facility,
                                            address: value,
                                        });
                                    }}
                                />

                                <Grid item xs={12} sm={6} lg={4}>
                                    <SttSportSelector
                                        fullWidth
                                        defVal={
                                            facility?.sport?.id
                                                ? cxt.t(
                                                      `sport.${facility.sport.id}`,
                                                  )
                                                : ''
                                        }
                                        onChange={(sport: Sport) =>
                                            setFacility({
                                                ...facility,
                                                sport,
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <FormControl
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    >
                                        <InputLabel>
                                            {cxt.t('Status')}
                                        </InputLabel>
                                        <Select
                                            value={facility.status}
                                            onChange={(e) =>
                                                setFacility({
                                                    ...facility,
                                                    status: String(
                                                        e.target.value,
                                                    ),
                                                })
                                            }
                                            labelWidth={60}
                                        >
                                            <MenuItem value="ACTIVE">
                                                {cxt.t('FS.ACTIVE')}
                                            </MenuItem>
                                            <MenuItem value="ONLY_SC">
                                                {cxt.t(
                                                    'const.FS.ACTIVE_ONLY_SC',
                                                )}
                                            </MenuItem>
                                            <MenuItem value="INACTIVE">
                                                {cxt.t('FS.INACTIVE')}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <FormControl
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    >
                                        <InputLabel>
                                            {cxt.t(
                                                translations.generic
                                                    .generateUnlockers,
                                            )}
                                        </InputLabel>
                                        <Select
                                            value={
                                                facility.generateOccupantUnlocker ||
                                                constants.unlocker.types
                                                    .noUnlocker
                                            }
                                            onChange={(e) => {
                                                const value = String(
                                                    e.target.value,
                                                );
                                                setFacility({
                                                    ...facility,
                                                    generateOccupantUnlocker:
                                                        value ===
                                                        cxt.t(
                                                            translations.generic
                                                                .noUnlockers,
                                                        )
                                                            ? null
                                                            : value,
                                                });
                                            }}
                                            labelWidth={160}
                                        >
                                            <MenuItem
                                                value={
                                                    constants.unlocker.types
                                                        .noUnlocker
                                                }
                                            >
                                                {cxt.t(
                                                    translations.generic
                                                        .noUnlockers,
                                                )}
                                            </MenuItem>
                                            <MenuItem
                                                value={
                                                    constants.unlocker.types.QR
                                                }
                                            >
                                                {translations.unlockers.QR}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <SttFormText
                                        name="position"
                                        caption={cxt.t('Position')}
                                        defVal={
                                            facility?.position?.toString() || ''
                                        }
                                        onChange={({ value }) => {
                                            setFacility({
                                                ...facility,
                                                position: parseInt(value, 10),
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <SttFormText
                                        name="mobile"
                                        caption={cxt.t('Mobile')}
                                        defVal={facility.mobile}
                                        onChange={({ value }) => {
                                            setFacility({
                                                ...facility,
                                                mobile: value,
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <SttFormText
                                        name="cancelation"
                                        caption={`${cxt.t(
                                            'Cancelation',
                                        )} (${cxt.t('hours')})`}
                                        defVal={
                                            facility?.cancelation?.toString() ||
                                            ''
                                        }
                                        onChange={({ value }) => {
                                            setFacility({
                                                ...facility,
                                                cancelation: Number.isNaN(
                                                    parseInt(value),
                                                )
                                                    ? null
                                                    : parseInt(value),
                                            });
                                        }}
                                    />
                                </Grid>

                                {/* --- Default booking period --- */}
                                <Grid item xs={12} sm={6} lg={4}>
                                    <SttSelectInterval
                                        caption={cxt.t('DefaultBookingPeriod')}
                                        defVal={facility.defaultBooking}
                                        labelWidth={120}
                                        min={15}
                                        max={600}
                                        interval={15}
                                        onChange={(value: number) =>
                                            setFacility({
                                                ...facility,
                                                defaultBooking: value,
                                            })
                                        }
                                    />
                                </Grid>

                                {/* --- Max booking period --- */}
                                <Grid item xs={12} sm={4} lg={4}>
                                    <SttSelectInterval
                                        caption={cxt.t('MaxBookingPeriod')}
                                        defVal={facility.maxBooking}
                                        labelWidth={128}
                                        min={15}
                                        max={600}
                                        interval={15}
                                        onChange={(value: number) => {
                                            setFacility({
                                                ...facility,
                                                maxBooking: value,
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} lg={4}>
                                    {/* @ts-expect-error pasar SttFormTime */}
                                    <SttFormTime
                                        interval={5}
                                        caption={cxt.t('TimeIniLightsOn')}
                                        name="timeiniLightsOn"
                                        max="24:00:00"
                                        min="00:00:00"
                                        defVal={facility.timeiniLightsOn}
                                        onChange={({
                                            value,
                                        }: {
                                            value: string;
                                        }) => {
                                            setFacility({
                                                ...facility,
                                                timeiniLightsOn: value,
                                            });
                                        }}
                                        disableClearable={false}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} lg={4}>
                                    <SttSelectNumbersInterval
                                        caption={cxt.t('occupants.max')}
                                        defVal={facility.maxOccupants}
                                        labelWidth={150}
                                        min={0}
                                        max={50}
                                        interval={1}
                                        onChange={(value: number) => {
                                            setFacility({
                                                ...facility,
                                                maxOccupants: value,
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SttFormTextarea
                                        name="bookingInfo"
                                        caption={cxt.t(
                                            'page.sc.facilites.infoRent',
                                        )}
                                        defVal={facility.bookingInfo}
                                        onChange={({ value }) => {
                                            setFacility({
                                                ...facility,
                                                bookingInfo: value,
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div>
                                        <Typography variant="body1">
                                            {cxt.t('MoreOptions')}
                                        </Typography>
                                    </div>
                                    <div>
                                        <SttFormCheck
                                            color="primary"
                                            name="mandatoryMobile"
                                            caption={cxt.t(
                                                'MobileMandatoryToBook',
                                            )}
                                            checked={facility.mandatoryMobile}
                                            onChange={({ value }) => {
                                                setFacility({
                                                    ...facility,
                                                    mandatoryMobile: value,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <SttFormCheck
                                            color="primary"
                                            name="mandatoryEmail"
                                            caption={cxt.t(
                                                'EmailMandatoryToBook',
                                            )}
                                            checked={facility.mandatoryEmail}
                                            onChange={({ value }) => {
                                                setFacility({
                                                    ...facility,
                                                    mandatoryEmail: value,
                                                });
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        {facility.id !== null && (
                            <Grid item xs={12} lg={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} style={{ height: 470 }}>
                                        {lat && lng && (
                                            <SttGoogleMap
                                                width="100%"
                                                height={400}
                                                latLng={{ lat, lng }}
                                                searchEnabled
                                                searchValue=""
                                                searchPosition="top"
                                                onChange={onChangeLatLng}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div>
                                            <SttTerrainList
                                                terrains={
                                                    facility.terrains || []
                                                }
                                                onCreate={() => addNewTerrain()}
                                                onAdd={(terrain: Terrain) => {
                                                    addExistingTerrain(
                                                        terrain.id,
                                                    );
                                                }}
                                                onDelete={(terrain: Terrain) =>
                                                    removeTerrain(terrain)
                                                }
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                {!facility.trash && (
                                    <Grid item xs={12} sm={6} md={3}>
                                        <SttButtonSave fullWidth onClick={save}>
                                            {cxt.t('Save')}
                                        </SttButtonSave>
                                    </Grid>
                                )}

                                {typeof facility.id === 'number' &&
                                    facility.id > 0 &&
                                    !facility.trash && (
                                        <Grid item xs={12} sm={6} md={3}>
                                            <SttButtonDelete
                                                fullWidth
                                                onClick={deleteFacility}
                                            />
                                        </Grid>
                                    )}

                                {facility.trash && (
                                    <Grid item xs={12} sm={6} md={3}>
                                        <SttButtonRecover
                                            fullWidth
                                            onClick={recoverFacility}
                                        />
                                    </Grid>
                                )}

                                {facility.id === null && (
                                    <Grid item xs={12} sm={6} md={3}>
                                        <SttButtonCancel
                                            fullWidth
                                            onClick={() => onClose()}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </SttFullDialog>
    );
}
