import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { usePageHeader } from '../../lib/hooks';
import { getPath } from '../Pages';
import { AppContext } from '../../sporttia/AppContext';

export default function ScHome() {
    const cxt = useContext(AppContext);

    usePageHeader('Home');

    const menus = cxt.menus.sc
        .filter(
            (menu) =>
                (!menu.privilege || cxt.privileges?.includes(menu.privilege)) &&
                (!menu.module || cxt.sc.modules?.includes(menu.module)) &&
                menu.submenus,
        )
        .map((menu) => ({
            ...menu,
            submenus: menu.submenus.filter(
                (submenu) =>
                    (!submenu.privilege ||
                        cxt.privileges?.includes(submenu.privilege)) &&
                    (!submenu.module ||
                        cxt.sc.modules?.includes(submenu.module)),
            ),
        }));

    return (
        <Grid
            container
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            }}
        >
            {menus.map((item) => (
                <Grid
                    item
                    xs={12}
                    md={3}
                    key={item.id}
                    style={{
                        margin: 25,
                    }}
                >
                    <Card
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%',
                        }}
                    >
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                {cxt.t(item.caption)}
                            </Typography>
                            <ul>
                                {item.submenus.map((subitem) => (
                                    <li
                                        style={{ fontSize: 18 }}
                                        key={subitem.path}
                                    >
                                        <Link to={getPath(subitem.path)}>
                                            {cxt.t(subitem.caption)}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}
