import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { AppContext } from '../../../sporttia/AppContext';
import { SttForm } from '../../../sporttia/SttForm';
import { SttSelectMship } from '../../../sporttia/selectors/SttSelectMship';

export default function ConfigurationBilling({}) {
    const cxt = useContext(AppContext);
    const [customer, setCustomer] = useState({});

    useEffect(() => {
        loadCustomer();
    }, []);

    /**
     * Load customer
     */
    const loadCustomer = () => {
        cxt.api('GET', `/customers/${cxt.sc.customer.id}`, {
            success: (r) => {
                delete r.customer.id;
                delete r.customer.created;
                delete r.customer.trash;
                delete r.customer.subscription;
                setCustomer(r.customer);
            },
        });
    };

    const saveCustomer = () => {
        delete customer.scs;
        delete customer.id;
        delete customer.created;
        delete customer.trash;
        cxt.api('PUT', `/customers/${cxt.sc.customer.id}`, {
            params: customer,
            success: (r) => {
                setCustomer(r.customer);
                cxt.showMessage('S', cxt.t('Saved'));
            },
        });
    };

    return (
        <Box>
            <SttForm
                onChangeForm={({ name, value }) =>
                    setCustomer({ ...customer, [name]: value })
                }
                elements={[
                    {
                        md: 6,
                        sm: 6,
                        xs: 6,
                        caption: cxt.t('NumInvoice'),
                        name: 'numInvoiceFormat',
                        value: customer.numInvoiceFormat,
                    },
                    {
                        md: 6,
                        sm: 6,
                        xs: 6,
                        caption: 'IBAN',
                        name: 'iban',
                        value: customer.iban,
                    },
                    {
                        md: 6,
                        sm: 6,
                        xs: 6,
                        caption: 'BIC',
                        name: 'bic',
                        value: customer.bic,
                    },
                    {
                        md: 6,
                        sm: 6,
                        xs: 6,
                        caption: cxt.t('CreditorID'),
                        name: 'idCreditor',
                        value: customer.idCreditor,
                    },
                ]}
                buttons={[
                    {
                        type: 'save',
                        onClick: () => saveCustomer(),
                    },
                ]}
            />
        </Box>
    );
}
