import React, { useState, useEffect, useContext } from 'react';
import { AppContext, SttFormSelect } from '../all';

export function SttSelectUnlockerType({ name, defVal, onChange, ...rest }) {
    const cxt = useContext(AppContext);

    return (
        <SttFormSelect
            caption={cxt.t('Type')}
            name={name}
            defVal={defVal}
            {...rest}
            options={[
                /* {
					caption: cxt.t('UT.TICKET_ONE_USE'),
					value: 'TICKET_ONE_USE,',
				},
				{
					caption: cxt.t('UT.TICKET_CARD'),
					value: 'TICKET_CARD',
				}, */
                {
                    caption: cxt.t('UT.CARD'),
                    value: 'CARD',
                },
                /* {
					caption: cxt.t('UT.FINGERPRINT'),
					value: 'FINGERPRINT',
				}, */
                {
                    caption: cxt.t('UT.PIN'),
                    value: 'PIN',
                },
                {
                    caption: cxt.t('UT.QR'),
                    value: 'QR',
                },
            ]}
            onChange={onChange}
            mandatory
        />
    );
}

/*
define("_UT_TICKET_ONE_USE", 'TICKET_ONE_USE');
define("_UT_TICKET_CARD", 'TICKET_CARD');
define("_UT_CARD", 'CARD');
define("_UT_FINGERPRINT", 'FINGERPRINT');
define("_UT_PIN", 'PIN');
* */

/*
 * Props
 *
 * 	* bool grid - wrap the element into a <Grid>
 * 	* Array options - options as an array of {caption, value}
 * 	* <any> defVal - the currently selected value
 * 	* boolean cleanable: if it's true, a clean button (X) is added to reset the selection.
 * 	* string name - the field name
 * 	* string caption - the field caption (top label & placeholder text)
 * 	* Function onChange - callback when the selected value changes
 * 	* bool disabled
 * 	* bool mandatory - if mandatory, the value cannot be de-selected
 */
