import React, { useState, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';
import { AppContext, SttList } from '../../../sporttia/all';
import { formatPriceByLocale } from '../../../lib/utils';

export default function BookingDialogOccupants({
    booking,
    onUpdate,
    onLastOccupantDeleted,
}) {
    const cxt = useContext(AppContext);
    const [occupants, setOccupants] = useState();

    /**
     * Load bono movs
     */
    const loadOccupants = () => {
        cxt.api('GET', `/bookings/${booking.id}/occupants`, {
            params: { page: 1, rows: 800 },
            success: (r) => {
                setOccupants(r);
            },
        });
    };

    /**
     * Booking changes
     */
    useEffect(() => {
        if (booking && booking.id) {
            loadOccupants();
        }
    }, [booking]);

    /**
     * Render rate
     */
    const renderRate = (occupant) => {
        if (occupant.rate) {
            return occupant.rate
                .map(
                    (price) =>
                        `${price.name} - ${formatPriceByLocale(
                            price?.price,
                            price?.priceShape?.currency,
                            price?.priceShape?.locale,
                        )}`,
                )
                .join(', ');
        }
        return ``;
    };

    function deleteOccupant(occupant) {
        if (!(occupant && occupant.id)) {
            return;
        }

        cxt.api('DELETE', `/bookings/occupants/${occupant.id}`, {
            params: {
                cancelBookingOnLastOccupant: true, // TODO: modulo piscina cuando se puedan añadir entradas a la reserva (ver /src/pages/public/booked/Occupants.js)
            },
            success: () => {
                cxt.showMessage('S', cxt.t('Deleted'));
                const newRows = occupants.rows;
                newRows.splice(
                    newRows.findIndex((occ) => occ.id === occupant.id),
                    1,
                );
                setOccupants({ ...occupants, rows: newRows });

                if (newRows.length === 0) {
                    onLastOccupantDeleted && onLastOccupantDeleted();
                } else {
                    onUpdate && onUpdate();
                }
            },
            confirmation: true,
        });
    }

    // -----| Render |-----
    return (
        <>
            {occupants && (
                <SttList
                    onDelete={deleteOccupant}
                    data={occupants.rows.map((occupant) => ({
                        caption: (
                            <Box display="flex" justifyContent="space-between">
                                <div>{`${occupant.user.name} ${renderRate(
                                    occupant,
                                )}`}</div>
                                <div />
                            </Box>
                        ),
                        objToSelect: occupant,
                    }))}
                />
            )}
        </>
    );
}
