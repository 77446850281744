import React, { useState, useContext } from 'react';
import {
    Table,
    TableContainer,
    Paper,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    IconButton,
    Box,
    Typography,
    Input,
} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Edit as EditIcon, Done as DoneIcon } from '@material-ui/icons';
import { AppContext } from '../../../sporttia/all';
import { formatPriceByLocale } from '../../../lib/utils';

export default function SalePointCart({
    cart,
    saveMovementInCart,
    readOnly = true,
    totalNet,
    totalVat,
    total,
}) {
    const cxt = useContext(AppContext);
    const [editedAmount, setEditedAmount] = useState(null);
    const [editingAmount, setEditingAmount] = useState(false);
    const toggleEdit = () => setEditingAmount(!editingAmount);

    /**
     * Decrement amount
     */
    const decrementAmount = (mov) => {
        saveMovementInCart({
            ...mov,
            amount: mov.amount + 1,
        });
    };

    // -----| Render |-----
    return (
        <>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                {cxt.t('Product')}
                            </TableCell>
                            <TableCell align="center">
                                {cxt.t('Amount')}
                            </TableCell>
                            <TableCell align="right">
                                {cxt.t('Price')}
                            </TableCell>
                            <TableCell align="right">{cxt.t('vat')}</TableCell>
                            <TableCell align="right">
                                {cxt.t('Total')}
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {cart &&
                            cart.map((mov) => (
                                <TableRow key={mov?.id}>
                                    <TableCell align="left">
                                        <b>{mov.product.name}</b>
                                    </TableCell>
                                    <TableCell align="center">
                                        {editingAmount ? (
                                            <>
                                                <Input
                                                    style={{
                                                        width: 30,
                                                    }}
                                                    value={editedAmount}
                                                    onChange={(event) =>
                                                        setEditedAmount(
                                                            event.target.value,
                                                        )
                                                    }
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                <IconButton
                                                    onClick={() => {
                                                        saveMovementInCart({
                                                            id: mov?.id,
                                                            amount: -editedAmount,
                                                        });
                                                        toggleEdit();
                                                    }}
                                                >
                                                    <DoneIcon
                                                        style={{
                                                            width: 20,
                                                            height: 20,
                                                        }}
                                                    />
                                                </IconButton>
                                            </>
                                        ) : (
                                            <Box
                                                display="flex"
                                                flexDirection="row"
                                                alignContent="center"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Typography
                                                    style={{ width: 30 }}
                                                >
                                                    {Math.abs(mov.amount)}
                                                </Typography>
                                                <IconButton
                                                    onClick={toggleEdit}
                                                >
                                                    <EditIcon
                                                        style={{
                                                            width: 20,
                                                            height: 20,
                                                        }}
                                                    />
                                                </IconButton>
                                            </Box>
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        {formatPriceByLocale(
                                            mov.finalPrice,
                                            cxt.sc.city.country.currency,
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        {mov.vat} %
                                    </TableCell>
                                    <TableCell align="right">
                                        <b>
                                            {formatPriceByLocale(
                                                Math.abs(mov.total),
                                                cxt.sc.city.country.currency,
                                            )}
                                        </b>
                                    </TableCell>

                                    {!readOnly && (
                                        <TableCell align="right">
                                            <IconButton
                                                onClick={() =>
                                                    decrementAmount(mov)
                                                }
                                            >
                                                <DeleteOutlineIcon />
                                            </IconButton>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}

                        {cart && cart.length === 0 && (
                            <TableRow>
                                <TableCell>
                                    <Box py={2}>{cxt.t('Empty')}</Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box mt={3} align="right">
                <Box>
                    {cxt.t('Net')}:{' '}
                    {formatPriceByLocale(
                        totalNet,
                        cxt.sc?.city?.country?.currency,
                    )}
                </Box>
                <Box>
                    {cxt.t('VAT')}:{' '}
                    {formatPriceByLocale(
                        totalVat,
                        cxt.sc?.city?.country?.currency,
                    )}
                </Box>
                <Box>
                    {cxt.t('Total')}:{' '}
                    <b>
                        {formatPriceByLocale(
                            total,
                            cxt.sc?.city?.country?.currency,
                        )}
                    </b>
                </Box>
            </Box>
        </>
    );
}
