import React, { useState, useContext } from 'react';
import { Paper } from '@material-ui/core';
import { AppContext } from '../../../../sporttia/all';
import AdminPayment from './AdminPayment';
import usePaymentsService from '../../../../services/PaymentsService';
import { SUPERADMIN_PAYMENTS } from '../../../../lib/queryKeys';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';
import {
    useInteractionsFiles,
    usePageHeader,
    useQueryFilters,
} from '../../../../lib/hooks';
import constants from '../../../../config/constants';

/**
 * View where the sporttia superadmin can see the list of payments.
 * @returns {JSX.Element}
 */
export default function AdminPayments() {
    usePageHeader('payments');
    const cxt = useContext(AppContext);
    const paymentsService = usePaymentsService();
    const [filters, setFilters] = useQueryFilters({}, SUPERADMIN_PAYMENTS);
    const [paymentId, setPaymentId] = useState(null);
    const { downloadFile } = useInteractionsFiles();
    const [currentPage, setCurrentPage] = useState(1);

    /**
     * In this case, the value of idCreatorUser and idUser is changed to an object with the corresponding id.
     * @param updatedFilters Object with the parameters returned by the SttTopFilteringControls component.
     */
    const doFiltering = (updatedFilters) => {
        const formatedFilters = { ...updatedFilters };

        if (updatedFilters?.paymentForms === 'ALL') {
            delete formatedFilters.paymentForms;
        }

        if (updatedFilters?.status === 'ALL') {
            delete formatedFilters.status;
        }

        setFilters(formatedFilters);
    };

    /**
     * Download booking PDF
     */
    function download(format) {
        const type = format === 'pdf' ? 'application/pdf' : 'text/csv';
        downloadFile(`/payments.${format}`, type, {
            rows: 20,
            page: currentPage,
            ...filters,
        });
    }

    return (
        <Paper>
            <SttTopFilteringControls
                p={2}
                fields={[
                    {
                        caption: 'Id',
                        name: 'id',
                        type: 'text',
                        value: '',
                    },
                ]}
                extraFields={[
                    {
                        caption: cxt.t('Concept'),
                        name: 'concept',
                        type: 'text',
                        value: '',
                    },
                    {
                        caption: `${cxt.t('Creator')} ID`,
                        name: 'idCreator',
                        type: 'text',
                        value: '',
                    },
                    {
                        caption: `${cxt.t('User')} ID`,
                        name: 'idUser',
                        type: 'text',
                        value: '',
                    },
                    {
                        caption: `${cxt.t('Sportcenter')} ID`,
                        name: 'idSC',
                        type: 'text',
                        value: '',
                    },
                    {
                        caption: cxt.t('Status'),
                        name: 'status',
                        type: 'select',
                        value: 'PAYING',
                        options: constants.payment.paymentStatus,
                    },
                    {
                        caption: cxt.t('PaymentForm'),
                        name: 'paymentForms',
                        type: 'select',
                        value: 'ALL',
                        options:
                            cxt.constants.paymentForms &&
                            cxt.constants.paymentForms.map((pf) =>
                                pf.id === ''
                                    ? {
                                          id: 'ALL',
                                          label: cxt.t('All'),
                                      }
                                    : pf,
                            ),
                    },
                    {
                        caption: cxt.t('Begin'),
                        name: 'paidIni',
                        type: 'date',
                        value: '',
                    },
                    {
                        caption: cxt.t('End'),
                        name: 'paidEnd',
                        type: 'date',
                        value: '',
                    },
                ]}
                menu={[
                    {
                        caption: `${cxt.t('Download')} PDF`,
                        onClick: () => download('pdf'),
                    },
                    {
                        caption: `${cxt.t('Download')} CSV`,
                        onClick: () => download('csv'),
                    },
                ]}
                trashAction
                onFilter={doFiltering}
            />

            <SttCachedTable
                queryKey={SUPERADMIN_PAYMENTS}
                queryFn={(params) => paymentsService.getList(params)}
                queryParams={filters}
                prefetching={false}
                onClickRow={(row) => setPaymentId(row?.id)}
                setCurrentPage={setCurrentPage}
                columns={[
                    {
                        title: 'Id',
                        type: 'id',
                        field: 'id',
                    },
                    {
                        title: cxt.t('Created'),
                        field: 'created',
                        type: 'dmyhm',
                    },
                    {
                        title: cxt.t('Creator'),
                        value: (row) => row?.creator?.login,
                    },
                    {
                        title: cxt.t('User'),
                        value: (row) => `${row?.user?.id} - ${row?.user?.name}`,
                    },
                    {
                        title: cxt.t('Concept'),
                        align: 'left',
                        value: (row) => <b>{row?.concept}</b>,
                    },
                    {
                        title: cxt.t('Status'),
                        field: 'status',
                    },
                    {
                        title: cxt.t('PaymentForm'),
                        field: 'paymentForm',
                    },
                    {
                        title: cxt.t('Paid'),
                        field: 'paymentDate',
                        type: 'dmyhm',
                    },
                    {
                        title: cxt.t('Price'),
                        field: 'price',
                        type: 'price',
                    },
                ]}
            />

            {paymentId && (
                <AdminPayment
                    id={paymentId}
                    onClose={() => setPaymentId(null)}
                />
            )}
        </Paper>
    );
}
