import React, { useState } from 'react';
import { Box, Button, Grid, IconButton, Tab, Tabs } from '@material-ui/core';
import moment from 'moment';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CachedIcon from '@material-ui/icons/Cached';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { usePageHeader, useTranslations } from '../../../lib/hooks';
import SttValidatedForm from '../../../sporttia/SttValidatedForm';

export default function GolfTimetableControls({ form, date, onChange }) {
    usePageHeader('golfTimetable');
    const { translate } = useTranslations();
    const [weekdayTabValue] = useState(0);

    return (
        <>
            <Box p={2}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item sm={9} xs={12}>
                        <SttValidatedForm
                            form={form}
                            fields={[
                                {
                                    type: 'select',
                                    name: 'tee',
                                    caption: translate('Tee'),
                                    options: [
                                        { caption: 'Tee 1', value: 'TEE1' },
                                        { caption: 'Tee 5', value: 'TEE5' },
                                        { caption: 'Tee 10', value: 'TEE10' },
                                    ],
                                    disableClearable: true,
                                    sm: 2,
                                    xs: 12,
                                },
                                {
                                    type: 'select',
                                    name: 'holes',
                                    caption: translate('Holes'),
                                    options: [
                                        {
                                            caption: '18 Hoyos',
                                            value: '8HOLES',
                                        },
                                        { caption: '9 Hoyos', value: '9HOLES' },
                                    ],
                                    disableClearable: true,
                                    sm: 2,
                                    xs: 12,
                                },
                                {
                                    type: 'date',
                                    name: 'date',
                                    caption: translate('Date'),
                                    sm: 2,
                                    xs: 12,
                                },
                                {
                                    type: 'time',
                                    name: 'timeini',
                                    caption: translate('Timeini'),
                                    sm: 2,
                                    xs: 12,
                                },
                                {
                                    type: 'time',
                                    name: 'timeend',
                                    caption: translate('Timeend'),
                                    sm: 2,
                                    xs: 12,
                                },
                            ]}
                        />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <Button
                            onClick={() => console.log(`TEST recargo datos.`)}
                        >
                            <CachedIcon />
                        </Button>
                        <Button
                            onClick={() =>
                                onChange({
                                    date: moment().format('YYYY-MM-DD'),
                                })
                            }
                        >
                            <RotateLeftIcon />
                            &nbsp;
                            {translate('Today')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            <Grid container spacing={3}>
                <Grid item md={1} xs={2}>
                    <IconButton
                        onClick={() =>
                            onChange({
                                date: moment(date)
                                    .subtract(1, 'week')
                                    .format('YYYY-MM-DD'),
                            })
                        }
                    >
                        <ArrowBackIosIcon />
                    </IconButton>
                </Grid>

                <Grid item md={10} xs={8}>
                    <Tabs
                        indicatorColor="primary"
                        variant="fullWidth"
                        textColor="primary"
                        value={weekdayTabValue}
                        onChange={(ev, value) =>
                            onChange({
                                date: moment(date)
                                    .weekday(value)
                                    .format('YYYY-MM-DD'),
                            })
                        }
                    >
                        {[0, 1, 2, 3, 4, 5, 6].map((wd) => (
                            <Tab
                                style={{ minWidth: 100 }}
                                key={wd}
                                label={
                                    window.innerWidth <= 760
                                        ? moment(date)
                                              .weekday(wd)
                                              .format('D dd')
                                        : moment(date)
                                              .weekday(wd)
                                              .format('D ddd')
                                }
                            />
                        ))}
                    </Tabs>
                </Grid>

                <Grid item md={1} xs={2}>
                    <IconButton
                        onClick={() =>
                            onChange({
                                date: moment(date)
                                    .add(1, 'week')
                                    .format('YYYY-MM-DD'),
                            })
                        }
                    >
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </>
    );
}
