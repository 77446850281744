import React, { useState, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import moment from 'moment';
import WebcamCapture from '../../../components/imageCapture/WebcamCapture';
import ImageCrop from '../../../components/imageCapture/ImageCrop';
import { AppContext, SttDialog } from '../../../sporttia/all';
import { fullName } from '../../../lib/utils';
import { useInteractionsFiles } from '../../../lib/hooks';

const Steps = {
    CAPTURING: 0,
    CROPPING: 1,
    DONE: 2,
};

export default function SttWebcamCaptureDialog({
    mship,
    open,
    onClose,
    onUploaded,
}) {
    const cxt = useContext(AppContext);
    const { uploadFile } = useInteractionsFiles();
    const [step, setStep] = useState(Steps.CAPTURING);
    const [imgSrc, setImgSrc] = useState(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        if (open) {
            setStep(Steps.CAPTURING);
        }
    }, [open]);

    useEffect(() => {
        if (imgSrc) {
            setStep(Steps.CROPPING);
        }
    }, [imgSrc]);

    useEffect(() => {
        if (croppedImageUrl) {
            setStep(Steps.DONE);
        }
    }, [croppedImageUrl]);

    function setCroppedImage(dataURL) {
        setCroppedImageUrl(dataURL);
    }

    function saveCroppedImage() {
        if (!croppedImageUrl) {
            console.log('No cropped image dataUrl');
            return;
        }

        if (!mship || !mship.id) {
            console.log('No membership to upload picture to');
            return;
        }

        setUploading(true);

        fetch(croppedImageUrl)
            .then((response) => response.blob())
            .then(
                (blob) => new File([blob], 'temp.jpg', { type: 'image/jpeg' }),
            )
            .then((file) => {
                uploadFile(
                    {
                        name: `${fullName(mship)}_${moment().format(
                            'DD-MM-YYYY',
                        )}`,
                        type: 'USER_PHOTO',
                        idMship: mship.id,
                    },
                    file,
                )
                    .then((r) => {
                        if (onUploaded && onUploaded.constructor === Function) {
                            onUploaded(r);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        setUploading(false);
                    });
            });
    }

    // TODO: títulos para las diferentes secciones / instrucciones para el usuario

    let content;
    switch (step) {
        case Steps.CAPTURING:
            content = <WebcamCapture width={400} onCapture={setImgSrc} />;
            break;
        case Steps.CROPPING:
            content = (
                <ImageCrop
                    src={imgSrc}
                    cropWidth={277}
                    cropHeight={300}
                    targetWidth={277}
                    targetHeight={300}
                    dataReturnType="base64"
                    onCrop={(dataURL) => setCroppedImage(dataURL)}
                />
            );
            break;
        case Steps.DONE:
            content = croppedImageUrl && (
                <Box
                    mt={3}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    <img src={croppedImageUrl} />
                </Box>
            );
            break;
    }

    return (
        <SttDialog
            maxWidth="sm"
            title={cxt.t('CaptureWithWebcam')}
            open={open}
            onClose={onClose}
            content={content}
            buttons={[
                step > 0 && {
                    caption: cxt.t('app.goBack'),
                    icon: <ArrowBack />,
                    onClick: () => setStep(step - 1),
                },
                step < Steps.DONE
                    ? {
                          type: 'cancel',
                          onClick: onClose,
                      }
                    : {
                          type: 'save',
                          onClick: saveCroppedImage,
                          loading: uploading,
                      },
            ]}
        />
    );
}
