import React from 'react';
import { usePageHeader } from '../../../../lib/hooks';
import Report from '../../../sc/Report';

/**
 * View where the sporttia superadmin can see the list of rentals per day.
 * @returns {JSX.Element}
 */
function AdminRentalsPerDay({}) {
    usePageHeader('rentalsPerDay');

    return <Report id={37} navigateBack="/home" />;
}

export default AdminRentalsPerDay;
