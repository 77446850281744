import React, { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { Box, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import Grid from '@material-ui/core/Grid';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import { useHistory } from 'react-router-dom';
import { SttForm } from '../../../sporttia/SttForm';
import { getPath } from '../../Pages';
import { AppContext } from '../../../sporttia/AppContext';

export default function SCContact(props) {
    const cxt = useContext(AppContext);
    const [sc, setSC] = useState({
        phonePublic: '',
        emailPublic: '',
    });
    const [form, setForm] = useState({});
    const [sent, setSent] = useState(false);
    const history = useHistory();

    /**
     * Load SC
     */
    const loadSC = () => {
        cxt.api('GET', `/scs/${props.match.params.id}`, {
            success: (r) => {
                setSC(r.sc);
            },
        });
    };

    /**
     * Send
     */
    const send = () => {
        cxt.api('POST', `/scs/${props.match.params.id}/messages`, {
            params: {
                text: form.text,
            },
            success: (r) => {
                cxt.showMessage('S', cxt.t('Sent'));
                history.push(
                    getPath('scProfile', { id: props.match.params.id }),
                );
            },
        });
    };

    /**
     * Handle form
     */
    const handleForm = ({ name, value }) => {
        setForm({ ...form, [name]: value });
    };

    /**
     * Sport center changes
     */
    useEffect(() => {
        loadSC();
    }, [props.match.params.id]);

    /**
     * Init
     */
    useEffect(() => {
        if (cxt.user) {
            setForm({
                name: cxt.user.name,
                email: cxt.user.email,
                phone: cxt.user.mobile,
            });
        }
    }, []);

    return (
        <Container maxWidth="md">
            <Paper>
                <Box p={2}>
                    <Typography variant="h4" gutterBottom>
                        {cxt.t('Contact')}
                    </Typography>
                    <Divider />
                    <Box pt={2} pb={2}>
                        <Typography variant="subtitle1">
                            <Grid container direction="row" alignItems="center">
                                <PhoneIcon fontSize="large" />
                                <Box ml={1}>
                                    {cxt.t('view.contact.byPhone')}
                                    &nbsp;<b>{sc.phonePublic}</b>
                                </Box>
                            </Grid>
                        </Typography>
                        <Typography variant="subtitle1">
                            <Grid container direction="row" alignItems="center">
                                <EmailIcon fontSize="large" />
                                <Box ml={1}>
                                    {cxt.t('view.contact.byEmail')}
                                    &nbsp;<b>{sc.emailPublic}</b>
                                </Box>
                            </Grid>
                        </Typography>
                    </Box>
                    <Divider />
                    <Box pt={2} pb={2}>
                        <Typography variant="subtitle1">
                            <Grid container direction="row" alignItems="center">
                                <LiveHelpIcon fontSize="large" />
                                <Box ml={1}>{cxt.t('view.contact.byForm')}</Box>
                            </Grid>
                        </Typography>

                        <Box pt={2}>
                            <SttForm
                                onChangeForm={handleForm}
                                elements={[
                                    {
                                        md: 12,
                                        sm: 12,
                                        xs: 12,
                                        caption: cxt.t('Name'),
                                        name: 'name',
                                        value: form.name,
                                    },
                                    {
                                        md: 12,
                                        sm: 12,
                                        xs: 12,
                                        caption: cxt.t('Email'),
                                        name: 'email',
                                        value: form.email,
                                    },
                                    {
                                        md: 12,
                                        sm: 12,
                                        xs: 12,
                                        caption: cxt.t('Phone'),
                                        name: 'phone',
                                        value: form.phone,
                                    },
                                    {
                                        md: 12,
                                        sm: 12,
                                        xs: 12,
                                        type: 'textarea',
                                        name: 'text',
                                        caption: cxt.t(
                                            'ReportIncidenceOrConsults',
                                        ),
                                        value: form.text,
                                    },
                                ]}
                                buttons={[
                                    {
                                        type: 'accept',
                                        show: !sent,
                                        caption: cxt.t('Send'),
                                        onClick: () => send(),
                                    },
                                ]}
                            />
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Container>
    );
}
