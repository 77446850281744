import { useContext } from 'react';
import {
    MutationFunction,
    useMutation,
    UseMutationOptions,
    useQuery,
    UseQueryOptions,
} from 'react-query';
import { useAPI } from '../lib/hooks';
import { AppContext } from '../sporttia/AppContext';
import useRestService from './RestService';
import { GetGroupDiscountsParams } from '../types/api/paths/Group';
import {
    CreateActivityRateDiscount200,
    DeleteActivityRateDiscount200,
    GetActivityRateDiscounts200,
    GetActivityRateDiscountsParams,
} from '../types/api/paths/ActivityRate';

// En realidad /activities/rates es 100% equivalente a /groups
const resource = '/activities/rates';

export const getGetActivityRateDiscountsQueryKey = (
    id: number,
    params?: GetGroupDiscountsParams,
) => [`${resource}/${id}/discounts`, ...(params ? [params] : [])];

/**
 * Hook to access all 'activities rates' resources.
 * @returns {{getActivityRateStudents: (function(*, *=): Promise<unknown>)}}
 */
export default function useActivityRatesService() {
    const { migrationApis } = useContext(AppContext)!;
    const [request] = useAPI(migrationApis);
    const services = useRestService(resource);

    /**
     * GET /activities/rates/:id/students request.
     * @param id ID parameter of the rates group object from where the students are.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function getActivityRateStudents(
        id: number,
        params: Record<string, unknown>,
    ) {
        return request('GET', `${resource}/${id}/students`, params);
    }

    function useGetActivityRateDiscounts<TData = GetActivityRateDiscounts200>(
        id: number,
        params?: GetActivityRateDiscountsParams,
        queryOptions?: UseQueryOptions<
            GetActivityRateDiscounts200,
            unknown,
            TData
        >,
    ) {
        const queryKey =
            queryOptions?.queryKey ??
            getGetActivityRateDiscountsQueryKey(id, params);

        const queryFn = () =>
            request<GetActivityRateDiscounts200>(
                'GET',
                `${resource}/${id}/discounts`,
                params,
            );

        const query = useQuery(queryKey, queryFn, queryOptions);

        return {
            ...query,
            queryKey,
        };
    }

    /**
     * POST /activities/rates/:id/discounts/:id request.
     */
    function useCreateActivityRateDiscount(
        mutationOptions?: UseMutationOptions<
            CreateActivityRateDiscount200,
            unknown,
            {
                rateId: number;
                discountId: number;
            }
        >,
    ) {
        const mutationFn: MutationFunction<
            CreateActivityRateDiscount200,
            {
                rateId: number;
                discountId: number;
            }
        > = ({ discountId, rateId }) =>
            request('POST', `${resource}/${rateId}/discounts/${discountId}`);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * DELETE /groups/:id/discounts/:id request.
     */
    function useDeleteActivityRateDiscount(
        mutationOptions?: UseMutationOptions<
            DeleteActivityRateDiscount200,
            unknown,
            {
                rateId: number;
                discountId: number;
            }
        >,
    ) {
        const mutationFn: MutationFunction<
            DeleteActivityRateDiscount200,
            {
                rateId: number;
                discountId: number;
            }
        > = ({ discountId, rateId }) =>
            request('DELETE', `${resource}/${rateId}/discounts/${discountId}`);

        return useMutation(mutationFn, mutationOptions);
    }

    return {
        ...services,
        getActivityRateStudents,
        useGetActivityRateDiscounts,
        useCreateActivityRateDiscount,
        useDeleteActivityRateDiscount,
    };
}
