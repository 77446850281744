import React, { useState, useContext } from 'react';
import moment from 'moment';
import { useInteractionsFiles, useTuples } from '../../../lib/hooks';
import {
    AppContext,
    SttChipUser,
    SttTable,
    SttTopControls,
    SttUnlockerChips,
} from '../../../sporttia/all';
import { copyToClipboard, fullName } from '../../../lib/utils';
import { SttChipAccessType } from '../../../sporttia/chips/SttChipAccessType';
import DateBuilder from '../../../lib/DateBuilder';

// Para mostrar los diferentes objetos que garantizaron acceso (Alquiler, Bono, Abono y Clase)
/* import BookingDialog from '../../../components/dialogs/bookingDialog/BookingDialog';
import BoletoDialog from '../../../components/dialogs/BoletoDialog';
import SttGroupMemberDialog from '../../../sporttia/dialogs/groupMemberDialog/SttGroupMemberDialog';
import StudentDialog from '../../../components/dialogs/StudentDialog'; */

export default function DeviceAccesses({ id }) {
    const cxt = useContext(AppContext);

    /* const [idBooking, setIdBooking] = useState(null);
    const [idBoleto, setIdBoleto] = useState(null);
    const [idGroupMember, setIdGroupMember] = useState(null);
    const [idStudent, setIdStudent] = useState(null); */

    // page usada para la descarga
    const [page, setPage] = useState(1);
    const [accesses, setAccesses] = useState(null);
    const [filters, setFilters] = useTuples({
        userName: '',
        unlockerKey: '',
        unlocker: '',
        dateini: moment().format('YYYY-MM-DD'),
        dateend: moment().format('YYYY-MM-DD'),
        timeini: '00:00',
        timeend: moment().format('HH:mm'),
        result: 'ALL',
    });

    const { downloadFile } = useInteractionsFiles();
    const rows = 20;

    function loadAccesses(numRowParams = { page: 1, rows }) {
        const finalFilters = { ...filters };

        if (finalFilters.result === 'ALL') {
            delete finalFilters.result;
        }

        cxt.api('GET', `/devices/${id}/accesses`, {
            params: {
                ...numRowParams,
                ...finalFilters,
            },
            success: setAccesses,
        });
    }

    function download(format) {
        const type = format === 'csv' ? 'text/csv' : 'application/pdf';

        const { dateini, dateend, timeini, timeend, unlockerKey } = filters;

        let { result } = filters;

        if (result === 'ALL') {
            result = '';
        }

        // PDF
        if (format === 'pdf') {
            downloadFile(
                `/devices/${id}/accesses.pdf?page=${page}&rows=${rows}&dateini=${dateini}&dateend=${dateend}&timeini=${timeini}&timeend=${timeend}&unlockerKey=${unlockerKey}&result=${result}`,
                type,
                {},
                `${cxt.t('Accesses')}_${moment(filters.dateini).format(
                    'DD-MM-YYYY',
                )}_${moment(filters.dateend).format('DD-MM-YYYY')}.${format}`,
            );
        }

        // CSV
        if (format === 'csv') {
            downloadFile(
                `/devices/${id}/accesses.csv?page=${page}&rows=${rows}&dateini=${dateini}&dateend=${dateend}&timeini=${timeini}&timeend=${timeend}&unlockerKey=${unlockerKey}&result=${result}`,
                type,
                {},
                `${cxt.t('Accesses')}_${moment(filters.dateini).format(
                    'DD-MM-YYYY',
                )}_${moment(filters.dateend).format('DD-MM-YYYY')}.${format}`,
            );
        }
    }

    function openItem(item) {
        /* Mostramos diferente diálogo según el tipo de objeto TODO
        switch (item.type) {
            case 'BK':
                setIdBooking(item.id);
                break;
            case 'BN':
                setIdBoleto(item.id);
                break;
            case 'GP':
                setIdGroupMember(item.id);
                break;
            case 'AC':
                setIdStudent(item.id);
                break;
            default:
                break;
        } */

        copyToClipboard(item.id, () =>
            cxt.showMessage('S', cxt.t('copiedToClipboard')),
        );
    }

    return (
        <>
            <SttTopControls
                fields={[
                    {
                        caption: cxt.t('Name'),
                        type: 'text',
                        name: 'userName',
                        value: filters.userName,
                        onEnterKey: loadAccesses,
                    },
                    {
                        caption: cxt.t('Unlocker'),
                        type: 'text',
                        name: 'unlockerKey',
                        value: filters.unlockerKey,
                        onEnterKey: loadAccesses,
                    },
                ]}
                extraFields={[
                    {
                        xs: 12,
                        sm: 12,
                        md: 6,
                        type: 'period',
                        nameIni: 'dateini',
                        valIni: filters.dateini,
                        nameEnd: 'dateend',
                        valEnd: filters.dateend,
                    },
                    {
                        xs: 12,
                        sm: 6,
                        md: 3,
                        caption: cxt.t('TimeIni'),
                        type: 'time',
                        name: 'timeini',
                        value: filters.timeini,
                        interval: 1,
                    },
                    {
                        xs: 12,
                        sm: 6,
                        md: 3,
                        caption: cxt.t('TimeEnd'),
                        type: 'time',
                        name: 'timeend',
                        value: filters.timeend,
                        interval: 1,
                    },
                    {
                        caption: cxt.t('Result'),
                        type: 'select',
                        name: 'result',
                        value: filters.result,
                        options: [
                            {
                                label: cxt.t('All'),
                                value: 'ALL',
                            },
                            {
                                label: cxt.t('Success'),
                                value: 'SUCCESS',
                            },
                            {
                                label: cxt.t('Fail'),
                                value: 'FAILD',
                            },
                        ],
                    },
                ]}
                menu={[
                    {
                        caption: `${cxt.t('Download')} PDF`,
                        onClick: () => download('pdf'),
                    },
                    {
                        caption: `${cxt.t('Download')} CSV`,
                        onClick: () => download('csv'),
                    },
                ]}
                onChange={setFilters}
                onFilter={() => loadAccesses()}
            />
            <SttTable
                onFetch={loadAccesses}
                data={accesses}
                onChangePagination={setPage}
                columns={[
                    {
                        title: '#',
                        type: 'tooltip',
                        value: (row) => ({ label: '#', value: row.id }),
                        onClick: (row) =>
                            copyToClipboard(row.id, () =>
                                cxt.showMessage(
                                    'S',
                                    cxt.t('copiedToClipboard'),
                                ),
                            ),
                    },
                    {
                        title: cxt.t('Date'),
                        field: 'created',
                        value: (r) =>
                            new DateBuilder(
                                r?.created,
                                cxt?.sc?.timezone?.name,
                                cxt?.lang.key,
                            ).dmyhm(),
                    },
                    {
                        title: cxt.t('Direction'),
                        value: (row) => row.direction,
                    },
                    {
                        title: cxt.t('Unlocker'),
                        value: (row) =>
                            row.unlocker &&
                            row.unlocker.user && (
                                <SttUnlockerChips
                                    unlockers={[row.unlocker]}
                                    showCreatingButton={false}
                                    idUser={row.unlocker.user.id}
                                    idSC={cxt.sc.id}
                                />
                            ),
                    },
                    {
                        title: cxt.t('Method'),
                        value: (row) => (
                            <SttChipAccessType
                                access={row}
                                onClick={openItem}
                            />
                        ),
                    },
                    {
                        title: cxt.t('User'),
                        value: (row) =>
                            row.unlocker &&
                            row.unlocker.user &&
                            row.unlocker.user.mship && (
                                <SttChipUser
                                    user={{
                                        login: fullName(
                                            row.unlocker.user.mship,
                                        ),
                                    }}
                                />
                            ),
                    },
                    {
                        title: cxt.t('Result'),
                        type: 'binaryStatus',
                        value: (row) => row.result === 'SUCCESS',
                    },
                    {
                        title: cxt.t('Error'),
                        type: 'text',
                        field: 'error',
                    },
                ]}
            />

            {/* Diálogos para mostrar los items mediante los que se accedió (boleto, groupMembter, etc) TODO */}

            {/* idBooking && (
                <BookingDialog
                    idBooking={idBooking}
                    onClose={() => setIdBooking(null)}
                />
            ) */}
            {/* idBoleto && (
                <BoletoDialog
                    item={idBoleto}
                    onClose={() => setIdBoleto(null)}
                />
            ) */}
            {/* idGroupMember && (
                <SttGroupMemberDialog
                    idGroupMember={idGroupMember}
                    trash={false}
                    onClose={() => {
                        setIdGroupMember(null);
                    }}
                    onUpdateMember={() => {
                        setIdGroupMember(null);
                    }}
                />
            ) */}
            {/* idStudent !== null && (
                <StudentDialog
                    open
                    idStudent={idStudent}
                    onClose={() => {
                        setIdStudent(null);
                    }}
                    onSave={() => {
                        setIdStudent(null);
                    }}
                    trash={false}
                />
            ) */}
        </>
    );
}
