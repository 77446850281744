import React, { useState, useContext } from 'react';
import {
    CloudDownload,
    PersonOutlineOutlined,
    Replay,
    ChevronRight,
    ExpandMore,
} from '@material-ui/icons';
import { Box, Link } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TreeView, TreeItem } from '@material-ui/lab';
import { useQueryClient } from 'react-query';
import moment from 'moment';
import { AppContext, SttChipPForm } from '../../../sporttia/all';
import SttGroupMemberDialog from '../../../sporttia/dialogs/groupMemberDialog/SttGroupMemberDialog';
import PaymentModal from '../../../components/paymentModal/PaymentModal';
import SttMshipDialog from '../../../sporttia/dialogs/SttMshipDialog';
import { formatPriceByLocale, fullName } from '../../../lib/utils';
import {
    useQueryFilters,
    useInteractionsFiles,
    useTranslations,
} from '../../../lib/hooks';
import { SttTopFilteringControls } from '../../../sporttia/SttTopFilteringControls';
import useGroupsService from '../../../services/GroupsService';
import { GROUP_MEMBERS } from '../../../lib/queryKeys';
import SttCachedTable from '../../../sporttia/SttCachedTable';
import { Group } from '../../../types/api/models';
import { DetailsId, DownloadFileFormat } from '../../../types/components';
import {
    GetGroupMembers200,
    GetGroupMembersParams,
} from '../../../types/api/paths/Group';
import DiscountTooltip from '../../../components/discounts/DiscountTooltip';
import { DefaultListParams } from '../../../types/api/utils';
import Payment from '../../../types/models/Payment';
import GroupMember from '../../../types/models/GroupMember';
import Mship from '../../../types/models/Mship';

const useStyles = makeStyles({
    infoButton: {
        '&:hover': {
            cursor: 'pointer',
            color: 'rgba(33, 150, 243, 0.5)',
        },
    },
    beneficiaryFont: {
        fontFamily: 'Roboto',
        color: '#788a08',
        backgroundColor: '#ffffff',
    },
});

type Filters = {
    isActive: string;
    upToDateWithPayments: string;
    subscriptionType: 'all' | 'inscription' | 'unsubscription';
    subscriptionIni: string;
    subscriptionEnd: string;
    trash: boolean;
};

const filtersToApi = ({
    subscriptionEnd,
    subscriptionIni,
    subscriptionType,
    isActive,
    upToDateWithPayments,
    page,
    rows,
    name,
    trash,
}: Filters & GetGroupMembersParams): GetGroupMembersParams => {
    const base = {
        isActive,
        upToDateWithPayments,
        ...(page && { page }),
        ...(rows && { rows }),
        ...(name && { name }),
        ...(trash && { trash }),
    };

    if (subscriptionType === 'inscription') {
        return {
            ...base,
            inscriptionIni: subscriptionIni,
            inscriptionEnd: subscriptionEnd,
        };
    }

    if (subscriptionType === 'unsubscription') {
        return {
            ...base,
            unsubscriptionIni: subscriptionIni,
            unsubscriptionEnd: subscriptionEnd,
        };
    }

    if (subscriptionType === 'all') {
        return base;
    }

    throw new Error(
        'subscriptionType must be "all", "inscription" or "unsubscription"',
    );
};

type GroupMembersProps = {
    item: Group;
    haveFees: boolean;
};

/**
 * GroupMembers : list of members for this Group
 */
export default function GroupMembers({ item, haveFees }: GroupMembersProps) {
    const cxt = useContext(AppContext)!;

    const { downloadFile } = useInteractionsFiles();
    const { translate } = useTranslations();

    const classes = useStyles();

    const [openGroupMemberDialog, setOpenGroupMemberDialog] = useState(false);
    const [groupMemberDialogId, setGroupMemberDialogId] = useState<DetailsId>();
    const [groupMemberTrash, setGroupMemberTrash] = useState<boolean>();
    const [idPayment, setIdPayment] = useState<number>();
    const [openMshipDialog, setOpenMshipDialog] = useState(false);
    const [selectedMshipId, setSelectedMshipId] = useState<number>();

    const groupsService = useGroupsService();

    const [filters, setFilters] = useQueryFilters<Filters>(
        {
            isActive: '0',
            upToDateWithPayments: '0',
            subscriptionType: 'all',
            subscriptionIni: moment().format('YYYY-MM-DD'),
            subscriptionEnd: moment().format('YYYY-MM-DD'),
            trash: false,
        },
        GROUP_MEMBERS,
    );

    const queryClient = useQueryClient();

    const queryParams = { ...filtersToApi(filters), id: item.id };
    const queryKey = [GROUP_MEMBERS, queryParams];

    // Enrollment is group member payment
    function updatePayment(payment: Payment) {
        queryClient.setQueriesData<GetGroupMembers200 | undefined>(
            { queryKey },
            (old) => {
                if (!old) {
                    return undefined;
                }

                return {
                    ...old,
                    rows: old.rows.map((row) =>
                        row.enrollment?.id === payment.id
                            ? {
                                  ...row,
                                  enrollment: {
                                      ...row.enrollment,
                                      ...payment,
                                  },
                              }
                            : row,
                    ),
                };
            },
        );
    }

    function updateMship(mship: Mship) {
        queryClient.setQueriesData<GetGroupMembers200 | undefined>(
            { queryKey },
            (old) => {
                if (!old) {
                    return undefined;
                }

                return {
                    ...old,
                    rows: old.rows.map((row) => {
                        if (row.user?.mship?.id === mship.id) {
                            return {
                                ...row,
                                user: {
                                    ...row.user,
                                    mship: {
                                        ...row.user.mship,
                                        ...mship,
                                    },
                                },
                            };
                        }

                        return row;
                    }),
                };
            },
        );
    }

    function download(format: DownloadFileFormat) {
        if (format === 'pdf') {
            downloadFile(
                `/groups/${item.id}/members.pdf`,
                'application/pdf',
                filtersToApi(filters),
                `${item.name}.${format}`,
            );
        }

        if (format === 'csv') {
            downloadFile(
                `/groups/${item.id}/members.csv`,
                'text/csv',
                filtersToApi(filters),
                `${item.name}.${format}`,
            );
        }
    }

    return (
        <>
            {/* @ts-expect-error TS(2739): Type '{ mb: number; fields: { type: string; captio... Remove this comment to see the full error message */}
            <SttTopFilteringControls
                mb={2}
                fields={[
                    {
                        type: 'text',
                        caption: cxt.t('User'),
                        name: 'name',
                    },
                ]}
                extraFields={[
                    {
                        name: 'isActive',
                        type: 'select',
                        caption: cxt.t('Active'),
                        value: filters.isActive,
                        sm: 5,
                        options: [
                            {
                                caption: cxt.t('All'),
                                value: 0,
                            },
                            {
                                caption: cxt.t('OnlyActiveOnes'),
                                value: 1,
                            },
                        ],
                    },
                    {
                        name: 'upToDateWithPayments',
                        type: 'select',
                        caption: cxt.t('Payments'),
                        value: filters.upToDateWithPayments,
                        sm: 5,
                        options: [
                            {
                                caption: cxt.t(
                                    'page.sc.groups.upToDateWithPayments',
                                ),
                                value: 1,
                            },
                            {
                                caption: cxt.t('All'),
                                value: 0,
                            },
                        ],
                    },
                    {
                        type: 'selectPeriod',
                        sm: 5,
                        selectCaption: cxt.t('groupMemberSubscriptionFilter'),
                        selectName: 'subscriptionType',
                        selectValue: filters.subscriptionType,
                        selectOptions: [
                            {
                                caption: cxt.t('groupMemberSubscription'),
                                value: 'inscription',
                            },
                            {
                                caption: cxt.t('groupMemberCancelation'),
                                value: 'unsubscription',
                            },
                            {
                                caption: cxt.t('All'),
                                value: 'all',
                            },
                        ],
                        nameIni: 'subscriptionIni',
                        valIni: filters.subscriptionIni,
                        nameEnd: 'subscriptionEnd',
                        valEnd: filters.subscriptionEnd,
                        disableOnValue: 'all',
                    },
                ]}
                mainAction={
                    item.type !== 'SPORT'
                        ? {
                              type: 'create',
                              onClick: () => {
                                  setOpenGroupMemberDialog(true);
                                  setGroupMemberDialogId('create');
                                  setGroupMemberTrash(false);
                              },
                          }
                        : null
                }
                menu={[
                    {
                        caption: `${cxt.t('Download')} PDF`,
                        onClick: () => download('pdf'),
                    },
                    {
                        caption: `${cxt.t('Download')} CSV`,
                        onClick: () => download('csv'),
                    },
                ]}
                menuIcon={<CloudDownload />}
                onFilter={setFilters}
                trashAction
            />
            <SttCachedTable
                queryKey={GROUP_MEMBERS}
                queryFn={(value: typeof queryParams & DefaultListParams) => {
                    const { id, ...params } = value;
                    return groupsService.getGroupMembers(id, params);
                }}
                /* @ts-expect-error QueryParams */
                queryParams={queryParams}
                prefetching
                columns={[
                    {
                        title: cxt.t('Name'),
                        type: 'link',
                        field: 'name',
                        align: 'left',
                        value: (row: GroupMember) => (
                            <>
                                <Link
                                    onClick={() => {
                                        setOpenGroupMemberDialog(true);
                                        setGroupMemberDialogId(row.id);
                                        setGroupMemberTrash(row.trash);
                                    }}
                                    variant="body2"
                                >
                                    {fullName(row.user)}
                                </Link>
                                {row.notes ? (
                                    <Box mt={1} style={{ color: 'red' }}>
                                        {row.notes}
                                    </Box>
                                ) : null}
                                {row.beneficiaries?.count ? (
                                    <Box
                                        mt={1}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <TreeView
                                            className={classes.beneficiaryFont}
                                            defaultCollapseIcon={<ExpandMore />}
                                            defaultExpandIcon={<ChevronRight />}
                                        >
                                            <TreeItem
                                                className={
                                                    classes.beneficiaryFont
                                                }
                                                nodeId="0"
                                                label={`${translate(
                                                    'Beneficiaries',
                                                )} - ${
                                                    row.beneficiaries.count
                                                }`}
                                            >
                                                {row.beneficiaries.rows?.map(
                                                    (beneficiary) => (
                                                        <TreeItem
                                                            key={beneficiary.id}
                                                            className={
                                                                classes.beneficiaryFont
                                                            }
                                                            nodeId={beneficiary.id.toString()}
                                                            label={`${
                                                                beneficiary.name
                                                            } - ${translate(
                                                                beneficiary.holderRelation,
                                                            )}`}
                                                        />
                                                    ),
                                                )}
                                            </TreeItem>
                                        </TreeView>
                                    </Box>
                                ) : null}

                                <br />
                            </>
                        ),
                    },
                    {
                        title: cxt.t('Inscription'),
                        value: (row: GroupMember) =>
                            row.enrollment &&
                            (row.enrollment.refund ? (
                                <Box
                                    px={1}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Link
                                        onClick={() => {
                                            setIdPayment(row.enrollment?.id);
                                        }}
                                    >
                                        {formatPriceByLocale(
                                            row.enrollment.price,
                                            cxt.sc?.city?.country?.currency,
                                        )}
                                    </Link>

                                    <Box mx={1}>
                                        <SttChipPForm
                                            pf={row.enrollment.paymentForm}
                                        />
                                    </Box>

                                    <Replay fontSize="small" />
                                </Box>
                            ) : (
                                <Box
                                    px={1}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Link
                                        onClick={() => {
                                            setIdPayment(row.enrollment?.id);
                                        }}
                                    >
                                        {formatPriceByLocale(
                                            row.enrollment.price,
                                            cxt.sc?.city?.country?.currency,
                                        )}
                                    </Link>

                                    <Box mx={1}>
                                        <SttChipPForm
                                            pf={row.enrollment.paymentForm}
                                        />
                                    </Box>

                                    <Box width={20} height={20} />
                                </Box>
                            )),
                    },
                    {
                        title: cxt.t('Period'),
                        type: 'period',
                        value: (row: GroupMember) => ({
                            ini: row.ini,
                            end: row.end,
                        }),
                    },
                    {
                        title: `${cxt.t('PaymentForm')} (${cxt.t('Fee')})`,
                        value: (row: GroupMember) =>
                            haveFees &&
                            ['ABONADOS', 'NORMAL', 'FAMILY'].includes(
                                row.group?.type ?? '',
                            ) ? (
                                <SttChipPForm pf={row.paymentFormFee} />
                            ) : (
                                ''
                            ),
                    },
                    {
                        title: '',
                        value: (row: GroupMember) =>
                            row.discounts && (
                                <DiscountTooltip discounts={row.discounts} />
                            ),
                    },
                    {
                        title: '',
                        value: (row: GroupMember) => (
                            <PersonOutlineOutlined
                                fontSize="small"
                                className={classes.infoButton}
                                onClick={() => {
                                    setOpenMshipDialog(true);
                                    setSelectedMshipId(row.user?.mship?.id);
                                }}
                            />
                        ),
                    },
                ]}
            />

            {openGroupMemberDialog && (
                <SttGroupMemberDialog
                    // @ts-expect-error TS(2322): Type 'DetailsId' is not assignable to type 'null |... Remove this comment to see the full error message
                    idGroupMember={groupMemberDialogId}
                    trash={groupMemberTrash}
                    group={item}
                    onClose={() => {
                        setOpenGroupMemberDialog(false);
                        setGroupMemberDialogId(null);
                    }}
                    onUpdateMember={() => {
                        queryClient.invalidateQueries({ queryKey });
                        setOpenGroupMemberDialog(false);
                        setGroupMemberDialogId(null);
                    }}
                />
            )}

            {idPayment && (
                <PaymentModal
                    idPayment={idPayment}
                    onClose={() => setIdPayment(undefined)}
                    onPay={(payment) => {
                        updatePayment(payment);
                    }}
                />
            )}

            <SttMshipDialog
                open={openMshipDialog}
                mshipId={selectedMshipId}
                onClose={() => {
                    setOpenMshipDialog(false);
                }}
                onSave={updateMship}
            />
        </>
    );
}
