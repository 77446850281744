import React from 'react';
import { Container } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import MessageList from '../../sc/messages/MessageList';

/**
 * Page where the user can consult all the messages received.
 * @returns {JSX.Element}
 */
export default function UserMessages({}) {
    return (
        <Container maxWidth="md">
            <Paper>
                <Box p={2}>
                    <MessageList fold="INBOX" />
                </Box>
            </Paper>
        </Container>
    );
}
