import React, { useState, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';
import { AppContext } from '../../sporttia/all';
import underConstruction from '../../images/underConstruction.svg';

export default function Files({}) {
    const cxt = useContext(AppContext);

    // -----| Render |-----
    return (
        <Box m={8} align="center">
            <img src={underConstruction} width={230} />

            <h1>Under construction ... </h1>
        </Box>
    );
}
