import React, { useContext } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Box, CircularProgress, Container, Grid } from '@material-ui/core';
import {
    AccountBalanceWallet,
    CreditCard,
    PostAdd,
    Contactless,
} from '@material-ui/icons';
import { AppContext, SttButton, SttPage } from '../../../sporttia/all';
import { getPath } from '../../Pages';
import { formatPriceByLocale, getErrorMessage } from '../../../lib/utils';
import useGroupsService from '../../../services/GroupsService';
import SttError from '../../../components/error/SttError';
import useScsService from '../../../services/ScsService';
import constants from '../../../config/constants';
import translations from '../../../translations';

type BuyAbonoProps = RouteComponentProps<{
    idGroup: string;
    idSc: string;
}>;

export default function BuyAbono({ match }: BuyAbonoProps) {
    const cxt = useContext(AppContext)!;
    const history = useHistory();
    const groupService = useGroupsService();
    const scsService = useScsService();

    const getGroupQuery = groupService.useGetGroup(
        parseInt(match.params.idGroup),
    );

    const getScTpvConfsQuery = scsService.useGetScTpvConfs(
        parseInt(match.params.idSc),
    );

    const createGroupMemberMutation = groupService.useCreateGroupMember({
        onSuccess: (response) => {
            // Go to pay with credit card
            if (response.tpv) {
                history.push(getPath('tpvSwitcher', { id: response.tpv.id }));

                // Just got it
            } else {
                history.push(
                    getPath('userAbono', { id: response.groupMember.id }),
                );
            }
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });

    if (getGroupQuery.isLoading || getScTpvConfsQuery.isLoading) {
        return (
            <Container maxWidth="md">
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    if (getGroupQuery.isError || getScTpvConfsQuery.isError) {
        return <SttError />;
    }

    if (!getGroupQuery.data || !getScTpvConfsQuery.data) {
        return null;
    }

    const { group } = getGroupQuery.data;
    const hasZitycard = getScTpvConfsQuery.data.rows.some(
        (tpv) => tpv.bank === constants.tpvConf.banks.zitycard.name,
    );

    return (
        <Container maxWidth="md">
            <SttPage innerTitle={cxt.t('BuyAbono')}>
                {
                    <Box mt={2}>
                        <Box>
                            {cxt.t('Name')}: <b>{group.name}</b>
                            <br />
                            {'enrollmentPrice' in group && (
                                <>
                                    {cxt.t('Enrollment')}:{' '}
                                    <b>
                                        {formatPriceByLocale(
                                            group.enrollmentPrice,
                                            group.enrollmentShape?.currency,
                                        )}
                                    </b>
                                </>
                            )}
                            <br />
                        </Box>
                        {group.feePrice !== undefined && group.feeInterval && (
                            <>
                                {cxt.t('Fee')}:{' '}
                                <b>
                                    {formatPriceByLocale(
                                        group.feePrice,
                                        group.feeShape?.currency,
                                    )}
                                </b>{' '}
                                / {cxt.t(group.feeInterval)}
                            </>
                        )}

                        <Box mt={2}>{group.description}</Box>

                        <Box mt={3}>
                            <Grid container spacing={3}>
                                {/* --- Paying with TPV --- */}
                                {!!group.enrollmentPrice && (
                                    <>
                                        <Grid item md={6} sm={12}>
                                            <SttButton
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                caption={cxt.t(
                                                    translations.payment
                                                        .payWithTpv,
                                                )}
                                                startIcon={<CreditCard />}
                                                onClick={() => {
                                                    createGroupMemberMutation.mutate(
                                                        {
                                                            id: group.id,
                                                            params: {
                                                                paymentFormSignup:
                                                                    constants
                                                                        .payment
                                                                        .paymentForms
                                                                        .tpv
                                                                        .name,
                                                            },
                                                        },
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <SttButton
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                caption={cxt.t(
                                                    translations.payment
                                                        .payWithPurse,
                                                )}
                                                startIcon={
                                                    <AccountBalanceWallet />
                                                }
                                                onClick={() => {
                                                    createGroupMemberMutation.mutate(
                                                        {
                                                            id: group.id,
                                                            params: {
                                                                paymentFormSignup:
                                                                    constants
                                                                        .payment
                                                                        .paymentForms
                                                                        .purse
                                                                        .name,
                                                            },
                                                        },
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        {hasZitycard && (
                                            <Grid item md={6} sm={12}>
                                                <SttButton
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    caption={cxt.t(
                                                        translations.payment
                                                            .payWithZitycard,
                                                    )}
                                                    startIcon={<Contactless />}
                                                    onClick={() => {
                                                        createGroupMemberMutation.mutate(
                                                            {
                                                                id: group.id,
                                                                params: {
                                                                    paymentFormSignup:
                                                                        constants
                                                                            .payment
                                                                            .paymentForms
                                                                            .zitycard
                                                                            .name,
                                                                },
                                                            },
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        )}
                                    </>
                                )}

                                {/* --- Get it for free (because it's free) --- */}
                                {!group.enrollmentPrice && (
                                    <Grid item md={5}>
                                        <SttButton
                                            variant="contained"
                                            color="primary"
                                            caption={cxt.t('GetAbono')}
                                            startIcon={<PostAdd />}
                                            onClick={() => {
                                                createGroupMemberMutation.mutate(
                                                    { id: group.id },
                                                );
                                            }}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </Box>
                }
            </SttPage>
        </Container>
    );
}
