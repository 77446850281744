import React, { useState, useContext } from 'react';
import {
    Box,
    Grid,
    Card,
    CardHeader,
    CardActions,
    CardContent,
    CardActionArea,
    Avatar,
    IconButton,
    Typography,
    makeStyles,
    Button,
    Tooltip,
    Theme,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CategoryIcon from '@material-ui/icons/Category';
import CreateIcon from '@material-ui/icons/Create';
import AddIcon from '@material-ui/icons/Add';
import { AppContext, SttFormSearch, SttNoResults } from '../../../sporttia/all';
import ProductDialog from './ProductDialog';
import { copyToClipboard, formatPriceByLocale } from '../../../lib/utils';
import Product from '../../../types/models/Product';

const useStyles = makeStyles((theme: Theme) => ({
    productCardHeaderRoot: {
        padding: 0,
    },
    productCardHeaderAction: {
        margin: 0,
    },
    productCardContentRoot: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

type SalePointProductCardProps = {
    product: Product;
    selectProduct: (product: Product) => void;
    setOpenProduct: (product: Product) => void;
};

function SalePointProductCard({
    product,
    selectProduct,
    setOpenProduct,
}: SalePointProductCardProps) {
    const cxt = useContext(AppContext)!;

    const classes = useStyles();

    return (
        <Card>
            <CardHeader
                classes={{
                    root: classes.productCardHeaderRoot,
                    action: classes.productCardHeaderAction,
                }}
                action={
                    <Tooltip title={product.id}>
                        <Button
                            size="small"
                            onClick={() =>
                                copyToClipboard(product.id, () => {
                                    cxt.showMessage(
                                        'S',
                                        cxt.t('copiedToClipboard'),
                                    );
                                })
                            }
                        >
                            #
                        </Button>
                    </Tooltip>
                }
            />
            <CardActionArea onClick={() => selectProduct(product)}>
                <CardContent
                    classes={{
                        root: classes.productCardContentRoot,
                    }}
                >
                    <Typography variant="h5" component="h2">
                        {product.name}
                    </Typography>
                    <Typography color="textSecondary">
                        {product.category}
                    </Typography>
                </CardContent>
            </CardActionArea>

            <CardActions>
                {formatPriceByLocale(
                    product.finalPrice,
                    cxt.sc?.city?.country?.currency,
                )}

                {product.stockageActive && (
                    <Box ml={2}>
                        <Typography>Uds.: {product.stock}</Typography>
                    </Box>
                )}

                <IconButton
                    size="small"
                    style={{
                        marginLeft: 'auto',
                    }}
                    onClick={() => setOpenProduct(product)}
                >
                    <CreateIcon />
                </IconButton>
            </CardActions>
        </Card>
    );
}

type SalePointProductsProps = {
    selectProduct: (product: Product) => void;
    allProducts: Product[];
    onUpdateProduct: () => void;
    onDeleteProduct: () => void;
};

export default function SalePointProducts({
    selectProduct,
    allProducts,
    onUpdateProduct,
    onDeleteProduct,
}: SalePointProductsProps) {
    const cxt = useContext(AppContext)!;

    const [searchingKeyword, setSearchingKeyword] = useState<string>('');
    const [selectedCategory, setSelectedCategory] = useState<string>();
    const [openProduct, setOpenProduct] = useState<
        Product | Record<string, never>
    >();

    const filteredProducts = allProducts
        .filter(
            (product) =>
                product.category === selectedCategory || searchingKeyword,
        )
        .filter(
            (product) =>
                product.name
                    .toLowerCase()
                    .indexOf(searchingKeyword.toLowerCase()) !== -1,
        );

    const categories = allProducts
        .map((product) => product.category || cxt.t('Others'))
        .filter((cat) => cat !== undefined)
        .filter((value, index, self) => self.indexOf(value) === index);

    return (
        <Box>
            <Box mb={3}>
                <SttFormSearch
                    defVal={searchingKeyword}
                    onClean={() => setSearchingKeyword('')}
                    onChange={({ value }) => setSearchingKeyword(value)}
                />
            </Box>

            <Grid container spacing={3}>
                {selectedCategory || searchingKeyword ? (
                    <>
                        {selectedCategory && (
                            <Grid item lg={4} xs={6}>
                                <CardActionArea
                                    onClick={() =>
                                        setSelectedCategory(undefined)
                                    }
                                >
                                    <Card>
                                        <CardHeader
                                            avatar={
                                                <Avatar>
                                                    <ArrowBackIcon />
                                                </Avatar>
                                            }
                                            title={cxt.t('Back')}
                                        />
                                    </Card>
                                </CardActionArea>
                            </Grid>
                        )}

                        {filteredProducts.length > 0 &&
                            filteredProducts.map((product) => (
                                <Grid key={product.id} item lg={4} xs={6}>
                                    <SalePointProductCard
                                        selectProduct={selectProduct}
                                        setOpenProduct={setOpenProduct}
                                        product={product}
                                    />
                                </Grid>
                            ))}

                        {filteredProducts.length === 0 && <SttNoResults />}
                    </>
                ) : (
                    <>
                        {categories.map((category) => (
                            <Grid key={category} item lg={4} xs={6}>
                                <CardActionArea
                                    onClick={() =>
                                        setSelectedCategory(category)
                                    }
                                >
                                    <Card>
                                        <CardHeader
                                            avatar={
                                                <Avatar
                                                    style={{
                                                        backgroundColor:
                                                            '#5f8bec',
                                                    }}
                                                >
                                                    <CategoryIcon />
                                                </Avatar>
                                            }
                                            title={category}
                                        />
                                    </Card>
                                </CardActionArea>
                            </Grid>
                        ))}

                        <Grid item lg={4} xs={6}>
                            <CardActionArea onClick={() => setOpenProduct({})}>
                                <Card>
                                    <CardHeader
                                        avatar={
                                            <Avatar
                                                style={{
                                                    backgroundColor: '#16c713',
                                                }}
                                            >
                                                <AddIcon />
                                            </Avatar>
                                        }
                                        title={cxt.t('AddProduct')}
                                    />
                                </Card>
                            </CardActionArea>
                        </Grid>
                    </>
                )}
            </Grid>

            <ProductDialog
                product={openProduct}
                onClose={() => setOpenProduct(undefined)}
                onUpdateProduct={onUpdateProduct}
                onDeleteProduct={onDeleteProduct}
            />
        </Box>
    );
}
