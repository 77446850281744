import React, { useContext, useEffect } from 'react';
import { Box, Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AppContext } from '../../../../sporttia/AppContext';
import { useLoader } from '../../../../lib/hooks';
import { getErrorMessage } from '../../../../lib/utils';
import { SttFullDialog } from '../../../../sporttia/dialogs/SttFullDialog';
import SttValidatedForm from '../../../../sporttia/SttValidatedForm';
import { SUPERADMIN_TPV_CONFIGURATION } from '../../../../lib/queryKeys';
import useTpvsService from '../../../../services/TpvsService';
import constants from '../../../../config/constants';
import translations from '../../../../translations';

/**
 * Tpv configuration view, contains tpv configuration form.
 * @param id Tpv configuration ID.
 * @param onClose Function executed when closing the full dialog.
 * @param onCreate Action taken when creating the tpv configuration.
 * @param onUpdate Action taken when updating the tpv configuration.
 * @param onDelete Method executed when removing tpv configuration.
 * @returns {JSX.Element}
 */
export default function AdminTPVConf({
    id,
    onClose,
    onCreate,
    onUpdate,
    onDelete,
}) {
    const cxt = useContext(AppContext);
    const form = useForm();
    const queryClient = useQueryClient();
    const watchBank = form.watch('bank');

    const tpvsService = useTpvsService();
    const tpvConfigurationQuery = useQuery(
        [SUPERADMIN_TPV_CONFIGURATION, { id }],
        () => tpvsService.getTpvsConfiguration(id),
        {
            enabled: id !== 'create',
            onSuccess: (response) => {
                form.reset({
                    name: response.tpvConf.name,
                    bank: response.tpvConf.bank,
                    environment: response.tpvConf.environment,
                    description: response.tpvConf.description,
                    // REDSYS_HMAC256//
                    redsysFuc: response.tpvConf.redsysFuc,
                    redsysIdTerminal: response.tpvConf.redsysIdTerminal,
                    // CCM//
                    cecaMerchantId: response.tpvConf.cecaMerchantId,
                    cecaAcquirerBin: response.tpvConf.cecaAcquirerBin,
                    cecaTerminalId: response.tpvConf.cecaTerminalId,
                    // STRIPE//
                    stripeSecretKey: response.tpvConf.stripeSecretKey,
                    stripePublicKey: response.tpvConf.stripePublicKey,
                    // PAYCOMET//
                    paycometApiKey: response.tpvConf.paycometApiKey,
                    paycometTerminalId: response.tpvConf.paycometTerminalId,
                    // COMUNES REDSYS_HMAC256 y CCM//
                    keyPro: response.tpvConf.keyPro,
                    keyDev: response.tpvConf.keyDev,
                    // Cuando un centro tiene muchos tpv confs (Es decir, un tpv conf tiene un centro)
                    scId: response.tpvConf.sc?.id,
                });
            },
        },
    );
    const deleteMutation = useMutation(
        (params) => tpvsService.removeTpvConfiguration(params?.id),
        {
            onSuccess: onDelete,
            onError: (error) => {
                cxt.showMessage('E', getErrorMessage(error));
            },
        },
    );
    const updateMutation = useMutation(
        (params) =>
            tpvsService.updateTpvConfiguration(params?.id, params?.params),
        {
            onSuccess: (data) => {
                queryClient.setQueryData(
                    [SUPERADMIN_TPV_CONFIGURATION, { id: data?.tpvConf.id }],
                    data,
                );
                onUpdate(data);
            },
            onError: (error) => {
                cxt.showMessage('E', getErrorMessage(error));
            },
        },
    );
    const createMutation = useMutation(
        (params) => tpvsService.createTpvConfiguration(params?.params),
        {
            onSuccess: (data) => {
                queryClient.setQueryData(
                    [SUPERADMIN_TPV_CONFIGURATION, { id: data?.tpvConf.id }],
                    data,
                );
                onCreate(data);
            },
            onError: (error) => {
                cxt.showMessage('E', getErrorMessage(error));
            },
        },
    );

    const [, loader] = useLoader([
        deleteMutation.status,
        updateMutation.status,
        createMutation.status,
    ]);

    /**
     * Method executed when the user clicks on save or create tpv configuration.
     * @param formData Form data.
     */
    const mutateTpvConfiguration = (formData) => {
        const formatedFormData = {
            name: formData.name,
            bank: formData.bank,
            environment: formData.environment,
            description: formData.description,
        };

        if (constants.tpvConf.banksWithManyCenters.includes(formData.bank)) {
            formatedFormData.scId = String(formData.scId);
        }

        switch (formData.bank) {
            case constants.tpvConf.banks.redsys.name:
                formatedFormData.redsysFuc = formData.redsysFuc;
                formatedFormData.redsysIdTerminal = String(
                    formData.redsysIdTerminal,
                );
                formatedFormData.keyPro = formData.keyPro;
                formatedFormData.keyDev = formData.keyDev;
                break;
            case constants.tpvConf.banks.ccm.name:
                formatedFormData.cecaMerchantId = formData.cecaMerchantId;
                formatedFormData.cecaAcquirerBin = formData.cecaAcquirerBin;
                formatedFormData.cecaTerminalId = formData.cecaTerminalId;
                formatedFormData.keyPro = formData.keyPro;
                formatedFormData.keyDev = formData.keyDev;
                break;
            case constants.tpvConf.banks.stripe.name:
                formatedFormData.stripeSecretKey = formData.stripeSecretKey;
                formatedFormData.stripePublicKey = formData.stripePublicKey;
                break;
            case constants.tpvConf.banks.paycomet.name:
                formatedFormData.paycometApiKey = formData.paycometApiKey;
                formatedFormData.paycometTerminalId =
                    formData.paycometTerminalId;
                break;
            default:
        }

        if (id !== 'create') {
            updateMutation.mutate({ id, params: formatedFormData });
        } else {
            createMutation.mutate({ params: formatedFormData });
        }
    };

    useEffect(() => {
        form.reset({
            name: '',
            bank: constants.tpvConf.banks.redsys.name,
            environment: constants.tpvConf.modes.develop.name,
            description: '',
            redsysFuc: '',
            redsysIdTerminal: '',
            keyPro: '',
            keyDev: '',
        });
    }, [form]);

    return (
        <SttFullDialog
            open
            onClose={onClose}
            title={(() => {
                let result;

                if (tpvConfigurationQuery.isLoading) {
                    result = '';
                } else if (tpvConfigurationQuery.data?.tpvConf.id) {
                    result = tpvConfigurationQuery.data?.tpvConf.name;
                } else {
                    result = cxt.t('createTpvConfiguration');
                }

                return result;
            })()}
        >
            {loader}
            <Paper>
                <Box p={3}>
                    <SttValidatedForm
                        form={form}
                        loadingData={tpvConfigurationQuery.isLoading}
                        fields={[
                            {
                                type: 'textinput',
                                name: 'name',
                                caption: cxt.t(translations.generic.name),
                                md: 4,
                            },
                            {
                                type: 'select',
                                name: 'bank',
                                caption: cxt.t(translations.generic.bank),
                                options: [
                                    {
                                        caption:
                                            constants.tpvConf.banks.redsys.name,
                                        value: constants.tpvConf.banks.redsys
                                            .name,
                                    },
                                    {
                                        caption:
                                            constants.tpvConf.banks.ccm.name,
                                        value: constants.tpvConf.banks.ccm.name,
                                    },
                                    {
                                        caption:
                                            constants.tpvConf.banks.stripe.name,
                                        value: constants.tpvConf.banks.stripe
                                            .name,
                                    },
                                    {
                                        caption:
                                            constants.tpvConf.banks.paycomet
                                                .name,
                                        value: constants.tpvConf.banks.paycomet
                                            .name,
                                    },
                                    {
                                        caption:
                                            constants.tpvConf.banks.zitycard
                                                .name,
                                        value: constants.tpvConf.banks.zitycard
                                            .name,
                                    },
                                ],
                                disableClearable: true,
                                md: 4,
                            },
                            {
                                type: 'select',
                                name: 'environment',
                                caption: cxt.t('Mode'),
                                options: [
                                    {
                                        caption: cxt.t(
                                            constants.tpvConf.modes.develop
                                                .translationCode,
                                        ),
                                        value: constants.tpvConf.modes.develop
                                            .name,
                                    },
                                    {
                                        caption: cxt.t(
                                            constants.tpvConf.modes.production
                                                .translationCode,
                                        ),
                                        value: constants.tpvConf.modes
                                            .production.name,
                                    },
                                ],
                                disableClearable: true,
                                md: 4,
                            },
                            {
                                type: 'textarea',
                                name: 'description',
                                caption: cxt.t(
                                    translations.generic.description,
                                ),
                                rows: 15,
                            },
                            // REDSYS_HMAC256 Inputs (redsysFuc, redsysIdTerminal, keyPro, keyDev)//
                            {
                                type: 'textinput',
                                name: 'redsysFuc',
                                caption: 'Fuc',
                                show:
                                    watchBank ===
                                    constants.tpvConf.banks.redsys.name,
                                md: 3,
                            },
                            {
                                type: 'textinput',
                                name: 'redsysIdTerminal',
                                caption: 'TerminalID',
                                show:
                                    watchBank ===
                                    constants.tpvConf.banks.redsys.name,
                                md: 3,
                            },
                            {
                                type: 'textinput',
                                name: 'keyPro',
                                caption: 'encryptPro',
                                show:
                                    watchBank ===
                                    constants.tpvConf.banks.redsys.name,
                                md: 3,
                            },
                            {
                                type: 'textinput',
                                name: 'keyDev',
                                caption: 'encryptDev',
                                show:
                                    watchBank ===
                                    constants.tpvConf.banks.redsys.name,
                                md: 3,
                            },
                            // CCM Inputs (cecaMerchantId, cecaAcquirerBin, cecaTerminalId, keyPro, keyDev)//
                            {
                                type: 'textinput',
                                name: 'cecaMerchantId',
                                caption: 'cecaMerchantId',
                                show:
                                    watchBank ===
                                    constants.tpvConf.banks.ccm.name,
                                md: 2,
                            },
                            {
                                type: 'textinput',
                                name: 'cecaAcquirerBin',
                                caption: 'cecaAcquirerBin',
                                show:
                                    watchBank ===
                                    constants.tpvConf.banks.ccm.name,
                                md: 2,
                            },
                            {
                                type: 'textinput',
                                name: 'cecaTerminalId',
                                caption: 'cecaTerminalId',
                                show:
                                    watchBank ===
                                    constants.tpvConf.banks.ccm.name,
                                md: 2,
                            },
                            {
                                type: 'textinput',
                                name: 'keyPro',
                                caption: 'keyPro',
                                show:
                                    watchBank ===
                                    constants.tpvConf.banks.ccm.name,
                                md: 3,
                            },
                            {
                                type: 'textinput',
                                name: 'keyDev',
                                caption: 'keyDev',
                                show:
                                    watchBank ===
                                    constants.tpvConf.banks.ccm.name,
                                md: 3,
                            },
                            // STRIPE Inputs (stripeSecretKey, stripePublicKey)//
                            {
                                type: 'textinput',
                                name: 'stripeSecretKey',
                                caption: 'stripeSecretKey',
                                show:
                                    watchBank ===
                                    constants.tpvConf.banks.stripe.name,
                                md: 6,
                            },
                            {
                                type: 'textinput',
                                name: 'stripePublicKey',
                                caption: 'stripePublicKey',
                                show:
                                    watchBank ===
                                    constants.tpvConf.banks.stripe.name,
                                md: 6,
                            },
                            // PAYCOMET Inputs (paycometApiKey)//
                            {
                                type: 'textinput',
                                name: 'paycometApiKey',
                                caption: 'paycometApiKey',
                                show:
                                    watchBank ===
                                    constants.tpvConf.banks.paycomet.name,
                                md: 6,
                            },
                            {
                                type: 'textinput',
                                name: 'paycometTerminalId',
                                caption: 'paycometTerminalId',
                                show:
                                    watchBank ===
                                    constants.tpvConf.banks.paycomet.name,
                                md: 6,
                            },
                            {
                                type: 'textinput',
                                name: 'scId',
                                caption: 'sportcenter id',
                                show: constants.tpvConf.banksWithManyCenters.includes(
                                    watchBank,
                                ),
                                md: 6,
                            },
                        ]}
                        buttons={[
                            {
                                show: !tpvConfigurationQuery.data?.tpvConf
                                    ?.trash,
                                type: 'save',
                                onClick: form.handleSubmit((formData) =>
                                    mutateTpvConfiguration(formData),
                                ),
                            },
                            {
                                show:
                                    tpvConfigurationQuery.data?.tpvConf.id &&
                                    !tpvConfigurationQuery.data?.tpvConf.trash,
                                type: 'delete',
                                onClick: () => deleteMutation.mutate({ id }),
                            },
                        ]}
                    />
                </Box>
            </Paper>
        </SttFullDialog>
    );
}
