import React, { useState, useEffect, useContext } from 'react';
import { Container, Box, Paper, Tabs, Tab, Grid } from '@material-ui/core';
import moment from 'moment';
import {
    AppContext,
    SttFormText,
    SttTabs,
    SttFormDate,
    SttTable,
    SttFormPeriod,
    SttChipUser,
    SttFormSelect,
    SttButtonFilter,
} from '../../../sporttia/all';
import BookingDialog from '../../../components/dialogs/bookingDialog/BookingDialog';
import DateBuilder from '../../../lib/DateBuilder';

export default function MshipBookings({ mship }) {
    const cxt = useContext(AppContext);
    const [bookings, setBookings] = useState({});
    const [filter, setFilter] = useState({
        ini: moment().format('YYYY-MM-DDTHH:mm:ss'),
        end: moment().format('YYYY-MM-DDTHH:mm:ss'),
        source: null,
    });
    const [dialogBookingId, setDialogBookingId] = useState();

    /**
     * Get bookings
     */
    const loadBookings = (p = { page: 1, rows: 20 }) => {
        cxt.api('GET', `/mships/${mship.id}/bookings`, {
            params: {
                page: p.page,
                rows: p.rows,
                ini: moment(filter.ini).format('YYYY-MM-DD'),
                end: moment(filter.end).format('YYYY-MM-DD'),
                source: filter.source,
            },
            success: (r) => {
                setBookings(r);
            },
        });
    };

    /**
     * Handle form
     */
    const handleForm = ({ name, value }) => {
        setFilter({ ...filter, [name]: value });
    };

    // -----| Render |-----
    return (
        <>
            <Box mb={3}>
                <Grid container spacing={3}>
                    <SttFormPeriod
                        grid
                        xs={12}
                        sm={12}
                        md={8}
                        caption={cxt.t('Period')}
                        nameIni="ini"
                        nameEnd="end"
                        defValIni={filter.ini}
                        defValEnd={filter.end}
                        onChange={handleForm}
                    />

                    <SttFormSelect
                        grid
                        xs={12}
                        sm={6}
                        md={2}
                        caption={cxt.t('Source')}
                        name="source"
                        defVal={filter.source}
                        onChange={handleForm}
                        options={[
                            { caption: cxt.t('Sportcenter'), value: 'SC' },
                            { caption: cxt.t('User'), value: 'USER' },
                        ]}
                    />

                    <Grid item xs={12} sm={6} md={2}>
                        <SttButtonFilter
                            onClick={() => loadBookings()}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Box>

            <SttTable
                data={bookings}
                onClickRow={(booking) => {
                    setDialogBookingId(booking.id);
                }}
                columns={[
                    {
                        field: 'created',
                        title: cxt.t('Created'),
                        width: 140,
                        value: (r) => (
                            <>
                                <SttChipUser user={r.user} />
                                <Box mt={1}>
                                    {new DateBuilder(
                                        r?.created,
                                        cxt?.sc?.timezone?.name,
                                        cxt?.lang.key,
                                    ).dmyhm()}
                                </Box>
                            </>
                        ),
                    },
                    {
                        field: 'created',
                        title: cxt.t('Modified'),
                        value: (r) =>
                            new DateBuilder(
                                r?.created,
                                cxt?.sc?.timezone?.name,
                                cxt?.lang.key,
                            ).dmy(),
                    },
                    {
                        field: 'user.name',
                        title: cxt.t('User'),
                        value: (r) => r.user.name,
                    },
                    { field: 'ini', title: cxt.t('Date'), type: 'dmy' },
                    {
                        field: 'time',
                        title: cxt.t('Time'),
                        value: (r) => ({ ini: r.ini, end: r.end }),
                        type: 'periodTime',
                    },
                    {
                        field: 'facility',
                        title: cxt.t('Facility'),
                        value: (r) => r.facility.name,
                    },
                    { field: 'price', title: cxt.t('Total'), type: 'price' },
                ]}
                onFetch={loadBookings}
            />

            <BookingDialog
                idBooking={dialogBookingId}
                onClose={() => setDialogBookingId(null)}
            />
        </>
    );
}
