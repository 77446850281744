import React, { useEffect, useContext } from 'react';
import { Box, Grid, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { OpenInNewOutlined } from '@material-ui/icons';
import { useCrud } from '../../../lib/hooks';
import {
    AppContext,
    SttButton,
    SttButtonCancel,
    SttButtonDelete,
    SttButtonRecover,
    SttButtonSave,
    SttFormCheck,
    SttFormDate,
    SttFormSelect,
    SttFormText,
    SttFormTextarea,
    SttFormTime,
    SttSelectSport,
} from '../../../sporttia/all';
import { getPath } from '../../Pages';

export default function EventDetails({ item, onSave, onDelete }) {
    const cxt = useContext(AppContext);
    const history = useHistory();

    const [event, setEvent, setProperty, , Post, Put, Delete] = useCrud(item);

    useEffect(() => {
        if (item) {
            setEvent(item);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    function saveEvent() {
        const params = {
            name: event.name,
            description: event.description,
            kind: event.kind,
            difficulty: event.difficulty,
            status: event.status,
            maxParticipants: event.maxParticipants,
            onlyCertified: event.onlyCertified,
            showParticipants: event.showParticipants,
            signupOnline: event.signupOnline,
            maxPlayers: event.maxPlayers,
            type: event.type,
            idSport: event.sport.id,
            idPoster: event?.poster?.id || null,
            inscIni: event.inscIni,
            inscEnd: event.inscEnd,
            ini: event.ini,
            end: event.end,
            paymentForms: event.paymentForms,
        };

        if (event.id) {
            Put(`/events/${event.id}`, params).then((response) => {
                if (response.event) {
                    if (onSave && onSave.constructor === Function) {
                        onSave({
                            ...response.event,
                            categories: event?.categories,
                        });
                    }
                }
            });
        } else {
            Post(`/events`, params).then((response) => {
                if (response.event) {
                    if (onSave && onSave.constructor === Function) {
                        onSave(response.event);
                    }
                }
            });
        }
    }

    function deleteEvent() {
        Delete(`/events/${event.id}`).then((response) => {
            if (response.event) {
                if (onDelete && onDelete.constructor === Function) {
                    onDelete(response.event);
                }
            }
        });
    }

    function recoverEvent() {
        Put(`/events/${event.id}/recover`).then((response) => {
            if (response.event) {
                if (onSave && onSave.constructor === Function) {
                    onSave(response.event);
                }
            }
        });
    }

    function setDate({ name, value }) {
        const finalDate = `${moment(value).format('YYYY-MM-DD')} ${moment(
            event[name],
        ).format('HH:mm:ss')}`;

        setProperty({
            name,
            value: moment(finalDate).isValid()
                ? moment(finalDate)
                : moment().format('YYYY-MM-DD 23:30:00'),
        });
    }

    function setTime({ name, value }) {
        setProperty({
            name,
            value: moment(event[name]).format(`YYYY-MM-DD ${value}:00`),
        });
    }

    function togglePaymentForm(type) {
        const found =
            event.paymentForms?.find((pf) => pf === type) !== undefined;

        setProperty({
            name: 'paymentForms',
            value: found
                ? event.paymentForms?.filter((pf) => pf !== type)
                : [...event.paymentForms, type],
        });
    }

    if (!event) {
        return null;
    }

    const checkRowStyle = { paddingTop: 0, paddingBottom: 0 };

    return (
        <>
            <Box my={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={4}>
                        <SttFormText
                            caption={cxt.t('Name')}
                            name="name"
                            defVal={event.name}
                            onChange={setProperty}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <SttFormSelect
                            caption={cxt.t('Type')}
                            name="kind"
                            defVal={event.kind}
                            options={cxt.constants?.eventKinds}
                            onChange={setProperty}
                            cleanable
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <SttFormSelect
                            caption={cxt.t('Inscription')}
                            name="type"
                            defVal={event.type}
                            options={[
                                {
                                    caption: cxt.t('ET.NORMAL'),
                                    value: 'NORMAL',
                                },
                                {
                                    caption: cxt.t('ET.TOURNAMENT'),
                                    value: 'TOURNAMENT',
                                },
                            ]}
                            disabled={!!event.id}
                            onChange={setProperty}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SttFormTextarea
                            caption={cxt.t('Description')}
                            name="description"
                            defVal={event.description}
                            onChange={setProperty}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <SttSelectSport
                            caption={cxt.t('Sport')}
                            name="sport"
                            defVal={event.sport?.id}
                            onChange={({ name, value }) =>
                                setProperty({ name, value: { id: value } })
                            }
                        />
                        {/*
						<SttSportSelector
							name={"sport"}
							fullWidth
							defVal={activity.sport.name}
							onChange={sport => setSport(sport)}
						/>
						*/}
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <SttFormSelect
                            caption={cxt.t('Difficulty')}
                            name="difficulty"
                            defVal={event.difficulty}
                            onChange={setProperty}
                            cleanable
                            options={cxt.constants.eventDifficulties}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <SttFormSelect
                            caption={cxt.t('Status')}
                            name="status"
                            defVal={event.status}
                            options={[
                                {
                                    caption: cxt.t('Active'),
                                    value: 'ACTIVE',
                                },
                                {
                                    caption: cxt.t('Inactive'),
                                    value: 'NOT_ACTIVE',
                                },
                            ]}
                            onChange={setProperty}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        {event.type === 'TOURNAMENT' ? (
                            <SttFormText
                                caption={cxt.t('MaxPlayersTeam')}
                                name="maxPlayers"
                                defVal={event.maxPlayers}
                                onChange={setProperty}
                            />
                        ) : (
                            <SttFormText
                                caption={cxt.t('sc.event.main.maxUsers')}
                                name="maxParticipants"
                                defVal={event.maxParticipants}
                                onChange={setProperty}
                            />
                        )}
                    </Grid>

                    {/* INSCRIPTION DATE START-DATE, TIME -- END-DATE, TIME */}
                    <Grid item xs={12} sm={6} md={3}>
                        <SttFormDate
                            caption={`${cxt.t('Inscription')} ${cxt
                                .t('From')
                                .toLowerCase()}`}
                            name="inscIni"
                            defVal={event.inscIni}
                            onChange={setDate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <SttFormTime
                            defVal={moment(event.inscIni).format('HH:mm')}
                            name="inscIni"
                            onChange={setTime}
                            interval={15}
                            min="00:00:00"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <SttFormDate
                            caption={cxt.t('Until')}
                            name="inscEnd"
                            defVal={event.inscEnd}
                            onChange={setDate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <SttFormTime
                            defVal={moment(event.inscEnd).format('HH:mm')}
                            name="inscEnd"
                            onChange={setTime}
                            interval={15}
                            min="00:00:00"
                        />
                    </Grid>

                    {/* INSCRIPTION DATE START-DATE, TIME -- END-DATE, TIME */}
                    <Grid item xs={12} sm={6} md={3}>
                        <SttFormDate
                            caption={cxt.t('sc.event.main.dateIniEvent')}
                            name="ini"
                            defVal={event.ini}
                            onChange={setDate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <SttFormTime
                            name="ini"
                            defVal={moment(event.ini).format('HH:mm')}
                            onChange={setTime}
                            interval={15}
                            min="00:00:00"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <SttFormDate
                            caption={cxt.t('Until')}
                            name="end"
                            defVal={event.end}
                            onChange={setDate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <SttFormTime
                            name="end"
                            defVal={moment(event.end).format('HH:mm')}
                            onChange={setTime}
                            interval={15}
                            min="00:00:00"
                        />
                    </Grid>

                    {/* CHECK BOXES */}

                    <Grid item xs={12} style={checkRowStyle}>
                        <SttFormCheck
                            caption={cxt.t('sc.event.main.certifiedUsers')}
                            name="onlyCertified"
                            checked={event.onlyCertified}
                            onChange={setProperty}
                        />
                    </Grid>
                    <Grid item xs={12} style={checkRowStyle}>
                        <SttFormCheck
                            caption={cxt.t('sc.event.main.showLastUsers')}
                            name="showParticipants"
                            checked={event.showParticipants}
                            onChange={setProperty}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} style={checkRowStyle}>
                        <SttFormCheck
                            caption={cxt.t('page.sc.events.signupOnline')}
                            name="signupOnline"
                            checked={event.signupOnline}
                            onChange={setProperty}
                        />
                    </Grid>
                    <Grid item xs={12} style={checkRowStyle}>
                        <SttFormCheck
                            caption={cxt.t('page.sc.events.later')}
                            checked={
                                event.paymentForms?.find((pf) => pf === '') !==
                                undefined
                            }
                            onChange={() => togglePaymentForm('')}
                            disabled={!event.signupOnline}
                        />
                    </Grid>
                    <Grid item xs={12} style={checkRowStyle}>
                        <SttFormCheck
                            caption={cxt.t('page.sc.events.purse')}
                            checked={
                                event.paymentForms?.find(
                                    (pf) => pf === 'PURSE',
                                ) !== undefined
                            }
                            onChange={() => togglePaymentForm('PURSE')}
                            disabled={!event.signupOnline}
                        />
                    </Grid>
                    <Grid item xs={12} style={checkRowStyle}>
                        <SttFormCheck
                            caption={cxt.t('page.sc.events.tpv')}
                            checked={
                                event.paymentForms?.find(
                                    (pf) => pf === 'TPV',
                                ) !== undefined
                            }
                            onChange={() => togglePaymentForm('TPV')}
                            disabled={!event.signupOnline}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Grid mt={3} container spacing={3}>
                {!event.trash && (
                    <Grid item xs={12} sm={6} md={3}>
                        <SttButtonSave fullWidth onClick={saveEvent} />
                    </Grid>
                )}

                {!event.id && (
                    <Grid item xs={12} sm={6} md={3}>
                        <SttButtonCancel fullWidth onClick={history.goBack} />
                    </Grid>
                )}

                {event.id && !event.trash && (
                    <Grid item xs={12} sm={6} md={3}>
                        <SttButtonDelete fullWidth onClick={deleteEvent} />
                    </Grid>
                )}

                {event.id && !event.trash && (
                    <Grid item xs={12} sm={6} md={3}>
                        <SttButton
                            fullWidth
                            caption={cxt.t('GoToEvent')}
                            icon={<OpenInNewOutlined fontSize="small" />}
                            onClick={() =>
                                history.push(
                                    getPath('eventPublic', { id: item.id }),
                                )
                            }
                        />
                    </Grid>
                )}

                {event.trash && (
                    <Grid item xs={12} sm={6} md={3}>
                        <SttButtonRecover fullWidth onClick={recoverEvent} />
                    </Grid>
                )}
            </Grid>
        </>
    );
}
