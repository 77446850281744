import React, { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { AppContext } from '../../../../sporttia/AppContext';
import { useLoader } from '../../../../lib/hooks';
import { SttFullDialog } from '../../../../sporttia/dialogs/SttFullDialog';
import { SUPERADMIN_TRANSLATION } from '../../../../lib/queryKeys';
import useTranslationsService from '../../../../services/TranslationsService';
import SttValidatedForm from '../../../../sporttia/SttValidatedForm';

/**
 * Translation view, contains translation form.
 * @param id Translation ID.
 * @param onClose Function executed when closing the full dialog.
 * @param onCreate Action taken when creating the translation.
 * @param onUpdate Action taken when updating the translation.
 * @param onDelete Method executed when removing translation.
 * @returns {JSX.Element}
 */
export default function AdminTranslation({
    id = null,
    onClose,
    onCreate,
    onUpdate,
    onDelete,
}) {
    const cxt = useContext(AppContext);
    const queryClient = useQueryClient();
    const translationsService = useTranslationsService();
    const form = useForm();
    const [translationId, setTranslationId] = useState(id);
    const translationQuery = useQuery(
        [SUPERADMIN_TRANSLATION, { id: translationId }],
        () => translationsService.get(translationId),
        { enabled: !!translationId && translationId !== 'create' },
    );
    const deleteMutation = useMutation(
        (params) => translationsService.remove(params?.id),
        {
            onSuccess: onDelete,
        },
    );
    const updateMutation = useMutation(
        (params) => translationsService.update(params?.id, params?.params),
        {
            onSuccess: (data) => {
                queryClient.setQueryData(
                    [SUPERADMIN_TRANSLATION, { id: data?.translation?.id }],
                    data,
                );
                onUpdate(data);
            },
        },
    );
    const createMutation = useMutation(
        (params) => translationsService.create(params?.params),
        {
            onSuccess: (data) => {
                queryClient.setQueryData(
                    [SUPERADMIN_TRANSLATION, { id: data?.translation?.id }],
                    data,
                );
                onCreate(data);
            },
        },
    );
    const [, loader] = useLoader([
        deleteMutation.status,
        updateMutation.status,
        createMutation.status,
    ]);

    /**
     * Method executed when the user clicks on save or create translation.
     * @param formData Form data.
     */
    const mutateTranslation = (formData) => {
        if (translationId !== 'create') {
            updateMutation.mutate({ id: translationId, params: formData });
        } else {
            createMutation.mutate({ params: formData });
        }
    };

    /**
     * When the "translation" data is returned by the API, it is set in the form.
     */
    useEffect(() => {
        if (translationQuery?.status === 'success') {
            form.reset({
                code: translationQuery?.data?.translation?.code,
                mobile: translationQuery?.data?.translation?.mobile,
                web: translationQuery?.data?.translation?.web,
                zero: translationQuery?.data?.translation?.zero,
                es: translationQuery?.data?.translation?.es,
                ca: translationQuery?.data?.translation?.ca,
                cl: translationQuery?.data?.translation?.cl,
                en: translationQuery?.data?.translation?.en,
                fr: translationQuery?.data?.translation?.fr,
                de: translationQuery?.data?.translation?.de,
            });
        } else {
            form.reset({
                code: '',
                mobile: false,
                web: false,
                zero: false,
                es: '',
                ca: '',
                cl: '',
                en: '',
                fr: '',
                de: '',
            });
        }
    }, [translationQuery?.data?.translation]);

    /**
     * Update translation id if id prop was changed and reset form values.
     */
    useEffect(() => {
        setTranslationId(id);
        form.reset();
    }, [id]);

    return (
        <SttFullDialog
            open={translationId !== null}
            onClose={onClose}
            title={
                translationQuery?.isLoading
                    ? ''
                    : translationQuery?.data?.translation?.id
                    ? translationQuery?.data?.translation?.code
                    : cxt.t('CreateTranslation')
            }
        >
            {loader}
            <Paper>
                <Box p={3}>
                    <SttValidatedForm
                        form={form}
                        loadingData={translationQuery.status === 'loading'}
                        fields={[
                            {
                                type: 'textinput',
                                name: 'code',
                                caption: cxt.t('Code'),
                                md: 6,
                            },
                            {
                                type: 'select',
                                name: 'mobile',
                                caption: 'APP',
                                options: [
                                    { caption: cxt.t('Active'), value: true },
                                    {
                                        caption: cxt.t('Inactive'),
                                        value: false,
                                    },
                                ],
                                disableClearable: true,
                                md: 2,
                            },
                            {
                                type: 'select',
                                name: 'web',
                                caption: 'WEB',
                                options: [
                                    { caption: cxt.t('Active'), value: true },
                                    {
                                        caption: cxt.t('Inactive'),
                                        value: false,
                                    },
                                ],
                                disableClearable: true,
                                md: 2,
                            },
                            {
                                type: 'select',
                                name: 'zero',
                                caption: 'ZERO',
                                options: [
                                    { caption: cxt.t('Active'), value: true },
                                    {
                                        caption: cxt.t('Inactive'),
                                        value: false,
                                    },
                                ],
                                disableClearable: true,
                                md: 2,
                            },
                            {
                                type: 'textarea',
                                name: 'es',
                                caption: 'ES',
                                rows: 3,
                            },
                            {
                                type: 'textinput',
                                name: 'ca',
                                caption: 'CA',
                                rows: 3,
                            },
                            {
                                type: 'textinput',
                                name: 'cl',
                                caption: 'CL',
                                rows: 3,
                            },
                            {
                                type: 'textinput',
                                name: 'en',
                                caption: 'EN',
                                rows: 3,
                            },
                            {
                                type: 'textinput',
                                name: 'fr',
                                caption: 'FR',
                                rows: 3,
                            },
                            {
                                type: 'textinput',
                                name: 'de',
                                caption: 'DE',
                                rows: 3,
                            },
                        ]}
                        buttons={[
                            {
                                show: !translationQuery?.data?.translation
                                    ?.trash,
                                type: 'save',
                                onClick: form.handleSubmit((formData) =>
                                    mutateTranslation(formData),
                                ),
                            },
                            {
                                show:
                                    translationQuery?.data?.translation?.id &&
                                    !translationQuery?.data?.translation?.trash,
                                type: 'delete',
                                onClick: () =>
                                    deleteMutation.mutate({
                                        id: translationId,
                                    }),
                            },
                        ]}
                    />
                </Box>
            </Paper>
        </SttFullDialog>
    );
}
