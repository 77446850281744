import React, { useState, useContext, useEffect } from 'react';
import {
    Grid,
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Divider,
    Box,
} from '@material-ui/core';
import { AppContext, SttFormSearch, SttPagination } from '../all';
import { checkOffTag, fullName, formatPriceByLocale } from '../../lib/utils';

export function SttSelectUser({
    grid = false,
    caption,
    name,
    user,
    onChange,
    onSelect,
    controlDisabled,
    returnMship = false,
    ...rest
}) {
    const cxt = useContext(AppContext);
    const [dialogOpened, setDialogOpened] = useState(false);
    const [mships, setMships] = useState();
    const [keywordSearch, setKewordSearch] = useState('');
    const [page, setPage] = useState(1);

    /**
     * Load mships
     */
    const loadMships = (p = { page: 1 }) => {
        const currPage = p.page !== undefined ? p.page : page;
        cxt.api('GET', `/scs/${cxt.sc.id}/mships`, {
            params: {
                page: currPage,
                rows: 8,
                keyword: keywordSearch,
            },
            success: (r) => {
                setMships(r);
                setPage(currPage);
            },
        });
    };

    useEffect(() => {
        loadMships();
    }, []);

    /**
     * Click search
     */
    const handleClickSearch = () => {
        if (!mships) {
        }
        setDialogOpened(true);
    };

    /**
     * Select
     */
    const onSelectOnList = (mship) => {
        setDialogOpened(false);
        if (onSelect && mship.user && !returnMship) {
            onSelect(mship.user);
        } else if (onSelect && mship.user && returnMship) {
            onSelect(mship);
        }
    };

    /* useEffect(() => {

		if (!user) {
			onSelect({name:''})
		}

	}, [user]) */

    // -----| Render |-----
    const content = (
        <>
            <SttFormSearch
                disabled
                caption={caption}
                defVal={fullName(user) || ''}
                onClean={controlDisabled ? null : () => onSelect(null)}
                onChange={onChange}
                onClickSearch={controlDisabled ? null : handleClickSearch}
            />

            <Dialog
                fullWidth
                onClose={() => setDialogOpened(false)}
                open={dialogOpened}
            >
                <DialogTitle>
                    <SttFormSearch
                        defVal={keywordSearch}
                        onClickSearch={() => loadMships({ page: 1 })}
                        onChange={({ name, value }) => setKewordSearch(value)}
                    />
                </DialogTitle>

                <List>
                    {mships &&
                        mships.rows.map((mship) => (
                            <div key={mship.id}>
                                <Divider />
                                <ListItem
                                    button
                                    onClick={() => onSelectOnList(mship)}
                                >
                                    <ListItemAvatar>
                                        <Avatar
                                            alt={mship.name}
                                            src={
                                                mship.img ? mship.img.url : null
                                            }
                                        />
                                    </ListItemAvatar>

                                    <ListItemText
                                        primary={mship.fullName}
                                        secondary={
                                            <>
                                                {mship.user &&
                                                checkOffTag(
                                                    mship.user.login,
                                                ) ? (
                                                    <i
                                                        style={{
                                                            color: '#5b5b5b',
                                                        }}
                                                    >
                                                        {cxt.t('UserCancelled')}
                                                    </i>
                                                ) : mship.amount ? (
                                                    <>
                                                        <span>
                                                            {cxt.t('Purse')}
                                                            :&nbsp;
                                                        </span>
                                                        <b>
                                                            {formatPriceByLocale(
                                                                mship.amount,
                                                                mship
                                                                    ?.amountShape
                                                                    ?.currency,
                                                                mship
                                                                    ?.amountShape
                                                                    ?.locale,
                                                            )}
                                                        </b>
                                                    </>
                                                ) : null}
                                            </>
                                        }
                                    />
                                </ListItem>
                            </div>
                        ))}

                    {mships && mships.count == 0 && (
                        <ListItem>
                            <ListItemText primary={cxt.t('NoResults')} />
                        </ListItem>
                    )}

                    <ListItem>
                        {mships && (
                            <Box m="auto">
                                <SttPagination
                                    page={page}
                                    pages={mships.pages}
                                    count={mships.count}
                                    onChangePage={(page) =>
                                        loadMships({ page })
                                    }
                                />
                            </Box>
                        )}
                    </ListItem>
                </List>
            </Dialog>
        </>
    );
    return grid ? (
        <Grid item {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
