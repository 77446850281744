import React, { useState, useEffect, useContext } from 'react';
import { Box, Divider } from '@material-ui/core';
import {
    AppContext,
    SttBookingList,
    SttButtonCollect,
    SttDialog,
    SttSelectPForm,
} from '../../../sporttia/all';
import { formatPriceByLocale } from '../../../lib/utils';

export default function ModalCollectBatch({
    open,
    bookings,
    onCollect,
    onClose,
}) {
    const cxt = useContext(AppContext);
    const [paymentForm, setPaymentForm] = useState('');

    return (
        <SttDialog
            open={open}
            onClose={onClose}
            title={cxt.t('bookings')}
            buttons={[
                {
                    type: 'component',
                    component: (
                        <SttButtonCollect
                            onClick={() => onCollect(paymentForm)}
                        />
                    ),
                },
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
            content={
                <div>
                    <SttBookingList showTotals bookings={bookings} />
                    <Box pb={2}>
                        <Divider />
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        pr={2}
                        style={{ fontSize: 16 }}
                    >
                        <span style={{ marginRight: 8 }}>
                            {cxt.t('Total')}:
                        </span>
                        <span>
                            {formatPriceByLocale(
                                bookings.reduce(
                                    (result, booking) => result + booking.price,
                                    0,
                                ),
                                bookings[0]?.totalBillShape?.currency,
                            )}
                        </span>
                    </Box>
                    <Box width="50%">
                        <SttSelectPForm
                            defVal={paymentForm}
                            activePFs={[
                                '',
                                'PURSE',
                                'CASH',
                                'BANK',
                                'DATAFONO',
                                'RECEIPT',
                                'FREE',
                            ]}
                            onChange={({ name, value }) =>
                                setPaymentForm(value)
                            }
                        />
                    </Box>
                </div>
            }
        />
    );
}
