import React, { useState, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';
import { AppContext } from '../all';
import SttTimetablePiece from './SttTimetablePiece';

/**
 * Timetable column
 * ================
 */
export default function SttTimetableColumn({
    title,
    column,
    onOpenPiece,
    width,
}) {
    const cxt = useContext(AppContext);

    return (
        <Box
            className={`column ${
                column.facility && column.facility.status == 'ONLY_SC'
                    ? 'onlySC'
                    : ''
            }`}
            style={{ width }}
        >
            {column.pieces.map((piece, idx) => (
                <SttTimetablePiece
                    key={idx}
                    column={column}
                    piece={piece}
                    onOpenPiece={onOpenPiece}
                />
            ))}

            {column.pieces.length == 0 && (
                <Box className="na">{cxt.t('timetable.noAvailable')}</Box>
            )}
        </Box>
    );
}
