import React, { useState, useEffect, useContext, Fragment } from 'react';
import {
    Box,
    List,
    Avatar,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Divider,
    Typography,
    Button,
} from '@material-ui/core';
import { AppContext, SttLabelDate } from '../../../sporttia/all';
import MessageCommentBox from './MessageCommentBox';

export default function MessageCommentList({ message }) {
    const cxt = useContext(AppContext);
    const [comments, setComments] = useState();
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(5);
    const [messageValue, setMessageValue] = useState({});

    /**
     * Load comments
     */
    const loadComments = () => {
        cxt.api('GET', `/messages/${messageValue.id}/comments`, {
            params: {
                page,
                rows,
            },
            success: (r) => {
                setComments(r);
            },
        });
    };

    /**
     * Send comment
     */
    const sendComment = (text) => {
        cxt.api('POST', `/messages/${messageValue.id}/comments`, {
            params: {
                text,
            },
            success: (r) => {
                loadComments();
            },
        });
    };

    const generatePrivateMessage = () => {
        cxt.api('POST', `/messages/${messageValue.id}/private`, {
            success: (r) => {
                setMessageValue(r.message);
            },
        });
    };

    /**
     * Message changes
     */
    useEffect(() => {
        if (message && message.id) setMessageValue(message);
    }, [message]);

    /**
     * MessageValue changes
     */
    useEffect(() => {
        if (messageValue && messageValue.id) loadComments();
    }, [messageValue]);

    /**
     * Rows change
     */
    useEffect(() => {
        if (rows && messageValue && messageValue.id) loadComments();
    }, [rows]);

    // -----| Render |-----
    return (
        <Box>
            <List>
                {comments && comments.count > comments.rows.length && (
                    <Button fullWidth onClick={() => setRows(rows + 8)}>
                        {cxt.t('ShowMoreResults')}
                    </Button>
                )}

                {comments &&
                    comments.rows.map((comment, idxComm) => (
                        <Fragment key={idxComm}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar
                                        alt={comment.creator.name}
                                        src={cxt.getResource(
                                            `/images/${comment?.img?.id}.jpg`,
                                        )}
                                    />
                                </ListItemAvatar>

                                <ListItemText
                                    primary={comment.creator.name}
                                    secondary={
                                        <Typography
                                            variant="body2"
                                            color="textPrimary"
                                        >
                                            <SttLabelDate
                                                date={comment.created}
                                            />
                                        </Typography>
                                    }
                                />
                            </ListItem>

                            <Box ml={9} mb={2}>
                                <Typography variant="body1">
                                    {comment.text}
                                </Typography>
                            </Box>

                            {idxComm != comments.rows.length - 1 && (
                                <Divider variant="inset" component="li" />
                            )}
                        </Fragment>
                    ))}
            </List>

            <MessageCommentBox
                onSendComment={sendComment}
                onSendPrivateMessage={generatePrivateMessage}
                senderName={
                    messageValue &&
                    messageValue.sender &&
                    messageValue.sender.fullName
                }
            />
        </Box>
    );
}
