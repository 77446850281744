import Configuration from './sc/configuration/Configuration';
import Roles from './sc/roles/Roles';
import Mships from './sc/mships/Mships';
import Facilities from './sc/facilities/Facilities';
import FacilitySets from './sc/facilitySets/FacilitySets';
import FacilitySet from './sc/facilitySets/FacilitySet';
import Report from './sc/Report';
import Rates from './sc/rates/Rates';
import Bookings from './sc/bookings/Bookings';
import BusySchedules from './sc/busySchedules/BusySchedules';
import BusySchedule from './sc/busySchedules/BusySchedule';
import FreeSchedules from './sc/freeSchedules/FreeSchedules';
import FreeSchedule from './sc/freeSchedules/FreeSchedule';
import Devices from './sc/devices/Devices';
import Device from './sc/devices/Device';
import Payments from './sc/payments/Payments';
import Groups from './sc/groups/Groups';
import Activities from './sc/activities/Activities';
import Bonos from './sc/bonos/Bonos';
import Bono from './sc/bonos/Bono';
import Files from './sc/Files';
import Remittences from './sc/remittences/Remittences';
import Remittence from './sc/remittences/Remittence';
import GenerateRemittence from './sc/remittences/GenerateRemittence';
import FileCSV from './sc/filesCSV/FileCSV';
import Teachers from './sc/teachers/Teachers';
import Teacher from './sc/teachers/Teacher';
import BookingSchedules from './sc/bookingSchedules/BookingSchedules';
import BookingSchedule from './sc/bookingSchedules/BookingSchedule';
import Restrictions from './sc/restrictions/Restrictions';
import Restriction from './sc/restrictions/Restriction';
import SCReports from './sc/reports/SCReports';
import SCMessages from './sc/messages/SCMessages';
import SCTickets from './sc/tickets/SCTickets';
import Students from './sc/students/Students';
import Events from './sc/events/Events';
import NoSubscription from './sc/noSubscription/NoSubscription';
import Golf from './sc/golf/Golf';
import Login from './public/Login';
import Register from './public/Register';
import Page404 from './Page404';
import AcceptConditions from './public/AcceptConditions';
import ConfirmEmail from './public/ConfirmEmail';
import RecoverPassword from './public/RecoverPassword';
import Privacy from './public/Privacy';
import Account from './public/Account';
import Apis from './public/apis/Apis';
import UnderConstruction from './public/UnderConstruction';
import Timetable from './public/timetable/Timetable';
import Booked from './public/booked/Booked';
import TPVResult from './public/tpv/TPVResult';
import Booking from './public/booking/Booking';
import SCProfile from './public/scProfile/SCProfile';
import AbonoList from './public/buyAbono/AbonoList';
import BuyAbono from './public/buyAbono/BuyAbono';
import BonoList from './public/buyBono/BonoList';
import BuyBono from './public/buyBono/BuyBono';
import ActivityPublic from './public/activity/Activity';
import EventPublic from './public/event/Event';
import Widget from './public/widget/Widget';
import AdminApis from './admin/hart/apis/AdminApis';
import AdminSCs from './admin/customers/scs/AdminSCs';
import AdminApiLogs from './admin/hart/apiLogs/AdminApiLogs';
import AdminTests from './admin/hart/tests/AdminTests';
import Agenda from './user/agenda/Agenda';
import UserAbono from './user/abonos/UserAbono';
import UserAbonos from './user/abonos/UserAbonos';
import UserBonos from './user/bonos/UserBonos';
import RechargePurse from './user/rechargePurse/RechargePurse';
import AdminTranslations from './admin/tables/translations/AdminTranslations';
import UserKeys from './user/keys/UserKeys';
import AdminReports from './admin/tables/reports/AdminReports';
import Group from './sc/groups/Group';
import UserConfiguration from './user/configuration/UserConfiguration';
import Subscription from './sc/subscription/Subscription';
import UserPayments from './user/payments/UserPayments';
import Home from './common/Home';
import Search from './public/search/Search';
import Contact from './public/Contact';
import UserMessages from './user/messages/UserMessages';
import AdminCities from './admin/tables/cities/AdminCities';
import AdminApiTriggersExecutions from './admin/hart/triggersExecutions/AdminApiTriggersExecutions';
import SCDocuments from './sc/documents/SCDocuments';
import SCContact from './public/scProfile/SCContact';
import UserPupils from './user/pupils/UserPupils';
import AdminCustomers from './admin/customers/customers/AdminCustomers';
import AdminSttZeroAccounts from './admin/customers/sttZeroAccounts/AdminSttZeroAccounts';
import AdminPlaytomicCenters from './admin/customers/playtomicCenters/AdminPlaytomicCenters';
import AdminPayments from './admin/accounting/payments/AdminPayments';
import AdminTPVConfs from './admin/accounting/tpvConfigurations/AdminTPVConfs';
import AdminTpvRecords from './admin/accounting/tpvRecords/AdminTpvRecords';
import AdminTpvRecordsBySc from './admin/accounting/tpvRecordsBySc/AdminTpvRecordsBySc';
import AdminUsers from './admin/users/users/AdminUsers';
import AdminRentals from './admin/rentals/rentals/AdminRentals';
import AdminRentalsPerDay from './admin/rentals/rentalsPerDay/AdminRentalsPerDay';
import AdminRentalsRatioPerYear from './admin/rentals/rentalsRatioPerYear/AdminRentalsRatioPerYear';
import AdminRentalsRatioByCenter from './admin/rentals/rentalsRatioByCenter/AdminRentalsRatioByCenter';
import AdminEvents from './admin/events/events/AdminEvents';
import AdminActivitiesRegistrationsRatioByCenter from './admin/activities/activitiesRegistrationsRatioByCenter/AdminActivitiesRegistrationsRatioByCenter';
import AdminActivitiesEnrrollmentIncreasesPerYear from './admin/activities/activitiesEnrrollmentIncreasesPerYear/AdminActivitiesEnrrollmentIncreasesPerYear';
import AdminSports from './admin/tables/sports/AdminSports';
import AdminNotificationsTypes from './admin/tables/notificationsTypes/AdminNotificationsTypes';
import AdminSmss from './admin/tables/smss/AdminSmss';
import AdminEmails from './admin/tables/emails/AdminEmails';
import AdminPushNotifications from './admin/tables/pushNotifications/AdminPushNotifications';
import AdminApiModels from './admin/hart/apiModels/AdminApiModels';
import AdminApiTriggersBySc from './admin/hart/triggersInCenters/AdminApiTriggersBySc';
import GolfBookingsCards from './public/golfBookingsCards/GolfBookingsCards';
import GolfTimetable from './sc/golfTimetable/GolfTimetable';
import SmartCourt from './public/Smartcourt';
import AdminMigrationEndpoints from './admin/migration/adminMigrationEndpoints/AdminMigrationEndpoints';
import Discounts from './sc/discounts/Discounts';
import TPVSwitcher from './public/tpv/TPVSwitcher';
import AdminFixTPV from './admin/fixTpv/AdminFixTpv';
import SCStats from './sc/reports/SCStats';
import DevicesSpaces from './sc/capacity/Capacities';

// Una ruta no es más que un objeto que contiene los siguientes parámetros:
// 	- id: Usado para la identificación única de la ruta (obligatorio).
// 	- path: URL asociada al ID de la ruta (obligatorio).
// 	- role: Los roles pueden ser: USER, TEACHER, SPORTCENTER o ADMIN. El rol obliga
// 		a que una ruta protegida con el parámetro "protected" solo pueda ser accedida
// 		por usuarios con dicho rol. Por defecto no usa ningún rol (no obligatorio).
// 	- protected: Su valor puede ser true (bloquea la ruta al usuario no conectado) y
// 		false (no bloquea la ruta a ningún usuario). Por defecto es false (no obligatorio).
// 	- header: Su valor puede ser true (activa el header en dicha ruta) y
// 		false (desactiva el header en la ruta). Por defecto es true (no obligatorio).
// 	- footer: Su valor puede ser true (activa el footer en dicha ruta) y
// 		false (desactiva el footer en la ruta). Por defecto es true (no obligatorio).
// 	- fullScreen: Su valor puede ser true (pagina fullScreen sin padding ni margenes) y
// 	    false (pagina con padding y margenes). Por defecto es false (no obligatorio).
// 	- component: Nombre del componente que se va a visualizar cuando el se acceda a la
// 		ruta dada por el parámetro "path" (obligatorio).
// 	- exact: Booleano para definir si queremos que la ruta tiene o no que ser exacta para renderizar
// 		un componente.

// 	Destacar que aquellos parámetros no obligatorios quiere decir que pueden ser no añadidos
// 	a la ruta y tomarán el valor por defecto.

// 	¡¡IMPORTANTE!!:
// 	- Toda ruta "PROTECTED" debe de tener un "ROL".
// 	- Las rutas que se usan un ÚNICO PATH para renderizar varios tipos de páginas en función del usuario
// 	(por ejemplo) hay que generar un único componente en el que dentro se elija el componente final a renderizar
// 	en función del usuario por ejemplo.

// 	Ejemplo con todos los parámetros:

// 	{
// 		id: 'pruebaRuta',
// 		path: '/prueba',
// 		role: 'TEACHER',
// 		protected: true,
// 		header: false,
// 		footer: false,
// 		fullScreen: false,
// 		component: Prueba,
// 		exact: false,
// 	}

export const paths = [
    // ----------------
    //     COMMON ROUTES (for example /home)
    // ----------------
    {
        id: 'home',
        path: '/home',
        component: Home,
    },

    // ----------------
    //     PUBLIC
    // ----------------
    {
        id: 'smartcourt',
        path: '/smartcourt',
        protected: false,
        component: SmartCourt,
    },
    {
        id: 'search',
        path: '/search',
        protected: false,
        component: Search,
    },
    {
        id: 'contact',
        path: '/contact',
        protected: false,
        component: Contact,
    },
    {
        id: 'login',
        path: '/login',
        protected: false,
        header: false,
        component: Login,
    },
    {
        id: 'signup',
        path: '/signup',
        protected: false,
        header: false,
        component: Register,
    },
    {
        id: 'recoverPassword',
        path: '/recover',
        protected: false,
        header: false,
        component: RecoverPassword,
    },
    {
        id: 'confirmEmail',
        path: '/email/activation',
        protected: false,
        component: ConfirmEmail,
    },
    {
        id: 'acceptConditions',
        path: '/privacy/confirmation/:id/:signature',
        protected: false,
        component: AcceptConditions,
    },
    {
        id: 'privacy',
        path: '/privacy',
        protected: false,
        component: Privacy,
    },
    {
        id: 'timetable',
        path: '/scs/:id/timetable',
        component: Timetable,
    },
    {
        id: 'apis',
        path: '/apis',
        component: Apis,
    },
    {
        id: 'tpvSwitcher',
        path: '/tpvs/:id',
        header: false,
        footer: false,
        fullScreen: true,
        component: TPVSwitcher,
    },
    {
        id: 'tpvResult',
        path: '/tpvs/:id/result',
        header: false,
        footer: false,
        fullScreen: true,
        component: TPVResult,
    },
    {
        id: 'activityPublic',
        path: '/activities/:id',
        component: ActivityPublic,
    },
    {
        id: 'abonoList',
        path: '/scs/:id/groups',
        component: AbonoList,
    },
    {
        id: 'scProfile',
        path: '/scs/:id/profile',
        component: SCProfile,
    },
    {
        id: 'scDocuments',
        path: '/scs/:id/documents',
        component: SCDocuments,
    },
    {
        id: 'scContact',
        path: '/scs/:id/contact',
        component: SCContact,
    },
    {
        id: 'bonoList',
        path: '/scs/:id/bonos',
        component: BonoList,
    },
    {
        id: 'booked',
        path: '/bookings/:id',
        component: Booked,
    },
    {
        id: 'eventPublic',
        path: '/events/:id',
        component: EventPublic,
    },
    {
        id: 'widget',
        path: '/scs/:id/widget',
        header: false,
        footer: false,
        component: Widget,
    },
    {
        id: 'golfBookingsCards',
        path: '/scs/:id/golfBookings',
        component: GolfBookingsCards,
    },

    // ----------------------
    //        USER
    // ----------------------
    {
        id: 'timetableFrame',
        role: 'USER',
        path: '/frames/scs/:id/timetable',
        protected: true,
        header: false,
        footer: false,
        component: Timetable,
    },
    {
        id: 'userKeys',
        role: 'USER',
        path: '/keys',
        protected: true,
        component: UserKeys,
    },
    {
        id: 'userAgenda',
        role: 'USER',
        path: '/agenda',
        protected: true,
        component: Agenda,
    },
    {
        id: 'userMessages',
        role: 'USER',
        path: '/messages',
        protected: true,
        component: UserMessages,
    },
    {
        id: 'buyBono',
        path: '/scs/:idSC/bonos/:id/purchase',
        role: 'USER',
        protected: true,
        component: BuyBono,
    },
    {
        id: 'buyAbono',
        path: '/scs/:idSc/groups/:idGroup/purchases',
        role: 'USER',
        protected: true,
        component: BuyAbono,
    },
    {
        id: 'userAbono',
        role: 'USER',
        path: '/abonos/:id',
        protected: true,
        component: UserAbono,
    },
    {
        id: 'userAbonos',
        role: 'USER',
        path: '/abonos',
        protected: true,
        component: UserAbonos,
    },
    {
        id: 'userBonos',
        role: 'USER',
        path: '/bonos',
        protected: true,
        component: UserBonos,
    },
    {
        id: 'rechargePurse',
        role: 'USER',
        path: '/scs/:id/purse',
        protected: true,
        component: RechargePurse,
    },
    {
        id: 'userConfiguration',
        role: 'USER',
        path: '/configuration',
        protected: true,
        component: UserConfiguration,
    },
    {
        id: 'userPayments',
        role: 'USER',
        path: '/payments',
        protected: true,
        component: UserPayments,
    },
    {
        id: 'userPupils',
        role: 'USER',
        path: '/pupils',
        protected: true,
        component: UserPupils,
    },

    // ----------------------
    //        TEACHER
    // ----------------------
    {
        id: 'userAgenda',
        role: 'TEACHER',
        path: '/agenda',
        protected: true,
        component: Agenda,
    },
    {
        id: 'userAbono',
        role: 'TEACHER',
        path: '/abonos/:id',
        protected: true,
        component: UserAbono,
    },
    {
        id: 'userAbonos',
        role: 'TEACHER',
        path: '/abonos',
        protected: true,
        component: UserAbonos,
    },
    {
        id: 'userBonos',
        role: 'TEACHER',
        path: '/bonos',
        protected: true,
        component: UserBonos,
    },
    {
        id: 'rechargePurse',
        role: 'TEACHER',
        path: '/scs/:id/purse',
        protected: true,
        component: RechargePurse,
    },
    {
        id: 'userKeys',
        role: 'TEACHER',
        path: '/keys',
        protected: true,
        component: UserKeys,
    },
    {
        id: 'userConfiguration',
        role: 'TEACHER',
        path: '/configuration',
        protected: true,
        component: UserConfiguration,
    },

    // ----------------------
    //     SPORTS CENTERS
    // ----------------------
    {
        id: 'scConfiguration',
        role: 'SPORTCENTER',
        path: '/scs/configurations',
        protected: true,
        component: Configuration,
    },
    {
        id: 'devicesSpaces',
        role: 'SPORTCENTER',
        path: '/scs/devicesSpaces',
        protected: true,
        component: DevicesSpaces,
    },
    {
        id: 'scRoles',
        role: 'SPORTCENTER',
        path: '/scs/roles',
        protected: true,
        component: Roles,
    },
    {
        id: 'freeSchedules',
        role: 'SPORTCENTER',
        path: '/scs/frees/schedules',
        protected: true,
        component: FreeSchedules,
    },
    {
        id: 'freeSchedule',
        role: 'SPORTCENTER',
        path: '/scs/frees/schedules/:id',
        protected: true,
        component: FreeSchedule,
    },
    {
        id: 'remittences',
        role: 'SPORTCENTER',
        path: '/scs/remittences',
        protected: true,
        component: Remittences,
    },
    {
        id: 'genRemittences',
        role: 'SPORTCENTER',
        path: '/scs/remittences/generate',
        protected: true,
        component: GenerateRemittence,
    },
    {
        id: 'remittence',
        role: 'SPORTCENTER',
        path: '/scs/remittences/:id',
        protected: true,
        component: Remittence,
    },
    {
        id: 'facilitySets',
        role: 'SPORTCENTER',
        path: '/scs/facilitySets',
        protected: true,
        component: FacilitySets,
    },
    {
        id: 'facilitySet',
        role: 'SPORTCENTER',
        path: '/scs/facilitySets/:id',
        protected: true,
        component: FacilitySet,
    },
    {
        id: 'facilitySetCreate',
        role: 'SPORTCENTER',
        path: '/scs/facilitySets/create',
        protected: true,
        component: FacilitySet,
    },
    {
        id: 'facilities',
        role: 'SPORTCENTER',
        path: '/scs/facilities',
        protected: true,
        component: Facilities,
    },
    {
        id: 'devices',
        role: 'SPORTCENTER',
        path: '/scs/devices',
        protected: true,
        component: Devices,
    },
    {
        id: 'device',
        role: 'SPORTCENTER',
        path: '/scs/devices/:id',
        protected: true,
        component: Device,
    },
    {
        id: 'mships',
        role: 'SPORTCENTER',
        path: '/scs/mships',
        protected: true,
        component: Mships,
    },
    {
        id: 'booking',
        role: 'SPORTCENTER',
        path: '/scs/:id/booking',
        protected: true,
        component: Booking,
    },
    {
        id: 'bookings',
        role: 'SPORTCENTER',
        path: '/scs/bookings',
        protected: true,
        component: Bookings,
    },
    {
        id: 'busySchedules',
        role: 'SPORTCENTER',
        path: '/scs/busy/schedules',
        protected: true,
        component: BusySchedules,
    },
    {
        id: 'busySchedule',
        role: 'SPORTCENTER',
        path: '/scs/busy/schedules/:id',
        protected: true,
        component: BusySchedule,
    },
    {
        id: 'payments',
        role: 'SPORTCENTER',
        path: '/scs/payments',
        protected: true,
        component: Payments,
    },
    {
        id: 'activities',
        role: 'SPORTCENTER',
        path: '/scs/activities',
        protected: true,
        component: Activities,
    },
    {
        id: 'groups',
        role: 'SPORTCENTER',
        path: '/scs/groups',
        protected: true,
        component: Groups,
    },
    {
        id: 'group',
        role: 'SPORTCENTER',
        path: '/scs/groups/:id',
        protected: true,
        component: Group,
    },
    {
        id: 'rates',
        role: 'SPORTCENTER',
        path: '/scs/rates',
        protected: true,
        component: Rates,
    },
    {
        id: 'report',
        role: 'SPORTCENTER',
        path: '/scs/reports/:id',
        protected: true,
        component: Report,
    },
    {
        id: 'files',
        role: 'SPORTCENTER',
        path: '/scs/files',
        protected: true,
        component: Files,
    },
    {
        id: 'bonos',
        role: 'SPORTCENTER',
        path: '/scs/bonos',
        protected: true,
        component: Bonos,
    },
    {
        id: 'bono',
        role: 'SPORTCENTER',
        path: '/scs/bonos/:id',
        protected: true,
        component: Bono,
    },
    {
        id: 'fileCSV',
        role: 'SPORTCENTER',
        path: '/scs/files/csvs/:id',
        protected: true,
        component: FileCSV,
    },
    {
        id: 'bookingSchedules',
        role: 'SPORTCENTER',
        path: '/scs/booking/schedules',
        protected: true,
        component: BookingSchedules,
    },
    {
        id: 'bookingSchedule',
        role: 'SPORTCENTER',
        path: '/scs/booking/schedules/:id',
        protected: true,
        component: BookingSchedule,
    },
    {
        id: 'restrictions',
        role: 'SPORTCENTER',
        path: '/scs/restrictions',
        protected: true,
        component: Restrictions,
    },
    {
        id: 'restriction',
        role: 'SPORTCENTER',
        path: '/scs/restrictions/:id',
        protected: true,
        component: Restriction,
    },
    {
        id: 'teachers',
        role: 'SPORTCENTER',
        path: '/scs/teachers',
        protected: true,
        component: Teachers,
    },
    {
        id: 'teacher',
        role: 'SPORTCENTER',
        path: '/scs/teachers/:id',
        protected: true,
        component: Teacher,
    },
    {
        id: 'scMessages',
        role: 'SPORTCENTER',
        path: '/scs/messages',
        protected: true,
        component: SCMessages,
    },
    {
        id: 'account',
        role: 'SPORTCENTER',
        path: '/account',
        protected: true,
        component: Account,
    },
    {
        id: 'scReports',
        role: 'SPORTCENTER',
        path: '/scs/reports',
        protected: true,
        component: SCReports,
    },
    {
        id: 'scTickets',
        role: 'SPORTCENTER',
        path: '/scs/tickets',
        protected: true,
        component: SCTickets,
    },
    {
        id: 'students',
        role: 'SPORTCENTER',
        path: '/scs/students',
        protected: true,
        component: Students,
    },
    {
        id: 'events',
        role: 'SPORTCENTER',
        path: '/scs/events',
        protected: true,
        component: Events,
    },
    {
        id: 'help',
        role: 'SPORTCENTER',
        path: '/help',
        protected: true,
        component: Contact,
    },
    {
        id: 'waitingLists',
        role: 'SPORTCENTER',
        path: '/scs/waitingLists',
        protected: true,
        component: UnderConstruction,
    },
    {
        id: 'subscriptions',
        role: 'SPORTCENTER',
        path: '/scs/subscriptions',
        protected: true,
        component: Subscription,
    },
    {
        id: 'noSubscription',
        role: 'SPORTCENTER',
        path: '/scs/NoSubscription',
        protected: true,
        component: NoSubscription,
    },
    {
        id: 'golfTimetable',
        role: 'SPORTCENTER',
        path: '/scs/golfTimetable',
        protected: true,
        component: GolfTimetable,
    },
    {
        id: 'golf',
        role: 'SPORTCENTER',
        path: '/scs/golf',
        protected: true,
        component: Golf,
    },
    {
        id: 'discounts',
        role: 'SPORTCENTER',
        path: '/scs/discounts',
        protected: true,
        component: Discounts,
    },
    {
        id: 'scStats',
        role: 'SPORTCENTER',
        path: '/scs/stats',
        protected: true,
        component: SCStats,
    },

    // ---------------------
    //    SPORTTIA ADMINS
    // ---------------------
    {
        id: 'adminCustomers',
        role: 'ADMIN',
        path: '/admin/customers',
        protected: true,
        component: AdminCustomers,
    },
    {
        id: 'adminSCs',
        role: 'ADMIN',
        path: '/admin/scs',
        protected: true,
        component: AdminSCs,
    },
    {
        id: 'adminSttZeroAccounts',
        role: 'ADMIN',
        path: '/admin/zeroAccounts',
        protected: true,
        component: AdminSttZeroAccounts,
    },
    {
        id: 'adminPlaytomicCenters',
        role: 'ADMIN',
        path: '/admin/playtomicCenters',
        protected: true,
        component: AdminPlaytomicCenters,
    },
    {
        id: 'adminPayments',
        role: 'ADMIN',
        path: '/admin/payments',
        protected: true,
        component: AdminPayments,
    },
    {
        id: 'adminTpvConfs',
        role: 'ADMIN',
        path: '/admin/tpvConfs',
        protected: true,
        component: AdminTPVConfs,
    },
    {
        id: 'adminTpvRecords',
        role: 'ADMIN',
        path: '/admin/tpvRecords',
        protected: true,
        component: AdminTpvRecords,
    },
    {
        id: 'adminTpvRecordsBySc',
        role: 'ADMIN',
        path: '/admin/tpvRecordsBySc',
        protected: true,
        component: AdminTpvRecordsBySc,
    },
    {
        id: 'adminUsers',
        role: 'ADMIN',
        path: '/admin/users',
        protected: true,
        component: AdminUsers,
    },
    {
        id: 'adminRentals',
        role: 'ADMIN',
        path: '/admin/rentals',
        protected: true,
        component: AdminRentals,
    },
    {
        id: 'adminRentalsPerDay',
        role: 'ADMIN',
        path: '/admin/rentalsPerDay',
        protected: true,
        component: AdminRentalsPerDay,
    },
    {
        id: 'adminRentalsRatioPerYear',
        role: 'ADMIN',
        path: '/admin/rentalsRatioPerYear',
        protected: true,
        component: AdminRentalsRatioPerYear,
    },
    {
        id: 'adminRentalsRatioByCenter',
        role: 'ADMIN',
        path: '/admin/rentalsRatioByCenter',
        protected: true,
        component: AdminRentalsRatioByCenter,
    },
    {
        id: 'adminEvents',
        role: 'ADMIN',
        path: '/admin/events',
        protected: true,
        component: AdminEvents,
    },
    {
        id: 'adminActivitiesRegistrationsRatioByCenter',
        role: 'ADMIN',
        path: '/admin/activitiesRegistrationsRatioByCenter',
        protected: true,
        component: AdminActivitiesRegistrationsRatioByCenter,
    },
    {
        id: 'adminAdminActivitiesEnrrollmentIncreasesPerYear',
        role: 'ADMIN',
        path: '/admin/adminAdminActivitiesEnrrollmentIncreasesPerYear',
        protected: true,
        component: AdminActivitiesEnrrollmentIncreasesPerYear,
    },
    {
        id: 'adminSports',
        role: 'ADMIN',
        path: '/admin/sports',
        protected: true,
        component: AdminSports,
    },
    {
        id: 'adminNotificationsTypes',
        role: 'ADMIN',
        path: '/admin/notificationsTypes',
        protected: true,
        component: AdminNotificationsTypes,
    },
    {
        id: 'adminSmss',
        role: 'ADMIN',
        path: '/admin/smss',
        protected: true,
        component: AdminSmss,
    },
    {
        id: 'adminEmails',
        role: 'ADMIN',
        path: '/admin/emails',
        protected: true,
        component: AdminEmails,
    },
    {
        id: 'adminPushNotifications',
        role: 'ADMIN',
        path: '/admin/pushNotifications',
        protected: true,
        component: AdminPushNotifications,
    },
    {
        id: 'adminReports',
        role: 'ADMIN',
        path: '/admin/reports',
        protected: true,
        component: AdminReports,
    },
    {
        id: 'adminReports',
        role: 'ADMIN',
        path: '/admin/reports/:id',
        protected: true,
        component: Report,
    },
    {
        id: 'adminTranslations',
        role: 'ADMIN',
        path: '/admin/translations',
        protected: true,
        component: AdminTranslations,
    },
    {
        id: 'adminCities',
        role: 'ADMIN',
        path: '/admin/cities',
        protected: true,
        component: AdminCities,
    },
    {
        id: 'adminAPIs',
        role: 'ADMIN',
        path: '/admin/apis',
        protected: true,
        component: AdminApis,
    },
    {
        id: 'adminAPIModels',
        role: 'ADMIN',
        path: '/admin/apis/models',
        protected: true,
        component: AdminApiModels,
    },
    {
        id: 'adminAPILogs',
        role: 'ADMIN',
        path: '/admins/apis/logs',
        protected: true,
        component: AdminApiLogs,
    },
    {
        id: 'adminAPITriggersBySC',
        role: 'ADMIN',
        path: '/admins/scs/triggers',
        protected: true,
        component: AdminApiTriggersBySc,
    },
    {
        id: 'adminAPITriggers',
        role: 'ADMIN',
        path: '/admins/triggers',
        protected: true,
        component: AdminApiTriggersExecutions,
    },
    {
        id: 'adminTests',
        role: 'ADMIN',
        path: '/admin/tests',
        protected: true,
        component: AdminTests,
    },
    {
        id: 'adminMigrationEndpoints',
        role: 'ADMIN',
        path: '/admin/migration/endpoints',
        protected: true,
        component: AdminMigrationEndpoints,
    },
    {
        id: 'fixTPV',
        role: 'ADMIN',
        path: '/admin/fixtpv',
        protected: true,
        component: AdminFixTPV,
    },

    // ---------------------
    //    PÁGINA NO ENCONTRADA (ERROR 404)
    // ---------------------
    {
        id: 'page404',
        path: '*',
        component: Page404,
        exact: false,
    },
] as const;

/**
 * Method to get url path using id key of route.
 */
export const getPath = (
    idPath: (typeof paths)[number]['id'],
    params: Record<string, unknown> = {},
) => {
    const pathObj = paths.find((path) => path.id === idPath);

    if (pathObj) {
        let finalPath: string = pathObj.path;

        if (params) {
            Object.keys(params).forEach((key) => {
                finalPath = finalPath.replace(
                    `:${key}`,
                    params[key]!.toString(),
                );
            });
        }
        return finalPath;
    }

    // In case the path couldn't be found, we return a base path to avoid crashes
    return '/';
};
