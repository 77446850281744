import React from 'react';
import {
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

/**
 * Componente para seleccionar intervalos de numeros. Empezando por min, hasta max, cada interval. El valor 0 estará representado por ∞.
 * string caption - field caption
 * int labelWidth - optional with for the caption can be provided
 * int defVal - selected value
 * int min - minimum value available for selection
 * int max - maximum value available for selection
 * int interval - length of intervals
 * Function onChange(number) - callback for select
 */
export function SttSelectNumbersInterval({
    grid = false,
    caption,
    labelWidth = 60,
    defVal = 0,
    min = 10,
    max = 23 * 60, // From 00:00 to 23:00
    interval,
    onChange,
    ...rest
}) {
    const menuItems = [];

    // Creamos los elementos
    for (let i = min; i <= max; i += interval) {
        menuItems.push(
            <MenuItem key={i} value={i}>
                {i === 0 ? '∞' : i}
            </MenuItem>,
        );
    }

    const content = (
        <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
            <InputLabel>{caption}</InputLabel>
            <Select
                value={defVal}
                onChange={(e) => onChange(e.target.value)}
                labelWidth={labelWidth}
            >
                {menuItems}
            </Select>
        </FormControl>
    );

    return grid ? (
        <Grid item {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
