import {
    UseMutationOptions,
    useMutation,
    MutationFunction,
    useQuery,
    UseQueryOptions,
} from 'react-query';
import { useContext } from 'react';
import { useAPI } from '../lib/hooks';
import {
    CreateTPVConfirmationFix200Body,
    CreateTpvPurchase200,
    CreateTpvPurchaseParams,
    CreateTpvReader200,
    CreateTpvReaderBody,
    CreateTpvReaderConnection200,
    CreateTpvReaderConnectionBody,
    GetTpv200,
    GetTpvParams,
    GetTpvsFromPayment200,
} from '../types/api/paths/Tpv';
import useRestService from './RestService';
import { AppContext } from '../sporttia/AppContext';
import Error from '../types/models/Error';
import { DefaultListResponse200 } from '../types/api/utils';
import Tpv from '../types/models/Tpv';

const resource = '/tpvs';

export const getGetTpvQueryKey = (id: number, params?: GetTpvParams) => [
    `${resource}/${id}`,
    ...(params ? [params] : []),
];

/**
 * Hook to access all 'tpvs' resources.
 */
export default function useTpvsService() {
    const { migrationApis } = useContext(AppContext)!;
    const [request] = useAPI(migrationApis);
    const services = useRestService(resource);

    /**
     * GET /tpvs/:id request.
     */
    function useGetTpv<TData = GetTpv200>(
        id: number,
        params?: GetTpvParams,
        queryOptions?: UseQueryOptions<GetTpv200, unknown, TData>,
    ) {
        const queryKey =
            queryOptions?.queryKey ?? getGetTpvQueryKey(id, params);

        const queryFn = () =>
            request<GetTpv200>('GET', `${resource}/${id}`, params);

        const query = useQuery(queryKey, queryFn, queryOptions);

        return {
            ...query,
            queryKey,
        };
    }

    /**
     * POST /tpvs/:id/purchase
     */
    function useCreateTpvPurchase(
        mutationOptions?: UseMutationOptions<
            CreateTpvPurchase200,
            unknown,
            { id: number; params?: CreateTpvPurchaseParams }
        >,
    ) {
        const mutationFn: MutationFunction<
            CreateTpvPurchase200,
            { id: number; params?: CreateTpvPurchaseParams }
        > = ({ id, params }) =>
            request('POST', `${resource}/${id}/purchase`, params);
        // Delete this comments if it's 2024.
        // Change to GET /tpv/id if something goes wrong with v7.
        // request('GET', `${resource}/${id}`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * GET /tpvs/configurations request.
     */
    function getTpvsConfigurations(params: Record<string, unknown>) {
        return request('GET', `${resource}/configurations`, params);
    }

    /**
     * POST /tpvs/:id/confirmations request.
     */

    function createTpvConfirmations(
        id: number,
        params: Record<string, unknown>,
    ): Promise<Tpv> {
        return request('POST', `${resource}/${id}/confirmations`, params);
    }

    /**
     * GET /tpvs/configurations/:id request.
     */
    function getTpvsConfiguration(id: number, params: Record<string, unknown>) {
        return request('GET', `${resource}/configurations/${id}`, params);
    }

    /**
     * POST /tpvs/configurations request.
     */
    function createTpvConfiguration(params: Record<string, unknown>) {
        return request('POST', `${resource}/configurations`, params);
    }

    /**
     * PUT /tpvs/configurations/:id request.
     */
    function updateTpvConfiguration(
        id: number,
        params: Record<string, unknown>,
    ) {
        return request('PUT', `${resource}/configurations/${id}`, params);
    }

    /**
     * DELETE /tpvs/configurations/:id request.
     */
    function removeTpvConfiguration(id: number) {
        return request('DELETE', `${resource}/configurations/${id}`);
    }

    /**
     * PUT /tpvs/:id/pay request.
     */
    function updateTpvPay(id: number, params: Record<string, unknown>) {
        return request('PUT', `${resource}/${id}/pay`, params);
    }

    /**
     * POST /tpvs/readers request.
     */
    function useCreateTpvReader(
        mutationOptions?: UseMutationOptions<
            CreateTpvReader200,
            unknown,
            CreateTpvReaderBody
        >,
    ) {
        const mutationFn: MutationFunction<
            CreateTpvReader200,
            CreateTpvReaderBody
        > = (params) => request('POST', `${resource}/readers`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * POST /tpvs/readers/connection request.
     */
    function useCreateTpvReaderConnection(
        mutationOptions?: UseMutationOptions<
            CreateTpvReaderConnection200,
            unknown,
            CreateTpvReaderConnectionBody
        >,
    ) {
        const mutationFn: MutationFunction<
            CreateTpvReaderConnection200,
            CreateTpvReaderConnectionBody
        > = (params) =>
            request('POST', `${resource}/readers/connections`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    function useCreateTPVConfirmationFix(
        mutationOptions?: UseMutationOptions<
            null,
            Error,
            CreateTPVConfirmationFix200Body
        >,
    ) {
        const mutationFn: MutationFunction<
            null,
            CreateTPVConfirmationFix200Body
        > = (params) =>
            request('POST', `${resource}/confirmations/fixes`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * GET /tpvs conseguir los tpvs asociados a un payment.
     */
    function useGetTpvsFromPaymentId<TData = DefaultListResponse200<GetTpv200>>(
        params?: GetTpvParams,
        queryOptions?: UseQueryOptions<GetTpvsFromPayment200, unknown, TData>,
    ) {
        const queryKey = queryOptions?.queryKey ?? [
            `${resource}`,
            params || [],
        ];

        const queryFn = () =>
            request<GetTpvsFromPayment200>('GET', `${resource}`, params);

        const query = useQuery(queryKey, queryFn, queryOptions);
        return {
            ...query,
            queryKey,
        };
    }

    return {
        ...services,
        getTpvsConfigurations,
        createTpvConfirmations,
        getTpvsConfiguration,
        createTpvConfiguration,
        updateTpvConfiguration,
        removeTpvConfiguration,
        updateTpvPay,
        useCreateTpvReader,
        useCreateTpvReaderConnection,
        useGetTpv,
        useCreateTpvPurchase,
        useCreateTPVConfirmationFix,
        useGetTpvsFromPaymentId,
    };
}
