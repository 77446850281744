import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Box, Typography, Link } from '@material-ui/core';
import { AppContext } from '../../../sporttia/all';
import { getPath } from '../../Pages';
import { useLoader, usePageHeader, useTranslations } from '../../../lib/hooks';
import useReportsService from '../../../services/ReportsService';

export default function SCReports() {
    usePageHeader('Reports');
    const cxt = useContext(AppContext)!;
    const history = useHistory();

    const reportService = useReportsService();
    const reportsQuery = reportService.useGetReports({ scope: 'SC' });
    const { translate } = useTranslations();
    const [, loader] = useLoader([reportsQuery.status]);

    const reportGroups: {
        [key: string]: Array<{
            caption: string;
            params: { id: number };
            path: 'report';
        }>;
    } = {};

    reportsQuery?.data?.rows.forEach((reportItem) => {
        if (reportItem.group && !(reportItem.group in reportGroups)) {
            // Inicializamos el grupo
            reportGroups[reportItem.group] = [];
        }
        if (reportGroups[reportItem.group]) {
            reportGroups[reportItem.group]!.push({
                caption: reportItem.name,
                params: { id: reportItem?.id },
                path: 'report',
            });
        }
    });

    const reportList: Array<{
        caption: string;
        params: { id: number };
        path: 'report';
    }> = Object.values(reportGroups).flat();

    /* TODO eliminar este código comentado cuando se aclare donde se va a filtrar por privilegios
       en la nueva forma de leer los reportes

    const reportGroups = [
        {
            title: cxt.t('Bookings'),
            reports: [
                {
                    caption: 'OccupationOfFacilities',
                    params: { id: 8 },
                },
                {
                    caption: 'Seating',
                    params: { id: 53 },
                },
            ],
        },
    ]; */

    if (!cxt.privileges?.includes(15)) {
        delete reportGroups.ACTIVITIES;
    }

    const goReport = (reportPicked: { params: { id: number } }) => {
        history.push({
            pathname: getPath('report', reportPicked.params),
            state: {
                reportGroups: reportList,
            },
        });
    };

    return (
        <Box p={2}>
            {loader}
            <Grid container spacing={3}>
                {reportGroups &&
                    Object.keys(reportGroups).map((keyGroup) => (
                        <Grid item md={4} sm={6} xs={12} key={keyGroup}>
                            <Typography variant="h5">
                                {translate(
                                    keyGroup.length > 0
                                        ? keyGroup.charAt(0) +
                                              keyGroup.slice(1).toLowerCase()
                                        : '',
                                )}
                            </Typography>

                            {reportGroups[keyGroup] &&
                                reportGroups[keyGroup]?.map((reportItem) => (
                                    <Box my={1} key={reportItem.params.id}>
                                        <Link
                                            onClick={() =>
                                                goReport({
                                                    params: {
                                                        id: reportItem.params
                                                            .id,
                                                    },
                                                })
                                            }
                                        >
                                            {reportItem.caption}
                                        </Link>
                                    </Box>
                                ))}
                        </Grid>
                    ))}
                <Grid item md={4} sm={6} xs={12}>
                    <Typography variant="h5">{cxt.t('stats')}</Typography>

                    <Box my={1}>
                        <Link
                            onClick={() => {
                                history.push(getPath('scStats'));
                            }}
                        >
                            {cxt.t('seeStats')}
                        </Link>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
