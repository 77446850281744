import React, { useContext } from 'react';
import {
    List,
    ListItem,
    Divider,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Box,
} from '@material-ui/core';
import { AppContext, SttPagination } from '../all';
import { checkOffTag, formatPriceByLocale } from '../../lib/utils';
/**
 *
 * Params
 *
 * 	* Object mships(count,rows)
 * 	* integer page=1
 *	* function onSelect(mship)
 *	* function onLoadMships({page})
 */
export function SttMshipList({ mships, page = 1, onSelect, onLoadMships }) {
    const cxt = useContext(AppContext);

    // -----| Render |-----
    return (
        <List>
            {/* --- Searching box --- */}
            {mships &&
                mships.rows &&
                mships.rows.map((mship) => (
                    <div key={mship.id}>
                        <Divider />
                        <ListItem button onClick={() => onSelect(mship)}>
                            <ListItemAvatar>
                                <Avatar
                                    alt={mship.name}
                                    src={mship.img ? mship.img.url : null}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary={mship.fullName}
                                secondary={
                                    <>
                                        {mship.user &&
                                        checkOffTag(mship.user.login) ? (
                                            <i style={{ color: '#5b5b5b' }}>
                                                {cxt.t('UserCancelled')}
                                            </i>
                                        ) : mship.amount ? (
                                            <>
                                                <span>
                                                    {cxt.t('Purse')}:&nbsp;
                                                </span>
                                                <b>
                                                    {formatPriceByLocale(
                                                        mship.amount,
                                                        mship?.amountShape
                                                            ?.currency,
                                                        mship?.amountShape
                                                            ?.locale,
                                                    )}
                                                </b>
                                            </>
                                        ) : null}
                                    </>
                                }
                            />
                        </ListItem>
                    </div>
                ))}

            {/* --- No results --- */}
            {mships && mships.count == 0 && (
                <ListItem>
                    <ListItemText primary={cxt.t('NoResults')} />
                </ListItem>
            )}

            {/* --- List --- */}
            <ListItem>
                {mships && (
                    <Box m="auto">
                        <SttPagination
                            page={page}
                            pages={mships.pages}
                            count={mships.count}
                            onChangePage={(page) => onLoadMships({ page })}
                        />
                    </Box>
                )}
            </ListItem>
        </List>
    );
}
