import React, { ReactNode } from 'react';
import { Grid, Box, GridSize } from '@material-ui/core';
import {
    SttFormText,
    SttFormTextarea,
    SttFormDate,
    SttFormSelect,
    SttButtonSave,
    SttButtonDelete,
    SttButton,
    SttButtonAccept,
    SttFormCheck,
    SttButtonRecover,
    SttSelectCity,
} from './all';

type FormElement = {
    name: string;
    caption: string;
    value?: string;
    type?: string;
    md?: boolean | GridSize;
    sm?: boolean | GridSize;
    xs?: boolean | GridSize;
    disabled?: boolean;
    options?: { value: string; label: string }[];
    rows?: number;
    show?: boolean;
    component?: ReactNode;
};

type FormButton = {
    caption?: string;
    onClick: () => void;
    show?: boolean;
    type: 'save' | 'delete' | 'accept' | 'recover';
};

type SttFormFormProps = {
    elements: FormElement[];
    buttons: FormButton[];
    onChangeForm: ({
        name,
        value,
    }: {
        name: string;
        value: string | null;
    }) => void;
};

export function SttForm({ elements, buttons, onChangeForm }: SttFormFormProps) {
    const renderElement = (ele: FormElement, idx: number) => {
        let content;
        const {
            name,
            caption,
            value,
            type = 'input',
            md,
            sm,
            xs,
            disabled,
            options,
            show = true,
            component,
        } = ele;

        switch (type ? type.toLowerCase() : 'input') {
            case 'date':
                content = (
                    <SttFormDate
                        name={name}
                        caption={caption}
                        defVal={value}
                        disabled={disabled}
                        onChange={onChangeForm}
                    />
                );
                break;

            case 'textarea':
                content = (
                    <SttFormTextarea
                        name={name}
                        rows={ele.rows}
                        caption={caption}
                        defVal={value || ''}
                        disabled={disabled}
                        onChange={onChangeForm}
                    />
                );
                break;

            case 'select':
                content = (
                    <SttFormSelect
                        name={name}
                        caption={caption}
                        defVal={value || ''}
                        disabled={disabled}
                        onChange={onChangeForm}
                        // @ts-expect-error TS(2322): Type '{ value: string; label: string; }[] | undefi... Remove this comment to see the full error message
                        options={options}
                    />
                );
                break;

            case 'check':
                content = (
                    <SttFormCheck
                        name={name}
                        caption={caption}
                        disabled={disabled}
                        checked={Boolean(value) || false}
                        onChange={(event) =>
                            onChangeForm({
                                value: event.value.toString(),
                                name: event.name,
                            })
                        }
                    />
                );
                break;

            case 'city':
                content = (
                    // @ts-expect-error TS(2739): Type '{ name: string; caption: string; disabled: b... Remove this comment to see the full error message
                    <SttSelectCity
                        name={name}
                        caption={caption}
                        disabled={disabled}
                        defVal={value || false}
                        onSelect={onChangeForm}
                    />
                );
                break;

            case 'component':
                if (component) {
                    content = component;
                }
                break;

            default:
                content = (
                    <SttFormText
                        name={name}
                        caption={caption}
                        defVal={value || ''}
                        disabled={disabled}
                        onChange={onChangeForm}
                    />
                );
        }

        if (show) {
            return (
                <Grid item key={idx} md={md} sm={sm} xs={xs}>
                    {content}
                </Grid>
            );
        }
        return null;
    };

    const renderButton = (button: FormButton, idx: number) => {
        let content;
        const { caption, onClick, show = true, type } = button;

        switch (type) {
            case 'save':
                content = <SttButtonSave fullWidth onClick={onClick} />;
                break;

            case 'delete':
                content = <SttButtonDelete fullWidth onClick={onClick} />;
                break;

            case 'accept':
                content = <SttButtonAccept fullWidth onClick={onClick} />;
                break;

            case 'recover':
                content = <SttButtonRecover fullWidth onClick={onClick} />;
                break;

            default:
                content = (
                    <SttButton fullWidth caption={caption} onClick={onClick} />
                );
        }

        if (show) {
            return (
                <Grid key={idx} item md={3} sm={12} xs={12}>
                    {content}
                </Grid>
            );
        }
    };

    return (
        <>
            <Grid container spacing={3}>
                {elements && elements.map((e, idx) => renderElement(e, idx))}
            </Grid>

            {buttons && (
                <Box mt={4}>
                    <Grid container spacing={3}>
                        {buttons.map(renderButton)}
                    </Grid>
                </Box>
            )}
        </>
    );
}
