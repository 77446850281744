import React, { useState, useEffect, useContext } from 'react';
import { Grid, Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import RedoIcon from '@material-ui/icons/Redo';
import {
    AppContext,
    SttDialog,
    SttFormText,
    SttFormTextarea,
    SttLabelDate,
} from '../../../sporttia/all';
import { subsetObject } from '../../../lib/utils';

/**
 * Remittence line
 * ===============
 *
 * Props
 *
 * 	* object remittenceLine
 * 	* function onClose
 * 	* function onUpdate(remittenceLine): call it when the internal line is updated
 */
export default function RemittenceLineDialog({
    remittenceLine = null,
    onClose,
    onUpdate,
}) {
    const cxt = useContext(AppContext);
    const [internalLine, setInternalLine] = useState();

    /**
     * Remittence line changes
     */
    useEffect(() => {
        setInternalLine(remittenceLine);
    }, [remittenceLine]);

    /**
     * Handle form
     */
    const handleForm = ({ name, value }) => {
        setInternalLine({ ...internalLine, [name]: value });
    };

    /**
     * Save line
     */
    const saveLine = () => {
        cxt.api('PUT', `/remittences/lines/${internalLine.id}`, {
            params: subsetObject(internalLine, ['name', 'description', 'iban']),
            success: (r) => {
                setInternalLine(r.remittenceLine);
                cxt.showMessage('S', cxt.t('Saved'));
                if (onUpdate) onUpdate(r.remittenceLine);
            },
        });
    };

    /**
     * Delete line
     */
    const deleteLine = () => {
        cxt.api('DELETE', `/remittences/lines/${internalLine.id}`, {
            confirmation: true,
            success: (r) => {
                setInternalLine(r.remittenceLine);
                cxt.showMessage('S', cxt.t('Deleted'));
                if (onUpdate) onUpdate(r.remittenceLine);
            },
        });
    };

    /**
     * Return line
     */
    const returnLine = () => {
        cxt.api('PUT', `/remittences/lines/${internalLine.id}/reject`, {
            confirmation: true,
            success: (r) => {
                setInternalLine(r.remittenceLine);
                cxt.showMessage('S', cxt.t('Returned'));
                if (onUpdate) onUpdate(r.remittenceLine);
            },
        });
    };

    // -----| Render |-----
    return (
        <SttDialog
            title={cxt.t('Line')}
            open={remittenceLine != null}
            onClose={onClose}
            content={
                <Grid container spacing={3}>
                    {internalLine && (
                        <>
                            {internalLine.rejected && (
                                <Grid item md={12}>
                                    <Box mb={2}>
                                        <Alert
                                            variant="filled"
                                            severity="warning"
                                        >
                                            {cxt.t('Returned')}:{' '}
                                            <SttLabelDate
                                                date={internalLine.rejected}
                                            />
                                        </Alert>
                                    </Box>
                                </Grid>
                            )}

                            <SttFormText
                                grid
                                md={12}
                                name="name"
                                caption={cxt.t('Name')}
                                defVal={internalLine.name}
                                onChange={handleForm}
                            />

                            <SttFormTextarea
                                grid
                                name="description"
                                caption={cxt.t('Description')}
                                defVal={internalLine.description}
                                onChange={handleForm}
                            />

                            <SttFormText
                                grid
                                md={12}
                                name="iban"
                                caption={cxt.t('IBAN')}
                                defVal={internalLine.iban}
                                onChange={handleForm}
                            />
                        </>
                    )}
                </Grid>
            }
            buttons={[
                {
                    show: internalLine && !internalLine.rejected,
                    caption: cxt.t('Return'),
                    icon: <RedoIcon />,
                    onClick: () => returnLine(),
                },
                {
                    show: internalLine && !internalLine.rejected,
                    type: 'delete',
                    onClick: () => deleteLine(),
                },
                {
                    show: internalLine && !internalLine.rejected,
                    type: 'save',
                    onClick: () => saveLine(),
                },
            ]}
        />
    );
}
