import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Chip,
    Typography,
    Grid,
    ChipProps,
    GridProps,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
    labelWrapper: {
        position: 'absolute',
        top: -10,
        left: 8,
        padding: '0px 6px',
        zIndex: 10,
        borderRadius: 2,
        backgroundColor: '#fff',
    },
    label: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
    },
    chipContainer: {
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        paddingTop: 4,
        paddingBottom: 4,
    },
    chipContainerOutline: {
        borderColor: '#c4c4c4',
        borderRadius: 4,
        borderStyle: 'solid',
        borderWidth: 1,
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        paddingTop: 4,
        paddingBottom: 4,
    },
    chip: {
        marginTop: 4,
        marginBottom: 4,
        marginRight: 8,
        cursor: 'pointer',
    },
    chipOutline: {
        margin: 4,
        cursor: 'pointer',
    },
});

type ChipItem = {
    label: string;
    value: string;
};

type SttSelectableChipsProps = {
    grid?: boolean;
    name: string;
    caption?: string;
    items: ChipItem[];
    selected: string[];
    onChange: ({
        name,
        value,
    }: {
        name: string;
        value: string | string[];
    }) => void;
    ChipComponent?: typeof Chip;
    chipSize?: ChipProps['size'];
    variant?: 'outlined' | 'regular';
} & Omit<GridProps, 'onChange'>;

/**
 * SttSelectableChips: toggle one or many of a list of simple items. They are rendered as chips and accept a specific chip component
 */
export function SttSelectableChips({
    grid = false,
    name,
    caption,
    items,
    selected,
    onChange,
    ChipComponent = Chip,
    chipSize = 'medium',
    variant = 'regular',
    ...rest
}: SttSelectableChipsProps) {
    const classes = useStyles();

    function select(value: string, isSelected: boolean) {
        if (isSelected) {
            onChange({ name, value: [...selected, value] });
        } else if (selected) {
            selected.splice(selected.indexOf(value), 1);
            onChange({ name, value: selected });
        }
    }

    const content = (
        <Box position="relative">
            {caption && (
                <div className={classes.labelWrapper}>
                    <Typography className={classes.label}>{caption}</Typography>
                </div>
            )}
            <Box
                className={
                    variant === 'outlined'
                        ? classes.chipContainerOutline
                        : classes.chipContainer
                }
            >
                {items.map((item, i) => {
                    const isSelected =
                        selected.find((val) => val === item.value) !==
                        undefined;
                    return (
                        <ChipComponent
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                            size={chipSize}
                            className={
                                variant === 'outlined'
                                    ? classes.chipOutline
                                    : classes.chip
                            }
                            label={item.label}
                            color={isSelected ? 'primary' : 'default'}
                            onClick={() => select(item.value, !isSelected)}
                        />
                    );
                })}
            </Box>
        </Box>
    );

    return grid ? (
        <Grid item {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
