import React, { useState, useEffect, useContext } from 'react';
import { AppContext, SttTable, SttTopControls } from '../../../sporttia/all';
import FormQuestionDialog from '../../../components/dialogs/FormQuestionDialog';

// Pestaña 'Formulario' para las actividades
export default function ActivityForm({ item, onChange }) {
    const [questions, setQuestions] = useState(null);
    const [viewQuestion, setViewQuestion] = useState(null);

    const cxt = useContext(AppContext);

    // Si cambia el item, recargamos el form (necesario al actualizar)
    useEffect(() => {
        if (item.form) {
            setQuestions({ count: item.form.count, rows: item.form });
        }
    }, [item]);

    function deleteForm(form) {
        setQuestions({
            rows: questions.rows.filter(
                (currentQuestion) => currentQuestion?.id !== form?.id,
            ),
        });
    }

    return (
        <>
            <SttTopControls
                mb={6}
                mainAction={{
                    type: 'create',
                    onClick: () => setViewQuestion({}),
                }}
            />
            <SttTable
                data={questions}
                hidePagination
                columns={[
                    {
                        title: cxt.t('Question'),
                        align: 'left',
                        type: 'link',
                        field: 'name',
                        onClick: (row) => setViewQuestion(row),
                    },
                    {
                        title: cxt.t('Type'),
                        type: 'text',
                        field: 'typeLang',
                    },
                    {
                        title: cxt.t('Mandatory'),
                        type: 'active',
                        value: (row) => !!row.mandatory,
                    },
                ]}
            />
            <FormQuestionDialog
                parentCategory="activities"
                idParent={item.id}
                item={viewQuestion}
                onSave={onChange}
                onDelete={deleteForm}
                onClose={() => setViewQuestion(null)}
            />
        </>
    );
}
