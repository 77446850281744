import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Typography from '@material-ui/core/Typography';
import { SttLabelWeekdays } from '../labels/SttLabelWeekdays';
import { SttList } from './SttList';
import { AppContext } from '../AppContext';
import { useCrud } from '../../lib/hooks';
import { SttFormText } from '../forms/SttFormText';
import { SttButton } from '../buttons/SttButton';

export default function SttTagsList({ activity }) {
    const cxt = useContext(AppContext);

    const [openAddingTag, setOpenAddingTag] = useState(false);
    const [, , , Get, Post, Put, Delete] = useCrud();
    const [scTags, setScTags] = useState();
    const [tags, setTags] = useState();
    const [newTag, setNewTag] = useState('');

    const getSportCenterTags = () => {
        if (cxt.sc?.id)
            Get(`/scs/${cxt.sc.id}/tags`).then((response) =>
                setScTags(response),
            );
    };

    const addSportCenterTag = () => {
        if (cxt.sc?.id)
            Post(`/scs/tags`, { name: newTag, description: newTag }).then(
                (response) => {
                    getSportCenterTags();
                },
            );
    };

    const getActivityTags = () => {
        if (cxt.sc?.id && activity)
            Get(`/activities/${activity.id}`, { trash: true }).then(
                (response) => setTags(response?.activity?.tags),
            );
    };

    const addActivityTag = (tagId) => {
        Post(`/scs/activities/${activity.id}/tags/${tagId}`).then(
            (response) => {
                getActivityTags();
            },
        );
    };

    const deleteActivityTag = (tagId) => {
        Delete(`/scs/activities/${activity.id}/tags/${tagId}`).then(
            (response) => {
                getActivityTags();
            },
        );
    };

    useEffect(() => {
        if (openAddingTag) getSportCenterTags();
    }, [openAddingTag]);

    useEffect(() => {
        setTags(activity?.tags);
    }, [activity]);

    return (
        <>
            <SttList
                title={cxt.t('Tags')}
                onAdd={() => setOpenAddingTag(true)}
                onDelete={(tag) => deleteActivityTag(tag.id)}
                // onSelect={slot => console.log(`Selecciono slot: ${JSON.stringify(slot)}`)}
                data={tags?.map((tag) => ({
                    caption: (
                        <Box display="flex">
                            <Typography>{tag.name}</Typography>
                        </Box>
                    ),
                    objToSelect: tag,
                }))}
            />

            <Dialog
                onClose={() => setOpenAddingTag(false)}
                open={openAddingTag}
            >
                <DialogTitle>{cxt.t('Tags')}</DialogTitle>
                <Box pl={3} pr={3}>
                    <Grid container>
                        <Grid item xs={10}>
                            <SttFormText
                                name="newTag"
                                caption={cxt.t('NewTag')}
                                defVal={newTag}
                                onChange={(tag) => setNewTag(tag.value)}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <IconButton
                                disabled={!newTag}
                                onClick={() => addSportCenterTag()}
                            >
                                <AddIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <List>
                        {scTags &&
                            scTags.rows.map((scTag, idx) => (
                                <ListItem
                                    button
                                    key={idx}
                                    onClick={() => addActivityTag(scTag.id)}
                                >
                                    <ListItemIcon>
                                        <LocalOfferIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={scTag.name} />
                                </ListItem>
                            ))}
                    </List>
                </Box>
            </Dialog>
        </>
    );
}
