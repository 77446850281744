import React, { useContext } from 'react';
import { AppContext } from '../../sporttia/AppContext';
import SCHome from '../sc/SCHome';
import AdminHome from '../admin/AdminHome';

export default function Home() {
    const cxt = useContext(AppContext);

    /**
     * Render Home according to user role.
     * @returns {JSX.Element} Home element.
     */
    function renderHome() {
        if (cxt.user && cxt.user.role === 'SPORTCENTER') {
            return <SCHome />;
        }
        if (cxt.user && cxt.user.role === 'ADMIN') {
            return <AdminHome />;
        }
    }

    return <>{renderHome()}</>;
}
