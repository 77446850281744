import React, { useContext, useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Paper,
    makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SportsIcon from '@material-ui/icons/Sports';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleIcon from '@material-ui/icons/People';
import { Chart } from 'react-charts';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { formatPriceByLocale } from '../../../lib/utils';
import { AppContext } from '../../../sporttia/all';
import SttFoldingCubeSpinner from '../../../sporttia/spinners/SttFoldingCubeSpinner';

export default function SCStats() {
    const cxt = useContext(AppContext);
    const [stats, setStats] = useState();
    const [year] = useState(parseInt(moment().format('YYYY')));
    const [totalBookings, setTotalBookings] = useState(0);
    const [totalIncome, setTotalIncome] = useState(0);
    const [loadedStats, setLoadedStats] = useState(true);

    const graphColors = ['#bbece5', '#35c7b3'];

    useEffect(() => {
        cxt.setHeader({ title: cxt.sc.name });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cxt.sc]);

    const getStats = () => {
        cxt.api('GET', `/scs/${cxt.sc.id}/stats`, {
            params: {
                years: [year - 1, year].join(','),
                bookingsByMonth: true,
                incomeByMonth: true,
                followersByMonth: true,
            },
            success: (r) => {
                setStats(r);
                setLoadedStats(false);

                // Calculate total bookings and income
                let bks = 0;
                let income = 0;
                for (let i = 1; i < 13; i++) {
                    bks += r.years[year][i].numBookings;
                    income += r.years[year][i].income;
                }
                setTotalBookings(bks);
                setTotalIncome(income);
            },
        });
    };

    /**
     * Init
     */
    useEffect(() => {
        if (cxt.sc?.id) {
            getStats();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cxt.sc?.id]);

    /**
     * Get stats
     */
    const containsFinancialInfo =
        stats &&
        !Number.isNaN(parseFloat(stats?.totalDebt, 10)) &&
        !Number.isNaN(parseFloat(stats?.totalPurse, 10));

    // Render
    return (
        <div>
            {loadedStats && (
                <Box align="center" mt={18}>
                    <SttFoldingCubeSpinner active={loadedStats} />
                </Box>
            )}
            {stats && (
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3} lg={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={12}>
                                <HomeCard
                                    title={cxt.t('Followers')}
                                    subtitle={cxt.t('Total')}
                                    label={cxt.sc.numFollowers}
                                    icon={<PeopleIcon />}
                                />
                            </Grid>

                            {containsFinancialInfo && (
                                <Grid item xs={12} sm={6} md={12}>
                                    <HomeCard
                                        title={cxt.t('Income')}
                                        subtitle={year}
                                        label={formatPriceByLocale(
                                            totalIncome.toFixed(2),
                                            cxt.sc?.city?.country?.currency,
                                        )}
                                        icon={<AttachMoneyIcon />}
                                    />
                                </Grid>
                            )}

                            {containsFinancialInfo && (
                                <Grid item xs={12} sm={6} md={12}>
                                    <HomeCard
                                        title={cxt.t('Debt')}
                                        subtitle={cxt.t('Total')}
                                        label={formatPriceByLocale(
                                            stats.totalDebt.toFixed(2),
                                            cxt.sc?.city?.country?.currency,
                                        )}
                                        icon={<SentimentVeryDissatisfiedIcon />}
                                    />
                                </Grid>
                            )}

                            {containsFinancialInfo && (
                                <Grid item xs={12} sm={6} md={12}>
                                    <HomeCard
                                        title={cxt.t('Purse')}
                                        subtitle={cxt.t('Total')}
                                        label={formatPriceByLocale(
                                            stats.totalPurse.toFixed(2),
                                            cxt.sc?.city?.country?.currency,
                                        )}
                                        icon={<LocalAtmIcon />}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12} sm={6} md={12}>
                                <HomeCard
                                    title={cxt.t('Bookings')}
                                    subtitle={year}
                                    label={totalBookings}
                                    icon={<SportsIcon />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={9} lg={10}>
                        {stats && (
                            <>
                                <HomeChart
                                    title={cxt.t('Bookings')}
                                    colors={graphColors}
                                    years={Object.keys(stats.years)}
                                    data={[
                                        stats.years[year - 1] && {
                                            label: year - 1,
                                            data: Object.keys(
                                                stats.years[year - 1],
                                            ).map((month) => [
                                                cxt.t(`month.${month}`),
                                                stats.years[year - 1][month]
                                                    .numBookings,
                                            ]),
                                        },
                                        {
                                            label: year,
                                            data: Object.keys(
                                                stats.years[year],
                                            ).map((month) => [
                                                cxt.t(`month.${month}`),
                                                stats.years[year][month]
                                                    .numBookings,
                                            ]),
                                        },
                                    ]}
                                />

                                {containsFinancialInfo && (
                                    <HomeChart
                                        title={cxt.t('Income')}
                                        colors={graphColors}
                                        years={Object.keys(stats.years)}
                                        data={[
                                            stats.years[year - 1] && {
                                                label: year - 1,
                                                data: Object.keys(
                                                    stats.years[year - 1],
                                                ).map((month) => [
                                                    cxt.t(`month.${month}`),
                                                    stats.years[year - 1][month]
                                                        .income,
                                                ]),
                                            },
                                            {
                                                label: year,
                                                data: Object.keys(
                                                    stats.years[year],
                                                ).map((month) => [
                                                    cxt.t(`month.${month}`),
                                                    stats.years[year][month]
                                                        .income,
                                                ]),
                                            },
                                        ]}
                                    />
                                )}

                                <HomeChart
                                    title={cxt.t('Followers')}
                                    colors={graphColors}
                                    years={Object.keys(stats.years)}
                                    data={[
                                        stats.years[year - 1] && {
                                            label: year - 1,
                                            data: Object.keys(
                                                stats.years[year - 1],
                                            ).map((month) => [
                                                cxt.t(`month.${month}`),
                                                stats.years[year - 1][month]
                                                    .numFollowers,
                                            ]),
                                        },
                                        {
                                            label: year,
                                            data: Object.keys(
                                                stats.years[year],
                                            ).map((month) => [
                                                cxt.t(`month.${month}`),
                                                stats.years[year][month]
                                                    .numFollowers,
                                            ]),
                                        },
                                    ]}
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
            )}
        </div>
    );
}

/**
 * HomeChart
 */
function HomeChart({ title, data, colors, years }) {
    const useStyles = makeStyles({
        legend: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 16,
        },
        box: {
            width: 14,
            height: 14,
            marginRight: 8,
        },
        text: {
            fontSize: 14,
            color: '#7b7b7b',
        },
    });

    const classes = useStyles();

    return (
        <Box mb={2}>
            <Paper>
                <Box p={2}>
                    <Box mb={2} display="flex">
                        <b>{title}</b>
                        <>
                            {years.map((year, i) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Box className={classes.legend} key={i}>
                                    <div
                                        className={classes.box}
                                        style={{
                                            backgroundColor: colors[i],
                                        }}
                                    />
                                    <span className={classes.text}>{year}</span>
                                </Box>
                            ))}
                        </>
                    </Box>

                    <Box style={{ height: 200 }}>
                        <Chart
                            tooltip
                            series={{
                                type: 'bar',
                            }}
                            getSeriesStyle={(series) => ({
                                color: colors[series.index],
                            })}
                            data={data}
                            axes={[
                                {
                                    primary: true,
                                    type: 'ordinal',
                                    position: 'bottom',
                                },
                                {
                                    position: 'left',
                                    type: 'linear',
                                    stacked: false,
                                },
                            ]}
                        />
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}

/**
 * Counter Card
 */
function HomeCard({ title, subtitle, label, icon }) {
    return (
        <Card>
            <CardHeader
                title={title}
                subheader={subtitle}
                avatar={
                    <Avatar
                        style={{ backgroundColor: 'white', color: 'black' }}
                    >
                        {icon}
                    </Avatar>
                }
            />

            <CardContent>
                <Box mt={0} style={{ fontSize: 25, textAlign: 'center' }}>
                    {label}
                </Box>
            </CardContent>
        </Card>
    );
}
