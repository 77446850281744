import React, { useState, useContext } from 'react';
import { Box, Paper, Grid, Typography } from '@material-ui/core';
import {
    AppContext,
    SttFormText,
    SttButton,
    SttFormPrice,
} from '../../../sporttia/all';
import { formatPriceByLocale, getErrorMessage } from '../../../lib/utils';
import POSPaymentDialog from '../../../components/dialogs/POSPaymentDialog';
import POSStripePaymentDialog from '../../../components/stripe/POSStripePaymentDialog';
import constants from '../../../config/constants';
import translations from '../../../translations';
import useProductsService from '../../../services/ProductsService';
import ModalConfirmation from '../../../layout/ModalConfirmation';
import { useLoader } from '../../../lib/hooks';
import { PaymentForm, PosPaymentParams } from '../../../types/payment';
import ProductTicket from '../../../types/models/ProductTicket';
import Mship from '../../../types/models/Mship';
import CashdroControlDialog from '../../../components/dialogs/CashdroControlDialog';

type ReturnParams = {
    productTicket: ProductTicket;
};

type SalePointPaymentProps = {
    ticket: ProductTicket;
    mship: Mship;
    total: number;
    onUpdateTicket: (ticket: ProductTicket) => void;
    onReset: (closedTicket: ProductTicket) => void;
};

export default function SalePointPayment({
    ticket,
    mship,
    total,
    onUpdateTicket,
    onReset,
}: SalePointPaymentProps) {
    const cxt = useContext(AppContext)!;

    const [cashIn, setCashIn] = useState(0);
    const [posPaymentParams, setPosPaymentParams] =
        useState<PosPaymentParams<ReturnParams> | null>(null);

    const [selectedPaymentForm, setSelectedPaymentForm] =
        useState<PaymentForm>();

    const [ticketForDelayedUpdate, setTicketForDelayedUpdate] =
        useState<ProductTicket | null>();

    const [cashdroPayment, setCashdroPayment] = useState(false);
    const [cashdroPaymentAmount, setCashdroPaymentAmount] = useState(0);

    const CASHDRO = cxt?.sc?.cashdroConnected;

    const rest = parseInt(cashIn.toString()) > 0 ? cashIn - total : 0;

    const productsServices = useProductsService();
    const closeProductTicketMutation = productsServices.useCloseProductTicket({
        onSuccess: (result) => {
            const { tpv, productTicket } = result;

            // Pay with TPV
            if (tpv) {
                if (
                    tpv.payment?.paymentForm ===
                    constants.payment.paymentForms.dataphoneConnected.name
                ) {
                    // params for the POS dialog (datáfono físico)
                    setPosPaymentParams({
                        amount: tpv.price,
                        concept: tpv.concept,
                        idTpv: tpv.id,
                        returnParams: {
                            productTicket,
                        },
                        sc: {
                            id: tpv?.sc?.id,
                            scName: tpv?.sc?.short,
                            entityName: tpv?.sc?.customer?.name,
                            address: tpv?.sc?.address,
                            cif: tpv?.sc?.customer?.cif,
                            phone: tpv?.sc?.phonePublic,
                        },
                    });
                }
            } else {
                cxt.showMessage('S', cxt.t(translations.generic.ticketClosed));
                onUpdateTicket(productTicket);
                onReset(productTicket);
            }
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });

    const setupPayment = () => {
        if (
            CASHDRO &&
            selectedPaymentForm === constants.payment.paymentForms.cashdro.name
        ) {
            setCashdroPayment(true);
            setCashdroPaymentAmount(total);
        } else {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            closeTicket(null);
        }
    };

    const closeTicket = (amount: number | null) => {
        closeProductTicketMutation.mutate({
            id: ticket.id,
            params: {
                // @ts-expect-error no hay tiempo
                paymentForm:
                    selectedPaymentForm ===
                    constants.payment.paymentForms.later.name
                        ? constants.payment.pendingPaymentForm
                        : selectedPaymentForm,
                paid: amount || cashIn,
                idUser: mship?.user?.id,
            },
        });

        setSelectedPaymentForm(undefined);
    };

    const cashdroPaymentSuccess = () => {
        closeTicket(total);
    };

    const [, loader] = useLoader([closeProductTicketMutation.status]);

    return (
        <>
            {loader}
            <Box mt={3}>
                <Paper>
                    <Box p={3}>
                        <Box mb={2}>
                            <Typography variant="h5">
                                {cxt.t(translations.generic.collect)}
                            </Typography>
                        </Box>

                        <Grid container spacing={3}>
                            <SttFormText
                                grid
                                disabled
                                md={4}
                                name="name"
                                caption={cxt.t(translations.generic.total)}
                                defVal={formatPriceByLocale(
                                    total,
                                    cxt.sc!.city!.country!.currency,
                                )}
                            />

                            <SttFormPrice
                                grid
                                md={4}
                                name="cashIn"
                                caption={cxt.t('CashIn')}
                                defVal={String(cashIn)}
                                onChange={({ value }) => {
                                    const parsedValue = Number(value);

                                    if (Number.isNaN(parsedValue)) {
                                        return;
                                    }

                                    setCashIn(parsedValue);
                                }}
                            />

                            <SttFormText
                                grid
                                disabled
                                md={4}
                                name="rest"
                                caption={cxt.t('Rest')}
                                defVal={formatPriceByLocale(
                                    rest,
                                    cxt.sc!.city!.country!.currency,
                                )}
                            />
                        </Grid>

                        {[
                            constants.payment.paymentForms.cash,
                            constants.payment.paymentForms.purse,
                            cxt?.sc?.dataphoneConnected
                                ? constants.payment.paymentForms
                                      .dataphoneConnected
                                : constants.payment.paymentForms.dataphone,
                            constants.payment.paymentForms.later,
                            constants.payment.paymentForms.bank,
                        ]
                            .concat(
                                // @ts-expect-error no hay tiempo
                                CASHDRO
                                    ? [constants.payment.paymentForms.cashdro]
                                    : [],
                            )
                            .map((paymentForm) => (
                                <Box key={paymentForm.name} mt={2}>
                                    <SttButton
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        caption={cxt.t(
                                            paymentForm.translationCode,
                                        )}
                                        onClick={() => {
                                            setSelectedPaymentForm(
                                                paymentForm.name,
                                            );
                                        }}
                                    />
                                </Box>
                            ))}
                    </Box>
                </Paper>
            </Box>

            {selectedPaymentForm && (
                <ModalConfirmation
                    show
                    onAccept={setupPayment}
                    onClose={() => {
                        setSelectedPaymentForm(undefined);
                    }}
                />
            )}

            {cxt.sc?.sporttiaStripeLocationId && posPaymentParams !== null ? (
                <POSStripePaymentDialog
                    paymentData={posPaymentParams}
                    onClose={() => setPosPaymentParams(null)}
                    onSuccess={() => {
                        const { productTicket } = posPaymentParams.returnParams;
                        cxt.showMessage(
                            'S',
                            cxt.t(translations.generic.ticketClosed),
                        );
                        onUpdateTicket(productTicket);
                        onReset(productTicket);
                    }}
                />
            ) : (
                <POSPaymentDialog
                    open={posPaymentParams !== null}
                    paymentData={posPaymentParams}
                    onClose={() => {
                        setPosPaymentParams(null);
                        if (ticketForDelayedUpdate) {
                            onUpdateTicket(ticketForDelayedUpdate);
                            onReset(ticketForDelayedUpdate);
                            setTicketForDelayedUpdate(null);
                        }
                    }}
                    onSuccess={(t: unknown, returnParams: ReturnParams) => {
                        const { productTicket } = returnParams;
                        cxt.showMessage(
                            'S',
                            cxt.t(translations.generic.ticketClosed),
                        );

                        setTicketForDelayedUpdate(productTicket);
                    }}
                />
            )}

            {cashdroPayment && (
                <CashdroControlDialog
                    open={cashdroPayment}
                    amount={cashdroPaymentAmount}
                    onClose={() => {
                        setCashdroPayment(false);
                        setCashdroPaymentAmount(0);
                    }}
                    onSuccess={() => {
                        setCashdroPayment(false);
                        setCashdroPaymentAmount(0);
                        cashdroPaymentSuccess();
                    }}
                />
            )}
        </>
    );
}
