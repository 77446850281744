import React, { useContext, useEffect, useState } from 'react';
import { Paper, Box, Grid, Typography } from '@material-ui/core';
import { SttDialog, SttFormDate, SttFormText, SttSelectGender } from '../all';
import { AppContext } from '../AppContext';
import { getErrorMessage, subsetObject } from '../../lib/utils';
import { GENDER } from '../selectors/SttSelectGender';

interface User {
    id: number;
    login: string;
    name: string;
    fullName: string;
    surname1: string;
    surname2?: string | null;
    dni?: string | null;
    phone?: string | null;
    birthday?: string | null;
    gender?: string | null;
    address?: string | null;
    lastVisit?: string | null;
}

interface SportCenter {
    id: number;
    name: string;
    short: string;
    domain: string;
}

interface Unlocker {
    id: number;
    type: string;
    code: string;
}

interface Pupil {
    id: number;
    user: User;
    sc: SportCenter;
    unlockers: Unlocker[];
}

interface SttPupilDialogProps {
    open: boolean;
    pupil: Pupil | null;
    onClose: () => void;
    onSave: (user: User) => void;
}

export default function SttPupilDialog({
    open,
    pupil,
    onClose,
    onSave,
}: SttPupilDialogProps): JSX.Element {
    const cxt = useContext(AppContext);
    const [pupilData, setPupilData] = useState<Pupil | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (pupil) {
            setPupilData(pupil);
        }
    }, [pupil]);

    function savePupil() {
        if (!pupilData) return;

        setLoading(true);

        const params = subsetObject(
            pupilData.user,
            'name,surname1,surname2,dni,birthday,gender,address',
        );

        cxt?.api('PUT', `/users/${pupilData.user.id}`, {
            params: {
                ...params,
            },
            success: (response: { user: User }) => {
                onSave?.(response.user);
                onClose();
            },
            error: (error) => {
                cxt.showMessage('E', getErrorMessage(error));
            },
        });
    }

    function handleChange({ name, value }: { name: string; value: string }) {
        setPupilData((prevPupilData) => {
            if (!prevPupilData) return null;
            return {
                ...prevPupilData,
                user: {
                    ...prevPupilData.user,
                    [name]: value,
                },
            };
        });
    }

    // -----| Render |-----
    return (
        <SttDialog
            title={pupilData ? `${pupilData.user.login}` : ''}
            open={open}
            onClose={onClose}
            content={
                pupilData && (
                    <Paper>
                        <Box p={2}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {cxt?.t(
                                            'app.account.pupils.pupilsData',
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <SttFormText
                                        name="name"
                                        caption={cxt?.t('Name')}
                                        defVal={pupilData.user?.name}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <SttFormText
                                        name="surname1"
                                        caption={cxt?.t('Surname1')}
                                        defVal={pupilData.user?.surname1}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <SttFormText
                                        name="surname2"
                                        caption={cxt?.t('Surname2')}
                                        defVal={pupilData.user?.surname2 || ''}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <SttFormText
                                        name="dni"
                                        caption={cxt?.t('DNI')}
                                        defVal={pupilData.user?.dni || ''}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SttFormDate
                                        name="birthday"
                                        caption={cxt?.t('Birthday')}
                                        defVal={pupilData.user?.birthday}
                                        onChange={handleChange}
                                        disabled={false}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SttSelectGender
                                        grid
                                        md={4}
                                        sm={12}
                                        xs={12}
                                        name="gender"
                                        caption={cxt?.t('Gender')}
                                        defVal={
                                            pupilData.user?.gender ||
                                            GENDER.UNKNOWN
                                        }
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={8}>
                                    <SttFormText
                                        name="address"
                                        caption={cxt?.t('Address')}
                                        defVal={pupilData.user?.address || ''}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                )
            }
            buttons={[
                {
                    type: 'save',
                    onClick: savePupil,
                    disabled: loading,
                },
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
        />
    );
}
