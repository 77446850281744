import React, { useState, useContext } from 'react';
import { Paper } from '@material-ui/core';
import {
    AppContext,
    SttChipFacilityRow,
    SttLabelMonths,
    SttButtonFab,
} from '../../../sporttia/all';
import { SttTopFilteringControls } from '../../../sporttia/SttTopFilteringControls';
import SttCachedTable from '../../../sporttia/SttCachedTable';
import { usePageHeader, useQueryFilters } from '../../../lib/hooks';
import { SPORTCENTER_FREE_SCHEDULES } from '../../../lib/queryKeys';
import FreeSchedule from './FreeSchedule';
import useScsService from '../../../services/ScsService';

export default function FreeSchedules() {
    usePageHeader('timetables');

    const cxt = useContext(AppContext);
    const [freeScheduleId, setFreeScheduleId] = useState(null);
    const scsServices = useScsService();
    const [filters, setFilters] = useQueryFilters(
        { isActive: true },
        SPORTCENTER_FREE_SCHEDULES,
    );

    return (
        <Paper>
            <SttTopFilteringControls
                p={2}
                fields={[
                    {
                        caption: cxt.t('Name'),
                        name: 'name',
                        type: 'text',
                        value: filters.name,
                    },
                ]}
                extraFields={[
                    {
                        caption: cxt.t('Active'),
                        name: 'isActive',
                        type: 'check',
                        checked: filters.isActive,
                    },
                ]}
                onFilter={(value) => setFilters(value)}
                trashAction
            />

            <SttCachedTable
                queryKey={SPORTCENTER_FREE_SCHEDULES}
                queryFn={(params) =>
                    scsServices.getFreeSchedules(cxt?.sc?.id, params)
                }
                queryParams={filters}
                prefetching={false}
                columns={[
                    {
                        title: cxt.t('Layer'),
                        field: 'layer',
                        value: (row) => (row.layer ? row.layer : 'B'),
                    },
                    {
                        type: 'link',
                        title: cxt.t('Name'),
                        field: 'name',
                        onClick: (row) => {
                            setFreeScheduleId({ id: row.id });
                        },
                    },
                    {
                        type: 'duration',
                        title: cxt.t('Advance'),
                        field: 'preBookingIni',
                    },
                    {
                        type: 'period',
                        title: cxt.t('Period'),
                        value: (row) => ({ ini: row.ini, end: row.end }),
                    },
                    {
                        title: cxt.t('Months'),
                        value: (row) => <SttLabelMonths months={row.months} />,
                    },
                    {
                        title: cxt.t('Facilities'),
                        value: (row) => (
                            <SttChipFacilityRow facilities={row.facilities} />
                        ),
                    },
                ]}
            />

            {freeScheduleId && (
                <FreeSchedule
                    idFreeSchedule={freeScheduleId.id}
                    onClose={() => {
                        setFreeScheduleId(null);
                    }}
                />
            )}

            <SttButtonFab
                onClick={() => {
                    setFreeScheduleId({ id: 'create' });
                }}
            />
        </Paper>
    );
}
