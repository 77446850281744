import React, { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { AppContext } from '../../../sporttia/AppContext';
import { SttTabs } from '../../../sporttia/SttTabs';
import UserData from './UserData';
import UserSports from './UserSports';
import UserNotifications from './UserNotifications';
import UserConnections from './UserConnections';

export default function UserConfiguration({}) {
    const cxt = useContext(AppContext);
    const [user, setUser] = useState();
    const [userSports, setUserSports] = useState([]);

    const loadUserData = () => {
        cxt.api('GET', `/me`, {
            success: (r) => {
                setUser(r.user);
                if (r.user.sports) setUserSports(r.user.sports);
            },
        });
    };

    useEffect(() => {
        loadUserData();
    }, []);

    // -----| Render |-----
    return (
        <Container maxWidth="md">
            <Paper>
                <SttTabs
                    tabs={[
                        {
                            caption: cxt.t('Data'),
                            component: <UserData userData={user} />,
                        },
                        {
                            caption: cxt.t('Sports'),
                            component: (
                                <UserSports
                                    userSportsList={userSports}
                                    onUpdate={loadUserData}
                                />
                            ),
                        },
                        {
                            caption: cxt.t('Notifications'),
                            component: <UserNotifications userData={user} />,
                        },
                        {
                            caption: cxt.t('Connections'),
                            component: <UserConnections userData={user} />,
                        },
                    ]}
                />
            </Paper>
        </Container>
    );
}
