/**
 * Sporttia palette
 *
 * To see palette: https://www.sporttia.com/images/palette.png
 */
export default {
    // Corporates
    A1: '#FBFF37',
    A2: '#F6FDC3',
    A3: '#EFF9B4',
    A4: '#E8F497',
    A5: '#DBEA7B',
    A6: '#CDDD62',
    A7: '#BACD44',
    A8: '#9EB319',
    A9: '#869816',
    B1: '#FEEFFF',
    B2: '#F6D4F9',
    B3: '#EDB6F2',
    B4: '#E599EC',
    B5: '#DF7FE8',
    B6: '#D563DF',
    B7: '#CD4CD9',
    B8: '#AC21B9',

    // Modules: Booking, Events, Activities, Games
    MB1: '#FFF4F4',
    MB2: '#FDD7D7',
    MB3: '#FBB0B0',
    MB4: '#FB8181',
    MB5: '#FF5353',
    MB6: '#DD2929',
    ME1: '#E9FFED',
    ME2: '#AEF9BC',
    ME3: '#73E888',
    ME4: '#46DF62',
    ME5: '#19CC3A',
    ME6: '#06A423',
    MA1: '#FFEEF7',
    MA2: '#FFD5ED',
    MA3: '#FFADDC',
    MA4: '#FB8ACA',
    MA5: '#FD66BC',
    MA6: '#D92B8E',
    MG1: '#EFF2FF',
    MG2: '#C1CCFF',
    MG3: '#9BADFB',
    MG4: '#728CFF',
    MG5: '#4768FD',
    MG6: '#2749E1',

    // Users
    MU1: '#FFF0D8',
    MU2: '#FBDFB2',
    MU3: '#FBD18E',
    MU4: '#728CFF',
    MU5: '#EEB04B',
    MU6: '#E69B22',

    // Grays
    G1: '#F5F5F5',
    G2: '#DDD',
    G3: '#999',
    G4: '#666',
    G5: '#333',

    green: '#19971a',
    red: '#df3525',
};
