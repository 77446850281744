import React, { useState, useEffect, useContext } from 'react';
import {
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import { AppContext, SttList } from '../all';
import { formatPriceByLocale } from '../../lib/utils';

/**
 *	Price list
 *	=========
 *
 *	Props
 *
 *		* array prices
 *		* function onAdd
 *		* function onDelete(price)
 */
export function SttPriceList({ prices, onAdd, onDelete, ...rest }) {
    const cxt = useContext(AppContext);
    const [selectingPriceOpen, setSelectingPriceOpen] = useState(false);
    const [allPrices, setAllPrices] = useState();

    /**
     * Open adding price
     */
    const openAddingPrice = () => {
        setSelectingPriceOpen(true);
    };

    /**
     * Load all prices
     */
    useEffect(() => {
        // Fetch all prices on the sports center
        if (selectingPriceOpen && !allPrices) {
            cxt.api('GET', `/scs/${cxt.sc.id}/rates`, {
                params: { rows: 200 },
                success: (r) => {
                    setAllPrices(r.rows);
                },
            });
        }
    }, [allPrices, cxt, selectingPriceOpen]);

    /**
     * Select price
     */
    const selectPrice = (price) => {
        setSelectingPriceOpen(false);
        onAdd(price);
    };

    // -----| Render |-----
    return (
        <>
            <SttList
                title={cxt.t('Prices')}
                onAdd={onAdd ? () => openAddingPrice() : null}
                onDelete={onDelete}
                data={
                    prices &&
                    prices.map((price) => ({
                        caption: (
                            <>
                                {price.name} -{' '}
                                {formatPriceByLocale(
                                    price.price,
                                    price?.priceShape?.currency,
                                    price?.priceShape?.locale,
                                )}
                            </>
                        ),
                        objToSelect: price,
                    }))
                }
                {...rest}
            />

            <Dialog
                onClose={() => setSelectingPriceOpen(false)}
                open={selectingPriceOpen}
            >
                <DialogTitle>{cxt.t('Prices')}</DialogTitle>

                <List>
                    {allPrices &&
                        allPrices.map((price, idx) => (
                            <ListItem
                                button
                                key={idx}
                                onClick={() => selectPrice(price)}
                            >
                                <ListItemText primary={price.name} />
                            </ListItem>
                        ))}
                </List>
            </Dialog>
        </>
    );
}
