import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid } from '@material-ui/core';
import {
    AppContext,
    SttFormSearch,
    SttMshipList,
    SttDialog,
    SttButton,
} from '../all';
import UserCreationDialog from '../../components/dialogs/UserCreationDialog';

/**
 *
 * Props
 *
 * 	* boolean open
 * 	* function onClose
 * 	* function onSelectMship(mship)
 *
 */
export function SttSelectMshipDialog({ open = false, onClose, onSelectMship }) {
    const cxt = useContext(AppContext);
    const [mships, setMships] = useState();
    const [page, setPage] = useState(1);
    const [keywordSearch, setKewordSearch] = useState('');
    const [creatingUserDialogOpen, setCreatingUserDialogOpen] = useState(false);

    /**
     * Load mships
     */
    const loadMships = (p = { page: 1 }) => {
        const currPage = p.page !== undefined ? p.page : page;
        cxt.api('GET', `/scs/${cxt.sc.id}/mships`, {
            params: {
                page: currPage,
                rows: 6,
                keyword: keywordSearch,
            },
            success: (r) => {
                setMships(r);
                setPage(currPage);
            },
        });
    };

    /**
     * Open
     */
    useEffect(() => {
        if (open && !mships) {
            loadMships();
        }
    }, [open]);

    // -----| Render |-----
    return (
        <SttDialog
            title={cxt.t('Users')}
            open={open}
            onClose={onClose}
            content={
                <Box>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <SttFormSearch
                                    defVal={keywordSearch}
                                    onClickSearch={() =>
                                        loadMships({ page: 1 })
                                    }
                                    onChange={({ name, value }) =>
                                        setKewordSearch(value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <SttButton
                                    caption={cxt.t('create.user')}
                                    onClick={() =>
                                        setCreatingUserDialogOpen(true)
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <SttMshipList
                        mships={mships}
                        page={page}
                        onSelect={onSelectMship}
                        onLoadMships={loadMships}
                    />

                    <UserCreationDialog
                        open={creatingUserDialogOpen}
                        onClose={() => {
                            setCreatingUserDialogOpen(false);
                            loadMships();
                        }}
                    />
                </Box>
            }
        />
    );
}
