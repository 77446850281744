import React, { useContext, useEffect, useState } from 'react';
import {
    Avatar,
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemIcon,
    Divider,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { AppContext } from '../AppContext';
import { SttList } from './SttList';

export function SttTerrainList({ terrains, onCreate, onAdd, onDelete }) {
    const cxt = useContext(AppContext);

    const [selectionOpen, setSelectionOpen] = useState(false);
    const [allTerrains, setAllTerrains] = useState();

    function openAddTerrain() {
        setSelectionOpen(true);
    }

    /**
     * Load all terrains
     */
    useEffect(() => {
        // Fetch all terrains on the sports center
        if (selectionOpen && !allTerrains) {
            cxt.api('GET', `/scs/${cxt.sc.id}/terrains`, {
                params: { rows: 1000 },
                success: (response) => {
                    if (response.rows) {
                        setAllTerrains(response.rows);
                    }
                },
            });
        }
    }, [selectionOpen]);

    function selectTerrain(terrain) {
        setSelectionOpen(false);
        onAdd(terrain);
    }

    function createTerrain(terrain) {
        setSelectionOpen(false);
        onCreate(terrain);
    }

    // Filter terrains by culling from 'allTerrains' those already present in 'terrains'
    let filteredTerrains = [{ id: null, name: cxt.t('NewTerrain') }];
    if (allTerrains) {
        filteredTerrains = filteredTerrains.concat(
            allTerrains.filter(
                (terrain) => !terrains.find((t) => t.id === terrain.id),
            ),
        );
    }

    // -----| Render |-----
    return (
        <>
            <SttList
                title={cxt.t('Terrains')}
                onAdd={openAddTerrain}
                onDelete={onDelete}
                data={
                    terrains &&
                    terrains.map((terrain) => ({
                        caption: terrain.name,
                        objToSelect: terrain,
                    }))
                }
            />

            <Dialog
                onClose={() => setSelectionOpen(false)}
                open={selectionOpen}
            >
                <DialogTitle>{cxt.t('Terrains')}</DialogTitle>
                <List>
                    {filteredTerrains.map((terrain, idx) => (
                        <ListItem
                            button
                            key={idx}
                            onClick={
                                terrain.id
                                    ? () => selectTerrain(terrain)
                                    : () => createTerrain(terrain)
                            }
                        >
                            {!terrain.id && (
                                <ListItemIcon>
                                    <AddIcon />
                                </ListItemIcon>
                            )}

                            <ListItemText primary={terrain.name} />
                        </ListItem>
                    ))}
                </List>
            </Dialog>
        </>
    );
}
