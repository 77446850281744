import React, { useState, useEffect, useContext } from 'react';
import {
    Avatar,
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import { AppContext, SttList } from '../all';

/**
 *	SCAdmin list
 *	============
 *
 *	Props
 *
 *		* array scAdmins
 *		* function onAdd: after adding from the list
 *		* function onCreate: instead of picking from the list just call this func
 *		* function onDelete
 *		* functino onSelect
 */
export function SttSCAdminList({
    scAdmins,
    onCreate,
    onAdd,
    onDelete,
    onSelect,
}) {
    const cxt = useContext(AppContext);
    const [selectingSCAdminOpen, setSelectingSCAdminOpen] = useState(false);
    const [allSCAdmins, setAllSCAdmins] = useState();

    /**
     * Load all scAdmins
     */
    useEffect(() => {
        // Fetch all scAdmins on the sports center
        if (selectingSCAdminOpen && !allSCAdmins) {
            cxt.api('GET', `/scs/${cxt.sc.id}/admins`, {
                success: (r) => {
                    if (r.rows) {
                        setAllSCAdmins(r.rows);
                    }
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectingSCAdminOpen, allSCAdmins]);

    /**
     * Pre-adding
     */
    const preAdd = () => {
        if (onCreate) {
            onCreate();
        } else if (onAdd) {
            setSelectingSCAdminOpen(true);
        }
    };

    /**
     * Select scAdmin
     */
    const selectSCAdmin = (scAdmin) => {
        setSelectingSCAdminOpen(false);
        onAdd(scAdmin);
    };

    // -----| Render |-----
    return (
        <>
            <SttList
                title={cxt.t('SCAdmins')}
                onAdd={() => preAdd()}
                onDelete={onDelete}
                onSelect={onSelect}
                data={
                    scAdmins &&
                    scAdmins.map((scAdmin) => ({
                        avatar: scAdmin.sport ? (
                            <Avatar
                                style={{
                                    width: 30,
                                    height: 30,
                                    borderWidth: 1,
                                    borderStyle: 'solid',
                                    borderColor: '#ddd',
                                    padding: 2,
                                }}
                                src={cxt.getResource(
                                    `/sports/${scAdmin.sport.id}.png`,
                                )}
                            />
                        ) : null,
                        caption: scAdmin.name,
                        text: `@${scAdmin.login}`,
                        objToSelect: scAdmin,
                    }))
                }
            />

            <Dialog
                onClose={() => setSelectingSCAdminOpen(false)}
                open={selectingSCAdminOpen}
            >
                <DialogTitle>{cxt.t('SCAdmins')}</DialogTitle>

                <List>
                    {allSCAdmins &&
                        allSCAdmins.map((scAdmin) => (
                            <ListItem
                                button
                                key={scAdmin?.id}
                                onClick={() => selectSCAdmin(scAdmin)}
                            >
                                <ListItemText
                                    primary={scAdmin.name}
                                    secondary={`@${scAdmin.login}`}
                                />
                            </ListItem>
                        ))}
                </List>
            </Dialog>
        </>
    );
}
