import useRestService from './RestService';

const resource = '/search';

/**
 * Hook to access all 'search' resources.
 */
export default function useSearchService() {
    const services = useRestService(resource);

    return {
        ...services,
    };
}
