import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import {
    AppContext,
    SttDialog,
    SttFormCheck,
    SttFormText,
    SttFormTextarea,
    SttFormTime,
} from '../../../sporttia/all';
import { useCrud } from '../../../lib/hooks';

// TODO: Convertir este componente en el auténtico SttFileDialog
export default function ModalFile({
    item,
    onSave,
    onDelete,
    onDownload,
    onClose,
}) {
    const cxt = useContext(AppContext);
    const [file, setFile, setProperty] = useCrud(item);
    const [strippedName, setStrippedName] = useState();
    const [extension, setExtension] = useState();

    // On component load, separate file name and extension
    useEffect(() => {
        const parts = item.name.split('.');
        setExtension(parts[parts.length - 1]);
        setStrippedName(
            parts.reduce(
                (res, part, index) =>
                    res +
                    (index > 0 && index < parts.length - 1 ? `.${part}` : ''),
                parts[0],
            ),
        );
    }, []);

    // Idiot-proof file extension handling
    function setFileName(value) {
        setStrippedName(value);
        setProperty({ name: 'name', value: `${value}.${extension}` });
    }

    return (
        <SttDialog
            maxWidth="sm"
            onClose={onClose}
            open
            title={cxt.t('File')}
            subtitle={file.name}
            content={
                <Grid container spacing={3}>
                    <Grid item xs={9}>
                        <SttFormText
                            caption={cxt.t('Name')}
                            defVal={strippedName}
                            onChange={({ undefined, value }) =>
                                setFileName(value)
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SttFormText
                            disabled
                            caption={cxt.t('FileExtension')}
                            defVal={extension}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SttFormTextarea
                            caption={cxt.t('Description')}
                            name="description"
                            defVal={file.description}
                            onChange={setProperty}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                        <SttFormCheck
                            caption={cxt.t('popup.file.docPublic')}
                            name="public"
                            checked={file.public}
                            onChange={setProperty}
                        />
                    </Grid>
                </Grid>
            }
            buttons={[
                {
                    type: 'download',
                    onClick: () => onDownload(file),
                },
                {
                    type: 'delete',
                    onClick: () => onDelete(file),
                },
                {
                    type: 'save',
                    onClick: () => onSave(file),
                },
            ]}
        />
    );
}
