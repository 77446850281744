import React, { useState, useEffect, useContext } from 'react';
import {} from '@material-ui/core';
import { AppContext } from './all';

export function SttQR({ unlocker }) {
    const cxt = useContext(AppContext);

    // -----| Render |-----
    return (
        <img
            style={{
                maxWidth: 300,
            }}
            width="100%"
            src={cxt.getResource(`/unlockers/${unlocker.id}.svg`)}
        />
    );
}
