import React, { useState, useEffect, useContext } from 'react';
import { Grid, InputAdornment } from '@material-ui/core';
import {
    AppContext,
    SttDialog,
    SttFormText,
    SttSelectGroup,
} from '../../../sporttia/all';
import { useCrud } from '../../../lib/hooks';
import { formatPriceByLocale } from '../../../lib/utils';

export default function ModalCategory({
    item,
    eventName,
    teamable,
    onClose,
    onSave,
    onDelete,
    onRecover,
}) {
    const [category, setCategory, setProperty] = useCrud(item);

    const cxt = useContext(AppContext);

    useEffect(() => {
        setCategory(item);
    }, [item]);

    if (!category) {
        return null;
    }

    return (
        <SttDialog
            title={cxt.t('Category')}
            subtitle={eventName}
            maxWidth="sm"
            open
            onClose={onClose}
            content={
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SttFormText
                            caption={cxt.t('Name')}
                            name="name"
                            defVal={category.name}
                            onChange={setProperty}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SttFormText
                            caption={cxt.t('Description')}
                            name="description"
                            defVal={category.description}
                            onChange={setProperty}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SttFormText
                            caption={cxt.t('Inscription')}
                            name="enrollment"
                            defVal={category.enrollment}
                            onChange={setProperty}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {cxt.sc?.city?.country?.currencySymbol
                                            ? cxt.sc?.city?.country
                                                  ?.currencySymbol
                                            : '€'}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {teamable ? (
                            <SttFormText
                                caption={cxt.t('MaxTeams')}
                                name="maxTeams"
                                defVal={category.maxTeams}
                                onChange={setProperty}
                            />
                        ) : (
                            <SttFormText
                                caption={cxt.t('MaxParticipants')}
                                name="maxParticipants"
                                defVal={category.maxParticipants}
                                onChange={setProperty}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <SttSelectGroup
                            caption={cxt.t('MandatoryGroup')}
                            name="mandatory"
                            defVal={category.mandatory}
                            onChange={setProperty}
                            onlyActive
                        />
                    </Grid>
                </Grid>
            }
            buttons={[
                {
                    type: 'save',
                    onClick: () => onSave(category),
                },
                category.id &&
                    (category.trash
                        ? {
                              type: 'recover',
                              onClick: () => onRecover(category),
                          }
                        : {
                              type: 'delete',
                              onClick: () => onDelete(category),
                          }),
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
        />
    );
}
