import React, { useState, useContext } from 'react';
import { Chip, Paper } from '@material-ui/core';
import { AppContext } from '../../../sporttia/AppContext';
import { SttButtonFab, SttTopControls, SttTable } from '../../../sporttia/all';
import { usePageHeader, useToggle } from '../../../lib/hooks';
import Rate from './Rate';
import translations from '../../../translations';
import { copyToClipboard } from '../../../lib/utils';

export default function Rates() {
    usePageHeader(translations.generic.rates);

    const cxt = useContext(AppContext);

    const [rates, setRates] = useState([]);
    const [filters, setFilters] = useState({ name: '', trash: 0 });
    const [page, setPage] = useState(1);

    const [trash, toggleTrash] = useToggle(() => {
        // eslint-disable-next-line no-use-before-define
        loadRates();
    });

    const [rateChosen, setRateChosen] = useState({
        id: null,
        trash: null,
    });

    function loadRates(params = { page: 1, rows: 20 }) {
        cxt.api('GET', `/scs/${cxt.sc.id}/rates`, {
            params: {
                ...params,
                name: filters.name,
                trash,
            },
            success: (response) => {
                setRates(response);
            },
        });
    }

    return (
        <Paper>
            <SttTopControls
                p={2}
                fields={[
                    {
                        name: 'name',
                        type: 'text',
                        value: filters.name,
                        caption: cxt.t(translations.generic.name),
                        align: 'left',
                        onEnterKey: loadRates,
                    },
                ]}
                onChange={({ name, value }) =>
                    setFilters((prev) => ({ ...prev, [name]: value }))
                }
                onFilter={loadRates}
                onToggleTrash={toggleTrash}
            />
            <SttTable
                data={rates}
                loading={false}
                onChangePagination={(value) => {
                    setPage(value);
                }}
                onClickRow={(row) =>
                    setRateChosen({ id: row.id, trash: row.trash })
                }
                columns={[
                    {
                        title: '#',
                        type: 'tooltip',
                        value: (row) => ({ label: '#', value: row.id }),
                        onClick: (row) =>
                            copyToClipboard(row.id, () =>
                                cxt.showMessage(
                                    'S',
                                    cxt.t('copiedToClipboard'),
                                ),
                            ),
                    },
                    {
                        field: 'name',
                        title: cxt.t(translations.generic.name),
                        align: 'left',
                    },
                    {
                        field: 'individual',
                        width: 95,
                        title: cxt.t(translations.generic.individual),
                        type: 'active',
                        value: (row) => row.individual === true,
                    },
                    {
                        type: 'rate',
                        title: `${cxt.t(translations.generic.rate)} 1`,
                        value: (row) => row.prices[0],
                    },
                    {
                        type: 'rate',
                        title: `${cxt.t(translations.generic.rate)} 2`,
                        value: (row) => row.prices[1],
                    },
                    {
                        type: 'rate',
                        title: `${cxt.t(translations.generic.rate)} 3`,
                        value: (row) => row.prices[2],
                    },
                    {
                        title: cxt.t(translations.generic.groups),
                        value: (row) =>
                            row.groups?.map((item) => (
                                <Chip key={item.id} label={item.name} />
                            )),
                    },
                ]}
                onFetch={loadRates}
            />

            {rateChosen.id && (
                <Rate
                    item={rateChosen}
                    onClose={() => {
                        setRateChosen({ id: null, trash: null });
                        loadRates({ page, rows: 20 });
                    }}
                />
            )}

            <SttButtonFab
                onClick={() => setRateChosen({ id: 'create', trash: 0 })}
            />
        </Paper>
    );
}
