import React, { useState } from 'react';
import {
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';

interface ColumnPopupProps {
    open: boolean;
    onClose: () => void;
    onSave: (align: 'C' | 'L' | 'R') => void;
}

export function ColumnPopup({ open, onClose, onSave }: ColumnPopupProps) {
    const [align, setAlign] = useState<'C' | 'L' | 'R'>('C');

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setAlign(event.target.value as 'C' | 'L' | 'R');
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Configurar Columna</DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="align-select">Alineación</InputLabel>
                    <Select
                        id="align-select"
                        value={align}
                        onChange={handleChange}
                        label="Alineación"
                    >
                        <MenuItem value="C">Centrado</MenuItem>
                        <MenuItem value="L">Izquierda</MenuItem>
                        <MenuItem value="R">Derecha</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={() => onSave(align)} color="primary">
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
