import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    Avatar,
    Box,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from '@material-ui/core';
import { Sportcenter } from '../../types/api/models';
import { AppContext } from '../../sporttia/AppContext';
import useTpvsService from '../../services/TpvsService';
import SttValidatedForm from '../../sporttia/SttValidatedForm';
import { useGetStripeReaders, useGetStripeTerminal } from './hooks';
import { getErrorMessage } from '../../lib/utils';
import { useLoader } from '../../lib/hooks';

type StripeReader = {
    id: string;
    label: string;
    status: string | null;
};

type StripeConfigurationForm = {
    name: string;
    registrationCode: string;
};

type POSStripeConfigurationProps = {
    sc: Sportcenter;
};

/**
 * Configure Stripe Terminal readers
 */
export default function POSStripeConfiguration({
    sc,
}: POSStripeConfigurationProps) {
    const cxt = useContext(AppContext)!;
    const form = useForm<StripeConfigurationForm>();
    const [stripeReaders, setStripeReaders] = useState<StripeReader[]>([]);

    const tpvService = useTpvsService();

    const createTpvReaderMutation = tpvService.useCreateTpvReader({
        onSuccess: (result) => {
            setStripeReaders((prev) => [...prev, result]);

            form.reset();

            cxt.showMessage(
                'S',
                cxt.t('stripe.terminal.createTerminalSuccess'),
            );
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });

    const getStripeTerminal = useGetStripeTerminal(sc.id, {
        onConnection: () => {
            cxt.showMessage(
                'S',
                cxt.t('stripe.terminal.connectionTerminalSuccess'),
            );
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
        onDisconnect: () => {
            cxt.showMessage('W', cxt.t('stripe.terminal.terminalDisconnect'));
        },
    });

    const getStripeReaders = useGetStripeReaders(
        getStripeTerminal.terminal,
        sc.sporttiaStripeLocationId,
        {
            onSuccess: (readers) => {
                setStripeReaders((prev) => [...prev, ...readers]);
            },
            onError: (error) => {
                cxt.showMessage('E', getErrorMessage(error));
            },
        },
    );

    const [, loader] = useLoader([
        getStripeTerminal.status,
        getStripeReaders.status,
    ]);

    return (
        <>
            {loader}
            <SttValidatedForm
                form={form}
                fields={[
                    {
                        type: 'textInput',
                        name: 'name',
                        caption: cxt.t('STRIPE.TERMINAL.LABEL'),
                        xs: 12,
                        rules: {
                            required: true,
                        },
                    },
                    {
                        type: 'textInput',
                        name: 'registrationCode',
                        caption: cxt.t('STRIPE.TERMINAL.CODE'),
                        xs: 12,
                        rules: {
                            required: true,
                        },
                    },
                ]}
                buttons={[
                    {
                        type: 'save',
                        onClick: form.handleSubmit((formData) => {
                            createTpvReaderMutation.mutate({
                                ...formData,
                                scId: sc.id,
                            });
                        }),
                    },
                ]}
            />
            <Box pt={3}>
                {stripeReaders.map((reader) => (
                    <div key={reader.id}>
                        <ListItem button>
                            <ListItemAvatar>
                                <Avatar
                                    style={{
                                        width: 30,
                                        height: 30,
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        borderColor: '#ddd',
                                        padding: 2,
                                    }}
                                />
                            </ListItemAvatar>

                            <ListItemText
                                primary={reader.label}
                                secondary={reader.status}
                            />
                        </ListItem>
                    </div>
                ))}
            </Box>
        </>
    );
}
