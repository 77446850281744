import React, { useState, useRef, SyntheticEvent, ReactNode } from 'react';
import {
    Box,
    Button,
    ButtonProps,
    ClickAwayListener,
    Grow,
    makeStyles,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    list: {
        maxHeight: 500,
        overflowY: 'scroll',
    },
}));

type Option = {
    value: string | number;
    label: string;
};

type SttDropdownButtonProps = {
    caption: string;
    options: Option[];
    size?: ButtonProps['size'];
    color?: ButtonProps['color'];
    variant?: ButtonProps['variant'];
    onSelected?: (option: Option) => void;
    icon?: ReactNode;
};

export function SttDropdownButton({
    caption,
    options,
    size = 'small',
    color = 'primary',
    variant = 'contained',
    onSelected,
    icon,
}: SttDropdownButtonProps) {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    const classes = useStyles();

    const select = (option: Option) => {
        if (typeof onSelected === 'function') {
            onSelected(option);
            setOpen(false);
        }
    };

    const close = (event: SyntheticEvent<Document, Event>) => {
        if (anchorRef.current?.contains(event.target as Node)) {
            return;
        }
        setOpen(false);
    };

    const toggle = () => {
        setOpen((prev) => !prev);
    };

    return (
        <>
            <Button
                startIcon={icon}
                size={size}
                color={color}
                variant={variant}
                ref={anchorRef}
                onClick={toggle}
            >
                <Typography variant="button">{caption}</Typography>
                <ArrowDropDown />
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{ zIndex: 10 }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom',
                        }}
                    >
                        <Paper>
                            <Box>
                                <ClickAwayListener onClickAway={close}>
                                    <MenuList
                                        id="split-button-menu"
                                        className={classes.list}
                                    >
                                        {options.map((opt) => (
                                            <MenuItem
                                                key={opt.value}
                                                onClick={() => select(opt)}
                                            >
                                                {opt.label}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Box>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}
