import React, { useContext, useState } from 'react';
import { Box, CircularProgress, Dialog, DialogTitle } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import DiscountList from '../../../components/discounts/DiscountsList';
import SttError from '../../../components/error/SttError';
import { AppContext } from '../../../sporttia/AppContext';
import { getErrorMessage } from '../../../lib/utils';
import { GetGroupDiscounts200 } from '../../../types/api/paths/Group';
import translations from '../../../translations';
import useScsService from '../../../services/ScsService';
import useActivityRatesService from '../../../services/ActivityRatesService';
import { GetActivityRateDiscounts200 } from '../../../types/api/paths/ActivityRate';

type ActivityRateDiscountListProps = {
    rateId: number;
};

/**
 * Componente para lista de descuentos en Actividades. Es básicamente lo mismo que GroupDiscountList, ya que por dentro una tarifa de actividad es un 'group'
 * He creado un componente aparte por si el día de mañana se desacoplan tarifas de actividades de la tabla grupos.
 * @param rateId
 * @constructor
 */
export default function ActivityRateDiscountList({
    rateId,
}: ActivityRateDiscountListProps) {
    const cxt = useContext(AppContext)!;

    const [openDialog, setOpenDialog] = useState(false);

    const queryClient = useQueryClient();

    const activityRateService = useActivityRatesService();
    const getActivityRateDiscountsQuery =
        activityRateService.useGetActivityRateDiscounts(
            rateId,
            {
                rows: 1000,
            },
            {
                enabled: !!rateId,
            },
        );

    const scsService = useScsService();
    const getScDiscountsQuery = scsService.useGetScsDiscounts(
        cxt.sc!.id,
        { rows: 1000 },
        { enabled: openDialog },
    );

    const createGroupDiscountMutation =
        activityRateService.useCreateActivityRateDiscount({
            onSuccess: (result) => {
                queryClient.setQueryData<GetGroupDiscounts200 | undefined>(
                    getActivityRateDiscountsQuery.queryKey,
                    (old) => {
                        if (!old) {
                            return undefined;
                        }

                        return {
                            ...old,
                            count: old.count + 1,
                            rows: [...old.rows, result.discount],
                        };
                    },
                );

                cxt.showMessage('S', cxt.t(translations.group.discountAdded));
                setOpenDialog(false);
            },
            onError: (error) => {
                cxt.showMessage('E', getErrorMessage(error));
            },
        });

    const deleteGroupDiscountMutation =
        activityRateService.useDeleteActivityRateDiscount({
            onSuccess: (result, variables) => {
                queryClient.setQueryData<
                    GetActivityRateDiscounts200 | undefined
                >(getActivityRateDiscountsQuery.queryKey, (old) => {
                    if (!old) {
                        return undefined;
                    }

                    return {
                        ...old,
                        count: old.count - 1,
                        rows: old?.rows.filter(
                            (row) => row.id !== variables.discountId,
                        ),
                    };
                });

                cxt.showMessage('S', cxt.t(translations.group.discountDeleted));
                setOpenDialog(false);
            },
            onError: (error) => {
                cxt.showMessage('E', getErrorMessage(error));
            },
        });

    if (
        getActivityRateDiscountsQuery.isLoading ||
        createGroupDiscountMutation.isLoading ||
        deleteGroupDiscountMutation.isLoading
    ) {
        return (
            <Box display="flex" justifyContent="center" paddingTop={12}>
                <CircularProgress />
            </Box>
        );
    }

    if (getActivityRateDiscountsQuery.isError || getScDiscountsQuery.isError) {
        return <SttError />;
    }

    if (!getActivityRateDiscountsQuery.isSuccess) {
        return null;
    }

    return (
        <>
            <DiscountList
                discounts={getActivityRateDiscountsQuery.data.rows}
                onAdd={() => setOpenDialog(true)}
                onDelete={(discount) => {
                    deleteGroupDiscountMutation.mutate({
                        discountId: discount.id,
                        rateId,
                    });
                }}
            />

            <Dialog
                onClose={() => setOpenDialog(false)}
                open={openDialog}
                fullWidth
                maxWidth="xs"
            >
                {getScDiscountsQuery.isLoading && (
                    <Box display="flex" justifyContent="center" paddingY={12}>
                        <CircularProgress />
                    </Box>
                )}

                {getScDiscountsQuery.isSuccess && (
                    <>
                        <DialogTitle>
                            {cxt.t(translations.generic.discounts)}
                        </DialogTitle>
                        <DiscountList
                            // Filtrar descuentos ya añadidos al grupo
                            discounts={getScDiscountsQuery.data.rows.filter(
                                (scDiscount) =>
                                    !getActivityRateDiscountsQuery.data.rows.some(
                                        (groupDiscount) =>
                                            groupDiscount.id === scDiscount.id,
                                    ),
                            )}
                            onSelect={(discount) => {
                                createGroupDiscountMutation.mutate({
                                    discountId: discount.id,
                                    rateId,
                                });
                            }}
                            showTitle={false}
                        />
                    </>
                )}
            </Dialog>
        </>
    );
}
