import { Grid, TextField, GridProps, TextFieldProps } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

type ValidationRule = {
    regex: RegExp;
    error: string;
};

type SttFormTextProps = {
    grid?: boolean;
    caption?: string;
    password?: boolean;
    onChange?: ({ name, value }: { name: string; value: string }) => void;
    defVal: string;
    name: string;
    onEnterKey?: () => void;
    validation?: ValidationRule[];
    setValidationError?: (value: boolean) => void;
} & Omit<TextFieldProps, 'onChange'> &
    Omit<GridProps, 'onChange'>;

export function SttFormText({
    grid = false,
    xs = 12,
    sm = 6,
    md = 3,
    caption,
    password,
    onChange,
    defVal,
    name,
    onEnterKey,
    validation,
    setValidationError,
    ...rest
}: SttFormTextProps) {
    const [error, setError] = useState(false);
    const [messageError, setMessageError] = useState('');

    useEffect(() => {
        if (validation) {
            /**
             * Array con las reglas comprobadas con booleanos. El valor "TRUE" significa que la regla se cumple
             * (es decir encuentra valores que hacen match con la REGEX) y "FALSE" que no.
             */
            const arrayWithValidateData =
                validation.map((rule) => defVal.match(rule.regex) !== null) ??
                [];
            const errorValue = arrayWithValidateData.find(
                (element) => element === true,
            );
            const index =
                errorValue === true
                    ? -1
                    : arrayWithValidateData.findIndex(
                          (element) => element === false,
                      );
            setValidationError?.(!errorValue);
            setError(!errorValue);
            setMessageError(index >= 0 ? validation[index]!.error : '');
        }
    }, [defVal, setValidationError, validation]);

    const content = (
        <TextField
            fullWidth
            type={password ? 'password' : 'text'}
            variant="outlined"
            label={caption}
            size="small"
            value={defVal || ''}
            onChange={(e) => onChange?.({ name, value: e.target.value })}
            onKeyUp={(event) => {
                if (event.key === 'Enter' && typeof onEnterKey === 'function') {
                    onEnterKey();
                }
            }}
            helperText={messageError}
            error={error}
            {...rest}
        />
    );

    return grid ? (
        <Grid item xs={xs} sm={sm} md={md} {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
