import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Container, IconButton } from '@material-ui/core';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import { FilterList as FilterListIcon } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import { AppContext } from '../../../sporttia/AppContext';
import { SttFullDialog } from '../../../sporttia/dialogs/SttFullDialog';
import { SttChipUnlocker } from '../../../sporttia/chips/SttChipUnlocker';
import { SttChipFacilityRow } from '../../../sporttia/chips/SttChipFacilityRow';
import { SttButton, SttTable, SttTopControls } from '../../../sporttia/all';
import { useToggle, useTuples } from '../../../lib/hooks';

export function DevicesKeysDialog({ openStatus = false, onClose }) {
    const cxt = useContext(AppContext);
    const history = useHistory();

    const [keys, setKeys] = useState(null);

    // Trash hook
    const [trash, toggleTrash] = useToggle(() => {
        loadKeys();
    });

    const [filters, setFilter] = useState({
        code: '',
        mshipName: '',
    });

    // Initial type
    const [keyType, setKeyType] = useState('ALL');

    // On keyType change, reload data
    useEffect(() => {
        loadKeys();
    }, [keyType]);

    /**
     * Load keys
     */
    const loadKeys = (p = { page: 1, rows: 10 }) => {
        const finalFilters = {
            code: filters.code,
            type: keyType === 'ALL' ? '' : keyType,
            mshipName: filters.mshipName,
        };

        cxt.api('GET', `/scs/${cxt.sc.id}/unlockers`, {
            params: {
                page: p.page,
                rows: p.rows,
                ...finalFilters,
                trash,
            },
            success: (r) => {
                setKeys(r);
            },
        });
    };

    // -----| Render |-----
    return (
        <SttFullDialog
            open={openStatus}
            onClose={onClose}
            title={cxt.t('Unlockers')}
        >
            <Container>
                <Box my={3}>
                    <Paper>
                        <SttTopControls
                            p={2}
                            mainAction={{
                                type: 'component',
                                component: (
                                    <IconButton onClick={() => loadKeys()}>
                                        <SearchIcon />
                                    </IconButton>
                                ),
                            }}
                            fields={[
                                {
                                    caption: cxt.t('Code'),
                                    type: 'text',
                                    name: 'code',
                                    value: filters.code,
                                    width: 100,
                                    onEnterKey: loadKeys,
                                },
                                {
                                    caption: cxt.t('Type'),
                                    type: 'select',
                                    name: 'type',
                                    value: keyType,
                                    options: [
                                        {
                                            caption: cxt.t('All'),
                                            value: 'ALL',
                                        },
                                        {
                                            caption: cxt.t('UT.CARD'),
                                            value: 'CARD',
                                        },
                                        {
                                            caption: cxt.t('UT.PIN'),
                                            value: 'PIN',
                                        },
                                        {
                                            caption: cxt.t('UT.QR'),
                                            value: 'QR',
                                        },
                                    ],
                                    onChange: ({ name, value }) =>
                                        setKeyType(value),
                                },
                                {
                                    caption: cxt.t('Name'),
                                    type: 'text',
                                    name: 'mshipName',
                                    value: filters.mshipName,
                                    onEnterKey: loadKeys,
                                },
                            ]}
                            onToggleTrash={toggleTrash}
                            onChange={({ name, value }) =>
                                setFilter({ ...filters, [name]: value })
                            }
                            onFilter={loadKeys}
                        />

                        <SttTable
                            onFetch={loadKeys}
                            data={keys}
                            columns={[
                                {
                                    title: cxt.t('Code'),
                                    type: 'id',
                                    field: 'code',
                                },
                                {
                                    title: cxt.t('Type'),
                                    value: (row) => (
                                        <SttChipUnlocker
                                            unlocker={{ type: row.type }}
                                        />
                                    ),
                                },
                                {
                                    align: 'left',
                                    title: cxt.t('User'),
                                    value: (row) => row.user.fullName,
                                },
                            ]}
                        />
                    </Paper>
                </Box>
            </Container>
        </SttFullDialog>
    );
}
