import { Fab, Zoom } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import React from 'react';

type SttButtonFabProps = {
    onClick: () => void;
};

/**
 * Fab button
 *
 * Button in the corner to make the default operation on the page
 */
export function SttButtonFab({ onClick }: SttButtonFabProps) {
    return (
        <Zoom in timeout={400} unmountOnExit>
            <Fab
                color="primary"
                onClick={onClick}
                style={{ position: 'fixed', bottom: 30, right: 30 }}
            >
                <AddIcon />
            </Fab>
        </Zoom>
    );
}
