import React, { useState, useContext, useEffect } from 'react';
import { Chip } from '@material-ui/core';
import { AppContext } from '../AppContext';

/**
 * Chip file type: user_photo, etc, etc ...
 */
export function SttChipFileType({ type }) {
    const cxt = useContext(AppContext);
    const [fileType, setFileType] = useState();

    useEffect(() => {
        if (type)
            setFileType(cxt.constants.fileTypes.find((e) => e.id == type));
    }, [type]);

    return (
        <>
            {type && fileType && (
                <Chip
                    size="small"
                    label={fileType.label}
                    style={{
                        backgroundColor: fileType.color,
                        color: 'white',
                    }}
                />
            )}
        </>
    );
}
