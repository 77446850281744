import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import {
    AppContext,
    SttDialog,
    SttFormSelect,
    SttFormPrice,
    SttSelectPForm,
    SttFormTextarea,
} from '../../sporttia/all';
import { parsePrice, formatPriceByLocale } from '../../lib/utils';

/**
 *
 *	Props
 *
 *	* array mship
 *	* function onClose
 *	* function onSuccess: si se realiza un depósito satisfactoriamente
 *
 */
export default function ChargePurseDialog({ mship, onClose, onSuccess }) {
    const cxt = useContext(AppContext);
    const [deposit, setDeposit] = useState({
        concept: '',
        paymentForm: 'CASH',
        chargingOption: null,
        price: '',
    });
    const [chargingOptions, setChargingOptions] = useState([]);
    const [purseAction, setPurseAction] = useState('Deposit');
    const paymentMethods = ['CASH', 'BANK', 'DATAFONO', 'FREE', ''];
    const returnPaymentMethods = ['CASH', 'BANK'];

    /**
     * Load charging options
     */
    const loadChargingOptions = () => {
        cxt.api('GET', `/scs/${cxt.sc.id}/chargings/options`, {
            params: { page: 1, rows: 100 },
            success: (r) => {
                setChargingOptions(r.rows);
            },
        });
    };

    /**
     * Add deposit
     */
    const addDeposit = () => {
        cxt.api('POST', `/mships/${mship.id}/purses/deposits`, {
            params: {
                concept: deposit.concept,
                paymentForm: deposit.paymentForm,
                price:
                    parsePrice(
                        purseAction === 'Return'
                            ? -deposit.price
                            : deposit.price,
                    ) ||
                    // Fallback to 0.00
                    parsePrice(0),
            },
            success: () => {
                cxt.showMessage('S', cxt.t('page.sc.user.chargeSuccess'));
                onClose();
                if (onSuccess) {
                    onSuccess();
                }
            },
        });
    };

    /**
     * Handle form
     */
    const handleForm = ({ name, value }) => {
        // Charing option changes
        let option;
        if (name === 'chargingOption') {
            option = chargingOptions.find((e) => e.id === value);
        }

        const newDeposit = { ...deposit, [name]: value };
        if (option) {
            newDeposit.price = option.amount;
        }
        setDeposit(newDeposit);
    };

    /**
     * Init
     */
    useEffect(() => {
        loadChargingOptions();
    }, []);

    // -----| Render |-----
    return (
        <SttDialog
            title={cxt.t('NewPurseMovement')}
            open={mship !== null && mship !== undefined}
            onClose={() => (onClose ? onClose() : null)}
            maxWidth="md"
            content={
                <Grid container spacing={3}>
                    <SttFormTextarea
                        grid
                        name="concept"
                        caption={cxt.t('PursePayment')}
                        defVal={deposit.concept}
                        onChange={handleForm}
                    />

                    <SttFormSelect
                        grid
                        md={2}
                        sm={4}
                        name="option"
                        defVal={purseAction}
                        onChange={({ value }) => {
                            setPurseAction(value);
                            setDeposit({ ...deposit, paymentForm: 'CASH' });
                        }}
                        options={[
                            { caption: cxt.t('Deposit'), value: 'Deposit' },
                            { caption: cxt.t('Return'), value: 'Return' },
                        ]}
                    />

                    <SttSelectPForm
                        grid
                        md={3}
                        sm={4}
                        name="paymentForm"
                        caption={cxt.t('PaymentForm')}
                        defVal={deposit.paymentForm}
                        onChange={handleForm}
                        activePFs={
                            purseAction === 'Deposit'
                                ? paymentMethods
                                : returnPaymentMethods
                        }
                    />

                    {purseAction === 'Deposit' && (
                        <SttFormSelect
                            grid
                            md={4}
                            sm={8}
                            name="chargingOption"
                            caption={cxt.t('SelectCharge')}
                            defVal={deposit.chargingOption}
                            onChange={handleForm}
                            options={chargingOptions.map((opt) => ({
                                caption: `${cxt.t(
                                    'Amount',
                                )}: ${formatPriceByLocale(
                                    opt?.amount,
                                    opt?.amountShape?.currency,
                                    opt?.amountShape?.locale,
                                )}${
                                    opt.bonus
                                        ? `, bonus: ${formatPriceByLocale(
                                              opt?.bonusShape?.price,
                                              opt?.bonusShape?.currency,
                                              opt?.bonusShape?.locale,
                                          )}`
                                        : ''
                                }`,
                                value: opt.id,
                            }))}
                        />
                    )}
                    <SttFormPrice
                        grid
                        md={3}
                        sm={4}
                        name="price"
                        disabled={deposit.chargingOption !== null}
                        caption={cxt.t('Amount')}
                        defVal={deposit.price}
                        onChange={handleForm}
                    />
                </Grid>
            }
            buttons={[
                { type: 'accept', onClick: () => addDeposit() },
                { type: 'close', onClick: onClose ? () => onClose() : null },
            ]}
        />
    );
}
