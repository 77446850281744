import React, { useState, useRef, useContext, useEffect } from 'react';
import {
    GoogleMap,
    LoadScript,
    Autocomplete,
    StandaloneSearchBox,
    Marker,
} from '@react-google-maps/api';
import { makeStyles } from '@material-ui/core/styles';
import useKey from '@rooks/use-key';
import { AppContext, SttFormText } from './all';

// The weirdest fucking syntax
const useStyles = makeStyles({
    autocomplete: {
        boxSizing: 'border-box',
        fontFamily: 'Roboto, Helvetica, Arial sans-serif',
        fontSize: '1rem',
        color: 'rgba(0, 0, 0, 0.87)',
        width: '100%',
        margin: 0,
        display: 'block',
        minWidth: 0,
        background: 'none',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#c4c4c4',
        borderRadius: 4,
        padding: '8px 16px',
        height: 40,
        '&:hover': {
            borderColor: 'rgba(0, 0, 0, 0.87)',
        },
    },
    mapContainer: {
        width: (props) => props.width,
        height: (props) => props.height,
    },
    top: {
        marginBottom: 16,
    },
    bottom: {
        marginTop: 16,
    },
});

const Positions = {
    top: 'top',
    bottom: 'bottom',
};

/**
 *	Somewhat basic Google Maps component with one marker and optional search functionality
 *	On search or location drag responds with onChange({lat,lng})
 *
 * Props
 *
 *	* string latLng.(lat,lng)
 *	* function onChange({lat, lng})
 */
export function SttGoogleMap({
    width = '100%',
    height,
    latLng = { lat: 40.3315573, lng: -3.5786729 },
    searchValue = '',
    searchEnabled = false,
    searchPlaceholder = '',
    searchPosition = Positions.top,
    onChange,
}) {
    const cxt = useContext(AppContext);
    const [locationLatLng, setLocationLatLng] = useState(latLng);
    const [mapLatLng, setMapLatLng] = useState(latLng);
    const [searchRef, setSearchRef] = useState(useRef(null));
    const [zoom, setZoom] = useState(12);

    const defaultDimensions = 400;

    const classes = useStyles({
        width: width || defaultDimensions,
        height: height || defaultDimensions,
    });

    /**
     * Plance changes
     */
    function onPlacesChanged() {
        const places = searchRef.getPlaces();
        let place;

        if (places.length > 0) {
            place = places[0];
        }

        if (place && place.geometry) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();

            setLocationLatLng({ lat, lng });
            setMapLatLng({ lat, lng });

            if (onChange && onChange.constructor == Function) {
                onChange({ lat, lng });
            }
        } else {
            console.log(`Error in place.geometry`);
        }
    }

    // Called when the user drops the marker
    function onDragEnd(event) {
        if (event.latLng) {
            const lat = event.latLng.lat();
            const lng = event.latLng.lng();

            setLocationLatLng({ lat, lng });

            if (onChange && onChange.constructor == Function) {
                onChange({ lat, lng });
            }
        }
    }

    let searchBlock;

    // Optional search bar
    if (searchEnabled) {
        searchBlock = (
            <div
                className={
                    searchPosition == Positions.top
                        ? classes.top
                        : classes.bottom
                }
            >
                <StandaloneSearchBox
                    onLoad={(ref) => setSearchRef(ref)}
                    onPlacesChanged={onPlacesChanged}
                >
                    <input
                        type="text"
                        placeholder={cxt.t('page.sc.conf.data.searchMap')}
                        className={classes.autocomplete}
                    />
                </StandaloneSearchBox>
            </div>
        );
    }

    // Render
    return (
        <LoadScript
            id="script-loader"
            googleMapsApiKey={cxt.constants.googleMapsApiKey}
            libraries={['places']}
        >
            {/* --- Searching box - TOP --- */}
            {searchPosition == Positions.top && searchBlock}

            {/* --- Map --- */}
            {mapLatLng.lat && mapLatLng.lng && (
                <div>
                    <GoogleMap
                        id="google-map"
                        mapContainerClassName={classes.mapContainer}
                        zoom={zoom}
                        center={mapLatLng}
                        clickableIcons={false}
                        gestureHandling="cooperative"
                        options={{
                            mapTypeControl: false,
                            streetViewControl: false,
                            panControl: false,
                        }}
                    >
                        <Marker
                            onDragEnd={onDragEnd}
                            position={locationLatLng}
                            draggable
                        />
                    </GoogleMap>
                </div>
            )}

            {/* --- Searching box - BOTTOM --- */}
            {searchPosition == Positions.bottom && searchBlock}
        </LoadScript>
    );
}
