import React from 'react';
import { SttDialog } from '../sporttia/all';
import { useTranslations } from '../lib/hooks';

type ModalConfirmationProps = {
    show: boolean;
    onClose: () => void;
    onAccept?: () => void;
};

/**
 * Confirmation dialog.
 */
export default function ModalConfirmation({
    show,
    onClose,
    onAccept,
}: ModalConfirmationProps) {
    const { translate } = useTranslations();

    return (
        <SttDialog
            title={translate('Information')}
            open={show}
            onClose={onClose}
            content={<>{translate('AreYouSure')}</>}
            buttons={[
                {
                    type: 'accept',
                    onClick: onAccept,
                },
                {
                    type: 'cancel',
                    onClick: onClose,
                },
            ]}
        />
    );
}
