import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Grid,
    ButtonGroup,
    Button,
    IconButton,
    Tabs,
    Tab,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import moment from 'moment';
import SettingsIcon from '@material-ui/icons/Settings';
import CachedIcon from '@material-ui/icons/Cached';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
    AppContext,
    SttFormSelect,
    SttMenu,
    SttButtonDate,
} from '../../../sporttia/all';

/**
 * Timetable controls
 * ==================
 */
export default function TimetableControls({
    sc,
    date,
    loadTimetable,
    onChange, // onChange({facilityGroups, date, coeff})
    facilityGroupId,
    coeffs,
    isWeekly,
    toggleWeekly,
}) {
    const cxt = useContext(AppContext);
    // const [coeff, setCoeff] = useState(0);
    const [facilityGroups, setFacilityGroups] = useState();
    const [weekdayTabValue, setWeekTabValue] = useState(0);
    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

    /**
     * Load facility groups
     */
    const loadFacilityGroups = () => {
        cxt.api('GET', `/scs/${sc.id}/facilitysets`, {
            params: { rows: 1000, status: 'ACTIVE' },
            success: (r) => {
                const filteredEmptyFacilities = { count: 0, rows: [] };
                filteredEmptyFacilities.rows = r.rows.filter(
                    (group) => group.facilities.count > 0,
                );
                filteredEmptyFacilities.count =
                    filteredEmptyFacilities.rows.length;
                const groupList = filteredEmptyFacilities.rows.map((group) => ({
                    value: group.id,
                    caption: group.name,
                }));

                setFacilityGroups([
                    {
                        value: -1,
                        caption: cxt.t('All'),
                    },
                    ...groupList,
                ]);

                // Set first group if this exists
                onChange({
                    facilityGroupId: r.rows.length > 1 ? r.rows[0].id : -1,
                });
            },
        });
    };

    /**
     * Get menu items
     */
    const getMenuItems = () => {
        // Init items
        let items = [
            {
                caption: isWeekly ? cxt.t('Day') : cxt.t('Week'),
                onClick: () => toggleWeekly(),
            },
        ];

        // Add coeffs
        items = [
            ...items,
            ...coeffs.map((coeff, idx) => ({
                caption: coeff.name,
                onClick: () => onChange({ coeff: coeff.id }),
                divider: idx === 0,
            })),
        ];

        return items;
    };

    /**
     * On change weekday tab
     */
    const onChangeWeekday = (newWeekdayTabValue) => {
        // Change date
        // onChange({date: weekdays[newWeekdayTabValue].ymd})
        onChange({
            date: moment(date).weekday(newWeekdayTabValue).format('YYYY-MM-DD'),
        });
    };

    /**
     * Date changes
     */
    useEffect(() => {
        setWeekTabValue(moment(date).weekday());
    }, [date]);

    /**
     * Init
     */
    useEffect(() => {
        loadFacilityGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // coeffs[coeff].width
    return (
        <Box mb={2}>
            <Grid container spacing={3}>
                <Grid item sm={4} xs={12}>
                    {facilityGroups && (
                        <SttFormSelect
                            defVal={facilityGroupId}
                            onChange={({ value }) =>
                                onChange({ facilityGroupId: value })
                            }
                            options={facilityGroups}
                        />
                    )}
                </Grid>

                <Grid item sm={7} xs={12} align="center">
                    <ButtonGroup>
                        <Button onClick={() => loadTimetable()}>
                            <CachedIcon />
                        </Button>
                        <Button
                            onClick={() =>
                                onChange({
                                    date: moment().format('YYYY-MM-DD'),
                                })
                            }
                        >
                            <RotateLeftIcon />
                            &nbsp;
                            {cxt.t('Today')}
                        </Button>
                        <SttButtonDate
                            date={date}
                            onSelectDate={(dated) => onChange({ date: dated })}
                        />
                    </ButtonGroup>
                </Grid>

                <Grid
                    item
                    sm={1}
                    xs={12}
                    style={xsDown ? { textAlign: 'right' } : {}}
                >
                    <SttMenu icon=<SettingsIcon /> items={getMenuItems()} />
                </Grid>
            </Grid>

            {!isWeekly && (
                <Grid container spacing={3}>
                    <Grid item md={1} xs={2}>
                        <IconButton
                            onClick={() =>
                                onChange({
                                    date: moment(date)
                                        .subtract(1, 'week')
                                        .format('YYYY-MM-DD'),
                                })
                            }
                        >
                            <ArrowBackIosIcon />
                        </IconButton>
                    </Grid>

                    <Grid item md={10} xs={8}>
                        <Tabs
                            indicatorColor="primary"
                            variant="fullWidth"
                            textColor="primary"
                            value={weekdayTabValue}
                            onChange={(ev, value) => onChangeWeekday(value)}
                        >
                            {[0, 1, 2, 3, 4, 5, 6].map((wd) => (
                                <Tab
                                    style={{ minWidth: 100 }}
                                    key={wd}
                                    label={
                                        window.innerWidth <= 760
                                            ? moment(date)
                                                  .weekday(wd)
                                                  .format('D dd')
                                            : moment(date)
                                                  .weekday(wd)
                                                  .format('D ddd')
                                    }
                                />
                            ))}
                        </Tabs>
                    </Grid>

                    <Grid item md={1} xs={2}>
                        <IconButton
                            onClick={() =>
                                onChange({
                                    date: moment(date)
                                        .add(1, 'week')
                                        .format('YYYY-MM-DD'),
                                })
                            }
                        >
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}
