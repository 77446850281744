import React, { useContext, useState } from 'react';
import { Paper } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { AppContext } from '../../../sporttia/AppContext';
import { SttChipUnlocker, SttTable } from '../../../sporttia/all';
import SttUserUnlockerDialog from '../../../sporttia/dialogs/SttUserUnlockerDialog';
import SttPupilDialog from '../../../sporttia/dialogs/SttPupilDialog';

/**
 * View to see the list of pupils of a user.
 * @returns {JSX.Element}
 */
function UserPupils() {
    const cxt = useContext(AppContext);
    const [state, setState] = useState({
        pupils: [],
        openUnlocker: null,
        selectedPupil: null,
        openPupilDialog: false,
    });

    const { pupils, openUnlocker, selectedPupil, openPupilDialog } = state;

    const loadUserPupils = ({ page = 1, rows = 20 } = {}) => {
        cxt.api('GET', '/my/pupils', {
            params: { page, rows },
            success: (r) =>
                setState((prevState) => ({ ...prevState, pupils: r })),
        });
    };

    const handleSavePupil = () => {
        loadUserPupils();
        setState((prevState) => ({
            ...prevState,
            openPupilDialog: false,
            selectedPupil: null,
        }));
    };

    const handleRowClick = (row) => {
        setState((prevState) => ({
            ...prevState,
            selectedPupil: row,
            openPupilDialog: true,
        }));
    };

    const handleUnlockerClick = (e, unlocker) => {
        e.stopPropagation();
        if (unlocker.type === 'PIN' || unlocker.type === 'QR') {
            setState((prevState) => ({ ...prevState, openUnlocker: unlocker }));
        }
    };

    return (
        <>
            <Container maxWidth="md">
                <Paper>
                    <SttTable
                        data={pupils}
                        onFetch={loadUserPupils}
                        autoload
                        columns={[
                            {
                                title: cxt.t('NameAndSurname'),
                                value: (row) => row?.user?.fullName,
                            },
                            {
                                title: cxt.t('Sportcenter'),
                                value: (row) => row?.sc?.name,
                            },
                            {
                                title: cxt.t('Unlocker'),
                                value: (row) =>
                                    row?.unlockers?.map((unlocker) => (
                                        <SttChipUnlocker
                                            key={unlocker.id}
                                            unlocker={unlocker}
                                            onClick={(e) =>
                                                handleUnlockerClick(e, unlocker)
                                            }
                                        />
                                    )),
                            },
                        ]}
                        onClickRow={handleRowClick}
                    />
                </Paper>
            </Container>

            <SttUserUnlockerDialog
                unlocker={openUnlocker}
                title={`${selectedPupil?.user?.fullName}`}
                subtitle={`${selectedPupil?.sc?.name}`}
                onClose={() =>
                    setState((prevState) => ({
                        ...prevState,
                        openUnlocker: null,
                        selectedPupil: null,
                    }))
                }
            />

            {openPupilDialog && selectedPupil && (
                <SttPupilDialog
                    open={openPupilDialog}
                    pupil={selectedPupil}
                    onClose={() =>
                        setState((prevState) => ({
                            ...prevState,
                            openPupilDialog: false,
                            selectedPupil: null,
                        }))
                    }
                    onSave={handleSavePupil}
                />
            )}
        </>
    );
}

export default UserPupils;
