import React, { useContext } from 'react';
import { Chip } from '@material-ui/core';
import { AppContext } from '../AppContext';

/**
 * Chip event kind
 */
export function SttChipEventKind({ kind }) {
    const cxt = useContext(AppContext);

    const color = {
        RACE: '#3186a7',
        TOURNAMENT: '#a341bb',
        HANGOUT: '#f35b77',
    };

    return (
        <>
            {kind && (
                <Chip
                    size="small"
                    label={cxt.t(`EK.${kind}`)}
                    style={{ backgroundColor: color[kind], color: 'white' }}
                />
            )}
        </>
    );
}
