import React, { useContext, useState } from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import { AppContext } from '../../../../sporttia/AppContext';
import LicenceModal from '../../../../components/licenceModal/LicenceModal';
import { SUPERADMIN_CUSTOMER_SUBSCRIPTION_LICENSES } from '../../../../lib/queryKeys';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import useSubscriptionsService from '../../../../services/SubscriptionsService';
import { objectWithoutKeys, formatPriceByLocale } from '../../../../lib/utils';

const useStyles = makeStyles({
    genericBorder: {
        borderColor: '#C4C4C4',
    },
    borderRounded: {
        borderRadius: '5px',
        minHeight: '150px',
    },
    cellTitle: {
        fontWeight: 'bold',
    },
    linkColor: {
        color: 'rgba(33, 150, 243, 0.5)',
    },
    redBold: {
        display: 'inline',
        color: '#b22a00',
    },
});

/**
 * Box with the list of licenses associated with a subscription.
 * @param subscriptionSelected Selected subscription from which all the list of licenses will be obtained.
 * @returns {JSX.Element}
 */
export default function AdminCustomersLicences({ subscriptionSelected }) {
    const cxt = useContext(AppContext);
    const classes = useStyles();
    const subscriptionsService = useSubscriptionsService();
    const queryClient = useQueryClient();
    const [licenceChosen, setLicenceChosen] = useState(null);

    return (
        <>
            <Box
                className={`${classes.genericBorder} ${classes.borderRounded}`}
                border={1}
            >
                {/* Title row */}
                <Box
                    className={`${classes.genericBorder} ${classes.cellTitle}`}
                    borderBottom={1}
                    p={1}
                >
                    {`${
                        cxt.t('Licences').charAt(0).toUpperCase() +
                        cxt.t('Licences').slice(1)
                    }`}
                </Box>

                {/* Table with licences data */}
                <SttCachedTable
                    queryKey={SUPERADMIN_CUSTOMER_SUBSCRIPTION_LICENSES}
                    queryFn={(params) =>
                        subscriptionsService.getSubscriptionLicenses(
                            params?.id,
                            objectWithoutKeys(params, ['id']),
                        )
                    }
                    queryOptions={{
                        enabled:
                            !!subscriptionSelected?.id &&
                            subscriptionSelected?.id !== 'create',
                    }}
                    queryParams={{
                        id: subscriptionSelected?.id,
                    }}
                    prefetching={false}
                    onClickRow={(row) => setLicenceChosen(row)}
                    rowsPerPage={5}
                    columns={[
                        {
                            title: cxt.t('Fee'),
                            field: 'id',
                            align: 'left',
                            value: (row) =>
                                `${row.id} - ${cxt.t('Fee')} ${moment(
                                    row.ini,
                                ).format('DD-MM-YYYY')} - ${moment(
                                    row.end,
                                ).format('DD-MM-YYYY')}`,
                        },
                        {
                            title: cxt.t('PaymentForm'),
                            value: (row) =>
                                row.paymentForm
                                    ? cxt.t(`PF.${row.paymentForm}`)
                                    : '',
                        },
                        {
                            title: cxt.t('Status'),
                            value: (row) =>
                                row.paymentForm ? (
                                    cxt.t('PAID')
                                ) : (
                                    <Box className={classes.redBold}>
                                        {cxt.t('Pending')}
                                    </Box>
                                ),
                        },
                        {
                            title: cxt.t('price'),
                            field: 'string',
                            align: 'right',
                            value: (row) =>
                                `${formatPriceByLocale(
                                    row?.price,
                                    row?.priceShape?.currency,
                                    row?.priceShape?.locale,
                                )}`,
                        },
                    ]}
                />
            </Box>
            <LicenceModal
                licence={licenceChosen}
                onClose={() => setLicenceChosen(null)}
                onUpdate={() =>
                    queryClient.invalidateQueries(
                        SUPERADMIN_CUSTOMER_SUBSCRIPTION_LICENSES,
                    )
                }
                onDelete={() => {
                    setLicenceChosen(null);
                    queryClient.invalidateQueries(
                        SUPERADMIN_CUSTOMER_SUBSCRIPTION_LICENSES,
                    );
                }}
            />
        </>
    );
}
