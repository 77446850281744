import React, { useState, useEffect, useContext } from 'react';
import { Grid, Box, Container } from '@material-ui/core';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {
    AppContext,
    SttFullDialog,
    SttSelectMship,
} from '../../../sporttia/all';
import SalePointProducts from './SalePointProducts';
import SalePointCart from './SalePointCart';
import SalePointPayment from './SalePointPayment';
import {
    updateElementInArray,
    deleteElementFromArray,
    subsetObject,
} from '../../../lib/utils';
import { useInteractionsFiles } from '../../../lib/hooks';

const type = 'SALE';

export default function SalePoint({
    open = false,
    idTicket, // "create" to creating mode
    onClose,
}) {
    const cxt = useContext(AppContext);
    const { openFile } = useInteractionsFiles();
    const [cart, setCart] = useState([]);
    const [mship, setMship] = useState({ fullName: '' });
    const [ticket, setTicket] = useState();
    const [allProducts, setAllProducts] = useState([]);
    const [readOnly, setReadOnly] = useState(true);
    const [previousPurchaseTicket, setPreviousPurchaseTicket] = useState(null);

    // Totals
    const [totalNet, setTotalNet] = useState(0);
    const [totalVat, setTotalVat] = useState(0);
    const [total, setTotal] = useState(0);

    const loadAllProducts = () => {
        cxt.api('GET', `/scs/${cxt.sc.id}/products`, {
            params: {
                rows: 500,
            },
            success: (r) => {
                // Product with no category, category = 'Productos'
                const products = r.rows.map((product) => ({
                    ...product,
                    category: product.category
                        ? product.category
                        : cxt.t('Others'),
                }));

                setAllProducts(products);
            },
        });
    };

    const loadTicket = () => {
        cxt.api('GET', `/products/tickets/${idTicket}`, {
            success: (r) => {
                setTicket(r.productTicket);
                setReadOnly(r.productTicket.status === 'CLOSE');
                if (r.productTicket.status === 'CLOSE')
                    setPreviousPurchaseTicket(r.productTicket);
                if (r.productTicket.movs) {
                    setCart(r.productTicket.movs);
                }
            },
        });
    };

    const createNewTicket = () => {
        cxt.api('POST', `/scs/${cxt.sc.id}/products/tickets`, {
            params: {
                type,
                idUser: mship?.user?.id,
            },
            success: (r) => {
                setTicket(r.productTicket);
                setReadOnly(false);
            },
        });
    };

    const downloadTicket = () => {
        if (previousPurchaseTicket.payment) {
            openFile(
                `/payments/${previousPurchaseTicket.payment.id}.pdf`,
                'application/pdf',
                null,
                `${cxt.t('Ticket')}_${previousPurchaseTicket.payment.id}`,
            );
        }
    };

    const deleteTicket = () => {
        cxt.api('DELETE', `/products/tickets/${ticket.id}`, {
            success: () => {
                onClose();
            },
        });
    };

    const updateProduct = (product) => {
        setAllProducts(updateElementInArray(allProducts, product));
    };

    const deleteProduct = (product) => {
        setAllProducts(
            allProducts.filter(
                (currentProduct) => currentProduct?.id !== product?.id,
            ),
        );
    };

    /**
     * Create/update movement in cart
     */
    const saveMovementInCart = (mov) => {
        const params = subsetObject(mov, 'amount,concept,netPrice,vat');

        // Product is in the cart already -> update movement
        if (mov.id) {
            cxt.api('PUT', `/products/movs/${mov.id}`, {
                params,
                success: (r) => {
                    // Final amount was 0 then the mov has been deleted by backend
                    if (r.productMov.trash) {
                        // Delete product from cart
                        setCart(deleteElementFromArray(cart, r.productMov));
                    } else {
                        // Update cart & product (stock)
                        setCart(updateElementInArray(cart, r.productMov));
                    }

                    updateProduct(r.product);
                },
            });

            // Product new in the cart -> create movement
        } else {
            cxt.api('POST', `/products/tickets/${ticket.id}/movs`, {
                params: {
                    ...params,
                    idProduct: mov.product.id,
                },
                success: (r) => {
                    // Update cart & product (stock)
                    setCart(updateElementInArray(cart, r.productMov));
                    updateProduct(r.product);
                },
            });
        }
    };

    const onSelectProduct = (product) => {
        // Find the movement just in case the product is already in the cart
        const foundMov = cart.find((mov) => mov.product.id === product.id);
        // Si seleccionamos un nuevo producto, reseteamos el anterior ticket, ya no se podrá imprimir
        setPreviousPurchaseTicket(null);
        // Create new movement with amount -1
        saveMovementInCart({
            id: foundMov && foundMov.id,
            amount: (foundMov ? foundMov.amount : 0) - 1,
            product,
        });
    };

    const reset = (closedProductTicket) => {
        // Empty cart
        setCart([]);

        // It's on creating mode
        if (idTicket === 'create') {
            // Guardamos el ticket que se ha cerrado para que se pueda imprimir y no se sobreescriba con el nuevo
            setPreviousPurchaseTicket(closedProductTicket);
            createNewTicket();
        }
    };

    /**
     * Before close, delete if it's empty
     */
    const onCloseDialog = () => {
        // Cart empty
        if (cart.length === 0 && ticket.status === 'OPEN') {
            deleteTicket();
        } else {
            onClose();
        }
    };

    /**
     * Ticket id changes
     */
    useEffect(() => {
        // Only create if we are not loading one
        if (idTicket) {
            if (idTicket === 'create') {
                createNewTicket();
            } else {
                loadTicket();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idTicket]);

    /**
     * INIT - Just opened
     */
    useEffect(() => {
        // Load all products
        loadAllProducts();

        // Get sports center to get the guest user
        cxt.api('GET', `/scs/${cxt.sc.id}`, {
            success: (r) => {
                // Set guest user
                if (r.sc.guestUser && r.sc.guestUser.mship) {
                    setMship({
                        id: r.sc.guestUser.mship.id,
                        fullName: r.sc.guestUser.mship.fullName,
                        user: {
                            id: r.sc.guestUser.id,
                        },
                    });
                }
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Calculate totals
     */
    useEffect(() => {
        let calcTotal = 0;
        let calcTotalNet = 0;
        let calcTotalVat = 0;
        cart.forEach((mov) => {
            calcTotal += mov.finalPrice * mov.amount;
        });
        if (cart.length > 0) {
            calcTotalNet = calcTotal / (1 + cart[0].vat / 100);
            calcTotalVat = calcTotal - calcTotalNet;
        }

        setTotalNet(Math.abs(calcTotalNet));
        setTotalVat(Math.abs(calcTotalVat));
        setTotal(Math.abs(calcTotal));
    }, [cart]);

    return (
        <SttFullDialog
            open={open}
            onClose={onCloseDialog}
            title={cxt.t('PointOfSale')}
            buttons={[
                {
                    show:
                        previousPurchaseTicket &&
                        previousPurchaseTicket?.status === 'CLOSE',
                    caption: cxt.t('Print'),
                    onClick: () => downloadTicket(),
                    icon: <ConfirmationNumberIcon />,
                },
            ]}
            menu={[
                {
                    caption: cxt.t('Delete'),
                    icon: <HighlightOffIcon />,
                    onClick: () => deleteTicket(),
                },
            ]}
        >
            <Container>
                <Box mt={3}>
                    <Grid container spacing={4} justifyContent="center">
                        {!readOnly && (
                            <Grid item md={6} sm={12} xs={12}>
                                <SalePointProducts
                                    selectProduct={onSelectProduct}
                                    allProducts={allProducts}
                                    setAllProducts={setAllProducts}
                                    onUpdateProduct={updateProduct}
                                    onDeleteProduct={deleteProduct}
                                />
                            </Grid>
                        )}

                        <Grid item md={6} sm={12} xs={12}>
                            <Box mb={2}>
                                <SttSelectMship
                                    readOnly={readOnly}
                                    caption={cxt.t(
                                        type === 'SALE' ? 'User' : 'Provider',
                                    )}
                                    mship={mship}
                                    onSelect={setMship}
                                />
                            </Box>

                            <SalePointCart
                                cart={cart}
                                saveMovementInCart={saveMovementInCart}
                                totalNet={totalNet}
                                totalVat={totalVat}
                                total={total}
                                readOnly={readOnly}
                            />

                            {total !== 0 && !readOnly && (
                                <SalePointPayment
                                    ticket={ticket}
                                    mship={mship}
                                    total={total}
                                    onUpdateTicket={(ticketToUpdate) => {
                                        setTicket(ticketToUpdate);
                                    }}
                                    onReset={reset}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </SttFullDialog>
    );
}
