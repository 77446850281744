import React, { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { Box, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { AppContext } from '../../../sporttia/AppContext';
import { SttTopControls } from '../../../sporttia/SttTopControls';
import { SttFileDialog, SttTable } from '../../../sporttia/all';

export default function SCDocuments(props) {
    const cxt = useContext(AppContext);
    const [filters, setFilters] = useState({ name: '' });
    const [documents, setDocuments] = useState();
    const [openFileId, setOpenFileId] = useState();

    /**
     * Load documents.
     * @param p {page, rows} values.
     */
    const loadDocuments = (p = { page: 1, rows: 20 }) => {
        cxt.api('GET', `/scs/${props.match.params.id}/files`, {
            params: {
                ...filters,
                page: p.page,
                rows: p.rows,
            },
            success: (r) => {
                setDocuments(r);
            },
        });
    };

    useEffect(() => {
        loadDocuments();
    }, []);

    return (
        <Container maxWidth="md">
            <Paper>
                <Box p={2}>
                    <Typography variant="h4" gutterBottom>
                        {cxt.t('Documents').toUpperCase()}
                    </Typography>
                    <Typography variant="body2" display="block" gutterBottom>
                        {cxt.t('page.scProfile.docs.desc')}
                    </Typography>

                    <SttTopControls
                        p={2}
                        fields={[
                            {
                                caption: cxt.t('Name'),
                                name: 'name',
                                type: 'text',
                                value: filters.name,
                                onEnterKey: () => loadDocuments(),
                            },
                        ]}
                        onChange={({ name, value }) =>
                            setFilters({ ...filters, [name]: value })
                        }
                        onFilter={loadDocuments}
                    />

                    <SttTable
                        data={documents}
                        columns={[
                            {
                                type: 'link',
                                align: 'left',
                                title: cxt.t('Name'),
                                value: (row) => row.name || cxt.t('Noname'),
                            },
                        ]}
                        onFetch={loadDocuments}
                        onClickRow={(row) => setOpenFileId(row.id)}
                    />

                    <SttFileDialog
                        idFile={openFileId}
                        onClose={() => setOpenFileId(null)}
                        // onUpdate={updateFile}
                    />
                </Box>
            </Paper>
        </Container>
    );
}
