import React, { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { AppContext } from '../../../sporttia/AppContext';
import { SttList } from '../../../sporttia/lists/SttList';
import Mship from './Mship';
import SttMshipDialog from '../../../sporttia/dialogs/SttMshipDialog';
import { fullName } from '../../../lib/utils';

export default function MshipPupils({ mshipId }) {
    const cxt = useContext(AppContext);
    const [pupils, setPupils] = useState([]);
    const [pupilChosen, setPupilChosen] = useState({
        id: null,
        mship: {
            id: null,
        },
    });

    useEffect(() => {
        if (mshipId) loadPupils();
    }, [mshipId]);

    const loadPupils = () => {
        cxt.api('GET', `/mships/${mshipId}/pupils`, {
            params: { rows: 1000 },
            success: (r) => {
                setPupils(r.rows);
            },
        });
    };

    return (
        <>
            <Paper>
                <SttList
                    fullWidth
                    title={cxt.t('Pupils')}
                    data={(pupils || []).map((pupil) => ({
                        caption: fullName(pupil),
                        objToSelect: pupil,
                    }))}
                    onSelect={(pupil) => setPupilChosen(pupil)}
                />
            </Paper>

            <SttMshipDialog
                mshipId={pupilChosen.mship.id}
                open={!!pupilChosen.mship.id}
                onClose={() => {
                    setPupilChosen({
                        id: null,
                        mship: {
                            id: null,
                        },
                    });
                    loadPupils();
                }}
            />
        </>
    );
}
