import React, { useContext, useState } from 'react';
import {
    Avatar,
    Box,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ReplyIcon from '@material-ui/icons/Reply';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { useMutation } from 'react-query';
import MessageCommentList from './MessageCommentList';
import { AppContext } from '../../../sporttia/AppContext';
import { useTranslations } from '../../../lib/hooks';
import { SttLabelDate } from '../../../sporttia/labels/SttLabelDate';
import useMessagesService from '../../../services/MessagesService';

/**
 * Representation of a message that will later be rendered in a list.
 * @param message Message object.
 * @param fold Fold type (INBOX|...).
 * @param onUpdate Launched when a message is updated.
 * @returns {JSX.Element}
 */
export default function MessageItem({ message, fold, onUpdate }) {
    const cxt = useContext(AppContext);
    const { translate } = useTranslations();
    const messagesService = useMessagesService();
    const [showCommentsMessage, setShowCommentsMessage] = useState();
    const updateMessageReadMutation = useMutation(
        (params) =>
            messagesService.updateMessagesRead(params?.id, params?.params),
        {
            onSuccess: onUpdate,
        },
    );

    const buildMsgTitle = (msg) => {
        if (fold === 'INBOX') {
            return msg?.sender?.fullName;
        }
        return msg?.receptors
            ? msg?.receptors?.rows
                  .map(
                      (receptor) =>
                          receptor?.user?.name ||
                          receptor?.group?.name ||
                          receptor?.sc?.name,
                  )
                  .filter((value, index, self) => self.indexOf(value) === index)
                  .join(', ')
            : translate('None');
    };

    return (
        <>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar
                        alt={message?.sender?.fullName}
                        src={cxt.getResource(
                            message?.img?.id
                                ? `/images/${message?.img?.id}.jpg`
                                : `/static/images/avatar/1.jpg`,
                        )}
                    />
                </ListItemAvatar>

                <ListItemText
                    primary={buildMsgTitle(message)}
                    secondary={
                        <Typography variant="body2" color="textPrimary">
                            <SttLabelDate
                                date={message?.created}
                                timezoneName={cxt?.sc?.timezone?.name}
                                locale={cxt?.lang?.key}
                            />
                        </Typography>
                    }
                />
            </ListItem>

            <Box ml={9}>
                <Box
                    my={2}
                    pl={2}
                    style={{
                        borderLeft: 2,
                        borderLeftStyle: 'solid',
                        borderColor: message?.numUnread > 0 ? 'red' : '#fafafa',
                    }}
                >
                    <Typography variant="body1">{message?.text}</Typography>
                </Box>

                <Box mb={2}>
                    <Grid container spacing={3}>
                        <Grid item md={2}>
                            {message?.comments?.count > 0 && (
                                <Button
                                    size="small"
                                    startIcon={<ChatBubbleOutlineIcon />}
                                    onClick={() =>
                                        setShowCommentsMessage(
                                            showCommentsMessage
                                                ? null
                                                : message,
                                        )
                                    }
                                >
                                    {message?.comments?.count}{' '}
                                    {cxt.t('comments')}
                                </Button>
                            )}

                            {fold === 'INBOX' &&
                                message?.comments?.count === 0 && (
                                    <Button
                                        size="small"
                                        startIcon={<ReplyIcon />}
                                        onClick={() =>
                                            setShowCommentsMessage(message)
                                        }
                                    >
                                        {cxt.t('Reply')}
                                    </Button>
                                )}
                        </Grid>

                        <Grid item md={3}>
                            {message?.numUnread > 0 && (
                                <Button
                                    size="small"
                                    startIcon={<DoneAllIcon />}
                                    onClick={() =>
                                        updateMessageReadMutation.mutate({
                                            id: message?.id,
                                        })
                                    }
                                >
                                    {cxt.t('MarkAsRead')}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Box>

                {showCommentsMessage?.id === message?.id && (
                    <MessageCommentList message={message} />
                )}
            </Box>
        </>
    );
}
