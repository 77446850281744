import React, { useState, useContext } from 'react';
import {
    Paper,
    Box,
    Container,
    Grid,
    Divider,
    Typography,
} from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import { useParams, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useQuery } from 'react-query';
import {
    AppContext,
    SttLabelForm,
    SttButton,
    SttLabelDate,
    SttButtonTicket,
} from '../../../sporttia/all';
import useTpvsService from '../../../services/TpvsService';
import { USER_TPV_RESULT } from '../../../lib/queryKeys';
import { useInteractionsFiles, useLoader } from '../../../lib/hooks';
import { formatPriceByLocale } from '../../../lib/utils';
import constants from '../../../config/constants';
import translations from '../../../translations';

const maxAttempts = 20;

/**
 *  View with the result of a payment made with the virtual POS.
 */
export default function TPVResult() {
    const cxt = useContext(AppContext);
    const { id: tpvId } = useParams();
    const params = useLocation().search;
    const stripeUrlParameter = new URLSearchParams(params).get('payment');
    const tpvsService = useTpvsService();
    const { openFile } = useInteractionsFiles();
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [skeletonOff, setSkeletonOff] = useState(false);
    const [refetchInterval, setRefetchInterval] = useState(1000);
    const [refetchAttempts, setRefetchAttempts] = useState(1);
    const tpvQuery = useQuery(
        [USER_TPV_RESULT, { id: tpvId }],
        () => tpvsService.get(tpvId, { trash: true }),
        {
            enabled: tpvId !== null && tpvId !== 'create' && !paymentSuccess,
            refetchInterval,
            onSuccess: (data) => {
                if (refetchAttempts < maxAttempts) {
                    setRefetchAttempts(refetchAttempts + 1);

                    if (
                        data?.tpv?.result !== 'RESULT_ERROR' &&
                        !data?.tpv?.trash &&
                        data?.tpv?.status === 'PAID' &&
                        data?.tpv?.payment?.id
                    ) {
                        setPaymentSuccess(true);
                        setSkeletonOff(true);
                    } else if (stripeUrlParameter == null) {
                        setRefetchInterval(false);
                        setSkeletonOff(true);
                    }
                } else {
                    setRefetchInterval(false);
                    setSkeletonOff(true);
                }
            },
            onError: () => {
                refetchInterval(false);
                setSkeletonOff(true);
            },
        },
    );

    const [, loader] = useLoader([
        tpvQuery.status ? tpvQuery.status : 'loading',
    ]);

    /**
     * Download ticket if payment was successful.
     */
    const downloadTicket = () => {
        openFile(
            `/payments/${tpvQuery?.data?.tpv?.payment?.id}.pdf`,
            'application/pdf',
            null,
            `${cxt.t('Ticket')}_${tpvQuery?.data?.tpv?.payment?.id}`,
        );
    };

    let paymentText;
    if (paymentSuccess) {
        paymentText = (
            <>
                <h2>{cxt.t('PaymentCollected')}</h2>
                <Typography color="textSecondary" variant="body2">
                    {cxt.t('page.tpv.ok.paymentOk')}
                </Typography>
            </>
        );
    } else if (tpvQuery.data?.tpv.result === constants.tpv.results.errorPurse) {
        paymentText = (
            <>
                <h1>{cxt.t('tpv.error.title')}</h1>
                <Typography color="textSecondary" variant="body2">
                    {cxt.t(translations.tpv.errorPurseRefund)}
                </Typography>
            </>
        );
    } else {
        paymentText = (
            <>
                <h1>{cxt.t('tpv.error.title')}</h1>
                <Typography color="textSecondary" variant="body2">
                    {cxt.t('tpv.error.unknown.desc')}
                </Typography>
            </>
        );
    }

    return (
        <Box my={5}>
            {loader}
            {skeletonOff ? (
                <Container maxWidth="sm">
                    <Box my={2}>
                        <Grid container>
                            <Grid item xs={6}>
                                <SttButton
                                    icon={<ArrowBackIcon />}
                                    caption={cxt.t('backToSporttia')}
                                    onClick={() => {
                                        window.parent.location.href = '/';
                                    }}
                                />
                            </Grid>
                            {paymentSuccess && (
                                <Grid item xs={6}>
                                    <SttButtonTicket
                                        fullWidth
                                        onClick={downloadTicket}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Box>

                    <Paper>
                        <Box p={3}>
                            {paymentText}
                            <Box my={4}>
                                <Divider variant="middle" />
                            </Box>

                            {/* --- Error, money on the e-purse --- */}
                            {tpvQuery?.data?.tpv?.result ===
                                'RESULT_ERROR_MONEY_IN_BANK' && (
                                <Box my={5}>
                                    <Alert severity="error">
                                        {cxt.t('page.tpv.ok.moneyInBank')}
                                    </Alert>
                                </Box>
                            )}

                            {/* --- TPV data --- */}
                            <Grid container spacing={3}>
                                <SttLabelForm
                                    grid
                                    md={4}
                                    sm={6}
                                    xs={6}
                                    title={cxt.t('InvoiceNumber')}
                                    text={tpvQuery?.data?.tpv?.id}
                                />

                                <SttLabelForm
                                    grid
                                    md={4}
                                    sm={6}
                                    xs={6}
                                    title={cxt.t('Created')}
                                    text={
                                        <SttLabelDate
                                            date={tpvQuery?.data?.tpv?.created}
                                        />
                                    }
                                />

                                <SttLabelForm
                                    grid
                                    md={4}
                                    sm={6}
                                    xs={6}
                                    title={cxt.t('PaymentForm')}
                                    text={cxt.t('PF.TPV')}
                                />

                                <SttLabelForm
                                    grid
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    title={cxt.t('Concept')}
                                    text={tpvQuery?.data?.tpv?.concept}
                                />

                                <SttLabelForm
                                    grid
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    title={cxt.t('Sportcenter')}
                                    text={tpvQuery?.data?.tpv?.sc?.name}
                                />

                                <SttLabelForm
                                    grid
                                    md={10}
                                    sm={12}
                                    xs={12}
                                    title={cxt.t('Payer')}
                                    text={tpvQuery?.data?.tpv?.user?.name}
                                />

                                <SttLabelForm
                                    grid
                                    md={2}
                                    sm={12}
                                    xs={12}
                                    title={cxt.t('Price')}
                                    text={`${formatPriceByLocale(
                                        tpvQuery?.data?.tpv?.price,
                                        tpvQuery?.data?.tpv?.priceShape
                                            ?.currency,
                                        tpvQuery?.data?.tpv?.priceShape?.locale,
                                    )}`}
                                />
                            </Grid>
                        </Box>
                    </Paper>
                </Container>
            ) : (
                <Container maxWidth="sm">
                    <Skeleton variant="rect" sm="5" height="50vh" />
                </Container>
            )}
        </Box>
    );
}
