import React, { useState, useEffect, useContext } from 'react';
import { Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
    AppContext,
    SttButtonFab,
    SttChipUser,
    SttTable,
    SttTopControls,
} from '../../../sporttia/all';
import { useToggle, useTuples, useCrud } from '../../../lib/hooks';
import { getPath } from '../../Pages';
import { copyToClipboard, fullName } from '../../../lib/utils';

export default function BookingSchedules() {
    // Filter presets, i.e. filter combinations handled by a single <Select>
    const presets = {
        ALL: {
            isActive: '',
            onTime: '',
        },
        ACTIVE: {
            isActive: true,
        },
        ONTIME: {
            onTime: true,
        },
    };

    const cxt = useContext(AppContext);
    const history = useHistory();

    const [bookingSchedules, setBookingSchedules, , Get] = useCrud();

    // Filters hook
    const [filters, setFilter] = useTuples({
        name: '',
    });

    // Initial preset
    const [preset, setPreset] = useState('ACTIVE');

    // Load data from API
    function loadBookingSchedules(params = { rows: 20 }) {
        Get(`/scs/${cxt.sc.id}/bookings/schedules`, {
            ...params,
            ...filters,
            ...presets[preset],
            // eslint-disable-next-line no-use-before-define
            trash,
        }).then(setBookingSchedules);
    }

    // Trash hook
    const [trash, toggleTrash] = useToggle(() => {
        loadBookingSchedules();
    });

    // On preset change, reload data
    useEffect(() => {
        loadBookingSchedules();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Init
    useEffect(() => {
        cxt.setHeader({ title: cxt.t('FixedBookings') });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Go to /scs/bookings/schedules/create
    function goCreateNew() {
        history.push(getPath('bookingSchedule', { id: 'create' }));
    }

    return (
        <Paper>
            <SttTopControls
                p={2}
                fields={[
                    {
                        caption: cxt.t('Name'),
                        type: 'text',
                        name: 'name',
                        value: filters.name,
                        onEnterKey: loadBookingSchedules,
                    },
                ]}
                extraFields={[
                    {
                        caption: cxt.t('Status'),
                        type: 'select',
                        name: 'status',
                        value: preset,
                        // These options correspond to the presets declared above
                        options: [
                            {
                                caption: cxt.t('All'),
                                value: 'ALL',
                            },
                            {
                                caption: cxt.t('Active'),
                                value: 'ACTIVE',
                            },
                            {
                                caption: cxt.t('OnTime'),
                                value: 'ONTIME',
                            },
                        ],
                        onChange: ({ value }) => setPreset(value),
                    },
                    {
                        caption: `${cxt.t('dni')}/CIF`,
                        type: 'text',
                        name: 'dni',
                        value: filters.dni,
                    },
                ]}
                onToggleTrash={toggleTrash}
                onChange={setFilter}
                onFilter={loadBookingSchedules}
            />
            <SttTable
                autoload={false}
                onClickRow={(row) =>
                    history.push(getPath('bookingSchedule', { id: row.id }))
                }
                onFetch={loadBookingSchedules}
                data={bookingSchedules}
                columns={[
                    {
                        title: '#',
                        type: 'tooltip',
                        value: (row) => ({ label: '#', value: row.id }),
                        onClick: (row) =>
                            copyToClipboard(row.id, () =>
                                cxt.showMessage(
                                    'S',
                                    cxt.t('copiedToClipboard'),
                                ),
                            ),
                    },
                    {
                        title: cxt.t('Name'),
                        align: 'left',
                        type: 'text',
                        field: 'name',
                    },
                    {
                        title: cxt.t('User'),
                        value: (row) => (
                            <SttChipUser user={{ login: fullName(row.user) }} />
                        ),
                    },
                    {
                        title: cxt.t('Active'),
                        type: 'binaryStatus',
                        value: (row) => row.status === 'ACTIVE',
                    },
                    {
                        title: cxt.t('Period'),
                        type: 'period',
                        value: (row) => ({
                            ini: row.dateini,
                            end: row.dateend,
                        }),
                    },
                    {
                        title: cxt.t('Facility'),
                        type: 'facility',
                        field: 'facility',
                    },
                    {
                        title: cxt.t('Bookings'),
                        type: 'text',
                        field: 'numPeriods',
                    },
                ]}
            />
            <SttButtonFab onClick={goCreateNew} />
        </Paper>
    );
}
