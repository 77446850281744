import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Paper,
    Avatar,
    Grid,
    Hidden,
    Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { PersonOutline, Add, Close, Euro } from '@material-ui/icons';
import {
    AppContext,
    SttButton,
    SttSelectMshipDialog,
    SttFormSwitch,
} from '../../../sporttia/all';
import {
    deleteElementFromArray,
    updateElementInArray,
    formatPriceByLocale,
} from '../../../lib/utils';
import BookingSelectRateDialog from './BookingSelectRateDialog';
import BookingSelectPupilsDialog from './BookingSelectPupilsDialog';
import RateAndBoletos from './RateAndBoletos';
import constants from '../../../config/constants';
/**
 *	Selecting the occupants
 *	-----------------------
 *
 *	We've got the next scenario:
 *
 *		1) I'm user and it's a capacity booking -> checklist
 *		2) I'm user and it's NOT a capacity booking -> just a SttFormText with my name
 *		3) I'm sports center and it's a capacity booking -> SttList with selected users
 *		4) I'm sports center and it's NOT a capacity booking -> SttSelectMship
 *
 */

const Scenarios = {
    USER_CAPACITY: 1,
    USER_NO_CAPACITY: 2,
    SC_CAPACITY: 3,
    SC_NO_CAPACITY: 4,
};

export default function BookingSectionOccupants({
    timeini,
    timeend,
    piece,
    column,
    rateType,
    setRateType,
    sc,
    ImSCOwner,
    occupants,
    setOccupants,
}) {
    const cxt = useContext(AppContext);
    const [scenario, setScenario] = useState(0);

    const [occupantMshipSelecting, setOccupantMshipSelecting] = useState(null);
    const [openSelectingRateOccupant, setOpenSelectingRateOccupant] =
        useState(null);
    const [openPupilsDialog, setOpenPupilsDialog] = useState(false);

    const isUserOrTeacher =
        cxt.user.role === constants.roles.user ||
        cxt.user.role === constants.roles.teacher;

    useEffect(() => {
        if (occupants) {
            // For each of the two rate types, we check if each user has rates of the appropriate type and set it to null otherwise
            let ratesKey;
            let altRatesKey;
            if (rateType === cxt.constants.RT_Individual) {
                ratesKey = 'individualBonos';
                altRatesKey = 'individualRates';
            } else if (rateType === cxt.constants.RT_Complete) {
                ratesKey = 'fullBonos';
                altRatesKey = 'fullRates';
            }

            // fullBonos, individualBonos

            const _occupants = occupants.map((occupant) => {
                let selectedRate = null;
                let selectedBoleto = null;

                if (occupant.fares[ratesKey]) {
                    selectedRate = null;
                    selectedBoleto = occupant.fares[ratesKey][0];
                } else if (occupant.fares[altRatesKey]) {
                    selectedRate = occupant.fares[altRatesKey][0];
                    selectedBoleto = null;
                }

                return {
                    ...occupant,
                    selectedRate,
                    selectedBoleto,
                };
            });

            setOccupants(_occupants);
        }
    }, [rateType]);

    /**
     * Load fares
     *
     * 	fares: {
     * 		individualRates: [{
     *			total, lowest,
     *			prices: [ { id, name, duration, price }, ... ]
     *		}, ... ]
     * 		fullRates: ... the same
     * 		individualBonos: [{ id, amount, consumed, bono: {id, name, type} }]
     * 		fullBonos: ... the same
     * 	}
     */
    const loadFares = (occupant) => {
        if (occupant && occupant.id) {
            cxt.api('GET', '/bookings/fares', {
                params: {
                    ini: timeini,
                    end: timeend,
                    idFacility: column.facility.id,
                    idUser: occupant.user.id,
                },
                success(r) {
                    const fares = r;

                    if (fares) {
                        // Initially, we must select the default rate but forcefully switch between full / individual if there are no appropriate rates.
                        if (rateType === cxt.constants.RT_Individual) {
                            if (
                                fares.individualBonos &&
                                fares.individualBonos.length > 0
                            ) {
                                occupant.selectedBoleto =
                                    fares.individualBonos[0];
                            } else if (
                                fares.individualRates &&
                                fares.individualRates.length > 0
                            ) {
                                occupant.selectedRate =
                                    fares.individualRates[0];
                            } else if (
                                fares.fullBonos &&
                                fares.fullBonos.length > 0
                            ) {
                                setRateType(cxt.constants.RT_Complete);
                                occupant.selectedBoleto = fares.fullBonos[0];
                            } else if (
                                fares.fullRates &&
                                fares.fullRates.length > 0
                            ) {
                                setRateType(cxt.constants.RT_Complete);
                                occupant.selectedRate = fares.fullRates[0];
                            }
                        } else if (
                            fares.fullBonos &&
                            fares.fullBonos.length > 0
                        ) {
                            occupant.selectedBoleto = fares.fullBonos[0];
                        } else if (
                            fares.fullRates &&
                            fares.fullRates.length > 0
                        ) {
                            occupant.selectedRate = fares.fullRates[0];
                        } else if (
                            fares.individualBonos &&
                            fares.individualBonos.length > 0
                        ) {
                            setRateType(cxt.constants.RT_Individual);
                            occupant.selectedBoleto = fares.individualBonos[0];
                        } else if (
                            fares.individualRates &&
                            fares.individualRates.length > 0
                        ) {
                            setRateType(cxt.constants.RT_Individual);
                            occupant.selectedRate = fares.individualRates[0];
                        }

                        // Update occupants
                        if (
                            (fares.individualRates &&
                                fares.individualRates.length > 0) ||
                            (fares.fullRates && fares.fullRates.length > 0) ||
                            (fares.individualBonos &&
                                fares.individualBonos.length > 0) ||
                            (fares.fullBonos && fares.fullBonos.length > 0)
                        ) {
                            occupant.fares = fares;
                            setOccupants(
                                updateElementInArray(occupants, occupant),
                            );
                        } else {
                            cxt.showMessage(
                                'I',
                                cxt.t('app.booking.noAvailableRates'),
                            );
                            setOccupants(
                                deleteElementFromArray(occupants, occupant),
                            );
                            if (ImSCOwner) {
                                setOccupantMshipSelecting({});
                            }
                            // else setOpenPupilsDialog(true)
                        }
                    }
                },
            });
        }
    };

    /**
     * Times change, we've got to recalculate fares
     */
    useEffect(() => {
        // Load all fares of all occupants
        if (timeini && timeend && occupants) {
            occupants.forEach((occupant) => {
                loadFares(occupant);
            });
        }
    }, [timeini, timeend]);

    /**
     * Update the occupant with not fares
     */
    useEffect(() => {
        occupants.forEach((occupant) => {
            if (!occupant.fares) {
                loadFares(occupant);
            }
        });
    }, [occupants]);

    /**
     * Select the current scenario
     */
    useEffect(() => {
        const isWithCapacity = piece.capacity && piece.capacity.total > 0;

        // Set scenario
        if (ImSCOwner) {
            setScenario(
                isWithCapacity
                    ? Scenarios.SC_CAPACITY
                    : Scenarios.SC_NO_CAPACITY,
            );
        } else {
            setScenario(
                isWithCapacity
                    ? Scenarios.USER_CAPACITY
                    : Scenarios.USER_NO_CAPACITY,
            );
        }
    }, [ImSCOwner, cxt.user, piece]);

    /**
     * Secenairo is just set
     */
    useEffect(() => {
        if (scenario) {
            switch (scenario) {
                // Just enter show the selecing mship dialog to choose one
                case Scenarios.SC_CAPACITY:
                case Scenarios.SC_NO_CAPACITY:
                    setOccupantMshipSelecting({});
                    break;
                default:
                    break;
            }
        }
    }, [scenario]);

    /**
     * Add / updateoccupant
     */
    const addSaveOccupant = (occupant) => {
        // Set default id for the occupant
        if (!occupant.id) {
            occupant.id = occupant.user.id;
        }

        // Update occupants
        setOccupants(updateElementInArray(occupants, occupant));
    };

    /**
     * Delete occupan
     */
    const delOccupant = (occupant) => {
        setOccupants(deleteElementFromArray(occupants, occupant));
    };

    /**
     * Init
     */
    useEffect(() => {
        // If I'm a user the get my mship (relation between sports center and me)
        if (isUserOrTeacher) {
            cxt.api('GET', `/scs/${sc.id}/me`, {
                success: (r) => {
                    // Set first occupant, me
                    addSaveOccupant({
                        name: r.mship.fullName
                            ? r.mship.fullName
                            : r.mship.user.fullName,
                        mship: r.mship,
                        user: cxt.user,
                    });
                },
            });
        }
    }, []);

    // -----| Render |-----
    return (
        <Box mt={2}>
            {occupants &&
                occupants.map((occupant) => (
                    <Box mt={2} key={occupant.id}>
                        <Paper>
                            {[
                                Scenarios.USER_NO_CAPACITY,
                                Scenarios.SC_NO_CAPACITY,
                            ].includes(scenario) && (
                                <Box
                                    p={3}
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    <SttFormSwitch
                                        caption={cxt.t('individualRates')}
                                        checked={
                                            rateType ===
                                            cxt.constants.RT_Individual
                                        }
                                        onChange={({ value }) =>
                                            setRateType(
                                                value
                                                    ? cxt.constants
                                                          .RT_Individual
                                                    : cxt.constants.RT_Complete,
                                            )
                                        }
                                    />
                                </Box>
                            )}
                            <Box p={3}>
                                <Grid container spacing={3}>
                                    <Hidden smDown>
                                        <Grid
                                            item
                                            md={2}
                                            sm={12}
                                            xs={12}
                                            align="center"
                                        >
                                            <Box align="center">
                                                <Avatar
                                                    alt={occupant.name}
                                                    src="/static/images/avatar/1.jpg"
                                                />
                                            </Box>
                                        </Grid>
                                    </Hidden>

                                    <Grid item md={10} sm={12} xs={12}>
                                        <Typography variant="h5">
                                            {occupant.name}
                                        </Typography>

                                        {occupant.mship &&
                                            occupant.mship.amount > 0 && (
                                                <Box>
                                                    {cxt.t('Purse')}:{' '}
                                                    <b>
                                                        {formatPriceByLocale(
                                                            occupant.mship
                                                                ?.amount,
                                                            occupant.mship
                                                                ?.amountShape
                                                                ?.currency,
                                                            occupant.mship
                                                                ?.amountShape
                                                                ?.locale,
                                                        )}
                                                    </b>
                                                </Box>
                                            )}

                                        <RateAndBoletos occupant={occupant} />

                                        {/* Buttons */}
                                        <Box mt={3}>
                                            <Grid container spacing={3}>
                                                {occupant.user && (
                                                    <Grid
                                                        item
                                                        md={3}
                                                        sm={6}
                                                        xs={6}
                                                    >
                                                        <SttButton
                                                            fullWidth
                                                            size="small"
                                                            startIcon={
                                                                piece.priceShape
                                                                    ?.currency !==
                                                                'EUR' ? (
                                                                    piece
                                                                        .priceShape
                                                                        ?.currency
                                                                ) : (
                                                                    <Euro />
                                                                )
                                                            }
                                                            caption={cxt.t(
                                                                'SelectRate',
                                                            )}
                                                            onClick={() =>
                                                                setOpenSelectingRateOccupant(
                                                                    occupant,
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                )}

                                                {[
                                                    Scenarios.SC_CAPACITY,
                                                    Scenarios.SC_NO_CAPACITY,
                                                ].includes(scenario) && (
                                                    <Grid
                                                        item
                                                        md={3}
                                                        sm={6}
                                                        xs={6}
                                                    >
                                                        <SttButton
                                                            fullWidth
                                                            size="small"
                                                            startIcon={
                                                                <PersonOutline />
                                                            }
                                                            caption={cxt.t(
                                                                'SelectUser',
                                                            )}
                                                            onClick={() =>
                                                                setOccupantMshipSelecting(
                                                                    occupant,
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                )}

                                                {[
                                                    Scenarios.USER_CAPACITY,
                                                    Scenarios.SC_CAPACITY,
                                                ].includes(scenario) && (
                                                    <Grid
                                                        item
                                                        md={3}
                                                        sm={6}
                                                        xs={6}
                                                    >
                                                        <SttButton
                                                            fullWidth
                                                            size="small"
                                                            startIcon={
                                                                <Close />
                                                            }
                                                            caption={cxt.t(
                                                                'Delete',
                                                            )}
                                                            onClick={() =>
                                                                delOccupant(
                                                                    occupant,
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                )}

                                                {occupant.selectedRate && (
                                                    <Grid item md>
                                                        <Box
                                                            align="right"
                                                            style={{
                                                                fontSize: 18,
                                                            }}
                                                        >
                                                            {cxt.t('Total')}:{' '}
                                                            <b>
                                                                {formatPriceByLocale(
                                                                    occupant
                                                                        ?.selectedRate
                                                                        ?.total,
                                                                    occupant
                                                                        ?.selectedRate
                                                                        ?.prices[0]
                                                                        ?.priceShape
                                                                        .currency,
                                                                    occupant
                                                                        ?.selectedRate
                                                                        ?.prices[0]
                                                                        ?.priceShape
                                                                        ?.locale,
                                                                )}
                                                            </b>
                                                        </Box>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>
                ))}

            {occupants && occupants.length === 0 && (
                <Alert severity="warning">
                    {cxt.t('booking.youMustSelectOneOccupant')}
                </Alert>
            )}

            {([Scenarios.USER_CAPACITY, Scenarios.SC_CAPACITY].includes(
                scenario,
            ) ||
                (scenario === Scenarios.SC_NO_CAPACITY &&
                    !occupants.length)) && (
                <Box mt={2}>
                    <SttButton
                        fullWidth
                        startIcon={<Add />}
                        caption={cxt.t('AddUserNotInTheList')}
                        onClick={() => {
                            if (ImSCOwner) {
                                setOccupantMshipSelecting({});
                            } else {
                                setOpenPupilsDialog(true);
                            }
                        }}
                    />
                </Box>
            )}

            <SttSelectMshipDialog
                open={occupantMshipSelecting != null}
                onClose={() => setOccupantMshipSelecting(null)}
                onSelectMship={(mship) => {
                    addSaveOccupant({
                        id: occupantMshipSelecting.id,
                        name: mship.fullName,
                        mship,
                        user: mship.user,
                    });

                    // Close popup
                    setOccupantMshipSelecting(null);
                }}
            />

            <BookingSelectRateDialog
                open={openSelectingRateOccupant != null}
                fares={
                    openSelectingRateOccupant && openSelectingRateOccupant.fares
                }
                rateType={rateType}
                onClose={() => setOpenSelectingRateOccupant(null)}
                onSelect={({ rate, boleto }) => {
                    addSaveOccupant({
                        ...openSelectingRateOccupant,
                        selectedRate: !boleto && rate,
                        selectedBoleto: boleto,
                    });
                }}
                occupants={occupants}
            />
            {isUserOrTeacher && (
                <BookingSelectPupilsDialog
                    open={openPupilsDialog}
                    sc={sc}
                    occupants={occupants}
                    onClose={() => setOpenPupilsDialog(false)}
                    onAddOccupant={addSaveOccupant}
                />
            )}
        </Box>
    );
}
