import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid } from '@material-ui/core';
import { AppContext, SttGoogleMap } from '../../../sporttia/all';

export default function ConfigurationMap() {
    const cxt = useContext(AppContext);
    const [mapX, setMapX] = useState();
    const [mapY, setMapY] = useState();

    /**
     * Save location
     */
    const saveSportcenter = (p) => {
        cxt.api('PUT', `/scs/${cxt.sc.id}`, {
            params: {
                mapX: p.lat,
                mapY: p.lng,
            },
            success: (r) => {
                cxt.showMessage('S', cxt.t('Saved'));
            },
        });
    };

    useEffect(() => {
        if (cxt && cxt.sc) {
            setMapX(cxt.sc.mapX);
            setMapY(cxt.sc.mapY);
        }
    }, [cxt.sc]);

    return (
        <>
            {mapX && mapY && (
                <SttGoogleMap
                    width="100%"
                    height={400}
                    apiKey="AIzaSyBy9IBfS1reqmVgvMaEL1xeUUavAYA_KTc"
                    latLng={{ lat: mapX, lng: mapY }}
                    searchEnabled
                    searchValue=""
                    searchPosition="top"
                    onChange={saveSportcenter}
                />
            )}
        </>
    );
}
