import React, { useState, useEffect, useContext } from 'react';
import {
    Avatar,
    Box,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import { Delete as DeleteIcon, Star } from '@material-ui/icons';
import { formatPriceByLocale, fullName } from '../../lib/utils';
import { AppContext } from '../AppContext';

const useStyles = makeStyles((theme) => ({
    pending: {
        color: 'red',
    },
    paid: {
        color: 'green',
    },
}));

export function SttParticipantsList({ participants, onDelete }) {
    const cxt = useContext(AppContext);
    const classes = useStyles();

    // -----| Render |-----
    return (
        <List>
            {participants &&
                participants.map((participant, i) => {
                    let priceText = '';
                    let paymentForm;
                    let paymentFormClass;
                    if (participant.enrollment) {
                        if (participant.enrollment.price > 0) {
                            priceText = formatPriceByLocale(
                                participant.enrollment.price,
                                participant?.enrollment?.priceShape?.currency,
                                participant?.enrollment?.priceShape?.locale,
                            );
                            paymentForm =
                                participant.enrollment.paymentFormLang;
                        } else {
                            priceText = cxt.t('Free');
                        }
                        paymentFormClass =
                            participant.enrollment.status == 'PAID'
                                ? classes.paid
                                : classes.pending;
                    }

                    return (
                        <div key={i}>
                            {i > 0 && <Divider />}
                            <ListItem button>
                                <ListItemText
                                    primary={fullName(participant)}
                                    secondary={`${priceText}${
                                        paymentForm ? ` - ${paymentForm}` : ''
                                    }`}
                                    secondaryTypographyProps={{
                                        className: paymentFormClass,
                                    }}
                                />
                                {!participant.admin ? (
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            onClick={() =>
                                                onDelete(participant)
                                            }
                                            edge="end"
                                            aria-label="delete"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                ) : (
                                    <Star />
                                )}
                            </ListItem>
                        </div>
                    );
                })}
            {participants && participants.length == 0 && (
                <ListItem>
                    <ListItemText primary={cxt.t('None')} />
                </ListItem>
            )}
        </List>
    );
}
