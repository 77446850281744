import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { useCrud } from '../../lib/hooks';
import {
    AppContext,
    SttDialog,
    SttFormDate,
    SttFormSelect,
    SttFormText,
    SttSelectFacility,
    SttSelectUser,
    SttSelectWeekdays,
} from '../../sporttia/all';

/**
 * CreateBookingScheduleDialog : used to create fixed bookings from the timetable section (TimetableOperate component)
 * Object item - bookingSchedule params
 * Function onSave - callback for the save button
 * Function onClose - callback fort he Close button
 */
export default function CreateBookingScheduleDialog({ item, onSave, onClose }) {
    const cxt = useContext(AppContext);

    const [bookingSchedule, , setProperty, , Post] = useCrud(item);

    // What it says
    function saveAndClose() {
        if (onSave && onSave.constructor === Function) {
            onSave();
        }
        if (onClose && onClose.constructor === Function) {
            onClose();
        }
    }

    // Create a new fixed booking via POST
    function save() {
        const params = {
            name: bookingSchedule.name,
            idUser: bookingSchedule.user?.id,
            idFacility: bookingSchedule.facility.id,
            dateini: moment(bookingSchedule.dateini).format('YYYY-MM-DD'),
            dateend: moment(bookingSchedule.dateend).format('YYYY-MM-DD'),
            timeini: bookingSchedule.timeini,
            timeend: bookingSchedule.timeend,
            weekdays: bookingSchedule.weekdays,
        };

        Post(`/scs/${cxt.sc.id}/bookings/schedules`, params).then(
            (response) => {
                if (response.bookingSchedule) {
                    saveAndClose();
                }
            },
        );
    }

    if (!bookingSchedule) {
        return null;
    }

    return (
        <SttDialog
            open
            onClose={onClose}
            title={cxt.t('timetable.createFixedBook')}
            buttons={[
                {
                    type: 'save',
                    onClick: save,
                },
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
            content={
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SttFormText
                            name="name"
                            caption={cxt.t('Name')}
                            defVal={bookingSchedule.name}
                            onChange={setProperty}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SttFormSelect
                            name="status"
                            caption={cxt.t('Status')}
                            defVal={bookingSchedule.status}
                            options={cxt.constants.scheduleStatuses}
                            onChange={setProperty}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <SttFormDate
                            disabled={!!bookingSchedule.id}
                            caption={cxt.t('dateIni')}
                            name="dateini"
                            defVal={bookingSchedule.dateini}
                            onChange={setProperty}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <SttFormDate
                            disabled={!!bookingSchedule.id}
                            caption={cxt.t('dateEnd')}
                            name="dateend"
                            defVal={bookingSchedule.dateend}
                            onChange={setProperty}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SttSelectUser
                            caption={cxt.t('User')}
                            user={bookingSchedule.user}
                            onSelect={(mship) => {
                                setProperty({
                                    name: 'user',
                                    value: {
                                        fullName: mship.fullName,
                                        id: mship.user.id,
                                    },
                                });
                            }}
                            returnMship
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SttSelectFacility
                            disabled
                            caption={cxt.t('Facility')}
                            defVal={
                                bookingSchedule.facility &&
                                bookingSchedule.facility.id
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {/* <SttBookingScheduleSlotList
							slots={[
								{
									weekdays: bookingSchedule.weekdays,
									timeini: bookingSchedule.timeini,
									timeend: bookingSchedule.timeend
								}
							]}
						/> */}
                        <SttSelectWeekdays
                            name="weekdays"
                            selected={bookingSchedule.weekdays}
                            onChange={setProperty}
                        />
                    </Grid>
                </Grid>
            }
        />
    );
}
