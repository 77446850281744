import React, { useState, useEffect, useContext } from 'react';
import { AppContext, SttList } from '../../../sporttia/all';
import { useTranslations } from '../../../lib/hooks';

export default function MshipPrivileges({ mship }) {
    const cxt = useContext(AppContext);
    const [privileges, setPrivileges] = useState([]);
    const { translate } = useTranslations();
    /**
     * Get privileges
     */
    const loadPrivileges = (p = {}) => {
        cxt.api('GET', `/mships/${mship.id}/privileges`, {
            params: {
                page: p.page,
                rows: p.numRows,
            },
            success: (r) => {
                setPrivileges(r.privileges);
            },
        });
    };

    /**
     * Init
     */
    useEffect(() => {
        loadPrivileges();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -----| Render |-----
    return (
        <SttList
            data={
                privileges &&
                privileges.map((priv) => ({
                    caption: translate(priv?.name),
                    text: translate(priv?.description),
                    chips: priv?.group?.name
                        ? [{ caption: translate(priv?.group?.name) }]
                        : null,
                }))
            }
        />
    );
}
