import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Grid,
    Box,
    Container,
    Typography,
    InputAdornment,
    IconButton,
} from '@material-ui/core';
import { Apps, PostAdd, BallotOutlined, Euro } from '@material-ui/icons';
import Paper from '@material-ui/core/Paper';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Button from '@material-ui/core/Button';
import GolfCourseIcon from '@material-ui/icons/GolfCourse';
import SearchIcon from '@material-ui/icons/Search';
import { useForm } from 'react-hook-form';
import SttValidatedForm from '../../../sporttia/SttValidatedForm';
import SCWall from './SCWall';
import { getArrayWithOnlyValues } from '../../../lib/utils';
import { SttEventCardList } from '../../../sporttia/public/cards/SttEventCardList';
import { getPath } from '../../Pages';
import logoEUNextGen from '../../../images/dipSevillaLogos/logoEUNextGeneration.png';
import logoPlanRecuperacion from '../../../images/dipSevillaLogos/logoPlanRecuperacion.png';
import logoDipuSevilla from '../../../images/dipSevillaLogos/logoDipuSevilla.png';
import logoGob from '../../../images/dipSevillaLogos/MPTMD.Gob.png';
import {
    AppContext,
    SttActivityCardList,
    SttButton,
    SttTabs,
} from '../../../sporttia/all';
import Page404 from '../../Page404';

export default function SCProfile(props) {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const formActivity = useForm();
    const watchActivityKeyword = formActivity.watch('keyword');
    const formEvent = useForm();
    const watchEventKeyword = formEvent.watch('keyword');
    const [idSC, setIdSC] = useState();
    const [sc, setSC] = useState();
    const [SCPrivileges, setSCPrivileges] = useState([]);
    const [searchKeywordEvent, setSearchKeywordEvent] = useState('');
    const [searchKeywordActivity, setSearchKeywordActivity] = useState('');

    const hasUserMship =
        cxt.user.role === cxt.constants.roleUser ||
        cxt.user.role === cxt.constants.roleTeacher;

    /**
     * Get sport centers privileges data
     */
    useEffect(() => {
        if (sc) {
            if (hasUserMship) {
                cxt.api('GET', `/scs/${sc.id}/me`, {
                    success: (r) => {
                        if (r?.mship?.privileges) {
                            setSCPrivileges(r.mship.privileges);
                        }
                    },
                });
            } else {
                cxt.api('GET', `/scs/${sc.id}/privileges`, {
                    success: (r) => {
                        const activePrivileges = r.rows.filter(
                            (privilege) => privilege.active,
                        );

                        setSCPrivileges(
                            getArrayWithOnlyValues('id', activePrivileges),
                        );
                    },
                });
            }
        }
    }, [sc]);

    /**
     * Sports center changes
     */
    useEffect(() => setIdSC(props.match.params.id), [props.match.params.id]);

    /**
     * Load sport center
     */
    useEffect(() => {
        if (idSC) {
            loadSC();
            cxt.setHeader({ title: cxt?.sc?.name });
        }
    }, [idSC]);

    /**
     * Load SC
     */
    const loadSC = () => {
        cxt.api('GET', `/scs/${idSC}`, {
            success: (r) => {
                setSC(r.sc);
            },
        });
    };

    const hasPurse =
        sc?.modules?.find((name) => name === 'purse') && sc?.tpvConf;

    // -----| Render |-----
    if (sc?.status === 'ACTIVE') {
        return (
            <Container maxWidth="md">
                <Paper>
                    <Box p={2}>
                        <Grid container spacing={3} justifyContent="center">
                            <Grid item md={12}>
                                <Box mt={3} align="center">
                                    <img
                                        src={cxt.getResource(
                                            `/images/${
                                                sc.logo?.id ?? 0
                                            }.jpg?outfit=100`,
                                        )}
                                        alt={`Logo de ${sc.name}`}
                                    />
                                </Box>

                                <Box mt={3} align="center">
                                    <Typography variant="h5">
                                        {sc.name}
                                    </Typography>

                                    <Typography variant="subtitle1">
                                        {sc.city && sc.city.name}{' '}
                                        {sc.city &&
                                            sc.city.province &&
                                            `(${sc.city.province.name})`}
                                    </Typography>
                                </Box>
                                {sc?.isDipuSevilla && (
                                    <Box
                                        display="flex"
                                        alignItems="center" // This aligns items to the top
                                        justifyContent="center"
                                        gap={2} // Adds space between
                                    >
                                        <img
                                            src={logoEUNextGen}
                                            alt="Logo EU Next Generation"
                                            width="200px"
                                            height="52px"
                                        />
                                        <img
                                            src={logoGob}
                                            alt="Logo Gobierno de España Min. Transp, Mov, Ag Urb"
                                            width="200px"
                                            height="52px"
                                        />
                                        <img
                                            src={logoPlanRecuperacion}
                                            alt="Logo Plan de recuperación"
                                            width="200px"
                                            height="100px"
                                        />
                                        <img
                                            src={logoDipuSevilla}
                                            alt="Logo Diputación de Sevilla"
                                            width="52px"
                                            height="52px"
                                        />
                                    </Box>
                                )}
                                <Box mt={1} align="center">
                                    <Button
                                        size="small"
                                        startIcon={<ContactSupportIcon />}
                                        onClick={() =>
                                            history.push(
                                                getPath('scContact', {
                                                    id: sc.id,
                                                }),
                                            )
                                        }
                                    >
                                        {cxt.t('Contact')}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>

                        {sc.bulletin && (
                            <Box
                                mt={2}
                                p={2}
                                border={3}
                                borderColor="secondary.main"
                            >
                                {sc.bulletin}
                            </Box>
                        )}

                        <Box mt={3}>
                            {/* La descripción del centro es HTML y así es como se inyecta HTML en React */}
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: sc.description,
                                }}
                            />
                        </Box>

                        <Box mt={3}>
                            <Grid
                                container
                                spacing={3}
                                alignItems="center"
                                justifyContent="center"
                            >
                                {/* --- Book --- */}
                                {SCPrivileges.includes(13) &&
                                    sc.modules.find(
                                        (name) => name === 'booking',
                                    ) && (
                                        <Grid
                                            item
                                            md={hasPurse ? 6 : 4}
                                            xs={12}
                                        >
                                            <SttButton
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                caption={cxt.t('Book')}
                                                startIcon={<Apps />}
                                                onClick={() =>
                                                    history.push(
                                                        getPath('timetable', {
                                                            id: sc.id,
                                                        }),
                                                    )
                                                }
                                            />
                                        </Grid>
                                    )}

                                {/* --- Golf book --- */}
                                {SCPrivileges.includes(13) &&
                                    sc?.moduleGolf && (
                                        <Grid
                                            item
                                            md={hasPurse ? 6 : 4}
                                            xs={12}
                                        >
                                            <SttButton
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                caption={cxt.t('BookGolf')}
                                                startIcon={<GolfCourseIcon />}
                                                onClick={() =>
                                                    history.push(
                                                        getPath(
                                                            'golfBookingsCards',
                                                            {
                                                                id: sc.id,
                                                            },
                                                        ),
                                                    )
                                                }
                                            />
                                        </Grid>
                                    )}

                                {/* --- Recharge purse --- */}
                                {hasPurse && (
                                    <Grid item md={6} xs={12}>
                                        <SttButton
                                            fullWidth
                                            caption={cxt.t('ChargePurse')}
                                            startIcon={
                                                sc.city?.country?.currency !==
                                                    'EUR' &&
                                                sc.city?.country?.currency ? (
                                                    sc.city?.country?.currency
                                                ) : (
                                                    <Euro />
                                                )
                                            }
                                            onClick={() =>
                                                history.push(
                                                    getPath('rechargePurse', {
                                                        id: sc.id,
                                                    }),
                                                )
                                            }
                                        />
                                    </Grid>
                                )}

                                {/* --- Buy abonos --- */}
                                <Grid item md={hasPurse ? 6 : 4} xs={12}>
                                    <SttButton
                                        fullWidth
                                        caption={cxt.t('BuyAbonos')}
                                        startIcon={<PostAdd />}
                                        onClick={() =>
                                            history.push(
                                                getPath('abonoList', {
                                                    id: sc.id,
                                                }),
                                            )
                                        }
                                    />
                                </Grid>

                                {/* --- Buy bono --- */}
                                <Grid item md={hasPurse ? 6 : 4} xs={12}>
                                    <SttButton
                                        fullWidth
                                        caption={cxt.t('Bonos')}
                                        startIcon={<BallotOutlined />}
                                        onClick={() =>
                                            history.push(
                                                getPath('bonoList', {
                                                    id: sc.id,
                                                }),
                                            )
                                        }
                                    />
                                </Grid>

                                {/* --- Documents --- */}
                                <Grid item md={hasPurse ? 6 : 4} xs={12}>
                                    <SttButton
                                        fullWidth
                                        caption={cxt.t('Documents')}
                                        startIcon={<InsertDriveFileIcon />}
                                        onClick={() =>
                                            history.push(
                                                getPath('scDocuments', {
                                                    id: sc.id,
                                                }),
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                    {/* Tabs with activities and events. */}
                    <SttTabs
                        fullWidth
                        tabs={[
                            {
                                caption: cxt.t('Wall'),
                                component: <SCWall scId={idSC} />,
                            },
                            {
                                caption: cxt.t('Activities'),
                                component: (
                                    <>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <SttValidatedForm
                                                form={formActivity}
                                                fields={[
                                                    {
                                                        type: 'textInput',
                                                        name: 'keyword',
                                                        caption:
                                                            cxt.t('search'),
                                                        size: 'medium',
                                                        InputProps: {
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            setSearchKeywordActivity(
                                                                                watchActivityKeyword,
                                                                            );
                                                                        }}
                                                                    >
                                                                        <SearchIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        },
                                                        onEnterKey: (value) => {
                                                            setSearchKeywordActivity(
                                                                value,
                                                            );
                                                        },
                                                    },
                                                ]}
                                            />
                                        </Box>
                                        <SttActivityCardList
                                            params={{
                                                keyword: searchKeywordActivity,
                                                idSC: sc?.id,
                                            }}
                                        />
                                    </>
                                ),
                            },
                            {
                                caption: cxt.t('Events'),
                                component: (
                                    <>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <SttValidatedForm
                                                form={formEvent}
                                                fields={[
                                                    {
                                                        type: 'textInput',
                                                        name: 'keyword',
                                                        caption:
                                                            cxt.t('search'),
                                                        size: 'medium',
                                                        InputProps: {
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            setSearchKeywordEvent(
                                                                                watchEventKeyword,
                                                                            );
                                                                        }}
                                                                    >
                                                                        <SearchIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        },
                                                        onEnterKey: (value) => {
                                                            setSearchKeywordEvent(
                                                                value,
                                                            );
                                                        },
                                                    },
                                                ]}
                                            />
                                        </Box>
                                        <SttEventCardList
                                            params={{
                                                keyword: searchKeywordEvent,
                                                idSC: sc?.id,
                                            }}
                                        />
                                    </>
                                ),
                            },
                        ]}
                    />
                </Paper>
            </Container>
        );
    }

    if (sc?.status === 'INACTIVE') {
        return <Page404 />;
    }

    return null;
}
