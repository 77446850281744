import React, { useContext } from 'react';
import {
    Box,
    Grid,
    Link,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { getPath } from '../../../pages/Pages';
import { AppContext } from '../../AppContext';
import { formatPriceByLocale, formatPeriod } from '../../../lib/utils';

/**
 * Card representing an event.
 * @param event Event object.
 * @returns {JSX.Element|null}
 */
export function SttEventCard({ event }) {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const classes = useStyles();

    const theme = useTheme();
    const mdUp = useMediaQuery(theme.breakpoints.up('sm'));

    if (!event) {
        return null;
    }

    const inTime =
        moment().isAfter(moment(event.ini)) &&
        moment().isBefore(moment(event.end));
    const inIscTime =
        moment().isAfter(moment(event.inscIni)) &&
        moment().isBefore(moment(event.inscEnd));
    const dateFormat = mdUp ? 'D MMMM YYYY' : 'DD/MM/YYYY';
    const [period, , periodLabel] = formatPeriod(
        event.ini,
        event.end,
        { dateFormat },
        cxt.t,
    );
    const [inscPeriod] = formatPeriod(
        event.inscIni,
        event.inscEnd,
        { dateFormat },
        cxt.t,
    );

    return (
        <Box p={3}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={10}>
                    <div>
                        <Link
                            onClick={() =>
                                history.push(
                                    getPath('eventPublic', {
                                        id: event.id,
                                    }),
                                )
                            }
                        >
                            <Typography variant="h5">{event.name}</Typography>
                        </Link>
                    </div>
                    <div>
                        <Link
                            onClick={() =>
                                history.push(
                                    getPath('timetable', {
                                        id: event.sc.id,
                                    }),
                                )
                            }
                        >
                            <Typography variant="body2">
                                {event.sc.name}
                            </Typography>
                        </Link>
                    </div>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Box textAlign={mdUp ? 'right' : 'left'}>
                        <Typography variant="button">
                            {event.sport.name}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="body1">
                        <span className={classes.dateLabel}>
                            {periodLabel}:{' '}
                        </span>
                        <span className={inTime ? classes.green : classes.red}>
                            {period}
                        </span>
                    </Typography>
                    <Typography variant="body1">
                        <span className={classes.dateLabel}>
                            {cxt.t('Inscription')}:{' '}
                        </span>
                        <span
                            className={inIscTime ? classes.green : classes.red}
                        >
                            {inscPeriod}
                        </span>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box display="flex" justifyContent="space-between">
                        <Box flex={2}>
                            <Typography variant="body2">
                                {cxt.t('Price')}
                            </Typography>
                            <Typography variant="body1">{`${cxt.t(
                                'From',
                            )} ${formatPriceByLocale(
                                event.price,
                                event?.priceShape?.currency,
                                event?.priceShape?.locale,
                            )}`}</Typography>
                        </Box>
                        {event.teamable ? (
                            <Box textAlign="right" flex={1}>
                                <Typography variant="body2">
                                    {cxt.t('Teams')}
                                </Typography>
                                <Typography variant="body1">{`${
                                    event.numTeams
                                }${
                                    event.maxTeams ? ` / ${event.maxTeams}` : ''
                                }`}</Typography>
                            </Box>
                        ) : (
                            <Box textAlign="right" flex={1}>
                                <Typography variant="body2">
                                    {cxt.t('SignedCount')}
                                </Typography>
                                <Typography variant="body1">{`${
                                    event.numParticipants
                                }${
                                    event.maxParticipants
                                        ? ` / ${event.maxParticipants}`
                                        : ''
                                }`}</Typography>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

const useStyles = makeStyles(() => ({
    green: {
        color: 'green',
    },
    red: {
        color: 'red',
    },
}));
