import React from 'react';
import { useMutation } from 'react-query';
import { SttGoogleMap } from '../../../../sporttia/all';
import { useLoader } from '../../../../lib/hooks';
import useScsService from '../../../../services/ScsService';

/**
 * View to modify and see on the map where a center is located.
 * @param sc Sport center object.
 * @param onUpdate Action taken when modifying the location of a center.
 * @returns {JSX.Element}
 */
export default function AdminSCMap({ sc, onUpdate }) {
    const scsService = useScsService();
    const updateMutation = useMutation(
        (params) => scsService.update(params?.id, params?.params),
        {
            onSuccess: (data) => {
                onUpdate(data);
            },
        },
    );
    const [, loader] = useLoader([updateMutation.status]);

    return (
        <>
            {loader}
            <SttGoogleMap
                latLng={{
                    lat: sc?.mapX,
                    lng: sc?.mapY,
                }}
                searchEnabled
                onChange={(data) =>
                    updateMutation.mutate({
                        id: sc?.id,
                        params: { mapX: data?.lat, mapY: data?.lng },
                    })
                }
            />
        </>
    );
}
