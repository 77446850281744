import React, { useState, useEffect, useContext } from 'react';
import { Paper, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AppContext, SttTabs, SttFullDialog } from '../../../sporttia/all';
import MshipDetail from './MshipDetail';
import MshipPayments from './MshipPayments';
import MshipPurseMovs from './MshipPurseMovs';
import MshipBookings from './MshipBookings';
import MshipStudents from './MshipStudents';
import MshipEventParticipants from './MshipEventParticipants';
import MshipPrivileges from './MshipPrivileges';
import MshipGroups from './MshipGroups';
import MshipDocuments from './MshipDocuments';
import translations from '../../../translations';

export default function Mship({ id, open, onClose }) {
    const cxt = useContext(AppContext);
    const [mship, setMship] = useState();

    const loadMship = () => {
        cxt.api('GET', `/mships/${id}`, {
            success: (r) => {
                setMship(r.mship);
            },
        });
    };

    useEffect(() => {
        if (id) {
            loadMship();
        }

        return () => {
            setTimeout(() => setMship(null), 50);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    function handleChangeMship({ name, value }) {
        switch (name) {
            case 'tutor':
                setMship({ ...mship, tutor: value || '' });
                break;
            case 'city.name':
                setMship({ ...mship, city: value ? value.city : '' });
                break;
            case 'iban':
                setMship({ ...mship, iban: value === '' ? null : value });
                break;
            default:
                setMship({ ...mship, [name]: value });
        }
    }

    const restoreUser = () => {
        cxt.api('PUT', `/mships/${mship.id}`, {
            params: {
                status: 'ACTIVE',
            },
            success: (r) => {
                setMship(r.mship);
                cxt.showMessage('S', cxt.t(translations.generic.recovered));
            },
        });
    };

    return (
        <SttFullDialog open={open} onClose={onClose} title={mship?.name}>
            <Paper>
                {mship?.status === 'INACTIVE' && (
                    <Alert
                        severity="warning"
                        action={
                            <Button color="inherit" onClick={restoreUser}>
                                {cxt.t(translations.generic.recover)}
                            </Button>
                        }
                    >
                        {cxt.t(translations.mship.mshipDeleted)}
                    </Alert>
                )}

                <SttTabs
                    tabs={[
                        {
                            caption: cxt.t(translations.generic.detail),
                            component: (
                                <MshipDetail
                                    mship={mship || {}}
                                    onChange={handleChangeMship}
                                    setMship={setMship}
                                    onClose={onClose}
                                />
                            ),
                        },
                        {
                            caption: cxt.t(translations.payment.payments),
                            show: !!mship,
                            component: <MshipPayments mship={mship} />,
                        },
                        {
                            caption: cxt.t(translations.generic.purse),
                            show: !!mship,
                            component: <MshipPurseMovs mship={mship} />,
                        },
                        {
                            caption: cxt.t(translations.generic.groups),
                            show: !!mship,
                            component: <MshipGroups mship={mship} />,
                        },
                        {
                            caption: cxt.t(translations.generic.bookings),
                            show: !!mship,
                            component: <MshipBookings mship={mship} />,
                        },
                        {
                            caption: cxt.t(translations.generic.activities),
                            show: !!mship,
                            component: <MshipStudents mship={mship} />,
                        },
                        {
                            caption: cxt.t(translations.generic.events),
                            show: !!mship,
                            component: <MshipEventParticipants mship={mship} />,
                        },
                        {
                            caption: cxt.t(translations.generic.privileges),
                            show: !!mship,
                            component: <MshipPrivileges mship={mship} />,
                        },
                        {
                            caption: cxt.t(translations.generic.documents),
                            show: !!mship,
                            component: <MshipDocuments mship={mship} />,
                        },
                    ]}
                />
            </Paper>
        </SttFullDialog>
    );
}
