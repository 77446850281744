import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { AppContext, SttDialog } from '../../../../sporttia/all';
import useApisService from '../../../../services/ApisService';
import { useLoader } from '../../../../lib/hooks';
import SttValidatedForm from '../../../../sporttia/SttValidatedForm';

/**
 * Dialog to modify, create and delete API responses.
 * @param apiId API Id.
 * @param responseValue API Response object.
 * @param onClose Function executed when closing the dialog.
 * @param onCreate Action taken when creating the api parameter.
 * @param onUpdate Action taken when updating the api parameter.
 * @param onDelete Method executed when removing api parameter.
 * @returns {JSX.Element}
 */
export default function AdminApiResponseDialog({
    apiId,
    responseValue,
    onClose,
    onCreate,
    onUpdate,
    onDelete,
}) {
    const cxt = useContext(AppContext);
    const apisService = useApisService();
    const form = useForm();
    const [response, setResponse] = useState(responseValue);
    const deleteMutation = useMutation(
        (params) => apisService.removeApiResponse(params?.id),
        {
            onSuccess: onDelete,
        },
    );
    const updateMutation = useMutation(
        (params) => apisService.updateApiResponse(params?.id, params?.params),
        {
            onSuccess: (data) => {
                onUpdate(data);
                setResponse(data?.apiResponse);
            },
        },
    );
    const createMutation = useMutation(
        (params) => apisService.createApiResponse(params?.id, params?.params),
        {
            onSuccess: (data) => {
                onCreate(data);
                setResponse(data?.apiResponse);
            },
        },
    );
    const [, loader] = useLoader([
        deleteMutation.status,
        updateMutation.status,
        createMutation.status,
    ]);

    /**
     * Method executed when the user clicks on save or create response.
     * @param formData Form data.
     */
    const mutateResponse = (formData) => {
        if (response?.id) {
            updateMutation.mutate({ id: response?.id, params: formData });
        } else {
            createMutation.mutate({ id: apiId, params: formData });
        }
    };

    /**
     * When the "response" data is returned by the API, it is set in the form.
     */
    useEffect(() => {
        if (response) {
            form.reset({
                id: response?.id,
                created: response?.created,
                type: response?.type,
                name: response?.name,
                description: response?.description,
            });
        } else {
            form.reset({
                id: '',
                created: new Date(),
                type: '',
                name: '',
                description: '',
            });
        }
    }, [response]);

    /**
     * When the value of the response passed to the component is modified, the selected internal
     * response saved in the "response" state will be modified.
     */
    useEffect(() => {
        setResponse(responseValue);
    }, [responseValue]);

    return (
        <SttDialog
            title="Response"
            open={response != null}
            onClose={onClose}
            content={
                <>
                    {loader}
                    <SttValidatedForm
                        form={form}
                        fields={[
                            {
                                type: 'textinput',
                                name: 'id',
                                caption: 'ID',
                                disabled: true,
                            },
                            {
                                type: 'date',
                                name: 'created',
                                caption: cxt.t('Created'),
                                disabled: true,
                            },
                            {
                                type: 'textinput',
                                name: 'type',
                                caption: cxt.t('Type'),
                            },
                            {
                                type: 'textinput',
                                name: 'name',
                                caption: cxt.t('Name'),
                            },
                            {
                                type: 'textinput',
                                name: 'description',
                                caption: cxt.t('Description'),
                            },
                        ]}
                    />
                </>
            }
            buttons={[
                {
                    type: 'delete',
                    confirmation: true,
                    onClick: () => deleteMutation.mutate({ id: response?.id }),
                },
                {
                    type: 'save',
                    onClick: form.handleSubmit((formData) =>
                        mutateResponse(formData),
                    ),
                },
            ]}
        />
    );
}
