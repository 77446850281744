import React, { useState, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';
import { AppContext, SttDialog, SttList } from '../../../sporttia/all';

export default function NewMessageResultDialog({
    open = false,
    errors = [],
    onClose,
}) {
    const cxt = useContext(AppContext);

    // -----| Render |-----
    return (
        <SttDialog
            title={cxt.t('MessageSent')}
            open={open}
            onClose={onClose}
            content={
                <>
                    <Box py={2}>
                        {cxt.t('page.sc.comm.noteNoEmailSMS')}
                        {cxt.t('page.sc.comm.emailTime')}
                    </Box>

                    {errors && (
                        <SttList
                            numRows={8}
                            data={errors.map((error) => ({
                                caption: error,
                            }))}
                        />
                    )}
                </>
            }
            buttons={[
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
        />
    );
}
