import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useQuery, useQueryClient } from 'react-query';
import SttSCSubscriptionsList from '../../../../sporttia/lists/SttSCSubscriptionsList';
import AdminCustomersLicences from './AdminCustomersLicences';
import useCustomersService from '../../../../services/CustomersService';
import { SUPERADMIN_CUSTOMER_SUBSCRIPTIONS } from '../../../../lib/queryKeys';

/**
 * Customer subscription view. View to modify and consult the data of a customer's subscriptions.
 * @param customerId Customer ID.
 * @returns {JSX.Element}
 */
export default function AdminCustomerSubscriptions({ customerId }) {
    const [subscriptionSelected, setSubscriptionSelected] = useState(null);
    const queryClient = useQueryClient();
    const customersService = useCustomersService();
    const customerSubscriptionsQuery = useQuery(
        [SUPERADMIN_CUSTOMER_SUBSCRIPTIONS, { id: customerId }],
        () => customersService.getCustomerSubscriptions(customerId),
        {
            enabled: !!customerId && customerId !== 'create',
            onSuccess: (data) => setSubscriptionSelected(data?.rows[0]),
        },
    );

    return (
        <Grid container spacing={3}>
            {/* Left side subscriptions data */}
            <Grid item xs={12} sm={12} md={6}>
                <Grid item xs={12} sm={12} md={12}>
                    <SttSCSubscriptionsList
                        customerId={customerId}
                        subscriptions={customerSubscriptionsQuery?.data?.rows}
                        subscriptionSelected={subscriptionSelected}
                        onSelect={setSubscriptionSelected}
                        onChange={() =>
                            queryClient.invalidateQueries(
                                SUPERADMIN_CUSTOMER_SUBSCRIPTIONS,
                            )
                        }
                    />
                </Grid>
            </Grid>

            {/* Right side charges data */}
            <Grid item xs={12} sm={12} md={6}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                        <AdminCustomersLicences
                            subscriptionSelected={subscriptionSelected}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
