export default {
    paths: {
        '/scs/files': 11,
        '/scs/devices': 11,
        '/scs/busy/schedules': 11,
        '/scs/facilities': 11,
        '/scs/rates': 11,
        '/scs/frees/schedules': 11,
        '/scs/discounts': 11,
        '/scs/events': 14,
        '/scs/activities': 15,
        '/scs/teachers': 15,
        '/scs/waitingLists': 15,
        '/scs/payments': 12,
        '/scs/tickets': 12,
        '/scs/remittences': 16,
        '/scs/roles': 11,
        '/scs/configurations': 11,
    },
};
