import { useContext } from 'react';
import useRestService from './RestService';
import { useAPI } from '../lib/hooks';
import { AppContext } from '../sporttia/AppContext';

const resource = '/my';

/**
 * Hook to access all 'my' resources.
 */
export default function useMyService() {
    const { migrationApis } = useContext(AppContext);
    const [request] = useAPI(migrationApis);
    const services = useRestService(resource);

    /**
     * GET /my/unlockers request.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function getMyUnlockers(params) {
        return request('GET', `${resource}/unlockers`, params);
    }

    return {
        ...services,
        getMyUnlockers,
    };
}
