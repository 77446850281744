import React, { useState } from 'react';
import { makeStyles, Box, Grid, Typography, Paper } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import imageStep1 from './images/sporttia1_smartcourt.jpeg';
import imageStep2 from './images/sporttia2_smartcourt.jpeg';
import imageStep3 from './images/sporttia3_smartcourt.jpeg';
import imageStep4 from './images/sporttia4_smartcourt.jpeg';
import imageStep5 from './images/sporttia5_smartcourt.jpeg';
import imageStep6 from './images/sporttia6_smartcourt.jpeg';
import imageStep7 from './images/sporttia7_smartcourt.jpeg';

import { SttButton } from '../../sporttia/all';
import { useTranslations } from '../../lib/hooks';

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        [theme.breakpoints.up(500)]: {
            maxWidth: '90%',
            marginLeft: '5%',
            marginRight: '5%',
        },
        [theme.breakpoints.up(820)]: {
            maxWidth: '80%',
            marginLeft: '10%',
            marginRight: '10%',
        },
        [theme.breakpoints.up(1020)]: {
            maxWidth: '70%',
            marginLeft: '15%',
            marginRight: '15%',
        },
        [theme.breakpoints.up(1600)]: {
            maxWidth: '50%',
            marginLeft: '25%',
            marginRight: '25%',
        },
    },
    container: {
        backgroundColor: '#FFFFFF',
        borderRadius: '25px',
    },
    title: {
        fontFamily: 'roboto',
        fontWeight: '800',
        color: 'black',
        [theme.breakpoints.down(768)]: {
            fontSize: '3em',
            letterSpacing: '2px',
        },
        [theme.breakpoints.up(768)]: {
            fontSize: '5em',
            letterSpacing: '4px',
        },
    },
    subtitle: {
        fontFamily: 'roboto',
        color: 'black',
        fontWeight: 'bold',
        [theme.breakpoints.down(768)]: {
            fontSize: '1em',
            letterSpacing: '2px',
        },
        [theme.breakpoints.up(768)]: {
            fontSize: '2em',
            letterSpacing: '2px',
        },
    },
    menuText: {
        fontFamily: 'roboto',
        color: 'black',
        fontWeight: 'bold',
        fontSize: '1.5em',
        letterSpacing: '2px',
    },
    stepTitleContainer: {
        marginBottom: '15px',
    },
    stepTitleText: {
        fontFamily: 'roboto',
        color: 'black',
        fontWeight: 'bold',
        fontSize: '1.5em',
        letterSpacing: '2px',
        marginBottom: '20px',
        textAlign: 'center',
    },
    textContainer: {
        marginBottom: '25px',
    },
    text: {
        fontFamily: 'roboto',
        color: 'black',
        textAlign: 'center',
        marginBottom: '5px',
        [theme.breakpoints.down(768)]: {
            fontSize: '1em',
            letterSpacing: '1px',
        },
    },
    logoStore: {
        [theme.breakpoints.down(768)]: {
            height: '100px',
            width: '150px',
        },
        [theme.breakpoints.up(768)]: {
            height: '150px',
            width: '200px',
        },
    },
    imageStepsContainer: {
        [theme.breakpoints.down(768)]: {
            textAlign: 'center',
            height: '100%',
            width: '100%',
        },
        [theme.breakpoints.up(768)]: {
            textAlign: 'center',
            height: '50%',
            width: '50%',
        },
    },
    imageSteps: {
        marginTop: '20px',
        border: '2px solid #c6c6c6',
        [theme.breakpoints.down(768)]: {
            height: '200px',
            width: '107px',
        },
        [theme.breakpoints.up(768)]: {
            height: '400px',
            width: '207px',
        },
    },
    imageLastStep: {
        marginTop: '20px',
        border: '2px solid #c6c6c6',
        [theme.breakpoints.down(768)]: {
            height: '199px',
            width: '300px',
        },
        [theme.breakpoints.up(768)]: {
            height: '299px',
            width: '400px',
        },
    },
    active: {
        backgroundColor: '#5c690e',
    },
    stepContainer: {
        marginTop: '5px',
        padding: '25px',
        height: '100%',
        borderRadius: '25px',
    },
    zoomedImage: {
        [theme.breakpoints.up(768)]: {
            height: '35%',
            width: '35%',
            borderRadius: '25px',
        },
        [theme.breakpoints.down(768)]: {
            height: '100%',
            width: '100%',
            borderRadius: '25px',
        },
    },
    buttons: {
        width: '50%',
        textAlign: 'center',
    },
    buttonsContainer: {
        marginTop: '30px',
    },
}));

export default function SmartCourt() {
    const classes = useStyles();
    const [currentStep, setCurrentStep] = useState(0);
    const [imageZoom, setImageZoom] = useState(null);
    const { translate } = useTranslations();

    // All five steps to show to the user with each correspondent description and images
    const steps = [
        <Grid item xs={12} container justifyContent="center" direction="row">
            <Grid
                item
                xs={12}
                container
                className={classes.stepTitleContainer}
                justifyContent="center"
            >
                <Typography className={classes.stepTitleText}>
                    {translate('smartcourt.titleStep1')}
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                className={classes.textContainer}
                container
                justifyContent="center"
            >
                <Typography className={classes.text}>
                    {translate('smartcourt.textStep1')}
                </Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="center">
                <a href="https://play.google.com/store/apps/details?id=es.sporttia.sporttia&gl=ES&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                    <Box
                        className={classes.logoStore}
                        component="img"
                        src="https://www.sporttia.com/wp-content/uploads/2020/05/4-3.png"
                    />
                </a>
            </Grid>
            <Grid item xs={6} container justifyContent="center">
                <a href="https://apps.apple.com/es/app/sporttia/id1093457829">
                    <Box
                        className={classes.logoStore}
                        component="img"
                        src="https://www.sporttia.com/wp-content/uploads/2020/05/3-2.png"
                    />
                </a>
            </Grid>
        </Grid>,
        <Grid item xs={12} container justifyContent="center" direction="column">
            <Grid
                item
                xs={12}
                container
                className={classes.stepTitleContainer}
                justifyContent="center"
            >
                <Typography className={classes.stepTitleText}>
                    {translate('smartcourt.titleStep2')}
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                className={classes.textContainer}
                container
                justifyContent="center"
            >
                <Typography className={classes.text}>
                    {translate('smartcourt.textStep2')}
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="center"
            >
                <Grid
                    item
                    container
                    xs={6}
                    className={classes.imageStepsContainer}
                    justifyContent="center"
                >
                    <Box
                        className={classes.imageSteps}
                        component="img"
                        src={imageStep1}
                        onClick={() => setImageZoom(imageStep1)}
                    />
                </Grid>
                <Grid
                    item
                    container
                    xs={6}
                    className={classes.imageStepsContainer}
                    justifyContent="center"
                >
                    <Box
                        className={classes.imageSteps}
                        component="img"
                        src={imageStep2}
                        onClick={() => setImageZoom(imageStep2)}
                    />
                </Grid>
            </Grid>
        </Grid>,
        <Grid item xs={12} container justifyContent="center" direction="column">
            <Grid
                item
                xs={12}
                container
                className={classes.stepTitleContainer}
                justifyContent="center"
            >
                <Typography className={classes.stepTitleText}>
                    {translate('smartcourt.titleStep3')}
                </Typography>
            </Grid>
            <Grid item className={classes.textContainer} xs={12}>
                <Typography className={classes.text}>
                    {translate('smartcourt.textStep3')}
                </Typography>
            </Grid>

            <Grid
                item
                xs={12}
                container
                justifyContent="center"
                direction="row"
            >
                <Grid item xs={6} className={classes.imageStepsContainer}>
                    <Box
                        className={classes.imageSteps}
                        component="img"
                        src={imageStep3}
                        onClick={() => setImageZoom(imageStep3)}
                    />
                </Grid>
                <Grid item xs={6} className={classes.imageStepsContainer}>
                    <Box
                        className={classes.imageSteps}
                        component="img"
                        src={imageStep4}
                        onClick={() => setImageZoom(imageStep4)}
                    />
                </Grid>
            </Grid>
        </Grid>,
        <Grid item xs={12} container justifyContent="center" direction="column">
            <Grid
                item
                xs={12}
                container
                className={classes.stepTitleContainer}
                justifyContent="center"
            >
                <Typography className={classes.stepTitleText}>
                    {translate('smartcourt.titleStep4')}
                </Typography>
            </Grid>
            <Grid item className={classes.textContainer} xs={12}>
                <Typography className={classes.text}>
                    {translate('smartcourt.textStep4')}
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                container
                justifyContent="center"
                direction="row"
            >
                <Grid item xs={6} className={classes.imageStepsContainer}>
                    <Box
                        className={classes.imageSteps}
                        component="img"
                        src={imageStep5}
                        onClick={() => setImageZoom(imageStep5)}
                    />
                </Grid>
                <Grid item xs={6} className={classes.imageStepsContainer}>
                    <Box
                        className={classes.imageSteps}
                        component="img"
                        src={imageStep6}
                        onClick={() => setImageZoom(imageStep6)}
                    />
                </Grid>
            </Grid>
        </Grid>,
        <Grid item xs={12} container justifyContent="center" direction="column">
            <Grid
                item
                xs={12}
                container
                className={classes.stepTitleContainer}
                justifyContent="center"
            >
                <Typography className={classes.stepTitleText}>
                    {translate('smartcourt.titleStep5')}
                </Typography>
            </Grid>
            <Grid item className={classes.textContainer} xs={12}>
                <Typography className={classes.text}>
                    <b>1.</b> {translate('smartcourt.textStep5.1')}
                </Typography>
                <Typography className={classes.text}>
                    <b>2.</b> {translate('smartcourt.textStep5.2')}
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="center"
            >
                <Grid
                    item
                    container
                    xs={6}
                    className={classes.imageStepsContainer}
                    justifyContent="center"
                >
                    <Box
                        className={classes.imageLastStep}
                        component="img"
                        src={imageStep7}
                    />
                </Grid>
            </Grid>
        </Grid>,
    ];

    return (
        <>
            {imageZoom ? (
                <Grid container justifyContent="center">
                    <Box
                        className={classes.zoomedImage}
                        component="img"
                        src={imageZoom}
                        onClick={() => setImageZoom(null)}
                    />
                </Grid>
            ) : (
                <Grid
                    className={classes.rootContainer}
                    container
                    direction="column"
                    spacing={3}
                >
                    <Grid item xs={12} container className={classes.container}>
                        <Grid item xs={12} container justifyContent="center">
                            <Typography className={classes.title}>
                                SMARTCOURT
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            container
                            className={classes.containerSubtitle}
                            justifyContent="center"
                        >
                            <Typography className={classes.subtitle}>
                                {translate('smartcourt.subtitle')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        direction="column"
                        justifyContent="center"
                    >
                        <Paper className={classes.stepContainer}>
                            {steps[currentStep]}
                        </Paper>
                        <Grid
                            item
                            className={classes.buttonsContainer}
                            md={12}
                            container
                            justifyContent="center"
                        >
                            <Box className={classes.buttons}>
                                <SttButton
                                    icon={
                                        currentStep < steps.length - 1 ? (
                                            <ArrowForward />
                                        ) : (
                                            <ArrowBack />
                                        )
                                    }
                                    color="primary"
                                    variant="contained"
                                    caption={
                                        currentStep < steps.length - 1
                                            ? 'Siguiente'
                                            : 'Volver'
                                    }
                                    onClick={() => {
                                        if (currentStep < steps.length - 1) {
                                            setCurrentStep(currentStep + 1);
                                        } else setCurrentStep(0);
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    );
}
