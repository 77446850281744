import React, { useRef } from 'react';
import { Box, CircularProgress, Divider } from '@material-ui/core';
import { useInfiniteQuery } from 'react-query';
import { SttSCCard } from '../../all';
import { useOnViewport, useTranslations } from '../../../lib/hooks';
import { USER_SEARCH_SCS } from '../../../lib/queryKeys';
import useSearchService from '../../../services/SearchService';

/**
 * List of sports center cards. Use the infinite scroll.
 * @param params Parameters that are sent to the API.
 * @returns {JSX.Element}
 */
export function SttSCCardList({ params = {} }) {
    const ROWS_PER_PAGE = 15;
    const { translate } = useTranslations();
    const searchService = useSearchService();
    const ref = useRef();
    const { data, status, isFetchingNextPage, fetchNextPage, hasNextPage } =
        useInfiniteQuery(
            [
                USER_SEARCH_SCS,
                {
                    ...params,
                },
            ],
            ({ pageParam = 1 }) =>
                searchService
                    .getList({
                        page: pageParam,
                        rows: ROWS_PER_PAGE,
                        kind: 1,
                        ...params,
                    })
                    .then((response) => {
                        response.scs.page = pageParam;
                        return response;
                    }),
            {
                getNextPageParam: (lastPage) =>
                    lastPage?.scs?.page < lastPage?.scs?.pages
                        ? lastPage?.scs?.page + 1
                        : undefined,
            },
        );
    useOnViewport(ref, '-100px', fetchNextPage);

    return (
        <>
            {status === 'loading' ? (
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            ) : status === 'error' ? (
                <>Error</>
            ) : (
                <>
                    <Box my={3} align="right">
                        <b>{data?.pages[0]?.scs?.count}</b>{' '}
                        {translate('sportcenters')}
                    </Box>
                    {data.pages.map((page, i) => (
                        <React.Fragment key={i}>
                            {page.scs.rows.map(
                                (sc, scKey) =>
                                    sc.status === 'ACTIVE' && (
                                        <Box key={scKey}>
                                            {scKey > 0 && (
                                                <Box mt={1} mb={1}>
                                                    <Divider />
                                                </Box>
                                            )}
                                            <SttSCCard sc={sc} />
                                        </Box>
                                    ),
                            )}
                        </React.Fragment>
                    ))}
                    {isFetchingNextPage && (
                        <Box display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    )}
                    {!hasNextPage && (
                        <Box display="flex" justifyContent="center">
                            No hay más resultados.
                        </Box>
                    )}
                </>
            )}
            {/* Este div es para detectar el final de la página, cuando sea visible se van a pedir datos a la API para realizar el scroll infinito. */}
            <div ref={ref} />
        </>
    );
}
