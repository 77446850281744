import React, { useContext } from 'react';
import moment from 'moment';
import { AppContext } from '../all';

/**
 *	LABEL MONTH LIST
 *	================
 *
 * 	Given [1,3,4] renders "E M A"
 *
 * Props
 *
 * 		* integer[] months
 */
export function SttLabelMonths({ months = [] }) {
    const cxt = useContext(AppContext);

    // Render
    return (
        <>
            {months.length === 12
                ? cxt.t('All')
                : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                      .map((m) =>
                          months.includes(m)
                              ? cxt.t(`month.initial.${m}`)
                              : '·',
                      )
                      .join('')}
        </>
    );
}
