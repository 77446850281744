import React from 'react';
import ReactDOM from 'react-dom';
import '@fontsource/roboto';
import './index.css';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { esES } from '@material-ui/core/locale';
import moment from 'moment';
import * as serviceWorker from './serviceWorker';
import App from './App';
import 'moment/locale/es';

// Set moment in Spanish (TODO)
moment.locale('es');

const theme = createTheme({}, esES);

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <App />
    </ThemeProvider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
