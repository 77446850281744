import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Link } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { AppContext } from '../../AppContext';
import { getPath } from '../../../pages/Pages';
import { SttChipUnlocker } from '../../chips/SttChipUnlocker';
import SttError from '../../../components/error/SttError';

/**
 * Card representing a user's unlocker.
 * @param unlocker Unlocker object.
 * @returns {JSX.Element}
 */

const useStyles = makeStyles({
    code_box: {
        height: '150px',
        padding: '2px',
    },
    information_box: {
        height: '150px',
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
});

export function SttKeyCard({ unlocker }) {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const classes = useStyles();

    return (
        <Box mb={1}>
            {unlocker && (
                <Container maxWidth="sm">
                    <Paper className={classes.paper}>
                        <Grid container>
                            <Grid
                                item
                                md={5}
                                sm={5}
                                style={{ padding: 10 }}
                                container
                                spacing={0}
                                align="center"
                                justifyContent="center"
                                direction="column"
                            >
                                {unlocker.code && unlocker.type === 'QR' && (
                                    <Box className={classes.code_box}>
                                        <img
                                            src={cxt.getResource(
                                                `/unlockers/${unlocker.id}.svg`,
                                            )}
                                            alt="QR"
                                            height="100%"
                                        />
                                    </Box>
                                )}
                                {unlocker.code && unlocker.type !== 'QR' && (
                                    <Typography variant="h4">
                                        {unlocker.code}
                                    </Typography>
                                )}
                                {!unlocker.code && (
                                    <SttError
                                        message={cxt.t('noQRCode')}
                                        heading={false}
                                        imageSize={50}
                                    />
                                )}
                            </Grid>
                            <Grid item md={7} sm={7}>
                                <Box className={classes.information_box}>
                                    <Link
                                        onClick={() =>
                                            history.push(
                                                getPath('scProfile', {
                                                    id: unlocker.sc.id,
                                                }),
                                            )
                                        }
                                    >
                                        <Typography variant="h6" gutterBottom>
                                            {unlocker.sc.name}
                                        </Typography>
                                    </Link>
                                    {unlocker.type === 'QR' ? (
                                        <SttChipUnlocker
                                            unlocker={{
                                                type: unlocker.type,
                                            }}
                                        />
                                    ) : (
                                        <SttChipUnlocker
                                            unlocker={{
                                                type: unlocker.type,
                                            }}
                                        />
                                    )}
                                    {unlocker.type === 'PIN' && (
                                        <Box mt={1}>
                                            <Typography variant="body2">
                                                * {cxt.t('RememberToPressHash')}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            )}
        </Box>
    );
}
