import React, { useState, useEffect, useContext } from 'react';
import {
    Container,
    Box,
    Typography,
    useTheme,
    useMediaQuery,
    makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import ModalImage from 'react-modal-image';
import { useCrud } from '../../../lib/hooks';
import { AppContext, SttTabs } from '../../../sporttia/all';
import RateList from './RateList';
import ClassesList from './ClassesList';
import ModalGotoSc from './ModalGotoSc';
import ModalSignUp from './ModalSignUp';
import SignedUsersList from '../../../sporttia/lists/SignedUsersList';
import translations from '../../../translations';
import constants from '../../../config/constants';

const useStyles = makeStyles(() => ({
    imageColumn: {
        maxWidth: 260,
    },
    imageRow: {
        width: '100%',
    },
    imgBG: {
        width: '100%',
        background: 'linear-gradient(rgba(0,0,0,0.9), rgba(0,0,0,0.0))',
    },
    greenBG: {
        backgroundColor: 'green',
        color: '#fff',
    },
    redBG: {
        backgroundColor: 'red',
    },
    textCenter: {
        textAlign: 'center',
    },
    activityName: {
        fontSize: 20,
        color: '#fff',
    },
    activityDesc: {
        fontSize: 18,
        'white-space': 'pre-line',
    },
    registrationBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        minHeight: 50,
    },
    registrationLabel: {
        color: '#fff',
        fontSize: 15,
    },
}));

export default function Activity({ match }) {
    const cxt = useContext(AppContext);

    const [activity, setActivity, , Get] = useCrud();
    const [classes, setClasses] = useState({ count: 0, rows: [] });
    const [rateModalGoto, setRateModalGoto] = useState(null);
    const [rateModalSignup, setRateModalSignup] = useState(null);
    const [privileges, setPrivileges] = useState(null);
    const [amSignedUp, setAmSignedUp] = useState(null);
    const [idMship, setIdMship] = useState();
    const [files, setFiles] = useState([]);

    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const styles = useStyles();

    function loadClasses(params = { rows: 20, page: 1 }) {
        const { id } = match.params;
        Get(`/activities/${id}/classes`, params).then((response) => {
            if (response.rows) {
                setClasses(response);
            }
        });
    }

    function loadMyInscription($idStudent) {
        if (!$idStudent) {
            return;
        }

        Get(`/students/${$idStudent}`).then((response) => {
            if (response.student) {
                setAmSignedUp(response.student);
            }
        });
    }

    function loadActivity() {
        const { id } = match.params;
        Get(`/activities/${id}`).then((response) => {
            if (response.activity) {
                setActivity(response.activity);

                response.activity.rates?.forEach((rate) => {
                    if (rate.myStudent) {
                        loadMyInscription(rate.myStudent.id);
                    }
                });
            }
        });
    }

    function loadActivityFiles() {
        const { id } = match.params;

        Get(`/activities/${id}/files`).then((response) => {
            if (response.count > 0) {
                setFiles(response.rows);
            }
        });
    }

    function loadMe() {
        Get(`/scs/${activity.sc.id}/me`)
            .then((response) => {
                if (response.mship) {
                    setPrivileges(response.mship.privileges);
                    setIdMship(response.mship.id);
                }
            })
            .catch(() => {});
    }

    useEffect(() => {
        if (match?.params) {
            loadActivity();
            loadClasses();
            loadActivityFiles();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            cxt.logged &&
            cxt.user.role !== 'SPORTCENTER' &&
            activity &&
            activity.sc
        ) {
            loadMe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity]);

    // Open signup modal
    function signUp(rate) {
        if (rate.signupOnline) {
            setRateModalSignup(rate);
        } else {
            setRateModalGoto(rate);
        }
    }

    if (!activity) {
        return null;
    }

    const inTime =
        moment().isAfter(moment(activity.ini)) &&
        moment().isBefore(moment(activity.end));
    const inIscTime =
        moment().isAfter(moment(activity.inscIni)) &&
        moment().isBefore(moment(activity.inscEnd));

    return (
        <Container maxWidth="md">
            <Box display="flex" flexDirection={smDown ? 'column' : 'row'}>
                <Box>
                    <Box position="relative">
                        <Box
                            position="absolute"
                            left={0}
                            top={0}
                            p={1}
                            pb={3}
                            className={styles.imgBG}
                        >
                            <Typography className={styles.activityName}>
                                {activity.name}
                            </Typography>
                        </Box>
                        {activity.poster.id ? (
                            <ModalImage
                                hideZoom
                                small={`${activity.poster.url}?outfit=282`}
                                large={`${activity.poster.url}?outfit=800`}
                                alt={activity.name}
                            />
                        ) : (
                            <img
                                alt="Event poster"
                                className={
                                    smDown
                                        ? styles.imageRow
                                        : styles.imageColumn
                                }
                                src={`${cxt.apiEnv.url}/v6/public/images/eventPosters/event_default_68.jpg`}
                            />
                        )}
                    </Box>
                    <Box mt={2}>
                        <Box
                            p={1}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            className={
                                inIscTime ? styles.greenBG : styles.redBG
                            }
                        >
                            <Typography
                                className={styles.textCenter}
                                variant="caption"
                            >
                                {inIscTime
                                    ? cxt.t('page.activity.inscInTime')
                                    : cxt.t('page.activity.inscOutTime')}
                            </Typography>
                            <Typography
                                className={styles.textCenter}
                                variant="body1"
                            >{`${moment(activity.inscIni).format(
                                'D MMM YYYY HH:mm:ss',
                            )} - ${moment(activity.inscEnd).format(
                                'D MMM YYYY HH:mm:ss',
                            )}`}</Typography>
                        </Box>
                    </Box>
                    <Box mt={2}>
                        <Box
                            p={1}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            className={inTime ? styles.greenBG : styles.redBG}
                        >
                            <Typography
                                className={styles.textCenter}
                                variant="caption"
                            >
                                {inTime
                                    ? cxt.t('page.activity.inTime')
                                    : cxt.t('page.activity.outTime')}
                            </Typography>
                            <Typography
                                className={styles.textCenter}
                                variant="body1"
                            >{`${moment(activity.ini).format(
                                'D MMM YYYY',
                            )} - ${moment(activity.end).format(
                                'D MMM YYYY',
                            )}`}</Typography>
                        </Box>
                    </Box>
                    {files.length > 0 && (
                        <Box mb={3}>
                            <Box mt={3} mb={2}>
                                <Typography variant="body1">
                                    <b>{cxt.t('Documents')}:</b>
                                </Typography>
                            </Box>
                            {files.map((file) => (
                                <Box my={1} key={file.id}>
                                    <div>
                                        <a href={file.url}>{file.name}</a>
                                    </div>
                                    <Typography variant="body2">
                                        {file.description}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    )}
                </Box>
                <Box p={3} flex={1}>
                    {/* --- Description --- */}
                    <Box mb={2}>
                        <div
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: activity.description,
                            }}
                            className={styles.activityDesc}
                        />
                    </Box>

                    {amSignedUp && (
                        <Box
                            my={1}
                            className={`${styles.registrationBox} ${styles.greenBG}`}
                        >
                            <Typography className={styles.registrationLabel}>
                                {cxt.t('page.activity.ImSigned')}
                            </Typography>
                        </Box>
                    )}
                    <SttTabs
                        tabs={[
                            {
                                show:
                                    activity?.status !==
                                    constants.activity.status.closed,
                                caption: cxt.t('Activity'),
                                component: (
                                    <RateList
                                        rates={activity.rates}
                                        onSelected={signUp}
                                        inIscTime={inIscTime}
                                    />
                                ),
                            },
                            {
                                caption: cxt.t('Calendar'),
                                show: classes.count > 0,
                                component: (
                                    <ClassesList
                                        classes={classes}
                                        onFetch={loadClasses}
                                    />
                                ),
                            },
                            {
                                caption: cxt.t(
                                    translations.generic.signedUsers,
                                ),
                                show:
                                    activity.rates.filter(
                                        (rate) => rate?.signedUsers?.length > 0,
                                    )?.length > 0,
                                component: (
                                    <SignedUsersList
                                        items={activity.rates
                                            .map((rate) => rate?.signedUsers)
                                            .flat()}
                                    />
                                ),
                            },
                        ]}
                    />
                </Box>
            </Box>
            {rateModalGoto && (
                <ModalGotoSc
                    rate={rateModalGoto}
                    onClose={() => setRateModalGoto(null)}
                    scContactInfo={{
                        phone: activity.sc.phonePublic,
                        email: activity.sc.emailPublic,
                    }}
                />
            )}
            {rateModalSignup && (
                <ModalSignUp
                    rate={rateModalSignup}
                    idSC={activity.sc.id}
                    onClose={() => {
                        setRateModalSignup(null);
                    }}
                    form={activity.form}
                    privileges={privileges}
                    paymentForms={activity.paymentForms}
                    idMship={idMship}
                />
            )}
        </Container>
    );
}
