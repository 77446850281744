import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { AppContext } from '../../../../sporttia/AppContext';
import { SttDialog } from '../../../../sporttia/dialogs/SttDialog';
import useReportsService from '../../../../services/ReportsService';
import SttValidatedForm from '../../../../sporttia/SttValidatedForm';
import { useLoader } from '../../../../lib/hooks';

/**
 * Dialog to modify, create and delete report columns.
 * @param reportId Report ID.
 * @param columnValue Column object.
 * @param onClose Function executed when closing the dialog.
 * @param onCreate Action taken when creating the tpv configuration.
 * @param onUpdate Action taken when updating the tpv configuration.
 * @param onDelete Method executed when removing tpv configuration.
 * @returns {JSX.Element}
 */
export default function AdminReportColumnDialog({
    reportId,
    columnValue,
    onClose,
    onCreate,
    onUpdate,
    onDelete,
}) {
    const cxt = useContext(AppContext);
    const reportsService = useReportsService();
    const form = useForm();
    const [column, setColumn] = useState(columnValue);
    const deleteMutation = useMutation(
        (params) => reportsService.removeReportColumn(params?.id),
        {
            onSuccess: onDelete,
        },
    );
    const updateMutation = useMutation(
        (params) =>
            reportsService.updateReportColumn(params?.id, params?.params),
        {
            onSuccess: (data) => {
                onUpdate(data);
                setColumn(data?.reportColumn);
            },
        },
    );
    const createMutation = useMutation(
        (params) =>
            reportsService.createReportColumn(params?.id, params?.params),
        {
            onSuccess: (data) => {
                onCreate(data);
                setColumn(data?.reportColumn);
            },
        },
    );
    const [, loader] = useLoader([
        deleteMutation.status,
        updateMutation.status,
        createMutation.status,
    ]);

    /**
     * Method executed when the user clicks on save or create column.
     * @param formData Form data.
     */
    const mutateColumn = (formData) => {
        if (column?.id) {
            updateMutation.mutate({ id: column?.id, params: formData });
        } else {
            createMutation.mutate({ id: reportId, params: formData });
        }
    };

    /**
     * When the "column" data is returned by the API, it is set in the form.
     */
    useEffect(() => {
        if (column) {
            form.reset({
                name: column?.name,
                field: column?.field,
                width: column?.width,
                align: column?.align,
                format: column?.format,
                position: column?.position,
                summatory: column?.summatory,
            });
        } else {
            form.reset({
                name: '',
                field: '',
                width: '',
                align: '',
                format: '',
                position: '',
                summatory: '',
            });
        }
    }, [column, form]);

    /**
     * When the value of the column passed to the component is modified, the selected internal
     * column saved in the "column" state will be modified.
     */
    useEffect(() => {
        setColumn(columnValue);
    }, [columnValue]);

    return (
        <SttDialog
            title={cxt.t('Column')}
            maxWidth="sm"
            open={column !== null}
            onClose={onClose}
            content={
                <>
                    {loader}
                    <SttValidatedForm
                        form={form}
                        fields={[
                            {
                                type: 'textinput',
                                name: 'name',
                                caption: cxt.t('Name'),
                            },
                            {
                                type: 'textinput',
                                name: 'field',
                                caption: cxt.t('Field'),
                            },
                            {
                                type: 'textinput',
                                name: 'width',
                                caption: cxt.t('Width'),
                            },
                            {
                                type: 'select',
                                name: 'align',
                                caption: cxt.t('Align'),
                                options: [
                                    { caption: 'Centrado', value: 'C' },
                                    { caption: 'Izquierda', value: 'L' },
                                    { caption: 'Derecha', value: 'R' },
                                ],
                            },
                            {
                                type: 'textinput',
                                name: 'format',
                                caption: cxt.t('Format'),
                            },
                            {
                                type: 'textinput',
                                name: 'position',
                                caption: cxt.t('Position'),
                            },
                            {
                                type: 'select',
                                name: 'summatory',
                                caption: cxt.t('Summatory'),
                                options: [
                                    { caption: cxt.t('Active'), value: true },
                                    {
                                        caption: cxt.t('Inactive'),
                                        value: false,
                                    },
                                ],
                                disableClearable: true,
                            },
                        ]}
                    />
                </>
            }
            buttons={[
                {
                    type: 'save',
                    onClick: form.handleSubmit((formData) =>
                        mutateColumn(formData),
                    ),
                },
                {
                    type: 'delete',
                    onClick: () => deleteMutation.mutate({ id: column?.id }),
                },
            ]}
        />
    );
}
