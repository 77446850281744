import { Chip } from '@material-ui/core';
import { PersonOutline as PersonIcon } from '@material-ui/icons';
import React from 'react';

/**
 * Chip user
 */
export function SttChipUser({ user }) {
    return (
        <>
            {user && (
                <Chip icon={<PersonIcon />} size="small" label={user.login} />
            )}
        </>
    );
}
