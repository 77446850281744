import React, { useState, useContext } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import {
    AppContext,
    SttDialog,
    SttForm,
    SttFullDialog,
} from '../../sporttia/all';
import { useTranslations } from '../../lib/hooks';

import { getErrorMessage } from '../../lib/utils';
import translations from '../../translations';
import DeviceSpace from '../../types/models/DeviceSpace';
import useDevicesService from '../../services/DevicesService';
import { SttItemList } from '../../sporttia/lists/SttItemList';
import useScsService from '../../services/ScsService';
import { SPORTCENTER_DEVICES_QUERY_KEY } from '../../lib/queryKeys';

type DeviceSpaceDialogProps = {
    item: DeviceSpace;
    onSave: () => void;
    onClose: () => void;
};

export default function DeviceSpaceDialog({
    item,
    onSave,
    onClose,
}: DeviceSpaceDialogProps) {
    const cxt = useContext(AppContext)!;
    const devicesService = useDevicesService();
    const scsService = useScsService();
    const [deviceSpace, setDeviceSpace] = useState<DeviceSpace>(item);
    const { translate } = useTranslations();
    const updateDeviceSpaceMutation = devicesService.useUpdateDeviceSpace({
        onSuccess: (response: { deviceSpace: DeviceSpace }) => {
            setDeviceSpace(response.deviceSpace);
            onSave();
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });
    const deleteDeviceSpaceMutation = devicesService.useDeleteDeviceSpace({
        onSuccess: () => {
            onSave();
            onClose();
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });
    const createDeviceSpaceMutation = devicesService.useCreateDeviceSpace({
        onSuccess: (response: { deviceSpace: DeviceSpace }) => {
            setDeviceSpace(response?.deviceSpace);
            onSave();
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });

    const createDeviceSpaceDeviceMutation =
        devicesService.useCreateDeviceSpaceDevice({
            onSuccess: (response: { deviceSpace: DeviceSpace }) => {
                setDeviceSpace(response.deviceSpace);
                onSave();
            },
            onError: (error) => {
                cxt.showMessage('E', getErrorMessage(error));
            },
        });
    const deleteDeviceSpaceDeviceMutation =
        devicesService.useDeleteDeviceSpaceDevice({
            onSuccess: (response: { deviceSpace: DeviceSpace }) => {
                setDeviceSpace(response.deviceSpace);
                onSave();
            },
            onError: (error) => {
                cxt.showMessage('E', getErrorMessage(error));
            },
        });
    if (
        createDeviceSpaceMutation.isLoading ||
        updateDeviceSpaceMutation.isLoading
    ) {
        return (
            <SttDialog
                maxWidth="md"
                open
                content={
                    <Box display="flex" justifyContent="center" paddingY={15}>
                        <CircularProgress />
                    </Box>
                }
                title={undefined}
                onClose={undefined}
                buttons={undefined}
            />
        );
    }

    return (
        <SttFullDialog
            open={!!item}
            title={
                deviceSpace.id !== -1
                    ? deviceSpace?.name
                    : cxt.t(translations.generic.devices)
            }
            onClose={onClose}
            bottomButtons={[
                {
                    caption: cxt.t('Save'),
                    type: 'save',
                    onClick: () => {
                        if (item?.id === -1)
                            createDeviceSpaceMutation.mutate({
                                sportcenterId: cxt?.sc?.id ? cxt.sc.id : -1,
                                params: {
                                    maximumCapacity:
                                        deviceSpace.maximumCapacity,
                                    name: deviceSpace.name,
                                },
                            });
                        else
                            updateDeviceSpaceMutation.mutate({
                                deviceSpaceId: item?.id,
                                params: {
                                    currentCapacity:
                                        deviceSpace.currentCapacity,
                                    maximumCapacity:
                                        deviceSpace.maximumCapacity,
                                },
                            });
                    },
                },
                {
                    caption: cxt.t('Delete'),
                    type: 'delete',
                    show: deviceSpace?.id !== -1,
                    onClick: () => {
                        deleteDeviceSpaceMutation.mutate({
                            deviceSpaceId: deviceSpace?.id,
                        });
                    },
                },
            ]}
        >
            <Box
                style={{
                    padding: 25,
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <Box
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: 25,
                    }}
                >
                    <SttForm
                        onChangeForm={({ name, value }) =>
                            setDeviceSpace({
                                ...deviceSpace,
                                [name]: value,
                            })
                        }
                        elements={[
                            {
                                xs: 5,
                                caption: translate(translations.generic.name),
                                name: 'name',
                                show: deviceSpace?.id === -1,
                                value: deviceSpace?.name,
                            },
                            {
                                xs: 5,
                                caption: translate(
                                    translations.deviceSpace.maximunCapacity,
                                ),
                                name: 'maximumCapacity',
                                value: deviceSpace?.maximumCapacity.toString(),
                            },
                            {
                                xs: 5,
                                caption: translate(
                                    translations.deviceSpace.currentCapacity,
                                ),
                                name: 'currentCapacity',
                                show: deviceSpace?.id !== -1,
                                value: deviceSpace?.currentCapacity.toString(),
                            },
                        ]}
                        buttons={[]}
                    />
                </Box>
                <Box
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {deviceSpace.id !== -1 && (
                        <SttItemList
                            title={translate(translations.generic.devices)}
                            items={deviceSpace.devices}
                            onAdd={(deviceId: number) => {
                                if (deviceId) {
                                    createDeviceSpaceDeviceMutation.mutate({
                                        deviceSpaceId: deviceSpace?.id,
                                        deviceId,
                                    });
                                }
                            }}
                            onDelete={(deviceId: number) => {
                                if (deviceId) {
                                    deleteDeviceSpaceDeviceMutation.mutate({
                                        deviceSpaceId: deviceSpace?.id,
                                        deviceId,
                                    });
                                }
                            }}
                            queryKey={SPORTCENTER_DEVICES_QUERY_KEY}
                            itemQueryCall={scsService.getScsDevices2}
                            queryCallParameters={{
                                id: cxt?.sc ? cxt?.sc?.id : -1,
                                params: {},
                            }}
                        />
                    )}
                </Box>
            </Box>
        </SttFullDialog>
    );
}
