import React, { useState, useContext } from 'react';
import { useQueryClient } from 'react-query';
import { AppContext } from '../../../../sporttia/all';
import AdminApiParameterDialog from './AdminApiParameterDialog';
import useApisService from '../../../../services/ApisService';
import { SUPERADMIN_API_PARAMETERS } from '../../../../lib/queryKeys';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';

/**
 * API parameters view. View with a table that lists the parameters of an api.
 * @param apiId API ID.
 * @returns {JSX.Element}
 */
export default function AdminApiParameters({ apiId }) {
    const cxt = useContext(AppContext);
    const [parameter, setParameter] = useState(null);
    const queryClient = useQueryClient();
    const apisService = useApisService();

    return (
        <>
            <SttTopFilteringControls
                p={2}
                mainAction={{
                    type: 'create',
                    caption: cxt.t('Create'),
                    onClick: () => setParameter({}),
                }}
            />

            <SttCachedTable
                queryKey={SUPERADMIN_API_PARAMETERS}
                queryFn={(params) =>
                    apisService.getApiParameters(apiId, params)
                }
                onClickRow={(row) => setParameter(row)}
                columns={[
                    {
                        title: cxt.t('Name'),
                        field: 'name',
                        value: (row) => <b>{row.name}</b>,
                    },
                    {
                        title: cxt.t('Description'),
                        field: 'description',
                        align: 'left',
                    },
                    {
                        title: cxt.t('Type'),
                        field: 'type',
                    },
                    {
                        type: 'active',
                        title: cxt.t('Mandatory'),
                        field: 'mandatory',
                    },
                ]}
            />

            {/* En el futuro si tenemos un GET /apis/parameters/:id podríamos quitar parameterValue y pasar el id del parameter. */}
            <AdminApiParameterDialog
                apiId={apiId}
                parameterValue={parameter}
                onClose={() => setParameter(null)}
                onCreate={() =>
                    queryClient.invalidateQueries(SUPERADMIN_API_PARAMETERS)
                }
                onUpdate={() =>
                    queryClient.invalidateQueries(SUPERADMIN_API_PARAMETERS)
                }
                onDelete={() => {
                    setParameter(null);
                    queryClient.invalidateQueries(SUPERADMIN_API_PARAMETERS);
                }}
            />
        </>
    );
}
