import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { SttDialog } from '../../../sporttia/dialogs/SttDialog';
import { AppContext } from '../../../sporttia/all';

export default function ModalGotoSc({ rate, scContactInfo, onClose }) {
    const cxt = useContext(AppContext);

    return (
        <SttDialog
            open={rate !== null}
            onClose={onClose}
            title={cxt.t('Inscription')}
            content={
                <>
                    <Box mb={3}>
                        <Typography>{cxt.t('page.activity.goToSC')}</Typography>
                    </Box>
                    <Box>
                        <Typography>
                            <span>{cxt.t('Phone')}:</span>&nbsp;
                            <b>{scContactInfo.phone}</b>
                        </Typography>
                        <Typography>
                            <span>{cxt.t('Email')}:</span>&nbsp;
                            <b>{scContactInfo.email}</b>
                        </Typography>
                    </Box>
                </>
            }
        />
    );
}
