import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Avatar,
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from '@material-ui/core';
import { SttList } from './SttList';
import { AppContext } from '../AppContext';
import { SttLabelWeekdays } from '../all';

export function SttBookingScheduleSlotList({ slots, onAdd, onSelect }) {
    const cxt = useContext(AppContext);

    function select(slot) {
        if (onSelect && onSelect.constructor === Function) {
            onSelect(slot);
        }
    }

    return (
        <SttList
            title={cxt.t('TimeSlot')}
            onAdd={onAdd}
            onSelect={select}
            data={
                slots &&
                slots.map((slot) => ({
                    avatar: null,
                    caption: (
                        <Box display="flex" justifyContent="space-between">
                            <SttLabelWeekdays weekdays={slot.weekdays} />
                            <Box
                                ml={1}
                            >{`${slot.timeini} - ${slot.timeend}`}</Box>
                        </Box>
                    ),
                    // text: `${slot.numBookings} ${cxt.t('Bookings')}`,
                    objToSelect: slot,
                }))
            }
        />
    );
}
