import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { SttDialog } from '../../sporttia/all';
import { latinize, requestAdHoc } from '../../lib/utils';
import { usePOSConfig, useTranslations } from '../../lib/hooks';
import Colors from '../../styles/Colors';

const PosStatuses = {
    ok: 'OK',
    error: 'ERROR',
    conn_error: 'CONN_ERROR',
};

/**
 * Diálogo para realizar el pago con datáfono.
 * @param paymentData parámetros para el pago en la forma {amount, concept, idTpv, returnParams, sc}
 * @param open modal abierto
 * @param onClose evento de cierre
 * @param onSuccess evento de pago exitoso
 * @returns {JSX.Element}
 */
export default function POSPaymentDialog({
    paymentData,
    open,
    onClose,
    onSuccess,
}) {
    const SELF_CLOSING_SECONDS = 30;

    // hooks
    const { translate } = useTranslations();
    const [status, setStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    // timer se usa para la cuenta atrás de "esta ventana se cerrará en X segundos"
    const [timer, setTimer] = useState(-1);

    // ip del dispositivo guardada en localStorage (ver /pages/sc/configuration/ConfigurationPOS.js)
    const ip = localStorage.getItem('CM5DeviceAddress');
    const setupPOSConfig = usePOSConfig('PAYCOMET');

    const POSConfig = setupPOSConfig(ip);

    // Actualizar valores al recibir un nuevo 'paymentData' y lanzar el pago
    useEffect(() => {
        if (paymentData) {
            setStatus(null);
            setErrorMessage('');
            setTimer(-1);

            // Handshake, y si ha ido bien, entonces pago.
            // eslint-disable-next-line no-use-before-define
            handShake()
                .then(() => {
                    // eslint-disable-next-line no-use-before-define
                    setStatus(null);
                    // eslint-disable-next-line no-use-before-define
                    requestPosPayment();
                })
                .catch((err) => {
                    setStatus(PosStatuses.conn_error);
                    setErrorMessage(err);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentData]);

    // Actualizar la cuenta atrás. Devuelve un callback que se llamará al desmontar el componente.
    useEffect(() => {
        let timeout;
        if (timer > 0) {
            timeout = setTimeout(() => {
                setTimer(timer - 1);
            }, 1000);
        } else if (timer === 0) {
            onClose();
        } else if (timer === -1) {
            clearTimeout(timeout);
        }

        return () => {
            clearTimeout(timeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timer]);

    // El handshake establece comunicación con el datáfono
    function handShake() {
        return new Promise((resolve, reject) => {
            // Si no está establecida la IP del datáfono no hay nada que rascar.
            if (!ip) {
                reject(translate('config.pos.deviceIpNotSet'));
            } else {
                // Lanzar la petición de handshake
                requestAdHoc(
                    POSConfig.init.method,
                    POSConfig.init.uri,
                    POSConfig.init.params(),
                )
                    .then((r) => {
                        const reponse = POSConfig.init.response(r);
                        if (reponse.status !== 'OK') {
                            // el handshake crujió
                            reject(translate('config.pos.deviceTestError'));
                        } else {
                            resolve();
                        }
                    })
                    .catch((err) => {
                        reject(
                            err?.error?.msg ||
                                translate('config.pos.unknownError'),
                        );
                    });
            }
        });
    }

    // Hacer una petición al datáfono
    function requestPosPayment() {
        const params = {
            amount: paymentData.amount,
            concept: latinize(paymentData.concept),
            idTpv: paymentData.idTpv,
        };

        // Lanzar la petición de pago
        requestAdHoc(
            POSConfig.transaction.method,
            POSConfig.transaction.uri,
            POSConfig.transaction.params(params),
        )
            .then((r) => {
                const response = POSConfig.transaction.response(r);

                if (response?.status === 'OK') {
                    // Callback: llamar pasando los parámetros dados por returnParams (se usan fuera para hacer cosas)
                    setStatus(PosStatuses.ok);
                    setErrorMessage(null);
                    onSuccess?.(response.ticket, paymentData?.returnParams);
                } else {
                    setStatus(PosStatuses.error);
                    setErrorMessage(response.message);
                }
                setTimer(SELF_CLOSING_SECONDS);
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
            });
    }

    function requestPrintCopy() {
        // Lanzar la petición de imprimir copia
        requestAdHoc(
            POSConfig.printLastTransaction.method,
            POSConfig.printLastTransaction.uri,
            null,
        )
            .then((response) => {
                if (response.resultCode) {
                    if (parseInt(response.resultCode) !== 0) {
                        setErrorMessage(response.resultMessage);
                    }
                }
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
            });

        setTimer(-1);
    }

    return (
        <SttDialog
            title={translate('posPaymentDialog.title')}
            maxWidth="sm"
            open={open}
            onClose={onClose}
            content={
                <Box>
                    {!status ? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                        >
                            <Typography>
                                {translate('posPaymentDialog.waitingForPos')}...
                            </Typography>
                            <Box mt={2}>
                                <CircularProgress />
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                        >
                            {status === PosStatuses.ok && (
                                <>
                                    <Typography>
                                        {translate(
                                            'posPaymentDialog.operationComplete',
                                        )}
                                    </Typography>
                                    <Box mt={2}>
                                        <Typography>
                                            {translate('Result')}:{' '}
                                            <span
                                                style={{ color: Colors.green }}
                                            >
                                                {status}
                                            </span>
                                        </Typography>
                                    </Box>
                                    <Box mt={2}>
                                        <Button
                                            onClick={requestPrintCopy}
                                            variant="contained"
                                            color="primary"
                                        >
                                            {translate('pos.printCopy')}
                                        </Button>
                                    </Box>
                                    {errorMessage !== '' && (
                                        <Box mt={2}>
                                            <Typography
                                                style={{ color: Colors.red }}
                                            >
                                                {errorMessage}
                                            </Typography>
                                        </Box>
                                    )}
                                    {timer > 0 && (
                                        <Box mt={2}>
                                            <Typography>
                                                {translate(
                                                    'posPaymentDialog.selfClosingIn',
                                                )}{' '}
                                                {timer}
                                            </Typography>
                                        </Box>
                                    )}
                                </>
                            )}
                            {status === PosStatuses.error && (
                                <>
                                    <Typography>
                                        {translate(
                                            'posPaymentDialog.operationComplete',
                                        )}
                                    </Typography>
                                    <Box mt={2}>
                                        <Typography>
                                            {translate('Result')}:{' '}
                                            <span style={{ color: Colors.red }}>
                                                {status}
                                            </span>
                                        </Typography>
                                        {errorMessage !== '' && (
                                            <Box mt={2}>
                                                <Typography
                                                    style={{
                                                        color: Colors.red,
                                                    }}
                                                >
                                                    {errorMessage}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                    {timer > 0 && (
                                        <Box mt={2}>
                                            <Typography>
                                                {translate(
                                                    'posPaymentDialog.selfClosingIn',
                                                )}{' '}
                                                {timer}
                                            </Typography>
                                        </Box>
                                    )}
                                </>
                            )}
                            {status === PosStatuses.conn_error && (
                                <>
                                    <Typography style={{ color: Colors.red }}>
                                        {errorMessage}
                                    </Typography>
                                    <Box mt={2}>
                                        <Button
                                            onClick={() => {
                                                handShake()
                                                    .then(() => {
                                                        setStatus(null);
                                                        requestPosPayment();
                                                    })
                                                    // eslint-disable-next-line no-console
                                                    .catch((err) => {
                                                        setStatus(
                                                            PosStatuses.conn_error,
                                                        );
                                                        setErrorMessage(err);
                                                    });
                                            }}
                                            color="primary"
                                        >
                                            {translate('pos.retry')}
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </Box>
                    )}
                </Box>
            }
            buttons={[
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
        />
    );
}
