import { useContext } from 'react';
import {
    MutationFunction,
    UseMutationOptions,
    UseQueryOptions,
    useMutation,
    useQuery,
} from 'react-query';
import useRestService from './RestService';
import { useAPI } from '../lib/hooks';
import { AppContext } from '../sporttia/AppContext';
import {
    DeletePayment200,
    GetPayment200,
    GetPaymentParams,
    UpdatePayment200,
    UpdatePaymentBody,
    UpdatePaymentCollect200,
    UpdatePaymentCollectBody,
    UpdatePaymentRecover200,
    UpdatePaymentRefund200,
    UpdatePaymentRefundBody,
} from '../types/api/paths/Payment';

const resource = '/payments';

export const getGetPaymentQueryKey = (
    id: number,
    params?: GetPaymentParams,
) => [`${resource}/${id}`, ...(params ? [params] : [])];

/**
 * Hook to access all 'payments' resources.
 */
export default function usePaymentsService() {
    const { migrationApis } = useContext(AppContext)!;
    const [request] = useAPI(migrationApis);
    const services = useRestService(resource);

    /**
     * PUT /payments/:id/refund request.
     */
    function updatePaymentsRefund(id: number, params: Record<string, unknown>) {
        return request('PUT', `${resource}/${id}/refund`, params);
    }

    /**
     * GET /payments/:id request.
     */
    function useGetPayment<TData = GetPayment200>(
        id: number,
        params?: GetPaymentParams,
        queryOptions?: UseQueryOptions<GetPayment200, unknown, TData>,
    ) {
        const queryKey =
            queryOptions?.queryKey ?? getGetPaymentQueryKey(id, params);

        const queryFn = () =>
            request<GetPayment200>('GET', `${resource}/${id}`, params);

        const query = useQuery(queryKey, queryFn, queryOptions);

        return {
            ...query,
            queryKey,
        };
    }

    /**
     * PUT /payments/:id request.
     */
    function useUpdatePayment(
        mutationOptions?: UseMutationOptions<
            UpdatePayment200,
            unknown,
            { id: number; params: UpdatePaymentBody }
        >,
    ) {
        const mutationFn: MutationFunction<
            UpdatePayment200,
            { id: number; params: UpdatePaymentBody }
        > = ({ id, params }) => request('PUT', `${resource}/${id}`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * DELETE /payments/:id request.
     */
    function useDeletePayment(
        mutationOptions?: UseMutationOptions<
            DeletePayment200,
            unknown,
            { id: number }
        >,
    ) {
        const mutationFn: MutationFunction<
            DeletePayment200,
            { id: number }
        > = ({ id }) => request('DELETE', `${resource}/${id}`);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * PUT /payments/:id/recover request.
     */
    function useRecoverPayment(
        mutationOptions?: UseMutationOptions<
            UpdatePaymentRecover200,
            unknown,
            { id: number }
        >,
    ) {
        const mutationFn: MutationFunction<
            UpdatePaymentRecover200,
            { id: number }
        > = ({ id }) => request('PUT', `${resource}/${id}/recover`);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * PUT /payments/:id/collect request.
     */
    function useCollectPayment(
        mutationOptions?: UseMutationOptions<
            UpdatePaymentCollect200,
            unknown,
            { id: number; params: UpdatePaymentCollectBody }
        >,
    ) {
        const mutationFn: MutationFunction<
            UpdatePaymentCollect200,
            { id: number; params: UpdatePaymentCollectBody }
        > = ({ id, params }) =>
            request('PUT', `${resource}/${id}/collect`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * PUT /payments/:id/refund request.
     */
    function useRefundPayment(
        mutationOptions?: UseMutationOptions<
            UpdatePaymentRefund200,
            unknown,
            { id: number; params: UpdatePaymentRefundBody }
        >,
    ) {
        const mutationFn: MutationFunction<
            UpdatePaymentRefund200,
            { id: number; params: UpdatePaymentRefundBody }
        > = ({ id, params }) =>
            request('PUT', `${resource}/${id}/refund`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    return {
        ...services,
        updatePaymentsRefund,
        useGetPayment,
        useDeletePayment,
        useRecoverPayment,
        useCollectPayment,
        useRefundPayment,
        useUpdatePayment,
    };
}
