import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, makeStyles, Divider } from '@material-ui/core';
import moment from 'moment';
import { AppContext } from '../../../sporttia/all';
import { formatPriceByLocale, fullName } from '../../../lib/utils';

const useStyles = makeStyles((theme) => ({
    heading: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: theme.spacing(1),
        borderBottom: '1px solid #cdcdcd',
    },
    name: {
        fontSize: 16,
        minWidth: 300,
    },
    cat: {
        fontSize: 16,
    },
    pricePending: {
        fontSize: 16,
        color: 'red',
    },
    pricePaid: {
        fontSize: 16,
        color: 'green',
    },
}));

export default function EventInscriptions({ event, inscriptions }) {
    const cxt = useContext(AppContext);
    const classes = useStyles();
    if (!inscriptions) {
        return null;
    }

    return (
        <>
            <Box mb={3}>
                <Typography variant="body1">
                    {cxt.t('page.event.oldInscriptions').toUpperCase()}
                </Typography>
            </Box>
            <Box>
                {inscriptions.map((insc, i) => (
                    <Box key={i} mb={3}>
                        <Box mb={1} className={classes.heading}>
                            <Typography>
                                <b>{cxt.t('Inscription')}</b>
                            </Typography>
                            <Typography style={{ color: '#000' }}>
                                {moment(insc.created).format('DD MMM YYYY')}
                            </Typography>
                        </Box>
                        <Box>
                            {insc.participants &&
                                insc.participants.map((participant, i) => {
                                    let priceClass = classes.pricePending;
                                    if (
                                        participant.category?.price === 0 ||
                                        (participant.enrollment &&
                                            participant.enrollment?.status ==
                                                'PAID')
                                    ) {
                                        priceClass = classes.pricePaid;
                                    }
                                    return (
                                        <React.Fragment key={i}>
                                            {i > 0 && (
                                                <Box my={1}>
                                                    <Divider />
                                                </Box>
                                            )}
                                            <Box
                                                p={1}
                                                display="flex"
                                                flexWrap="wrap"
                                                justifyContent="space-between"
                                            >
                                                <Typography
                                                    className={classes.name}
                                                >
                                                    {fullName(participant)}
                                                </Typography>
                                                <Typography>
                                                    {participant.category?.name}
                                                </Typography>
                                                <Typography
                                                    className={priceClass}
                                                >
                                                    {participant.category
                                                        ?.enrollment > 0
                                                        ? formatPriceByLocale(
                                                              participant
                                                                  ?.category
                                                                  ?.enrollment,
                                                              participant
                                                                  ?.category
                                                                  ?.enrollmentShape
                                                                  ?.currency,
                                                              participant
                                                                  ?.category
                                                                  ?.enrollmentShape
                                                                  ?.locale,
                                                          )
                                                        : cxt.t('Free')}
                                                </Typography>
                                            </Box>
                                        </React.Fragment>
                                    );
                                })}
                        </Box>
                    </Box>
                ))}
            </Box>
        </>
    );
}
