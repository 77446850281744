import { useContext } from 'react';
import { useAPI } from '../lib/hooks';
import { AppContext } from '../sporttia/AppContext';

/**
 * Hook that returns the default REST methods of the resource (GET, POST, PUT and DELETE).
 * @deprecated
 */
export default function useRestService(resource: string) {
    const { migrationApis } = useContext(AppContext)!;
    const [request] = useAPI(migrationApis);

    /**
     * GET /resource/:id request.
     * @deprecated
     */
    function get<T>(id: number, params?: Record<string, unknown>) {
        return request<T>('GET', `${resource}/${id}`, params);
    }

    /**
     * GET /resource request.
     * @deprecated
     */
    function getList<T>(params?: Record<string, unknown>) {
        return request<T>('GET', resource, params);
    }

    /**
     * POST /resource request.
     * @deprecated
     */
    function create<T>(params: Record<string, unknown>) {
        return request<T>('POST', resource, params);
    }

    /**
     * PUT /resource/:id request.
     * @deprecated
     */
    function update<T>(id: number, params: Record<string, unknown>) {
        return request<T>('PUT', `${resource}/${id}`, params);
    }

    /**
     * DELETE /resource/:id request.
     * @deprecated
     */
    function remove<T>(id: number, params?: Record<string, unknown>) {
        return request<T>('DELETE', `${resource}/${id}`, params);
    }

    /**
     * Recuperar fila borrara. PUEDE QUE NO ESTÉ IMPLEMENTADO PARA UN MODELO DADO
     * @deprecated
     * */
    function recover<T>(id: number) {
        return request<T>('PUT', `${resource}/${id}/recover`);
    }

    return { get, getList, create, update, remove, recover };
}
