import React, { useState, useEffect, useContext } from 'react';
import {
    Paper,
    Box,
    Button,
    Container,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Link,
} from '@material-ui/core';
import {
    VerifiedUserOutlined,
    ArrowRight,
    ChatBubbleOutline,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import {
    AppContext,
    SttButton,
    SttButtonAccept,
    SttFormCheck,
} from '../../sporttia/all';
import { getPath } from '../Pages';

export default function AcceptConditions(props) {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const [user, setUser] = useState(null);
    const [acceptPrivacy, setAcceptPrivacy] = useState(false);
    const [acceptEmail, setAcceptEmail] = useState(true);

    useEffect(() => {
        confirmEmail();
    }, []);

    useEffect(() => {
        if (user && user.numSCs > 0) {
            setAcceptPrivacy(true);
        }
    }, [user]);

    /**
     * Confirm email
     */
    function confirmEmail() {
        const { id, signature } = props.match.params;

        // We've got signature
        if (id && signature) {
            cxt.api('PUT', `/users/${id}/emails/confirmations`, {
                params: {
                    signature,
                },
                success: (r) => {
                    if (r.user) {
                        if (r.user.status === 'ACTIVE') {
                            history.replace(getPath('login'));
                        } else {
                            setUser(r.user);
                        }
                    }
                },
            });

            // Not enough data, go to home
        } else {
            history.replace(getPath('home'));
        }
    }

    function proceed() {
        // User accept privay check
        if (acceptPrivacy) {
            const { id, signature } = props.match.params;

            cxt.api('PUT', `/users/${id}/confirm`, {
                params: {
                    signature,
                    commOn: acceptEmail,
                },
                success: (response) => {
                    cxt.showMessage('S', cxt.t('confirm.success'));
                    setTimeout(() => {
                        history.replace(getPath('login'));
                    }, 2000);
                },
            });

            // Please, check the privacy check
        } else {
            cxt.showMessage('E', cxt.t('register.mustAccept'));
        }
    }

    if (!user) {
        return null;
    }

    return (
        <Container component="main" maxWidth="md">
            <Box mt={5}>
                <Paper>
                    <Box p={4}>
                        <Box>
                            <Box display="flex" alignItems="center">
                                <Box mr={1}>
                                    <VerifiedUserOutlined fontSize="large" />
                                </Box>
                                <Typography variant="h4">
                                    {cxt.t('page.accept.title').toUpperCase()}
                                </Typography>
                            </Box>
                            <Separator />
                        </Box>
                        <Typography variant="body1">
                            {`${cxt.t('Hello')}, `}
                            <b>{user.name}</b>
                        </Typography>
                        <Box mt={1} mb={1}>
                            <Typography variant="body1">
                                {cxt.t('page.accept.desc')}
                            </Typography>
                        </Box>
                        <Box mt={1} mb={1}>
                            <List dense={false}>
                                <ListItem style={{ padding: 0 }}>
                                    <ListItemIcon>
                                        <ArrowRight />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={`${cxt.t(
                                            'page.accept.task1',
                                        )}.`}
                                    />
                                </ListItem>
                                <ListItem style={{ padding: 0 }}>
                                    <ListItemIcon>
                                        <ArrowRight />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={`${cxt.t(
                                            'page.accept.task2',
                                        )}.`}
                                    />
                                </ListItem>
                                <ListItem style={{ padding: 0 }}>
                                    <ListItemIcon>
                                        <ArrowRight />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={`${cxt.t(
                                            'page.accept.task3',
                                        )}.`}
                                    />
                                </ListItem>
                                <ListItem style={{ padding: 0 }}>
                                    <ListItemIcon>
                                        <ArrowRight />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={`${cxt.t(
                                            'page.accept.task4',
                                        )}.`}
                                    />
                                </ListItem>
                            </List>
                        </Box>
                        <Box mt={1} mb={1}>
                            <Typography variant="body1">
                                <b>{cxt.t('Note')}: </b>
                                <span>{cxt.t('page.accept.updateCond')}: </span>
                                <Link href="/privacy">
                                    {cxt.t('LegalConditions')}.
                                </Link>
                            </Typography>
                        </Box>
                        {user.numSCs === 0 && (
                            <Box mt={1} mb={1}>
                                <SttFormCheck
                                    color="primary"
                                    caption={
                                        <>
                                            <b>{cxt.t('page.accept.check1')}</b>
                                            &nbsp;
                                            <Link href="/privacy">
                                                {cxt.t('LegalConditions')}
                                            </Link>
                                            .
                                        </>
                                    }
                                    checked={acceptPrivacy}
                                    onChange={({ undefined, value }) =>
                                        setAcceptPrivacy(value)
                                    }
                                />
                            </Box>
                        )}
                        <Box mt={3} mb={1}>
                            <SttFormCheck
                                color="primary"
                                caption={
                                    <>
                                        <b>{cxt.t('page.accept.check2')}</b>{' '}
                                        <span>
                                            {cxt.t(
                                                'page.accept.check2DescShort',
                                            )}
                                        </span>
                                        .{' '}
                                        <b>
                                            {cxt.t(
                                                'app.account.notifications.notEnabled3',
                                            )}
                                        </b>
                                    </>
                                }
                                checked={acceptEmail}
                                onChange={({ undefined, value }) =>
                                    setAcceptEmail(value)
                                }
                            />
                        </Box>
                        <Separator />
                        <Box
                            mt={1}
                            mb={1}
                            display="flex"
                            justifyContent="space-between"
                        >
                            <SttButtonAccept onClick={proceed} />
                            <SttButton
                                caption={cxt.t('Contact')}
                                icon={<ChatBubbleOutline />}
                                size="small"
                                onClick={() => history.push(getPath('contact'))}
                            />
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Container>
    );
}

function Separator({}) {
    return <Box m={4} height={0} borderBottom="1px solid #d4d4d4" />;
}
