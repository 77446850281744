import React, { useState, useEffect, useContext } from 'react';
import {
    Container,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    TablePagination,
    Box,
} from '@material-ui/core';
import { Search, Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import useKey from '@rooks/use-key';
import { AppContext } from '../all';

const useStyles = makeStyles((theme) => ({
    cursorPointer: {
        cursor: 'pointer',
    },
    icon: {
        width: 32,
        height: 32,
    },
}));

function SportPickModal({ open, onSelected, onClose }) {
    const cxt = useContext(AppContext);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [numRows, setNumRows] = useState(25);
    const [totalRows, setTotalRows] = useState(0);
    const [sports, setSports] = useState([]);

    const textRef = React.useRef(null);

    const classes = useStyles();

    // Filter sports when search changes
    useEffect(() => {
        loadSports({ page, numRows });
    }, [page, numRows]);

    useKey(
        ['Enter'],
        () => {
            if (page !== 1) {
                setPage(1);
            } else {
                loadSports({ page: 1, numRows });
            }
        },
        { target: textRef },
    );

    function loadSports({ page, numRows }) {
        cxt.api('GET', `/sports`, {
            params: {
                name: search,
                page,
                rows: numRows,
            },
            success: (r) => {
                setTotalRows(r.count);
                setSports(r.rows);
            },
        });
    }

    return (
        <Dialog
            fullWidth={false}
            maxWidth="md"
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    minWidth:
                        '60%' /* Guarrería que hay que hacer para que no se vaya todo a la mierda con el Grid cuando hay pocos deportes */,
                },
            }}
        >
            <DialogTitle>
                <Box display="flex" justifyContent="flex-end">
                    <Close cursor="pointer" onClick={onClose} />
                </Box>
            </DialogTitle>
            <DialogContent style={{ minHeight: 680 }}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    paddingLeft={4}
                    paddingRight={4}
                    paddingBottom={1}
                >
                    <TextField
                        ref={textRef}
                        margin="none"
                        id="sport-search"
                        label={cxt.t('Search')}
                        value={search}
                        className="search"
                        variant="outlined"
                        size="small"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <TablePagination
                        component="div"
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        // colSpan={100}
                        count={totalRows}
                        rowsPerPage={numRows}
                        page={page - 1}
                        SelectProps={{
                            native: true,
                        }}
                        onChangePage={(e, page) => setPage(page + 1)}
                        onChangeRowsPerPage={(e) => setNumRows(e.target.value)}
                        labelDisplayedRows={({ from, to, count }) =>
                            `${from}-${to === -1 ? count : to} ${cxt.t(
                                'of',
                            )} ${count}`
                        }
                    />
                </Box>
                <Container>
                    <Grid container spacing={3}>
                        {(() => {
                            const items = [];
                            for (let i = 0; i < numRows; i++) {
                                const item = sports[i];
                                if (item) {
                                    items.push(
                                        <Grid
                                            style={{ minWidth: 280 }}
                                            item
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            key={item.id}
                                        >
                                            <Button
                                                onClick={() => {
                                                    onSelected(item);
                                                    onClose();
                                                }}
                                                fullWidth
                                                variant="contained"
                                                color="default"
                                                startIcon={
                                                    <img
                                                        className={classes.icon}
                                                        src={cxt.getResource(
                                                            `/sports/${item.id}.png`,
                                                        )}
                                                    />
                                                }
                                            >
                                                {cxt.t(`Sport.${item.id}`)}
                                            </Button>
                                        </Grid>,
                                    );
                                } else {
                                    items.push(
                                        <Grid
                                            style={{ minWidth: 280 }}
                                            item
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            key={`filler_${i}`}
                                        />,
                                    );
                                }
                            }
                            return items;
                        })()}
                    </Grid>
                </Container>
            </DialogContent>
        </Dialog>
    );
}

export function SttSportSelector({
    fullWidth = false,
    style = {},
    className = '',
    onChange,
    defVal = '',
    open = false,
}) {
    const cxt = useContext(AppContext);
    const [modalVisible, setModalVisible] = useState(open);
    const [search, setSearch] = useState('');

    const classes = useStyles();

    function change(sport) {
        if (onChange && onChange.constructor === Function) {
            onChange(sport);
        }
    }

    return (
        <div>
            <FormControl
                variant="outlined"
                size="small"
                onClick={() => setModalVisible(true)}
                className={`${className} ${classes.cursorPointer}`}
                style={{ width: fullWidth ? '100%' : 'auto', ...style }}
            >
                <InputLabel>{cxt.t('Sport')}</InputLabel>
                <OutlinedInput
                    inputProps={{
                        className: classes.cursorPointer,
                    }}
                    type="text"
                    value={defVal}
                    endAdornment={
                        <InputAdornment
                            position="end"
                            className={classes.cursorPointer}
                        >
                            <Search />
                        </InputAdornment>
                    }
                    labelWidth={70}
                />
            </FormControl>
            <SportPickModal
                open={modalVisible}
                onSelected={(sport) => change(sport)}
                onClose={() => setModalVisible(false)}
            />
        </div>
    );
}
