import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import { MoreVert as MoreIcon, PowerSettingsNew } from '@material-ui/icons';
import { getPath } from '../../Pages';
import { AppContext } from '../../../sporttia/AppContext';
import { SttTable } from '../../../sporttia/all';
import { useAPI, useCrud, useLoadingBar } from '../../../lib/hooks';
import Contact from '../../public/Contact';
import { formatPriceByLocale } from '../../../lib/utils';

const useStyles = makeStyles((theme) => ({
    subscriptionBox: {
        backgroundColor: '#D8EDD0',
        padding: '1px',
        borderRadius: '3px',
    },
    subExpired: {
        backgroundColor: '#ede0e0',
    },
    subscriptionSummary: {},
    subscriptionPayments: {
        backgroundColor: 'white',
    },
    dividerSubscriptionSummary: {
        marginLeft: '15px',
        marginRight: '15px',
        marginTop: '10px',
        marginBottom: '10px',
    },
    greenBold: {
        display: 'inline',
        color: '#3C763D',
        fontWeight: 'bold',
    },
    redBold: {
        display: 'inline',
        color: '#b22a00',
    },
}));

export default function Subscription({}) {
    const classes = useStyles();
    const history = useHistory();
    const cxt = useContext(AppContext);

    const [, , , Get, Post, ,] = useCrud(undefined, false);

    const [sportCenter, setSportCenter] = useState();
    const [currentSubscription, setCurrentSubscription] = useState({
        id: null,
        status: '',
        dateini: '',
        dateend: '',
        plan: '',
        fee: 0,
        feeInterval: '',
    });
    const [licences, setLicences] = useState();
    const [ready, setReady] = useState(false);
    const [subIsValid, setSubIsValid] = useState(false);
    const currentDate = new Date();

    const [showLoading, hideLoading, loadingBar] = useLoadingBar(true);

    /**
     * Set header title.
     */
    useEffect(() => {
        cxt.setHeader({
            title: cxt.t('Subscription'),
        });
    }, []);

    /**
     * When load page we will request sport center data.
     */
    useEffect(() => {
        if (cxt.sc && !isNaN(cxt.sc.id)) {
            loadSportCenterData();
        }
    }, [cxt.sc]);

    /**
     * When "sportCenter" change we will request subscription sport center data.
     */
    useEffect(() => {
        if (sportCenter) {
            loadSubscriptionData();
        }
    }, [sportCenter]);

    /**
     * When "currentSubscription" is modified will be request to server
     * to load subscription licenses.
     */
    useEffect(() => {
        if (currentSubscription.id) {
            loadLicenses();
        }
    }, [currentSubscription]);

    useEffect(() => {
        if (licences) {
            setSubIsValid(validateSub(licences));
        }
    }, [licences]);

    useEffect(() => {
        if (ready) {
            hideLoading();
        }
    }, [ready]);

    /**
     * Load sportcenter data
     */
    const loadSportCenterData = () => {
        Get(`/scs/${cxt.sc.id}`).then((response) => {
            setSportCenter(response.sc);
        });
    };

    /**
     * Load subscription data
     */
    const loadSubscriptionData = () => {
        Get(`/customers/${sportCenter.customer.id}/subscriptions`, {
            page: 1,
            rows: 100,
        }).then((response) => {
            if (response.count > 0) {
                setCurrentSubscription(getCurrentSubscription(response.rows));
            } else {
                // no sub, like, at all
                setReady(true);
            }
        });
    };

    /**
     * Method to get licences associated with subscription.
     * @param params Params with rows.
     */
    function loadLicenses(params = { rows: 10 }) {
        Get(`/subscriptions/${currentSubscription.id}/licences`, params)
            .then((response) => {
                setLicences(response);
            })
            .finally(() => {
                setReady(true);
            });
    }

    /**
     * Method to get current subscription using current date, "dateini" and "dateend" from subscriptions date.
     * @param subscriptions Array with subscriptions
     * @returns {*} return current subscription.
     */
    function getCurrentSubscription(subscriptions) {
        return subscriptions.find(
            (sub) =>
                currentDate >=
                (new Date(sub.dateini) && currentDate <= new Date(sub.dateend)),
        );
    }

    // Get the latest chronological licence
    function getLatestLicence(licences) {
        if (!licences.rows) {
            return null;
        }

        return licences.rows.sort((a, b) => moment(a.end) > moment(b.end))[0];
    }

    /// /Return true if at least one liecense is not expired
    function validateSub(licences) {
        if (!licences.rows) {
            return false;
        }

        return licences.rows.reduce(
            (result, item) =>
                (!item.trash && !item.inExpirationPeriod) || result,
            false,
        );
    }

    function logout() {
        Post('/logout', {}, true).then((response) => {
            cxt.setLoggedUser(false);
            cxt.setHeader(null);
        });
    }

    const sub = {
        id: 958,
        created: '2020-07-01T11:52:51',
        status: 'ACTIVE',
        dateini: '2020-07-01T00:00:00',
        dateend: '2020-09-30T00:00:00',
        plan: 'ZERO',
        fee: 0,
        feeInterval: '0000-03-00 00:00:00',
        trash: false,
    };

    const lic = {
        id: 3714,
        created: '2020-10-27T08:46:13',
        status: null,
        price: 0,
        ini: '2020-07-01T00:00:00',
        end: '2020-09-30T00:00:00',
        paymentForm: 'FREE',
        trash: false,
        inExpirationPeriod: true,
        daysToExpire: -236,
        subscription: {
            id: 958,
        },
    };

    const latest = licences && getLatestLicence(licences);

    if (!ready) {
        return null;
    }

    if (!currentSubscription.id) {
        return (
            <>
                <Box px={3} display="flex" alignItems="flex-start">
                    <Box flex={1}>
                        <Typography variant="body1">
                            {cxt.t('page.sc.subscription.noSubscription')}
                        </Typography>
                    </Box>
                    <div>
                        <IconButton color="inherit" onClick={logout}>
                            <PowerSettingsNew style={{ color: 'red' }} />
                        </IconButton>
                    </div>
                </Box>
                <br />
                <Contact />
            </>
        );
    }

    // -----| Render |-----
    return (
        <>
            <Box>{loadingBar}</Box>

            {/* Container with remaining days of subscription. */}
            <Box mb={3}>
                <Typography variant="subtitle1" gutterBottom>
                    {subIsValid ? (
                        <div>
                            {cxt.t('page.sc.subscription.numDaysToExpire')}:
                            <Box fontWeight="fontWeightMedium" display="inline">
                                {moment(currentSubscription.dateend).diff(
                                    new Date(),
                                    'days',
                                )}
                            </Box>
                            <Box fontWeight="fontWeightMedium" display="inline">
                                &nbsp;(
                                {moment(currentSubscription.dateend).format(
                                    'DD MMMM YYYY',
                                )}
                                )
                            </Box>
                        </div>
                    ) : (
                        latest && (
                            <Box display="flex">
                                <Box flex={1}>
                                    {cxt.t('page.sc.licence.expiredAt')}:&nbsp;
                                    <Box
                                        fontWeight="fontWeightMedium"
                                        display="inline"
                                    >
                                        {moment(latest.end).format(
                                            'DD/MM/YYYY',
                                        )}
                                    </Box>
                                </Box>
                                <div>
                                    <IconButton
                                        color="inherit"
                                        onClick={logout}
                                    >
                                        <PowerSettingsNew
                                            style={{ color: 'red' }}
                                        />
                                    </IconButton>
                                </div>
                            </Box>
                        )
                    )}
                </Typography>
            </Box>

            {/* Container with subscription summary and subscription payments table. */}
            <Box
                className={`${classes.subscriptionBox} ${
                    !subIsValid ? classes.subExpired : ''
                }`}
            >
                {/* First box with subscription summary. */}
                <Box className={classes.subscriptionSummary}>
                    <Box pr={2} pl={2} pt={2} display="flex">
                        <Box flexGrow={1}>
                            <Typography variant="h6" gutterBottom>
                                <Box className={classes.greenBold}>
                                    {cxt.t('Subscription').toUpperCase()}
                                </Box>{' '}
                                [{' '}
                                <Box className={classes.greenBold}>
                                    {subIsValid
                                        ? cxt.t(`Active`)
                                        : cxt.t('Inactive')}
                                </Box>{' '}
                                ]
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                variant="overline"
                                display="block"
                                gutterBottom
                            >
                                {moment(currentSubscription.dateini).format(
                                    'DD MMM YYYY',
                                )}{' '}
                                -{' '}
                                {moment(currentSubscription.dateend).format(
                                    'DD MMM YYYY',
                                )}
                            </Typography>
                        </Box>
                    </Box>
                    <Divider className={classes.dividerSubscriptionSummary} />
                    <Box p={2}>
                        <Box>
                            {cxt.t('Plan')}:{' '}
                            <Box className={classes.greenBold}>
                                {cxt.t(`SP.${currentSubscription.plan}`)}
                            </Box>
                        </Box>
                        <Box>
                            {cxt.t('Fee')}:{' '}
                            <Box
                                className={classes.greenBold}
                            >{`${formatPriceByLocale(
                                currentSubscription.fee,
                                cxt.sc.city.country.currency,
                            )}`}</Box>{' '}
                            /{' '}
                            <Box className={classes.greenBold}>
                                {cxt.t(`${currentSubscription.feeInterval}`)}
                            </Box>
                        </Box>
                        <Box mt={2}>
                            {subIsValid ? (
                                <Link
                                    style={{ color: 'blue' }}
                                    onClick={() =>
                                        history.push(getPath('help'))
                                    }
                                >
                                    {cxt.t('page.sc.subscription.cancel')}
                                </Link>
                            ) : (
                                <Link
                                    style={{ color: 'blue' }}
                                    onClick={() => {
                                        // window.location.href = "https://www.sporttia.com/es/contactar/";
                                        window.open(
                                            'https://www.sporttia.com/es/contactar/',
                                            '_new',
                                        );
                                    }}
                                >
                                    {cxt.t('page.sc.subscription.renewLicence')}
                                </Link>
                            )}
                        </Box>
                    </Box>
                </Box>

                {/* Second box with subscription payments table. */}
                <Box className={classes.subscriptionPayments}>
                    <Box p={2}>
                        <SttTable
                            data={licences}
                            loading={false}
                            autoload={false}
                            columns={[
                                {
                                    title: cxt.t('period'),
                                    align: 'left',
                                    value: (row) => `${moment(row.ini).format(
                                        'DD MMM YYYY',
                                    )} - ${moment(row.end).format(
                                        'DD MMM YYYY',
                                    )}
									${
                                        currentDate >= new Date(row.ini) &&
                                        currentDate <= new Date(row.end)
                                            ? `[ ${cxt.t('Active')} ]`
                                            : ''
                                    }`,
                                },
                                {
                                    title: cxt.t('PaymentForm'),
                                    value: (row) =>
                                        row.paymentForm
                                            ? cxt.t(`PF.${row.paymentForm}`)
                                            : '',
                                },
                                {
                                    title: cxt.t('Status'),
                                    value: (row) =>
                                        row.paymentForm ? (
                                            cxt.t('PAID')
                                        ) : (
                                            <Box className={classes.redBold}>
                                                {cxt.t('Pending')}
                                            </Box>
                                        ),
                                },
                                {
                                    title: cxt.t('price'),
                                    field: 'price',
                                    align: 'right',
                                    value: (row) =>
                                        `${formatPriceByLocale(
                                            row.price,
                                            cxt.sc?.city?.country?.currency,
                                        )}`,
                                },
                            ]}
                            onFetch={loadLicenses}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
}
