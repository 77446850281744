import React, { useState } from 'react';
import { Box, InputLabel } from '@material-ui/core';
import moment from 'moment';
import queryStringLib from 'query-string';
import { SttFormText } from '../sporttia/forms/SttFormText';
import { SttFormTextarea } from '../sporttia/forms/SttFormTextarea';
import { SttFormSelect } from '../sporttia/forms/SttFormSelect';
import { SttFormDate } from '../sporttia/forms/SttFormDate';
import { SttFormCheck } from '../sporttia/forms/SttFormCheck';
import { getPath } from '../pages/Pages';
import { isErrorResponse, isObject } from '../types/common';
import latinizeCharacters from './latinizeCharacters';
import constants from '../config/constants';

/**
 * Format a ini,end pair as "date1 - date2", "date, time1 : time2" etc.
 */
export function formatPeriod(ini, end, options, translationFunc) {
    const defaultOptions = {
        dateFormat: 'DD/MM/YYYY',
        timeFormat: 'HH:mm',
        dateLabel: translationFunc ? translationFunc('Date') : 'Fecha',
        periodLabel: translationFunc ? translationFunc('Period') : 'Periodo',
        timeLabel: translationFunc ? translationFunc('Time') : 'Hora',
        scheduleLabel: translationFunc ? translationFunc('Horary') : 'Horario',
    };

    const myOptions = options
        ? { ...defaultOptions, ...options }
        : defaultOptions;

    const dateIni = moment(ini).format(myOptions.dateFormat);
    const dateEnd = moment(end).format(myOptions.dateFormat);
    const timeIni = moment(ini).format(myOptions.timeFormat);
    const timeEnd = moment(end).format(myOptions.timeFormat);

    let dateString = '';
    let timeString;
    let dateLabel = myOptions.periodLabel;
    let timeLabel = myOptions.timetableLabel;

    if (dateIni !== dateEnd) {
        dateString = `${dateIni} - ${dateEnd}`;
    } else {
        dateString = dateIni;
        dateLabel = myOptions.dateLabel;
    }

    if (timeIni !== timeEnd) {
        timeString = `${timeIni} - ${timeEnd}`;
    } else {
        timeString = timeIni;
        timeLabel = myOptions.scheduleLabel;
    }

    const dateTime = `${dateIni} ${timeIni} - ${dateEnd} ${timeEnd}`;

    return [dateString, timeString, dateLabel, timeLabel, dateTime];
}

/**
 * Format price
 */
export const formatPrice = (num, currencySymbol = '€') => {
    const result = (Math.round(num * 100) / 100).toFixed(2);
    return `${Number.isNaN(result) ? ' - ' : result} ${currencySymbol}`;
};

/**
 * Format price by locale
 */
export const formatPriceByLocale = (num, currency = 'EUR', locale = 'es-ES') =>
    Number(num).toLocaleString(locale, {
        style: 'currency',
        currency,
    });

/**
 * Parse price into a regular JS float
 */
export const parsePrice = (price) => {
    if (price === undefined || price === null || price === '') {
        return null;
    }

    const parsedPrice = parseFloat(price.toString().replace(',', '.')).toFixed(
        2,
    );

    return parsedPrice === 'NaN' ? NaN : parsedPrice;
};

/**
 * It's define
 */
export const isDefined = (val) => val != null;

/**
 * Construir un query string
 *
 * Devuelve un string con lo que enviar a una llamada de tipo GET del tipo a=1&b=2&c=82. Tener en cuenta
 * que no devuelve el inicio "?".
 *
 * @param {Object} params - Objeto con los parámetros
 * @param {Object} options - Opciones:
 *
 *  - bool includeEmpty - Incluye a los que el valor esté vacío o es undefined.
 *
 * @return string - Query string sin "?"
 */
export const buildQueryString = (params, options = {}) =>
    Object.entries(params)
        .filter(([, value]) => options.includeEmpty || value !== undefined)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

/**
 * Update element in array
 *
 * This function create as well if the element doesn't exist.
 */
export function updateElementInArray(
    arr,
    element,
    { key = 'id', merge = false } = {},
) {
    if (element) {
        const newArray = arr ? [...arr] : [];
        const foundElementIndex = newArray.findIndex(
            (e) => e[key] === element[key],
        );

        // Update it
        if (foundElementIndex > -1) {
            newArray[foundElementIndex] = merge
                ? { ...newArray[foundElementIndex], ...element }
                : element;

            // Create a new one
        } else {
            newArray.push(element);
        }

        return newArray;
    }
}

/**
 * Delete element from array
 */
export function deleteElementFromArray(arr, element) {
    if (arr) {
        const newArray = [...arr];
        newArray.splice(
            newArray.findIndex((e) => e.id === element.id),
            1,
        );
        return newArray;
    }
}

/**
 * Sub set object
 *
 *    * Object obj
 *    * Array|String fields
 */
export function subsetObject(obj, fields) {
    let myFields = fields;
    if (typeof myFields === 'string') {
        myFields = myFields.split(',');
    }

    const newObj = {};
    myFields.forEach((field) => {
        if (obj[field] !== undefined) {
            newObj[field] = obj[field];
        }
    });

    return newObj;
}

/**
 * Normalize duration
 *
 * Given duration > 60 turns it into <hours>h <minutes>m
 */
export function normalizeDuration(duration) {
    // Crear cadena xh ym
    if (duration > 60) {
        const hours = Math.floor(duration / 60);
        const minutes = duration % 60;

        return `${hours}h ${minutes > 0 ? `${minutes}m` : ''}`;
    }
    return `${duration}m`;
}

// Generate 'question' fields for the activity/event forms
export function generateFormField(item, onChange, placeholder = false) {
    let field;

    switch (item.type) {
        case 'NUMERIC':
            field = (
                <SttFormText
                    name={item.id.toString()}
                    caption={item.name}
                    defVal={item.answer ? item.answer.value : ''}
                    onChange={onChange}
                />
            );
            break;
        case 'STRING':
            field = (
                <>
                    {placeholder && (
                        <>
                            <InputLabel>{item.name}</InputLabel>
                            <Box sx={{ mb: 1 }} />
                        </>
                    )}

                    <SttFormTextarea
                        name={item.id.toString()}
                        caption={item.name}
                        defVal={item.answer ? item.answer.value : ''}
                        onChange={onChange}
                        placeholder={placeholder}
                    />
                </>
            );
            break;
        case 'SELECT':
            field = (
                <SttFormSelect
                    name={item.id.toString()}
                    caption={item.name}
                    defVal={item.answer ? item.answer.value : ''}
                    options={item?.options?.map((opt) => ({
                        caption: opt.name,
                        value: opt.value,
                    }))}
                    onChange={onChange}
                />
            );
            break;
        case 'DATE':
            field = (
                <SttFormDate
                    name={item.id.toString()}
                    caption={item.name}
                    defVal={item.answer ? item.answer.value : ''}
                    onChange={onChange}
                />
            );
            break;
        case 'CHECK':
            field = (
                <SttFormCheck
                    name={item.id.toString()}
                    caption={item.name}
                    checked={item.answer ? !!item.answer.value : false}
                    onChange={({ name, value }) =>
                        onChange({ name, value: +value })
                    }
                />
            );
            break;
        default:
            field = null;
    }

    return field;
}

// Given a possibly user object, return its mship.fullName property, its fullName property, or the combination of name, surname1 and surname2 if applicable.
export function fullName(userObj) {
    if (!userObj) {
        return '';
    }

    if (userObj.mship) {
        if (userObj.mship?.fullName)
            return userObj.mship.fullName.replaceAll('null', '');
        if (
            userObj.mship?.name &&
            userObj.mship?.surname1 &&
            userObj.mship?.surname2
        )
            return `${userObj.mship.name} ${userObj.mship.surname1} ${userObj.mship.surname2}`?.replaceAll(
                'null',
                '',
            );
    }

    if (userObj.displayName) {
        return userObj.displayName.replaceAll('null', '');
    }

    return (
        userObj.fullName?.replaceAll('null', '') ||
        (
            userObj.name +
            (userObj.surname1 ? ` ${userObj.surname1}` : '') +
            (userObj.surname2 ? ` ${userObj.surname2}` : '')
        )?.replaceAll('null', '')
    );
}

// Return an array of values as a comma-separated string, optinally using extractorFunc() to get the values
export function commaSeparated(array, extractorFunc) {
    if (!Array.isArray(array)) {
        return null;
    }

    return array.reduce(
        (res, val, i) =>
            res +
            (i > 0 ? ',' : '') +
            (extractorFunc ? extractorFunc(val) : val.toString()),
        '',
    );
}

// Esta función intenta hacer un truqui para cargar el documento en un iframe e imprimir desde allí, pero no funciona (cross-origin)
// x-frame-options no sirve ya que solo acepta DENY y SAMEORIGIN (no es el caso)
// No sirve ni con un dataurl ni con url normal. ni siquiera está claro que sea posible mandar desde javascript algo a la cola de impresión
// Lo dejo aquí para que nuestro yo del futuro no intente implementar de nuevo una cosa imposible.
export function print(url) {
    fetch(url)
        .then((response) => response.blob())
        .then(
            (blob) =>
                new Promise((resolve) => {
                    const fr = new FileReader();
                    fr.onload = () => {
                        resolve(fr.result);
                    };
                    fr.readAsDataURL(blob);
                }),
        )
        .then((dataUrl) => {
            let iframe = document.createElement('iframe');
            document.body.appendChild(iframe);

            iframe.style.display = 'none';
            iframe.onload = function handleOnLoad() {
                // print frame 10ms after load
                setTimeout(() => {
                    try {
                        iframe.focus();
                        iframe.contentWindow.print();
                    } catch (err) {
                        // eslint-disable-next-line no-console
                        console.log(err);
                    }
                }, 10);
                // kill frame 20ms after load
                setTimeout(() => {
                    iframe.blur();
                    document.body.removeChild(iframe);
                    iframe = null;
                }, 20);
            };

            iframe.src = dataUrl;
        });
}

// Copiar value al portapapeles
export function copyToClipboard(value, callback) {
    navigator.clipboard
        .writeText(value)
        .then(() => typeof callback === 'function' && callback(value));
}

/**
 * The returned object is created with [`query-string`](https://www.npmjs.com/package/query-string).
 */
export function getQueryStrings(string, options = {}) {
    return queryStringLib.parse(string, options);
}

/**
 * Abre una pestaña con la url pasada como parámetro.
 * @param url
 */
export function openNewBrowserTab(url) {
    const win = window.open(url, '_blank');
    win.focus();
}

/**
 * Devuelve un array con solo los valores obtenidos a través de la clave pasada como parámetro.
 * @param key Clave de la que se va a sacar el valor.
 * @param objects Array con los objetos.
 * @returns {[]} Array con los valores.
 */
export function getArrayWithOnlyValues(key, objects) {
    const data = [];

    objects.forEach((item) => {
        if (key in item) data.push(item[key]);
    });

    return data;
}

/**
 * Realiza el proceso de redireccionar al login y luego redireccionar de nuevo a la página desde
 * donde se inició el redireccionamiento a login.
 * @param history useHistory de la página donde nos encontremos.
 */
export function redirectLogin(history) {
    history.push({
        pathname: getPath('login'),
        state: { from: history.location },
    });
}

/**
 * Comprueba si un @login tiene el string #OFF#taltal que indica que el usuario se dio de baja
 * @param string
 * @returns {boolean}
 */
export function checkOffTag(string) {
    let result = false;
    if (string.includes('#OFF#')) {
        result = true;
    }
    return result;
}

/**
 * Devuelve un objeto al que se le eliminan las claves dadas por "keys".
 * @param object Objecto a modificar.
 * @param keys Lista de claves a eliminar.
 * @returns {*}
 */
export const objectWithoutKeys = (object, keys = []) => {
    const newObject = { ...object };

    keys.map((key) => (newObject[key] ? delete newObject[key] : null));

    return newObject;
};

/**
 * Compara dos objetos si son iguales.
 * @param obj1 Objeto 1.
 * @param obj2 Objecto 2.
 * @returns {boolean} Devuelve true si son iguales y false en otro caso.
 */
export const checkObjEqual = (obj1, obj2) => {
    const obj1Length = Object.keys(obj1).length;
    const obj2Length = Object.keys(obj2).length;

    if (obj1Length === obj2Length) {
        return Object.keys(obj1).every(
            (key) => key in obj2 && obj2[key] === obj1[key],
        );
    }
    return false;
};

/**
 * Open an external url in a new tab, simulating a link click.
 * @param url URL to open.
 */
export const openUrl = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_new';
    link.click();
};

/**
 * Formats seconds to a string of hours: minutes: seconds.
 * @param duration Seconds.
 * @returns {string} Format string.
 */
export const secondsToFormatTime = (duration) => {
    // Hours, minutes and seconds
    // eslint-disable-next-line no-bitwise
    const hrs = ~~(duration / 3600);
    // eslint-disable-next-line no-bitwise
    const mins = ~~((duration % 3600) / 60);
    // eslint-disable-next-line no-bitwise
    const secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = '';

    if (hrs > 0) {
        ret += `${hrs}:${mins < 10 ? '0' : ''}`;
    }

    ret += `${mins}:${secs < 10 ? '0' : ''}`;
    ret += `${secs}`;
    return ret;
};

export function shallowEqual(object1, object2) {
    if (object1 && object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const key of keys1) {
            if (
                typeof object1[key] === 'object' &&
                typeof object2[key] === 'object'
            ) {
                if (!shallowEqual(object1[key], object2[key])) {
                    return false;
                }
            } else if (object1[key] !== object2[key]) {
                return false;
            }
        }

        return true;
    }
}

export function showErrors(error, showMessage) {
    function validationErrors() {
        if (error.errors?.length > 0) {
            let errorsFormatted = '';
            error.errors.forEach((err) => {
                errorsFormatted += `Field: ${err.field} - ${err.message}\n`;
            });
            showMessage('E', errorsFormatted);
        }
    }

    if (error) {
        // Temporal filter until http error codes are forwarded to individual components
        if (error.msg === '[[Se ha producido un error de validación.]]') {
            validationErrors();
        }
    }
}

/**
 * Generic http(s) request that can be self-aborted after a specified timeout.
 * @param method
 * @param uri
 * @param params
 * @param https
 * @param timeoutMillisecs
 * @returns {Promise<unknown>}
 */
export const requestAdHoc = (method, url, params = {}) => {
    const headers = {
        Connection: 'keep-alive',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'X-SOURCE': 'Sporttia',
    };

    const METHOD = method.toUpperCase();
    let queryString = '';

    if (METHOD === 'GET' && params) {
        if (Object.keys(params).length > 0) {
            // filter null and undefined param values
            const filteredParams = Object.keys(params).reduce(
                (result, param) =>
                    params[param] !== undefined && params[param] !== null
                        ? {
                              ...result,
                              [param]: params[param],
                          }
                        : result,
                {},
            );

            // create the querystring
            queryString += Object.keys(filteredParams).reduce(
                (result, param, i) =>
                    `${result + (i > 0 ? '&' : '?') + param}=${params[param]}`,
                '',
            );
        }
    }

    return new Promise((resolve, reject) => {
        fetch(url + queryString, {
            ...{
                headers,
                method,
            },
            ...((METHOD === 'POST' || METHOD === 'PUT') &&
                params && { body: JSON.stringify(params) }),
        })
            .then((response) => {
                if (response.status === 200) {
                    try {
                        response.json().then(resolve);
                    } catch (error) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        reject({
                            error: {
                                msg: error,
                            },
                        });
                    }
                } else {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject(`Error ${response.status}`);
                }
            })
            .catch((err) => {
                // Not even a status code
                // eslint-disable-next-line prefer-promise-reject-errors
                reject({
                    error: {
                        msg:
                            err?.message || 'Error de conexión sin especificar',
                        log: JSON.stringify(err),
                    },
                });
            });
    });
};

export const getErrorMessage = (error) =>
    (isObject(error) && typeof error.message === 'string' && error.message) ||
    (isErrorResponse(error) && error.error.msg) ||
    'Error';

export const getObjectWithoutEmptyStrings = (obj) =>
    Object.entries(obj)
        .filter(
            ([, value]) => !(typeof value === 'string' && value.trim() === ''),
        )
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

export const composeFormData = (form) =>
    form.reduce((result, field) => {
        const fieldValue = field.answer ? field.answer.value : '';
        return { ...result, [field.id]: fieldValue };
    }, {});

export const latinize = (string) =>
    string.replace(/[^A-Za-z0-9]/g, (a) => latinizeCharacters[a] || a);

/*
        Función para recoger en un objeto los valores que hayan cambiado con respecto al objeto de izquierda
    */
export const getObjectDiff = (left, right) => {
    const diffObjectKeys = Object.keys(left).filter((leftKey) => {
        if (left[leftKey] !== undefined) {
            if (right[leftKey] === undefined) return true;
            return right[leftKey] !== left[leftKey];
        }
        return false;
    });
    return diffObjectKeys.reduce(
        (diff, key) => ({ ...diff, [key]: left[key] }),
        {},
    );
};

/*
        Función para recoger en un objeto los valores que hayan cambiado con respecto al objeto de izquierda
    */
export const createObjectWithUpdatedFieldsOnly = (left, right) => {
    if (right) {
        return getObjectDiff(left, right);
    }

    return left;
};

export const usePrevAndUpdatedStates = (item) => {
    const [prevState, setPrevState] = useState(item);
    const [updatedState, setUpdatedState] = useState({});

    return {
        setPrevState,
        setUpdatedState,
        prevState,
        updatedState,
    };
};

/**
 * Comprueba si la forma de pago requiere una pasarela de pago.
 * Algunos pagos no se confirman de inmediato, sino después de pasar por la pasarela de pago.
 * La confirmación del pago se recibe, por lo general, en el tpv confirmation, cuando el banco notifica.
 * @param paymentForm Forma de pago.
 * @returns {boolean}
 */
export const isPaymentFormWithGateway = (paymentForm) =>
    paymentForm === constants.payment.paymentForms.tpv.name ||
    paymentForm === constants.payment.paymentForms.zitycard.name;
