import React, { useContext, useEffect, useState } from 'react';
import { Container, Box } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import SttValidatedForm from '../../../../sporttia/SttValidatedForm';
import {
    AppContext,
    SttButton,
    SttFullDialog,
    SttPage,
} from '../../../../sporttia/all';
import {
    SUPERADMIN_PAYMENT,
    SUPERADMIN_PAYMENTS,
    SUPERADMIN_TPV,
} from '../../../../lib/queryKeys';
import usePaymentsService from '../../../../services/PaymentsService';
import useTpvsService from '../../../../services/TpvsService';
import { useLoader } from '../../../../lib/hooks';
import { SttAdminPaymentFormTpv } from '../../../../sporttia/forms/SttAdminPaymentFormTpv';

/**
 * Payment view, contains payment data.
 * @param id Payment ID.
 * @param onClose Function executed when closing the full dialog.
 * @returns {JSX.Element}
 */
export default function AdminPayment({ id, onClose }) {
    const cxt = useContext(AppContext);
    const queryClient = useQueryClient();
    const paymentsService = usePaymentsService();
    const tpvService = useTpvsService();
    const formPayment = useForm();
    const formParent = useForm();
    const formTpv = useForm();
    const [paymentId, setPaymentId] = useState(id);
    const paymentQuery = useQuery(
        [SUPERADMIN_PAYMENT, { id: paymentId }],
        () => paymentsService.get(paymentId, { trash: true }),
        { enabled: !!paymentId && paymentId !== 'create' },
    );
    const tpvQuery = useQuery(
        [SUPERADMIN_TPV, { id: paymentQuery?.data?.payment?.tpv?.id }],
        () =>
            tpvService.get(paymentQuery?.data?.payment?.tpv?.id, {
                trash: true,
            }),
        { enabled: !!paymentQuery?.data?.payment?.tpv?.id },
    );
    const tpvsFromPaymentIdQuery = tpvService.useGetTpvsFromPaymentId({
        paymentId: id,
        trash: true,
    });
    const updatePaymentMutation = useMutation(
        (params) => paymentsService.update(params?.id, params?.params),
        {
            onSuccess: (data) => {
                if (data?.payment) {
                    queryClient.setQueryData(
                        [SUPERADMIN_PAYMENT, { id: paymentId }],
                        data,
                    );
                }

                queryClient.invalidateQueries(SUPERADMIN_PAYMENTS);
            },
        },
    );
    const createTpvConfirmationMutation = useMutation(
        (params) =>
            tpvService.createTpvConfirmations(params?.id, params?.params),
        {
            onSuccess: (data) => {
                if (data?.payment) {
                    queryClient.setQueryData(
                        [
                            SUPERADMIN_TPV,
                            { id: paymentQuery?.data?.payment?.tpv?.id },
                        ],
                        data,
                    );
                }

                queryClient.invalidateQueries(SUPERADMIN_PAYMENTS);
            },
        },
    );
    const updatePaymentRefundMutation = useMutation(
        (params) =>
            paymentsService.updatePaymentsRefund(params?.id, params?.params),
        {
            onSuccess: (data) => {
                if (data?.payment) {
                    queryClient.setQueryData(
                        [SUPERADMIN_PAYMENT, { id: paymentId }],
                        data,
                    );
                }

                queryClient.invalidateQueries(SUPERADMIN_PAYMENTS);
            },
        },
    );
    const [, loader] = useLoader([
        updatePaymentMutation.status,
        createTpvConfirmationMutation.status,
        updatePaymentRefundMutation.status,
    ]);

    /**
     * When the "payment" data is returned by the API, it is set in the form.
     */
    useEffect(() => {
        if (paymentQuery?.status === 'success') {
            formParent.reset({
                id: paymentQuery?.data?.payment?.parent?.id,
            });

            formPayment.reset({
                id: paymentQuery?.data?.payment?.id,
                created: paymentQuery?.data?.payment?.created,
                status: paymentQuery?.data?.payment?.status,
                paymentForm: paymentQuery?.data?.payment?.paymentForm,
                user: `${paymentQuery?.data?.payment?.id} - ${paymentQuery?.data?.payment?.name}`,
                concept: paymentQuery?.data?.payment?.concept,
                price: paymentQuery?.data?.payment?.price,
                paymentDate: paymentQuery?.data?.payment?.paymentDate,
            });
        } else {
            formParent.reset({
                id: '',
            });

            formPayment.reset({
                id: '',
                created: '',
                status: '',
                paymentForm: '',
                user: '',
                concept: '',
                price: 0,
                paymentDate: new Date(),
            });
        }
    }, [
        paymentQuery?.data?.payment,
        paymentQuery?.status,
        formParent,
        formPayment,
    ]);

    /**
     * When the "tpv" data is returned by the API, it is set in the form.
     */
    useEffect(() => {
        if (tpvQuery?.status === 'success') {
            formTpv.reset({
                id: tpvQuery?.data?.tpv?.id,
                status: tpvQuery?.data?.tpv?.status,
                result: tpvQuery?.data?.tpv?.result,
            });
        } else {
            formTpv.reset({
                id: '',
                status: '',
                result: '',
            });
        }
    }, [tpvQuery?.data?.tpv, tpvQuery?.status, formTpv]);

    /**
     * Update payment id if id prop was changed.
     */
    useEffect(() => {
        setPaymentId(id);
    }, [id]);

    return (
        <SttFullDialog
            open={id !== null}
            onClose={onClose}
            title={cxt.t('Payment')}
        >
            {loader}
            <Container>
                {paymentQuery?.data?.payment && (
                    <Box mt={2}>
                        <SttPage innerTitle={cxt.t('Payment')}>
                            <SttValidatedForm
                                form={formPayment}
                                loadingData={paymentQuery?.status === 'loading'}
                                fields={[
                                    {
                                        type: 'label',
                                        caption: 'Id',
                                        name: 'id',
                                        md: 2,
                                    },
                                    {
                                        type: 'label',
                                        caption: cxt.t('Created'),
                                        name: 'created',
                                        md: 2,
                                    },
                                    {
                                        type: 'label',
                                        caption: cxt.t('Status'),
                                        name: 'status',
                                        md: 1,
                                    },
                                    {
                                        type: 'label',
                                        caption: cxt.t('paymentForm'),
                                        name: 'paymentForm',
                                        md: 2,
                                    },
                                    {
                                        type: 'label',
                                        caption: cxt.t('User'),
                                        name: 'user',
                                        md: 3,
                                    },
                                    {
                                        type: 'textArea',
                                        name: 'concept',
                                        caption: cxt.t('Concept'),
                                        disabled:
                                            paymentQuery?.data?.payment
                                                ?.status === 'PAYING',
                                        rows: 3,
                                    },
                                    {
                                        type: 'price',
                                        name: 'price',
                                        caption: cxt.t('Price'),
                                        disabled:
                                            paymentQuery?.data?.payment
                                                ?.status === 'PAYING',
                                        md: 3,
                                    },
                                    {
                                        type: 'date',
                                        name: 'paymentDate',
                                        caption: cxt.t('PaymentDate'),
                                        disabled:
                                            paymentQuery?.data?.payment
                                                ?.status === 'PAYING',
                                        md: 3,
                                    },
                                ]}
                                buttons={[
                                    {
                                        type: 'save',
                                        show:
                                            paymentQuery?.data?.payment
                                                ?.status !== 'PAYING',
                                        onClick: formPayment.handleSubmit(
                                            (formData) =>
                                                updatePaymentMutation.mutate({
                                                    id: paymentId,
                                                    params: {
                                                        concept:
                                                            formData?.concept,
                                                        price: formData?.price,
                                                        paymentDate:
                                                            formData?.paymentDate,
                                                    },
                                                }),
                                        ),
                                    },
                                    {
                                        type: 'component',
                                        show:
                                            paymentQuery?.data?.payment
                                                ?.paymentForm === 'TPV' &&
                                            paymentQuery?.data?.payment
                                                ?.status === 'PAID',
                                        component: (
                                            <SttButton
                                                caption={cxt.t('Refund')}
                                                icon={
                                                    <SettingsBackupRestoreIcon />
                                                }
                                                onClick={() =>
                                                    updatePaymentRefundMutation.mutate(
                                                        {
                                                            id: paymentId,
                                                            params: {
                                                                paymentForm:
                                                                    'TPV',
                                                            },
                                                        },
                                                    )
                                                }
                                                confirmation
                                            />
                                        ),
                                    },
                                    {
                                        type: 'component',
                                        show:
                                            !paymentQuery?.data?.payment
                                                ?.parent &&
                                            paymentQuery?.data?.payment
                                                ?.paymentForm === 'FREE' &&
                                            paymentQuery?.data?.payment
                                                ?.status === 'PAID',
                                        component: (
                                            <SttButton
                                                caption={cxt.t('ConvertToTPV')}
                                                icon={<CompareArrowsIcon />}
                                                onClick={() =>
                                                    updatePaymentMutation.mutate(
                                                        {
                                                            id: paymentId,
                                                            params: {
                                                                paymentForm:
                                                                    'TPV',
                                                            },
                                                        },
                                                    )
                                                }
                                                confirmation
                                            />
                                        ),
                                    },
                                ]}
                            />
                        </SttPage>
                    </Box>
                )}

                {paymentQuery?.data?.payment?.parent?.id && (
                    <Box mt={2}>
                        <SttPage innerTitle={cxt.t('Father')}>
                            <SttValidatedForm
                                form={formParent}
                                loadingData={paymentQuery?.status === 'loading'}
                                fields={[
                                    {
                                        type: 'label',
                                        caption: 'Id',
                                        name: 'id',
                                        md: 2,
                                    },
                                ]}
                            />
                        </SttPage>
                    </Box>
                )}
                {tpvsFromPaymentIdQuery?.data?.rows?.length > 0 && (
                    <Box mt={2}>
                        <SttPage innerTitle="Tpvs">
                            {tpvsFromPaymentIdQuery?.data?.rows.map(
                                (currentTpv) => (
                                    <SttAdminPaymentFormTpv
                                        key={currentTpv?.id}
                                        tpv={currentTpv}
                                        getTpvsQueryKey={
                                            tpvsFromPaymentIdQuery.queryKey
                                        }
                                    />
                                ),
                            )}
                        </SttPage>
                    </Box>
                )}
            </Container>
        </SttFullDialog>
    );
}
