import Error from './models/Error';

// TODO: Mover a su archivo correspondiente después de reestructurar el proyecto o migrar a utils.ts

export const isObject = (object: unknown): object is Record<string, unknown> =>
    typeof object === 'object' && object !== null;

// TODO: Mover a su archivo correspondiente después de reestructurar el proyecto o migrar a utils.ts

export const isErrorResponse = (error: unknown): error is Error =>
    isObject(error) &&
    isObject(error.error) &&
    typeof error.error.msg === 'string';

export type SocketMessage = {
    access?: {
        type: string;
    };
    mship?: {
        id: string;
    };
};

export const toLowerCase = <T extends string>(x: T) =>
    x.toLowerCase() as Lowercase<T>;
