import React, { useState } from 'react';
import { Box, Divider, makeStyles, Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import GolfBookingCard from './GolfBookingsCard';
import GolfTimetableControls from '../../sc/golfTimetable/GolfTimetableControls';
import GolfBookingCardDialog from './GolfBookingCardDialog';

/**
 * Draw golf cards.
 * @returns {JSX.Element}
 */
export default function GolfBookingsCards({}) {
    const classes = useStyles();
    const form = useForm();
    const [bookingId, setBookingId] = useState(null);

    {
        /* Render golf cards. */
    }
    return (
        <>
            <Paper>
                <GolfTimetableControls form={form} />
                <Box mb={2}>
                    <Divider />
                </Box>
                <Box p={2}>
                    <div className={classes.timetable}>
                        <Box className={classes.timetableBox}>
                            <Box
                                display="flex"
                                flexWrap="wrap"
                                justifyContent="flex-start"
                                alignContent="flex-start"
                            >
                                {bookingsMockUpData.rows.map((booking) => (
                                    <GolfBookingCard
                                        booking={booking}
                                        onSelect={() =>
                                            setBookingId(booking.id)
                                        }
                                    />
                                ))}
                            </Box>
                        </Box>
                    </div>
                </Box>
            </Paper>
            <GolfBookingCardDialog
                id={bookingId}
                onClose={() => setBookingId(null)}
            />
        </>
    );
}

const useStyles = makeStyles(() => ({
    timetable: {
        height: '75vh',
        margin: 0,
        padding: 0,
    },
    timetableBox: {
        maxHeight: '100%',
        overflowX: 'auto',
        overflowY: 'auto',
    },
}));

const bookingMockUpData1 = {
    id: 10,
    time: '09:00',
    golfCartAvailability: 2,
    players: [
        {
            id: 0,
            name: 'Jugador 0',
        },
        {
            id: 1,
            name: 'Jugador 1',
        },
        {
            id: 2,
            name: 'Jugador 2',
        },
        {
            id: 3,
            name: 'Jugador 3',
        },
    ],
    fees: [
        {
            holes: 18,
            price: 100,
        },
        {
            holes: 9,
            price: 50,
        },
    ],
};

const bookingMockUpData2 = {
    id: 11,
    time: '10:00',
    golfCartAvailability: 2,
    players: [
        {
            id: 0,
            name: 'Jugador 0',
        },
        {
            id: 1,
            name: 'Jugador 1',
        },
        {
            id: 2,
            name: 'Jugador 2',
        },
    ],
    fees: [
        {
            holes: 18,
            price: 100,
        },
        {
            holes: 9,
            price: 50,
        },
    ],
};

const bookingMockUpData3 = {
    id: 11,
    time: '11:00',
    golfCartAvailability: 2,
    players: [],
    fees: [
        {
            holes: 18,
            price: 100,
        },
        {
            holes: 9,
            price: 50,
        },
    ],
};

const bookingsMockUpData = {
    count: 10,
    rows: [
        bookingMockUpData1,
        bookingMockUpData2,
        bookingMockUpData3,
        bookingMockUpData3,
        bookingMockUpData3,
        bookingMockUpData3,
        bookingMockUpData3,
        bookingMockUpData3,
        bookingMockUpData3,
        bookingMockUpData3,
    ],
};
