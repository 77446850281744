import React, { useContext } from 'react';
import { InputAdornment } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import Discount from '../../../types/models/Discount';
import { AppContext, SttDialog } from '../../../sporttia/all';
import { getErrorMessage } from '../../../lib/utils';
import { useLoader } from '../../../lib/hooks';
import SttValidatedForm from '../../../sporttia/SttValidatedForm';
import useDiscountService from '../../../services/DiscountsService';
import useScsService from '../../../services/ScsService';

type DiscountEditDialogProps = {
    discount: Discount | undefined;
    onUpdate?: (discount: Discount) => void;
    onSave?: (discount: Discount) => void;
    onDelete?: (discount: Discount) => void;
    onClose?: () => void;
};

export default function DiscountEditDialog({
    discount,
    onUpdate,
    onSave,
    onDelete,
    onClose,
}: DiscountEditDialogProps) {
    const cxt = useContext(AppContext)!;
    const form = useForm({
        defaultValues: {
            name: discount?.name,
            discountPercentage: discount?.discountPercentage,
        },
    });

    const discountService = useDiscountService();
    const scsService = useScsService();

    const createScsDiscountMutation = scsService.useCreateDiscount({
        onSuccess: (data) => {
            onSave?.(data.discount);
            cxt.showMessage('S', cxt.t('discountCreated'));
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });

    const updateDiscountMutation = discountService.useUpdateDiscount({
        onSuccess: (data) => {
            onUpdate?.(data.discount);
            cxt.showMessage('S', cxt.t('discountUpdated'));
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });

    const deleteDiscountMutation = discountService.useDeleteDiscount({
        onSuccess: (data) => {
            onDelete?.(data.discount);
            cxt.showMessage('S', cxt.t('discountDeleted'));
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });

    const handleOnSubmit = () => {
        form.handleSubmit(({ name, discountPercentage }) => {
            if (name === undefined || discountPercentage === undefined) {
                return;
            }

            const params = {
                name,
                discountPercentage,
            };

            if (discount) {
                updateDiscountMutation.mutate({
                    id: discount.id,
                    params,
                });
            } else {
                createScsDiscountMutation.mutate({
                    id: cxt.sc!.id,
                    params,
                });
            }
        })();
    };

    const handleOnDelete = () => {
        if (!discount) {
            return;
        }

        deleteDiscountMutation.mutate(discount.id);
    };

    const [isMutationsLoading, mutationsLoader] = useLoader([
        createScsDiscountMutation.status,
        updateDiscountMutation.status,
        deleteDiscountMutation.status,
    ]);

    if (isMutationsLoading) {
        return mutationsLoader;
    }

    return (
        <SttDialog
            title={discount ? discount.name : cxt.t('newDiscount')}
            open
            onClose={onClose}
            content={
                <SttValidatedForm
                    form={form}
                    containerProps={{
                        spacing: 0,
                    }}
                    fields={[
                        {
                            type: 'textInput',
                            name: 'name',
                            caption: cxt.t('name'),
                            rules: {
                                required: true,
                            },
                        },
                        {
                            type: 'numberInput',
                            name: 'discountPercentage',
                            InputProps: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        %
                                    </InputAdornment>
                                ),
                            },
                            rules: {
                                required: true,
                            },
                            xs: 3,
                        },
                    ]}
                />
            }
            buttons={[
                {
                    type: 'save',
                    onClick: () => handleOnSubmit(),
                },
                {
                    show: !!discount && !discount.trash,
                    type: 'delete',
                    onClick: () => handleOnDelete(),
                },
            ]}
        />
    );
}
