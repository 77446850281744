import { useContext } from 'react';
import {
    MutationFunction,
    UseMutationOptions,
    UseQueryOptions,
    useMutation,
    useQuery,
} from 'react-query';
import { useAPI } from '../lib/hooks';
import { AppContext } from '../sporttia/AppContext';
import {
    GetEventTeam200,
    GetEventTeamQueryParams200,
} from '../types/api/paths/EventTeams';

const resource = '/events';

/**
 * Hook to access all 'events' resources.
 */
export default function useEventsService() {
    const { migrationApis } = useContext(AppContext)!;
    const [request] = useAPI(migrationApis);

    /**
     * PUT /events/participants/:id request.
     */
    function useUpdateParticipant(
        mutationOptions?: UseMutationOptions<
            unknown,
            unknown,
            {
                participantId: number;
                params: Record<string, unknown>;
            }
        >,
    ) {
        const mutationFn: MutationFunction<
            unknown,
            {
                participantId: number;
                params: Record<string, unknown>;
            }
        > = ({ participantId, params }) =>
            request('PUT', `${resource}/participants/${participantId}`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * DELETE /events/participants/:id request.
     */
    function useDeleteParticipant(
        mutationOptions?: UseMutationOptions<
            unknown,
            unknown,
            { participantId: number }
        >,
    ) {
        const mutationFn: MutationFunction<
            unknown,
            { participantId: number }
        > = ({ participantId }) =>
            request('DELETE', `${resource}/participants/${participantId}`);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * POST /events/categories/:categoryId/participants request.
     */
    function useCreateEventCategoryParticipant(
        mutationOptions?: UseMutationOptions<
            unknown,
            unknown,
            {
                categoryId: number;
                params: Record<string, unknown>;
            }
        >,
    ) {
        const mutationFn: MutationFunction<
            unknown,
            {
                categoryId: number;
                params: Record<string, unknown>;
            }
        > = ({ categoryId, params }) =>
            request(
                'POST',
                `${resource}/categories/${categoryId}/participants`,
                params,
            );

        return useMutation(mutationFn, mutationOptions);
    }

    // GET events/categories/:id/teams

    function useGetEventTeamsByCategoryId<TData = GetEventTeam200>(
        id: number,
        params?: GetEventTeamQueryParams200,
        queryOptions?: UseQueryOptions<GetEventTeam200, unknown, TData>,
    ) {
        const queryKey = queryOptions?.queryKey ?? [
            `${resource}/${id}/discounts`,
            ...(params ? [params] : []),
        ];
        const queryFn = () =>
            request<GetEventTeam200>(
                'GET',
                `${resource}/categories/${id}/teams`,
                params,
            );

        const query = useQuery(queryKey, queryFn, queryOptions);

        return {
            ...query,
            queryKey,
        };
    }

    return {
        useUpdateParticipant,
        useDeleteParticipant,
        useCreateEventCategoryParticipant,
        useGetEventTeamsByCategoryId,
    };
}
