import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext, SttTabs, SttFullDialog } from '../../../sporttia/all';
import HandlePaymentsModal from '../../../pages/sc/groups/HandlePaymentsModal';
import BookingDialogDetail from './BookingDialogDetail';
import BookingDialogPayments from './BookingDialogPayments';
import BookingDialogOccupants from './BookingDialogOccupants';
import { getPath } from '../../../pages/Pages';
import BookingDialogDeviceAuths from './BookingDialogDeviceAuths';
import { parsePrice } from '../../../lib/utils';
import { useInteractionsFiles } from '../../../lib/hooks';

/**
 *	Booking dialog
 *	==============
 *
 *	Cuando se abre el popup se carga el alquiler.
 *
 *	Props
 *
 *		* integer idBooking
 *		* function onClose
 *		* funcion onUpdate(booking): if the booking has been updated (deleted also)
 */
export default function BookingDialog({
    idBooking,
    onClose,
    onUpdate,
    cutBooking = null,
}) {
    const cxt = useContext(AppContext);
    const { openFile, downloadFile } = useInteractionsFiles();
    const history = useHistory();
    const [booking, setBooking] = useState();
    const [payments, setPayments] = useState();
    const [bonoMovs, setBonoMovs] = useState();
    const [onlyBonos, setOnlyBonos] = useState(false);
    const [openAskingPaymentProcedure, setOpenAskingPaymentProcedure] =
        useState(false);

    useEffect(() => {
        if (payments && bonoMovs && payments.count <= 0 && bonoMovs.count > 0)
            setOnlyBonos(true);
        else setOnlyBonos(false);
    }, [payments, bonoMovs]);

    /**
     * Load booking
     */
    const loadBooking = () => {
        cxt.api('GET', `/bookings/${idBooking}`, {
            params: {
                trash: true,
            },
            success: (r) => {
                setBooking(r.booking);
            },
        });
    };

    /**
     * Load payments
     */
    const loadPayments = () => {
        cxt.api('GET', `/bookings/${idBooking}/payments`, {
            params: { page: 1, rows: 100, trash: false },
            success: (r) => {
                setPayments(r);
            },
        });
    };

    /**
     * Load bono movs
     */
    const loadBonoMovs = () => {
        cxt.api('GET', `/bookings/${idBooking}/bonos/movs`, {
            params: { page: 1, rows: 100, trash: false },
            success: (r) => {
                setBonoMovs(r);
            },
        });
    };

    /**
     * Init
     */
    useEffect(() => {
        if (idBooking) {
            loadBooking();
            loadPayments();
            loadBonoMovs();
        } else setBooking(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idBooking]);

    /**
     * Save
     */
    const save = () => {
        cxt.api('PUT', `/bookings/${booking.id}`, {
            params: {
                ini: booking?.ini,
                end: booking?.end,
                idFacility: booking?.facility?.id ?? undefined,
                name: booking?.name,
                notes: booking?.notes,
                price: parsePrice(booking.price),
            },
            success: (r) => {
                cxt.showMessage('S', cxt.t('Saved'));
                if (onUpdate) onUpdate(r.booking);
            },
        });
    };

    /**
     * Cancel booking
     */
    const cancelBooking = (deletingPaymentProcedure) => {
        let params;
        if (deletingPaymentProcedure !== undefined) {
            params = {
                paymentAction: deletingPaymentProcedure,
            };
        }
        cxt.api('DELETE', `/bookings/${idBooking}`, {
            params,
            success: (r) => {
                onClose();
                setOpenAskingPaymentProcedure(false);
                if (onUpdate) onUpdate(r.booking);
                cxt.showMessage('S', cxt.t('Deleted'));
            },
        });
    };

    /**
     * Download
     */
    const download = (type) => {
        let funcToUse = downloadFile;
        if (type === 'TICKET') {
            funcToUse = openFile;
        }

        funcToUse(
            `/bookings/${idBooking}.pdf?paper=${type}`,
            'application/pdf',
            null,
            'booking.pdf',
        );
    };

    // TODO: mejorar la porquería esta de pedirlo todo en cuanto hay un cambio local

    // He booking has already been cancelled server-side
    function lastOccupantDeleted() {
        if (onUpdate) onUpdate();
        if (onClose) onClose();
    }

    // -----| Render |-----
    return (
        <>
            <SttFullDialog
                open={Boolean(booking && booking.id)}
                onClose={onClose}
                title={cxt.t('Booking')}
                bottomButtons={[
                    {
                        caption: cxt.t('Booking'),
                        onClick: () =>
                            history.push(getPath('booked', { id: booking.id })),
                    },
                    {
                        show:
                            booking &&
                            Object.prototype.hasOwnProperty.call(
                                booking,
                                'slot',
                            ),
                        caption: cxt.t('GoToBooking'),
                        onClick: () =>
                            history.push(
                                getPath('bookingSchedule', {
                                    id: booking.slot.schedule.id,
                                }),
                            ),
                    },
                    {
                        show: cutBooking != null,
                        caption: cxt.t('Cut'),
                        onClick: () => cutBooking(booking),
                    },
                    {
                        type: 'delete',
                        caption: cxt.t('CancelBooking'),
                        onClick: () => {
                            setOpenAskingPaymentProcedure(!onlyBonos);
                            if (onlyBonos) cancelBooking();
                        },
                    },
                    {
                        type: 'save',
                        onClick: save,
                    },
                ]}
                menu={[
                    {
                        caption: `${cxt.t('Download')} PDF`,
                        onClick: () => download(),
                    },
                    {
                        caption: cxt.t('Ticket'),
                        onClick: () => download('TICKET'),
                    },
                ]}
            >
                {booking && (
                    <SttTabs
                        tabs={[
                            {
                                caption: cxt.t('Detail'),
                                component: (
                                    <BookingDialogDetail
                                        booking={booking}
                                        setBooking={setBooking}
                                        onUpdate={onUpdate}
                                    />
                                ),
                            },
                            {
                                caption: `${cxt.t('Payments')} & ${cxt.t(
                                    'Bonos',
                                )}`,
                                component: (
                                    <BookingDialogPayments
                                        booking={booking}
                                        onUpdate={onUpdate}
                                    />
                                ),
                            },
                            {
                                caption: cxt.t('Occupants'),
                                component: (
                                    <BookingDialogOccupants
                                        booking={booking}
                                        onUpdate={onUpdate}
                                        onLastOccupantDeleted={
                                            lastOccupantDeleted
                                        }
                                    />
                                ),
                            },
                            {
                                caption: cxt.t('DeviceAuths'),
                                component: (
                                    <BookingDialogDeviceAuths
                                        booking={booking}
                                    />
                                ),
                            },
                        ]}
                    />
                )}
            </SttFullDialog>
            {/* <SttDialog
				title={cxt.t('Booking')}
				maxWidth="md"
				open={Boolean(booking && booking.id)}
				onClose={onClose}
				content={
					<>
						{booking &&
						<SttTabs
							tabs={[
								{
									caption: cxt.t('Detail'),
									component:
										<BookingDialogDetail
											booking={booking}
											setBooking={setBooking}
											onUpdate={onUpdate}
										/>
								},
								{
									caption: `${cxt.t('Payments')} & ${cxt.t('Bonos')}`,
									component:
										<BookingDialogPayments
											booking={booking}
											onUpdate={onUpdate}
										/>
								},
								{
									caption: cxt.t('Occupants'),
									component:
										<BookingDialogOccupants
											booking={booking}
											onUpdate={onUpdate}
											onLastOccupantDeleted={lastOccupantDeleted}
										/>
								},
								{
									caption: cxt.t('DeviceAuths'),
									component:
										<BookingDialogDeviceAuths
											booking={booking}
										/>
								},
							]}
						/>}
					</>
				}
				buttons={[
					{
						caption: cxt.t('Booking'),
						onClick: () => history.push( getPath('booked', {id: booking.id}) )
					},
					booking && booking.slot && {
						caption: cxt.t('GoToBooking'),
						onClick: () => history.push( getPath('bookingSchedule', {id: booking.slot.schedule.id}) )
					},
					{
						show: cutBooking != null,
						caption: cxt.t('Cut'),
						onClick: () => cutBooking(booking)
					},
					{
						type: 'delete',
						caption: cxt.t('CancelBooking'),
						onClick: () => {setOpenAskingPaymentProcedure(!onlyBonos); if (onlyBonos) cancelBooking();}
					},
					{
						type: 'save',
						onClick: save
					}
				]}
				menu={[
					{
						caption: cxt.t('Download') + ' PDF',
							onClick: () => download(),
					},
					{
						caption: cxt.t('Ticket'),
						onClick: () => download('TICKET'),
					}
				]}
			/> */}

            {/* --- Modal asking what to do with paymtns --- */}
            <HandlePaymentsModal
                warningText={cxt.t('sc.bookings.delete.howToHandlePayments')}
                open={openAskingPaymentProcedure}
                onClose={() => setOpenAskingPaymentProcedure(false)}
                onAccept={(option) => {
                    /* JPB 9 Jul 2020. Deleting action codes in groups is
						different than in other in booking. */

                    switch (option) {
                        case '0':
                            cancelBooking();
                            break; // PBM: 'do nothing' case was missing
                        case '1':
                            cancelBooking(4);
                            break;
                        case '2':
                            cancelBooking(5);
                            break;
                        case '3':
                            cancelBooking(2);
                            break;
                        case '4':
                            cancelBooking(3);
                            break;
                        default:
                            break;
                    }
                }}
            />
        </>
    );
}
