import React, { useState, useContext, useEffect } from 'react';
import {
    Box,
    Grid,
    MenuItem,
    IconButton,
    Menu,
    Divider,
    ListItemIcon,
    FormControlLabel,
    Switch,
} from '@material-ui/core';
import {
    MoreVert as MoreIcon,
    FilterList as FilterListIcon,
} from '@material-ui/icons';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Typography from '@material-ui/core/Typography';
import { AppContext } from './AppContext';
import FilterDialog from '../components/filter/FilterDialog';
import { SttButtonDelete, SttButtonNew, SttFormSelect } from './all';
import FilterFields from '../components/filter/FilterFields';
import { useToggle } from '../lib/hooks';

const FIELD_CHECKED_VALUES = ['check', 'switch'];
const RENDER_AFTER_CHANGE_FILTERS = ['select', 'check', 'switch'];

/**
 * Si el campo forma parte de la lista de campos que usa "checked" en vez de "value" se devuelve "checked" y si no "value".
 * @param {*} field
 * @returns
 */
const fieldCheckedToValue = (field) =>
    FIELD_CHECKED_VALUES.includes(field.type) ? field.checked : field.value;

/**
 * Componente filtrar tablas que suelen aparecer en la parte superior de las paginas con tablas.
 * La diferencia con el antiguo SttTopControls es que ahora se gestiona internamente el filtrado y cuando
 * se finaliza el filtrado se ejecuta onFilter que devolvera los filtros finales que se deben enviar como
 * parametros en la peticion a la API.
 * @param fields Campos para pintar (y filtrar). Ver tipos aceptados en /components/filter/FilterFields.js.
 * @param extraFields Se usarán para rellenar un modal con campos de filtro.
 * @param mainAction Algunas pantallas pueden un botón por defecto que en general es el de añadir depósito, etc.
 *        - onClick
 *        - component
 *        - caption
 *        - options
 *        - type: diferentes posibilidades dependiendo de mainAction.type
 *        - create
 *            - onClick: callback para el botón
 *        - select
 *            - options: opciones para el select
 *            - selected: opción seleccionada por defecto en el select
 *            - onChange: callback para el select
 *        - delete
 *        - component: render component in mainAction.component
 * @param menu Muestra los "3 puntos" para abrir un menú con opciones. Cada compnente del menú tiene:
 *        - boolean show
 *        - string caption
 *        - function onClick
 *        - Component icon
 *        - boolean divider: si está establecido pondrá un <Divider /> justo arriba del item.
 * @param menuIcon Icono opcional para el menú desplegable ("3 puntos" por defecto).
 * @param filterButton Enseña un boton de filtrado.
 *        - onClick
 * @param downloadButton Enseña un boton de descarga.
 *        - onClick
 * @param iconButtons Muestra un botones en formato icono que realiza una función, es un array con objetos que contienen:
 *        - icon
 *        - onClick
 *        - caption
 * @param trashAction Por defecto esta desactivado (false), cuando se activa aparece un toggle button para activar o desactivar el filtro trash de la api.
 * @param onFilter Función ejecutada al finalizar filtrado, esto suele ser en los casos en los que se acepta el modal de filtrado, se pulsar en buscar, se cambiar selector...
 * @param skipFirstFiltering Para evitar el primer filtrado por defecto.
 * @param rest
 * @returns {JSX.Element}
 */
export function SttTopFilteringControls({
    fields = [],
    extraFields = [],
    mainAction,
    menu,
    menuIcon,
    filterButton,
    downloadButton,
    iconButtons,
    trashAction,
    onFilter,
    skipFirstFiltering = false,
    ...rest
}) {
    const cxt = useContext(AppContext);
    const [buttonMenuDOMiObject, setButtonMenuDOMObject] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [internalFields, setInternalFields] = useState(fields);
    const [internalExtraFields, setInternalExtraFilters] =
        useState(extraFields);
    const [trash, toggleTrash] = useToggle(() => {
        // eslint-disable-next-line no-use-before-define
        doFiltering();
    }, false);

    // Setup relative sizes of the controls
    const extra =
        extraFields ||
        filterButton ||
        downloadButton ||
        menu ||
        trashAction ||
        iconButtons;
    let sizes;
    if (fields && extra) {
        // Everything's up
        sizes = {
            fields: {
                xs: 12,
                md: 8,
                lg: 10,
            },
            extra: {
                xs: 12,
                md: 4,
                lg: 2,
            },
        };
    } else {
        sizes = {
            fields: {
                xs: 12,
            },
        };
    }

    /**
     * Funcion que se ejecuta cuando se acepta el modal de filtrado, se pulsa en buscar... Es decir, cuando se quiere realizar
     * el filtrado final.
     */
    function doFiltering() {
        const updatedFilters = {};
        if (internalFields) {
            internalFields.forEach((field) => {
                updatedFilters[field.name] = fieldCheckedToValue(field);
            });
        }

        if (internalExtraFields) {
            internalExtraFields.forEach((field) => {
                // Chapuza para FromSelectPeriod porque no tiene 'name'
                if (field.selectName && field.nameIni && field.nameEnd) {
                    updatedFilters[field.selectName] = field.selectValue;
                    updatedFilters[field.nameIni] = field.valIni;
                    updatedFilters[field.nameEnd] = field.valEnd;
                } else {
                    updatedFilters[field.name] = fieldCheckedToValue(field);
                }
            });
        }
        if (trashAction) {
            updatedFilters.trash = trash;
        }

        onFilter(updatedFilters);
    }

    useEffect(() => {
        if (onFilter && !skipFirstFiltering) {
            doFiltering();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            extraFields &&
            extraFields.length > 0 &&
            internalExtraFields &&
            internalExtraFields.length <= 0
        ) {
            setInternalExtraFilters(extraFields);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extraFields]);

    /**
     * Function that is executed when the filtering modal is accepted, you click on search ...
     * That is, when you want to perform the final filtering.
     * @param fieldsParams Fields params.
     * @param extraFieldsParams Extra fields params.
     */
    function doFilteringParams(fieldsParams, extraFieldsParams) {
        const updatedFilters = {};
        if (fieldsParams) {
            fieldsParams.forEach((field) => {
                updatedFilters[field.name] = fieldCheckedToValue(field);
            });
        }

        if (extraFieldsParams) {
            extraFieldsParams.forEach((field) => {
                updatedFilters[field.name] = fieldCheckedToValue(field);
            });
        }

        if (trashAction) {
            updatedFilters.trash = trash;
        }

        onFilter(updatedFilters);
    }

    /**
     * Función ejecutada cuando se modifican los valores de filtrado, son valores que solo se usan de manera interna
     * en el componente.
     * @param name Clave del filtro a modificar.
     * @param value Valor que se va a modificar.
     */
    function onChange({ name, value }) {
        const updatedFields = [...internalFields];
        const updatedExtraFields = [...internalExtraFields];
        const internalFieldsIndexToChange = internalFields.findIndex(
            (field) => field.name === name,
        );
        const internalExtraFieldsIndexToChange = internalExtraFields.findIndex(
            (field) => field.name === name,
        );

        if (internalFieldsIndexToChange >= 0) {
            if (
                FIELD_CHECKED_VALUES.includes(
                    updatedFields[internalFieldsIndexToChange].type,
                )
            ) {
                updatedFields[internalFieldsIndexToChange].checked = value;
            } else {
                updatedFields[internalFieldsIndexToChange].value = value;
            }

            setInternalFields(updatedFields);
        }

        if (internalExtraFieldsIndexToChange >= 0) {
            if (
                FIELD_CHECKED_VALUES.includes(
                    updatedExtraFields[internalExtraFieldsIndexToChange].type,
                )
            ) {
                updatedExtraFields[internalExtraFieldsIndexToChange].checked =
                    value;
            } else {
                updatedExtraFields[internalExtraFieldsIndexToChange].value =
                    value;
            }

            setInternalExtraFilters(updatedExtraFields);
        }

        // Esto es una chapuza mientras no migremos a react-hook-forms este componente para que el selector
        // haga la petición de filtrado cuando se modifique su valor ya que no podemos esperar a que la
        // actualización asincrona del estado de internalFields termine y luego llamar a onFilter.
        if (
            internalFieldsIndexToChange >= 0 &&
            RENDER_AFTER_CHANGE_FILTERS.includes(
                updatedFields[internalFieldsIndexToChange].type,
            )
        ) {
            doFilteringParams(updatedFields, updatedExtraFields);
        }

        // Chapuza para FromSelectPeriod porque no tiene 'name'
        if (
            internalFieldsIndexToChange < 0 &&
            internalExtraFieldsIndexToChange < 0
        ) {
            const selectIndex = internalExtraFields.findIndex(
                (field) => field.selectName === name,
            );

            if (selectIndex >= 0) {
                updatedExtraFields[selectIndex].selectValue = value;
                setInternalExtraFilters(updatedExtraFields);
                return;
            }

            const dateIniIndex = internalExtraFields.findIndex(
                (field) => field.nameIni === name,
            );

            if (dateIniIndex >= 0) {
                updatedExtraFields[dateIniIndex].valIni = value;
                setInternalExtraFilters(updatedExtraFields);
                return;
            }

            const dateEndIndex = internalExtraFields.findIndex(
                (field) => field.nameEnd === name,
            );

            if (dateEndIndex >= 0) {
                updatedExtraFields[dateEndIndex].valEnd = value;
                setInternalExtraFilters(updatedExtraFields);
            }
        }
    }

    /**
     * Render main action button
     * @returns {*}
     */
    function renderMainAction() {
        let content;

        if (mainAction) {
            switch (mainAction.type) {
                case 'create':
                    content = (
                        <SttButtonNew
                            caption={mainAction.caption}
                            onClick={mainAction.onClick}
                        />
                    );
                    break;
                case 'delete':
                    content = <SttButtonDelete onClick={mainAction.onClick} />;
                    break;
                case 'select':
                    content = (
                        <SttFormSelect
                            options={mainAction.options}
                            onChange={({ value }) => mainAction.onChange(value)}
                            defVal={mainAction.selected}
                        />
                    );
                    break;
                case 'component':
                    content = mainAction.component;
                    break;
                default:
            }
        }
        return content;
    }

    /**
     * Render fields.
     * @param gridSizes Controls size.
     * @returns {JSX.Element}
     */
    function renderDefaultFields(gridSizes) {
        if (internalFields) {
            let content;

            // If we only specify a single text field to filter by default, we turn it into a search form text field
            if (
                internalFields.length === 1 &&
                internalFields[0].type === 'text'
            ) {
                content = (
                    <FilterFields
                        spacing={3}
                        onChange={onChange}
                        fields={[
                            {
                                ...internalFields[0],
                                type: 'search',
                                onEnterKey: () => doFiltering(),
                            },
                        ]}
                        action={renderMainAction()}
                    />
                );
            } else {
                content = (
                    <FilterFields
                        spacing={3}
                        onChange={onChange}
                        fields={internalFields.map((field) =>
                            ['search', 'text'].includes(field.type)
                                ? { ...field, onEnterKey: doFiltering }
                                : field,
                        )}
                        action={renderMainAction()}
                    />
                );
            }

            return (
                <Grid item {...gridSizes}>
                    {content}
                </Grid>
            );
        }
    }

    /**
     * Render extra filters.
     * @param gridSizes Controls size.
     * @returns {JSX.Element}
     */
    function renderExtra(gridSizes) {
        // Extra components container should grow to take all available space (but should it really?)
        const mustGrow = true; // onToggleTrash && !(extraFields || filterButton || downloadButton || menu)

        return (
            <Grid item {...gridSizes} style={mustGrow ? { flexGrow: 1 } : {}}>
                <Box display="flex" justifyContent="flex-end">
                    {/* --- Trash --- */}
                    {trashAction && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={
                                        undefined /* We don't care about this value because the switch has its own state and this will always be false first */
                                    }
                                    onChange={(ev) =>
                                        toggleTrash(ev.target.checked)
                                    }
                                />
                            }
                            label={cxt.t('Trash')}
                        />
                    )}

                    {/* --- Popup filter --- */}
                    {!!internalExtraFields.length && (
                        <IconButton onClick={() => setDialogOpen(true)}>
                            <FilterListIcon />
                        </IconButton>
                    )}

                    {filterButton /* Solo se usa en Report y Mship; TODO: Unificar esos 2 componentes y eliminar esto */ && (
                        <IconButton onClick={filterButton.onClick}>
                            <FilterListIcon />
                        </IconButton>
                    )}

                    {downloadButton /* Solo se usa en Report y Mship; TODO: Unificar esos 2 componentes y eliminar esto */ && (
                        <IconButton onClick={downloadButton.onClick}>
                            <CloudDownloadIcon />
                        </IconButton>
                    )}

                    <Box display="flex" alignItems="center">
                        {iconButtons?.map((item, idx) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <React.Fragment key={idx}>
                                <IconButton onClick={item.onClick}>
                                    {item.icon}
                                </IconButton>
                                {item.caption && (
                                    <Typography
                                        variant="caption"
                                        display="block"
                                        gutterBottom
                                    >
                                        {item.caption}
                                    </Typography>
                                )}
                            </React.Fragment>
                        ))}
                    </Box>

                    {menu && (
                        <>
                            <IconButton
                                onClick={(ev) =>
                                    setButtonMenuDOMObject(ev.currentTarget)
                                }
                            >
                                {menuIcon || <MoreIcon />}
                            </IconButton>
                            <Menu
                                anchorEl={buttonMenuDOMiObject}
                                open={buttonMenuDOMiObject !== null}
                                onClose={() => setButtonMenuDOMObject(null)}
                            >
                                {menu?.map((item, idx) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <div key={idx}>
                                        {item.divider && <Divider />}
                                        <MenuItem
                                            onClick={() => {
                                                if (
                                                    typeof item.onClick ===
                                                    'function'
                                                ) {
                                                    item.onClick();
                                                }
                                                setButtonMenuDOMObject(null);
                                            }}
                                            disabled={item.disabled}
                                        >
                                            {item.icon && (
                                                <ListItemIcon>
                                                    {item.icon}
                                                </ListItemIcon>
                                            )}
                                            {item.caption}
                                        </MenuItem>
                                    </div>
                                ))}
                            </Menu>
                        </>
                    )}
                </Box>
            </Grid>
        );
    }

    return (
        <>
            <Box {...rest}>
                <Grid container spacing={3}>
                    {renderDefaultFields(sizes.fields)}

                    {mainAction && !internalFields && (
                        <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
                            {renderMainAction()}
                        </Grid>
                    )}

                    {extra && renderExtra(sizes.extra)}
                </Grid>
            </Box>

            {!!internalExtraFields.length && (
                <FilterDialog
                    title={cxt.t('Filter')}
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    fields={internalExtraFields}
                    onChange={onChange}
                    onApply={() => {
                        doFiltering();
                        setDialogOpen(false);
                    }}
                />
            )}
        </>
    );
}
