import React, { useContext, useState } from 'react';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import {
    AppContext,
    SttButtonFab,
    SttChipFacilityRow,
    SttChipUnlocker,
} from '../../../../sporttia/all';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';
import { SUPERADMIN_SC_DEVICES } from '../../../../lib/queryKeys';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import useScsService from '../../../../services/ScsService';
import DeviceDialog from '../../../../components/dialogs/DeviceDialog';
import { useQueryFilters } from '../../../../lib/hooks';

/**
 * Sport center devices. View used with superadmin profile.
 * @param sc Sport Center object.
 * @returns {JSX.Element}
 */
export default function AdminSCDevices({ sc }) {
    const cxt = useContext(AppContext);

    const scsService = useScsService();
    const queryClient = useQueryClient();
    const [filters, setFilters] = useQueryFilters({}, SUPERADMIN_SC_DEVICES);
    const [deviceIdSelected, setDeviceIdSelected] = useState(null);

    return (
        <>
            <SttTopFilteringControls
                p={2}
                fields={[
                    {
                        name: 'name',
                        type: 'text',
                        value: '',
                        caption: cxt.t('Search'),
                    },
                ]}
                onFilter={setFilters}
                trashAction
            />

            <SttCachedTable
                queryKey={SUPERADMIN_SC_DEVICES}
                queryFn={(params) => scsService.getScsDevices(sc?.id, params)}
                queryParams={filters}
                prefetching={false}
                onClickRow={(row) => setDeviceIdSelected(row?.id)}
                columns={[
                    {
                        title: cxt.t('Code'),
                        type: 'id',
                        field: 'id',
                    },
                    {
                        title: cxt.t('Name'),
                        align: 'left',
                        field: 'name',
                    },
                    {
                        title: cxt.t('Type'),
                        value: (row) => (
                            <SttChipUnlocker unlocker={{ type: row.type }} />
                        ),
                    },
                    {
                        title: cxt.t('Lights'),
                        type: 'active',
                        field: 'lightOn',
                    },
                    {
                        title: 'APB',
                        type: 'active',
                        field: 'antiPassback',
                    },
                    {
                        title: cxt.t('Open'),
                        type: 'active',
                        field: 'foorOpen',
                    },
                    {
                        title: 'RO',
                        type: 'active',
                        field: 'remoteOpening',
                    },
                    {
                        title: cxt.t('Counter'),
                        type: 'text',
                        field: 'counter',
                    },
                    {
                        title: cxt.t('Date'),
                        value: (row) =>
                            row.localDate
                                ? moment(row.localDate).format('D MMM YYYY')
                                : '',
                    },
                    {
                        title: cxt.t('Facilities'),
                        value: (row) => (
                            <SttChipFacilityRow facilities={row.facilities} />
                        ),
                    },
                ]}
            />

            <DeviceDialog
                id={deviceIdSelected}
                scId={sc?.id}
                onClose={() => {
                    setDeviceIdSelected(null);
                    queryClient.invalidateQueries(SUPERADMIN_SC_DEVICES);
                }}
            />

            <SttButtonFab onClick={() => setDeviceIdSelected('create')} />
        </>
    );
}
