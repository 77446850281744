import React, { ComponentType, useContext, useEffect, useState } from 'react';
import {
    BrowserRouter,
    Route,
    Switch,
    Redirect,
    useLocation,
} from 'react-router-dom';
import { Container, makeStyles, CssBaseline, Box } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import clsx from 'clsx';
import { CheckOutlined } from '@material-ui/icons';
import { ErrorBoundary } from 'react-error-boundary';
import { AppProvider, AppContext } from './sporttia/all';
import { getPath, paths } from './pages/Pages';
import GlobalCSS from './styles/GlobalCSS';
import UserAccessPopup from './components/popups/UserAccessPopup';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Page401 from './pages/Page401';
import { AlertDialog } from './components/dialogs/AlertDialog';
import constants from './config/constants';
import generalConfig from './config/general';
import SttFullPageError from './components/error/SttFullPageError';
import privilegedPaths from './config/SporttiaPrivilegedPaths';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100vh',
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflowX: 'hidden',
    },
    paddingTopContent: {
        [theme.breakpoints.down('xs')]: {
            paddingTop: 64,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 64,
        },
    },
    headerlessContent: {
        flexGrow: 1,
        overflowX: 'hidden',
    },
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(4),
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: 'auto',
    },
    fullScreenContainer: {
        padding: 0,
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: 'auto',
    },
    plainContent: {
        flex: 1,
    },
}));

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

function escapeRegex(string: string) {
    return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

// Implementación cristiana de lo de arriba: comparamos con window.location.href y
// además con location (useLocation) cuando no se cambia window.location.href.
function matchRoutes(array: string[], location: { pathname: string }) {
    return array.reduce((result, route) => {
        const regExp = new RegExp(
            `${escapeRegex(route).replace(':id', '[0-9]+')}$`,
            'gi',
        );
        return (
            result ||
            regExp.test(window.location.href) ||
            (location && route === location.pathname)
        );
    }, false);
}

type ProtectedRouteProps = {
    path: string;
    component: ComponentType;
    routeRole?: string;
    userRole?: string;
    exact: boolean;
};

type PrivilegedSporttiaPaths = {
    [key: string]: number;
};

/*  Función para comprobar que el administrador de centro accede a una pagina (a traves de la ruta o del menu) para la
    que tiene privilegios de acceso e interacción.

*/

function checkPrivilegesForPath(
    path: string,
    privileges: Array<number>,
): boolean {
    const privilegedPathsToCheck: PrivilegedSporttiaPaths =
        privilegedPaths.paths as PrivilegedSporttiaPaths;

    if (Object.prototype.hasOwnProperty.call(privilegedPathsToCheck, path)) {
        // Ruta privilegiada
        if (
            privileges.length > 0 &&
            privilegedPathsToCheck[path] !== undefined &&
            privileges.includes(privilegedPathsToCheck[path] as number)
        ) {
            return true;
        }

        return false;
    }

    // Ruta no privilegiada
    return true;
}

/**
 * Protected route.
 * En una ruta protegida pueden ocurrir dos cosas:
 *    1. Si el usuario está logueado y tanto la ruta como el usuario tienen el mismo rol le carga dicha ruta. La excepción
 *    es para el caso en el que el rol sea SPORTCENTER. En ese caso se hace un segundo filtrado en base a los privilegios del
 *    administrador (para evitar el bypass del menu lateral escribiendo la ruta directamente en el navegador)
 *    2. Si el usuario está logueado, pero la ruta y el usuario no tienen el mismo rol se le muestra la página
 *    de acceso no autorizado.
 *    3. Si el usuario no está logueado y quiere acceder a una ruta protegida primero se lleva a la página de
 *    login y luego se le redirige a la página que había pedido.
 */
function ProtectedRoute({ path, component, ...rest }: ProtectedRouteProps) {
    const cxt = useContext(AppContext)!;
    if (
        cxt.logged &&
        'routeRole' in rest &&
        'userRole' in rest &&
        rest.routeRole === rest.userRole
    ) {
        if (rest.userRole === constants.roles.sportcenter) {
            if (
                checkPrivilegesForPath(
                    path,
                    cxt?.privileges ? cxt.privileges : [],
                )
            )
                return <Route path={path} component={component} {...rest} />;
        } else {
            return <Route path={path} component={component} {...rest} />;
        }

        return (
            <Route>
                <Page401 />
            </Route>
        );
    }

    if (
        cxt.logged &&
        'routeRole' in rest &&
        'userRole' in rest &&
        rest.routeRole !== rest.userRole
    ) {
        return (
            <Route>
                <Page401 />
            </Route>
        );
    }

    return (
        <Route
            key="wait-login"
            render={(props) => (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: props.location },
                    }}
                    {...rest}
                />
            )}
        />
    );
}

let logoutTimeout: ReturnType<typeof setTimeout>;

function SporttiaContent() {
    const cxt = useContext(AppContext)!;
    const classes = useStyles();
    const location = useLocation();

    const [sessionExpired, setSessionExpired] = useState(false);

    useEffect(() => {
        if (sessionExpired === true) {
            cxt.setLoggedUser(false);
            cxt.setHeader(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionExpired]);

    // Load pages from Pages
    const routes = paths.map((path) => {
        if ('protected' in path && path.protected) {
            const roleProps = {
                ...('role' in path && { routeRole: path.role }),
                ...('role' in path &&
                    cxt.user &&
                    cxt.user.role && { userRole: cxt.user.role }),
            };

            return (
                <ProtectedRoute
                    key={path.id}
                    exact
                    path={path.path}
                    component={path.component as ComponentType}
                    {...roleProps}
                />
            );
        }

        return (
            <Route
                key={path.id}
                exact
                path={path.path}
                component={path.component as ComponentType}
            />
        );
    });

    // Tenemos que interceptar ciertas rutas para evitar pintar header y footer.
    // Existen 4 opciones (en Page.js, el valor de true no tiene sentido ponerlo por que solo se tiene en cuenta si es false):
    // - Que la ruta lleve solo el valor de "header" (header: true o header: false).
    // - Que la ruta lleve solo el valor de "footer" (footer: true o footer: false).
    // - Que la ruta lleve el valor de "header" y "footer" (header: true o header: false y footer: true o footer: false).
    // - Que la ruta no lleve ni "header" ni "footer" entonces el valor de ambos es true (header: true y footer: false).

    // Con las siguientes dos líneas conseguimos todas aquellas rutas que no queremos que tengan header. (header: false en Pages.js)
    const routesNoHeader = paths
        .filter((path) => 'header' in path && !path.header)
        .map((r) => r.path);

    // Con las siguientes dos líneas conseguimos todas aquellas rutas que no queremos que tengan footer. (footer: false en Pages.js)
    const routesNoFooter = paths
        .filter((path) => 'footer' in path && !path.footer)
        .map((r) => r.path);

    // Con las siguientes dos líneas conseguimos todas aquellas rutas que queremos que sean fullScreen. (fullScreen: true en Pages.js)
    const routesFullScreen = paths
        .filter((path) => 'fullScreen' in path && path.fullScreen)
        .map((r) => r.path);

    const interceptRouteMatchNoHeader = matchRoutes(routesNoHeader, location);
    const interceptRouteMatchNoFooter = matchRoutes(routesNoFooter, location);
    const interceptRouteMatchFullScreen = matchRoutes(
        routesFullScreen,
        location,
    );

    const header = interceptRouteMatchNoHeader ? null : <Header />;
    const footer = interceptRouteMatchNoFooter ? null : <Footer />;
    const fullScreen = interceptRouteMatchFullScreen;

    // ¿tenemos que mostrar el popup de acceso por dispositivos?
    // Este valor se actualizará al recibir mensajes del socket abierto contra v7
    const socketMessages = cxt.getSocketMessages();
    const userAccessPopup = (
        <UserAccessPopup
            visible={socketMessages.length > 0}
            onDelete={cxt.deleteReceivedMessage}
            messages={socketMessages}
        />
    );

    // Resetea el timer de logout, pero solo si existe un usuario y no es un centro (para evitar llamadas diarias porque 'da error')
    const resetLogoutTimeout = () => {
        clearTimeout(logoutTimeout);
        if (
            cxt?.user?.id &&
            cxt.user.role === constants.roles.user &&
            generalConfig.sessionTimeout.isEnabled
        ) {
            logoutTimeout = setTimeout(() => {
                setSessionExpired(true);
            }, generalConfig.sessionTimeout.timeoutMs);
        }
    };

    // Cuando el usuario toca una tecla, reseteamos el timeout
    const handleKeyUp = () => {
        resetLogoutTimeout();
    };

    // Cuando el usuario mueve el ratón, reseteamos el timeout
    const handleMouseMove = () => {
        resetLogoutTimeout();
    };

    // Cuando el usuario pulsa un botón del ratón, reseteamos el timeout
    const handleMouseUp = () => {
        resetLogoutTimeout();
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            onKeyUp={handleKeyUp}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            className={classes.root}
            style={{
                flexDirection:
                    cxt.user?.role === constants.roles.user ? 'column' : 'row',
            }}
        >
            {cxt.logged ? (
                <>
                    {header}
                    <div
                        className={clsx(
                            header !== null
                                ? classes.content
                                : classes.headerlessContent,
                            cxt.user?.role !== constants.roles.user &&
                                classes.paddingTopContent,
                        )}
                    >
                        <Container
                            maxWidth="xl"
                            className={`${
                                fullScreen === true
                                    ? classes.fullScreenContainer
                                    : classes.container
                            }`}
                        >
                            <Box flex={1}>
                                <Switch>
                                    {/* Nota: estas rutas sobreescriben las definidas en Pages/paths, de forma que redireccionamos a / */}
                                    {/* <Route exact path='/login'> */}
                                    {/*    {(cxt?.user?.role === 'SPORTCENTER' || cxt?.user?.role === 'ADMIN') ? <Redirect to={getPath('home')}/> : (cxt?.user?.role === 'USER' || cxt?.user?.role === 'TEACHER') && <Redirect to={getPath('search')}/>} */}
                                    {/* </Route> */}
                                    <Route exact path="/register">
                                        {cxt.user?.role ===
                                            constants.roles.sportcenter ||
                                        cxt.user?.role ===
                                            constants.roles.admin ? (
                                            <Redirect to={getPath('home')} />
                                        ) : (
                                            (cxt.user?.role ===
                                                constants.roles.user ||
                                                cxt.user?.role ===
                                                    constants.roles
                                                        .teacher) && (
                                                <Redirect
                                                    to={getPath('search')}
                                                />
                                            )
                                        )}
                                    </Route>
                                    <Route exact path="/">
                                        {cxt.user?.role ===
                                            constants.roles.sportcenter ||
                                        cxt.user?.role ===
                                            constants.roles.admin ? (
                                            <Redirect to={getPath('home')} />
                                        ) : (
                                            (cxt.user?.role ===
                                                constants.roles.user ||
                                                cxt.user?.role ===
                                                    constants.roles
                                                        .teacher) && (
                                                <Redirect
                                                    to={getPath('search')}
                                                />
                                            )
                                        )}
                                    </Route>
                                    {routes}
                                </Switch>
                            </Box>
                            {userAccessPopup}
                        </Container>
                        {footer}
                    </div>
                </>
            ) : (
                <>
                    {header}
                    <div
                        className={clsx(
                            header !== null
                                ? classes.content
                                : classes.headerlessContent,
                            classes.paddingTopContent,
                        )}
                    >
                        <Container maxWidth="xl" className={classes.container}>
                            <Box mx="auto">
                                <Switch>
                                    <Route exact path="/">
                                        <Redirect to={getPath('search')} />
                                    </Route>
                                    {routes}
                                </Switch>
                            </Box>
                        </Container>
                        {footer}
                    </div>
                </>
            )}

            <AlertDialog
                title={cxt.t('sessionExpired.title')}
                open={sessionExpired}
                buttons={[
                    {
                        icon: <CheckOutlined />,
                        caption: cxt.t('Close'),
                        onClick: () => {
                            setSessionExpired(false);
                            clearTimeout(logoutTimeout);
                        },
                    },
                ]}
                subtitle={cxt.t('sessionExpired.message')}
            />
        </div>
    );
}

/**
 * Main App component
 */
export default function App() {
    // Load the tawk script but only in PRO and not using the embed widget.
    useEffect(() => {
        if (
            generalConfig.environment === 'PRO' &&
            !window.location.href.match(/\/[\d]+\/widget$/)
        ) {
            const script = document.createElement('script');
            script.src =
                'https://embed.tawk.to/56cd89c76cb3a9164247a9fb/default';
            script.charset = 'UTF-8';
            script.async = true;
            script.setAttribute('crossorigin', '*');
            script.setAttribute('id', 'tawk-script');
            document.body.appendChild(script);

            // Return a callback to offload the script (this is called on component unmount, which should be never, but just in case...)
            return () => {
                const tawkScript = document.getElementById('tawk-script');
                if (tawkScript?.parentNode) {
                    tawkScript.parentNode.removeChild(tawkScript);
                }
            };
        }
    }, []);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <GlobalCSS />
            <QueryClientProvider client={queryClient}>
                <AppProvider>
                    <ErrorBoundary fallback={<SttFullPageError />}>
                        <CssBaseline />
                        <BrowserRouter>
                            <SporttiaContent />
                        </BrowserRouter>
                    </ErrorBoundary>
                </AppProvider>
                <ReactQueryDevtools initialIsOpen />
            </QueryClientProvider>
        </MuiPickersUtilsProvider>
    );
}
