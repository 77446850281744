import constants from '../config/constants';
import { PaymentForm } from '../types/payment';
// Retorna paymentForm basandose en el valor trash del pago y teniendo en cuenta que LATER ahora es ''
export function getPaymentForm(payment: {
    paymentForm: PaymentForm | '';
    trash: boolean;
}) {
    // Si existe la forma de pago y es distinto de '', retornamos
    if (payment?.paymentForm) return payment?.paymentForm;
    // Si el pago es pendiente y no esta eliminado, retornamos LATER
    if (
        payment?.paymentForm === constants.payment.pendingPaymentForm &&
        !payment.trash
    )
        return constants.payment.paymentForms.later.name;

    return null;
}
