import React, { useContext } from 'react';
import { Box, Grid } from '@material-ui/core';
import {
    AppContext,
    SttFacilityList,
    SttFormText,
    SttFormCheck,
    SttFormTextarea,
    SttSelectDeviceStatus,
    SttSelectUnlockerType,
} from '../../../sporttia/all';
import SimulateAccessDialog from './SimulateAccessDialog';

export default function DeviceDetails({
    device,
    scId,
    setProperty,
    setSimulateAccessOpen,
    simulateAccessOpen,
}) {
    const cxt = useContext(AppContext);

    const checkIfUserSC = cxt.logged && cxt.user.role !== 'ADMIN';

    // Añadir facility a este device y luego añadir localmente el 'facility' a device.facilities
    function addFacility(facility) {
        cxt.api('POST', `/devices/${device.id}/facilities/${facility.id}`, {
            success: () => {
                setProperty({
                    name: 'facilities',
                    value: (device.facilities || []).concat([facility]),
                });
            },
        });
    }

    // Eliminar facility de este device y luego quitar localmente el 'facility' de device.facilities
    function removeFacility(facility) {
        cxt.api('DELETE', `/devices/${device.id}/facilities/${facility.id}`, {
            confirmation: true,
            success: () => {
                setProperty({
                    name: 'facilities',
                    value: device.facilities.filter(
                        (f) => f.id !== facility.id,
                    ),
                });
            },
        });
    }

    // Render
    return (
        <>
            {device && (
                <Box p={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={8}>
                                    <SttFormText
                                        caption={cxt.t('name')}
                                        name="name"
                                        defVal={device.name}
                                        onChange={setProperty}
                                        disabled={checkIfUserSC}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <SttSelectUnlockerType
                                        name="type"
                                        defVal={device.type}
                                        onChange={setProperty}
                                        disabled={checkIfUserSC}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SttFormTextarea
                                        caption={cxt.t('Description')}
                                        name="description"
                                        defVal={device.description}
                                        onChange={setProperty}
                                        disabled={checkIfUserSC}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SttSelectDeviceStatus
                                        caption={cxt.t('Status')}
                                        name="status"
                                        defVal={device.status}
                                        onChange={setProperty}
                                        disabled={checkIfUserSC}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SttFormText
                                        caption={cxt.t('Signature')}
                                        name="signature"
                                        defVal={device.signature}
                                        onChange={setProperty}
                                        disabled={checkIfUserSC}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <SttFormText
                                        caption={cxt.t('PreLight')}
                                        name="preLight"
                                        defVal={device.preLight}
                                        onChange={setProperty}
                                        disabled={checkIfUserSC}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <SttFormText
                                        caption={cxt.t('PostLight')}
                                        name="postLight"
                                        defVal={device.postLight}
                                        onChange={setProperty}
                                        disabled={checkIfUserSC}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <SttFormText
                                        caption={cxt.t('PreCortesy')}
                                        name="preCortesy"
                                        defVal={device.preCortesy}
                                        onChange={setProperty}
                                        disabled={checkIfUserSC}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <SttFormText
                                        caption={cxt.t('Margin')}
                                        name="margin"
                                        defVal={device.margin}
                                        onChange={setProperty}
                                        disabled={checkIfUserSC}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <SttFormText
                                        caption={cxt.t('SirenTime')}
                                        name="soundTime"
                                        defVal={device.soundTime}
                                        onChange={setProperty}
                                        disabled={checkIfUserSC}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <SttFormText
                                        caption={cxt.t('PendingPaymentsMargin')}
                                        name="soundTime"
                                        defVal={device.pendingPaymentsMargin}
                                        onChange={setProperty}
                                        disabled={checkIfUserSC}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    {/* --- Anti Passback --- */}
                                    <Box>
                                        <SttFormCheck
                                            caption={cxt.t('AntiPassback')}
                                            name="antiPassback"
                                            checked={device.antiPassback}
                                            onChange={setProperty}
                                            disabled={checkIfUserSC}
                                        />
                                    </Box>

                                    {/* --- Checking direction out --- */}
                                    <Box>
                                        <SttFormCheck
                                            caption={cxt.t(
                                                'CheckingDirectionOutDesc',
                                            )}
                                            name="checkDirectionOut"
                                            checked={device.checkDirectionOut}
                                            onChange={setProperty}
                                            disabled={checkIfUserSC}
                                        />
                                    </Box>

                                    {/* --- Remote opening --- */}
                                    <Box>
                                        <SttFormCheck
                                            caption={cxt.t('RemoteOpeningDesc')}
                                            name="remoteOpening"
                                            checked={device.remoteOpening}
                                            onChange={setProperty}
                                            disabled={checkIfUserSC}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            {device.id && !device.trash && (
                                <SttFacilityList
                                    facilities={device.facilities || []}
                                    onAdd={(facility) => addFacility(facility)}
                                    onDelete={(facility) =>
                                        removeFacility(facility)
                                    }
                                    scId={scId}
                                    disabled={checkIfUserSC}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            )}

            {/* --- Dilalog for simulating --- */}
            <SimulateAccessDialog
                open={simulateAccessOpen}
                device={device}
                onClose={() => setSimulateAccessOpen(false)}
            />
        </>
    );
}
