import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Typography,
    useMediaQuery,
    useTheme,
    makeStyles,
    Container,
} from '@material-ui/core';
import moment from 'moment';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import ModalImage from 'react-modal-image';
import { AppContext, SttTabs } from '../../../sporttia/all';
import { useCrud, useLoader } from '../../../lib/hooks';
import { formatPeriod } from '../../../lib/utils';
import { getPath } from '../../Pages';
import EventDetails from './EventDetails';
import EventInscriptions from './EventInscriptions';
import EventTeam from './EventTeam';
import SignedUsersList from '../../../sporttia/lists/SignedUsersList';
import translations from '../../../translations';
import constants from '../../../config/constants';
import SignedTeamsByCategoryList from '../../../sporttia/lists/SignedTeamsByCategoryList';

const useStyles = makeStyles((theme) => ({
    dateBox: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 62,
        borderRadius: 4,
    },
    greenBg: {
        backgroundColor: 'green',
    },
    redBg: {
        backgroundColor: 'red',
    },
    inscriptionLabel: {
        color: '#fff',
        fontSize: 13,
    },
    eventName: {
        fontSize: 20,
        color: '#fff',
    },
    registrationLabel: {
        color: '#fff',
        fontSize: 15,
    },
    registrationBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        minHeight: 50,
    },
    imageColumn: {
        maxWidth: 260,
    },
    imageRow: {
        width: '100%',
    },
    imgBG: {
        width: '100%',
        background: 'linear-gradient(rgba(0,0,0,0.9), rgba(0,0,0,0.0))',
    },
}));

export default function Event({ match }) {
    const [event, setEvent, , Get] = useCrud();
    const [inscriptions, setInscriptions] = useState(null);
    const [team, setTeam] = useState();
    const [me, setMe] = useState();
    const [eventCategories, setEventCategories] = useState();
    const [files, setFiles] = useState([]);
    const cxt = useContext(AppContext);
    const classes = useStyles();
    const [signedParticipants, setSignedParticipants] = useState();

    const theme = useTheme();
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const [, loader] = useLoader([event ? 'success' : 'loading']);
    const showSignedParticipants =
        event?.showParticipants &&
        (signedParticipants?.length > 0 || event?.numTeams > 0);
    function loadEvent() {
        const { id } = match.params;

        Get(`/events/${id}`)
            .then((response) => {
                if (response.event) {
                    setEvent(response.event);
                    setInscriptions(response.inscriptions);
                    setTeam(response.team);
                    setEventCategories(response.event.categories);
                }

                if (response.me) {
                    setMe(response.me);
                }

                if (response?.signedParticipants) {
                    const participants = response?.signedParticipants?.rows
                        ?.map((participant) => ({
                            user: {
                                fullName: participant?.fullName,
                            },
                        }))
                        .flat();
                    setSignedParticipants(participants);
                }
            })
            .catch();
    }

    function loadFiles() {
        const { id } = match.params;

        Get(`/events/${id}/files`).then((response) => {
            if (response.count > 0) {
                setFiles(response.rows);
            }
        });
    }

    useEffect(() => {
        if (match.params.id) {
            loadEvent();
            loadFiles();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!event) {
        return loader;
    }

    const inTime =
        moment().isAfter(moment(event.ini)) &&
        moment().isBefore(moment(event.end));
    const inIscTime =
        moment().isAfter(moment(event.inscIni)) &&
        moment().isBefore(moment(event.inscEnd));
    const dateFormat = mdUp ? 'D MMMM YYYY' : 'DD/MM/YYYY';
    const timeFormat = 'HH:mm:ss';
    const [period, ,] = formatPeriod(
        event.ini,
        event.end,
        { dateFormat },
        cxt.t,
    );
    const [, , , , dateTime] = formatPeriod(
        event.inscIni,
        event.inscEnd,
        { dateFormat, timeFormat },
        cxt.t,
    );

    return (
        <Container maxWidth="md">
            <Box display="flex" flexDirection={smDown ? 'column' : 'row'}>
                <Box>
                    <Box position="relative">
                        {event.poster.id ? (
                            <ModalImage
                                hideZoom
                                small={`${event.poster.url}?outfit=282`}
                                large={`${event.poster.url}?outfit=800`}
                                alt={event.name}
                            />
                        ) : (
                            <img
                                className={
                                    smDown
                                        ? classes.imageRow
                                        : classes.imageColumn
                                }
                                src={`${cxt.apiEnv.url}/v6/public/images/eventPosters/event_default_68.jpg`}
                                alt=""
                            />
                        )}
                    </Box>
                    <Box
                        className={clsx({
                            [classes.dateBox]: true,
                            [classes.greenBg]: inTime,
                            [classes.redBg]: !inTime,
                        })}
                    >
                        <Typography className={classes.inscriptionLabel}>
                            {inTime
                                ? cxt.t('page.event.inProcess')
                                : cxt.t('page.event.notInProcess')}
                        </Typography>
                        <Typography
                            className={classes.inscriptionLabel}
                            style={{ fontSize: 16 }}
                        >
                            {period}
                        </Typography>
                    </Box>
                    <Box
                        className={clsx({
                            [classes.dateBox]: true,
                            [classes.greenBg]: inIscTime,
                            [classes.redBg]: !inIscTime,
                        })}
                    >
                        <Typography className={classes.inscriptionLabel}>
                            {inIscTime
                                ? cxt.t('page.event.inscInTime')
                                : cxt.t('page.activity.inscOutTime')}
                        </Typography>
                        <Typography
                            className={classes.inscriptionLabel}
                            style={{ fontSize: 16 }}
                        >
                            {`${dateTime}`}
                        </Typography>
                    </Box>
                    {files.length > 0 && (
                        <Box mb={3}>
                            <Box mt={3} mb={2}>
                                <Typography variant="body1">
                                    <b>{cxt.t('Documents')}:</b>
                                </Typography>
                            </Box>
                            {files.map((file) => (
                                <Box my={1} key={file?.url}>
                                    <div>
                                        <a href={file.url}>{file.name}</a>
                                    </div>
                                    <Typography variant="body2">
                                        {file.description}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    )}
                </Box>
                <Box p={mdUp ? 2 : 0} flex={1}>
                    <Box mb={2}>
                        <Typography variant="h5">{event.name}</Typography>
                        <Typography variant="body1">
                            <Link
                                to={getPath('scProfile', { id: event.sc.id })}
                            >
                                {event.sc.short}
                            </Link>
                        </Typography>
                        {me && (
                            <Box
                                my={1}
                                className={`${classes.registrationBox} ${classes.greenBg}`}
                            >
                                <Typography
                                    className={classes.registrationLabel}
                                >
                                    {cxt.t('page.event.ImSigned')}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <SttTabs
                        tabs={[
                            {
                                caption: cxt.t('Event'),
                                component: (
                                    <EventDetails
                                        event={event}
                                        team={team}
                                        inscriptionDisabled={!inIscTime}
                                        onInscription={loadEvent}
                                    />
                                ),
                            },
                            {
                                caption: cxt.t('Inscriptions'),
                                component: (
                                    <EventInscriptions
                                        event={event}
                                        inscriptions={inscriptions}
                                    />
                                ),
                                show: inscriptions?.length > 0,
                            },
                            {
                                caption: cxt.t('Team'),
                                component: (
                                    <EventTeam
                                        event={event}
                                        team={team}
                                        onDelete={loadEvent}
                                    />
                                ),
                                show: !!team,
                            },
                            {
                                caption: cxt.t(
                                    translations.generic.signedUsers,
                                ),
                                show: showSignedParticipants,
                                component:
                                    event?.type ===
                                    constants.events.types.tournament ? (
                                        <SignedTeamsByCategoryList
                                            items={eventCategories}
                                        />
                                    ) : (
                                        <SignedUsersList
                                            items={signedParticipants}
                                        />
                                    ),
                            },
                        ]}
                    />
                </Box>
            </Box>
        </Container>
    );
}
