import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { useTranslations } from '../../lib/hooks';

type SttButtonUnsubscribeProps = {
    onClick: () => void;
} & ButtonProps;

/**
 * Unsubscribe button
 */
export function SttButtonUnsubscribe({
    onClick,
    ...rest
}: SttButtonUnsubscribeProps) {
    const { translate } = useTranslations();

    return (
        <Button
            {...rest}
            variant="contained"
            color="secondary"
            className="button-delete"
            startIcon={<Cancel />}
            onClick={onClick}
        >
            {translate('Unsubscribe')}
        </Button>
    );
}
