import React from 'react';
import { Box, Typography } from '@material-ui/core';
import errorIcon from '../../images/errorIcon.png';
import { useTranslations } from '../../lib/hooks';

type ErrorProps = {
    message?: string;
    heading?: string | boolean;
    imageSize?: number;
};

export default function SttError({
    message,
    heading = true,
    imageSize = 100,
}: ErrorProps) {
    const { translate } = useTranslations();

    return (
        <>
            <Box display="flex" justifyContent="center" pb={2}>
                <img
                    src={errorIcon}
                    alt="Error"
                    height={imageSize}
                    width={imageSize}
                />
            </Box>

            {heading && (
                <Typography variant="h5" align="center" gutterBottom>
                    {typeof heading === 'string' ? heading : 'Error'}
                </Typography>
            )}

            <Typography variant="body2" align="center">
                {message || translate('errorMsg')}
            </Typography>
        </>
    );
}
