import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Container,
    Box,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    LinearProgress,
    Link,
    Avatar,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { AppContext } from '../sporttia/all';
import { getPath } from '../pages/Pages';
import sporttiaSVG from '../images/sporttia.svg';

export default function PublicHeader() {
    const cxt = useContext(AppContext);
    const history = useHistory();

    // -----| Render |-----
    return (
        <Box style={{ paddingTop: 105 }}>
            <AppBar
                position="absolute"
                style={{
                    backgroundColor:
                        localStorage.getItem('environment') === 'PRE'
                            ? '#fbc02d'
                            : 'default',
                }}
            >
                <Container maxWidth="md">
                    <Toolbar>
                        <Box mr={2}>
                            <Avatar
                                style={{
                                    backgroundColor: 'white',
                                }}
                            >
                                <img
                                    style={{
                                        width: 30,
                                        height: 30,
                                    }}
                                    src={sporttiaSVG}
                                />
                            </Avatar>
                        </Box>

                        <Typography
                            noWrap
                            component="h1"
                            variant="h6"
                            color="inherit"
                        >
                            <Link
                                onClick={() => history.push('/')}
                                style={{
                                    color: 'white',
                                    textDecoration: 'none',
                                }}
                            >
                                {
                                    /* cxt?.header?.title || 'SPORTTIA' */ 'SPORTTIA'
                                }
                            </Link>
                        </Typography>

                        <Box flex={1}>
                            <Box display="flex">
                                <Box
                                    flex={1}
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    <IconButton
                                        color="inherit"
                                        className="float-right"
                                        onClick={() =>
                                            history.push(getPath('login'))
                                        }
                                    >
                                        <AccountCircleIcon />
                                        <Box sx={{ m: 1 }}>
                                            <Typography
                                                sx={{ m: '10px' }}
                                                variant="body1"
                                            >
                                                {cxt.t('page.login.Enter')}
                                            </Typography>
                                        </Box>
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
    );
}
