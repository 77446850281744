import { useContext } from 'react';
import { useAPI } from '../lib/hooks';
import useRestService from './RestService';
import { AppContext } from '../sporttia/AppContext';

const resource = '/apis';

/**
 * Hook to access all 'apis' resources.
 */
export default function useApisService() {
    const { migrationApis } = useContext(AppContext);
    const [request] = useAPI(migrationApis);
    const services = useRestService(resource);

    /**
     * GET /apis/:id/parameters request.
     * @param id API ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function getApiParameters(id, params) {
        return request('GET', `${resource}/${id}/parameters`, params);
    }

    /**
     * PUT /apis/parameters/:id request.
     * @param id API Parameter ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function updateApiParameter(id, params) {
        return request('PUT', `${resource}/parameters/${id}`, params);
    }

    /**
     * POST /apis/:id/parameters request.
     * @param id API ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function createApiParameter(id, params) {
        return request('POST', `${resource}/${id}/parameters`, params);
    }

    /**
     * DELETE /apis/parameters/:id request.
     * @param id API Parameter ID.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function removeApiParameter(id) {
        return request('DELETE', `${resource}/parameters/${id}`);
    }

    /**
     * GET /apis/:id/responses request.
     * @param id API ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function getApiResponses(id, params) {
        return request('GET', `${resource}/${id}/responses`, params);
    }

    /**
     * PUT /apis/responses/:id request.
     * @param id API Parameter ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function updateApiResponse(id, params) {
        return request('PUT', `${resource}/responses/${id}`, params);
    }

    /**
     * POST /apis/:id/responses request.
     * @param id API ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function createApiResponse(id, params) {
        return request('POST', `${resource}/${id}/responses`, params);
    }

    /**
     * DELETE /apis/responses/:id request.
     * @param id API Parameter ID.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function removeApiResponse(id) {
        return request('DELETE', `${resource}/responses/${id}`);
    }

    /**
     * GET /apis/:id/tests request.
     * @param id API ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function getApiTests(id, params) {
        return request('GET', `${resource}/${id}/tests`, params);
    }

    /**
     * GET /apis/models request.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function getApiModels(params) {
        return request('GET', `${resource}/models`, params);
    }

    /**
     * GET /apis/models/:id request.
     * @param id API Model ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function getApiModel(id, params) {
        return request('GET', `${resource}/models/${id}`, params);
    }

    /**
     * POST /apis/models request.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function createApiModel(params) {
        return request('POST', `${resource}/models`, params);
    }

    /**
     * PUT /apis/models/:id request.
     * @param id API Model ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function updateApiModel(id, params) {
        return request('PUT', `${resource}/models/${id}`, params);
    }

    /**
     * DELETE /apis/models/:id request.
     * @param id API Model ID.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function removeApiModel(id) {
        return request('DELETE', `${resource}/models/${id}`);
    }

    /**
     * POST /apis/models/:id/properties request.
     * @param id API Model ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function createApiModelProperty(id, params) {
        return request('POST', `${resource}/models/${id}/properties`, params);
    }

    /**
     * PUT /apis/models/properties/:id request.
     * @param id API Model property ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function updateApiModelProperty(id, params) {
        return request('PUT', `${resource}/models/properties/${id}`, params);
    }

    /**
     * DELETE /apis/models/properties/:id request.
     * @param id API Model property ID.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function removeApiModelProperty(id) {
        return request('DELETE', `${resource}/models/properties/${id}`);
    }

    /**
     * GET /apis/executions request.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function getApisExecutions(params) {
        return request('GET', `${resource}/executions`, params);
    }

    return {
        ...services,
        getApiParameters,
        updateApiParameter,
        createApiParameter,
        removeApiParameter,
        getApiResponses,
        updateApiResponse,
        createApiResponse,
        removeApiResponse,
        getApiTests,
        getApiModels,
        getApiModel,
        createApiModel,
        updateApiModel,
        removeApiModel,
        createApiModelProperty,
        updateApiModelProperty,
        removeApiModelProperty,
        getApisExecutions,
    };
}
